import { NotificationType } from "apis/oag";
import { Title } from "atoms/Typography";
import {
  StyledButton,
  StyledCheckbox,
  StyledControls,
  StyledDescription,
  StyledDescriptionContainer,
  StyledIconWrapper,
  StyledJob,
  StyledLeftContent,
  StyledNotificationRow,
  StyledNotificationTitle,
  StyledRightContent,
  StyledRigTitleContainer,
  StyledTime,
  StyledTitleContainer,
  StyledWellContainer,
  StyledWellName,
} from "components/Header/RightContent/Notifications/NotificationsPopover/NotificationList/NotificationRow/style";
import { useDetailedNotificationModal } from "components/Notification/useDetailedNotificationModal";
import { PDComponent } from "components/PDComponents";
import { CheckboxCheckedState } from "components/PDComponents/Checkbox/Checkbox";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { useWellShortInfo } from "hooks/wells/useWellShortInfo";
import type { CSSProperties } from "react";
import { useCallback, useState } from "react";
import { Track } from "services/Mixpanel";
import { Tooltip } from "utils/componentLibrary";
import type { BaseNotificationVm } from "utils/notifications/mappers";

dayjs.extend(relativeTime);

export const NotificationRow = ({
  notification,
  isEditMode,
  style,
  onCheckboxChange,
  isChecked,
  handleOnToggleMarkAsRead,
  handleOnMarkAsArchived,
}: {
  notification: BaseNotificationVm;
  isEditMode: boolean;
  style: CSSProperties;
  onCheckboxChange: (isChecked: boolean) => void;
  isChecked: boolean;
  handleOnToggleMarkAsRead: () => void;
  handleOnMarkAsArchived: () => void;
}) => {
  const { data: rigs } = useRigs();
  const { data: wellShortInfo } = useWellShortInfo();
  const rig = rigs.byId[notification.rigId];
  const well = wellShortInfo?.byId[notification.wellId];
  const [isHovered, setIsHovered] = useState(false);

  const { openDetailedNotification, detailedNotificationElement, isModalOpen } =
    useDetailedNotificationModal();
  const handleNotificationClick = useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >(() => {
    if (!isModalOpen) {
      Track.clickEvent("Open Detailed Notification", {
        Id: notification.id,
        Type: notification.type,
        Title: notification.title,
      });
      openDetailedNotification(notification);
    }
  }, [isModalOpen, openDetailedNotification, notification]);

  const isCheckboxShown = isEditMode || isHovered;

  const handleOnCheckboxChange = useCallback(
    (isChecked: boolean) => {
      onCheckboxChange(isChecked);
    },
    [onCheckboxChange],
  );

  return (
    <StyledNotificationRow
      $isRead={notification.isRead}
      style={style}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledRigTitleContainer $notificationType={notification.type}>
        {isCheckboxShown ? (
          <StyledCheckbox
            onChange={handleOnCheckboxChange}
            checkedState={
              isChecked
                ? CheckboxCheckedState.Checked
                : CheckboxCheckedState.Unchecked
            }
          />
        ) : (
          <StyledIconWrapper>
            {notification.type === NotificationType.ScorecardComment ? (
              <PDComponent.SvgIcon name="chat" />
            ) : (
              <PDComponent.SvgIcon
                name="exceptionNote"
                width="42"
                height="46"
              />
            )}
          </StyledIconWrapper>
        )}

        <Title level={4}>
          <strong>{rig?.shortName}</strong>
        </Title>
      </StyledRigTitleContainer>

      <StyledLeftContent onClick={handleNotificationClick}>
        <StyledTitleContainer>
          <StyledNotificationTitle
            $isRead={notification.isRead}
            $notificationType={notification.type}
          >
            {notification.popupTitle}

            <StyledTime>
              {dayjs(notification.createdAt?.utc)
                .add(notification.createdAt?.minutesOffset ?? 0, "minutes")
                .fromNow()}
            </StyledTime>
          </StyledNotificationTitle>
          <StyledWellContainer>
            <StyledWellName>
              <Title level={4} ellipsis={{ rows: 1 }} title={well?.name ?? ""}>
                {well?.name}
              </Title>
            </StyledWellName>
            {notification.jobExternalIds.length > 0 ? (
              <StyledJob>
                <Title
                  level={4}
                  variant="faded"
                  ellipsis={{ rows: 1 }}
                  title={notification.jobExternalIds.join(", ")}
                >
                  Job: {notification.jobExternalIds.join(", ")}
                </Title>
              </StyledJob>
            ) : null}
          </StyledWellContainer>

          <Title level={4}>{notification.title}</Title>
        </StyledTitleContainer>
        <StyledDescriptionContainer>
          <StyledDescription level={4} ellipsis={{ rows: 2 }}>
            {notification.description}
          </StyledDescription>
        </StyledDescriptionContainer>
      </StyledLeftContent>

      <StyledRightContent>
        <StyledControls $isVisible={isHovered}>
          <Tooltip title={notification.isRead ? "Mark as new" : "Mark as read"}>
            <StyledButton
              size="large"
              icon={<PDComponent.SvgIcon name="checkmarkOutline" />}
              $isRead={notification.isRead}
              onClick={handleOnToggleMarkAsRead}
            />
          </Tooltip>

          <Tooltip title="Archive">
            <StyledButton
              size="large"
              icon={<PDComponent.SvgIcon name="archive" />}
              onClick={handleOnMarkAsArchived}
            />
          </Tooltip>
        </StyledControls>
      </StyledRightContent>
      {detailedNotificationElement}
    </StyledNotificationRow>
  );
};
