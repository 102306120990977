/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IntelGroupingKeyPair } from './IntelGroupingKeyPair';
import {
    IntelGroupingKeyPairFromJSON,
    IntelGroupingKeyPairFromJSONTyped,
    IntelGroupingKeyPairToJSON,
} from './IntelGroupingKeyPair';

/**
 * 
 * @export
 * @interface IntelScatterPlotFactDto
 */
export interface IntelScatterPlotFactDto {
    /**
     * 
     * @type {IntelGroupingKeyPair}
     * @memberof IntelScatterPlotFactDto
     */
    key: IntelGroupingKeyPair;
    /**
     * 
     * @type {number}
     * @memberof IntelScatterPlotFactDto
     */
    measuredDepth: number;
    /**
     * 
     * @type {number}
     * @memberof IntelScatterPlotFactDto
     */
    productivity: number;
    /**
     * 
     * @type {number}
     * @memberof IntelScatterPlotFactDto
     */
    quartile: number;
}

/**
 * Check if a given object implements the IntelScatterPlotFactDto interface.
 */
export function instanceOfIntelScatterPlotFactDto(value: object): value is IntelScatterPlotFactDto {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('measuredDepth' in value) || value['measuredDepth'] === undefined) return false;
    if (!('productivity' in value) || value['productivity'] === undefined) return false;
    if (!('quartile' in value) || value['quartile'] === undefined) return false;
    return true;
}

export function IntelScatterPlotFactDtoFromJSON(json: any): IntelScatterPlotFactDto {
    return IntelScatterPlotFactDtoFromJSONTyped(json, false);
}

export function IntelScatterPlotFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelScatterPlotFactDto {
    if (json == null) {
        return json;
    }
    return {
        
        'key': IntelGroupingKeyPairFromJSON(json['Key']),
        'measuredDepth': json['MeasuredDepth'],
        'productivity': json['Productivity'],
        'quartile': json['Quartile'],
    };
}

export function IntelScatterPlotFactDtoToJSON(value?: IntelScatterPlotFactDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Key': IntelGroupingKeyPairToJSON(value['key']),
        'MeasuredDepth': value['measuredDepth'],
        'Productivity': value['productivity'],
        'Quartile': value['quartile'],
    };
}

