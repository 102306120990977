import type {
  RefetchQueryFilters,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import type { DataFeedRefreshInfoDto, DataFeedWellDatesDto } from "apis/oag";
import { DataFeedsApi } from "apis/oag";
import { RealTimeDataEnum } from "components/RealTimeIndicator";
import { useLensTemplates } from "hooks/lens/useLensTemplates";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { useDebounce } from "use-debounce";
import { API_REFRESH_INTERVAL, apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import type { PDWellsDataFeedQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const DataFeeds = new DataFeedsApi(apiConfig);

export const useWellsDataFeed = (
  isDetailed: boolean,
  options?: UseQueryOptions<Array<DataFeedRefreshInfoDto>>,
) => {
  const wellId = useAppSelector(({ state, report }) =>
    location.pathname.includes("export")
      ? report.selectedWell
      : state.selectedWell,
  );
  const lastByDepthRefreshDate = useAppSelector(
    (state) => state.dataState.lastByDepthRefreshDate,
  );
  const lastPerStandRefreshDate = useAppSelector(
    (state) => state.dataState.lastPerStandRefreshDate,
  );
  const lastRigStateRefreshDate = useAppSelector(
    (state) => state.dataState.lastRigStateRefreshDate,
  );
  const lastTvdRefreshDate = useAppSelector(
    (state) => state.dataState.lastTvdRefreshDate,
  );
  const lastWellRefreshDate = useAppSelector(
    (state) => state.dataState.lastWellRefreshDate,
  );

  const lastBatteryStateRefreshDate = useAppSelector(
    (state) => state.dataState.lastBatteryStateRefreshDate,
  );
  const lastPowerManagementRefreshDate = useAppSelector(
    (state) => state.dataState.lastPowerManagementRefreshDate,
  );
  const lastFuelManagementRefreshDate = useAppSelector(
    (state) => state.dataState.lastFuelManagementRefreshDate,
  );

  const displayedLenses = useAppSelector(
    (state) => state.state.displayedLenses,
  );
  const [realTimeDataState] = useStateQuery<RealTimeDataEnum>(
    isDetailed
      ? URL_STATE_PARAM.REALTIME_DATA_WIDGET
      : URL_STATE_PARAM.REALTIME_DATA_DASHBOARD,
    RealTimeDataEnum.ACTIVE,
  );
  const [offsetWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );
  const queryClient = useQueryClient();
  const { data: lensTemplates } = useLensTemplates();

  const refreshWells = useCallback(
    (data: Array<DataFeedRefreshInfoDto>) => {
      const opts: RefetchQueryFilters = {
        exact: false,
      };

      if (!data || !displayedLenses || !lensTemplates?.byId) return;

      // refetch one by one to not kill the server on what is essentially just a silent data refresh
      if (data.some((e) => e?.requiresByDepthRefresh)) {
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.stickSlipByDepthFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.parameterByDepthFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.parameterHeatmapFacts }],
          ...opts,
        });
      }

      if (data.some((e) => e.requiresPowerManagementRefresh)) {
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.generatorLoadProfileFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.generatorStatsFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.generatorStatusBarFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.naturalGasFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.powerConsumptionByOperationFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.powerLoadDistributionFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.powerLoadEfficiencyFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.rigPowerFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.statCardFacts }],
          ...opts,
        });
      }

      if (data.some((e) => e.requiresFuelManagementRefresh)) {
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.boilerFuelRateFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.dieselNatGasRatioFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.fuelConsumptionFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.fuelRateFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.fuelTankVolumeFacts }],
          ...opts,
        });
      }

      if (data.some((e) => e.requiresBatteryStateRefresh)) {
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.batteryStateFacts }],
          ...opts,
        });
      }

      if (data.some((e) => e?.requiresPerStandRefresh)) {
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.tagBottomFingerprintFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.kpiGroupStackedFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.kpiTypeFacts }],
          ...opts,
        });
      }

      if (data.some((e) => e?.requiresRigStateRefresh)) {
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.WedgeFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.drillingProductivityFacts }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.wellDrillingSummaryFacts }],
          ...opts,
        });
      }

      // TvD and well details refetch
      if (!isDetailed && data.some((e) => e?.requiresTvdRefresh)) {
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.StickSlipByTime }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ type: PDQueryType.WELL_OVERVIEW_ZOOM }],
          ...opts,
        });
        queryClient.invalidateQueries({
          queryKey: [{ type: PDQueryType.WELL_OVERVIEW }],
          ...opts,
        });
      }
      if (data.some((e) => e?.requiresWellRefresh)) {
        data
          .filter((e) => e.requiresWellRefresh)
          .forEach(({ wellId }) => {
            queryClient.invalidateQueries({
              queryKey: [{ type: PDQueryType.WELL_PLAN_TIMELINE }],
              ...opts,
            });
            queryClient.invalidateQueries({
              queryKey: [{ type: PDQueryType.WELL_ACTUAL_TIMELINE }],
              ...opts,
            });
            queryClient.invalidateQueries({
              queryKey: [{ type: PDQueryType.WELL_DETAILS, wellId }],
              ...opts,
            });
            queryClient.invalidateQueries({
              queryKey: [{ type: PDQueryType.WELL_LEGEND_DETAILS, wellId }],
              ...opts,
            });
          });
      }
    },
    [displayedLenses, lensTemplates?.byId, isDetailed, queryClient],
  );

  const request: Array<DataFeedWellDatesDto> | null = useMemo(() => {
    const r = !wellId
      ? null
      : [wellId, ...offsetWells].map((key) => ({
          lastBatteryStateRefreshDate: lastBatteryStateRefreshDate
            ? lastBatteryStateRefreshDate[+key]
            : defaultDateDto.from,
          lastByDepthRefreshDate: lastByDepthRefreshDate
            ? lastByDepthRefreshDate[+key]
            : defaultDateDto.from,
          lastFuelManagementRefreshDate: lastFuelManagementRefreshDate
            ? lastFuelManagementRefreshDate[+key]
            : defaultDateDto.from,
          lastPerStandRefreshDate: lastPerStandRefreshDate
            ? lastPerStandRefreshDate[+key]
            : defaultDateDto.from,
          lastPowerManagementRefreshDate: lastPowerManagementRefreshDate
            ? lastPowerManagementRefreshDate[+key]
            : defaultDateDto.from,
          lastRigStateRefreshDate: lastRigStateRefreshDate
            ? lastRigStateRefreshDate[+key]
            : defaultDateDto.from,
          lastTvdRefreshDate: lastTvdRefreshDate
            ? lastTvdRefreshDate[+key]
            : defaultDateDto.from,
          lastWellRefreshDate: lastWellRefreshDate
            ? lastWellRefreshDate[+key]
            : defaultDateDto.from,
          wellId: +key,
        }));
    return r;
  }, [
    lastBatteryStateRefreshDate,
    lastByDepthRefreshDate,
    lastFuelManagementRefreshDate,
    lastPerStandRefreshDate,
    lastPowerManagementRefreshDate,
    lastRigStateRefreshDate,
    lastTvdRefreshDate,
    lastWellRefreshDate,
    offsetWells,
    wellId,
  ]);

  const [debouncedQuery] = useDebounce(request, 1000, {
    leading: true,
  });

  const queryKey: PDWellsDataFeedQueryKey | null = useMemo(
    () =>
      !wellId
        ? null
        : {
            type: PDQueryType.WELLS_DATA_FEED,
            uid: RequestUID.wellsDataFeed,
            wellId,
            isDetailed,
            offsetWells,
          },
    [isDetailed, offsetWells, wellId],
  );

  // maybe check if there is not other facts endpoint fetching and fetch afterwards
  useQuery<DataFeedRefreshInfoDto[]>({
    queryKey: [queryKey],

    queryFn: () => {
      // If it is not enabled or everything is null don t do the request
      if (
        !debouncedQuery ||
        debouncedQuery.every((wellRequest) =>
          Object.keys(wellRequest).every((predicate) =>
            predicate === "wellId"
              ? true
              : wellRequest[predicate as keyof typeof wellRequest] === null,
          ),
        )
      )
        return [];

      return DataFeeds.apiDataFeedsPut({
        feedUpdateRequestDto: { wellDates: debouncedQuery },
      }).then((res) => {
        refreshWells(res);
        return res;
      });
    },
    ...options,
    enabled: realTimeDataState === RealTimeDataEnum.ACTIVE,
    refetchIntervalInBackground: true,
    refetchInterval: API_REFRESH_INTERVAL,
  });
};
