import {
  NotificationType,
  type ScorecardKpiCommentExceptionInfoDto,
  TimeUnit,
} from "apis/oag";
import { Title } from "atoms/Typography";
import { useDetailedNotificationModal } from "components/Notification/useDetailedNotificationModal";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { useWellShortInfo } from "hooks/wells/useWellShortInfo";
import * as Styled from "pages/PendingTasks/components/style";
import { memo, useCallback } from "react";
import { Track } from "services/Mixpanel";
import { useTimeUom } from "utils/format";

dayjs.extend(relativeTime);

export const NotificationRow = memo(
  ({ task }: { task: ScorecardKpiCommentExceptionInfoDto }) => {
    const { data: rigs } = useRigs();
    const { data: wellShortInfo } = useWellShortInfo();
    const rig = rigs.byId[task.rigId];
    const well = wellShortInfo?.byId[task.wellId];
    const {
      openDetailedNotification,
      detailedNotificationElement,
      isModalOpen,
    } = useDetailedNotificationModal();
    const hoursUOM = useTimeUom(TimeUnit.Hour);

    const handleNotificationClick = useCallback(() => {
      if (!isModalOpen) {
        Track.clickEvent("Open Detailed Task", {
          Id: task.id,
          Title: task.title,
        });
        openDetailedNotification(task);
      }
    }, [isModalOpen, openDetailedNotification, task]);

    return (
      <Styled.NotificationRow>
        <Styled.NotificationInfoRow onClick={handleNotificationClick}>
          <Styled.RigTitleContainer>
            <Title level={3}>
              <strong>{rig.shortName}</strong>
            </Title>
          </Styled.RigTitleContainer>
          <Styled.TitleContainer>
            <Styled.NotificationTitle
              $notificationType={NotificationType.ExceptionRequest}
            >
              Pending Exception Request
            </Styled.NotificationTitle>
            <Styled.WellContainer>
              <Styled.WellName>
                <Title level={4} weight={500}>
                  {well?.name}
                </Title>
              </Styled.WellName>
            </Styled.WellContainer>
            {task.jobExternalIds.length > 0 ? (
              <Styled.Job>
                <Title level={4} variant="faded">
                  Job: {task.jobExternalIds.join(", ")}
                </Title>
              </Styled.Job>
            ) : null}
            <Title level={4}>{task.title}</Title>
          </Styled.TitleContainer>

          <Styled.DescriptionContainer>
            <Styled.Description>{task.description}</Styled.Description>
          </Styled.DescriptionContainer>

          <Styled.KpiSummaryContainer>
            <Styled.TitleContainer>
              <Styled.Description>{`${task.kpiFullDisplayName}: ${hoursUOM.display(task.kpiTime)}`}</Styled.Description>

              <Styled.Description>
                Exception hours: {hoursUOM.display(task.exceptionTime)}
              </Styled.Description>
            </Styled.TitleContainer>
          </Styled.KpiSummaryContainer>
          <Styled.Time>
            {dayjs(task.createdAt?.utc)
              .add(task.createdAt?.minutesOffset, "minutes")
              .fromNow()}
          </Styled.Time>
        </Styled.NotificationInfoRow>

        {detailedNotificationElement}
      </Styled.NotificationRow>
    );
  },
);
