import type { UseQueryOptions } from "@tanstack/react-query";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import type {
  ApiGeneratorStatsUserLensesIdFactsPutRequest,
  GeneratorSlotType,
  GeneratorStatsResultDto,
  GeneratorStatsUserLensDto,
  UserLensDto,
} from "apis/oag";
import { GeneratorStatsUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { useActiveGenerators } from "hooks/evergreen/useActiveGenerators";
import { useFilterParams } from "hooks/filters/useFilterParams";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useCallback } from "react";
import { useAppDispatch } from "reducers/store";
import type { IZoomData } from "reducers/types";
import { IZoomType } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateDto } from "utils/helper";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const generatorStatsUserLensesApi = new GeneratorStatsUserLensesApi(apiConfig);

export function useGeneratorStatsFetcher(
  lens: GeneratorStatsUserLensDto,
  options?: UseQueryOptions<GeneratorStatsResultDto>,
) {
  const filterParams = useFilterParams();
  const wellDataID = useSelectedWell();
  const dispatch = useAppDispatch();

  const { data: activeGenerators } = useActiveGenerators(wellDataID);
  const requestParameters: ApiGeneratorStatsUserLensesIdFactsPutRequest = {
    id: lens.id ?? -1,
    generatorStatsQueryDto: {
      ...filterParams,
      selectedGenerators: lens.selectedGenerators
        ? lens.selectedGenerators
        : activeGenerators,
    },
  };

  const [zoomData] = useStateQuery<IZoomData>(
    URL_STATE_PARAM.ZOOM_WELL,
    initialZoomData,
    [URL_STATE_PARAM.ZOOM_WIDGET],
  );
  let requestParametersWithZoom = requestParameters;
  if (zoomData.date_start && zoomData.type === IZoomType.DATE) {
    requestParametersWithZoom = {
      ...requestParameters,
      generatorStatsQueryDto: requestParameters.generatorStatsQueryDto
        ? {
            ...requestParameters.generatorStatsQueryDto,
            from: dateToDateDto(zoomData.date_start) || defaultDateDto.from,
            to: dateToDateDto(zoomData.date_end) || defaultDateDto.to,
          }
        : undefined,
    };
  }

  const queryKey: PDLensFactsQueryKey<ApiGeneratorStatsUserLensesIdFactsPutRequest> =
    {
      type: PDQueryType.FACTS,
      uid: RequestUID.generatorStatsFacts,
      params: requestParametersWithZoom,
      lensId: lens.id,
    };

  const request = useSuspenseQuery<GeneratorStatsResultDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      generatorStatsUserLensesApi
        .apiGeneratorStatsUserLensesIdFactsPut(requestParametersWithZoom, {
          signal,
        })
        .then((data) => {
          if (data?.lastUpdatedAt) {
            dispatch({
              type: "SET_POWER_MANAGEMENT_REFRESH_DATE",
              payload: {
                [wellDataID]: data?.lastUpdatedAt,
              },
            });
          }

          return data;
        }),
    ...options,
  });

  return request;
}

export function useGeneratorStatsLensUpdate(
  lens: GeneratorStatsUserLensDto,
  onLensUpdated?: (newItem: UserLensDto) => void,
) {
  const handleLensUpdate = useMutation({
    mutationFn: (lens: GeneratorStatsUserLensDto) =>
      generatorStatsUserLensesApi.apiGeneratorStatsUserLensesIdPut({
        id: lens.id,
        generatorStatsUserLensDto: lens,
      }),
    onSettled: (lens) => {
      if (lens && onLensUpdated) {
        onLensUpdated(lens);
      }
    },
  });

  const handleGeneratorUpdate = useCallback(
    async (generators: GeneratorSlotType[]) => {
      await handleLensUpdate.mutateAsync({
        ...lens,
        selectedGenerators: generators,
      });
    },
    [handleLensUpdate, lens],
  );

  return { handleGeneratorUpdate };
}
