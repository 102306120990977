/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QuarterYear } from './QuarterYear';
import {
    QuarterYearFromJSON,
    QuarterYearFromJSONTyped,
    QuarterYearToJSON,
} from './QuarterYear';
import type { IntelRankingRibbonQuarterBarSliceDto } from './IntelRankingRibbonQuarterBarSliceDto';
import {
    IntelRankingRibbonQuarterBarSliceDtoFromJSON,
    IntelRankingRibbonQuarterBarSliceDtoFromJSONTyped,
    IntelRankingRibbonQuarterBarSliceDtoToJSON,
} from './IntelRankingRibbonQuarterBarSliceDto';

/**
 * 
 * @export
 * @interface IntelRankingRibbonQuarterBarDto
 */
export interface IntelRankingRibbonQuarterBarDto {
    /**
     * 
     * @type {QuarterYear}
     * @memberof IntelRankingRibbonQuarterBarDto
     */
    quarterYear: QuarterYear;
    /**
     * 
     * @type {Array<IntelRankingRibbonQuarterBarSliceDto>}
     * @memberof IntelRankingRibbonQuarterBarDto
     */
    slices: Array<IntelRankingRibbonQuarterBarSliceDto>;
}

/**
 * Check if a given object implements the IntelRankingRibbonQuarterBarDto interface.
 */
export function instanceOfIntelRankingRibbonQuarterBarDto(value: object): value is IntelRankingRibbonQuarterBarDto {
    if (!('quarterYear' in value) || value['quarterYear'] === undefined) return false;
    if (!('slices' in value) || value['slices'] === undefined) return false;
    return true;
}

export function IntelRankingRibbonQuarterBarDtoFromJSON(json: any): IntelRankingRibbonQuarterBarDto {
    return IntelRankingRibbonQuarterBarDtoFromJSONTyped(json, false);
}

export function IntelRankingRibbonQuarterBarDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelRankingRibbonQuarterBarDto {
    if (json == null) {
        return json;
    }
    return {
        
        'quarterYear': QuarterYearFromJSON(json['QuarterYear']),
        'slices': ((json['Slices'] as Array<any>).map(IntelRankingRibbonQuarterBarSliceDtoFromJSON)),
    };
}

export function IntelRankingRibbonQuarterBarDtoToJSON(value?: IntelRankingRibbonQuarterBarDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'QuarterYear': QuarterYearToJSON(value['quarterYear']),
        'Slices': ((value['slices'] as Array<any>).map(IntelRankingRibbonQuarterBarSliceDtoToJSON)),
    };
}

