import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import type { AllWellsDataFeedQueryDto } from "apis/oag";
import { DataFeedsApi } from "apis/oag";
import { RealTimeDataEnum } from "components/RealTimeIndicator";
import {
  AttributeStore,
  useLinkedWellAttributes,
} from "hooks/filters/useLinkedAttributes";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { API_REFRESH_INTERVAL, apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateOnlyDto } from "utils/helper";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const DataFeeds = new DataFeedsApi(apiConfig);

const useCommonWellsDataFeed = (
  isEvergreen: boolean,
  optionRQ?: UseQueryOptions<boolean>,
) => {
  const reduxState = isEvergreen
    ? AttributeStore.evergreenWells
    : AttributeStore.allWells;
  const searchState = useAppSelector((state) => state[reduxState].search);
  const statusState = useAppSelector((state) => state[reduxState].status);
  const startDateState = useAppSelector((state) => state[reduxState].startDate);
  const endDateState = useAppSelector((state) => state[reduxState].endDate);
  const lastAllWellRefreshDate = useAppSelector(
    (state) => state[reduxState].lastAllWellRefreshDate,
  );
  const queryClient = useQueryClient();
  const { selectedValues, options, isLoading } = useLinkedWellAttributes({
    storeName: isEvergreen
      ? AttributeStore.evergreenWells
      : AttributeStore.allWells,
  });

  const [realTimeDataState] = useStateQuery<RealTimeDataEnum>(
    URL_STATE_PARAM.REALTIME_DATA_DASHBOARD,
    RealTimeDataEnum.UNAVAILABLE,
  );

  const refreshAllWells = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [{ type: PDQueryType.WELL_INCLUDED_WELLS_KPIS }],
      ...{ exact: false },
    });
    queryClient.invalidateQueries({
      queryKey: [{ type: PDQueryType.WELL_SUMMARIES }],
      ...{ exact: false },
    });
  }, [queryClient]);

  const requestQuery: AllWellsDataFeedQueryDto | null = useMemo(
    (): AllWellsDataFeedQueryDto | null =>
      !lastAllWellRefreshDate
        ? null
        : {
            includeEverGreenOnly: isEvergreen,
            lastWellRefreshDate: lastAllWellRefreshDate,
            search: searchState,
            operatorIds:
              selectedValues.operators === null
                ? null
                : (options?.operators ?? []).map((op) => op.id),
            rigIds:
              selectedValues.rigs === null
                ? null
                : (options?.rigs ?? []).map((rig) => rig.id),
            from: startDateState
              ? dateToDateOnlyDto(startDateState)
              : defaultDateDto.from,
            to: endDateState
              ? dateToDateOnlyDto(endDateState)
              : defaultDateDto.to,
            formationIds:
              selectedValues.formations === null
                ? null
                : (options?.formations ?? []).map((f) => f.id),
            selectedFilters: {
              includeFlatTime: true,
              includeRotatingTime: true,
              includeSlidingTime: true,
              includeNullHoleDepth: true,
              includeAlphaRigs: true,
              includeNonAlphaRigs: true,
              selectedWellStatusTypes: statusState,
            },
          },
    [
      lastAllWellRefreshDate,
      isEvergreen,
      endDateState,
      options?.formations,
      options?.operators,
      options?.rigs,
      searchState,
      selectedValues.formations,
      selectedValues.operators,
      selectedValues.rigs,
      startDateState,
      statusState,
    ],
  );

  useQuery<boolean>({
    queryKey: [{ uid: RequestUID.wellsDataFeed, requestQuery }],
    queryFn: () => {
      if (!requestQuery || realTimeDataState !== RealTimeDataEnum.ACTIVE)
        return Promise.resolve(false);
      return DataFeeds.apiDataFeedsWellsPut({
        allWellsDataFeedQueryDto: requestQuery,
      }).then((res) => {
        if (res) refreshAllWells();
        return res;
      });
    },
    ...optionRQ,
    refetchOnMount: false,
    enabled:
      realTimeDataState === RealTimeDataEnum.ACTIVE &&
      Object.values(isLoading).some((loading) => !loading),
    refetchIntervalInBackground: true,
    refetchInterval: API_REFRESH_INTERVAL,
  });
};

export const useAllWellsDataFeed = (optionRQ?: UseQueryOptions<boolean>) => {
  return useCommonWellsDataFeed(false, optionRQ);
};

export const useEvergeenWellsDataFeed = (
  optionRQ?: UseQueryOptions<boolean>,
) => {
  return useCommonWellsDataFeed(true, optionRQ);
};
