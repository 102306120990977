import type { StandKpiType } from "apis/oag";
import { DashboardType, TimeUnit } from "apis/oag";
import { useUserLensTabs } from "hooks/dashboard/useUserLensTabs";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { useEffectExceptOnMount } from "hooks/react-utils/useEffectExceptOnMount";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import { ActionableCommentRow } from "pages/RigScoreCard/RightPane/CommentsGroups/ActionableCommentRow/ActionableCommentRow";
import * as Styled from "pages/RigScoreCard/RightPane/CommentsGroups/style";
import * as CommonStyled from "pages/RigScoreCard/RightPane/StyledComponents";
import type { RequiredCommentsViewmodel } from "pages/RigScoreCard/RightPane/useRequiredComments";
import { useRequiredComments } from "pages/RigScoreCard/RightPane/useRequiredComments";
import * as TaskCounterStyles from "pages/RigScoreCard/style";
import React from "react";
import { Fragment, useCallback, useMemo } from "react";
import { useAppDispatch } from "reducers/store";
import { useTimeUom } from "utils/format";

export const CommentsGroup = () => {
  const {
    requiredFacts,
    pendingExceptionFacts,
    approvedExceptionFacts,
    wellScoreFacts,
  } = useRequiredComments();
  const { data: wellShortInfo } = useWellShortInfoSuspended();
  const { data: kpiTypes } = useKpiTypes();
  const { data: lensTabs } = useUserLensTabs(DashboardType.RigScorecard, {
    staleTime: Infinity,
  });

  const [isExceptionsCategoryVisible, setIsExceptionsCategoryVisible] =
    React.useState(true);
  const [
    isPendingExceptionsCategoryVisible,
    setIsPendingExceptionsCategoryVisible,
  ] = React.useState(true);
  const [
    isApprovedExceptionsCategoryVisible,
    setIsApprovedExceptionsCategoryVisible,
  ] = React.useState(true);
  const [isCommentsCategoryVisible, setIsCommentsCategoryVisible] =
    React.useState(true);
  const [isKpiGroupVisible, setIsKpiGroupVisible] = React.useState(true);
  const [isWellScoresGroupVisible, setIsWellScoresGroupVisible] =
    React.useState(true);
  const hourUOM = useTimeUom(TimeUnit.Hour);

  const allFacts = useMemo(
    () => [
      ...requiredFacts,
      ...pendingExceptionFacts,
      ...approvedExceptionFacts,
      ...wellScoreFacts,
    ],
    [
      requiredFacts,
      pendingExceptionFacts,
      approvedExceptionFacts,
      wellScoreFacts,
    ],
  );

  const requiredFactsWellIds = useMemo(
    () => [
      ...new Set(
        requiredFacts
          .sort(
            (a, b) => a.wellFact?.kpiValue?.rank - b.wellFact?.kpiValue?.rank,
          )
          .map((fact) => fact.wellFact.label),
      ),
    ],
    [requiredFacts],
  );

  const dispatch = useAppDispatch();

  const getWellRank = useCallback(
    (wellId: string) => {
      const well = allFacts.find(
        (fact) => fact?.wellFact?.label === wellId + "",
      );
      const rank = well?.wellFact.kpiValue.rank;
      return rank ?? "#Unknown Rank";
    },
    [allFacts],
  );
  const getWellName = useCallback(
    (wellId: string) => {
      const well = allFacts.find((fact) => fact?.wellFact?.label === wellId);
      const wellName =
        wellShortInfo?.byId?.[+(well?.wellFact?.label || "")]?.name ?? "";
      return wellName ?? "Unknown Well";
    },
    [allFacts, wellShortInfo?.byId],
  );

  const getWellJobs = useCallback(
    (wellId: string) => {
      return wellShortInfo?.byId?.[+wellId]?.jobExternalIds?.length >= 1
        ? `Job: ${wellShortInfo.byId[+wellId]?.jobExternalIds.join(", ")}`
        : null;
    },
    [wellShortInfo?.byId],
  );

  const getKpiGroupNameFromFact = useCallback(
    (fact: RequiredCommentsViewmodel) => {
      const id = fact.rigGroupFacts.tabId;
      return lensTabs.find((tab) => tab.id === id)?.name;
    },
    [lensTabs],
  );

  const getKpiGroupDescriptionFromFact = useCallback(
    (fact: RequiredCommentsViewmodel) => {
      const id = fact.rigGroupFacts.tabId;
      return lensTabs.find((tab) => tab.id === id)?.description;
    },
    [lensTabs],
  );

  const getWellGroupKpis = useCallback(
    (wellId: string) => {
      const filteredRequiredFacts = requiredFacts.filter(
        (fact) => fact?.wellFact?.label === wellId,
      );
      const kpiNamesAndIds: {
        id: number;
        name: string;
        description: string;
      }[] = [];

      filteredRequiredFacts.forEach((fact) => {
        const id = fact.rigGroupFacts.tabId;
        const kpiGroupName = getKpiGroupNameFromFact(fact);
        const kpiGroupDescription = getKpiGroupDescriptionFromFact(fact);
        if (!kpiNamesAndIds.find((presentKpi) => presentKpi.id === id)) {
          kpiNamesAndIds.push({
            name: kpiGroupName || "",
            description: kpiGroupDescription || "",
            id,
          });
        }
      });

      return kpiNamesAndIds;
    },
    [requiredFacts, getKpiGroupDescriptionFromFact, getKpiGroupNameFromFact],
  );

  const getKpiNameFromType = useCallback(
    (kpiType: StandKpiType) => {
      try {
        const lens = kpiTypes?.byId[kpiType];
        return lens?.shortName ?? lens?.name;
      } catch {
        return "";
      }
    },
    [kpiTypes?.byId],
  );

  const getKpiCommentsFacts = useCallback(
    (wellId: string, groupKpiId: number) => {
      return requiredFacts
        .filter((fact) => fact?.wellFact?.label === wellId)
        .filter((fact) => fact.rigGroupFacts.tabId === groupKpiId)
        .map((fct) => ({
          fact: fct,
          kpiName: getKpiNameFromType(fct.cardFacts.kpiType),
        }));
    },
    [getKpiNameFromType, requiredFacts],
  );

  useEffectExceptOnMount(() => {
    dispatch({
      type: "ADD_FACT_INFO",
      payload: undefined,
    });
  }, [requiredFacts.length, dispatch]);

  const commentsCategoryCount = useMemo(
    () => (requiredFacts?.length ?? 0) + (wellScoreFacts?.length ?? 0),
    [requiredFacts?.length, wellScoreFacts?.length],
  );

  return (
    <>
      <Styled.CategoryDropdown
        role="button"
        onClick={() => setIsExceptionsCategoryVisible((val) => !val)}
      >
        <div>
          <Styled.Chevron isRotated={isExceptionsCategoryVisible} />
        </div>
        <div>
          <h1>Exception Requests</h1>
          <p>Pending Approval Review</p>
        </div>
        <div>
          {pendingExceptionFacts.length > 0 ? (
            <TaskCounterStyles.TaskCounter>
              {pendingExceptionFacts.length}
            </TaskCounterStyles.TaskCounter>
          ) : null}
        </div>
      </Styled.CategoryDropdown>

      {isExceptionsCategoryVisible ? (
        <>
          <Styled.SubcategoryDropdown
            role="button"
            onClick={() => setIsPendingExceptionsCategoryVisible((val) => !val)}
          >
            <div>
              <Styled.Chevron isRotated={isPendingExceptionsCategoryVisible} />
            </div>
            <Styled.SubtitleWithScore>
              <h2>Pending</h2>
              {pendingExceptionFacts.length > 0 ? (
                <TaskCounterStyles.TaskCounter>
                  {pendingExceptionFacts.length}
                </TaskCounterStyles.TaskCounter>
              ) : null}
            </Styled.SubtitleWithScore>
          </Styled.SubcategoryDropdown>

          {isPendingExceptionsCategoryVisible
            ? pendingExceptionFacts
                .sort((a, b) => {
                  const aCompVal = `${a.wellFact.label}${getKpiGroupNameFromFact(a)}${a.cardFacts.kpiType}`;
                  const bCompVal = `${b.wellFact.label}${getKpiGroupNameFromFact(b)}${b.cardFacts.kpiType}`;
                  return bCompVal.localeCompare(aCompVal);
                })
                .map((exceptionFact) => (
                  <ActionableCommentRow
                    type="exception"
                    key={
                      +exceptionFact.wellFact.label + exceptionFact.comment.id
                    }
                    fact={exceptionFact}
                    kpiName={
                      getKpiNameFromType(exceptionFact.cardFacts.kpiType) || ""
                    }
                    rank={getWellRank(exceptionFact.wellFact.label)}
                    wellName={getWellName(exceptionFact.wellFact.label)}
                    kpiGroupName={getKpiGroupNameFromFact(exceptionFact) || ""}
                    kpiDescription={
                      getKpiGroupDescriptionFromFact(exceptionFact) || ""
                    }
                    kpiDuration={exceptionFact.wellFact.kpiValue?.value || 0}
                    exceptionTimeLabel={hourUOM.display(
                      exceptionFact.comment.exceptionTime,
                      { fractionDigits: 2 },
                    )}
                  />
                ))
            : null}

          <Styled.SubcategoryDropdown
            role="button"
            onClick={() =>
              setIsApprovedExceptionsCategoryVisible((val) => !val)
            }
          >
            <div>
              <Styled.Chevron isRotated={isApprovedExceptionsCategoryVisible} />
            </div>
            <Styled.SubtitleWithScore>
              <h2>Approved</h2>
              {approvedExceptionFacts.length > 0 ? (
                <TaskCounterStyles.TaskCounter $isPositive>
                  {approvedExceptionFacts.length}
                </TaskCounterStyles.TaskCounter>
              ) : null}
            </Styled.SubtitleWithScore>
          </Styled.SubcategoryDropdown>

          {isApprovedExceptionsCategoryVisible
            ? approvedExceptionFacts
                .sort((a, b) => {
                  const aCompVal = `${a.wellFact.label}${getKpiGroupNameFromFact(a)}${a.cardFacts.kpiType}`;
                  const bCompVal = `${b.wellFact.label}${getKpiGroupNameFromFact(b)}${b.cardFacts.kpiType}`;
                  return bCompVal.localeCompare(aCompVal);
                })
                .map((exceptionFact) => (
                  <ActionableCommentRow
                    type="exception"
                    key={
                      +exceptionFact.wellFact.label + exceptionFact.comment.id
                    }
                    fact={exceptionFact}
                    kpiName={
                      getKpiNameFromType(exceptionFact.cardFacts.kpiType) || ""
                    }
                    rank={getWellRank(exceptionFact.wellFact.label)}
                    wellName={getWellName(exceptionFact.wellFact.label)}
                    kpiGroupName={getKpiGroupNameFromFact(exceptionFact) || ""}
                    kpiDescription={
                      getKpiGroupDescriptionFromFact(exceptionFact) || ""
                    }
                    kpiDuration={exceptionFact.wellFact.kpiValue?.value || 0}
                    exceptionTimeLabel={hourUOM.display(
                      exceptionFact.comment.exceptionTime,
                      { fractionDigits: 2 },
                    )}
                  />
                ))
            : null}
        </>
      ) : null}

      <Styled.CategoryDropdown
        role="button"
        onClick={() => setIsCommentsCategoryVisible((val) => !val)}
      >
        <div>
          <Styled.Chevron isRotated={isCommentsCategoryVisible} />
        </div>
        <div>
          <h1>Comments Required</h1>
          <p>Please add notes to the Items below</p>
        </div>
        <div>
          {commentsCategoryCount > 0 ? (
            <TaskCounterStyles.TaskCounter>
              {commentsCategoryCount}
            </TaskCounterStyles.TaskCounter>
          ) : null}
        </div>
      </Styled.CategoryDropdown>

      {isCommentsCategoryVisible ? (
        <>
          <Styled.SubcategoryDropdown
            role="button"
            onClick={() => setIsWellScoresGroupVisible((val) => !val)}
          >
            <div>
              <Styled.Chevron isRotated={isWellScoresGroupVisible} />
            </div>
            <h2>Well Scores</h2>
          </Styled.SubcategoryDropdown>

          {isWellScoresGroupVisible
            ? wellScoreFacts.map((fact) => (
                <ActionableCommentRow
                  type="wellscore"
                  jobsLabel={getWellJobs(fact.wellFact.label) || ""}
                  key={
                    +fact.wellFact.label +
                    fact.rigGroupFacts.tabId +
                    fact.cardFacts.kpiType
                  }
                  fact={fact}
                  kpiName={getKpiNameFromType(fact.cardFacts.kpiType) || ""}
                  rank={getWellRank(fact.wellFact.label)}
                  wellName={getWellName(fact.wellFact.label)}
                  kpiDescription={getKpiGroupDescriptionFromFact(fact) || ""}
                  kpiGroupName={getKpiGroupNameFromFact(fact) || ""}
                />
              ))
            : null}

          <Styled.SubcategoryDropdown
            role="button"
            onClick={() => setIsKpiGroupVisible((val) => !val)}
          >
            <div>
              <Styled.Chevron isRotated={isKpiGroupVisible} />
            </div>
            <h2>KPIs</h2>
          </Styled.SubcategoryDropdown>

          {isKpiGroupVisible
            ? requiredFactsWellIds.map((wellId) => (
                <CommonStyled.RowLeft key={wellId}>
                  <CommonStyled.RowPadding isAltBg>
                    <Styled.WellRank>#{getWellRank(wellId)}</Styled.WellRank>

                    <Styled.WellInfo>
                      <Styled.WellTitle>{getWellName(wellId)}</Styled.WellTitle>
                      <Styled.WellJobs>{getWellJobs(wellId)}</Styled.WellJobs>
                    </Styled.WellInfo>
                  </CommonStyled.RowPadding>
                  {getWellGroupKpis(wellId).map(({ name, description, id }) => {
                    return (
                      <Fragment key={wellId + name + id}>
                        <CommonStyled.RowPadding
                          isAltBg
                          hasBorder
                          paddingVertical={12}
                          style={{
                            display: "grid",
                            gap: 0,
                          }}
                        >
                          <Styled.Title key={id}>{name}</Styled.Title>
                          {description ? (
                            <Styled.Description>
                              {description}
                            </Styled.Description>
                          ) : null}
                        </CommonStyled.RowPadding>
                        {getKpiCommentsFacts(wellId, id).map(
                          ({ fact, kpiName }, idx) => (
                            <ActionableCommentRow
                              type="kpi"
                              key={idx + (kpiName || "")}
                              fact={fact}
                              kpiName={kpiName || ""}
                              kpiDescription={
                                getKpiGroupDescriptionFromFact(fact) || ""
                              }
                              kpiGroupName={name}
                            />
                          ),
                        )}
                      </Fragment>
                    );
                  })}
                </CommonStyled.RowLeft>
              ))
            : null}
        </>
      ) : null}
    </>
  );
};
