import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import styled from "styled-components";
import colors from "utils/colors";
import { Card as PdCard } from "utils/componentLibrary";
import { PdCardBody } from "utils/componentLibrary/Card";

export const ChevronDownIcon = styled(PDComponent.SvgIcon).attrs({
  name: "chevronDown",
})`
  opacity: 0;
`;

export const Option = styled.div`
  width: 100%;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  cursor: pointer;
`;

export const SelectedFuel = styled(Title).attrs({
  level: 3,
  variant: "faded",
})`
  :hover {
    cursor: pointer;

    ${ChevronDownIcon} {
      opacity: 1;
    }
  }
`;

export const PlaceHolder = styled.div`
  height: 15px;
  width: 100%;
`;

export const Card = styled(PdCard)`
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 ${colors.soft_shadow};
  min-width: 186px;
  ${PdCardBody} {
    padding: 0;
  }
`;
