/* eslint-disable react/no-multi-comp */
import type { DragEndEvent } from "@dnd-kit/core";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import type {
  KpiGroupUserLensDto,
  ParameterByDepthUserLensDto,
  UserLensDto,
} from "apis/oag";
import { DashboardType, DisplayOption, VisualAidType } from "apis/oag";
import { Button } from "atoms/Form";
import { indicatorNames } from "components/DetailsTopBar/utils";
import { Section, SectionLayout } from "components/Layout";
import { OrderedVisualAidType } from "components/Lenses/CommonLensSettingsModalContents";
import { MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import { getAbilityMatrix } from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import IconComponent from "components/WellPlan/WellPlanCommun/Icon";
import { useUserLensTabs } from "hooks/dashboard/useUserLensTabs";
import { useKpiOptions } from "hooks/drillingInvariants/useKpiOptions";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { isWedgeTemplate, useLensTemplates } from "hooks/lens/useLensTemplates";
import { useUserLenses } from "hooks/lens/useUserLenses";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import ExportTopBar from "pages/Report/components/ExportTopBar";
import {
  Cell,
  StyledCheckbox,
  TabelCol,
  TableRow,
  WellCard,
  Wrapper,
} from "pages/Report/creator/style";
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import type { IItem } from "reducers/reportReducer";
import { IndicatorsState } from "reducers/stateReducer";
import type { RootState } from "reducers/store";
import { useAppDispatch, useAppSelector } from "reducers/store";
import type { IDisplayOptionsType } from "reducers/types";
import { CurvesEnum, initialDisplayOptions } from "reducers/types";
import { Track, useMixpanel } from "services/Mixpanel";
import {
  Checkbox,
  Col,
  Divider,
  Popover,
  Radio,
  Row,
  Space,
  Tooltip,
  Typography,
} from "utils/componentLibrary";
import { CheckboxState } from "utils/enums";
import { TVD_ID } from "utils/reports";

const { Text } = Typography;

enum TimeVsDepthDisplayOptions {
  Actual = "Actual",
  Plan = "Plan",
  "Bit Depth" = "Bit Depth",
}

const ReportRow = ({
  localLens,
  id,
  setRows,
}: {
  localLens: Partial<IItem>;
  selected: boolean;
  id: string;
  setRows: React.Dispatch<React.SetStateAction<Partial<IItem>[]>>;
}) => {
  const { data: kpiTypes } = useKpiTypes();

  const { kpiType, kpiGroup } = useKpiOptions(localLens as UserLensDto);

  const comparisonWells = useAppSelector(
    (state: RootState) => state.report.offsetWells,
  );
  const { data: templates } = useLensTemplates();
  const template = localLens.lensTemplateId
    ? templates.byId[localLens.lensTemplateId]
    : null;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const lensTitle = useMemo(() => {
    if (!localLens) return "Time vs Depth";
    if (
      (localLens as ParameterByDepthUserLensDto)?.userLensTrackItems &&
      !("showZTorque" in localLens)
    ) {
      return "Parameter";
    }

    if (kpiType) {
      return kpiType.name;
    }

    if (kpiGroup) {
      return kpiGroup.name;
    }
    return template?.name || localLens.name || "";
  }, [kpiGroup, kpiType, template?.name, localLens]);

  const [indicators, setIndicators] = useState<Array<string> | null>(null);
  const [visualAidsState, setVisualAidsState] = useState<string[] | null>(null);
  const [outliersEnabled, setOutliersEnabled] = useState<boolean>(true);
  const [selected, setSelected] = useState(localLens.selected ?? true);
  const [displayOptionsState, setDisplayOptionsState] = useState(
    comparisonWells.length > 0 ? DisplayOption.Well : DisplayOption.StandDepth,
  );
  const [displayOptionsStateTvD, setDisplayOptionsStateTvD] = useState(
    localLens.displayOptions,
  );
  const [abilities, setAbilities] = useState(
    getAbilityMatrix({
      type: template?.type,
      isComparing: comparisonWells.length > 0,
      displayOption: displayOptionsState,
      stackingType: (localLens as KpiGroupUserLensDto).stackingType,
      isSystem: !!localLens?.isSystem,
    }),
  );
  const [legendVisibilityState, setLegendVisibilityState] = useState(
    abilities?.legend === MatrixAvailability.AVAILABLE,
  );

  useEffect(() => {
    setAbilities(
      getAbilityMatrix({
        type: template?.type || localLens?.type,
        isComparing: comparisonWells.length > 0,
        displayOption: displayOptionsState,
        stackingType: (localLens as KpiGroupUserLensDto).stackingType,
        isSystem: !!localLens?.isSystem,
      }),
    );
  }, [
    comparisonWells.length,
    displayOptionsState,
    template?.type,
    localLens?.type,
    localLens,
  ]);

  useEffect(() => {
    setDisplayOptionsState(
      comparisonWells.length > 0
        ? DisplayOption.Well
        : DisplayOption.StandDepth,
    );
  }, [comparisonWells?.length, setDisplayOptionsState]);
  useEffect(() => {
    setSelected(localLens.selected ?? true);
  }, [localLens.selected]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setRows((rows) =>
      rows
        .filter((row) => row.isAvailableOnReports)
        .map((row) =>
          row?.id?.toString() === id
            ? {
                ...row,
                selected,
                legendVisible: legendVisibilityState,
                displayOptions:
                  localLens.type === "tvd"
                    ? displayOptionsStateTvD
                    : [displayOptionsState],
                indicators:
                  localLens.type === "tvd"
                    ? indicators || []
                    : indicators?.map((e: string) => e.replace(" ", "")),
                visualAids: visualAidsState,
                outliersEnabled,
              }
            : row,
        ),
    );
  }, [
    legendVisibilityState,
    indicators,
    visualAidsState,
    outliersEnabled,
    setRows,
    id,
    selected,
    displayOptionsState,
    localLens.type,
    displayOptionsStateTvD,
    dispatch,
  ]);

  const lenskpiText = useMemo(() => {
    if (template && isWedgeTemplate(template)) {
      return template?.breakdowns
        ? template?.breakdowns.find(
            (breakdown) => breakdown.id === localLens.breakdownId,
          )?.name ?? ""
        : "";
    }

    if (
      kpiTypes &&
      localLens.kpiTypeId &&
      kpiTypes.byId[localLens.kpiTypeId]?.name
    ) {
      return kpiTypes.byId[localLens.kpiTypeId]?.name;
    } else return localLens.kpiTypeId || lensTitle;
  }, [localLens, template, kpiTypes, lensTitle]);

  return (
    <TableRow
      justify="space-between"
      gutter={40}
      ref={setNodeRef}
      style={style}
    >
      <Cell flex="1">
        {/* Lens Type */}
        <Row gutter={12}>
          <Col>
            <StyledCheckbox
              checked={selected}
              onChange={(e: CheckboxChangeEvent) => {
                Track.interact(
                  `Report Builder - ${localLens?.name || lensTitle}`,
                  {
                    Selected: e.target.checked,
                  },
                );
                setSelected(e.target.checked);
              }}
            />
          </Col>
          <Col>
            <Text>{template?.name ?? "Time vs Depth"}</Text>
          </Col>
        </Row>
      </Cell>
      <Cell flex="1">
        {/* Lens KPI */}
        <Text>{lenskpiText}</Text>
        {/* )} */}
      </Cell>
      <TabelCol>
        {/* Display Options */}
        {abilities.display_options !== MatrixAvailability.NOT_SUPPORTED ? (
          <Popover
            content={
              localLens?.type === "tvd" ? (
                <Space direction="vertical">
                  <Wrapper>
                    <Checkbox.Group
                      value={
                        displayOptionsStateTvD as TimeVsDepthDisplayOptions[]
                      }
                      onChange={(e) => {
                        Track.interact(
                          `Report Builder - ${localLens?.name || lensTitle}`,
                          {
                            "Display Options": e ?? [],
                          },
                        );
                        setDisplayOptionsStateTvD(
                          e?.length === 0
                            ? undefined
                            : (e as Array<TimeVsDepthDisplayOptions>),
                        );
                      }}
                      style={{ maxWidth: "250px" }}
                    >
                      <Row gutter={[0, 6]}>
                        {["Actual", "Plan", "Bit Depth"].map((e) => {
                          return (
                            <Col span={24} key={e}>
                              <Checkbox value={e}>{e}</Checkbox>
                            </Col>
                          );
                        })}
                      </Row>
                    </Checkbox.Group>
                  </Wrapper>
                </Space>
              ) : (
                <Wrapper>
                  <Radio.Group
                    onChange={(e) => {
                      Track.interact(
                        `Report Builder - ${localLens?.name || lensTitle}`,
                        {
                          "Display Options": e.target.value ?? [],
                        },
                      );
                      setDisplayOptionsState(e.target.value);
                    }}
                    value={displayOptionsState}
                  >
                    {comparisonWells.length > 0 && (
                      <Space direction="vertical" size={6}>
                        <Radio key="well" value={DisplayOption.Well}>
                          Well
                        </Radio>
                        <Radio
                          key="hole_section"
                          value={DisplayOption.HoleSection}
                        >
                          Hole Section
                        </Radio>
                        <Radio
                          key="directional_interval"
                          value={DisplayOption.DirectionInterval}
                        >
                          Directional Interval
                        </Radio>
                        <Radio key="shift" value={DisplayOption.Shift}>
                          Shift (Day/Night)
                        </Radio>
                      </Space>
                    )}
                    {comparisonWells.length === 0 && (
                      <Space direction="vertical" size={6}>
                        <Radio
                          key="stand_depth"
                          value={DisplayOption.StandDepth}
                        >
                          Stand Depth
                        </Radio>
                        <Radio key="day" value={DisplayOption.DateShift}>
                          Day
                        </Radio>
                        <Radio key="shift" value={DisplayOption.Shift}>
                          Shift (Day/Night)
                        </Radio>
                      </Space>
                    )}
                  </Radio.Group>
                </Wrapper>
              )
            }
            trigger={
              abilities.display_options === MatrixAvailability.NOT_AVAILABLE
                ? []
                : ["click"]
            }
            placement="bottomLeft"
            destroyTooltipOnHide
          >
            <Text
              style={{
                cursor: "pointer",
              }}
            >
              {(localLens.type === "tvd"
                ? (displayOptionsStateTvD as TimeVsDepthDisplayOptions[])?.join(
                    " & ",
                  )
                : displayOptionsState) || "None"}
            </Text>
          </Popover>
        ) : (
          <Text>--</Text>
        )}
      </TabelCol>
      <TabelCol>
        {/* Visual Aids */}
        {abilities.selectedVisualAids !== MatrixAvailability.NOT_SUPPORTED ? (
          <Popover
            content={
              <Space direction="vertical">
                <Wrapper>
                  <Checkbox.Group
                    value={visualAidsState || undefined}
                    onChange={(e) => {
                      Track.interact(
                        `Report Builder - ${localLens?.name || lensTitle}`,
                        {
                          "Visual Aids": e ?? [],
                        },
                      );
                      setVisualAidsState(
                        e.length === 0 ? null : (e as string[]),
                      );
                    }}
                    style={{ maxWidth: "250px" }}
                  >
                    <Row gutter={[0, 6]}>
                      {Object.values(OrderedVisualAidType)
                        .filter((e) => e !== VisualAidType.None)
                        .map((e) => {
                          return (
                            <Col span={24} key={e}>
                              <Checkbox value={e}>{e}</Checkbox>
                            </Col>
                          );
                        })}
                    </Row>
                  </Checkbox.Group>
                </Wrapper>
              </Space>
            }
            trigger={
              abilities.selectedVisualAids === MatrixAvailability.NOT_AVAILABLE
                ? []
                : ["click"]
            }
            placement="bottomLeft"
            destroyTooltipOnHide
          >
            <Text
              style={{
                cursor: "pointer",
              }}
            >
              {visualAidsState?.length
                ? `${visualAidsState.length} Aids`
                : visualAidsState ?? "None"}
            </Text>
          </Popover>
        ) : (
          <Text>--</Text>
        )}
      </TabelCol>
      <TabelCol>
        {/* Indicators */}
        {abilities.indicators !== MatrixAvailability.NOT_SUPPORTED ? (
          <Popover
            content={
              <Space direction="vertical">
                <Wrapper>
                  <Checkbox.Group
                    value={indicators || undefined}
                    onChange={(e) => {
                      Track.interact(
                        `Report Builder - ${localLens?.name || lensTitle}`,
                        {
                          Indicators: e ?? [],
                        },
                      );
                      setIndicators(e.length === 0 ? null : (e as string[]));
                    }}
                    style={{ maxWidth: "250px" }}
                  >
                    <Row gutter={[0, 6]}>
                      {Object.keys(IndicatorsState).map((e) => {
                        return (
                          <Col span={24} key={e}>
                            <Checkbox value={e}>
                              {
                                indicatorNames[
                                  e.replaceAll(
                                    " ",
                                    "",
                                  ) as keyof typeof indicatorNames
                                ]
                              }
                            </Checkbox>
                          </Col>
                        );
                      })}
                    </Row>
                  </Checkbox.Group>
                </Wrapper>
              </Space>
            }
            trigger={
              abilities.indicators === MatrixAvailability.NOT_AVAILABLE
                ? []
                : ["click"]
            }
            placement="bottomLeft"
            destroyTooltipOnHide
          >
            <Text
              style={{
                cursor: "pointer",
              }}
            >
              {indicators?.length
                ? `${indicators.length} Indicators`
                : IndicatorsState[
                    indicators as unknown as keyof typeof IndicatorsState
                  ] ?? "None"}
            </Text>
          </Popover>
        ) : (
          <Text>--</Text>
        )}
      </TabelCol>
      <Col
        style={{
          width: 240,
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* Settings */}
        <Row
          style={{
            display: "flex",
            alignItems: "center",
          }}
          wrap={false}
          gutter={8}
        >
          {/* Outliers */}
          <Col>
            <Tooltip
              title={
                abilities.showsOutliers === MatrixAvailability.NOT_SUPPORTED
                  ? "Unsupported Ability"
                  : "Auto Crop"
              }
            >
              <Button
                size="large"
                icon={
                  outliersEnabled ? (
                    <PDComponent.SvgIcon name="outliersEnabled" />
                  ) : (
                    <PDComponent.SvgIcon name="outliersDisabled" />
                  )
                }
                disabled={
                  abilities.showsOutliers !== MatrixAvailability.AVAILABLE
                }
                ghost={abilities.showsOutliers !== MatrixAvailability.AVAILABLE}
                onClick={() => {
                  Track.interact(
                    `Report Builder - ${localLens?.name || lensTitle}`,
                    {
                      Outliers: !outliersEnabled,
                    },
                  );
                  setOutliersEnabled((e) => !e);
                }}
              />
            </Tooltip>
          </Col>

          {/* Legend Interaction */}
          <Col>
            <Tooltip
              title={
                abilities.legend !== MatrixAvailability.AVAILABLE
                  ? "Unsupported Ability"
                  : "Legend"
              }
            >
              <Button
                disabled={abilities.legend !== MatrixAvailability.AVAILABLE}
                ghost={abilities.legend !== MatrixAvailability.AVAILABLE}
                size="large"
                icon={<PDComponent.SvgIcon name="list" />}
                onClick={() => {
                  Track.interact(
                    `Report Builder - ${localLens?.name || lensTitle}`,
                    {
                      "Legend Interaction": !legendVisibilityState,
                    },
                  );
                  setLegendVisibilityState((e) => !e);
                }}
                $engaged={!legendVisibilityState}
                // $engaged={Math.random() > 0.5}÷
              />
            </Tooltip>
          </Col>
          <Col>
            <Divider type="vertical" style={{ margin: 0, height: "90%" }} />
          </Col>
          <Col span={8}>
            <IconComponent
              name="move"
              iconProps={{ ...listeners, ...attributes }}
            />
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
};

const Creator = () => {
  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();
  const [checkBoxState, setCheckBoxState] = useState(CheckboxState.Selected);
  useEffect(() => {
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage("Report Builder");
    }
  }, [viewPage]);
  const { data: lenses } = useUserLenses();
  const { data: tabs } = useUserLensTabs(DashboardType.Well);
  const wellId = useSelectedWell();
  const dispatch = useAppDispatch();
  const [displayOptions] = useStateQuery<IDisplayOptionsType>(
    URL_STATE_PARAM.DISPLAY_OPTIONS_WELL,
    initialDisplayOptions,
  );
  const planActive =
    displayOptions.curves === null ||
    displayOptions.curves.includes(CurvesEnum.PLAN);
  const actualActive =
    displayOptions.curves === null ||
    displayOptions.curves.includes(CurvesEnum.HOLE_DEPTH);
  const bitDepthActive =
    displayOptions.curves === null ||
    displayOptions.curves.includes(CurvesEnum.BIT_DEPTH);
  // TODO display options for TvD shall be of type curve enum, but we are treating them the same so the implementation is a bit cumbersome
  const [rows, setRows] = useState<Array<Partial<IItem>>>([
    {
      name: "Time vs. Depth",
      type: "tvd",
      id: TVD_ID,
      selected: true,
      isAvailableOnReports: true,
      displayOptions: Object.keys(TimeVsDepthDisplayOptions)
        .map((key) => {
          if (
            (actualActive && key === "Actual") ||
            (planActive && key === "Plan") ||
            (bitDepthActive && key === "Bit Depth")
          ) {
            return TimeVsDepthDisplayOptions[key];
          }
          return "";
        })
        .filter((e) => e),
      indicators: Object.keys(indicatorNames),
      lensInfo: {
        name: "Time vs. Depth",
        type: "Time vs. Depth",
        displayOptions: Object.keys(TimeVsDepthDisplayOptions),
        visualAids: false,
      },
    },
    ...(lenses && tabs
      ? tabs
          .filter((e) => lenses.byTab[e.id])
          .flatMap((e) =>
            lenses.byTab[e.id].flatMap((ee) => ({
              ...ee,
              selected: true,
            })),
          )
      : []),
  ]);

  useEffect(() => {
    dispatch({
      type: "SET_SELECTED_WELL",
      payload: {
        well: wellId,
      },
    });
  }, [dispatch, wellId]);

  const onCommonCheckboxChange = (e: CheckboxChangeEvent) => {
    setRows((prevRows) => {
      return prevRows.map((row) => ({
        ...row,
        selected: e.target.checked,
      }));
    });
  };

  useEffect(() => {
    if (rows.every((lens) => lens.selected)) {
      Track.event("Report Builder - All Rows Selected");
      setCheckBoxState(CheckboxState.Selected);
    } else if (rows.some((lens) => lens.selected))
      setCheckBoxState(CheckboxState.Partially);
    else {
      Track.event("Report Builder - All Rows Unselected");
      setCheckBoxState(CheckboxState.None);
    }
  }, [rows]);

  const sensors = useSensors(useSensor(PointerSensor));
  const ids = useMemo(
    () => rows.map((item) => (item.id ?? "").toString()),
    [rows],
  );

  const onSortEnd = ({ active, over }: DragEndEvent) => {
    Track.interact("Report Builder - Move Lens");

    setRows((prevRows) => {
      const points = prevRows;
      const oldIndex = points.findIndex(
        (item) => item?.id?.toString() === active.id,
      );
      const newIndex = points.findIndex(
        (item) => item?.id?.toString() === over?.id,
      );
      return [
        ...arrayMove(points, oldIndex, newIndex).map((item, index) => ({
          ...item,
          position: index,
        })),
      ];
    });
  };

  useEffect(() => {
    dispatch({
      type: "SET_REPORT_SELECTED_LENSES",
      payload: rows?.filter((e) => e?.selected),
    });
  }, [rows, dispatch]);

  return (
    <SectionLayout
      header={
        <Section style={{ marginBottom: "14px", padding: 0 }}>
          <Suspense fallback={<Loader centered />}>
            <ExportTopBar
              selectedCount={rows?.filter((e) => e.selected)?.length}
            />
          </Suspense>
        </Section>
      }
    >
      <WellCard
        title={
          <Row justify="space-between" gutter={40}>
            <Col flex="1">
              <Row gutter={12}>
                <Col>
                  <StyledCheckbox
                    onChange={onCommonCheckboxChange}
                    indeterminate={checkBoxState === CheckboxState.Partially}
                    checked={checkBoxState === CheckboxState.Selected}
                  />
                </Col>
                <Col>
                  Lens Type (
                  {(() => {
                    const total = rows?.length;
                    const selected = rows?.filter((e) => e.selected)?.length;
                    if (total === selected) return "All";
                    if (selected === 0) return "None";
                    return selected;
                  })()}
                  )
                </Col>
              </Row>
            </Col>
            <Col flex="1">Lens KPI</Col>
            <TabelCol>Display Option</TabelCol>
            <TabelCol>Visual Aids</TabelCol>
            <TabelCol>Indicators</TabelCol>
            <Col
              style={{
                width: 240,
              }}
            >
              Settings
            </Col>
          </Row>
        }
        style={{
          overflow: "hidden",
        }}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={onSortEnd}
        >
          <Row gutter={0}>
            <SortableContext items={ids} strategy={verticalListSortingStrategy}>
              {rows?.map((lens) => (
                <Col span={24} key={lens.id}>
                  <ReportRow
                    localLens={lens}
                    id={lens?.id?.toString() || ""}
                    selected={!!lens?.selected}
                    setRows={setRows}
                  />
                </Col>
              ))}
            </SortableContext>
          </Row>
        </DndContext>
      </WellCard>
    </SectionLayout>
  );
};

export default Creator;
