import {
  baseMatrix,
  MatrixAvailability,
} from "components/Lenses/ContainerLens/common/utils/constants";
import type {
  AbilityMatrixProps,
  AbilityMatrixType,
} from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";

export const getParameterByDepthAbilityMatrix = (
  props?: AbilityMatrixProps,
): AbilityMatrixType => {
  return {
    ...baseMatrix,
    selectedVisualAids: MatrixAvailability.NOT_SUPPORTED,
    display_options: MatrixAvailability.NOT_SUPPORTED,
    showsOutliers: MatrixAvailability.NOT_SUPPORTED,
    showOperationCount: MatrixAvailability.NOT_SUPPORTED,
    manual_axis: MatrixAvailability.NOT_SUPPORTED,
    trackKPI: MatrixAvailability.NOT_SUPPORTED,
    label: MatrixAvailability.NOT_SUPPORTED,
    addUserTracks: MatrixAvailability.AVAILABLE,
    indicators: props?.isComparing
      ? MatrixAvailability.NOT_AVAILABLE
      : MatrixAvailability.AVAILABLE,
  };
};

export const getParameterRoadmapAbilityMatrix = (): AbilityMatrixType => {
  return {
    ...baseMatrix,
    comparison: MatrixAvailability.NOT_SUPPORTED,
    selectedVisualAids: MatrixAvailability.NOT_SUPPORTED,
    display_options: MatrixAvailability.NOT_SUPPORTED,
    showsOutliers: MatrixAvailability.NOT_SUPPORTED,
    showOperationCount: MatrixAvailability.NOT_SUPPORTED,
    label: MatrixAvailability.NOT_SUPPORTED,
    manual_axis: MatrixAvailability.NOT_SUPPORTED,
    trackKPI: MatrixAvailability.NOT_SUPPORTED,
    addUserTracks: MatrixAvailability.AVAILABLE,
  };
};
