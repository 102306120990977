import styled from "styled-components";
import colors from "utils/colors";

import { TOOLTIP_WIDTH } from "./utils";

export const ContainerDiv = styled.div`
  height: 100%;
  width: 100%;

  border-radius: 4px;
  background: ${({ theme }) => theme.themeStyle.intel.chart_bg};
  height: calc(100% - 40px);
  max-height: 450px;
  width: 100%;
  /* Box Shadow */
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12);
  h1 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    font-size: 16px;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
  }
`;

export const Card = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  background: ${({ theme }) => theme.themeStyle.colors.secondary_chart_accent};
  /* Box Shadow */
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12);
`;

export const CardInner = styled.div`
  height: 100%;
  width: 100%;
  overflow: visible;
  .scrolled .pinned {
    box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.2) !important;
  }
`;
export const RankSpan = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

export const Circle = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-right: 8px;
`;

export const TooltipContainer = styled.div`
  width: ${TOOLTIP_WIDTH}px;
  color: ${colors.actions_bg};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;
