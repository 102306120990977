import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  ApiParameterByDepthUserLensesIdFactsPutRequest,
  ParameterByDepthKpiComparisonDto,
  ParameterByDepthUserLensDto,
} from "apis/oag";
import { ParameterByDepthUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import {
  isParameterRoadmapTemplate,
  useLensTemplates,
} from "hooks/lens/useLensTemplates";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useAppDispatch } from "reducers/store";
import {
  type IFiltersType,
  initialFilters,
  type IZoomData,
} from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, PARAMETER_LENS_SAMPLE_COUNT } from "utils/common";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const parameterApi = new ParameterByDepthUserLensesApi(apiConfig);

export const ParameterByDepthKey = "ParameterByDepth/Facts";

// TODO Why are we not using the "useParameter..." hook we create as per other lenses?
export const useParameterByDepthFacts = (
  lens: ParameterByDepthUserLensDto,
  options?: UseQueryOptions<ParameterByDepthKpiComparisonDto>,
) => {
  const [comparisonWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );
  const { data: templates } = useLensTemplates();

  const lensId = lens.id;
  const template = templates.byId[lens.lensTemplateId];
  const dispatch = useAppDispatch();

  const wellDataID = useSelectedWell();

  const [
    {
      sections: sectionIds,
      operationTime,
      directionalIntervals: directionalIntervalIds,
      phases: phaseIds,
      holeSizes: holeSizeIds,
    },
  ] = useStateQuery<IFiltersType>(
    URL_STATE_PARAM.FILTERS_WIDGET,
    initialFilters,
  );
  const [
    {
      ts_start: zoomStartDuration,
      ts_end: zoomEndDuration,
      depth_start: zoomStartDepth,
      depth_end: zoomEndDepth,
    },
  ] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WIDGET, initialZoomData);

  const requestParameters: ApiParameterByDepthUserLensesIdFactsPutRequest = {
    id: lensId ?? -1,
    byDepthUserLensQueryDto: {
      selectedFilters: {
        sectionIds,
        directionalIntervalIds,
        holeSizeIds,
        phaseIds,
        includeFlatTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
        includeAlphaRigs: true,
        includeNonAlphaRigs: true,
      },
      comparisonWellIds: isParameterRoadmapTemplate(template)
        ? []
        : comparisonWells,
      zoomStartDepth,
      zoomEndDepth,
      zoomStartDuration,
      zoomEndDuration,
      wellId: wellDataID,
      sampleCount: PARAMETER_LENS_SAMPLE_COUNT,
      useMockData: false,
      from: defaultDateDto.from,
      to: defaultDateDto.to,

      wellAggregationThreshold: 6,
      maxLookAheadDepthFromFocal: null, // no look ahead on Web
    },
  };

  const queryKey: PDLensFactsQueryKey<ApiParameterByDepthUserLensesIdFactsPutRequest> =
    {
      type: PDQueryType.FACTS,
      uid: RequestUID.parameterByDepthFacts,
      params: requestParameters,
      lensId,
      comparisonType: comparisonWells.length > 0 ? "single" : "comparison",
    };

  return useQuery<ParameterByDepthKpiComparisonDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      parameterApi
        .apiParameterByDepthUserLensesIdFactsPut(requestParameters, { signal })
        .then((data) => {
          if (data?.lastUpdatedAt) {
            dispatch({
              type: "SET_BY_DEPTH_REFRESH_DATE",
              payload: { [+wellDataID]: data.lastUpdatedAt },
            });
          }
          return data;
        }),
    ...options,
  });
};
