import * as Styled from "components/Header/RightContent/Notifications/NotificationsButton/style";
import {
  isUnread,
  NotificationsPopover,
} from "components/Header/RightContent/Notifications/NotificationsPopover/NotificationsPopover";
import { StyledButton } from "components/Header/RightContent/style";
import { PDComponent } from "components/PDComponents";
import { useActiveNotifications } from "hooks/notifications/useNotifications";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Track } from "services/Mixpanel";
import { secondsInMinute } from "utils/common";
import { Popover, Tooltip } from "utils/componentLibrary";
import { IdempotentMapper } from "utils/notifications/mappers";

export const NotificationsButton = () => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const location = useLocation();

  const { data: notifications } = useActiveNotifications({
    select: IdempotentMapper,
    refetchIntervalInBackground: true,
    refetchInterval: secondsInMinute * 1000,
  });

  const notifCount = useMemo(() => {
    return (
      notifications.exceptionRequestNotifications.filter(isUnread).length +
      notifications.scorecardCommentNotifications.filter(isUnread).length
    );
  }, [notifications]);

  const notificationsCountLabel = useMemo(() => {
    return notifCount > 99 ? "99+" : notifCount;
  }, [notifCount]);

  useEffect(() => setIsNotificationsOpen(false), [location]);

  useEffect(() => {
    // Fix because for some reason trigger={["click", "scroll"]} doesn't work on this popup, probably too big Y-wise
    function handleScroll() {
      setIsNotificationsOpen(false);
    }

    window.addEventListener("scroll", handleScroll);
    () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Styled.OuterContainer>
      <Tooltip title="Notifications">
        <Popover
          content={
            <NotificationsPopover
              onPopoverClose={() => {
                setIsNotificationsOpen(false);
              }}
            />
          }
          trigger={["click", "scroll"]}
          placement="bottom"
          open={isNotificationsOpen}
          onOpenChange={setIsNotificationsOpen}
          destroyTooltipOnHide
          overlayStyle={{ paddingTop: 0 }}
        >
          <StyledButton
            ghost
            onClick={() => {
              Track.clickEvent("Open Notification");
              setIsNotificationsOpen((prev) => !prev);
            }}
          >
            <Styled.Container $isActive={notifCount > 0}>
              <PDComponent.SvgIcon name="notification" />
              {notifCount > 0 ? (
                <Styled.CircleCount>
                  {notificationsCountLabel}
                </Styled.CircleCount>
              ) : null}
            </Styled.Container>
          </StyledButton>
        </Popover>
      </Tooltip>
    </Styled.OuterContainer>
  );
};
