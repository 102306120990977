/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigCardAverages } from './RigCardAverages';
import {
    RigCardAveragesFromJSON,
    RigCardAveragesFromJSONTyped,
    RigCardAveragesToJSON,
} from './RigCardAverages';
import type { ScoreSummaryRootDto } from './ScoreSummaryRootDto';
import {
    ScoreSummaryRootDtoFromJSON,
    ScoreSummaryRootDtoFromJSONTyped,
    ScoreSummaryRootDtoToJSON,
} from './ScoreSummaryRootDto';
import type { RigCardGroupFactDto } from './RigCardGroupFactDto';
import {
    RigCardGroupFactDtoFromJSON,
    RigCardGroupFactDtoFromJSONTyped,
    RigCardGroupFactDtoToJSON,
} from './RigCardGroupFactDto';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { RigScorecardComplianceRate } from './RigScorecardComplianceRate';
import {
    RigScorecardComplianceRateFromJSON,
    RigScorecardComplianceRateFromJSONTyped,
    RigScorecardComplianceRateToJSON,
} from './RigScorecardComplianceRate';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';

/**
 * 
 * @export
 * @interface RigCardFactSetDto
 */
export interface RigCardFactSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof RigCardFactSetDto
     */
    readonly stopWatch: StopWatchDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof RigCardFactSetDto
     */
    readonly dataState: ResultDataState;
    /**
     * 
     * @type {boolean}
     * @memberof RigCardFactSetDto
     */
    usesExceptions: boolean;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactSetDto
     */
    totalDowntime?: number | null;
    /**
     * 
     * @type {RigCardAverages}
     * @memberof RigCardFactSetDto
     */
    averages: RigCardAverages;
    /**
     * 
     * @type {RigScorecardComplianceRate}
     * @memberof RigCardFactSetDto
     */
    readonly complianceRate: RigScorecardComplianceRate;
    /**
     * 
     * @type {Array<RigCardGroupFactDto>}
     * @memberof RigCardFactSetDto
     */
    cardGroups: Array<RigCardGroupFactDto>;
    /**
     * 
     * @type {ScoreSummaryRootDto}
     * @memberof RigCardFactSetDto
     */
    scoreSummary: ScoreSummaryRootDto;
}



/**
 * Check if a given object implements the RigCardFactSetDto interface.
 */
export function instanceOfRigCardFactSetDto(value: object): value is RigCardFactSetDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    if (!('usesExceptions' in value) || value['usesExceptions'] === undefined) return false;
    if (!('averages' in value) || value['averages'] === undefined) return false;
    if (!('complianceRate' in value) || value['complianceRate'] === undefined) return false;
    if (!('cardGroups' in value) || value['cardGroups'] === undefined) return false;
    if (!('scoreSummary' in value) || value['scoreSummary'] === undefined) return false;
    return true;
}

export function RigCardFactSetDtoFromJSON(json: any): RigCardFactSetDto {
    return RigCardFactSetDtoFromJSONTyped(json, false);
}

export function RigCardFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigCardFactSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'usesExceptions': json['UsesExceptions'],
        'totalDowntime': json['TotalDowntime'] == null ? undefined : json['TotalDowntime'],
        'averages': RigCardAveragesFromJSON(json['Averages']),
        'complianceRate': RigScorecardComplianceRateFromJSON(json['ComplianceRate']),
        'cardGroups': ((json['CardGroups'] as Array<any>).map(RigCardGroupFactDtoFromJSON)),
        'scoreSummary': ScoreSummaryRootDtoFromJSON(json['ScoreSummary']),
    };
}

export function RigCardFactSetDtoToJSON(value?: Omit<RigCardFactSetDto, 'StopWatch'|'DataState'|'ComplianceRate'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'UsesExceptions': value['usesExceptions'],
        'TotalDowntime': value['totalDowntime'],
        'Averages': RigCardAveragesToJSON(value['averages']),
        'CardGroups': ((value['cardGroups'] as Array<any>).map(RigCardGroupFactDtoToJSON)),
        'ScoreSummary': ScoreSummaryRootDtoToJSON(value['scoreSummary']),
    };
}

