/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  IntelScatterPlotFactSetDto,
  IntelScatterPlotUserLensDto,
  WellIntelQueryDto,
} from '../models/index';
import {
    IntelScatterPlotFactSetDtoFromJSON,
    IntelScatterPlotFactSetDtoToJSON,
    IntelScatterPlotUserLensDtoFromJSON,
    IntelScatterPlotUserLensDtoToJSON,
    WellIntelQueryDtoFromJSON,
    WellIntelQueryDtoToJSON,
} from '../models/index';

export interface ApiIntelScatterPlotUserLensesIdFactsPutRequest {
    id: number;
    wellIntelQueryDto?: WellIntelQueryDto;
}

export interface ApiIntelScatterPlotUserLensesIdPutRequest {
    id: number;
    intelScatterPlotUserLensDto?: IntelScatterPlotUserLensDto;
}

export interface ApiIntelScatterPlotUserLensesPostRequest {
    intelScatterPlotUserLensDto?: IntelScatterPlotUserLensDto;
}

/**
 * 
 */
export class IntelScatterPlotUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiIntelScatterPlotUserLensesIdFactsPutRaw(requestParameters: ApiIntelScatterPlotUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntelScatterPlotFactSetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiIntelScatterPlotUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/IntelScatterPlotUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WellIntelQueryDtoToJSON(requestParameters['wellIntelQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntelScatterPlotFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiIntelScatterPlotUserLensesIdFactsPut(requestParameters: ApiIntelScatterPlotUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntelScatterPlotFactSetDto> {
        const response = await this.apiIntelScatterPlotUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiIntelScatterPlotUserLensesIdPutRaw(requestParameters: ApiIntelScatterPlotUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntelScatterPlotUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiIntelScatterPlotUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/IntelScatterPlotUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IntelScatterPlotUserLensDtoToJSON(requestParameters['intelScatterPlotUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntelScatterPlotUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiIntelScatterPlotUserLensesIdPut(requestParameters: ApiIntelScatterPlotUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntelScatterPlotUserLensDto> {
        const response = await this.apiIntelScatterPlotUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiIntelScatterPlotUserLensesPostRaw(requestParameters: ApiIntelScatterPlotUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntelScatterPlotUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/IntelScatterPlotUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntelScatterPlotUserLensDtoToJSON(requestParameters['intelScatterPlotUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntelScatterPlotUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiIntelScatterPlotUserLensesPost(requestParameters: ApiIntelScatterPlotUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntelScatterPlotUserLensDto> {
        const response = await this.apiIntelScatterPlotUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
