/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationCategoryType } from './OperationCategoryType';
import {
    OperationCategoryTypeFromJSON,
    OperationCategoryTypeFromJSONTyped,
    OperationCategoryTypeToJSON,
} from './OperationCategoryType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface WellDrillingDetailsDto
 */
export interface WellDrillingDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    wellId: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    cumulativeDurationActiveOnly: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    lastHoleDepth: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    maxHoleDepth: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    lastBitDepth: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    drillingDistance: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    numberOfStrings: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    weightToWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    weightToWeightCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    slipToSlip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    slipToSlipCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    drillingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    trippingPipeTravelDistance?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingDetailsDto
     */
    trippingRunningSpeed?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WellDrillingDetailsDto
     */
    hasZTorque: boolean;
    /**
     * 
     * @type {OperationCategoryType}
     * @memberof WellDrillingDetailsDto
     */
    lastOperationCategory: OperationCategoryType;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    spudDateTime?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastWellFactUpdateAt?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastTvdFactUpdateAt?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastStandKpiFactUpdateAt?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastRigStateFactUpdateAt?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastDepthFactUpdateAt?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastActiveRigStateFactAt?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastBatteryStateFactUpdateAt?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingDetailsDto
     */
    lastRigPowerFactUpdateAt?: DateDto | null;
}



/**
 * Check if a given object implements the WellDrillingDetailsDto interface.
 */
export function instanceOfWellDrillingDetailsDto(value: object): value is WellDrillingDetailsDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('cumulativeDuration' in value) || value['cumulativeDuration'] === undefined) return false;
    if (!('cumulativeDurationActiveOnly' in value) || value['cumulativeDurationActiveOnly'] === undefined) return false;
    if (!('lastHoleDepth' in value) || value['lastHoleDepth'] === undefined) return false;
    if (!('maxHoleDepth' in value) || value['maxHoleDepth'] === undefined) return false;
    if (!('lastBitDepth' in value) || value['lastBitDepth'] === undefined) return false;
    if (!('drillingDistance' in value) || value['drillingDistance'] === undefined) return false;
    if (!('numberOfStrings' in value) || value['numberOfStrings'] === undefined) return false;
    if (!('drillingDuration' in value) || value['drillingDuration'] === undefined) return false;
    if (!('hasZTorque' in value) || value['hasZTorque'] === undefined) return false;
    if (!('lastOperationCategory' in value) || value['lastOperationCategory'] === undefined) return false;
    return true;
}

export function WellDrillingDetailsDtoFromJSON(json: any): WellDrillingDetailsDto {
    return WellDrillingDetailsDtoFromJSONTyped(json, false);
}

export function WellDrillingDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellDrillingDetailsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'wellId': json['WellId'],
        'cumulativeDuration': json['CumulativeDuration'],
        'cumulativeDurationActiveOnly': json['CumulativeDurationActiveOnly'],
        'lastHoleDepth': json['LastHoleDepth'],
        'maxHoleDepth': json['MaxHoleDepth'],
        'lastBitDepth': json['LastBitDepth'],
        'drillingDistance': json['DrillingDistance'],
        'numberOfStrings': json['NumberOfStrings'],
        'weightToWeight': json['WeightToWeight'] == null ? undefined : json['WeightToWeight'],
        'weightToWeightCount': json['WeightToWeightCount'] == null ? undefined : json['WeightToWeightCount'],
        'slipToSlip': json['SlipToSlip'] == null ? undefined : json['SlipToSlip'],
        'slipToSlipCount': json['SlipToSlipCount'] == null ? undefined : json['SlipToSlipCount'],
        'drillingDuration': json['DrillingDuration'],
        'trippingPipeTravelDistance': json['TrippingPipeTravelDistance'] == null ? undefined : json['TrippingPipeTravelDistance'],
        'trippingRunningSpeed': json['TrippingRunningSpeed'] == null ? undefined : json['TrippingRunningSpeed'],
        'hasZTorque': json['HasZTorque'],
        'lastOperationCategory': OperationCategoryTypeFromJSON(json['LastOperationCategory']),
        'spudDateTime': json['SpudDateTime'] == null ? undefined : DateDtoFromJSON(json['SpudDateTime']),
        'lastWellFactUpdateAt': json['LastWellFactUpdateAt'] == null ? undefined : DateDtoFromJSON(json['LastWellFactUpdateAt']),
        'lastTvdFactUpdateAt': json['LastTvdFactUpdateAt'] == null ? undefined : DateDtoFromJSON(json['LastTvdFactUpdateAt']),
        'lastStandKpiFactUpdateAt': json['LastStandKpiFactUpdateAt'] == null ? undefined : DateDtoFromJSON(json['LastStandKpiFactUpdateAt']),
        'lastRigStateFactUpdateAt': json['LastRigStateFactUpdateAt'] == null ? undefined : DateDtoFromJSON(json['LastRigStateFactUpdateAt']),
        'lastDepthFactUpdateAt': json['LastDepthFactUpdateAt'] == null ? undefined : DateDtoFromJSON(json['LastDepthFactUpdateAt']),
        'lastActiveRigStateFactAt': json['LastActiveRigStateFactAt'] == null ? undefined : DateDtoFromJSON(json['LastActiveRigStateFactAt']),
        'lastBatteryStateFactUpdateAt': json['LastBatteryStateFactUpdateAt'] == null ? undefined : DateDtoFromJSON(json['LastBatteryStateFactUpdateAt']),
        'lastRigPowerFactUpdateAt': json['LastRigPowerFactUpdateAt'] == null ? undefined : DateDtoFromJSON(json['LastRigPowerFactUpdateAt']),
    };
}

export function WellDrillingDetailsDtoToJSON(value?: WellDrillingDetailsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'WellId': value['wellId'],
        'CumulativeDuration': value['cumulativeDuration'],
        'CumulativeDurationActiveOnly': value['cumulativeDurationActiveOnly'],
        'LastHoleDepth': value['lastHoleDepth'],
        'MaxHoleDepth': value['maxHoleDepth'],
        'LastBitDepth': value['lastBitDepth'],
        'DrillingDistance': value['drillingDistance'],
        'NumberOfStrings': value['numberOfStrings'],
        'WeightToWeight': value['weightToWeight'],
        'WeightToWeightCount': value['weightToWeightCount'],
        'SlipToSlip': value['slipToSlip'],
        'SlipToSlipCount': value['slipToSlipCount'],
        'DrillingDuration': value['drillingDuration'],
        'TrippingPipeTravelDistance': value['trippingPipeTravelDistance'],
        'TrippingRunningSpeed': value['trippingRunningSpeed'],
        'HasZTorque': value['hasZTorque'],
        'LastOperationCategory': OperationCategoryTypeToJSON(value['lastOperationCategory']),
        'SpudDateTime': DateDtoToJSON(value['spudDateTime']),
        'LastWellFactUpdateAt': DateDtoToJSON(value['lastWellFactUpdateAt']),
        'LastTvdFactUpdateAt': DateDtoToJSON(value['lastTvdFactUpdateAt']),
        'LastStandKpiFactUpdateAt': DateDtoToJSON(value['lastStandKpiFactUpdateAt']),
        'LastRigStateFactUpdateAt': DateDtoToJSON(value['lastRigStateFactUpdateAt']),
        'LastDepthFactUpdateAt': DateDtoToJSON(value['lastDepthFactUpdateAt']),
        'LastActiveRigStateFactAt': DateDtoToJSON(value['lastActiveRigStateFactAt']),
        'LastBatteryStateFactUpdateAt': DateDtoToJSON(value['lastBatteryStateFactUpdateAt']),
        'LastRigPowerFactUpdateAt': DateDtoToJSON(value['lastRigPowerFactUpdateAt']),
    };
}

