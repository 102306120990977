import styled from "styled-components";
import { Collapse, Divider, Space } from "utils/componentLibrary";
import { PdSpaceItem } from "utils/componentLibrary/Space";

export const StyledSpace = styled(Space)`
  width: 100%;
  ${PdSpaceItem} {
    width: 100%;
  }
`;

export const StyledWellContainerSpace = styled(StyledSpace).attrs({
  direction: "vertical",
  size: 0,
})`
  width: 100%;
  ${PdSpaceItem} {
    width: 100%;
    border-bottom: 1px solid
      ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0 !important;
`;

export const StyledCollapse = styled(Collapse)`
  background: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
  border-radius: 10px;

  .ant-collapse-header {
    padding: 20px !important;

    .ant-collapse-arrow {
      right: 0 !important;
      padding: 20px !important;
      color: ${({ theme }) =>
        theme.themeStyle.colors.primary_typography} !important;
    }
  }

  .ant-collapse-content-box {
    padding: 0 !important;

    ${PdSpaceItem} {
      padding: 16px 20px;
      margin: 0 !important;
    }
  }
`;
