import type { ParameterByDepthTrackValuesDto } from "apis/oag";
import { bisector } from "d3-array";

export function lerpHoleDepth(
  a: ParameterByDepthTrackValuesDto,
  b: ParameterByDepthTrackValuesDto,
  value: number,
): number {
  if (!a.value || !b.value || !a.holeDepth || !b.holeDepth) {
    return 0;
  }

  return (
    (a.holeDepth * (b.value - value) + b.holeDepth * (value - a.value)) /
    (b.value - a.value)
  );
}

// eslint-disable-next-line @typescript-eslint/unbound-method
export const bisectDepth = bisector<ParameterByDepthTrackValuesDto, number>(
  (d) => d.holeDepth || 0,
).left;

export const TRACK_SIZE_HEIGHT_INCREMENT_PX = 14;
export const MIN_TRACK_HEIGHT = 84;
