import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { StandKpiTypeDto } from "apis/oag";
import { StandKpiTypesApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const kpiTypes = new StandKpiTypesApi(apiConfig);

const KpiTypesQueryKey: PDUniqueQueryKey = { uid: RequestUID.kpiTypes };

export type KpiTypesData = {
  list: StandKpiTypeDto[];
  byId: Record<number, StandKpiTypeDto>;
};

export async function fetchKpiTypes(): Promise<KpiTypesData> {
  const data = await kpiTypes.apiStandKpiTypesGet();

  return {
    list: data,
    byId: data.reduce<Record<number, StandKpiTypeDto>>((acc, cur) => {
      acc[cur.id ?? 0] = cur;
      return acc;
    }, {}),
  };
}

export function useKpiTypes(
  options?: Omit<
    UseQueryOptions<{
      list: StandKpiTypeDto[];
      byId: Record<number, StandKpiTypeDto>;
    }>,
    "queryKey" | "queryFn"
  >,
) {
  return useQuery<{
    list: StandKpiTypeDto[];
    byId: Record<number, StandKpiTypeDto>;
  }>({
    queryKey: [KpiTypesQueryKey],
    queryFn: () => fetchKpiTypes(),
    ...options,
    refetchOnMount: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
