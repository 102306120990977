import { useQuery } from "@tanstack/react-query";
import { WellsApi } from "apis/oag";
import { API_REFRESH_INTERVAL, apiConfig } from "utils/apiConfig";
import type { PDWellTvdPointsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const wells = new WellsApi(apiConfig);

export function useWellTvDPoints(
  { wellId, points }: { wellId: number; points: number },
  enabled: boolean = true,
  refetchAllowed: boolean = false,
) {
  const queryKey: PDWellTvdPointsQueryKey = {
    type: PDQueryType.WELL_TVD_POINTS,
    uid: RequestUID.wellTvdPoints,
    wellId,
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      wells.apiWellsIdRawActualTvdGet(
        {
          id: wellId,
          sampleCount: points,
        },
        { signal },
      ),
    enabled,
    staleTime: Infinity,
    refetchInterval: (qState) =>
      qState.isActive() && refetchAllowed ? API_REFRESH_INTERVAL : false,
  });
}
