/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface TimelineSegmentDto
 */
export interface TimelineSegmentDto {
    /**
     * 
     * @type {number}
     * @memberof TimelineSegmentDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TimelineSegmentDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TimelineSegmentDto
     */
    startCumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineSegmentDto
     */
    endCumulativeDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TimelineSegmentDto
     */
    startHoleDepth: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineSegmentDto
     */
    endHoleDepth?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof TimelineSegmentDto
     */
    at?: DateDto | null;
}

/**
 * Check if a given object implements the TimelineSegmentDto interface.
 */
export function instanceOfTimelineSegmentDto(value: object): value is TimelineSegmentDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('startCumulativeDuration' in value) || value['startCumulativeDuration'] === undefined) return false;
    if (!('startHoleDepth' in value) || value['startHoleDepth'] === undefined) return false;
    return true;
}

export function TimelineSegmentDtoFromJSON(json: any): TimelineSegmentDto {
    return TimelineSegmentDtoFromJSONTyped(json, false);
}

export function TimelineSegmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineSegmentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'startCumulativeDuration': json['StartCumulativeDuration'],
        'endCumulativeDuration': json['EndCumulativeDuration'] == null ? undefined : json['EndCumulativeDuration'],
        'startHoleDepth': json['StartHoleDepth'],
        'endHoleDepth': json['EndHoleDepth'] == null ? undefined : json['EndHoleDepth'],
        'at': json['At'] == null ? undefined : DateDtoFromJSON(json['At']),
    };
}

export function TimelineSegmentDtoToJSON(value?: TimelineSegmentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Description': value['description'],
        'StartCumulativeDuration': value['startCumulativeDuration'],
        'EndCumulativeDuration': value['endCumulativeDuration'],
        'StartHoleDepth': value['startHoleDepth'],
        'EndHoleDepth': value['endHoleDepth'],
        'At': DateDtoToJSON(value['at']),
    };
}

