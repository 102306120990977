import animationDataDark from "components/Clara/Loaders/robot-dark.json";
import animationData from "components/Clara/Loaders/robot-light.json";
import { LottieContainer } from "components/Clara/Loaders/style";
import Lottie from "react-lottie";
import { useCustomTheme } from "utils/useTheme";

export const RobotLoader = () => {
  const { isDark } = useCustomTheme();
  return (
    <LottieContainer>
      <Lottie
        isPaused={false}
        isStopped={false}
        options={{
          loop: true,
          autoplay: true,
          animationData: isDark ? animationDataDark : animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={40}
        width={40}
        style={{ margin: "0" }}
      />
    </LottieContainer>
  );
};
