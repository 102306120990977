import { ShareTabsModal } from "pages/WellDashboard/ShareTabs/ShareTabsModal/ShareTabsModal";
import { useCallback, useMemo, useState } from "react";

export const useShareTabsModal = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const modalElement = useMemo(() => {
    return showModal ? (
      <ShareTabsModal
        isVisible={showModal}
        onCancel={() => setShowModal(false)}
      />
    ) : null;
  }, [showModal]);

  return { modalElement, openModal };
};
