import { CustomSwitch } from "atoms/common";
import OperatorSelector from "components/RigComparisonSelectors/OperatorSelector";
import { WellSelector } from "components/RigComparisonSelectors/WellSelector";
import { RigSelector } from "components/RigSelector";
import { URL_STATE_PARAM } from "hooks/navigation/useQueryState";
import {
  HeaderContainerRow,
  StyledTitle,
} from "pages/RigScoreCard/Header/styles";
import { Suspense } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import { Col, Divider, Row, Space } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

export const NUM_DEFAULT_RECENT_WELLS = 5;

export function Header() {
  const dispatch = useAppDispatch();
  const exceptions = useAppSelector((state) => state.rigsCommon.exceptions);

  const { atomThemeVariant } = useCustomTheme();

  return (
    <HeaderContainerRow>
      <Col>
        <RigSelector redirectLocation={(rigId) => `/rig/scorecard/${rigId}`} />
      </Col>

      <Suspense fallback={null}>
        <Row gutter={10}>
          <Col>
            <Space>
              <WellSelector
                urlStateParam={URL_STATE_PARAM.SELECTED_WELLS_RIG_SCORECARD}
                numSelectRecent={NUM_DEFAULT_RECENT_WELLS}
                isFilteringIgnored
                dontSetSelectedWells
                isGroupable
              />
              <OperatorSelector
                urlStateParam={URL_STATE_PARAM.SELECTED_OPERATORS_RIG_SCORECARD}
                isFilteringIgnored
              />
            </Space>
          </Col>
          <Divider
            type="vertical"
            style={{ margin: "0 10px", height: "auto" }}
          />
          <Space>
            <StyledTitle variant={exceptions ? atomThemeVariant : "faded"}>
              Exceptions
            </StyledTitle>
            <CustomSwitch
              $isChecked={exceptions}
              checked={exceptions}
              onChange={(e) => {
                dispatch({
                  type: "RIGS_COMMON_EXCEPTIONS_INCLUDED",
                  payload: e,
                });
                Track.interact("Rigs Scorecard - Exceptions", {
                  Value: e ? "On" : "Off",
                });
              }}
            />
          </Space>
        </Row>
      </Suspense>
    </HeaderContainerRow>
  );
}
