import { useQuery } from "@tanstack/react-query";
import type { DateDto, ListWellQueryDto } from "apis/oag";
import { WellsApi } from "apis/oag";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import {
  AttributeStore,
  useLinkedWellAttributes,
} from "hooks/filters/useLinkedAttributes";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { RigTechnology } from "utils/enums";
import { dateToDateOnlyDto } from "utils/helper";
import type { PDWellSummariesQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const wells = new WellsApi(apiConfig);

export function useWellSummaries() {
  const { isEvergreen } = useDashboardType();
  const reduxState = isEvergreen
    ? AttributeStore.evergreenWells
    : AttributeStore.allWells;
  const searchState = useAppSelector((state) => state[reduxState].search);
  const statusState = useAppSelector((state) => state[reduxState].status);
  const startDateState = useAppSelector((state) => state[reduxState].startDate);
  const endDateState = useAppSelector((state) => state[reduxState].endDate);

  const { selectedValues, options } = useLinkedWellAttributes({
    storeName: reduxState,
  });

  const dispatch = useAppDispatch();

  const startDate = useMemo(
    () => startDateState || defaultDateDto.from.utc,
    [startDateState],
  );
  const endDate = useMemo(
    () => endDateState || defaultDateDto.to.utc,
    [endDateState],
  );

  const filterState = useAppSelector((state) => state[reduxState].filters);

  const queryKey: PDWellSummariesQueryKey = {
    type: PDQueryType.WELL_SUMMARIES,
    uid: RequestUID.wellSummaries,
    query: {
      options,
      searchState,
      startDate,
      endDate,
      isEvergreen,
      statusState,
      filterState,
    },
  };

  const request = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      const requestQuery: ListWellQueryDto = {
        search: searchState,
        operatorIds:
          selectedValues.operators === null
            ? null
            : (options?.operators ?? []).map((op) => op.id),
        rigIds:
          selectedValues.rigs === null
            ? null
            : (options?.rigs ?? []).map((rig) => rig.id),
        from: dateToDateOnlyDto(startDate || defaultDateDto.from.utc),
        to: dateToDateOnlyDto(endDate || defaultDateDto.to.utc),
        includeEverGreenOnly: isEvergreen,
        formationIds:
          selectedValues.formations === null
            ? null
            : (options?.formations ?? []).map((f) => f.id),
        selectedFilters: {
          includeFlatTime: true,
          includeRotatingTime: true,
          includeSlidingTime: true,
          includeNullHoleDepth: true,
          selectedWellStatusTypes: statusState,
          ...(filterState
            ? {
                includeAlphaRigs: filterState?.technology
                  ? filterState?.technology
                      ?.map((tech) => tech.id)
                      .includes(RigTechnology.Alpha)
                  : true,

                includeNonAlphaRigs: filterState?.technology
                  ? filterState?.technology
                      ?.map((tech) => tech.id)
                      .includes(RigTechnology.NonAlpha)
                  : true,

                selectedClasses: filterState?.rigType
                  ? filterState?.rigType.map((t) => (t.id ?? -1).toString())
                  : null,
                selectedCountryIds: filterState?.country
                  ? filterState?.country.map((c) => +(c.id ?? -1))
                  : null,
                selectedHorsePowers: filterState?.horsePower
                  ? filterState?.horsePower.map((hp) => +(hp.id ?? -1))
                  : null,
                selectedOperatingCenters: filterState?.operatingCenter
                  ? filterState?.operatingCenter.map((oc) =>
                      (oc.id ?? -1).toString(),
                    )
                  : null,
              }
            : {
                includeAlphaRigs: true,
                includeNonAlphaRigs: true,
              }),
        },
      };

      return wells.apiWellsSummariesPut({
        listWellQueryDto: requestQuery,
      });
    },
    enabled: Object.keys(options).every((key) => {
      return options[key as keyof typeof options] !== null;
    }),
  });

  useEffect(() => {
    const data = request.data;
    if (!data) return;

    const wells = data?.wells ?? [];
    if (wells) {
      const lastUpdatedAtDate = wells.reduce((acc: DateDto | null, well) => {
        const freshDate = well.lastWellFactUpdateAt;
        if (!freshDate) return acc;
        const date1 = new Date(freshDate.utc).getTime();
        const date2 = acc ? new Date(acc.utc).getTime() : null;
        if (!date2) return freshDate;
        if (date1 > date2) return freshDate;
        return acc;
      }, null);
      dispatch({
        type: (() => {
          if (isEvergreen) return "EVERGREEN_WELLS_SET_LAST_UPDATE_DATE";
          return "ALL_WELLS_SET_LAST_UPDATE_DATE";
        })(),
        payload: lastUpdatedAtDate,
      });
    }
  }, [request.data, dispatch, isEvergreen]);
  return request;
}
