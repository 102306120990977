import type { UseMutationResult } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import type { LensTemplateType, UserLensDto } from "apis/oag";
import { CommonLensSettingsModalContent } from "components/Lenses/CommonLensSettingsModalContents";
import { getContainerLens } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { UserLensesQueryKey } from "hooks/lens/useUserLenses";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { PDQueryType } from "utils/queryNamespaces";

interface UseLensSettingsModalParams {
  lens: UserLensDto;
  isLocked: boolean;
  handleLensUpdate: UseMutationResult<
    UserLensDto,
    unknown,
    UserLensDto,
    unknown
  >;
  onLensUpdated?: (data: UserLensDto) => void;
  templateType?: LensTemplateType;
}

export function useLensSettingsModal({
  lens,
  onLensUpdated,
  handleLensUpdate,
  isLocked,
  templateType,
}: UseLensSettingsModalParams) {
  const queryClient = useQueryClient();

  const [visible, setVisible] = useState(false);
  const [selectedLens, setSelectedLens] = useState<UserLensDto | undefined>(
    lens,
  );

  const CustomSettingsModalComponent = useMemo(
    () => getContainerLens(templateType)?.LensSettingsModal,
    [templateType],
  );

  const close = useCallback(() => {
    setSelectedLens(undefined);
    setVisible(false);
  }, []);

  const open = useCallback((lens: UserLensDto) => {
    setSelectedLens(lens);
    setVisible(true);
  }, []);

  useEffect(() => {
    if (!visible) {
      setSelectedLens(undefined);
    }
  }, [visible]);

  const updateLens = useCallback(
    (newLens: UserLensDto) => {
      setSelectedLens((prevLens) => ({ ...prevLens, ...newLens }));
      onLensUpdated?.(newLens);
    },
    [onLensUpdated],
  );

  const handleOnSave = useCallback(
    async (newLens: UserLensDto) => {
      await handleLensUpdate.mutateAsync(newLens);
      queryClient.invalidateQueries({
        queryKey: [{ type: PDQueryType.FACTS, lensId: lens.id }],
        exact: false,
      });
      queryClient.invalidateQueries({ queryKey: [UserLensesQueryKey] });
    },
    [handleLensUpdate, lens?.id, queryClient],
  );

  const node = selectedLens ? (
    <Suspense fallback={null}>
      {CustomSettingsModalComponent ? (
        <CustomSettingsModalComponent
          isVisible={visible}
          setIsVisible={setVisible}
          lens={selectedLens}
          onLensUpdated={updateLens}
        />
      ) : (
        <CommonLensSettingsModalContent
          selectedLens={selectedLens}
          setVisible={setVisible}
          visible={visible}
          isLocked={isLocked}
          onSave={handleOnSave}
        />
      )}
    </Suspense>
  ) : null;

  return {
    visible,
    close,
    open,
    node,
  };
}
