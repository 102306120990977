import { WellStatusType } from "apis/oag";
import { ClaraModal } from "components/Clara/ClaraModal";
import { useFiltersCategories } from "components/Filters/useFiltersCategories";
import Header from "components/RigsHeader";
import { useRigsCommonDataFeed } from "hooks/dataFeed/useRigsCommonDataFeed";
import { AttributeStore } from "hooks/filters/useLinkedAttributes";
import { RigLeaderboardList } from "pages/RigLeaderboard/components/RigLeaderboardList";
import { RigListContainer, RigListInner } from "pages/RigLeaderboard/styled";
import { ScoreBenchmarkProvider } from "pages/RigScoreCard/ScoreBenchmarkContext";
import { useCallback, useEffect, useState } from "react";
import type {
  IFilterOptions,
  IFilterOptionValue,
} from "reducers/rigsCommonReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { usePageView } from "services/Mixpanel";

export const RigsLeaderboard = () => {
  usePageView("Rig Leaderboard");
  const [totalRigs, setTotalRigs] = useState(0);
  useRigsCommonDataFeed(true);
  const dispatch = useAppDispatch();

  const setFilters = useCallback(
    (filters: IFilterOptions | null) => {
      dispatch({ type: "RIGS_COMMON_SET_FILTERS", payload: filters });
    },
    [dispatch],
  );

  const rigsCommonFilters = useAppSelector((state) => state.rigsCommon.filters);
  const { initialFilters } = useFiltersCategories(
    rigsCommonFilters,
    setFilters,
    AttributeStore.rigsCommon,
  );

  useEffect(() => {
    dispatch({
      type: "RIGS_COMMON_FULL_WELLS_INCLUDED",
      payload: true,
    });

    const filterOptionValue = (initialFilters.wellStatus ?? []).find(
      (option: IFilterOptionValue) => option.value === WellStatusType.Completed,
    );

    if (filterOptionValue) {
      dispatch({
        type: "RESET_RIGS_COMMON_FILTERS_90_DAYS",
        payload: {
          ...initialFilters,
          wellStatus: [filterOptionValue],
        },
      });
    }
    dispatch({ type: "RESET_RIG_DASHBOARD" });
  }, [dispatch, initialFilters]);

  return (
    <ScoreBenchmarkProvider>
      <ClaraModal />
      <Header
        setFilters={setFilters}
        filters={rigsCommonFilters}
        totalRigs={totalRigs}
      />
      <RigListContainer>
        <RigListInner>
          <RigLeaderboardList setTotalRigs={setTotalRigs} />
        </RigListInner>
      </RigListContainer>
    </ScoreBenchmarkProvider>
  );
};
