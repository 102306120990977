import type { IAction } from "reducers/types";

interface ClaraReducerState {
  pendingMessage: string;
  id: number | null;
  favorite: boolean;
  isEditMode: boolean;
  isTyped: boolean;
  isChatThreadMode: boolean;
  imperativelyPopulateInputWithThisMessage: string | null;
}
const defaultState: ClaraReducerState = {
  pendingMessage: "",
  id: null,
  favorite: false,
  isChatThreadMode: false,
  isEditMode: false,
  isTyped: false,
  imperativelyPopulateInputWithThisMessage: null,
};

interface ISetPendingPromptMessage extends IAction {
  type: "SET_PENDING_MESSAGE";
  payload: {
    message: string;
    id: number | null;
    favorite?: boolean;
  };
}

interface IResetPendingPromptMessage extends IAction {
  type: "RESET_PENDING_MESSAGE";
}

interface ISetEditMode extends IAction {
  type: "CLARA_SET_EDIT_MODE";
  payload: boolean;
}

interface ISetChatThreadMode extends IAction {
  type: "CLARA_SET_CHAT_THREAD_MODE";
  payload: boolean;
}

interface IPopulateInput extends IAction {
  type: "CLARA_POPULATE_INPUT";
  payload: string | null;
}

type AvailableActions =
  | ISetPendingPromptMessage
  | IResetPendingPromptMessage
  | ISetEditMode
  | ISetChatThreadMode
  | IPopulateInput;

function claraReducer(
  state: ClaraReducerState = defaultState,
  action: AvailableActions,
): ClaraReducerState {
  switch (action.type) {
    case "CLARA_SET_CHAT_THREAD_MODE":
      return { ...state, isChatThreadMode: action.payload };
    case "SET_PENDING_MESSAGE":
      return {
        ...state,
        id: action.payload.id,
        pendingMessage: action.payload.message,
        isChatThreadMode: true,
        favorite: action.payload.favorite ?? false,
      };
    case "RESET_PENDING_MESSAGE":
      return { ...state, pendingMessage: "" };
    case "CLARA_SET_EDIT_MODE":
      return { ...state, isEditMode: action.payload };
    case "CLARA_POPULATE_INPUT":
      return {
        ...state,
        imperativelyPopulateInputWithThisMessage: action.payload,
      };
    default:
      return state;
  }
}

export default claraReducer;
