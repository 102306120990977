import { scaleLinear } from "@visx/scale";
import type { ScrollbarRange } from "components/Lenses/common/Scrollbar";
import { useLayoutEffect, useMemo, useState } from "react";

export const useChartScrollbar = ({
  plotWidth,
  detailed,
  zoomRate,
}: {
  plotWidth: number;
  detailed: boolean;
  zoomRate: number;
}) => {
  const scrollbarPadding = detailed ? 28 : 4;
  const scrollbarWidth = plotWidth - scrollbarPadding * 2;

  const scrollbarScale = useMemo(
    () => scaleLinear<number>({ domain: [0, 1], range: [0, scrollbarWidth] }),
    [scrollbarWidth],
  );

  const [scrollbarRange, setScrollbarRange] = useState<ScrollbarRange>({
    startX: 1 - 1 / zoomRate,
    endX: 1,
  });

  useLayoutEffect(() => {
    setScrollbarRange({ startX: 1 - 1 / zoomRate, endX: 1 });
  }, [zoomRate]);

  return {
    scrollbarScale,
    scrollbarRange,
    scrollbarPadding,
    scrollbarWidth,
    zoomRate,
    setScrollbarRange,
  };
};
