import { PDComponent } from "components/PDComponents";
import type { IconName } from "icons/autogeneratedTypes";
import React from "react";

import { SelectorGroupContainer, StyledSelector } from "./style";

export interface SelectorItem<T> {
  key: T;
  label: string;
  icon?: IconName;
}

export const GroupSelector = <T extends string>({
  items,
  onSelect,
  selectedItem,
  width,
}: {
  items: SelectorItem<T>[];
  selectedItem: T;
  onSelect: (key: T) => void;
  width?: number;
}) => {
  return (
    <SelectorGroupContainer $width={width}>
      {items.map((item) => (
        <StyledSelector
          key={item.key as string}
          $selected={selectedItem === item.key}
          onClick={() => onSelect(item.key)}
          $removePadding={!!width}
        >
          {item.icon ? <PDComponent.SvgIcon name={item.icon} /> : null}
          {item.label}
        </StyledSelector>
      ))}
    </SelectorGroupContainer>
  );
};
