import { PDComponent } from "components/PDComponents";
import { NotificationRow } from "pages/Notifications/components/NotificationRow";
import type { BaseNotificationVm } from "utils/notifications/mappers";

export const NotificationList = ({
  notifications,
  selectedIdsList,
  handleNotificationSelect,
}: {
  notifications: BaseNotificationVm[];
  selectedIdsList: number[];
  handleNotificationSelect: (id: number) => void;
}) => {
  return (
    <PDComponent.VirtualizedList
      items={notifications}
      itemSizePx={72}
      overscanCount={1}
    >
      {(notification, idx) => (
        <NotificationRow
          notification={notification}
          key={`${notification.id}-${idx}`}
          handleNotificationSelect={() => {
            handleNotificationSelect(notification.id);
          }}
          editMode={selectedIdsList?.length > 0}
          isSelected={selectedIdsList?.includes(notification.id)}
        />
      )}
    </PDComponent.VirtualizedList>
  );
};
