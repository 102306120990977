import { LottieContainer } from "components/Clara/Loaders/style";
import animationDataDark from "components/Clara/MessagePending/pending-dark.json";
import animationData from "components/Clara/MessagePending/pending-light.json";
import Lottie from "react-lottie";
import { useCustomTheme } from "utils/useTheme";

export const MessagePending = () => {
  const { isDark } = useCustomTheme();
  return (
    <LottieContainer id="messagePending">
      <Lottie
        isPaused={false}
        isStopped={false}
        options={{
          loop: true,
          autoplay: true,
          animationData: isDark ? animationDataDark : animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={39}
        width={41}
        style={{
          margin: "0",
        }}
      />
    </LottieContainer>
  );
};
