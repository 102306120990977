import { useQueryClient } from "@tanstack/react-query";
import { ClaraMessageSource } from "apis/oag";
import { IndividualResponse } from "components/Clara/MessageThread/IndividualResponse";
import { IndividualUserMessage } from "components/Clara/MessageThread/IndividualUserMessage";
import { IndividualMessageError } from "components/Clara/MessageThread/MessageFallbacks";
import { type Message, useResetErrorMessage } from "hooks/clara/useClara";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

export const IndividualMessage = ({ message }: { message: Message }) => {
  const isResponse = (message: Message) =>
    message.source === ClaraMessageSource.Model;

  const { resetErrorMessage } = useResetErrorMessage();
  const queryClient = useQueryClient();

  if (message.isError)
    return (
      <IndividualMessageError
        onReset={() => {
          queryClient.removeQueries({
            queryKey: [
              { uid: RequestUID.clara, type: PDQueryType.CLARA_MESSAGE },
            ],
            exact: false,
          });
          resetErrorMessage(message);
        }}
      />
    );

  if (isResponse(message)) {
    return <IndividualResponse message={message} />;
  } else {
    return <IndividualUserMessage message={message} />;
  }
};
