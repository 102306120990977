import { ElevatedSurfaceBoxShadowCSS } from "commonStyles";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import {
  Button,
  Card,
  Dropdown,
  Radio,
  Row,
  Space,
} from "utils/componentLibrary";
import { PdCardActions, PdCardBody } from "utils/componentLibrary/Card";
import { PdRow } from "utils/componentLibrary/Row";
import { PdSpaceItem } from "utils/componentLibrary/Space";

export const StyledSpace = styled(Space)`
  height: 64px;
  display: flex;
  ${PdSpaceItem} {
    display: inline-flex;
  }
  .pd-icon {
    font-size: 14px;
    color: ${colors.gray};
  }
`;

export const StyledButtonProfile = styled(Button)`
  padding: 9px 0px;
  height: 64px;

  .pd-icon {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &.ant-btn {
    &.ant-btn-background-ghost {
      border: none;
    }
    color: ${colors.gray};
    :hover {
      color: ${colors.gray};
      .pd-icon {
        color: ${colors.gray};
      }
    }
    &[ant-click-animating-without-extra-node="true"]::after,
    &.ant-click-animating-node {
      animation: none;
      box-shadow: none;
    }
  }
`;

export const StyledButton = styled(Button)`
  padding: 9px 0px;
  margin-left: 16px;
  height: 64px;

  .pd-icon {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &.ant-btn {
    &.ant-btn-background-ghost {
      border: none;
    }
    color: ${colors.gray};
    :hover {
      color: ${colors.white};
      .pd-icon {
        color: ${colors.white};
      }
    }
    &[ant-click-animating-without-extra-node="true"]::after,
    &.ant-click-animating-node {
      animation: none;
      box-shadow: none;
    }
  }
`;

export const StyledOptionRow = styled(Row)`
  height: 40px;
  padding: 0 16px;
  .ant-radio-group span {
    font-size: 10px;
    letter-spacing: -0.13px;
  }
  .ant-radio-button-wrapper {
    width: 48px;
    height: 22px;
    padding: 0;
    color: ${colors.gray};

    :hover {
      color: ${({ theme }) => theme.themeStyle.colors.primary_button_bg};
    }

    &.ant-radio-button-wrapper-checked,
    &.ant-radio-button-wrapper-checked:not(
        [class*=" ant-radio-button-wrapper-disabled"]
      ).ant-radio-button-wrapper:first-child {
      background-color: ${colors.pale_grey};
      border-color: ${colors.off_state};
      border-right-color: ${colors.off_state};
      border-left-color: ${colors.off_state};
    }
    &.ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      )::before {
      background-color: ${colors.off_state};
    }
    .ant-radio-button {
      &.ant-radio-button-checked {
        ${({ theme }) =>
          theme.isDark
            ? css`
                background-color: ${colors.soft_white};
              `
            : {}}

        border-color: ${colors.off_state};
        & + span {
          color: ${() => colors.black};
        }
      }
    }
    > span {
      line-height: 20px;
      position: absolute;
      padding: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const StyledLink = styled(NavLink)`
  margin-left: 16px;
  font-weight: 500;
  letter-spacing: -0.1px;
  font-size: 14px;
  color: ${colors.gray};
  position: relative;
  &.active,
  :hover {
    color: ${colors.white};
  }
  &.active {
    ::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 4px;
      left: 0;
      bottom: 2px;
      background-color: ${colors.well_color};
    }
  }
  .pd-icon {
    margin-right: 8px;
    font-size: 14px;
    color: inherit;
  }
`;

export const StyledHelpButton = styled(StyledButton)`
  font-weight: 500;
  letter-spacing: -0.1px;
  font-size: 14px;
  color: ${colors.gray};
  display: flex;
  align-items: center;

  :hover {
    color: ${colors.white};
  }
`;

export const StyledMenuLink = styled(NavLink)`
  width: 100%;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const StyledLogoutRow = styled(Row)`
  cursor: pointer;
`;

export const StyledCard = styled(Card)`
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  min-width: 186px;
  margin-top: -10px;
  ${PdCardBody} {
    padding: 0;
    font-size: 14px;
    line-height: normal;
    letter-spacing: -0.18px;
    ${StyledOptionRow} + ${StyledOptionRow} {
      border-top: 1px solid
        ${({ theme }) => theme.themeStyle.colors.primary_accent};
    }
  }
  ${PdCardActions} {
    ${StyledLogoutRow} {
      padding: 12px 16px;
      color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
      :hover {
        color: ${({ theme }) => theme.themeStyle.colors.primary_button_bg};
      }
      span {
        padding-right: 8px;
      }
    }
  }
`;

export const StyledDropDown = styled(Dropdown)`
  display: flex;
  justify-content: center;
  align-items: center;

  &${StyledButton} span {
    color: ${colors.white};
  }
  :hover {
    .pd-icon.profile {
      color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    }
  }
  .pd-icon.profile {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    background-color: ${({ theme }) =>
      theme.themeStyle.colors.primary_chart_accent};

    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
`;

export const StyledThemeButton = styled(Radio.Button)`
  > span {
    ${PdRow} {
      > span {
        padding-right: 3px;
      }
      width: 48px;
    }
  }
`;

export const BoxShadowSurface = styled.div`
  ${ElevatedSurfaceBoxShadowCSS};
`;
