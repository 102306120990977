import { PDComponent } from "components/PDComponents";
import type { FC } from "react";
import React, { useEffect, useRef } from "react";
import { useMixpanel } from "services/Mixpanel";
import { Result } from "utils/componentLibrary";

const AuthBlank: FC = () => {
  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();
  useEffect(() => {
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage("Authentication Wink");
    }
  }, [viewPage]);
  return (
    <Result icon={<PDComponent.SvgIcon name="faceWink" />} title="Hey there!" />
  );
};

export default AuthBlank;
