import { scaleLinear } from "@visx/scale";
import { LinePath } from "@visx/shape";
import type { ActualTvdPointDto } from "apis/oag";
import { curveMonotoneX } from "d3";
import { extent } from "d3-array";
import type { FC } from "react";
import { useMemo } from "react";
import styled from "styled-components";
import colors from "utils/colors";

const TvDHeight = 25;
const TvDWidth = 70;

const MiniTvDHolder = styled.div<{ excluded: boolean }>`
  height: ${TvDHeight}px;
  width: ${TvDWidth}px;
`;

const MiniTVD: FC<{
  data: ActualTvdPointDto[];
  excluded: boolean;
  isActive: boolean;
}> = ({ data, excluded, isActive }) => {
  const [xScale, yScale] = useMemo(() => {
    const [xMin = 0, xMax = 0] = extent(data.map((p) => p.cumulativeDuration));
    const [yMin = 0, yMax = 0] = extent(
      data
        .filter((p) => p.holeDepth !== null && p.holeDepth !== undefined)
        .map((p) => p.holeDepth ?? 0),
    );
    return [
      scaleLinear<number>({
        domain: [xMin, xMax],
        range: [0, TvDWidth - 2],
      }),
      scaleLinear<number>({
        domain: [yMin, yMax],
        range: [0, TvDHeight - 2],
      }),
    ];
  }, [data]);

  return (
    <MiniTvDHolder excluded={excluded}>
      <svg
        height={`${TvDHeight}px`}
        width={`${TvDWidth}px`}
        style={{ overflow: "visible", opacity: excluded ? 0 : 1 }}
      >
        <LinePath
          data={data}
          defined={(d) => d !== null && d !== undefined}
          x={(d) => xScale(d.cumulativeDuration)}
          y={(d) => yScale(d.holeDepth ?? 0)}
          curve={curveMonotoneX}
          fill="none"
          stroke={isActive ? colors.well_color : colors.lunar_rays}
          strokeWidth="2"
        />
      </svg>
    </MiniTvDHolder>
  );
};

export default MiniTVD;
