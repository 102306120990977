import type { KpiGroupUserLensDto, UserLensDto } from "apis/oag";
import { DimensionType, DrillingProductivityType, TimeUnit } from "apis/oag";
import type { DrillingProductivityUserLensDto } from "apis/oag/models/DrillingProductivityUserLensDto";
import { useHoleSizes } from "hooks/drillingInvariants/useHoleSizes";
import { isNil } from "lodash";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { IUnitSystem } from "reducers/types";
import {
  ftInM,
  lb_ft3_to_km_m3,
  secondsInDay,
  secondsInHour,
  secondsInMinute,
  secondsInMonth,
  secondsInWeek,
} from "utils/common";

export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = 60 * SECONDS_IN_MINUTE;
export const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR;
export const SECONDS_IN_WEEK = 7 * SECONDS_IN_DAY;
export const SECONDS_IN_MONTH = 30 * SECONDS_IN_DAY;
export const SECONDS_IN_QUARTER = 3 * SECONDS_IN_MONTH;
export const SECONDS_IN_YEAR = 365 * SECONDS_IN_DAY;

export const FACTOR_L_TO_GAL = 0.2641720524;
export const FACTOR_M_TO_FT = 3.28084;
export const FACTOR_M_TO_FT_PER_HOUR = FACTOR_M_TO_FT * SECONDS_IN_HOUR;
export const FACTOR_M3_TO_FT3 = 35.3146667;
export const FACTOR_PA_TO_PSI = 0.0001450377;
export const FACTOR_TONNS_TO_IMPERIAL_TONES = 0.907185;

export const DEFAULT_DATE_FORMAT = "MM/DD/YY HH:mm:ss";
export const ALTERNATIVE_DATE_FORMAT = "MM/DD/YY HH:mm:ss";
export const SHORT_DATE_FORMAT = "MM/DD/YY HH:mm";
export const FULL_YEAR_DATE_FORMAT = "MM/DD/YYYY HH:mm";
export const SHORTER_DATE_FORMAT = "MM/DD HH:mm";
const timeUnitConversionMap: Record<
  TimeUnit,
  { factor: number; unit: string }
> = {
  [TimeUnit.Hour]: {
    factor: 1 / secondsInHour,
    unit: "hr",
  },
  Unknown: {
    factor: 1,
    unit: "Unknown",
  },
  Second: {
    factor: 1,
    unit: "s",
  },
  Minute: {
    factor: 1 / secondsInMinute,
    unit: "min",
  },
  Day: {
    factor: 1 / secondsInDay,
    unit: "day",
  },
  Week: {
    factor: 1 / secondsInWeek,
    unit: "week",
  },
  Month: {
    factor: 1 / secondsInMonth,
    unit: "month",
  },
  Quarter: {
    factor: 1 / (secondsInMonth * 3),
    unit: "quarter",
  },
  Year: {
    factor: 1 / (secondsInMonth * 12),
    unit: "year",
  },
};

export function useTimeUom(timeUnit: TimeUnit) {
  const formatTimeUnit: UOMDisplayFunction = useCallback(
    (value, { factor = 1, unit = undefined, fractionDigits = 2 } = {}) => {
      const displayValue = !isNil(value)
        ? value * factor * timeUnitConversionMap[timeUnit].factor
        : null;
      return `${valueToString(displayValue, fractionDigits)} ${
        unit === "" ? "" : timeUnitConversionMap[timeUnit].unit
      }`;
    },
    [timeUnit],
  );

  const uom = useUOM(DimensionType.Count, formatTimeUnit);
  return useMemo<UOMHelper>(
    () => ({
      ...uom,
      abbr: timeUnitConversionMap[timeUnit].unit,
      displayWithAutoDecimals: (domain, value, opts) => {
        if (!Number.isFinite(value))
          return uom.displayWithAutoDecimals(domain, value, opts);
        const newDomain = domain.map(
          (d) => d * timeUnitConversionMap[timeUnit].factor,
        );
        return uom.displayWithAutoDecimals(newDomain, value, {
          ...opts,
          factor: timeUnitConversionMap[timeUnit].factor,
          unit: opts?.unit ?? timeUnitConversionMap[timeUnit].unit,
        });
      },
      toSI: (value: number) => value / timeUnitConversionMap[timeUnit].factor,
      fromSI: (value: number) => value * timeUnitConversionMap[timeUnit].factor,
    }),
    [timeUnit, uom],
  );
}
const timeDistanceUnitConversionMap: Record<
  TimeUnit,
  Record<IUnitSystem, { factor: number; unit: string }>
> = {
  [TimeUnit.Second]: {
    [IUnitSystem.METRIC]: {
      factor: 1,
      unit: "m/s",
    },
    [IUnitSystem.IMPERIAL]: {
      factor: 1 / FACTOR_M_TO_FT,
      unit: "ft/s",
    },
  },
  [TimeUnit.Minute]: {
    [IUnitSystem.METRIC]: {
      factor: SECONDS_IN_MINUTE,
      unit: "m/min",
    },
    [IUnitSystem.IMPERIAL]: {
      factor: SECONDS_IN_MINUTE * FACTOR_M_TO_FT,
      unit: "ft/min",
    },
  },
  [TimeUnit.Hour]: {
    [IUnitSystem.METRIC]: {
      factor: SECONDS_IN_HOUR,
      unit: "m/hr",
    },
    [IUnitSystem.IMPERIAL]: {
      factor: SECONDS_IN_HOUR * FACTOR_M_TO_FT,
      unit: "ft/hr",
    },
  },
  [TimeUnit.Day]: {
    [IUnitSystem.METRIC]: {
      factor: SECONDS_IN_DAY,
      unit: "m/day",
    },
    [IUnitSystem.IMPERIAL]: {
      factor: SECONDS_IN_DAY * FACTOR_M_TO_FT,
      unit: "ft/day",
    },
  },
  [TimeUnit.Week]: {
    [IUnitSystem.METRIC]: {
      factor: SECONDS_IN_WEEK,
      unit: "m/week",
    },
    [IUnitSystem.IMPERIAL]: {
      factor: SECONDS_IN_WEEK * FACTOR_M_TO_FT,
      unit: "ft/week",
    },
  },
  [TimeUnit.Month]: {
    [IUnitSystem.METRIC]: {
      factor: SECONDS_IN_MONTH,
      unit: "m/month",
    },
    [IUnitSystem.IMPERIAL]: {
      factor: SECONDS_IN_MONTH * FACTOR_M_TO_FT,
      unit: "ft/month",
    },
  },
  [TimeUnit.Quarter]: {
    [IUnitSystem.METRIC]: {
      factor: SECONDS_IN_QUARTER,
      unit: "m/quarter",
    },
    [IUnitSystem.IMPERIAL]: {
      factor: SECONDS_IN_QUARTER * FACTOR_M_TO_FT,
      unit: "ft/quarter",
    },
  },
  [TimeUnit.Year]: {
    [IUnitSystem.METRIC]: {
      factor: SECONDS_IN_YEAR,
      unit: "m/year",
    },
    [IUnitSystem.IMPERIAL]: {
      factor: SECONDS_IN_YEAR * FACTOR_M_TO_FT,
      unit: "ft/year",
    },
  },
  [TimeUnit.Unknown]: {
    [IUnitSystem.METRIC]: {
      factor: 1,
      unit: "m/s",
    },
    [IUnitSystem.IMPERIAL]: {
      factor: 1 / FACTOR_M_TO_FT,
      unit: "ft/s",
    },
  },
};

export const valueToString = (
  val: number | null,
  fractionDigits = 2,
): string => {
  return val === null || isNaN(val)
    ? "- -"
    : val.toLocaleString("en-US", {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
      });
};

type UOMDisplayFunctionValue = number | null;
type UOMDisplayFunctionOptions = {
  factor?: number;
  unit?: string;
  fractionDigits?: number;
  metricOption?: boolean;
};

export type UOMDisplayFunction = (
  value?: UOMDisplayFunctionValue,
  options?: UOMDisplayFunctionOptions,
) => string;

export type UOMDisplayWithAutoDecimalsFunction = (
  dimension: ExtendedDimensions,
  currentUOM: IUnitSystem,
  valuesDomain: number[],
  value?: UOMDisplayFunctionValue,
  options?: UOMDisplayFunctionOptions,
) => string;

type UOMTransformFunction = (
  value: number,
  options: {
    factor: number;
    unit?: string;
    fractionDigits?: number;
    metricOption?: boolean;
  },
) => number;

export enum UtilDimensions {
  NoOp = "NoOp",
  Percentage = "Percentage",
  PumpOutput = "PumpOutput",
  KgM3 = "KgM3",
  ALTERNATE_CubicMetresPerSecond = "ALTERNATE_CubicMetresPerSecond", // Unstable solution for: wi-15034 , wi-15076
}

type ExtendedDimensions = DimensionType | UtilDimensions;

export const dimensionAbbrs: Record<
  ExtendedDimensions,
  Record<IUnitSystem | "SI", string>
> = {
  [UtilDimensions.NoOp]: { SI: "", MetricView: "", ImperialView: "" },
  [UtilDimensions.Percentage]: { SI: "%", MetricView: "%", ImperialView: "%" },
  [UtilDimensions.PumpOutput]: {
    SI: "m³/sec",
    MetricView: "m³/min",
    ImperialView: "gpm",
  },
  [UtilDimensions.ALTERNATE_CubicMetresPerSecond]: {
    SI: "m³/sec",
    MetricView: "m³/min",
    ImperialView: "ft³/min",
  },
  [DimensionType.Undefined]: {
    SI: "undefined",
    MetricView: "undefined",
    ImperialView: "undefined",
  },
  [DimensionType.Seconds]: {
    SI: "s",
    MetricView: "Minutes",
    ImperialView: "Minutes",
  },
  [DimensionType.MetresPerSecond]: {
    SI: "m/s",
    MetricView: "m/hr",
    ImperialView: "ft/hr",
  },
  [DimensionType.Metres]: { SI: "m", MetricView: "m", ImperialView: "ft" },
  [DimensionType.JointsPerSecond]: {
    SI: "joints / s",
    MetricView: "joints / hr",
    ImperialView: "joints / hr",
  },
  [DimensionType.RevolutionsPerSecond]: {
    SI: "rps",
    MetricView: "rpm",
    ImperialView: "rpm",
  },
  [DimensionType.Newtons]: {
    SI: "kDaN",
    MetricView: "kDaN",
    ImperialView: "klbsf",
  },
  [DimensionType.Pascals]: {
    SI: "kPa",
    MetricView: "kPa",
    ImperialView: "psi",
  },
  [DimensionType.NewtonMeters]: {
    SI: "Nm",
    MetricView: "Nm",
    ImperialView: "ft-lbf",
  },
  [DimensionType.CubicMetresPerSecond]: {
    SI: "m³/sec",
    MetricView: "m³/min",
    ImperialView: "gpm",
  },
  [DimensionType.Count]: { SI: "", MetricView: "", ImperialView: "" },
  [UtilDimensions.KgM3]: {
    SI: "undefined",
    MetricView: "undefined",
    ImperialView: "undefined",
  },
  [DimensionType.Watts]: { SI: "kW", MetricView: "kW", ImperialView: "kW" },
  [DimensionType.KiloWattHours]: {
    SI: "kWh",
    MetricView: "kWh",
    ImperialView: "kWh",
  },

  [DimensionType.CubicMetres]: {
    SI: `m³`,
    MetricView: "m³",
    ImperialView: "ft³",
  },
  [DimensionType.Litres]: { SI: "L", MetricView: "L", ImperialView: "Gal" },
  [DimensionType.LitresPerSecond]: {
    SI: "L/s",
    MetricView: "L/min",
    ImperialView: "gpm",
  },
  [DimensionType.Temperature]: {
    SI: "°C",
    MetricView: "°C",
    ImperialView: "°F",
  },
  [DimensionType.KiloGramsPerSecond]: {
    SI: "kg/s",
    MetricView: "Tonnes/min",
    ImperialView: "Tons/min",
  },
  [DimensionType.KiloGrams]: {
    SI: "kg",
    MetricView: "Tonnes",
    ImperialView: "Tons",
  },
};

export const dimensionChange: Record<
  ExtendedDimensions,
  Record<IUnitSystem | "SI", UOMTransformFunction>
> = {
  Percentage: {
    SI: (value, { factor }) => value * Math.pow(10, factor * 2),
    MetricView: (value, { factor }) => value * Math.pow(10, factor * -2),
    ImperialView: (value, { factor }) => value * Math.pow(10, factor * -2),
  },
  PumpOutput: {
    SI: (value) => value,
    MetricView: (value) => value * 60,
    ImperialView: (value) => value * 1000 * FACTOR_L_TO_GAL * 60,
  },
  KgM3: {
    SI: (value) => value,
    MetricView: (value) => value,
    ImperialView: (value, { factor }) =>
      value * Math.pow(lb_ft3_to_km_m3, factor),
  },
  Metres: {
    SI: (value) => value,
    MetricView: (value) => value,
    ImperialView: (value, { factor }) => value * Math.pow(1 / ftInM, factor),
  },
  MetresPerSecond: {
    SI: (value) => value,
    MetricView: (value, { factor }) => value * Math.pow(1 / 3600, factor),
    ImperialView: (value, { factor }) => value * Math.pow(1 / 11811, factor),
  },
  JointsPerSecond: {
    SI: (value) => value,
    MetricView: (value) => value,
    ImperialView: (value) => value,
  },
  Seconds: {
    SI: (value) => value,
    MetricView: (value, { factor }) => value * Math.pow(60, factor),
    ImperialView: (value, { factor }) => value * Math.pow(60, factor),
  },
  Undefined: {
    SI: (value) => value,
    MetricView: (value) => value,
    ImperialView: (value) => value,
  },
  RevolutionsPerSecond: {
    SI: (value) => value,
    MetricView: (value, { factor }) => value * Math.pow(1 / 60, factor),
    ImperialView: (value, { factor }) => value * Math.pow(1 / 60, factor),
  },
  Newtons: {
    SI: (value) => value,
    MetricView: (kdan: number, { factor }) => kdan * Math.pow(1e4, factor),
    ImperialView: (klbsf: number, { factor }) =>
      Number(klbsf) * Math.pow(4448.2216, factor),
  },

  CubicMetresPerSecond: {
    SI: (value) => value,
    MetricView: (value) => value * 60,
    ImperialView: (value) => value * FACTOR_L_TO_GAL * 1000 * 60,
  },
  ALTERNATE_CubicMetresPerSecond: {
    SI: (value) => value,
    MetricView: (value) => value * 60,
    ImperialView: (value) => value * 60 * FACTOR_M3_TO_FT3,
  },
  NewtonMeters: {
    SI: (value) => value,
    MetricView: (value) => value,
    ImperialView: (ftlb: number, { factor }) =>
      ftlb * Math.pow(1.3558179483, factor),
  },
  Count: {
    SI: (value) => value,
    MetricView: (value) => value,
    ImperialView: (value) => value,
  },
  NoOp: {
    SI: (value) => value,
    MetricView: (value) => value,
    ImperialView: (value) => value,
  },
  Pascals: {
    SI: (value) => value,
    MetricView: (pa: number, { factor }) => pa * Math.pow(1000, factor),
    ImperialView: (pa, { factor }) =>
      pa * Math.pow(1 / FACTOR_PA_TO_PSI, factor),
  },
  Watts: {
    SI: (value) => value,
    MetricView: (watts: number) => watts / 1e3,
    ImperialView: (watts: number) => watts / 1e3,
  },
  KiloWattHours: {
    SI: (value) => value,
    MetricView: (kWh: number) => kWh,
    ImperialView: (kWh: number) => kWh,
  },
  CubicMetres: {
    SI: (value) => value,
    MetricView: (value: number) => value,
    ImperialView: (value: number) => value * FACTOR_M3_TO_FT3,
  },
  Litres: {
    SI: (value) => value,
    MetricView: (value: number) => value,
    ImperialView: (value: number) => value * FACTOR_L_TO_GAL,
  },
  LitresPerSecond: {
    SI: (value) => value,
    MetricView: (value: number) => value * 60,
    ImperialView: (value: number) => value * 60 * FACTOR_L_TO_GAL,
  },
  Temperature: {
    SI: (value) => value,
    MetricView: (value: number) => value,
    ImperialView: (value: number) => (value * 9) / 5 + 32,
  },
  KiloGramsPerSecond: {
    SI: (value) => value,
    MetricView: (value: number) => (value * 60) / 1e3,
    ImperialView: (value: number) =>
      (value * 60) / (1e3 * FACTOR_TONNS_TO_IMPERIAL_TONES),
  },
  KiloGrams: {
    SI: (value) => value,
    MetricView: (value: number) => value / 1e3,
    ImperialView: (value: number) =>
      value / (1e3 * FACTOR_TONNS_TO_IMPERIAL_TONES),
  },
};

export const dimensionMap: Record<
  ExtendedDimensions,
  Record<IUnitSystem | "SI", UOMDisplayFunction>
> = {
  PumpOutput: {
    SI: (value, { factor = 1, unit = `m³/s`, fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 60, unit = `m³/min`, fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      {
        factor = 1000 * FACTOR_L_TO_GAL * 60,
        unit = "gpm",
        fractionDigits = 2,
      } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
  },
  Percentage: {
    SI: (value, { unit = "%", fractionDigits = 2, factor = 1 } = {}) =>
      `${valueToString(!isNil(value) ? (value * factor) / 100 : null, fractionDigits)} ${unit}`,
    MetricView: (value, { unit = "%", fractionDigits = 2, factor = 1 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor * 100 : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      { unit = "%", fractionDigits = 2, factor = 1 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor * 100 : null, fractionDigits)} ${unit}`,
  },
  KgM3: {
    SI: () => "",
    MetricView: () => "",
    ImperialView: () => "",
  },
  Metres: {
    SI: (value, { factor = 1, unit = "m", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 1, unit = "m", fractionDigits = 2, metricOption = false } = {},
    ) => {
      return !metricOption
        ? `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`
        : `${valueToString(!isNil(value) ? value * 1000 * factor : null, 0)} ${"mm"}`;
    },
    ImperialView: (
      value,
      {
        factor = 1,
        unit = "ft",
        fractionDigits = 2,
        metricOption = false,
      } = {},
    ) => {
      return !metricOption
        ? `${valueToString(!isNil(value) ? value * factor * 3.28084 : null, fractionDigits)} ${unit}`
        : `${valueToString(!isNil(value) ? value * 12 * factor * 3.28084 : null, fractionDigits)} ${"in"}`;
    },
  },
  MetresPerSecond: {
    SI: (value, { factor = 1, unit = "m/s", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 1, unit = "m/hr", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor * 3600 : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      { factor = 1, unit = "ft/hr", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor * FACTOR_M_TO_FT_PER_HOUR : null, fractionDigits)} ${unit}`,
  },
  JointsPerSecond: {
    SI: (value, { factor = 1, unit = "joints/s", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 1, unit = "joints/hr", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor * 3600 : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      { factor = 1, unit = "joints/hr", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor * 3600 : null, fractionDigits)} ${unit}`,
  },
  Seconds: {
    SI: (value, { factor = 1, unit = "s", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 1, unit = "min", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? (value * factor) / 60 : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      { factor = 1, unit = "min", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? (value * factor) / 60 : null, fractionDigits)} ${unit}`,
  },
  Undefined: {
    SI: (value, { factor = 1, unit = "", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (value, { factor = 1, unit = "", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (value, { factor = 1, unit = "", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
  },
  RevolutionsPerSecond: {
    SI: (value, { factor = 1, unit = "rps", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 1, unit = "rpm", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor * 60 : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      { factor = 1, unit = "rpm", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor * 60 : null, fractionDigits)} ${unit}`,
  },
  Newtons: {
    SI: (value, { factor = 1, unit = "", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (value, { factor = 1, unit = "", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? (value * factor) / 1e4 : null, fractionDigits)} ${unit}`,
    ImperialView: (value, { factor = 1, unit = "", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? (value * factor) / 4448.2216 : null, fractionDigits)} ${unit}`,
  },
  CubicMetresPerSecond: {
    SI: (value, { factor = 1, unit = `m³/s`, fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 60, unit = `m³/min`, fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      {
        factor = 1000 * FACTOR_L_TO_GAL * 60,
        unit = "gpm",
        fractionDigits = 2,
      } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
  },
  ALTERNATE_CubicMetresPerSecond: {
    SI: (value, { factor = 1, unit = `m³/s`, fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 60, unit = `m³/min`, fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      {
        factor = FACTOR_M3_TO_FT3 * 60,
        unit = "ft³/min",
        fractionDigits = 2,
      } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
  },
  NewtonMeters: {
    SI: (value, { factor = 1, unit = "", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (value, { factor = 1, unit = "", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (value, { unit = "", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value / 1.3558179483 : null, fractionDigits)} ${unit}`,
  },
  Count: {
    SI: (value, { factor = 1, unit = "cycles", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 1, unit = "cycles", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      { factor = 1, unit = "cycles", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
  },
  NoOp: {
    SI: (value, { factor = 1, unit = "cycles", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 1, unit = "cycles", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      { factor = 1, unit = "cycles", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
  },
  Pascals: {
    SI: (value, { factor = 1, unit = "", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 1, unit = "kPa", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? (value * factor) / 1000 : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      { factor = 1, unit = "psi", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor * FACTOR_PA_TO_PSI : null, fractionDigits)} ${unit}`,
  },
  Watts: {
    SI: (value, { factor = 1, unit = "", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 1 / 1e3, unit = "kW", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      { factor = 1 / 1e3, unit = "kW", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
  },
  KiloWattHours: {
    SI: (value, { factor = 1, unit = "", fractionDigits = 0 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      {
        factor = value && value >= 1e6 ? 1 / 1e3 : 1,
        unit = value && value >= 1e6 ? "MWh" : "kWh",
        fractionDigits = 0,
      } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      {
        factor = value && value >= 1e6 ? 1 / 1e3 : 1,
        unit = value && value >= 1e6 ? "MWh" : "kWh",
        fractionDigits = 0,
      } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
  },
  CubicMetres: {
    SI: (value, { factor = 1, unit = "m³", fractionDigits = 0 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (value, { factor = 1, unit = "m³", fractionDigits = 0 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      { factor = FACTOR_M3_TO_FT3, unit = "ft³", fractionDigits = 0 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
  },
  Litres: {
    SI: (value, { factor = 1, unit = "L", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (value, { factor = 1, unit = "L", fractionDigits = 2 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      { factor = FACTOR_L_TO_GAL, unit = "Gal", fractionDigits = 2 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
  },
  LitresPerSecond: {
    SI: (value, { factor = 1, unit = "L/sec", fractionDigits = 0 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 60, unit = "L/min", fractionDigits = 0 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      { factor = FACTOR_L_TO_GAL * 60, unit = "gpm", fractionDigits = 0 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
  },
  Temperature: {
    SI: (
      value,
      { factor = 1, unit = "Temperature", fractionDigits = 0 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (value, { factor = 1, unit = "°C", fractionDigits = 0 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      { factor = 9 / 5, unit = "°F", fractionDigits = 0 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor + 32 : null, fractionDigits)} ${unit}`,
  },
  KiloGramsPerSecond: {
    SI: (value, { factor = 1, unit = "Kg/s", fractionDigits = 0 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 60 / 1e3, unit = "Tonns/min", fractionDigits = 0 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      {
        factor = 60 / (1e3 * FACTOR_TONNS_TO_IMPERIAL_TONES),
        unit = "Tonnes/min",
        fractionDigits = 0,
      } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
  },
  KiloGrams: {
    SI: (value, { factor = 1, unit = "Kg", fractionDigits = 0 } = {}) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    MetricView: (
      value,
      { factor = 1 / 1e3, unit = "Tonns", fractionDigits = 0 } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
    ImperialView: (
      value,
      {
        factor = 1 / (1e3 * FACTOR_TONNS_TO_IMPERIAL_TONES),
        unit = "Tonnes",
        fractionDigits = 0,
      } = {},
    ) =>
      `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`,
  },
};

export interface UOMHelper {
  currentUOM: IUnitSystem;
  display: UOMDisplayFunction;
  displayWithAutoDecimals: (
    valuesDomain: number[],
    value?: number,
    options?: Omit<UOMDisplayFunctionOptions, "fractionDigits">,
    extendedOptions?: { valuesDomain: number[] },
  ) => string;
  dimension: ExtendedDimensions;
  abbr: string;
  // convert number from current UOM to SI
  toSI: (value: number) => number;
  // convert number from SI to current UOM
  fromSI: (value: number) => number;
}

export function getFractionDigits(interval: number) {
  let fractionDigits = 0;
  if (interval < 1) {
    fractionDigits = 3;
  } else if (interval < 100) {
    fractionDigits = 2;
  }
  return fractionDigits;
}

const displayWithAutoDecimals: UOMDisplayWithAutoDecimalsFunction = (
  dimension,
  currentUOM,
  valuesDomain,
  value,
  options,
) => {
  const [displayedStart, displayedEnd] = [
    dimensionMap[dimension]?.[currentUOM](valuesDomain[0]),
    dimensionMap[dimension]?.[currentUOM](valuesDomain[1]),
  ];

  const parsedStart = parseFloat(displayedStart.split(",").join(""))
    ? parseFloat(displayedStart.split(",").join(""))
    : 0;
  const parsedEnd = parseFloat(displayedEnd.split(",").join(""))
    ? parseFloat(displayedEnd.split(",").join(""))
    : 1;

  const difference = Math.abs(parsedStart - parsedEnd);
  const fractionDigits = getFractionDigits(difference);
  return dimensionMap[dimension]?.[currentUOM](value, {
    ...options,
    fractionDigits,
  });
};

export function useUOM(
  dimension: ExtendedDimensions = DimensionType.Undefined,
  customDisplayFn?: UOMDisplayFunction,
) {
  const currentUOM = useAppSelector((state) => state.global.unit);
  return useMemo<UOMHelper>(() => {
    return {
      currentUOM,
      display: customDisplayFn ?? dimensionMap[dimension]?.[currentUOM],
      displayWithAutoDecimals: displayWithAutoDecimals.bind(
        null,
        dimension,
        currentUOM,
      ),
      dimension,
      abbr: dimensionAbbrs[dimension][currentUOM],
      toSI: (value: number) =>
        +dimensionChange[dimension][currentUOM](value, { factor: 1 }),
      fromSI: (value: number) =>
        +dimensionChange[dimension][currentUOM](value, { factor: -1 }),
    };
  }, [currentUOM, dimension, customDisplayFn]);
}

export function useLockableUOM({
  dimension = DimensionType.Undefined,
  customDisplayFn,
  lockUnitSystemTo,
}: {
  dimension: ExtendedDimensions;
  customDisplayFn?: UOMDisplayFunction;
  lockUnitSystemTo?: IUnitSystem;
}) {
  const globalUom = useAppSelector((state) => state.global.unit);
  const currentUOM = lockUnitSystemTo ?? globalUom;
  return useMemo<UOMHelper>(() => {
    return {
      currentUOM,
      display: customDisplayFn
        ? customDisplayFn
        : dimensionMap[dimension]?.[currentUOM],
      displayWithAutoDecimals: displayWithAutoDecimals.bind(
        null,
        dimension,
        currentUOM,
      ),
      dimension,
      abbr: dimensionAbbrs[dimension][currentUOM],
      toSI: (value: number) =>
        +dimensionChange[dimension][currentUOM](value, { factor: 1 }),
      fromSI: (value: number) =>
        +dimensionChange[dimension][currentUOM](value, { factor: -1 }),
    };
  }, [currentUOM, dimension, customDisplayFn]);
}

export function useCustomHoleSizeUom() {
  const currentUOM = useAppSelector((state) => state.global.unit);

  const { data: holeSizes } = useHoleSizes();

  const formatHoleSizes: UOMDisplayFunction = useCallback(
    (
      value,
      { factor = 1, unit = "m", fractionDigits = 2, metricOption = false } = {},
    ) => {
      if (currentUOM === IUnitSystem.METRIC) {
        return !metricOption
          ? `${valueToString(!isNil(value) ? value * factor : null, fractionDigits)} ${unit}`
          : `${valueToString(!isNil(value) ? value * 1000 * factor : null, 0)} ${"mm"}`;
      }

      const holeSize = holeSizes?.find((size) => size.value === value);
      return holeSize?.valueFormattedInInches || "";
    },
    [holeSizes, currentUOM],
  );

  return useUOM(DimensionType.Metres, formatHoleSizes);
}

export function useMetresPerTimeUnitUom(timeUnit: TimeUnit) {
  // TODO fix here
  const currentUOM = useAppSelector((state) => state.global.unit);

  const formatMPerTimeUnit: UOMDisplayFunction = useCallback(
    (value, { factor = 1, unit = undefined, fractionDigits = 2 } = {}) => {
      const displayValue = !isNil(value)
        ? value *
          factor *
          timeDistanceUnitConversionMap[timeUnit][currentUOM].factor
        : null;
      return `${valueToString(displayValue, fractionDigits)} ${
        unit === ""
          ? ""
          : timeDistanceUnitConversionMap[timeUnit][currentUOM].unit
      }`;
    },
    [currentUOM, timeUnit],
  );

  const uom = useUOM(DimensionType.MetresPerSecond, formatMPerTimeUnit);

  const timeUom = useMemo<UOMHelper>(
    () => ({
      ...uom,
      abbr: timeDistanceUnitConversionMap[timeUnit][currentUOM].unit,
      displayWithAutoDecimals: (domain, value, opts) => {
        if (!Number.isFinite(value))
          return uom.displayWithAutoDecimals(domain, value, opts);

        const newDomain = domain.map(
          (v) =>
            (v * timeDistanceUnitConversionMap[timeUnit][currentUOM].factor) /
            uom.fromSI(1),
        );
        return uom.displayWithAutoDecimals(newDomain, value, {
          ...opts,
          factor:
            timeDistanceUnitConversionMap[timeUnit][currentUOM].factor /
            uom.fromSI(1),
          unit:
            opts?.unit ??
            timeDistanceUnitConversionMap[timeUnit][currentUOM].unit,
        });
      },
      toSI: (value: number) =>
        value / timeDistanceUnitConversionMap[timeUnit][currentUOM].factor,
      fromSI: (value: number) => {
        return (
          value * timeDistanceUnitConversionMap[timeUnit][currentUOM].factor
        );
      },
    }),
    [currentUOM, timeUnit, uom],
  );

  return timeUom;
}

export function useUOMbyLens(
  dimension: DimensionType | undefined,
  lens: UserLensDto,
) {
  const defaultUom = useUOM(dimension);
  const distanceUom = useUOM(DimensionType.Metres);
  const dayUom = useTimeUom(
    (lens as DrillingProductivityUserLensDto).selectedTimeUnit ?? TimeUnit.Day,
  );
  const drillingUom = useMetresPerTimeUnitUom(
    (lens as DrillingProductivityUserLensDto).selectedTimeUnit ?? TimeUnit.Day,
  );
  const timeUnitUom = useMetresPerTimeUnitUom(
    (lens as KpiGroupUserLensDto)?.selectedTimeUnit ?? TimeUnit.Day,
  );

  if ("drillingProductivityType" in lens) {
    if (lens.drillingProductivityType === DrillingProductivityType.DrillingRate)
      return drillingUom;
    if (
      lens.drillingProductivityType ===
      DrillingProductivityType.DrillingDistance
    )
      return distanceUom;

    return dayUom;
  }

  if (dimension !== DimensionType.MetresPerSecond) {
    return defaultUom;
  }

  if (
    (lens as KpiGroupUserLensDto)?.selectedTimeUnit &&
    (lens as KpiGroupUserLensDto)?.selectedTimeUnit !== TimeUnit.Unknown
  ) {
    return timeUnitUom;
  }

  return defaultUom;
}

export const getDecimalsInterval = (domain: number[]) => {
  return Math.abs(domain[1] - domain[0]);
};

export const adjustDecimalPlaces = (
  value: number | string | null,
  interval: number,
) => {
  const parsedFloat = parseFloat(value + "");
  if (parsedFloat) {
    return parsedFloat.toFixed(getFractionDigits(interval));
  } else {
    return "";
  }
};
