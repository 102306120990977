import { WellOverviewTvD } from "components/TvDChart/products/WellOverviewTvD";
import type { ITimelineInfo } from "pages/WellDashboard/types";

export enum ChartEnum {
  "SnowFlake",
  "Evergreen",
}

export const TvDChart = ({
  isReport,
  timelineOverride,
  setTimelineOverride,
}: {
  isReport: boolean;
  timelineOverride: ITimelineInfo | null;
  setTimelineOverride: React.Dispatch<ITimelineInfo | null> | null;
}) => (
  <WellOverviewTvD
    isReport={isReport}
    timelineOverride={timelineOverride}
    setTimelineOverride={setTimelineOverride}
  />
);
