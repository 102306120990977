import type {
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type {
  DashboardDto,
  DashboardType,
  LensTabWithVisibilityDto,
} from "apis/oag";
import { DashboardsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUserLensTabsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new DashboardsApi(apiConfig);

export function useUserLensTabs(
  dashboardType: DashboardType,
  options?: Omit<UseSuspenseQueryOptions<DashboardDto>, "queryKey" | "queryFn">,
) {
  const request = useUserDashboard(dashboardType, options);
  const { data } = request;
  return {
    ...request,
    data: [...(data?.operatorTabs ?? []), ...(data?.personalTabs ?? [])],
  } as UseSuspenseQueryResult<LensTabWithVisibilityDto[], Error>;
}

export function useUserDashboard(
  dashboardType: DashboardType,
  options?: Omit<UseSuspenseQueryOptions<DashboardDto>, "queryKey" | "queryFn">,
) {
  const queryKey: PDUserLensTabsQueryKey = {
    type: PDQueryType.USER_LENS_TABS,
    uid: RequestUID.userLensTabs,
    dashboardType,
  };

  return useSuspenseQuery({
    queryKey: [queryKey],
    queryFn: () =>
      api.apiDashboardsTypeGet({
        type: dashboardType,
      }),
    ...options,
  });
}
