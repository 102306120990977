import { useMutation } from "@tanstack/react-query";
import type {
  DisplayOption,
  LensTabWithVisibilityDto,
  UserLensPositionDto,
} from "apis/oag";
import {
  DashboardBlockSize,
  GenericUserLensesApi,
  LensTemplateType,
} from "apis/oag";
import { Button } from "atoms/Form";
import { Text } from "atoms/Typography";
import {
  getLensGridBounds,
  GRID_HEIGHT_HALF,
  GRID_HEIGHT_QUARTER,
  GRID_HEIGHT_THREE_QUARTERs,
  GRID_WIDTH_FACTOR,
  GRID_WIDTH_HALF,
  GRID_WIDTH_THREE_QUARTERS,
} from "components/Lenses/constants";
import {
  GRID_WIDTH_FULL,
  GRID_WIDTH_QUARTER,
  HEIGHT_GRID_UNIT,
} from "components/Lenses/constants";
import type { ICrtLayout } from "components/Lenses/utils";
import {
  getSVGNormalizedValue,
  LensGridContext,
} from "components/Lenses/utils";
import { Loader } from "components/Loader";
import { MiniLens } from "components/MiniLens/MiniLens";
import { PDComponent } from "components/PDComponents";
import { useAllLensGridPosition } from "hooks/lens/useLensGridPosition";
import type { LensTemplatesResult } from "hooks/lens/useLensTemplates";
import { useLensTemplates } from "hooks/lens/useLensTemplates";
import type { UserLensesQueryResult } from "hooks/lens/useUserLenses";
import { throttle } from "lodash";
import type { Dict } from "mixpanel-browser";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import type { Layout, Layouts } from "react-grid-layout";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { useResizeDetector } from "react-resize-detector";
import { useAppDispatch } from "reducers/store";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { Col, Row } from "utils/componentLibrary";
import { PdCol } from "utils/componentLibrary/Col";
import { PdRow } from "utils/componentLibrary/Row";
import { useCustomTheme } from "utils/useTheme";

interface LensGridProps {
  selectedTab?: LensTabWithVisibilityDto | null;
  focalWellColor: string;
  isComparing: boolean;
  displayOption: DisplayOption | null;
  lenses: UserLensesQueryResult;
  templates: LensTemplatesResult;
  trackingName?: string;
  trackingProps?: Dict;
  addLens?: () => void;
}

// We are always going to have full width for now; AKA 16 columns 69f5edfe5787d07ecfa34613a3f2e0897b2209b3
// PR: https://dev.azure.com/precisiondrilling/Alpha%20Analytics%20and%20Insights%20Portal/_git/AAIP%20Web/pullrequest/5294
const PixelBreakpoints = {
  lg: 4, // settings breakpoints so small is a fast way to always force the current size to be "lg"
  md: 3,
  sm: 2,
  xxs: 1,
};

const dashboardScreenWidth = {
  lg: DashboardBlockSize.FullWidth,
  md: DashboardBlockSize.Large,
  sm: DashboardBlockSize.Medium,
  xxs: DashboardBlockSize.Small,
};

export const BP_MAX_COLS = {
  lg: GRID_WIDTH_FULL,
  md: GRID_WIDTH_THREE_QUARTERS,
  sm: GRID_WIDTH_HALF,
  xxs: GRID_WIDTH_QUARTER,
};

const StyledContainer = styled(Row)`
  justify-content: center;
  align-items: center;
  height: 20vh;

  ${PdCol} {
    width: 450px;
    border: solid 1px ${colors.widget_line};

    ${PdRow} {
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 20px 24px 24px;
      align-items: center;
      justify-content: space-between;

      .ant-typography {
        width: 250px;
        font-size: 16px !important;
      }
    }
  }
`;

export const LENS_POSITION_BOTTOM = -1;

export function LensList({
  selectedTab,
  focalWellColor,
  isComparing,
  displayOption,
  lenses: lensesDtos,
  templates,
  addLens,
}: LensGridProps) {
  const dispatch = useAppDispatch();
  const genericUserLensApi = new GenericUserLensesApi(apiConfig);

  const [bp, setBp] = useState<keyof ICrtLayout>("lg");
  const [crtLayout, setCrtLayout] = useState<ICrtLayout | null>(null);

  const setBpState = useCallback(
    (newBp: keyof ICrtLayout) => {
      if (bp !== newBp) {
        setBp(newBp);
      }
    },
    [bp],
  );
  const [deletedElements, setDeletedElements] = useState<Array<number>>([]);

  const { width: chartWidthHook, ref: containerRef } = useResizeDetector();
  const { chartWidth: containerWidth } = {
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  const { data: lensTemplates } = useLensTemplates({ refetchOnMount: false });
  const {
    query: {
      data: serverDashboardsBySize,
      refetch: refetchPositions,
      isFetching: isFetchingPositions,
    },
    setQueryPositionData,
  } = useAllLensGridPosition();

  const initialLensPositionsForCurrentTab = useMemo(() => {
    const dashBoardBySize: Record<DashboardBlockSize, UserLensPositionDto[]> = {
      [DashboardBlockSize.FullWidth]: [],
      [DashboardBlockSize.Large]: [],
      [DashboardBlockSize.Medium]: [],
      [DashboardBlockSize.Small]: [],
      [DashboardBlockSize.Unknown]: [],
    };

    if (
      !selectedTab ||
      !lensesDtos.byTab[selectedTab.id] ||
      lensesDtos.byTab[selectedTab.id].length === 0
    ) {
      return dashBoardBySize;
    }

    // Putting each size from remote to its own corresponding grid-specific screen size
    (Object.keys(dashBoardBySize) as DashboardBlockSize[]).forEach((size) => {
      const positionsForThisSize = (serverDashboardsBySize || [])?.find(
        (d) => d.dashboardWidth === size,
      )?.positions;

      if (positionsForThisSize && positionsForThisSize.length > 0) {
        // Only consider lens positions for those items that are actually displayed, ignoring missing ones
        const filteredPositions = positionsForThisSize.filter((position) =>
          lensesDtos.byTab[selectedTab.id]
            .map((lensDto) => lensDto.id)
            .includes(position.lensId),
        );

        dashBoardBySize[size].push(...filteredPositions);
      }
    });

    dashBoardBySize[DashboardBlockSize.FullWidth].forEach(
      (lensPositionInBiggest) => {
        // This fills in lens positions that are empty or not returned by the server ( typically for smaller sizes )
        // Missing positional values for lens will be replaced with the biggest one, until we get an explicit layout set from the user
        // Hard Assumption here is the biggest "FullWidth" position will always be populated with size info

        (Object.keys(dashBoardBySize) as DashboardBlockSize[]).forEach(
          (dashSize) => {
            if (
              dashSize !== DashboardBlockSize.Unknown &&
              dashSize !== DashboardBlockSize.FullWidth
            ) {
              // If the top level lens is not present in smaller sizes, we add it
              if (
                !dashBoardBySize[dashSize].find(
                  (someLens) =>
                    someLens.lensId === lensPositionInBiggest.lensId,
                )
              ) {
                dashBoardBySize[dashSize].push(lensPositionInBiggest);
              }
            }
          },
        );
      },
    );

    return dashBoardBySize;
  }, [lensesDtos.byTab, selectedTab, serverDashboardsBySize]);

  const staleLensDto = useMemo(() => {
    if (!selectedTab) return null;

    // This is for rendering each lens, but only after making sure they have the size information available
    if (!selectedTab || !lensesDtos?.byTab || !lensesDtos.byTab[selectedTab.id])
      return null;
    const lensWithSizingInformation = initialLensPositionsForCurrentTab[
      DashboardBlockSize.FullWidth
    ].map((lens) => lens.lensId);
    return lensesDtos.byTab[selectedTab.id].filter((lensDto) =>
      lensWithSizingInformation.includes(lensDto.id),
    );
  }, [lensesDtos.byTab, selectedTab, initialLensPositionsForCurrentTab]);

  useLayoutEffect(() => {
    if (containerWidth) {
      if (containerWidth >= PixelBreakpoints.lg) setBpState("lg");
      else if (containerWidth >= PixelBreakpoints.md) setBpState("md");
      else if (containerWidth >= PixelBreakpoints.sm) setBpState("sm");
      else setBpState("xxs");
      dispatch({
        type: "SET_LENS_CONTAINER_WIDTH",
        payload: { width: containerWidth },
      });
    }
  }, [containerWidth, dispatch, setBpState]);

  useEffect(() => {
    function generateLensLayout(
      maxLayoutGrid: number,
      layoutLenses: UserLensPositionDto[],
    ) {
      if (!layoutLenses || !layoutLenses.length) return [];

      const layoutLens = layoutLenses.map((lens) => {
        const templateId = lensesDtos.list?.find(
          (lensDto) => lens.lensId === lensDto.id,
        )?.lensTemplateId;
        const templateType = templateId
          ? lensTemplates?.byId[templateId]?.type
          : null;
        const hasPosition =
          Number.isFinite(lens.position) &&
          lens.position !== LENS_POSITION_BOTTOM;
        return {
          y: hasPosition ? Math.floor(lens.position / maxLayoutGrid) : Infinity,
          x: hasPosition ? lens.position % maxLayoutGrid : 0,
          w: lens.width || 1,
          h: lens.height || 1,
          i: `${lens.lensId}`,
          isBounded: true,
          ...(templateType ? getLensGridBounds(templateType) : {}),
        };
      });

      return layoutLens;
    }

    const newLayout = {
      lg: generateLensLayout(
        BP_MAX_COLS["lg"],
        initialLensPositionsForCurrentTab.FullWidth,
      ),
      md: generateLensLayout(
        BP_MAX_COLS["md"],
        initialLensPositionsForCurrentTab.Large,
      ),
      sm: generateLensLayout(
        BP_MAX_COLS["sm"],
        initialLensPositionsForCurrentTab.Medium,
      ),
      xxs: generateLensLayout(
        BP_MAX_COLS["xxs"],
        initialLensPositionsForCurrentTab.Small,
      ),
    };

    setCrtLayout(newLayout);
  }, [initialLensPositionsForCurrentTab, lensTemplates?.byId, lensesDtos.list]);

  const updatePositionMutation = useMutation({
    mutationFn: ({
      lensPositions,
      dashboardWidth,
    }: {
      lensPositions: UserLensPositionDto[];
      dashboardWidth: DashboardBlockSize;
    }) => {
      setQueryPositionData(lensPositions, dashboardWidth);
      return genericUserLensApi.apiGenericUserLensesPositionsPut({
        userLensPositionSetDto: {
          dashboardWidth,
          positions: lensPositions,
        },
      });
    },
    ...{
      onError: () => {
        refetchPositions();
      },
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledUpdatePosition = useCallback(
    throttle(
      (lens: UserLensPositionDto[], dashboardScreenWidth: DashboardBlockSize) =>
        updatePositionMutation.mutate({
          lensPositions: lens,
          dashboardWidth: dashboardScreenWidth,
        }),
      25,
      {
        trailing: true,
        leading: false,
      },
    ),
    [],
  );

  function layoutSignature(layout: Layout[]) {
    // To compare layout changes and see if it's necessary to update, a slower alternative would be deepEqual
    return layout.reduce((hash, lens) => {
      hash += `${lens.i}${lens.h}${lens.w}${lens.x}${lens.y}`;
      return hash;
    }, "");
  }

  const handleOnLayoutChange = useCallback(
    (rawLayout: Layout[]) => {
      setCrtLayout((crtLayout) => {
        if (containerWidth === 0) return crtLayout;
        const incomingLayout = rawLayout || [];
        const existingLayout = crtLayout ? crtLayout[bp] || [] : [];

        // If there are no interesting changes, skip this update
        if (layoutSignature(incomingLayout) === layoutSignature(existingLayout))
          return crtLayout;

        const incomingLensThatChanged = incomingLayout.filter((lens) =>
          initialLensPositionsForCurrentTab[dashboardScreenWidth[bp]]
            .map((lens) => lens.lensId)
            .includes(+lens.i),
        );

        const updatedLens: UserLensPositionDto[] = incomingLensThatChanged
          .filter((lens) => !(lens.h === 1 && lens.w === 1)) // TODO need to check why lens load before positions
          .map((lens) => {
            return {
              lensId: Number(lens.i),
              position: BP_MAX_COLS[bp] * lens.y + lens.x,
              height: lens.h,
              width: lens.w,
              templateId: 0, // TODO remove this as soon as possible
            };
          });

        if (updatedLens.length > 0) {
          throttledUpdatePosition(updatedLens, dashboardScreenWidth[bp]);
        }
        return { ...crtLayout, [bp]: incomingLayout };
      });
    },
    [
      bp,
      containerWidth,
      initialLensPositionsForCurrentTab,
      throttledUpdatePosition,
    ],
  );

  const getLensName = useCallback(
    (lensId: number = 0) => {
      const lens = (lensesDtos?.list ?? [])?.find(
        (lensDto) => lensDto.id === lensId,
      );
      if (lens && templates?.byId) {
        return (
          templates?.byId?.[lens?.id]?.name ||
          templates?.byId?.[lens?.lensTemplateId]?.name
        );
      }

      return "";
    },
    [lensesDtos?.list, templates?.byId],
  );

  const handleOnResizeStop = useCallback(
    (layout: Layout[], element: Layout) => {
      handleOnLayoutChange(layout);
      Track.interact("Change Lens Layout", {
        "Lens Name": getLensName(+element.i),
        "Interaction Type": "Resize",
      });
    },
    [handleOnLayoutChange, getLensName],
  );

  const handleOnDragStop = useCallback(
    (layout: Layout[], element: Layout) => {
      handleOnLayoutChange(layout);
      Track.interact("Change Lens Layout", {
        "Lens Name": getLensName(+element.i),
        "Interaction Type": "Drag",
      });
    },
    [handleOnLayoutChange, getLensName],
  );

  const handleOnResize = useCallback(
    (
      _layout: Layout[],
      _oldLayoutItem: Layout,
      layoutItem: Layout,
      placeholder: Layout,
    ) => {
      if (!layoutItem) return;
      const template =
        templates.byId[lensesDtos?.byId[+layoutItem.i].lensTemplateId];
      if (template.type === LensTemplateType.GeneratorStatusBar) {
        if (
          layoutItem.w >= GRID_WIDTH_FULL &&
          layoutItem.h >= GRID_HEIGHT_THREE_QUARTERs
        ) {
          layoutItem.w = GRID_WIDTH_FULL;
          placeholder.w = GRID_WIDTH_FULL;
          layoutItem.h = GRID_HEIGHT_THREE_QUARTERs;
          placeholder.h = GRID_HEIGHT_THREE_QUARTERs;
        } else if (layoutItem.w <= GRID_WIDTH_FACTOR && layoutItem.h <= 1) {
          layoutItem.h = 1;
          placeholder.h = 1;
          layoutItem.w = GRID_WIDTH_FACTOR;
          placeholder.w = GRID_WIDTH_FACTOR;
        }
        return;
      }
      if (template.type === LensTemplateType.WellDrillingSummary) {
        if (
          layoutItem.w === GRID_WIDTH_QUARTER &&
          layoutItem.h > GRID_HEIGHT_QUARTER
        ) {
          layoutItem.h = GRID_HEIGHT_HALF;
          placeholder.h = GRID_HEIGHT_HALF;
        } else if (
          layoutItem.w > GRID_WIDTH_QUARTER &&
          layoutItem.h > GRID_HEIGHT_QUARTER
        ) {
          layoutItem.h = GRID_HEIGHT_QUARTER;
          placeholder.h = GRID_HEIGHT_QUARTER;
        } else if (layoutItem.w >= GRID_WIDTH_FULL) {
          layoutItem.w = GRID_WIDTH_FULL;
          placeholder.w = GRID_WIDTH_FULL;
        }
      }
    },
    [lensesDtos?.byId, templates.byId],
  );
  const gridContextValue = useMemo(
    () => ({
      crtLayout,
      bp,
      setDeletedElements,
      dashboardScreenWidth: dashboardScreenWidth[bp],
    }),
    [bp, crtLayout, setDeletedElements],
  );

  const { isDark } = useCustomTheme();

  if (!staleLensDto?.length && isFetchingPositions) {
    return <Loader withWrapper />;
  } else if (!staleLensDto?.length) {
    return (
      <StyledContainer>
        <Col>
          <Row>
            <Text primary="description" variant="faded">
              {selectedTab?.description ??
                "No lenses were found on this tab. Click the Add Lens button to get this tab populated."}
            </Text>
            <Button
              size="large"
              $secondary
              trackingName="Add New Lens"
              onClick={addLens}
              icon={<PDComponent.SvgIcon name="addLensLogo" />}
              disabled={selectedTab?.isLocked}
            >
              Add Lens
            </Button>
          </Row>
        </Col>
      </StyledContainer>
    );
  }

  return (
    <LensGridContext.Provider value={gridContextValue}>
      <div
        ref={containerRef}
        style={{
          paddingBottom: 300,
        }}
      >
        {containerWidth > 0 && crtLayout ? (
          <ResponsiveGridLayout
            className="layout"
            margin={[3, 3]}
            useCSSTransforms
            layouts={crtLayout as Layouts}
            width={containerWidth}
            style={{ width: containerWidth }}
            autoSize
            containerPadding={[0, 3]}
            breakpoint={bp}
            breakpoints={PixelBreakpoints}
            onDragStop={handleOnDragStop}
            onResizeStop={handleOnResizeStop}
            onResize={handleOnResize}
            rowHeight={HEIGHT_GRID_UNIT}
            cols={BP_MAX_COLS}
            isDraggable={!selectedTab?.isLocked}
            isResizable={!selectedTab?.isLocked}
            resizeHandle={
              selectedTab?.isLocked ? null : (
                <div
                  style={{
                    position: "absolute",
                    padding: 0,
                    right: 4,
                    bottom: 4,
                    cursor: "grab",
                    color: colors.well_color,
                  }}
                >
                  <PDComponent.SvgIcon
                    name="dragToResize"
                    width="24px"
                    height="24px"
                  />
                </div>
              )
            }
            draggableHandle=".grid-item__drag_handler"
          >
            {staleLensDto
              .filter((e) => !deletedElements.includes(e.id))
              .map((item) => {
                return selectedTab ? (
                  <div key={item.id?.toString?.()}>
                    <MiniLens
                      graphKey={item.id.toString()}
                      item={item}
                      isComparing={isComparing}
                      selectedTab={selectedTab}
                      focalWellColor={focalWellColor}
                      displayOption={displayOption}
                    />
                  </div>
                ) : null;
              })}
          </ResponsiveGridLayout>
        ) : null}
      </div>
    </LensGridContext.Provider>
  );
}
