/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TagBottomFingerprintStandSeriesDto } from './TagBottomFingerprintStandSeriesDto';
import {
    TagBottomFingerprintStandSeriesDtoFromJSON,
    TagBottomFingerprintStandSeriesDtoFromJSONTyped,
    TagBottomFingerprintStandSeriesDtoToJSON,
} from './TagBottomFingerprintStandSeriesDto';
import type { TagBottomFingerprintOverallFactDto } from './TagBottomFingerprintOverallFactDto';
import {
    TagBottomFingerprintOverallFactDtoFromJSON,
    TagBottomFingerprintOverallFactDtoFromJSONTyped,
    TagBottomFingerprintOverallFactDtoToJSON,
} from './TagBottomFingerprintOverallFactDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';

/**
 * 
 * @export
 * @interface TagBottomFingerprintTrackDetailsDto
 */
export interface TagBottomFingerprintTrackDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintTrackDetailsDto
     */
    trackId: number;
    /**
     * 
     * @type {Array<TagBottomFingerprintStandSeriesDto>}
     * @memberof TagBottomFingerprintTrackDetailsDto
     */
    standSeries: Array<TagBottomFingerprintStandSeriesDto>;
    /**
     * 
     * @type {Array<TagBottomFingerprintOverallFactDto>}
     * @memberof TagBottomFingerprintTrackDetailsDto
     */
    overallFacts: Array<TagBottomFingerprintOverallFactDto>;
    /**
     * 
     * @type {ResultDataState}
     * @memberof TagBottomFingerprintTrackDetailsDto
     */
    readonly dataState: ResultDataState;
}



/**
 * Check if a given object implements the TagBottomFingerprintTrackDetailsDto interface.
 */
export function instanceOfTagBottomFingerprintTrackDetailsDto(value: object): value is TagBottomFingerprintTrackDetailsDto {
    if (!('trackId' in value) || value['trackId'] === undefined) return false;
    if (!('standSeries' in value) || value['standSeries'] === undefined) return false;
    if (!('overallFacts' in value) || value['overallFacts'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function TagBottomFingerprintTrackDetailsDtoFromJSON(json: any): TagBottomFingerprintTrackDetailsDto {
    return TagBottomFingerprintTrackDetailsDtoFromJSONTyped(json, false);
}

export function TagBottomFingerprintTrackDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagBottomFingerprintTrackDetailsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'trackId': json['TrackId'],
        'standSeries': ((json['StandSeries'] as Array<any>).map(TagBottomFingerprintStandSeriesDtoFromJSON)),
        'overallFacts': ((json['OverallFacts'] as Array<any>).map(TagBottomFingerprintOverallFactDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function TagBottomFingerprintTrackDetailsDtoToJSON(value?: Omit<TagBottomFingerprintTrackDetailsDto, 'DataState'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'TrackId': value['trackId'],
        'StandSeries': ((value['standSeries'] as Array<any>).map(TagBottomFingerprintStandSeriesDtoToJSON)),
        'OverallFacts': ((value['overallFacts'] as Array<any>).map(TagBottomFingerprintOverallFactDtoToJSON)),
    };
}

