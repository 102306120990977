import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  ApiWellDrillingSummaryUserLensesIdSingleFactsPutRequest,
  WellDrillingSummaryLensResultDto,
} from "apis/oag";
import { WellDrillingSummaryUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls/index";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useAppDispatch, useAppSelector } from "reducers/store";
import {
  type IFiltersType,
  initialFilters,
  type IZoomData,
} from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, TVD_SAMPLE_COUNT } from "utils/common";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const wellSummaryApi = new WellDrillingSummaryUserLensesApi(apiConfig);

export function useWellDrillingSummaryFacts(
  lensId: number,
  options?: UseQueryOptions<WellDrillingSummaryLensResultDto>,
) {
  const [
    {
      sections: sectionIds,
      operationTime,
      directionalIntervals: directionalIntervalIds,
      holeSizes: holeSizeIds,
      phases: phaseIds,
    },
  ] = useStateQuery<IFiltersType>(
    URL_STATE_PARAM.FILTERS_WIDGET,
    initialFilters,
  );
  const [zoomState] = useStateQuery<IZoomData>(
    URL_STATE_PARAM.ZOOM_WIDGET,
    initialZoomData,
  );
  const selectedWell = useAppSelector((state) => state.state.selectedWell);
  const dispatch = useAppDispatch();

  const {
    ts_start: zoomStartDuration,
    ts_end: zoomEndDuration,
    depth_start: zoomStartDepth,
    depth_end: zoomEndDepth,
  } = zoomState;
  const wellDataID = useSelectedWell();

  const requestParameters: ApiWellDrillingSummaryUserLensesIdSingleFactsPutRequest =
    {
      id: lensId ?? -1,
      baseFocalQueryDto: {
        selectedFilters: {
          sectionIds,
          directionalIntervalIds,
          holeSizeIds,
          phaseIds,
          includeFlatTime:
            operationTime === null || operationTime === undefined
              ? true
              : operationTime.includes("Flat Time"),
          includeSlidingTime:
            operationTime === null || operationTime === undefined
              ? true
              : operationTime.includes("Sliding Time"),
          includeRotatingTime:
            operationTime === null || operationTime === undefined
              ? true
              : operationTime.includes("Rotating Time"),
          includeNullHoleDepth: true,
          includeAlphaRigs: true,
          includeNonAlphaRigs: true,
        },
        zoomStartDuration,
        zoomEndDuration,
        zoomStartDepth,
        zoomEndDepth,
        wellId: wellDataID,
        useMockData: false,
        sampleCount: TVD_SAMPLE_COUNT,
        comparisonWellIds: null,
        from: defaultDateDto.from,
        to: defaultDateDto.to,
      },
    };

  const queryKey: PDLensFactsQueryKey<ApiWellDrillingSummaryUserLensesIdSingleFactsPutRequest> =
    {
      type: PDQueryType.FACTS,
      uid: RequestUID.wellDrillingSummaryFacts,
      params: requestParameters,
      lensId,
    };

  return useQuery<WellDrillingSummaryLensResultDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      wellSummaryApi
        .apiWellDrillingSummaryUserLensesIdSingleFactsPut(requestParameters, {
          signal,
        })
        .then((data) => {
          if (data?.lastUpdatedAt && selectedWell) {
            dispatch({
              type: "SET_RIG_STATE_REFRESH_DATE",
              payload: { [selectedWell]: data?.lastUpdatedAt },
            });
          }
          return data;
        }),
    ...options,
  });
}
