import { useSuspenseQuery } from "@tanstack/react-query";
import { DashboardType, GenericUserLensesApi } from "apis/oag";
import { useUserLensTabs } from "hooks/dashboard/useUserLensTabs";
import { apiConfig } from "utils/apiConfig";
import { RequestUID } from "utils/queryNamespaces";

const api = new GenericUserLensesApi(apiConfig);

export const useIntelLens = () => {
  const { data: allLensTabs, isLoading: isLoadingLensTabs } = useUserLensTabs(
    DashboardType.IntelAnalysis,
  );
  return useSuspenseQuery({
    queryKey: [{ uid: RequestUID.intelLenses }],
    queryFn: async () => {
      if (isLoadingLensTabs) return [];
      const data = await api.apiGenericUserLensesPut({
        dashboardQueryDto: {
          dashboardTypes: [DashboardType.IntelAnalysis],
        },
      });

      return [
        data.intelScatterPlotUserLenses.find((x) =>
          allLensTabs.some((tab) => tab.id === x.lensTabId),
        ),
        data.intelRankingRibbonUserLenses.find((x) =>
          allLensTabs.some((tab) => tab.id === x.lensTabId),
        ),
        data.intelScorecardUserLenses.find((x) =>
          allLensTabs.some((tab) => tab.id === x.lensTabId),
        ),
      ] as const;
    },
  });
};
