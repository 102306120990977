import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import type { IOption } from "components/PDComponents/Search/utils";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import { useOperators } from "hooks/drillingInvariants/useOperators";
import {
  URL_STATE_PARAM,
  useBulkQuerySet,
  useStateQuery,
} from "hooks/navigation/useQueryState";
import { useRigsActiveOperators } from "hooks/rigs/useRigsActiveOperators";
import { useRigToWellsSuspended } from "hooks/rigs/useRigToWells";
import { useSelectedRig } from "hooks/rigs/useSelectedRig";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import { useCallback, useMemo, useState } from "react";
import { PrimarySelectorEnum } from "reducers/rigDashboardReducer";
import { Track } from "services/Mixpanel";
import { Popover, Tooltip } from "utils/componentLibrary";

export default function OperatorSelector({
  urlStateParam = URL_STATE_PARAM.SELECTED_OPERATORS_RIGS,
  isFilteringIgnored,
}: {
  urlStateParam?: string;
  isFilteringIgnored?: boolean;
}) {
  const selectedRig = useSelectedRig();
  const { isEvergreen } = useDashboardType();
  const { data: wellData } = useWellShortInfoSuspended();
  const { data: availableWellsRig } = useRigToWellsSuspended({
    isFilteringIgnored,
  });
  const [offsetRigs] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET,
    [],
  );
  const { data: operatorIds } = useRigsActiveOperators(
    [selectedRig, ...offsetRigs],
    isEvergreen,
    {
      enabled: !!selectedRig,
    },
  );

  const [primarySelector] = useStateQuery<PrimarySelectorEnum>(
    URL_STATE_PARAM.PRIMARY_SELECTOR_RIGS,
    PrimarySelectorEnum.NONE,
  );

  const [selectedWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.SELECTED_WELLS_RIG_DASHBOARD,
    [],
  );
  const [operatorsState] = useStateQuery<Array<number> | null>(
    urlStateParam,
    null,
  );
  const [setBulkState] = useBulkQuerySet();

  const { data: operators, isLoading } = useOperators();
  const [showOperatorPopup, setShowOperatorPopup] = useState(false);
  const operatorOptions = useMemo(() => {
    const list = operatorIds;

    const set = new Set(list ?? []);
    const availableWells = (wellData?.list ?? []).filter((e) =>
      (availableWellsRig ?? []).includes(e.id),
    );
    if (operators?.byId) {
      if (
        availableWells?.length !== selectedWells?.length &&
        primarySelector === PrimarySelectorEnum.WELL
      ) {
        const operatorsState = Array.from(set).filter(
          (operatorId) =>
            availableWells.findIndex(
              (well) =>
                selectedWells.includes(well.id) &&
                well.operatorId === operatorId,
            ) !== -1,
        );
        setBulkState([
          {
            strParam: urlStateParam,
            defaultValue: null,
          },
          {
            strParam: URL_STATE_PARAM.SELECTED_WELLS_RIG_DASHBOARD,
            defaultValue: null,
          },
          {
            strParam: URL_STATE_PARAM.SELECTED_WELLS_RIG_WIDGET,
            defaultValue: null,
          },
        ]);

        return operatorsState.map((operatorId) => operators?.byId[operatorId]);
      }
      return Array.from(set).map((operatorId) => operators?.byId[operatorId]);
    } else return [];
  }, [
    availableWellsRig,
    operatorIds,
    urlStateParam,
    operators?.byId,
    primarySelector,
    selectedWells,
    setBulkState,
    wellData?.list,
  ]);

  const updateOperators = useCallback(
    (operatorIds: number[] | null) => {
      const availableWells = (wellData?.list ?? []).filter((e) =>
        (availableWellsRig ?? []).includes(e.id),
      );
      setBulkState([
        {
          strParam: urlStateParam,
          defaultValue: operatorIds
            ? operatorIds.slice().sort((a, b) => a - b)
            : null,
        },
        {
          strParam: URL_STATE_PARAM.SELECTED_WELLS_RIG_DASHBOARD,
          defaultValue: availableWells
            .filter((well) => operatorIds?.includes(well.operatorId))
            .map((well) => well.id),
        },
        {
          strParam: URL_STATE_PARAM.SELECTED_WELLS_RIG_WIDGET,
          defaultValue: availableWells
            .filter((well) => operatorIds?.includes(well.operatorId))
            .map((well) => well.id),
        },
      ]);
      return true;
    },
    [availableWellsRig, urlStateParam, setBulkState, wellData?.list],
  );

  if (isLoading) return null;

  return (
    <Tooltip title="Operator">
      <Popover
        content={
          <PDComponent.ComboBoxMultiSelect
            placeholder="Search"
            options={operatorOptions as IOption<number>[]}
            values={operatorsState ?? operatorIds ?? []}
            onChange={(e) => {
              Track.interact("Dashboard Rigs - Operator", {
                Action: "Update",
              });
              updateOperators(e);
              setShowOperatorPopup(false);
            }}
          />
        }
        trigger="click"
        placement="bottom"
        open={showOperatorPopup}
        onOpenChange={(e) => setShowOperatorPopup(e)}
        destroyTooltipOnHide
      >
        <Button
          size="large"
          loading={isLoading}
          icon={<PDComponent.SvgIcon name="identification" />}
          type={operatorsState === null ? "default" : "primary"}
          ghost={operatorsState !== null}
          $engaged={showOperatorPopup}
        />
      </Popover>
    </Tooltip>
  );
}
