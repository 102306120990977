/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { GeneratorStatsSummaryDto } from './GeneratorStatsSummaryDto';
import {
    GeneratorStatsSummaryDtoFromJSON,
    GeneratorStatsSummaryDtoFromJSONTyped,
    GeneratorStatsSummaryDtoToJSON,
} from './GeneratorStatsSummaryDto';
import type { GeneratorStatsFactDto } from './GeneratorStatsFactDto';
import {
    GeneratorStatsFactDtoFromJSON,
    GeneratorStatsFactDtoFromJSONTyped,
    GeneratorStatsFactDtoToJSON,
} from './GeneratorStatsFactDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface GeneratorStatsResultDto
 */
export interface GeneratorStatsResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof GeneratorStatsResultDto
     */
    readonly stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof GeneratorStatsResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatsResultDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof GeneratorStatsResultDto
     */
    lastUpdatedAt?: DateDto | null;
    /**
     * 
     * @type {Array<GeneratorStatsFactDto>}
     * @memberof GeneratorStatsResultDto
     */
    facts?: Array<GeneratorStatsFactDto> | null;
    /**
     * 
     * @type {GeneratorStatsSummaryDto}
     * @memberof GeneratorStatsResultDto
     */
    summary: GeneratorStatsSummaryDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof GeneratorStatsResultDto
     */
    readonly dataState: ResultDataState;
}



/**
 * Check if a given object implements the GeneratorStatsResultDto interface.
 */
export function instanceOfGeneratorStatsResultDto(value: object): value is GeneratorStatsResultDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('summary' in value) || value['summary'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function GeneratorStatsResultDtoFromJSON(json: any): GeneratorStatsResultDto {
    return GeneratorStatsResultDtoFromJSONTyped(json, false);
}

export function GeneratorStatsResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratorStatsResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': json['LastUpdatedAt'] == null ? undefined : DateDtoFromJSON(json['LastUpdatedAt']),
        'facts': json['Facts'] == null ? undefined : ((json['Facts'] as Array<any>).map(GeneratorStatsFactDtoFromJSON)),
        'summary': GeneratorStatsSummaryDtoFromJSON(json['Summary']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function GeneratorStatsResultDtoToJSON(value?: Omit<GeneratorStatsResultDto, 'StopWatch'|'DataState'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'Facts': value['facts'] == null ? undefined : ((value['facts'] as Array<any>).map(GeneratorStatsFactDtoToJSON)),
        'Summary': GeneratorStatsSummaryDtoToJSON(value['summary']),
    };
}

