/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClaraAnswerDto,
  ClaraFeedbackDto,
  ClaraPromptDto,
  ClaraQuestionDto,
  ClaraSessionDto,
  LeaderboardFactQueryDto,
  RigCardDataType,
  RigLeaderboardListAco,
  ScorecardKpiCommentDto,
  ScorecardKpiCommentSetAco,
  StandKpiCommentSetAco,
} from '../models/index';
import {
    ClaraAnswerDtoFromJSON,
    ClaraAnswerDtoToJSON,
    ClaraFeedbackDtoFromJSON,
    ClaraFeedbackDtoToJSON,
    ClaraPromptDtoFromJSON,
    ClaraPromptDtoToJSON,
    ClaraQuestionDtoFromJSON,
    ClaraQuestionDtoToJSON,
    ClaraSessionDtoFromJSON,
    ClaraSessionDtoToJSON,
    LeaderboardFactQueryDtoFromJSON,
    LeaderboardFactQueryDtoToJSON,
    RigCardDataTypeFromJSON,
    RigCardDataTypeToJSON,
    RigLeaderboardListAcoFromJSON,
    RigLeaderboardListAcoToJSON,
    ScorecardKpiCommentDtoFromJSON,
    ScorecardKpiCommentDtoToJSON,
    ScorecardKpiCommentSetAcoFromJSON,
    ScorecardKpiCommentSetAcoToJSON,
    StandKpiCommentSetAcoFromJSON,
    StandKpiCommentSetAcoToJSON,
} from '../models/index';

export interface ApiClaraLeaderboardContextScoreTypePutRequest {
    scoreType: RigCardDataType;
    leaderboardFactQueryDto?: LeaderboardFactQueryDto;
}

export interface ApiClaraLeaderboardSessionCreateScoreTypePostRequest {
    scoreType: RigCardDataType;
    leaderboardFactQueryDto?: LeaderboardFactQueryDto;
}

export interface ApiClaraLeaderboardSessionFeedbackPostRequest {
    claraFeedbackDto?: ClaraFeedbackDto;
}

export interface ApiClaraLeaderboardSessionSessionIdMessagePostRequest {
    sessionId: string;
    claraQuestionDto?: ClaraQuestionDto;
}

export interface ApiClaraPromptsFavoriteIdDeleteRequest {
    id: number;
}

export interface ApiClaraPromptsFavoriteIdPositionPositionPutRequest {
    id: number;
    position: number;
}

export interface ApiClaraPromptsFavoriteIdPutRequest {
    id: number;
    claraPromptDto?: ClaraPromptDto;
}

export interface ApiClaraPromptsFavoritePostRequest {
    claraPromptDto?: ClaraPromptDto;
}

export interface ApiClaraScorecardKpiCommentsContextFromDrillerCommentsWellIdLensIdGetRequest {
    lensId: number;
    wellId: number;
}

export interface ApiClaraScorecardKpiCommentsContextFromKpisWellIdUseExceptionsScoreTypeGetRequest {
    wellId: number;
    useExceptions: boolean;
    scoreType: RigCardDataType;
}

export interface ApiClaraScorecardKpiCommentsGenerateLensIdWellIdUseExceptionsScoreTypeGetRequest {
    lensId: number;
    wellId: number;
    useExceptions: boolean;
    scoreType: RigCardDataType;
}

/**
 * 
 */
export class ClaraApi extends runtime.BaseAPI {

    /**
     */
    async apiClaraLeaderboardContextScoreTypePutRaw(requestParameters: ApiClaraLeaderboardContextScoreTypePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RigLeaderboardListAco>> {
        if (requestParameters['scoreType'] == null) {
            throw new runtime.RequiredError(
                'scoreType',
                'Required parameter "scoreType" was null or undefined when calling apiClaraLeaderboardContextScoreTypePut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Clara/Leaderboard/Context/{scoreType}`.replace(`{${"scoreType"}}`, encodeURIComponent(String(requestParameters['scoreType']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardFactQueryDtoToJSON(requestParameters['leaderboardFactQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RigLeaderboardListAcoFromJSON(jsonValue));
    }

    /**
     */
    async apiClaraLeaderboardContextScoreTypePut(requestParameters: ApiClaraLeaderboardContextScoreTypePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RigLeaderboardListAco> {
        const response = await this.apiClaraLeaderboardContextScoreTypePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClaraLeaderboardSessionCreateScoreTypePostRaw(requestParameters: ApiClaraLeaderboardSessionCreateScoreTypePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClaraSessionDto>> {
        if (requestParameters['scoreType'] == null) {
            throw new runtime.RequiredError(
                'scoreType',
                'Required parameter "scoreType" was null or undefined when calling apiClaraLeaderboardSessionCreateScoreTypePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Clara/Leaderboard/Session/Create/{scoreType}`.replace(`{${"scoreType"}}`, encodeURIComponent(String(requestParameters['scoreType']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardFactQueryDtoToJSON(requestParameters['leaderboardFactQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClaraSessionDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiClaraLeaderboardSessionCreateScoreTypePost(requestParameters: ApiClaraLeaderboardSessionCreateScoreTypePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClaraSessionDto> {
        const response = await this.apiClaraLeaderboardSessionCreateScoreTypePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClaraLeaderboardSessionFeedbackPostRaw(requestParameters: ApiClaraLeaderboardSessionFeedbackPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Clara/Leaderboard/Session/Feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClaraFeedbackDtoToJSON(requestParameters['claraFeedbackDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiClaraLeaderboardSessionFeedbackPost(requestParameters: ApiClaraLeaderboardSessionFeedbackPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiClaraLeaderboardSessionFeedbackPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClaraLeaderboardSessionSessionIdMessagePostRaw(requestParameters: ApiClaraLeaderboardSessionSessionIdMessagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClaraAnswerDto>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling apiClaraLeaderboardSessionSessionIdMessagePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Clara/Leaderboard/Session/{sessionId}/Message`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClaraQuestionDtoToJSON(requestParameters['claraQuestionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClaraAnswerDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiClaraLeaderboardSessionSessionIdMessagePost(requestParameters: ApiClaraLeaderboardSessionSessionIdMessagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClaraAnswerDto> {
        const response = await this.apiClaraLeaderboardSessionSessionIdMessagePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClaraPromptsFavoriteIdDeleteRaw(requestParameters: ApiClaraPromptsFavoriteIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiClaraPromptsFavoriteIdDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Clara/Prompts/Favorite/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiClaraPromptsFavoriteIdDelete(requestParameters: ApiClaraPromptsFavoriteIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiClaraPromptsFavoriteIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClaraPromptsFavoriteIdPositionPositionPutRaw(requestParameters: ApiClaraPromptsFavoriteIdPositionPositionPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiClaraPromptsFavoriteIdPositionPositionPut().'
            );
        }

        if (requestParameters['position'] == null) {
            throw new runtime.RequiredError(
                'position',
                'Required parameter "position" was null or undefined when calling apiClaraPromptsFavoriteIdPositionPositionPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Clara/Prompts/Favorite/{id}/Position/{position}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"position"}}`, encodeURIComponent(String(requestParameters['position']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiClaraPromptsFavoriteIdPositionPositionPut(requestParameters: ApiClaraPromptsFavoriteIdPositionPositionPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiClaraPromptsFavoriteIdPositionPositionPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClaraPromptsFavoriteIdPutRaw(requestParameters: ApiClaraPromptsFavoriteIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClaraPromptDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiClaraPromptsFavoriteIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Clara/Prompts/Favorite/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClaraPromptDtoToJSON(requestParameters['claraPromptDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClaraPromptDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiClaraPromptsFavoriteIdPut(requestParameters: ApiClaraPromptsFavoriteIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClaraPromptDto> {
        const response = await this.apiClaraPromptsFavoriteIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClaraPromptsFavoritePostRaw(requestParameters: ApiClaraPromptsFavoritePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClaraPromptDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Clara/Prompts/Favorite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClaraPromptDtoToJSON(requestParameters['claraPromptDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClaraPromptDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiClaraPromptsFavoritePost(requestParameters: ApiClaraPromptsFavoritePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClaraPromptDto> {
        const response = await this.apiClaraPromptsFavoritePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClaraPromptsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClaraPromptDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Clara/Prompts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClaraPromptDtoFromJSON));
    }

    /**
     */
    async apiClaraPromptsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClaraPromptDto>> {
        const response = await this.apiClaraPromptsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClaraScorecardKpiCommentsContextFromDrillerCommentsWellIdLensIdGetRaw(requestParameters: ApiClaraScorecardKpiCommentsContextFromDrillerCommentsWellIdLensIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StandKpiCommentSetAco>> {
        if (requestParameters['lensId'] == null) {
            throw new runtime.RequiredError(
                'lensId',
                'Required parameter "lensId" was null or undefined when calling apiClaraScorecardKpiCommentsContextFromDrillerCommentsWellIdLensIdGet().'
            );
        }

        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling apiClaraScorecardKpiCommentsContextFromDrillerCommentsWellIdLensIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Clara/ScorecardKpiComments/Context/FromDrillerComments/{wellId}/{lensId}`.replace(`{${"lensId"}}`, encodeURIComponent(String(requestParameters['lensId']))).replace(`{${"wellId"}}`, encodeURIComponent(String(requestParameters['wellId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StandKpiCommentSetAcoFromJSON(jsonValue));
    }

    /**
     */
    async apiClaraScorecardKpiCommentsContextFromDrillerCommentsWellIdLensIdGet(requestParameters: ApiClaraScorecardKpiCommentsContextFromDrillerCommentsWellIdLensIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StandKpiCommentSetAco> {
        const response = await this.apiClaraScorecardKpiCommentsContextFromDrillerCommentsWellIdLensIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClaraScorecardKpiCommentsContextFromKpisWellIdUseExceptionsScoreTypeGetRaw(requestParameters: ApiClaraScorecardKpiCommentsContextFromKpisWellIdUseExceptionsScoreTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScorecardKpiCommentSetAco>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling apiClaraScorecardKpiCommentsContextFromKpisWellIdUseExceptionsScoreTypeGet().'
            );
        }

        if (requestParameters['useExceptions'] == null) {
            throw new runtime.RequiredError(
                'useExceptions',
                'Required parameter "useExceptions" was null or undefined when calling apiClaraScorecardKpiCommentsContextFromKpisWellIdUseExceptionsScoreTypeGet().'
            );
        }

        if (requestParameters['scoreType'] == null) {
            throw new runtime.RequiredError(
                'scoreType',
                'Required parameter "scoreType" was null or undefined when calling apiClaraScorecardKpiCommentsContextFromKpisWellIdUseExceptionsScoreTypeGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Clara/ScorecardKpiComments/Context/FromKpis/{wellId}/{useExceptions}/{scoreType}`.replace(`{${"wellId"}}`, encodeURIComponent(String(requestParameters['wellId']))).replace(`{${"useExceptions"}}`, encodeURIComponent(String(requestParameters['useExceptions']))).replace(`{${"scoreType"}}`, encodeURIComponent(String(requestParameters['scoreType']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScorecardKpiCommentSetAcoFromJSON(jsonValue));
    }

    /**
     */
    async apiClaraScorecardKpiCommentsContextFromKpisWellIdUseExceptionsScoreTypeGet(requestParameters: ApiClaraScorecardKpiCommentsContextFromKpisWellIdUseExceptionsScoreTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScorecardKpiCommentSetAco> {
        const response = await this.apiClaraScorecardKpiCommentsContextFromKpisWellIdUseExceptionsScoreTypeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClaraScorecardKpiCommentsGenerateLensIdWellIdUseExceptionsScoreTypeGetRaw(requestParameters: ApiClaraScorecardKpiCommentsGenerateLensIdWellIdUseExceptionsScoreTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScorecardKpiCommentDto>> {
        if (requestParameters['lensId'] == null) {
            throw new runtime.RequiredError(
                'lensId',
                'Required parameter "lensId" was null or undefined when calling apiClaraScorecardKpiCommentsGenerateLensIdWellIdUseExceptionsScoreTypeGet().'
            );
        }

        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling apiClaraScorecardKpiCommentsGenerateLensIdWellIdUseExceptionsScoreTypeGet().'
            );
        }

        if (requestParameters['useExceptions'] == null) {
            throw new runtime.RequiredError(
                'useExceptions',
                'Required parameter "useExceptions" was null or undefined when calling apiClaraScorecardKpiCommentsGenerateLensIdWellIdUseExceptionsScoreTypeGet().'
            );
        }

        if (requestParameters['scoreType'] == null) {
            throw new runtime.RequiredError(
                'scoreType',
                'Required parameter "scoreType" was null or undefined when calling apiClaraScorecardKpiCommentsGenerateLensIdWellIdUseExceptionsScoreTypeGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Clara/ScorecardKpiComments/Generate/{lensId}/{wellId}/{useExceptions}/{scoreType}`.replace(`{${"lensId"}}`, encodeURIComponent(String(requestParameters['lensId']))).replace(`{${"wellId"}}`, encodeURIComponent(String(requestParameters['wellId']))).replace(`{${"useExceptions"}}`, encodeURIComponent(String(requestParameters['useExceptions']))).replace(`{${"scoreType"}}`, encodeURIComponent(String(requestParameters['scoreType']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScorecardKpiCommentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiClaraScorecardKpiCommentsGenerateLensIdWellIdUseExceptionsScoreTypeGet(requestParameters: ApiClaraScorecardKpiCommentsGenerateLensIdWellIdUseExceptionsScoreTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScorecardKpiCommentDto> {
        const response = await this.apiClaraScorecardKpiCommentsGenerateLensIdWellIdUseExceptionsScoreTypeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
