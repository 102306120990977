import { scaleBand, scaleLinear } from "@visx/scale";
import { BarRounded, Circle } from "@visx/shape";
import type {
  RigCardFactDto,
  RigCardFactSeriesDto,
  RigCardFactValue,
  UserLensDto,
} from "apis/oag";
import { DashboardType, ResultDataState, TimeUnit } from "apis/oag";
import { Chart } from "components/Lenses/common/Chart";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { max } from "lodash";
import { GroupTitle } from "pages/RigScoreCard/LeftPane/components/GroupTitle";
import {
  barOpacity,
  getColorByScore,
  SCORECARD_AXIS_HEIGHT,
  SCORECARD_HEIGHT,
  SCORECARD_HORIZONTAL_PADDING,
  SCORECARD_MIN_TITLE_HEIGHT,
  SCORECARD_PADDING,
  useScoreCardTags,
} from "pages/RigScoreCard/LeftPane/utils";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useResizeDetector } from "react-resize-detector";
import { useTimeUom, useUOM, UtilDimensions } from "utils/format";
export const isUndefinedOrNull = (value: unknown) =>
  value === undefined || value === null;

import { AxisBottom } from "@visx/axis";
import { LinearGradient } from "@visx/gradient";
import { Group } from "@visx/group";
import { PatternLines } from "@visx/pattern";
import TargetLine from "components/Lenses/common/TargetLine";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { RigScoreCardCommentsModal } from "components/RigScoreCardCommentsModal";
import { useUserLensTabs } from "hooks/dashboard/useUserLensTabs";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { useSelectedRig } from "hooks/rigs/useSelectedRig";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import { cardKpiTypesMap } from "pages/FleetPerformance/components/utils";
import CommentCircle from "pages/RigScoreCard/LeftPane/components/CommentCircle";
import type { CustomTagProps } from "pages/RigScoreCard/LeftPane/components/CustomTag";
import { KpiInfoPopupContent } from "pages/RigScoreCard/LeftPane/components/KpiInfoPopupContent";
import { NoDataScoreCard } from "pages/RigScoreCard/LeftPane/NoDataScoreCard";
import * as Styled from "pages/RigScoreCard/LeftPane/Styled";
import {
  BenchmarkType,
  useScoreBenchmarkContext,
} from "pages/RigScoreCard/ScoreBenchmarkContext";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";
import { FALLBACK_NUM_TICKS_Y_AXIS } from "utils/constants";
import { useCustomTheme } from "utils/useTheme";

const LOWER_LIMIT = 3;
const UPPER_LIMIT = 5;
export const divisors = (num: number) => {
  if (num === LOWER_LIMIT) return LOWER_LIMIT;
  if (num < LOWER_LIMIT) {
    for (let i = LOWER_LIMIT; i <= UPPER_LIMIT; i++) {
      if ((num * 10) % i === 0) {
        return i;
      }
    }
    return LOWER_LIMIT;
  }
  let max = LOWER_LIMIT;
  for (let i = UPPER_LIMIT; i >= LOWER_LIMIT; i--) {
    if (i === num) return i;
    if (num % i === 0) {
      max = Math.max(i, num / i, max);
    }
  }
  return Math.min(max, UPPER_LIMIT);
};

export type CommentsModalInfoType = {
  d: RigCardFactDto;
  wellNumber: number;
  wellId: number;
  kpiValue: RigCardFactValue;
  tags: CustomTagProps[];
  lensId: number;
  wellName: string;
  targetValue?: number | null;
  baseValue?: number | null;
  value?: number | null;
  exception?: number | null;
  hasDrillerComments?: boolean;
};

const RigScoreCard = memo(
  ({
    rigCardScore,
    showNavigationButton,
    lens,
    getDetailedViewUrl,
  }: {
    rigCardScore: RigCardFactSeriesDto;
    showNavigationButton: boolean;
    lens: UserLensDto;
    getDetailedViewUrl: (wellId: number) => string;
  }) => {
    const defaultUom = useUOM(rigCardScore.dimension);
    const percentageUom = useUOM(UtilDimensions.Percentage);
    const { data } = useKpiTypes();
    const {
      width: chartWidthHook,
      height: chartHeightHook,
      ref: containerRef,
    } = useResizeDetector({
      refreshOptions: { leading: true, trailing: true },
    });
    const { data: wellShortInfo } = useWellShortInfoSuspended({
      staleTime: Infinity,
    });
    const { scoreBenchmark } = useScoreBenchmarkContext();
    const [commentsModalInfo, setCommentsModalInfo] =
      useState<CommentsModalInfoType | null>(null);
    const [hoveredWellId, setHoveredWellId] = useState<number | null>(null);
    const hourUom = useTimeUom(TimeUnit.Hour);
    const dayUom = useTimeUom(TimeUnit.Day);

    const dispatch = useAppDispatch();
    const { data: lensTabs } = useUserLensTabs(DashboardType.RigScorecard, {
      staleTime: Infinity,
    });

    const lensGroupName = useMemo(
      () => lensTabs?.find((tab) => tab.id === lens.lensTabId)?.name ?? "",
      [lens.lensTabId, lensTabs],
    );
    const lensGroupDescription = useMemo(
      () =>
        lensTabs?.find((tab) => tab.id === lens.lensTabId)?.description ?? "",
      [lens.lensTabId, lensTabs],
    );
    const selectedRig = useSelectedRig();
    const { data: rigs } = useRigs({ staleTime: Infinity });

    const rigName = useMemo(() => {
      return rigs?.byId[selectedRig]?.shortName || "";
    }, [rigs?.byId, selectedRig]);

    const chartWidth = getSVGNormalizedValue((chartWidthHook || 0) - 2);

    const chartHeight =
      chartHeightHook && chartHeightHook >= 0 ? chartHeightHook : 0;
    const plotHeight = chartHeight - SCORECARD_AXIS_HEIGHT - SCORECARD_PADDING;
    const plotWidth =
      chartWidth > SCORECARD_HORIZONTAL_PADDING
        ? chartWidth - SCORECARD_HORIZONTAL_PADDING
        : 0;

    const transformedData = useMemo(() => {
      if (!rigCardScore || !lens) return null;
      return rigCardScore.facts ?? null;
    }, [lens, rigCardScore]);

    const uom = useMemo(() => {
      if (rigCardScore.dimension === "Undefined") return percentageUom;
      if (rigCardScore.kpiType === cardKpiTypesMap.LocationRigMoveTime)
        return dayUom;
      if (
        [
          cardKpiTypesMap.WalkSpudTime,
          cardKpiTypesMap.WalkTime,
          cardKpiTypesMap.NippleTimeSurface,
          cardKpiTypesMap.NippleTimeIntermediate,
          cardKpiTypesMap.NippleTimeTotal,
          cardKpiTypesMap.PressureTestTimeIntermediate,
          cardKpiTypesMap.PressureTestTimeSurface,
          cardKpiTypesMap.PressureTestTimeTotal,
          cardKpiTypesMap.SlipCutTime,
        ].includes(rigCardScore.kpiType)
      )
        return hourUom;

      return defaultUom;
    }, [
      rigCardScore.dimension,
      rigCardScore.kpiType,
      percentageUom,
      dayUom,
      hourUom,
      defaultUom,
    ]);

    const valueScale = useMemo(() => {
      const values = !transformedData
        ? []
        : transformedData.map((e) =>
            max([
              (e.kpiValue.value || 0) + (e.kpiValue?.exception || 0),
              e.baseValue,
              scoreBenchmark === BenchmarkType.OperatorTarget
                ? e.targetValue
                : e.pdBenchmarkValue,
            ]),
          );
      dispatch({
        type: "SET_MAX_VALUE_BY_KPI",
        payload: {
          [lens.id]: max(values) || 0,
        },
      });

      // Check if we can extend the values to get nice increments
      return scaleLinear<number>({
        domain: [max(values) || 0, 0],
        range: [0, plotHeight],
      });
    }, [transformedData, dispatch, lens.id, plotHeight, scoreBenchmark]);

    const categoryScale = useMemo(() => {
      return scaleBand<number>({
        domain: !transformedData
          ? []
          : transformedData.map((i) => i.kpiValue.rank).sort((a, b) => b - a),
        range: [0, plotWidth],
        paddingInner: 0.2,
        paddingOuter: 0.5,
      });
    }, [plotWidth, transformedData]);

    const lensName = useMemo(() => {
      const getName = () => {
        try {
          const lens = data?.byId[rigCardScore.kpiType];
          return lens?.shortName || lens?.name || "";
        } catch {
          return "";
        }
      };
      return getName();
    }, [data?.byId, rigCardScore?.kpiType]);

    const { getTagsByAveragesOrFact } = useScoreCardTags({ borderless: true });
    const tags = useMemo(() => {
      const tags = getTagsByAveragesOrFact({
        averages: rigCardScore?.averages,
      });
      return [tags.score, tags.opportunityTime, tags.timeSaved];
    }, [getTagsByAveragesOrFact, rigCardScore?.averages]);

    const getWellTags = useCallback(
      (fact: RigCardFactDto) => {
        const tags = getTagsByAveragesOrFact({ fact });
        return [tags.score, tags.opportunityTime, tags.timeSaved];
      },
      [getTagsByAveragesOrFact],
    );

    const factInfo = useAppSelector((state) => state.rigDashboard.factInfo);

    const { showTooltip, hideTooltip, tooltipElement } =
      useChartTooltip<RigCardFactDto>({
        containerRef,
        renderContent: ({ tooltipData }) => {
          return (
            <>
              {!isUndefinedOrNull(tooltipData?.kpiValue?.exception) ? (
                <TooltipHighlightValue>
                  Adj. {lensName}:{" "}
                  {uom.display(tooltipData?.kpiValue?.value || 0)}
                </TooltipHighlightValue>
              ) : null}
              <TooltipHighlightValue>
                {lensName}:{" "}
                {uom.display(
                  (tooltipData?.kpiValue?.value || 0) +
                    (tooltipData?.kpiValue?.exception || 0),
                )}
              </TooltipHighlightValue>
              {(() => {
                const score =
                  scoreBenchmark === BenchmarkType.OperatorTarget
                    ? tooltipData?.operatorScore?.value
                    : tooltipData?.rigScore?.value;
                return (
                  <span>
                    Score: {score ? percentageUom.display(score) ?? 0 : "-"}
                  </span>
                );
              })()}
              <span>
                Time vs{" "}
                {scoreBenchmark === BenchmarkType.OperatorTarget
                  ? "Target"
                  : "Benchmark"}
                :{" "}
                {(() => {
                  const opTime =
                    scoreBenchmark === BenchmarkType.OperatorTarget
                      ? tooltipData?.targetDeltaTime?.value
                      : tooltipData?.invisibleLostTime?.value;
                  if (!opTime) return `-`;
                  return hourUom.display(opTime);
                })()}
              </span>
              <span>
                Time vs Base:{" "}
                {(() => {
                  const timeSaved = tooltipData?.savedTime?.value;
                  if (!timeSaved) return `-`;
                  return hourUom.display(timeSaved);
                })()}
              </span>
              {(() => {
                const targetValue =
                  scoreBenchmark === BenchmarkType.OperatorTarget
                    ? tooltipData?.targetValue
                    : tooltipData?.pdBenchmarkValue;
                return (
                  <TooltipHighlightValue>
                    Target: {targetValue ? uom.display(targetValue) : "-"}
                  </TooltipHighlightValue>
                );
              })()}
              {(() => {
                const baseValue = tooltipData?.baseValue;
                return (
                  <TooltipHighlightValue>
                    Base Value: {baseValue ? uom.display(baseValue) : "-"}
                  </TooltipHighlightValue>
                );
              })()}
              {tooltipData?.label ? (
                <span>
                  {wellShortInfo?.byId?.[+tooltipData?.label]?.name ?? ""}
                </span>
              ) : null}
              <span>#{tooltipData?.kpiValue?.rank}</span>
            </>
          );
        },
      });
    const getValue = useCallback(
      (d: RigCardFactDto) => {
        if (isUndefinedOrNull(d?.kpiValue?.value)) return null;
        const value = valueScale(d.kpiValue.value || 0);
        return value;
      },
      [valueScale],
    );
    const getValueException = useCallback(
      (d: RigCardFactDto) => {
        if (isUndefinedOrNull(d?.kpiValue?.exception)) return null;
        const value = valueScale(d.kpiValue.exception || 0);
        return value;
      },
      [valueScale],
    );

    const handleMouseOver = useCallback(
      (d: RigCardFactDto) => {
        if (!d) return;
        const leftX =
          (categoryScale(d.kpiValue.rank || 0) || 0) +
          categoryScale.bandwidth() / 2;
        const value = getValue(d);
        const barHeight = Math.min(plotHeight - (value || 0), plotHeight);
        const barHeightException = Math.min(
          plotHeight - (getValueException(d) || plotHeight),
          plotHeight,
        );
        const barYException = plotHeight - barHeightException - barHeight;
        setHoveredWellId(+d.label);
        showTooltip({
          tooltipLeft: leftX,
          tooltipTop: barYException + SCORECARD_PADDING,
          tooltipData: d,
        });
      },
      [categoryScale, showTooltip, getValue, getValueException, plotHeight],
    );
    const {
      themeStyle: { colors: themeColors },
    } = useCustomTheme();

    const navigateToDetailedView = useCallback(
      (d: RigCardFactDto) => {
        if (showNavigationButton) return getDetailedViewUrl(+d.label);
        return "";
      },
      [getDetailedViewUrl, showNavigationButton],
    );

    const kpiLabels = [
      commentsModalInfo?.exception ? (
        <span key={`adj-${lensName}`}>
          <b>Adj. {lensName}</b>: {uom.display(commentsModalInfo?.value || 0)}
        </span>
      ) : null,
      <span key={lensName}>
        <b>{lensName}:</b>{" "}
        {uom.display(
          (commentsModalInfo?.value || 0) + (commentsModalInfo?.exception || 0),
        )}
      </span>,
    ].filter((kpi) => kpi);

    const CommentsModalElement = useMemo(() => {
      return commentsModalInfo ? (
        <RigScoreCardCommentsModal
          kpiType={rigCardScore.kpiType}
          wellNumber={commentsModalInfo.wellNumber}
          rigName={rigName}
          wellId={commentsModalInfo.wellId}
          wellName={commentsModalInfo.wellName || "Unknown Well"}
          kpiName={lensGroupName}
          kpiDescription={lensGroupDescription}
          kpiLabels={kpiLabels}
          unformattedValue={
            (commentsModalInfo.value || 0) + (commentsModalInfo.exception || 0)
          }
          baseValue={uom.display(commentsModalInfo.baseValue)}
          targetValue={uom.display(commentsModalInfo.targetValue)}
          lensId={lens?.id}
          tags={commentsModalInfo.tags}
          navigateToDetailedView={() =>
            navigateToDetailedView(commentsModalInfo.d)
          }
          onClose={() => {
            setCommentsModalInfo(null);
            // TODO invalidate query to resolve item please!
          }}
        />
      ) : null;
    }, [
      commentsModalInfo,
      kpiLabels,
      lens?.id,
      lensGroupDescription,
      lensGroupName,
      navigateToDetailedView,
      rigCardScore.kpiType,
      rigName,
      uom,
    ]);

    const handleOnMouseClick = useCallback(
      (d: RigCardFactDto) => {
        Track.clickEvent("Rig Scorecard - Bar Clicked", {
          "Well Rank": d.kpiValue.rank,
          "Well Id": +d.label,
        });
        const cardTargetValue =
          scoreBenchmark === BenchmarkType.OperatorTarget
            ? d.targetValue
            : d.pdBenchmarkValue;
        setCommentsModalInfo({
          d,
          wellNumber: d.kpiValue.rank,
          wellId: +d.label,
          kpiValue: d.kpiValue,
          tags: getWellTags(d),
          lensId: lens.id,
          wellName: wellShortInfo?.byId?.[+d?.label]?.name ?? "",
          targetValue: cardTargetValue,
          baseValue: d.baseValue,
          value: d.kpiValue.value,
          exception: d.kpiValue.exception,
        });
      },
      [getWellTags, lens.id, scoreBenchmark, wellShortInfo?.byId],
    );

    const getBarSelected = useCallback(
      (d: RigCardFactDto) => {
        const thisWellId = +d.label;
        if (
          !Number.isFinite(factInfo?.cardGroupId) &&
          !Number.isFinite(factInfo?.kpiId)
        ) {
          return (
            factInfo?.wellId === thisWellId || factInfo?.wellId === undefined
          );
        } else {
          return (
            factInfo?.cardGroupId === lens.lensTabId &&
            factInfo?.kpiId === rigCardScore.kpiType &&
            factInfo?.wellId === thisWellId
          );
        }
      },
      [
        factInfo?.cardGroupId,
        factInfo?.kpiId,
        factInfo?.wellId,
        lens.lensTabId,
        rigCardScore.kpiType,
      ],
    );
    const getOpacity = useCallback(
      (d: RigCardFactDto) => {
        return getBarSelected(d) ? barOpacity.default : barOpacity.transparent;
      },
      [getBarSelected],
    );
    const titleRef = useRef<HTMLDivElement>(null);
    const scorecardRef = useRef<HTMLDivElement>(null);
    const [chartWrapperHeight, setChartWrapperHeight] = useState(
      SCORECARD_MIN_TITLE_HEIGHT,
    );

    useEffect(() => {
      setChartWrapperHeight(
        SCORECARD_HEIGHT -
          SCORECARD_PADDING -
          Math.max(
            titleRef?.current?.clientHeight ?? 0,
            SCORECARD_MIN_TITLE_HEIGHT,
          ),
      );
    }, [lensName, titleRef?.current?.clientHeight]);

    useEffect(() => {
      if (
        transformedData?.some(getBarSelected) &&
        factInfo?.cardGroupId !== undefined &&
        factInfo?.kpiId !== undefined
      ) {
        setTimeout(
          () =>
            scorecardRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            }),
          100,
        );
      }
    }, [factInfo, getBarSelected, transformedData]);
    if (
      rigCardScore.dataState !== ResultDataState.Valid ||
      (valueScale.domain()[0] === 0 && valueScale.domain()[1] === 0)
    )
      return <NoDataScoreCard title={lensName} />;

    return (
      <Styled.RigScoreCardContainer
        height={SCORECARD_HEIGHT}
        ref={scorecardRef}
      >
        <Styled.CardWrapper>
          <GroupTitle
            inner
            ref={titleRef}
            title={lensName}
            withTagSeparators
            tags={tags}
            hoverText={
              KpiInfoPopupContent({ kpiType: rigCardScore.kpiType }) ? (
                <KpiInfoPopupContent kpiType={rigCardScore.kpiType} />
              ) : null
            }
          />
          <Styled.ChartWrapper height={chartWrapperHeight} ref={containerRef}>
            <svg
              width={chartWidth}
              height={chartHeight}
              style={{ overflow: "visible", userSelect: "none", paddingTop: 8 }}
            >
              <Chart
                detailed={false}
                isManual={false}
                chartWidth={plotWidth}
                chartHeight={chartHeight}
                plotWidth={plotWidth}
                plotHeight={plotHeight}
                valueScale={valueScale}
                showOperationCount={false}
                categoryScale={categoryScale}
                maxNumFractionalDigits={1}
                valueUOM={uom}
                tickLabelFontSizeDefault="12px"
                bottomTickComponent={() => ""}
                hideZeroValues
                showRightLine={false}
                defaultNumTicks={FALLBACK_NUM_TICKS_Y_AXIS - 1}
              >
                <AxisBottom
                  top={plotHeight}
                  scale={categoryScale}
                  numTicks={Math.min(
                    categoryScale.domain().length,
                    Math.max(
                      5,
                      (categoryScale.bandwidth() / 60) *
                        categoryScale.domain().length,
                    ),
                  )}
                  tickLabelProps={() => ({
                    fontSize: "12px",
                    fill: themeColors.disabled_typography,
                    letterSpacing: "-0.2px",
                    textAnchor: "middle",
                  })}
                  tickFormat={(id) => `#${id}`}
                  hideAxisLine
                  hideTicks
                />
                {transformedData?.map((d, index) => {
                  if (
                    d?.kpiValue?.value === undefined ||
                    d?.kpiValue?.rank === undefined
                  )
                    return null;
                  const x = categoryScale(d?.kpiValue?.rank) || 0;
                  if (!Number.isFinite(x) || x < 0) return null;
                  const barHeight = Math.min(
                    plotHeight - (getValue(d) || 0),
                    plotHeight,
                  );
                  const barHeightException = Math.min(
                    plotHeight - (getValueException(d) || 0),
                    plotHeight,
                  );
                  const barY = plotHeight - barHeight;
                  const barYException =
                    plotHeight - barHeightException - barHeight;
                  const color = getColorByScore(
                    scoreBenchmark === BenchmarkType.OperatorTarget
                      ? d?.operatorScore?.value || 0
                      : d?.rigScore?.value || 0,
                  );
                  const gradientToColor = `${color}70`;
                  const gradientFromColor = `${color}`;
                  return (
                    <React.Fragment key={`${d?.kpiValue?.rank}-bar`}>
                      <LinearGradient
                        from={gradientFromColor}
                        to={gradientToColor}
                        fromOpacity={1}
                        toOpacity={0.9}
                        id={`linear_gradient_${gradientToColor}_${lens.id}`}
                      />
                      <PatternLines
                        id={`pattern_lines_${gradientToColor}_${lens.id}`}
                        height={5}
                        width={5}
                        stroke={`url(#linear_gradient_${gradientToColor}_${lens.id})`}
                        strokeWidth={1.5}
                        orientation={["diagonalRightToLeft"]}
                      />

                      <BarRounded
                        // Not shown, acts as a backdrop to catch hover events
                        radius={4}
                        top
                        x={x}
                        y={0}
                        opacity={0}
                        fill="transparent"
                        key={`single-kpi-${d?.kpiValue?.rank}-${index}-hover-catch`}
                        width={categoryScale.bandwidth()}
                        height={plotHeight}
                        cursor="pointer"
                        onMouseOver={() => {
                          setHoveredWellId(+d.label);
                        }}
                        onClick={() => handleOnMouseClick(d)}
                        onMouseOut={() => {
                          setHoveredWellId(null);
                        }}
                      />

                      <BarRounded
                        radius={4}
                        top
                        x={x}
                        y={barY}
                        opacity={getOpacity(d)}
                        key={`single-kpi-${d?.kpiValue?.rank}-${index}`}
                        width={categoryScale.bandwidth()}
                        height={barHeight}
                        cursor="pointer"
                        onMouseOver={() => {
                          handleMouseOver(d);
                        }}
                        onClick={() => handleOnMouseClick(d)}
                        onMouseOut={() => {
                          hideTooltip();
                          setHoveredWellId(null);
                        }}
                        fill={`url(#linear_gradient_${gradientToColor}_${lens.id})`}
                      />

                      {getValueException(d) === null ? null : (
                        <BarRounded
                          radius={4}
                          top
                          x={x}
                          y={barYException}
                          opacity={getOpacity(d)}
                          key={`single-exception-kpi-${d?.kpiValue?.rank}-${index}`}
                          width={categoryScale.bandwidth()}
                          height={barHeightException}
                          cursor="pointer"
                          onMouseOver={() => {
                            handleMouseOver(d);
                          }}
                          onClick={() => handleOnMouseClick(d)}
                          onMouseOut={() => {
                            hideTooltip();
                            setHoveredWellId(null);
                          }}
                          fill={`url(#pattern_lines_${gradientToColor}_${lens.id})`}
                        />
                      )}
                      <Group key={`${d.label}+i`}>
                        <CommentCircle
                          fact={d}
                          cx={
                            (categoryScale(d?.kpiValue?.rank) || 0) +
                            categoryScale.bandwidth() / 2
                          }
                          cy={valueScale(valueScale.domain()[1])}
                          r={7.5}
                          lensId={lens?.id}
                        />
                      </Group>
                      {hoveredWellId === +d?.label && (
                        <Group
                          left={
                            (categoryScale(d?.kpiValue?.rank) || 0) +
                            categoryScale.bandwidth() / 2 -
                            7
                          }
                          top={valueScale(valueScale.domain()[1]) - 4}
                          pointerEvents={"none"}
                        >
                          <Circle
                            cursor="pointer"
                            cx={7}
                            cy={7}
                            r={14}
                            z={12}
                            fill={colors.well_color}
                          ></Circle>
                          <svg
                            z={1}
                            focusable="false"
                            fill={colors.white}
                            width="1em"
                            height="1em"
                            viewBox="0 0 32 32"
                            aria-hidden="true"
                          >
                            <path d="M17.74,30,16,29l4-7h6a2,2,0,0,0,2-2V8a2,2,0,0,0-2-2H6A2,2,0,0,0,4,8V20a2,2,0,0,0,2,2h9v2H6a4,4,0,0,1-4-4V8A4,4,0,0,1,6,4H26a4,4,0,0,1,4,4V20a4,4,0,0,1-4,4H21.16Z" />
                            <path d="M8 10H24V12H8zM8 16H18V18H8z" />
                          </svg>
                        </Group>
                      )}

                      {(() => {
                        const cardTargetValue =
                          scoreBenchmark === BenchmarkType.OperatorTarget
                            ? d.targetValue
                            : d.pdBenchmarkValue;

                        return cardTargetValue ? (
                          <TargetLine
                            start={categoryScale(d?.kpiValue?.rank) || 0}
                            end={
                              (categoryScale(d?.kpiValue?.rank) || 0) +
                              categoryScale.bandwidth()
                            }
                            y={Math.min(
                              valueScale(cardTargetValue),
                              plotHeight,
                            )}
                            detailed={false}
                            showTag={false}
                            label=""
                          />
                        ) : null;
                      })()}
                      {d.baseValue ? (
                        <TargetLine
                          start={categoryScale(d?.kpiValue?.rank) || 0}
                          end={
                            (categoryScale(d?.kpiValue?.rank) || 0) +
                            categoryScale.bandwidth()
                          }
                          y={Math.min(valueScale(d.baseValue), plotHeight)}
                          detailed={false}
                          showTag={false}
                          strokeDasharray={"2 2"}
                          label=""
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </Chart>
            </svg>
          </Styled.ChartWrapper>
          {CommentsModalElement}
          {tooltipElement}
        </Styled.CardWrapper>
      </Styled.RigScoreCardContainer>
    );
  },
);

export { RigScoreCard };
