import { AxisLeft } from "@visx/axis";
import { LinearGradient } from "@visx/gradient";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Bar, BarRounded } from "@visx/shape";
import type {
  StandDrillingInfoDto,
  TagBottomFingerprintFactSetDto,
} from "apis/oag";
import { DimensionType, ShiftType, TimeUnit } from "apis/oag";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { TagBottomFingerPrintContext } from "components/Lenses/ContainerLens/TagBottomFingerprint/Chart/Chart";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { omit } from "lodash";
import { shade } from "polished";
import { useContext, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import styled from "styled-components";
import colors from "utils/colors";
import { SHORTER_DATE_FORMAT, useTimeUom, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";
const PLOT_WIDTH = 157;

// TODO add utils for magic values

const Container = styled.div`
  padding: 0px;
  width: 100%;
  height: 100%;
  position: relative;
  background: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
`;
export const Stands = ({
  isOverall,
  stands,
  showAlphaConnection,
  setTagBottomContext,
}: {
  isOverall: boolean;
  stands: TagBottomFingerprintFactSetDto["slipsToBottomStands"];
  showAlphaConnection: boolean;
  setTagBottomContext: React.Dispatch<
    React.SetStateAction<{
      alphaStands: number[];
      selectedStand: number;
      hoveredStand: number;
      outlierStands: number[];
    }>
  >;
}) => {
  const { height: chartHeightHook, ref: containerRef } = useResizeDetector();
  const tagBottomContext = useContext(TagBottomFingerPrintContext);
  if (!tagBottomContext)
    throw new Error("TagBottomFingerPrintContext is not defined");
  const depthUOM = useUOM(DimensionType.Metres);
  const minuteUom = useTimeUom(TimeUnit.Minute);
  const { chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
  };
  const plotHeight = chartHeight;
  const valueScale = useMemo(() => {
    const selectedStands = stands.filter((stand) => !stand.isOutlier);

    return scaleLinear({
      domain: [
        0,
        Math.max(
          ...(selectedStands.length > 0 ? selectedStands : stands).map(
            (e) => e.duration,
          ),
        ),
      ],
      range: [0, PLOT_WIDTH],
    });
  }, [stands]);

  const categoryScale = useMemo(() => {
    const chartSize = plotHeight;

    const startX = 0;

    return scaleBand<number>({
      domain: stands.map((i) => i?.standNumber),
      // Offsetting the pixel range based on zoom, provides scrolling and zoom at the same time
      range: [chartSize - startX, -startX],
      paddingInner: 0.2,
      paddingOuter: 0.5,
    });
  }, [plotHeight, stands]);
  const columnWidth = categoryScale.bandwidth();
  const {
    themeStyle: { colors: themeColors, outliers: outliersTheme },
    isDark,
  } = useCustomTheme();
  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip({
    containerRef,
    rightTooltip: true,
    renderContent: ({
      tooltipData,
    }: {
      tooltipData?: StandDrillingInfoDto;
    }) => {
      if (!tooltipData) return null;
      return (
        <>
          <TooltipHighlightValue style={{ fontWeight: "bold", fontSize: 12 }}>
            {minuteUom.display(tooltipData.duration)}
          </TooltipHighlightValue>
          <span>
            {depthUOM.display(tooltipData.startBitDepth)} to{" "}
            {depthUOM.display(tooltipData.endBitDepth)}
          </span>
          <span>
            {formatTime(tooltipData.startDate, {
              formatStr: SHORTER_DATE_FORMAT,
            })}{" "}
            to{" "}
            {formatTime(tooltipData.endDate, {
              formatStr: SHORTER_DATE_FORMAT,
            })}
          </span>
          <span>{tooltipData.isOutlier ? "Outlier" : tooltipData.shift}</span>
        </>
      );
    },
  });

  return (
    <Container
      ref={containerRef}
      onClick={() => {
        setTagBottomContext((prev) => ({
          ...prev,
          selectedStand: -1,
        }));
      }}
    >
      <svg
        width={PLOT_WIDTH}
        height={chartHeight > 0 ? chartHeight : 0}
        style={{
          overflow: "visible",
          userSelect: "none",
        }}
      >
        <LinearGradient
          id={"gradient-id"}
          from={themeColors.alt_secondary_bg}
          x1={1}
          y1={0}
          x2={0}
          y2={0}
          to={themeColors.quaterniary_bg}
          fromOpacity={1}
          toOpacity={0}
        />
        {stands.map((d, index) => {
          /**
           * category scale is on y
           * value scale on x
           */
          const barHeight = columnWidth;
          const barY = categoryScale(d.standNumber);
          const barWidth = valueScale(
            Math.min(d.duration || 0, valueScale.domain()[1]),
          );
          const barX = 55;
          return (
            <Group key={`bar-stack-${index}`}>
              {d.isAlpha && showAlphaConnection ? (
                <BarRounded
                  x={barX}
                  y={barY || 0}
                  radius={0}
                  key={`single-kpi-${index}-${d.duration}-${d.shift}-${index}-alpha`}
                  width={PLOT_WIDTH + 50}
                  height={barHeight}
                  opacity={(() => {
                    if (isOverall) return 0.1;
                    if (
                      (tagBottomContext.selectedStand === -1 &&
                        tagBottomContext.hoveredStand === -1) ||
                      tagBottomContext.selectedStand === d.id ||
                      tagBottomContext.hoveredStand === d.id
                    )
                      return 0.2;
                    return 0.1;
                  })()}
                  fill={colors.turtle_green}
                />
              ) : null}
              <BarRounded
                x={barX}
                y={barY || 0}
                topRight
                onMouseEnter={() => {
                  if (d.isOutlier) return;
                  if (isOverall) return;
                  if (
                    tagBottomContext.selectedStand !== -1 &&
                    tagBottomContext.selectedStand !== d.id
                  )
                    return;
                  if (tagBottomContext.selectedStand === -1) {
                    setTagBottomContext((prev) => ({
                      ...prev,
                      hoveredStand: d.id,
                    }));
                  }
                  showTooltip({
                    tooltipLeft: barWidth + barX + 10,
                    tooltipTop: (barY || 0) + barHeight / 2,
                    tooltipData: d,
                  });
                }}
                onMouseLeave={() => {
                  hideTooltip();
                  if (tagBottomContext.selectedStand === -1) {
                    setTagBottomContext((prev) => ({
                      ...prev,
                      hoveredStand: -1,
                    }));
                  }
                }}
                opacity={(() => {
                  if (isOverall) return isDark ? 0.2 : 0.4;
                  if (
                    (tagBottomContext.selectedStand === -1 &&
                      tagBottomContext.hoveredStand === -1) ||
                    tagBottomContext.selectedStand === d.id ||
                    tagBottomContext.hoveredStand === d.id
                  )
                    return 1;
                  return isDark ? 0.2 : 0.4;
                })()}
                bottomRight
                radius={d.isOutlier ? 0 : 4}
                key={`single-kpi-${index}-${d.duration}-${d.shift}-${index}`}
                width={barWidth}
                height={barHeight}
                onClick={(e) => {
                  if (isOverall) return;
                  e.preventDefault();
                  e.stopPropagation();
                  setTagBottomContext((prev) => ({
                    ...prev,
                    hoveredStand: -1,
                    selectedStand: d.id === prev.selectedStand ? -1 : d.id,
                  }));
                }}
                fill={(() => {
                  if (d.isOutlier) return outliersTheme.bars_transparent;
                  if (d.shift === ShiftType.Day) return colors.well_color;
                  return shade(0.5, colors.well_color);
                })()}
              />
              {d.isOutlier ? (
                <Bar
                  x={barX}
                  y={barY}
                  width={barWidth + 2}
                  onMouseOver={() => {
                    if (isOverall) return;
                    if (
                      tagBottomContext.selectedStand !== -1 &&
                      tagBottomContext.selectedStand !== d.id
                    )
                      return;

                    showTooltip({
                      tooltipLeft: barWidth + 2,
                      tooltipTop: (barY || 0) + barHeight / 2,
                      tooltipData: d,
                    });
                    if (tagBottomContext.selectedStand === -1)
                      setTagBottomContext((prev) => ({
                        ...prev,
                        hoveredStand: d.id,
                      }));
                  }}
                  onClick={(e) => {
                    if (isOverall) return;
                    e.preventDefault();
                    e.stopPropagation();
                    setTagBottomContext((prev) => ({
                      ...prev,
                      selectedStand: d.id === prev.selectedStand ? -1 : d.id,
                    }));
                  }}
                  onMouseOut={() => {
                    hideTooltip();
                    if (tagBottomContext.selectedStand === -1)
                      setTagBottomContext((prev) => ({
                        ...prev,
                        hoveredStand: -1,
                      }));
                  }}
                  height={barHeight}
                  fill={"url(#gradient-id)"}
                />
              ) : null}
            </Group>
          );
        })}
        <AxisLeft
          top={5}
          scale={categoryScale}
          hideAxisLine
          hideTicks
          left={PLOT_WIDTH - 140}
          numTicks={chartHeight / 20}
          tickLabelProps={() => ({
            fontSize: 12,
            textAnchor: "start",
            fontWeight: 400,
          })}
          tickComponent={(props) => {
            const value = +(props.formattedValue || "");
            const stand = stands.find((e) => e.standNumber === value);
            const displayValue = depthUOM.display(value, {
              unit: "",
              fractionDigits: 0,
            });
            return (
              <text
                {...omit(props, ["formattedValue"])}
                opacity={(() => {
                  if (isOverall) return 0.4;
                  if (
                    (tagBottomContext.selectedStand === -1 &&
                      tagBottomContext.hoveredStand === -1) ||
                    tagBottomContext.selectedStand === stand?.id ||
                    tagBottomContext.hoveredStand === stand?.id
                  )
                    return 1;
                  return 0.4;
                })()}
                fill={
                  stand?.isAlpha && showAlphaConnection
                    ? colors.turtle_green
                    : themeColors.disabled_typography
                }
              >
                {displayValue}
              </text>
            );
          }}
        />
        {tooltipElement}
      </svg>
    </Container>
  );
};
