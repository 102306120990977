import { useIsFetching } from "@tanstack/react-query";
import type { AllWellsKpiDto } from "apis/oag";
import { Loader } from "components/Loader";
import { useAllWellsKPI } from "hooks/wells/useAllWellsKPI";
import SingleKPI from "pages/AllWells/components/KPIsContainer/SingleKPI";
import {
  CenterLoader,
  Grid,
} from "pages/AllWells/components/KPIsContainer/style";
import type { FC } from "react";
import { useAppSelector } from "reducers/store";
import { dimensionMap } from "utils/format";
import { PDQueryType } from "utils/queryNamespaces";

const KPINames = {
  drillingProductivity: "Drilling Productivity",
  avgWellDuration: "Avg. Well Duration",
  avgTotalDrillingDistance: "Avg. Drilling Distance",
  avgWeightToWeight: "Avg. Weight to Weight",
  avgSlipsToSlips: "Avg. Slips to Slips",
  avgOnBottomRop: "Avg. On-Bottom ROP",
  avgTrippingRunningSpeed: "Avg. Tripping Running Speed",
};

const KPIsContainer: FC = () => {
  const allKpis = useAllWellsKPI();
  const currentUom = useAppSelector((state) => state.global.unit);
  const isLoading = useIsFetching({
    predicate(query) {
      return (
        [
          PDQueryType.WELL_SUMMARIES,
          PDQueryType.LINKED_FILTERS,
        ] as PDQueryType[]
      ).includes(
        (
          query.queryKey[0] as {
            type: PDQueryType;
          }
        ).type,
      );
    },
  });
  if (allKpis.isLoading || !allKpis.data || isLoading) {
    return (
      <CenterLoader>
        <Loader />
      </CenterLoader>
    );
  }
  return (
    <Grid>
      {Object.keys(allKpis?.data || {}).map((kpiName) => {
        if (kpiName === "avgTotalDepth") return null;

        const getFactor = () => {
          if (kpiName === "avgWellDuration") {
            return { factor: 1 / (60 * 24), unit: "days" };
          } else if (kpiName === "drillingProductivity") {
            return { factor: 24 };
          }
        };
        const result = (() => {
          return dimensionMap[
            allKpis.data[kpiName as keyof AllWellsKpiDto].dimension
          ]
            [currentUom](
              allKpis.data[kpiName as keyof AllWellsKpiDto].value,
              getFactor(),
            )
            .replaceAll(
              kpiName === "drillingProductivity" ? "hr" : "",
              kpiName === "drillingProductivity" ? "day" : "",
            );
        })();
        return (
          <SingleKPI
            key={kpiName}
            title={KPINames[kpiName as keyof typeof KPINames]}
            value={result}
          />
        );
      })}
    </Grid>
  );
};

export default KPIsContainer;
