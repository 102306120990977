import type { UserDto } from "apis/oag";
import { ClaraEndpointType, SystemOfMeasurementType, UsersApi } from "apis/oag";
import {
  StyledCard,
  StyledLogoutRow,
  StyledMenuLink,
  StyledOptionRow,
  StyledThemeButton,
} from "components/Header/RightContent/style";
import { PDComponent } from "components/PDComponents";
import { useCallback } from "react";
import { useAppDispatch } from "reducers/store";
import { IUnitSystem } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import type { RadioChangeEvent } from "utils/componentLibrary";
import { Radio, Row } from "utils/componentLibrary";

enum SelectorThemes {
  LIGHT = "Light",
  DARK = "Dark",
}

const users = new UsersApi(apiConfig);

export const transformToLocalUOM = (unit: SystemOfMeasurementType) =>
  unit === SystemOfMeasurementType.Imperial
    ? IUnitSystem.IMPERIAL
    : IUnitSystem.METRIC;

export const MenuItems = ({
  setMenuVisible,
  handleLogout,
  setUom,
  localUOM,
  setTheme,
  currentSelectorTheme,
  userInfo,
}: {
  setMenuVisible: (visible: boolean) => void;
  handleLogout: () => void;
  setUom: (unit: SystemOfMeasurementType) => void;
  localUOM: SystemOfMeasurementType;
  setTheme: (theme: "Light" | "Dark") => void;
  currentSelectorTheme: "Light" | "Dark";
  userInfo: UserDto | null;
}) => {
  const selectedClaraEndpoint =
    userInfo?.claraEndpoint ?? ClaraEndpointType.Prod; // by default everybody queries Clara/AltaMl Prod (in any Clarity environment)
  const isInDemoMode = userInfo?.isInDemoMode ?? false;

  const isAdmin = userInfo?.role === "Administrator";
  const canSelectClaraEndpoint =
    isAdmin || (userInfo?.canSelectClaraEndpoint ?? false);
  const dispatch = useAppDispatch();
  const setClaraEndpoint = useCallback(
    (claraEndpoint: ClaraEndpointType) => {
      users
        .apiUsersMeClaraEndpointEndpointPut({
          endpoint: claraEndpoint,
        })
        .then(() => {
          if (userInfo)
            dispatch({
              type: "SET_USER_INFO",
              payload: {
                ...userInfo,
                claraEndpoint: claraEndpoint ?? ClaraEndpointType.Prod,
              },
            });
        });
    },
    [dispatch, userInfo],
  );

  const setDemoMode = useCallback((isDemoMode: boolean) => {
    users
      .apiUsersMeDemoModeIsInDemoModePut({
        isInDemoMode: isDemoMode,
      })
      .then(() => {
        location.reload();
      });
  }, []);

  return (
    <StyledCard
      actions={[
        <StyledLogoutRow justify="start" key="logout" onClick={handleLogout}>
          <PDComponent.SvgIcon name="logout" /> Logout
        </StyledLogoutRow>,
      ]}
    >
      <StyledOptionRow justify="space-between" align="middle">
        <StyledMenuLink
          onClick={() => {
            setMenuVisible(false);
          }}
          to="/account/me"
        >
          My account
        </StyledMenuLink>
      </StyledOptionRow>
      <StyledOptionRow justify="space-between" align="middle">
        Units{" "}
        <Radio.Group
          options={[
            { label: "Imperial", value: SystemOfMeasurementType.Imperial },
            { label: "Metric", value: SystemOfMeasurementType.Metric },
          ]}
          onChange={(e: RadioChangeEvent) => {
            setUom(e.target.value);
          }}
          value={localUOM}
          optionType="button"
        />
      </StyledOptionRow>

      <StyledOptionRow justify="space-between" align="middle">
        Theme{" "}
        <Radio.Group
          value={currentSelectorTheme}
          optionType="button"
          onChange={(e: RadioChangeEvent) => {
            setTheme(e.target.value);
          }}
        >
          <StyledThemeButton value={SelectorThemes.LIGHT}>
            <Row justify="center" align="middle">
              <PDComponent.SvgIcon name="light" /> Light
            </Row>
          </StyledThemeButton>
          <StyledThemeButton value={SelectorThemes.DARK}>
            <Row justify="center" align="middle">
              <PDComponent.SvgIcon name="dark" /> Dark
            </Row>
          </StyledThemeButton>
        </Radio.Group>
      </StyledOptionRow>

      {canSelectClaraEndpoint ? (
        <StyledOptionRow justify="space-between" align="middle">
          Clara{" "}
          <Radio.Group
            options={[
              { label: "Dev", value: ClaraEndpointType.Dev },
              { label: "Prod", value: ClaraEndpointType.Prod },
            ]}
            onChange={(e: RadioChangeEvent) => {
              setClaraEndpoint(e.target.value);
            }}
            value={selectedClaraEndpoint}
            optionType="button"
          />
        </StyledOptionRow>
      ) : null}

      {isAdmin ? (
        <StyledOptionRow justify="space-between" align="middle">
          Demo{" "}
          <Radio.Group
            options={[
              { label: "On", value: true },
              { label: "Off", value: false },
            ]}
            onChange={(e: RadioChangeEvent) => {
              setDemoMode(e.target.value);
            }}
            value={isInDemoMode}
            optionType="button"
          />
        </StyledOptionRow>
      ) : null}
    </StyledCard>
  );
};
