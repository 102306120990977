import { Single } from "components/Lenses/ContainerLens/GeneratorStatusBar/Single";
import type { GeneratorStatusBarProps } from "components/Lenses/interfaces";
import React from "react";

export const GeneratorStatusBar: React.FC<GeneratorStatusBarProps> = ({
  lens,
  detailed,
  setLensDate,
}) => {
  return <Single lens={lens} detailed={detailed} setLensDate={setLensDate} />;
};
