/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { DashboardType } from './DashboardType';
import {
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import type { PivotType } from './PivotType';
import {
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface LeaderboardFactQueryDto
 */
export interface LeaderboardFactQueryDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof LeaderboardFactQueryDto
     */
    formationIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LeaderboardFactQueryDto
     */
    operatorIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LeaderboardFactQueryDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {DateDto}
     * @memberof LeaderboardFactQueryDto
     */
    from?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof LeaderboardFactQueryDto
     */
    to?: DateDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof LeaderboardFactQueryDto
     */
    includeFullWellFacts: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof LeaderboardFactQueryDto
     */
    selectedWellIds?: Array<number> | null;
    /**
     * 
     * @type {FiltersDto}
     * @memberof LeaderboardFactQueryDto
     */
    selectedFilters?: FiltersDto | null;
    /**
     * 
     * @type {DashboardType}
     * @memberof LeaderboardFactQueryDto
     */
    dashboard: DashboardType;
    /**
     * 
     * @type {Array<number>}
     * @memberof LeaderboardFactQueryDto
     */
    highlightedRigIds: Array<number>;
    /**
     * 
     * @type {PivotType}
     * @memberof LeaderboardFactQueryDto
     */
    pivot: PivotType;
    /**
     * 
     * @type {Array<number>}
     * @memberof LeaderboardFactQueryDto
     */
    tabIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof LeaderboardFactQueryDto
     */
    useExceptions: boolean;
}



/**
 * Check if a given object implements the LeaderboardFactQueryDto interface.
 */
export function instanceOfLeaderboardFactQueryDto(value: object): value is LeaderboardFactQueryDto {
    if (!('includeFullWellFacts' in value) || value['includeFullWellFacts'] === undefined) return false;
    if (!('dashboard' in value) || value['dashboard'] === undefined) return false;
    if (!('highlightedRigIds' in value) || value['highlightedRigIds'] === undefined) return false;
    if (!('pivot' in value) || value['pivot'] === undefined) return false;
    if (!('useExceptions' in value) || value['useExceptions'] === undefined) return false;
    return true;
}

export function LeaderboardFactQueryDtoFromJSON(json: any): LeaderboardFactQueryDto {
    return LeaderboardFactQueryDtoFromJSONTyped(json, false);
}

export function LeaderboardFactQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardFactQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'formationIds': json['FormationIds'] == null ? undefined : json['FormationIds'],
        'operatorIds': json['OperatorIds'] == null ? undefined : json['OperatorIds'],
        'rigIds': json['RigIds'] == null ? undefined : json['RigIds'],
        'from': json['From'] == null ? undefined : DateDtoFromJSON(json['From']),
        'to': json['To'] == null ? undefined : DateDtoFromJSON(json['To']),
        'includeFullWellFacts': json['IncludeFullWellFacts'],
        'selectedWellIds': json['SelectedWellIds'] == null ? undefined : json['SelectedWellIds'],
        'selectedFilters': json['SelectedFilters'] == null ? undefined : FiltersDtoFromJSON(json['SelectedFilters']),
        'dashboard': DashboardTypeFromJSON(json['Dashboard']),
        'highlightedRigIds': json['HighlightedRigIds'],
        'pivot': PivotTypeFromJSON(json['Pivot']),
        'tabIds': json['TabIds'] == null ? undefined : json['TabIds'],
        'useExceptions': json['UseExceptions'],
    };
}

export function LeaderboardFactQueryDtoToJSON(value?: LeaderboardFactQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'FormationIds': value['formationIds'],
        'OperatorIds': value['operatorIds'],
        'RigIds': value['rigIds'],
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
        'IncludeFullWellFacts': value['includeFullWellFacts'],
        'SelectedWellIds': value['selectedWellIds'],
        'SelectedFilters': FiltersDtoToJSON(value['selectedFilters']),
        'Dashboard': DashboardTypeToJSON(value['dashboard']),
        'HighlightedRigIds': value['highlightedRigIds'],
        'Pivot': PivotTypeToJSON(value['pivot']),
        'TabIds': value['tabIds'],
        'UseExceptions': value['useExceptions'],
    };
}

