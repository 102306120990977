import { Title } from "atoms/Typography";
import { _SvgIcon } from "components/PDComponents/SvgIcon";
import styled from "styled-components";
import {
  Button,
  Card as PdCard,
  Dropdown as AndDropdown,
  Row,
} from "utils/componentLibrary";
import { PdCardBody } from "utils/componentLibrary/Card";
import { PdRow } from "utils/componentLibrary/Row";

export const Option = styled.div`
  width: 100%;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  cursor: pointer;
`;

export const Card = styled(PdCard)`
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  min-width: 186px;
  ${PdCardBody} {
    padding: 0;
  }
`;

export const ChevronDownIcon = styled(_SvgIcon).attrs({ name: "chevronDown" })<{
  $alwaysShowChevron?: boolean;
}>`
  opacity: ${(props) => (props.$alwaysShowChevron ? 1 : 0)};
`;

export const SelectedOption = styled(Title).attrs({
  level: 5,
})<{ $isActive?: boolean }>`
  cursor: pointer;
  font-size: 12px;
  display: flex;
  user-select: none;
  word-break: keep-all;
  white-space: nowrap;

  &:hover {
    ${ChevronDownIcon} {
      opacity: 1;
    }
  }

  ${ChevronDownIcon} {
    transform: rotate(${(props) => (props.$isActive ? "180deg" : "0deg")});
  }
`;

export const StyledButton = styled(Button)<{
  $isActive?: boolean;
  $backgroundColor?: string;
}>`
  padding: 0px 12px;
  height: 36px;
  line-height: 36px;
  letter-spacing: -0.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.$backgroundColor
      ? props.$backgroundColor
      : props.theme.themeStyle.colors.primary_accent};
  color: ${(props) => props.theme.themeStyle.colors.primary_typography};
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};

  :focus,
  &.ant-btn:focus {
    outline: none;
    box-shadow: none;
    color: ${(props) => props.theme.themeStyle.colors.primary_typography};
    background: ${(props) => props.theme.themeStyle.colors.primary_accent};
  }

  :hover {
    background: ${(props) => props.theme.themeStyle.colors.primary_accent};
    color: ${(props) => props.theme.themeStyle.colors.primary_typography};
  }

  ${ChevronDownIcon} {
    opacity: 1;
    transform: rotate(${(props) => (props.$isActive ? "180deg" : "0deg")});
  }
`;

export const DropDown = styled(AndDropdown)<{ $hideBorder?: boolean }>`
  border: ${(props) => (props.$hideBorder ? "none" : undefined)};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OptionRow = styled(Row)`
  height: 40px;
  padding: 0 16px;
  &${PdRow} + &${PdRow} {
    border-top: 1px solid
      ${({ theme }) => theme.themeStyle.colors.primary_accent} !important;
  }
  :hover {
    background-color: ${({ theme }) =>
      theme.themeStyle.colors.secondary_accent};
  }
`;

export const LabelDescription = styled.span`
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;
