import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useEffectExceptOnMount } from "hooks/react-utils/useEffectExceptOnMount";
import { useRigToWellsSuspended } from "hooks/rigs/useRigToWells";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import { LegendItem } from "pages/RigScoreCard/RightPane/LegendItem";
import {
  BenchmarkType,
  ScoreBenchmarkContext,
} from "pages/RigScoreCard/ScoreBenchmarkContext";
import { useContext, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import styled from "styled-components";
import { Row } from "utils/componentLibrary";

const StyledLineLegend = styled.div`
  height: 24px;
  line-height: 24px;
  display: flex;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  gap: 10px;
  align-items: center;
`;

const StyledLineLegendContainer = styled(Row)`
  padding: 24px;
  flex-direction: column;
  ${StyledLineLegend} + ${StyledLineLegend} {
    margin-top: 16px;
  }
`;

export default function RightPaneLegendTab() {
  const [wellIds] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.SELECTED_WELLS_RIG_SCORECARD,
    [],
  );
  const { data: wellShortInfo } = useWellShortInfoSuspended();
  const { data: rigToWells } = useRigToWellsSuspended({
    isFilteringIgnored: true,
    staleTime: Infinity,
  });
  const sortedWellIds = rigToWells.filter((id) => wellIds?.includes(id));
  useEffectExceptOnMount(() => {
    dispatch({
      type: "ADD_FACT_INFO",
      payload: undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedWellIds.join(",")]);

  const benchmarkType = useContext(ScoreBenchmarkContext);
  const scoreBenchmarkLabel = useMemo(() => {
    return benchmarkType?.scoreBenchmark === BenchmarkType.OperatorTarget
      ? "Operator Target"
      : "PD Benchmark";
  }, [benchmarkType]);

  const factInfo = useAppSelector((state) => state.rigDashboard.factInfo);
  const dispatch = useAppDispatch();

  return (
    <div>
      <StyledLineLegendContainer>
        <StyledLineLegend>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="1"
            viewBox="0 0 19 1"
            fill="none"
          >
            <path d="M0 0.5H19" stroke="currentColor" strokeDasharray="2 2" />
          </svg>
          <span>Base Value</span>
        </StyledLineLegend>
        <StyledLineLegend>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="1"
            viewBox="0 0 19 1"
            fill="none"
          >
            <path d="M0 0.5H19" stroke="currentColor" />
          </svg>
          {scoreBenchmarkLabel}
        </StyledLineLegend>
      </StyledLineLegendContainer>

      {sortedWellIds.map((wellId, index) => (
        <LegendItem
          key={wellId}
          title={wellShortInfo?.byId[wellId]?.name || ""}
          onClick={() =>
            dispatch({
              type: "ADD_FACT_INFO",
              payload: {
                wellId: wellId === factInfo?.wellId ? undefined : wellId,
              },
            })
          }
          isSelected={wellId === factInfo?.wellId}
          description={
            wellShortInfo?.byId?.[wellId]?.jobExternalIds?.length >= 1
              ? `Job: ${wellShortInfo.byId[wellId].jobExternalIds.join(", ")}`
              : undefined
          }
          legendKey={`#${index + 1}`}
        />
      ))}
    </div>
  );
}
