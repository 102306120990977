import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  LeaderboardFactQueryDto,
  RigLeaderboardFactListsDto,
  WellStatusType,
} from "apis/oag";
import { DashboardType, RigLeaderboardApi } from "apis/oag";
import {
  AttributeStore,
  useLinkedWellAttributes,
} from "hooks/filters/useLinkedAttributes";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { SelectedRigsContext } from "pages/FleetPerformance/RigList/SelectedRigsContext";
import { useContext, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { type IFiltersType, initialFilters } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { RigTechnology } from "utils/enums";
import { dateToDateOnlyDto } from "utils/helper";
import type { PDRigLeaderboardQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const laderboardApi = new RigLeaderboardApi(apiConfig);

export function useRigLeaderboard(
  options?: Omit<
    UseQueryOptions<RigLeaderboardFactListsDto>,
    "queryKey" | "queryFn"
  >,
) {
  const periodState = useAppSelector((state) => state.rigsCommon.period);
  const { options: leaderboardOptions, selectedValues } =
    useLinkedWellAttributes({
      storeName: AttributeStore.rigsCommon,
    });
  const filterState = useAppSelector((state) => state.rigsCommon.filters);

  const allWellsIncluded = useAppSelector(
    (state) => state.rigsCommon.allWellsIncluded,
  );
  const exceptions = useAppSelector((state) => state.rigsCommon.exceptions);

  const selectedRigsContext = useContext(SelectedRigsContext);
  const [{ operationTime }] = useStateQuery<IFiltersType>(
    URL_STATE_PARAM.FILTERS_WIDGET,
    initialFilters,
  );

  const dispatch = useAppDispatch();
  const dispatchLastUpdateDate = (
    data: RigLeaderboardFactListsDto,
  ): RigLeaderboardFactListsDto => {
    if (data?.lastFactUpdatedAt) {
      dispatch({
        type: "RIGS_COMMON_SET_LAST_UPDATE_DATE",
        payload: data.lastFactUpdatedAt,
      });
    }

    return data;
  };

  const waitBeforeRequesting = useMemo(() => {
    return !filterState || !periodState;
  }, [filterState, periodState]);

  const requestQuery: LeaderboardFactQueryDto = useMemo(() => {
    return {
      highlightedRigIds: selectedRigsContext?.selectedRigIds ?? [],
      selectedFilters: {
        sectionIds: filterState?.holeSection.map((hs) => +(hs.id || -1)),
        directionalIntervalIds: filterState?.directionalInterval.map(
          (hs) => +(hs.id || -1),
        ),
        phaseIds: filterState?.phases.map((p) => +(p.id || -1)),
        holeSizeIds: filterState?.holeSize.map((hs) => +(hs.id || -1)),
        includeFlatTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
        includeAlphaRigs: filterState?.technology
          ? filterState?.technology
              .map((tech) => tech.id)
              .includes(RigTechnology.Alpha)
          : true,
        includeNonAlphaRigs: filterState?.technology
          ? filterState?.technology
              .map((tech) => tech.id)
              .includes(RigTechnology.NonAlpha)
          : true,
        selectedClasses: filterState?.rigType
          ? filterState?.rigType.map((t) => (t.id || -1).toString())
          : null,
        selectedCountryIds: filterState?.country
          ? filterState?.country.map((c) => +(c.id || -1))
          : null,
        selectedHorsePowers: filterState?.horsePower
          ? filterState?.horsePower.map((hp) => +(hp.id || -1))
          : null,
        selectedOperatingCenters: filterState?.operatingCenter
          ? filterState?.operatingCenter.map((oc) => (oc.id || -1).toString())
          : null,
        selectedWellStatusTypes: filterState?.wellStatus
          ? (filterState?.wellStatus.map((ws) => ws.id) as WellStatusType[])
          : null,
      },
      dashboard: DashboardType.RigFleetPerformance,
      formationIds:
        selectedValues.formations === null &&
        leaderboardOptions?.formations?.length !== 0
          ? null
          : (leaderboardOptions?.formations ?? []).map(
              (formation) => formation.id,
            ),
      selectedWellIds:
        selectedValues.wells === null && leaderboardOptions?.wells?.length !== 0
          ? null
          : (leaderboardOptions?.wells ?? []).map((well) => well.id),
      operatorIds:
        selectedValues.operators === null &&
        leaderboardOptions?.operators?.length !== 0
          ? null
          : (leaderboardOptions?.operators ?? []).map((op) => op.id),
      rigIds:
        selectedValues.rigs === null && leaderboardOptions?.rigs?.length !== 0
          ? null
          : (leaderboardOptions?.rigs ?? []).map((rig) => rig.id),
      from: dateToDateOnlyDto(
        periodState?.startDate || defaultDateDto.from.utc,
      ),
      to: dateToDateOnlyDto(periodState?.endDate || defaultDateDto.to.utc),
      includeFullWellFacts: allWellsIncluded,
      useExceptions: exceptions,
      pivot: "None",
    };
  }, [
    selectedRigsContext?.selectedRigIds,
    filterState?.holeSection,
    filterState?.directionalInterval,
    filterState?.phases,
    filterState?.holeSize,
    filterState?.technology,
    filterState?.rigType,
    filterState?.country,
    filterState?.horsePower,
    filterState?.operatingCenter,
    filterState?.wellStatus,
    operationTime,
    selectedValues.operators,
    selectedValues.formations,
    selectedValues.rigs,
    selectedValues.wells,
    leaderboardOptions?.formations,
    leaderboardOptions?.operators,
    leaderboardOptions?.rigs,
    leaderboardOptions?.wells,
    periodState?.startDate,
    periodState?.endDate,
    allWellsIncluded,
    exceptions,
  ]);

  const RigLeaderboardQueryKey: PDRigLeaderboardQueryKey = {
    uid: RequestUID.rigLeaderboard,
    params: requestQuery,
    type: PDQueryType.RIG_LEADERBOARD,
  };

  return useQuery<RigLeaderboardFactListsDto>({
    queryKey: [RigLeaderboardQueryKey, allWellsIncluded],
    queryFn: () =>
      laderboardApi
        .apiRigLeaderboardAllPut({
          leaderboardFactQueryDto: requestQuery,
        })
        .then((data) => dispatchLastUpdateDate(data)),
    ...options,
    refetchOnMount: false,
    enabled: (options?.enabled ?? true) && !waitBeforeRequesting,
  });
}
