import React from "react";
import styled from "styled-components";
import { Col, Layout, Row, Tabs } from "utils/componentLibrary";
import { PdRow } from "utils/componentLibrary/Row";

export const PlanSectionLayoutContainer = styled(Layout)`
  height: 100%;
`;

const HeaderWrapper = styled.div<{ showTopBorder: boolean }>`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  ${(props) =>
    props.showTopBorder ? "border-top: 1px solid rgba(0, 0, 0, 0.06);" : ""}
`;

const ContentWrapper = styled.div`
  padding: 24px 40px;
  background-color: ${({ theme }) =>
    theme.themeStyle.colors.primary_bg} !important;
  height: calc(100vh - 64px - 120px - 64px - 1px);

  flex-grow: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  > form > ${PdRow} {
    width: 100%;
  }
`;

export const OverviewHeaderContainer = styled.div`
  padding: 0 24px;
`;

export const StyledTabs = styled(Tabs)`
  &&& .ant-tabs-nav .ant-tabs-tab {
    background-color: rgba(0, 0, 0, 0.06);
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
    border-bottom-color: transparent;

    &.ant-tabs-tab-active {
      background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};

      .ant-tabs-tab-btn {
        font-weight: unset !important;
        color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
      }
    }
  }
`;

export const ActionsHeader = styled.div`
  padding: 0 24px;
  background: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
`;

interface PlanLayoutProps {
  header?: React.ReactNode;
  content?: React.ReactNode;
  noTabs?: boolean;
}

export function PlanSectionLayout({
  header,
  content,
  noTabs = false,
}: PlanLayoutProps) {
  return (
    <PlanSectionLayoutContainer>
      <Row>
        {header ? (
          <Col span={24}>
            <HeaderWrapper showTopBorder={noTabs}>{header}</HeaderWrapper>
          </Col>
        ) : null}
        {content ? (
          <Col span={24}>
            <ContentWrapper>{content}</ContentWrapper>
          </Col>
        ) : null}
      </Row>
    </PlanSectionLayoutContainer>
  );
}
