import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useIsFetching } from "@tanstack/react-query";
import type {
  StickSlipByDepthUserLensTrackItemDto,
  UserLensDto,
} from "apis/oag";
import type { ScrollbarRange } from "components/Lenses/common/Scrollbar";
import {
  DETAIL_BAR_HEIGHT,
  GRID_WIDTH_QUARTER,
  HEIGHT_GRID_UNIT,
} from "components/Lenses/constants";
import { ParameterByDepthItem } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/ParameterByDepthItem";
import type { TTooltipFWRef } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/ParameterByDepthTooltip";
import { ParameterByDepthTooltip } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/ParameterByDepthTooltip";
import { LensContainer } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/style";
import {
  getChartWidth,
  getParameterAxisWidth,
  getParameterNameWidth,
  getParameterStatsWidth,
} from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/utils";
import { BottomRowWithAxis } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/BottomRowWithAxis";
import { useTrackAdd } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTrackAdd";
import { useTrackChangeOperations } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTrackChangeOperations";
import { useTrackUnits } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTrackUnits";
import { useTransformedData } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTransformedData";
import { getTransformedIndicators } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/utils/getTransformedIndicators";
import { trackUnitTypesByDepth } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/utils/trackUnitTypes";
import { useAvailableTrackIds } from "components/Lenses/ContainerLens/ParameterByDepthKPI/useAvailableTrackIds";
import type { ParameterByDepthKpiProps } from "components/Lenses/interfaces";
import { LensGridContext } from "components/Lenses/utils";
import { Loader } from "components/Loader";
import { useDirectionalIntervals } from "hooks/drillingInvariants/useDirectionalIntervals";
import { useHoleSections } from "hooks/drillingInvariants/useHoleSections";
import { useTracks } from "hooks/drillingInvariants/useTracks";
import { useLensSize } from "hooks/lens/useLensSize";
import {
  isParameterRoadmapTemplate,
  useLensTemplates,
} from "hooks/lens/useLensTemplates";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import React from "react";
import { useResizeDetector } from "react-resize-detector";
import { useAppSelector } from "reducers/store";
import { IUnitSystem } from "reducers/types";
import { RequestUID } from "utils/queryNamespaces";

export const ParameterByDepthChart = ({
  graphKey,
  isLoading,
  data,
  detailed,
  lens,
  selectedTrackItems = [],
  setSelectedTrackItems,
  onLensUpdated,
}: ParameterByDepthKpiProps) => {
  const { crtLayout, bp } = useContext(LensGridContext);
  const {
    width: _containerWidth,
    height: _containerHeight,
    ref: containerRef,
  } = useResizeDetector<HTMLDivElement>();
  const { containerWidth, containerHeight } = {
    containerWidth: Math.abs(_containerWidth ?? 0),
    containerHeight: Math.abs(_containerHeight ?? 0),
  };
  const { data: tracks } = useTracks();
  const selectedIndicators = useAppSelector(
    (state) => state.widgetOptions.indicators,
  );
  const { data: holeSections } = useHoleSections();
  const { data: intervals } = useDirectionalIntervals();

  const { data: templates } = useLensTemplates();
  const template = templates.byId[lens.lensTemplateId];
  const isRoadmap = isParameterRoadmapTemplate(template);

  const [maxChartHeight, setMaxChartHeight] = useState<number | null>(null);
  const [trackCount, setTrackCount] = useState(2);
  const [showGraph, setShowGraph] = useState(true);

  const isFetching = useIsFetching({
    queryKey: [{ uid: RequestUID.parameterByDepthFacts, lensId: lens.id }],
    exact: false,
  });

  const [scrollState, setScrollState] = useState<ScrollbarRange>({
    startX: 0,
    endX: 0,
  });

  const [isTooltipEnabled, setTooltipEnabled] = useState(false);
  const [isPointerInsideChart, setPointerInsideChart] = useState(false);
  const [containerGridWidth, containerGridHeight] = useLensSize(lens.id);
  const sensors = useSensors(useSensor(PointerSensor));
  const chartWidth = getChartWidth(detailed, containerWidth ?? 0);
  const stringIds = useMemo(
    () => selectedTrackItems.map((t) => t.trackId?.toString() ?? "-1"),
    [selectedTrackItems],
  );
  const availableTrackIds = useAvailableTrackIds(selectedTrackItems);

  const { selectedUnits, selectedUom } = useTrackUnits({
    trackUnitTypes: trackUnitTypesByDepth,
    tracks,
    selectedTrackItems,
  });

  const { depthScale, flatTracks, tracksById } = useTransformedData({
    selectedTrackItems,
    lens,
    scrollState,
    chartWidth,
    data,
  });

  const [offsetSelection] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );

  const variant =
    !detailed &&
    offsetSelection.length > 2 &&
    offsetSelection.length < 5 &&
    selectedTrackItems.length > 2
      ? "large"
      : "regular";

  useEffect(() => {
    if (detailed) return;

    const numTrack = selectedTrackItems?.length;
    if (!containerGridHeight || !containerGridWidth) {
      return;
    }

    setShowGraph(containerGridWidth > GRID_WIDTH_QUARTER);
    setTrackCount(numTrack);
    const containerMargin = 15;
    const height =
      (HEIGHT_GRID_UNIT * containerGridHeight -
        DETAIL_BAR_HEIGHT -
        (detailed ? 0 : 40) +
        containerMargin) /
      numTrack;
    const defaultMaxHeight = variant === "large" ? 90 : 76;
    setMaxChartHeight(
      Number.isFinite(height)
        ? Math.max(defaultMaxHeight, Math.ceil(height))
        : null,
    );
  }, [
    variant,
    bp,
    crtLayout,
    detailed,
    containerGridHeight,
    offsetSelection.length,
    containerGridWidth,
    graphKey,
    selectedTrackItems,
  ]);

  const {
    handleChangeTracksOrderAndUpdateLens,
    openTrackSettingsModal,
    trackSettingsModalElement,
  } = useTrackChangeOperations({
    selectedUnits,
    availableTrackIds,
    selectedUom,
    lens,
    tracks,
    stringIds,
    selectedTrackItems,
    setSelectedTrackItems,
    onLensUpdated,
    trackUnitTypes: trackUnitTypesByDepth,
  });

  const { TrackAddModalElement, openTrackAddModal } = useTrackAdd({
    lens,
    selectedTrackItems,
    onAdd: (tracks) => {
      onLensUpdated?.({ ...lens, userLensTrackItems: tracks } as UserLensDto);
      setSelectedTrackItems?.(tracks);
    },
  });

  const transformedIndicators = getTransformedIndicators({
    data,
    selectedIndicators,
    holeSections,
    intervals,
  });

  const getUnitSystem = useCallback(
    (track: StickSlipByDepthUserLensTrackItemDto) => {
      if (track.isUomUnlocked) {
        return undefined;
      } else
        return track.systemOfMeasurementType === "Imperial"
          ? IUnitSystem.IMPERIAL
          : IUnitSystem.METRIC;
    },
    [],
  );

  const refArray = useMemo(() => {
    return Array.from({ length: selectedTrackItems.length }, () =>
      createRef<TTooltipFWRef>(),
    );
  }, [selectedTrackItems.length]);
  const svgRefs = useMemo(() => {
    return Array.from({ length: selectedTrackItems.length }, () =>
      createRef<SVGSVGElement>(),
    );
  }, [selectedTrackItems.length]);

  const leftOffset = useMemo(() => {
    return (
      getParameterNameWidth(detailed) +
      getParameterStatsWidth(detailed) +
      getParameterAxisWidth(detailed)
    );
  }, [detailed]);

  return (
    <>
      <LensContainer
        className={"lens-container"}
        ref={containerRef}
        rows={detailed ? selectedTrackItems.length : trackCount ?? 3}
        detailed={detailed}
        onMouseLeave={() => setPointerInsideChart(false)}
        onMouseEnter={() => setPointerInsideChart(true)}
        onMouseMove={() => setPointerInsideChart(true)}
        variant={variant}
      >
        {tracks ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleChangeTracksOrderAndUpdateLens}
          >
            <SortableContext
              items={stringIds}
              strategy={verticalListSortingStrategy}
            >
              {selectedTrackItems.map((track, index) => {
                if ((isFetching || isLoading) && !tracksById?.[track.trackId]) {
                  return (
                    <div
                      key={`${selectedTrackItems.length}_${track.trackId}_${lens.id}_${index}`}
                      // eslint-disable-next-line react/forbid-dom-props
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Loader size={24} />
                    </div>
                  );
                }
                return (
                  <React.Fragment
                    key={`${selectedTrackItems.length}_${track.trackId}_${lens.id}_${index}`}
                  >
                    <ParameterByDepthItem
                      chartWidth={chartWidth}
                      depthScale={depthScale}
                      detailed={detailed}
                      indicators={transformedIndicators}
                      isComparing={false}
                      isTopMost={index === 0}
                      isUnitUnlocked={track.isUomUnlocked}
                      forcedUnitSystem={getUnitSystem(track)}
                      key={`${selectedTrackItems.length}_${track.trackId}_${lens.id}_${index}`}
                      maxChartHeight={maxChartHeight || 0}
                      onPointerPositionChange={(position) => {
                        refArray.forEach((ref) => {
                          ref.current?.setPointerPosition(position);
                        });
                      }}
                      onTrackSettings={() => openTrackSettingsModal(track)}
                      selectedUnit={selectedUnits[track.trackId]}
                      showGraph={showGraph}
                      trackCount={selectedTrackItems.length}
                      tracks={(tracksById && tracksById?.[track.trackId]) || []}
                      yMax={
                        track.isManualYaxis
                          ? track.yaxisEnd || undefined
                          : undefined
                      }
                      yMin={
                        track.isManualYaxis
                          ? track.yaxisStart || undefined
                          : undefined
                      }
                      isRoadmap={isRoadmap}
                      svgRef={svgRefs[index]}
                      chartHeightPassThrough={(height) => {
                        refArray.forEach((ref) => {
                          ref.current?.setChartHeight(height);
                        });
                      }}
                      highlightedInterval={null}
                    />
                    <ParameterByDepthTooltip
                      ref={refArray[index]}
                      index={index}
                      chartWidth={chartWidth}
                      depthScale={depthScale}
                      detailed={detailed}
                      leftOffset={leftOffset}
                      indicators={transformedIndicators}
                      isComparing={false}
                      isTopMost={index === 0}
                      isUnitUnlocked={track.isUomUnlocked}
                      forcedUnitSystem={getUnitSystem(track)}
                      key={`${selectedTrackItems.length}_${track.trackId}`}
                      maxChartHeight={maxChartHeight || 0}
                      onTrackSettings={() => openTrackSettingsModal(track)}
                      selectedUnit={selectedUnits[track.trackId]}
                      showGraph={showGraph}
                      trackCount={selectedTrackItems.length}
                      tracks={(tracksById && tracksById?.[track.trackId]) || []}
                      yMax={
                        track.isManualYaxis
                          ? track.yaxisEnd || undefined
                          : undefined
                      }
                      yMin={
                        track.isManualYaxis
                          ? track.yaxisStart || undefined
                          : undefined
                      }
                      isRoadmap={isRoadmap}
                      highlightedInterval={null}
                      onTooltipToggle={() => setTooltipEnabled((prev) => !prev)}
                      isPointerInsideChart={isPointerInsideChart}
                      isTooltipEnabled={isTooltipEnabled}
                      svgRef={svgRefs[index]}
                    />
                  </React.Fragment>
                );
              })}
            </SortableContext>
          </DndContext>
        ) : null}
      </LensContainer>

      <>
        <BottomRowWithAxis
          setScrollState={setScrollState}
          availableTrackIds={availableTrackIds}
          detailed={detailed}
          openTrackAddModal={openTrackAddModal}
          chartWidth={chartWidth}
          containerHeight={containerHeight ? containerHeight : 0}
          flatTracks={flatTracks}
          depthScale={depthScale}
          lens={lens}
          isStickSlipByDepth={false}
        />
        {TrackAddModalElement}
        {trackSettingsModalElement}
      </>
    </>
  );
};
