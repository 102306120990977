import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import { useCallback, useMemo, useState } from "react";
import { Track } from "services/Mixpanel";
import { Popover, Tooltip } from "utils/componentLibrary";

const trackingTitle = "Notifications Page";

interface RigViewmodel {
  id: number;
  name: string;
}

export const RigFilterDropdownButton = ({
  selectedRigIds,
  setSelectedRigIds,
  rigList,
}: {
  selectedRigIds: number[];
  setSelectedRigIds: (rigIds: number[]) => void;
  rigList: RigViewmodel[];
}) => {
  const [showRigPopup, setShowRigPopup] = useState(false);

  const resetSelection = useCallback(() => {
    setSelectedRigIds(rigList.map((rig) => rig.id));
  }, [rigList, setSelectedRigIds]);

  const updateRigSelection = useCallback(
    (currSelRigIds: number[]) => {
      Track.clickEvent("Notification Settings - Apply Rigs filter");
      setSelectedRigIds(
        rigList
          .filter((rig) => currSelRigIds.includes(rig.id))
          .map((rig) => rig.id),
      );
    },
    [rigList, setSelectedRigIds],
  );

  const buttonType = useMemo(() => {
    if (selectedRigIds.length === rigList.length) return "default";
    return "primary";
  }, [rigList.length, selectedRigIds.length]);

  return (
    <Tooltip title="Rig">
      <Popover
        content={
          <PDComponent.ComboBoxMultiSelect
            placeholder="Search Rigs"
            options={rigList}
            values={selectedRigIds}
            onReset={() => {
              Track.interact(`${trackingTitle} - Rigs Reset`);
              resetSelection();
              setShowRigPopup(false);
            }}
            onChange={(e: number[]) => {
              Track.interact(`${trackingTitle} - Rigs`, { Action: "Update" });
              updateRigSelection(e);
              setShowRigPopup(false);
            }}
          />
        }
        trigger="click"
        placement="bottomLeft"
        open={showRigPopup}
        onOpenChange={(e) => setShowRigPopup(e)}
        destroyTooltipOnHide
      >
        <Button
          size="large"
          icon={<PDComponent.SvgIcon name="rig" />}
          type={buttonType}
          ghost={buttonType === "primary"}
          $engaged={showRigPopup}
        />
      </Popover>
    </Tooltip>
  );
};
