import { TimeUnit } from "apis/oag";
import { Title } from "atoms/Typography";
import { NO_DATA_KPI_STRING } from "components/Lenses/constants";
import { PDComponent } from "components/PDComponents";
import { ScoreBreakoutModalTab } from "pages/RigLeaderboard/components/ScoreBreakout/Modal";
import * as Styled from "pages/RigLeaderboard/components/ScoreBreakout/styles";
import type { IPartialRig } from "pages/RigLeaderboard/components/ScoreBreakout/useScoreBreakoutModal";
import { CustomTag } from "pages/RigScoreCard/LeftPane/components/CustomTag";
import {
  getColorByNetTime,
  getColorByScore,
  scoreColors,
} from "pages/RigScoreCard/LeftPane/utils";
import {
  BenchmarkType,
  useScoreBenchmarkContext,
} from "pages/RigScoreCard/ScoreBenchmarkContext";
import React from "react";
import { useTimeUom, useUOM, UtilDimensions } from "utils/format";

export const Header = ({
  selectedRig,
  selectedView,
  setSelectedView,
  onCancel,
  justComment,
}: {
  selectedRig: IPartialRig;
  selectedView: ScoreBreakoutModalTab;
  setSelectedView: React.Dispatch<React.SetStateAction<ScoreBreakoutModalTab>>;
  onCancel: (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => void;
  justComment?: boolean;
}) => {
  const hourUom = useTimeUom(TimeUnit.Hour);
  const percentageUom = useUOM(UtilDimensions.Percentage);
  const { scoreBenchmark } = useScoreBenchmarkContext();

  const opportunityValue = selectedRig.opportunityTime;
  const scoreValue = selectedRig.score;

  return (
    <Styled.HeaderContainer>
      <Styled.HeaderLeftContainer>
        <Styled.DescriptionNumber>{selectedRig?.name}</Styled.DescriptionNumber>
        {selectedRig?.description ? (
          <Styled.Description>{selectedRig?.description}</Styled.Description>
        ) : null}
      </Styled.HeaderLeftContainer>

      <Styled.HeaderRightContainer>
        {justComment ? null : (
          <Styled.SwitchButton
            $isCombinedComments={
              selectedView === ScoreBreakoutModalTab.Comments
            }
          >
            <PDComponent.SvgIcon
              name="rigScores"
              onClick={() => setSelectedView(ScoreBreakoutModalTab.Breakout)}
            />
            <PDComponent.SvgIcon
              name="book"
              onClick={() => setSelectedView(ScoreBreakoutModalTab.Comments)}
            />
          </Styled.SwitchButton>
        )}
        <Styled.CloseButton onClick={onCancel}>
          <PDComponent.SvgIcon name="close" />
        </Styled.CloseButton>
      </Styled.HeaderRightContainer>
      {justComment ? null : (
        <div>
          <CustomTag
            icon={<PDComponent.SvgIcon name="meter" />}
            color={getColorByScore(scoreValue || 0)}
            text={percentageUom.display(scoreValue, { fractionDigits: 1 })}
            variant="default"
            size="default"
          />
          <Styled.OpportunityTime
            $color={scoreColors[getColorByNetTime(opportunityValue || 0)]}
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <Title
              level={4}
              variant="faded"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {scoreBenchmark === BenchmarkType.PDBenchmark
                ? "Time vs Benchmark"
                : "Time vs Target"}
              :
            </Title>
            {opportunityValue && opportunityValue > 0 ? "+" : ""}
            {Number.isFinite(opportunityValue)
              ? hourUom.display(opportunityValue)
              : NO_DATA_KPI_STRING}
          </Styled.OpportunityTime>
        </div>
      )}
    </Styled.HeaderContainer>
  );
};
