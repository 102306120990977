import { MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import { baseMatrix } from "components/Lenses/ContainerLens/common/utils/constants";
import type {
  AbilityMatrixProps,
  AbilityMatrixType,
} from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";

export const getDrillingProductivityAbilityMatrix = (
  props?: AbilityMatrixProps,
): AbilityMatrixType => {
  return {
    ...baseMatrix,
    unitOfTime: MatrixAvailability.NOT_SUPPORTED,
    unitOfTimeHourDay: MatrixAvailability.AVAILABLE,
    selectedVisualAids: MatrixAvailability.NOT_SUPPORTED,
    outlierFlaggingType: MatrixAvailability.NOT_SUPPORTED,
    showsOutliers: MatrixAvailability.NOT_SUPPORTED,
    showOperationCount: MatrixAvailability.NOT_SUPPORTED,
    indicators: MatrixAvailability.NOT_SUPPORTED,
    squeezesDisplay: props?.isComparing
      ? MatrixAvailability.NOT_SUPPORTED
      : MatrixAvailability.AVAILABLE,
  };
};
