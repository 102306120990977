import type {
  ApiFuelConsumptionByGeneratorUserLensesIdFactsPutRequest,
  DateDto,
  WellDrillingSummaryDto,
} from "apis/oag";
import { DashboardType, UserRoleType, WellStatusType } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { isString } from "lodash";
import type { IZoomData } from "reducers/types";
import { IZoomType } from "reducers/types";
import superjson from "superjson";
import { defaultDateDto } from "utils/common";
import { SHOW_DECIMALS_LIMIT } from "utils/constants";
import { DashboardTypeSlugs, SortDirections } from "utils/enums";

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const highPrivilegeRoles: UserRoleType[] = [
  UserRoleType.OptimizationEngineer,
  UserRoleType.Administrator,
];

export const statusIconSelector = (f: WellStatusType) => {
  switch (f) {
    case WellStatusType.Active:
      return <PDComponent.SvgIcon name="playOutline" />;
    case WellStatusType.Completed:
      return <PDComponent.SvgIcon name="checkmarkOutline" />;
    case WellStatusType.Paused:
      return <PDComponent.SvgIcon name="pauseOutline" />;
    case WellStatusType.Pending:
      return <PDComponent.SvgIcon name="time" />;
    default:
      return null;
  }
};

const now = new Date();
export const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
export const last30Days = dayjs(today).subtract(29, "day").toDate();
export const last90Days = dayjs(today).subtract(89, "day").toDate();

export const range30Days = { startDate: last30Days, endDate: today };
export const range90Days = { startDate: last90Days, endDate: today };
export const rangeAll = { startDate: null, endDate: null };

export const msInMin = 60000;
export const formatDate = (
  date: Date,
  options: { formatStr?: string; useUTC?: boolean } = {
    formatStr: "MM/DD/YYYY",
    useUTC: false,
  },
) => {
  return dayjs
    .tz(
      date.getTime() +
        (options.useUTC ? 0 : new Date(date).getTimezoneOffset() * msInMin),
      options.useUTC ? "UTC" : dayjs.tz.guess(),
    )
    .format(options?.formatStr);
};

export const toLocalWellTime = (time: DateDto, ignoreLocalTime?: boolean) => {
  return (
    time.utc.getTime() +
    time.minutesOffset * msInMin +
    (ignoreLocalTime ? 0 : new Date(time.utc).getTimezoneOffset() * msInMin)
  );
};

export const fromLocalWellTime = (time: DateDto, ignoreLocalTime?: boolean) => {
  return (
    time.utc.getTime() -
    time.minutesOffset * msInMin -
    (ignoreLocalTime ? 0 : new Date(time.utc).getTimezoneOffset() * msInMin)
  );
};

export const formatTime = (
  time: DateDto | string,
  options: {
    localeFormat?: boolean;
    localeFormatStr?: string;
    formatStr?: string;
    offset?: number;
    ignoreLocalTime?: boolean;
  },
) => {
  if (isString(time)) return time;
  if (!time?.utc) return "";

  let date = toLocalWellTime(time, options?.ignoreLocalTime);
  if (options?.offset) {
    date += options.offset;
  }
  if (options?.localeFormat) {
    return dayjs(date).format(options.localeFormatStr);
  }
  if (options?.formatStr) {
    return dayjs(date).format(options.formatStr);
  }
  return new Date(date).toString();
};

export const getTimeStamp = (
  time: DateDto,
  options?: {
    ignoreLocalTime?: boolean;
  },
) => {
  return (
    time.utc.getTime() +
    time.minutesOffset * msInMin +
    (options?.ignoreLocalTime
      ? 0
      : new Date(time.utc).getTimezoneOffset() * msInMin)
  );
};

export const generateQuickGuid = () => {
  return Math.random().toString(36).substring(2, 15);
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const blobToDownload = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export function truncateMiddleString(str: string, length: number): string {
  if (!str) return "";
  const partSize = Math.floor(length / 2);
  const ignoreLength = 1;
  if (partSize >= str.length - partSize - ignoreLength) {
    return str;
  }

  return (
    str.substring(0, partSize) +
    "..." +
    str.substring(str.length - partSize, str.length)
  );
}

export const dateToDateOnlyDto: (date: Date) => DateDto = (date) => ({
  utc: new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0),
  ),
  minutesOffset: 0,
  isDateOnly: true,
});

export const dateToDateDto: (date?: Date | null) => DateDto | null = (date) => {
  return date
    ? {
        isDateOnly: false,
        utc: date,
        minutesOffset: 0,
      }
    : null;
};

export const sortByValue = (a: number, b: number, sortDir: SortDirections) => {
  return sortDir === SortDirections.Asc ? a - b : b - a;
};

export const transformKey = (key: string) => {
  if (!key) return "";
  let title = "";
  if (key.includes("Sliding")) title += "Sliding";
  if (key.includes("Rotating")) title += "Rotation";
  if (title) {
    if (key.includes("Distance")) return `${title} (Distance)`;
    if (key.includes("Time")) return `${title} (Time)`;
    return title;
  }
  return key;
};

const getStatusSortValue = (w: WellDrillingSummaryDto) => {
  switch (w.status) {
    case WellStatusType.Pending:
      return 1;
    case WellStatusType.Paused:
    case WellStatusType.Completed:
      return 2;
    case WellStatusType.Active:
      return 3;
  }
};

export const sortByRigs = (
  a: WellDrillingSummaryDto,
  b: WellDrillingSummaryDto,
  sortDir: SortDirections,
  rigSort: (a: number, b: number) => number,
) => {
  const aRigs = [...new Set(a?.jobs?.map((e) => e.rigId))];
  const bRigs = [...new Set(b?.jobs?.map((e) => e.rigId))];

  if (aRigs.length > 1 && bRigs.length > 1) {
    // Sort by second rig item
    return sortDir === SortDirections.Asc
      ? rigSort(bRigs[bRigs.length - 1], aRigs[aRigs.length - 1])
      : rigSort(aRigs[aRigs.length - 1], bRigs[bRigs.length - 1]);
  }

  if (aRigs.length < 1 || bRigs.length < 1) {
    // Sort by length of item because we want wells with empty/no rigs to be at the end
    return sortDir === SortDirections.Asc
      ? aRigs.length - bRigs.length
      : bRigs.length - aRigs.length;
  }

  // Sort by second rig item
  return sortDir === SortDirections.Asc
    ? rigSort(bRigs[0], aRigs[0])
    : rigSort(aRigs[0], bRigs[0]);
};

export const sortWellsByEnd = (
  a: WellDrillingSummaryDto,
  b: WellDrillingSummaryDto,
  sortDir: SortDirections,
  rigSort: (a: number, b: number) => number,
) => {
  const statusValueA = getStatusSortValue(a);
  const statusValueB = getStatusSortValue(b);
  if (statusValueA > statusValueB)
    return sortDir === SortDirections.Asc ? 1 : -1;
  if (statusValueA < statusValueB)
    return sortDir === SortDirections.Asc ? -1 : 1;

  // Only Active wells are sorted by Rigs. Skip this step for other statuses.
  if (a.status === WellStatusType.Active && b.status === WellStatusType.Active)
    return sortByRigs(a, b, sortDir, rigSort);

  const timeValueA = a.lastWellFactUpdateAt?.utc.getTime() ?? 0;
  const timeValueB = b.lastWellFactUpdateAt?.utc.getTime() ?? 0;
  if (timeValueA > timeValueB) return sortDir === SortDirections.Asc ? 1 : -1;
  if (timeValueA < timeValueB) return sortDir === SortDirections.Asc ? -1 : 1;

  // last criteria of "SortBy.End": always by alphabetical order, whatever the SortDirection
  return (a.name ?? "").localeCompare(b.name ?? "");
};

export const getQuery = (well: number) => {
  const location = window.location.search;
  const entries = location.replace("?", "").split("&");
  const entrObj = Object.fromEntries(entries.map((e) => e.split("=")));
  const qsArr = entries
    .map((e) => {
      if (e.startsWith("ofsW=") || e.startsWith("ofsWW=")) {
        let crtSelectedOffset: Array<number> = [];
        try {
          crtSelectedOffset = superjson
            .parse<number[]>(window.atob(entrObj["ofsW"] ?? ""))
            .filter((e) => e !== well);
        } catch (e) {
          crtSelectedOffset = [];
        }
        if (crtSelectedOffset.length > 0)
          return `${e.split("=")[0]}=${window.btoa(superjson.stringify(crtSelectedOffset))}`;
        return "";
      }
      if (e.startsWith("selectedTab=")) {
        return e;
      }
      return "";
    })
    .filter((e) => e);
  return qsArr;
};

export const checkDashboardType = (path: string) => {
  if (path.toLocaleLowerCase().startsWith(DashboardTypeSlugs.Well)) {
    return DashboardType.Well;
  } else if (
    path.toLocaleLowerCase().startsWith(DashboardTypeSlugs.Evergreen)
  ) {
    return DashboardType.EverGreen;
  } else if (path.toLocaleLowerCase().startsWith(DashboardTypeSlugs.Rig)) {
    return DashboardType.Rig;
  }
  return null;
};

export const dashboardTypeToUrl = (dashboardType: DashboardType) => {
  if (dashboardType === DashboardType.Well) {
    return DashboardTypeSlugs.Well;
  } else if (dashboardType === DashboardType.EverGreen) {
    return DashboardTypeSlugs.Evergreen;
  }
  return DashboardType.Rig;
};

export const getRequestParametersWithZoom = (
  requestParameters: ApiFuelConsumptionByGeneratorUserLensesIdFactsPutRequest,
  zoomData: IZoomData,
) => {
  if (zoomData.date_start && zoomData.type === IZoomType.DATE) {
    return {
      ...requestParameters,
      baseFuelQueryDto: requestParameters.baseFuelQueryDto
        ? {
            ...requestParameters.baseFuelQueryDto,
            from: dateToDateDto(zoomData.date_start) || defaultDateDto.from,
            to: dateToDateDto(zoomData.date_end) || defaultDateDto.to,
          }
        : undefined,
    };
  }
  return requestParameters;
};

export const formatTickDecimalsByDomain = (
  value: number | string,
  decimalPlaces: number = 0,
  domain: number[] = [],
  isManual: boolean = false,
) => {
  const [min, max] = domain;
  let fractionDigits = decimalPlaces;

  const decimalsForcedByManualAxis = isManual && (min % 1 || max % 1);
  const decimalsDiffExceedingLimit = Math.abs(max - min) > SHOW_DECIMALS_LIMIT;
  const decimalsMaxExceedingLimit = max > SHOW_DECIMALS_LIMIT;

  if (
    !decimalsForcedByManualAxis &&
    (decimalsDiffExceedingLimit || decimalsMaxExceedingLimit)
  ) {
    fractionDigits = 0;
  }

  const sanitizedValue =
    typeof value === "string"
      ? +value.replaceAll(",", "").replaceAll("−", "-")
      : value;

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(sanitizedValue);
};

export const calculateExtremitiesBisectFix = (
  pointerPosition: {
    x: number;
    y: number;
  },
  plotWidth: number,
) => {
  if (pointerPosition.x < 30) {
    // To make sure we can select both points at the edge of the graph, normal  bisect leaves out either one or the other
    // Artificially adding mouse X if it's close to the plot edges to get correct edge index
    return -0.001;
  } else if (Math.abs(pointerPosition.x - plotWidth) < 10) {
    return 0.001;
  } else {
    return 0;
  }
};

export const getFuelFractionalDigits = (value: number) => {
  if (value < 1) {
    return 2;
  } else if (value < 10) {
    return 1;
  }
  return 0;
};

export const ratioToPercent = (ratio?: number, fractionDigits: number = 2) =>
  ratio ? `${(ratio * 100).toFixed(fractionDigits)}%` : "--";

export const stripSlashes = (str: string) => str.replaceAll("/", "");

export const TWO_DECIMAL_PLACES_INPUT_MASK = "^d*(.d{0,2})?$";
