import { FuelType, GeneratorSlotType } from "apis/oag";
import { LegendPreviewerType } from "components/Lenses/common/ChartLegend/interfaces";
import colors from "utils/colors";
import { OperationClasses } from "utils/enums";

export function getAxisFontSize(isDetailed?: boolean) {
  return isDetailed ? 16 : 14;
}

export function getManualAxisLabelSize() {
  // Suited best for default date format
  return 120;
}

export function getManualAxisLabelSizeAlt(isDetailed?: boolean) {
  // Suited best for alt date format
  return isDetailed ? 115 : 100;
}

export function getInternalAxisMargin(isDetailed?: boolean) {
  return isDetailed ? 8 : 6;
}

export const getLensOrientation = (w: number, h: number) => {
  return w <= h * 1.5 ? "vertical" : "horizontal";
};
export const TOP_AXIS_PADDING_FACTOR = 1.1;
export const SHADING_BANDWIDTH_PERCENTAGE = 1.15;
export const SHADING_OFFSET_PERCENTAGE = (SHADING_BANDWIDTH_PERCENTAGE - 1) / 2;

export const SUMMARY_HEIGHT_MINI = 64;
export const SUMMARY_HEIGHT_DETAILED = 143;

export const LENS_CONTROLS_BAR_HEIGHT = 40;
export const X_AXIS_HEIGHT = 30;
export const CLIP_SERIES_ID = "clip-series-threshold";
export const MINI_ZOOM_FACTOR = 4;
export const DETAILED_ZOOM_FACTOR = 2;

// RIGHT AXIS
export const LATERAL_AXIS_WHITESPACE_WIDTH = 24;
export const LATERAL_AXIS_TEXT_WIDTH = 22;
export const LATERAL_AXIS_WIDTH =
  LATERAL_AXIS_TEXT_WIDTH * 2 + LATERAL_AXIS_WHITESPACE_WIDTH + 5;
export const LATERAL_LABEL_POSITION =
  LATERAL_AXIS_TEXT_WIDTH +
  LATERAL_AXIS_WHITESPACE_WIDTH / 2 +
  getAxisFontSize(false) / 2;
export const TEXT_WIDTH_WHEN_VERTICAL = 11;

// LEFT AXIS
export const LATERAL_AXIS_LEFT_MARGIN = 20;
export const LATERAL_AXIS_LEFT_WIDTH = 64;

export const BOTTOM_AXIS_HEIGHT = 25;
export const SECONDARY_AXIS_PADDING = 10;

// COLORS FOR GENERATORS
export const generatorColorsBySlotType: Record<GeneratorSlotType, string> = {
  [GeneratorSlotType.None]: colors.marble_green_grey,
  [GeneratorSlotType.Generator1]: colors.waterfall,
  [GeneratorSlotType.Generator2]: colors.perrywinkle,
  [GeneratorSlotType.Generator3]: colors.girl_power,
  [GeneratorSlotType.Generator4]: colors.balanced_beige,
};

export const TEXT_ALL_GEN = "All Gens";

// LABELS FOR GENERATORS
export const generatorLabelsBySlotType: Record<GeneratorSlotType, string> = {
  [GeneratorSlotType.None]: TEXT_ALL_GEN,
  [GeneratorSlotType.Generator1]: "Gen 1",
  [GeneratorSlotType.Generator2]: "Gen 2",
  [GeneratorSlotType.Generator3]: "Gen 3",
  [GeneratorSlotType.Generator4]: "Gen 4",
};

export const getGeneratorLabelsBySlotType = (
  generators: GeneratorSlotType[],
) => {
  if (generators?.length === 1) {
    return generatorLabelsBySlotType[generators[0]];
  }
  return TEXT_ALL_GEN;
};

export const getFuelLabelsByFuelType = (fuelType: FuelType) => {
  if (fuelType === FuelType.Diesel) {
    return "Diesel";
  }
  if (fuelType === FuelType.NaturalGas) {
    return "Nat Gas";
  }
  return "";
};

export const getGeneratorColorsBySlotType = (
  generators: GeneratorSlotType[],
) => {
  if (generators?.length === 1) {
    return generatorColorsBySlotType[generators[0]];
  }
  return generatorColorsBySlotType[GeneratorSlotType.None];
};

export const getGeneratorSlotTypeByNumber = (num: number) => {
  const generatorNumberToSlotType = {
    0: GeneratorSlotType.None,
    1: GeneratorSlotType.Generator1,
    2: GeneratorSlotType.Generator2,
    3: GeneratorSlotType.Generator3,
    4: GeneratorSlotType.Generator4,
  };
  return (
    generatorNumberToSlotType[num as keyof typeof generatorNumberToSlotType] ||
    GeneratorSlotType.None
  );
};

export const getGeneratorNumberBySlotType = (generator: GeneratorSlotType) => {
  const generatorSlotTypeToNumber = {
    [GeneratorSlotType.None]: 0,
    [GeneratorSlotType.Generator1]: 1,
    [GeneratorSlotType.Generator2]: 2,
    [GeneratorSlotType.Generator3]: 3,
    [GeneratorSlotType.Generator4]: 4,
  };
  return generator ? generatorSlotTypeToNumber[generator] : 0;
};

export const defaultGeneratorLegendItems = [
  GeneratorSlotType.Generator1,
  GeneratorSlotType.Generator2,
  GeneratorSlotType.Generator3,
  GeneratorSlotType.Generator4,
].map((genId) => ({
  id: genId,
  name: generatorLabelsBySlotType[genId],
  color: generatorColorsBySlotType[genId],
  isEnabled: true,
  previewerType: LegendPreviewerType.BOX,
  columnValues: {
    Min: "--",
    Max: "--",
    Mean: "--",
  },
}));

export const fuelColors = {
  diesel: colors.sunburnt_toes,
  natGas: colors.livid,
};

export const boilerColors = {
  boiler1: colors.coral_expression,
  boiler2: colors.exciting_orange,
};

export const operationColorsByExternalId = {
  [OperationClasses.DrillingFormation]: colors.perrywinkle,
  [OperationClasses.TrippingIn]: colors.perrywinkle,
  [OperationClasses.TrippingOut]: colors.exciting_orange,
  [OperationClasses.Casing]: colors.flickering_sea,
  [OperationClasses.LDDP]: colors.fresco_green,
  [OperationClasses.PUDP]: colors.tanager_turquoise,
  [OperationClasses.Surface]: colors.helen_of_troy,
  [OperationClasses.Drilling]: colors.green_fluorite,
  [OperationClasses.WellConditioningTreatment]: colors.perfect_pear,
  [OperationClasses.CementingandBOPWork]: colors.prettiest_pink,
  [OperationClasses.Liner]: colors.charybdis,
  [OperationClasses.TrippingwithLiner]: colors.green_chalk,
  [OperationClasses.InsufficientData]: colors.coral_expression,
  [OperationClasses.DataGap]: colors.sunburnt_toes,
  [OperationClasses.Unknown]: colors.marble_green_grey,
};

export const generatorColorsByActiveCount = {
  1: "#d5aba4",
  2: "#5dc0cd",
  3: "#c2b8a0",
  4: "#726588",
};

export function addIndexToSeries<T>(series: T[] = []) {
  return series.map((point, index) => ({ ...point, index: index + 1 }));
}

export type WithIndex<T> = T & { index: number };
