import type { RigDto } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { CheckboxCheckedState } from "components/PDComponents/Checkbox/Checkbox";
import * as Styled from "pages/NotificationSettings/RigList/style";
import type { ListChildComponentProps } from "react-window";

interface RowItemProps extends ListChildComponentProps {
  data: {
    rigsList: RigDto[];
    handleOnToggleCommentForRig: (rigId: number, isChecked: boolean) => void;
    handleOnToggleExceptionForRig: (rigId: number, isChecked: boolean) => void;
    rigIdsSubscribedComments: number[];
    rigIdsSubscribedExceptions: number[];
  };
}

export const VirtualizedRigRow = ({ index, style, data }: RowItemProps) => {
  const rig = data.rigsList[index];
  const {
    handleOnToggleCommentForRig,
    handleOnToggleExceptionForRig,
    rigIdsSubscribedComments,
    rigIdsSubscribedExceptions,
  } = data;

  return (
    <Styled.Row key={rig.id} style={style}>
      <Styled.Col>
        <Styled.RigName
          level={3}
          ellipsis={{ rows: 1 }}
          title={rig.shortName ?? ""}
        >
          {rig.shortName}
        </Styled.RigName>
      </Styled.Col>

      <Styled.Col>
        <Styled.CheckboxWithLabel>
          <label htmlFor={`comments-${rig.id}`}>Scorecard Comments</label>
          <PDComponent.Checkbox
            id={`comments-${rig.id}`}
            checkedState={
              rigIdsSubscribedComments.includes(rig.id)
                ? CheckboxCheckedState.Checked
                : CheckboxCheckedState.Unchecked
            }
            onChange={(isChecked) =>
              handleOnToggleCommentForRig(rig.id, isChecked)
            }
            size={14}
          />
        </Styled.CheckboxWithLabel>
      </Styled.Col>

      <Styled.Col>
        <Styled.CheckboxWithLabel>
          <label htmlFor={`exceptions-${rig.id}`}>Exceptions</label>
          <PDComponent.Checkbox
            id={`exceptions-${rig.id}`}
            checkedState={
              rigIdsSubscribedExceptions.includes(rig.id)
                ? CheckboxCheckedState.Checked
                : CheckboxCheckedState.Unchecked
            }
            onChange={(isChecked) =>
              handleOnToggleExceptionForRig(rig.id, isChecked)
            }
            size={14}
          />
        </Styled.CheckboxWithLabel>
      </Styled.Col>
    </Styled.Row>
  );
};
