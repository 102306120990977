/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TagBottomFingerprintTrackDetailsDto } from './TagBottomFingerprintTrackDetailsDto';
import {
    TagBottomFingerprintTrackDetailsDtoFromJSON,
    TagBottomFingerprintTrackDetailsDtoFromJSONTyped,
    TagBottomFingerprintTrackDetailsDtoToJSON,
} from './TagBottomFingerprintTrackDetailsDto';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { StandDrillingInfoDto } from './StandDrillingInfoDto';
import {
    StandDrillingInfoDtoFromJSON,
    StandDrillingInfoDtoFromJSONTyped,
    StandDrillingInfoDtoToJSON,
} from './StandDrillingInfoDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface TagBottomFingerprintFactSetDto
 */
export interface TagBottomFingerprintFactSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof TagBottomFingerprintFactSetDto
     */
    readonly stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof TagBottomFingerprintFactSetDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintFactSetDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof TagBottomFingerprintFactSetDto
     */
    lastUpdatedAt?: DateDto | null;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintFactSetDto
     */
    wellId: number;
    /**
     * 
     * @type {Array<TagBottomFingerprintTrackDetailsDto>}
     * @memberof TagBottomFingerprintFactSetDto
     */
    tracks: Array<TagBottomFingerprintTrackDetailsDto>;
    /**
     * 
     * @type {Array<StandDrillingInfoDto>}
     * @memberof TagBottomFingerprintFactSetDto
     */
    slipsToBottomStands: Array<StandDrillingInfoDto>;
    /**
     * 
     * @type {FiltersDto}
     * @memberof TagBottomFingerprintFactSetDto
     */
    availableFilters: FiltersDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof TagBottomFingerprintFactSetDto
     */
    readonly dataState: ResultDataState;
}



/**
 * Check if a given object implements the TagBottomFingerprintFactSetDto interface.
 */
export function instanceOfTagBottomFingerprintFactSetDto(value: object): value is TagBottomFingerprintFactSetDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('tracks' in value) || value['tracks'] === undefined) return false;
    if (!('slipsToBottomStands' in value) || value['slipsToBottomStands'] === undefined) return false;
    if (!('availableFilters' in value) || value['availableFilters'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function TagBottomFingerprintFactSetDtoFromJSON(json: any): TagBottomFingerprintFactSetDto {
    return TagBottomFingerprintFactSetDtoFromJSONTyped(json, false);
}

export function TagBottomFingerprintFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagBottomFingerprintFactSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': json['LastUpdatedAt'] == null ? undefined : DateDtoFromJSON(json['LastUpdatedAt']),
        'wellId': json['WellId'],
        'tracks': ((json['Tracks'] as Array<any>).map(TagBottomFingerprintTrackDetailsDtoFromJSON)),
        'slipsToBottomStands': ((json['SlipsToBottomStands'] as Array<any>).map(StandDrillingInfoDtoFromJSON)),
        'availableFilters': FiltersDtoFromJSON(json['AvailableFilters']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function TagBottomFingerprintFactSetDtoToJSON(value?: Omit<TagBottomFingerprintFactSetDto, 'StopWatch'|'DataState'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'WellId': value['wellId'],
        'Tracks': ((value['tracks'] as Array<any>).map(TagBottomFingerprintTrackDetailsDtoToJSON)),
        'SlipsToBottomStands': ((value['slipsToBottomStands'] as Array<any>).map(StandDrillingInfoDtoToJSON)),
        'AvailableFilters': FiltersDtoToJSON(value['availableFilters']),
    };
}

