import {
  OperatorStatusType,
  UserRoleType,
  UserStatusType,
  WellStatusType,
} from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import type { IAction } from "reducers/types";
import { rangeAll } from "utils/helper";

interface IAdminState {
  users: {
    search: string | null;
    userStatus: UserStatusType[] | null;
    wells: number[] | null;
    rigs: number[] | null;
    userRole: UserRoleType[] | null;
    operators: number[] | null;
  };
  accounts: {
    search: string | null;
    operatorStatus: OperatorStatusType[] | null;
    wells: number[] | null;
    rigs: number[] | null;
    userStatus: UserStatusType[] | null;
  };
  wellAccess: {
    search: string | null;
    period: RangeType;
    rigs: number[] | null;
    wellStatus: WellStatusType[] | null;
  };
  singleAccountUsers: {
    search: string | null;
    period: RangeType;
    rigs: number[] | null;
    userStatus: UserStatusType[] | null;
  };
}

const initialState: IAdminState = {
  users: {
    search: null,
    userStatus: Object.values(UserStatusType),
    wells: null,
    rigs: null,
    userRole: Object.values(UserRoleType),
    operators: null,
  },
  accounts: {
    search: null,
    operatorStatus: Object.values(OperatorStatusType),
    wells: [],
    rigs: null,
    userStatus: Object.values(UserStatusType),
  },
  wellAccess: {
    search: null,
    period: rangeAll,
    rigs: null,
    wellStatus: Object.values(WellStatusType),
  },
  singleAccountUsers: {
    search: null,
    period: rangeAll,
    rigs: null,
    userStatus: Object.values(UserStatusType),
  },
};

interface ISetUsersSearchAction extends IAction {
  type: "ADMIN_USERS_SET_SEARCH";
  payload: {
    search: IAdminState["users"]["search"];
  };
}

interface ISetUserStatusAction extends IAction {
  type: "ADMIN_USERS_SET_USER_STATUS";
  payload: {
    userStatus: IAdminState["users"]["userStatus"];
  };
}

interface ISetUserWellsAction extends IAction {
  type: "ADMIN_USERS_SET_WELLS";
  payload: {
    wells: IAdminState["users"]["wells"];
  };
}

interface ISetUserRigsAction extends IAction {
  type: "ADMIN_USERS_SET_RIGS";
  payload: {
    rigs: IAdminState["users"]["rigs"];
  };
}

interface ISetUserRoleAction extends IAction {
  type: "ADMIN_USERS_SET_USER_ROLE";
  payload: {
    userRole: IAdminState["users"]["userRole"];
  };
}

interface ISetUserOperatorsAction extends IAction {
  type: "ADMIN_USERS_SET_OPERATORS";
  payload: {
    operators: IAdminState["users"]["operators"];
  };
}
interface ISetAccountsSearchAction extends IAction {
  type: "ADMIN_ACCOUNTS_SET_SEARCH";
  payload: {
    search: IAdminState["accounts"]["search"];
  };
}

interface ISetAccountsStatusesAction extends IAction {
  type: "ADMIN_ACCOUNTS_SET_STATUSES";
  payload: {
    statuses: IAdminState["accounts"]["operatorStatus"];
  };
}

interface ISetAccountsUserStatusesAction extends IAction {
  type: "ADMIN_ACCOUNTS_SET_USER_STATUSES";
  payload: {
    statuses: IAdminState["accounts"]["userStatus"];
  };
}

interface ISetAccountsRigsAction extends IAction {
  type: "ADMIN_ACCOUNTS_SET_RIGS";
  payload: {
    rigs: IAdminState["accounts"]["rigs"];
  };
}

interface ISetAccountsWellsAction extends IAction {
  type: "ADMIN_ACCOUNTS_SET_WELLS";
  payload: {
    wells: IAdminState["accounts"]["wells"];
  };
}

interface ISetAccountsUsersSearchAction extends IAction {
  type: "ADMIN_ACCOUNTS_USERS_SET_SEARCH";
  payload: {
    search: IAdminState["singleAccountUsers"]["search"];
  };
}

interface ISetAccountsUsersStatusesAction extends IAction {
  type: "ADMIN_ACCOUNTS_USERS_SET_USER_STATUSES";
  payload: {
    statuses: IAdminState["singleAccountUsers"]["userStatus"];
  };
}

interface ISetAccountsUsersRigsAction extends IAction {
  type: "ADMIN_ACCOUNTS_USERS_SET_RIGS";
  payload: {
    rigs: IAdminState["singleAccountUsers"]["rigs"];
  };
}

interface ISetAccountsUsersTimeRangeAction extends IAction {
  type: "ADMIN_ACCOUNTS_USERS_SET_TIME_RANGE";
  payload: {
    period: IAdminState["singleAccountUsers"]["period"];
  };
}

interface ISetWellAccessTimeRangeAction extends IAction {
  type: "ADMIN_WELL_ACCESS_SET_TIME_RANGE";
  payload: {
    period: IAdminState["wellAccess"]["period"];
  };
}

interface ISetWellAccessRigsAction extends IAction {
  type: "ADMIN_WELL_ACCESS_SET_RIGS";
  payload: {
    rigs: IAdminState["wellAccess"]["rigs"];
  };
}

interface ISetWellAccessWellStatusAction extends IAction {
  type: "ADMIN_WELL_ACCESS_SET_WELL_STATUS";
  payload: {
    wellStatus: IAdminState["wellAccess"]["wellStatus"];
  };
}

interface ISetWellAccessWellSearchAction extends IAction {
  type: "ADMIN_WELL_ACCESS_SET_SEARCH";
  payload: {
    search: IAdminState["wellAccess"]["search"];
  };
}

interface ISetWellAccessResetAction extends IAction {
  type: "ADMIN_WELL_ACCESS_RESET";
  payload: null;
}
interface ISingleAccountResetAction extends IAction {
  type: "ADMIN_SINGLE_ACCOUNT_RESET";
  payload: null;
}

type AvailableActions =
  | ISetUserRoleAction
  | ISetUserOperatorsAction
  | ISetUsersSearchAction
  | ISetUserStatusAction
  | ISetUserWellsAction
  | ISetUserRigsAction
  | ISetAccountsSearchAction
  | ISetAccountsStatusesAction
  | ISetAccountsUserStatusesAction
  | ISetAccountsRigsAction
  | ISetAccountsWellsAction
  | ISetAccountsUsersSearchAction
  | ISetAccountsUsersStatusesAction
  | ISetAccountsUsersRigsAction
  | ISetAccountsUsersTimeRangeAction
  | ISetWellAccessTimeRangeAction
  | ISetWellAccessRigsAction
  | ISetWellAccessWellStatusAction
  | ISetWellAccessWellSearchAction
  | ISetWellAccessResetAction
  | ISingleAccountResetAction;

function adminReducer(
  state: IAdminState = initialState,
  action: AvailableActions,
): IAdminState {
  switch (action.type) {
    case "ADMIN_USERS_SET_SEARCH":
      return {
        ...state,
        users: {
          ...state.users,
          search: action.payload.search,
        },
      };
    case "ADMIN_USERS_SET_USER_STATUS":
      return {
        ...state,
        users: {
          ...state.users,
          userStatus: action.payload.userStatus,
        },
      };
    case "ADMIN_USERS_SET_WELLS":
      return {
        ...state,
        users: {
          ...state.users,
          wells: action.payload.wells,
        },
      };
    case "ADMIN_USERS_SET_RIGS":
      return {
        ...state,
        users: {
          ...state.users,
          rigs: action.payload.rigs,
        },
      };
    case "ADMIN_USERS_SET_USER_ROLE":
      return {
        ...state,
        users: {
          ...state.users,
          userRole: action.payload.userRole,
        },
      };
    case "ADMIN_USERS_SET_OPERATORS":
      return {
        ...state,
        users: {
          ...state.users,
          operators: action.payload.operators,
        },
      };
    case "ADMIN_ACCOUNTS_SET_SEARCH":
      return {
        ...state,
        accounts: {
          ...state.accounts,
          search: action.payload.search,
        },
      };
    case "ADMIN_ACCOUNTS_SET_STATUSES":
      return {
        ...state,
        accounts: {
          ...state.accounts,
          operatorStatus: action.payload.statuses,
        },
      };
    case "ADMIN_ACCOUNTS_SET_USER_STATUSES":
      return {
        ...state,
        accounts: {
          ...state.accounts,
          userStatus: action.payload.statuses,
        },
      };
    case "ADMIN_ACCOUNTS_SET_RIGS":
      return {
        ...state,
        accounts: {
          ...state.accounts,
          rigs: action.payload.rigs,
        },
      };
    case "ADMIN_ACCOUNTS_SET_WELLS":
      return {
        ...state,
        accounts: {
          ...state.accounts,
          wells: action.payload.wells,
        },
      };
    case "ADMIN_ACCOUNTS_USERS_SET_SEARCH":
      return {
        ...state,
        singleAccountUsers: {
          ...state.singleAccountUsers,
          search: action.payload.search,
        },
      };
    case "ADMIN_ACCOUNTS_USERS_SET_USER_STATUSES":
      return {
        ...state,
        singleAccountUsers: {
          ...state.singleAccountUsers,
          userStatus: action.payload.statuses,
        },
      };
    case "ADMIN_ACCOUNTS_USERS_SET_RIGS":
      return {
        ...state,
        singleAccountUsers: {
          ...state.singleAccountUsers,
          rigs: action.payload.rigs,
        },
      };
    case "ADMIN_ACCOUNTS_USERS_SET_TIME_RANGE":
      return {
        ...state,
        singleAccountUsers: {
          ...state.singleAccountUsers,
          period: action.payload.period,
        },
      };
    case "ADMIN_WELL_ACCESS_SET_TIME_RANGE":
      return {
        ...state,
        wellAccess: {
          ...state.wellAccess,
          period: action.payload.period,
        },
      };
    case "ADMIN_WELL_ACCESS_SET_RIGS":
      return {
        ...state,
        wellAccess: {
          ...state.wellAccess,
          rigs: action.payload.rigs,
        },
      };
    case "ADMIN_WELL_ACCESS_SET_WELL_STATUS":
      return {
        ...state,
        wellAccess: {
          ...state.wellAccess,
          wellStatus: action.payload.wellStatus,
        },
      };
    case "ADMIN_WELL_ACCESS_SET_SEARCH":
      return {
        ...state,
        wellAccess: {
          ...state.wellAccess,
          search: action.payload.search,
        },
      };
    case "ADMIN_WELL_ACCESS_RESET":
      return {
        ...state,
        wellAccess: initialState.wellAccess,
      };
    case "ADMIN_SINGLE_ACCOUNT_RESET":
      return {
        ...state,
        singleAccountUsers: initialState.singleAccountUsers,
      };
    default:
      return state;
  }
}

export default adminReducer;
