import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  IntelGroupingType,
  type IntelScatterPlotUserLensDto,
  IntelScatterPlotUserLensesApi,
} from "apis/oag";
import { Title } from "atoms/Typography";
import type { SelectorItem } from "components/GroupSelector/GroupSelector";
import { GroupSelector } from "components/GroupSelector/GroupSelector";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { Loader } from "components/Loader";
import { Suspense, useRef } from "react";
import { useResizeDetector } from "react-resize-detector";
import { apiConfig } from "utils/apiConfig";
import { Space } from "utils/componentLibrary";
import { Switch } from "utils/componentLibrary";
import { RequestUID } from "utils/queryNamespaces";
import { zIndexLayer } from "utils/zIndex";

import { Chart } from "./Chart";
import {
  Card,
  CardHeader,
  CardTitle,
  ChartContainer,
  ScatterPlotContainer,
} from "./style";
import { CARD_PADDING, HEADER_HEIGHT, SELECTOR_HEIGHT } from "./utils";

const api = new IntelScatterPlotUserLensesApi(apiConfig);

export const IntelScatterPlot = ({
  lens,
}: {
  lens: IntelScatterPlotUserLensDto;
}) => {
  const {
    width: containerWidth,
    height: containerHeight,
    ref: containerRef,
  } = useResizeDetector();
  const chartWidth = getSVGNormalizedValue(
    (containerWidth ?? 0) - CARD_PADDING * 2,
  );
  const chartHeight = getSVGNormalizedValue(
    (containerHeight ?? 0) -
      CARD_PADDING * 3 -
      HEADER_HEIGHT -
      SELECTOR_HEIGHT -
      1,
  );

  const chartRef = useRef<HTMLDivElement>(null);

  const selectorItems: SelectorItem<IntelGroupingType>[] = [
    { key: IntelGroupingType.Well, icon: "wellInfo", label: "Well" },
    { key: IntelGroupingType.Rig, icon: "rig", label: "Rig" },
    {
      key: IntelGroupingType.Contractor,
      icon: "intelContractor",
      label: "Contractor",
    },
    { key: IntelGroupingType.Operator, icon: "operator", label: "Operator" },
  ];

  const queryClient = useQueryClient();

  const updateLensMutation = useMutation({
    mutationFn: (lens: IntelScatterPlotUserLensDto) => {
      return api.apiIntelScatterPlotUserLensesIdPut({
        id: lens.id,
        intelScatterPlotUserLensDto: lens,
      });
    },
    onSettled: (updatedLens) => {
      queryClient.setQueryData(
        [{ uid: RequestUID.intelLenses }],
        (data: IntelScatterPlotUserLensDto[]) => {
          return data.map((l) => {
            if (l.id === lens.id) {
              return updatedLens;
            }
            return l;
          });
        },
      );
    },
  });

  const handleGroupingChange = (grouping: IntelGroupingType) => {
    updateLensMutation.mutate({
      ...lens,
      grouping,
    });
  };

  const handleQuartileChecked = (showQuartile: boolean) => {
    queryClient.setQueryData(
      [{ uid: RequestUID.intelLenses }],
      (data: IntelScatterPlotUserLensDto[]) => {
        return data.map((l) => {
          if (l.id === lens.id) {
            return {
              ...l,
              showQuartile,
            };
          }
          return l;
        });
      },
    );
    updateLensMutation.mutate({
      ...lens,
      showQuartile,
    });
  };

  return (
    <Card ref={containerRef}>
      <ScatterPlotContainer>
        <CardHeader>
          <CardTitle>Scatter Plot Chart</CardTitle>
          <Space>
            <Switch
              size="small"
              checked={lens.showQuartile}
              onChange={() => handleQuartileChecked(!lens.showQuartile)}
            />

            <Title level={3}>Quartile</Title>
          </Space>
        </CardHeader>
        <GroupSelector<IntelGroupingType>
          items={selectorItems}
          onSelect={handleGroupingChange}
          selectedItem={lens.grouping}
        />

        <ChartContainer height={chartHeight} ref={chartRef}>
          <Suspense fallback={<Loader centered zIndex={zIndexLayer.above} />}>
            <Chart
              lens={lens}
              chartHeight={chartHeight}
              chartWidth={chartWidth}
              chartRef={chartRef}
              showQuartile={lens.showQuartile}
            />
          </Suspense>
        </ChartContainer>
      </ScatterPlotContainer>
    </Card>
  );
};
