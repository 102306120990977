import type { ApiNaturalGasStateUserLensesIdFactsPutRequest } from "apis/oag/apis/NaturalGasStateUserLensesApi";
import type { NaturalGasStateUserLensDto } from "apis/oag/models/NaturalGasStateUserLensDto";
import { allGenerators } from "components/Lenses/utils";
import { useNaturalGasStateFacts } from "hooks/facts/useNaturalGasStateFacts";
import { useFilterParams } from "hooks/filters/useFilterParams";
import { isNull } from "lodash";

interface UseNaturalGasStateFetcherParams {
  lens: NaturalGasStateUserLensDto;
}

export function useNaturalGasStateFetcher({
  lens,
}: UseNaturalGasStateFetcherParams) {
  const filterParams = useFilterParams();
  const requestParameters: ApiNaturalGasStateUserLensesIdFactsPutRequest = {
    id: lens.id,
    baseFuelQueryDto: {
      ...filterParams,
      selectedGenerators: lens.selectedGenerators
        ? lens.selectedGenerators
        : allGenerators,
    },
  };

  const request = useNaturalGasStateFacts(lens.id, requestParameters, {
    enabled: !isNull(lens.id),
    refetchOnMount: false,
  });

  return { request };
}
