/* eslint-disable react/no-multi-comp */
import type { DragEndEvent } from "@dnd-kit/core";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ClaraApi, type ClaraPromptDto } from "apis/oag";
import { arrayMoveImmutable } from "array-move";
import * as Styled from "components/Clara/QuestionList/style";
import { PDComponent } from "components/PDComponents";
import { cloneDeep } from "lodash";
import type { KeyboardEvent } from "react";
import React, { useMemo } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const claraApi = new ClaraApi(apiConfig);

export const FavoriteQuestionRow = ({ prompt }: { prompt: ClaraPromptDto }) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [isEditMode, setIsEditMode] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [editedMessage, setEditedMessage] = useState(prompt.prompt);
  const editAreaRef = useRef<HTMLDivElement>(null);

  const populateInput = (message: string) => {
    dispatch({ type: "CLARA_POPULATE_INPUT", payload: message });
  };

  const deleteFavoriteMutation = useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      return claraApi.apiClaraPromptsFavoriteIdDelete({ id });
    },
  });

  const editFavoritePromptMutation = useMutation({
    mutationFn: async () => {
      return claraApi.apiClaraPromptsFavoriteIdPut({
        id: prompt.id,
        claraPromptDto: { ...prompt, prompt: editedMessage },
      });
    },
  });

  const handleOnEdit = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.stopPropagation();
      if (!isEditMode) {
        setMenuVisible(false);
      }
      setEditedMessage(prompt.prompt);
      setIsEditMode(!isEditMode);
    },
    [isEditMode, prompt.prompt],
  );

  const handleOnOptionsClick = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setMenuVisible(true);
    },
    [],
  );

  const handleOnUnfavorite = useCallback(
    async (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setMenuVisible(false);
      const id = prompt.id;

      // Optimistically remove it from the cache to get instant feedback
      queryClient.setQueryData(
        [
          {
            uid: RequestUID.clara,
            type: PDQueryType.CLARA_CONVERSATION_PROMPTS,
          },
        ],
        (oldData: Array<ClaraPromptDto>) => {
          const messageIndex = oldData.findIndex(
            (dataMsg) => dataMsg.id === id,
          );
          if (messageIndex === -1) return oldData;
          const newData = oldData
            .slice(0, messageIndex)
            .concat(oldData.slice(messageIndex + 1));
          return newData;
        },
      );

      await deleteFavoriteMutation
        .mutateAsync({ id })
        .then((isDeleted) => {
          if (!isDeleted) {
            console.error("Failed to optimistically remove favorite prompt");
          }
        })
        .then(() => {
          queryClient.refetchQueries({
            queryKey: [
              {
                uid: RequestUID.clara,
                type: PDQueryType.CLARA_CONVERSATION_PROMPTS,
              },
            ],
          });
        });
    },
    [deleteFavoriteMutation, prompt.id, queryClient],
  );

  useEffect(() => {
    const handleOnClick = (event: MouseEvent) => {
      if (
        editAreaRef.current &&
        !editAreaRef.current.contains(event.target as Node)
      ) {
        setIsEditMode(false);
      }
    };

    window.addEventListener("click", handleOnClick);
    window.addEventListener("mousedown", handleOnClick);

    return () => {
      window.removeEventListener("click", handleOnClick);
      window.removeEventListener("mousedown", handleOnClick);
    };
  }, []);

  const handleOnSubmit = useCallback(
    async (ev: KeyboardEvent<HTMLTextAreaElement>) => {
      if (ev.key === "Enter" && !ev.shiftKey) {
        ev.preventDefault();
        ev.stopPropagation();
        setMenuVisible(false);
        setIsEditMode(false);
        const id = prompt.id;

        // Optimistically remove it from the cache to get instant feedback
        queryClient.setQueryData(
          [
            {
              uid: RequestUID.clara,
              type: PDQueryType.CLARA_CONVERSATION_PROMPTS,
            },
          ],
          (oldData: Array<ClaraPromptDto>) => {
            const messageIndex = oldData.findIndex(
              (dataMsg) => dataMsg.id === id,
            );
            if (messageIndex === -1) return oldData;
            const newData = cloneDeep(oldData);
            newData[messageIndex].prompt = editedMessage;
            return newData;
          },
        );

        await editFavoritePromptMutation.mutateAsync().catch(() => {
          console.error("Failed to optimistically rename favorite prompt");
        });
      }
    },
    [editFavoritePromptMutation, editedMessage, prompt.id, queryClient],
  );

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: prompt.id.toString(),
    disabled: isEditMode,
  });

  const dndStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return isEditMode ? (
    <Styled.TextAreaWithButton ref={editAreaRef}>
      <Styled.FavoriteQuestionEditTextarea
        value={editedMessage}
        onChange={(ev) => setEditedMessage(ev.target.value)}
        maxRows={4}
        rows={1}
        autoFocus
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length,
          )
        }
        onKeyDown={handleOnSubmit}
      />

      <Styled.CloseIcon
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          setEditedMessage("");
        }}
      />
    </Styled.TextAreaWithButton>
  ) : (
    <Styled.FavoriteQuestionRow
      onClick={() => populateInput(prompt.prompt)}
      $forceHideButton={isEditMode}
      style={dndStyle}
      ref={setNodeRef}
      {...attributes}
    >
      <Styled.DragIcon
        $isDragging={isDragging}
        {...listeners}
        width={8}
        height={12}
      />

      <Styled.PromptMessage>{prompt.prompt}</Styled.PromptMessage>

      <Styled.StyledDropDown
        overlay={
          <Styled.OptionsMenu>
            <Styled.OptionRow onClick={handleOnUnfavorite}>
              <PDComponent.SvgIcon name="unfavorite" />
              Unfavorite
            </Styled.OptionRow>

            <Styled.OptionRow onClick={handleOnEdit}>
              <PDComponent.SvgIcon name="edit" />
              Edit
            </Styled.OptionRow>
          </Styled.OptionsMenu>
        }
        placement="bottomRight"
        trigger={["click"]}
        open={menuVisible}
        onOpenChange={() => {
          setMenuVisible(!menuVisible);
        }}
      >
        <Styled.DotsSimpleButton
          $forceShow={menuVisible}
          onClick={handleOnOptionsClick}
          role="button"
        >
          <Styled.DotsIcon width="24" height="24" />
        </Styled.DotsSimpleButton>
      </Styled.StyledDropDown>
    </Styled.FavoriteQuestionRow>
  );
};

export const FavoriteQuestionRowContainer = ({
  prompts: initialPrompts,
}: {
  prompts: ClaraPromptDto[];
}) => {
  const sensors = useSensors(useSensor(PointerSensor));
  const [prompts, setPrompts] = useState<ClaraPromptDto[]>(initialPrompts);
  const stringIds = useMemo(
    () => prompts.map((p) => p.id.toString()),
    [prompts],
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    setPrompts(initialPrompts);
  }, [initialPrompts]);

  const handleOnDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;

      function getPosition(id: number) {
        const prompt = prompts.find((p) => p.id === id);
        if (!prompt) return 0;
        return prompt.position;
      }

      if (over && active.id !== over.id) {
        const oldIndex = stringIds.indexOf(active.id.toString());
        const newIndex = stringIds.indexOf(over.id.toString());

        setPrompts(arrayMoveImmutable(prompts, oldIndex, newIndex));

        queryClient.setQueryData(
          [
            {
              uid: RequestUID.clara,
              type: PDQueryType.CLARA_CONVERSATION_PROMPTS,
            },
          ],
          (oldData: Array<ClaraPromptDto>) => {
            const newArray = arrayMoveImmutable(oldData, oldIndex, newIndex);
            [newArray[oldIndex].position, newArray[newIndex].position] = [
              newArray[newIndex].position,
              newArray[oldIndex].position,
            ];
            return newArray;
          },
        );

        await Promise.all([
          claraApi.apiClaraPromptsFavoriteIdPositionPositionPut({
            id: parseInt(active.id.toString()),
            position: getPosition(prompts[newIndex].id),
          }),
          claraApi.apiClaraPromptsFavoriteIdPositionPositionPut({
            id: parseInt(over.id.toString()),
            position: getPosition(prompts[oldIndex].id),
          }),
        ]).catch(() => {
          console.error("Failed to optimistically reorder favorite prompts");
        });
      }
    },
    [prompts, queryClient, stringIds],
  );

  return (
    <Styled.QuestionRowContainer>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleOnDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={stringIds}
          strategy={verticalListSortingStrategy}
        >
          {prompts?.map((prompt) => {
            return (
              <FavoriteQuestionRow prompt={prompt} key={prompt.id.toString()} />
            );
          })}
        </SortableContext>
      </DndContext>
    </Styled.QuestionRowContainer>
  );
};
