import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { StyledSpaceForLayout } from "components/PDComponents/Search/style";
import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Checkbox, Col, Row, Space } from "utils/componentLibrary";
import { PdSpaceItem } from "utils/componentLibrary/Space";
import { useCustomTheme } from "utils/useTheme";

type IAllowedOptions = number | string;

export interface IPickoffOption<T extends IAllowedOptions> {
  id: T;
  name: string;
}

const StyledSpaceForItems = styled(Space)`
  width: 100%;
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    .ant-typography {
      line-height: 1;
    }
  }
  ${PdSpaceItem} {
    padding: 12px;
    border-bottom: 1px solid
      ${({ theme }) => theme.themeStyle.colors.primary_chart_accent};
    margin: 0px !important;
    width: 100%;
  }
`;

const StyledCheckboxGroup = styled(Checkbox.Group)`
  width: 100%;

  .ant-checkbox {
    top: 0;
  }
  ${({ theme }) => css`
    background: ${theme.themeStyle.colors.tertiary_bg};
    color: ${theme.themeStyle.colors.primary_typography};
    border-color: ${theme.themeStyle.colors.primary_accent};
  `}
`;

interface ICustomPickoff<T extends IAllowedOptions> {
  options: IPickoffOption<T>[];
  values: T[];
  width?: number;
  onChange: (e: T[]) => void;
}

/**
 * @deprecated This component is deprecated and should not be used in new code.
 */
export function _Pickoff<T extends IAllowedOptions>({
  options = [],
  values = [],
  width = 250,
  onChange,
}: ICustomPickoff<T>) {
  const [tempSelOptions, setTempSelOptions] = useState<T[]>(values);

  useEffect(() => {
    setTempSelOptions(values);
  }, [values]);

  const toggledSelect = () => {
    if (tempSelOptions?.length > 0) {
      setTempSelOptions([]);
    } else {
      setTempSelOptions(options.map((e) => e?.id));
    }
  };

  const { atomThemeVariant } = useCustomTheme();

  const getTypographyVariant = useCallback(
    (e: IPickoffOption<T>) => {
      if (!tempSelOptions?.includes(e.id)) return "faded";
      return atomThemeVariant;
    },
    [tempSelOptions, atomThemeVariant],
  );

  return (
    <StyledSpaceForLayout direction="vertical" width={width}>
      <StyledCheckboxGroup
        value={tempSelOptions}
        onChange={(e) => setTempSelOptions(e as T[])}
      >
        <StyledSpaceForItems direction="vertical" size={0}>
          {options.map((e, index) => (
            <Row
              justify="space-between"
              align="bottom"
              key={`${e.id}_${index}`}
            >
              <Col flex="0 auto">
                <Checkbox value={e.id}>
                  <Title
                    level={3}
                    variant={getTypographyVariant(e)}
                    style={{ display: "inline-block" }}
                  >
                    {e.name}
                  </Title>
                </Checkbox>
              </Col>
            </Row>
          ))}
        </StyledSpaceForItems>
      </StyledCheckboxGroup>
      <Row justify="space-between" style={{ padding: "12px" }}>
        <Col flex="0 auto">
          <Button
            size="large"
            onClick={toggledSelect}
            trackingName={`${tempSelOptions?.length > 0 ? "Deselect" : "Select"} All`}
          >
            {tempSelOptions?.length > 0 ? "Deselect" : "Select"} All
          </Button>
        </Col>
        <Col flex="0 auto">
          <Button
            type="primary"
            size="large"
            trackingName="Apply"
            onClick={() => {
              onChange(tempSelOptions);
            }}
          >
            Apply
          </Button>
        </Col>
      </Row>
    </StyledSpaceForLayout>
  );
}
