/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntelGroupingKeyPair
 */
export interface IntelGroupingKeyPair {
    /**
     * 
     * @type {string}
     * @memberof IntelGroupingKeyPair
     */
    queryKey: string;
    /**
     * 
     * @type {string}
     * @memberof IntelGroupingKeyPair
     */
    lensKey: string;
}

/**
 * Check if a given object implements the IntelGroupingKeyPair interface.
 */
export function instanceOfIntelGroupingKeyPair(value: object): value is IntelGroupingKeyPair {
    if (!('queryKey' in value) || value['queryKey'] === undefined) return false;
    if (!('lensKey' in value) || value['lensKey'] === undefined) return false;
    return true;
}

export function IntelGroupingKeyPairFromJSON(json: any): IntelGroupingKeyPair {
    return IntelGroupingKeyPairFromJSONTyped(json, false);
}

export function IntelGroupingKeyPairFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelGroupingKeyPair {
    if (json == null) {
        return json;
    }
    return {
        
        'queryKey': json['QueryKey'],
        'lensKey': json['LensKey'],
    };
}

export function IntelGroupingKeyPairToJSON(value?: IntelGroupingKeyPair | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'QueryKey': value['queryKey'],
        'LensKey': value['lensKey'],
    };
}

