import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type {
  ApiBroomsticksModelsWellIdTypeGetRequest,
  ApiTorqueAndDragUserLensesIdSingleFactsPutRequest,
  BroomstickDto,
  TorqueAndDragInspectionResultDto,
  TorqueAndDragResultDto,
  TorqueAndDragUserLensDto,
} from "apis/oag";
import { BroomsticksApi, TorqueAndDragUserLensesApi } from "apis/oag";
import type { TorqueAndDragType } from "apis/oag/models/TorqueAndDragType";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import {
  type IFiltersType,
  initialFilters,
  type IZoomData,
} from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, TVD_SAMPLE_COUNT } from "utils/common";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

export function useTorqueAndDragData(
  lens: TorqueAndDragUserLensDto,
  selectedBroomstick: string,
  options?: UseQueryOptions<TorqueAndDragResultDto>,
) {
  const torqueAndDrag = new TorqueAndDragUserLensesApi(apiConfig);

  const wellId = useSelectedWell();
  const [filters] = useStateQuery<IFiltersType>(
    URL_STATE_PARAM.FILTERS_WIDGET,
    initialFilters,
  );
  const {
    sections,
    directionalIntervals,
    operationTime,
    holeSizes,
    phases: phaseIds,
  } = filters;

  const [zoomState] = useStateQuery<IZoomData>(
    URL_STATE_PARAM.ZOOM_WIDGET,
    initialZoomData,
  );
  const requestParameters: ApiTorqueAndDragUserLensesIdSingleFactsPutRequest = {
    id: lens.id,
    torqueAndDragQueryDto: {
      sampleCount: TVD_SAMPLE_COUNT,
      selectedFilters: {
        sectionIds: sections,
        directionalIntervalIds: directionalIntervals,
        holeSizeIds: holeSizes,
        phaseIds,
        includeFlatTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
        includeAlphaRigs: true,
        includeNonAlphaRigs: true,
      },
      selectedBroomstickModelName: selectedBroomstick,
      zoomStartDepth: zoomState?.depth_start,
      zoomEndDepth: zoomState?.depth_end,
      zoomStartDuration: zoomState?.ts_start,
      zoomEndDuration: zoomState?.ts_end,
      wellId: wellId,
      useMockData: false,
      comparisonWellIds: null,
      from: defaultDateDto.from,
      to: defaultDateDto.to,
    },
  };

  const queryKey: PDLensFactsQueryKey<typeof requestParameters> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.torqueAndDrag,
    params: requestParameters,
    lensId: lens?.id,
    lensKpiType: lens?.torqueAndDragType,
  };

  return useSuspenseQuery<TorqueAndDragResultDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      torqueAndDrag.apiTorqueAndDragUserLensesIdSingleFactsPut(
        requestParameters,
        { signal },
      ),
    ...options,
  });
}

export function useBroomSticks(lensId: number) {
  const broomsticksApi = new BroomsticksApi(apiConfig);
  const queryKey: PDLensFactsQueryKey = {
    type: PDQueryType.FACTS,
    uid: RequestUID.torqueAndDragBroomstickList,
    lensId,
  };
  return useSuspenseQuery<BroomstickDto[]>({
    queryKey: [queryKey],
    queryFn: ({ signal }) => broomsticksApi.apiBroomsticksGet({ signal }),
  });
}

export function useBroomStickByType(
  type: ApiBroomsticksModelsWellIdTypeGetRequest["type"],
  lensId: number,
  options?: UseQueryOptions<string[]>,
) {
  const broomsticksApi = new BroomsticksApi(apiConfig);

  const wellId = useSelectedWell();

  const queryKey: PDLensFactsQueryKey & {
    torqueAndDragType: TorqueAndDragType;
  } = {
    type: PDQueryType.FACTS,
    uid: RequestUID.torqueAndDragBroomstickListType,
    torqueAndDragType: type,
    wellId,
    lensId,
  };
  return useSuspenseQuery<string[]>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      broomsticksApi.apiBroomsticksModelsWellIdTypeGet(
        { wellId, type },
        { signal },
      ),
    ...options,
  });
}

export function useInspectionPoint(
  pointId: number,
  lensId: number,
  // Hack to prevent svg bad draw states
  uid: string,
  options?: Omit<
    UseQueryOptions<TorqueAndDragInspectionResultDto>,
    "queryKey" | "queryFn"
  >,
) {
  const torqueAndDrag = new TorqueAndDragUserLensesApi(apiConfig);
  const queryKey: PDLensFactsQueryKey = {
    type: PDQueryType.FACTS,
    uid: uid as RequestUID,
    lensId,
  };

  return useSuspenseQuery<TorqueAndDragInspectionResultDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      torqueAndDrag.apiTorqueAndDragUserLensesUserLensIdInspectionDetailsPointIdGet(
        { userLensId: lensId, pointId },
        { signal },
      ),
    ...options,
  });
}
