import { useIsAuthenticated } from "@azure/msal-react";
import { useCurrentUser } from "hooks/user/useCurrentUser";
import type { Dict } from "mixpanel-browser";
import mixpanel from "mixpanel-browser";
import type { FC } from "react";
import React, { createContext, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import type { ReactChildren } from "utils/types";
mixpanel.init("2a4c101038742d94211295a38790facc", { debug: true });

const env_check = true;

export const Mixpanel = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name: string, props?: Dict) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props: Dict) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

interface Tracker {
  event: (name: string, props?: Dict) => void;
  viewPage: (name: string, props?: Dict) => void;
  viewPopup: (name: string, props?: Dict) => void;
  interact: (name: string, props?: Dict) => void;
  clickEvent: (name: string, props?: Dict) => void;
}
const mpTracker: Tracker = {
  event: (name: string, props?: Dict) => {
    Mixpanel.track(name, props);
  },
  viewPage: (name: string, props?: Dict) => {
    Mixpanel.track(`View Page - ${name}`, props);
  },
  viewPopup: (name: string, props?: Dict) => {
    Mixpanel.track(`View Popup - ${name}`, props);
  },
  interact: (name: string, props?: Dict) => {
    Mixpanel.track(`Interaction - ${name}`, props);
  },
  clickEvent: (name: string, props?: Dict) => {
    Mixpanel.track(`Click Event - ${name}`, props);
  },
};

const localTracker: Tracker = {
  event: (name: string, props?: Dict) => {
    console.log(name, props);
  },
  viewPage: (name: string, props?: Dict) => {
    console.log(`View Page - ${name}`, props);
  },
  viewPopup: (name: string, props?: Dict) => {
    console.log(`View Popup - ${name}`, props);
  },
  interact: (name: string, props?: Dict) => {
    console.log(`Interaction - ${name}`, props);
  },
  clickEvent: (name: string, props?: Dict) => {
    console.log(`Click Event - ${name}`, props);
  },
};
export const Track = import.meta.env.PROD ? mpTracker : localTracker;
const MixpanelContext = createContext(Track);

export const useMixpanel = () => {
  return useContext(MixpanelContext);
};

export const usePageView = (
  page:
    | {
        title: string;
        enabled: boolean;
        options?: Record<string | number, string | number>;
      }
    | string,
) => {
  const { title, enabled, options } =
    typeof page === "string"
      ? { title: page, enabled: true, options: undefined }
      : page;
  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();

  useEffect(() => {
    if (viewPage && enabled && !pageSeen.current) {
      pageSeen.current = true;
      viewPage(title, options);
    }
  }, [viewPage, title, enabled, options]);
};

export const MixpanelProvider: FC<ReactChildren> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const user = useCurrentUser(isAuthenticated);

  const [userIdentified, setUserIdentified] = useState(false);

  useEffect(() => {
    if (userIdentified || !import.meta.env.PROD) return;
    if (isAuthenticated && !user.isLoading && user.data) {
      setUserIdentified(true);
      Mixpanel.identify(user.data.id.toString());
      Mixpanel.people.set({
        FirstName: user.data.firstName,
        LastName: user.data.lastName,
        JobTitle: user.data.jobTitle,
        Role: user.data.role,
        Status: user.data.status,
        Email: user.data.userName,
      });
    }
  }, [isAuthenticated, user.data, user.isLoading, userIdentified]);

  return (
    <MixpanelContext.Provider value={Track}>
      {children}
    </MixpanelContext.Provider>
  );
};
