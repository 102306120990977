import React from "react";
import colors from "utils/colors";
import { Divider } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

export default function _VerticalDivider() {
  const { isDark } = useCustomTheme();
  return (
    <Divider
      type="vertical"
      style={{
        margin: "0 4px",
        borderLeft: isDark ? colors.limo_scene : colors.widget_line,
      }}
    />
  );
}
