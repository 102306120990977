import type { TooltipPlacement } from "antd/es/tooltip";
import { PDComponent } from "components/PDComponents";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { IconContainer } from "icons/styles";
import * as Styled from "pages/WellDashboard/RigSpecificityDropdown/style";
import type { PropsWithChildren } from "react";
import { useState } from "react";
import { Popover } from "utils/componentLibrary";

interface RigSpecificityDropdownProps {
  selectedRigs: number[];
  onRigIdsSelect?: (rigIds: number[]) => void;
  placement?: TooltipPlacement;
  isReadOnly?: boolean;
}

export const RigSpecificityDropdown = ({
  selectedRigs,
  placement = "bottom",
  onRigIdsSelect,
  isReadOnly,
  children,
}: PropsWithChildren<RigSpecificityDropdownProps>) => {
  const { data: rigOptions } = useRigs();
  const [selectRigs, setSelectRigs] = useState<Array<number>>(
    selectedRigs ?? [],
  );
  const [isRigsModalOpen, setIsRigsModalOpen] = useState(false);
  const selectedRigOptions = rigOptions?.list.filter((rig) =>
    selectRigs.includes(rig.id),
  );

  return (
    <Popover
      content={
        isReadOnly ? (
          <Styled.ListContainer>
            <Styled.ListInfo>
              {selectRigs?.length
                ? selectedRigs?.length + " selected"
                : "Visible to all rigs"}
            </Styled.ListInfo>
            <Styled.ListRowsContainer>
              {selectedRigOptions?.map(({ id, shortName }) => (
                <Styled.ListRow key={id}>
                  <Styled.CustomCheckbox disabled checked />
                  {shortName}
                </Styled.ListRow>
              ))}
            </Styled.ListRowsContainer>
          </Styled.ListContainer>
        ) : (
          <PDComponent.ComboBoxMultiSelect<number>
            placeholder="Search Rigs"
            noSelectAll
            options={(rigOptions?.list ?? []).map((rig) => ({
              id: rig.id,
              name: rig.shortName || "",
            }))}
            values={selectRigs ?? (rigOptions?.list ?? []).map((rig) => rig.id)}
            onChange={(e: number[]) => {
              setSelectRigs(e);
              setIsRigsModalOpen(false);
              onRigIdsSelect?.(e);
            }}
          />
        )
      }
      placement={placement}
      open={isRigsModalOpen}
      onOpenChange={(isOpen) => setIsRigsModalOpen(isOpen)}
      trigger="click"
      destroyTooltipOnHide
    >
      <IconContainer
        onClick={(e) => {
          e.stopPropagation();
          setIsRigsModalOpen(!isRigsModalOpen);
        }}
      >
        {children}
      </IconContainer>
    </Popover>
  );
};
