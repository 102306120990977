import { AddClaraIcon } from "components/Clara/style";
import { PDComponent } from "components/PDComponents";
import { useAppSelector } from "reducers/store";
import { Tooltip } from "utils/componentLibrary";

export const PlusIcon = ({ onClick }: { onClick: () => void }) => {
  const isChatThreadMode = useAppSelector(
    (state) => state.claraReducer.isChatThreadMode,
  );
  return (
    <Tooltip
      title={
        isChatThreadMode ? (
          <>
            Starting a new chat <br /> will clear the current
            <br /> conversation
          </>
        ) : (
          ""
        )
      }
      placement="bottom"
    >
      <AddClaraIcon onClick={isChatThreadMode ? onClick : Function.prototype()}>
        <PDComponent.SvgIcon name="addClara" />
      </AddClaraIcon>
    </Tooltip>
  );
};
