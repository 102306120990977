/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WellStatusType } from './WellStatusType';
import {
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface LinkedWellAttributesSetDto
 */
export interface LinkedWellAttributesSetDto {
    /**
     * 
     * @type {boolean}
     * @memberof LinkedWellAttributesSetDto
     */
    keepEvengreenOnly: boolean;
    /**
     * 
     * @type {Array<WellStatusType>}
     * @memberof LinkedWellAttributesSetDto
     */
    wellStatuses?: Array<WellStatusType> | null;
    /**
     * 
     * @type {DateDto}
     * @memberof LinkedWellAttributesSetDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof LinkedWellAttributesSetDto
     */
    to: DateDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof LinkedWellAttributesSetDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LinkedWellAttributesSetDto
     */
    wellIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LinkedWellAttributesSetDto
     */
    operatorIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LinkedWellAttributesSetDto
     */
    targetFormationIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LinkedWellAttributesSetDto
     */
    countryIds?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LinkedWellAttributesSetDto
     */
    rigTypes?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LinkedWellAttributesSetDto
     */
    rigClasses?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LinkedWellAttributesSetDto
     */
    rigHorsePowers?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LinkedWellAttributesSetDto
     */
    operatingCenters?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof LinkedWellAttributesSetDto
     */
    includeAlphaRigs: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LinkedWellAttributesSetDto
     */
    includeNonAlphaRigs: boolean;
}

/**
 * Check if a given object implements the LinkedWellAttributesSetDto interface.
 */
export function instanceOfLinkedWellAttributesSetDto(value: object): value is LinkedWellAttributesSetDto {
    if (!('keepEvengreenOnly' in value) || value['keepEvengreenOnly'] === undefined) return false;
    if (!('from' in value) || value['from'] === undefined) return false;
    if (!('to' in value) || value['to'] === undefined) return false;
    if (!('includeAlphaRigs' in value) || value['includeAlphaRigs'] === undefined) return false;
    if (!('includeNonAlphaRigs' in value) || value['includeNonAlphaRigs'] === undefined) return false;
    return true;
}

export function LinkedWellAttributesSetDtoFromJSON(json: any): LinkedWellAttributesSetDto {
    return LinkedWellAttributesSetDtoFromJSONTyped(json, false);
}

export function LinkedWellAttributesSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedWellAttributesSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'keepEvengreenOnly': json['KeepEvengreenOnly'],
        'wellStatuses': json['WellStatuses'] == null ? undefined : ((json['WellStatuses'] as Array<any>).map(WellStatusTypeFromJSON)),
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'rigIds': json['RigIds'] == null ? undefined : json['RigIds'],
        'wellIds': json['WellIds'] == null ? undefined : json['WellIds'],
        'operatorIds': json['OperatorIds'] == null ? undefined : json['OperatorIds'],
        'targetFormationIds': json['TargetFormationIds'] == null ? undefined : json['TargetFormationIds'],
        'countryIds': json['CountryIds'] == null ? undefined : json['CountryIds'],
        'rigTypes': json['RigTypes'] == null ? undefined : json['RigTypes'],
        'rigClasses': json['RigClasses'] == null ? undefined : json['RigClasses'],
        'rigHorsePowers': json['RigHorsePowers'] == null ? undefined : json['RigHorsePowers'],
        'operatingCenters': json['OperatingCenters'] == null ? undefined : json['OperatingCenters'],
        'includeAlphaRigs': json['IncludeAlphaRigs'],
        'includeNonAlphaRigs': json['IncludeNonAlphaRigs'],
    };
}

export function LinkedWellAttributesSetDtoToJSON(value?: LinkedWellAttributesSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'KeepEvengreenOnly': value['keepEvengreenOnly'],
        'WellStatuses': value['wellStatuses'] == null ? undefined : ((value['wellStatuses'] as Array<any>).map(WellStatusTypeToJSON)),
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
        'RigIds': value['rigIds'],
        'WellIds': value['wellIds'],
        'OperatorIds': value['operatorIds'],
        'TargetFormationIds': value['targetFormationIds'],
        'CountryIds': value['countryIds'],
        'RigTypes': value['rigTypes'],
        'RigClasses': value['rigClasses'],
        'RigHorsePowers': value['rigHorsePowers'],
        'OperatingCenters': value['operatingCenters'],
        'IncludeAlphaRigs': value['includeAlphaRigs'],
        'IncludeNonAlphaRigs': value['includeNonAlphaRigs'],
    };
}

