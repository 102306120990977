import { type DateDto, DrillingProductivityType } from "apis/oag";
import { IUnitSystem } from "reducers/types";

// data from the server comes in seconds so we just set msInSecond to 1; the names should be changed as well
export const secondsInMinute = 60;
export const secondsInHour = secondsInMinute * 60;
export const secondsInDay = secondsInHour * 24;
export const secondsInWeek = secondsInDay * 7;
export const secondsInMonth = secondsInDay * 30;
export const msToSeconds = 1000;
export const daysToSI = secondsInDay;
export const PLAN_SERIES_ID = -1;
export const RIG_LEGEND_IDS = -2;

export const ftInM = 3.28084;
export const sortEventsTimeline = (
  a: { cumulativeDuration?: number; id?: number },
  b: { cumulativeDuration?: number; id?: number },
) => {
  if (!a || !b) return 0;
  if (a.id === Infinity) return 1;
  else if (b.id === Infinity) return -1;
  return (a.cumulativeDuration || 0) - (b.cumulativeDuration || 0);
};

export const lb_ft3_to_km_m3 = 0.06242796;

export const TVD_SAMPLE_COUNT = 500;

export const PARAMETER_LENS_SAMPLE_COUNT = 2000;
export const PARAMETER_LENS_SAMPLE_COUNT_NULL = 0;

export const responsiveBreakpoints = {
  xs: 576,
  sm: 768,
  md: 960,
  lg: 1200,
  xl: 1440,
  xxl: 1580,
};

export type ResponsiveBreakpoints = keyof typeof responsiveBreakpoints;

export const defaultUnitSystem: IUnitSystem = IUnitSystem.METRIC;

export const defaultDateDto: { from: DateDto; to: DateDto } = {
  from: {
    isDateOnly: true,
    minutesOffset: 0,
    utc: new Date(Date.UTC(1970)),
  },
  to: {
    isDateOnly: true,
    minutesOffset: 0,
    utc: new Date(Date.UTC(2100)),
  },
};

export const drillingProductivityMap = [
  {
    id: DrillingProductivityType.DrillingRate,
    name: "Drilling Productivity",
  },
  {
    id: DrillingProductivityType.DrillingDistance,
    name: "Drilling Distance",
  },
  {
    id: DrillingProductivityType.DrillingDuration,
    name: "On Bottom Duration",
  },
  {
    id: DrillingProductivityType.TotalDuration,
    name: "Total Duration",
  },
];
