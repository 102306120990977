import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import type { UseTooltipParams } from "@visx/tooltip/lib/hooks/useTooltip";
import type {
  CurveDetails,
  CurveType,
  SeriesPoint,
  TooltipPoint,
} from "components/Lenses/common/LineChart/utils";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { bisector } from "d3-array";
import type { ScaleLinear } from "d3-scale";
import { min } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { OnRefChangeType } from "react-resize-detector/build/types/types";
import { calculateExtremitiesBisectFix } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

export const useLineChartTooltip = ({
  containerRef,
  xScale,
  isPointerInsideChart,
  plotWidth,
  plotHeight,
  renderContent,
  curves,
  availableSeries,
  legendActiveItemsMap,
}: {
  containerRef:
    | OnRefChangeType<HTMLDivElement>
    | React.MutableRefObject<HTMLDivElement>;
  xScale: ScaleLinear<number, number, never>;
  isPointerInsideChart: boolean;
  plotWidth: number;
  plotHeight: number;
  curves: CurveDetails[];
  availableSeries: SeriesPoint[];
  renderContent: (params: UseTooltipParams<TooltipPoint>) => React.ReactNode;
  legendActiveItemsMap: (
    pointValues: Record<CurveType, number>,
  ) => Record<CurveType, number>;
}) => {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const [pointerPosition, setPointerPosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });

  const handlePointerMove = useCallback(
    (event: React.PointerEvent<SVGSVGElement>) => {
      const { left, top } = event.currentTarget.getBoundingClientRect();
      const x = ("clientX" in event ? event.clientX : 0) - left;
      const y = ("clientY" in event ? event.clientY : 0) - top;
      setPointerPosition({ x, y });
    },
    [setPointerPosition],
  );

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip({
    containerRef,
    renderContent,
  });

  const tooltipPoint = useMemo<TooltipPoint | null>(() => {
    if (pointerPosition.x < 64 || pointerPosition.x > plotWidth + 64) {
      return null;
    }

    const x0 = xScale.invert(pointerPosition.x);

    const extremitiesBisectFix = calculateExtremitiesBisectFix(
      pointerPosition,
      plotWidth,
    );

    const index = bisector<SeriesPoint, number>(
      (p: SeriesPoint) => p.index,
    ).left(availableSeries, x0 + extremitiesBisectFix);

    return availableSeries[index]
      ? {
          at: availableSeries[index].at,
          index: availableSeries[index].index,
          left: pointerPosition.x,
          values: curves.reduce(
            (acc, curve) => {
              const value = curve.data[index]?.value || 0;
              return { ...acc, [curve.key]: value };
            },
            {} as Record<string, number>,
          ),
        }
      : null;
  }, [pointerPosition, plotWidth, xScale, curves, availableSeries]);

  useEffect(() => {
    isPointerInsideChart && tooltipPoint
      ? showTooltip({
          tooltipLeft: tooltipPoint?.left || 0,
          tooltipTop: min(
            curves.map((curve) =>
              curve.yScale(curve.data[tooltipPoint.index]?.value || 0),
            ),
          ),
          tooltipData: tooltipPoint,
        })
      : hideTooltip();
  }, [
    tooltipPoint,
    isPointerInsideChart,
    showTooltip,
    hideTooltip,
    xScale,
    curves,
  ]);

  const Tooltip =
    tooltipPoint && isPointerInsideChart ? (
      <>
        {tooltipElement}
        <Group>
          <Line
            from={{
              x: tooltipPoint.left,
              y: plotHeight,
            }}
            to={{
              x: tooltipPoint.left,
              y: 0,
            }}
            strokeOpacity={0.3}
            stroke={themeColors.primary_typography}
            strokeWidth={2}
          />
          {Object.keys(legendActiveItemsMap(tooltipPoint.values)).map((key) => {
            const curve = curves.find((curve) => curve.key === key);
            return curve?.data?.length ? (
              <g key={key}>
                <circle
                  cx={xScale(tooltipPoint.index)}
                  cy={curve?.yScale(tooltipPoint.values[key]) || 0}
                  r={4}
                  fill={curve?.color}
                />
                <circle
                  cx={xScale(tooltipPoint.index)}
                  cy={curve?.yScale(tooltipPoint.values[key]) || 0}
                  r={1}
                  fill={"white"}
                />
              </g>
            ) : null;
          })}
        </Group>
      </>
    ) : null;

  return { handlePointerMove, tooltipPoint, tooltipElement: Tooltip };
};
