import { PDComponent } from "components/PDComponents";
import * as Styled from "components/PDComponents/Modal/style";
import { defaultModalStyles } from "components/PDComponents/Modal/style";
import type { PropsWithChildren } from "react";
import React from "react";

export type ModalProps = {
  onCancel?: () => void;
  onAfterClose?: () => void;
  title?: React.ReactNode;
  centered?: boolean;
  open: boolean;
  hasCloseIcon?: boolean;
  footer?: React.ReactNode;
  width?: number;
  bodyStyle?: React.CSSProperties;
};

export const _Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  onCancel,
  onAfterClose,
  centered = false,
  open,
  hasCloseIcon = true,
  title,
  footer = null,
  width,
  bodyStyle,
  children,
}) => {
  return (
    <Styled.Modal
      isOpen={open}
      onAfterClose={onAfterClose}
      onRequestClose={onCancel}
      $width={width}
      $centered={centered}
      style={{
        overlay: defaultModalStyles.overlay,
      }}
      ariaHideApp={false}
    >
      <Styled.ModalContainer>
        {hasCloseIcon ? (
          <Styled.CloseIcon onClick={onCancel}>
            <PDComponent.SvgIcon name="close" />
          </Styled.CloseIcon>
        ) : null}
        {title ? (
          <Styled.ModalHeader>
            <Styled.Title>{title}</Styled.Title>
          </Styled.ModalHeader>
        ) : null}
        <Styled.ModalContent style={bodyStyle}>{children}</Styled.ModalContent>
        {footer ? <Styled.ModalFooter>{footer}</Styled.ModalFooter> : null}
      </Styled.ModalContainer>
    </Styled.Modal>
  );
};
