import { CustomSwitch } from "atoms/common";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { ListSearch } from "components/ListSearch";
import { GroupedOptions } from "components/PDComponents/Search/ComboBoxMultiSelect/GroupedOptions";
import type { OptionItemDataSource } from "components/PDComponents/Search/ComboBoxMultiSelect/OptionItem";
import { OptionItem } from "components/PDComponents/Search/ComboBoxMultiSelect/OptionItem";
import {
  GroupLabel,
  GroupSelectorContainer,
  LastSelectionContainer,
  SelectAllContainer,
  StyledBottomRow,
  StyledButton,
  StyledList,
  StyledOptionsContainer,
  StyledSpace,
  StyledSpaceForLayout,
  StyledSwitch,
} from "components/PDComponents/Search/style";
import type {
  IAllowedOptions,
  IOption,
} from "components/PDComponents/Search/utils";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { Checkbox, Col, Space } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

interface ICustomSearch<T extends IAllowedOptions> {
  placeholder: string;
  options: IOption<T>[];
  values: T[];
  width?: number;
  disableIfNoOptions?: boolean;
  showResetOnlyIfNoOptions?: boolean;
  height?: number;
  onChange: (e: T[]) => void;
  onOptionsChange?: (e: T[]) => void;
  onReset?: () => void;
  noSelectAll?: boolean;
  numSelectRecent?: number;
  resetName?: string;
  getLastNItemsIds?: () => T[];
  groupingOptions?: {
    isGroupChecked: boolean;
    setIsGroupChecked: React.Dispatch<boolean>;
    groupName: string;
  };
  filterOptions?: {
    showFiltered: boolean;
    handleFilteredClick: () => void;
    numFilteredWells: number;
    numAvailableWells: number;
  };
}

export function _ComboBoxMultiSelect<T extends IAllowedOptions>({
  placeholder = "Search",
  disableIfNoOptions = false,
  showResetOnlyIfNoOptions = false,
  options = [],
  values = [],
  width = 350,
  height = 257,
  onChange: onApply,
  onOptionsChange: onOptionsChangeParent,
  onReset,
  filterOptions,
  noSelectAll = false,
  numSelectRecent,
  resetName = "Reset",
  getLastNItemsIds,
  groupingOptions,
}: ICustomSearch<T>) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<IOption<T>[]>(options);
  const [selectedOptions, setSelectedOptions] = useState<T[]>(
    values || options.map((e) => e.id),
  );
  const [tempSelOptions, setTempSelOptions] = useState<T[]>(
    values || options.map((e) => e.id),
  );

  const onOptionsChange = useCallback(() => {
    onOptionsChangeParent?.(tempSelOptions);
  }, [onOptionsChangeParent, tempSelOptions]);

  useEffect(() => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    const selectedSet = new Set(selectedOptions);

    const tempFilter = options.filter((e) =>
      e?.name?.toLowerCase().includes(lowerSearchTerm),
    );

    const selected = tempFilter.filter((e) => selectedSet.has(e.id));
    const unselected = tempFilter.filter((e) => !selectedSet.has(e.id));

    setFilteredOptions([...selected, ...unselected]);
  }, [searchTerm, options, selectedOptions]);

  const selectAllChecked = tempSelOptions.length > 0;
  const selectAllIndeterminate =
    selectAllChecked && tempSelOptions.length < filteredOptions.length;

  const { isDark, atomThemeVariant } = useCustomTheme();

  const typographyVariant = useMemo(() => {
    if (!selectAllChecked) return "faded";
    return isDark ? "white" : "dark";
  }, [isDark, selectAllChecked]);

  const tempSelOptionsSet = new Set(tempSelOptions);

  const totalSelectedCount =
    tempSelOptions && filteredOptions
      ? filteredOptions.filter(
          (fOption) => fOption && tempSelOptionsSet.has(fOption.id),
        ).length
      : 0;

  const displayOnResetButton = useMemo(() => {
    const hideBecauseOfNoOptions =
      showResetOnlyIfNoOptions && tempSelOptions.length !== 0;

    return onReset && !hideBecauseOfNoOptions;
  }, [onReset, showResetOnlyIfNoOptions, tempSelOptions.length]);

  return (
    <StyledSpaceForLayout direction="vertical" size={0} width={width}>
      <ListSearch
        placeholder={placeholder}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
      >
        {!noSelectAll && (
          <SelectAllContainer>
            <Checkbox
              value="select-all"
              checked={selectAllChecked}
              indeterminate={selectAllIndeterminate}
              onChange={() => {
                if (selectAllIndeterminate || !selectAllChecked) {
                  setTempSelOptions(options.map((e) => e?.id));
                } else {
                  setTempSelOptions([]);
                }
                onOptionsChange();
              }}
            >
              <Title
                level={3}
                variant={typographyVariant}
                style={{
                  display: "inline-block",
                  width: width ? width - 50 : "100%",
                }}
              >
                Select All
              </Title>
            </Checkbox>
          </SelectAllContainer>
        )}
        {getLastNItemsIds && numSelectRecent ? (
          <LastSelectionContainer>
            {isEqual(selectedOptions, getLastNItemsIds()) ? (
              <>Last {numSelectRecent} wells selected</>
            ) : (
              <StyledButton
                onClick={() => {
                  setSelectedOptions(getLastNItemsIds());
                  setTempSelOptions(getLastNItemsIds());
                  onOptionsChange();
                  onApply(getLastNItemsIds());
                }}
              >
                Select last {numSelectRecent} wells
              </StyledButton>
            )}
            {groupingOptions ? (
              <GroupSelectorContainer>
                <CustomSwitch
                  onChange={groupingOptions.setIsGroupChecked}
                  checked={groupingOptions.isGroupChecked}
                />{" "}
                &nbsp;
                <GroupLabel
                  onClick={() => {
                    onOptionsChange();
                    groupingOptions.setIsGroupChecked(
                      !groupingOptions.isGroupChecked,
                    );
                  }}
                >
                  Group by {groupingOptions.groupName}
                </GroupLabel>
              </GroupSelectorContainer>
            ) : null}
          </LastSelectionContainer>
        ) : null}
        <StyledOptionsContainer $height={height}>
          {groupingOptions?.isGroupChecked ? (
            <GroupedOptions<T>
              filteredOptions={filteredOptions}
              tempSelOptions={tempSelOptions}
              onChange={(newOptions: T[]) => {
                setTempSelOptions(newOptions);
                onOptionsChange();
              }}
            />
          ) : (
            <AutoSizer>
              {({ height, width }: { height: number; width: number }) => (
                <StyledList<OptionItemDataSource<T>>
                  height={Number.isNaN(height) ? "100%" : height}
                  width={width}
                  itemSize={(item) => {
                    if (filteredOptions[item]?.description) {
                      return filteredOptions[item]?.name?.length > 45 ? 71 : 61;
                    }
                    return filteredOptions[item]?.name?.length > 45 ? 56 : 46;
                  }}
                  itemCount={filteredOptions.length}
                  itemData={{
                    width,
                    filteredOptions,
                    tempSelOptions,
                    onChange: (newOptions: T[]) => {
                      setTempSelOptions(newOptions);
                      onOptionsChange();
                    },
                  }}
                >
                  {({ style, index, data }) => (
                    <OptionItem<T> {...{ style, index, data }} />
                  )}
                </StyledList>
              )}
            </AutoSizer>
          )}
        </StyledOptionsContainer>
      </ListSearch>
      <StyledBottomRow>
        <Col flex="0 auto">
          {filterOptions ? (
            <StyledSpace>
              <Space>
                <StyledSwitch
                  disabled={
                    filterOptions.numFilteredWells === 0 ||
                    filterOptions.numFilteredWells ===
                      filterOptions.numAvailableWells
                  }
                  checked={filterOptions.showFiltered}
                  onClick={filterOptions.handleFilteredClick}
                />
                <Title
                  level={3}
                  variant={
                    filterOptions.numFilteredWells === 0 ||
                    filterOptions.numFilteredWells ===
                      filterOptions.numAvailableWells
                      ? "faded"
                      : atomThemeVariant
                  }
                >
                  Filtered Wells Only
                </Title>
              </Space>
              <Title level={5} variant={typographyVariant}>
                {filterOptions.showFiltered
                  ? filterOptions.numFilteredWells
                  : filterOptions.numAvailableWells}
                /{filterOptions.numAvailableWells} Wells Available
              </Title>
            </StyledSpace>
          ) : (
            <Title level={3} variant={typographyVariant}>
              {totalSelectedCount} selected
            </Title>
          )}
        </Col>
        <Col flex="0 auto">
          <Space>
            {displayOnResetButton ? (
              <Button
                size="large"
                onClick={onReset}
                trackingName="Reset Search"
              >
                {resetName}
              </Button>
            ) : null}

            {filterOptions ? (
              <Title level={3} variant={typographyVariant}>
                {totalSelectedCount} selected
              </Title>
            ) : null}
            <Button
              disabled={
                disableIfNoOptions ? tempSelOptions.length === 0 : false
              }
              type="primary"
              size="large"
              trackingName="Search"
              onClick={() => {
                onOptionsChange();
                onApply(tempSelOptions);
                setSelectedOptions(tempSelOptions);
              }}
            >
              Apply
            </Button>
          </Space>
        </Col>
      </StyledBottomRow>
    </StyledSpaceForLayout>
  );
}
