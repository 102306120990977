/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface DataFeedWellDatesDto
 */
export interface DataFeedWellDatesDto {
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastWellRefreshDate?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastTvdRefreshDate?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastPerStandRefreshDate?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastRigStateRefreshDate?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastByDepthRefreshDate?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastBatteryStateRefreshDate?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastPowerManagementRefreshDate?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof DataFeedWellDatesDto
     */
    lastFuelManagementRefreshDate?: DateDto | null;
    /**
     * 
     * @type {number}
     * @memberof DataFeedWellDatesDto
     */
    wellId: number;
}

/**
 * Check if a given object implements the DataFeedWellDatesDto interface.
 */
export function instanceOfDataFeedWellDatesDto(value: object): value is DataFeedWellDatesDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    return true;
}

export function DataFeedWellDatesDtoFromJSON(json: any): DataFeedWellDatesDto {
    return DataFeedWellDatesDtoFromJSONTyped(json, false);
}

export function DataFeedWellDatesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataFeedWellDatesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'lastWellRefreshDate': json['LastWellRefreshDate'] == null ? undefined : DateDtoFromJSON(json['LastWellRefreshDate']),
        'lastTvdRefreshDate': json['LastTvdRefreshDate'] == null ? undefined : DateDtoFromJSON(json['LastTvdRefreshDate']),
        'lastPerStandRefreshDate': json['LastPerStandRefreshDate'] == null ? undefined : DateDtoFromJSON(json['LastPerStandRefreshDate']),
        'lastRigStateRefreshDate': json['LastRigStateRefreshDate'] == null ? undefined : DateDtoFromJSON(json['LastRigStateRefreshDate']),
        'lastByDepthRefreshDate': json['LastByDepthRefreshDate'] == null ? undefined : DateDtoFromJSON(json['LastByDepthRefreshDate']),
        'lastBatteryStateRefreshDate': json['LastBatteryStateRefreshDate'] == null ? undefined : DateDtoFromJSON(json['LastBatteryStateRefreshDate']),
        'lastPowerManagementRefreshDate': json['LastPowerManagementRefreshDate'] == null ? undefined : DateDtoFromJSON(json['LastPowerManagementRefreshDate']),
        'lastFuelManagementRefreshDate': json['LastFuelManagementRefreshDate'] == null ? undefined : DateDtoFromJSON(json['LastFuelManagementRefreshDate']),
        'wellId': json['WellId'],
    };
}

export function DataFeedWellDatesDtoToJSON(value?: DataFeedWellDatesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'LastWellRefreshDate': DateDtoToJSON(value['lastWellRefreshDate']),
        'LastTvdRefreshDate': DateDtoToJSON(value['lastTvdRefreshDate']),
        'LastPerStandRefreshDate': DateDtoToJSON(value['lastPerStandRefreshDate']),
        'LastRigStateRefreshDate': DateDtoToJSON(value['lastRigStateRefreshDate']),
        'LastByDepthRefreshDate': DateDtoToJSON(value['lastByDepthRefreshDate']),
        'LastBatteryStateRefreshDate': DateDtoToJSON(value['lastBatteryStateRefreshDate']),
        'LastPowerManagementRefreshDate': DateDtoToJSON(value['lastPowerManagementRefreshDate']),
        'LastFuelManagementRefreshDate': DateDtoToJSON(value['lastFuelManagementRefreshDate']),
        'WellId': value['wellId'],
    };
}

