import { useMutation, useQueryClient } from "@tanstack/react-query";
import type {
  KpiGroupLensTemplateDto,
  PivotKpiGroupLensTemplateDto,
  PivotKpiTypeLensTemplateDto,
  UserLensPositionDto,
} from "apis/oag";
import {
  BatteryStateUserLensesApi,
  BessType,
  DashboardType,
  DieselNatGasRatioStatsUserLensesApi,
  DrillingProductivityType,
  DrillingProductivityUserLensesApi,
  FuelConsumptionByGeneratorUserLensesApi,
  FuelFlowRateUserLensesApi,
  FuelTankVolumeUserLensesApi,
  FuelType,
  GasType,
  GeneratorLoadProfileUserLensesApi,
  GeneratorStatsUserLensesApi,
  GeneratorStatusBarUserLensesApi,
  GhgEmissionsUserLensesApi,
  KpiGroupUserLensesApi,
  KpiTypeUserLensesApi,
  LensTemplateType,
  OutlierFlaggingType,
  OutliersFilteringType,
  ParameterByDepthUserLensesApi,
  ParameterHeatmapUserLensesApi,
  PivotKpiGroupUserLensesApi,
  PivotKpiTypeUserLensesApi,
  PivotType,
  PowerConsumptionUserLensesApi,
  PowerLoadDistributionType,
  PowerLoadDistributionUserLensesApi,
  PowerLoadEfficiencyUserLensesApi,
  RigPowerUserLensesApi,
  StatCardDataType,
  StatCardUserLensesApi,
  StickSlipByDepthUserLensesApi,
  StickSlipByTimeUserLensesApi,
  TagBottomFingerprintUserLensesApi,
  TimeUnit,
  TorqueAndDragType,
  TorqueAndDragUserLensesApi,
  WedgeUserLensesApi,
  WellDrillingSummaryUserLensesApi,
} from "apis/oag";
import { DashboardBlockSize } from "apis/oag";
import { GenericUserLensesApi } from "apis/oag/apis/GenericUserLensesApi";
import { NaturalGasStateUserLensesApi } from "apis/oag/apis/NaturalGasStateUserLensesApi";
import { FuelConsumerType } from "apis/oag/models/FuelConsumerType";
import type { RigPowerLensTemplateDto } from "apis/oag/models/RigPowerLensTemplateDto";
import { Input } from "atoms/Form";
import { toast } from "atoms/toast";
import CoreLayout from "components/Layout";
import {
  GRID_HEIGHT_HALF,
  GRID_HEIGHT_QUARTER,
  GRID_HEIGHT_QUARTER_AND_EIGHTH,
  GRID_HEIGHT_STAT,
  GRID_WIDTH_FACTOR,
  GRID_WIDTH_FULL,
  GRID_WIDTH_HALF,
  GRID_WIDTH_QUARTER,
  GRID_WIDTH_THREE_QUARTERS,
} from "components/Lenses/constants";
import { LENS_POSITION_BOTTOM } from "components/Lenses/index";
import { allGenerators } from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import { useUserLensTabs } from "hooks/dashboard/useUserLensTabs";
import { useKpiGroups } from "hooks/drillingInvariants/useKPIGroups";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import type { LensTemplate } from "hooks/lens/useLensTemplates";
import {
  isDrillingProductivityLensTemplate,
  isFueFlowRateTemplate,
  isMultipleKpiTemplate,
  isPivotKpiGroupLensTemplate,
  isPivotKpiTypeLensTemplate,
  isRigPowerTemplate,
  isSingleKpiTemplate,
  isStatCardTemplate,
  isWedgeTemplate,
  useLensTemplates,
} from "hooks/lens/useLensTemplates";
import { UserLensesQueryKey } from "hooks/lens/useUserLenses";
import { LensItem } from "pages/LensLibrary/LensItem";
import { mockElements } from "pages/LensLibrary/mockElements";
import qs from "qs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Track, useMixpanel } from "services/Mixpanel";
import styled, { css } from "styled-components";
import { useDebounce } from "use-debounce";
import { apiConfig } from "utils/apiConfig";
import { drillingProductivityMap } from "utils/common";
import { checkDashboardType } from "utils/helper";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";
import { zIndexLayer } from "utils/zIndex";

const isKpiGroupLensTemplate = (
  template: LensTemplate,
): template is KpiGroupLensTemplateDto => {
  return template.type === LensTemplateType.StackedKpis;
};
const isPivotKpiLensTemplate = (
  template: LensTemplate,
): template is PivotKpiTypeLensTemplateDto => {
  return template.type === LensTemplateType.PivotKpiType;
};

const isPivotGroupKpiLensTemplate = (
  template: LensTemplate,
): template is PivotKpiGroupLensTemplateDto => {
  return template.type === LensTemplateType.PivotKpiGroup;
};

const Header = styled.header`
  padding: 44px 44px 40px 44px;
  background: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
`;

const Title = styled.div`
  font-size: 34px;
  font-weight: 500;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

const Separator = styled.div`
  opacity: 0.06;
  background-color: black;
  height: 1px;
  margin: 0 44px;
`;

const AvailableLenses = styled.div`
  color: #828c91;
  font-size: 16px;
  letter-spacing: -0.2px;
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: baseline;
  position: sticky;
  top: 0;
  z-index: ${zIndexLayer.sky};

  background: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 26px 30px;

  .ant-input-suffix .pd-icon {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;

const SearchInput = styled(Input)`
  border: ${({ theme: { isDark, themeStyle } }) =>
    isDark ? "1px solid " + themeStyle.colors.primary_accent : "none"};
  outline: none;
  max-width: 360px;
  &,
  input,
  .ant-input {
    ${({ theme: { isDark } }) =>
      isDark &&
      css`
        background: transparent !important;
      `}
  }
`;

const LensGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 326px);
  grid-gap: 16px;
  padding: 40px 44px;
`;

const genericUserLensesApi = new GenericUserLensesApi(apiConfig);

const pivotKpiGroupUserLensesApi = new PivotKpiGroupUserLensesApi(apiConfig);
const pivotKpiTypeUserLensesApi = new PivotKpiTypeUserLensesApi(apiConfig);
const kpiGroupUserLensesApi = new KpiGroupUserLensesApi(apiConfig);
const kpiTypeUserLensesApi = new KpiTypeUserLensesApi(apiConfig);
const wedgeUserLensesApi = new WedgeUserLensesApi(apiConfig);
const parameterUserLensesApi = new ParameterByDepthUserLensesApi(apiConfig);
const wellDrillingSummaryUserLensesApi = new WellDrillingSummaryUserLensesApi(
  apiConfig,
);
const statCardUserLensesApi = new StatCardUserLensesApi(apiConfig);
const parameterHeatmapUserLensesApi = new ParameterHeatmapUserLensesApi(
  apiConfig,
);
const torqueAndDragUserLensesApi = new TorqueAndDragUserLensesApi(apiConfig);
const rigPowerUserLensesApi = new RigPowerUserLensesApi(apiConfig);
const naturalGasStateUserLenses = new NaturalGasStateUserLensesApi(apiConfig);
const generatorLoadProfileLensesApi = new GeneratorLoadProfileUserLensesApi(
  apiConfig,
);

const batteryStateUserLensesApi = new BatteryStateUserLensesApi(apiConfig);
const powerConsumptionUserLensesApi = new PowerConsumptionUserLensesApi(
  apiConfig,
);
const generatorStatusBarUserLensesApi = new GeneratorStatusBarUserLensesApi(
  apiConfig,
);
const powerLoadDistributionUserLensesApi =
  new PowerLoadDistributionUserLensesApi(apiConfig);
const GHGEmissionsUserLensesApi = new GhgEmissionsUserLensesApi(apiConfig);
const fuelFlowRateUserLensesApi = new FuelFlowRateUserLensesApi(apiConfig);
const generatorStatsUserLensesApi = new GeneratorStatsUserLensesApi(apiConfig);
const fuelTankVolumeUserLensesApi = new FuelTankVolumeUserLensesApi(apiConfig);
const fuelConsumptionByGeneratorUserLensesApi =
  new FuelConsumptionByGeneratorUserLensesApi(apiConfig);
const powerLoadEfficiencyUserLensesApi = new PowerLoadEfficiencyUserLensesApi(
  apiConfig,
);
const dieselNatGasRatioUserLensesApi = new DieselNatGasRatioStatsUserLensesApi(
  apiConfig,
);
const drillingProductivityUserLensesApi = new DrillingProductivityUserLensesApi(
  apiConfig,
);
const stickSlipByDepthUserLensesApi = new StickSlipByDepthUserLensesApi(
  apiConfig,
);
const tagBottomFingerprintUserLensesApi = new TagBottomFingerprintUserLensesApi(
  apiConfig,
);
const stickSlipByTimeUserLensesApi = new StickSlipByTimeUserLensesApi(
  apiConfig,
);

let newlyAddedLensPositionInfo = {
  width: GRID_WIDTH_FULL,
  height: GRID_HEIGHT_QUARTER,
};

export function LensLibrary() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTab =
    parseInt(
      qs.parse(location.search, { ignoreQueryPrefix: true })
        .selectedTab as string,
      10,
    ) || 1;
  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();
  useEffect(() => {
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage("Lens Library");
    }
    return () => {
      newlyAddedLensPositionInfo = {
        width: GRID_WIDTH_FULL,
        height: GRID_HEIGHT_QUARTER,
      };
    };
  }, [viewPage]);

  const { dashboardType: dashboardTypeParam, dashboardId } = useParams<{
    dashboardType: string;
    dashboardId: string;
  }>();

  const dashboardType = useMemo(
    () => checkDashboardType(dashboardTypeParam || ""),
    [dashboardTypeParam],
  );

  const { data: tabs } = useUserLensTabs(dashboardType || DashboardType.Well);
  const tabName = useMemo(
    () => tabs.find((t) => t.id === selectedTab)?.name || "Undefined",
    [selectedTab, tabs],
  );

  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 500);
  const lowerCaseSearch = debouncedSearch.toLocaleLowerCase();

  const { data: kpiGroups } = useKpiGroups();
  const { data: kpiTypes } = useKpiTypes();
  const { data: templates } = useLensTemplates();

  const updateLensPositionMutation = useMutation({
    mutationFn: ({
      id,
      position,
    }: {
      id: number;
      position: Omit<UserLensPositionDto, "lensId">;
    }) => {
      // It's an array but you can have a partial put to update only one lens in this case
      const updatedLens: Array<UserLensPositionDto> = [
        { ...position, lensId: id },
      ];

      return genericUserLensesApi.apiGenericUserLensesPositionsPut({
        userLensPositionSetDto: {
          dashboardWidth: DashboardBlockSize.FullWidth,
          positions: updatedLens,
        },
      });
    },
    ...{
      onSuccess: async () => {
        Track.interact("Lens Library - Lens Added Successfully!");
        toast.success({
          message: "Lens added successfully!",
        });
        await queryClient.invalidateQueries({ queryKey: [UserLensesQueryKey] });
        navigate(
          `/${(dashboardType || "Well").toLowerCase()}/${dashboardId}${
            location.search
              ? `${location.search}&selectedTab=${selectedTab}`
              : `?selectedTab=${selectedTab}`
          }`,
        );
      },
    },
  });

  const addLensMutation = useMutation({
    mutationFn: async (lens: {
      lensTemplateId: number;
      lensTabId: number;
      breakDownId?: number;
    }) => {
      const template = templates.byId[lens.lensTemplateId];
      if (!template) return null;
      const DEFAULT_LENS_SETTINGS = {
        lensTabId: selectedTab,
        lensTemplateId: lens.lensTemplateId,
        id: 0,
        isAvailableOnReports: false,
        label: false,
        isSystem: false,
      };

      if (template.type === LensTemplateType.StatCard) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type": "Stat card lens",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_FACTOR,
          height: GRID_HEIGHT_STAT,
        };

        return statCardUserLensesApi.apiStatCardUserLensesPost({
          statCardUserLensDto: {
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            selectedFuel: FuelType.Diesel,
            id: 0,
            isAvailableOnReports: false,
            label: false,
            selectedBoilers: [],
            selectedGenerators: allGenerators,
            squeezesDisplay: false,
            selectedGas: GasType.Unknown,
            isSystem: false,
          },
        });
      }
      if (template.type === LensTemplateType.WellDrillingSummary) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type": "Well Drilling Summary",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_THREE_QUARTERS,
          height: GRID_HEIGHT_QUARTER,
        };

        return wellDrillingSummaryUserLensesApi.apiWellDrillingSummaryUserLensesPost(
          {
            wellDrillingSummaryUserLensDto: {
              lensTabId: selectedTab,
              lensTemplateId: lens.lensTemplateId,
              id: 0,
              isAvailableOnReports: false,
              label: false,
              isSystem: false,
            },
          },
        );
      }

      if (
        isPivotKpiLensTemplate(template) &&
        template?.selectableKpiTypeIds?.length
      ) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type": "Pivot Kpi Type",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_QUARTER,
          height: GRID_HEIGHT_QUARTER,
        };

        return pivotKpiTypeUserLensesApi.apiPivotKpiTypeUserLensesPost({
          pivotKpiTypeUserLensDto: {
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            kpiTypeId: template.selectableKpiTypeIds[0],
            outlierFlaggingType: OutlierFlaggingType.Auto,
            id: 0,
            isAvailableOnReports: true,
            label: false,
            squeezesDisplay: false,
            isManualYaxis: false,
            showsOutliers: false,
            showStandKpiComments: false,
            showOperationCount: false,
            selectedVisualAids: [],
            selectedTimeUnit: TimeUnit.Unknown,
            defaultPivotLevel1: PivotType.None,
            defaultPivotLevel2: PivotType.None,
            defaultPivotLevel3: PivotType.None,
            isSystem: false,
          },
        });
      }

      if (
        isPivotGroupKpiLensTemplate(template) &&
        template?.selectableKpiGroupIds?.length
      ) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type": "Pivot Kpi Group",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_QUARTER,
          height: GRID_HEIGHT_QUARTER,
        };

        return pivotKpiGroupUserLensesApi.apiPivotKpiGroupUserLensesPost({
          pivotKpiGroupUserLensDto: {
            selectedTimeUnit: TimeUnit.Unknown,
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            kpiGroupId: template.selectableKpiGroupIds[0],
            outlierFlaggingType: OutlierFlaggingType.Auto,
            stackingType: template.defaultStackingType,
            id: 0,
            isAvailableOnReports: true,
            label: false,
            squeezesDisplay: false,
            isManualYaxis: false,
            showsOutliers: false,
            showOperationCount: false,
            selectedVisualAids: [],
            defaultPivotLevel1: PivotType.None,
            defaultPivotLevel2: PivotType.None,
            defaultPivotLevel3: PivotType.None,
            isSystem: false,
          },
        });
      }

      if (
        isSingleKpiTemplate(template) &&
        template?.selectableKpiTypeIds?.length
      ) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type": "Single Kpi",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_QUARTER,
          height: GRID_HEIGHT_QUARTER,
        };

        return kpiTypeUserLensesApi.apiKpiTypeUserLensesPost({
          kpiTypeUserLensDto: {
            selectedTimeUnit: TimeUnit.Unknown,
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            kpiTypeId: template.selectableKpiTypeIds[0],
            outlierFlaggingType: OutlierFlaggingType.Auto,
            id: 0,
            isAvailableOnReports: true,
            label: false,
            squeezesDisplay: false,
            isManualYaxis: false,
            showsOutliers: false,
            showStandKpiComments: false,
            showOperationCount: false,
            selectedVisualAids: [],
            isSystem: false,
          },
        });
      }

      if (
        isKpiGroupLensTemplate(template) &&
        template?.selectableKpiGroupIds?.length
      ) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type": "Stacked Kpi",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_QUARTER,
          height: GRID_HEIGHT_QUARTER,
        };

        return kpiGroupUserLensesApi.apiKpiGroupUserLensesPost({
          kpiGroupUserLensDto: {
            id: 0,
            label: false,
            selectedTimeUnit: TimeUnit.Unknown,
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            stackingType: template.defaultStackingType,
            kpiGroupId: template.selectableKpiGroupIds[0],
            outlierFlaggingType: OutlierFlaggingType.Auto,
            isAvailableOnReports: true,
            squeezesDisplay: false,
            isManualYaxis: false,
            showsOutliers: false,
            showOperationCount: false,
            selectedVisualAids: [],
            isSystem: false,
          },
        });
      }

      if (
        template.type === LensTemplateType.ParameterByDepth ||
        template.type === LensTemplateType.ParameterByDepthRoadmap
      ) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type":
            template.type === LensTemplateType.ParameterByDepth
              ? "Parameter By Depth"
              : "Parameter By Depth Roadmap",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_THREE_QUARTERS,
          height: GRID_HEIGHT_QUARTER,
        };

        return parameterUserLensesApi.apiParameterByDepthUserLensesPost({
          parameterByDepthUserLensDto: {
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            id: 0,
            isAvailableOnReports: true,
            label: false,
            squeezesDisplay: false,
            userLensTrackItems: [],
            isSystem: false,
          },
        });
      }

      if (template.type === LensTemplateType.Wedge && lens.breakDownId) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type": "Wedge Rig States",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_QUARTER,
          height: GRID_HEIGHT_QUARTER,
        };

        return wedgeUserLensesApi.apiWedgeUserLensesPost({
          wedgeUserLensDto: {
            breakdownId: lens.breakDownId,
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            id: 0,
            isAvailableOnReports: true,
            label: false,
            isSystem: false,
          },
        });
      }

      if (template.type === LensTemplateType.ParameterHeatmap) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type": "Parameter Heatmap",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_QUARTER,
          height: GRID_HEIGHT_QUARTER,
        };

        return parameterHeatmapUserLensesApi.apiParameterHeatmapUserLensesPost({
          parameterHeatmapUserLensDto: {
            isSystem: false,
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            xTrack: {
              trackId: 1,
              id: 0,
              outliersType: OutliersFilteringType.Percentile,
              outliersMinPercentile: null,
              outliersMaxPercentile: null,
              outliersMinValue: null,
              outliersMaxValue: null,
            },
            yTrack: {
              trackId: 2,
              id: 0,
              outliersType: OutliersFilteringType.Percentile,
              outliersMinPercentile: null,
              outliersMaxPercentile: null,
              outliersMinValue: null,
              outliersMaxValue: null,
            },
            zTrack: {
              trackId: 3,
              outliersType: OutliersFilteringType.Percentile,
              outliersMinPercentile: 0.03,
              outliersMaxPercentile: 0.97,
              outliersMinValue: null,
              outliersMaxValue: null,
              id: 0,
            },
            showActiveBin: true,
            showFocalRecommendation: true,
            showOffsetRecommendation: true,
            focalRecommendationDepthRange: 300,
            offsetRecommendationDepthRange: 150,
            binDistanceThreshold: 0,
            id: 0,
            isAvailableOnReports: true,
            label: false,
            xTrackId: 0,
            yTrackId: 0,
            zTrackId: 0,
            showOffsetData: false,
          },
        });
      }
      if (template.type === LensTemplateType.TorqueAndDrag) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type": "Torque And Drag",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_HALF,
          height: GRID_HEIGHT_HALF,
        };

        return torqueAndDragUserLensesApi.apiTorqueAndDragUserLensesPost({
          torqueAndDragUserLensDto: {
            isSystem: false,
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            showSurvey: true,
            torqueAndDragType: TorqueAndDragType.Drag,
            id: 0,
            isAvailableOnReports: true,
            label: false,
          },
        });
      }

      if (template.type === LensTemplateType.RigPower) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type": "Rig Power",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_FULL,
          height:
            (template as RigPowerLensTemplateDto).bessType === BessType.Bess
              ? GRID_HEIGHT_HALF
              : GRID_HEIGHT_QUARTER,
        };

        return rigPowerUserLensesApi.apiRigPowerUserLensesPost({
          rigPowerUserLensDto: {
            isSystem: false,
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            squeezesDisplay: true,
            showDetailedLegend:
              (template as RigPowerLensTemplateDto).bessType === BessType.Bess,
            id: 0,
            isAvailableOnReports: true,
            label: false,
          },
        });
      }

      if (template.type === LensTemplateType.NaturalGasState) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type": "Natural Gas State",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_FULL,
          height: GRID_HEIGHT_QUARTER,
        };

        return naturalGasStateUserLenses.apiNaturalGasStateUserLensesPost({
          naturalGasStateUserLensDto: {
            isSystem: false,
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            squeezesDisplay: true,
            id: 0,
            isAvailableOnReports: true,
            label: false,
            selectedGenerators: allGenerators,
          },
        });
      }

      if (template.type === LensTemplateType.GeneratorLoadProfile) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type": "Generator Load Profile",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_QUARTER,
          height: GRID_HEIGHT_QUARTER,
        };

        return generatorLoadProfileLensesApi.apiGeneratorLoadProfileUserLensesPost(
          {
            generatorLoadProfileUserLensDto: {
              isSystem: false,
              squeezesDisplay: false,
              lensTabId: selectedTab,
              lensTemplateId: lens.lensTemplateId,
              id: 0,
              isAvailableOnReports: true,
              label: false,
              selectedGenerators: allGenerators,
            },
          },
        );
      }

      if (template.type === LensTemplateType.BatteryState) {
        Track.interact("Lens Library - Add New Lens", {
          "Lens Type": "Battery state",
          "Tab Name": tabName,
        });

        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_FULL,
          height: GRID_HEIGHT_QUARTER,
        };

        return batteryStateUserLensesApi.apiBatteryStateUserLensesPost({
          batteryStateUserLensDto: {
            isSystem: false,
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            squeezesDisplay: true,
            id: 0,
            isAvailableOnReports: false,
            label: false,
          },
        });
      }

      if (template.type === LensTemplateType.PowerConsumptionByOperation) {
        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_FULL,
          height: GRID_HEIGHT_QUARTER,
        };

        return powerConsumptionUserLensesApi.apiPowerConsumptionUserLensesPost({
          powerConsumptionByOperationUserLensDto: {
            isSystem: false,
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            id: 0,
            isAvailableOnReports: false,
            label: false,
            squeezesDisplay: false,
          },
        });
      }
      if (template.type === LensTemplateType.GeneratorStatusBar) {
        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_FULL,
          height: GRID_HEIGHT_STAT,
        };

        return generatorStatusBarUserLensesApi.apiGeneratorStatusBarUserLensesPost(
          {
            generatorStatusBarUserLensDto: {
              isSystem: false,
              lensTabId: selectedTab,
              lensTemplateId: lens.lensTemplateId,
              id: 0,
              isAvailableOnReports: false,
              label: false,
            },
          },
        );
      }
      if (template.type === LensTemplateType.PowerLoadDistribution) {
        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_HALF,
          height: GRID_HEIGHT_QUARTER,
        };

        return powerLoadDistributionUserLensesApi.apiPowerLoadDistributionUserLensesPost(
          {
            powerLoadDistributionUserLensDto: {
              isSystem: false,
              lensTabId: selectedTab,
              lensTemplateId: lens.lensTemplateId,
              id: 0,
              isAvailableOnReports: false,
              label: false,
              squeezesDisplay: false,
              distributionType: PowerLoadDistributionType.ActiveGeneratorCount,
              selectedGenerators: allGenerators,
            },
          },
        );
      }
      if (template.type === LensTemplateType.GhgEmissions) {
        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_FULL,
          height: GRID_HEIGHT_QUARTER,
        };
        return GHGEmissionsUserLensesApi.apiGhgEmissionsUserLensesPost({
          ghgEmissionsUserLensDto: {
            isSystem: false,
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            id: 0,
            isAvailableOnReports: false,
            label: false,
          },
        });
      }
      if (template.type === LensTemplateType.GeneratorStats) {
        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_QUARTER,
          height: GRID_HEIGHT_QUARTER,
        };

        return generatorStatsUserLensesApi.apiGeneratorStatsUserLensesPost({
          generatorStatsUserLensDto: {
            isSystem: false,
            lensTabId: selectedTab,
            lensTemplateId: lens.lensTemplateId,
            id: 0,
            isAvailableOnReports: false,
            label: false,
            squeezesDisplay: false,
            selectedGenerators: allGenerators,
          },
        });
      }

      if (template.type === LensTemplateType.FuelFlowRate) {
        return fuelFlowRateUserLensesApi.apiFuelFlowRateUserLensesPost({
          fuelFlowRateUserLensDto: DEFAULT_LENS_SETTINGS,
        });
      }

      if (template.type === LensTemplateType.FuelTankVolume) {
        return fuelTankVolumeUserLensesApi.apiFuelTankVolumeUserLensesPost({
          fuelTankVolumeUserLensDto: DEFAULT_LENS_SETTINGS,
        });
      }
      if (template.type === LensTemplateType.FuelConsumptionByGenerator) {
        return fuelConsumptionByGeneratorUserLensesApi.apiFuelConsumptionByGeneratorUserLensesPost(
          {
            fuelConsumptionByGeneratorUserLensDto: {
              ...DEFAULT_LENS_SETTINGS,
              selectedFuel: FuelType.Diesel,
              squeezesDisplay: false,
              selectedBoilers: [],
              selectedGenerators: allGenerators,
            },
          },
        );
      }
      if (template.type === LensTemplateType.PowerLoadEfficiency) {
        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_QUARTER,
          height: GRID_HEIGHT_QUARTER,
        };

        return powerLoadEfficiencyUserLensesApi.apiPowerLoadEfficiencyUserLensesPost(
          {
            powerLoadEfficiencyUserLensDto: {
              ...DEFAULT_LENS_SETTINGS,
              squeezesDisplay: false,
              selectedGenerators: allGenerators,
            },
          },
        );
      }

      if (template.type === LensTemplateType.DieselNatGasRatioStats) {
        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_FACTOR,
          height: GRID_HEIGHT_STAT,
        };

        return dieselNatGasRatioUserLensesApi.apiDieselNatGasRatioStatsUserLensesPost(
          {
            dieselNatGasRatioStatsUserLensDto: DEFAULT_LENS_SETTINGS,
          },
        );
      }
      if (template.type === LensTemplateType.DrillingProductivity) {
        return drillingProductivityUserLensesApi.apiDrillingProductivityUserLensesPost(
          {
            drillingProductivityUserLensDto: {
              ...DEFAULT_LENS_SETTINGS,
              drillingProductivityType: DrillingProductivityType.DrillingRate,
              squeezesDisplay: false,
              isManualYaxis: false,
              selectedTimeUnit: TimeUnit.Day,
            },
          },
        );
      }

      // TODO change those
      if (template.type === LensTemplateType.TagBottomFingerprint) {
        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_FULL,
          height: GRID_HEIGHT_QUARTER + GRID_HEIGHT_STAT,
        };
        return tagBottomFingerprintUserLensesApi.apiTagBottomFingerprintUserLensesPost(
          {
            tagBottomFingerprintUserLensDto: {
              ...DEFAULT_LENS_SETTINGS,
              isBithDepthNormalized: false,
              squeezesDisplay: false,
              userLensTrackItems: [],
              standCount: 15,
              showOverallView: false,
              showAlphaComparison: false,
            },
          },
        );
      }
      if (template.type === LensTemplateType.StickSlipByDepth) {
        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_FULL,
          height: GRID_HEIGHT_QUARTER_AND_EIGHTH,
        };

        return stickSlipByDepthUserLensesApi.apiStickSlipByDepthUserLensesPost({
          stickSlipByDepthUserLensDto: {
            ...DEFAULT_LENS_SETTINGS,
            squeezesDisplay: false,
            userLensTrackItems: [],
            showZTorque: false,
            showPercentageDifference: false,
          },
        });
      }
      if (template.type === LensTemplateType.StickSlipByTime) {
        newlyAddedLensPositionInfo = {
          width: GRID_WIDTH_FULL,
          height: GRID_HEIGHT_QUARTER_AND_EIGHTH,
        };

        return stickSlipByTimeUserLensesApi.apiStickSlipByTimeUserLensesPost({
          stickSlipByTimeUserLensDto: {
            ...DEFAULT_LENS_SETTINGS,
            squeezesDisplay: false,
            userLensTrackItems: [],
            showZTorque: false,
            showPercentageDifference: false,
          },
        });
      }
    },

    // ! lens boilerplate

    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [UserLensesQueryKey] });
      await queryClient.invalidateQueries({
        queryKey: [{ type: PDQueryType.WELL_OVERVIEW }],
        ...{ exact: false },
      });
      await queryClient.invalidateQueries({
        queryKey: [{ uid: RequestUID.wellShortInfo }],
        ...{ exact: false },
      });
    },
    onSuccess: (newlyInsertedLens) => {
      if (newlyInsertedLens) {
        updateLensPositionMutation.mutate({
          id: newlyInsertedLens.id,
          position: {
            ...newlyAddedLensPositionInfo,
            position: LENS_POSITION_BOTTOM,
          },
        });
      }
    },
  });

  const filteredResults = useMemo(
    () =>
      templates?.list
        .filter((template) => template.targetDashboardType === dashboardType)
        .sort((a, b) => a.position - b.position)
        .map((template) => {
          let options: string[] = [];
          const element = (() => {
            if (isDrillingProductivityLensTemplate(template)) {
              options = Object.keys(DrillingProductivityType)
                .map((type) => {
                  if (type === DrillingProductivityType.Unknown) {
                    return "";
                  }

                  return (
                    drillingProductivityMap.find((p) => p.id === type)?.name ??
                    type.split(/(?=[A-Z])/).join(" ")
                  );
                })
                .filter((e) => e);
            }
            if (isStatCardTemplate(template)) {
              switch (template.dataSource) {
                case StatCardDataType.FuelConsumption:
                  options = ["Diesel Used", "Nat Gas Used"];
                  return mockElements.FuelConsumption;
                case StatCardDataType.FuelFlow:
                  options = ["Avg Diesel Flow Rate", "Avg Nat Gas Flow Rate"];
                  return mockElements.FuelFlow;
                case StatCardDataType.TotalActiveDuration:
                  return mockElements.TotalActiveDuration;
                case StatCardDataType.AveragePowerConsumptionPerSecond:
                  return mockElements.AveragePowerConsumptionPerSecond;
                case StatCardDataType.TotalEnergyConsumed:
                  return mockElements.TotalEnergyConsumed;
                case StatCardDataType.TotalEnergyCapacity:
                  return mockElements.TotalEnergyCapacity;
                case StatCardDataType.GhgEmissions:
                  options = [
                    "Total Emissions",
                    "CO₂ Emissions",
                    "CH₄ Emissions",
                    "N₂O Emissions",
                    "CO Emissions",
                    "NOₓ Emissions",
                  ];
                  return mockElements.GhgEmissionsStatCard;
              }
            }

            if (isRigPowerTemplate(template)) {
              if (template.bessType === BessType.NonBess)
                return mockElements["RigPower"];
              return mockElements["RigPowerBESS"];
            }

            if (isFueFlowRateTemplate(template)) {
              if (template.fuelConsumerType === FuelConsumerType.Generator)
                return mockElements["FuelFlowRate"];
              return mockElements["BoilerFuelFlowRate"];
            }
            if (template.type === "PowerLoadDistribution")
              options = [
                "By Count of Gens Online",
                "By Operation Type",
                "By Gen Number",
              ];
            if (template.type === "FuelConsumptionByGenerator")
              options = ["Diesel Consumption", "Nat Gas Consumption"];

            return mockElements[template.type];
          })();
          if (!kpiTypes?.byId) return null;

          if (isSingleKpiTemplate(template) && template.selectableKpiTypeIds) {
            options = template.selectableKpiTypeIds.map(
              (id) => kpiTypes?.byId[id]?.name || "",
            );
          }
          if (!kpiGroups?.byId) return null;
          if (
            isMultipleKpiTemplate(template) &&
            template.selectableKpiGroupIds
          ) {
            options = template.selectableKpiGroupIds.map(
              (id) => kpiGroups?.byId[id]?.name || "",
            );
          }
          if (isWedgeTemplate(template) && template.breakdowns) {
            options = template.breakdowns.map(
              (breakdown) => breakdown?.name || "",
            );

            return (
              <LensItem
                id={template.id}
                key={template.id}
                name={template.name || ""}
                description={template.description || ""}
                chartElement={element}
                options={options}
                tabName={tabName}
                onAdd={() =>
                  addLensMutation.mutate({
                    lensTemplateId: template.id,
                    lensTabId: selectedTab,
                    breakDownId: template.breakdowns?.[0].id,
                  })
                }
              />
            );
          }
          if (
            isPivotKpiTypeLensTemplate(template) &&
            template.selectableKpiTypeIds
          ) {
            options = template.selectableKpiTypeIds.map(
              (id) => kpiTypes?.byId[id]?.name || "",
            );
          }
          if (
            isPivotKpiGroupLensTemplate(template) &&
            template.selectableKpiGroupIds
          ) {
            options = template.selectableKpiGroupIds.map(
              (id) => kpiGroups?.byId[id]?.name || "",
            );
          }

          if (
            template.name?.toLocaleLowerCase().includes(lowerCaseSearch) ||
            options.some((item) =>
              item.toLocaleLowerCase().includes(lowerCaseSearch),
            )
          ) {
            return (
              <LensItem
                id={template.id}
                key={template.id}
                name={template.name || ""}
                description={template.description || ""}
                chartElement={element}
                options={options}
                tabName={tabName}
                onAdd={() =>
                  addLensMutation.mutate({
                    lensTemplateId: template.id,
                    lensTabId: selectedTab,
                  })
                }
              />
            );
          }

          return null;
        })
        .filter((item) => item !== null) || [],
    [
      addLensMutation,
      dashboardType,
      kpiGroups?.byId,
      kpiTypes?.byId,
      lowerCaseSearch,
      selectedTab,
      tabName,
      templates?.list,
    ],
  );

  return (
    <CoreLayout>
      <Header>
        <Title>Lens Library</Title>
        <AvailableLenses>
          {filteredResults.length} lenses available
        </AvailableLenses>
      </Header>
      <Separator />
      <SearchBarContainer>
        <SearchInput
          prefix={<PDComponent.SvgIcon name="search" />}
          type="text"
          placeholder="Search by lens name or KPI"
          value={search}
          allowClear
          bordered={false}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
      </SearchBarContainer>
      <LensGrid>{filteredResults}</LensGrid>
    </CoreLayout>
  );
}
