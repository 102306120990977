import type { TrackAddModalProps } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/TrackAddModal";
import { TrackAddModal } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/TrackAddModal";
import { useCallback, useState } from "react";

export function useTrackAddModal({
  availableTrackIds,
  onAdd,
}: Pick<TrackAddModalProps, "availableTrackIds" | "onAdd">) {
  const [visible, setVisible] = useState(false);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  const open = useCallback(() => {
    setVisible(true);
  }, []);

  const node = (
    <TrackAddModal
      setVisible={setVisible}
      visible={visible}
      availableTrackIds={availableTrackIds}
      onAdd={onAdd}
    />
  );

  return { visible, close, open, node };
}
