import { PivotType } from "apis/oag";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import type { FC } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import {
  AddColorForIdAction,
  ResetLegendColorsAction,
} from "reducers/legendColorReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import type { ReactChildren } from "utils/types";

const aggregationColor = "#C8CED0";
export const colorsSequence = [
  "#0D9B83",
  "#AA9C7C",
  "#A492C3",
  "#C4877D",
  "#18A6B8",
  "#DCBDCB",
  "#8B90A6",
  "#FFB299",
  "#69BB90",
  "#8AC4D0",
  "#B9B591",
  "#9199C2",
  "#D2D6AE",
  "#D66E6B",
  "#CAACCF",
  "#F6B179",
  "#DEC299",
  "#B7D299",
  "#8E7E78",
  "#B0CBE1",
  "#82B584",
];
export const colorDecisionalPivotTypeArray: PivotType[] = [
  PivotType.DirectionalInterval,
  PivotType.HoleSize,
  PivotType.Rig,
  PivotType.HoleSection,
  PivotType.Driller,
];
export type SetColorProps = {
  key: string;
  defaultPivot?: PivotType | "WedgeAlternative";
  isMock?: boolean;
};
type ISetColorCallback = ({
  key,
  defaultPivot,
  isMock,
}: SetColorProps) => string;

const initialColors: { [pivot: string]: { [key: string]: string } } = {};
const initialStateA: {
  colors: { [pivot: string]: { [key: string]: string } };
  setColor: ISetColorCallback;
  getColor: ({
    key,
    isMock,
    currentPivot,
  }: {
    key: string;
    isMock?: boolean;
    currentPivot?: string;
  }) => string;
} = {
  colors: {},
  setColor: () => "",
  getColor: () => "",
};
const initialState: {
  colors: { [pivot: string]: { [key: string]: string } };
  setColor: ISetColorCallback;
  getColor: ({
    key,
  }: {
    key: string;
    isMock?: boolean;
    currentPivot?: string;
  }) => string;
} = {
  colors: {},
  setColor: () => "",
  getColor: () => "",
};

const ColorsContext = createContext(initialState);

export const useColors = () => {
  return useContext(ColorsContext);
};

function getNextInSeq(crtVals: string[]) {
  const currentValues = crtVals;
  for (const i of colorsSequence) {
    if (!currentValues.includes(i)) {
      return i;
    }
  }
  return aggregationColor;
}
export function isPivotColor(pivot: PivotType) {
  return colorDecisionalPivotTypeArray.includes(pivot);
}
export function isShading(pivot: PivotType) {
  return pivot === PivotType.Shift;
}

export function getCurrentPivot({
  group,
  subGroup,
  isComparing,
}: {
  group: PivotType;
  subGroup: PivotType;
  isComparing: boolean;
}) {
  let pivot: PivotType = PivotType.Rig;
  if (isComparing) return pivot;
  if (subGroup !== PivotType.None && isPivotColor(subGroup)) pivot = subGroup;
  else if (isPivotColor(group))
    pivot = group === PivotType.None ? PivotType.Rig : group;
  return pivot;
}

export const GenericColorsContextProvider: FC<ReactChildren> = ({
  children,
}) => {
  const [state, setState] = useState(initialStateA);
  const colors = useRef(initialColors).current;
  // Colors need to be generated based on pivot type
  const location = useLocation();

  const selectedRig = useMemo(() => {
    return (location.pathname || "").split("/")[2]
      ? Number.parseInt((location.pathname || "").split("/")[2])
      : -1;
  }, [location]);
  const [displayOptions] = useStateQuery<Array<PivotType>>(
    URL_STATE_PARAM.DATA_GROUPS_WIDGET,
    [PivotType.Day, PivotType.None],
  );
  const [offsetRigs] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET,
    [],
  );
  const [offsetWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WELL,
    [],
  );
  const [offsetWellsRigsScoreCards] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.SELECTED_WELLS_RIG_DASHBOARD,
    [],
  );

  const [offsetWellsLenses] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );
  const prevDisplayOptions = useRef("");

  const selectedWell = useAppSelector((state) => state.state.selectedWell);
  const selectedWellReport = useAppSelector(
    (state) => state.report.selectedWell,
  );
  const offsetWellsReports = useAppSelector(
    (state) => state.report.offsetWells,
  );
  const dispatch = useAppDispatch();

  const isScoreCard = location.pathname.includes("scorecard");

  const addLegend = useCallback(
    (key: string) => {
      dispatch(AddColorForIdAction(key));
    },
    [dispatch],
  );

  useEffect(() => {
    // TODO Quite an ugly solution we need to think of something better
    const stringifiedOptions = JSON.stringify({
      selectedRig,
      selectedWell,
      selectedWellReport,
      colors,
      len: offsetRigs.length,
      offsetWellsRigsScoreCards,
      offsetWells,
      offsetWellsReports,
      offsetWellsLenses,
      displayOptions,
    });

    if (stringifiedOptions === prevDisplayOptions.current) return;

    prevDisplayOptions.current = stringifiedOptions;
    const pivot = getCurrentPivot({
      group: displayOptions[0],
      subGroup: displayOptions[1],
      isComparing:
        offsetRigs.length > 0 ||
        offsetWells.length > 0 ||
        (offsetWellsRigsScoreCards?.length > 0 && isScoreCard) ||
        offsetWellsReports.length > 0 ||
        offsetWellsLenses.length > 0,
    });
    colors[PivotType.None] = {};
    colors[pivot] = {};
    colors[`${pivot}-mock`] = {};
    dispatch(ResetLegendColorsAction());
    setState({
      colors,
      setColor: ({ key, defaultPivot, isMock }) => {
        if (!key) return aggregationColor;

        const pivot: PivotType | "WedgeAlternative" =
          defaultPivot ||
          getCurrentPivot({
            group: displayOptions[0],
            subGroup: displayOptions[1],
            isComparing:
              offsetRigs.length > 0 ||
              offsetWells.length > 0 ||
              (offsetWellsRigsScoreCards.length > 0 && isScoreCard) ||
              offsetWellsReports.length > 0 ||
              offsetWellsLenses.length > 0,
          });

        if (!colors[isMock ? `${pivot}-mock` : pivot]) {
          colors[isMock ? `${pivot}-mock` : pivot] = {};
        }

        if (!isMock) {
          addLegend(key);
        }

        if (colors[isMock ? `${pivot}-mock` : pivot]?.[key]) {
          return colors[isMock ? `${pivot}-mock` : pivot][key];
        }

        const next = getNextInSeq([
          ...(Object.values(colors[isMock ? `${pivot}-mock` : pivot]) ?? []),
        ]);
        colors[isMock ? `${pivot}-mock` : pivot][key] = next;
        return next;
      },

      getColor: ({ key, isMock, currentPivot }) => {
        if (!key) return aggregationColor;
        const pivot =
          currentPivot ||
          getCurrentPivot({
            group: displayOptions[0],
            subGroup: displayOptions[1],
            isComparing:
              offsetRigs.length > 0 ||
              offsetWells.length > 0 ||
              (offsetWellsRigsScoreCards?.length > 0 && isScoreCard) ||
              offsetWellsReports.length > 0 ||
              offsetWellsLenses.length > 0,
          });
        if (colors[isMock ? `${pivot}-mock` : pivot]?.[key]) {
          return colors[isMock ? `${pivot}-mock` : pivot][key];
        }
        return aggregationColor;
      },
    });
  }, [
    selectedRig,
    selectedWell,
    selectedWellReport,
    colors,
    offsetRigs.length,
    offsetWells,
    offsetWellsRigsScoreCards,
    offsetWellsReports,
    offsetWellsLenses,
    displayOptions,
    isScoreCard,
    dispatch,
    addLegend,
  ]);

  return (
    <ColorsContext.Provider value={state}>{children}</ColorsContext.Provider>
  );
};
