import { Single } from "components/Lenses/ContainerLens/PowerLoadDistribution/Single";
import type { PowerLoadDistributionProps } from "components/Lenses/interfaces";
import React from "react";

export const PowerLoadDistribution: React.FC<PowerLoadDistributionProps> = ({
  isLocked,
  lens,
  detailed,
  setLensDate,
}) => {
  return (
    <Single
      lens={lens}
      detailed={detailed}
      setLensDate={setLensDate}
      isLocked={isLocked}
    />
  );
};
