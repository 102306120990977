import type { DateDto, RigCardFactValue } from "apis/oag";
import type { IAction } from "reducers/types";

export enum PrimarySelectorEnum {
  OPERATOR,
  WELL,
  NONE,
}

interface SelectedFact {
  cardGroupId?: number;
  kpiId?: number;
  wellId?: number;
}

export interface IRigsDashboardState {
  selectedRig: number | null;
  lastWellRefreshDate: DateDto | null;
  selectedWellsInfo: Array<{
    wellId: number;
    operatorScore?: RigCardFactValue | null;
    rigScore?: RigCardFactValue | null;
  }>;
  maxValueByKpi: Record<number, number>;
  factInfo?: SelectedFact | null;
}

const initialState: IRigsDashboardState = {
  selectedRig: null,
  lastWellRefreshDate: null,
  selectedWellsInfo: [],
  maxValueByKpi: {},
  factInfo: null,
};

interface IFactInfo extends IAction {
  type: "ADD_FACT_INFO";
  payload: IRigsDashboardState["factInfo"];
}

interface ISetRawDashboard extends IAction {
  type: "RAW_SET_RIG_DASHBOARD";
  payload: IRigsDashboardState;
}

interface IResetRigDashboard extends IAction {
  type: "RESET_RIG_DASHBOARD";
}

interface ISetLastRefreshDate extends IAction {
  type: "SET_LAST_REFRESH_DATE";
  payload: IRigsDashboardState["lastWellRefreshDate"];
}
interface ISetSelectedRig extends IAction {
  type: "SET_SELECTED_RIG";
  payload: IRigsDashboardState["selectedRig"];
}
interface ISetSelectedWellsInfo extends IAction {
  type: "SET_SELECTED_WELLS_INFO";
  payload: IRigsDashboardState["selectedWellsInfo"];
}
interface ISetMaxValueByKpi extends IAction {
  type: "SET_MAX_VALUE_BY_KPI";
  payload: IRigsDashboardState["maxValueByKpi"];
}

type AvailableActions =
  | IFactInfo
  | IResetRigDashboard
  | ISetSelectedRig
  | ISetLastRefreshDate
  | ISetSelectedWellsInfo
  | ISetMaxValueByKpi
  | ISetRawDashboard;

function rigDashboard(
  state: IRigsDashboardState = initialState,
  action: AvailableActions,
): IRigsDashboardState {
  switch (action.type) {
    case "SET_SELECTED_RIG":
      return {
        ...state,
        selectedRig: action.payload,
      };
    case "SET_LAST_REFRESH_DATE":
      return {
        ...state,
        lastWellRefreshDate: action.payload,
      };

    case "RAW_SET_RIG_DASHBOARD":
      return {
        ...state,
        ...action.payload,
      };

    case "SET_SELECTED_WELLS_INFO":
      return {
        ...state,
        selectedWellsInfo: action.payload,
      };
    case "SET_MAX_VALUE_BY_KPI":
      return {
        ...state,
        maxValueByKpi: {
          ...state.maxValueByKpi,
          ...action.payload,
        },
      };
    case "ADD_FACT_INFO":
      return {
        ...state,
        factInfo: action.payload,
      };
    case "RESET_RIG_DASHBOARD":
      return initialState;
    default:
      return state;
  }
}

export default rigDashboard;
