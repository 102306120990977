import type { BaseFocalQueryDto } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls/index";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import {
  type IFiltersType,
  initialFilters,
  type IZoomData,
} from "reducers/types";
import { defaultDateDto } from "utils/common";
export const DEFAULT_SAMPLE_COUNT = 500;

export const useFilterParams: () => BaseFocalQueryDto = () => {
  const wellId = useSelectedWell();
  if (wellId === null) {
    throw new Error("WellId should not be null");
  }

  const { isEvergreen } = useDashboardType();
  const [
    {
      sections: sectionIds,
      operationTime,
      directionalIntervals: directionalIntervalIds,
      holeSizes: holeSizeIds,
      phases: phaseIds,
    },
  ] = useStateQuery<IFiltersType>(
    URL_STATE_PARAM.FILTERS_WIDGET,
    initialFilters,
  );

  const [
    {
      ts_start: zoomStartDuration,
      ts_end: zoomEndDuration,
      depth_start: zoomStartDepth,
      depth_end: zoomEndDepth,
    },
  ] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WIDGET, initialZoomData);

  return {
    useMockData: false,
    from: defaultDateDto.from,
    to: defaultDateDto.to,
    sampleCount: DEFAULT_SAMPLE_COUNT,
    comparisonWellIds: null,
    selectedFilters: {
      sectionIds,
      directionalIntervalIds,
      holeSizeIds,
      phaseIds: isEvergreen ? null : phaseIds,
      includeFlatTime:
        operationTime === null || operationTime === undefined
          ? true
          : operationTime.includes("Flat Time"),
      includeSlidingTime:
        operationTime === null || operationTime === undefined
          ? true
          : operationTime.includes("Sliding Time"),
      includeRotatingTime:
        operationTime === null || operationTime === undefined
          ? true
          : operationTime.includes("Rotating Time"),
      includeNullHoleDepth: true,
      includeAlphaRigs: true,
      includeNonAlphaRigs: true,
    },
    operationTime,
    zoomStartDuration,
    zoomEndDuration,
    zoomStartDepth,
    zoomEndDepth,
    wellId: wellId,
  };
};
