import { DisplayOption, ResultDataState } from "apis/oag";
import {
  PrimaryKpiSummary,
  SecondaryKpiSummary,
} from "components/Lenses/common/KpiSummaries";
import {
  LENS_KPI_HEIGHT_INNER,
  LOADING_KPI_STRING,
  NO_DATA_KPI_STRING,
} from "components/Lenses/constants";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import { StyledLensContainerFlex } from "components/Lenses/ContainerLens/common/StyledComponents";
import { StyledChartContainerFlexDiv } from "components/Lenses/ContainerLens/common/StyledComponents";
import { SingleDayViewChart } from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleDayViewChart";
import { SingleShiftChart } from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleShiftChart";
import { SingleStandViewChart } from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleStandViewChart";
import { useSingleKpiFetcher } from "components/Lenses/ContainerLens/SingleKpi/fetcher";
import type { SingleKpiProps } from "components/Lenses/interfaces";
import { LoadingChart } from "components/Lenses/LoadingChart";
import { InfoContainer } from "components/MiniLens/InfoContainer";
import { getLensTitle } from "components/MiniLens/MiniLens";
import { SecondaryKpiContainer, StyledKpiRow } from "components/MiniLens/style";
import { useKpiOptions } from "hooks/drillingInvariants/useKpiOptions";
import { useLensTemplates } from "hooks/lens/useLensTemplates";
import { usePreviousNonNullValue } from "hooks/react-utils/usePreviousNonNullValue";
import { getDisplayValue } from "pages/Lens/LensSummaryView/index";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { useUOMbyLens } from "utils/format";

export const SingleKPISimple = (props: SingleKpiProps) => {
  const { lens, detailed } = props;
  const dispatch = useAppDispatch();

  const {
    request: { data, isLoading },
  } = useSingleKpiFetcher({ lensId: lens?.id });

  const uom = useUOMbyLens(props.dimension, lens);

  // TODO check maybe the set available filters part can be avoided for the overview fetch
  // TODO check is this still used ?

  useEffect(() => {
    if (detailed && data?.availableFilters) {
      dispatch({
        type: "SET_AVAILABLE_FILTERS",
        payload: data.availableFilters,
      });
    }
  }, [data?.availableFilters, detailed, dispatch]);

  const displayOption = useAppSelector(
    (state) => state.widgetOptions.display_options,
  );
  const selectedIndicators = useAppSelector(
    (state) => state.widgetOptions.indicators,
  );
  const indicators = useMemo(
    () => (detailed ? selectedIndicators : []),
    [detailed, selectedIndicators],
  );
  const { data: templates } = useLensTemplates();
  const template = templates?.byId[lens.lensTemplateId];
  const previousData = usePreviousNonNullValue(data);

  const LoadedComponent = useMemo(() => {
    switch (displayOption) {
      case DisplayOption.DateShift:
        return data?.detailsByDateShift ? (
          <SingleDayViewChart
            valueUOM={uom}
            data={data}
            indicators={indicators}
            lens={lens}
            detailed={detailed}
            enableTooltip
          />
        ) : (
          <LoadingChart isLoading={false} />
        );
      case DisplayOption.StandDepth:
        return data?.detailsByStandDepth ? (
          <SingleStandViewChart
            valueUOM={uom}
            data={data}
            indicators={indicators}
            lens={lens}
            detailed={detailed}
            enableTooltip
          />
        ) : (
          <LoadingChart isLoading={false} />
        );
      case DisplayOption.Shift:
        return data?.detailsByShift ? (
          <SingleShiftChart
            valueUOM={uom}
            data={data}
            indicators={indicators}
            lens={lens}
            detailed={detailed}
            enableTooltip
          />
        ) : (
          <LoadingChart isLoading={false} />
        );
    }
  }, [data, detailed, displayOption, indicators, lens, uom]);

  const mainKpiValues = useMemo(() => {
    const kpi = { name: "", main: NO_DATA_KPI_STRING, secondary: "" };

    if (isLoading) {
      kpi.main = LOADING_KPI_STRING;
      if (detailed && previousData) {
        kpi.name = template?.name || "Undefined";
        kpi.main = getDisplayValue(previousData, lens, uom);
      }
      return kpi;
    }

    if (data?.dataState === ResultDataState.Valid) {
      kpi.name = template?.name || "Undefined";
      kpi.main = getDisplayValue(data, lens, uom);
    }

    return kpi;
  }, [data, detailed, isLoading, lens, previousData, template?.name, uom]);

  const secondaryKPIValues = useMemo(() => {
    if (data?.dataState !== ResultDataState.Valid || !data) {
      return [];
    }

    if (displayOption === DisplayOption.Shift) {
      return [
        {
          name: "Day",
          main: `${uom.display(data.detailsByShift?.dayValue)} avg`,
          secondary: "",
        },
        {
          name: "Night",
          main: `${uom.display(data.detailsByShift?.nightValue)} avg`,
          secondary: "",
        },
      ];
    }

    return [];
  }, [data, displayOption, uom]);

  const { kpiType, kpiGroup } = useKpiOptions(lens);

  const primaryKpiTitle = useMemo(() => {
    return getLensTitle({ lens, template, kpiType, kpiGroup });
  }, [kpiType, kpiGroup, lens, template]);

  const SummaryComponent = useMemo(
    () => (
      <div style={{ height: detailed ? 300 : undefined }}>
        <InfoContainer size={6} direction="vertical" style={{ height: "auto" }}>
          <StyledKpiRow gutter={34} height={LENS_KPI_HEIGHT_INNER} wrap={true}>
            {mainKpiValues ? (
              <PrimaryKpiSummary
                secondary={mainKpiValues.secondary}
                main={mainKpiValues.main}
                description={kpiType?.description || ""}
                title={primaryKpiTitle}
                detailed={detailed}
              />
            ) : null}
            {secondaryKPIValues ? (
              <SecondaryKpiContainer>
                <SecondaryKpiSummary
                  detailed={detailed}
                  kpis={secondaryKPIValues?.slice(0)}
                />
              </SecondaryKpiContainer>
            ) : null}
          </StyledKpiRow>
        </InfoContainer>
      </div>
    ),
    [
      detailed,
      kpiType?.description,
      mainKpiValues,
      primaryKpiTitle,
      secondaryKPIValues,
    ],
  );

  return (
    <LensLoadingContainer
      key={lens.id}
      dataState={data?.dataState}
      isDetailed={detailed}
      SummaryComponent={SummaryComponent}
      LoadedComponent={
        <StyledLensContainerFlex>
          {SummaryComponent}
          <StyledChartContainerFlexDiv style={{ paddingLeft: 24 }}>
            {LoadedComponent}
          </StyledChartContainerFlexDiv>
        </StyledLensContainerFlex>
      }
    />
  );
};
