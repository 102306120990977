import { RealTimeDataEnum } from "components/RealTimeIndicator";
import { IIndicators } from "reducers/widgetReducer";

export const indicatorNames: Partial<Record<IIndicators, string>> = {
  [IIndicators.DirectionalIntervals]: "Directional Intervals",
  [IIndicators.HoleSections]: "Hole Sections",
};

export const getTitleRealTimeIndicator = (realtimeData: RealTimeDataEnum) => {
  switch (realtimeData) {
    case RealTimeDataEnum.DISABLED:
      return "Paused";
    case RealTimeDataEnum.ACTIVE:
      return "Live";
    default:
      return "Live not available";
  }
};
