import { scaleLinear } from "@visx/scale";
import type { FuelFlowRateFactDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import { Title } from "atoms/Typography";
import { Indicator } from "components/Lenses/common/InspectionView/InspectionChart/style";
import { LineChart } from "components/Lenses/common/LineChart";
import { CurveType } from "components/Lenses/common/LineChart/utils";
import {
  TooltipFadedValue,
  TooltipHighlightValue,
} from "components/Lenses/common/Tooltip";
import { NO_DATA_KPI_STRING } from "components/Lenses/constants";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import * as CommonStyles from "components/Lenses/ContainerLens/common/StyledComponents";
import { StyledChartContainerFlexDiv } from "components/Lenses/ContainerLens/common/StyledComponents";
import type { WithIndex } from "components/Lenses/ContainerLens/common/utils/utils";
import {
  getInternalAxisMargin,
  LATERAL_AXIS_WIDTH,
  X_AXIS_HEIGHT,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { curveColors } from "components/Lenses/ContainerLens/FuelConsumptionByGenerator/common/utils";
import { useFuelFlowRateFacts } from "components/Lenses/ContainerLens/FuelFlowRate/common/fetcher";
import {
  DECIMAL_THRESHOLD,
  LEGEND_HEIGHT,
} from "components/Lenses/ContainerLens/FuelFlowRate/common/utils";
import type { FuelFlowRateProps } from "components/Lenses/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { max, min } from "d3-array";
import { useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { Col, Row } from "utils/componentLibrary";
import { DEFAULT_DATE_FORMAT, useUOM, UtilDimensions } from "utils/format";
import { formatTime } from "utils/helper";

const LENS_TITLE = "Fuel Rate";

export type FuelFlowRateFactDtoWithIndex = WithIndex<FuelFlowRateFactDto>;

export const FuelRateChart: React.FC<FuelFlowRateProps> = ({
  lens,
  detailed,
  setLensDate,
}) => {
  const { data: fuelFLowFacts } = useFuelFlowRateFacts(lens);

  useEffect(() => {
    if (fuelFLowFacts?.lastUpdatedAt && setLensDate)
      setLensDate(fuelFLowFacts.lastUpdatedAt);
  }, [fuelFLowFacts.lastUpdatedAt, setLensDate]);

  const naturalGasFacts = useMemo<FuelFlowRateFactDtoWithIndex[]>(() => {
    return (fuelFLowFacts?.naturalGasFacts ?? []).map((fact, index) => ({
      ...fact,
      index,
    }));
  }, [fuelFLowFacts]);

  const dieselFacts = useMemo<FuelFlowRateFactDtoWithIndex[]>(() => {
    return (fuelFLowFacts?.dieselFacts ?? []).map((fact, index) => ({
      ...fact,
      index,
    }));
  }, [fuelFLowFacts]);

  const natGasUom = useUOM(UtilDimensions.ALTERNATE_CubicMetresPerSecond);
  const dieselUom = useUOM(DimensionType.LitresPerSecond);

  const availableSeries = useMemo<FuelFlowRateFactDtoWithIndex[]>(
    () =>
      [...dieselFacts, ...naturalGasFacts].sort(
        (a, b) => a.at.utc.getTime() - b.at.utc.getTime(),
      ),
    [dieselFacts, naturalGasFacts],
  );

  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: containerRef,
  } = useResizeDetector();

  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(
      Math.max((chartHeightHook ?? 0) - LEGEND_HEIGHT, 0),
    ),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  const plotWidth = getSVGNormalizedValue(chartWidth - 2 * LATERAL_AXIS_WIDTH);
  const plotHeight = getSVGNormalizedValue(chartHeight - X_AXIS_HEIGHT);

  const xScale = useMemo(() => {
    const startX = LATERAL_AXIS_WIDTH;
    const timeSeries = availableSeries.map(({ index }) => index);
    const [_min = 0, _max = 0] = [min(timeSeries), max(timeSeries)];

    return scaleLinear<number>({
      domain: [_min, _max],
      range: [startX, plotWidth + startX],
    });
  }, [availableSeries, plotWidth]);

  const internalAxisMargin = useMemo(
    () => getInternalAxisMargin(detailed),
    [detailed],
  );

  const dieselScale = useMemo(() => {
    const maxFromData = dieselFacts ? max(dieselFacts.map((e) => e.value)) : 0;
    const [minValue, maxValue = 0] = [0, maxFromData];
    return scaleLinear<number>({
      range: [plotHeight, internalAxisMargin],
      domain: [minValue, maxValue],
      nice: true,
    });
  }, [dieselFacts, internalAxisMargin, plotHeight]);

  const natGasScale = useMemo(() => {
    const maxFromData = naturalGasFacts
      ? max(naturalGasFacts.map((e) => e.value))
      : 0;
    const [minValue, maxValue = 0] = [0, maxFromData];
    return scaleLinear<number>({
      range: [plotHeight, internalAxisMargin],
      domain: [minValue, maxValue],
      nice: true,
    });
  }, [naturalGasFacts, internalAxisMargin, plotHeight]);

  const [activeLegendItems, setActiveLegendItems] = useState<string[]>([
    CurveType.NatGas,
    CurveType.Diesel,
  ]);

  return (
    <LensLoadingContainer
      key={lens.id}
      dataState={fuelFLowFacts.dataState}
      title={LENS_TITLE}
      isDetailed={false}
      LoadedComponent={
        <>
          <Row justify="space-between">
            <Col style={{ padding: 24 }}>
              <Title variant="faded" level={3}>
                {LENS_TITLE}
              </Title>
            </Col>
          </Row>

          <StyledChartContainerFlexDiv>
            <LineChart
              curves={[
                {
                  legendTitle: "Nat Gas Flow Rate",
                  key: CurveType.NatGas,
                  type: CurveType.NatGas,
                  data: naturalGasFacts,
                  yScale: natGasScale,
                  color: curveColors.NatGas,
                },
                {
                  legendTitle: "Diesel Flow Rate",
                  key: CurveType.Diesel,
                  type: CurveType.Diesel,
                  data: dieselFacts,
                  yScale: dieselScale,
                  color: curveColors.Diesel,
                },
              ]}
              lensId={lens?.id}
              lensTemplateId={lens?.lensTemplateId}
              xScale={xScale}
              yScale={dieselScale}
              leftAxis={
                !!naturalGasFacts?.length &&
                activeLegendItems.includes(CurveType.NatGas)
                  ? {
                      scale: natGasScale,
                      uom: natGasUom,
                      label: natGasUom.abbr,
                      useFuelFormatting: true,
                    }
                  : undefined
              }
              rightAxis={
                !!dieselFacts?.length &&
                activeLegendItems.includes(CurveType.Diesel)
                  ? {
                      scale: dieselScale,
                      uom: dieselUom,
                      label: dieselUom.abbr,
                      useFuelFormatting: true,
                    }
                  : undefined
              }
              chartWidth={chartWidth}
              chartHeight={chartHeight}
              containerRef={containerRef}
              activeLegendItems={activeLegendItems}
              setActiveLegendItems={setActiveLegendItems}
              tooltipRenderer={({ tooltipData }) =>
                tooltipData ? (
                  <CommonStyles.TooltipContainer>
                    {!!dieselFacts?.length &&
                      activeLegendItems.includes(CurveType.Diesel) && (
                        <CommonStyles.TooltipRow>
                          <CommonStyles.HighlightValue>
                            <Indicator color={curveColors.Diesel} />
                            Diesel Flow
                          </CommonStyles.HighlightValue>

                          <TooltipHighlightValue>
                            {dieselUom.display(
                              tooltipData.values[CurveType.Diesel],
                              {
                                fractionDigits:
                                  dieselUom.fromSI(
                                    tooltipData.values[CurveType.Diesel],
                                  ) > DECIMAL_THRESHOLD
                                    ? 2
                                    : 3,
                              },
                            )}
                          </TooltipHighlightValue>
                        </CommonStyles.TooltipRow>
                      )}

                    {!!naturalGasFacts?.length &&
                      activeLegendItems.includes(CurveType.NatGas) && (
                        <CommonStyles.TooltipRow>
                          <CommonStyles.HighlightValue>
                            <Indicator color={curveColors.NatGas} />
                            Nat Gas Flow
                          </CommonStyles.HighlightValue>

                          <TooltipHighlightValue>
                            {natGasUom.display(
                              tooltipData.values[CurveType.NatGas],
                              {
                                fractionDigits:
                                  natGasUom.fromSI(
                                    tooltipData.values[CurveType.NatGas],
                                  ) > DECIMAL_THRESHOLD
                                    ? 2
                                    : 3,
                              },
                            )}
                          </TooltipHighlightValue>
                        </CommonStyles.TooltipRow>
                      )}

                    <CommonStyles.TooltipBorder>
                      <div />
                      <TooltipFadedValue>
                        {tooltipData?.at
                          ? formatTime(tooltipData?.at, {
                              formatStr: DEFAULT_DATE_FORMAT,
                            })
                          : NO_DATA_KPI_STRING}
                      </TooltipFadedValue>
                    </CommonStyles.TooltipBorder>
                  </CommonStyles.TooltipContainer>
                ) : null
              }
            />
          </StyledChartContainerFlexDiv>
        </>
      }
    />
  );
};
