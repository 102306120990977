import { NotificationRow } from "components/Header/RightContent/Notifications/NotificationsPopover/NotificationList/NotificationRow/NotificationRow";
import { useCallback } from "react";
import type { ListChildComponentProps } from "react-window";
import type { BaseNotificationVm } from "utils/notifications/mappers";

interface RowItemProps extends ListChildComponentProps {
  data: {
    notifications: BaseNotificationVm[];
    selectedIdsList: number[];
    setSelectedIdsList: (ids: number[]) => void;
    handleOnMarkAsArchived: (id: number) => void;
    handleOnToggleMarkAsRead: (id: number) => void;
  };
}
export function NotificationRowVirtualizedWrapper({
  style,
  index,
  data,
}: RowItemProps) {
  const currentNotification = data.notifications[index];
  const { handleOnMarkAsArchived, handleOnToggleMarkAsRead } = data;

  const handleOnCheckboxChange = useCallback(
    (notificationId: number, isChecked: boolean) => {
      if (!isChecked && data.selectedIdsList?.includes(notificationId)) {
        data.setSelectedIdsList?.(
          data.selectedIdsList?.filter((id) => id !== notificationId),
        );
      } else if (isChecked && !data.selectedIdsList?.includes(notificationId)) {
        data.setSelectedIdsList?.([...data.selectedIdsList, notificationId]);
      }
    },
    [data],
  );

  if (!data) return null;
  return (
    <NotificationRow
      isEditMode={data.selectedIdsList?.length > 0}
      style={style}
      isChecked={data.selectedIdsList?.includes(currentNotification.id)}
      onCheckboxChange={(isChecked) =>
        handleOnCheckboxChange(currentNotification.id, isChecked)
      }
      notification={currentNotification}
      handleOnMarkAsArchived={() =>
        handleOnMarkAsArchived(currentNotification.id)
      }
      handleOnToggleMarkAsRead={() =>
        handleOnToggleMarkAsRead(currentNotification.id)
      }
    />
  );
}
