import { Group } from "@visx/group";
import { AreaClosed, LinePath } from "@visx/shape";
import type { ParameterByDepthTrackValuesDto } from "apis/oag";
import type { ScaleLinear } from "d3";
import { curveMonotoneX } from "d3";

export const LineChart = ({
  yScale,
  xScale,
  fill,
  strokeColor,
  trackPoints,
  chartHeight,
}: {
  yScale: ScaleLinear<number, number, never>;
  xScale: ScaleLinear<number, number, never>;
  trackPoints: ParameterByDepthTrackValuesDto[];
  strokeColor?: string;
  fill?: string;
  chartHeight: number;
}) => {
  const data = trackPoints.map((point, index) => ({
    index,
    value: point.value,
  }));
  const isValid = Math.max(...data.map((d) => d.value ?? 0)) > 0;
  return (
    <Group>
      {fill && isValid ? (
        <Group top={chartHeight}>
          <AreaClosed
            yScale={yScale}
            curve={curveMonotoneX}
            data={[...data, { index: data.length, value: yScale.domain()[1] }]}
            defined={(d) => d !== null && d !== undefined}
            x={(d) => xScale(d.index) ?? 0}
            y={(d) => yScale(d.value ?? 0) - chartHeight || 0}
            strokeWidth={0}
            fill={fill ?? "transparent"}
          />
        </Group>
      ) : null}
      <LinePath
        curve={curveMonotoneX}
        data={data}
        defined={(d) => d !== null && d !== undefined}
        x={(d) => xScale(d.index) ?? 0}
        y={(d) => yScale(d.value ?? 0) ?? 0}
        stroke={strokeColor}
        strokeWidth={1.5}
      />
    </Group>
  );
};
