import { Button as FormsButton } from "atoms/Form";
import { Title } from "atoms/Typography";
import { darkButtonCss } from "commonStyles";
import { NoScrollCol } from "components/ListSearch";
import {
  ListItemContainerSimpleSearch,
  StyledSpaceForLayoutSimpleSearch,
} from "components/PDComponents/Search/style";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Button, Col, InputNumber, Row, Space } from "utils/componentLibrary";
import { PdCol } from "utils/componentLibrary/Col/index";
import { PdSpaceItem } from "utils/componentLibrary/Space";

export const BasinName = styled.div<{ $isDark: boolean }>`
  height: 36px;
  width: 200px;
  display: grid;
  place-items: center;
  padding: 0px 6px;
  border: 1px solid
    ${({ theme }) => (theme.isDark ? colors.limo_scene : colors.widget_line)};
  border-radius: 0px 5px 5px 0px;
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};
  ${({ $isDark }) => ($isDark ? darkButtonCss : null)}
`;

export const StyledSpaceForItems = styled(Space)`
  width: 100%;

  ${PdSpaceItem} {
    padding: 12px;
    margin: 0 !important;
    border-bottom: 1px solid ${colors.separation_line};
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    .ant-typography {
      line-height: 1;
    }
  }
`;

export const StyledSpace = styled(Space)`
  margin: -12px -16px;
  border-radius: 4px;
  overflow: hidden;
  background: ${colors.actions_bg};
  min-width: 250px;
`;

export const StyledInputNumber = styled(InputNumber)<{ $isDark: boolean }>`
  height: 36px;
  border-radius: 0;

  .ant-input-number-input-wrap {
    height: 100%;
  }

  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  background: ${({ theme }) => theme.themeStyle.colors.primary_accent};

  input {
    height: 100%;
    background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
    border-color: transparent;
    border-radius: 0;
  }

  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};

  .ant-input-number-handler-wrap {
    background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
    svg {
      fill: ${({ theme }) => theme.themeStyle.colors.primary_typography};
      color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    }
  }

  .ant-input-number-handler-down {
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }
  ${({ $isDark }) =>
    $isDark &&
    css`
      background: ${colors.limo_scene};

      input {
        background-color: ${colors.shutter_black};
      }

      border-color: ${colors.limo_scene};

      .ant-input-number-handler-wrap {
        background-color: ${colors.shutter_black};
        border-color: ${colors.limo_scene};
        svg {
          fill: ${colors.pale_grey};
          color: ${colors.pale_grey};
        }
      }

      .ant-input-number-handler-down {
        border-color: ${colors.limo_scene};
      }
    `}
`;

export const StyledRadiusButton = styled(Button)<{ $isDark: boolean }>`
  background-color: ${({ theme }) =>
    theme.themeStyle.colors.secondary_bg} !important;
  color: ${({ theme }) =>
    theme.themeStyle.colors.disabled_typography} !important;
  border-color: ${({ theme }) =>
    theme.themeStyle.colors.primary_accent} !important;
  min-width: 150px;

  ${({ $isDark }) => ($isDark ? darkButtonCss : null)}
`;

export const StyledCircleButton = styled(FormsButton)<{ $isDark: boolean }>`
  border-width: 1px;
  ${({ $isDark }) => ($isDark ? darkButtonCss : null)}
`;

const padding = css`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const StyledCol = styled(Col)`
  ${padding}
  display: flex;
  min-width: 500px;
  align-items: center;
  gap: 8px;
`;

export const StyledTitle = styled(Title)`
  white-space: nowrap;
`;

export const StyledButton = styled(FormsButton)<{ $isDark: boolean }>`
  min-width: 36px;
  ${({ $isDark }) => ($isDark ? darkButtonCss : null)}
`;

export const StyledListWithSearch = styled.div<{ $isDark: boolean }>`
  ${({ $isDark }) =>
    $isDark
      ? css`
          *::-webkit-scrollbar-thumb {
            background-color: ${colors.soft_white};
          }
          ${StyledSpaceForLayoutSimpleSearch} {
            background-color: ${colors.jaguar_gray} !important;
            .ant-input-affix-wrapper {
              background-color: ${colors.jaguar_gray} !important;
              border: 1px solid ${colors.soft_white} !important;
            }
            .ant-input,
            .ant-input-prefix {
              background-color: ${colors.jaguar_gray} !important;
              color: ${colors.white} !important;
            }
            ${PdCol} {
              background-color: ${colors.jaguar_gray} !important;
            }
          }
          ${NoScrollCol} {
            background-color: ${colors.shutter_black} !important;
            ${ListItemContainerSimpleSearch} {
              background-color: ${colors.shutter_black};
              border-bottom: 1px solid ${colors.soft_white};
              ${Title} {
                color: ${colors.white} !important;
              }
            }
          }
        `
      : null}
`;

export const PaddedSpace = styled(Space)`
  ${padding}
`;

export const HeaderContainer = styled(Row)`
  min-height: 68px;
`;

export const FilterTagsContainer = styled.div`
  max-width: 500px;
  ${padding}
`;

export const RightContainer = styled(Space)`
  justify-content: end;
`;
