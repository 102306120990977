import { PDComponent } from "components/PDComponents";
import * as Styled from "components/PDComponents/Checkbox/style";

export const InnerIcon = ({ size }: { size: number }) => {
  return (
    <Styled.IconContainer>
      <PDComponent.SvgIcon name="checkmarkThick" style={{ fontSize: size }} />
    </Styled.IconContainer>
  );
};
