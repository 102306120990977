import { Title } from "atoms/Typography";
import styled from "styled-components";

import { Loader } from ".";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: start;
  align-items: center;
  margin-top: 100px;
  height: 100%;
  width: 100%;
`;

export function FullPageLoader({
  loadingText = "Setting you up...",
}: {
  loadingText?: string;
}) {
  return (
    <StyledContainer>
      <Loader size={80} />
      <Title level={2}>{loadingText}</Title>
    </StyledContainer>
  );
}
