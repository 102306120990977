import { AxisBottom } from "@visx/axis";
import { scaleLinear } from "@visx/scale";
import { ResultDataState } from "apis/oag";
import {
  ZoomChartContext,
  ZoomState,
} from "components/Lenses/common/LensZoom/ZoomChartContext";
import { Chart } from "components/Lenses/ContainerLens/TagBottomFingerprint/Chart/Chart";
import { Header } from "components/Lenses/ContainerLens/TagBottomFingerprint/Header";
import { LegendItem } from "components/Lenses/ContainerLens/TagBottomFingerprint/LegendItem";
import {
  BottomAxis,
  Legend,
  NoDataComponent,
} from "components/Lenses/ContainerLens/TagBottomFingerprint/style";
import { useTagBottomFingerprint } from "components/Lenses/ContainerLens/TagBottomFingerprint/utils/fetcher";
import type { TagBottomFingerprintProps } from "components/Lenses/interfaces";
import { omit } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { useCustomTheme } from "utils/useTheme";

type Domain = [number, number];

export const TagBottomFingerprint = (props: TagBottomFingerprintProps) => {
  const { data } = useTagBottomFingerprint({
    lensId: props.lens.id,
  });
  const { ref: containerRef, width: chartWidthHook } = useResizeDetector();
  // calculate width of the chart
  const width = (chartWidthHook || 0) - 140 - 265 + 20;
  const getCurrentDomain = useCallback(() => {
    if (!data?.tracks) return null;
    const stands = data.slipsToBottomStands;
    const outlierStands = stands
      .filter((stand) => stand.isOutlier)
      .map((stand) => stand.id);

    return [
      0,
      props.lens?.showOverallView
        ? (data.tracks || [])[0]?.overallFacts?.length || 0 // get the length of the first track
        : (data?.tracks || []).reduce((acc, curr) => {
            const stands = curr.standSeries.filter(
              (x) => !outlierStands.includes(x.standId),
            );
            return Math.max(
              acc,
              stands.reduce((acc, curr) => Math.max(acc, curr.facts.length), 0),
            );
          }, 0),
    ] as Domain;
  }, [data, props.lens?.showOverallView]);
  const [initialDomain, setInitialDomain] = useState<Domain | null>(
    getCurrentDomain(),
  );
  const [domain, setDomain] = useState<Domain | null>(initialDomain);
  const [zoomState, setZoomState] = useState(ZoomState.Zooming);

  useEffect(() => {
    const currentDomain = getCurrentDomain();
    if (currentDomain) setInitialDomain(currentDomain);
  }, [data.tracks, getCurrentDomain, props.lens.showOverallView]);
  useEffect(() => {
    setDomain(initialDomain);
  }, [initialDomain]);
  // maybe pass the xScaleDate down to the chart
  const xScaleDate = useMemo(() => {
    return scaleLinear({
      domain: domain || [0, 0],
      range: [0, width],
    });
  }, [domain, width]);
  const resetZoom = useCallback(() => {
    setDomain(initialDomain);
    setZoomState(ZoomState.Zooming);
  }, [initialDomain]);

  const { themeStyle } = useCustomTheme();
  if (!data) return null; // add loading state
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: `44px 1fr ${props.detailed ? 50 : 26}px`,
        height: props.detailed ? "calc(100% - 70px)" : "100%",
      }}
      id="track-container"
    >
      <ZoomChartContext.Provider
        value={{
          zoomState,
          triggerZoomReset: resetZoom,
          domain,
          initialDomain,
          setDomain,
        }}
      >
        <Header
          domain={domain}
          lens={props.lens}
          setZoomState={setZoomState}
          resetZoom={resetZoom}
          onLensUpdated={props.onLensUpdated}
        />
        <div
          style={{
            height: "calc(100% - 10px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {data.dataState === ResultDataState.NoData ? (
            <NoDataComponent />
          ) : (
            <Chart
              data={data}
              isOverall={!!props.lens?.showOverallView}
              lensId={props.lens?.id}
              normalizeBlockHeight={
                !!props.lens.isBithDepthNormalized &&
                props.lens?.normalizationReferenceBitDepth !== null &&
                props.lens?.normalizationReferenceBitDepth !== undefined
              }
              showAlphaConnection={!!props.lens?.showAlphaComparison}
            />
          )}
        </div>
        <BottomAxis ref={containerRef}>
          <h1>Duration (Sec)</h1>
          {data.dataState === ResultDataState.NoData ? null : (
            <svg
              style={{
                overflow: "visible",
                width: "100%",
              }}
            >
              <AxisBottom
                hideAxisLine
                hideTicks
                scale={xScaleDate}
                top={-10}
                left={0}
                tickComponent={(props) => (
                  <text
                    {...omit(props, ["formattedValue"])}
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      fill: themeStyle.colors.disabled_typography,
                      textAnchor: "middle",
                    }}
                  >
                    {props.formattedValue}
                  </text>
                )}
              />
            </svg>
          )}
        </BottomAxis>
        {props.lens?.showAlphaComparison ? (
          <Legend>
            <span>
              <LegendItem isAlphaConnections /> Alpha connections
            </span>
            <span>
              <LegendItem isAlphaConnections={false} /> Non-Alpha connections
            </span>
          </Legend>
        ) : null}
      </ZoomChartContext.Provider>
    </div>
  );
};
