import { scaleLinear } from "@visx/scale";
import type { TagBottomFingerprintFactSetDto } from "apis/oag";
import { DimensionType, TimeUnit } from "apis/oag";
import { ZoomChartContext } from "components/Lenses/common/LensZoom/ZoomChartContext";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { StyledChartContainerFlexDiv } from "components/Lenses/ContainerLens/common/StyledComponents";
import { Stands } from "components/Lenses/ContainerLens/TagBottomFingerprint/Chart/Stands";
import { Track } from "components/Lenses/ContainerLens/TagBottomFingerprint/Chart/Track";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import colors from "utils/colors";
import { SHORT_DATE_FORMAT, useTimeUom, useUOM } from "utils/format";
import { formatTime } from "utils/helper";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 265px 1fr;
  height: 100%;
  width: 100%;
`;
const initialContext = {
  alphaStands: [] as Array<number>,
  outlierStands: [] as Array<number>,
  selectedStand: -1,
  hoveredStand: -1,
};
export const TagBottomFingerPrintContext = React.createContext(initialContext);

export const StyledTrackContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  height: 100%;
  border-left: 1px solid ${colors.necron_compound_light};
`;
export const Chart = ({
  data,
  isOverall,
  showAlphaConnection,
  lensId,
  normalizeBlockHeight,
}: {
  data: TagBottomFingerprintFactSetDto;
  isOverall: boolean;
  lensId: number;
  showAlphaConnection: boolean;
  normalizeBlockHeight: boolean;
}) => {
  const [tagBottomContext, setTagBottomContext] = useState(initialContext);
  const [currentPointerPosition, setCurrentPointerPosition] = useState<
    number | null
  >(null);
  const stands = data?.slipsToBottomStands;
  const tracks = data?.tracks;
  const secUom = useTimeUom(TimeUnit.Second);
  const depthUom = useUOM(DimensionType.Metres);
  useEffect(() => {
    if (isOverall) {
      setTagBottomContext(initialContext);
      return;
    }
    if (stands) {
      const alphaStands = stands
        .filter((stand) => stand.isAlpha)
        .map((stand) => stand.id);
      const outlierStands = stands
        .filter((stand) => stand.isOutlier)
        .map((stand) => stand.id);

      setTagBottomContext({
        alphaStands,
        outlierStands,
        selectedStand: -1,
        hoveredStand: -1,
      });
    }
  }, [isOverall, stands]);
  const containerRef = useRef(null);
  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<{
    isOverall: boolean;
    duration: number;
    stand: number;
    depthRange: string;
    timeRange: string;
    isAlpha: boolean;
  }>({
    containerRef,
    tbfTooltip: true,
    renderContent: ({ tooltipData }) => {
      return tooltipData ? (
        <>
          <TooltipHighlightValue
            style={{
              color:
                tooltipData.isAlpha && tagBottomContext.alphaStands.length > 0
                  ? colors.turtle_green
                  : colors.white,
            }}
          >
            Stand: {depthUom.display(tooltipData.stand, { fractionDigits: 0 })}
          </TooltipHighlightValue>
          <span>{tooltipData.depthRange}</span>
          <span>{tooltipData.timeRange}</span>
          <span>
            {secUom.display(tooltipData.duration, {
              fractionDigits: 0,
              unit: "",
            })}{" "}
            Sec
          </span>
        </>
      ) : null;
    },
  });
  const zoomChartContext = useContext(ZoomChartContext);
  if (!zoomChartContext) {
    throw new Error("ZoomChartContext is not defined");
  }
  const { domain } = zoomChartContext;
  const [chartWidth, setChartWidth] = useState(0);
  const xScale = useMemo(() => {
    // need to add the scroll inside here
    return scaleLinear({
      domain: domain || [0, 0],
      range: [0, chartWidth],
    });
  }, [chartWidth, domain]);
  useEffect(() => {
    if (!currentPointerPosition || isOverall) {
      hideTooltip();
    } else {
      const crtStand =
        tagBottomContext.selectedStand === -1
          ? stands[0]
          : stands.find((stand) => stand.id === tagBottomContext.selectedStand);
      showTooltip({
        tooltipLeft: currentPointerPosition + 222,
        tooltipTop: 0,
        tooltipData: crtStand
          ? {
              // adding this to change the width of the tooltip
              isOverall: true,
              duration: xScale.invert(currentPointerPosition),
              stand: crtStand.standNumber,
              depthRange: `${depthUom.display(crtStand.startBitDepth)} to ${depthUom.display(crtStand.endBitDepth)}`,
              timeRange: `${formatTime(crtStand.startDate, {
                formatStr: SHORT_DATE_FORMAT,
              })} to ${formatTime(crtStand.endDate, { formatStr: SHORT_DATE_FORMAT })}`,
              isAlpha:
                tagBottomContext.alphaStands.length > 0 &&
                showAlphaConnection &&
                crtStand.isAlpha,
            }
          : undefined,
      });
    }
  }, [
    currentPointerPosition,
    depthUom,
    hideTooltip,
    isOverall,
    showAlphaConnection,
    showTooltip,
    stands,
    tagBottomContext.alphaStands.length,
    tagBottomContext.selectedStand,
    xScale,
  ]);
  return (
    <Container>
      <TagBottomFingerPrintContext.Provider value={tagBottomContext}>
        <StyledChartContainerFlexDiv
          style={{
            borderBottom: `1px solid ${colors.necron_compound_light}`,
          }}
        >
          <Stands
            isOverall={isOverall}
            stands={stands}
            showAlphaConnection={showAlphaConnection}
            setTagBottomContext={setTagBottomContext}
          />
        </StyledChartContainerFlexDiv>
        {tooltipElement}
        <StyledTrackContainer ref={containerRef}>
          {(tracks || []).map((track, idx) => (
            <Track
              track={track}
              currentPointerPosition={currentPointerPosition}
              setCurrentPointerPosition={setCurrentPointerPosition}
              key={`${track.trackId}-${idx}`}
              isOverall={isOverall}
              lensId={lensId}
              normalizeBlockHeight={normalizeBlockHeight}
              setChartWidth={setChartWidth}
              showAlphaConnection={showAlphaConnection}
            />
          ))}
        </StyledTrackContainer>
      </TagBottomFingerPrintContext.Provider>
    </Container>
  );
};
