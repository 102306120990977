import { Button } from "atoms/Form";
import styled, { css } from "styled-components";
import { Dropdown as AntDropdown } from "utils/componentLibrary";

export const DROPDOWN_WIDTH = 186;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: row;
  align-items: center;
`;

export const Dropdown = styled(AntDropdown)`
  background-color: transparent;
`;

export const ScoresButton = styled(Button)<{
  $active?: boolean;
  $width?: number;
  $hasBorder?: boolean;
}>`
  height: 33px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: ${({ $width }) => ($width ? `${$width}px` : "auto")};

  &:not(.ant-btn-primary) {
    background-color: transparent !important;
    color: ${({ theme }) =>
      theme.themeStyle.colors.faint_typography} !important;
    border: 0 !important;
    border-left: ${({ $hasBorder, theme }) =>
      $hasBorder
        ? `1px solid ${theme.themeStyle.colors.primary_scroll_bg} !important`
        : "none"};

    ${(props) =>
      props.$active &&
      css`
        background-color: ${({ theme }) =>
          theme.themeStyle.colors.primary_accent} !important;
        color: ${({ theme }) =>
          theme.themeStyle.colors.primary_typography} !important;
      `}
  }

  border-radius: 4px;
  font-size: 16px;

  &:not(disabled) {
    cursor: pointer;
  }

  &:hover {
    &:not(.ant-btn-primary) {
      background-color: ${({ theme }) =>
        theme.themeStyle.colors.alt_primary_bg} !important;
    }
  }
`;

export const DropdownItem = styled.div`
  height: 41px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};

  &:hover {
    background-color: ${({ theme }) => theme.themeStyle.colors.alt_primary_bg};
  }

  &:not(:last-child):hover {
    border-bottom: 1px solid
      ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }
`;

export const DropdownContainer = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  border-radius: 2px;
  width: ${DROPDOWN_WIDTH}px;
  cursor: pointer;
`;

export const NetTimeLabel = styled.div`
  /* padding-left: 8px; */
  gap: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const NetTimeLabelText = styled.span`
  width: 100%;
  text-align: left;
`;
