import { AxisBottom } from "@visx/axis";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Bar, BarRounded, BarStack } from "@visx/shape";
import type {
  BarSliceDto,
  PivotBarDto,
  PivotTree1Dto,
  PivotTree2Dto,
  PivotTree3Dto,
} from "apis/oag";
import {
  PivotDepthType,
  PivotType,
  StackingType,
  VisualAidType,
} from "apis/oag";
import {
  AverageLabel,
  AverageLine,
} from "components/Lenses/common/AverageLine";
import { Chart } from "components/Lenses/common/Chart";
import { Label } from "components/Lenses/common/Label";
import { MedianLabel, MedianLine } from "components/Lenses/common/MedianLine";
import { OperationCount } from "components/Lenses/common/OperationCount";
import type { ScrollbarRange } from "components/Lenses/common/Scrollbar";
import { Scrollbar } from "components/Lenses/common/Scrollbar";
import TargetLine, {
  TARGET_FULL_PILL_WIDTH,
} from "components/Lenses/common/TargetLine";
import {
  TooltipGroup,
  TooltipHighlightValue,
} from "components/Lenses/common/Tooltip";
import {
  TooltipVisualAidInfo,
  useChartTooltip,
} from "components/Lenses/common/useChartTooltip";
import { useOutlierThreshold } from "components/Lenses/common/useOutlierThreshold";
import SeparatorLines from "components/Lenses/ContainerLens/PivotKpi/Chart/SeparatorLines";
import {
  DETAILED_SCROLLBAR_PADDING,
  DETAILED_SECONDARY_AXIS_HEIGHT,
  DETAILED_VALUE_AXIS_WIDTH,
  DETAILED_ZOOM_SCALER,
  MINI_SCROLLBAR_PADDING,
  MINI_SECONDARY_AXIS_HEIGHT,
  MINI_VALUE_AXIS_WIDTH,
  MINI_ZOOM_SCALER,
  PIVOT_SEPARATOR,
} from "components/Lenses/ContainerLens/PivotKpi/constants";
import type {
  IColorGenerator,
  PivotColumn,
  TData,
} from "components/Lenses/ContainerLens/PivotKpi/interfaces";
import { usePivotHelpers } from "components/Lenses/ContainerLens/PivotKpi/utils";
import { getMinMax, getSVGNormalizedValue } from "components/Lenses/utils";
import { RIGHT_AXIS_PADDING } from "components/TvDChart/constants";
import type { ScaleBand } from "d3-scale";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedRig } from "hooks/rigs/useSelectedRig";
import { indexOf, omit, uniq } from "lodash";
import { roundNumberToDecimal } from "pages/Lens/LensSummaryView";
import { shade, tint, transparentize } from "polished";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { DisableColorForIdAction } from "reducers/legendColorReducer";
import { useAppDispatch } from "reducers/store";
import {
  OPERATION_COUNT_HEIGHT,
  RIG_OPERATION_COUNT_LEFT_MARGIN,
  RIG_OPERATION_COUNT_TOP_MARGIN,
  TOP_LABEL_HEIGHT,
} from "utils/constants";
import { useUOM, useUOMbyLens, UtilDimensions } from "utils/format";
import { truncateMiddleString } from "utils/helper";
import {
  colorDecisionalPivotTypeArray,
  colorsSequence,
  useColors,
} from "utils/useColors";
import { useCustomTheme } from "utils/useTheme";

const PivotKpi = ({
  data,
  enableTooltip = true,
  detailed,
  disableValueAxis,
  dimension,
  isMock,
  lens,
  disableAxis,
}: PivotColumn) => {
  const paddingLabels = useMemo(
    () => (disableValueAxis ? 0 : 24),
    [disableValueAxis],
  );
  const { data: kpiTypes } = useKpiTypes();
  const valueUOM = useUOMbyLens(dimension, lens);
  const percentageUOM = useUOM(UtilDimensions.Percentage);
  const dispatch = useAppDispatch();
  const {
    getLabelFromKey,
    getData,
    getTotalValueLevel1AsArr,
    getTotalValueLevel2AsArr,
    getTotalValueLevel3AsArr,
    getValue,
  } = usePivotHelpers(lens, data);

  const { setColor, getColor } = useColors();

  const selectedRig = useSelectedRig();
  const [offsetRigs] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET,
    [],
  );
  const outliersSettingsEnabled = lens?.showsOutliers;
  const isManual = !!lens?.isManualYaxis;
  const outliersEnabled = outliersSettingsEnabled || isManual;
  const visualAidsState = lens?.selectedVisualAids;

  const rigsArr = useMemo(
    () => [selectedRig, ...offsetRigs],
    [offsetRigs, selectedRig],
  );

  const { valueAxisWidth, scrollbarPadding, secondaryAxisHeight } = useMemo(
    () => ({
      zoomScaler: detailed ? DETAILED_ZOOM_SCALER : MINI_ZOOM_SCALER,
      valueAxisWidth: detailed
        ? DETAILED_VALUE_AXIS_WIDTH
        : MINI_VALUE_AXIS_WIDTH,
      scrollbarPadding: detailed
        ? DETAILED_SCROLLBAR_PADDING
        : MINI_SCROLLBAR_PADDING,
      secondaryAxisHeight: detailed
        ? DETAILED_SECONDARY_AXIS_HEIGHT
        : MINI_SECONDARY_AXIS_HEIGHT,
    }),
    [detailed],
  );

  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: containerRef,
  } = useResizeDetector();
  const { chartWidth = 76, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  // A quarter of the chart by default
  const zoomRate = useMemo(() => {
    if (!data) return 1;
    const getDataLength = () => {
      switch (data.depth) {
        case PivotDepthType.One:
          return data.dataDepth1?.children.length ?? 1;
        case PivotDepthType.Two:
          return (
            data.dataDepth2?.children.reduce(
              (acc, curr) => acc + curr.children.length,
              0,
            ) ?? 1
          );
        case PivotDepthType.Three:
          return (
            data.dataDepth3?.children.reduce(
              (acc, curr) =>
                acc +
                curr.children.reduce(
                  (acc1, curr1) => acc1 + curr1.children.length,
                  0,
                ),
              0,
            ) ?? 1
          );
        default:
          return 1;
      }
    };
    return lens?.squeezesDisplay
      ? 1
      : Math.ceil(getDataLength() / DETAILED_ZOOM_SCALER);
  }, [data, lens?.squeezesDisplay]);

  const targetSegments = useMemo(() => {
    switch (data?.depth) {
      case PivotDepthType.One:
        return data.targetSegmentsLevel1;
      case PivotDepthType.Two:
        return data.targetSegmentsLevel2;
      case PivotDepthType.Three:
        return data.targetSegmentsLevel3;
      default:
        return [];
    }
  }, [
    data.depth,
    data.targetSegmentsLevel1,
    data.targetSegmentsLevel2,
    data.targetSegmentsLevel3,
  ]);

  const plotWidth = chartWidth - (disableValueAxis ? 0 : valueAxisWidth) - 10;
  const scrollbarWidth = plotWidth - scrollbarPadding * 2;
  const scrollbarHeight = 8;
  const hasScrollbar = !lens?.squeezesDisplay && zoomRate > 1;

  const [scrollbarRange, setScrollbarRange] = useState<ScrollbarRange>({
    startX: 1 - 1 / zoomRate,
    endX: 1,
  });
  // update if squeeze changed
  useEffect(() => {
    setScrollbarRange({ startX: 1 - 1 / zoomRate, endX: 1 });
  }, [zoomRate]);

  const isDay = ({ key, dataP1, dataP2, pivot3TypeInfo }: IColorGenerator) => {
    if (dataP2?.pivotType === PivotType.Shift) {
      return dataP1.key?.value === "Day";
    }
    if (dataP1?.pivotType === PivotType.Shift) {
      return key === "Day";
    }
    if (pivot3TypeInfo === PivotType.Shift) {
      return dataP2.key?.value === "Day";
    }
    return null;
  };
  const chartSize = plotWidth * zoomRate;

  // The inner most one
  const pivot1Scale: ScaleBand<string> = useMemo(() => {
    // Always the inner most pivot
    const getDomain = (depth: PivotDepthType) => {
      switch (depth) {
        case PivotDepthType.One:
          return (
            data.dataDepth1?.children.map((child) => child.key?.value) ?? []
          );
        case PivotDepthType.Two:
          return (
            data.dataDepth2?.children.map((child) => child.key?.value) ?? []
          );
        case PivotDepthType.Three:
          if (data.pivotLevel3 === PivotType.Rig) {
            return [
              ...new Set(data?.dataDepth3?.children.map((e) => e.key?.value)),
            ];
          }
          return [
            ...(new Set(
              data.dataDepth3?.children.reduce((acc, child) => {
                return [...acc, child.key?.value];
              }, [] as string[]),
            ) ?? []),
          ];
        default:
          return [];
      }
    };

    const startX = chartSize * scrollbarRange.startX;
    const range: () => [number, number] = () => {
      return [-startX, chartSize - startX];
    };

    return scaleBand<string>({
      domain: getDomain(data.depth),
      paddingInner: 0.2,
      range: range(),
      paddingOuter: getDomain(data.depth).length < 3 ? 1 : 0.2,
    });
  }, [
    chartSize,
    data.dataDepth1?.children,
    data.dataDepth2?.children,
    data.dataDepth3?.children,
    data.depth,
    data.pivotLevel3,
    scrollbarRange.startX,
  ]);

  // The outer most one
  const pivot2Scale = useMemo(() => {
    const getDomain = (depth: PivotDepthType) => {
      switch (depth) {
        case PivotDepthType.One:
          return [];
        case PivotDepthType.Two:
          return (
            data?.dataDepth2?.children.reduce<string[]>((acc, child) => {
              const sortedChildren = child.children.map(
                (child) => child.key?.value,
              );
              const childrenKey = [...child.children]
                .sort(
                  (a, b) =>
                    indexOf(sortedChildren, a.key?.value) -
                    indexOf(sortedChildren, b.key?.value),
                )
                .reduce<string[]>((acc2, child2) => {
                  acc2.push(
                    child.key?.value + PIVOT_SEPARATOR + child2.key?.value,
                  );
                  return acc2;
                }, []);
              return [...acc, ...childrenKey];
            }, []) ?? []
          );

        case PivotDepthType.Three:
          return (
            data?.dataDepth3?.children.reduce<string[]>((acc, child) => {
              const sortedChildren = child.children.map(
                (child) => child.key?.value,
              );
              const childrenKey = child.children
                .sort(
                  (a, b) =>
                    indexOf(sortedChildren, a.key?.value) -
                    indexOf(sortedChildren, b.key?.value),
                )
                .reduce<string[]>((acc2, child2) => {
                  const sortedChildren = child2.children.map(
                    (child2) => child2.key?.value,
                  );
                  const children3Key = child2.children
                    .sort(
                      (a, b) =>
                        indexOf(sortedChildren, a.key?.value) -
                        indexOf(sortedChildren, b.key?.value),
                    )
                    .reduce<string[]>((acc3, child3) => {
                      acc3.push(
                        child.key?.value +
                          PIVOT_SEPARATOR +
                          child2.key?.value +
                          PIVOT_SEPARATOR +
                          child3.key?.value,
                      );
                      return acc3;
                    }, []);
                  return [...acc2, ...children3Key];
                }, []);
              return [...acc, ...childrenKey];
            }, []) ?? []
          );
        default:
          return [];
      }
    };

    const startX = chartSize * scrollbarRange.startX;
    const range: () => [number, number] = () => {
      if (
        data.depth === PivotDepthType.Two ||
        data.depth === PivotDepthType.Three
      )
        return [-startX, chartSize - startX];
      return [0, chartSize];
    };

    const domainIdx = getDomain(data.depth).map(
      (e) => e.split(PIVOT_SEPARATOR)[0],
    );
    const domain: string[] = getDomain(data.depth).sort((a, b) => {
      return (
        domainIdx.indexOf(a.split(PIVOT_SEPARATOR)[0]) -
        domainIdx.indexOf(b.split(PIVOT_SEPARATOR)[0])
      );
    });
    return scaleBand<string>({
      domain,
      range: range(),
    });
  }, [
    chartSize,
    data?.dataDepth2?.children,
    data?.dataDepth3?.children,
    data.depth,
    scrollbarRange.startX,
  ]);

  // Get every domain and calculate the color based on that domain
  useEffect(() => {
    const disableRigLegend = (domain: string[]) => {
      if (!isMock) {
        rigsArr
          .filter((rig) => !domain.includes(`${rig}`))
          .forEach((key) => {
            dispatch(DisableColorForIdAction(key + ""));
          });
      }
    };

    switch (data.depth) {
      case PivotDepthType.One:
        if (colorDecisionalPivotTypeArray.includes(data.pivotLevel1)) {
          pivot1Scale
            .domain()
            .forEach((key) =>
              setColor({ key, isMock, defaultPivot: data.pivotLevel1 }),
            );
          if (data.pivotLevel1 === PivotType.Rig) {
            disableRigLegend(pivot1Scale.domain());
          }
        } else {
          setColor({
            key: selectedRig.toString(),
            isMock,
            defaultPivot: PivotType.Rig,
          });
        }
        break;
      case PivotDepthType.Two:
        if (offsetRigs.length > 0) {
          if (data.pivotLevel1 === PivotType.Rig) {
            pivot1Scale
              .domain()
              .sort((a, b) => rigsArr.indexOf(+a) - rigsArr.indexOf(+b))
              .forEach((key) => {
                setColor({ key, isMock, defaultPivot: PivotType.Rig });
              });
            disableRigLegend(pivot1Scale.domain());
          } else if (data.pivotLevel2 === PivotType.Rig) {
            uniq(
              pivot2Scale
                .domain()
                .map((e) => (e.split(PIVOT_SEPARATOR) ?? "").slice(-1)[0]),
            )
              .sort((a, b) => rigsArr.indexOf(+a) - rigsArr.indexOf(+b))
              .forEach((key) =>
                setColor({ key, isMock, defaultPivot: PivotType.Rig }),
              );
            disableRigLegend(
              uniq(
                pivot2Scale
                  .domain()
                  .map((e) => (e.split(PIVOT_SEPARATOR) ?? "").slice(-1)[0]),
              ),
            );
          }
          break;
        }

        if (colorDecisionalPivotTypeArray.includes(data.pivotLevel2)) {
          uniq(
            pivot2Scale
              .domain()
              .map((e) => (e.split(PIVOT_SEPARATOR) ?? "").slice(-1)[0]),
          ).forEach((key) =>
            setColor({ key, isMock, defaultPivot: data.pivotLevel2 }),
          );
          break;
        }
        if (colorDecisionalPivotTypeArray.includes(data.pivotLevel1)) {
          // should this be the case just when depth = 2?

          pivot1Scale
            .domain()
            .forEach((key) =>
              setColor({ key, isMock, defaultPivot: data.pivotLevel1 }),
            );
          break;
        }

        setColor({
          key: selectedRig.toString(),
          isMock,
          defaultPivot: PivotType.Rig,
        });
        break;
      case PivotDepthType.Three:
        if (data.pivotLevel2 === PivotType.Rig) {
          uniq(
            pivot2Scale
              .domain()
              .map((e) => e.split(PIVOT_SEPARATOR).slice(-2)[0]) // Rig is the second pivot
              .sort((a, b) => rigsArr.indexOf(+a) - rigsArr.indexOf(+b)),
          ).forEach((key) =>
            setColor({ key, isMock, defaultPivot: PivotType.Rig }),
          );

          disableRigLegend(
            pivot2Scale
              .domain()
              .map((e) => e.split(PIVOT_SEPARATOR).slice(-2)[0]),
          );
        } else if (data.pivotLevel1 === PivotType.Rig) {
          pivot1Scale
            .domain()
            .sort((a, b) => rigsArr.indexOf(+a) - rigsArr.indexOf(+b))
            .forEach((key) =>
              setColor({ key, isMock, defaultPivot: PivotType.Rig }),
            );

          disableRigLegend(pivot1Scale.domain());
        } else {
          const depth3Scale = [
            ...new Set(
              (data.dataDepth3?.children ?? []).reduce<string[]>(
                (acc, child) => {
                  if (!child?.children) return acc;
                  acc.push(
                    ...child.children.reduce<string[]>((acc2, child2) => {
                      if (!child2?.children) return acc2;
                      acc2.push(
                        ...child2.children.reduce<string[]>((acc3, child3) => {
                          if (!child3?.key?.value) return acc3;
                          acc3.push(child3.key?.value);
                          return acc3;
                        }, []),
                      );
                      return acc2;
                    }, []),
                  );
                  return acc;
                },
                [],
              ),
            ),
          ];
          depth3Scale.forEach((key) =>
            setColor({ key, isMock, defaultPivot: PivotType.Rig }),
          );

          disableRigLegend(depth3Scale);
        }

        break;
    }
  }, [
    data.dataDepth3?.children,
    data.depth,
    data.pivotLevel1,
    data.pivotLevel2,
    data.pivotLevel3,
    dispatch,
    isMock,
    offsetRigs.length,
    pivot1Scale,
    pivot2Scale,
    rigsArr,
    selectedRig,
    setColor,
  ]);

  const {
    isDark,
    themeStyle: { colors: themeColors, outliers: outliersTheme },
  } = useCustomTheme();

  const getFillColor = useCallback(
    ({
      key,
      dataP1,
      barStack,
      barStacks,
      dataP2,
      pivot3TypeInfo,
      isOutlier,
    }: IColorGenerator) => {
      if (!barStacks || !barStack) return "transparent";
      if (isOutlier)
        return isDark
          ? `${transparentize(0.9, shade(0.3 * (barStacks.length - barStack.index - 1), outliersTheme.bars))}`
          : `${tint(0.3 * barStack.index, outliersTheme.bars_stacked_transparent)}`;

      // Fill color logic is wrong
      const getShiftColor = (color: string) => {
        const scaler = 0.3;
        const day = isDay({
          key,
          dataP1,
          dataP2,
          pivot3TypeInfo,
        });
        if (offsetRigs.length > 0) return tint(scaler * barStack.index, color);
        if (day !== null)
          return day
            ? tint(scaler * barStack.index, color)
            : shade(
                scaler,
                shade(scaler * (barStacks.length - barStack.index - 1), color),
              );
        return shade(
          scaler * (barStacks.length - barStack.index - 1),
          !color ? colorsSequence[0] : color,
        );
      };
      if (offsetRigs.length > 0) {
        if (dataP1.pivotType === PivotType.Rig) {
          return getShiftColor(
            getColor({ key, isMock, currentPivot: PivotType.Rig }),
          );
        }
        if (pivot3TypeInfo === PivotType.Rig) {
          return getShiftColor(
            getColor({
              key: dataP2.key?.value,
              isMock,
              currentPivot: PivotType.Rig,
            }),
          );
        }

        return getShiftColor(
          getColor({
            key: dataP1.key?.value,
            isMock,
            currentPivot: PivotType.Rig,
          }),
        );
      }
      const color = getColor({
        key: selectedRig.toString(),
        isMock,
        currentPivot: PivotType.Rig,
      });
      switch (data.depth) {
        case PivotDepthType.One:
          if (colorDecisionalPivotTypeArray.includes(dataP1?.pivotType)) {
            return getShiftColor(
              getColor({ key, isMock, currentPivot: dataP1?.pivotType }),
            );
          }
          return getShiftColor(color);
        case PivotDepthType.Two:
          if (colorDecisionalPivotTypeArray.includes(dataP1?.pivotType)) {
            return getShiftColor(
              getColor({ key, isMock, currentPivot: dataP1?.pivotType }),
            );
          }
          if (colorDecisionalPivotTypeArray.includes(dataP2?.pivotType)) {
            return getShiftColor(
              getColor({
                key: dataP1.key?.value,
                isMock,
                currentPivot: dataP2?.pivotType,
              }),
            );
          }
          return getShiftColor(
            getColor({
              key: selectedRig.toString(),
              isMock,
              currentPivot: PivotType.Rig,
            }),
          );
      }
    },
    [
      isDark,
      outliersTheme.bars,
      outliersTheme.bars_stacked_transparent,
      offsetRigs.length,
      getColor,
      selectedRig,
      isMock,
      data.depth,
    ],
  );

  const kpiKeys = useMemo(() => {
    const getSlicesDepth1 = (data?: PivotTree1Dto | null) =>
      (data?.children ?? [])[0]?.slices;
    const getSlicesDepth2 = (data?: PivotTree2Dto | null) =>
      getSlicesDepth1((data?.children ?? [])[0]);
    const getSlicesDepth3 = (data?: PivotTree3Dto | null) =>
      getSlicesDepth2((data?.children ?? [])[0]);

    let slices: BarSliceDto[] = [];
    switch (data.depth) {
      case PivotDepthType.One:
        slices = getSlicesDepth1(data.dataDepth1);
        break;
      case PivotDepthType.Two:
        slices = getSlicesDepth2(data.dataDepth2);
        break;
      case PivotDepthType.Three:
        slices = getSlicesDepth3(data.dataDepth3);
        break;
      default:
        slices = [];
        break;
    }

    return [...(slices ?? [])]
      .sort((a, b) => a.position - b.position)
      .map((slice) => slice.kpiTypeId);
  }, [data.dataDepth1, data.dataDepth2, data.dataDepth3, data.depth]);

  const getTotalValues = useCallback(
    (withOutliers: boolean = true) => {
      switch (data.depth) {
        case PivotDepthType.One:
          return getTotalValueLevel1AsArr(data.dataDepth1, withOutliers);
        case PivotDepthType.Two:
          return getTotalValueLevel2AsArr(data.dataDepth2, withOutliers);
        case PivotDepthType.Three:
          return getTotalValueLevel3AsArr(data.dataDepth3, withOutliers);
      }
      return [];
    },
    [
      data.dataDepth1,
      data.dataDepth2,
      data.dataDepth3,
      data.depth,
      getTotalValueLevel1AsArr,
      getTotalValueLevel2AsArr,
      getTotalValueLevel3AsArr,
    ],
  );

  const { outlierThreshold, gradientDefinition, gradientFill } =
    useOutlierThreshold({
      values: getTotalValues(),
      enabled: outliersEnabled,
      selectedVisualAids: lens?.selectedVisualAids,
      targetSegments: targetSegments,
    });

  // Do we actually need this one?
  // Also naming is bad as we use pivot 2 inside it
  const pivot1ScaleByData: (children: PivotBarDto[]) => ScaleBand<string> = (
    children,
  ) => {
    return scaleBand<string>({
      domain: children.map((child) => child.key?.value),
      range: [0, pivot2Scale.bandwidth() * children.length],
      paddingInner: 0.1,
      paddingOuter: 0.2,
    });
  };
  // Maybe we do not need the transform data function
  const transformedData = useMemo(() => {
    switch (data.depth) {
      case PivotDepthType.Zero:
        return [];
      case PivotDepthType.One:
        return [
          {
            children: [
              {
                ...data.dataDepth1,
                children: (data.dataDepth1?.children ?? []).sort(
                  (a, b) =>
                    (pivot1Scale.domain() ?? []).indexOf(a.key?.value) -
                    (pivot1Scale.domain() ?? []).indexOf(b.key?.value),
                ),
              },
            ],
          },
        ];
      case PivotDepthType.Two:
        return [
          {
            ...data.dataDepth2,
            children: (data.dataDepth2?.children ?? []).sort((a, b) => {
              return (
                (pivot2Scale.domain() ?? [])
                  .map((e) => e.split(PIVOT_SEPARATOR)[0])
                  .indexOf(a.key?.value) -
                (pivot2Scale.domain() ?? [])
                  .map((e) => e.split(PIVOT_SEPARATOR)[0])
                  .indexOf(b.key?.value)
              );
            }),
          },
        ];
      case PivotDepthType.Three:
        return (data.dataDepth3?.children ?? []).sort((a, b) => {
          return (
            (pivot2Scale.domain() ?? [])
              .map((e) => e.split(PIVOT_SEPARATOR)[0])
              .indexOf(a.key?.value) -
            (pivot2Scale.domain() ?? [])
              .map((e) => e.split(PIVOT_SEPARATOR)[0])
              .indexOf(b.key?.value)
          );
        });
      default:
        return null;
    }
  }, [
    data.dataDepth1,
    data.dataDepth2,
    data.dataDepth3?.children,
    data.depth,
    pivot1Scale,
    pivot2Scale,
  ]);

  const barWidth =
    data.depth === PivotDepthType.One
      ? pivot1Scale.bandwidth()
      : pivot2Scale.bandwidth();
  const hasBottomAxis = data.depth !== PivotDepthType.One && barWidth >= 6;

  const topMargin = lens?.label ? TOP_LABEL_HEIGHT : 0;

  const plotHeight =
    (chartHeight ?? 0) -
    (detailed ? 90 : 15) -
    (hasBottomAxis ? secondaryAxisHeight : 0) +
    (disableAxis ? 35 : 0) -
    (hasScrollbar ? scrollbarHeight : 0) -
    scrollbarHeight -
    topMargin;

  const median = useMemo(() => {
    return data?.summaryByKpi?.find((e) => e.id === 0)?.median;
  }, [data?.summaryByKpi]);

  const valueScale = useMemo(() => {
    return scaleLinear<number>({
      domain: getMinMax(
        getTotalValues(false),
        targetSegments,
        outlierThreshold,
        median,
        lens?.selectedVisualAids,
        lens?.outlierFlaggingType,
        lens?.isManualYaxis,
        lens?.yaxisEnd,
        lens?.yaxisStart,
      ),
      range: [plotHeight, 0],
      clamp: true,
    });
  }, [
    getTotalValues,
    lens?.isManualYaxis,
    lens?.outlierFlaggingType,
    lens?.selectedVisualAids,
    lens?.yaxisEnd,
    lens?.yaxisStart,
    median,
    outlierThreshold,
    plotHeight,
    targetSegments,
  ]);

  const averageValue = useMemo(() => {
    switch (data.depth) {
      case PivotDepthType.One:
        return data.dataDepth1?.averageValue ?? 0;
      case PivotDepthType.Two:
        return data.dataDepth2?.averageValue ?? 0;
      case PivotDepthType.Three:
        return data.dataDepth3?.averageValue ?? 0;
    }
    return 0;
  }, [data.dataDepth1, data.dataDepth2, data.dataDepth3, data.depth]);

  const valuesDomain = useMemo(
    // Magic number 100 to have a default of 2 decimals and ignore autodigits. Temporary solution until stacked bars are also included in decimals feature
    () => (kpiKeys.length === 1 ? valueScale.domain() : [0, 100]),
    [kpiKeys.length, valueScale],
  );

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<TData>({
    containerRef,
    renderContent: ({ tooltipData }) => {
      return (
        <>
          <TooltipHighlightValue>
            {lens.stackingType === StackingType.Values ||
            tooltipData?.slices.length === 1
              ? valueUOM.displayWithAutoDecimals(
                  valuesDomain,
                  tooltipData?.totalValue,
                )
              : kpiKeys
                  .slice()
                  .sort(
                    (a, b) =>
                      data?.summaryByKpi?.find((e) => e.id === a)?.position ||
                      0 -
                        (data?.summaryByKpi?.find((e) => e.id === b)
                          ?.position || 0),
                  )
                  .map(
                    (key, i) =>
                      kpiTypes?.byId?.[key] && (
                        <div
                          key={`${key}-${i}-label`}
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {kpiTypes.byId[key].name}:{" "}
                          {roundNumberToDecimal(
                            getData(
                              tooltipData?.slices?.find(
                                (e: BarSliceDto) => e.kpiTypeId === key,
                              ),
                            ) * 100,
                            2,
                          ) + "%"}
                        </div>
                      ),
                  )}
          </TooltipHighlightValue>
          <TooltipGroup>
            {(tooltipData?.slices?.length || 0) > 0 &&
              kpiKeys
                .slice()
                .reverse() // Tooltip and bars/slices building logic must be reversed for result to look good. Go figure!
                .map(
                  (key, i) =>
                    kpiTypes?.byId[key] && (
                      <div
                        key={`${key}-${i}-label`}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {kpiTypes.byId[key].name}:{" "}
                        {valueUOM.displayWithAutoDecimals(
                          valuesDomain,
                          tooltipData?.slices.find(
                            (e: BarSliceDto) => e.kpiTypeId === key,
                          )?.value,
                        )}
                      </div>
                    ),
                )}
          </TooltipGroup>
          <TooltipGroup
            style={{
              textAlign: "center",
            }}
          >
            {tooltipData?.dataArr.map(
              (key: string, i: number) =>
                key && <div key={`${key}-${i}-label`}>{key}</div>,
            )}
          </TooltipGroup>

          <TooltipVisualAidInfo
            selectedVisualAids={lens?.selectedVisualAids}
            display={valueUOM.display}
            targetValue={tooltipData?.targetValue}
            averageValue={averageValue}
            median={median}
          />

          {lens.showOperationCount && tooltipData?.operationCount ? (
            <TooltipHighlightValue>
              Operation count: {tooltipData?.operationCount}
            </TooltipHighlightValue>
          ) : null}
          {tooltipData?.isOutlier ? (
            <TooltipHighlightValue fontWeight="normal">
              OUTLIER
            </TooltipHighlightValue>
          ) : null}
        </>
      );
    },
  });

  const handleMouseOver = useCallback(
    (leftX: number, barData: PivotBarDto, dataArr: Array<string>) => {
      if (!enableTooltip) return;

      showTooltip({
        tooltipLeft: leftX + paddingLabels,
        tooltipTop: valueScale(
          Math.min(barData.totalValue ?? 0, valueScale.domain()[1] ?? 0),
        ),
        tooltipData: { ...barData, dataArr },
      });
    },
    [enableTooltip, paddingLabels, showTooltip, valueScale],
  );

  // A separate scale for scrollbar
  // Maps scrollbar pixel position to a [0, 1] range
  const scrollbarScale = useMemo(
    () => scaleLinear<number>({ domain: [0, 1], range: [0, scrollbarWidth] }),
    [scrollbarWidth],
  );

  const bottomTickWidth = useMemo(() => {
    const min = 50;

    switch (data.depth) {
      case PivotDepthType.One:
        return Math.max(pivot1Scale.bandwidth(), detailed ? 60 : 40);
      case PivotDepthType.Two:
        return min;
      case PivotDepthType.Three:
        return 0;
    }
    return 0;
  }, [data.depth, detailed, pivot1Scale]);

  const getPivotKey = (
    pivot1Key: string,
    pivot2Key?: string,
    pivot3Key?: string,
  ) => {
    if (data.depth === PivotDepthType.One) {
      return pivot1Key;
    }

    if (data.depth === PivotDepthType.Two) {
      const foundKey = pivot2Scale
        .domain()
        .find((pivot2Domain) =>
          pivot2Domain.startsWith(pivot1Key + PIVOT_SEPARATOR),
        );

      if (!pivot2Key && foundKey) {
        return foundKey;
      }
      return pivot1Key + PIVOT_SEPARATOR + pivot2Key;
    }
    if (data.depth === PivotDepthType.Three) {
      if (!pivot3Key) {
        const foundPivot1Key = pivot2Scale
          .domain()
          .find((pivot2Domain) =>
            pivot2Domain.startsWith(pivot1Key + PIVOT_SEPARATOR),
          );

        if (!pivot2Key && foundPivot1Key) {
          return foundPivot1Key;
        }

        const foundPivot2Key = pivot2Scale
          .domain()
          .find((pivot2Domain) =>
            pivot2Domain.startsWith(
              pivot1Key + PIVOT_SEPARATOR + pivot2Key + PIVOT_SEPARATOR,
            ),
          );
        if (foundPivot2Key) return foundPivot2Key;
      }
      return (
        pivot1Key + PIVOT_SEPARATOR + pivot2Key + PIVOT_SEPARATOR + pivot3Key
      );
    }
    return pivot1Key;
  };

  const getXFromPivot = (
    pivot1Key?: string,
    pivot2Key?: string,
    pivot3Key?: string,
  ) => {
    if (!pivot1Key) {
      return 0;
    }

    const getXFromPivot2 = (pivot1Key: string, pivot2Key?: string) => {
      if (!pivot2Key) {
        return getPivotKey(pivot1Key)
          ? pivot2Scale(getPivotKey(pivot1Key))
          : pivot1Scale(pivot1Key);
      }
      return pivot2Scale(getPivotKey(pivot1Key, pivot2Key));
    };

    if (data.depth === PivotDepthType.One) {
      return pivot1Scale(pivot1Key);
    }

    if (data.depth === PivotDepthType.Two) {
      return getXFromPivot2(pivot1Key, pivot2Key);
    }

    if (data.depth === PivotDepthType.Three) {
      if (!pivot3Key) {
        if (!pivot2Key) {
          return getXFromPivot2(pivot1Key);
        }
        return getPivotKey(pivot1Key, pivot2Key)
          ? pivot2Scale(getPivotKey(pivot1Key, pivot2Key))
          : getXFromPivot2(pivot1Key, pivot2Key);
      }
      return pivot2Scale(getPivotKey(pivot1Key, pivot2Key, pivot3Key));
    }
  };

  const kpiSummaryHeight = useMemo(() => {
    let height = 0;
    if (detailed) height += 350;
    if (!detailed && !isMock) height += 160;
    if (hasScrollbar) height += 8;
    if (lens?.showOperationCount) height += OPERATION_COUNT_HEIGHT;
    return height;
  }, [detailed, isMock, lens?.showOperationCount, hasScrollbar]);

  return (
    <div
      ref={containerRef}
      style={{
        padding: 0,
        paddingLeft: paddingLabels,
        height: `calc(100% - ${kpiSummaryHeight}px)`,
        marginTop: `${lens?.showOperationCount ? OPERATION_COUNT_HEIGHT : 0}px`,
        overflow: "visible",
        position: "relative",
      }}
    >
      <svg
        width={chartWidth}
        height={chartHeight ?? 0}
        style={{ overflow: "visible", userSelect: "none" }}
      >
        {gradientDefinition}
        <Chart
          hideZeroValues
          topMargin={topMargin}
          disableValueAxis={disableValueAxis}
          detailed={detailed}
          isManual={lens?.isManualYaxis}
          chartWidth={chartWidth}
          chartHeight={chartHeight ?? 0}
          plotWidth={plotWidth}
          startX={plotWidth * zoomRate * scrollbarRange.startX}
          plotHeight={plotHeight}
          valueScale={valueScale}
          maxBottomTickWidth={bottomTickWidth}
          categoryScale={pivot1Scale}
          showOperationCount={lens?.showOperationCount}
          operationCountTopMargin={RIG_OPERATION_COUNT_TOP_MARGIN}
          operationCountLeftMargin={RIG_OPERATION_COUNT_LEFT_MARGIN}
          bottomTickComponent={(e) => {
            let pivot: PivotType = PivotType.None;
            switch (data.depth) {
              case PivotDepthType.One:
                pivot = data.pivotLevel1;
                break;
              case PivotDepthType.Two:
                pivot = data.pivotLevel1;
                break;
              case PivotDepthType.Three:
                if (data.pivotLevel1 !== PivotType.Rig)
                  pivot = data.pivotLevel1;
                else if (data.pivotLevel2 !== PivotType.Rig)
                  pivot = data.pivotLevel2;
                else pivot = data.pivotLevel3;
                break;
            }

            const pointDomain = pivot2Scale
              .domain()
              .filter((pivot2Domain) =>
                pivot2Domain.startsWith(e.formattedValue + PIVOT_SEPARATOR),
              );
            const getXFromValue = (value?: string) => {
              const middle = pointDomain[Math.trunc(pointDomain.length / 2)];
              if (data.depth === PivotDepthType.One) {
                return (
                  (value ? pivot1Scale(value) || 0 : 0) +
                  pivot1Scale.bandwidth() / 2
                );
              }
              return (
                (middle ? pivot2Scale(middle) ?? 0 : 0) +
                (pointDomain.length % 2 ? pivot2Scale.bandwidth() / 2 : 0)
              );
            };
            const getAvailableWidth = () => {
              if (data.depth === PivotDepthType.One) {
                return bottomTickWidth;
              }
              if (data.depth === PivotDepthType.Two) {
                return Math.max(bottomTickWidth, barWidth * pointDomain.length);
              }
              return barWidth * pointDomain.length;
            };
            const label = e.formattedValue
              ? getLabelFromKey(e.formattedValue, pivot, "long")
              : "";

            return getAvailableWidth() > 19.99 ? (
              <text
                {...omit(e, "formattedValue")}
                key={"N" + e.x}
                x={getXFromValue(e.formattedValue)}
              >
                {truncateMiddleString(label, getAvailableWidth() / 10)}
              </text>
            ) : null;
          }}
          valueUOM={
            lens.stackingType === StackingType.Distribution
              ? percentageUOM
              : valueUOM
          }
          rightTickFormat={
            lens.stackingType === StackingType.Distribution
              ? (value) => {
                  const n = value.valueOf();
                  if (n === 0) return "";
                  return n + "%";
                }
              : undefined
          }
          axisBottomMargin={hasBottomAxis ? secondaryAxisHeight - 2 : 0}
          axisBottomColor={themeColors.primary_typography}
        >
          {/* Secondary bottom axis BG on mini nad detailed lens */}
          {hasBottomAxis ? (
            <Bar
              x={isMock ? 0 : -50}
              y={plotHeight}
              height={secondaryAxisHeight}
              width={chartWidth + (isMock ? 0 : 50)}
              fill={themeColors.primary_bg}
            />
          ) : null}
          {/* Primary bottom axis BG on mini lens */}
          {!detailed && (
            <Bar
              x={disableValueAxis || isMock ? 0 : -50}
              y={plotHeight + (hasBottomAxis ? secondaryAxisHeight : 0)}
              height={30}
              width={chartWidth + (disableValueAxis ? 0 : isMock ? 0 : 50)}
              fill={themeColors.quaterniary_bg}
            />
          )}
          {transformedData?.map((dataP2NoType) => {
            const dataP2 = dataP2NoType as PivotTree2Dto;
            if (!dataP2?.children) return null;
            return (
              <Group key={`${dataP2.key?.value}-key`}>
                {dataP2?.children.map((dataP1) => {
                  const left =
                    (data.depth === PivotDepthType.Two
                      ? getXFromPivot(dataP1.key?.value)
                      : getXFromPivot(dataP2.key?.value, dataP1.key?.value)) ??
                    0;

                  return (
                    <Group
                      key={`${dataP1.key?.value}-${dataP2.key?.value}-bar-index`}
                      left={left}
                    >
                      <BarStack<PivotBarDto, number>
                        x={(d) => d.key?.value}
                        keys={kpiKeys}
                        value={getValue}
                        data={dataP1?.children ?? []}
                        xScale={
                          data.depth === PivotDepthType.One
                            ? pivot1Scale
                            : pivot1ScaleByData(dataP1?.children ?? [])
                        }
                        yScale={valueScale}
                        color={() => "transparent"}
                      >
                        {(barStacks) =>
                          barStacks.map((barStack) => {
                            return [
                              <Group
                                key={`${dataP1.key?.value}-${dataP2.key?.value}-bar-index-bar-stack-${barStack.index}`}
                              >
                                {barStack.bars.map((bar) => {
                                  if (
                                    bar.bar.some((e) => Number.isNaN(e)) ||
                                    bar.x + bar.width < 0 ||
                                    left - (bar.x + bar.width) > plotWidth
                                  )
                                    return null;

                                  // Hole sections, hole sizes,
                                  const color = getFillColor({
                                    key: bar.bar.data.key?.value,
                                    dataP1,
                                    barStack,
                                    barStacks,
                                    dataP2,
                                    pivot3TypeInfo:
                                      data.depth === PivotDepthType.Three
                                        ? data.pivotLevel3
                                        : undefined,
                                    isOutlier: bar.bar.data.isOutlier,
                                  });
                                  const bars = Object.values(
                                    bar?.bar?.data?.slices ?? [],
                                  ).filter((x) => x?.value);
                                  const isLast =
                                    barStack.index === bars.length - 1;
                                  const maxValue = valueScale.domain()[1];
                                  const threshold =
                                    isManual || outlierThreshold === null
                                      ? maxValue
                                      : outlierThreshold;
                                  const thresholdValue = valueScale(maxValue);
                                  const showGradient =
                                    outliersEnabled &&
                                    lens.stackingType !==
                                      StackingType.Distribution &&
                                    isLast &&
                                    threshold - bar.bar.data.totalValue <
                                      Number.EPSILON;
                                  let barY = bar.y;
                                  let barHeight = bar.height;

                                  if (showGradient) {
                                    barHeight -= thresholdValue - barY;
                                    barY = thresholdValue;
                                  }
                                  return (
                                    <Group
                                      key={`${dataP1.key?.value}-${dataP2.key?.value}-bar-index-bar-stack-${barStack.index}-${bar.index}`}
                                      onMouseOver={() => {
                                        handleMouseOver(
                                          left + bar.x + bar.width / 2,
                                          bar.bar.data,
                                          [
                                            getLabelFromKey(
                                              bar.bar.data.key?.value,
                                              dataP1?.pivotType,
                                            ),
                                            data.depth === PivotDepthType.Two ||
                                            data.depth === PivotDepthType.Three
                                              ? getLabelFromKey(
                                                  dataP1.key?.value,
                                                  dataP2?.pivotType,
                                                )
                                              : "",
                                            data.depth === PivotDepthType.Three
                                              ? getLabelFromKey(
                                                  dataP2.key?.value,
                                                  data.pivotLevel1,
                                                )
                                              : "",
                                          ],
                                        );
                                      }}
                                      onMouseOut={hideTooltip}
                                    >
                                      <BarRounded
                                        x={bar.x}
                                        y={barY}
                                        top={
                                          lens.stackingType !==
                                            StackingType.Distribution && isLast
                                        }
                                        radius={2}
                                        height={barHeight}
                                        width={bar.width}
                                        filter={(() => {
                                          if (
                                            isDay({
                                              key: bar.bar.data.key?.value,
                                              dataP1,
                                              barStack,
                                              barStacks,
                                              dataP2,
                                              pivot3TypeInfo:
                                                data.depth ===
                                                PivotDepthType.Three
                                                  ? data.pivotLevel3
                                                  : undefined,
                                            }) &&
                                            lens.stackingType ===
                                              StackingType.Distribution
                                          )
                                            return `grayscale(${barStack.index * 20}%)`;
                                          return "";
                                        })()}
                                        fill={color}
                                      />
                                      {showGradient ? (
                                        <Bar
                                          x={bar.x - 1}
                                          y={barY - 1}
                                          width={bar.width + 2}
                                          height={detailed ? 50 : 25}
                                          fill={gradientFill}
                                        />
                                      ) : null}
                                    </Group>
                                  );
                                })}

                                <Group id="inner-labels">
                                  {barStacks.map((barStack) => {
                                    return (
                                      <React.Fragment
                                        key={`bar-stack-inner-${barStack.index}`}
                                      >
                                        {barStack.bars.map((bar, index) => {
                                          return dataP1.children[index]?.slices
                                            ?.length === 1 ? null : (
                                            <Label
                                              key={`bar-stack-inner-${barStack.index}-${index}-label`}
                                              barX={bar.x}
                                              innerLabel
                                              barY={bar.y + bar.height / 2}
                                              columnWidth={bar.width}
                                              barHeight={bar.height}
                                              index={index}
                                              value={
                                                lens.stackingType ===
                                                StackingType.Distribution
                                                  ? roundNumberToDecimal(
                                                      getValue(
                                                        dataP1.children[index],
                                                        bar.key,
                                                      ) * 100,
                                                      2,
                                                    ) + "%"
                                                  : valueUOM.display(
                                                      getValue(
                                                        dataP1.children[index],
                                                        bar.key,
                                                      ),
                                                      {
                                                        unit: "",
                                                      },
                                                    )
                                              }
                                              label={lens?.label}
                                            />
                                          );
                                        })}
                                      </React.Fragment>
                                    );
                                  })}
                                </Group>
                              </Group>,
                              barStacks.length > 0 && (
                                <Group
                                  key={`bar-stack-inner-${barStack.index}-fragment`}
                                  id={`outer-labels-${barStack.index}`}
                                >
                                  {barStacks.map((barStack) => {
                                    return (
                                      <React.Fragment
                                        key={`bar-stack-inner-${barStack.index}`}
                                      >
                                        {barStack.bars.map((bar, index) => {
                                          const isLast =
                                            barStack.index ===
                                            barStacks.length - 1;

                                          const nIndex =
                                            data.depth === PivotDepthType.One
                                              ? index
                                              : indexOf(
                                                  pivot2Scale.domain(),
                                                  data.depth ===
                                                    PivotDepthType.Three
                                                    ? getPivotKey(
                                                        dataP2.key?.value,
                                                        dataP1.key?.value,
                                                        bar.bar.data.key?.value,
                                                      )
                                                    : getPivotKey(
                                                        dataP1.key?.value,
                                                        bar.bar.data.key?.value,
                                                      ),
                                                );

                                          const bardata =
                                            dataP1.children[index];
                                          if (!isLast) {
                                            return null;
                                          }
                                          return (
                                            <React.Fragment
                                              key={`bar-stack-outer-${barStack.index}-${index}-label-container`}
                                            >
                                              <Label
                                                key={`bar-stack-outer-${barStack.index}-${index}-label`}
                                                barX={bar.x}
                                                barY={bar.y}
                                                topLabel={bar.y !== plotHeight}
                                                columnWidth={bar.width}
                                                barHeight={10}
                                                index={+nIndex}
                                                value={
                                                  lens.stackingType ===
                                                  StackingType.Distribution
                                                    ? "" // No label for distribution as that would always be 100%
                                                    : valueUOM.displayWithAutoDecimals(
                                                        valuesDomain,
                                                        bardata.slices.reduce(
                                                          (acc, val) =>
                                                            acc + val.value,
                                                          0,
                                                        ),
                                                        {
                                                          unit: "",
                                                        },
                                                      )
                                                }
                                                label={lens?.label}
                                              />
                                              {lens?.showOperationCount ? (
                                                <OperationCount
                                                  x={bar.x}
                                                  topMargin={-16}
                                                  width={bar.width}
                                                  index={+nIndex}
                                                  detailed={detailed}
                                                  value={bardata.operationCount}
                                                />
                                              ) : null}
                                            </React.Fragment>
                                          );
                                        })}
                                      </React.Fragment>
                                    );
                                  })}
                                </Group>
                              ),
                            ];
                          })
                        }
                      </BarStack>

                      {data.depth === PivotDepthType.Two && (
                        <SeparatorLines
                          detailed={detailed}
                          hidden={
                            (transformedData[0] as PivotTree2Dto)?.children[0]
                              ?.key?.value === dataP1?.key?.value
                          }
                          plotHeight={plotHeight}
                          x={
                            0 -
                            2 * pivot2Scale.bandwidth() * pivot2Scale.padding()
                          }
                        />
                      )}
                      {hasBottomAxis ? (
                        <AxisBottom
                          top={plotHeight}
                          scale={pivot1ScaleByData(dataP1?.children ?? [])}
                          hideAxisLine
                          hideTicks
                          tickFormat={(value) => {
                            if (data.depth === PivotDepthType.Three) {
                              if (dataP1.pivotType === PivotType.Rig) {
                                return truncateMiddleString(
                                  getLabelFromKey(
                                    dataP1.key?.value,
                                    dataP2.pivotType,
                                  ),
                                  detailed ? 13 : 6,
                                );
                              }
                            }
                            return truncateMiddleString(
                              getLabelFromKey(value, dataP1.pivotType),
                              detailed ? 13 : 6,
                            );
                          }}
                          numTicks={(dataP1?.children ?? []).length}
                          tickLabelProps={(value) => {
                            const idx =
                              data.depth === PivotDepthType.Three
                                ? getPivotKey(
                                    dataP2.key?.value,
                                    dataP1.key?.value,
                                    value,
                                  )
                                : getPivotKey(dataP1.key?.value, value);

                            const nIndex = +(
                              Object.keys(pivot2Scale.domain()).find(
                                (key) => pivot2Scale.domain()[+key] === idx,
                              ) || 0
                            );

                            const isVisible = () => {
                              if (
                                (barWidth < 19 &&
                                  barWidth >= 12 &&
                                  nIndex % 2 !== 0) ||
                                (barWidth < 12 &&
                                  barWidth >= 8 &&
                                  nIndex % 4 !== 0) ||
                                (barWidth < 8 &&
                                  barWidth >= 6 &&
                                  nIndex % 5 !== 0) ||
                                barWidth < 6
                              ) {
                                return false;
                              }
                              return true;
                            };
                            return {
                              fontSize: 14,
                              opacity: isVisible() ? 1 : 0,
                              fill: detailed
                                ? themeColors.tertiary_typography
                                : themeColors.primary_typography,
                              letterSpacing: "-0.2px",
                              textAnchor: barWidth >= 50 ? "middle" : "start",
                              style: {
                                transformOrigin: "0% 0%",
                                transformBox: "fill-box",
                                transform:
                                  barWidth >= 50
                                    ? `rotate(45deg)`
                                    : `rotate(45deg) translate(-5px, -5px)`,
                              },
                            };
                          }}
                        />
                      ) : null}
                    </Group>
                  );
                })}

                {data.depth === PivotDepthType.Three && (
                  <SeparatorLines
                    detailed={detailed}
                    hidden={
                      dataP2.key?.value ===
                      (transformedData[0] as PivotTree2Dto).key?.value
                    }
                    plotHeight={plotHeight}
                    x={getXFromPivot(dataP2.key?.value) || 0}
                  />
                )}
              </Group>
            );
          })}

          {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Targets) &&
            targetSegments?.map((targetSegment, index) => {
              if (!targetSegment || !targetSegment.target) return null;
              const start =
                getXFromPivot(
                  targetSegment.first?.key?.path?.valueLevel1,
                  targetSegment.first?.key?.path?.valueLevel2,
                  targetSegment.first?.key?.path?.valueLevel3,
                ) ?? 0;
              const end =
                (getXFromPivot(
                  targetSegment.last?.key?.path?.valueLevel1,
                  targetSegment.last?.key?.path?.valueLevel2,
                  targetSegment.last?.key?.path?.valueLevel3,
                ) ?? 0) + barWidth;

              return (
                <TargetLine
                  key={`target-${targetSegment.first?.key.name}-${targetSegment.last?.key.name}-${index}`}
                  start={start}
                  end={end}
                  y={valueScale(targetSegment.target)}
                  label={valueUOM.displayWithAutoDecimals(
                    valuesDomain,
                    targetSegment.target,
                    { unit: "" },
                  )}
                  showTag={index === 0 && end - start > TARGET_FULL_PILL_WIDTH}
                  detailed={detailed}
                />
              );
            })}

          <AverageLine
            isVisible={(visualAidsState ?? []).includes(VisualAidType.Average)}
            y={valueScale(averageValue)}
            x={-RIGHT_AXIS_PADDING}
            width={plotWidth + RIGHT_AXIS_PADDING}
          />

          <MedianLine
            isVisible={(visualAidsState ?? []).includes(VisualAidType.Median)}
            y={valueScale(median || 0)}
            x={-RIGHT_AXIS_PADDING}
            width={plotWidth + RIGHT_AXIS_PADDING}
          />

          {tooltipElement}
        </Chart>
        {hasScrollbar ? (
          <Group
            key="scrollbar"
            top={
              plotHeight +
              topMargin +
              (hasBottomAxis && barWidth >= 6 ? secondaryAxisHeight : 0) +
              (detailed ? 62 : 26)
            }
            left={scrollbarPadding ?? 0}
          >
            <Scrollbar
              width={scrollbarWidth}
              height={scrollbarHeight}
              scale={scrollbarScale}
              wheelCaptureContainer={containerRef}
              onScroll={setScrollbarRange}
              valueSpan={1 / zoomRate}
            />
          </Group>
        ) : null}
      </svg>

      {(visualAidsState ?? []).includes(VisualAidType.Average) && (
        <AverageLabel
          style={{
            top: `${valueScale(averageValue) - 16}px`,
          }}
        >
          Average:{" "}
          {valueUOM.displayWithAutoDecimals(valuesDomain, averageValue)}
        </AverageLabel>
      )}

      {(visualAidsState ?? []).includes(VisualAidType.Median) && (
        <MedianLabel
          isDetailed={detailed}
          style={{
            top: `${median ? valueScale(median) : 0 - 16}px`,
          }}
        >
          Median: {valueUOM.displayWithAutoDecimals(valuesDomain, median)}
        </MedianLabel>
      )}
    </div>
  );
};

export default PivotKpi;
