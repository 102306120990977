import { ManageTabsModal } from "pages/WellDashboard/ManageTabs/ManageTabsModal";
import { useCallback, useMemo, useState } from "react";

export const useManageTabsModal = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const modalElement = useMemo(() => {
    return showModal ? (
      <ManageTabsModal
        isVisible={showModal}
        onCancel={() => setShowModal(false)}
      />
    ) : null;
  }, [showModal]);

  return { modalElement, openModal };
};
