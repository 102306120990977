import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  type AllRigsDataFeedQueryDto,
  DataFeedsApi,
  type WellStatusType,
} from "apis/oag";
import { RealTimeDataEnum } from "components/RealTimeIndicator";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { API_REFRESH_INTERVAL, apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { RigTechnology } from "utils/enums";
import { dateToDateOnlyDto } from "utils/helper";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const DataFeeds = new DataFeedsApi(apiConfig);

export const useRigsCommonDataFeed = (
  isOnLeaderboard: boolean,
  options?: UseQueryOptions<boolean>,
) => {
  const operatorsState = useAppSelector((state) => state.rigsCommon.operators);
  const rigIdsState = useAppSelector((state) => state.rigsCommon.rigIds);
  const formationIdsState = useAppSelector(
    (state) => state.rigsCommon.formationIds,
  );

  const periodState = useAppSelector((state) => state.rigsCommon.period);
  const queryClient = useQueryClient();
  const lastAllRigsRefreshDate = useAppSelector(
    (state) => state.rigsCommon.lastAllRigsRefreshDate,
  );

  const realtimeData = useAppSelector((state) => state.global.realTimeData);
  const filterState = useAppSelector((state) => state.rigsCommon.filters);
  const allWellsIncluded = useAppSelector(
    (state) => state.rigsCommon.allWellsIncluded,
  );

  const requestQuery: AllRigsDataFeedQueryDto = useMemo(
    (): AllRigsDataFeedQueryDto => ({
      isOnLeaderboard: isOnLeaderboard,

      operatorIds: operatorsState,
      rigIds: rigIdsState,
      formationIds: formationIdsState,

      from: dateToDateOnlyDto(
        periodState?.startDate ?? defaultDateDto.from.utc,
      ),
      to: dateToDateOnlyDto(periodState?.endDate ?? defaultDateDto.to.utc),
      includeFullWellFacts: allWellsIncluded,
      lastWellRefreshDate: lastAllRigsRefreshDate ?? defaultDateDto.from,
      selectedFilters: {
        // BEGIN : Not impacted by Fact related data
        directionalIntervalIds: null,
        holeSizeIds: null,
        sectionIds: null,
        phaseIds: null,
        includeFlatTime: true,
        includeSlidingTime: true,
        includeRotatingTime: true,
        includeNullHoleDepth: true,
        // END

        includeAlphaRigs: filterState?.technology
          ? filterState?.technology
              .map((tech) => tech.id)
              .includes(RigTechnology.Alpha)
          : true,
        includeNonAlphaRigs: filterState?.technology
          ? filterState?.technology
              .map((tech) => tech.id)
              .includes(RigTechnology.NonAlpha)
          : true,
        selectedClasses: filterState?.rigType
          ? filterState?.rigType.map((t) => (t.id || -1).toString())
          : null,
        selectedCountryIds: filterState?.country
          ? filterState?.country.map((c) => +(c.id || -1))
          : null,
        selectedHorsePowers: filterState?.horsePower
          ? filterState?.horsePower.map((hp) => +(hp.id || -1))
          : null,
        selectedOperatingCenters: filterState?.operatingCenter
          ? filterState?.operatingCenter.map((oc) => (oc.id || -1).toString())
          : null,
        selectedWellStatusTypes: filterState?.wellStatus?.map(
          (ws) => ws.id as WellStatusType,
        ),
      },
    }),
    [
      isOnLeaderboard,
      operatorsState,
      rigIdsState,
      formationIdsState,
      periodState?.startDate,
      periodState?.endDate,
      allWellsIncluded,
      lastAllRigsRefreshDate,
      filterState?.technology,
      filterState?.rigType,
      filterState?.country,
      filterState?.horsePower,
      filterState?.operatingCenter,
      filterState?.wellStatus,
    ],
  );

  const refreshRigsCommon = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [{ type: PDQueryType.RIGS_KPI }],
      ...{ exact: false },
    });
    queryClient.invalidateQueries({
      queryKey: [{ type: PDQueryType.RIGS_SUMMARIES }],
      ...{ exact: false },
    });
    queryClient.invalidateQueries({
      queryKey: [{ type: PDQueryType.RIG_LEADERBOARD }],
      ...{ exact: false },
    });
    // Fleet performance
    queryClient.invalidateQueries({
      queryKey: [{ uid: RequestUID.rigsCardFacts }],
      ...{ exact: false },
    });
  }, [queryClient]);

  useQuery<boolean>({
    queryKey: [{ uid: RequestUID.rigsCommonDataFeed, requestQuery }],
    queryFn: () => {
      if (!lastAllRigsRefreshDate || realtimeData !== RealTimeDataEnum.ACTIVE)
        return Promise.resolve(false);

      return DataFeeds.apiDataFeedsRigsPut({
        allRigsDataFeedQueryDto: requestQuery,
      }).then((res) => {
        if (res) refreshRigsCommon();
        return res;
      });
    },
    ...options,
    refetchIntervalInBackground: true,
    refetchInterval: API_REFRESH_INTERVAL,
  });
};
