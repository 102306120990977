import { AxisLeft } from "@visx/axis";
import { getAxisFontSize } from "components/Lenses/ContainerLens/common/utils/utils";
import { axisMargins } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/utils";
import type { ScaleLinear } from "d3-scale";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import type { UOMHelper } from "utils/format";
import { useCustomTheme } from "utils/useTheme";

export interface TrackAxisProps {
  chartHeight: number;
  trackUomHelper: UOMHelper;
  scale: ScaleLinear<number, number>;
  top?: number;
  left?: number;
  detailed?: boolean;
  isManual?: boolean;
}

export function TrackAxis({
  scale,
  top = axisMargins.vertical,
  left = axisMargins.horizontal,
  isManual = true,
  trackUomHelper,
  detailed,
  chartHeight,
}: TrackAxisProps) {
  const { verticalAxisTicksCount, yDisplayOnlyScale, manualTickValues } =
    useYAxisDisplayScale({
      originalScale: scale,
      uom: trackUomHelper,
      tickContainerHeight: chartHeight,
      isManual,
    });

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  return (
    <AxisLeft
      hideTicks
      top={top}
      left={left}
      hideAxisLine
      tickStroke={themeColors.primary_typography}
      stroke={themeColors.primary_typography}
      scale={yDisplayOnlyScale}
      numTicks={verticalAxisTicksCount}
      tickValues={isManual ? manualTickValues : undefined}
      tickLabelProps={(value) => {
        const [min, max] = yDisplayOnlyScale.domain();
        const bold = (value: number) => isManual && [min, max].includes(+value);

        const fillColor = (value: number) => {
          if (bold(value)) return themeColors.primary_typography;
          return themeColors.faint_typography;
        };

        return {
          fontSize: `${getAxisFontSize(detailed)}px`,
          fill: fillColor(+value),
          fontWeight: bold(+value) ? 600 : "normal",
        };
      }}
    />
  );
}
