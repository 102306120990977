import { Title } from "atoms/Typography";
import styled from "styled-components";
import colors from "utils/colors";
import { Row } from "utils/componentLibrary";

export const StyledHeaderContainer = styled.div`
  margin: 0 24px;
`;

export const StyledTitle = styled(Title).attrs({
  level: 3,
})`
  display: inline-block;
  margin-bottom: 0 !important;
  font-weight: normal !important;
`;

export const Box = styled.div`
  width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
  background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
`;

export const Circle = styled.div<{ color: string }>`
  height: 12px;
  width: 12px;
  background-color: ${(props) => props.color};
  border-radius: 100%;
`;

export const LegendRow = styled(Row).attrs({
  align: "middle",
  justify: "center",
  gutter: 12,
  wrap: false,
})<{ $opacity: number }>`
  padding: 20px 16px;
  border-bottom: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
  opacity: ${({ $opacity }) => $opacity};
`;
