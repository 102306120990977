import { Title } from "atoms/Typography";
import * as Styled from "components/Lenses/ContainerLens/StickSlipByTime/components/Summary/styles";
import {
  LEFT_COLUMN_WIDTH,
  LEFT_COLUMN_WIDTH_DETAILED,
} from "components/Lenses/ContainerLens/StickSlipByTime/utils/constants";
import { PDComponent } from "components/PDComponents";

export default function Summary({
  summaries,
  isDetailed,
}: {
  isDetailed: boolean;
  summaries: {
    title: string;
    unit?: string;
    on?: string;
    off?: string;
    percentageDifference?: string;
  }[];
}) {
  const leftColumnWidth = isDetailed
    ? LEFT_COLUMN_WIDTH_DETAILED
    : LEFT_COLUMN_WIDTH;

  return (
    <Styled.Summary>
      <Styled.SummaryTitle $isDetailed={isDetailed}>
        <Title level={3}>
          <strong>Summary</strong>
        </Title>
      </Styled.SummaryTitle>
      <Styled.SummaryDetails $leftColumnWidth={leftColumnWidth}>
        {summaries.map((summary) => (
          <Styled.SummaryColumn key={summary.title}>
            <Styled.SummaryItemTitle>
              <Title level={isDetailed ? 4 : 5}>
                <strong> {summary.title}</strong>
              </Title>
              {summary.unit ? (
                <Title level={isDetailed ? 4 : 5} variant="faded">
                  ({summary.unit})
                </Title>
              ) : null}
            </Styled.SummaryItemTitle>
            <Styled.SummaryInfo>
              <Styled.SummaryData>
                <PDComponent.SvgIcon
                  name="zTorqueIndicatorOn"
                  width="12"
                  height="12"
                  color="white"
                />
                <Title level={isDetailed ? 4 : 5}>
                  <strong> {summary.on}</strong>
                </Title>
              </Styled.SummaryData>
              <Styled.SummaryData>
                <PDComponent.SvgIcon
                  name="zTorqueIndicatorOff"
                  width="12"
                  height="12"
                  color="white"
                />
                <Title level={isDetailed ? 4 : 5}>
                  <strong> {summary.off}</strong>
                </Title>
              </Styled.SummaryData>
              {summary.percentageDifference ? (
                <Styled.SummaryData>
                  <PDComponent.SvgIcon
                    name="percentageDifference"
                    width="12"
                    height="12"
                    color="white"
                  />
                  <Title level={isDetailed ? 4 : 5} variant="faded">
                    {summary.percentageDifference}
                  </Title>
                </Styled.SummaryData>
              ) : null}
            </Styled.SummaryInfo>
          </Styled.SummaryColumn>
        ))}
      </Styled.SummaryDetails>
    </Styled.Summary>
  );
}
