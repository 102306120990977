import {
  FormGroupContainer,
  FormLabel,
} from "components/Lenses/ContainerLens/ParameterHeatmapKpi/style";
import React from "react";
import { Col, Row } from "utils/componentLibrary";

export const FormGroup: React.FC<{
  label: React.ReactNode;
  rightElement?: React.ReactNode;
  children?: React.ReactNode;
}> = ({ label, rightElement, children }) => {
  return (
    <FormGroupContainer>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "12px" }}
      >
        <Col>
          {typeof label === "string" ? <FormLabel>{label}</FormLabel> : label}
        </Col>
        {rightElement ? <Col>{rightElement}</Col> : null}
      </Row>
      <Row>{children}</Row>
    </FormGroupContainer>
  );
};
