import { AxisBottom, AxisLeft } from "@visx/axis";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { Bar, Line, LinePath } from "@visx/shape";
import { Text as VisXText } from "@visx/text";
import type { InspectionTvdPointDto } from "apis/oag";
import { DimensionType, ResultDataState, TorqueAndDragType } from "apis/oag";
import { Text } from "atoms/Typography";
import { AverageLine } from "components/Lenses/common/AverageLine";
import {
  Indicator,
  StyledTooltipContainer,
} from "components/Lenses/common/InspectionView/InspectionChart/style";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { useInspectionPoint } from "components/Lenses/ContainerLens/TorqueAndDrag/fetcher";
import type { InspectionViewProps } from "components/Lenses/ContainerLens/TorqueAndDrag/Inspection/types";
import {
  ColLine,
  LineLegend,
} from "components/Lenses/ContainerLens/TorqueAndDrag/style";
import {
  StyledBottomAxisDiv,
  StyledChartBackground,
  StyledChartCol,
  StyledDetailsCol,
  StyledDistanceDuration,
  StyledFirstYAxisDiv,
  StyledRightContainerDiv,
  StyledRow,
  StyledSecondYAxisDiv,
  StyledSvg,
  StyledTitle,
  StyledXAxisLabelContainer,
} from "components/Lenses/ContainerLens/TorqueAndDrag/styledComponents";
import {
  activityCircleColors,
  CHART_CONTAINER_X,
  CHART_PADDING_TOP,
  GRAPH_CONTAINER_HEIGHT_PX,
  kpiToLabel,
  MODAL_HEIGHT_PX,
  STATUS_BAR_HEIGHT,
  toNormalCase,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from "components/Lenses/ContainerLens/TorqueAndDrag/utils";
import { curveMonotoneX } from "d3";
import { bisector, max, min } from "d3-array";
import dayjs from "dayjs";
import useDiscontinuousTimeAxis from "hooks/charting/useDiscontinuousTimeAxis";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import colors from "utils/colors";
import { defaultDateDto } from "utils/common";
import { Row } from "utils/componentLibrary";
import {
  ALTERNATIVE_DATE_FORMAT,
  getFractionDigits,
  useUOM,
} from "utils/format";
import { formatTime, toLocalWellTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

const BlockHeightLabel = "Block Height";
interface LegendItem {
  id: number;
  label: string;
  color: string;
  isVisible: boolean;
}

interface TooltipData {
  chartValues: { time: number; kpi: number; blockHeight: number };
  values: { time: number; kpi: number; blockHeight: number };
}

export const InspectionViewInner: React.FC<InspectionViewProps> = ({
  lensId,
  selectedPoint,
  valueUOM,
  kpiType,
}) => {
  // Hack to force query refresh until the source for svg text scaling is found
  const [mountKey, setMountKey] = useState("");
  useLayoutEffect(() => setMountKey("" + Math.random()), []);
  const { data } = useInspectionPoint(selectedPoint?.id, lensId, mountKey, {
    enabled: !!lensId && !!selectedPoint?.id,
  });

  const svgRef = useRef<SVGSVGElement>(null);
  const tooltipContainerRef = useRef(null);
  const mainActivityText = toNormalCase(data?.activity || "");
  const distanceTransformer = useUOM(DimensionType.Metres);
  const timeTransformer = useUOM(DimensionType.Seconds);

  const kpiLabel = kpiToLabel(kpiType);

  const [isPointerInsideChart, setPointerInsideChart] = useState(false);
  const [legendItems, _setLegendItems] = useState<LegendItem[]>([
    {
      id: 1,
      label: kpiLabel,
      color:
        activityCircleColors[
          data?.activity as keyof typeof activityCircleColors
        ] || "",
      isVisible: true,
    },
    {
      id: 2,
      label: BlockHeightLabel,
      color: colors.gray_cta as keyof typeof activityCircleColors,
      isVisible: true,
    },
  ]);

  useEffect(() => {
    _setLegendItems([
      {
        id: 1,
        label: kpiLabel,
        color:
          activityCircleColors[
            data?.activity as keyof typeof activityCircleColors
          ] || "",
        isVisible: true,
      },
      {
        id: 2,
        label: BlockHeightLabel,
        color: colors.gray_cta as keyof typeof activityCircleColors,
        isVisible: true,
      },
    ]);
  }, [data, kpiLabel]);

  const toggleLegendItemVisibility = useCallback((clickedItem: LegendItem) => {
    _setLegendItems((legendItems) =>
      (legendItems ?? []).map((item) => {
        return item.id === clickedItem.id
          ? {
              ...item,
              isVisible: !item.isVisible,
            }
          : item;
      }),
    );
  }, []);

  const getPointValueByKpi = useCallback(
    (point: InspectionTvdPointDto) => {
      return (
        (kpiType === TorqueAndDragType.Torque
          ? point.torque
          : point.hookLoad) || 0
      );
    },
    [kpiType],
  );

  const kpiValueScale = useMemo(() => {
    const [minValue, maxValue] = [
      min((data?.series ?? []).map(getPointValueByKpi)) || 0,
      max((data?.series ?? []).map(getPointValueByKpi)) || 0,
    ];

    return scaleLinear<number>({
      domain: [maxValue, minValue],
      range: [0, GRAPH_CONTAINER_HEIGHT_PX],
    });
  }, [data?.series, getPointValueByKpi]);

  const blockHeightValueScale = useMemo(() => {
    const [minValue = 0, maxValue = 0] = [
      min(
        (data?.series ?? []).map((item) =>
          distanceTransformer.fromSI(item.blockHeight || 0),
        ),
      ) || 0,
      max(
        (data?.series ?? []).map((item) =>
          distanceTransformer.fromSI(item.blockHeight || 0),
        ),
      ) || 0,
    ];

    return scaleLinear<number>({
      domain: [maxValue, minValue],
      range: [0, GRAPH_CONTAINER_HEIGHT_PX],
    });
  }, [data?.series, distanceTransformer]);

  const timeValueScale = useMemo(() => {
    const [min, max] = [toLocalWellTime(data.from), toLocalWellTime(data.to)];

    return scaleLinear<number>({
      domain: [min, max],
      range: [0, CHART_CONTAINER_X - 2 * Y_AXIS_WIDTH],
      clamp: true,
    });
  }, [data?.from, data?.to]);

  const stateGuideBars = useMemo(() => {
    return [
      {
        from: toLocalWellTime(data.from),
        to: toLocalWellTime(data.to),
        isLight: true,
      },
      {
        from: toLocalWellTime(data?.sourcePoint.startDate),
        to: toLocalWellTime(data?.sourcePoint.endDate),
        isLight: false,
      },
    ];
  }, [data]);

  const getKpiDefined = (point: InspectionTvdPointDto) => {
    const kpiValue = getPointValueByKpi(point);
    return (
      kpiValue >= kpiValueScale.domain()[1] - 5 &&
      kpiValue <= kpiValueScale.domain()[0] + 5
    );
  };

  const [pointerPosition, setPointerPosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });

  const handlePointerMove = useCallback(
    (event: React.PointerEvent<SVGSVGElement>) => {
      const { left, top } = event.currentTarget.getBoundingClientRect();
      const x = ("clientX" in event ? event.clientX : 0) - left;
      const y = ("clientY" in event ? event.clientY : 0) - top;
      setPointerPosition({ x, y });
    },
    [],
  );

  const {
    themeStyle: { colors: themeColors },
    atomThemeVariant,
  } = useCustomTheme();

  const { xScaleDate, chunksCount, getAverageChunkDate } =
    useDiscontinuousTimeAxis({
      plotWidth: CHART_CONTAINER_X - Y_AXIS_WIDTH * 2,
      xScaleDomain: [0, (data?.series ?? []).length - 1],
      series: data.series ?? [],
      overrideAxisLabelSize: 100,
    });

  const tooltipInfo: TooltipData | null = useMemo(() => {
    const x0 = timeValueScale.invert(pointerPosition.x - Y_AXIS_WIDTH * 2);

    let selectedPoint: InspectionTvdPointDto | null = null;
    const index = bisector<InspectionTvdPointDto, number>((p) =>
      toLocalWellTime(p.at),
    ).left(data?.series ?? [], x0);

    if (index > 0 && data?.series?.[index]?.at) {
      const p0 = data?.series[index - 1];
      const p1 = data?.series[index];
      selectedPoint = p0?.at?.utc ? p0 : p1;
    }

    if (!selectedPoint) {
      return null;
    }

    const timeValue = toLocalWellTime(selectedPoint.at) ?? 0;
    const chartTimeValue = timeValueScale(timeValue);

    const kpiValue = getPointValueByKpi(selectedPoint);
    const chartKpiValue =
      kpiValueScale(kpiValue) + STATUS_BAR_HEIGHT + CHART_PADDING_TOP;

    const blockHeightValue = distanceTransformer.fromSI(
      selectedPoint.blockHeight || 0,
    );
    const chartBlockHeightValue =
      blockHeightValueScale(blockHeightValue) +
      STATUS_BAR_HEIGHT +
      CHART_PADDING_TOP;

    return {
      chartValues: {
        time: chartTimeValue,
        kpi: chartKpiValue,
        blockHeight: chartBlockHeightValue,
      },
      values: { time: timeValue, kpi: kpiValue, blockHeight: blockHeightValue },
    };
  }, [
    blockHeightValueScale,
    data?.series,
    distanceTransformer,
    getPointValueByKpi,
    kpiValueScale,
    pointerPosition.x,
    timeValueScale,
  ]);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip({
    containerRef: tooltipContainerRef,
    renderContent: ({ tooltipData }: { tooltipData?: TooltipData }) => {
      return (
        <StyledTooltipContainer>
          <span>
            <span style={{ fontWeight: "bold", fontSize: 12 }}>
              <div>{mainActivityText}</div>
              <div>
                {valueUOM.display(data.sourcePoint?.measure, {
                  fractionDigits: getFractionDigits(
                    valueUOM.fromSI(data.sourcePoint?.measure),
                  ),
                  unit: valueUOM.abbr,
                })}
              </div>
            </span>
          </span>
          {legendItems[0].isVisible ? (
            <span>
              <span>
                <div>
                  <Indicator color={legendItems[0].color} />
                  {legendItems[0].label}
                </div>
                <div>
                  {valueUOM.display(tooltipData?.values.kpi, {
                    fractionDigits: getFractionDigits(
                      valueUOM.fromSI(tooltipData?.values.kpi || 0),
                    ),
                    unit: valueUOM.abbr,
                  })}
                </div>
              </span>
            </span>
          ) : null}
          {legendItems[1].isVisible ? (
            <span>
              <span>
                <div>
                  <Indicator color={legendItems[1].color} />
                  {legendItems[1].label}
                </div>
                <div>
                  {distanceTransformer.display(tooltipData?.values.blockHeight)}
                </div>
              </span>
            </span>
          ) : null}

          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{dayjs(tooltipData?.values.time).format("MM/DD/YYYY")}</div>
            <div>{dayjs(tooltipData?.values.time).format("HH:mm:ss")}</div>
          </div>
        </StyledTooltipContainer>
      );
    },
  });

  useEffect(() => {
    isPointerInsideChart && tooltipInfo
      ? showTooltip({
          tooltipLeft: tooltipInfo?.chartValues.time || 0,
          tooltipTop: 175,
          tooltipData: tooltipInfo,
        })
      : hideTooltip();
  }, [
    hideTooltip,
    isPointerInsideChart,
    pointerPosition,
    showTooltip,
    timeValueScale,
    tooltipInfo,
  ]);

  return data?.dataState === ResultDataState.Valid ? (
    <StyledRow wrap={false}>
      <StyledDetailsCol>
        <div>
          <Text variant={atomThemeVariant} primary="description">
            <b>{mainActivityText}</b>
          </Text>
          <br />
          <Text variant={atomThemeVariant} primary="caption">
            {valueUOM.display(data.sourcePoint?.measure, {
              fractionDigits: getFractionDigits(
                valueUOM.fromSI(data.sourcePoint?.measure),
              ),
              unit: valueUOM.abbr,
            })}
          </Text>

          <StyledDistanceDuration>
            <Text variant={atomThemeVariant} primary="caption">
              Distance:{" "}
              {distanceTransformer.display(data?.sourcePoint.distance, {
                unit: distanceTransformer.abbr,
              })}
            </Text>
            <br />
            <Text variant={atomThemeVariant} primary="caption">
              {/* // TODO check if this is correct */}
              Duration:{" "}
              {timeTransformer.display(data?.sourcePoint.duration, {
                unit: "min",
              })}
            </Text>
          </StyledDistanceDuration>
        </div>

        <div>
          {legendItems.map((item) => (
            <Row onClick={() => toggleLegendItemVisibility(item)} key={item.id}>
              <ColLine>
                <LineLegend backgroundColor={item.color} />
                <StyledTitle
                  variant={
                    item.isVisible
                      ? "torque_drag_legend_on"
                      : "torque_drag_legend_off"
                  }
                  level={5}
                  $lineHeight="1.75em"
                >
                  {item.label}
                </StyledTitle>
              </ColLine>
            </Row>
          ))}
        </div>
      </StyledDetailsCol>

      <StyledChartCol>
        <StyledRightContainerDiv ref={tooltipContainerRef}>
          {/* Left AXES */}
          <StyledFirstYAxisDiv />
          <StyledSecondYAxisDiv />
          <StyledChartBackground />

          <StyledSvg
            width={CHART_CONTAINER_X}
            height={MODAL_HEIGHT_PX}
            onPointerMove={handlePointerMove}
            onMouseLeave={() => setPointerInsideChart(false)}
            onMouseEnter={() => setPointerInsideChart(true)}
            onMouseMove={() => setPointerInsideChart(true)}
            ref={svgRef}
          >
            {legendItems.some((x) => x.isVisible) ? tooltipElement : null}
            <AxisLeft
              hideTicks
              hideAxisLine
              scale={kpiValueScale}
              top={CHART_PADDING_TOP + STATUS_BAR_HEIGHT}
              left={12}
              numTicks={5}
              tickComponent={(props) => (
                <Group>
                  <text
                    x={props.x}
                    y={props.y}
                    fontSize={12}
                    fill={legendItems[0].color}
                    pointerEvents="none"
                  >
                    {valueUOM.display(
                      Number.parseFloat(
                        (props.formattedValue || "").replaceAll(",", ""),
                      ),
                      {
                        fractionDigits: getFractionDigits(
                          valueUOM.fromSI(kpiValueScale.domain()[0]),
                        ),
                        unit: "",
                      },
                    )}
                  </text>
                </Group>
              )}
            />
            <AxisLeft
              hideTicks
              hideAxisLine
              scale={blockHeightValueScale}
              top={CHART_PADDING_TOP + STATUS_BAR_HEIGHT}
              left={Y_AXIS_WIDTH + 12}
              numTicks={5}
              tickComponent={(props) => (
                <Group>
                  <text
                    x={props.x}
                    y={props.y}
                    fontSize={12}
                    fill={legendItems[1].color}
                    pointerEvents="none"
                  >
                    {distanceTransformer.display(
                      Number.parseFloat(
                        (props.formattedValue || "").replaceAll(",", ""),
                      ),
                      {
                        fractionDigits: 2,
                        unit: "",
                      },
                    )}
                  </text>
                </Group>
              )}
            />
            <AxisBottom
              tickLength={1}
              hideAxisLine
              tickStroke={themeColors.disabled_typography}
              stroke={themeColors.disabled_typography}
              scale={xScaleDate}
              numTicks={chunksCount}
              left={Y_AXIS_WIDTH * 2}
              top={MODAL_HEIGHT_PX - X_AXIS_HEIGHT}
              tickFormat={(value) =>
                formatTime(getAverageChunkDate(value) || defaultDateDto.from, {
                  formatStr: ALTERNATIVE_DATE_FORMAT,
                })
              }
              tickComponent={(props) => (
                <Group>
                  <VisXText
                    x={props.x}
                    y={props.y}
                    dy={12}
                    dx={-36}
                    fontSize={12}
                    fill={themeColors.disabled_typography}
                    pointerEvents="none"
                  >
                    {props.formattedValue}
                  </VisXText>
                </Group>
              )}
            />
            <Group>
              {stateGuideBars.map((bar, i) => {
                return (
                  <Bar
                    key={`bar-${i}`}
                    x={2 * Y_AXIS_WIDTH + timeValueScale(bar.from)}
                    width={timeValueScale(bar.to) - timeValueScale(bar.from)}
                    height={STATUS_BAR_HEIGHT}
                    opacity={bar.isLight ? 0.3 : 1}
                    fill={legendItems[0].color}
                  />
                );
              })}
            </Group>
            <LinePath
              curve={curveMonotoneX}
              data={data?.series ?? []}
              dx={Y_AXIS_WIDTH * 2}
              defined={getKpiDefined}
              strokeWidth={2}
              x={(point) =>
                timeValueScale(toLocalWellTime(point.at)) + 2 * Y_AXIS_WIDTH
              }
              y={(point) =>
                Number.isNaN(kpiValueScale(getPointValueByKpi(point)))
                  ? 0
                  : kpiValueScale(getPointValueByKpi(point)) +
                    STATUS_BAR_HEIGHT +
                    CHART_PADDING_TOP
              }
              stroke={
                legendItems[0].isVisible ? legendItems[0].color : "transparent"
              }
            />
            <LinePath
              curve={curveMonotoneX}
              data={data?.series ?? []}
              dx={Y_AXIS_WIDTH * 2}
              defined={(p) =>
                distanceTransformer.fromSI(p.blockHeight || 0) >=
                  blockHeightValueScale.domain()[1] &&
                distanceTransformer.fromSI(p.blockHeight || 0) <=
                  blockHeightValueScale.domain()[0]
              }
              strokeWidth={0.7}
              x={(point) =>
                timeValueScale(toLocalWellTime(point.at)) + 2 * Y_AXIS_WIDTH
              }
              y={(point) =>
                Number.isNaN(
                  blockHeightValueScale(
                    distanceTransformer.fromSI(point.blockHeight || 0),
                  ),
                )
                  ? 0
                  : blockHeightValueScale(
                      distanceTransformer.fromSI(point.blockHeight || 0),
                    ) +
                    STATUS_BAR_HEIGHT +
                    CHART_PADDING_TOP
              }
              stroke={
                legendItems[1].isVisible ? legendItems[1].color : "transparent"
              }
            />

            {legendItems[0].isVisible ? (
              <AverageLine
                isVisible={true}
                x={2 * Y_AXIS_WIDTH + timeValueScale(stateGuideBars[1].from)}
                width={
                  timeValueScale(stateGuideBars[1].to) -
                  timeValueScale(stateGuideBars[1].from)
                }
                y={
                  kpiValueScale(data?.sourcePoint.measure) +
                  STATUS_BAR_HEIGHT +
                  CHART_PADDING_TOP
                }
              />
            ) : null}
            {tooltipInfo &&
            Number.isFinite(tooltipInfo.chartValues.time) &&
            isPointerInsideChart &&
            legendItems.some((x) => x.isVisible) ? (
              <Group cursor="pointer">
                <Line
                  type="vertical"
                  from={{
                    x: tooltipInfo.chartValues.time + 2 * Y_AXIS_WIDTH,
                    y: MODAL_HEIGHT_PX - X_AXIS_HEIGHT,
                  }}
                  to={{
                    x: tooltipInfo.chartValues.time + 2 * Y_AXIS_WIDTH,
                    y: STATUS_BAR_HEIGHT,
                  }}
                  strokeWidth={0.5}
                  stroke={themeColors.primary_typography}
                />
                {legendItems.find((item) => item.label === BlockHeightLabel)
                  ?.isVisible ? (
                  <>
                    <circle
                      cx={tooltipInfo.chartValues.time + 2 * Y_AXIS_WIDTH}
                      cy={tooltipInfo.chartValues.blockHeight}
                      r={4}
                      fill={
                        legendItems.find(
                          (item) => item.label === BlockHeightLabel,
                        )?.color
                      }
                    />
                    <circle
                      cx={tooltipInfo.chartValues.time + 2 * Y_AXIS_WIDTH}
                      cy={tooltipInfo.chartValues.blockHeight}
                      r={2}
                      fill="white"
                    />
                  </>
                ) : null}
                {legendItems.find((item) => item.label === kpiLabel)
                  ?.isVisible ? (
                  <>
                    <circle
                      fill={
                        legendItems.find((item) => item.label === kpiLabel)
                          ?.color
                      }
                      cx={tooltipInfo.chartValues.time + 2 * Y_AXIS_WIDTH}
                      cy={tooltipInfo.chartValues.kpi}
                      r={4}
                    />
                    <circle
                      fill="white"
                      cx={tooltipInfo.chartValues.time + 2 * Y_AXIS_WIDTH}
                      cy={tooltipInfo.chartValues.kpi}
                      r={2}
                    />{" "}
                  </>
                ) : null}
              </Group>
            ) : null}
          </StyledSvg>
        </StyledRightContainerDiv>
        <StyledBottomAxisDiv>
          <StyledXAxisLabelContainer> Date time </StyledXAxisLabelContainer>
        </StyledBottomAxisDiv>
      </StyledChartCol>
    </StyledRow>
  ) : null;
};
