import type {
  ActualTimelineDto,
  ActualTimelineEventDto,
  DateDto,
  PlanTimelineDto,
  PlanTimelineEventDto,
  WellDto,
} from "apis/oag";
import { isSameEvent } from "components/Timeline/utils";
import type { ICombinedEvents } from "components/TvDChart/types";
import type { ExtendedEventType, IAction } from "reducers/types";

/**
 * This reducer holds and controls the data for Events Timeline
 * TODO this reducer does not really make sense rewrite it when rewriting the TvD
 */
const MAX_DISTANCE_NORMAL_ZOOM = 150;
export interface ISingleEvent extends Omit<ActualTimelineEventDto, "type"> {
  id: number;
  timestamp: DateDto;
  timestamp_end?: number;
  depth?: number;
  type: ExtendedEventType;
  title?: string;
  description?: string;
}

interface IDataState {
  planId: number | null;
  currentWellDetails: WellDto | null;
  addingElement: ExtendedEventType | null;
  events: {
    actualEvents: ActualTimelineDto | null;
    planEvents: PlanTimelineDto | null;
  };
  actionEnabled: boolean;
  override: boolean;
  maxDistance: number;
  position: {
    visible: boolean;
    target: ISingleEvent | null;
    position: number; // within the event
    timestamp: number;
    selectedDepth: number;
  };
  scrollToLegendSeries: number | null;
  selectedTimelineEvent: number | null;
}

const initialState: IDataState = {
  planId: null,
  currentWellDetails: null,
  addingElement: null,
  events: {
    actualEvents: null,
    planEvents: null,
  },
  actionEnabled: false,
  override: false,
  maxDistance: MAX_DISTANCE_NORMAL_ZOOM,
  position: {
    visible: false,
    target: null,
    position: 0,
    timestamp: 0,
    selectedDepth: 0,
  },
  scrollToLegendSeries: null,
  selectedTimelineEvent: null,
};

interface ISetCurrentWellDetails extends IAction {
  type: "SET_WELL_DETAILS";
  payload: WellDto;
}
interface ISetAddingElement extends IAction {
  type: "SET_ADDING_ELEMENT";
  payload: ExtendedEventType;
}
interface ISetMaxDistance extends IAction {
  type: "SET_MAX_DISTANCE";
  payload: number;
}

interface ISetPlanId extends IAction {
  type: "SET_PLAN_ID";
  payload: number;
}

interface ISetEvents extends IAction {
  type: "SET_EVENTS";
  payload: IDataState["events"];
}

interface IAddActualTimeline extends IAction {
  type: "ADD_ACTUAL_EVENT";
  payload: ActualTimelineEventDto;
}
interface IAddPlanTimeline extends IAction {
  type: "ADD_PLAN_EVENT";
  payload: PlanTimelineEventDto;
}
interface IEditActualTimeline extends IAction {
  type: "EDIT_ACTUAL_EVENT";
  payload: ActualTimelineEventDto;
}
interface IEditPlanTimeline extends IAction {
  type: "EDIT_PLAN_EVENT";
  payload: PlanTimelineEventDto;
}
interface IDeleteOrRestoreActualTimeline extends IAction {
  type: "DELETE_OR_RESTORE_ACTUAL_EVENT";
  payload: { event: ActualTimelineEventDto; restore?: boolean };
}
interface IDeleteOrRestorePlanTimeline extends IAction {
  type: "DELETE_OR_RESTORE_PLAN_EVENT";
  payload: { event: PlanTimelineEventDto; restore?: boolean };
}

interface ISimpleDeleteActualTimeline extends IAction {
  type: "SIMPLE_DELETE_ACTUAL_EVENT";
  payload: ActualTimelineEventDto;
}
interface ISimpleDeletePlanTimeline extends IAction {
  type: "SIMPLE_DELETE_PLAN_EVENT";
  payload: PlanTimelineEventDto;
}
interface ISetActionEnabled extends IAction {
  type: "SET_ACTION_ENABLED";
  payload: boolean;
}

interface ISetActionOverride extends IAction {
  type: "SET_OVERRIDE";
  payload: IDataState["override"];
}

interface ISetPosition extends IAction {
  type: "SET_POSITION";
  payload: Omit<IDataState["position"], "visible">;
}

interface ISetSelectedTimelineEvent extends IAction {
  type: "SET_SELECTED_TIMELINE_EVENT";
  payload: number | null;
}

interface ISetVisibility extends IAction {
  type: "SET_VISIBILITY";
  payload: Omit<IDataState["position"], "target, position">;
}

interface ISetOverrideAndPosition extends IAction {
  type: "SET_OVERRIDE_AND_POSITION";
  payload: {
    override: IDataState["override"];
    position: Omit<IDataState["position"], "visible">;
  };
}

interface ISetScrollToLegendSeries extends IAction {
  type: "SET_SCROLL_TO_LEGEND_SERIES";
  payload: number;
}

type AvailableActions =
  | ISetAddingElement
  | ISetCurrentWellDetails
  | ISetMaxDistance
  | ISetPlanId
  | ISetEvents
  | ISetActionEnabled
  | ISetActionOverride
  | ISetPosition
  | ISetVisibility
  | IAddPlanTimeline
  | IAddActualTimeline
  | IEditActualTimeline
  | IEditPlanTimeline
  | IDeleteOrRestoreActualTimeline
  | IDeleteOrRestorePlanTimeline
  | ISimpleDeleteActualTimeline
  | ISimpleDeletePlanTimeline
  | ISetOverrideAndPosition
  | ISetScrollToLegendSeries
  | ISetSelectedTimelineEvent;

function timelineReducer(
  state: IDataState = initialState,
  action: AvailableActions,
): IDataState {
  switch (action.type) {
    // Update the events list if the endpoint of the series is changing
    case "SET_WELL_DETAILS":
      return { ...state, currentWellDetails: action.payload };
    case "SET_SCROLL_TO_LEGEND_SERIES":
      return { ...state, scrollToLegendSeries: action.payload };
    case "SET_ADDING_ELEMENT":
      return {
        ...state,
        addingElement: action.payload,
      };
    case "SET_MAX_DISTANCE":
      return {
        ...state,
        maxDistance: action.payload,
      };
    case "SET_PLAN_ID":
      return {
        ...state,
        planId: action.payload,
      };
    case "SET_SELECTED_TIMELINE_EVENT":
      return {
        ...state,
        selectedTimelineEvent: action.payload,
      };
    case "SET_EVENTS":
      return {
        ...state,
        events: action.payload,
      };
    case "ADD_ACTUAL_EVENT":
      const actualEventsReturnState = state;
      if (!actualEventsReturnState.events?.actualEvents?.events)
        actualEventsReturnState.events.actualEvents = {};
      actualEventsReturnState.events.actualEvents.events = [
        ...(actualEventsReturnState.events.actualEvents.events ?? []),
        action.payload,
      ];
      return actualEventsReturnState;
    case "ADD_PLAN_EVENT":
      const planEventsReturnState = state;
      if (!planEventsReturnState.events?.planEvents?.events)
        planEventsReturnState.events.planEvents = {};
      planEventsReturnState.events.planEvents.events = [
        ...(planEventsReturnState.events.planEvents.events ?? []),
        action.payload,
      ];
      return planEventsReturnState;
    case "DELETE_OR_RESTORE_ACTUAL_EVENT":
      const { event: eventActual, restore: restoreActual } = action.payload;
      const deleteActualEventsReturnState = state;
      if (!deleteActualEventsReturnState.events?.actualEvents?.events)
        deleteActualEventsReturnState.events.actualEvents = {};
      deleteActualEventsReturnState.events.actualEvents.events = [
        ...(deleteActualEventsReturnState.events.actualEvents.events || []).map(
          (oldEvent) =>
            isSameEvent({
              event: oldEvent as ICombinedEvents,
              eventCmp: eventActual as ICombinedEvents,
            })
              ? {
                  ...oldEvent,
                  deletedAtUtc: restoreActual ? null : new Date(),
                }
              : oldEvent,
        ),
      ];
      return deleteActualEventsReturnState;
    case "DELETE_OR_RESTORE_PLAN_EVENT":
      const { event: eventPlan, restore: restorePlan } = action.payload;
      const deletePlanEventsReturnState = state;
      if (!deletePlanEventsReturnState.events?.planEvents?.events)
        deletePlanEventsReturnState.events.planEvents = {};
      deletePlanEventsReturnState.events.planEvents.events = [
        ...(deletePlanEventsReturnState.events.planEvents.events || []).map(
          (oldEvent) =>
            isSameEvent({
              event: oldEvent as ICombinedEvents,
              eventCmp: eventPlan as ICombinedEvents,
            })
              ? {
                  ...oldEvent,
                  deletedAtUtc: restorePlan ? null : new Date(),
                }
              : oldEvent,
        ),
      ];
      return deletePlanEventsReturnState;
    case "SIMPLE_DELETE_ACTUAL_EVENT":
      return {
        ...state,
        events: {
          ...state.events,
          actualEvents: {
            ...state.events.actualEvents,
            events: (state?.events?.actualEvents?.events ?? []).filter(
              (e) =>
                !isSameEvent({
                  event: e as ICombinedEvents,
                  eventCmp: action.payload as ICombinedEvents,
                }),
            ),
          },
        },
      };
    case "SIMPLE_DELETE_PLAN_EVENT":
      return {
        ...state,
        events: {
          ...state.events,
          planEvents: {
            ...state.events.planEvents,
            events: (state?.events?.planEvents?.events ?? []).filter(
              (e) =>
                !isSameEvent({
                  event: e as ICombinedEvents,
                  eventCmp: action.payload as ICombinedEvents,
                }),
            ),
          },
        },
      };
    case "EDIT_ACTUAL_EVENT":
      const editedActualEventsReturnState = state;
      if (!editedActualEventsReturnState.events?.actualEvents?.events)
        editedActualEventsReturnState.events.actualEvents = {};
      editedActualEventsReturnState.events.actualEvents.events = [
        ...(editedActualEventsReturnState.events.actualEvents.events || []).map(
          (oldEvent) =>
            isSameEvent({
              event: oldEvent as ICombinedEvents,
              eventCmp: action.payload as ICombinedEvents,
            })
              ? {
                  ...oldEvent,
                  ...action.payload,
                }
              : oldEvent,
        ),
      ];
      return editedActualEventsReturnState;
    case "EDIT_PLAN_EVENT":
      const editedPlanEventsReturnState = state;
      if (!editedPlanEventsReturnState.events?.planEvents?.events)
        editedPlanEventsReturnState.events.planEvents = {};
      editedPlanEventsReturnState.events.planEvents.events = [
        ...(editedPlanEventsReturnState.events.planEvents.events || []).map(
          (oldEvent) =>
            isSameEvent({
              event: oldEvent as ICombinedEvents,
              eventCmp: action.payload as ICombinedEvents,
            })
              ? {
                  ...oldEvent,
                  ...action.payload,
                }
              : oldEvent,
        ),
      ];
      return editedPlanEventsReturnState;

    case "SET_ACTION_ENABLED":
      return {
        ...state,
        actionEnabled: action.payload,
      };
    case "SET_OVERRIDE":
      return {
        ...state,
        override: action.payload,
      };
    case "SET_POSITION":
      return {
        ...state,
        position: {
          ...state.position,
          ...action.payload,
        },
      };
    case "SET_VISIBILITY":
      return {
        ...state,
        position: {
          ...state.position,
          ...action.payload,
        },
      };
    case "SET_OVERRIDE_AND_POSITION":
      return {
        ...state,
        override: action.payload.override,
        position: {
          ...state.position,
          ...action.payload.position,
        },
      };
    default:
      return state;
  }
}

export default timelineReducer;
