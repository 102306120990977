import { TooltipWithBounds } from "@visx/tooltip";
import {
  getParameterAxisWidthStickSlip,
  getParameterNameWidthStickSlip,
  getParameterStatsWidthStickSlip,
} from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/utils";
import { PDComponent } from "components/PDComponents";
import type { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { zIndexLayer } from "utils/zIndex";

export const InnerDiv = styled.div<{ detailed?: boolean; showGraph?: boolean }>`
  overflow: hidden;
  display: grid;
  grid-template-columns: ${(props) =>
      getParameterNameWidthStickSlip(!!props?.detailed)}px ${(props) => {
      if (!props.showGraph) {
        return "1fr";
      } else {
        return `${getParameterStatsWidthStickSlip(!!props?.detailed)}px
         ${getParameterAxisWidthStickSlip(!!props?.detailed)}px
        1fr 
       `;
      }
    }};
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_chart_bg};
  border-bottom: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
  height: 100%;
`;
export const ParameterComparisonContainer = styled.div``;

export const IconContainer = styled.div<{ cursor?: CSSProperties["cursor"] }>`
  font-size: 20px;
  color: #8d9599;
  cursor: ${(props) => props.cursor || "pointer"};
  z-index: ${zIndexLayer.base};

  svg {
    transform: rotate(90deg);
  }
`;

export const InfoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: center;
  gap: 4px;
  text-align: center;
  overflow: hidden;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(-180deg);
`;

export const IconsContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  visibility: hidden;
  z-index: ${zIndexLayer.above};
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 1;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
`;

export const ParameterAvgKpiVal = styled.div<{
  detailed?: boolean;
  parameterNameWidth?: string;
  $isSingle?: boolean;
}>`
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  height: 100%;
  width: ${(props) =>
    props.parameterNameWidth ||
    `${getParameterStatsWidthStickSlip(!!props?.detailed)}px`};
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ $isSingle }) => ($isSingle ? "center" : "flex-start")};
  border-right: 1px solid rgba(0, 0, 0, 0.06);

  span {
    font-size: ${(props) => (props.detailed ? "34px" : "24px")};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
  }
`;

export const ParameterComparisonGrid = styled.div<{ rowCount: number }>`
  display: grid;
  grid-template-rows: repeat(${(props) => props.rowCount}, 1fr);
  row-gap: 8px;
`;

export const ParameterComparisonGridItem = styled.div<{
  isFocalWell?: boolean;
}>`
  display: flex;
  column-gap: 5px;

  & > div {
    height: 16px;
  }
  &,
  & > span {
    align-self: center;
    font-size: 14px;
  }
  font-weight: ${(props) => (props.isFocalWell ? 500 : "normal")};
  font-stretch: normal;
  font-style: normal;

  letter-spacing: -0.2px;
  color: ${({ isFocalWell, theme }) =>
    isFocalWell
      ? theme.themeStyle.colors.alt_typography
      : theme.themeStyle.colors.disabled_typography};
`;

export const BottomRowContainer = styled.div`
  background: ${({ theme }) => theme.themeStyle.colors.tertiary_chart_bg};
  display: grid;
  grid-template-columns: 285px 1fr;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
`;

export const LensContainer = styled.div<{
  rows?: number;
  detailed?: boolean;
  variant: "large" | "regular";
}>`
  height: calc(100% - ${(props) => (props.detailed ? 160 : 80)}px);
  position: relative;
  display: grid;
  grid-template-rows: 44px 1fr;

  ${BottomRowContainer} {
    &,
    & > div {
      &:first-child {
        border-right: 1px solid rgba(0, 0, 0, 0.06);
      }
    }
  }
  ${(props) =>
    props.variant === "large" &&
    `
      ${ParameterComparisonGrid}{
        row-gap: 6px;
      }
      
      ${ParameterComparisonGridItem} {
          font-size:13px;
          & > div {
            height: 10px;
          }

          & > span {
            font-size: 14px;
            line-height: 10px;
            svg {
              width: 10px;
              height: 10px;
              transform: scale(1.5);
            }
          }
      }
    `}
`;

export const ScrollContainer = styled.div<{
  $detailed?: boolean;
  $rows: number;
}>`
  width: 100%;
  height: 100%;
  overflow-y: ${(props) => (props.$detailed ? "auto" : "scroll")};
  overflow-x: hidden;
  display: grid;
  grid-template-rows: repeat(${(props) => props.$rows || 1}, 1fr);
`;

export const ChartContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  user-select: none;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const TooltipBoundsContainer = styled(TooltipWithBounds)`
  background-color: ${({ theme }) =>
    theme.themeStyle.colors.quaterniary_chart_bg};
  border-radius: 6px;
  padding: 10px 16px;
  width: fit-content;
  color: white;
  font-size: 12px;
  pointer-events: none;
  user-select: none;
  z-index: ${zIndexLayer.scraper};
`;

export const TooltipContainer = styled(TooltipBoundsContainer)`
  position: absolute;
  translate: 0 -50%;
  top: 50% !important;
`;

export const TooltipEntry = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 6px;
  }
  & > *:last-child {
    margin-right: 0;
    margin-left: auto;
  }

  & + & {
    margin-top: 10px;
  }
`;

export const TooltipExtra = styled.div`
  display: flex;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  border-top: 1px solid rgba(130, 140, 145, 0.1);
  padding-top: 10px;
  margin-top: 10px;

  & > * {
    margin-right: 12px;
  }

  & > *:last-child {
    margin-right: 0;
    margin-left: auto;
  }
`;

export const TooltipWellColor = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  background: ${(props) => props.color};
  border-radius: 2px;
`;

export const KPIText = styled.div<{ $detailed?: boolean }>`
  font-size: ${({ $detailed }) => ($detailed ? "18px" : "16px")};
  text-align: center;
  word-spacing: 99999px; /// To force break line
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  overflow: hidden;
  max-width: 120px;
  text-overflow: ellipsis;
`;

export const UOMText = styled.div<{ $detailed?: boolean }>`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  white-space: nowrap;
`;

export const ParameterName = styled.div<{
  detailed?: boolean;
  $ignoreHoverEffects?: boolean;
}>`
  position: relative;
  background: rgba(13, 155, 131, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  ${({ $ignoreHoverEffects }) =>
    !$ignoreHoverEffects &&
    css`
      &:hover {
        ${IconsContainer} {
          visibility: visible;
        }

        ${KPIText} {
          display: none;
        }

        ${UOMText} {
          margin-left: 0;
        }
      }
    `}

  ${(props) =>
    props.detailed
      ? css`
          &:hover {
            background-color: ${({ theme }) =>
              theme.themeStyle.colors.primary_chart_bg};
          }
        `
      : css`
          gap: 0px;
          &:hover {
            background-color: ${({ theme }) =>
              theme.themeStyle.colors.primary_chart_bg};
            gap: 5px;
          }
        `}
`;

export const ParameterContainer = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 22px 1fr;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
`;

export const ParameterLeftAxis = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
  svg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 28px;
  }
`;

export const ParameterSlantedName = styled.div`
  position: absolute;
  left: 5px;
  text-orientation: mixed;
  transform: rotate(-180deg);
  height: 100%;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  gap: 2px;
`;

export const ParameterKpiSlantedName = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledLockIcon = styled(PDComponent.SvgIcon).attrs({
  name: "locked",
})`
  display: block;
  line-height: 16px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.themeStyle.colors.black_white};
  transform: rotate(90deg);
`;
