/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  IntelScorecardFactSetDto,
  IntelScorecardUserLensDto,
  WellIntelQueryDto,
} from '../models/index';
import {
    IntelScorecardFactSetDtoFromJSON,
    IntelScorecardFactSetDtoToJSON,
    IntelScorecardUserLensDtoFromJSON,
    IntelScorecardUserLensDtoToJSON,
    WellIntelQueryDtoFromJSON,
    WellIntelQueryDtoToJSON,
} from '../models/index';

export interface ApiIntelScorecardUserLensesIdFactsPutRequest {
    id: number;
    wellIntelQueryDto?: WellIntelQueryDto;
}

export interface ApiIntelScorecardUserLensesIdPutRequest {
    id: number;
    intelScorecardUserLensDto?: IntelScorecardUserLensDto;
}

export interface ApiIntelScorecardUserLensesPostRequest {
    intelScorecardUserLensDto?: IntelScorecardUserLensDto;
}

/**
 * 
 */
export class IntelScorecardUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiIntelScorecardUserLensesIdFactsPutRaw(requestParameters: ApiIntelScorecardUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntelScorecardFactSetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiIntelScorecardUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/IntelScorecardUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WellIntelQueryDtoToJSON(requestParameters['wellIntelQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntelScorecardFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiIntelScorecardUserLensesIdFactsPut(requestParameters: ApiIntelScorecardUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntelScorecardFactSetDto> {
        const response = await this.apiIntelScorecardUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiIntelScorecardUserLensesIdPutRaw(requestParameters: ApiIntelScorecardUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntelScorecardUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiIntelScorecardUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/IntelScorecardUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IntelScorecardUserLensDtoToJSON(requestParameters['intelScorecardUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntelScorecardUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiIntelScorecardUserLensesIdPut(requestParameters: ApiIntelScorecardUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntelScorecardUserLensDto> {
        const response = await this.apiIntelScorecardUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiIntelScorecardUserLensesPostRaw(requestParameters: ApiIntelScorecardUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntelScorecardUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/IntelScorecardUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntelScorecardUserLensDtoToJSON(requestParameters['intelScorecardUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntelScorecardUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiIntelScorecardUserLensesPost(requestParameters: ApiIntelScorecardUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntelScorecardUserLensDto> {
        const response = await this.apiIntelScorecardUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
