/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StandKpiCommentAco
 */
export interface StandKpiCommentAco {
    /**
     * 
     * @type {string}
     * @memberof StandKpiCommentAco
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof StandKpiCommentAco
     */
    commentDateTime: Date;
    /**
     * 
     * @type {string}
     * @memberof StandKpiCommentAco
     */
    authorDisplayName: string | null;
}

/**
 * Check if a given object implements the StandKpiCommentAco interface.
 */
export function instanceOfStandKpiCommentAco(value: object): value is StandKpiCommentAco {
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('commentDateTime' in value) || value['commentDateTime'] === undefined) return false;
    if (!('authorDisplayName' in value) || value['authorDisplayName'] === undefined) return false;
    return true;
}

export function StandKpiCommentAcoFromJSON(json: any): StandKpiCommentAco {
    return StandKpiCommentAcoFromJSONTyped(json, false);
}

export function StandKpiCommentAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiCommentAco {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['Description'],
        'commentDateTime': (new Date(json['CommentDateTime'])),
        'authorDisplayName': json['AuthorDisplayName'],
    };
}

export function StandKpiCommentAcoToJSON(value?: StandKpiCommentAco | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Description': value['description'],
        'CommentDateTime': ((value['commentDateTime']).toISOString()),
        'AuthorDisplayName': value['authorDisplayName'],
    };
}

