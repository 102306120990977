import type { ParameterByDepthUserLensTrackItemDto } from "apis/oag";
import * as Styled from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/Loading/style";
import { LensGridContext } from "components/Lenses/utils";
import { useTracks } from "hooks/drillingInvariants/useTracks";
import type { ReactChild } from "react";
import React, { useMemo } from "react";
import { useContext } from "react";

export function ParameterByDepthLoading({
  lensId,
  title = "...",
  titleElement,
  detailed,
  selectedTrackItems = [],
}: {
  lensId: number;
  title?: string;
  detailed: boolean;
  titleElement?: ReactChild;
  selectedTrackItems?: ParameterByDepthUserLensTrackItemDto[];
}) {
  const { crtLayout, bp } = useContext(LensGridContext);

  const { data: tracks } = useTracks();

  const loadingTracks = useMemo(() => {
    return selectedTrackItems
      .slice()
      .sort((a, b) => a.position - b.position)
      .map((t) => tracks?.byId[t.trackId].name);
  }, [selectedTrackItems, tracks?.byId]);

  const hasChartPlaceholder =
    detailed ||
    ((crtLayout && crtLayout[bp]?.find?.((e) => +e.i === lensId)?.w) || 0) > 1;

  return (
    <Styled.ParameterLoadingContainer>
      <Styled.MiniLoadingChartParameterGrid
        trackCount={loadingTracks.length}
        hasChartPlaceholder={hasChartPlaceholder.toString()}
      >
        {loadingTracks.map((track, index) => (
          <React.Fragment key={`${track}_${index}_${Math.random()}`}>
            <Styled.KpiLabel>
              <div>{track}</div>
            </Styled.KpiLabel>
            <Styled.LabelContainer>
              {titleElement ? titleElement : title}
            </Styled.LabelContainer>
            {hasChartPlaceholder ? (
              <>
                <div />
                <Styled.ChartPlaceholder />
              </>
            ) : null}
          </React.Fragment>
        ))}
      </Styled.MiniLoadingChartParameterGrid>
      <Styled.ParameterToolbarContainer />
    </Styled.ParameterLoadingContainer>
  );
}
