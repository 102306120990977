import type { WellIntelQueryDto } from "apis/oag";
import { FilterTags } from "components/Filters/FilterTags";
import { useIntelFilterActions } from "hooks/filters/useIntelFilterActions";
import {
  selectDetailedFilterGroups,
  selectSimpleFilters,
} from "hooks/intel/useIntelLinkedFilters";
import { startTransition, useCallback } from "react";
import { useAppSelector } from "reducers/store";

export const IntelFilterTags = () => {
  const getFilterLabelFromSelectedOptions = useCallback(
    (groupName: string, selectedOptions: string[]) => {
      if (selectedOptions.length === 1) {
        return selectedOptions[0];
      } else return `${groupName} +${selectedOptions.length}`;
    },
    [],
  );

  const { removeFilterKey } = useIntelFilterActions();

  const removeFilterFromStore = useCallback(
    (key: keyof WellIntelQueryDto) => {
      removeFilterKey(key);
    },
    [removeFilterKey],
  );

  const bigFilterGroups = useAppSelector((state) =>
    selectDetailedFilterGroups(state.intelFiltersCommitted),
  )
    .filter((filterGroups) => (filterGroups?.options ?? []).length > 0)
    .map((filterGroups) => {
      return {
        text: getFilterLabelFromSelectedOptions(
          filterGroups.displayGroupTitle,
          filterGroups.options as string[],
        ),
        onRemove: () => {
          startTransition(() => {
            removeFilterFromStore(filterGroups.storeKey);
          });
        },
      };
    });

  const simpleFilterGroups = useAppSelector((state) =>
    selectSimpleFilters(state.intelFiltersCommitted),
  )
    .filter((filterGroups) => (filterGroups?.options ?? []).length > 0)
    .map((filterGroups) => ({
      text: getFilterLabelFromSelectedOptions(
        filterGroups.displayName,
        filterGroups.options as string[],
      ),
      onRemove: () => {
        startTransition(() => {
          removeFilterFromStore(filterGroups.storeKey);
        });
      },
    }));

  const tags = [...simpleFilterGroups, ...bigFilterGroups];

  return <FilterTags tags={tags} />;
};
