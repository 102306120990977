import { useQueryClient } from "@tanstack/react-query";
import type { DateDto } from "apis/oag";
import { Title } from "atoms/Typography";
import {
  FallbackButtonContainer,
  FallbackContainer,
  InfoContainer,
  StyledButton,
} from "components/MiniLens/style";
import { PDComponent } from "components/PDComponents";
import React from "react";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

export const FallbackCom = React.forwardRef<
  HTMLButtonElement,
  {
    resetError: () => void;
    setLensDate: React.Dispatch<React.SetStateAction<string | DateDto>>;
    lensTitle: string;
    localLensId: number;
  }
>(({ resetError, setLensDate, lensTitle, localLensId }, ref) => {
  // TODO needs cleanup for query fetching/invalidation, via object query keys
  setLensDate("Click to Reload");
  const { atomThemeVariant } = useCustomTheme();
  const queryClient = useQueryClient();
  return (
    <FallbackContainer>
      <InfoContainer size={6} direction="vertical">
        <Title ellipsis={{ rows: 1 }} level={3} variant="faded">
          {lensTitle}
        </Title>
        <Title level={2} variant={atomThemeVariant} weight={500}>
          Failed to fetch data
        </Title>
        <Title ellipsis={{ rows: 1 }} level={3} variant="faded">
          Click to Reload
        </Title>
      </InfoContainer>
      <FallbackButtonContainer>
        <StyledButton
          ref={ref}
          onClick={() => {
            queryClient
              .refetchQueries({
                predicate: (query) =>
                  (query.queryKey[0] as PDLensFactsQueryKey).lensId ===
                  localLensId,
              })
              .then(() => {
                resetError();
              });
          }}
        >
          <PDComponent.SvgIcon name="refetchIcon" width={40} height={40} />
        </StyledButton>
      </FallbackButtonContainer>
    </FallbackContainer>
  );
});
