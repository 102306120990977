/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  IntelGroupingType,
  ProfileInfoDto,
} from '../models/index';
import {
    IntelGroupingTypeFromJSON,
    IntelGroupingTypeToJSON,
    ProfileInfoDtoFromJSON,
    ProfileInfoDtoToJSON,
} from '../models/index';

export interface ApiProfilesIntelDashboardGroupingTypePutRequest {
    body?: string;
}

/**
 * 
 */
export class ProfilesApi extends runtime.BaseAPI {

    /**
     */
    async apiProfilesInfoAllGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProfileInfoDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Profiles/Info/All`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProfileInfoDtoFromJSON));
    }

    /**
     */
    async apiProfilesInfoAllGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProfileInfoDto>> {
        const response = await this.apiProfilesInfoAllGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProfilesInfoAllShareRecipientsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProfileInfoDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Profiles/Info/All/Share/Recipients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProfileInfoDtoFromJSON));
    }

    /**
     */
    async apiProfilesInfoAllShareRecipientsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProfileInfoDto>> {
        const response = await this.apiProfilesInfoAllShareRecipientsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProfilesIntelDashboardGroupingTypePutRaw(requestParameters: ApiProfilesIntelDashboardGroupingTypePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Profiles/IntelDashboardGroupingType`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiProfilesIntelDashboardGroupingTypePut(requestParameters: ApiProfilesIntelDashboardGroupingTypePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiProfilesIntelDashboardGroupingTypePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
