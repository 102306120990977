import type { DisplayOption, LensTemplateType, PivotType } from "apis/oag";
import { StackingType } from "apis/oag";
import {
  baseMatrix,
  MatrixAvailability,
} from "components/Lenses/ContainerLens/common/utils/constants";
import { getContainerLens } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import type { HiddenFilters } from "components/WellDashboard/ControlHeader/atoms/Filters";

export interface AbilityMatrixType {
  manual_axis: MatrixAvailability;
  breakdowns: MatrixAvailability;
  perceptual_kpi_value: MatrixAvailability;
  comparison: MatrixAvailability;
  selectedVisualAids: MatrixAvailability;
  indicators: MatrixAvailability;
  display_options: MatrixAvailability;
  filters: MatrixAvailability;
  kpiSelect: MatrixAvailability;
  zoom: MatrixAvailability;
  squeezesDisplay: MatrixAvailability;
  showStandKpiComments: MatrixAvailability;
  showOperationCount: MatrixAvailability;
  showsOutliers: MatrixAvailability;
  zoomByTime: MatrixAvailability;
  zoomByDate: MatrixAvailability;
  lensSettings: MatrixAvailability;
  label: MatrixAvailability;
  trackKPI: MatrixAvailability;
  group: MatrixAvailability;
  subGroup: MatrixAvailability;
  period: MatrixAvailability;
  hasDetailedView: MatrixAvailability;
  legend: MatrixAvailability;
  showSurvey: MatrixAvailability;
  outlierFlaggingType: MatrixAvailability;
  hasExport: MatrixAvailability;
  hiddenFiltersDetailed: HiddenFilters;
  hasDetailedLegend: MatrixAvailability;
  unitOfTime: MatrixAvailability;
  unitOfTimeHourDay: MatrixAvailability;
  showZTorque: MatrixAvailability;
  showPercentageDifference: MatrixAvailability;
  addUserTracks: MatrixAvailability;
}

export interface AbilityMatrixProps {
  isSystem: boolean;
  type?: string;
  isComparing?: boolean;
  displayOption?: DisplayOption;
  pivotType?: PivotType;
  stackingType?: StackingType;
}
export const getAbilityMatrix = ({
  type,
  isComparing,
  displayOption,
  stackingType,
  isSystem,
}: AbilityMatrixProps): AbilityMatrixType => {
  const containerLens = getContainerLens(type as LensTemplateType);
  const abilityMatrixFromContainerTypeLens = containerLens?.getAbilityMatrix
    ? containerLens?.getAbilityMatrix({
        type,
        isComparing,
        displayOption,
        stackingType,
        isSystem,
      })
    : baseMatrix;

  const tvdOnlyGeneralSettings =
    type === "tvd"
      ? {
          selectedVisualAids: MatrixAvailability.NOT_SUPPORTED,
          showsOutliers: MatrixAvailability.NOT_SUPPORTED,
          showOperationCount: MatrixAvailability.NOT_SUPPORTED,
          lensSettings: MatrixAvailability.NOT_SUPPORTED,
          label: MatrixAvailability.NOT_SUPPORTED,
        }
      : {};

  const stackingTypeOnlyGeneralSettings =
    stackingType === StackingType.Distribution
      ? {
          selectedVisualAids: MatrixAvailability.NOT_SUPPORTED,
          manual_axis: MatrixAvailability.NOT_SUPPORTED,
          outlierFlaggingType: MatrixAvailability.NOT_SUPPORTED,
          showsOutliers: MatrixAvailability.NOT_SUPPORTED,
          showOperationCount: MatrixAvailability.NOT_SUPPORTED,
        }
      : {};

  return {
    // Individual settings for each lens
    ...abilityMatrixFromContainerTypeLens,
    // Generally applicable settings based on override criteria
    ...tvdOnlyGeneralSettings,
    ...stackingTypeOnlyGeneralSettings,
  };
};
