import { useRigs } from "hooks/drillingInvariants/useRigs";
import { useCallback } from "react";

export const useRigSort = () => {
  const rigs = useRigs();
  const sortRigs = useCallback(
    (a: number, b: number) => {
      const aRig = rigs.data?.byId[a];
      const bRig = rigs.data?.byId[b];
      if (bRig?.contractorRigNumber === aRig?.contractorRigNumber) {
        return (aRig?.shortName ?? "").localeCompare(bRig?.shortName ?? "");
      }
      return bRig?.contractorRigNumber - aRig?.contractorRigNumber;
    },
    [rigs.data?.byId],
  );
  return sortRigs;
};
