import type { StandKpiDetailsDto } from "apis/oag";
import { type UserLensDto } from "apis/oag";
import { useStandKpiCurves } from "components/Lenses/common/InspectionView/StandKpis/utils";
import { useKpiGroups } from "hooks/drillingInvariants/useKPIGroups";
import { useKpiOptions } from "hooks/drillingInvariants/useKpiOptions";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import type { StandData } from "hooks/wells/useWellInspectionDetails";
import { useMemo, useState } from "react";
import type { UOMHelper } from "utils/format";

import { InspectionView } from "..";

export function useStandKpiInspectionView({
  lens,
  showDrillerComments,
  standList,
}: {
  lens: UserLensDto;
  showDrillerComments?: boolean;
  standList?: StandKpiDetailsDto[] | null;
}) {
  const [isInspectionVisible, setIsInspectionVisible] = useState(false);

  const [valueUOM, setValueUOM] = useState<UOMHelper | null>(null);

  const [totalDisplay, setTotalDisplay] = useState("");
  const [selectedStand, setSelectedStand] = useState<StandData | null>(null);
  const { standKpiCurvesGroup } = useStandKpiCurves();

  const { data: kpiTypes } = useKpiTypes();
  const { data: kpiGroups } = useKpiGroups();

  const { kpiType, kpiGroup } = useKpiOptions(lens);
  const lensTitle = useMemo(() => {
    if (!kpiGroups?.byId || !kpiTypes?.byId) return "";
    if (kpiType) {
      return kpiType.name;
    }

    if (kpiGroup) {
      return kpiGroup.name;
    }
  }, [kpiType, kpiGroup, kpiGroups?.byId, kpiTypes?.byId]);

  const openInspectionView = (
    item: StandData,
    valueUOM: UOMHelper,
    totalDisplay: string,
  ) => {
    setIsInspectionVisible(true);
    setSelectedStand(item);
    setValueUOM(valueUOM);
    setTotalDisplay(totalDisplay);
  };

  const inspectionViewElement =
    selectedStand && valueUOM ? (
      <InspectionView
        onCancel={() => {
          setIsInspectionVisible(false);
          setSelectedStand(null);
        }}
        lensTitle={lensTitle || ""}
        lensId={lens.id}
        valueUOM={valueUOM}
        totalDisplay={totalDisplay}
        showDrillerComments={showDrillerComments ?? false}
        standList={standList ?? []}
        curvesGroup={standKpiCurvesGroup}
        isVisible={isInspectionVisible}
        selectedStand={selectedStand}
      />
    ) : null;

  return { openInspectionView, inspectionViewElement };
}
