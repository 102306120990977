import { LATERAL_AXIS_WIDTH } from "components/Lenses/ContainerLens/common/utils/utils";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import type { ScaleLinear } from "d3-scale";
import React, { Fragment } from "react";
import { useCustomTheme } from "utils/useTheme";

import {
  QUARTILE_HORIZONTAL_OFFSET,
  QUARTILE_TOP_MARGIN,
  QUARTILE_VERTICAL_OFFSET,
} from "./utils";

const quartileColorsLight = ["#C4877D", "#F2D87A", "#47A0AB", "#07A032"];
const quartileColorsDark = ["#C4877D", "#F2D87A", "#13A9CA", "#0D9B83"];

export const Quartiles = ({
  plotHeight,
  plotWidth,
  yScale,
  quartileThresholds,
}: {
  plotHeight: number;
  plotWidth: number;
  yScale: ScaleLinear<number, number>;
  quartileThresholds: number[];
}) => {
  const { isDark } = useCustomTheme();
  const quartileColors = isDark ? quartileColorsDark : quartileColorsLight;
  if (quartileThresholds.length < 4) return null;
  return (
    <>
      <rect
        width={plotWidth}
        height={getSVGNormalizedValue(
          yScale(quartileThresholds[quartileColors.length - 1]) -
            QUARTILE_TOP_MARGIN,
        )}
        fill={quartileColors[quartileColors.length - 1]}
        fillOpacity={isDark ? "0.25" : "0.15"}
        transform={`translate(${LATERAL_AXIS_WIDTH}, ${QUARTILE_TOP_MARGIN})`}
      />

      {quartileThresholds.map((quartile, i) => {
        const top = yScale(quartile);
        const height =
          (i === 0 ? plotHeight : yScale(quartileThresholds[i - 1])) -
          yScale(quartile);
        const fill = quartileColors[i];

        return (
          <Fragment key={quartile}>
            <rect
              width={plotWidth}
              height={height}
              fill={fill}
              fillOpacity={isDark ? "0.25" : "0.15"}
              transform={`translate(${LATERAL_AXIS_WIDTH}, ${top})`}
            />
            {height > QUARTILE_VERTICAL_OFFSET ||
            i === quartileThresholds.length - 1 ? (
              <text
                x={plotWidth + LATERAL_AXIS_WIDTH - QUARTILE_HORIZONTAL_OFFSET}
                y={height + top - QUARTILE_VERTICAL_OFFSET}
                fill={fill}
                fontSize={11}
                textAnchor="end"
              >
                Quartile {quartileThresholds.length - i}
              </text>
            ) : null}
          </Fragment>
        );
      })}
    </>
  );
};
