import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { LensNavigationFilters } from "apis/oag";
import { RigScorecardUserLensesApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const LensNavigationFiltersQueryKey: PDUniqueQueryKey = {
  uid: RequestUID.lensNavigationFilters,
};

const rigCardApi = new RigScorecardUserLensesApi(apiConfig);
export function useRigNavigationFilters(
  options?: UseQueryOptions<Array<LensNavigationFilters>>,
) {
  return useSuspenseQuery<LensNavigationFilters[]>({
    queryKey: [LensNavigationFiltersQueryKey],
    queryFn: () => rigCardApi.apiRigScorecardUserLensesNavigationFiltersGet(),
    ...options,
    refetchOnMount: false,
  });
}
