import { useCustomTheme } from "utils/useTheme";

export const MockStickSlipByDepthSvg = () => {
  const { themeStyle } = useCustomTheme();
  return (
    <svg
      width="326"
      height="71"
      viewBox="0 0 326 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-7.5"
        y="8"
        width="150"
        height="8"
        fill={themeStyle.colors.alt_primary_bg}
      />
      <rect x="142.5" y="8" width="95" height="8" fill="#0D9B83" />
      <rect
        x="237.5"
        y="8"
        width="147"
        height="8"
        fill={themeStyle.colors.alt_primary_bg}
      />
      <rect
        x="291"
        y="8"
        width="36"
        height="8"
        fill={themeStyle.colors.alt_primary_bg}
      />
      <rect
        x="-71.5"
        y="19.75"
        width="89"
        height="8"
        fill={themeStyle.colors.alt_primary_bg}
      />
      <rect x="17.5" y="19.75" width="318" height="8" fill="#AA9C7C" />
      <rect x="-36" y="31.5" width="133" height="8" fill="#A492C3" />
      <rect
        x="97"
        y="31.5"
        width="35"
        height="8"
        fill={themeStyle.colors.alt_primary_bg}
      />
      <rect x="132" y="31.5" width="107" height="8" fill="#A492C3" />
      <rect
        x="239"
        y="31.5"
        width="53"
        height="8"
        fill={themeStyle.colors.alt_primary_bg}
      />
      <rect x="292" y="31.5" width="226" height="8" fill="#A492C3" />
      <rect
        x="-0.5"
        y="43.25"
        width="45"
        height="8"
        fill={themeStyle.colors.alt_primary_bg}
      />
      <rect x="44.5" y="43.25" width="282" height="8" fill="#C4877D" />
      <rect
        y="55"
        width="20"
        height="8"
        fill={themeStyle.colors.alt_primary_bg}
      />
      <rect x="20" y="55" width="196" height="8" fill="#18A6B8" />
      <rect
        x="216"
        y="55"
        width="37"
        height="8"
        fill={themeStyle.colors.alt_primary_bg}
      />
      <rect x="253" y="55" width="73" height="8" fill="#18A6B8" />
    </svg>
  );
};
