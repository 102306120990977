/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PivotTree1Dto } from './PivotTree1Dto';
import {
    PivotTree1DtoFromJSON,
    PivotTree1DtoFromJSONTyped,
    PivotTree1DtoToJSON,
} from './PivotTree1Dto';
import type { PivotKey } from './PivotKey';
import {
    PivotKeyFromJSON,
    PivotKeyFromJSONTyped,
    PivotKeyToJSON,
} from './PivotKey';
import type { PivotType } from './PivotType';
import {
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface PivotTree2Dto
 */
export interface PivotTree2Dto {
    /**
     * 
     * @type {PivotKey}
     * @memberof PivotTree2Dto
     */
    key: PivotKey;
    /**
     * 
     * @type {PivotType}
     * @memberof PivotTree2Dto
     */
    pivotType: PivotType;
    /**
     * 
     * @type {number}
     * @memberof PivotTree2Dto
     */
    averageValue: number;
    /**
     * 
     * @type {number}
     * @memberof PivotTree2Dto
     */
    focalAverage: number;
    /**
     * 
     * @type {number}
     * @memberof PivotTree2Dto
     */
    comparisonAverage: number;
    /**
     * 
     * @type {number}
     * @memberof PivotTree2Dto
     */
    comparisonAverageRelativeDiff: number;
    /**
     * 
     * @type {number}
     * @memberof PivotTree2Dto
     */
    operationCount: number;
    /**
     * 
     * @type {number}
     * @memberof PivotTree2Dto
     */
    targetValue?: number | null;
    /**
     * 
     * @type {Array<PivotTree1Dto>}
     * @memberof PivotTree2Dto
     */
    children: Array<PivotTree1Dto>;
    /**
     * 
     * @type {boolean}
     * @memberof PivotTree2Dto
     */
    readonly isOutlier: boolean;
    /**
     * 
     * @type {DateDto}
     * @memberof PivotTree2Dto
     */
    readonly referenceDate: DateDto;
}



/**
 * Check if a given object implements the PivotTree2Dto interface.
 */
export function instanceOfPivotTree2Dto(value: object): value is PivotTree2Dto {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('pivotType' in value) || value['pivotType'] === undefined) return false;
    if (!('averageValue' in value) || value['averageValue'] === undefined) return false;
    if (!('focalAverage' in value) || value['focalAverage'] === undefined) return false;
    if (!('comparisonAverage' in value) || value['comparisonAverage'] === undefined) return false;
    if (!('comparisonAverageRelativeDiff' in value) || value['comparisonAverageRelativeDiff'] === undefined) return false;
    if (!('operationCount' in value) || value['operationCount'] === undefined) return false;
    if (!('children' in value) || value['children'] === undefined) return false;
    if (!('isOutlier' in value) || value['isOutlier'] === undefined) return false;
    if (!('referenceDate' in value) || value['referenceDate'] === undefined) return false;
    return true;
}

export function PivotTree2DtoFromJSON(json: any): PivotTree2Dto {
    return PivotTree2DtoFromJSONTyped(json, false);
}

export function PivotTree2DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotTree2Dto {
    if (json == null) {
        return json;
    }
    return {
        
        'key': PivotKeyFromJSON(json['Key']),
        'pivotType': PivotTypeFromJSON(json['PivotType']),
        'averageValue': json['AverageValue'],
        'focalAverage': json['FocalAverage'],
        'comparisonAverage': json['ComparisonAverage'],
        'comparisonAverageRelativeDiff': json['ComparisonAverageRelativeDiff'],
        'operationCount': json['OperationCount'],
        'targetValue': json['TargetValue'] == null ? undefined : json['TargetValue'],
        'children': ((json['Children'] as Array<any>).map(PivotTree1DtoFromJSON)),
        'isOutlier': json['IsOutlier'],
        'referenceDate': DateDtoFromJSON(json['ReferenceDate']),
    };
}

export function PivotTree2DtoToJSON(value?: Omit<PivotTree2Dto, 'IsOutlier'|'ReferenceDate'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Key': PivotKeyToJSON(value['key']),
        'PivotType': PivotTypeToJSON(value['pivotType']),
        'AverageValue': value['averageValue'],
        'FocalAverage': value['focalAverage'],
        'ComparisonAverage': value['comparisonAverage'],
        'ComparisonAverageRelativeDiff': value['comparisonAverageRelativeDiff'],
        'OperationCount': value['operationCount'],
        'TargetValue': value['targetValue'],
        'Children': ((value['children'] as Array<any>).map(PivotTree1DtoToJSON)),
    };
}

