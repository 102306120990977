import type { RigSummaryDto } from "apis/oag";
import { SCROLLBAR_GAP_AROUND } from "components/Layout/LayoutUtils";
import NoData from "components/Lenses/common/NoData";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import { useOperators } from "hooks/drillingInvariants/useOperators";
import {
  AttributeStore,
  useLinkedWellAttributes,
} from "hooks/filters/useLinkedAttributes";
import { useRigSort } from "hooks/rigs/useRigSort";
import { useRigSummaries } from "hooks/rigs/useRigSummaries";
import { RigListRow } from "pages/FleetPerformance/RigList/RigListRow";
import {
  StyledContainer,
  StyledMatchingRigsText,
  StyledNoDataContainer,
  StyledSpinnerContainer,
  StyledTextNoDataMessage,
  StyledWrapper,
} from "pages/FleetPerformance/RigList/styles";
import { useEffect, useMemo } from "react";

const ROW_HEIGHT = 55;
const TOP_DOWN_PADDING = 4;
const RIGHT_MARGIN = 16;

export interface RigViewModel extends RigSummaryDto {
  operatorName: string;
}

export const RigList = ({
  setTotalRigs,
}: {
  setTotalRigs: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { data: operators, isLoading: isLoadingOperators } = useOperators({
    staleTime: Infinity,
  });
  const { data: rigs, isLoading: isLoadingSummaries } = useRigSummaries({
    staleTime: Infinity,
  });
  const {
    options: linkedFiltersAvailableOptions,
    isLoading: isLoadingFilters,
  } = useLinkedWellAttributes({
    storeName: AttributeStore.rigsCommon,
  });

  const visibleRigs = useMemo(() => {
    const visibleIds =
      linkedFiltersAvailableOptions?.rigs?.map((rig) => rig.id) ?? [];
    return visibleIds.length
      ? rigs?.filter((rig) => visibleIds?.includes(rig.id))
      : rigs;
  }, [linkedFiltersAvailableOptions?.rigs, rigs]);

  const sortByRigs = useRigSort();

  const viewModelData: RigViewModel[] = useMemo(() => {
    const sortedRigs = [...(visibleRigs || [])].sort((a, b) =>
      sortByRigs(a.id, b.id),
    );

    return sortedRigs.map((rig) => {
      return {
        ...rig,
        operatorName: operators?.byId?.[rig.operatorId]?.name || "Unknown",
      };
    });
  }, [operators?.byId, sortByRigs, visibleRigs]);

  const isLoading = useMemo(
    () =>
      isLoadingOperators ||
      isLoadingSummaries ||
      isLoadingFilters.filterOptions,
    [isLoadingFilters, isLoadingOperators, isLoadingSummaries],
  );
  const isInvalidDataState = useMemo(
    () => isLoading || !viewModelData.length,
    [isLoading, viewModelData?.length],
  );

  useEffect(() => {
    setTotalRigs(viewModelData.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewModelData.length]);

  return (
    <StyledWrapper>
      <StyledContainer>
        {isInvalidDataState ? (
          isLoading ? (
            <StyledSpinnerContainer>
              <Loader withWrapper />
            </StyledSpinnerContainer>
          ) : (
            <>
              <StyledNoDataContainer>
                <NoData message=" " />
              </StyledNoDataContainer>

              <StyledTextNoDataMessage>
                <span>
                  <StyledMatchingRigsText>
                    No matching rigs found.
                  </StyledMatchingRigsText>{" "}
                  <br />
                  Please try another configuration.
                </span>
              </StyledTextNoDataMessage>
            </>
          )
        ) : (
          <PDComponent.VirtualizedList
            items={viewModelData}
            itemSizePx={ROW_HEIGHT + TOP_DOWN_PADDING}
            scrollbarDistance={RIGHT_MARGIN + SCROLLBAR_GAP_AROUND}
          >
            {(rig, idx) => <RigListRow rig={rig} key={`${rig.id}-${idx}`} />}
          </PDComponent.VirtualizedList>
        )}
      </StyledContainer>
    </StyledWrapper>
  );
};
