import { UserRoleType } from "apis/oag";
import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Typography } from "utils/componentLibrary";

export type MergeModalProps = {
  onConfirm: () => void;
  userRole?: UserRoleType;
  setVisible: (value: boolean) => void;
  visible: boolean;
};
// TODO ADD MP Tracking

export function MergeModal({
  onConfirm,
  userRole,
  setVisible,
  visible,
}: MergeModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  useEffect(() => {
    if (!visible) {
      setIsLoading(false);
    }
  }, [visible]);

  const message =
    userRole === UserRoleType.Administrator ? (
      <Typography>
        System Well plan information is going to be replaced by the Pending well
        plan information. Are you sure you’d ike to proceed with this merge?
      </Typography>
    ) : (
      <>
        <Typography style={{ marginBottom: "24px" }}>
          Upon submitting a Merge request, an email will be sent to a clarity
          admin to evaluate the request.
        </Typography>
        <Typography>
          Once merged, the System Well plan information is going to be replaced
          by the Pending well plan information.
        </Typography>
      </>
    );

  return (
    <PDComponent.Modal
      onCancel={handleCancel}
      title={
        userRole === UserRoleType.Administrator
          ? "Merge wells?"
          : "Submit merge request?"
      }
      footer={
        <Row justify="space-between">
          <Col>
            <Button onClick={handleCancel}>Cancel</Button>
          </Col>
          <Col>
            <Button
              type="primary"
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
                if (onConfirm) {
                  onConfirm();
                }
                setVisible(false);
                setIsLoading(false);
              }}
            >
              Merge
            </Button>
          </Col>
        </Row>
      }
      open={visible}
    >
      {message}
    </PDComponent.Modal>
  );
}

export function useMergeModal({
  onConfirm,
  userRole,
}: Pick<MergeModalProps, "onConfirm" | "userRole">) {
  const [visible, setVisible] = useState(false);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  const open = useCallback(() => {
    setVisible(true);
  }, []);

  const node = (
    <MergeModal
      setVisible={setVisible}
      visible={visible}
      onConfirm={onConfirm}
      userRole={userRole}
    />
  );

  return { visible, close, open, node };
}
