import type {
  DirectionalIntervalDto,
  HoleSectionDto,
  ParameterByDepthKpiComparisonDto,
} from "apis/oag";
import { groupBy, keyBy } from "lodash";
import { IIndicators } from "reducers/widgetReducer";

export function getTransformedIndicators({
  data,
  selectedIndicators,
  holeSections,
  intervals,
}: {
  data: ParameterByDepthKpiComparisonDto;
  selectedIndicators?: Array<IIndicators>;
  holeSections?: HoleSectionDto[];
  intervals?: DirectionalIntervalDto[];
}) {
  if (!selectedIndicators || !holeSections) return {};
  const holeSectionMap = keyBy(holeSections, "id");
  const intervalMap = keyBy(intervals, "id");

  const mergedIndicators: { value: number; label: string }[] = [];

  if (
    selectedIndicators?.includes(IIndicators.DirectionalIntervals) &&
    data.indicators?.directionIntervals
  ) {
    mergedIndicators.push(
      ...(data.indicators?.directionIntervals.map((i) => ({
        label: intervalMap[i.id]?.name ?? "Undefined",
        value: i.value ?? 0,
      })) ?? []),
    );
  }

  if (selectedIndicators?.includes(IIndicators.HoleSections) && holeSections) {
    mergedIndicators.push(
      ...(data.indicators?.holeSections || []).map((i) => ({
        label: holeSectionMap[i.id ?? -1].name ?? "Undefined Hole Section",
        value: i.value ?? 0,
      })),
    );
  }

  return groupBy(mergedIndicators, (item) => item.value);
}
