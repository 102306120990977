import { Button } from "atoms/Form";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import colors from "utils/colors";

export const CustomInput = styled(TextareaAutosize)`
  outline: none;
  resize: none;
  border: 0;
  width: 100%;
  padding: 16px 58px 16px 22px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  border-radius: 10px;
  background: ${({ theme }) => (theme.isDark ? colors.revolver : colors.white)};

  &::placeholder {
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    font-size: 16px;
    font-weight: 400;
  }

  &:focus {
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
  }
`;

export const InputGroup = styled.div`
  width: 700px;
  min-height: 50px;
  height: 50px;
  max-height: 150px;
  margin: auto;
  position: relative;

  @media screen and (max-width: 1024px) {
    width: 470px;
  }
`;

export const SendButton = styled(Button)`
  position: absolute;
  background-color: ${colors.shutter_black} !important;

  svg {
    fill: ${({ theme }) =>
      theme.isDark ? colors.aphrodite_aqua : colors.actions_bg};
    color: ${({ theme }) =>
      theme.isDark ? colors.aphrodite_aqua : colors.actions_bg};
    font-size: 18px;
  }

  bottom: 9px;
  right: 16px;

  // Form Button overrides
  border: 0 !important;
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px !important;
  padding: 5px 8px !important;

  &:disabled {
    background-color: ${({ theme }) =>
      theme.isDark ? colors.shutter_black : colors.off_state} !important;

    svg {
      fill: ${({ theme }) =>
        theme.isDark ? colors.revolver : colors.default_bg};
      color: ${({ theme }) =>
        theme.isDark ? colors.revolver : colors.default_bg};
    }
  }
`;

export const ResizableContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
`;
