import type { ScoreSummaryRootDto, WellBarDto } from "apis/oag";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import type { Dict } from "mixpanel-browser";
import { ScoreBreakoutModal } from "pages/RigLeaderboard/components/ScoreBreakout/Modal";
import type { TSummary } from "pages/RigLeaderboard/components/ScoreBreakout/SummarizedComments";
import { useState } from "react";
import { useAppDispatch } from "reducers/store";
import { Track } from "services/Mixpanel";

export interface IPartialRig {
  opportunityTime?: number | null;
  score?: number | null;
  name: string;
  description?: string;
  id: number;
  scoreSummary?: ScoreSummaryRootDto | null;
  // used to get well score
  wellInfo?: Partial<WellBarDto>[];
  selectedWell?: number;
  kpiInfo?: {
    id: number;
    name: string;
    maxVal: number;
  }[];
  summaryType: TSummary;
  justComment?: boolean;
}

export function useScoreBreakoutModal() {
  const [isInspectionVisible, setIsInspectionVisible] = useState(false);

  const [selectedRig, setSelectedRig] = useState<IPartialRig | null>(null);
  const { data: wellShortInfo } = useWellShortInfoSuspended();
  const dispatch = useAppDispatch();

  const openScoreBreakout = (
    item: IPartialRig,
    trackingInfo?: { title?: string; data?: Dict },
  ) => {
    Track.interact(
      trackingInfo?.title || "Rig Scorecard - Open Score Breakout",
      trackingInfo?.data || {
        Rig: item.name,
        "Rig Id": item.id,
        ...(item.selectedWell
          ? {
              "Well Name": wellShortInfo?.byId[item.selectedWell]?.name || "",
              "Well Id": item.selectedWell,
            }
          : {}),
      },
    );
    setIsInspectionVisible(true);
    setSelectedRig(item);

    dispatch({
      // To help summarized comments inside score breakout get id from url
      type: "SET_SELECTED_RIG",
      payload: item.id,
    });
  };

  const scoreBreakoutElement = selectedRig ? (
    <ScoreBreakoutModal
      onCancel={() => {
        setIsInspectionVisible(false);
        setSelectedRig(null);
      }}
      justComment={selectedRig.justComment}
      isVisible={isInspectionVisible}
      selectedRig={selectedRig}
    />
  ) : null;

  return { openScoreBreakout, scoreBreakoutElement };
}
