/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QuarterYear } from './QuarterYear';
import {
    QuarterYearFromJSON,
    QuarterYearFromJSONTyped,
    QuarterYearToJSON,
} from './QuarterYear';

/**
 * 
 * @export
 * @interface QuarterYearSet
 */
export interface QuarterYearSet {
    /**
     * 
     * @type {Array<QuarterYear>}
     * @memberof QuarterYearSet
     */
    quarters: Array<QuarterYear>;
}

/**
 * Check if a given object implements the QuarterYearSet interface.
 */
export function instanceOfQuarterYearSet(value: object): value is QuarterYearSet {
    if (!('quarters' in value) || value['quarters'] === undefined) return false;
    return true;
}

export function QuarterYearSetFromJSON(json: any): QuarterYearSet {
    return QuarterYearSetFromJSONTyped(json, false);
}

export function QuarterYearSetFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuarterYearSet {
    if (json == null) {
        return json;
    }
    return {
        
        'quarters': ((json['Quarters'] as Array<any>).map(QuarterYearFromJSON)),
    };
}

export function QuarterYearSetToJSON(value?: QuarterYearSet | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Quarters': ((value['quarters'] as Array<any>).map(QuarterYearToJSON)),
    };
}

