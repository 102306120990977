import type {
  ScoreSummaryLensDto,
  ScoreSummaryRootDto,
  ScoreSummaryTabGroupDto,
} from "apis/oag";
import { DashboardType, TimeUnit } from "apis/oag";
import { Title } from "atoms/Typography";
import { Collapsible } from "components/Collapsible";
import { useUserLensTabs } from "hooks/dashboard/useUserLensTabs";
import { HorizontalBars } from "pages/RigLeaderboard/components/ScoreBreakout/HorizontalBars";
import * as Styled from "pages/RigLeaderboard/components/ScoreBreakout/styles";
import type { IPartialRig } from "pages/RigLeaderboard/components/ScoreBreakout/useScoreBreakoutModal";
import {
  BREAKOUT_BAR_HEIGHT,
  BREAKOUT_BAR_WIDTH,
} from "pages/RigLeaderboard/components/utils";
import {
  BenchmarkType,
  ScoreBenchmarkContext,
} from "pages/RigScoreCard/ScoreBenchmarkContext";
import { useCallback, useContext, useMemo } from "react";
import { Space } from "utils/componentLibrary";
import { useTimeUom } from "utils/format";

import { CollapsibleInner } from "./CollapsibleInner";

export const ScorecardBreakout = ({
  selectedRig,
}: {
  selectedRig: IPartialRig;
}) => {
  const hourUom = useTimeUom(TimeUnit.Hour);
  const benchmarkType = useContext(ScoreBenchmarkContext);
  const scoreSelectors = useMemo<{
    timeSelector: "targetDeltaTime" | "invisibleLostTime";
    maxTimeSelector: "targetDeltaTimeMaxRange" | "invisibleLostTimeMaxRange";
  }>(() => {
    return benchmarkType?.scoreBenchmark === BenchmarkType.OperatorTarget
      ? ({
          timeSelector: "targetDeltaTime",
          maxTimeSelector: "targetDeltaTimeMaxRange",
        } as const)
      : ({
          timeSelector: "invisibleLostTime",
          maxTimeSelector: "invisibleLostTimeMaxRange",
        } as const);
  }, [benchmarkType]);
  const { data: lensTabs } = useUserLensTabs(DashboardType.RigScorecard, {
    staleTime: Infinity,
  });
  const getTimeByBenchmarkType = useCallback(
    (group: ScoreSummaryTabGroupDto | ScoreSummaryLensDto) => {
      return group[scoreSelectors.timeSelector] || 0;
    },
    [scoreSelectors],
  );

  const getMaxTimeByBenchmarkType = useCallback(
    (group: ScoreSummaryRootDto) => {
      return group[scoreSelectors.maxTimeSelector];
    },
    [scoreSelectors],
  );

  return (
    <Styled.CollapsibleContainer>
      {selectedRig.scoreSummary?.children?.map((group) => (
        <Collapsible
          key={group.groupKey}
          title={group.groupKey || ""}
          rightContent={
            <Space size={12}>
              <Title level={3}>
                {(getTimeByBenchmarkType(group) || 0) > 0 ? "+" : ""}
                {hourUom.display(getTimeByBenchmarkType(group))}{" "}
              </Title>
              <HorizontalBars
                height={BREAKOUT_BAR_HEIGHT}
                width={BREAKOUT_BAR_WIDTH}
                ratio={
                  !selectedRig.scoreSummary
                    ? 0
                    : (getTimeByBenchmarkType(group) || 0) /
                      getMaxTimeByBenchmarkType(selectedRig.scoreSummary)
                }
              />
            </Space>
          }
        >
          {group.children?.map((tab) => (
            <CollapsibleInner
              key={tab.tabId}
              getMaxTimeByBenchmarkType={getMaxTimeByBenchmarkType}
              getTimeByBenchmarkType={getTimeByBenchmarkType}
              scoreSummary={selectedRig.scoreSummary}
              lensTabs={lensTabs}
              tab={tab}
            />
          ))}
        </Collapsible>
      ))}
    </Styled.CollapsibleContainer>
  );
};
