import * as Styled from "components/Layout/style";

interface HiddenScrollbarColumnProps
  extends React.HTMLAttributes<HTMLDivElement> {
  headerPadding: number;
  headerSize: number;
  isReport: boolean;
  isReportTvD: boolean;
  showScrollHints: boolean;
}

export const HiddenScrollbarColumn = ({
  headerPadding,
  headerSize,
  showScrollHints,
  isReport,
  isReportTvD,
  children,
}: React.PropsWithChildren<HiddenScrollbarColumnProps>) => {
  return showScrollHints && !isReport && !isReportTvD ? (
    <Styled.NoScrollColWithHints hz={headerPadding + headerSize}>
      {children}
    </Styled.NoScrollColWithHints>
  ) : (
    <Styled.NoScrollCol
      span={24}
      hz={headerPadding + headerSize}
      $isReport={isReport}
      $isReportTvD={isReportTvD}
    >
      {children}
    </Styled.NoScrollCol>
  );
};
