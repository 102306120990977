import type { UserDto } from "apis/oag";
import * as FormAtom from "atoms/Form";
import * as Styled from "components/RigScoreCardCommentsModal/styled";
import type { ChangeEvent } from "react";
import { SECONDS_IN_HOUR } from "utils/format";
import { TWO_DECIMAL_PLACES_INPUT_MASK } from "utils/helper";

export const FormItem = ({
  isExceptionEnabled,
  exceptionTime,
  handleOnExceptionHoursChange,
  exceptionError,
  approver,
  handleOnApproverChange,
  approvers,
}: {
  isExceptionEnabled: boolean;
  handleOnApproverChange: (optionValue: number) => void;
  handleOnExceptionHoursChange: (e: ChangeEvent<HTMLInputElement>) => void;
  exceptionTime: number;
  exceptionError: string;
  approver?: UserDto | null;
  approvers: UserDto[];
}) => {
  return isExceptionEnabled ? (
    <>
      <Styled.InputWithLabel>
        <Styled.DropdownLabel>Hours:</Styled.DropdownLabel>
        <FormAtom.Input
          value={exceptionTime / SECONDS_IN_HOUR}
          type="number"
          tabIndex={1}
          onChange={handleOnExceptionHoursChange}
          required
          autoFocus
          step="0.01"
          pattern={TWO_DECIMAL_PLACES_INPUT_MASK}
          error={exceptionError}
        />
      </Styled.InputWithLabel>

      <Styled.InputWithLabel>
        <Styled.DropdownLabel>Approver:</Styled.DropdownLabel>
        <FormAtom.Select
          tabIndex={2}
          size="small"
          value={approver?.id}
          placeholder="Select approver"
          onSelect={(event) => handleOnApproverChange(event as number)}
          options={(approvers ?? []).map((approver) => ({
            ...approver,
            label: `${approver.firstName} ${approver.lastName}`,
            value: approver?.id,
          }))}
        />
      </Styled.InputWithLabel>
    </>
  ) : (
    <Styled.ExceptionInfoAboutCheckbox>
      Check this box to exclude a portion of time from the reported KPI time.
    </Styled.ExceptionInfoAboutCheckbox>
  );
};
