import type {
  QueryObserverResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { ActualTimelineDto, PlanTimelineDto } from "apis/oag";
import { useWellActualTimeline } from "hooks/tvd/useWellActualTimeline";
import { useWellPlanTimeline } from "hooks/wells/useWellPlanTimeline";
import { useEffect } from "react";
import { useAppSelector } from "reducers/store";
import { useAppDispatch } from "reducers/store";

// Zoom and Filters related considerations for showing and
// hiding the events shall be taken care of
// in the components that creates that window

const fetchEvents = async ({
  refetchPlan,
  refetchActual,
}: {
  refetchPlan: () => Promise<QueryObserverResult<PlanTimelineDto, unknown>>;
  refetchActual: () => Promise<QueryObserverResult<ActualTimelineDto, unknown>>;
}) => {
  let {
    planEvents,
    actualEvents,
  }: {
    planEvents: PlanTimelineDto;
    actualEvents: ActualTimelineDto;
  } = {
    planEvents: {},
    actualEvents: {},
  };
  try {
    planEvents = (await refetchPlan()).data ?? {};
  } catch {
    console.error("Plan Events error");
  }
  try {
    actualEvents = (await refetchActual()).data ?? {};
  } catch {
    console.error("Actual Events error");
  }
  return {
    planEvents,
    actualEvents,
  };
};

export function useFetchTimelineEvents(options?: UseQueryOptions) {
  const dispatch = useAppDispatch();

  const { data: planEventsInitial, refetch: refetchPlan } = useWellPlanTimeline(
    options as UseQueryOptions<PlanTimelineDto>,
  ) as UseQueryResult<PlanTimelineDto>;
  const { data: actualEventsInitial, refetch: refetchActual } =
    useWellActualTimeline(
      options as UseQueryOptions<ActualTimelineDto>,
    ) as UseQueryResult<ActualTimelineDto>;

  const wellDataID = useAppSelector((state) => state.state.selectedWell);
  const refetchOverview = useAppSelector(
    (state) => state.state.refetchOverview,
  );

  useEffect(() => {
    if (planEventsInitial === undefined || actualEventsInitial === undefined)
      return;
    dispatch({
      type: "SET_EVENTS",
      payload: {
        planEvents: {
          ...planEventsInitial,
        },
        actualEvents: actualEventsInitial,
      },
    });
  }, [actualEventsInitial, dispatch, planEventsInitial]);

  useEffect(() => {
    fetchEvents({
      refetchPlan,
      refetchActual,
    }).then(({ planEvents, actualEvents }) => {
      dispatch({
        type: "SET_EVENTS",
        payload: {
          planEvents,
          actualEvents,
        },
      });
      dispatch({
        type: "SET_REFETCH_OVERVIEW",
        payload: false,
      });
    });
  }, [dispatch, wellDataID, refetchOverview, refetchPlan, refetchActual]);
}
