import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { OperationClassDto } from "apis/oag";
import { OperationClassesApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";
const OperationClasses = new OperationClassesApi(apiConfig);
const OperationClassApiQueryKey: PDUniqueQueryKey = {
  uid: RequestUID.operationClassesNames,
};

export function useOperationClasses(
  options?: UseQueryOptions<OperationClassDto[]>,
) {
  return useSuspenseQuery<OperationClassDto[]>({
    queryKey: [OperationClassApiQueryKey],
    queryFn: () => OperationClasses.apiOperationClassesGet(),
    refetchOnMount: false,
    ...options,
  });
}
