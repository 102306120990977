/* eslint-disable react/forbid-dom-props */
import type { VirtualItem } from "@tanstack/react-virtual";
import type { ReactElement } from "react";
import React, { startTransition, useEffect } from "react";
import { memo } from "react";

export const ListRowItem = memo(
  ({
    virtualRow,
    children,
  }: {
    virtualRow: VirtualItem;
    children: ReactElement;
  }) => {
    const [isUnblockedRender, setIsUnblockedRender] = React.useState(false);

    useEffect(() => {
      // Using every tool available to defer rendering and stop blocking the main thread --> prevents jank on scroll

      let timeoutId: ReturnType<typeof setTimeout>;

      requestAnimationFrame(() => {
        startTransition(() => {
          timeoutId = setTimeout(() => {
            setIsUnblockedRender(true);
          }, 0);
        });
      });
      () => clearTimeout(timeoutId);

      // Whenever a new row is rendered by the virtualizer, we want to unblock the render
    }, []);

    return isUnblockedRender ? (
      <div
        key={virtualRow.index}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: `${virtualRow.size}px`,
          transform: `translateY(${virtualRow.start}px)`,
          willChange: "transform",
        }}
      >
        {children}
      </div>
    ) : null;
  },
);
