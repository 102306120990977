import { PivotType } from "apis/oag";
import dayjs from "dayjs";
import { useCrewMembers } from "hooks/drillingInvariants/useCrewMembers";
import { useDirectionalIntervals } from "hooks/drillingInvariants/useDirectionalIntervals";
import { useHoleSections } from "hooks/drillingInvariants/useHoleSections";
import { useHoleSizes } from "hooks/drillingInvariants/useHoleSizes";
import { useOperators } from "hooks/drillingInvariants/useOperators";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import { useCallback } from "react";
import { useCustomHoleSizeUom } from "utils/format";

export function usePivotLabels() {
  const { data: crewMembers } = useCrewMembers();
  const { data: rigs } = useRigs();
  const { data: holeSizes } = useHoleSizes();
  const { data: holeSections } = useHoleSections();
  const { data: directionalIntervals } = useDirectionalIntervals();
  const { data: wells } = useWellShortInfoSuspended();
  const { data: operators } = useOperators();

  const holeSizeUom = useCustomHoleSizeUom();

  const getLabelFromKey = useCallback(
    (key: string, pivotType: PivotType, format: "short" | "long" = "short") => {
      let year: string, semester: string, quarter: string, week: string;
      switch (pivotType) {
        case PivotType.Day:
          return dayjs(key).format("M/D");

        case PivotType.Week:
          [year, week] = key.split("-W");
          return `W${+week} ${year.slice(-2)}`;

        case PivotType.Month:
          return dayjs(key).format(format === "short" ? "MMM YY" : "MMMM YYYY");

        case PivotType.Quarter:
          [year, quarter] = key.split("-");
          return `${quarter} ${year.slice(-2)}`;

        case PivotType.Semester:
          [year, semester] = key.split("-");
          return `${semester} ${year.slice(-2)}`;

        case PivotType.Well: {
          const well = wells.list.find((w) => w.id === +key);
          return +key < 0 ? `Well ${-key}` : well?.name ?? "";
        }
        case PivotType.HoleSection: {
          const holeSection = holeSections.find((hs) => hs.id === +key);
          return holeSection?.name ?? "";
        }

        case PivotType.HoleSize: {
          const holeSize = holeSizes.find((hs) => hs.id === +key);
          return holeSize && +holeSize.value >= 0
            ? holeSizeUom.display(+holeSize.value, { metricOption: true })
            : "Unknown";
        }

        case PivotType.DirectionalInterval: {
          const directionalInterval = directionalIntervals?.find(
            (di) => di.id === +key,
          );
          return directionalInterval?.name ?? "";
        }

        case PivotType.Driller: {
          const crewMember = crewMembers.find((cm) => cm.id === +key);
          return (+key < 0 ? `Driller ${-key}` : crewMember?.name) ?? "";
        }

        case PivotType.Rig: {
          const rig = rigs.list.find((r) => r.id === +key);
          return +key < 0 ? `Rig ${-key}` : rig?.shortName || "";
        }

        case PivotType.Operator: {
          const operator = operators.list.find(
            (operator) => operator.id === +key,
          );
          return operator?.name ?? `Unknown operator id: ${key}`;
        }

        case PivotType.Year:
        case PivotType.Shift:
        default:
          return key;
      }
    },
    [
      wells.list,
      holeSections,
      holeSizes,
      holeSizeUom,
      directionalIntervals,
      crewMembers,
      rigs.list,
      operators.list,
    ],
  );
  return { getLabelFromKey };
}
