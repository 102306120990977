import { INITIAL_FILTERS } from "components/Filters/AllFilters";
import { useFiltersLoading } from "hooks/filters/useFiltersLoading";
import type { AttributeStore } from "hooks/filters/useLinkedAttributes";
import { useLinkedWellAttributes } from "hooks/filters/useLinkedAttributes";
import { useEffect, useMemo, useState } from "react";
import type {
  IFilterOptions,
  IFilterOptionValue,
} from "reducers/rigsCommonReducer";
import { useCustomHoleSizeUom } from "utils/format";

export type FilterTypes = keyof IFilterOptions;

export const useFiltersCategories = (
  filterState: IFilterOptions | null,
  setFilters: (filters: IFilterOptions | null) => void,
  attributeStore: AttributeStore,
) => {
  const {
    directionalIntervals,
    holeSections,
    holeSizes,
    filtersLoading,
    phases,
  } = useFiltersLoading();
  const uom = useCustomHoleSizeUom();

  const { options, isLoading: isLoadingWellAttributes } =
    useLinkedWellAttributes({
      storeName: attributeStore,
    });

  useEffect(() => {
    setLocalFilters(filterState);
  }, [filterState]);

  const allFilterOptions: Record<
    FilterTypes,
    {
      categoryLabel: string;
      options: IFilterOptionValue[];
    }
  > = useMemo(
    () => ({
      directionalInterval: {
        categoryLabel: "Directional Intervals",
        options: (directionalIntervals ?? []).map((interval) => ({
          value: interval.name,
          id: interval.id,
        })),
      },
      holeSection: {
        categoryLabel: "Sections",
        options: (holeSections ?? []).map((section) => ({
          value: section.name,
          id: section.id,
        })),
      },
      holeSize: {
        categoryLabel: "Hole Size",
        options: (holeSizes ?? [])
          .toSorted((a, b) => {
            if (a.value < 0) return Infinity;
            if (b.value < 0) return -Infinity;
            return b.value - a.value;
          })
          .map((holeSize) => {
            const newValue =
              +holeSize.value <= 0
                ? "Unknown"
                : uom
                    .display(+holeSize.value, { metricOption: true })
                    .toString();
            return { value: newValue, id: holeSize.id };
          }),
      },
      phases: {
        categoryLabel: "Well Phases",
        options: (phases ?? [])
          .toSorted((a, b) => {
            const unknownSectionId =
              holeSections.find((section) => section.name === "Unknown")?.id ??
              0;
            if (
              a.sectionId === unknownSectionId ||
              b.sectionId === unknownSectionId
            )
              return Number.MAX_VALUE;
            return a.sectionId - b.sectionId;
          })
          .map((phase) => ({ value: phase.name, id: phase.id })),
      },

      wellStatus: {
        categoryLabel: "Well Status",
        options: (options.wellStatus ?? []).map((option) => ({
          value: option.name,
          id: option.id,
        })),
      },
      rigType: {
        categoryLabel: "Rig Type",
        options: (options.rigType ?? []).map((type) => ({
          value: type.name,
          id: type.id,
        })),
      },
      horsePower: {
        categoryLabel: "Horsepower Rating",
        options: (options.horsePower ?? []).map((hp) => ({
          value: hp.name,
          id: hp.id,
        })),
      },
      operatingCenter: {
        categoryLabel: "Operating Centers",
        options: (options.operatingCenter ?? []).map((center) => ({
          value: center.name,
          id: center.id,
        })),
      },
      technology: {
        categoryLabel: "Technology",
        options: (options.technology ?? []).map((tech) => ({
          value: tech.id,
          id: tech.id,
        })),
      },
      country: {
        categoryLabel: "Country",
        options: (options.country ?? []).map((country) => ({
          value: country.name,
          id: country.id,
        })),
      },
    }),
    [directionalIntervals, holeSections, holeSizes, phases, options, uom],
  );

  const initialFilters = useMemo(
    () => ({
      rigType: null,
      horsePower: null,
      country: null,
      operatingCenter: null,
      technology: null,
      wellStatus: null,
      holeSection: allFilterOptions.holeSection.options,
      directionalInterval: allFilterOptions.directionalInterval.options,
      holeSize: allFilterOptions.holeSize.options,
      phases: allFilterOptions.phases.options,
    }),
    [
      allFilterOptions.directionalInterval.options,
      allFilterOptions.holeSection.options,
      allFilterOptions.holeSize.options,
      allFilterOptions.phases.options,
    ],
  );

  const [localFilters, setLocalFilters] = useState<IFilterOptions | null>(
    initialFilters,
  );

  const areFiltersReadyForInitialSelfSet = useMemo(
    () =>
      localFilters === null &&
      !filtersLoading &&
      phases &&
      !isLoadingWellAttributes.filterOptions,
    [
      filtersLoading,
      isLoadingWellAttributes.filterOptions,
      localFilters,
      phases,
    ],
  );

  useEffect(() => {
    if (areFiltersReadyForInitialSelfSet) {
      setLocalFilters(initialFilters);
      setFilters(initialFilters);
    }
  }, [
    areFiltersReadyForInitialSelfSet,
    initialFilters,
    setFilters,
    setLocalFilters,
  ]);

  const toggleFilter = (filterType: FilterTypes, optionId: string | number) => {
    setLocalFilters((prev) => {
      const newFilters: IFilterOptions = prev ? { ...prev } : INITIAL_FILTERS;

      if (!newFilters[filterType]) {
        // If this had no filters before meaning === null,
        // then set the list as all filters minus the unchecked one
        newFilters[filterType] = allFilterOptions[filterType]?.options?.filter(
          (opt) => opt.id !== optionId,
        );
      } else {
        if (
          // If this is freshly unchecked item
          newFilters[filterType]
            ?.map((allOptions) => allOptions.id)
            .includes(optionId)
        ) {
          newFilters[filterType] =
            newFilters[filterType]?.filter((item) => item.id !== optionId) ??
            [];
        } else {
          // If this is a checked item
          const filterItem = allFilterOptions[filterType]?.options?.find(
            (option) => option.id === optionId,
          );
          if (filterItem) {
            newFilters[filterType]?.push(filterItem);
          }
        }
      }
      return newFilters;
    });
  };

  return {
    initialFilters,
    allFilters: allFilterOptions,
    localFilters,
    setLocalFilters,
    toggleFilter,
  };
};
