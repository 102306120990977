/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigLeaderboardScoreGroupAco } from './RigLeaderboardScoreGroupAco';
import {
    RigLeaderboardScoreGroupAcoFromJSON,
    RigLeaderboardScoreGroupAcoFromJSONTyped,
    RigLeaderboardScoreGroupAcoToJSON,
} from './RigLeaderboardScoreGroupAco';
import type { RigLeaderboardScoreCardCommentAco } from './RigLeaderboardScoreCardCommentAco';
import {
    RigLeaderboardScoreCardCommentAcoFromJSON,
    RigLeaderboardScoreCardCommentAcoFromJSONTyped,
    RigLeaderboardScoreCardCommentAcoToJSON,
} from './RigLeaderboardScoreCardCommentAco';

/**
 * 
 * @export
 * @interface ScorecardKpiCommentSetAco
 */
export interface ScorecardKpiCommentSetAco {
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentSetAco
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentSetAco
     */
    wellName: string;
    /**
     * 
     * @type {Array<RigLeaderboardScoreCardCommentAco>}
     * @memberof ScorecardKpiCommentSetAco
     */
    comments: Array<RigLeaderboardScoreCardCommentAco>;
    /**
     * 
     * @type {Array<RigLeaderboardScoreGroupAco>}
     * @memberof ScorecardKpiCommentSetAco
     */
    summaries: Array<RigLeaderboardScoreGroupAco>;
}

/**
 * Check if a given object implements the ScorecardKpiCommentSetAco interface.
 */
export function instanceOfScorecardKpiCommentSetAco(value: object): value is ScorecardKpiCommentSetAco {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('wellName' in value) || value['wellName'] === undefined) return false;
    if (!('comments' in value) || value['comments'] === undefined) return false;
    if (!('summaries' in value) || value['summaries'] === undefined) return false;
    return true;
}

export function ScorecardKpiCommentSetAcoFromJSON(json: any): ScorecardKpiCommentSetAco {
    return ScorecardKpiCommentSetAcoFromJSONTyped(json, false);
}

export function ScorecardKpiCommentSetAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScorecardKpiCommentSetAco {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'wellName': json['WellName'],
        'comments': ((json['Comments'] as Array<any>).map(RigLeaderboardScoreCardCommentAcoFromJSON)),
        'summaries': ((json['Summaries'] as Array<any>).map(RigLeaderboardScoreGroupAcoFromJSON)),
    };
}

export function ScorecardKpiCommentSetAcoToJSON(value?: ScorecardKpiCommentSetAco | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'WellName': value['wellName'],
        'Comments': ((value['comments'] as Array<any>).map(RigLeaderboardScoreCardCommentAcoToJSON)),
        'Summaries': ((value['summaries'] as Array<any>).map(RigLeaderboardScoreGroupAcoToJSON)),
    };
}

