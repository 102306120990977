import { useQueryClient } from "@tanstack/react-query";
import { ClaraMessageSource } from "apis/oag";
import { IndividualResponse } from "components/Clara/MessageThread/IndividualResponse";
import type { Message } from "hooks/clara/useClara";
import { useClaraMessage, useClaraPrompts } from "hooks/clara/useClara";
import { useEffect } from "react";
import { useId } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

export const ModelResponse = ({ sessionId }: { sessionId: string }) => {
  const newId = useId();

  const {
    pendingMessage: message,
    id: currentId,
    favorite,
  } = useAppSelector((state) => state.claraReducer);
  const { data: prompts } = useClaraPrompts({
    select: (data) => data.filter((prompt) => prompt.type === "Favorite"),
  });
  const { data } = useClaraMessage(message);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const id = currentId ?? Math.trunc(Math.random() * 1000);

  useEffect(() => {
    if (!message) return;
    queryClient.setQueryData(
      [{ uid: RequestUID.clara, type: PDQueryType.CLARA_CONVERSATION_HISTORY }],
      (oldData: Array<Message>) => {
        const favoritePrompt = prompts?.find(
          (prompt) => prompt.prompt === message,
        );
        return [
          ...oldData,
          {
            message,
            source: ClaraMessageSource.User,
            id: favoritePrompt?.id ?? id,
            isFavorite: !!favoritePrompt,
          },
          {
            message: data.message,
            source: ClaraMessageSource.Model,
            id: data.id,
            isTyped: false,
          },
        ];
      },
    );
    queryClient.removeQueries({
      queryKey: [{ uid: RequestUID.clara, type: PDQueryType.CLARA_MESSAGE }],
      exact: false,
    });
    dispatch({ type: "RESET_PENDING_MESSAGE" });
  }, [
    data.id,
    data.message,
    dispatch,
    favorite,
    id,
    message,
    newId,
    prompts,
    queryClient,
  ]);

  return (
    <IndividualResponse
      message={{
        sessionId: sessionId,
        message: data.message,
        source: ClaraMessageSource.Model,
        id,
      }}
    />
  );
};
