import { Title } from "atoms/Typography";
import { SCROLLBAR_WIDTH_PX } from "components/Layout/LayoutUtils";
import { PDComponent } from "components/PDComponents";
import {
  ChevronDownIcon,
  SelectedOption,
  StyledButton,
} from "components/PDComponents/Dropdown/style";
import {
  ModalContainer,
  ModalContent,
} from "components/PDComponents/Modal/style";
import {
  CARD_PADDING,
  CARD_SUMMARY_HEIGHT,
  CARD_SUMMARY_PADDING,
  CARD_SUMMARY_VERTICAL_SPACE,
  EXCESS_HEIGHT,
} from "pages/FleetPerformance/components/utils";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Button, Card, Col, Radio, Row } from "utils/componentLibrary";
import { PdCardBody } from "utils/componentLibrary/Card";

export const StyledFleetPerformanceGrid = styled.div<{
  $hasOneLensDisplayed: boolean;
}>`
  gap: ${CARD_PADDING}px;
  width: 100%;

  ${({ $hasOneLensDisplayed }) =>
    $hasOneLensDisplayed
      ? css`
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          place-content: flex-start;
          height: 100%;
          flex-wrap: wrap;
        `
      : css`
          display: grid;
          grid-template-columns: repeat(3, 1fr);

          @media screen and (max-width: 1625px) {
            /* numbers to allow for a minimum width of 400px per lens */
            grid-template-columns: repeat(2, 1fr);
          }

          @media screen and (max-width: 1140px) {
            /* numbers to allow for a minimum width of 400px per lens */
            grid-template-columns: repeat(1, 1fr);
          }
        `}
`;

export const StyledFleetPerformanceSection = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  padding-top: ${CARD_PADDING}px;
  padding-left: ${CARD_PADDING}px;
  padding-right: ${CARD_PADDING - SCROLLBAR_WIDTH_PX}px;
  border-radius: 4px;
  max-height: calc(100vh - ${EXCESS_HEIGHT}px);
`;

export const StyledCard = styled(Card)<{
  $isExpanded?: boolean;
  $isSingleOnGrid?: boolean;
}>`
  ${PdCardBody} {
    padding: 0;
    height: 100%;
  }

  ${({ $isExpanded }) =>
    $isExpanded
      ? css`
          width: 100%;
          height: 100%;
        `
      : css`
          height: 410px;
          box-shadow: 0px 4px 6px ${colors.buttons_clicked};
        `}
  ${({ $isSingleOnGrid }) =>
    $isSingleOnGrid
      ? css`
          width: calc(100% - 20px);
          height: 100%;
          ${ViewContainer} {
            width: calc(100% - 30px);
          }
          ${StyledCardHeader} {
            width: calc(100% - 40px);
          }
        `
      : null}
`;

export const StyledModalBody = styled.div`
  width: 900px;
  height: 700px;
  box-shadow: 0px 4px 6px ${colors.buttons_clicked};
`;

export const StyledModal = styled(PDComponent.Modal)`
  height: 700px;

  ${ModalContent} {
    padding: 0;
  }
  ${ModalContainer} {
    height: 700px;
    width: 900px;

    ${PdCardBody} {
      height: 700px;
      width: 900px;
    }
  }
`;

export const StyledLTabsRow = styled(Row).attrs({
  align: "middle",
  gutter: 8,
})`
  padding-left: 0px;
`;

export const StyledLOptionsRow = styled(Row).attrs({
  justify: "space-between",
})`
  padding-right: 13px;
  padding-bottom: 16px;
`;

export const StyledDropdownContainer = styled(Col)`
  ${StyledButton}, ${StyledButton}:focus {
    background-color: ${({ theme: { isDark } }) =>
      isDark ? undefined : colors.white};
  }
`;

export const StyledLensTabButton = styled.button<{ $active?: boolean }>`
  padding: 15px 12px;
  margin-bottom: 15px;
  border: none;
  border-bottom: 4px solid transparent;
  outline: none;
  color: ${({ theme }) => theme.themeStyle.colors.light_typography};
  cursor: pointer;

  background-color: transparent;
  font-size: 16px;
  letter-spacing: -0.2px;

  &:hover {
    border-bottom: 4px solid
      ${({ theme }) => theme.themeStyle.colors.primary_button_bg};
  }

  ${(props) =>
    props.$active &&
    css`
      border-bottom: 4px solid
        ${({ theme }) => theme.themeStyle.colors.primary_button_bg};
      color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    `}
`;

export const StyledSummary = styled.div`
  display: flex;
  height: ${CARD_SUMMARY_HEIGHT}px;
  gap: 2px;
  flex-direction: column;
  align-items: start;
  ${SelectedOption} {
    font-size: 14px;
  }
  ${ChevronDownIcon} {
    opacity: 1;
  }
`;

export const ViewContainer = styled.div`
  position: relative;
  height: calc(100% - ${CARD_SUMMARY_VERTICAL_SPACE}px);
`;

export const StyledCardBody = styled.div<{ $isOnlyOne: boolean }>`
  position: relative;
  height: calc(100% - ${CARD_SUMMARY_VERTICAL_SPACE}px);
  display: grid;
  grid-template-rows: ${({ $isOnlyOne }) => ($isOnlyOne ? "1fr" : "1.2fr 1fr")};
`;
export const SelectedTooltipValue = styled.span`
  color: ${colors.well_color_light};
`;

export const StyledKpiTitle = styled(Title).attrs({
  level: 4,
  variant: "faded",
})`
  font-weight: 400;
`;

export const StyledAverage = styled(Title).attrs({
  level: 3,
})`
  font-weight: 500 !important;
`;

export const RadioButtonGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    height: 24px;
    width: 24px;
    padding: 0;
    .ant-radio-button.ant-radio-button-checked {
      background-color: transparent;
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${({ theme }) =>
        theme.themeStyle.colors.primary_button_bg};
    }
  }
  .ant-radio-button-wrapper svg {
    width: 22px;
    color: ${({ theme }) =>
      theme.isDark ? colors.white : theme.themeStyle.colors.primary_scroll_bg};
  }
  .ant-radio-button-wrapper-checked svg {
    color: ${colors.white};
  }
`;

export const StyledExpandButton = styled(Button)`
  padding: 0;
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const StyledCardHeader = styled(Row).attrs({
  justify: "space-between",
  align: "top",
})`
  padding: 16px ${CARD_SUMMARY_PADDING}px 0px 16px;
`;
