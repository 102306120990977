import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import styled from "styled-components";
import { Card } from "utils/componentLibrary";
import { PdCardBody } from "utils/componentLibrary/Card";

export const ChevronDownIcon = styled(PDComponent.SvgIcon).attrs({
  name: "chevronDown",
})`
  opacity: 0;
`;

export const Option = styled.div`
  width: 100%;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  cursor: pointer;
`;

export const SelectedOperator = styled(Title).attrs({
  level: 5,
})`
  font-size: 14px !important;
  :hover {
    cursor: pointer;

    ${ChevronDownIcon} {
      opacity: 1;
    }
  }
`;

export const StyledCard = styled(Card)`
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  min-width: 186px;
  ${PdCardBody} {
    padding: 0;
  }
`;
