import type { CountryDto, RigDto } from "apis/oag";
import { WellStatusType } from "apis/oag";
import { useCountries } from "hooks/drillingInvariants/useCountries";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { useMemo } from "react";
import { RigTechnology } from "utils/enums";

export const idToCountry = (id: number, countries: CountryDto[]) => {
  const country = countries?.find((c) => c.id === id);
  return country ? country.name || "" : id.toString();
};

export const getWellStatusFilterOptions = (
  wellStatuses = [
    WellStatusType.Pending,
    WellStatusType.Active,
    WellStatusType.Paused,
    WellStatusType.Completed,
  ],
) => wellStatuses.map((status) => ({ value: status, id: status }));

export const TechnologyFilterOptions = [
  RigTechnology.Alpha,
  RigTechnology.NonAlpha,
].map((tech) => ({
  value: tech,
  id: tech,
}));

export const getHorsepowerFilterOptions = (rigs: RigDto[]) =>
  [...new Set(rigs.map((rig) => rig.horsePower.toString()))].map((val) => ({
    value: `${val} hp`,
    id: val,
  }));

export const getOperatingCenterFilterOptions = (rigs: RigDto[]) =>
  [...new Set(rigs.map((rig) => rig.operatingCenter))].map((center) => ({
    value: center,
    id: center,
  }));

export const getRigTypeFilterOptions = (rigs: RigDto[]) =>
  [...new Set(rigs.map((rig) => rig._class))].map((type) => ({
    value: type,
    id: type,
  }));

export const getCountryFilterOptions = (
  rigs: RigDto[],
  countries: CountryDto[],
) =>
  [...new Set(rigs.map((rig) => rig.countryId.toString()))].map((country) => ({
    value: idToCountry(+country, countries),
    id: country,
  }));

export const useAsyncInitialAttributeFilterOptions = () => {
  const { data: rigsData, isLoading: isRigsLoading } = useRigs();
  const rigs = useMemo(() => rigsData?.list ?? [], [rigsData]);
  const { data: countries, isLoading: isCountriesLoading } = useCountries();

  const wellStatus = useMemo(() => getWellStatusFilterOptions(), []);
  const horsePower = useMemo(() => getHorsepowerFilterOptions(rigs), [rigs]);
  const operatingCenter = useMemo(
    () => getOperatingCenterFilterOptions(rigs),
    [rigs],
  );
  const type = useMemo(() => getRigTypeFilterOptions(rigs), [rigs]);

  const country = useMemo(
    () => (isCountriesLoading ? [] : getCountryFilterOptions(rigs, countries)),
    [isCountriesLoading, rigs, countries],
  );

  const isLoading = useMemo(
    () => isRigsLoading || isCountriesLoading,
    [isRigsLoading, isCountriesLoading],
  );

  const filters = useMemo(
    () =>
      isLoading
        ? null
        : {
            wellStatus,
            horsePower,
            operatingCenter,
            type,
            country,
            technology: TechnologyFilterOptions,
          },
    [isLoading, wellStatus, horsePower, operatingCenter, type, country],
  );

  return {
    isLoading,
    filters,
  };
};
