import type {
  AllRigsQueryDto,
  ApiFiltersOperatorsByRigsPutRequest,
  ApiOperatorsAccountsPutRequest,
  ApiOperatorsIdUsersPutRequest,
  ApiRigsWellIdsPutRequest,
  ApiScorecardKpiCommentsIdWithFactsGetRequest,
  ApiWellsIdInspectionDetailsPutRequest,
  ApiWellsIdRangesPutRequest,
  ApiWellsIdTvdsPutRequest,
  DateDto,
  FuelType,
  IntelGroupingType,
  LeaderboardFactQueryDto,
  PivotOrderType,
  PivotType,
  RigFleetPerformanceFactQueryDto,
  RigScorecardFactQueryDto,
  UserQueryDto,
  WellAccessQueryDto,
  WellStatusType,
} from "apis/oag";
import type { DashboardType } from "apis/oag/models/DashboardType";
import type { GasType } from "apis/oag/models/GasType";
import type { RangeType } from "atoms/DatePicker";
import type { IFilterOptions } from "reducers/rigsCommonReducer";

export enum PDQueryType {
  FACTS = "FACTS",
  LENS = "LENS",
  LENS_POSITIONS = "LENS_POSITIONS",
  // To query what filters are available for a specific lens template type
  // Some components require a set of specific hole sizes for ex to be displayed
  LENS_FILTERS = "LENS_FILTERS",
  RIG_OPERATION_TIME = "RIG_OPERATION_TIME",
  RIGS_ACTIVE_OPERATORS = "RIGS_ACTIVE_OPERATORS",
  WELL_OVERVIEW = "WELL_OVERVIEW",
  WELL_OVERVIEW_ZOOM = "WELL_OVERVIEW_ZOOM",
  WELL_ACTUAL_TIMELINE = "WELL_ACTUAL_TIMELINE",
  OPERATORS_ACTIVE_RIG = "OPERATORS_ACTIVE_RIG",
  REFERENCE_FILTERS = "REFERENCE_FILTERS",
  RIGS_KPI = "RIGS_KPI",
  RIGS_SUMMARIES = "RIGS_SUMMARIES",
  RIG_LEADERBOARD = "RIG_LEADERBOARD",
  RIGS_WELLS = "RIGS_WELLS",
  RIGS_ACTIVE_WELLS = "RIGS_ACTIVE_WELLS",
  WELL_BASIC = "WELL_BASIC",
  WELL_DETAILS = "WELL_DETAILS",
  WELL_LEGEND_DETAILS = "WELL_LEGEND_DETAILS",
  WELL_PLAN_TIMELINE = "WELL_PLAN_TIMELINE",
  WELL_PLAN = "WELL_PLAN",
  WELL_RANGES = "WELL_RANGES",
  WELL_INCLUDED_WELLS_KPIS = "WELL_INCLUDED_WELLS_KPIS",
  DATA_FEED = "DATA_FEED",
  COPY_FROM_MODAL = "COPY_FROM_MODAL",
  USER_LENS_TABS = "USER_LENS_TAB",
  WELL_QUICKSEARCH = "WELL_QUICKSEARCH",
  WELL_SUMMARIES = "WELL_SUMMARIES",
  WELL_TVD_POINTS = "WELL_TVD_POINTS",
  LINKED_FILTERS = "LINKED_FILTERS",
  LINKED_FILTERS_INTEL = "LINKED_FILTERS_INTEL",
  LINKED_FILTERS_INTEL_QUARTER_OPTIONS = "LINKED_FILTERS_INTEL_QUARTER_OPTIONS",
  OPERATORS_ACCOUNTS = "OPERATORS_ACCOUNTS",
  OPERATORS_ACCOUNT_USERS = "OPERATORS_ACCOUNT_USERS",
  ADMIN_ACCOUNTS_WELL_ACCESS = "ADMIN_ACCOUNTS_WELL_ACCESS",
  ADMIN_USERS_SINGLE = "ADMIN_USERS_SINGLE",
  ADMIN_USERS_ACCESS_INFO = "ADMIN_USERS_ACCESS_INFO",
  ADMIN_USERS_WELL_ACCESS = "ADMIN_USERS_WELL_ACCESS",
  ADMIN_USERS_LIST = "ADMIN_USERS_LIST",
  USERS_WELL_APPROVERS = "USERS_WELL_APPROVERS",
  WELLS_DATA_FEED = "WELLS_DATA_FEED",
  KPI_GROUP_PIVOT_FACTS_MOCK = "KPI_GROUP_PIVOT_FACTS_MOCK",
  WELLS_COMMENTS = "WELLS_COMMENTS",
  WELLS_INSPECTION_DETAILS = "WELLS_INSPECTION_DETAILS",
  CLARA_CREATE_SESSION = "CLARA_CREATE_SESSION",
  CLARA_MESSAGE = "CLARA_MESSAGE",
  CLARA_CONVERSATION_HISTORY = "CLARA_CONVERSATION_HISTORY",
  CLARA_CONVERSATION_PROMPTS = "CLARA_CONVERSATION_PROMPTS",
  STAND_COMMENTS = "STAND_COMMENTS",
}

// Explicitly type query characteristics so they can be handled better via built-in useQuery filters
// at preload/refetch/cancel/invalidate time
export enum RequestUID {
  allLens = "allLens",
  clara = "clara",
  intelLenses = "intelLenses",
  allProfileRecipients = "allProfileRecipients",
  lensPositions = "lensPositions",
  availableFilters = "hooks/availableFilters",
  torqueAndDrag = "torqueAndDrag",
  torqueAndDragBroomstickList = "torqueAndDrag/broomsticks/list",
  torqueAndDragBroomstickListType = "torqueAndDrag/broomsticks/list/type/", // by id
  formations = "formations/list",
  activeFormations = "activeFormations",
  lensTemplates = "lenses/templates",
  rigOperationTimes = "rigs/operationTimes",
  wellOverviewZoom = "well/overview/zoom",
  wellOverview = "well/overview",
  holeSectionNames = "holeSections/names",
  countries = "countries",
  crewMemberNames = "crewMembers/names",
  operationClassesNames = "operationClasses/names",
  directionalIntervals = "directionalIntervals/names",
  basins = "basins",
  holeSizes = "holeSizes/names",
  kpiGroups = "kpiGroups",
  kpiTypes = "kpiTypes",
  phases = "phases/names",
  referenceFilters = "referenceFilters/names",
  rigsKpi = "rigs/kpi",
  rigsList = "rigs/list",
  generatorsList = "generators/list",
  rigsActiveOperators = "rigs/activeOperators",
  rigsSummaries = "rigs/summaries",
  rigsWells = "rigs/wells/mapping",
  shift = "shift",
  tracks = "tracks/list",
  wellActualTimeline = "well/actualTimeline",
  wellBasic = "well/basic",
  wellDetails = "well/details",
  wellIncludedWellsKpi = "well/includedWells/kpi",
  wellPlan = "wellPlan",
  wellLegendDetails = "well/legend/details",
  wellPlanTimeline = "well/planTimeline",
  wellShortInfo = "well/shortInfo",
  copyFromModal = "copyFrom/availableIds",
  userWhoAmI = "user/whoami",
  userWellAccess = "user/wellaccess",
  operatorsList = "operators/list",
  userLensTabs = "user/lensTabs",
  wellQuicksearch = "well/quicksearch",
  wellSummaries = "well/summaries",
  wellTvdPoints = "well/tvdpoints",
  operatorsAccounts = "operators/accounts",
  operatorsAccountUsers = "operators/account/users",
  operatorsActiveRigs = "operators/activeRigs",
  adminAccountsWellAccess = "admin/accounts/wellaccess",
  adminUsersSingle = "admin/users/single",
  adminUsersAccessInfo = "admin/users/accessinfo",
  adminUsersWellAccess = "admin/users/wellaccess",
  adminUsersList = "admin/users/list",
  wellRanges = "well/ranges",
  wellInspectionDetails = "well/inspectionDetails",
  pivotFactsOrder = "rig/pivotFactsOrder",
  approvers = "approvers",
  shareRecipients = "share/recipients",
  standComments = "standComments",

  // Rig cards
  rigsCardFacts = "rigsCard/Facts",
  rigsCardKpitypes = "rigsCard/Kpitypes",
  rigsCardLens = "rigsCard/Lens",
  wellsComments = "wells/comments",
  rigLeaderboard = "rig/leaderboard",
  lensNavigationFilters = "rigCard/navigation/filters",

  // Facts
  wellDrillingSummaryFacts = "kpiWellDrillingSummaryFacts",
  kpiGroupStackedFacts = "kpiGroupStackedFacts",
  kpiTypeFacts = "kpiTypeFacts",
  WedgeFacts = "WedgeFacts",
  kpiGroupPivotFacts = "kpiGroupPivotFacts",
  kpiGroupPivotFactsMock = "kpiGroupPivotFactsMock",
  wellsDataFeed = "datafeed",
  rigsDataFeed = "rigsDataFeed",
  rigsCommonDataFeed = "rigsCommonDataFeed",
  intelRankingRibbon = "intelRankingRibbon",
  intelScatterPlot = "intelScatterPlot",
  intelScorecard = "intelScorecard",

  drillingProductivityFacts = "drillingProductivity/Facts",
  batteryStateFacts = "batteryState/Facts",
  boilerFuelRateFacts = "boilerFuelRate/Facts",
  dieselNatGasRatioFacts = "dieselNatGasRatio/Facts",
  fuelConsumptionFacts = "fuelConsumption/Facts",
  fuelRateFacts = "fuelRate/Facts",
  fuelTankVolumeFacts = "fuelTankVolume/Facts",
  generatorLoadProfileFacts = "generatorLoadProfileFacts",
  generatorStatsFacts = "generatorStats/Facts",
  generatorStatusBarFacts = "generatorStatusBarFacts/Facts",
  naturalGasFacts = "naturalGasFacts",
  powerConsumptionByOperationFacts = "powerConsumptionByOperation/Facts",
  powerLoadDistributionFacts = "powerLoadDistribution/Facts",
  powerLoadEfficiencyFacts = "powerLoadEfficiency/Facts",
  rigPowerFacts = "rigPowerFacts",
  statCardFacts = "statCardFacts",

  parameterByDepthFacts = "ParameterByDepth/Facts",
  stickSlipByDepthFacts = "StickSlipByDepth/Facts",
  parameterHeatmapFacts = "ParameterHeatmap/Facts",
  StickSlipByTime = "stickSlipByTime/Facts",
  StickSlipByTimeSummary = "stickSlipByTime/Summary",
  tagBottomFingerprintFacts = "TagBottomFingerprint/Facts",

  // Notifications
  detailedNotifications = "notifications/detailed",
  archivedNotifications = "notifications/archived",
  archivedNotificationsCount = "notifications/archived/count",
  activeNotifications = "notifications/active",
  notificationSubscriptions = "notificationSubscriptions",
  tasksCount = "tasks/count",
  pendingTasks = "tasks/pending",
}

export interface PDUniqueQueryKey {
  uid: RequestUID;
}
interface BaseQueryKey<PDQueryType> extends PDUniqueQueryKey {
  type: PDQueryType;
}

export interface PDLensFactsQueryKey<T = void>
  extends BaseQueryKey<PDQueryType.FACTS> {
  lensId: number;
  params?: T;
  lensKpiType?: string;
  wellId?: number;
  comparisonType?: "comparison" | "single";
}
export interface PDIntelFactsQueryKey<T = void> extends PDLensFactsQueryKey<T> {
  lensGrouping: IntelGroupingType;
}

export interface PDLensPositionsByDashboardTypeQueryKey
  extends BaseQueryKey<PDQueryType.LENS_POSITIONS> {
  dashboardType: DashboardType;
}

export interface PDLensStatCardFactsQueryKey<T = void>
  extends BaseQueryKey<PDQueryType.FACTS> {
  lensId: number;
  params?: T;
  wellId?: number;
  selectedFuel?: FuelType;
  selectedGas?: GasType;
}

export interface PDLensFiltersQueryKey<T = void>
  extends BaseQueryKey<PDQueryType.LENS_FILTERS> {
  dashboardType?: DashboardType;
  params?: T;
  offsetWells?: number[];
}

export interface PDOperationTimeQueryKey
  extends BaseQueryKey<PDQueryType.RIG_OPERATION_TIME> {
  periodState: RangeType;
  params: AllRigsQueryDto;
}

export interface PDWellReferenceFiltersQueryKey
  extends BaseQueryKey<PDQueryType.REFERENCE_FILTERS> {
  selectedWell: number;
  offsetWells: number[];
  rigSelectedWells: number[];
  dashboardType: DashboardType;
}

export interface PDWellOverviewZoomQueryKey
  extends BaseQueryKey<PDQueryType.WELL_OVERVIEW_ZOOM> {
  offsetWells: number[];
  wellId: number;
}

export interface PDRigsKpiQueryKey extends BaseQueryKey<PDQueryType.RIGS_KPI> {
  params: AllRigsQueryDto;
}

export interface PDRigScorecardFactsQueryKey
  extends BaseQueryKey<PDQueryType.FACTS> {
  params: RigScorecardFactQueryDto;
}
export interface PDDetailedNotificaionsQueryKey
  extends BaseQueryKey<PDQueryType.FACTS> {
  params: ApiScorecardKpiCommentsIdWithFactsGetRequest;
}

export interface PDStandCommentsKey
  extends BaseQueryKey<PDQueryType.STAND_COMMENTS> {
  wellId: number[] | undefined;
  lensId: number;
}
export interface PDRigFleetPerformanceFactQueryDtokey
  extends BaseQueryKey<PDQueryType.FACTS> {
  params: RigFleetPerformanceFactQueryDto;
}

export interface PDWellCommentsQueryKey
  extends BaseQueryKey<PDQueryType.WELLS_COMMENTS> {
  params: number[];
}

export interface PDRigsActiveOperatorsQueryKey
  extends BaseQueryKey<PDQueryType.RIGS_ACTIVE_OPERATORS> {
  params: ApiFiltersOperatorsByRigsPutRequest;
}

export interface PDRigsSummariesQueryKey
  extends BaseQueryKey<PDQueryType.RIGS_SUMMARIES> {
  params: AllRigsQueryDto;
}

export interface PDWellInspectionDetailsKey
  extends BaseQueryKey<PDQueryType.WELLS_INSPECTION_DETAILS> {
  params: ApiWellsIdInspectionDetailsPutRequest;
}

export interface PDRigLeaderboardQueryKey
  extends BaseQueryKey<PDQueryType.RIG_LEADERBOARD> {
  params: LeaderboardFactQueryDto;
}

export interface PDRigsWellsQueryKey
  extends BaseQueryKey<PDQueryType.RIGS_WELLS> {
  selectedRig: number;
  offsetRigs: number[];
  from: DateDto;
  to: DateDto;
  isFilteringIgnored: boolean;
}

export interface PDRigsActiveWellsQueryKey
  extends BaseQueryKey<PDQueryType.RIGS_ACTIVE_WELLS> {
  isFilteringIgnored: boolean;
  requestBody: ApiRigsWellIdsPutRequest;
}

export interface PDWellOverviewQueryKey
  extends BaseQueryKey<PDQueryType.WELL_OVERVIEW> {
  wellDataID: number;
  params: ApiWellsIdTvdsPutRequest | null;
}
export interface PDWellActualTimelineQueryKey
  extends BaseQueryKey<PDQueryType.WELL_ACTUAL_TIMELINE> {
  wellDataID: number;
}

export interface PDWellBasicQueryKey
  extends BaseQueryKey<PDQueryType.WELL_BASIC> {
  wellId: number;
}

export interface PDWellDetailsQueryKey
  extends BaseQueryKey<PDQueryType.WELL_DETAILS> {
  wellId: number;
}

export interface PDWellLegendDetailsQueryKey
  extends BaseQueryKey<PDQueryType.WELL_LEGEND_DETAILS> {
  wellId: number;
}

export interface PDWellPlanTimelineQueryKey
  extends BaseQueryKey<PDQueryType.WELL_PLAN_TIMELINE> {
  wellDataID: number;
}

export interface PDDataFeedQueryKey
  extends BaseQueryKey<PDQueryType.WELL_PLAN_TIMELINE> {
  wellDataID: number;
}

export interface PDCopyFromModalQueryKey
  extends BaseQueryKey<PDQueryType.COPY_FROM_MODAL> {
  queryType: "formations" | "drillingParameters" | "overview";
}
export interface PDWellIncludedWellsKpisQueryKey
  extends BaseQueryKey<PDQueryType.WELL_INCLUDED_WELLS_KPIS> {
  wellIds: number[];
}

export interface PDUserLensTabsQueryKey
  extends BaseQueryKey<PDQueryType.USER_LENS_TABS> {
  dashboardType: DashboardType | null;
}

export interface PDWellPlanQueryKey
  extends BaseQueryKey<PDQueryType.WELL_PLAN> {
  id: number;
}
export interface PDWellQuicksearchQueryKey
  extends BaseQueryKey<PDQueryType.WELL_QUICKSEARCH> {
  query: string;
}

export interface PDWellSummariesQueryKey
  extends BaseQueryKey<PDQueryType.WELL_SUMMARIES> {
  query: {
    options: {
      rigs: { id: number; name: string }[] | null;
      operators: { id: number; name: string }[] | null;
      formations: { id: number; name: string }[] | null;
      wells: { id: number; name: string }[] | null;
    };
    searchState: string;
    startDate: Date;
    endDate: Date;
    isEvergreen: boolean;
    statusState: WellStatusType[];
    filterState: IFilterOptions | null;
  };
}

export interface PDWellTvdPointsQueryKey
  extends BaseQueryKey<PDQueryType.WELL_TVD_POINTS> {
  wellId: number;
}

export interface PDOperatorsAccountsQueryKey
  extends BaseQueryKey<PDQueryType.OPERATORS_ACCOUNTS> {
  params: ApiOperatorsAccountsPutRequest;
}

export interface PDOperatorsAccountUsersQueryKey
  extends BaseQueryKey<PDQueryType.OPERATORS_ACCOUNT_USERS> {
  params: ApiOperatorsIdUsersPutRequest;
}

export interface PDAdminAccountsWellAccessQueryKey
  extends BaseQueryKey<PDQueryType.ADMIN_ACCOUNTS_WELL_ACCESS> {
  params: WellAccessQueryDto;
  paramsUid: number;
}

export interface PDAdminUsersSingleQueryKey
  extends BaseQueryKey<PDQueryType.ADMIN_USERS_SINGLE> {
  userId: number;
}

export interface PDAdminUsersAccessInfoQueryKey
  extends BaseQueryKey<PDQueryType.ADMIN_USERS_ACCESS_INFO> {
  userId: number;
}

export interface PDAdminUsersWellAccessQueryKey
  extends BaseQueryKey<PDQueryType.ADMIN_USERS_WELL_ACCESS> {
  params: WellAccessQueryDto;
  userId: number;
}

export interface PDAdminUsersListQueryKey
  extends BaseQueryKey<PDQueryType.ADMIN_USERS_LIST> {
  params: UserQueryDto | null;
}

export interface PDWellRangesQueryKey
  extends BaseQueryKey<PDQueryType.WELL_RANGES> {
  params: ApiWellsIdRangesPutRequest;
}

export interface PDKpiGroupPivotFactsMockQueryKey
  extends BaseQueryKey<PDQueryType.KPI_GROUP_PIVOT_FACTS_MOCK> {
  pivots: PivotType[];
  pivotLevelOrder: PivotOrderType[];
}

export interface PDWellsDataFeedQueryKey
  extends BaseQueryKey<PDQueryType.WELLS_DATA_FEED> {
  isDetailed: boolean;
  wellId: number;
  offsetWells: number[];
}

export interface PDUsersWellApproversQueryKey
  extends BaseQueryKey<PDQueryType.USERS_WELL_APPROVERS> {
  wellId: number;
}
