import {
  DashboardType,
  DimensionType,
  NotificationType,
  RigScorecardLensType,
  ScorecardExceptionStatusType,
  TimeUnit,
} from "apis/oag";
import { Title } from "atoms/Typography";
import { Chart } from "components/Notification/Chart";
import { PDComponent } from "components/PDComponents";
import dayjs from "dayjs";
import { useUserDashboard } from "hooks/dashboard/useUserLensTabs";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { URL_STATE_PARAM } from "hooks/navigation/useQueryState";
import { useDetailedNotification } from "hooks/notifications/useDetailedNotification";
import { useDetailedNotificationsActions } from "hooks/notifications/useDetailedNotificationsActions";
import { useNotificationsActions } from "hooks/notifications/useNotificationsActions";
import { useWellShortInfo } from "hooks/wells/useWellShortInfo";
import { cardKpiTypesMap } from "pages/FleetPerformance/components/utils";
import * as Styled from "pages/Notifications/components/style";
import { GroupTitle } from "pages/RigScoreCard/LeftPane/components/GroupTitle";
import { useScoreCardTags } from "pages/RigScoreCard/LeftPane/utils";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";
import {
  SHORT_DATE_FORMAT,
  useTimeUom,
  useUOM,
  UtilDimensions,
} from "utils/format";
import type { BaseNotificationVm } from "utils/notifications/mappers";

export const ModalBody: React.FC<{
  notification: BaseNotificationVm | null;
}> = ({ notification: selectedNotification }) => {
  const { data: rigs } = useRigs();
  const { data: wellShortInfo } = useWellShortInfo();
  const rig = selectedNotification
    ? rigs.byId[selectedNotification?.rigId]
    : null;
  const well = selectedNotification
    ? wellShortInfo?.byId[selectedNotification?.wellId]
    : null;

  const { data: detailedNotification } = useDetailedNotification(
    selectedNotification?.kpiCommentId,
  );

  const user = useAppSelector((state) => state.global.userInfo);

  const isApprover = useMemo(
    () => detailedNotification?.comment?.exceptionApproverId === user?.id,
    [detailedNotification?.comment?.exceptionApproverId, user?.id],
  );

  const rigCardFactSeries = useMemo(
    () => detailedNotification?.factSet?.cardGroups?.[0]?.facts?.[0] ?? null,
    [detailedNotification?.factSet],
  );

  const isWellScoreCard =
    detailedNotification?.comment?.scorecardType ===
    RigScorecardLensType.OverallWellScores;

  const defaultUom = useUOM(rigCardFactSeries?.dimension);
  const percentageUom = useUOM(UtilDimensions.Percentage);
  const dayUom = useTimeUom(TimeUnit.Day);
  const hoursUOM = useTimeUom(TimeUnit.Hour);

  const uom = useMemo(() => {
    if (isWellScoreCard) return percentageUom;
    if (rigCardFactSeries?.dimension === DimensionType.Undefined)
      return percentageUom;
    if (rigCardFactSeries?.kpiType === cardKpiTypesMap.LocationRigMoveTime)
      return dayUom;
    if (
      rigCardFactSeries?.kpiType &&
      [
        cardKpiTypesMap.WalkSpudTime,
        cardKpiTypesMap.WalkTime,
        cardKpiTypesMap.NippleTimeSurface,
        cardKpiTypesMap.NippleTimeIntermediate,
        cardKpiTypesMap.NippleTimeTotal,
        cardKpiTypesMap.PressureTestTimeIntermediate,
        cardKpiTypesMap.PressureTestTimeSurface,
        cardKpiTypesMap.PressureTestTimeTotal,
        cardKpiTypesMap.SlipCutTime,
      ].includes(rigCardFactSeries?.kpiType)
    )
      return hoursUOM;

    return defaultUom;
  }, [
    isWellScoreCard,
    percentageUom,
    rigCardFactSeries?.dimension,
    rigCardFactSeries?.kpiType,
    dayUom,
    hoursUOM,
    defaultUom,
  ]);

  const { data: kpiTypes } = useKpiTypes();
  const lensName = useMemo(() => {
    if (isWellScoreCard) {
      return "Well Scores";
    }
    const lens = detailedNotification?.comment?.kpiType
      ? kpiTypes?.byId?.[detailedNotification?.comment?.kpiType]
      : null;

    return lens?.shortName || lens?.name || "";
  }, [isWellScoreCard, detailedNotification?.comment?.kpiType, kpiTypes?.byId]);

  const { markRead } = useNotificationsActions();

  const { data: lensTabsDashboard } = useUserDashboard(
    DashboardType.RigScorecard,
  );
  const tabTitle =
    useMemo(
      () =>
        [
          ...(lensTabsDashboard?.operatorTabs || []),
          ...(lensTabsDashboard?.personalTabs || []),
        ].find(
          (t) => t.id === detailedNotification?.factSet?.cardGroups?.[0]?.tabId,
        ),
      [
        detailedNotification?.factSet?.cardGroups,
        lensTabsDashboard?.operatorTabs,
        lensTabsDashboard?.personalTabs,
      ],
    )?.name ?? "";

  useEffect(() => {
    if (selectedNotification && !selectedNotification?.isRead) {
      markRead([selectedNotification.id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNotification]);
  const urlRoutingIds = useMemo(
    () => ({
      cardGroupId: detailedNotification?.factSet?.cardGroups?.[0].tabId,
      kpiId: rigCardFactSeries?.kpiType,
    }),
    [detailedNotification?.factSet?.cardGroups, rigCardFactSeries?.kpiType],
  );

  const shouldShowControls =
    // selectedNotification?.type === NotificationType.ExceptionRequest &&
    isApprover &&
    detailedNotification?.comment?.exceptionStatus ===
      ScorecardExceptionStatusType.Pending;

  const { handleOnReject, handleOnApprove } = useDetailedNotificationsActions(
    detailedNotification?.comment?.id || -1,
  );

  const [icon, message] = useMemo(() => {
    switch (detailedNotification?.comment?.exceptionStatus) {
      case ScorecardExceptionStatusType.Approved:
        return [
          <PDComponent.SvgIcon
            name="checkmarkFilled"
            style={{ fontSize: 16 }}
            key={ScorecardExceptionStatusType.Approved}
          />,
          "Exception approved.",
        ];
      case ScorecardExceptionStatusType.Rejected:
        return [
          <PDComponent.SvgIcon
            name="forbidden"
            style={{ fontSize: 16 }}
            key={ScorecardExceptionStatusType.Rejected}
          />,
          "Exception Rejected.",
        ];
      case ScorecardExceptionStatusType.Pending:
        return [
          <PDComponent.SvgIcon
            name="warning"
            style={{ color: colors.exciting_orange, fontSize: 16 }}
            key={ScorecardExceptionStatusType.Pending}
          />,
          "Exception waiting for approval.",
        ];
      default:
        return [null, null];
    }
  }, [detailedNotification?.comment?.exceptionStatus]);

  const { getTagsByAveragesOrFact } = useScoreCardTags({ borderless: true });
  const tags = useMemo(() => {
    const tags = getTagsByAveragesOrFact({
      averages: detailedNotification?.factSet?.cardGroups?.[0]?.averages,
    });

    return isWellScoreCard
      ? [tags.score, tags.opportunityTime]
      : [tags.score, tags.opportunityTime, tags.timeSaved];
  }, [
    getTagsByAveragesOrFact,
    detailedNotification?.factSet?.cardGroups,
    isWellScoreCard,
  ]);

  if (!selectedNotification) return null;

  const detailedNotificationTimestamp =
    detailedNotification?.comment?.updatedAt?.utc ??
    detailedNotification?.comment?.createdAt?.utc;
  return (
    <>
      <Styled.ModalLeft>
        <Styled.RigTitleContainer>
          <Styled.NotificationTitle
            $isRead={selectedNotification?.isRead}
            $notificationType={selectedNotification?.type}
          >
            <Styled.IconWrapper $notificationType={selectedNotification?.type}>
              {selectedNotification?.type ===
              NotificationType.ScorecardComment ? (
                <PDComponent.SvgIcon name="chat" />
              ) : (
                <PDComponent.SvgIcon
                  name="exceptionNote"
                  width="42"
                  height="46"
                />
              )}
            </Styled.IconWrapper>
          </Styled.NotificationTitle>
          <Title level={3}>
            <strong>{rig?.shortName}</strong>
          </Title>
        </Styled.RigTitleContainer>
      </Styled.ModalLeft>
      <Styled.ModalRight>
        <Styled.ModalTopContainer>
          <Styled.InfoContainer>
            <Styled.TitleContainer>
              <Styled.NotificationTitle
                $isRead
                $notificationType={selectedNotification?.type}
              >
                {selectedNotification.popupTitle}
              </Styled.NotificationTitle>
              <Styled.WellContainer>
                <Styled.WellName>
                  <Title level={3}>
                    <strong>{well?.name}</strong>

                    {(selectedNotification?.jobExternalIds || [])?.length >
                    0 ? (
                      <Styled.JobDetailed>
                        <Title level={3} variant="faded">
                          Job:{" "}
                          {(selectedNotification?.jobExternalIds || [])?.join(
                            ", ",
                          )}
                        </Title>
                      </Styled.JobDetailed>
                    ) : null}
                  </Title>
                </Styled.WellName>
              </Styled.WellContainer>

              <Styled.KpiTitle>
                {tabTitle}{" "}
                {detailedNotification?.comment?.scorecardType ===
                RigScorecardLensType.VerticalOnlyNoSurface
                  ? "(Excluding Surface)"
                  : ""}
              </Styled.KpiTitle>

              <Styled.LensTitle>
                {lensName}:{" "}
                {uom.display(
                  isWellScoreCard
                    ? detailedNotification?.factSet?.cardGroups?.[0]?.facts?.[0]
                        .facts?.[0].operatorScore?.value ?? 0
                    : detailedNotification?.factSet?.cardGroups?.[0]?.facts?.[0]
                        .facts?.[0].kpiValue?.value ?? 0,
                )}
              </Styled.LensTitle>
              {!isWellScoreCard ? (
                <Styled.KpiSummaryContainer>
                  <Styled.SummaryItem>
                    <Styled.SummaryTitle>Target: </Styled.SummaryTitle>
                    <Styled.SummaryValue>
                      {uom.display(
                        detailedNotification?.factSet?.cardGroups?.[0]?.averages
                          ?.targetValue,
                      )}
                    </Styled.SummaryValue>
                  </Styled.SummaryItem>
                  <Styled.SummaryItem>
                    <Styled.SummaryTitle>Base Value: </Styled.SummaryTitle>
                    <Styled.SummaryValue>
                      {uom.display(
                        detailedNotification?.factSet?.cardGroups?.[0]?.averages
                          ?.baseValue,
                      )}
                    </Styled.SummaryValue>
                  </Styled.SummaryItem>
                </Styled.KpiSummaryContainer>
              ) : null}
              <GroupTitle inner title={""} withTagSeparators tags={tags} />
              <Styled.SeparatorLine />
            </Styled.TitleContainer>
          </Styled.InfoContainer>
          <Styled.ModalChartContainer>
            <Chart
              notificationId={selectedNotification?.id}
              lensName={lensName}
              facts={detailedNotification?.factSet}
              uom={uom}
              isWellScoreCard={isWellScoreCard}
            />
            <Styled.ScorecardButton>
              <Link
                to={{
                  pathname: `/rig/scorecard/${selectedNotification?.rigId}`,
                  search: [
                    `${URL_STATE_PARAM.ADDITIONAL_SELECTED_WELLS_RIG_SCORECARD}=${selectedNotification?.wellId}`,
                    `${URL_STATE_PARAM.NOTIFICATION_SCORE_CARD_GROUP_ID}=${urlRoutingIds.cardGroupId}`,
                    `${URL_STATE_PARAM.NOTIFICATION_SCORE_CARD_KPI_ID}=${urlRoutingIds.kpiId}`,
                  ].join("&"),
                }}
                onClick={() => {
                  Track.clickEvent("Notification - Go to Scorecard", {
                    "Notification Id": selectedNotification?.id,
                    "Notification Type": selectedNotification?.type,
                    "Notification Title": selectedNotification?.title,
                  });
                }}
                target="_blank"
              >
                Go to Scorecard <PDComponent.SvgIcon name="newTab" />
              </Link>
            </Styled.ScorecardButton>
          </Styled.ModalChartContainer>
        </Styled.ModalTopContainer>
        <Styled.ModalCommentContainer>
          <Styled.ListNoteExceptionStatus>
            <Styled.AuthorName>
              {detailedNotification?.comment.authorDisplayName}
            </Styled.AuthorName>
            <Styled.CommentDate>
              {detailedNotificationTimestamp
                ? dayjs(detailedNotificationTimestamp).format(SHORT_DATE_FORMAT)
                : null}
            </Styled.CommentDate>

            <Styled.Description>
              {selectedNotification?.description}
            </Styled.Description>
            {selectedNotification.type == NotificationType.ExceptionRequest ? (
              <>
                <Styled.StatusText>
                  <Styled.NoWrap>
                    Hours:{" "}
                    <b>
                      {hoursUOM.display(
                        detailedNotification?.comment?.exceptionTime,
                        { fractionDigits: 2 },
                      )}
                    </b>
                  </Styled.NoWrap>
                  <Styled.OverflowEllipsis>
                    Approver:{" "}
                    <b>
                      {
                        detailedNotification?.comment
                          ?.exceptionApproverDisplayName
                      }
                    </b>
                  </Styled.OverflowEllipsis>
                </Styled.StatusText>

                {shouldShowControls ? (
                  <Styled.ApprovalActions>
                    <Styled.RejectButton
                      size="middle"
                      type="primary"
                      onClick={handleOnReject}
                    >
                      Reject
                    </Styled.RejectButton>
                    <Styled.ApproveButton
                      size="middle"
                      type="primary"
                      onClick={handleOnApprove}
                    >
                      Approve
                    </Styled.ApproveButton>
                  </Styled.ApprovalActions>
                ) : (
                  <Styled.ApprovalStatus>
                    {icon}
                    {message}
                  </Styled.ApprovalStatus>
                )}
              </>
            ) : null}
          </Styled.ListNoteExceptionStatus>
        </Styled.ModalCommentContainer>
      </Styled.ModalRight>
    </>
  );
};
