import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type {
  ApiPowerConsumptionUserLensesIdFactsPutRequest,
  PowerConsumptionFactSetDto,
} from "apis/oag";
import { PowerConsumptionUserLensesApi } from "apis/oag";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useAppDispatch } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new PowerConsumptionUserLensesApi(apiConfig);

export const usePowerConsumptionByOperationFacts = (
  lensId: number,
  requestParameters: ApiPowerConsumptionUserLensesIdFactsPutRequest,
  options?: Omit<
    UseQueryOptions<PowerConsumptionFactSetDto>,
    "queryKey" | "queryFn"
  >,
) => {
  const crtWellId = useSelectedWell();
  const dispatch = useAppDispatch();

  const queryKey: PDLensFactsQueryKey<ApiPowerConsumptionUserLensesIdFactsPutRequest> =
    {
      type: PDQueryType.FACTS,
      uid: RequestUID.powerConsumptionByOperationFacts,
      lensId,
      params: requestParameters,
    };

  const request = useSuspenseQuery<PowerConsumptionFactSetDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      api
        .apiPowerConsumptionUserLensesIdFactsPut(requestParameters, { signal })
        .then((data) => {
          if (data?.lastUpdatedAt) {
            dispatch({
              type: "SET_POWER_MANAGEMENT_REFRESH_DATE",
              payload: {
                [crtWellId]: data?.lastUpdatedAt,
              },
            });
          }

          return data;
        }),
    ...options,
  });

  return request;
};
