import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import type { WellIntelQueryDto } from "apis/oag";
import { FiltersApi } from "apis/oag";
import type { IconName } from "icons/autogeneratedTypes";
import { useMemo } from "react";
import type { DetailedFilterVisibleKeys } from "reducers/intelFiltersReducerUncommitted";
import { SimpleFilterVisibleKeys } from "reducers/intelFiltersReducerUncommitted";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { PDQueryType } from "utils/queryNamespaces";

const filters = new FiltersApi(apiConfig);

export const useIntelLinkedFiltersByGroupAndQuarter = <T = WellIntelQueryDto,>(
  options?: Partial<UseSuspenseQueryOptions<WellIntelQueryDto, Error, T>>,
) => {
  const committedFilters = useAppSelector(
    (state) => state.intelFiltersCommitted,
  );

  const enabled = useAppSelector(
    (state) => state.intelDashboard.hasAutoSelectedQuarterYearList,
  );

  const linkedFiltersQueryKey = useMemo(
    () => ({
      type: PDQueryType.LINKED_FILTERS_INTEL,
      committedFilters,
    }),
    [committedFilters],
  );

  return useQuery({
    queryKey: [linkedFiltersQueryKey],
    queryFn: () => {
      return filters.apiFiltersIntelLinkedWellAttributesPut({
        wellIntelQueryDto: committedFilters,
      });
    },
    ...options,
    enabled,
    staleTime: Infinity,
    gcTime: Infinity,
    placeholderData: keepPreviousData,
  });
};

export const selectDetailedFilterGroups = (
  filterGroups: WellIntelQueryDto,
  cosmeticFilterOptions?: WellIntelQueryDto,
  forcedSelectedCosmeticFilterOptions?: WellIntelQueryDto,
) => {
  const detailedFilterGroup: {
    displayGroupTitle: string;
    options: string[] | null | undefined;
    storeKey: keyof typeof DetailedFilterVisibleKeys;
  }[] = [
    {
      displayGroupTitle: "Region",
      options: Array.from(
        new Set(
          (cosmeticFilterOptions?.regions ?? filterGroups.regions ?? []).concat(
            forcedSelectedCosmeticFilterOptions?.regions ?? [],
          ),
        ),
      ),
      storeKey: "regions",
    },
    {
      displayGroupTitle: "Basin",
      options: Array.from(
        new Set(
          (cosmeticFilterOptions?.basins ?? filterGroups.basins ?? []).concat(
            forcedSelectedCosmeticFilterOptions?.basins ?? [],
          ),
        ),
      ),
      storeKey: "basins",
    },
    {
      displayGroupTitle: "Play",
      options: Array.from(
        new Set(
          (cosmeticFilterOptions?.plays ?? filterGroups.plays ?? []).concat(
            forcedSelectedCosmeticFilterOptions?.plays ?? [],
          ),
        ),
      ),
      storeKey: "plays",
    },
    {
      displayGroupTitle: "Field",
      options: Array.from(
        new Set(
          (cosmeticFilterOptions?.fields ?? filterGroups.fields ?? []).concat(
            forcedSelectedCosmeticFilterOptions?.fields ?? [],
          ),
        ),
      ),
      storeKey: "fields",
    },
    {
      displayGroupTitle: "Country",
      options: Array.from(
        new Set(
          (
            cosmeticFilterOptions?.countries ??
            filterGroups.countries ??
            []
          ).concat(forcedSelectedCosmeticFilterOptions?.countries ?? []),
        ),
      ),
      storeKey: "countries",
    },
    {
      displayGroupTitle: "State Province",
      options: Array.from(
        new Set(
          (
            cosmeticFilterOptions?.stateProvinces ??
            filterGroups.stateProvinces ??
            []
          ).concat(forcedSelectedCosmeticFilterOptions?.stateProvinces ?? []),
        ),
      ),
      storeKey: "stateProvinces",
    },
    {
      displayGroupTitle: "County/Parrish",
      options: Array.from(
        new Set(
          (
            cosmeticFilterOptions?.countyParishes ??
            filterGroups.countyParishes ??
            []
          ).concat(forcedSelectedCosmeticFilterOptions?.countyParishes ?? []),
        ),
      ),
      storeKey: "countyParishes",
    },
    {
      displayGroupTitle: "Rig Class",
      options: Array.from(
        new Set(
          (
            cosmeticFilterOptions?.rigClasses ??
            filterGroups.rigClasses ??
            []
          ).concat(forcedSelectedCosmeticFilterOptions?.rigClasses ?? []),
        ),
      ),
      storeKey: "rigClasses",
    },
  ];

  return detailedFilterGroup;
};

export const selectSimpleFilters = (
  filterGroups: WellIntelQueryDto,
  cosmeticFilterOptions?: WellIntelQueryDto,
  forcedSelectedCosmeticFilterOptions?: WellIntelQueryDto,
) => {
  const filters: {
    displayName: string;
    options: string[] | null | undefined;
    iconName: IconName;
    storeKey: SimpleFilterVisibleKeys;
  }[] = [
    {
      displayName: "Rigs",
      options: Array.from(
        new Set(
          (
            cosmeticFilterOptions?.rigNames ??
            filterGroups.rigNames ??
            []
          ).concat(forcedSelectedCosmeticFilterOptions?.rigNames ?? []),
        ),
      ),
      iconName: "rig",
      storeKey: SimpleFilterVisibleKeys.rigNames,
    },
    {
      displayName: "Wells",
      options: Array.from(
        new Set(
          (
            cosmeticFilterOptions?.wellNames ??
            filterGroups.wellNames ??
            []
          ).concat(forcedSelectedCosmeticFilterOptions?.wellNames ?? []),
        ),
      ),
      iconName: "wellInfo",
      storeKey: SimpleFilterVisibleKeys.wellNames,
    },
    {
      displayName: "Contractors",
      options: Array.from(
        new Set(
          (
            cosmeticFilterOptions?.contractorNames ??
            filterGroups.contractorNames ??
            []
          ).concat(forcedSelectedCosmeticFilterOptions?.contractorNames ?? []),
        ),
      ),
      iconName: "contractorAlt",
      storeKey: SimpleFilterVisibleKeys.contractorNames,
    },
    {
      displayName: "Operators",
      options: Array.from(
        new Set(
          (
            cosmeticFilterOptions?.operatorNames ??
            filterGroups.operatorNames ??
            []
          ).concat(forcedSelectedCosmeticFilterOptions?.operatorNames ?? []),
        ),
      ),
      iconName: "operator",
      storeKey: SimpleFilterVisibleKeys.operatorNames,
    },
    {
      displayName: "Formations",
      options: Array.from(
        new Set(
          (
            cosmeticFilterOptions?.formations ??
            filterGroups.formations ??
            []
          ).concat(forcedSelectedCosmeticFilterOptions?.formations ?? []),
        ),
      ),
      iconName: "formation",
      storeKey: SimpleFilterVisibleKeys.formations,
    },
  ];

  return filters;
};
