import { Title } from "atoms/Typography";
import styled from "styled-components";
import colors from "utils/colors";
import { Button } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

export const confirmModalStyles = {
  overlay: {
    zIndex: zIndexLayer.jupiter,
    backgroundColor: colors.faded_bg,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    borderRadius: 12,
    bottom: "auto",
    padding: "0px!important",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 448,
    height: "auto",
    overflow: "hidden",
    border: "none",
  },
};

export const StyledModalContainer = styled.div`
  padding: 0px;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
`;

export const StyledModalContent = styled.div`
  padding: 0px 24px 26px 24px;
`;

export const StyledModalHeader = styled.div`
  padding: 20px 24px 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledModalFooter = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};
`;
export const StyledTitle = styled(Title).attrs({ level: 2, weight: 500 })`
  font-size: 20px !important;
`;

export const StyledCloseIcon = styled.span`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  cursor: pointer;
`;

export const StyledCancelButton = styled(Button)`
  color: ${colors.transparent_black};
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  background-color: ${colors.white};
  height: 34px;
`;

export const StyledConfirmButton = styled(Button)<{
  $hasSuccessColor: boolean;
}>`
  height: 37px;
  background-color: ${({ $hasSuccessColor }) =>
    $hasSuccessColor ? colors.flamboyant_teal : colors.whisky};
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  color: ${colors.white};
`;
