import { StyledTabs } from "pages/WellDashboard/style";
import styled from "styled-components";
import colors from "utils/colors";

export const TaskCounter = styled.div<{ $isPositive?: boolean }>`
  border-radius: 100%;
  background-color: ${({ $isPositive }) =>
    $isPositive ? colors.well_color : colors.error_color};
  height: 1.5em;
  width: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
`;

export const TabContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
`;

export const RigTabs = styled(StyledTabs)`
  > .ant-tabs-nav .ant-tabs-tab {
    margin-right: 8px;
  }
`;
