import { Group } from "@visx/group";
import type { PositionScale } from "@visx/shape/lib/types";
import {
  IndicatorBar,
  maxIndicatorWidth,
} from "components/Lenses/common/IndicatorBar";
import type { AggregatedIndicatorItem } from "components/Lenses/common/useAggregatedIndicators";
import type { IIndicators } from "reducers/widgetReducer";

export interface IndicatorsProps {
  keys: string[];
  value: (key: string) => AggregatedIndicatorItem[];
  selectedIndicators: IIndicators[];
  xScale: PositionScale;
  gapWidth: number;
  height: number;
}

export const Indicators: React.VFC<IndicatorsProps> = ({
  keys,
  value,
  selectedIndicators,
  xScale,
  gapWidth,
  height,
}) => {
  const [minX, maxX] = xScale.range();

  return (
    <Group>
      {keys.map((key) => {
        const domain = xScale.domain();

        let x: number;
        if (!domain.includes(+key)) {
          const a = domain.filter((n) => n <= +key);
          x = (xScale(a?.[a.length - 1]) || 0) - gapWidth / 2 - 2;
        } else {
          x = (xScale(+key) || 0) - gapWidth / 2 - 2;
        }

        let alignment: "left" | "right" | "center" = "center";

        if (x - minX < maxIndicatorWidth / 2) {
          alignment = "left";
        } else if (maxX - x < maxIndicatorWidth / 2) {
          alignment = "right";
        }

        const labels = value(key)
          .filter((item) => selectedIndicators?.includes(item.type))
          .map((item) => item.label);

        if (labels.length === 0) {
          return null;
        }

        return (
          <IndicatorBar
            key={key}
            x={x}
            height={height ?? 0}
            labels={labels}
            alignment={alignment}
          />
        );
      })}
    </Group>
  );
};
