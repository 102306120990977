import type { TrackDto } from "apis/oag";
import { Button, Select } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import { useTracks } from "hooks/drillingInvariants/useTracks";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Typography } from "utils/componentLibrary";

export type TrackAddModalProps = {
  availableTrackIds: number[];
  onAdd: (track: TrackDto) => Promise<void> | void;
  setVisible: (value: boolean) => void;
  visible: boolean;
};

export function TrackAddModal({
  availableTrackIds,
  onAdd,
  setVisible,
  visible,
}: TrackAddModalProps) {
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const { data: tracks } = useTracks();
  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  useEffect(() => {
    if (!visible) {
      setSelectedId(undefined);
    }
  }, [visible]);

  return (
    <PDComponent.Modal
      onCancel={handleCancel}
      title="Add Track"
      footer={
        <Row justify="space-between">
          <Col>
            <Button trackingName="Cancel Add Track" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              trackingName="Add Track"
              trackingProps={{
                "Track Name": tracks?.list?.find((t) => t.id === selectedId)
                  ?.name,
              }}
              disabled={selectedId === undefined}
              onClick={() => {
                const track = tracks?.list?.find((t) => t.id === selectedId);

                if (track) {
                  void onAdd(track);
                }

                setVisible(false);
              }}
            >
              Add
            </Button>
          </Col>
        </Row>
      }
      open={visible}
    >
      <Typography style={{ marginBottom: "24px" }}>
        Adding a track to this lens will add it to the bottom of the tracks
        list.
      </Typography>

      <Select
        value={selectedId !== undefined ? `${selectedId}` : undefined}
        onChange={(value) => {
          if (value) setSelectedId(+value);
        }}
        placeholder="Select track"
        style={{ width: "100%" }}
      >
        {availableTrackIds.map((id) => (
          <Select.Option key={id} value={`${id}`}>
            {tracks?.byId[id || 0]?.description || "Unnamed"}
          </Select.Option>
        ))}
      </Select>
    </PDComponent.Modal>
  );
}
