import { ResultDataState } from "apis/oag";
import { NoDataContainer } from "components/Lenses/common/NoData";
import {
  GRID_HEIGHT_QUARTER,
  GRID_WIDTH_THREE_QUARTERS,
} from "components/Lenses/constants";
import { getLensOrientation } from "components/Lenses/ContainerLens/common/utils/utils";
import * as Styled from "components/Lenses/ContainerLens/WellDrillingSummaryKpi/Chart/styles";
import { NoDataText } from "components/Lenses/ContainerLens/WellDrillingSummaryKpi/Chart/styles";
import { LensGridContext } from "components/Lenses/utils";
import { useContext } from "react";
import styled from "styled-components";

import { DataColumn } from "..";

const LoadingContainer = styled(Styled.Container)`
  width: 100%;
  height: 100%;
  padding-bottom: 68px;
  ${Styled.InfoGroup} {
    height: 100%;
  }

  ${(props) =>
    props.$orientation === "vertical" &&
    `    
      ${NoDataText} {
        display:flex;
      }
      ${NoDataContainer} {
        display:none;
      }
    `}

  ${(props) =>
    props.$orientation === "horizontal" &&
    `    
      ${NoDataText} {
        display:none;
      }
      ${NoDataContainer} {
        display:flex;
      }
    `}
`;

export function WellDrillingSummaryLoading({
  lensId,
  dataState,
}: {
  lensId: number;
  dataState: ResultDataState;
}) {
  const { crtLayout, bp } = useContext(LensGridContext);
  const { w: lensWidth, h: lensHeight } = crtLayout?.[bp]?.find(
    ({ i }) => +i === lensId,
  ) || {
    w: GRID_WIDTH_THREE_QUARTERS,
    h: GRID_HEIGHT_QUARTER,
  };

  if (dataState !== ResultDataState.Valid) {
    return (
      <LoadingContainer
        $orientation={getLensOrientation(lensWidth, lensHeight)}
        $height={lensHeight}
        $width={lensWidth}
      >
        <DataColumn title={`Distance Drilled (m)`} />
        <DataColumn title="Hours Drilled" />
        <DataColumn title="Drilling Connections" />
      </LoadingContainer>
    );
  }

  return (
    <LoadingContainer
      $orientation={getLensOrientation(lensWidth, lensHeight)}
      $height={lensHeight}
      $width={lensWidth}
    >
      <DataColumn
        title={`Distance Drilled (m)`}
        total={".."}
        rows={[
          {
            title: `Rotate`,
            total: "..",
            split: 0,
            variant: "primary",
          },
          {
            title: `Slide`,
            total: "..",
            split: 0,
          },
        ]}
      />
      <DataColumn
        title="Hours Drilled"
        total={".."}
        rows={[
          {
            title: `Rotate`,
            total: "..",
            split: 0,
            variant: "primary",
          },
          {
            title: `Slide`,
            total: "..",
            split: 0,
          },
        ]}
      />
      <DataColumn
        title="Drilling Connections"
        total={".."}
        rows={[
          {
            title: `With reaming:`,
            total: "..",
          },
          { title: `Reaming time (hr):`, total: ".." },
        ]}
      />
    </LoadingContainer>
  );
}
