import type {
  ParameterByDepthKpiComparisonDto,
  StickSlipByDepthSetDto,
} from "apis/oag";
import type { ParameterByDepthTrack } from "components/Lenses/interfaces";

export function getTracksById(
  data: StickSlipByDepthSetDto | ParameterByDepthKpiComparisonDto,
) {
  const tracksById =
    data.facts?.reduce<Record<number, ParameterByDepthTrack[]>>(
      (acc, comparison) => {
        comparison.tracks?.forEach((track) => {
          if (!track.trackId) {
            return;
          }

          if (!acc[track.trackId]) {
            acc[track.trackId] = [];
          }

          acc[track.trackId].push({
            ...track,
            wellId: comparison?.wellId ?? -1,
            isFocalWell: !!comparison.isFocalWell,
            trackValues:
              track.trackValues?.map((value) => ({
                ...value,
                value: value.value,
              })) ?? [],
          });
        });

        return acc;
      },
      {},
    ) || {};

  return tracksById;
}
