import { convertLength } from "@turf/turf";
import ButtonGroup from "antd/lib/button/button-group";
import { Title } from "atoms/Typography";
import {
  AreaSelectionType,
  useWellsMapViewContext,
} from "components/MapView/useWellsMapView";
import { PDComponent } from "components/PDComponents";
import { useGetBasins } from "hooks/wells/useBasins";
import {
  BasinName,
  StyledButton,
  StyledCircleButton,
  StyledInputNumber,
  StyledListWithSearch,
  StyledRadiusButton,
} from "pages/AllWells/components/AllWellsHeader/style";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "reducers/store";
import { IUnitSystem } from "reducers/types";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";
import { Col, Popover, Row, Tooltip } from "utils/componentLibrary";

export const MapControlHeader = ({
  trackingTitle,
  isDark,
}: {
  trackingTitle: string;
  isDark: boolean;
}) => {
  const { data: basins } = useGetBasins();
  const basinNames = useMemo(
    () => basins?.map((basin) => basin.name) ?? [],
    [basins],
  );

  const [shouldShowCircleSweep, setShouldShowCircleSweep] = useState(false);
  const [shouldShowPolygonSweep, setShouldShowPolygonSweep] = useState(false);
  const [shouldShowBasinSweep, setShouldShowBasinSweep] = useState(false);
  const [showBasinPopup, setShowBasinPopup] = useState(false);

  const {
    areaText,
    handleOnCircleAreaPress,
    handleOnPolygonAreaPress,
    handleOnTrashPress,
    areaSelectionType,
    radius,
    radiusMaxBound,
    basinName,
    setBasinName,
    setRadius,
  } = useWellsMapViewContext();
  useEffect(() => {
    const handleScroll = () => {
      setShowBasinPopup(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleOnCircleHover = useCallback(() => {
    if (areaSelectionType === AreaSelectionType.Circle) {
      setShouldShowCircleSweep(true);
    }
  }, [areaSelectionType]);

  const handleOnCircleOut = useCallback(
    () => setShouldShowCircleSweep(false),
    [],
  );
  const handleOnPolygonHover = useCallback(() => {
    if (areaSelectionType === AreaSelectionType.Polygon) {
      setShouldShowPolygonSweep(true);
    }
  }, [areaSelectionType]);

  const handleOnPolygonOut = useCallback(
    () => setShouldShowPolygonSweep(false),
    [],
  );

  const handleOnBasinHover = useCallback(() => {
    if (basinName) {
      setShouldShowBasinSweep(true);
    }
  }, [basinName]);

  const handleOnBasinOut = useCallback(
    () => setShouldShowBasinSweep(false),
    [],
  );
  const currentUom = useAppSelector((state) => state.global.unit);

  const handleOnRadiusChange = (value: number | null) => {
    if (value && +value > 0 && +value <= radiusMaxBound) {
      const translatedValue =
        currentUom === IUnitSystem.METRIC
          ? +convertLength(+value, "kilometers", "meters").toFixed(0)
          : +convertLength(+value, "miles", "meters").toFixed(0);

      Track.interact(`${trackingTitle} - Circle radius change`, {
        "New Radius": `${translatedValue} m`,
      });

      setRadius(+translatedValue);
    }
  };
  const circleRadiusBoundsText = (
    <>
      {currentUom === IUnitSystem.METRIC ? "Km" : "Mi"} Radius{" "}
      {radius >= radiusMaxBound && (
        <span style={{ color: colors.error_color }}>(Max)</span>
      )}
    </>
  );

  return (
    <Row gutter={6} justify={"center"} align="middle">
      <Col>
        <ButtonGroup size="large">
          <StyledCircleButton
            $isDark={isDark}
            size="large"
            icon={
              shouldShowCircleSweep ? (
                <PDComponent.SvgIcon name="sweep" />
              ) : (
                <PDComponent.SvgIcon name="mapCenterIcon" height={18} />
              )
            }
            type="default"
            $engaged={areaSelectionType === AreaSelectionType.Circle}
            onClick={() => {
              if (shouldShowCircleSweep) {
                Track.interact(`${trackingTitle} - Remove circle selection`);
                if (handleOnTrashPress) {
                  handleOnTrashPress();
                }
              } else {
                Track.interact(`${trackingTitle} - Add new circle`);
                if (handleOnCircleAreaPress) {
                  handleOnCircleAreaPress();
                }
              }
            }}
            onMouseOver={handleOnCircleHover}
            onMouseLeave={handleOnCircleOut}
          />

          {areaSelectionType === AreaSelectionType.Circle && (
            <>
              <StyledInputNumber
                $isDark={isDark}
                value={radius || 0}
                type="number"
                min={0}
                step={1}
                max={1000}
                onChange={(x) => handleOnRadiusChange(+(x ?? 0))}
              />

              <StyledRadiusButton
                $isDark={isDark}
                size="large"
                type="default"
                disabled
              >
                {circleRadiusBoundsText}
              </StyledRadiusButton>
            </>
          )}
        </ButtonGroup>
      </Col>
      <Col>
        <ButtonGroup>
          <StyledButton
            $isDark={isDark}
            size="large"
            icon={
              shouldShowPolygonSweep ? (
                <PDComponent.SvgIcon name="sweep" />
              ) : (
                <PDComponent.SvgIcon name="polygon" height={18} />
              )
            }
            type="default"
            onClick={() => {
              if (shouldShowPolygonSweep) {
                Track.interact(`${trackingTitle} - Remove polygon selection`);
                if (handleOnTrashPress) {
                  handleOnTrashPress();
                }
              } else {
                if (handleOnPolygonAreaPress) {
                  handleOnPolygonAreaPress();
                }
              }
            }}
            onMouseOver={handleOnPolygonHover}
            onMouseLeave={handleOnPolygonOut}
          />
          {areaSelectionType === AreaSelectionType.Polygon && (
            <StyledButton
              $isDark={isDark}
              disabled
              size="large"
              type="default"
              ghost={false}
              $engaged={false}
            >
              {areaText}
            </StyledButton>
          )}
        </ButtonGroup>
      </Col>
      <Col>
        <Tooltip title="Basin Name">
          <Popover
            content={
              <StyledListWithSearch $isDark={isDark}>
                <PDComponent.ListWithSearch<string>
                  placeholder={"Search by basin name"}
                  options={basinNames.sort().map((basin) => ({
                    id: basin,
                    name: basin,
                  }))}
                  values={basinNames}
                  selectedItem={basinName}
                  onClick={(item) => {
                    Track.interact(`${trackingTitle} - Select Basin`, {
                      "Basin Name": item.name,
                    });
                    setBasinName(item.name);
                  }}
                />
              </StyledListWithSearch>
            }
            trigger="click"
            placement="bottom"
            open={showBasinPopup}
            onOpenChange={(e) => setShowBasinPopup(e)}
            destroyTooltipOnHide
          >
            <Row>
              <Col>
                <StyledButton
                  $isDark={isDark}
                  size="large"
                  icon={
                    shouldShowBasinSweep ? (
                      <PDComponent.SvgIcon name="sweep" />
                    ) : (
                      <PDComponent.SvgIcon
                        name="basins"
                        width="1.1em"
                        height="1.1em"
                      />
                    )
                  }
                  type={"default"}
                  onMouseOver={handleOnBasinHover}
                  onMouseLeave={handleOnBasinOut}
                  onClick={
                    basinName
                      ? () => {
                          Track.interact(
                            `${trackingTitle} - Remove basin selection`,
                          );
                          setBasinName("");
                          if (handleOnTrashPress) {
                            handleOnTrashPress();
                          }
                        }
                      : () => {}
                  }
                  style={
                    basinName
                      ? {
                          borderRight: "none",
                          borderRadius: "5px 0px 0px 5px",
                        }
                      : undefined
                  }
                />
              </Col>
              <Col>
                {basinName ? (
                  <BasinName $isDark={isDark}>
                    <Title level={3} variant={isDark ? "white" : "dark"}>
                      {basinName}
                    </Title>
                  </BasinName>
                ) : null}
              </Col>
            </Row>
          </Popover>
        </Tooltip>
      </Col>
    </Row>
  );
};
