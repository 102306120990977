import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type {
  ApiDieselNatGasRatioStatsUserLensesIdFactsPutRequest,
  DieselNatGasRatioStatsResultDto,
  DieselNatGasRatioStatsUserLensDto,
} from "apis/oag";
import { DieselNatGasRatioStatsUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { useFilterParams } from "hooks/filters/useFilterParams";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useAppDispatch } from "reducers/store";
import type { IZoomData } from "reducers/types";
import { IZoomType } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateDto } from "utils/helper";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const dieselNatGasRatioStatsUserLensesApi =
  new DieselNatGasRatioStatsUserLensesApi(apiConfig);

export function useDieselNatGasRatioStatsFacts(
  lens: DieselNatGasRatioStatsUserLensDto,
  options?: UseQueryOptions<DieselNatGasRatioStatsResultDto>,
) {
  const filterParams = useFilterParams();

  const crtWellId = useSelectedWell();
  const dispatch = useAppDispatch();

  const [zoomData] = useStateQuery<IZoomData>(
    URL_STATE_PARAM.ZOOM_WELL,
    initialZoomData,
    [URL_STATE_PARAM.ZOOM_WIDGET],
  );

  const requestParameters: ApiDieselNatGasRatioStatsUserLensesIdFactsPutRequest =
    {
      id: lens.id,
      baseFuelQueryDto: {
        ...filterParams,
      },
    };

  let requestParametersWithZoom: ApiDieselNatGasRatioStatsUserLensesIdFactsPutRequest =
    requestParameters;
  if (zoomData.date_start && zoomData.type === IZoomType.DATE) {
    requestParametersWithZoom = {
      ...requestParameters,
      baseFuelQueryDto: requestParameters.baseFuelQueryDto
        ? {
            ...requestParameters.baseFuelQueryDto,
            from: dateToDateDto(zoomData.date_start) || defaultDateDto.from,
            to: dateToDateDto(zoomData.date_end) || defaultDateDto.to,
          }
        : undefined,
    };
  }

  const queryKey: PDLensFactsQueryKey<ApiDieselNatGasRatioStatsUserLensesIdFactsPutRequest> =
    {
      type: PDQueryType.FACTS,
      uid: RequestUID.dieselNatGasRatioFacts,
      params: requestParametersWithZoom,
      lensId: lens.id,
    };

  const request = useSuspenseQuery<DieselNatGasRatioStatsResultDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      dieselNatGasRatioStatsUserLensesApi
        .apiDieselNatGasRatioStatsUserLensesIdFactsPut(
          requestParametersWithZoom,
          {
            signal,
          },
        )
        .then((data) => {
          if (data?.lastUpdatedAt) {
            dispatch({
              type: "SET_FUEL_MANAGEMENT_REFRESH_DATE",
              payload: {
                [crtWellId]: data?.lastUpdatedAt,
              },
            });
          }
          return data;
        }),
    ...options,
  });
  return request;
}
