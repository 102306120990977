import { useMsal } from "@azure/msal-react";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import Error404Svg from "pages/Auth/Error404/Error404Svg";
import type { FC } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import { Card, Col, Row } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

const Holder404 = styled.div`
  text-align: center;
  margin-top: 160px;

  .mainHeadline {
    max-width: 380px;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 52px;
  }
  .subline {
    margin-bottom: 40px;
  }
`;

const Error404: FC = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { atomThemeVariant } = useCustomTheme();
  useEffect(() => {
    const isUnauthorized = JSON.parse(
      localStorage.getItem("unauthorizedApi") || "false",
    );
    if (isUnauthorized) {
      localStorage.removeItem("unauthorizedApi");
      Track.event("Log Out", { reason: "Error - 404" });
      instance.logoutRedirect();
    }
  }, [instance]);

  return (
    <Row align="middle" justify="center">
      <Col style={{ width: "480px" }}>
        <Holder404>
          <Error404Svg />
          <Title
            level={1}
            variant={atomThemeVariant}
            weight={500}
            className="mainHeadline"
          >
            Couldn’t reach this page.
          </Title>
          <Title level={3} variant={atomThemeVariant} className="subline">
            This page doesn’t exist or you don’t have access to it. Go back to
            the homepage or navigate back to the previous page.
          </Title>
          <Card bordered style={{ borderRadius: "10px" }}>
            <Button block type="primary" onClick={() => navigate(`/`)}>
              Go to All Wells
            </Button>
          </Card>
        </Holder404>
      </Col>
    </Row>
  );
};

export default Error404;
