import { useEffect, useRef } from "react";

export const useEffectExceptOnMount = (
  effect: React.EffectCallback,
  deps?: React.DependencyList,
) => {
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      const unmount = effect();
      return () => {
        if (unmount && typeof unmount === "function") {
          unmount();
        }
      };
    }
    mounted.current = true;
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps ?? [])]);

  // Reset on unmount for the next mount.
  useEffect(() => {
    return () => {
      mounted.current = false;
      return;
    };
  }, []);
};
