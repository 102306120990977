import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationsApi } from "apis/oag";
import { useState } from "react";
import { apiConfig } from "utils/apiConfig";
import { RequestUID } from "utils/queryNamespaces";

const notificationsApi = new NotificationsApi(apiConfig);

enum MutationType {
  MarkAsRead = "markAsRead",
  MarkAsUnread = "markAsUnread",
  Archive = "archive",
  Unarchive = "unarchive",
  Delete = "delete",
}

export function useNotificationsActions(onUpdate?: () => void) {
  const queryClient = useQueryClient();
  const [isMutating, setIsMutating] = useState(false);

  const updateNotification = useMutation({
    mutationFn: ({
      notificationIds,
      type,
      onUpdate,
    }: {
      notificationIds: number[];
      type: MutationType;
      onUpdate?: () => void;
    }) => {
      const requestDto = {
        notificationStatusUpdateRequestDto: { notificationIds },
      };

      switch (type) {
        case MutationType.MarkAsRead:
          return notificationsApi.apiNotificationsMarkAsReadPut(requestDto);
        case MutationType.MarkAsUnread:
          return notificationsApi.apiNotificationsMarkAsUnreadPut(requestDto);
        case MutationType.Archive:
          return notificationsApi.apiNotificationsMarkAsArchivedPut(requestDto);
        case MutationType.Unarchive:
          return notificationsApi.apiNotificationsMarkAsUnarchivedPut(
            requestDto,
          );
        case MutationType.Delete:
          return notificationsApi
            .apiNotificationsDeletePut(requestDto)
            .then((e) => {
              onUpdate?.();
              return e;
            });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [{ uid: RequestUID.activeNotifications }],
      });
      queryClient.invalidateQueries({
        queryKey: [{ uid: RequestUID.archivedNotifications }],
      });
      queryClient.invalidateQueries({
        queryKey: [{ uid: RequestUID.archivedNotificationsCount }],
      });
      setIsMutating(false);
      onUpdate?.();
    },
    onMutate: () => {
      setIsMutating(true);
    },
    onError: () => {
      setIsMutating(false);
    },
  });

  const markRead = (notificationIds: number[]) =>
    updateNotification.mutate({
      notificationIds,
      type: MutationType.MarkAsRead,
    });
  const markUnread = (notificationIds: number[]) =>
    updateNotification.mutate({
      notificationIds,
      type: MutationType.MarkAsUnread,
    });
  const archive = (notificationIds: number[]) =>
    updateNotification.mutate({ notificationIds, type: MutationType.Archive });
  const unarchive = (notificationIds: number[]) =>
    updateNotification.mutate({
      notificationIds,
      type: MutationType.Unarchive,
    });
  const deleteNotification = (
    notificationIds: number[],
    onUpdate?: () => void,
  ) =>
    updateNotification.mutate({
      notificationIds,
      type: MutationType.Delete,
      onUpdate,
    });

  return {
    isMutating,
    markRead,
    markUnread,
    archive,
    unarchive,
    deleteNotification,
  };
}
