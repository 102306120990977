import { useMutation, useQueryClient } from "@tanstack/react-query";
import type {
  ApiStickSlipByTimeUserLensesIdFactsPutRequest,
  StickSlipByDepthUserLensDto,
} from "apis/oag";
import {
  type StickSlipByTimeUserLensDto,
  StickSlipByTimeUserLensesApi,
  type StickSlipByTimeUserLensTrackItemDto,
} from "apis/oag";
import type { TrackViewmodel } from "components/Lenses/ContainerLens/StickSlipByTime/components/Chart/LineTrack/LineTrack";
import { TrackSettingsModal } from "components/Lenses/ContainerLens/StickSlipByTime/components/TrackSettingsModal/TrackSettingsModal";
import { useCallback, useMemo, useState } from "react";
import { apiConfig } from "utils/apiConfig";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const stickSlipByTime = new StickSlipByTimeUserLensesApi(apiConfig);

export const useTrackSettingsModal = (
  lens: StickSlipByTimeUserLensDto,
  onUpdateLens: (updatedLens: StickSlipByDepthUserLensDto) => void,
) => {
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [trackViewmodel, setTrackViewmodel] = useState<TrackViewmodel | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const open = useCallback((trackViewmodel: TrackViewmodel) => {
    setTrackViewmodel(trackViewmodel);
    setIsSettingsModalOpen(true);
  }, []);

  const lensTrackSettings = useMemo(() => {
    return lens?.userLensTrackItems?.find(
      (track: StickSlipByTimeUserLensTrackItemDto) =>
        track.trackId === trackViewmodel?.lensSettingsTrackId,
    );
  }, [lens?.userLensTrackItems, trackViewmodel?.lensSettingsTrackId]);

  const updateTrackMutation = useMutation({
    mutationFn: (stickSlipByTimeUserLensDto: StickSlipByTimeUserLensDto) => {
      setIsLoading(true);
      return stickSlipByTime.apiStickSlipByTimeUserLensesIdPut({
        id: lens.id,
        stickSlipByTimeUserLensDto,
      });
    },
    onSettled: async (newLens) => {
      const factsQueryKey: PDLensFactsQueryKey<ApiStickSlipByTimeUserLensesIdFactsPutRequest> =
        {
          type: PDQueryType.FACTS,
          uid: RequestUID.StickSlipByTime,
          lensId: lens.id,
        };

      const summaryQueryKey = {
        type: PDQueryType.FACTS,
        uid: RequestUID.StickSlipByTimeSummary,
        lensId: lens.id,
      };

      if (newLens) {
        onUpdateLens(newLens);
      }

      await Promise.all([
        queryClient.refetchQueries({ queryKey: [factsQueryKey] }),
        queryClient.refetchQueries({ queryKey: [summaryQueryKey] }),
      ]);

      setIsLoading(false);
      setIsSettingsModalOpen(false);
    },
  });

  const handleOnSave = useCallback(
    (updatedTrack: StickSlipByTimeUserLensTrackItemDto) => {
      const updatedDto = {
        ...lens,
        userLensTrackItems: lens.userLensTrackItems.map((item) =>
          item.trackId === updatedTrack.trackId ? updatedTrack : item,
        ),
      };

      updateTrackMutation.mutate(updatedDto);
    },
    [lens, updateTrackMutation],
  );

  return {
    element:
      lensTrackSettings && trackViewmodel ? (
        <TrackSettingsModal
          key={`track-settings-${lensTrackSettings.id}`}
          isVisible={isSettingsModalOpen}
          onClose={() => setIsSettingsModalOpen(false)}
          onSave={handleOnSave}
          isLoading={isLoading}
          uom={trackViewmodel.uom}
          lensTrackSettings={lensTrackSettings}
          trackTitle={trackViewmodel.label}
        />
      ) : null,
    open,
  };
};
