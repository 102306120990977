import { Button } from "atoms/Form";
import { Loader } from "components/Loader";
import { useIntelFilterActions } from "hooks/filters/useIntelFilterActions";
import {
  selectDetailedFilterGroups,
  useIntelLinkedFiltersByGroupAndQuarter,
} from "hooks/intel/useIntelLinkedFilters";
import { FilterGroup } from "pages/IntelDashboard/components/IntelHeader/DetailedFilterPopup/FilterGroup/FilterGroup";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { isDetailedFilterEmptyFiltersSelection } from "reducers/intelFiltersReducerUncommitted";
import { useAppSelector } from "reducers/store";
import { Space } from "utils/componentLibrary";

import * as Styled from "./style";

export const DetailedFilterPopup = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();

  const cosmeticFilterOptions = useAppSelector(
    (state) => state.intelFiltersCosmeticOptions,
  );
  const uncommittedFilters = useAppSelector(
    (state) => state.intelFiltersUncommitted,
  );
  const committedFilters = useAppSelector(
    (state) => state.intelFiltersCommitted,
  );

  const { data: filterGroups, isLoading: isFilterOptionsLoading } =
    useIntelLinkedFiltersByGroupAndQuarter({
      select: (filtersFromNetwork) =>
        selectDetailedFilterGroups(
          filtersFromNetwork,
          cosmeticFilterOptions,
          committedFilters,
        ),
    });

  const { commitAllFilters, resetFilterKey, emptyFilterKey, setAllFilters } =
    useIntelFilterActions();

  const handleOnClickApply = useCallback(() => {
    commitAllFilters();
    onClose();
  }, [commitAllFilters, onClose]);

  const selectAllToggleButtonState = useMemo(
    () =>
      isDetailedFilterEmptyFiltersSelection(uncommittedFilters)
        ? "Select All"
        : "Deselect All",
    [uncommittedFilters],
  );

  const handleOnToggleSelectAll = useCallback(() => {
    if (selectAllToggleButtonState === "Select All") {
      filterGroups?.forEach((filterGroup) => {
        resetFilterKey(filterGroup.storeKey);
      });
    } else {
      filterGroups?.forEach((filterGroup) => {
        emptyFilterKey(filterGroup.storeKey);
      });
    }
  }, [
    emptyFilterKey,
    filterGroups,
    resetFilterKey,
    selectAllToggleButtonState,
  ]);

  const isAnyFilterWithNoOptionSelection = useMemo(() => {
    return filterGroups?.some((filterGroup) => {
      const filterGroupStoreKey = filterGroup.storeKey;
      return uncommittedFilters[filterGroupStoreKey]?.length === 0;
    });
  }, [filterGroups, uncommittedFilters]);

  const handleOnCancel = useCallback(() => {
    setAllFilters(committedFilters);
    onClose();
  }, [committedFilters, onClose, setAllFilters]);

  return (
    <Styled.PopupContainer>
      <Styled.PopupHeader>
        <Space>
          <Button type="default" onClick={handleOnToggleSelectAll}>
            {selectAllToggleButtonState}
          </Button>
        </Space>

        <Space>
          <Button onClick={handleOnCancel}>Cancel</Button>
          <Button
            type="primary"
            onClick={handleOnClickApply}
            disabled={isAnyFilterWithNoOptionSelection}
          >
            Apply
          </Button>
        </Space>
      </Styled.PopupHeader>

      <Styled.Grid>
        {isFilterOptionsLoading || !filterGroups?.length ? (
          <Loader centered />
        ) : (
          filterGroups?.map((filterGroup) => (
            <FilterGroup
              key={filterGroup.storeKey}
              groupStoreKey={filterGroup.storeKey}
              groupTitle={filterGroup.displayGroupTitle}
              listOptions={filterGroup.options ?? []}
            />
          ))
        )}
      </Styled.Grid>
    </Styled.PopupContainer>
  );
};
