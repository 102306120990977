import styled from "styled-components";
import colors from "utils/colors";

export const StyledParent = styled.div<{ isNoData?: boolean }>`
  height: calc(100vh - 140px);
  overflow: ${({ isNoData }) => (isNoData ? "hidden" : "scroll")};
`;
export const StyledTable = styled.table`
  table-layout: fixed; /* Proportional column widths */
  border-collapse: collapse;
  thead {
    background-color: ${({ theme }) =>
      theme.isDark ? "#2C2C31" : colors.white};
    width: 100%;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    th {
      padding-left: 16px;
      height: 44px;
      width: 100%;
      background-color: ${({ theme }) =>
        theme.isDark ? "#2C2C31" : colors.white};
      color: ${colors.necron_compound};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 44px;
      display: flex;
      gap: 10px;
      .sort-buttons {
        display: none;
      }
      .filter-button {
        display: none;
      }
      .sort-buttons {
        flex-direction: column;
        justify-content: center;
      }
      &:hover {
        .sort-buttons {
          display: flex;
        }
        .filter-button {
          display: block;
        }
      }
      .text {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-direction: row;
        white-space: nowrap;
        user-select: none;
      }
    }
  }
  tbody {
    width: 100%;
    tr {
      width: 100%;
      border-bottom: 4px solid
        ${({ theme }) => (theme.isDark ? "#2C2C31" : "#f1f7f9")};
      background: ${({ theme }) =>
        theme.isDark ? colors.revolver : colors.white};
      td {
        width: 100%;
        padding: 6px 0;
        padding-left: 16px;
        text-align: center;
        color: ${({ theme }) => (theme.isDark ? colors.white : colors.neutral)};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
      }
    }
  }
`;
