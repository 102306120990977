import { ResultDataState } from "apis/oag";
import GeneratorSelector from "components/Lenses/common/GeneratorSelector";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import GeneratorStatsChart from "components/Lenses/ContainerLens/GeneratorStats/Chart";
import { GeneratorStatsContainer } from "components/Lenses/ContainerLens/GeneratorStats/Chart/styles";
import {
  useGeneratorStatsFetcher,
  useGeneratorStatsLensUpdate,
} from "components/Lenses/ContainerLens/GeneratorStats/fetcher";
import { LENS_TITLE } from "components/Lenses/ContainerLens/GeneratorStats/utils";
import type { GeneratorStatsProps } from "components/Lenses/interfaces";
import { useLensNameByTemplateId } from "hooks/lens/useLensNameByTemplateId";
import { useLensSize } from "hooks/lens/useLensSize";
import React, { useEffect } from "react";

export const GeneratorStatsKpi: React.FC<GeneratorStatsProps> = ({
  lens,
  isLocked = false,
  setLensDate,
  onLensUpdated,
}) => {
  const { data } = useGeneratorStatsFetcher(lens);

  const lensName = useLensNameByTemplateId(lens?.lensTemplateId);

  useEffect(() => {
    if (data?.lastUpdatedAt && setLensDate) setLensDate(data.lastUpdatedAt);
  }, [data.lastUpdatedAt, setLensDate]);
  const [width] = useLensSize(lens.id);

  const { handleGeneratorUpdate } = useGeneratorStatsLensUpdate(
    lens,
    onLensUpdated,
  );
  return (
    <GeneratorStatsContainer $withSpace={width > 4}>
      <LensLoadingContainer
        key={lens.id}
        dataState={
          data.summary
            ? data?.dataState ?? ResultDataState.NoData
            : ResultDataState.NoData
        }
        title={LENS_TITLE}
        isDetailed={false}
        LoadedComponent={
          <GeneratorStatsChart
            lens={lens}
            data={data.facts || []}
            summary={data.summary}
          />
        }
      />

      <GeneratorSelector
        selectedGenerators={lens.selectedGenerators}
        handleGeneratorUpdate={handleGeneratorUpdate}
        isLocked={isLocked}
        trackingText="Evergreen Dashboard - Update Generator"
        trackingData={{
          lensId: lens.id,
          lens: lensName,
        }}
      />
    </GeneratorStatsContainer>
  );
};
