import type {
  IntelRankingRibbonFactSetDto,
  IntelRanlingRiggonQuarterBasSliceDiffDto,
} from "apis/oag";

export type IntelRankingRibbonVm = {
  name: string;
  quarter: string;
  rank: number;
  productivity: number;
  diff: IntelRanlingRiggonQuarterBasSliceDiffDto | null;
};

export const IntelRankingRibbonSelector = (
  result: IntelRankingRibbonFactSetDto,
): IntelRankingRibbonVm[] => {
  return result.bars.reduce((acc, curr) => {
    const { quarter, year } = curr.quarterYear;
    const quarterStr = `Q${quarter} ${year.toString().slice(-2)}`;
    return [
      ...acc,
      ...(curr.slices.length
        ? curr.slices.map((v) => ({
            name: v.sliceName,
            quarter: quarterStr,
            rank: v.rank,
            productivity: v.productivity,
            diff: v.diff ?? null,
          }))
        : [
            // We need this for an empty slice with only the quarter data
            {
              name: "",
              quarter: quarterStr,
              rank: 0,
              productivity: 0,
              diff: null,
            },
          ]),
    ];
  }, [] as IntelRankingRibbonVm[]);
};
