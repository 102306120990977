export class ColorGenerator {
  private readonly goldenRatioConjugate: number;
  private readonly hueCounter: number;
  private colorCache: Map<number, string>;

  constructor() {
    this.goldenRatioConjugate = 0.618033988749895;
    this.colorCache = new Map();
    this.hueCounter = 0.5;
  }

  hslToHex(h: number, s: number, l: number) {
    h /= 360;
    s /= 100;
    l /= 100;

    const hueToRgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    const r = hueToRgb(p, q, h + 1 / 3);
    const g = hueToRgb(p, q, h);
    const b = hueToRgb(p, q, h - 1 / 3);

    const toHex = (x: number) =>
      Math.round(x * 255)
        .toString(16)
        .padStart(2, "0");

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }

  getNextColor(index: number) {
    if (this.colorCache.has(index)) {
      return this.colorCache.get(index)!;
    }

    const hue =
      ((this.hueCounter + index * this.goldenRatioConjugate) % 1) * 360;
    const saturation = 70;
    const lightness = 50;

    const color = this.hslToHex(hue, saturation, lightness);
    this.colorCache.set(index, color);
    return color;
  }
}
