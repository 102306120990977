import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import type { IOption } from "components/PDComponents/Search/utils";
import type { IconName } from "icons/autogeneratedTypes";
import { startTransition } from "react";
import { Popover, Tooltip } from "utils/componentLibrary";

export const SelectorButton = ({
  // #TODO this component has a bad flow, should be refactored later
  // On change is actually an on apply, and it's misleading/ inducing anti-patterns
  onReset,
  options,
  placeholder,
  selectedValues,
  isSelected,
  onChange,
  onOptionsChange,
  open,
  onOpenChange,
  name,
  tooltipName,
  disabled = false,
  disableIfNoOptions = false,
  showResetOnlyIfNoOptions = false,
}: {
  onReset?: () => void;
  options: IOption<number | string>[];
  placeholder: string;
  selectedValues: (number | string)[];
  isSelected: boolean;
  onChange: (e: (number | string)[]) => void;
  onOptionsChange?: (e: (number | string)[]) => void;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  name: IconName;
  tooltipName: string;
  disabled?: boolean;
  disableIfNoOptions?: boolean;
  showResetOnlyIfNoOptions?: boolean;
}) => {
  return (
    <Tooltip title={tooltipName}>
      <Popover
        content={
          <PDComponent.ComboBoxMultiSelect
            placeholder={placeholder}
            disableIfNoOptions={disableIfNoOptions}
            showResetOnlyIfNoOptions={showResetOnlyIfNoOptions}
            options={options}
            values={selectedValues}
            onReset={
              onReset
                ? () => {
                    startTransition(() => {
                      onReset();
                      onOpenChange(false);
                    });
                  }
                : undefined
            }
            resetName="Remove"
            onChange={(e) => {
              startTransition(() => {
                onChange(e);
                onOpenChange(false);
              });
            }}
            onOptionsChange={(opts) => onOptionsChange?.(opts)}
          />
        }
        trigger="click"
        placement="bottom"
        open={open}
        onOpenChange={onOpenChange}
        destroyTooltipOnHide
      >
        <Button
          disabled={disabled}
          size="large"
          icon={<PDComponent.SvgIcon name={name} />}
          type={isSelected ? "primary" : "default"}
          ghost={isSelected}
        />
      </Popover>
    </Tooltip>
  );
};
