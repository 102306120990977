import type { TickFormatter, TickRendererProps } from "@visx/axis";
import { AxisBottom, AxisRight } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import type { ScaleInput } from "@visx/scale";
import { Bar } from "@visx/shape";
import OperationCount from "components/Lenses/common/OperationCount";
import { getAxisFontSize } from "components/Lenses/ContainerLens/common/utils/utils";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import type { NumberValue, ScaleBand, ScaleLinear } from "d3-scale";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import { useMemo } from "react";
import { getXAxisTickCount } from "utils/charting/getXAxisTicksCount";
import { OPERATION_COUNT_HEIGHT } from "utils/constants";
import type { UOMHelper } from "utils/format";
import { useCustomTheme } from "utils/useTheme";

export interface ChartProps {
  children?: React.ReactNode;
  axisBottomColor?: string;
  axisBottomMargin?: number;
  bottomTickComponent?: (
    tickRendererProps: TickRendererProps,
  ) => React.ReactNode;
  categoryScale:
    | ScaleBand<string>
    | ScaleBand<number>
    | ScaleBand<Date>
    | ScaleBand<NumberValue>;
  chartHeight: number;
  chartWidth: number;
  maxNumFractionalDigits?: number;
  tickLabelFontSizeDefault?: string;
  showRightLine?: boolean;
  defaultNumTicks?: number;
  detailed: boolean;
  disableAxis?: boolean;
  disableValueAxis?: boolean;
  showOperationCount?: boolean;
  startX?: number;
  hideZeroValues?: boolean;
  isManual: boolean;
  maxBottomTickWidth?: number;
  plotHeight: number;
  plotWidth: number;
  rightTickFormat?: TickFormatter<ScaleInput<ChartProps["categoryScale"]>>;
  miniRightTicks?: boolean;
  topMargin?: number;
  operationCountTopMargin?: number;
  operationCountLeftMargin?: number;
  tickFormat?: TickFormatter<ScaleInput<ChartProps["categoryScale"]>>;
  valueScale: ScaleLinear<number, number, never>;
  valueUOM: UOMHelper;
}

export const Chart: React.FC<ChartProps> = ({
  axisBottomColor,
  axisBottomMargin = 0,
  bottomTickComponent,
  maxNumFractionalDigits = 2,
  tickLabelFontSizeDefault,
  showRightLine = true,
  categoryScale,
  chartHeight,
  showOperationCount,
  startX = 0,
  chartWidth,
  children,
  detailed,
  disableAxis,
  disableValueAxis,
  hideZeroValues,
  isManual,
  maxBottomTickWidth,
  plotHeight,
  plotWidth,
  rightTickFormat,
  topMargin = 0,
  operationCountTopMargin = 0,
  operationCountLeftMargin = 0,
  tickFormat,
  valueScale,
  valueUOM,
  defaultNumTicks,
}) => {
  const {
    yDisplayOnlyScale,
    verticalAxisTicksCount,
    formatTickDecimals,
    manualTickValues,
  } = useYAxisDisplayScale({
    originalScale: valueScale,
    uom: valueUOM,
    tickContainerHeight: chartHeight,
    isManual,
  });

  const numValueTicks = useMemo(
    () => defaultNumTicks ?? verticalAxisTicksCount,
    [verticalAxisTicksCount, defaultNumTicks],
  );

  const tickLabelFontSize = useMemo(() => {
    if (tickLabelFontSizeDefault) return tickLabelFontSizeDefault;
    return `${getAxisFontSize(detailed)}px`;
  }, [detailed, tickLabelFontSizeDefault]);

  const axisBottomMarginTop = detailed ? 12 : 2;

  const categoryBandwidth = categoryScale.bandwidth();
  const itemsCount = categoryScale.domain().length;
  const numHorizontalTicks = getXAxisTickCount({
    categoryBandwidth,
    itemsCount,
    maxBottomTickWidth,
  });

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const fill = useMemo(() => {
    if (axisBottomColor) {
      return axisBottomColor;
    } else if (detailed) {
      return themeColors.tertiary_typography;
    } else {
      return themeColors.disabled_typography;
    }
  }, [axisBottomColor, themeColors, detailed]);

  if (!plotHeight || !plotWidth || !chartWidth || !chartHeight) return null;

  return (
    <Group top={topMargin}>
      <GridRows
        width={plotWidth}
        height={plotHeight}
        scale={yDisplayOnlyScale}
        stroke={themeColors.primary_chart_accent}
        strokeWidth={1}
        strokeOpacity={1}
        numTicks={numValueTicks}
      />

      {showRightLine ? (
        <line
          x1={plotWidth}
          y1={0}
          x2={plotWidth}
          y2={plotHeight}
          strokeWidth={1}
          strokeOpacity={1}
          stroke={themeColors.primary_chart_accent}
          shapeRendering="geometricPrecision"
        />
      ) : null}
      {showOperationCount ? (
        <OperationCount
          x={-startX - operationCountLeftMargin}
          topMargin={operationCountTopMargin}
          width={50}
          detailed={detailed}
          value={"Cnt."}
        />
      ) : null}
      {children}

      {/* TODO use a chart clip instead of this trick,with RectClipPath */}
      {/* value axis background to clip the chart */}
      {!disableAxis &&
        !disableValueAxis &&
        -(showOperationCount ? OPERATION_COUNT_HEIGHT - 40 : 0) >= 0 && (
          <Bar
            x={plotWidth + 2}
            y={-40 - (showOperationCount ? OPERATION_COUNT_HEIGHT : 0)}
            width={
              chartWidth - plotWidth - 2 > 0 ? chartWidth - plotWidth - 2 : 0
            }
            height={
              plotHeight +
              40 +
              (showOperationCount ? OPERATION_COUNT_HEIGHT : 0)
            }
            fill={themeColors.primary_chart_bg}
          />
        )}

      {/* bottom right white square to clip everything */}
      {!disableAxis &&
      plotWidth &&
      plotHeight &&
      !detailed &&
      !hideZeroValues ? (
        <Bar
          x={plotWidth}
          y={plotHeight}
          width={getSVGNormalizedValue(chartWidth - plotWidth)}
          height={getSVGNormalizedValue(chartHeight - plotHeight)}
          fill={
            detailed
              ? themeColors.tertiary_chart_bg
              : themeColors.primary_chart_bg
          }
        />
      ) : null}
      {!disableAxis && (
        <AxisBottom
          top={plotHeight + axisBottomMarginTop + axisBottomMargin}
          scale={categoryScale}
          numTicks={numHorizontalTicks}
          tickLabelProps={() => ({
            fontSize: tickLabelFontSize,
            fill,
            letterSpacing: "-0.2px",
            textAnchor: "middle",
          })}
          tickComponent={bottomTickComponent}
          tickFormat={tickFormat}
          hideAxisLine
          hideTicks
        />
      )}

      {!disableAxis && !disableValueAxis && (
        <AxisRight
          scale={yDisplayOnlyScale}
          left={plotWidth - 5}
          hideZero={hideZeroValues}
          numTicks={numValueTicks}
          tickValues={isManual ? manualTickValues : undefined}
          tickFormat={(value, index, values) => {
            if (rightTickFormat) return rightTickFormat(value, index, values);
            const n = value.valueOf();
            if (n === 0 && hideZeroValues) return "";
            return formatTickDecimals(n, maxNumFractionalDigits);
          }}
          tickLabelProps={(value) => {
            const [min, max] = yDisplayOnlyScale.domain();

            const bold = isManual && [min, max].includes(+value);
            const fillColor = () => {
              if (bold) return themeColors.primary_typography;
              return themeColors.disabled_typography;
            };

            return {
              fontSize: tickLabelFontSize,
              fill: fillColor(),
              fontWeight: bold ? 600 : "normal",
              letterSpacing: "-0.2px",
              verticalAnchor: "middle",
              visibility: "visible",
            };
          }}
          hideAxisLine
          hideTicks
        />
      )}
    </Group>
  );
};
