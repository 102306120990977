import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import { useIntelFilterActions } from "hooks/filters/useIntelFilterActions";
import { useIntelLinkedFiltersByGroupAndQuarter } from "hooks/intel/useIntelLinkedFilters";
import { DetailedFilterPopup } from "pages/IntelDashboard/components/IntelHeader/DetailedFilterPopup/DetailedFilterPopup";
import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { isDetailedFilterApplied } from "reducers/intelFiltersReducerUncommitted";
import { useAppSelector } from "reducers/store";
import { Popover, Tooltip } from "utils/componentLibrary";

export const DetailedFilterButton = React.memo(() => {
  const [isFilterPopupVisible, setIsFilterPopupVisible] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(true);

  const { isLoading: isFilterOptionsLoading } =
    useIntelLinkedFiltersByGroupAndQuarter();

  const dispatch = useDispatch();

  const filterStateCommitted = useAppSelector(
    (state) => state.intelFiltersCommitted,
  );

  const deferredFilterState = useDeferredValue(filterStateCommitted);
  const { setAllFilters } = useIntelFilterActions();

  useEffect(() => {
    const isApplied = isDetailedFilterApplied(deferredFilterState);
    setIsFilterApplied(isApplied);
  }, [deferredFilterState]);

  const handleSetIsPopupVisible = useCallback(
    (isVisible: boolean) => {
      setAllFilters(filterStateCommitted);
      setIsFilterPopupVisible(isVisible);
    },
    [filterStateCommitted, setAllFilters],
  );

  return (
    <Tooltip title="Detailed Filter">
      <Popover
        content={
          <DetailedFilterPopup onClose={() => handleSetIsPopupVisible(false)} />
        }
        trigger={["click", "scroll"]}
        placement="bottomRight"
        open={isFilterPopupVisible}
        onOpenChange={(e) => handleSetIsPopupVisible(e)}
        destroyTooltipOnHide
      >
        <Button
          size="large"
          disabled={isFilterOptionsLoading}
          icon={<PDComponent.SvgIcon name="filter" />}
          onClick={() => handleSetIsPopupVisible(!isFilterPopupVisible)}
          $engaged={isFilterPopupVisible}
          type={isFilterApplied ? "primary" : "default"}
          ghost={isFilterApplied}
        />
      </Popover>
    </Tooltip>
  );
});
