import styled, { css } from "styled-components";
import colors from "utils/colors";
import { zIndexLayer } from "utils/zIndex";

export const Container = styled.div`
  position: relative;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
`;

export const Row = styled.div`
  border-bottom: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const TrackerRow = styled(Row)<{ isFixed?: boolean }>`
  height: 76px;
  top: 0;
  position: sticky;
  z-index: ${zIndexLayer.above};
  width: 100%;
`;

export const RowPadding = styled.div<{
  isAltBg?: boolean;
  paddingVertical?: number;
  hasBorder?: boolean;
  isClickable?: boolean;
  isActive?: boolean;
}>`
  height: 100%;
  padding: ${({ paddingVertical }) =>
    paddingVertical ? `${paddingVertical}px 20px` : "20px"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 10px;
  user-select: none;
  background-color: ${({ theme, isAltBg }) =>
    isAltBg
      ? theme.themeStyle.colors.primary_bg
      : theme.themeStyle.colors.alt_secondary_bg};
  ${({ hasBorder, theme }) =>
    hasBorder &&
    `border-bottom: 1px solid ${theme.themeStyle.colors.primary_accent}`};
  ${({ isClickable }) => isClickable && `cursor:pointer;`}
  ${({ isActive, theme }) =>
    isActive && `background-color: ${theme.themeStyle.colors.alt_primary_bg};`}
`;

export const Heading = styled.h1`
  margin: 0;
  display: block;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const ProgressOuter = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  height: 10px;
  border-radius: 10px;
  background-color: ${colors.error_color};
`;

export const ProgressInner = styled(ProgressOuter)<{ progress: number }>`
  width: ${({ progress }) => progress}%;
  position: absolute;
  left: 0;
  background-color: ${colors.well_color};
  ${({ progress }) =>
    progress < 100 &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

export const RowLeft = styled(Row)`
  justify-content: flex-start;
`;

export const WellRank = styled.span`
  font-size: 14px;
  width: 36px;
  min-width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
  background-color: ${(props) => props.theme.themeStyle.colors.tertiary_bg};
`;

export const WellTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;

  @media screen and (max-width: 1300px) {
    font-size: 14px;
  }
`;

export const WellInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const WellJobs = styled(WellTitle)`
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  font-weight: 400;
`;

export const WellSubheading = styled.div`
  font-size: 14px;
`;

export const RequiredCommentRow = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const KpiDescription = styled.div`
  flex: 7;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CommentIconContainer = styled.div`
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  background-color: ${colors.well_color};
`;

export const ControlsContainer = styled.div`
  flex: 3;
  gap: 4px;
  display: flex;
  justify-content: flex-end;
`;
