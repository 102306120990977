import type { DragEndEvent } from "@dnd-kit/core";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import type { PlanDto } from "apis/oag";
import { arrayMoveImmutable } from "array-move";
import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import { OverviewRow } from "components/WellPlan/Overview/OverviewRow";
import { useDeleteModal } from "components/WellPlan/WellPlanCommun/DeleteModal";
import { useDescriptionModal } from "components/WellPlan/WellPlanCommun/DescriptionModal";
import { useHoleSections } from "hooks/drillingInvariants/useHoleSections";
import { usePhases } from "hooks/drillingInvariants/usePhases";
import React, { useMemo } from "react";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import colors from "utils/colors";
import { Card, Col, Row } from "utils/componentLibrary";
import { PdCardHead } from "utils/componentLibrary/Card";
import type { IErrorPlanEditor } from "utils/wellplan/errors";

const WellCard = styled(Card)`
  border-radius: 10px;
  border: solid 1px ${({ theme }) => theme.themeStyle.colors.primary_accent};
  ${PdCardHead} {
    padding: 22px 20px;
  }
`;

const Well = ({
  errors,
  plan,
  setPlan,
  wellName,
  selection,
  setPlanModified,
}: {
  errors: IErrorPlanEditor["overview"];
  plan: PlanDto;
  selection: string;
  setPlan: React.Dispatch<React.SetStateAction<PlanDto>>;
  wellName: string;
  setPlanModified: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { data: sectionData } = useHoleSections();
  const { data: _phaseData } = usePhases();
  const phaseData = _phaseData ?? [];
  const sensors = useSensors(useSensor(PointerSensor));

  const ids = useMemo(
    () => plan?.activities.map((item) => item.id.toString()) || [],
    [plan.activities],
  );

  const onSortEnd = ({ active, over }: DragEndEvent) => {
    setPlan((prevPlan) => {
      const points = prevPlan.activities;
      const oldIndex = points.findIndex(
        (item) => item.id.toString() === active.id,
      );
      const newIndex = points.findIndex(
        (item) => item.id.toString() === over?.id,
      );
      const newActivities = arrayMoveImmutable(points, oldIndex, newIndex).map(
        (item, index) => ({
          ...item,
          position: index,
        }),
      );
      return { ...prevPlan, activities: newActivities };
    });
    setPlanModified(true);
  };

  const { node: descriptionModalNode, open: openDescriptionModal } =
    useDescriptionModal({
      onSave: (description, id) => {
        Track.interact("Well Overview - Add Comment", { description, id });

        setPlan((prevPlan) => ({
          ...prevPlan,
          activities: prevPlan.activities.map((point) =>
            point.id === id ? { ...point, description } : point,
          ),
        }));
        setPlanModified(true);
      },
    });

  const { node: deleteModalNode, deleteActivity } = useDeleteModal({
    wellName,
    onDelete: (id) => {
      setPlan((prevPlan) => ({
        ...prevPlan,
        activities: prevPlan.activities.filter((point) => point.id !== id),
      }));
      setPlanModified(true);
    },
  });

  return (
    <>
      <WellCard
        title={
          <Row justify="space-between" gutter={8}>
            <Col flex="1">Hole Section</Col>
            <Col flex="1">Phase</Col>
            <Col span={4}>Duration</Col>
            <Col span={4}>End Depth</Col>
            <Col flex="1" style={{ maxWidth: "132px" }} />
          </Row>
        }
        style={{
          overflow: "hidden",
        }}
      >
        <Col style={{ paddingBottom: 8 }}>
          <OverviewRow
            errors={errors}
            type="StartDepth"
            key="first_id"
            selection={selection}
            initialStartDepth={plan.startHoleDepth ?? undefined}
            setPlanModified={setPlanModified}
            setPlan={setPlan}
            {...{ sectionData, phaseData }}
          />
        </Col>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={onSortEnd}
        >
          <Row gutter={[0, 8]}>
            <SortableContext items={ids} strategy={verticalListSortingStrategy}>
              {plan.activities.map((activity) => (
                <Col span={24} key={activity.id}>
                  <OverviewRow
                    activity={activity}
                    errors={errors}
                    initialStartDepth={plan.startHoleDepth ?? undefined}
                    type="WellPlanPoint"
                    selection={selection}
                    onDescriptionClick={() =>
                      openDescriptionModal({
                        trackingProps: {
                          Well: wellName,
                          "Activity Id": activity.id,
                          "Activity Position": activity.position,
                          "Phase Name":
                            phaseData.find(
                              (phase) => phase.id === activity.phaseId,
                            )?.name ?? "Unknown",
                        },
                        activityId: activity.id,
                        initialDescription: activity.description || "",
                        title:
                          phaseData.find(
                            (phase) => phase.id === activity.phaseId,
                          )?.name ?? "Unknown",
                      })
                    }
                    onDeleteClick={() =>
                      deleteActivity({ activityId: activity.id })
                    }
                    setPlanModified={setPlanModified}
                    setPlan={setPlan}
                    {...{
                      wells: plan.activities,
                      sectionData,
                      phaseData,
                    }}
                  />
                </Col>
              ))}
            </SortableContext>
          </Row>
        </DndContext>
        <Button
          size="large"
          trackingName="Add Plan Point"
          style={{ width: "100%", marginTop: "8px", color: colors.well_color }}
          onClick={() => {
            Track.interact("Well Overview - Add Record");
            setPlan((prevPlan) => {
              const minNegativeId = prevPlan.activities
                .map((activity) => activity.id)
                .filter((id) => id < 0)
                .reduce((a: number, b: number) => Math.min(a, b), 0);

              const maxPosition = prevPlan.activities
                .map((activity) => activity.position)
                .reduce((a, b) => Math.max(a, b), 1);

              return {
                ...prevPlan,
                activities: [
                  ...prevPlan.activities,
                  {
                    startHoleDepth: 0,
                    startCumulativeDuration: 0,
                    endCumulativeDuration: 0,
                    id: minNegativeId - 1,
                    position: maxPosition + 1,
                    phaseId: -1,
                    sectionId: -1,
                    endHoleDepth: -1,
                    duration: -1,
                    description: "",
                  },
                ],
              };
            });
          }}
        >
          <PDComponent.SvgIcon name="plusOutline" />
          Add record
        </Button>
      </WellCard>
      {descriptionModalNode}
      {deleteModalNode}
    </>
  );
};

export default Well;
