import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import type { RigPowerFactDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import {
  BESS_OUTPUT_ID,
  RIG_DEMAND_ID,
  STATE_OF_CHARGE_ID,
} from "components/Lenses/ContainerLens/RigPower/RigPowerBess/useAdditionalBessLegendItems";
import {
  getCumulativeVisibleGenerators,
  getTotalDisplayedPower,
  UNIT_LABEL,
} from "components/Lenses/ContainerLens/RigPower/utils";
import { bisector } from "d3-array";
import type { ScaleLinear } from "d3-scale";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { OnRefChangeType } from "react-resize-detector/build/types/types";
import { ALTERNATIVE_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

export const useRigPowerTooltip = ({
  containerRef,
  legendItems,
  generatorLegendItems,
  xScale,
  yScale,
  percentageYScale,
  series,
  isPointerInsideChart,
  plotWidth,
  plotHeight,
}: {
  containerRef:
    | OnRefChangeType<HTMLDivElement>
    | React.MutableRefObject<HTMLDivElement>;
  legendItems: LegendItem[];
  generatorLegendItems: LegendItem[];
  xScale: ScaleLinear<number, number, never>;
  yScale: ScaleLinear<number, number, never>;
  percentageYScale: ScaleLinear<number, number, never>;
  series: RigPowerFactDto[];
  isPointerInsideChart: boolean;
  plotWidth: number;
  plotHeight: number;
}) => {
  const [pointerPosition, setPointerPosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });

  const uomValue = useUOM(DimensionType.Watts);
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const handlePointerMove = useCallback(
    (event: React.PointerEvent<SVGSVGElement>) => {
      const { left, top } = event.currentTarget.getBoundingClientRect();
      const x = ("clientX" in event ? event.clientX : 0) - left;
      const y = ("clientY" in event ? event.clientY : 0) - top;
      setPointerPosition({ x, y });
    },
    [],
  );

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip({
    containerRef,
    renderContent: ({ tooltipData }: { tooltipData?: RigPowerFactDto }) => {
      return (
        <>
          <TooltipHighlightValue style={{ fontWeight: "bold", fontSize: 12 }}>
            {uomValue.display(
              getTotalDisplayedPower(generatorLegendItems, tooltipData),
              {
                fractionDigits: 0,
              },
            )}
          </TooltipHighlightValue>

          {generatorLegendItems
            .toReversed()
            .filter((legendItem) => legendItem.isEnabled)
            .map((legendItem) => {
              const matechedTooltipValue = tooltipData?.slices?.find(
                (slice) => slice.generator === legendItem.id,
              );
              return (
                <TooltipHighlightValue
                  key={legendItem.id}
                  style={{
                    fontSize: 12,
                    color: themeColors.disabled_typography,
                  }}
                >
                  {legendItem.name}:{" "}
                  {uomValue.display(
                    matechedTooltipValue ? matechedTooltipValue.sliceValue : 0,
                    {
                      fractionDigits: 0,
                    },
                  )}
                </TooltipHighlightValue>
              );
            })}

          {legendItems.find(
            (legendItem) => legendItem.id === STATE_OF_CHARGE_ID,
          )?.isEnabled && tooltipData?.stateOfCharge ? (
            <TooltipHighlightValue
              style={{ fontSize: 12, color: themeColors.disabled_typography }}
            >
              State of Charge:{" "}
              {tooltipData?.stateOfCharge
                ? (tooltipData?.stateOfCharge * 100).toFixed(2) + "%"
                : "--"}
            </TooltipHighlightValue>
          ) : null}

          {legendItems.find((legendItem) => legendItem.id === RIG_DEMAND_ID)
            ?.isEnabled && tooltipData?.rigDemand ? (
            <TooltipHighlightValue
              style={{ fontSize: 12, color: themeColors.disabled_typography }}
            >
              Rig Demand:
              {uomValue.display(tooltipData?.rigDemand, {
                fractionDigits: 0,
                unit: UNIT_LABEL,
              })}
            </TooltipHighlightValue>
          ) : null}

          {legendItems.find((legendItem) => legendItem.id === BESS_OUTPUT_ID)
            ?.isEnabled && tooltipData?.bessOutput ? (
            <TooltipHighlightValue
              style={{ fontSize: 12, color: themeColors.disabled_typography }}
            >
              BESS Output:
              {uomValue.display(tooltipData.bessOutput, {
                fractionDigits: 0,
                unit: UNIT_LABEL,
              })}
            </TooltipHighlightValue>
          ) : null}

          {tooltipData?.at ? (
            <TooltipHighlightValue
              style={{ color: themeColors.disabled_typography, fontSize: 12 }}
            >
              {formatTime(tooltipData.at, {
                formatStr: ALTERNATIVE_DATE_FORMAT,
              })}
            </TooltipHighlightValue>
          ) : null}
        </>
      );
    },
  });

  const tooltipPoint: RigPowerFactDto | null = useMemo(() => {
    if (!series) return null;

    if (pointerPosition.x > plotWidth) {
      return null;
    }

    const x0 = xScale.invert(pointerPosition.x);

    let extremitiesBisectFix = 0;

    if (pointerPosition.x < 30) {
      // To make sure we can select both points at the edge of the graph, normal  bisect leaves out either one or the other
      // Artificially adding mouse X if it's close to the plot edges to get correct edge index
      extremitiesBisectFix = -0.001;
    } else if (Math.abs(pointerPosition.x - plotWidth) < 10) {
      extremitiesBisectFix = 0.001;
    }

    const index = bisector<RigPowerFactDto, number>((p) => p.index).left(
      series,
      x0 + extremitiesBisectFix,
    );

    if (index >= 0 && index < series.length && series[index].index) {
      return series[index];
    }

    return null;
  }, [plotWidth, pointerPosition.x, series, xScale]);

  const cumulativeVisibleGenerators = useMemo(
    () => getCumulativeVisibleGenerators(generatorLegendItems, tooltipPoint),
    [generatorLegendItems, tooltipPoint],
  );

  const [stateOfChargeValue, bessOutputValue, rigDemandValue] = useMemo(() => {
    const stateOfCharge =
      legendItems.find((item) => item.id === STATE_OF_CHARGE_ID)?.isEnabled &&
      tooltipPoint?.stateOfCharge
        ? percentageYScale(tooltipPoint.stateOfCharge * 100)
        : Infinity;

    const bessOutput =
      legendItems.find((item) => item.id === BESS_OUTPUT_ID)?.isEnabled &&
      tooltipPoint?.bessOutput
        ? yScale(tooltipPoint.bessOutput)
        : Infinity;

    const rigDemand =
      legendItems.find((item) => item.id === RIG_DEMAND_ID)?.isEnabled &&
      tooltipPoint?.rigDemand
        ? yScale(tooltipPoint.rigDemand)
        : Infinity;

    return [stateOfCharge, bessOutput, rigDemand];
  }, [legendItems, percentageYScale, tooltipPoint, yScale]);

  useEffect(() => {
    const top = Math.min(
      stateOfChargeValue,
      bessOutputValue,
      rigDemandValue,
      yScale(
        Math.min(
          tooltipPoint?.slices
            ? Math.max(
                ...(cumulativeVisibleGenerators.map(
                  (l) => l.positionalValue ?? 0,
                ) ?? []),
              )
            : Infinity,
          yScale.domain()[1],
        ),
      ) || 0,
    );

    return isPointerInsideChart && tooltipPoint
      ? showTooltip({
          tooltipLeft: xScale(tooltipPoint.index) || 0,
          tooltipTop: top,
          tooltipData: tooltipPoint,
        })
      : hideTooltip();
  }, [
    bessOutputValue,
    cumulativeVisibleGenerators,
    hideTooltip,
    isPointerInsideChart,
    percentageYScale,
    pointerPosition,
    rigDemandValue,
    showTooltip,
    stateOfChargeValue,
    tooltipPoint,
    xScale,
    yScale,
  ]);

  const Tooltip =
    tooltipPoint && isPointerInsideChart ? (
      <>
        {tooltipElement}
        <Group>
          <Line
            from={{
              x: xScale(tooltipPoint.index),
              y: plotHeight,
            }}
            to={{
              x: xScale(tooltipPoint.index),
              y: 0,
            }}
            strokeOpacity={0.3}
            stroke={themeColors.primary_typography}
            strokeWidth={2}
          />

          {cumulativeVisibleGenerators.map((l) => {
            return (
              <circle
                key={l?.legendItem.id}
                cx={xScale(tooltipPoint.index)}
                cy={yScale(l?.positionalValue ?? 0)}
                r={3}
                fill={themeColors.black_white}
              />
            );
          })}

          {legendItems.find((item) => item.id === STATE_OF_CHARGE_ID)
            ?.isEnabled && tooltipPoint.stateOfCharge ? (
            <circle
              cx={xScale(tooltipPoint.index)}
              cy={percentageYScale(tooltipPoint.stateOfCharge * 100)}
              r={3}
              fill={themeColors.black_white}
            />
          ) : null}

          {legendItems.find((item) => item.id === BESS_OUTPUT_ID)?.isEnabled &&
          tooltipPoint.bessOutput ? (
            <circle
              cx={xScale(tooltipPoint.index)}
              cy={yScale(tooltipPoint.bessOutput)}
              r={3}
              fill={themeColors.black_white}
            />
          ) : null}

          {legendItems.find((item) => item.id === RIG_DEMAND_ID)?.isEnabled &&
          tooltipPoint.rigDemand ? (
            <circle
              cx={xScale(tooltipPoint.index)}
              cy={yScale(tooltipPoint.rigDemand)}
              r={3}
              fill={themeColors.black_white}
            />
          ) : null}
        </Group>
      </>
    ) : null;
  return { handlePointerMove, tooltipPoint, tooltipElement: Tooltip };
};
