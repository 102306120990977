/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParameterByDepthTrackComparisonWellDto } from './ParameterByDepthTrackComparisonWellDto';
import {
    ParameterByDepthTrackComparisonWellDtoFromJSON,
    ParameterByDepthTrackComparisonWellDtoFromJSONTyped,
    ParameterByDepthTrackComparisonWellDtoToJSON,
} from './ParameterByDepthTrackComparisonWellDto';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface StickSlipByDepthSetDto
 */
export interface StickSlipByDepthSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof StickSlipByDepthSetDto
     */
    readonly stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof StickSlipByDepthSetDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthSetDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof StickSlipByDepthSetDto
     */
    lastUpdatedAt?: DateDto | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof StickSlipByDepthSetDto
     */
    readonly dataState: ResultDataState;
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthSetDto
     */
    wellId: number;
    /**
     * 
     * @type {Array<ParameterByDepthTrackComparisonWellDto>}
     * @memberof StickSlipByDepthSetDto
     */
    facts: Array<ParameterByDepthTrackComparisonWellDto>;
}



/**
 * Check if a given object implements the StickSlipByDepthSetDto interface.
 */
export function instanceOfStickSlipByDepthSetDto(value: object): value is StickSlipByDepthSetDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('facts' in value) || value['facts'] === undefined) return false;
    return true;
}

export function StickSlipByDepthSetDtoFromJSON(json: any): StickSlipByDepthSetDto {
    return StickSlipByDepthSetDtoFromJSONTyped(json, false);
}

export function StickSlipByDepthSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StickSlipByDepthSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': json['LastUpdatedAt'] == null ? undefined : DateDtoFromJSON(json['LastUpdatedAt']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'wellId': json['WellId'],
        'facts': ((json['Facts'] as Array<any>).map(ParameterByDepthTrackComparisonWellDtoFromJSON)),
    };
}

export function StickSlipByDepthSetDtoToJSON(value?: Omit<StickSlipByDepthSetDto, 'StopWatch'|'DataState'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'WellId': value['wellId'],
        'Facts': ((value['facts'] as Array<any>).map(ParameterByDepthTrackComparisonWellDtoToJSON)),
    };
}

