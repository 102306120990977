import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Bar, BarRounded, BarStack } from "@visx/shape";
import type {
  KpiGroupUserLensDto,
  StackedStandKpiComparisonDto,
  StandKpiSliceDto,
} from "apis/oag";
import {
  DisplayOption,
  ResultDataState,
  StackingType,
  VisualAidType,
} from "apis/oag";
import {
  AverageLabel,
  AverageLine,
} from "components/Lenses/common/AverageLine";
import { Chart } from "components/Lenses/common/Chart";
import { Label } from "components/Lenses/common/Label";
import { MedianLabel, MedianLine } from "components/Lenses/common/MedianLine";
import {
  OperationCount,
  StyledOperationCountContainer,
} from "components/Lenses/common/OperationCount";
import TargetLine from "components/Lenses/common/TargetLine";
import {
  TooltipGroup,
  TooltipHighlightValue,
} from "components/Lenses/common/Tooltip";
import {
  TooltipVisualAidInfo,
  useChartTooltip,
} from "components/Lenses/common/useChartTooltip";
import { useOutlierThreshold } from "components/Lenses/common/useOutlierThreshold";
import type { ContainerLensProps } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { ShiftKey } from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleComparisonWellShiftChart";
import { getMinMax, getSVGNormalizedValue } from "components/Lenses/utils";
import { RIGHT_AXIS_PADDING } from "components/TvDChart/constants";
import { max } from "d3-array";
import { useTargetSegments } from "hooks/charting/useTargetSegments";
import { useDirectionalIntervals } from "hooks/drillingInvariants/useDirectionalIntervals";
import { useHoleSections } from "hooks/drillingInvariants/useHoleSections";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import { keyBy } from "lodash";
import { roundNumberToDecimal } from "pages/Lens/LensSummaryView";
import { shade, tint, transparentize } from "polished";
import React, { useCallback, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import { OPERATION_COUNT_HEIGHT } from "utils/constants";
import { useUOM, useUOMbyLens, UtilDimensions } from "utils/format";
import { truncateMiddleString } from "utils/helper";
import { useColors } from "utils/useColors";
import { useCustomTheme } from "utils/useTheme";

interface StackedColumnProps extends ContainerLensProps {
  data: StackedStandKpiComparisonDto;
  lens: KpiGroupUserLensDto;
  templateType?: string;
  displayOption: DisplayOption;
}

type TransformedDataWellItem = {
  wellId: number;
  categoryId: number;
  targetValue: number;
  totalValue: number;
  values: Record<number, number>;
  average: Record<number, number>;
  operationCount?: number;
  isOutlier?: boolean;
};

export function StackedComparisonCategoryChart({
  detailed,
  data,
  focalWellColor = "",
  displayOption,
  dimension,
  lens,
  templateType,
}: StackedColumnProps) {
  const { data: kpiTypes } = useKpiTypes();
  const { getColor } = useColors();
  const { data: wellShortInfo } = useWellShortInfoSuspended();
  const selectedWells = data.comparisons
    .filter((c) => c.dataState === ResultDataState.Valid)
    .map((c) => c.wellId);

  const { data: holeSectionsValues } = useHoleSections();
  const { data: directionalIntervalsValues } = useDirectionalIntervals();
  const getData = useCallback(
    (d: StandKpiSliceDto) => {
      if (!d) return 0;
      if (lens.stackingType === StackingType.Distribution)
        return d.distribution;
      return d.average;
    },
    [lens.stackingType],
  );

  const availableDisplayOptionIds = useMemo(
    () =>
      data.comparisons?.reduce<number[]>((acc, curr) => {
        return [
          ...new Set([
            ...acc,
            ...(curr.detailsByDisplayOption ?? []).map((e) => e.id),
          ]),
        ];
      }, []),
    [data.comparisons],
  );

  const categoryValues = useMemo(() => {
    if (displayOption === DisplayOption.Shift)
      return selectedWells?.map((e, idx) => ({
        id: e,
        name: e.toString(),
        position: idx,
      }));

    return (
      (displayOption === DisplayOption.HoleSection
        ? holeSectionsValues
        : directionalIntervalsValues) || []
    )
      .filter((e) => availableDisplayOptionIds.includes(e.id))
      .sort((a, b) => a.position - b.position);
  }, [
    availableDisplayOptionIds,
    directionalIntervalsValues,
    displayOption,
    holeSectionsValues,
    selectedWells,
  ]);

  const categoryMap = useMemo(
    () => keyBy(categoryValues, (i) => i.id),
    [categoryValues],
  );

  const average = useMemo(
    () => data?.summaryByKpi?.find((e) => e.isAggregate)?.allAverage ?? 0,
    [data.summaryByKpi],
  );
  const median = useMemo(
    () => data?.summaryByKpi?.find((e) => e.isAggregate)?.median ?? 0,
    [data.summaryByKpi],
  );
  const valueUOM = useUOMbyLens(dimension, lens);
  const percentageUOM = useUOM(UtilDimensions.Percentage);

  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: containerRef,
  } = useResizeDetector();
  const { chartWidth, chartHeight: containerHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };
  const chartHeight = getSVGNormalizedValue(
    containerHeight - (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0),
  );

  const plotHeight = getSVGNormalizedValue(chartHeight - (detailed ? 136 : 36));
  const plotWidth = getSVGNormalizedValue(
    chartWidth - (detailed ? 76 : 44) - 10,
  );

  const kpiKeys = data.summaryByKpi
    ?.slice()
    .sort((a, b) => a.position - b.position)
    .map((item) => item.id);

  const transformedData = useMemo(() => {
    if (displayOption === DisplayOption.Shift) {
      return data.comparisons.map((comparison) => {
        const infoByWell = (comparison.detailsByDisplayOption ?? []).map(
          (category) => {
            const categoryInfo = comparison?.detailsByDisplayOption?.find(
              (detail) => {
                return detail.id === category.id;
              },
            );
            const sliceMap =
              categoryInfo?.slices?.reduce(
                (acc, slice) => {
                  acc[slice.kpiTypeId] = getData(slice);
                  return acc;
                },
                {} as Record<number, number>,
              ) ?? {};
            const sliceMapAverage =
              categoryInfo?.slices?.reduce(
                (acc, slice) => {
                  acc[slice.kpiTypeId] = slice.average;
                  return acc;
                },
                {} as Record<number, number>,
              ) ?? {};

            return {
              wellId: category.id,
              id: category.id,
              categoryId: comparison.wellId,
              targetValue: categoryInfo?.targetAverage ?? 0,
              totalValue: categoryInfo?.average ?? 0,
              operationCount: categoryInfo?.operationCount,
              isOutlier: categoryInfo?.isOutlier,
              values: sliceMap,
              average: sliceMapAverage,
            };
          },
        );

        const maxValue = max(infoByWell, (item) =>
          Math.max(item.totalValue, item.targetValue ?? 0),
        );

        return {
          id: comparison.wellId,
          name: wellShortInfo?.byId[comparison.wellId].name,
          position: selectedWells.indexOf(comparison.wellId),
          wellIds: Object.values(ShiftKey),
          list: infoByWell,
          maxValue,
        };
      });
    }
    return categoryValues.map((category) => {
      const infoByWell = data.comparisons.map((comparison) => {
        const { wellId } = comparison;
        const categoryInfo = (comparison.detailsByDisplayOption ?? []).find(
          (detail) => {
            return detail.id === category.id;
          },
        );
        const sliceMap =
          categoryInfo?.slices?.reduce(
            (acc, slice) => {
              acc[slice.kpiTypeId] = getData(slice);
              return acc;
            },
            {} as Record<number, number>,
          ) ?? {};
        const sliceMapAverage =
          categoryInfo?.slices?.reduce(
            (acc, slice) => {
              acc[slice.kpiTypeId] = slice.average;
              return acc;
            },
            {} as Record<number, number>,
          ) ?? {};
        return {
          wellId,
          id: wellId,
          categoryId: category.id,
          targetValue: categoryInfo?.targetAverage ?? 0,
          totalValue:
            lens.stackingType === StackingType.Distribution
              ? 1
              : categoryInfo?.average ?? 0,
          values: sliceMap,
          operationCount: categoryInfo?.operationCount,
          isOutlier: categoryInfo?.isOutlier,
          average: sliceMapAverage,
        };
      });

      const maxValue = max(infoByWell, (item) =>
        Math.max(item.totalValue, item.targetValue ?? 0),
      );

      return {
        ...category,
        wellIds: Object.keys(infoByWell),
        list: infoByWell,
        maxValue,
      };
    });
  }, [
    categoryValues,
    data.comparisons,
    displayOption,
    getData,
    lens.stackingType,
    selectedWells,
    wellShortInfo?.byId,
  ]);

  const totalValues = useMemo(() => {
    return transformedData.flatMap((item) =>
      (item?.list ?? [])
        .filter((category) => !category.isOutlier)
        .map((category) =>
          lens.stackingType === StackingType.Distribution
            ? 1
            : Math.max(category.targetValue, category.totalValue),
        ),
    );
  }, [lens.stackingType, transformedData]);

  const categoryScale = useMemo(
    () =>
      scaleBand<number>({
        domain: categoryValues.map((e) => e.id),
        // Offsetting the pixel range based on zoom, provides scrolling and zoom at the same time
        range: [0, plotWidth],
        paddingInner: 0.2,
        paddingOuter: displayOption === DisplayOption.Shift ? 0.2 : 1,
      }),
    [categoryValues, displayOption, plotWidth],
  );

  const groupScale = useMemo(() => {
    return scaleBand({
      domain:
        displayOption === DisplayOption.Shift
          ? Object.values(ShiftKey)
          : selectedWells,
      paddingInner: displayOption === DisplayOption.Shift ? 0.1 : 0.15,
      range: [0, categoryScale.bandwidth()],
    });
  }, [categoryScale, displayOption, selectedWells]);

  const computedTargetSegments = useTargetSegments(
    transformedData,
    categoryScale,
    groupScale,
  );

  const { outlierThreshold, gradientDefinition, gradientFill } =
    useOutlierThreshold({
      values: totalValues,
      enabled: lens?.showsOutliers,
      selectedVisualAids: lens?.selectedVisualAids,
      targetSegments: computedTargetSegments,
    });

  const valueScale = useMemo(() => {
    return scaleLinear<number>({
      domain: getMinMax(
        totalValues,
        computedTargetSegments,
        outlierThreshold,
        median,
        lens?.selectedVisualAids,
        lens?.outlierFlaggingType,
        lens?.isManualYaxis,
        lens?.yaxisEnd,
        lens?.yaxisStart,
      ),
      range: [plotHeight, 0],
      clamp: true,
    });
  }, [
    totalValues,
    computedTargetSegments,
    outlierThreshold,
    median,
    lens?.selectedVisualAids,
    lens?.outlierFlaggingType,
    lens?.isManualYaxis,
    lens?.yaxisEnd,
    lens?.yaxisStart,
    plotHeight,
  ]);
  const {
    isDark,
    themeStyle: { colors: themeColors, outliers: outliersTheme },
  } = useCustomTheme();

  const categoryColumnWidth = categoryScale.bandwidth();

  const groupColumnWidth = groupScale.bandwidth();

  const { showTooltip, hideTooltip, tooltipElement } =
    useChartTooltip<TransformedDataWellItem>({
      containerRef,
      renderContent: ({ tooltipData }) => (
        <>
          <TooltipHighlightValue>
            {lens.stackingType === StackingType.Values
              ? valueUOM.display(tooltipData?.totalValue)
              : (kpiKeys || [])
                  .slice()
                  .reverse()
                  .map((key) =>
                    kpiTypes?.byId[key] ? (
                      <div key={key}>
                        {kpiTypes.byId[key].name}:{" "}
                        {lens.stackingType === StackingType.Distribution
                          ? roundNumberToDecimal(
                              (tooltipData?.values[key] ?? 0) * 100,
                              2,
                            ) + "%"
                          : valueUOM.display(tooltipData?.values[key] ?? 0)}
                      </div>
                    ) : null,
                  )}
          </TooltipHighlightValue>
          <TooltipGroup>
            {(kpiKeys || [])
              .slice()
              .reverse()
              .map(
                (key) =>
                  kpiTypes?.byId[key] && (
                    <div key={key + "avg"}>
                      {kpiTypes.byId[key].name}:{" "}
                      {valueUOM.display(tooltipData?.average[key] ?? 0)}
                    </div>
                  ),
              )}
          </TooltipGroup>

          <TooltipVisualAidInfo
            selectedVisualAids={lens?.selectedVisualAids}
            display={valueUOM.display}
            targetValue={tooltipData?.targetValue}
            averageValue={average}
            median={median}
          />

          {lens.showOperationCount && tooltipData?.operationCount ? (
            <TooltipHighlightValue>
              Operation count: {tooltipData?.operationCount}
            </TooltipHighlightValue>
          ) : null}
          <span>
            {tooltipData
              ? wellShortInfo?.byId[
                  tooltipData[
                    displayOption === DisplayOption.Shift
                      ? "categoryId"
                      : "wellId"
                  ]
                ]?.name
              : "Unnamed Well"}
          </span>
          {tooltipData?.isOutlier ? (
            <TooltipHighlightValue fontWeight="normal">
              OUTLIER
            </TooltipHighlightValue>
          ) : null}
        </>
      ),
    });

  const handleMouseOver = useCallback(
    (barData: TransformedDataWellItem) => {
      if (Object.keys(barData.values).length === 0) return null;
      showTooltip({
        tooltipLeft:
          (categoryScale(barData.categoryId) || 0) +
          (groupScale(barData.wellId) || 0) +
          groupColumnWidth / 2,
        tooltipTop: valueScale(barData.totalValue) - 12,
        tooltipData: barData,
      });
    },
    [showTooltip, categoryScale, groupScale, groupColumnWidth, valueScale],
  );

  // Starting initialization only with div to calculate plot width & height
  if (Number.isNaN(plotWidth) || Number.isNaN(plotHeight)) {
    return (
      <div
        ref={containerRef}
        style={{
          padding: 0,
          width: "100%",
          height: "100%",
          position: "relative",
          overflow: "visible",
        }}
      />
    );
  }

  return (
    <div
      ref={containerRef}
      style={{
        padding: 0,
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "visible",
      }}
    >
      <StyledOperationCountContainer visible={lens.showOperationCount} />
      <svg
        width={chartWidth}
        height={chartHeight}
        style={{ overflow: "visible", userSelect: "none" }}
      >
        {gradientDefinition}
        <Chart
          detailed={detailed}
          isManual={lens?.isManualYaxis}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          plotWidth={plotWidth}
          plotHeight={plotHeight}
          valueScale={valueScale}
          categoryScale={categoryScale}
          showOperationCount={lens.showOperationCount}
          valueUOM={
            lens.stackingType === StackingType.Distribution
              ? percentageUOM
              : valueUOM
          }
          rightTickFormat={
            lens.stackingType === StackingType.Distribution
              ? (value) => (value as string) + "%"
              : undefined
          }
          bottomTickComponent={
            displayOption === DisplayOption.Shift
              ? (e) => {
                  const keys = { ...e };
                  delete keys.formattedValue;

                  return [
                    <text
                      key={"D" + e.y}
                      {...keys}
                      y={e.y}
                      x={
                        e.x -
                        categoryScale.bandwidth() / 2 +
                        groupScale.bandwidth() / 2
                      }
                    >
                      {groupScale.bandwidth() > 27 ? "Day" : "D"}
                    </text>,
                    <text
                      key={"N" + e.x}
                      {...keys}
                      x={
                        e.x +
                        categoryScale.bandwidth() / 2 -
                        groupScale.bandwidth() / 2
                      }
                    >
                      {groupScale.bandwidth() > 27 ? "Night" : "N"}
                    </text>,
                  ];
                }
              : undefined
          }
          tickFormat={(id) =>
            truncateMiddleString(
              categoryMap[id as string]?.name ?? "Undefined",
              categoryColumnWidth / 10,
            )
          }
        >
          {transformedData.map((category) => (
            <Group key={category.id} left={categoryScale(category.id)}>
              <BarStack
                x={(d) => d.wellId}
                keys={kpiKeys}
                value={(d, key) => d.values[key] ?? 0}
                data={category?.list || []}
                xScale={groupScale}
                yScale={valueScale}
                color={() => focalWellColor}
              >
                {(barStacks) => [
                  ...barStacks.map((barStack) => (
                    <React.Fragment key={`bar-stack-${barStack.index}`}>
                      {barStack.bars.map((bar, index) => {
                        if (bar.bar.some((e) => Number.isNaN(e))) return null;
                        const bars = Object.values(
                          bar?.bar?.data?.average ?? [],
                        ).filter((x) => x);
                        const isLast = barStack.index === bars.length;
                        return (
                          <React.Fragment
                            key={`bar-stack-${barStack.index}-${bar.index}-fragment`}
                          >
                            <BarRounded
                              key={`bar-stack-${barStack.index}-${bar.index}`}
                              x={bar.x}
                              y={bar.y}
                              top={
                                isLast
                                  ? lens.stackingType !==
                                    StackingType.Distribution
                                  : undefined
                              }
                              radius={2}
                              height={bar.height}
                              width={bar.width}
                              filter={(() => {
                                if (
                                  templateType === "Rotate vs Slide" &&
                                  bar.bar.data.wellId === ShiftKey["Night"]
                                )
                                  return `grayscale(${(barStack.index - 1) * 50}%)`;
                                return "";
                              })()}
                              // fill={fillColor(bar, barStacks, barStack, category)}
                              fill={(() => {
                                if (
                                  !getColor({
                                    key: bar.bar.data.wellId.toString(),
                                  })
                                )
                                  return "";
                                if (templateType === "Rotate vs Slide") {
                                  if (displayOption === DisplayOption.Shift) {
                                    if (bar.bar.data.wellId === ShiftKey["Day"])
                                      return tint(
                                        0.15 * barStack.index,
                                        getColor({
                                          key: category.id.toString(),
                                        }),
                                      );
                                    return shade(
                                      0.3,
                                      shade(
                                        0.3 *
                                          (barStacks.length -
                                            barStack.index -
                                            1),
                                        getColor({
                                          key: category.id.toString(),
                                        }),
                                      ),
                                    );
                                  }
                                  if (bar.bar.data.wellId === ShiftKey["Day"])
                                    return tint(
                                      0.5 * (barStack.index - 1),
                                      getColor({
                                        key: bar.bar.data.wellId.toString(),
                                      }),
                                    );
                                  return shade(
                                    0.5 *
                                      (barStacks.length - barStack.index - 1),
                                    getColor({
                                      key: bar.bar.data.wellId.toString(),
                                    }),
                                  );
                                }

                                if (bar.bar.data?.isOutlier)
                                  return isDark
                                    ? `${transparentize(
                                        0.9,
                                        shade(
                                          0.3 *
                                            (barStacks.length -
                                              barStack.index -
                                              1),
                                          outliersTheme.bars,
                                        ),
                                      )}`
                                    : `${tint(0.3 * barStack.index, outliersTheme.bars_stacked_transparent)}`;

                                if (displayOption !== DisplayOption.Shift)
                                  return shade(
                                    0.3 *
                                      (barStacks.length - barStack.index - 1),
                                    getColor({
                                      key: bar.bar.data.wellId.toString(),
                                    }),
                                  );
                                if (!getColor({ key: category.id.toString() }))
                                  return "";
                                if (bar.bar.data.wellId === ShiftKey["Day"])
                                  return tint(
                                    0.15 * barStack.index,
                                    getColor({ key: category.id.toString() }),
                                  );
                                return shade(
                                  0.3,
                                  shade(
                                    0.3 *
                                      (barStacks.length - barStack.index - 1),
                                    getColor({ key: category.id.toString() }),
                                  ),
                                );
                              })()}
                            />
                            {barStack.index === barStacks.length - 1 && (
                              <>
                                <Label
                                  barX={bar.x}
                                  barY={bar.y + (detailed ? 0 : 10)}
                                  topLabel={bar.y !== plotHeight}
                                  detailed={detailed}
                                  index={index}
                                  columnWidth={bar.width}
                                  barHeight={bar.height}
                                  value={
                                    lens.stackingType ===
                                    StackingType.Distribution
                                      ? ""
                                      : valueUOM.display(
                                          bar.bar.data.totalValue,
                                          { unit: "" },
                                        )
                                  }
                                  label={lens?.label}
                                />
                                {lens.showOperationCount ? (
                                  <OperationCount
                                    x={bar.x}
                                    width={bar.width}
                                    index={index}
                                    detailed={detailed}
                                    value={bar.bar.data.operationCount}
                                  />
                                ) : null}
                                {bar.bar.data?.isOutlier ? (
                                  <Bar
                                    x={bar.x - 1}
                                    y={bar.y - 1}
                                    width={categoryScale.bandwidth() + 2}
                                    onMouseOver={() =>
                                      handleMouseOver(bar.bar.data)
                                    }
                                    onMouseOut={hideTooltip}
                                    height={bar.height}
                                    fill={gradientFill}
                                  />
                                ) : null}
                              </>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  )),
                  ...barStacks.map((barStack) => (
                    <React.Fragment
                      key={`bar-stack-${barStack.index}-label-container`}
                    >
                      {barStack.bars.map((bar, index) => {
                        if (bar.bar.some((e) => Number.isNaN(e))) return null;
                        return (
                          <Label
                            barX={bar.x}
                            innerLabel
                            key={`bar-stack-${barStack.index}-${bar.index}-label`}
                            barY={bar.y + bar.height / 2}
                            columnWidth={bar.width}
                            barHeight={bar.height}
                            index={index}
                            value={
                              lens.stackingType === StackingType.Distribution
                                ? `${roundNumberToDecimal(bar.bar.data.values[bar.key] * 100, 2)}%`
                                : valueUOM.display(
                                    bar.bar.data.values[bar.key],
                                    { unit: "" },
                                  )
                            }
                            label={lens?.label}
                          />
                        );
                      })}
                    </React.Fragment>
                  )),
                ]}
              </BarStack>
            </Group>
          ))}
          {/* Rectangles to cover the whole stacked bar and handle all the events */}
          {transformedData.map((category) => {
            return (
              <Group key={category.id} left={categoryScale(category.id)}>
                {(category?.list ?? []).map((item) => (
                  <Bar
                    key={item.wellId}
                    x={groupScale(item.wellId)}
                    y={valueScale(item.totalValue)}
                    width={groupColumnWidth}
                    height={valueScale(0) - valueScale(item.totalValue)}
                    fillOpacity={0}
                    onMouseOver={() => handleMouseOver(item)}
                    onMouseOut={hideTooltip}
                  />
                ))}
              </Group>
            );
          })}
          {Number.isFinite(average) && (
            <AverageLine
              isVisible={(lens.selectedVisualAids ?? []).includes(
                VisualAidType.Average,
              )}
              y={valueScale(average)}
              x={-RIGHT_AXIS_PADDING}
              width={plotWidth + RIGHT_AXIS_PADDING}
            />
          )}

          {Number.isFinite(median) && (
            <MedianLine
              isVisible={(lens.selectedVisualAids ?? []).includes(
                VisualAidType.Median,
              )}
              y={valueScale(median)}
              x={-RIGHT_AXIS_PADDING}
              width={plotWidth + RIGHT_AXIS_PADDING}
            />
          )}

          {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Targets) &&
            computedTargetSegments.map(
              ({ target, lineStart, lineEnd, showTag }, index) => {
                if (!target) return null;
                return (
                  <TargetLine
                    key={`${index}-${lineStart}-${lineEnd}-${target}`}
                    start={lineStart}
                    end={lineEnd}
                    y={valueScale(target)}
                    label={valueUOM.display(target, { unit: "" })}
                    showTag={!!showTag}
                    detailed={detailed}
                  />
                );
              },
            )}
        </Chart>
      </svg>
      {(lens.selectedVisualAids ?? []).includes(VisualAidType.Average) && (
        <AverageLabel
          style={{
            top: `${valueScale(average) - 16 + (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)}px`,
            left: `0px`,
            transform: "translateX(50%)",
          }}
        >
          Average: {valueUOM.display(average)}
        </AverageLabel>
      )}

      {(lens.selectedVisualAids ?? []).includes(VisualAidType.Median) && (
        <MedianLabel
          isDetailed={detailed}
          style={{
            top: `${valueScale(median) - 16 + (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)}px`,
          }}
        >
          Median: {valueUOM.display(median)}
        </MedianLabel>
      )}

      {tooltipElement}
    </div>
  );
}
