// if there is a need to change those; check that useDashboardType work afterwards
export const base = {
  well: "/well/",
  rig: "/rig/",
  evergreen: "/evergreen/",
  auth: "/auth/",
  admin: "/admin/",
  report: "/report/",
};

export const routePaths = {
  // Because of Vite limitation, need to avoid circular imports
  intelDashboard: "/intel/",
  evergreenDashboard: `${base.evergreen}:wellId`,
  rigDashboard: `${base.rig}:rigId`,
  wellDashboard: `${base.well}:wellId`,

  rigScoreCard: `${base.rig}scorecard/:rigId`,
  rigLeaderboard: `${base.rig}leaderboard`,
  fleetPerformance: base.rig,

  blank: `${base.auth}blank`,
  error401: `${base.auth}401`,
  error404: `${base.auth}404`,
  signOut: `${base.auth}sign-out`,
  reportPrepare: "/report/:reportId/:wellId/:lensId/:position/prepare/:page",

  accountList: `${base.admin}accounts`,
  accountEdit: `${base.admin}accounts/:accountId/:tabId?`,
  usersList: `${base.admin}users`,
  userCreate: `${base.admin}:breadcrumbs/:accountId/users/new/:tabId?`,
  userEdit: `${base.admin}:breadcrumbs/:accountId/users/:userId/:tabId?`,

  accountMe: "/account/me",

  createReport: `/export/:wellId/:lensId`,
  newWell: `${base.well}new`,
  wellEdit: `${base.well}:wellId/edit`,
  evergreenWellEdit: `${base.evergreen}:wellId/edit`,

  wellPlan: `${base.well}:wellId/plan/:tabId?`,

  prepareLens: "/:dashboardType/:dashboardId/:lensId/prepare/:data",
  lensLibrary: "/:dashboardType/:dashboardId/lens-library",
  lensDetailed: "/:dashboardType/:dashboardId/:lensId",
  systemRigLens: "/:dashboardType/:dashboardId/:lensId/:rigId/system",

  notifications: "/notifications/:notificationTab",
  pendingTasks: "/pendingTasks",

  reportFront: `${base.report}:reportId/:wellId/:position/front`,
  reportLens: `${base.report}:reportId/:wellId/:lensId/:position/lens`,
  reportTvd: `${base.report}:reportId/:wellId/:lensId/:position/tvd`,

  allWells: "/",
  evergreen: base.evergreen,
};
