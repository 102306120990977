import adminReducer from "reducers/adminReducer";
import allWellsReducer from "reducers/allWellsReducer";
import autoRefreshReducer from "reducers/autoRefreshReducer";
import claraReducer from "reducers/claraReducer";
import evergreenWellsReducer from "reducers/evergreenWellsReducer";
import globalReducer from "reducers/globalReducer";
import intelDashboardReducer from "reducers/intelDashboardReducer";
import intelFiltersReducerCommitted from "reducers/intelFiltersReducerCommitted";
import intelFiltersReducerCosmeticOptions from "reducers/intelFiltersReducerCosmeticOptions";
import intelFiltersReducerUncommitted from "reducers/intelFiltersReducerUncommitted";
import legendColorReducer from "reducers/legendColorReducer";
import lensDimensionsReducer from "reducers/lensDimensionsReducer";
import notificationsReducer from "reducers/notificationsReducer";
import reportReducer from "reducers/reportReducer";
import rigDashboardReducer from "reducers/rigDashboardReducer";
import rigsCommonReducer from "reducers/rigsCommonReducer";
import stateReducer from "reducers/stateReducer";
import themeReducer from "reducers/themeReducer";
import timelineReducer from "reducers/timelineReducer";
import widgetReducer from "reducers/widgetReducer";
import { combineReducers } from "redux";

// TODO group reducers in folders by dashboard relationship

export const appReducer = combineReducers({
  admin: adminReducer,
  rigsCommon: rigsCommonReducer,
  allWells: allWellsReducer,
  intelDashboard: intelDashboardReducer,
  intelFiltersCommitted: intelFiltersReducerCommitted,
  intelFiltersUncommitted: intelFiltersReducerUncommitted,
  intelFiltersCosmeticOptions: intelFiltersReducerCosmeticOptions,
  evergreenWells: evergreenWellsReducer,
  dataState: autoRefreshReducer,
  global: globalReducer,
  lensDimensions: lensDimensionsReducer,
  report: reportReducer,
  rigDashboard: rigDashboardReducer,
  state: stateReducer, // TODO shall rename to wellDashboard
  timeline: timelineReducer,
  widgetOptions: widgetReducer,
  theme: themeReducer,
  legendColor: legendColorReducer,
  notifications: notificationsReducer,
  claraReducer: claraReducer,
});

const rootReducer: typeof appReducer = (
  state,
  action: Parameters<typeof appReducer>[1],
) => {
  return appReducer(state, action);
};

export { rootReducer };
