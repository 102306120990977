import type { ParameterByDepthUserLensTrackItemDto } from "apis/oag";
import type { TrackSettingsModalProps } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/TrackSettingsModal/TrackSettingsModal";
import { TrackSettingsModal } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/TrackSettingsModal/TrackSettingsModal";
import type { ITrackUnitTypes } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/utils/trackUnitTypes";
import { useCallback, useEffect, useState } from "react";
import type { IValueWithUnit } from "utils/interfaces/Well/interfaces";

export function useTrackSettingsModal({
  trackUnitTypes,
  availableTrackIds,
  onSave,
  onDelete,
  selectedUnits,
}: Pick<
  TrackSettingsModalProps,
  "availableTrackIds" | "onSave" | "onDelete"
> & {
  selectedUnits: Record<number, IValueWithUnit>;
  trackUnitTypes: ITrackUnitTypes;
}) {
  const [visible, setVisible] = useState(false);
  const [selectedTrack, setSelectedTrack] = useState<
    ParameterByDepthUserLensTrackItemDto | undefined
  >(undefined);

  const close = useCallback(() => {
    setSelectedTrack(undefined);
    setVisible(false);
  }, []);

  const open = useCallback((track: ParameterByDepthUserLensTrackItemDto) => {
    setSelectedTrack(track);
    setVisible(true);
  }, []);

  useEffect(() => {
    if (!visible) {
      setSelectedTrack(undefined);
    }
  }, [visible]);

  const node = selectedTrack ? (
    <TrackSettingsModal
      trackUnitTypes={trackUnitTypes}
      selectedTrack={selectedTrack}
      setVisible={setVisible}
      visible={visible}
      availableTrackIds={availableTrackIds}
      onSave={onSave}
      onDelete={onDelete}
      selectedUnits={selectedUnits}
    />
  ) : null;

  return { visible, close, open, node };
}
