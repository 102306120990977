/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IntelScatterPlotFactDto } from './IntelScatterPlotFactDto';
import {
    IntelScatterPlotFactDtoFromJSON,
    IntelScatterPlotFactDtoFromJSONTyped,
    IntelScatterPlotFactDtoToJSON,
} from './IntelScatterPlotFactDto';

/**
 * 
 * @export
 * @interface IntelScatterPlotFactSetDto
 */
export interface IntelScatterPlotFactSetDto {
    /**
     * 
     * @type {Array<IntelScatterPlotFactDto>}
     * @memberof IntelScatterPlotFactSetDto
     */
    facts: Array<IntelScatterPlotFactDto>;
    /**
     * 
     * @type {Array<number>}
     * @memberof IntelScatterPlotFactSetDto
     */
    quartileThresholds: Array<number>;
}

/**
 * Check if a given object implements the IntelScatterPlotFactSetDto interface.
 */
export function instanceOfIntelScatterPlotFactSetDto(value: object): value is IntelScatterPlotFactSetDto {
    if (!('facts' in value) || value['facts'] === undefined) return false;
    if (!('quartileThresholds' in value) || value['quartileThresholds'] === undefined) return false;
    return true;
}

export function IntelScatterPlotFactSetDtoFromJSON(json: any): IntelScatterPlotFactSetDto {
    return IntelScatterPlotFactSetDtoFromJSONTyped(json, false);
}

export function IntelScatterPlotFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelScatterPlotFactSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'facts': ((json['Facts'] as Array<any>).map(IntelScatterPlotFactDtoFromJSON)),
        'quartileThresholds': json['QuartileThresholds'],
    };
}

export function IntelScatterPlotFactSetDtoToJSON(value?: IntelScatterPlotFactSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Facts': ((value['facts'] as Array<any>).map(IntelScatterPlotFactDtoToJSON)),
        'QuartileThresholds': value['quartileThresholds'],
    };
}

