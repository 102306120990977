/* eslint-disable react/forbid-dom-props */
/* eslint-disable react/no-multi-comp */
import * as Styled from "components/PDComponents/Dropdown/style";
import { useEffectExceptOnMount } from "hooks/react-utils/useEffectExceptOnMount";
import { isEqual } from "lodash";
import type { Dict } from "mixpanel-browser";
import { useMemo, useState } from "react";
import { Track } from "services/Mixpanel";
import { useCustomTheme } from "utils/useTheme";
type TOptionData = number | string;
export type TDropDownOptions<OptionType> = {
  label: string;
  labelDescription?: string;
  value: OptionType;
  optionData?: TOptionData;
};

export function _Dropdown<OptionType>({
  selectedOption,
  options,
  optionData,
  handleOptionUpdate,
  onMenuVisibilityChange,
  variant = "text",
  backgroundColor,
  trackingText,
  trackingData,
  isLocked,
  selectedItemStyle,
  placement = "bottomLeft",
  alwaysShowChevron = false,
}: {
  selectedOption: OptionType;
  optionData?: TOptionData;
  options: TDropDownOptions<OptionType>[];
  handleOptionUpdate: (option: OptionType, optionData?: TOptionData) => void;
  onMenuVisibilityChange?: React.Dispatch<React.SetStateAction<boolean>>;
  variant?: "text" | "button";
  backgroundColor?: string;
  trackingText?: string;
  trackingData?: Dict;
  isLocked?: boolean;
  selectedItemStyle?: React.CSSProperties;
  placement?:
    | "top"
    | "bottom"
    | "bottomLeft"
    | "topLeft"
    | "topCenter"
    | "topRight"
    | "bottomCenter"
    | "bottomRight";
  alwaysShowChevron?: boolean;
  disabled?: boolean;
}) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffectExceptOnMount(() => {
    onMenuVisibilityChange?.(isMenuVisible);
  }, []);

  const selectedOptionLabel = useMemo(
    () =>
      options.find((option) => {
        if (isEqual(option.value, selectedOption)) {
          if (option.optionData) {
            return isEqual(option.optionData, optionData);
          }
          return true;
        }
        return false;
      })?.label,
    [selectedOption, options, optionData],
  );

  const MenuItems = () => (
    <Styled.Card>
      {options.map(({ value, label, optionData, labelDescription }) => (
        <Styled.OptionRow justify="space-between" align="middle" key={label}>
          <Styled.Option
            onClick={() => {
              setIsMenuVisible(false);
              handleOptionUpdate(value, optionData);
              if (trackingText) {
                Track.interact(trackingText, {
                  ...trackingData,
                  selectedOption: label,
                });
              }
            }}
          >
            {selectedOption === value ? <strong>{label}</strong> : label}
            {labelDescription ? (
              <Styled.LabelDescription>
                {labelDescription}
              </Styled.LabelDescription>
            ) : null}
          </Styled.Option>
        </Styled.OptionRow>
      ))}
    </Styled.Card>
  );

  const dropdownProps = {
    overlay: <MenuItems />,
    open: isLocked ? false : isMenuVisible,
    onOpenChange: () => {
      if (isLocked) {
        return;
      }
      setIsMenuVisible(!isMenuVisible);
    },
  };

  const { atomThemeVariant } = useCustomTheme();
  return (
    <>
      {variant === "text" ? (
        <Styled.DropDown
          trigger={["click"]}
          placement={placement}
          {...dropdownProps}
          $hideBorder
          disabled={isLocked}
        >
          <Styled.SelectedOption
            style={selectedItemStyle}
            $isActive={isMenuVisible}
            variant={atomThemeVariant}
          >
            {selectedOptionLabel}{" "}
            {isLocked ? null : (
              <Styled.ChevronDownIcon $alwaysShowChevron={alwaysShowChevron} />
            )}
          </Styled.SelectedOption>
        </Styled.DropDown>
      ) : null}
      {variant === "button" ? (
        <Styled.DropDown
          trigger={["click"]}
          placement={placement}
          {...dropdownProps}
          disabled={isLocked}
        >
          <Styled.StyledButton
            $isActive={isMenuVisible}
            $backgroundColor={backgroundColor}
            style={selectedItemStyle}
          >
            {selectedOptionLabel}{" "}
            {isLocked ? null : (
              <Styled.ChevronDownIcon $alwaysShowChevron={alwaysShowChevron} />
            )}
          </Styled.StyledButton>
        </Styled.DropDown>
      ) : null}
    </>
  );
}
