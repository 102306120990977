import { BessType } from "apis/oag";
import type { RigPowerLensTemplateDto } from "apis/oag/models/RigPowerLensTemplateDto";
import { RigpowerBess } from "components/Lenses/ContainerLens/RigPower/RigPowerBess";
import { RigPowerSimple } from "components/Lenses/ContainerLens/RigPower/RigPowerSimple/index";
import type { RigPowerProps } from "components/Lenses/interfaces";
import { useLensTemplates } from "hooks/lens/useLensTemplates";

export const RigPower: React.FC<RigPowerProps> = ({ lens, setLensDate }) => {
  const { data: templates } = useLensTemplates();
  const template = templates?.byId[
    lens?.lensTemplateId
  ] as RigPowerLensTemplateDto;

  return template.bessType === BessType.NonBess ? (
    <RigPowerSimple lens={lens} detailed={false} setLensDate={setLensDate} />
  ) : (
    <RigpowerBess lens={lens} detailed={false} setLensDate={setLensDate} />
  );
};
