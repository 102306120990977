import ButtonGroup from "antd/lib/button/button-group";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { darkButtonCss } from "commonStyles";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Row, Space } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

export const HeaderContainerRow = styled(Row)`
  padding: 0 24px;
  min-height: 64px;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_chart_bg};
  z-index: ${zIndexLayer.high};
  overflow-x: auto;
`;

const padding = css`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const PaddedSpace = styled(Space)`
  ${padding}
`;

export const RightContainer = styled(Space)`
  justify-content: end;
`;

export const FilterTagsContainer = styled.div`
  max-width: 500px;
  ${padding}
`;

export const Buttons = styled(ButtonGroup)`
  a:first-child {
    .ant-btn {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  a:last-child {
    .ant-btn {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

export const StyledTitle = styled(Title).attrs({
  level: 3,
})`
  white-space: nowrap;
`;

export const StyledGap = styled.div`
  display: flex;
  gap: 5px;
`;

export const HeaderMap = styled.div`
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

export const ResetButton = styled(Button)`
  letter-spacing: -0.2px;
  ${darkButtonCss}
  color: ${colors.white} !important;
  &.ant-btn[disabled] {
    background-color: ${colors.limo_scene} !important;
    color: ${colors.gray} !important;
  }
`;

export const ApplyButton = styled(Button)`
  letter-spacing: -0.2px;
  &.ant-btn:hover,
  &.ant-btn:focus {
    background-color: ${colors.shutter_black} !important;
    color: ${colors.white} !important;
  }

  &.ant-btn[disabled] {
    background-color: ${colors.limo_scene} !important;
    color: ${colors.gray} !important;
  }

  border-color: ${colors.limo_scene} !important;
`;

export const LegendStatus = styled.div`
  width: 264px;
  height: 36px;
  display: flex;
  align-items: center;

  border-right: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;
