import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Button } from "atoms/Form";
import FiltersPopup from "components/General/FiltersPopup";
import SelectFilter from "components/General/SelectFilter";
import { PDComponent } from "components/PDComponents";
import {
  type FilterTypes,
  useNotificationRigFilterCategories,
} from "pages/NotificationSettings/AllFiltersDropdownButton/useNotificationRigFilterCategories";
import { useMemo, useState } from "react";
import type { NotificationRigFilters } from "reducers/notificationsReducer";
import { useAppDispatch } from "reducers/store";
import { Track } from "services/Mixpanel";
import { Popover, Tooltip } from "utils/componentLibrary";

export const AllFiltersDropdownButton = () => {
  const dispatch = useAppDispatch();
  const [filterVisible, setFilterPopupVisible] = useState(false);

  const {
    initialFilters,
    localFilters,
    setLocalFilters,
    allFilters,
    toggleFilter,
  } = useNotificationRigFilterCategories();

  const isAllSelected = useMemo(() => {
    return localFilters
      ? Object.keys(localFilters ?? []).every(
          (key) =>
            localFilters[key as FilterTypes].length ===
            allFilters[key as FilterTypes].options.length,
        )
      : false;
  }, [allFilters, localFilters]);

  const toggleAll = (filterType: FilterTypes) => (e: CheckboxChangeEvent) => {
    setLocalFilters((prev) => {
      const newFilters: NotificationRigFilters = prev
        ? { ...prev }
        : {
            rigType: [],
            horsePower: [],
            country: [],
            operatingCenter: [],
            technology: [],
          };

      if (e.target.checked) {
        newFilters[filterType] = allFilters[filterType].options;
      } else {
        newFilters[filterType] = [];
      }
      return newFilters;
    });
  };

  const deselectAll = () => {
    if (isAllSelected) {
      setLocalFilters({
        rigType: [],
        horsePower: [],
        country: [],
        operatingCenter: [],
        technology: [],
      });
    } else {
      setLocalFilters(initialFilters);
    }
  };

  const onCancel = () => {
    setFilterPopupVisible(false);
  };

  const applyCustomization = () => {
    setFilterPopupVisible(false);

    Track.clickEvent("Notification Settings - Apply Filters");
    dispatch({ type: "NOTIFICATIONS_SET_FILTERS", payload: localFilters });
  };

  return (
    <Tooltip title="Filter">
      <Popover
        content={
          <FiltersPopup
            isAllSelected={isAllSelected}
            trackingProps={{ page: "Notification Settings" }}
            deselectAll={deselectAll}
            onCancel={onCancel}
            applyCustomization={applyCustomization}
            numRows={2}
            numCols={3}
          >
            {(Object.keys(allFilters) as FilterTypes[]).map((filterType) => (
              <SelectFilter
                key={filterType}
                label={allFilters[filterType].categoryLabel}
                filterValues={
                  localFilters
                    ? localFilters[filterType].map(
                        (filterOption) => filterOption.id || "",
                      )
                    : []
                }
                options={allFilters[filterType].options.map((option) => ({
                  label: (option.value || "").toString(),
                  value: option.id || "",
                }))}
                onChangeGroup={(e) => {
                  toggleAll(filterType)(e);
                }}
                onChange={(option) => {
                  toggleFilter(filterType, option as string);
                }}
              />
            ))}
          </FiltersPopup>
        }
        trigger={["click", "scroll"]}
        placement="bottomRight"
        open={filterVisible}
        onOpenChange={(e) => setFilterPopupVisible(e)}
        destroyTooltipOnHide
      >
        <Button
          size="large"
          icon={<PDComponent.SvgIcon name="filter" />}
          onClick={() => setFilterPopupVisible((fl: boolean) => !fl)}
          $engaged={filterVisible}
          type={isAllSelected ? "default" : "primary"}
          ghost={!isAllSelected}
        />
      </Popover>
    </Tooltip>
  );
};
