import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  RigCardFactSetDto,
  RigFleetPerformanceFactQueryDto,
  WellStatusType,
} from "apis/oag";
import { DashboardType, RigFleetPerformanceCardUserLensesApi } from "apis/oag";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedRigsContext } from "pages/FleetPerformance/RigList/SelectedRigsContext";
import { useFleetPerformanceOptions } from "pages/FleetPerformance/utils";
import { useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { type IFiltersType, initialFilters } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { RigTechnology } from "utils/enums";
import { dateToDateOnlyDto } from "utils/helper";
import type { PDRigFleetPerformanceFactQueryDtokey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const rigCardApi = new RigFleetPerformanceCardUserLensesApi(apiConfig);

export function useRigFleetPerformanceCardFacts(
  selectedTabId?: number[],
  options?: Omit<UseQueryOptions<RigCardFactSetDto>, "queryFn" | "queryKey">,
) {
  const allWellsIncluded = useAppSelector(
    (state) => state.rigsCommon.allWellsIncluded,
  );
  const operatorsState = useAppSelector((state) => state.rigsCommon.operators);
  const rigIdsState = useAppSelector((state) => state.rigsCommon.rigIds);
  const selectedWells = useAppSelector(
    (state) => state.rigsCommon.selectedWells,
  );
  const selectedWellsMap = useAppSelector(
    (state) => state.rigsCommon.selectedWellsMap,
  );

  const periodState = useAppSelector((state) => state.rigsCommon.period);
  const filterState = useAppSelector((state) => state.rigsCommon.filters);
  const exceptions = useAppSelector((state) => state.rigsCommon.exceptions);
  const formationIds = useAppSelector((state) => state.rigsCommon.formationIds);

  const { rigOptions } = useFleetPerformanceOptions();
  const { selectedRigIds } = useSelectedRigsContext();
  const [{ operationTime }] = useStateQuery<IFiltersType>(
    URL_STATE_PARAM.FILTERS_WIDGET,
    initialFilters,
  );

  const requestQuery: RigFleetPerformanceFactQueryDto = useMemo(() => {
    return {
      highlightedRigIds: selectedRigIds,
      formationIds,
      selectedFilters: {
        phaseIds: filterState?.phases.map((p) => +(p.id || -1)),
        sectionIds: filterState?.holeSection.map((hs) => +(hs.id || -1)),
        directionalIntervalIds: filterState?.directionalInterval.map(
          (hs) => +(hs.id || -1),
        ),
        holeSizeIds: filterState?.holeSize.map((hs) => +(hs.id || -1)),
        includeFlatTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
        includeAlphaRigs: filterState?.technology
          ? filterState?.technology
              .map((tech) => tech.id)
              .includes(RigTechnology.Alpha)
          : true,
        includeNonAlphaRigs: filterState?.technology
          ? filterState?.technology
              .map((tech) => tech.id)
              .includes(RigTechnology.NonAlpha)
          : true,
        selectedClasses: filterState?.rigType
          ? filterState?.rigType.map((t) => (t?.id || -1).toString())
          : null,
        selectedCountryIds: filterState?.country
          ? filterState?.country.map((c) => +(c?.id || -1))
          : null,
        selectedHorsePowers: filterState?.horsePower
          ? filterState?.horsePower.map((hp) => +(hp?.id || -1))
          : null,
        selectedOperatingCenters: filterState?.operatingCenter
          ? filterState?.operatingCenter.map((oc) => (oc?.id || -1).toString())
          : null,
        selectedWellStatusTypes: filterState?.wellStatus
          ? (filterState?.wellStatus.map((ws) => ws.id) as WellStatusType[])
          : null,
      },
      dashboard: DashboardType.RigFleetPerformance,
      operatorIds: operatorsState,
      rigIds: rigIdsState,
      from: dateToDateOnlyDto(
        periodState?.startDate || defaultDateDto.from.utc,
      ),
      to: dateToDateOnlyDto(periodState?.endDate || defaultDateDto.to.utc),
      includeFullWellFacts: allWellsIncluded,
      pivot: rigOptions.pivot,
      useExceptions: exceptions,
      tabIds: selectedTabId,
      selectedWellIds: selectedWellsMap ?? selectedWells,
    };
  }, [
    selectedRigIds,
    formationIds,
    filterState?.phases,
    filterState?.holeSection,
    filterState?.directionalInterval,
    filterState?.holeSize,
    filterState?.technology,
    filterState?.rigType,
    filterState?.country,
    filterState?.horsePower,
    filterState?.operatingCenter,
    filterState?.wellStatus,
    operationTime,
    operatorsState,
    rigIdsState,
    periodState?.startDate,
    periodState?.endDate,
    allWellsIncluded,
    rigOptions.pivot,
    exceptions,
    selectedTabId,
    selectedWellsMap,
    selectedWells,
  ]);

  const RigsCardQueryKey: PDRigFleetPerformanceFactQueryDtokey = {
    uid: RequestUID.rigsCardFacts,
    type: PDQueryType.FACTS,
    params: requestQuery,
  };

  return useQuery<RigCardFactSetDto>({
    queryKey: [RigsCardQueryKey],
    queryFn: ({ signal }) => {
      return rigCardApi.apiRigFleetPerformanceCardUserLensesAllFactsPut(
        {
          rigFleetPerformanceFactQueryDto: requestQuery,
        },
        { signal },
      );
    },
    ...options,
    enabled: filterState !== null,
  });
}
