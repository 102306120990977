import { FuelType } from "apis/oag";
import {
  StyledDropDown,
  StyledOptionRow,
} from "components/Header/RightContent/style";
import * as Styled from "components/Lenses/ContainerLens/FuelConsumptionByGenerator/common/FuelSelector/styles";
import { useMemo } from "react";

const fuelOptions = [
  {
    value: FuelType.Diesel,
    label: `Diesel Consumption by Gen`,
  },
  {
    value: FuelType.NaturalGas,
    label: `Nat Gas Consumption by Gen`,
  },
  {
    value: FuelType.BiFuel,
    label: `All Fuel Consumption by Gen`,
  },
];

export default function FuelSelector({
  selectedFuel,
  fuelMenuVisible,
  setFuelMenuVisible,
  handleFuelUpdate,
  isLocked,
}: {
  selectedFuel: FuelType;
  fuelMenuVisible: boolean;
  setFuelMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleFuelUpdate: (fuel: FuelType) => void;
  isLocked: boolean;
}) {
  const fuel = useMemo(
    () => fuelOptions.find((fuel) => fuel.value === selectedFuel)?.label,
    [selectedFuel],
  );

  const FuelMenuItems = () => (
    <Styled.Card>
      {fuelOptions.map(({ value, label }) => (
        <StyledOptionRow justify="space-between" align="middle" key={value}>
          <Styled.Option
            onClick={() => {
              setFuelMenuVisible(false);
              handleFuelUpdate(value);
            }}
          >
            {label}
          </Styled.Option>
        </StyledOptionRow>
      ))}
    </Styled.Card>
  );

  return (
    <>
      <Styled.PlaceHolder />
      <StyledDropDown
        overlay={<FuelMenuItems />}
        placement="bottomLeft"
        trigger={["click"]}
        open={isLocked ? false : fuelMenuVisible}
        onOpenChange={() => {
          if (!isLocked) setFuelMenuVisible(!fuelMenuVisible);
        }}
      >
        <Styled.SelectedFuel>
          {fuel}
          {isLocked ? null : <Styled.ChevronDownIcon />}
        </Styled.SelectedFuel>
      </StyledDropDown>
    </>
  );
}
