import styled from "styled-components";

export const QuarterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  .ant-input-affix-wrapper {
    margin-top: 0px !important;
  }
  border-radius: 4px;
  padding-bottom: 8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  background: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
`;
