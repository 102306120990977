import { StyledFilterTag } from "atoms/Form/FilterTag";
import { memo } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  justify-content: end;
`;

export interface FilterTag {
  onRemove?: () => void;
  text: string;
}

export const FilterTags = memo(({ tags }: { tags: FilterTag[] }) => {
  return (
    <StyledWrapper>
      {tags.map((tag, index) => (
        <StyledFilterTag
          key={tag.text + index}
          closable
          onClose={() => {
            tag.onRemove?.();
          }}
        >
          {tag.text}
        </StyledFilterTag>
      ))}
    </StyledWrapper>
  );
});
