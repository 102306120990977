import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { NotificationSubscriptionDto } from "apis/oag";
import { NotificationSubscriptionsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const api = new NotificationSubscriptionsApi(apiConfig);

export const notificationSubscriptionKey: PDUniqueQueryKey = {
  uid: RequestUID.notificationSubscriptions,
};

export const useNotificationSubscriptions = (
  options?: UseSuspenseQueryOptions<NotificationSubscriptionDto[]>,
) => {
  return useSuspenseQuery({
    queryFn: () => api.apiNotificationSubscriptionsGet(),
    queryKey: [notificationSubscriptionKey],
    ...options,
  });
};
