export const UNIT_LABEL = "Hrs";
export const AVG_UNIT_LABEL = "Power Load (100 kW bins)";
export const AVAILABLE_POWER_LEGEND_ID = -1;
export const tickValues = [
  "0",
  "500000",
  "1000000",
  "1500000",
  "2000000",
  "2500000",
  "3000000",
  "3500000",
];
