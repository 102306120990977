import type { ApiPowerLoadDistributionUserLensesIdFactsPutRequest } from "apis/oag/apis/PowerLoadDistributionUserLensesApi";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { usePowerLoadDistributionFacts } from "hooks/facts/usePowerLoadDistributionFacts";
import { useFilterParams } from "hooks/filters/useFilterParams";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import type { IZoomData } from "reducers/types";
import { IZoomType } from "reducers/types";
import { defaultDateDto } from "utils/common";
import { dateToDateDto } from "utils/helper";

interface UsePowerLoadDistributionFetcherParams {
  lensId: number;
}

export function usePowerLoadDistributionFetcher({
  lensId,
}: UsePowerLoadDistributionFetcherParams) {
  const filterParams = useFilterParams();

  const [zoomData] = useStateQuery<IZoomData>(
    URL_STATE_PARAM.ZOOM_WELL,
    initialZoomData,
    [URL_STATE_PARAM.ZOOM_WIDGET],
  );

  const requestParameters: ApiPowerLoadDistributionUserLensesIdFactsPutRequest =
    {
      id: lensId ?? -1,
      rigPowerQueryDto: {
        ...filterParams,
      },
    };
  if (
    zoomData.date_start &&
    zoomData.type === IZoomType.DATE &&
    requestParameters.rigPowerQueryDto
  ) {
    requestParameters.rigPowerQueryDto.from =
      dateToDateDto(zoomData.date_start) || defaultDateDto.from;
    requestParameters.rigPowerQueryDto.to =
      dateToDateDto(zoomData.date_end) || defaultDateDto.to;
  }

  const request = usePowerLoadDistributionFacts(lensId, requestParameters, {
    enabled: !!lensId,
  });

  return { request };
}
