/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LensTemplateType = {
    Undefined: 'Undefined',
    SingleKpi: 'SingleKpi',
    StackedKpis: 'StackedKpis',
    Wedge: 'Wedge',
    ParameterByDepth: 'ParameterByDepth',
    ParameterByDepthRoadmap: 'ParameterByDepthRoadmap',
    PivotKpiType: 'PivotKpiType',
    PivotKpiGroup: 'PivotKpiGroup',
    WellDrillingSummary: 'WellDrillingSummary',
    ParameterHeatmap: 'ParameterHeatmap',
    TorqueAndDrag: 'TorqueAndDrag',
    BatteryState: 'BatteryState',
    RigPower: 'RigPower',
    PowerConsumptionByOperation: 'PowerConsumptionByOperation',
    StatCard: 'StatCard',
    NaturalGasState: 'NaturalGasState',
    GeneratorStatusBar: 'GeneratorStatusBar',
    FuelFlowRate: 'FuelFlowRate',
    GeneratorStats: 'GeneratorStats',
    GeneratorLoadProfile: 'GeneratorLoadProfile',
    PowerLoadDistribution: 'PowerLoadDistribution',
    GhgEmissions: 'GhgEmissions',
    FuelTankVolume: 'FuelTankVolume',
    PowerLoadEfficiency: 'PowerLoadEfficiency',
    FuelConsumptionByGenerator: 'FuelConsumptionByGenerator',
    DieselNatGasRatioStats: 'DieselNatGasRatioStats',
    DrillingProductivity: 'DrillingProductivity',
    RigFleetPerformanceCard: 'RigFleetPerformanceCard',
    RigScorecard: 'RigScorecard',
    ParameterByDepthUnified: 'ParameterByDepthUnified',
    TagBottomFingerprint: 'TagBottomFingerprint',
    StickSlipByTime: 'StickSlipByTime',
    StickSlipByDepth: 'StickSlipByDepth',
    IntelScatterPlot: 'IntelScatterPlot',
    IntelRankingRibbon: 'IntelRankingRibbon',
    IntelScorecard: 'IntelScorecard'
} as const;
export type LensTemplateType = typeof LensTemplateType[keyof typeof LensTemplateType];


export function instanceOfLensTemplateType(value: any): boolean {
    for (const key in LensTemplateType) {
        if (Object.prototype.hasOwnProperty.call(LensTemplateType, key)) {
            if (LensTemplateType[key as keyof typeof LensTemplateType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LensTemplateTypeFromJSON(json: any): LensTemplateType {
    return LensTemplateTypeFromJSONTyped(json, false);
}

export function LensTemplateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LensTemplateType {
    return json as LensTemplateType;
}

export function LensTemplateTypeToJSON(value?: LensTemplateType | null): any {
    return value as any;
}

