import { EllipsisTextCSS } from "commonStyles";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  /* width: 100%; */
  height: 246px;
  padding: 8px 8px 12px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  background: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};

  border-radius: 4px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12);
`;

export const CheckAllContainer = styled.div`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 6px;
  justify-content: center;
  flex-direction: row;
`;

export const GroupTitleLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const OptionLabel = styled.label`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  ${EllipsisTextCSS};
`;

export const OptionRowContainer = styled.div`
  height: 15px;
  user-select: none;
  padding: 0;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 8px;
`;
