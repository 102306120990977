import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type {
  BatteryStateLensTemplateDto,
  DieselNatGasRatioStatsLensTemplateDto,
  DrillingProductivityLensTemplateDto,
  FuelConsumptionByGeneratorLensTemplateDto,
  FuelTankVolumeLensTemplateDto,
  GeneratorLoadProfileLensTemplateDto,
  GeneratorStatsLensTemplateDto,
  GhgEmissionsLensTemplateDto,
  KpiGroupLensTemplateDto,
  KpiTypeLensTemplateDto,
  NaturalGasStateLensTemplateDto,
  ParameterByDepthLensTemplateDto,
  ParameterHeatmapLensTemplateDto,
  PivotKpiGroupLensTemplateDto,
  PivotKpiTypeLensTemplateDto,
  PowerLoadEfficiencyLensTemplateDto,
  RigPowerLensTemplateDto,
  StatCardLensTemplateDto,
  StickSlipLensTemplateDto,
  TagBottomFingerprintLensTemplateDto,
  TorqueAndDragLensTemplateDto,
  WedgeLensTemplateDto,
  WellDrillingSummaryLensTemplateDto,
} from "apis/oag";
import { LensTemplatesApi, LensTemplateType } from "apis/oag";
import type { FuelFlowRateLensTemplateDto } from "apis/oag/models/FuelFlowRateLensTemplateDto";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const api = new LensTemplatesApi(apiConfig);

export type LensTemplate =
  | KpiTypeLensTemplateDto
  | KpiGroupLensTemplateDto
  | DrillingProductivityLensTemplateDto
  | PivotKpiTypeLensTemplateDto
  | PivotKpiGroupLensTemplateDto
  | WedgeLensTemplateDto
  | ParameterByDepthLensTemplateDto
  | StatCardLensTemplateDto
  | WellDrillingSummaryLensTemplateDto
  | RigPowerLensTemplateDto
  | GeneratorStatsLensTemplateDto;

export const LensTemplatesQueryKey: PDUniqueQueryKey = {
  uid: RequestUID.lensTemplates,
};
export function isPivotKpiTypeLensTemplate(
  data: LensTemplate | undefined,
): data is PivotKpiTypeLensTemplateDto {
  return data?.type === LensTemplateType.PivotKpiType;
}
export function isPivotKpiGroupLensTemplate(
  data: LensTemplate | undefined,
): data is PivotKpiGroupLensTemplateDto {
  return data?.type === LensTemplateType.PivotKpiGroup;
}
export function isSingleKpiTemplate(
  data: LensTemplate | undefined,
): data is KpiTypeLensTemplateDto {
  return data?.type === LensTemplateType.SingleKpi;
}
export function isStatCardTemplate(
  data: LensTemplate | undefined,
): data is StatCardLensTemplateDto {
  return data?.type === LensTemplateType.StatCard;
}
export function isMultipleKpiTemplate(
  data: LensTemplate | undefined,
): data is KpiGroupLensTemplateDto {
  return data?.type === LensTemplateType.StackedKpis;
}

export function isWedgeTemplate(
  data: LensTemplate | undefined,
): data is WedgeLensTemplateDto {
  return data?.type === LensTemplateType.Wedge;
}

export function isParameterByDepthTemplate(
  data: LensTemplate | undefined,
): data is ParameterByDepthLensTemplateDto {
  return (
    data?.type === LensTemplateType.ParameterByDepth ||
    data?.type === LensTemplateType.ParameterByDepthUnified
  );
}

export function isParameterRoadmapTemplate(
  data: LensTemplate | undefined,
): data is ParameterByDepthLensTemplateDto {
  return data?.type === LensTemplateType.ParameterByDepthRoadmap;
}

export function isParameterHeatmapTemplate(
  data: LensTemplate | undefined,
): data is ParameterHeatmapLensTemplateDto {
  return data?.type === LensTemplateType.ParameterHeatmap;
}

export function isWellDrillingSummaryTemplate(
  data: LensTemplate | undefined,
): data is WellDrillingSummaryLensTemplateDto {
  return data?.type === LensTemplateType.WellDrillingSummary;
}

export function isTorqueAndDrag(
  data: LensTemplate | undefined,
): data is TorqueAndDragLensTemplateDto {
  return data?.type === LensTemplateType.TorqueAndDrag;
}

export function isRigPowerTemplate(
  data: LensTemplate | undefined,
): data is RigPowerLensTemplateDto {
  return data?.type === LensTemplateType.RigPower;
}

export function isBatteryStateTemplate(
  data: LensTemplate | undefined,
): data is BatteryStateLensTemplateDto {
  return data?.type === LensTemplateType.BatteryState;
}

export function isNaturalGasStateTemplate(
  data: LensTemplate | undefined,
): data is NaturalGasStateLensTemplateDto {
  return data?.type === LensTemplateType.NaturalGasState;
}

export function isGeneratorLoadProfileTemplate(
  data: LensTemplate | undefined,
): data is GeneratorLoadProfileLensTemplateDto {
  return data?.type === LensTemplateType.GeneratorLoadProfile;
}

export function isGeneratorStatTemplate(
  data: LensTemplate | undefined,
): data is GeneratorStatsLensTemplateDto {
  return data?.type === LensTemplateType.GeneratorStats;
}

export function isGHGEmissionsTemplate(
  data: LensTemplate | undefined,
): data is GhgEmissionsLensTemplateDto {
  return data?.type === LensTemplateType.GhgEmissions;
}

export function isFuelTankVolumeTemplate(
  data: LensTemplate | undefined,
): data is FuelTankVolumeLensTemplateDto {
  return data?.type === LensTemplateType.FuelTankVolume;
}

export function isPowerLoadEfficiencyTemplate(
  data: LensTemplate | undefined,
): data is PowerLoadEfficiencyLensTemplateDto {
  return data?.type === LensTemplateType.PowerLoadEfficiency;
}

export function isFuelConsumptionByGenTemplate(
  data: LensTemplate | undefined,
): data is FuelConsumptionByGeneratorLensTemplateDto {
  return data?.type === LensTemplateType.FuelConsumptionByGenerator;
}

export function isFueFlowRateTemplate(
  data: LensTemplate | undefined,
): data is FuelFlowRateLensTemplateDto {
  return data?.type === LensTemplateType.FuelFlowRate;
}

export function isDieselNatGasRatioStatsTemplate(
  data: LensTemplate | undefined,
): data is DieselNatGasRatioStatsLensTemplateDto {
  return data?.type === LensTemplateType.DieselNatGasRatioStats;
}

export function isDrillingProductivityLensTemplate(
  data: LensTemplate | undefined,
): data is DrillingProductivityLensTemplateDto {
  return data?.type === LensTemplateType.DrillingProductivity;
}

export function isTagBottomFingerprintLensTemplate(
  data: LensTemplate | undefined,
): data is TagBottomFingerprintLensTemplateDto {
  return data?.type === LensTemplateType.TagBottomFingerprint;
}
export function isStickSlipByDepthLensTemplate(
  data: LensTemplate | undefined,
): data is StickSlipLensTemplateDto {
  return data?.type === LensTemplateType.StickSlipByDepth;
}
export function isStickSlipByTimeLensTemplate(
  data: LensTemplate | undefined,
): data is StickSlipLensTemplateDto {
  return data?.type === LensTemplateType.StickSlipByTime;
}

// ! lens boilerplate

export async function fetchLensTemplates() {
  const data = await api.apiLensTemplatesGet();
  const allTemplates: LensTemplate[] = [
    ...(data?.kpiTypeLensTemplates || []),
    ...(data?.kpiGroupLensTemplates || []),
    ...(data?.wedgeLensTemplates || []),
    ...(data?.parameterByDepthLensTemplates || []),
    ...(data?.pivotKpiGroupLensTemplates ?? []),
    ...(data?.pivotKpiTypeLensTemplates ?? []),
    ...(data?.wellDrillingSummaryTemplates ?? []),
    ...(data?.parameterHeatmapLensTemplates ?? []),
    ...(data?.torqueAndDragLensTemplates ?? []),
    ...(data?.rigPowerLensTemplates ?? []),
    ...(data?.statCardLensTemplates ?? []),
    ...(data?.batteryStateLensTemplates ?? []),
    ...(data?.powerConsumptionByOperationLensTemplates ?? []),
    ...(data?.generatorStatusBarLensTemplates ?? []),
    ...(data?.powerLoadDistributionLensTemplates ?? []),
    ...(data?.fuelFlowRateLensTemplates ?? []),
    ...(data?.naturalGasStateLensTemplates ?? []),
    ...(data?.generatorLoadProfileLensTemplates ?? []),
    ...(data?.generatorStatsLensTemplates ?? []),
    ...(data?.ghgEmissionsLensTemplates ?? []),
    ...(data?.fuelTankVolumeLensTemplates ?? []),
    ...(data?.powerLoadEfficiencyLensTemplates ?? []),
    ...(data?.fuelConsumptionByGeneratorLensTemplates ?? []),
    ...(data?.dieselNatGasRatioStatsLensTemplates ?? []),
    ...(data?.drillingProductivityLensTemplates ?? []),
    ...(data?.tagBottomFingerprintLensTemplates ?? []),
    ...(data?.stickSlipLensTemplates ?? []),
    // ! lens boilerplate
  ].sort((a, b) => (a.id ?? 0) - (b.id ?? 0));

  return {
    list: allTemplates,
    byId: allTemplates.reduce<Record<number, LensTemplate>>((acc, item) => {
      if (item.id) {
        acc[item.id] = item;
      }
      return acc;
    }, {}),
  };
}
export type LensTemplatesResult = {
  list: LensTemplate[];
  byId: Record<number, LensTemplate>;
};
export function useLensTemplates(
  options?: Omit<
    UseSuspenseQueryOptions<LensTemplatesResult>,
    "queryKey" | "queryFn"
  >,
) {
  return useSuspenseQuery<LensTemplatesResult>({
    queryKey: [LensTemplatesQueryKey],
    queryFn: () => fetchLensTemplates(),
    ...options,
    refetchOnMount: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
