import type { ParameterHeatmapUserLensDto } from "apis/oag";
import { trackUnitTypesByDepth } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/utils/trackUnitTypes";
import {
  BIN_LEGEND_WIDTH,
  BIN_PADDING_X,
  DEFAULT_TRANSLATE,
  LEGEND_PADDING,
  MAX_BIN_COUNT,
} from "components/Lenses/ContainerLens/ParameterHeatmapKpi/HoneyComb/HoneyCombChart";
import type { TracksResponse } from "hooks/drillingInvariants/useTracks";
import { clamp } from "lodash";
import type { IUnitSystem } from "reducers/types";

export const GLOWING_FILTER_ID = "activeHexbinGlow";
export const ACTIVE_HEX_DOM_ID = "x-active";

export function getUnits(
  lens: ParameterHeatmapUserLensDto,
  tracks: TracksResponse | undefined,
  currentUOM: IUnitSystem,
) {
  const xTrackUnits =
    trackUnitTypesByDepth[tracks?.byId[lens.xTrack.trackId]?.name || ""];
  const yTrackUnits =
    trackUnitTypesByDepth[tracks?.byId[lens.yTrack.trackId]?.name || ""];
  const zTrackUnits =
    trackUnitTypesByDepth[tracks?.byId[lens.zTrack.trackId]?.name || ""];

  if (!xTrackUnits || !yTrackUnits || !zTrackUnits) {
    console.error("Couldn't find correct track units for ParameterHeatmap");
  }

  const xConvertedUnit =
    Object.keys(xTrackUnits).find(
      (key) => xTrackUnits[key].unitSystem === currentUOM,
    ) || Object.keys(xTrackUnits)[0];

  const yConvertedUnit =
    Object.keys(yTrackUnits).find((key) => {
      return yTrackUnits[key].unitSystem === currentUOM;
    }) || Object.keys(yTrackUnits)[0];

  const zConvertedUnit =
    Object.keys(zTrackUnits).find(
      (key) => zTrackUnits[key].unitSystem === currentUOM,
    ) || Object.keys(zTrackUnits)[0];

  return {
    xUnits: xTrackUnits[xConvertedUnit],
    yUnits: yTrackUnits[yConvertedUnit],
    zUnits: zTrackUnits[zConvertedUnit],
  };
}

/*  
    This is used both for request params purposes and to calculate display. The tight coupling is intentional,
    We need to request from the server approximately amount of bins we can fit into the display.
    In one instance we use the lens metadata size, in the other we use the react measurement hook
*/

export const getHeatmapDimensionsForParent = ({
  parentContainerHeight,
  parentContainerWidth,
}: {
  parentContainerHeight: number;
  parentContainerWidth: number;
}) => {
  const defaultBinRadius = 18;
  const binWidth = Math.sqrt(3) * defaultBinRadius;
  const withPaddingAndLegend =
    parentContainerWidth - BIN_LEGEND_WIDTH - 2 * BIN_PADDING_X;

  const width = {
    widthPadding: clamp(parentContainerWidth - 2 * BIN_PADDING_X, 0, +Infinity),
    withPaddingAndLegend: clamp(withPaddingAndLegend, 0, +Infinity),
  };
  const height = {
    widthPadding: clamp(parentContainerHeight, 0, +Infinity),
    withPaddingAndLegend: clamp(
      parentContainerHeight - LEGEND_PADDING,
      0,
      +Infinity,
    ),
  };

  const [xCount, yCount] = [
    Math.floor(withPaddingAndLegend / binWidth),
    /*
      Derived from how many hexagons arranged in a grid can fit inside a height container in terms of radius
      Below, 2r is the height of one single hex bin with a radius of r
      gridHeight(hexCount) = 2r + (2r - 1/2r)*(hexCount - 1), since subsequent bins height take only 2r - 1/2r, or 3/4 radius
      The other way around, extracting hexCount for a given height, yields the formula below
      See derivations here: https://www.wolframalpha.com/input?i=%28h-2*r%29+%2F+%283%2F2*r%29+%3D+c-1 
    */
    Math.floor(
      (height.widthPadding - 2 * defaultBinRadius) /
        ((3 / 2) * defaultBinRadius),
    ) + 1,
  ];

  return {
    width,
    height,
    defaultTranslate: DEFAULT_TRANSLATE,
    xBinCount: clamp(xCount, 1, MAX_BIN_COUNT),
    yBinCount: clamp(yCount, 1, MAX_BIN_COUNT),
  };
};

export const GLOWING_FILTER_HTML = `
<defs>
  <filter id="${GLOWING_FILTER_ID}" width="200%" x="-25%" height="200%" y="-25%">
    <feFlood result="flood" flood-color="green" flood-opacity="0.5"></feFlood>
    <feComposite in="flood" result="mask" in2="SourceGraphic" operator="in"></feComposite>
    <feMorphology in="mask" result="dilated" operator="dilate" radius="4"></feMorphology>
    <feGaussianBlur in="dilated" result="blurred" stdDeviation="4"></feGaussianBlur>
    <feMerge>
        <feMergeNode in="blurred"></feMergeNode>
        <feMergeNode in="SourceGraphic"></feMergeNode>
    </feMerge>
  </filter>
</defs>
`;
