/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntelScorecardProductivityDetailsDto
 */
export interface IntelScorecardProductivityDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof IntelScorecardProductivityDetailsDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof IntelScorecardProductivityDetailsDto
     */
    productivityDeviation: number;
    /**
     * 
     * @type {number}
     * @memberof IntelScorecardProductivityDetailsDto
     */
    rank: number;
}

/**
 * Check if a given object implements the IntelScorecardProductivityDetailsDto interface.
 */
export function instanceOfIntelScorecardProductivityDetailsDto(value: object): value is IntelScorecardProductivityDetailsDto {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('productivityDeviation' in value) || value['productivityDeviation'] === undefined) return false;
    if (!('rank' in value) || value['rank'] === undefined) return false;
    return true;
}

export function IntelScorecardProductivityDetailsDtoFromJSON(json: any): IntelScorecardProductivityDetailsDto {
    return IntelScorecardProductivityDetailsDtoFromJSONTyped(json, false);
}

export function IntelScorecardProductivityDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelScorecardProductivityDetailsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['Name'],
        'productivityDeviation': json['ProductivityDeviation'],
        'rank': json['Rank'],
    };
}

export function IntelScorecardProductivityDetailsDtoToJSON(value?: IntelScorecardProductivityDetailsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Name': value['name'],
        'ProductivityDeviation': value['productivityDeviation'],
        'Rank': value['rank'],
    };
}

