import { Text } from "atoms/Typography";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { LegendPreviewerType } from "components/Lenses/common/ChartLegend/interfaces";
import {
  LegendDot,
  LegendDotContainerSvg,
  StyledLegendColumn,
  StyledLegendContainer,
  StyledLegendItem,
  StyledLegendRow,
  StyledTableContainer,
  StyledTableHeader,
} from "components/Lenses/common/ChartLegend/StyledComponentsLegend";
import {
  LEGEND_DISABLED_OPACITY,
  LEGEND_EMPTY_COLUMN_KEY,
} from "components/Lenses/common/ChartLegend/utils";
import type { Alignments } from "utils/enums";

interface DetailedLegendProps {
  legendItems: LegendItem[];
  detailedColumns?: DetailedLegendColumn[];
  customStyle?: React.CSSProperties;
  clickable?: boolean;
}

export interface DetailedLegendColumn {
  title: string;
  key: string;
  widthPercent: number;
  align?: Alignments;
  minWidth?: number;
}

const DashLegendPreviewer = ({ color }: { color: string }) => {
  return (
    <LegendDotContainerSvg>
      <g fill="none" stroke={color} strokeWidth="2">
        <path strokeDasharray="1,1" d="M0 0 l215 0" />
      </g>
    </LegendDotContainerSvg>
  );
};

export const DetailedLegend: React.FC<DetailedLegendProps> = ({
  legendItems,
  detailedColumns,
  customStyle,
  clickable = true,
}) => {
  return (
    <StyledLegendContainer isDetailed={true}>
      <StyledTableContainer style={customStyle} $clickable={clickable}>
        <StyledLegendRow hideBorder={true}>
          {detailedColumns?.map((col) => {
            return (
              <StyledTableHeader
                flexWidth={col.widthPercent}
                key={col.key}
                align={col.align}
                minWidth={col.minWidth || 0}
              >
                <Text primary="description" variant="faded" fontSize={14}>
                  <b>{col.title}</b>
                </Text>
              </StyledTableHeader>
            );
          })}
        </StyledLegendRow>

        {legendItems.map((legendItem, idx) => {
          const { isEnabled, name } = legendItem;

          const opacity = isEnabled ? 1 : LEGEND_DISABLED_OPACITY;

          const isLast = legendItems.length - 1 === idx;

          return (
            <StyledLegendRow
              onClick={() =>
                legendItem.onClick &&
                legendItem.onClick(legendItem, legendItems)
              }
              hideBorder={isLast}
              key={legendItem.id}
            >
              {detailedColumns?.map((col) => {
                return (
                  <StyledLegendColumn
                    flexWidth={col.widthPercent}
                    align={col.align}
                    minWidth={col.minWidth || 0}
                    key={legendItem.id + col.key}
                    isDescriptionColumn={col.key === LEGEND_EMPTY_COLUMN_KEY}
                  >
                    {col.key === LEGEND_EMPTY_COLUMN_KEY ? (
                      <StyledLegendItem>
                        {legendItem.previewerType ===
                        LegendPreviewerType.LINE_DASHED ? (
                          <DashLegendPreviewer color={legendItem.color} />
                        ) : (
                          <LegendDot
                            opacity={opacity}
                            background={legendItem.color}
                            isLine={
                              legendItem.previewerType ===
                              LegendPreviewerType.LINE
                            }
                          />
                        )}
                        <Text
                          primary="description"
                          variant="faded"
                          fontSize={14}
                          style={{ opacity, userSelect: "none" }}
                        >
                          {name}
                        </Text>
                      </StyledLegendItem>
                    ) : (
                      <Text
                        primary="description"
                        variant="faded"
                        fontSize={14}
                        style={{ opacity, userSelect: "none" }}
                      >
                        {legendItem?.columnValues?.[col.key]}
                      </Text>
                    )}
                  </StyledLegendColumn>
                );
              })}
            </StyledLegendRow>
          );
        })}
      </StyledTableContainer>
    </StyledLegendContainer>
  );
};
