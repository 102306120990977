import type { UseQueryOptions } from "@tanstack/react-query";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import type {
  ApiFuelConsumptionByGeneratorUserLensesIdFactsPutRequest,
  FuelConsumptionByGeneratorResultDto,
  FuelConsumptionByGeneratorUserLensDto,
  FuelType,
  GeneratorSlotType,
  UserLensDto,
} from "apis/oag";
import { FuelConsumptionByGeneratorUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { useActiveGenerators } from "hooks/evergreen/useActiveGenerators";
import { useFilterParams } from "hooks/filters/useFilterParams";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useCallback } from "react";
import { useAppDispatch } from "reducers/store";
import type { IZoomData } from "reducers/types";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import { getRequestParametersWithZoom } from "utils/helper";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const fuelConsumptionUserLensesApi =
  new FuelConsumptionByGeneratorUserLensesApi(apiConfig);

export function useFuelConsumptionByGeneratorFacts(
  lens: FuelConsumptionByGeneratorUserLensDto,
  options?: UseQueryOptions<FuelConsumptionByGeneratorResultDto>,
) {
  const filterParams = useFilterParams();
  const crtWellId = useSelectedWell();
  const dispatch = useAppDispatch();

  const { data: activeGenerators } = useActiveGenerators(crtWellId);
  const [zoomData] = useStateQuery<IZoomData>(
    URL_STATE_PARAM.ZOOM_WELL,
    initialZoomData,
    [URL_STATE_PARAM.ZOOM_WIDGET],
  );

  const requestParameters: ApiFuelConsumptionByGeneratorUserLensesIdFactsPutRequest =
    {
      id: lens.id,
      baseFuelQueryDto: {
        ...filterParams,
        selectedGenerators: lens.selectedGenerators
          ? lens.selectedGenerators
          : activeGenerators,
      },
    };

  const requestParametersWithZoom: ApiFuelConsumptionByGeneratorUserLensesIdFactsPutRequest =
    getRequestParametersWithZoom(requestParameters, zoomData);

  const queryKey: PDLensFactsQueryKey<ApiFuelConsumptionByGeneratorUserLensesIdFactsPutRequest> =
    {
      type: PDQueryType.FACTS,
      uid: RequestUID.fuelConsumptionFacts,
      params: requestParametersWithZoom,
      lensId: lens.id,
    };

  const request = useSuspenseQuery<FuelConsumptionByGeneratorResultDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      fuelConsumptionUserLensesApi
        .apiFuelConsumptionByGeneratorUserLensesIdFactsPut(
          requestParametersWithZoom,
          {
            signal,
          },
        )
        .then((data) => {
          if (data?.lastUpdatedAt) {
            dispatch({
              type: "SET_FUEL_MANAGEMENT_REFRESH_DATE",
              payload: {
                [crtWellId]: data?.lastUpdatedAt,
              },
            });
          }
          return data;
        }),
    ...options,
  });

  return request;
}

export function useFuelConsumptionByGeneratorLensUpdate(
  lens: FuelConsumptionByGeneratorUserLensDto,
  onLensUpdated?: (newItem: UserLensDto) => void,
) {
  const handleLensUpdate = useMutation({
    mutationFn: (lens: FuelConsumptionByGeneratorUserLensDto) => {
      return fuelConsumptionUserLensesApi.apiFuelConsumptionByGeneratorUserLensesIdPut(
        {
          id: lens.id,
          fuelConsumptionByGeneratorUserLensDto: lens,
        },
      );
    },
    onSettled: (lens) => {
      if (lens && onLensUpdated) {
        onLensUpdated(lens);
      }
    },
  });

  const handleGeneratorUpdate = useCallback(
    async (generators: GeneratorSlotType[]) => {
      await handleLensUpdate.mutateAsync({
        ...lens,
        selectedGenerators: generators,
      });
    },
    [handleLensUpdate, lens],
  );

  const handleFuelUpdate = useCallback(
    async (fuel: FuelType) => {
      Track.interact("Evergreen Dashboard - Update Fuel", {
        lens: lens.id,
        selectedFuel: fuel,
      });
      await handleLensUpdate.mutateAsync({ ...lens, selectedFuel: fuel });
    },
    [handleLensUpdate, lens],
  );

  return { handleGeneratorUpdate, handleFuelUpdate };
}
