import type { DateDto, WellDto } from "apis/oag";
import type { IAction } from "reducers/types";

type TDateState = {
  lastBatteryStateRefreshDate: null | { [id: number]: DateDto };
  lastByDepthRefreshDate: null | { [id: number]: DateDto };
  lastFuelManagementRefreshDate: null | { [id: number]: DateDto };
  lastPerStandRefreshDate: null | { [id: number]: DateDto };
  lastPowerManagementRefreshDate: null | { [id: number]: DateDto };
  lastRigStateRefreshDate: null | { [id: number]: DateDto };
  lastTvdRefreshDate: null | { [id: number]: DateDto };
  lastWellRefreshDate: null | { [id: number]: DateDto };
  wellDetails: null | { [id: number]: WellDto };
};
// TODO: Maybe move those from here? as they
const initialState: TDateState = {
  lastBatteryStateRefreshDate: null, // battery state
  lastByDepthRefreshDate: null, // parameter by depth + road-map ✅
  lastFuelManagementRefreshDate: null, // fuel management
  lastPerStandRefreshDate: null, // group stacked and per stand ✅
  lastPowerManagementRefreshDate: null, // power management
  lastRigStateRefreshDate: null, // wedge ✅
  lastTvdRefreshDate: null, // TvD ✅
  lastWellRefreshDate: null, // well refresh ✅
  wellDetails: null, // well details ✅
};

interface ISetWellRefreshDate extends IAction {
  type: "SET_WELL_REFRESH_DATE";
  force?: boolean;
  payload: TDateState["lastWellRefreshDate"];
}
interface ISetWellDetails extends IAction {
  type: "SET_WELL_DETAILS_OFFSET";
  force?: boolean;
  payload: TDateState["wellDetails"];
}

interface IResetWellRefreshDate extends IAction {
  type: "RESET_WELL_REFRESH_DATE";
  ids?: number[];
}
interface ISetTvdRefreshDate extends IAction {
  type: "SET_TVD_REFRESH_DATE";
  payload: TDateState["lastTvdRefreshDate"];
}
interface ISetPerStandRefreshDate extends IAction {
  type: "SET_PER_STAND_REFRESH_DATE";
  payload: TDateState["lastPerStandRefreshDate"];
}
interface ISetRigStateRefreshDate extends IAction {
  type: "SET_RIG_STATE_REFRESH_DATE";
  payload: TDateState["lastRigStateRefreshDate"];
}
interface ISetByDepthRefreshDate extends IAction {
  type: "SET_BY_DEPTH_REFRESH_DATE";
  payload: TDateState["lastByDepthRefreshDate"];
}

interface ISetBatteryStateRefreshDate extends IAction {
  type: "SET_BATTERY_STATE_REFRESH_DATE";
  payload: TDateState["lastBatteryStateRefreshDate"];
}

interface ISetFuelManagementRefreshDate extends IAction {
  type: "SET_FUEL_MANAGEMENT_REFRESH_DATE";
  payload: TDateState["lastFuelManagementRefreshDate"];
}

interface ISetPowerManagementRefreshDate extends IAction {
  type: "SET_POWER_MANAGEMENT_REFRESH_DATE";
  payload: TDateState["lastPowerManagementRefreshDate"];
}

type AvailableActions =
  | IResetWellRefreshDate
  | ISetByDepthRefreshDate
  | ISetPerStandRefreshDate
  | ISetRigStateRefreshDate
  | ISetTvdRefreshDate
  | ISetWellRefreshDate
  | ISetWellDetails
  | ISetBatteryStateRefreshDate
  | ISetFuelManagementRefreshDate
  | ISetPowerManagementRefreshDate;

function dataStateReducer(
  state: TDateState = initialState,
  action: AvailableActions,
): TDateState {
  switch (action.type) {
    case "SET_WELL_REFRESH_DATE":
      if (action.force)
        return {
          ...state,
          lastWellRefreshDate: action.payload,
        };

      return {
        ...state,
        lastWellRefreshDate: {
          ...(state.lastWellRefreshDate ?? {}),
          ...action.payload,
        },
      };
    case "SET_WELL_DETAILS_OFFSET":
      return {
        ...state,
        wellDetails: { ...(state.wellDetails ?? {}), ...action.payload },
      };
    case "RESET_WELL_REFRESH_DATE":
      if (action.ids === undefined) {
        return { ...state, lastWellRefreshDate: null };
      } else {
        const newLastWellRefreshDate = { ...state.lastWellRefreshDate };
        action.ids.forEach((id) => {
          delete newLastWellRefreshDate[id];
        });
        return {
          ...state,
          lastWellRefreshDate: { ...newLastWellRefreshDate },
        };
      }

    case "SET_TVD_REFRESH_DATE":
      return {
        ...state,
        lastTvdRefreshDate: {
          ...(state.lastTvdRefreshDate ?? {}),
          ...action.payload,
        },
      };
    case "SET_PER_STAND_REFRESH_DATE":
      return {
        ...state,
        lastPerStandRefreshDate: {
          ...(state.lastPerStandRefreshDate ?? {}),
          ...action.payload,
        },
      };
    case "SET_RIG_STATE_REFRESH_DATE":
      return {
        ...state,
        lastRigStateRefreshDate: {
          ...(state.lastRigStateRefreshDate ?? {}),
          ...action.payload,
        },
      };
    case "SET_BY_DEPTH_REFRESH_DATE":
      return {
        ...state,
        lastByDepthRefreshDate: {
          ...(state.lastByDepthRefreshDate ?? {}),
          ...action.payload,
        },
      };
    case "SET_BATTERY_STATE_REFRESH_DATE":
      return {
        ...state,
        lastBatteryStateRefreshDate: {
          ...(state.lastBatteryStateRefreshDate ?? {}),
          ...action.payload,
        },
      };
    case "SET_FUEL_MANAGEMENT_REFRESH_DATE":
      return {
        ...state,
        lastFuelManagementRefreshDate: {
          ...(state.lastFuelManagementRefreshDate ?? {}),
          ...action.payload,
        },
      };
    case "SET_POWER_MANAGEMENT_REFRESH_DATE":
      return {
        ...state,
        lastPowerManagementRefreshDate: {
          ...(state.lastPowerManagementRefreshDate ?? {}),
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export default dataStateReducer;
