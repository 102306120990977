/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IntelWellInfoDto } from './IntelWellInfoDto';
import {
    IntelWellInfoDtoFromJSON,
    IntelWellInfoDtoFromJSONTyped,
    IntelWellInfoDtoToJSON,
} from './IntelWellInfoDto';

/**
 * 
 * @export
 * @interface IntelWellInfoSetDto
 */
export interface IntelWellInfoSetDto {
    /**
     * 
     * @type {Array<IntelWellInfoDto>}
     * @memberof IntelWellInfoSetDto
     */
    wells: Array<IntelWellInfoDto>;
}

/**
 * Check if a given object implements the IntelWellInfoSetDto interface.
 */
export function instanceOfIntelWellInfoSetDto(value: object): value is IntelWellInfoSetDto {
    if (!('wells' in value) || value['wells'] === undefined) return false;
    return true;
}

export function IntelWellInfoSetDtoFromJSON(json: any): IntelWellInfoSetDto {
    return IntelWellInfoSetDtoFromJSONTyped(json, false);
}

export function IntelWellInfoSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelWellInfoSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wells': ((json['Wells'] as Array<any>).map(IntelWellInfoDtoFromJSON)),
    };
}

export function IntelWellInfoSetDtoToJSON(value?: IntelWellInfoSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Wells': ((value['wells'] as Array<any>).map(IntelWellInfoDtoToJSON)),
    };
}

