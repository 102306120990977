import { VariableSizeList as List } from "react-window";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Row, Space, Switch } from "utils/componentLibrary";
import { Button as AntButton } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

export const StyledBottomRow = styled(Row).attrs({
  justify: "space-between",
  align: "middle",
})`
  padding: 12px;
  background: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};
`;

export const StyledSpace = styled(Space).attrs({ direction: "vertical" })`
  padding-top: 8px;
`;

export const StyledSwitch = styled(Switch)`
  border-radius: 3px;
  .ant-switch-handle::before {
    border-radius: 3px;
  }
`;

export const StyledButton = styled(AntButton)`
  background-color: transparent;
  padding: 0;
  border: none;
  box-shadow: none !important;
  width: 100%;
  text-align: left;
  color: ${colors.well_color};
  :hover,
  :focus,
  :focus-within {
    background-color: transparent;
  }
  :after {
    display: none;
  }
`;

export const StyledOptionsContainer = styled.div<{ $height: number }>`
  width: 100%;
  min-height: ${({ $height }) => $height}px;
`;

export const StyledSpaceForLayout = styled(Space)<{ width?: number }>`
  border-radius: 4px;
  overflow: hidden;
  background: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  width: ${(props) => props.width || 350}px;

  & > * {
    width: ${(props) => props.width || 350}px;
  }
`;

export const ListItemContainer = styled.div<{ $marginLeft?: number }>`
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.soft_accent};
  margin-left: ${({ $marginLeft }) => $marginLeft}px;
  ${({ theme: { isDark } }) =>
    isDark &&
    css`
      border-color: ${colors.soft_white};
    `}

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    .ant-checkbox {
      top: 0;
    }
    .ant-typography {
      line-height: 1;
    }
  }
`;

export const SelectAllContainer = styled(ListItemContainer)`
  background: ${colors.actions_bg};
  ${({ theme: { isDark } }) =>
    isDark &&
    css`
      color: ${colors.white};
      background: ${colors.shutter_black};
    `}
`;

export const LastSelectionContainer = styled.div`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const GroupSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
`;

export const GroupLabel = styled.label`
  white-space: nowrap;
`;

export const StyledList = styled(List)`
  outline: none;
  -ms-overflow-style: none;
  overflow-y: scroll;
  overflow-x: none;
` as unknown as typeof List;
// `styled` limitation: this `as unknown as X` trick to preserve generic type <T> when calling StyledList<T>. Mayber later versions of styled component will handle generics better

export const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledGroupedList = styled.div<{
  $width: number;
  $height: string;
}>`
  -ms-overflow-style: none;
  overflow-y: scroll;
  overflow-x: none;
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width}px;
`;

export const StyledSpaceForLayoutSimpleSearch = styled(Space)<{
  width?: number;
}>`
  border-radius: 4px;
  overflow: hidden;
  background: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  width: ${(props) => props.width || 350}px;
  z-index: ${zIndexLayer.mars};
  & > * {
    width: ${(props) => props.width || 350}px;
  }
`;

export const StyledListSimpleSearch = styled(List)`
  outline: none;
  overflow-y: scroll;
`;

export const ListItemContainerSimpleSearch = styled.div`
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.soft_accent};
  cursor: pointer;
  ${({ theme: { isDark } }) =>
    isDark &&
    css`
      border-color: ${colors.soft_white};
    `}

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    .ant-checkbox {
      top: 0;
    }
    .ant-typography {
      line-height: 1;
    }
  }
`;
