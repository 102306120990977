export const CARD_PADDING = 16;
export const CHART_HORIZONTAL_PADDING = 32;
export const CHART_VERTICAL_PADDING = 16;
export const HEADER_HEIGHT = 21;
export const SELECTOR_HEIGHT = 50;
export const BOTTOM_AXIS_HEIGHT = 50;
export const BOTTOM_AXIS_PADDING = 60;
export const QUARTILE_TOP_MARGIN = 0;
export const QUARTILE_VERTICAL_OFFSET = 6;
export const QUARTILE_HORIZONTAL_OFFSET = 4;
