import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { HoleSectionDto } from "apis/oag";
import { HoleSectionsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const holeSections = new HoleSectionsApi(apiConfig);

const HoleSectionsQueryKey: PDUniqueQueryKey = {
  uid: RequestUID.holeSectionNames,
};

export function fetchHoleSections() {
  return holeSections.apiHoleSectionsGet();
}

export function useHoleSections(
  options?: Omit<
    UseSuspenseQueryOptions<HoleSectionDto[]>,
    "queryKey" | "queryFn"
  >,
) {
  return useSuspenseQuery<HoleSectionDto[]>({
    queryKey: [HoleSectionsQueryKey],
    queryFn: () => fetchHoleSections(),
    ...options,
    refetchOnMount: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
