import { scaleLinear } from "@visx/scale";
import { extent } from "d3-array";

export const parameterByDepthMockTrack = {
  trackValues: [
    {
      value: 3894.16666667,
      holeDepth: 614.77,
    },
    {
      value: 4984.75,
      holeDepth: 637.17,
    },
    {
      value: 5229,
      holeDepth: 659.59,
    },
    {
      value: 5459.75,
      holeDepth: 681.97,
    },
    {
      value: 4196.83333333,
      holeDepth: 704.38,
    },
    {
      value: 5534,
      holeDepth: 726.76,
    },
    {
      value: 5470,
      holeDepth: 749.15,
    },
    {
      value: 5563.5,
      holeDepth: 771.56,
    },
    {
      value: 4928.25,
      holeDepth: 793.97,
    },
    {
      value: 6483.8,
      holeDepth: 816.38,
    },
    {
      value: 5562,
      holeDepth: 838.79,
    },
    {
      value: 5294.75,
      holeDepth: 861.17,
    },
    {
      value: 5131.25,
      holeDepth: 883.54,
    },
    {
      value: 4485,
      holeDepth: 905.97,
    },
    {
      value: 5506.25,
      holeDepth: 928.36,
    },
    {
      value: 5223.2,
      holeDepth: 950.79,
    },
    {
      value: 5165,
      holeDepth: 973.15,
    },
    {
      value: 5745.2,
      holeDepth: 995.57,
    },
    {
      value: 5431.25,
      holeDepth: 1017.98,
    },
    {
      value: 5294.5,
      holeDepth: 1040.36,
    },
    {
      value: 5607.8,
      holeDepth: 1062.79,
    },
    {
      value: 5303.66666667,
      holeDepth: 1085.16,
    },
    {
      value: 5564,
      holeDepth: 1107.59,
    },
    {
      value: 5805.8,
      holeDepth: 1129.97,
    },
    {
      value: 5936.75,
      holeDepth: 1152.35,
    },
    {
      value: 5073.6,
      holeDepth: 1174.78,
    },
    {
      value: 4639.66666667,
      holeDepth: 1197.18,
    },
    {
      value: 5554.75,
      holeDepth: 1219.57,
    },
    {
      value: 4748.16666667,
      holeDepth: 1241.99,
    },
    {
      value: 4937.66666667,
      holeDepth: 1263.59,
    },
    {
      value: 4059.93333333,
      holeDepth: 1285.99,
    },
    {
      value: 4146.29411765,
      holeDepth: 1308.39,
    },
    {
      value: 4690.93333333,
      holeDepth: 1330.79,
    },
  ],
};

const [minHoleDepth = 0, maxHoleDepth = 0] =
  extent(parameterByDepthMockTrack.trackValues, (i) => i.holeDepth) || [];
const [min = 0, max = 0] =
  extent(parameterByDepthMockTrack.trackValues, (i) => i.value) || [];

export const parameterByDepthMockDepthScale = scaleLinear<number>({
  range: [0, 326],
  domain: [minHoleDepth, maxHoleDepth],
});

export const parameterByDepthMockValueScale = scaleLinear<number>({
  range: [106, 0],
  domain: [min, max],
});
