import { HeaderButtonContainer } from "components/Clara/style";
import { PDComponent } from "components/PDComponents";

export const Header = ({
  onClose,
  onMinimize,
}: {
  onClose: () => void;
  onMinimize: () => void;
}) => {
  return (
    <div id="header">
      <h4>Clara</h4>
      <div
        style={{
          display: "flex",
          gap: "15px",
          flexDirection: "row",
        }}
      >
        <HeaderButtonContainer onClick={onMinimize}>
          <PDComponent.SvgIcon name="minimize" />
        </HeaderButtonContainer>
        <HeaderButtonContainer onClick={onClose}>
          <PDComponent.SvgIcon name="closeClara" />
        </HeaderButtonContainer>
      </div>
    </div>
  );
};
