import {
  ChevronDownIcon,
  SelectedOption,
} from "components/PDComponents/Dropdown/style";
import styled from "styled-components";

export const GeneratorStatsContainer = styled.div<{ $withSpace: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${(props) =>
    props.$withSpace ? "space-between" : undefined};
  position: relative;

  ${SelectedOption} {
    position: absolute;
    top: 30px;
    right: 14px;
    transition: all 0.2s ease-in-out;
  }

  :hover {
    ${ChevronDownIcon} {
      opacity: 1;
    }
  }
`;
export const SummaryContainer = styled.div`
  position: relative;
  margin-left: 24px;
  margin-top: 24px;
  flex-grow: 1;
`;

export const GeneratorImage = styled.div`
  margin-left: 24px;
  margin-top: 24px;
  flex-grow: 5;
  display: flex;
  align-items: flex-end;
  > img {
    max-height: 75px;
    max-width: calc(100% - 24px);
  }
`;

export const LegendContainer = styled.div`
  margin-top: 24px;
`;

export const Row = styled.div`
  display: flex;
  gap: 24px;
  & + & {
    margin-top: 16px;
  }
`;
