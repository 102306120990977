/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { BatteryStateFactSummaryDto } from './BatteryStateFactSummaryDto';
import {
    BatteryStateFactSummaryDtoFromJSON,
    BatteryStateFactSummaryDtoFromJSONTyped,
    BatteryStateFactSummaryDtoToJSON,
} from './BatteryStateFactSummaryDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { BatteryStateFactDto } from './BatteryStateFactDto';
import {
    BatteryStateFactDtoFromJSON,
    BatteryStateFactDtoFromJSONTyped,
    BatteryStateFactDtoToJSON,
} from './BatteryStateFactDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface BatteryStateResultDto
 */
export interface BatteryStateResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof BatteryStateResultDto
     */
    readonly stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof BatteryStateResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof BatteryStateResultDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof BatteryStateResultDto
     */
    lastUpdatedAt?: DateDto | null;
    /**
     * 
     * @type {Array<BatteryStateFactDto>}
     * @memberof BatteryStateResultDto
     */
    facts?: Array<BatteryStateFactDto> | null;
    /**
     * 
     * @type {BatteryStateFactSummaryDto}
     * @memberof BatteryStateResultDto
     */
    summary?: BatteryStateFactSummaryDto | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof BatteryStateResultDto
     */
    readonly dataState: ResultDataState;
}



/**
 * Check if a given object implements the BatteryStateResultDto interface.
 */
export function instanceOfBatteryStateResultDto(value: object): value is BatteryStateResultDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function BatteryStateResultDtoFromJSON(json: any): BatteryStateResultDto {
    return BatteryStateResultDtoFromJSONTyped(json, false);
}

export function BatteryStateResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatteryStateResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': json['LastUpdatedAt'] == null ? undefined : DateDtoFromJSON(json['LastUpdatedAt']),
        'facts': json['Facts'] == null ? undefined : ((json['Facts'] as Array<any>).map(BatteryStateFactDtoFromJSON)),
        'summary': json['Summary'] == null ? undefined : BatteryStateFactSummaryDtoFromJSON(json['Summary']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function BatteryStateResultDtoToJSON(value?: Omit<BatteryStateResultDto, 'StopWatch'|'DataState'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'Facts': value['facts'] == null ? undefined : ((value['facts'] as Array<any>).map(BatteryStateFactDtoToJSON)),
        'Summary': BatteryStateFactSummaryDtoToJSON(value['summary']),
    };
}

