import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type {
  ApiIntelScorecardUserLensesIdFactsPutRequest,
  IntelScorecardFactSetDto,
  IntelScorecardUserLensDto,
} from "apis/oag";
import { IntelScorecardUserLensesApi } from "apis/oag";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new IntelScorecardUserLensesApi(apiConfig);

export const useIntelScorecardFacts = (
  lens: IntelScorecardUserLensDto,
  requestParameters: ApiIntelScorecardUserLensesIdFactsPutRequest,
  options?: Omit<
    UseQueryOptions<IntelScorecardFactSetDto>,
    "queryKey" | "queryFn"
  >,
) => {
  const enabled = useAppSelector(
    (state) => state.intelDashboard.hasAutoSelectedQuarterYearList,
  );

  const queryKey: PDLensFactsQueryKey<ApiIntelScorecardUserLensesIdFactsPutRequest> =
    {
      type: PDQueryType.FACTS,
      uid: RequestUID.intelScorecard,
      params: requestParameters,
      lensId: requestParameters.id,
    };

  return useSuspenseQuery<IntelScorecardFactSetDto>({
    queryKey: [lens, queryKey],
    queryFn: ({ signal }) =>
      enabled
        ? api.apiIntelScorecardUserLensesIdFactsPut(requestParameters, {
            signal,
          })
        : new Promise(() => {}),
    ...options,
  });
};
