import { DisplayOption, ResultDataState } from "apis/oag";
import {
  PrimaryKpiSummary,
  SecondaryKpiSummary,
} from "components/Lenses/common/KpiSummaries";
import { LENS_KPI_HEIGHT_INNER } from "components/Lenses/constants";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import { StyledLensContainerFlex } from "components/Lenses/ContainerLens/common/StyledComponents";
import { StyledChartContainerFlexDiv } from "components/Lenses/ContainerLens/common/StyledComponents";
import { useDetailedLegendActiveState } from "components/Lenses/ContainerLens/hooks/useDetailedLegendActiveState";
import { SingleComparisonCategoryChart } from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleComparisonCategoryChart";
import { SingleComparisonWellChart } from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleComparisonWellChart";
import { SingleComparisonWellShiftChart } from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleComparisonWellShiftChart";
import { useComparisonKpiFetcher } from "components/Lenses/ContainerLens/SingleKpi/fetcher";
import type { SingleKpiProps } from "components/Lenses/interfaces";
import { LoadingChart } from "components/Lenses/LoadingChart";
import { InfoContainer } from "components/MiniLens/InfoContainer";
import { getLensTitle } from "components/MiniLens/MiniLens";
import { SecondaryKpiContainer, StyledKpiRow } from "components/MiniLens/style";
import { useDirectionalIntervals } from "hooks/drillingInvariants/useDirectionalIntervals";
import { useHoleSections } from "hooks/drillingInvariants/useHoleSections";
import { useKpiOptions } from "hooks/drillingInvariants/useKpiOptions";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { useLensTemplates } from "hooks/lens/useLensTemplates";
import { usePreviousNonNullValue } from "hooks/react-utils/usePreviousNonNullValue";
import { getComparisonDisplayValue } from "pages/Lens/LensSummaryView/index";
import { getSecondaryKPIValues } from "pages/Lens/LensSummaryView/index";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { useUOMbyLens } from "utils/format";

export const SingleKPIComparison = (props: SingleKpiProps) => {
  const { lens, detailed } = props;
  const dispatch = useAppDispatch();

  const {
    request: { data, isLoading },
  } = useComparisonKpiFetcher({ lensId: lens?.id });

  const previousData = usePreviousNonNullValue(data);
  const uom = useUOMbyLens(props.dimension, lens);

  // TODO check maybe the set available filters part can be avoided for the overview fetch
  // TODO check is this still used ?

  useEffect(() => {
    if (detailed && data?.availableFilters)
      dispatch({
        type: "SET_AVAILABLE_FILTERS",
        payload: data.availableFilters,
      });
  }, [data?.availableFilters, detailed, dispatch]);

  const displayOption = useAppSelector(
    (state) => state.widgetOptions.display_options,
  );
  const selectedIndicators = useAppSelector(
    (state) => state.widgetOptions.indicators,
  );
  const indicators = useMemo(
    () => (detailed ? selectedIndicators : []),
    [detailed, selectedIndicators],
  );
  const { data: templates } = useLensTemplates();
  const template = templates?.byId[lens.lensTemplateId];
  const { data: kpiTypes } = useKpiTypes();
  const { data: holeSections } = useHoleSections({ refetchOnMount: false });
  const { data: directionalIntervals } = useDirectionalIntervals({
    refetchOnMount: false,
  });

  const mainKPIValues = useMemo(() => {
    const dataSource =
      detailed && previousData && isLoading ? previousData : data;
    return getComparisonDisplayValue(dataSource?.summaryByKpi, lens, uom);
  }, [data, detailed, isLoading, lens, previousData, uom]);

  const secondaryKPIValues = useMemo(() => {
    const dataSource =
      detailed && previousData && isLoading ? previousData : data;
    return getSecondaryKPIValues(
      dataSource as any,
      true,
      displayOption,
      template,
      lens,
      holeSections,
      directionalIntervals || [],
      uom,
      kpiTypes,
    );
  }, [
    data,
    detailed,
    directionalIntervals,
    displayOption,
    holeSections,
    isLoading,
    kpiTypes,
    lens,
    previousData,
    template,
    uom,
  ]);

  useDetailedLegendActiveState({
    comparisons: data?.comparisons || [],
    detailed,
    dataState: data?.dataState || ResultDataState.NoData,
  });

  const { kpiType, kpiGroup } = useKpiOptions(lens);
  const primaryKpiTitle = useMemo(() => {
    return getLensTitle({ lens, template, kpiType, kpiGroup });
  }, [kpiType, kpiGroup, lens, template]);

  const LoadedComponent = useMemo(() => {
    switch (displayOption) {
      case DisplayOption.Well:
        return data?.comparisons ? (
          <SingleComparisonWellChart
            valueUOM={uom}
            data={data}
            lens={lens}
            detailed={detailed}
            indicators={indicators}
            enableTooltip
          />
        ) : (
          <LoadingChart isLoading={false} />
        );
      case DisplayOption.DirectionInterval:
      case DisplayOption.HoleSection:
        return data?.comparisons ? (
          <SingleComparisonCategoryChart
            valueUOM={uom}
            data={data}
            lens={lens}
            detailed={detailed}
            indicators={indicators}
            enableTooltip
          />
        ) : (
          <LoadingChart isLoading={false} />
        );
      case DisplayOption.Shift:
        return data?.comparisons ? (
          <SingleComparisonWellShiftChart
            valueUOM={uom}
            data={data}
            lens={lens}
            detailed={detailed}
            indicators={indicators}
            enableTooltip
          />
        ) : (
          <LoadingChart isLoading={false} />
        );
    }
  }, [data, detailed, displayOption, indicators, lens, uom]);

  const SummaryComponent = useMemo(
    () => (
      <div style={{ height: detailed ? 300 : undefined }}>
        <InfoContainer
          size={6}
          direction="vertical"
          style={{
            height: "auto",
          }}
        >
          <StyledKpiRow gutter={34} height={LENS_KPI_HEIGHT_INNER} wrap={true}>
            {mainKPIValues ? (
              <PrimaryKpiSummary
                secondary={mainKPIValues.secondary}
                main={mainKPIValues.main}
                description={kpiType?.description || ""}
                title={primaryKpiTitle || ""}
                detailed={detailed}
              />
            ) : null}
            {secondaryKPIValues ? (
              <SecondaryKpiContainer>
                <SecondaryKpiSummary
                  detailed={detailed}
                  kpis={secondaryKPIValues?.slice(0)}
                />
              </SecondaryKpiContainer>
            ) : null}
          </StyledKpiRow>
        </InfoContainer>
      </div>
    ),
    [
      detailed,
      kpiType?.description,
      mainKPIValues,
      primaryKpiTitle,
      secondaryKPIValues,
    ],
  );

  return (
    <LensLoadingContainer
      key={lens.id}
      dataState={data?.dataState}
      isDetailed={detailed}
      SummaryComponent={SummaryComponent}
      LoadedComponent={
        <StyledLensContainerFlex>
          {SummaryComponent}
          <StyledChartContainerFlexDiv style={{ paddingLeft: 24 }}>
            {LoadedComponent}
          </StyledChartContainerFlexDiv>
        </StyledLensContainerFlex>
      }
    />
  );
};
