/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntelRanlingRiggonQuarterBasSliceDiffDto
 */
export interface IntelRanlingRiggonQuarterBasSliceDiffDto {
    /**
     * 
     * @type {number}
     * @memberof IntelRanlingRiggonQuarterBasSliceDiffDto
     */
    productivityDiff: number;
    /**
     * 
     * @type {number}
     * @memberof IntelRanlingRiggonQuarterBasSliceDiffDto
     */
    productivityDiffPCt: number;
    /**
     * 
     * @type {number}
     * @memberof IntelRanlingRiggonQuarterBasSliceDiffDto
     */
    rankDiff: number;
}

/**
 * Check if a given object implements the IntelRanlingRiggonQuarterBasSliceDiffDto interface.
 */
export function instanceOfIntelRanlingRiggonQuarterBasSliceDiffDto(value: object): value is IntelRanlingRiggonQuarterBasSliceDiffDto {
    if (!('productivityDiff' in value) || value['productivityDiff'] === undefined) return false;
    if (!('productivityDiffPCt' in value) || value['productivityDiffPCt'] === undefined) return false;
    if (!('rankDiff' in value) || value['rankDiff'] === undefined) return false;
    return true;
}

export function IntelRanlingRiggonQuarterBasSliceDiffDtoFromJSON(json: any): IntelRanlingRiggonQuarterBasSliceDiffDto {
    return IntelRanlingRiggonQuarterBasSliceDiffDtoFromJSONTyped(json, false);
}

export function IntelRanlingRiggonQuarterBasSliceDiffDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelRanlingRiggonQuarterBasSliceDiffDto {
    if (json == null) {
        return json;
    }
    return {
        
        'productivityDiff': json['ProductivityDiff'],
        'productivityDiffPCt': json['ProductivityDiffPCt'],
        'rankDiff': json['RankDiff'],
    };
}

export function IntelRanlingRiggonQuarterBasSliceDiffDtoToJSON(value?: IntelRanlingRiggonQuarterBasSliceDiffDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ProductivityDiff': value['productivityDiff'],
        'ProductivityDiffPCt': value['productivityDiffPCt'],
        'RankDiff': value['rankDiff'],
    };
}

