import type { IFilterOptionValue } from "reducers/rigsCommonReducer";
import type { IAction } from "reducers/types";

export interface NotificationRigFilters {
  rigType: IFilterOptionValue[];
  horsePower: IFilterOptionValue[];
  country: IFilterOptionValue[];
  operatingCenter: IFilterOptionValue[];
  technology: IFilterOptionValue[];
}

interface IAllRigsState {
  rigIds?: number[] | null;
  filters: NotificationRigFilters | null;
}

const initialState: IAllRigsState = {
  rigIds: [],
  filters: null,
};

interface ISetFiltersAction extends IAction {
  type: "NOTIFICATIONS_SET_FILTERS";
  payload: IAllRigsState["filters"];
}

type AvailableActions = ISetFiltersAction;

function notificationsReducer(
  state: IAllRigsState = initialState,
  action: AvailableActions,
): IAllRigsState {
  switch (action.type) {
    case "NOTIFICATIONS_SET_FILTERS":
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return state;
  }
}

export default notificationsReducer;
