/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuarterYear
 */
export interface QuarterYear {
    /**
     * 
     * @type {number}
     * @memberof QuarterYear
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof QuarterYear
     */
    quarter: number;
    /**
     * 
     * @type {string}
     * @memberof QuarterYear
     */
    readonly key: string;
}

/**
 * Check if a given object implements the QuarterYear interface.
 */
export function instanceOfQuarterYear(value: object): value is QuarterYear {
    if (!('year' in value) || value['year'] === undefined) return false;
    if (!('quarter' in value) || value['quarter'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    return true;
}

export function QuarterYearFromJSON(json: any): QuarterYear {
    return QuarterYearFromJSONTyped(json, false);
}

export function QuarterYearFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuarterYear {
    if (json == null) {
        return json;
    }
    return {
        
        'year': json['Year'],
        'quarter': json['Quarter'],
        'key': json['Key'],
    };
}

export function QuarterYearToJSON(value?: Omit<QuarterYear, 'Key'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Year': value['year'],
        'Quarter': value['quarter'],
    };
}

