import { Title } from "atoms/Typography";
import type { FC } from "react";
import React from "react";
import styled from "styled-components";
import { Card, Col, Divider as AntDivier, Row } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

const StyledCard = styled(Card)`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent} !important;
`;

export const PaneDivider = styled(AntDivier)`
  margin: 20px -20px;
  width: unset;
`;

interface IPane {
  title?: string;
  secondary?: string;
  childrenSpan?: number;
  children?: React.ReactNode;
}

const Pane: FC<IPane> = ({
  title = "",
  secondary = "",
  children,
  childrenSpan = 24,
}) => {
  const { atomThemeVariant } = useCustomTheme();
  return (
    <Row>
      {(title !== "" || secondary !== "") && (
        <Col span={24}>
          <Row
            justify="space-between"
            style={{ margin: "16px", marginTop: "0" }}
          >
            <Col flex="0 auto">
              <Title level={3} variant={atomThemeVariant} weight={500}>
                {title}
              </Title>
            </Col>
            <Col>
              <Title level={3} variant="faded">
                {secondary}
              </Title>
            </Col>
          </Row>
        </Col>
      )}
      {children ? (
        <Col span={childrenSpan}>
          <StyledCard>{children}</StyledCard>
        </Col>
      ) : null}
    </Row>
  );
};

export default Pane;
