import { NotificationType } from "apis/oag";
import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import styled from "styled-components";
import colors from "utils/colors";
import { Button } from "utils/componentLibrary";

export const StyledControls = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  visibility: ${({ $isVisible }) => ($isVisible ? "visible" : "hidden")};
  right: 0;
  top: 20px;
`;

export const StyledNotificationRow = styled.div<{ $isRead: boolean }>`
  display: flex;
  height: 110px;
  border-bottom: 1px solid
    ${({ theme }) => theme.themeStyle.colors.separation_line};
  background-color: ${({ theme, $isRead }) =>
    $isRead
      ? theme.themeStyle.notifications.read_bg
      : theme.themeStyle.notifications.unread_bg};
  border-left: 5px solid
    ${({ $isRead }) => ($isRead ? "transparent" : colors.flamboyant_teal)};
  :hover {
    background-color: ${({ theme }) => theme.themeStyle.notifications.hover_bg};
  }
`;

export const StyledRigTitleContainer = styled.div<{
  $notificationType: NotificationType;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  height: 100px;
  gap: 4px;
  place-items: center;
  font-weight: 500;
  font-size: 14px;
  color: ${({ $notificationType, theme }) => {
    if ($notificationType !== NotificationType.ScorecardComment) {
      return theme.isDark ? colors.whisky : colors.hugo;
    }
    return undefined;
  }};
`;

export const StyledNotificationTitle = styled.div<{
  $notificationType: NotificationType;
  $isRead: boolean;
}>`
  font-size: 14px;
  font-weight: ${({ $isRead }) => ($isRead ? 500 : 700)};

  color: ${({ $notificationType, $isRead }) => {
    if ($isRead) {
      return colors.gray;
    }
    if ($notificationType === NotificationType.ScorecardComment) {
      return colors.bluetiful;
    }
    return colors.coral_expression;
  }};
`;

export const StyledWellContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledWellName = styled.div`
  font-weight: 500;
  max-width: 70%;
  margin-right: 8px;
  & h3 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const StyledJob = styled.div`
  display: flex;
  gap: 8px;
  flex-grow: 1;
  overflow: hidden;
  ::before {
    font-weight: 900;
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    content: "·";
  }
`;

export const StyledIconWrapper = styled.div`
  height: 36px;
  width: 36px;
  background: ${({ theme }) => theme.themeStyle.colors.alt_primary_accent};
  border-radius: 50%;
  display: flex;
  place-items: center;
  place-content: center;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  min-width: 0;
  flex-direction: column;
  gap: 4px;
  place-content: center;
`;

export const StyledDescription = styled(Title)`
  color: ${({ theme }) =>
    theme.themeStyle.colors.disabled_typography} !important;
  line-height: normal !important;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 2lh;
`;

export const StyledDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  margin-top: 6px;
  border-left: 2px solid
    ${({ theme }) => theme.themeStyle.colors.alt_primary_accent};
  height: 35px;
`;

export const StyledTime = styled.div`
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: 6px;
  right: 6px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  display: flex;
  place-items: center;
`;

export const StyledButton = styled(Button)<{ $isRead?: boolean }>`
  &,
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  color: ${({ theme, $isRead }) =>
    $isRead
      ? colors.well_color
      : theme.themeStyle.colors.primary_typography} !important;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const StyledCheckbox = styled(PDComponent.Checkbox)`
  margin: 7px;
`;

export const StyledLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  min-width: 320px;
  cursor: pointer;
`;

export const StyledRightContent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;
