import { keepPreviousData, useQuery } from "@tanstack/react-query";
import type { WellStatusType } from "apis/oag";
import { FiltersApi } from "apis/oag";
import { AttributeStore } from "hooks/filters/useLinkedAttributes";
import { useRigsPeriodState } from "hooks/rigs/useRigsPeriodState";
import { useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { RigTechnology } from "utils/enums";
import { PDQueryType } from "utils/queryNamespaces";

const filters = new FiltersApi(apiConfig);

export const useLinkedWellAttributesOptions = ({
  wellIds,
  targetFormationIds,
  operatorIds,
  rigIds,
  storeName,
  enabled = true,
}: {
  storeName: AttributeStore;
  wellIds: number[] | null;
  targetFormationIds: number[] | null;
  operatorIds: number[] | null;
  rigIds: number[] | null;
  enabled: boolean;
}) => {
  //  we are handling date differently in the rigs and wells... bad
  const periodState = useRigsPeriodState();
  const startDate = useAppSelector(
    (state) => state[storeName as "allWells" | "evergreenWells"]?.startDate,
  );
  const endDate = useAppSelector(
    (state) => state[storeName as "allWells" | "evergreenWells"]?.endDate,
  );

  const filterState = useAppSelector((state) => state[storeName].filters);

  const linkedFiltersQueryKey = useMemo(
    () => ({
      type: PDQueryType.LINKED_FILTERS,
      periodState,
      wellIds,
      targetFormationIds,
      operatorIds,
      rigIds,
      startDate,
      filterState,
      endDate,
    }),
    [
      endDate,
      operatorIds,
      periodState,
      rigIds,
      startDate,
      targetFormationIds,
      wellIds,
      filterState,
    ],
  );

  return useQuery({
    queryKey: [linkedFiltersQueryKey],
    queryFn: () => {
      return filters.apiFiltersLinkedWellAttributesPut({
        linkedWellAttributesSetDto: {
          keepEvengreenOnly: storeName === AttributeStore.evergreenWells,
          from: startDate
            ? {
                utc: startDate,
                minutesOffset: 0,
                isDateOnly: true,
              }
            : periodState.from,
          to: endDate
            ? {
                utc: endDate,
                minutesOffset: 0,
                isDateOnly: true,
              }
            : periodState.to,
          wellIds,
          targetFormationIds,
          operatorIds,
          rigIds,
          rigClasses: filterState?.rigType
            ? filterState?.rigType.map((t) => t.id as string)
            : null,
          includeAlphaRigs: filterState?.technology
            ? filterState?.technology
                .map((tech) => tech.id)
                .includes(RigTechnology.Alpha)
            : true,
          includeNonAlphaRigs: filterState?.technology
            ? filterState?.technology
                .map((tech) => tech.id)
                .includes(RigTechnology.NonAlpha)
            : true,
          countryIds: filterState?.country
            ? filterState?.country.map((c) => +(c?.id || -1))
            : null,
          rigHorsePowers: filterState?.horsePower
            ? filterState?.horsePower.map((hp) => +(hp?.id || -1))
            : null,
          operatingCenters: filterState?.operatingCenter
            ? filterState?.operatingCenter.map((oc) =>
                (oc?.id || -1).toString(),
              )
            : null,
          wellStatuses: filterState?.wellStatus
            ? (filterState?.wellStatus.map((ws) => ws.id) as WellStatusType[])
            : null,
        },
      });
    },
    enabled,
    placeholderData: keepPreviousData,
  });
};
