import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { ProfileInfoDto } from "apis/oag";
import { ProfilesApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const profiles = new ProfilesApi(apiConfig);

export function useShareRecipients(
  options?: UseQueryOptions<ProfileInfoDto[]>,
) {
  const queryKey: PDUniqueQueryKey = {
    uid: RequestUID.shareRecipients,
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: () => profiles.apiProfilesInfoAllShareRecipientsGet(),
    staleTime: Infinity,
    ...options,
  });
}
