import { DieselNatGasRatioChart } from "components/Lenses/ContainerLens/DieselNatGasRatio/Chart";
import { useDieselNatGasRatioStatsFacts } from "components/Lenses/ContainerLens/DieselNatGasRatio/fetcher";
import type { DieselNatGasRatioStatsProps } from "components/Lenses/interfaces";

export const DieselNatGasRatioKpi: React.FC<DieselNatGasRatioStatsProps> = ({
  lens,
}) => {
  const { data, isLoading } = useDieselNatGasRatioStatsFacts(lens);
  return (
    <DieselNatGasRatioChart
      dieselRatio={data?.dieselRatio}
      natgasRatio={data?.naturalGasRatio}
      lens={lens}
      dataState={data?.dataState}
      isLoading={isLoading}
    />
  );
};
