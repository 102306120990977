/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { PivotDepthType } from './PivotDepthType';
import {
    PivotDepthTypeFromJSON,
    PivotDepthTypeFromJSONTyped,
    PivotDepthTypeToJSON,
} from './PivotDepthType';
import type { PivotLevelSummaryDto } from './PivotLevelSummaryDto';
import {
    PivotLevelSummaryDtoFromJSON,
    PivotLevelSummaryDtoFromJSONTyped,
    PivotLevelSummaryDtoToJSON,
} from './PivotLevelSummaryDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { DrillingTaskSummary } from './DrillingTaskSummary';
import {
    DrillingTaskSummaryFromJSON,
    DrillingTaskSummaryFromJSONTyped,
    DrillingTaskSummaryToJSON,
} from './DrillingTaskSummary';
import type { PivotType } from './PivotType';
import {
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import type { PivotTree2Dto } from './PivotTree2Dto';
import {
    PivotTree2DtoFromJSON,
    PivotTree2DtoFromJSONTyped,
    PivotTree2DtoToJSON,
} from './PivotTree2Dto';
import type { PivotTree3Dto } from './PivotTree3Dto';
import {
    PivotTree3DtoFromJSON,
    PivotTree3DtoFromJSONTyped,
    PivotTree3DtoToJSON,
} from './PivotTree3Dto';
import type { PivotTree1Dto } from './PivotTree1Dto';
import {
    PivotTree1DtoFromJSON,
    PivotTree1DtoFromJSONTyped,
    PivotTree1DtoToJSON,
} from './PivotTree1Dto';
import type { PivotTargetSegment } from './PivotTargetSegment';
import {
    PivotTargetSegmentFromJSON,
    PivotTargetSegmentFromJSONTyped,
    PivotTargetSegmentToJSON,
} from './PivotTargetSegment';
import type { PivotSummaryByKpiDto } from './PivotSummaryByKpiDto';
import {
    PivotSummaryByKpiDtoFromJSON,
    PivotSummaryByKpiDtoFromJSONTyped,
    PivotSummaryByKpiDtoToJSON,
} from './PivotSummaryByKpiDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';

/**
 * 
 * @export
 * @interface KpiPivotFactSetDto
 */
export interface KpiPivotFactSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof KpiPivotFactSetDto
     */
    readonly stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof KpiPivotFactSetDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof KpiPivotFactSetDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof KpiPivotFactSetDto
     */
    lastUpdatedAt?: DateDto | null;
    /**
     * 
     * @type {FiltersDto}
     * @memberof KpiPivotFactSetDto
     */
    availableFilters?: FiltersDto | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof KpiPivotFactSetDto
     */
    readonly dataState: ResultDataState;
    /**
     * 
     * @type {PivotDepthType}
     * @memberof KpiPivotFactSetDto
     */
    readonly depth: PivotDepthType;
    /**
     * 
     * @type {PivotTree1Dto}
     * @memberof KpiPivotFactSetDto
     */
    dataDepth1?: PivotTree1Dto | null;
    /**
     * 
     * @type {PivotTree2Dto}
     * @memberof KpiPivotFactSetDto
     */
    dataDepth2?: PivotTree2Dto | null;
    /**
     * 
     * @type {PivotTree3Dto}
     * @memberof KpiPivotFactSetDto
     */
    dataDepth3?: PivotTree3Dto | null;
    /**
     * 
     * @type {Array<PivotSummaryByKpiDto>}
     * @memberof KpiPivotFactSetDto
     */
    summaryByKpi?: Array<PivotSummaryByKpiDto> | null;
    /**
     * 
     * @type {Array<PivotLevelSummaryDto>}
     * @memberof KpiPivotFactSetDto
     */
    pivotLevel1Summaries?: Array<PivotLevelSummaryDto> | null;
    /**
     * 
     * @type {Array<PivotLevelSummaryDto>}
     * @memberof KpiPivotFactSetDto
     */
    pivotLevel2Summaries?: Array<PivotLevelSummaryDto> | null;
    /**
     * 
     * @type {Array<PivotLevelSummaryDto>}
     * @memberof KpiPivotFactSetDto
     */
    pivotLevel3Summaries?: Array<PivotLevelSummaryDto> | null;
    /**
     * 
     * @type {PivotType}
     * @memberof KpiPivotFactSetDto
     */
    pivotLevel1: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof KpiPivotFactSetDto
     */
    pivotLevel2: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof KpiPivotFactSetDto
     */
    pivotLevel3: PivotType;
    /**
     * 
     * @type {Array<PivotTargetSegment>}
     * @memberof KpiPivotFactSetDto
     */
    targetSegmentsLevel1?: Array<PivotTargetSegment> | null;
    /**
     * 
     * @type {Array<PivotTargetSegment>}
     * @memberof KpiPivotFactSetDto
     */
    targetSegmentsLevel2?: Array<PivotTargetSegment> | null;
    /**
     * 
     * @type {Array<PivotTargetSegment>}
     * @memberof KpiPivotFactSetDto
     */
    targetSegmentsLevel3?: Array<PivotTargetSegment> | null;
    /**
     * 
     * @type {DrillingTaskSummary}
     * @memberof KpiPivotFactSetDto
     */
    drillingTaskSummary?: DrillingTaskSummary | null;
}



/**
 * Check if a given object implements the KpiPivotFactSetDto interface.
 */
export function instanceOfKpiPivotFactSetDto(value: object): value is KpiPivotFactSetDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    if (!('depth' in value) || value['depth'] === undefined) return false;
    if (!('pivotLevel1' in value) || value['pivotLevel1'] === undefined) return false;
    if (!('pivotLevel2' in value) || value['pivotLevel2'] === undefined) return false;
    if (!('pivotLevel3' in value) || value['pivotLevel3'] === undefined) return false;
    return true;
}

export function KpiPivotFactSetDtoFromJSON(json: any): KpiPivotFactSetDto {
    return KpiPivotFactSetDtoFromJSONTyped(json, false);
}

export function KpiPivotFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiPivotFactSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': json['LastUpdatedAt'] == null ? undefined : DateDtoFromJSON(json['LastUpdatedAt']),
        'availableFilters': json['AvailableFilters'] == null ? undefined : FiltersDtoFromJSON(json['AvailableFilters']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'depth': PivotDepthTypeFromJSON(json['Depth']),
        'dataDepth1': json['DataDepth1'] == null ? undefined : PivotTree1DtoFromJSON(json['DataDepth1']),
        'dataDepth2': json['DataDepth2'] == null ? undefined : PivotTree2DtoFromJSON(json['DataDepth2']),
        'dataDepth3': json['DataDepth3'] == null ? undefined : PivotTree3DtoFromJSON(json['DataDepth3']),
        'summaryByKpi': json['SummaryByKpi'] == null ? undefined : ((json['SummaryByKpi'] as Array<any>).map(PivotSummaryByKpiDtoFromJSON)),
        'pivotLevel1Summaries': json['PivotLevel1Summaries'] == null ? undefined : ((json['PivotLevel1Summaries'] as Array<any>).map(PivotLevelSummaryDtoFromJSON)),
        'pivotLevel2Summaries': json['PivotLevel2Summaries'] == null ? undefined : ((json['PivotLevel2Summaries'] as Array<any>).map(PivotLevelSummaryDtoFromJSON)),
        'pivotLevel3Summaries': json['PivotLevel3Summaries'] == null ? undefined : ((json['PivotLevel3Summaries'] as Array<any>).map(PivotLevelSummaryDtoFromJSON)),
        'pivotLevel1': PivotTypeFromJSON(json['PivotLevel1']),
        'pivotLevel2': PivotTypeFromJSON(json['PivotLevel2']),
        'pivotLevel3': PivotTypeFromJSON(json['PivotLevel3']),
        'targetSegmentsLevel1': json['TargetSegmentsLevel1'] == null ? undefined : ((json['TargetSegmentsLevel1'] as Array<any>).map(PivotTargetSegmentFromJSON)),
        'targetSegmentsLevel2': json['TargetSegmentsLevel2'] == null ? undefined : ((json['TargetSegmentsLevel2'] as Array<any>).map(PivotTargetSegmentFromJSON)),
        'targetSegmentsLevel3': json['TargetSegmentsLevel3'] == null ? undefined : ((json['TargetSegmentsLevel3'] as Array<any>).map(PivotTargetSegmentFromJSON)),
        'drillingTaskSummary': json['DrillingTaskSummary'] == null ? undefined : DrillingTaskSummaryFromJSON(json['DrillingTaskSummary']),
    };
}

export function KpiPivotFactSetDtoToJSON(value?: Omit<KpiPivotFactSetDto, 'StopWatch'|'DataState'|'Depth'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'AvailableFilters': FiltersDtoToJSON(value['availableFilters']),
        'DataDepth1': PivotTree1DtoToJSON(value['dataDepth1']),
        'DataDepth2': PivotTree2DtoToJSON(value['dataDepth2']),
        'DataDepth3': PivotTree3DtoToJSON(value['dataDepth3']),
        'SummaryByKpi': value['summaryByKpi'] == null ? undefined : ((value['summaryByKpi'] as Array<any>).map(PivotSummaryByKpiDtoToJSON)),
        'PivotLevel1Summaries': value['pivotLevel1Summaries'] == null ? undefined : ((value['pivotLevel1Summaries'] as Array<any>).map(PivotLevelSummaryDtoToJSON)),
        'PivotLevel2Summaries': value['pivotLevel2Summaries'] == null ? undefined : ((value['pivotLevel2Summaries'] as Array<any>).map(PivotLevelSummaryDtoToJSON)),
        'PivotLevel3Summaries': value['pivotLevel3Summaries'] == null ? undefined : ((value['pivotLevel3Summaries'] as Array<any>).map(PivotLevelSummaryDtoToJSON)),
        'PivotLevel1': PivotTypeToJSON(value['pivotLevel1']),
        'PivotLevel2': PivotTypeToJSON(value['pivotLevel2']),
        'PivotLevel3': PivotTypeToJSON(value['pivotLevel3']),
        'TargetSegmentsLevel1': value['targetSegmentsLevel1'] == null ? undefined : ((value['targetSegmentsLevel1'] as Array<any>).map(PivotTargetSegmentToJSON)),
        'TargetSegmentsLevel2': value['targetSegmentsLevel2'] == null ? undefined : ((value['targetSegmentsLevel2'] as Array<any>).map(PivotTargetSegmentToJSON)),
        'TargetSegmentsLevel3': value['targetSegmentsLevel3'] == null ? undefined : ((value['targetSegmentsLevel3'] as Array<any>).map(PivotTargetSegmentToJSON)),
        'DrillingTaskSummary': DrillingTaskSummaryToJSON(value['drillingTaskSummary']),
    };
}

