import { useMutation, useQueryClient } from "@tanstack/react-query";
import type {
  ApiPlansIdPutRequest,
  ApiPlansPostRequest,
  PlanActivityDto,
  PlanDrillingParameterDto,
  PlanDto,
  PlanFormationDto,
} from "apis/oag";
import { DimensionType, PlansApi } from "apis/oag";
import { Button } from "atoms/Form";
import { toast } from "atoms/toast";
import { Title } from "atoms/Typography";
import { OverviewHeaderContainer, StyledTabs } from "components/Layout/Tabbed";
import { PDComponent } from "components/PDComponents";
import type { SelectedUnits } from "components/WellPlan/DrillingParameters/DrillingParameter";
import { DrillingParameter } from "components/WellPlan/DrillingParameters/DrillingParameter";
import { Formation } from "components/WellPlan/Formations/Formation";
import { Overview } from "components/WellPlan/Overview/Overview";
import { useRestoreDataModal } from "components/WellPlan/WellPlanCommun/RestoreDataModal";
import { useWellPlan } from "hooks/wells/useWellPlan";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import { omit } from "lodash";
import { Suspense, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { store } from "reducers/store";
import { IUnitSystem } from "reducers/types";
import { Track, useMixpanel } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import { Col, Row, Space, Typography } from "utils/componentLibrary";
import { useUOM } from "utils/format";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";
import type { IErrorPlanEditor } from "utils/wellplan/errors";
import { checkErrors } from "utils/wellplan/errors";
import { isNotEmptyRow } from "utils/wellplan/utils";
// TODO ADD MP Tracking

const MIN_ROWS = 5;
const plans = new PlansApi(apiConfig);

const localStorageKey = "planDraft";
const selectionStorageKey = "selection";

function mapNegativeIdToZero<
  T extends PlanActivityDto | PlanFormationDto | PlanDrillingParameterDto,
>(items: T[]) {
  return items.map((item) => (item.id <= 0 ? { ...item, id: 0 } : item));
}

const defaultSelection: SelectedUnits = {
  wob: "kdan",
  rpm: "rpm",
  rop: "mhr",
  spp: "kpa",
  diffPressure: "kpa",
  torque: "ftlb",
  flowIn: "m3min",
};
const DEFAULT_NUM_ROWS = 5;

const initialErrors: IErrorPlanEditor = {
  overview: null,
  formation: null,
  drilling: null,
};

export const PlanPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { data: wellData } = useWellShortInfoSuspended();

  const params = useParams<{ wellId: string; tabId?: string }>();
  const wellId = parseInt(params.wellId || "");
  const selectedWell = wellData?.byId[wellId];

  const { data: initialPlan } = useWellPlan({ id: wellId });
  const [plan, setPlan] = useState<PlanDto | undefined>(initialPlan);
  const [planModified, setPlanModified] = useState(false);
  const planModifiedRef = useRef<boolean | null>(null);
  const [isShowModal, changeModalVisibility] = useState(false);
  const [selection, setSelection] = useState<SelectedUnits>(defaultSelection);
  const uom = useAppSelector((state) => state.global.unit);
  useEffect(() => {
    // add more rows function
    setPlan(getTransformedData(initialPlan as PlanDto));
  }, [initialPlan]);
  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();
  useEffect(() => {
    if (!params) return;
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage("Well Plan", {
        "Well ID": params.wellId,
        "Tab Name": params.tabId,
      });
    }
  }, [viewPage, params]);

  useEffect(() => {
    planModifiedRef.current = planModified;
  }, [planModified]);

  const [componentLevelErrors, setComponentLevelErrors] =
    useState<IErrorPlanEditor>(initialErrors);
  const [showComponentErrors, setShowComponentErrors] = useState(false);

  const depthData = useUOM(DimensionType.Metres);

  // Add 5 rows in the plan pages start
  // @ts-expect-error TODO Manage empty rows (nullable properties) with proper typing (wrap/unwrap)
  const getTransformedData: (planData: PlanDto) => PlanDto = (
    planData: PlanDto,
  ) => {
    return {
      ...planData,
      activities: [
        ...planData.activities,
        ...(planData.activities.length >= MIN_ROWS
          ? []
          : Array(DEFAULT_NUM_ROWS - planData.activities.length)
              .fill(1)
              .map((_, index) => {
                const minNegativeId = planData.activities
                  .map((activity) => activity.id)
                  .filter((id) => id < 0)
                  .reduce((a: number, b: number) => Math.min(a, b), 0);

                const maxPosition = planData.activities
                  .map((activity) => activity.position)
                  .reduce((a, b) => Math.max(a, b), 1);
                return {
                  phaseId: null,
                  duration: -1,
                  endHoleDepth: -1,
                  startHoleDepth: 0,
                  endCumulativeDuration: -1,
                  startCumulativeDuration: 0,
                  id: minNegativeId - 1 - index,
                  position: maxPosition + 1 + index,
                };
              })),
      ],
      formations: [
        ...planData.formations,
        ...(planData.formations.length >= MIN_ROWS
          ? []
          : Array(DEFAULT_NUM_ROWS - planData.formations.length)
              .fill(1)
              .map((_, index) => {
                const minNegativeId = planData.formations
                  .map((activity) => activity.id)
                  .filter((id) => id < 0)
                  .reduce((a: number, b: number) => Math.min(a, b), 0);

                return {
                  measuredDepth: null,
                  id: minNegativeId - 1 - index,
                  formationId: undefined,
                };
              })),
      ],
      drillingParameters: [
        ...planData.drillingParameters,
        ...(planData.drillingParameters.length >= MIN_ROWS
          ? []
          : Array(DEFAULT_NUM_ROWS - planData.drillingParameters.length)
              .fill(1)
              .map((_, index) => {
                const minNegativeId = planData.drillingParameters
                  .map((activity) => activity.id)
                  .filter((id) => id < 0)
                  .reduce((a: number, b: number) => Math.min(a, b), 0);

                return {
                  measuredDepth: null,
                  id: minNegativeId - 1 - index,
                };
              })),
      ],
    };
  };

  const { node: restoreDataModal, open: openRestoreDataModal } =
    useRestoreDataModal({
      onRestore: (data) => {
        // add more rows function
        setPlan(getTransformedData(data.planObj));
        setSelection(data.selectionObj ?? defaultSelection);
        setPlanModified(true);
        setSelection(
          JSON.parse(window.localStorage.getItem(selectionStorageKey) || ""),
        );
      },
      onCancel: () => {
        window.localStorage.removeItem(localStorageKey);
        window.localStorage.removeItem(selectionStorageKey);
      },
    });

  useEffect(() => {
    if (!showComponentErrors) return;
    checkErrors({
      setComponentLevelErrors,
      plan,
      depthData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan, showComponentErrors]);

  useEffect(() => {
    dispatch({
      type: "SET_SELECTED_WELL",
      payload: {
        well: wellId,
      },
    });
  }, [dispatch, wellId]);

  useEffect(() => {
    const storedPlan = window.localStorage.getItem(localStorageKey);
    const selectionKeys = window.localStorage.getItem(selectionStorageKey);

    try {
      const planObj = storedPlan ? JSON.parse(storedPlan) : null;
      let selectionObj = null;
      if (selectionKeys) selectionObj = JSON.parse(selectionKeys);

      if (planObj) {
        openRestoreDataModal({ planObj, selectionObj });
      }
    } catch {
      // do nothing
    }
  }, [openRestoreDataModal]);

  useEffect(() => {
    if (!planModified) {
      return;
    }

    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [planModified]);

  const onCancel = () => {
    changeModalVisibility(false);
  };

  const handleConfirm = () => {
    navigate(`/well/${wellId}`);
  };

  useEffect(() => {
    if (planModified) {
      if (store.getState().global.unit === IUnitSystem.METRIC)
        window.localStorage.setItem(localStorageKey, JSON.stringify(plan));
      else window.localStorage.setItem(localStorageKey, JSON.stringify(plan));
      window.localStorage.setItem(
        selectionStorageKey,
        JSON.stringify(selection),
      );
      return () => {
        window.localStorage.removeItem(localStorageKey);
        window.localStorage.removeItem(selectionStorageKey);
      };
    }
  }, [plan, planModified, selection, uom]);

  const publishMutation = useMutation({
    mutationFn: () => {
      const pageLevelErrors = checkErrors({
        setComponentLevelErrors,
        plan,
        depthData,
      });
      setShowComponentErrors(true);
      const activities: PlanActivityDto[] = (plan?.activities || [])
        .map((activity) =>
          omit(activity, "startHoleDepth", "startCumulativeDuration"),
        )
        .filter((activity) =>
          // @ts-expect-error TODO Manage empty rows (nullable properties) with proper typing (wrap/unwrap)
          isNotEmptyRow(activity, { ignoredValues: ["position"] }),
        )
        .map((activity) => ({
          startCumulativeDuration: 0,
          startHoleDepth: 0,
          ...activity,
          duration: Math.round(activity.duration),
          endCumulativeDuration: activity.endCumulativeDuration
            ? Math.round(activity.endCumulativeDuration)
            : 0,
          endHoleDepth: activity.endHoleDepth,
        }));
      const formations = plan?.formations.filter((formation) =>
        isNotEmptyRow(formation, { defaultValues: { formationId: 1 } }),
      );
      let drillingParameters = plan?.drillingParameters.filter(
        (drillingParameter) => isNotEmptyRow(drillingParameter, {}),
      );

      if (pageLevelErrors?.length > 0) {
        throw pageLevelErrors;
      }

      // TODO Simplify those
      drillingParameters = [
        ...(drillingParameters || []).map((drillingParameter) => {
          Object.keys(drillingParameter).forEach((key) => {
            if (key === "measuredDepth" || key === "id") return;
          });
          return drillingParameter;
        }),
      ];

      // We replace all negative ids with zeros before sending a request
      if (plan?.id) {
        const request: ApiPlansIdPutRequest = {
          id: plan.id,
          planDto: {
            ...plan,
            startFormationDepth: plan.startFormationDepth ?? null,
            startParameterDepth: plan.startParameterDepth ?? null,
            startHoleDepth: plan.startHoleDepth ?? null,
            activities: mapNegativeIdToZero(activities),
            formations: mapNegativeIdToZero(formations || []),
            drillingParameters: mapNegativeIdToZero(drillingParameters),
          },
        };
        return plans.apiPlansIdPut(request);
      } else {
        const request: { planDto: ApiPlansPostRequest["planDto"] } = {
          planDto: {
            ...omit(plan, "id"),
            id: 0,
            startFormationDepth: plan?.startFormationDepth ?? null,
            startParameterDepth: plan?.startParameterDepth ?? null,
            startHoleDepth: plan?.startHoleDepth ?? null,
            activities: mapNegativeIdToZero(activities),
            formations: mapNegativeIdToZero(formations || []),
            drillingParameters: mapNegativeIdToZero(drillingParameters),
          },
        };
        return plans.apiPlansPost(request);
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          { type: PDQueryType.WELL_PLAN_TIMELINE, wellDataId: wellId },
        ],
        ...{
          refetchType: "inactive",
          exact: false,
        },
      });
    },
    onSuccess: (newPlan) => {
      window.localStorage.removeItem(localStorageKey);
      setPlanModified(false);
      queryClient.setQueryData(
        [{ type: PDQueryType.WELL_PLAN, id: newPlan.id }],
        newPlan,
      );

      // Pushing to the end of the queue in order to unblock history
      setTimeout(() => {
        navigate(`/well/${wellId}`);
        dispatch({
          type: "SET_REFETCH_OVERVIEW",
          payload: true,
        });
        toast.success({ message: "Well Plan has been published." });
      }, 1);
    },
    onError: (errors: Error[]) => {
      errors.map((error) =>
        toast.error({
          message: `Could not save plan. ${
            error.message.includes("depth")
              ? "Measured depth is not correct in"
              : "Values are malformed on"
          } the ${(error.message?.split(" - ") ?? [])[0]} tab.`,
        }),
      );
    },
  });

  const tabs = [
    {
      key: "overview",
      children: (
        <Suspense fallback={null}>
          <Overview
            wellId={wellId}
            plan={plan}
            setPlan={setPlan}
            setPlanModified={setPlanModified}
            errors={componentLevelErrors["overview"]}
          />
        </Suspense>
      ),
      label: "Overview",
    },
    {
      key: "formation",
      children: (
        <Suspense fallback={null}>
          <Formation
            wellId={wellId}
            plan={plan}
            setPlan={setPlan}
            setPlanModified={setPlanModified}
            errors={componentLevelErrors["formation"]}
          />
        </Suspense>
      ),
      label: "Formation / Pore & Frac",
    },
    {
      key: "drilling",
      children: (
        <Suspense fallback={null}>
          <DrillingParameter
            selection={selection}
            setSelection={setSelection}
            wellId={wellId}
            plan={plan}
            setPlan={setPlan}
            setPlanModified={setPlanModified}
            errors={componentLevelErrors["drillingParameter"]}
          />
        </Suspense>
      ),
      label: "Drilling Parameter",
    },
  ];

  const { atomThemeVariant, themeStyle } = useCustomTheme();

  return (
    <>
      <PDComponent.Modal
        onCancel={onCancel}
        title="Save this draft?"
        footer={
          <Row justify="space-between" align="middle">
            <Button
              onClick={() => {
                Track.interact("Well Overview - Cancel Draft");
                // do not publish and do not save and go back
                window.localStorage.removeItem(localStorageKey);
                window.localStorage.removeItem(selectionStorageKey);
                planModifiedRef.current = false;
                // remove restore data from this flow
                // if you say do not save you are not going to be prompted with restore
                handleConfirm();
                onCancel();
              }}
            >
              Cancel Draft
            </Button>
            <Button
              type="primary"
              loading={publishMutation.isPending}
              onClick={() => {
                Track.interact("Well Overview - Publish Draft");
                // publish and go back
                publishMutation.mutate();
                onCancel();
              }}
            >
              Publish Draft
            </Button>
          </Row>
        }
        open={isShowModal}
      >
        <Typography>
          It looks like you haven’t published your work. By leaving this page
          your draft will be lost. Would you like to publish this draft before
          leaving this page?{" "}
        </Typography>
      </PDComponent.Modal>
      <Row>
        <Col span={24} style={{ background: themeStyle.colors.primary_bg }}>
          <OverviewHeaderContainer>
            <Row
              justify="space-between"
              align="middle"
              style={{ height: "80px" }}
            >
              <Col flex="0 auto">
                <Title level={3} variant={atomThemeVariant} weight={500}>
                  Plan for {selectedWell?.name}
                </Title>
              </Col>
              <Col flex="0 auto">
                <Space>
                  <Button
                    size="large"
                    onClick={() => {
                      Track.interact("Well Overview - Cancel Draft");

                      window.localStorage.removeItem(localStorageKey);
                      window.localStorage.removeItem(selectionStorageKey);
                      planModifiedRef.current = false;
                      handleConfirm();
                      onCancel();
                    }}
                    loading={publishMutation.isPending}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => {
                      Track.interact("Well Overview - Publish Draft");
                      publishMutation.mutate();
                    }}
                    loading={publishMutation.isPending}
                  >
                    Publish
                  </Button>
                </Space>
              </Col>
            </Row>
            <StyledTabs
              type="card"
              tabBarStyle={{ margin: 0 }}
              tabBarGutter={6}
              activeKey={params.tabId}
              onTabClick={(key) => navigate(`/well/${wellId}/plan/${key}`)}
              items={tabs}
            />
          </OverviewHeaderContainer>
        </Col>
      </Row>
      {restoreDataModal}
    </>
  );
};

export default PlanPage;
