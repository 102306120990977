import { Brush } from "@visx/brush";
import type { Bounds } from "@visx/brush/lib/types";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import type { DateDto } from "apis/oag";
import { selectedBrushStyle } from "components/Lenses/utils";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { TEMP_BRUSH_FIX_CONST } from "components/WellDashboard/ControlHeader/atoms/Zoom/ZoomSvg";
import { bisector } from "d3-array";
import type { ScaleLinear } from "d3-scale";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import moment from "moment";
import { useCallback, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "reducers/store";
import type { IZoomData } from "reducers/types";
import { IZoomType } from "reducers/types";
import { Track } from "services/Mixpanel";

export function useChartDateTimeRange<
  SeriesT extends { at?: DateDto; index?: number },
>({
  xScale,
  yScale,
  series,
  plotWidth,
  plotHeight,
  plotWidthOffset = 0,
}: {
  xScale: ScaleLinear<number, number, never>;
  yScale: ScaleLinear<number, number, never>;
  series: SeriesT[];
  plotWidth: number;
  plotHeight: number;
  plotWidthOffset?: number;
}) {
  const [isDragging, setIsDragging] = useState(false);
  const brushRef = useRef(null);
  const dispatch = useAppDispatch();

  const handleOnBrushStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const [zoomData, setZoom] = useStateQuery<IZoomData>(
    URL_STATE_PARAM.ZOOM_WELL,
    initialZoomData,
    [URL_STATE_PARAM.ZOOM_WIDGET],
  );

  const setZoomData = useCallback(
    (zoom: IZoomData) => {
      setZoom(zoom);
      if (zoom)
        dispatch({
          type: "SET_REPORT_ZOOM",
          payload: { zoom, state: true },
        });
    },
    [dispatch, setZoom],
  );

  const getPointForChartPosition = useCallback(
    (series: SeriesT[], pointIndexInSeries: number) => {
      if (!series) return null;
      const index = bisector<SeriesT, number>((p) => p?.index || -1).left(
        series,
        pointIndexInSeries,
      );

      if (
        index >= 0 &&
        index < series.length &&
        Number.isFinite(series[index].index)
      ) {
        return series[index];
      }

      return null;
    },
    [],
  );

  const xScaleBrush = useMemo(() => {
    const chartSize = plotWidth;
    return scaleLinear({
      domain: xScale.domain(),
      range: [-TEMP_BRUSH_FIX_CONST, chartSize + TEMP_BRUSH_FIX_CONST],
    });
  }, [plotWidth, xScale]);

  const handleOnBrushEnd = useCallback(
    (bounds: Bounds | null) => {
      const { x0: start, x1: end } = bounds ?? { x0: 0, x1: 0 };
      const startPoint = getPointForChartPosition(series, start);
      const endPoint = getPointForChartPosition(series, end);

      if (end - start <= 2) {
        Track.interact("Evergreen Dashboard - Reset Zoom", {
          action: "Click",
        });
        setZoomData({
          ...zoomData,
          date_start: null,
          date_end: null,
        });
        return;
      }

      if (startPoint?.at && endPoint?.at) {
        Track.interact("Evergreen Dashboard - Zoom", {
          action: "Drag",
          date_start: moment.utc(startPoint?.at.utc).toDate(),
          date_end: moment.utc(endPoint?.at.utc).toDate(),
        });
        setZoomData({
          ...initialZoomData,
          type: IZoomType.DATE,
          date_start: moment.utc(startPoint?.at.utc).toDate(),
          date_end: moment.utc(endPoint?.at.utc).toDate(),
          date_start_well_offset: startPoint?.at.minutesOffset,
          date_end_well_offset: endPoint?.at.minutesOffset,
        });
      }
      // wait for the component know if it's suspended
      requestAnimationFrame(() => {
        setIsDragging(false);
      });
    },
    [getPointForChartPosition, series, setZoomData, zoomData],
  );

  const SelectedRectangleElement = (
    <Group left={plotWidthOffset}>
      <Brush
        margin={{
          left: plotWidthOffset,
          top: 0,
          bottom: 0,
          right: 0,
        }}
        brushRegion="chart"
        innerRef={brushRef}
        xScale={xScaleBrush}
        yScale={yScale}
        width={plotWidth}
        height={plotHeight}
        handleSize={8}
        resizeTriggerAreas={["left", "right"]}
        brushDirection={"horizontal"}
        onBrushEnd={handleOnBrushEnd}
        onBrushStart={handleOnBrushStart}
        selectedBoxStyle={selectedBrushStyle}
        useWindowMoveEvents
        onClick={() => setIsDragging(false)}
        resetOnEnd
      />
    </Group>
  );

  return {
    selectionRectangleElement: SelectedRectangleElement,
    isDragging,
  };
}
