import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { FormationDto } from "apis/oag";
import { FormationsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const api = new FormationsApi(apiConfig);

const FormationsQueryKey: PDUniqueQueryKey = { uid: RequestUID.formations };

type FormationsData = {
  list: FormationDto[];
  byId: { [key: number]: FormationDto };
};

export function useFormations(
  options?: UseSuspenseQueryOptions<FormationsData>,
) {
  return useSuspenseQuery<FormationsData>({
    queryKey: [FormationsQueryKey],
    queryFn: () =>
      api.apiFormationsGet().then((data) => ({
        list: data,
        byId: data.reduce<Record<number, FormationDto>>((acc, cur) => {
          acc[cur.id ?? 0] = cur;
          return acc;
        }, {}),
      })),
    ...options,
  });
}
