import { useMutation, useQueryClient } from "@tanstack/react-query";
import type {
  ColumnFiltersState,
  Row,
  SortingState,
} from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  IntelGroupingType,
  type IntelScorecardFactDto,
  type IntelScorecardUserLensDto,
  IntelScorecardUserLensesApi,
} from "apis/oag";
import { Title } from "atoms/Typography";
import type { SelectorItem } from "components/GroupSelector/GroupSelector";
import { GroupSelector } from "components/GroupSelector/GroupSelector";
import NoData from "components/Lenses/common/NoData";
import { PDComponent } from "components/PDComponents";
import { useIntelScorecardFacts } from "hooks/facts/useIntelScorecardFacts";
import { CardInner } from "pages/IntelDashboard/components/IntelRankingRibbon/style";
import {
  CardHeader,
  CardTitle,
} from "pages/IntelDashboard/components/IntelScatterPlot/style";
import { IntelLegendColorsContext } from "pages/IntelDashboard/useIntelLegendColors";
import { mix } from "polished";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import {
  Col,
  Row as StyledRow,
  Space,
  Switch,
  Tooltip,
} from "utils/componentLibrary";
import { SortDirections } from "utils/enums";
import { RequestUID } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

import { Filter } from "./Filter";
import { StyledParent, StyledTable } from "./style";
import { useColumns } from "./useColumns";
import { getCommonPinningStyles } from "./utils";

const api = new IntelScorecardUserLensesApi(apiConfig);

export enum COLUMNS_IDS {
  lensGrouping = "lens-grouping",
  rig = "rig",
  operator = "operator",
  basin = "basin",
  operatorRank = "operator-rank",
  basinAvgComp = "basin-avg-comp",
  basinRank = "basin-rank",
  wellEndDate = "well-end-date",
  drillingProd = "drilling-prod",
  averageDays = "average-days",
  averageMD = "average-md",
  quartile = "quartile",
}

const FIXED_SIZE_COLUMNS = [
  COLUMNS_IDS.lensGrouping,
  COLUMNS_IDS.rig,
  COLUMNS_IDS.operator,
  COLUMNS_IDS.basin,
] as const;

type Keys = (typeof FIXED_SIZE_COLUMNS)[number];
export const IntelScorecard = ({
  lens,
}: {
  lens: IntelScorecardUserLensDto;
}) => {
  const filterStateCommitted = useAppSelector(
    (state) => state.intelFiltersCommitted,
  );

  const legendSelectedGroup = useAppSelector(
    (state) => state.intelDashboard.groupingType,
  );
  const { data } = useIntelScorecardFacts(lens, {
    id: lens.id,
    wellIntelQueryDto: {
      ...filterStateCommitted,
      grouping: legendSelectedGroup,
    },
  });
  const highlightedIds = useAppSelector(
    (state) => state.intelDashboard.highlightedIds,
  );

  const { getColor } = useContext(IntelLegendColorsContext);
  const isOperatorDisabled = legendSelectedGroup === IntelGroupingType.Operator;
  const { isDark } = useCustomTheme();
  const columns = useColumns({
    lens,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]); // can set initial column filter state here
  const updateFiltersBasedOnVisibility = useCallback(
    (columnVisibility: Record<Keys, boolean>) => {
      setColumnFilters((columnFilters) => {
        const newColumnFilters = columnFilters.filter(
          (filter) => columnVisibility[filter.id as Keys],
        );
        return newColumnFilters;
      });
    },
    [],
  );
  const columnVisibility = useMemo(() => {
    const newVisibility = {
      [COLUMNS_IDS.lensGrouping]: true, // always visible
      [COLUMNS_IDS.wellEndDate]: [legendSelectedGroup, lens.grouping].includes(
        IntelGroupingType.Well,
      ),
      [COLUMNS_IDS.rig]:
        legendSelectedGroup !== lens.grouping &&
        legendSelectedGroup !== IntelGroupingType.Operator,
      [COLUMNS_IDS.basin]: lens.showBasin,
      [COLUMNS_IDS.basinAvgComp]: lens.showBasin,
      [COLUMNS_IDS.basinRank]: lens.showBasin,
      [COLUMNS_IDS.operator]:
        (lens.showOperator ||
          legendSelectedGroup === IntelGroupingType.Operator) &&
        lens.grouping !== IntelGroupingType.Operator,
      [COLUMNS_IDS.operatorRank]:
        (lens.showOperator ||
          legendSelectedGroup === IntelGroupingType.Operator) &&
        lens.grouping !== IntelGroupingType.Operator,
    };
    updateFiltersBasedOnVisibility(newVisibility);
    return newVisibility;
  }, [
    legendSelectedGroup,
    lens.grouping,
    lens.showBasin,
    lens.showOperator,
    updateFiltersBasedOnVisibility,
  ]);

  const table = useReactTable({
    data: data.facts,
    columns,
    enableColumnPinning: true,
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: (newSorting) => {
      setSorting(newSorting);
    },
    initialState: {
      columnPinning: {
        left: [COLUMNS_IDS.lensGrouping, COLUMNS_IDS.rig],
      },
    },
    state: {
      sorting,
      columnVisibility,
      columnFilters,
    },
    filterFns: {
      // add a custom global filter function
      myCustomFilterFn: (row, columnId, filterValue) => {
        // defined inline here
        return filterValue.includes(row.original[columnId]);
      },
    },
    getFacetedRowModel: getFacetedRowModel(), // client-side faceting
    getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(), // needed for client-side filtering
    getCoreRowModel: getCoreRowModel(),
  });

  const parentRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: table.getFilteredRowModel().rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 50, // border included
    overscan: 5,
  });
  const { rows } = table.getRowModel();
  const queryClient = useQueryClient();
  const updateLensMutation = useMutation({
    mutationFn: async (lens: IntelScorecardUserLensDto) => {
      return api.apiIntelScorecardUserLensesIdPut({
        id: lens.id,
        intelScorecardUserLensDto: lens,
      });
    },
    onSettled: (updatedLens) => {
      queryClient.setQueryData(
        [{ uid: RequestUID.intelLenses }],
        (data: IntelScorecardUserLensDto[]) => {
          return data.map((l) => {
            if (l.id === lens.id) {
              return updatedLens;
            }
            return l;
          });
        },
      );
    },
  });

  const handleKeyToggle = (key: "Basin" | "Operator", value: boolean) => {
    updateLensMutation.mutate(
      key === "Basin"
        ? {
            ...lens,
            showBasin: value,
          }
        : {
            ...lens,
            showOperator: value,
          },
    );
  };
  const selectorItems: SelectorItem<IntelGroupingType>[] = [
    { key: IntelGroupingType.Well, icon: "wellInfo", label: "Well" },
    { key: IntelGroupingType.Rig, icon: "rig", label: "Rig" },
    {
      key: IntelGroupingType.Contractor,
      icon: "intelContractor",
      label: "Contractor",
    },
    { key: IntelGroupingType.Operator, icon: "operator", label: "Operator" },
  ];

  const handleGroupingChange = (grouping: IntelGroupingType) => {
    updateLensMutation.mutate({
      ...lens,
      grouping,
    });
  };
  const { themeStyle } = useCustomTheme();
  const adjustTableWidth = useCallback(() => {
    const tableContainer: HTMLDivElement | null =
      document.querySelector(".table-container");
    const table: HTMLTableElement | null = document.querySelector(
      ".proportional-table",
    );

    if (!table || !tableContainer) return;
    const tableWidth = table.offsetWidth;
    const containerWidth = tableContainer.offsetWidth;

    if (tableWidth < containerWidth) {
      table.style.width = "100%";
    } else {
      table.style.width = "";
    }
  }, []);
  useEffect(() => {
    if (lens.showOperator || isOperatorDisabled) {
      updateLensMutation.mutate({
        ...lens,
        showOperator: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOperatorDisabled, lens.showOperator]);

  useEffect(() => {
    if (!adjustTableWidth) return;
    // Adjust table width on load and window resize
    adjustTableWidth();
    window.addEventListener("resize", adjustTableWidth);
    return () => {
      window.removeEventListener("resize", adjustTableWidth);
    };
  }, [adjustTableWidth]);

  useEffect(() => {
    adjustTableWidth();
  }, [lens, legendSelectedGroup, adjustTableWidth]);
  useEffect(() => {
    const container: HTMLDivElement | null =
      document.querySelector(".table-container");

    if (container) {
      container.addEventListener("scroll", () => {
        if (container.scrollLeft > 0) {
          container.classList.add("scrolled");
        } else {
          container.classList.remove("scrolled");
        }
      });
    }
  }, []);
  return (
    <CardInner>
      <CardHeader>
        <CardTitle>Scorecard Table</CardTitle>
      </CardHeader>
      <StyledRow style={{ justifyContent: "space-between", paddingBottom: 8 }}>
        <GroupSelector<IntelGroupingType>
          items={selectorItems}
          onSelect={handleGroupingChange}
          selectedItem={lens.grouping}
        />
        <Space>
          <Switch
            size="small"
            checked={lens.showBasin}
            onChange={() => handleKeyToggle("Basin", !lens.showBasin)}
          />

          <Title level={3}>Basin</Title>

          {lens.grouping !== IntelGroupingType.Operator && (
            <Tooltip
              title={
                isOperatorDisabled
                  ? "Disabled due to legend being set to Operator"
                  : undefined
              }
              placement="topRight"
            >
              <StyledRow
                gutter={8}
                style={{
                  paddingRight: 16,
                }}
              >
                <Col>
                  <Switch
                    size="small"
                    checked={lens.showOperator || isOperatorDisabled}
                    disabled={isOperatorDisabled}
                    onChange={() =>
                      handleKeyToggle("Operator", !lens.showOperator)
                    }
                  />
                </Col>
                <Col>
                  <Title
                    level={3}
                    variant={isOperatorDisabled ? "faded" : undefined}
                  >
                    Operator
                  </Title>
                </Col>
              </StyledRow>
            </Tooltip>
          )}
        </Space>
      </StyledRow>

      <StyledParent
        ref={parentRef}
        className="table-container"
        isNoData={!table.getFilteredRowModel().rows.length}
      >
        <StyledTable
          cellPadding={0}
          cellSpacing={0}
          className="proportional-table"
        >
          <thead
            style={{
              width: "100%",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                style={{ display: "flex", width: "100%" }}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={`${header.id}-th`}
                      // get this element by id
                      id={`${header.id}-th`}
                      colSpan={header.colSpan}
                      className={header.column.getIsPinned() ? "pinned" : ""}
                      style={{
                        ...getCommonPinningStyles(header.column),
                        cursor: header.column.getCanSort()
                          ? "pointer"
                          : "default",
                        textAlign: FIXED_SIZE_COLUMNS.includes(
                          header.column.id as Keys,
                        )
                          ? "left"
                          : "center",
                        justifyContent: FIXED_SIZE_COLUMNS.includes(
                          header.column.id as Keys,
                        )
                          ? "left"
                          : "center",
                        minWidth: header.column.getSize(),
                      }}
                    >
                      {header.column.getCanFilter() ? (
                        <Filter
                          column={header.column}
                          lensGrouping={lens.grouping}
                          legendGrouping={legendSelectedGroup}
                          key={`${header.column.id}-filter`}
                          onReset={
                            columnFilters.length > 0
                              ? () => {
                                  setColumnFilters([]);
                                }
                              : undefined
                          }
                        />
                      ) : null}
                      <span
                        className="text"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        <span
                          className="sort-buttons"
                          // eslint-disable-next-line react/forbid-dom-props
                          style={{
                            display: header.column.getIsSorted()
                              ? "flex"
                              : undefined,
                          }}
                        >
                          <PDComponent.SvgIcon
                            name="up_arrow"
                            color={
                              header.column.getIsSorted() &&
                              header.column.getIsSorted() === SortDirections.Asc
                                ? themeStyle.colors.all_rigs_title
                                : ""
                            }
                          />
                          <PDComponent.SvgIcon
                            name="down_arrow"
                            color={
                              header.column.getIsSorted() &&
                              header.column.getIsSorted() ===
                                SortDirections.Desc
                                ? themeStyle.colors.all_rigs_title
                                : ""
                            }
                          />
                        </span>
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            style={{
              display: "grid",
              height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              position: "relative", //needed for absolute positioning of rows
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[
                virtualRow.index
              ] as unknown as Row<IntelScorecardFactDto>;
              if (!row) return null;
              const highlightColor = highlightedIds?.has(
                row.original.key.queryKey,
              )
                ? getColor({
                    key: row.original.key.queryKey,
                  })
                : undefined;
              // remove opacity from hsl color
              const removeOpacity = (color: string) =>
                color.replace(/[^,]+(?=\))/, "1");
              const defaultColor = isDark ? colors.revolver : colors.white;
              const combinedColor = mix(
                isDark ? 0.1 : 0.07,
                highlightColor ? removeOpacity(highlightColor) : defaultColor,
                defaultColor,
              );

              return (
                <tr
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                  key={row.id}
                  style={{
                    display: "flex",
                    position: "absolute",
                    transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    const { column } = cell;
                    return (
                      <td
                        key={cell.id}
                        className={column.getIsPinned() ? "pinned" : ""}
                        style={{
                          ...getCommonPinningStyles(column),
                          minWidth: column.getSize(),
                          backgroundColor: combinedColor,
                          textAlign: FIXED_SIZE_COLUMNS.includes(
                            column.id as Keys,
                          )
                            ? "left"
                            : "center",
                          justifyContent: FIXED_SIZE_COLUMNS.includes(
                            column.id as Keys,
                          )
                            ? "left"
                            : "center",
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
        {table.getFilteredRowModel().rows.length ? null : <NoData isStatic />}
      </StyledParent>
    </CardInner>
  );
};
