import type { WellIntelQueryDto } from "apis/oag";
import { UNFILTERED_SELECTIONS } from "reducers/intelFiltersReducerUncommitted";

import type { IAction } from "./types";

export type IFiltersReducerState = WellIntelQueryDto;

const initialState: IFiltersReducerState = UNFILTERED_SELECTIONS;

interface ISetAllFilters extends IAction {
  type: "INTEL_DASHBOARD_SET_ALL_COMMITTED_FILTERS";
  payload: IFiltersReducerState;
}

interface ISetFilter extends IAction {
  type: "INTEL_DASHBOARD_SET_COMMITTED_FILTER";
  payload: {
    key: keyof IFiltersReducerState;
    value: IFiltersReducerState[keyof IFiltersReducerState];
  };
}

interface ISetFilter extends IAction {
  type: "INTEL_DASHBOARD_SET_COMMITTED_FILTER";
  payload: {
    key: keyof IFiltersReducerState;
    value: IFiltersReducerState[keyof IFiltersReducerState];
  };
}

interface IFiltersResetToInitialState extends IAction {
  type: "INTEL_DASHBOARD_COMMITTED_FILTERS_RESET_TO_INITIAL_STATE";
}

type AvailableActions =
  | ISetAllFilters
  | ISetFilter
  | IFiltersResetToInitialState;

function intelFiltersReducerCommitted(
  state: IFiltersReducerState = initialState,
  action: AvailableActions,
): IFiltersReducerState {
  switch (action.type) {
    case "INTEL_DASHBOARD_SET_ALL_COMMITTED_FILTERS":
      return {
        ...action.payload,
      };
    case "INTEL_DASHBOARD_SET_COMMITTED_FILTER":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "INTEL_DASHBOARD_COMMITTED_FILTERS_RESET_TO_INITIAL_STATE":
      return initialState;

    default:
      return state;
  }
}

export default intelFiltersReducerCommitted;
