import type { QuarterYear } from "apis/oag";
import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import { CheckboxCheckedState } from "components/PDComponents/Checkbox/Checkbox";
import { useIntelFilterActions } from "hooks/filters/useIntelFilterActions";
import { useIntelLinkedFiltersByGroupAndQuarter } from "hooks/intel/useIntelLinkedFilters";
import { useIntelQuarterYearList } from "hooks/intel/useIntelQuarterYearList";
import { FilterOptionRow } from "pages/IntelDashboard/components/IntelHeader/DetailedFilterPopup/FilterGroup/FilterOptionRow";
import {
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { Popover, Space, Tooltip } from "utils/componentLibrary";

import { ButtonsContainer, QuarterContainer } from "./style";

export const QuarterSelector = () => {
  const dispatch = useAppDispatch();
  const [isFilterPopupVisible, setIsFilterPopupVisible] = useState(false);

  const { isFetching } = useIntelLinkedFiltersByGroupAndQuarter();
  const { commitFilterKey } = useIntelFilterActions();

  const { data: quarterSet } = useIntelQuarterYearList();
  const allQuarters = useMemo(() => quarterSet?.quarters ?? [], [quarterSet]);

  const defaultAndResetSelectionQuarters = useMemo(
    () => allQuarters.slice(0, 4),
    [allQuarters],
  );

  const committedQuarters = useAppSelector(
    (state) => state.intelFiltersCommitted.selectedQuarters,
  );

  const hasAutoSelectedQuarterYearList = useAppSelector(
    (state) => state.intelDashboard.hasAutoSelectedQuarterYearList,
  );

  const uncommittedQuarters = useAppSelector(
    (state) => state.intelFiltersUncommitted.selectedQuarters,
  );

  const reset = useCallback(() => {
    setIsFilterPopupVisible(false);

    startTransition(() => {
      commitFilterKey("selectedQuarters", defaultAndResetSelectionQuarters);
    });
  }, [commitFilterKey, defaultAndResetSelectionQuarters]);

  useEffect(() => {
    if (
      quarterSet?.quarters &&
      !hasAutoSelectedQuarterYearList &&
      defaultAndResetSelectionQuarters
    ) {
      commitFilterKey("selectedQuarters", defaultAndResetSelectionQuarters);

      dispatch({
        // TODO Maybe this can be optimized too and put in a filters action if i have time
        type: "INTEL_SET_QUARTER_YEAR_LIST_AS_AUTOSELECTED",
        payload: true,
      });
    }
  }, [
    commitFilterKey,
    committedQuarters,
    defaultAndResetSelectionQuarters,
    dispatch,
    hasAutoSelectedQuarterYearList,
    quarterSet?.quarters,
    reset,
  ]);

  const isFilterApplied = useMemo(() => {
    const committedSet = new Set(committedQuarters?.map((q) => q.key));
    const defaultSet = new Set(
      defaultAndResetSelectionQuarters?.map((q) => q.key),
    );

    if (committedSet.difference(defaultSet).size === 0) {
      return false;
    }

    return (committedQuarters?.length ?? 0) > 0;
  }, [committedQuarters, defaultAndResetSelectionQuarters]);

  const handleOnClickQuarterItem = useCallback(
    (quarterYearKey: QuarterYear["key"]) => {
      const foundQuarterOptionFromAllAvailable = quarterSet?.quarters.find(
        (q) => q.key === quarterYearKey,
      );

      const wasAlreadySelected =
        uncommittedQuarters?.find((q) => q.key === quarterYearKey) ?? false;

      let newOptions = uncommittedQuarters ? [...uncommittedQuarters] : [];

      if (wasAlreadySelected) {
        newOptions = newOptions.filter((q) => q.key !== wasAlreadySelected.key);
      } else if (foundQuarterOptionFromAllAvailable) {
        newOptions.push(foundQuarterOptionFromAllAvailable);
      }

      dispatch({
        type: "INTEL_DASHBOARD_SET_FILTER_KEY",
        payload: {
          key: "selectedQuarters",
          value: newOptions,
        },
      });
    },
    [dispatch, quarterSet?.quarters, uncommittedQuarters],
  );

  const handleOnClickApply = useCallback(() => {
    setIsFilterPopupVisible(false);
    commitFilterKey("selectedQuarters");
  }, [commitFilterKey]);

  const getOptionCheckboxState = useCallback(
    (option: QuarterYear) => {
      return uncommittedQuarters?.includes(option)
        ? CheckboxCheckedState.Checked
        : CheckboxCheckedState.Unchecked;
    },
    [uncommittedQuarters],
  );

  const listWithSearchOptions: {
    id: QuarterYear["key"];
    name: QuarterYear["key"];
    component: JSX.Element;
  }[] = useMemo(
    () =>
      allQuarters.map((option) => ({
        id: option.key,
        name: option.key,
        component: (
          <FilterOptionRow
            optionName={option.key}
            idForLabel={`quarter-option-${option.key}`}
            onOptionChecked={() => {}}
            checkedState={getOptionCheckboxState(option)}
          />
        ),
      })),
    [allQuarters, getOptionCheckboxState],
  );

  return (
    <Tooltip title="Quarters">
      <Popover
        content={
          <QuarterContainer>
            <PDComponent.ListWithSearch
              onClick={(selectedQuarterOption) => {
                handleOnClickQuarterItem(selectedQuarterOption.id);
              }}
              options={listWithSearchOptions}
              placeholder="Search by quarters"
              values={[]}
              height={165}
              itemHeight={37}
              innerListStyle={{
                border: "none",
                padding: 8,
              }}
            />
            <ButtonsContainer>
              <Space>
                <Button onClick={reset}>Reset</Button>
                <Button
                  type="primary"
                  onClick={handleOnClickApply}
                  disabled={uncommittedQuarters?.length === 0}
                >
                  Apply
                </Button>
              </Space>
            </ButtonsContainer>
          </QuarterContainer>
        }
        trigger={["click", "scroll"]}
        placement="bottomRight"
        open={isFilterPopupVisible}
        onOpenChange={(e) => setIsFilterPopupVisible(e)}
        destroyTooltipOnHide
      >
        <Button
          size="large"
          icon={<PDComponent.SvgIcon name="calendar" />}
          onClick={() =>
            setIsFilterPopupVisible(
              (isFilterPopupVisible) => !isFilterPopupVisible,
            )
          }
          disabled={isFetching}
          $engaged={isFilterPopupVisible}
          type={isFilterApplied ? "primary" : "default"}
          ghost={isFilterApplied}
        >
          {`${committedQuarters?.length ?? 0} Quarters`}
        </Button>
      </Popover>
    </Tooltip>
  );
};
