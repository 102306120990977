import { useQuery } from "@tanstack/react-query";
import { WellsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDWellQuicksearchQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const wells = new WellsApi(apiConfig);

export function useWellQuickSearch({
  query,
  nonPendingOnly,
}: {
  query: string;
  nonPendingOnly?: boolean;
}) {
  const queryKey: PDWellQuicksearchQueryKey = {
    type: PDQueryType.WELL_QUICKSEARCH,
    uid: RequestUID.wellQuicksearch,
    query,
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: () =>
      wells.apiWellsSearchFullTextGet({
        search: query,
        nonPendingOnly,
      }),
    enabled: !!query,
  });
}
