import { useQuery } from "@tanstack/react-query";
import type { WellIntelQueryDto } from "apis/oag";
import { FiltersApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import { PDQueryType } from "utils/queryNamespaces";

const filters = new FiltersApi(apiConfig);

export const useIntelQuarterYearList = () => {
  return useQuery({
    queryKey: [
      {
        type: PDQueryType.LINKED_FILTERS_INTEL_QUARTER_OPTIONS,
      },
    ],
    queryFn: () => {
      return filters.apiFiltersIntelQuartersPut({
        wellIntelQueryDto: {} as WellIntelQueryDto, // all filters and quarter selection to null by default
      });
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
};
