import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { DirectionalIntervalDto } from "apis/oag";
import { DirectionalIntervalsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const directionalIntervals = new DirectionalIntervalsApi(apiConfig);

const DirectionalIntervalsQueryKey: PDUniqueQueryKey = {
  uid: RequestUID.directionalIntervals,
};

export function fetchDirectionalIntervals() {
  return directionalIntervals.apiDirectionalIntervalsGet();
}

export function useDirectionalIntervals(
  options?: Omit<
    UseSuspenseQueryOptions<DirectionalIntervalDto[]>,
    "queryKey" | "queryFn"
  >,
) {
  return useSuspenseQuery<DirectionalIntervalDto[]>({
    queryKey: [DirectionalIntervalsQueryKey],
    queryFn: () => fetchDirectionalIntervals(),

    ...options,
    refetchOnMount: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
