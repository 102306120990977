import { Title } from "atoms/Typography";
import {
  confirmModalStyles,
  StyledCancelButton,
  StyledCloseIcon,
  StyledConfirmButton,
  StyledModalContainer,
  StyledModalContent,
  StyledModalFooter,
  StyledModalHeader,
  StyledTitle,
} from "components/ConfirmModal/style";
import { PDComponent } from "components/PDComponents";
import { useCallback, useMemo, useState } from "react";
import Modal from "react-modal";
import { useCustomTheme } from "utils/useTheme";

type ModalProps = {
  title: string;
  description: string;
  loading?: boolean;
  hasSuccessColor?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
};

type ConfirmModalProps = ModalProps & {
  isVisible: boolean;
};

export const ConfirmModal = ({
  title,
  description,
  isVisible,
  onCancel,
  onConfirm,
  hasSuccessColor = false,
  loading,
}: ConfirmModalProps) => {
  const { themeStyle } = useCustomTheme();

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onCancel}
      style={{
        content: {
          ...confirmModalStyles.content,
          backgroundColor: themeStyle.colors.primary_bg,
        },
        overlay: confirmModalStyles.overlay,
      }}
      ariaHideApp={false}
    >
      <StyledModalContainer>
        <StyledModalHeader>
          <StyledTitle>{title}</StyledTitle>
          <StyledCloseIcon onClick={onCancel}>
            <PDComponent.SvgIcon name="close" />
          </StyledCloseIcon>
        </StyledModalHeader>
        <StyledModalContent>
          <Title variant="faded" level={3}>
            {description}
          </Title>
        </StyledModalContent>
        <StyledModalFooter>
          <StyledCancelButton onClick={onCancel}>Cancel</StyledCancelButton>
          <StyledConfirmButton
            $hasSuccessColor={hasSuccessColor}
            onClick={onConfirm}
            loading={loading}
          >
            Confirm
          </StyledConfirmButton>
        </StyledModalFooter>
      </StyledModalContainer>
    </Modal>
  );
};

export const useConfirmModal = (props: ModalProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const openConfirmModal = () => {
    setIsVisible(true);
  };

  const onModalConfirm = useCallback(() => {
    setIsVisible(false);
    if (props.onConfirm) {
      props.onConfirm();
    }
  }, [props]);

  const onModalClose = useCallback(() => {
    setIsVisible(false);
    if (props.onCancel) {
      props.onCancel();
    }
  }, [props]);

  const confirmModalElement = useMemo(
    () => (
      <ConfirmModal
        {...props}
        isVisible={isVisible}
        onCancel={onModalClose}
        onConfirm={onModalConfirm}
      />
    ),
    [props, isVisible, onModalClose, onModalConfirm],
  );

  return { confirmModalElement, openConfirmModal };
};
