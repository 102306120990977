import type {
  LinkedAttributesOptions,
  LinkedAttributesOptionsString,
} from "components/Filters/AllFilters";
import { UNINITIALIZED_OPTIONS } from "components/Filters/AllFilters";
import { useAsyncInitialAttributeFilterOptions } from "components/Filters/useInitialAttributeFiltersOptions";
import { useFormations } from "hooks/drillingInvariants/useFormations";
import { useOperators } from "hooks/drillingInvariants/useOperators";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import type {
  additionalLinkedFilters,
  linkedFilters,
} from "hooks/filters/useFilterTags";
import { useLinkedWellAttributesOptions } from "hooks/filters/useLinkedWellAttributesOptions";
import { useWellShortInfo } from "hooks/wells/useWellShortInfo";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { IFilterOptions } from "reducers/rigsCommonReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { match, P } from "ts-pattern";
import { RigTechnology } from "utils/enums";

export enum AttributeStore {
  "rigsCommon" = "rigsCommon",
  "allWells" = "allWells",
  "evergreenWells" = "evergreenWells",
}

const useUpdateAttributes = ({ storeName }: { storeName: AttributeStore }) => {
  const customReducerName = useMemo(() => {
    return match(storeName)
      .with(AttributeStore.rigsCommon, () => "RIGS_COMMON_")
      .with(AttributeStore.allWells, () => "ALL_WELLS_")
      .with(AttributeStore.evergreenWells, () => "EVERGREEN_WELLS_")
      .exhaustive() as "RIGS_COMMON_" | "ALL_WELLS_" | "EVERGREEN_WELLS_";
  }, [storeName]);
  const dispatch = useAppDispatch();

  const updateFormations = useCallback(
    (formationIds: number[] | null) => {
      dispatch({
        type: `${customReducerName}FORMATIONS_IDS`,
        payload: formationIds ? formationIds.toSorted((a, b) => a - b) : null,
      });
      return true;
    },
    [customReducerName, dispatch],
  );

  const updateOperators = useCallback(
    (operatorIds: number[] | null) => {
      dispatch({
        type: `${customReducerName}SET_OPERATORS`,
        payload: {
          operators: operatorIds ?? null,
        },
      });
    },
    [customReducerName, dispatch],
  );

  const updateRigs = useCallback(
    (rigIds: number[] | null) => {
      dispatch({
        type: `${customReducerName}SET_RIGS`,
        payload: {
          rigIds: rigIds ?? null,
        },
      });
    },
    [customReducerName, dispatch],
  );

  const onWellsChange = useCallback(
    (selectedWells: number[] | null) => {
      if (customReducerName === "RIGS_COMMON_") {
        dispatch({
          type: `${customReducerName}SET_WELLS`,
          payload: {
            selectedWells: selectedWells ?? null,
          },
        });
        if (selectedWells) {
          // If we don t want to reset the wells
          dispatch({
            type: `${customReducerName}SET_WELLS_MAP`,
            payload: {
              selectedWells,
            },
          });
        }
      }

      // we don't have selected wells in other places than rigsCommon
    },
    [customReducerName, dispatch],
  );

  const onFiltersChange = useCallback(
    (filterName: keyof IFilterOptions, value: number[] | null) => {
      dispatch({
        type: `${customReducerName}UPDATE_FILTER`,
        payload: {
          key: filterName,
          value,
        },
      });
    },
    [customReducerName, dispatch],
  );

  const updateAttributes = useCallback(
    (filter: (typeof linkedFilters | typeof additionalLinkedFilters)[number]) =>
      (value: number[] | null) => {
        match(filter)
          .with("rigs", () => updateRigs(value))
          .with("operators", () => updateOperators(value))
          .with("formations", () => updateFormations(value))
          .with("wells", () => onWellsChange(value))
          .with(
            P.union(
              "wellStatus",
              "rigType",
              "horsePower",
              "operatingCenter",
              "technology",
              "country",
            ),
            () =>
              onFiltersChange(
                filter as (typeof additionalLinkedFilters)[number],
                value,
              ),
          )
          .exhaustive();
      },
    [
      onFiltersChange,
      onWellsChange,
      updateFormations,
      updateOperators,
      updateRigs,
    ],
  );
  const resetFilters = useCallback(() => {
    updateOperators(null);
    updateRigs(null);
    onWellsChange(null);
    updateFormations(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onWellsChange, updateFormations, updateOperators, updateRigs]);

  return {
    updateFilters: updateAttributes,
    resetFilters,
  };
};

export const useLinkedWellAttributes = ({
  storeName,
  closePopups,
  ignoreMapSelectedWells,
}: {
  storeName: AttributeStore;
  closePopups?: () => void;
  ignoreMapSelectedWells?: boolean;
}) => {
  const { data: operators } = useOperators();
  const { data: rigs } = useRigs();
  const { data: formations } = useFormations();

  const {
    data: wellData,
    isLoading: isLoadingWells,
    isFetched: isFetchedWells,
  } = useWellShortInfo();

  const [options, setOptions] = useState<{
    rigs: LinkedAttributesOptions;
    operators: LinkedAttributesOptions;
    formations: LinkedAttributesOptions;
    wells: LinkedAttributesOptions;
    wellStatus: LinkedAttributesOptionsString;
    rigType: LinkedAttributesOptionsString;
    horsePower: LinkedAttributesOptions;
    operatingCenter: LinkedAttributesOptionsString;
    technology: LinkedAttributesOptionsString;
    country: LinkedAttributesOptions;
  }>(UNINITIALIZED_OPTIONS);

  const { updateFilters, resetFilters } = useUpdateAttributes({ storeName });

  const {
    filters: initialAttributeFilterOptions,
    isLoading: isInitialAttributeFilterOptionsLoading,
  } = useAsyncInitialAttributeFilterOptions();

  const formationIdsState = useAppSelector(
    (state) => state[storeName].formationIds,
  );
  const operatorsIdsState = useAppSelector(
    (state) => state[storeName].operators,
  );
  const rigsIdsState = useAppSelector((state) => state[storeName].rigIds);
  const mapWellIdsState = useAppSelector(
    (state) =>
      (
        state[storeName] as {
          selectedWellsMap: number[] | null;
        }
      )?.selectedWellsMap,
  );
  const wellsIdsState =
    useAppSelector(
      (state) =>
        (
          state[storeName] as {
            selectedWells: number[] | null;
          }
        )?.selectedWells,
    ) ?? null;

  const filtersState = useAppSelector((state) => state[storeName]?.filters);

  const selectedWells = useMemo(() => {
    if (ignoreMapSelectedWells) {
      return wellsIdsState;
    }
    return mapWellIdsState ?? wellsIdsState;
  }, [ignoreMapSelectedWells, mapWellIdsState, wellsIdsState]);

  const {
    data,
    isPlaceholderData: isLoadingOptions,
    isFetched: isFetchedFilters,
  } = useLinkedWellAttributesOptions({
    storeName,
    enabled: !isLoadingWells,
    wellIds: selectedWells,
    targetFormationIds: formationIdsState,
    operatorIds: operatorsIdsState,
    rigIds: rigsIdsState,
  });

  useEffect(() => {
    if (!data) return;
    const {
      rigIds: rigOptions,
      operatorIds: operatorOptions,
      targetFormationIds: formationOptions,
      wellIds: wellOptions,
      wellStatuses: wellStatusOptions,
      rigClasses: rigTypeOptions,
      rigHorsePowers: horsePowerOptions,
      operatingCenters: operatingCenterOptions,
      includeAlphaRigs: includeAlphaRigsOption,
      includeNonAlphaRigs: includeNonAlphaRigsOption,
      countryIds: countryOptions,
    } = data;

    const rigTechnologyOptions: RigTechnology[] = [];
    if (includeAlphaRigsOption) {
      rigTechnologyOptions.push(RigTechnology.Alpha);
    }
    if (includeNonAlphaRigsOption) {
      rigTechnologyOptions.push(RigTechnology.NonAlpha);
    }

    const optionsData = {
      rigs: (rigs?.list ?? [])
        .filter((r) => (rigOptions ?? []).find((id) => id === r.id))
        .map((r) => ({ id: r.id, name: r.shortName })),
      operators: (operators?.list ?? [])
        .filter((r) => (operatorOptions ?? []).find((id) => id === r.id))
        .map((r) => ({ id: r.id, name: r.name })),
      formations: (formations?.list ?? [])
        .filter((r) => (formationOptions ?? []).find((id) => id === r.id))
        .map((r) => ({ id: r.id, name: r.name })),
      wells: (wellData?.list ?? [])
        .filter((r) => (wellOptions ?? []).find((id) => id === r.id))
        .map((r) => ({ id: r.id, name: r.name })),
      wellStatus: (initialAttributeFilterOptions?.wellStatus ?? [])
        .filter((r) => (wellStatusOptions ?? []).find((id) => id === r.id))
        .map((r) => ({ id: r.value, name: r.value })),
      rigType: (initialAttributeFilterOptions?.type ?? [])
        .filter((r) => (rigTypeOptions ?? []).find((id) => id === r.id))
        .map((r) => ({ id: r.value, name: r.value })),
      horsePower: (initialAttributeFilterOptions?.horsePower ?? [])
        .filter((r) => (horsePowerOptions ?? []).find((id) => id === +r.id))
        .map((r) => ({ id: +r.id, name: r.value })),
      operatingCenter: (initialAttributeFilterOptions?.operatingCenter ?? [])
        .filter((r) => (operatingCenterOptions ?? []).find((id) => id === r.id))
        .map((r) => ({ id: r.value, name: r.value })),
      technology: (initialAttributeFilterOptions?.technology ?? [])
        .filter((r) => (rigTechnologyOptions ?? []).find((id) => id === r.id))
        .map((r) => ({ id: r.value, name: r.value })),
      country: (initialAttributeFilterOptions?.country ?? [])
        .filter((r) => (countryOptions ?? []).find((id) => id === +r.id))
        .map((r) => ({ id: +r.id, name: r.value })),
    };
    setOptions(optionsData);
  }, [
    data,
    formations?.list,
    initialAttributeFilterOptions,
    operators?.list,
    rigs?.list,
    wellData?.list,
  ]);

  const selectedValues = useMemo(() => {
    return {
      rigs: rigsIdsState,
      operators: operatorsIdsState,
      formations: formationIdsState,
      wells: wellsIdsState,
      wellStatus: filtersState?.wellStatus?.map((s) => s.id ?? null) ?? null,
      rigType: filtersState?.rigType?.map((s) => s.id ?? null) ?? null,
      horsePower: filtersState?.horsePower?.map((s) => s.id ?? null) ?? null,
      operatingCenter:
        filtersState?.operatingCenter?.map((s) => s.id ?? null) ?? null,
      technology: filtersState?.technology?.map((s) => s.id ?? null) ?? null,
      country: filtersState?.country?.map((s) => s.id ?? null) ?? null,
    };
  }, [
    filtersState,
    formationIdsState,
    operatorsIdsState,
    rigsIdsState,
    wellsIdsState,
  ]);

  return {
    resetFilters: () => {
      resetFilters();
      closePopups?.();
    },
    options,
    selectedValues: selectedValues,
    isSelected: {
      rigs:
        rigsIdsState !== null &&
        (rigsIdsState ?? []).length !== rigs?.list.length,
      operators:
        operatorsIdsState !== null &&
        (operatorsIdsState ?? []).length !== operators?.list.length,
      formations:
        formationIdsState !== null &&
        (formationIdsState ?? []).length !== formations?.list.length,
      wells:
        wellsIdsState !== null &&
        (wellsIdsState ?? []).length !== wellData?.list.length,
    },
    updateFilters,
    isLoading: {
      wells: isLoadingWells || !isFetchedWells,
      filterOptions:
        isLoadingOptions ||
        !isFetchedFilters ||
        isInitialAttributeFilterOptionsLoading ||
        options === UNINITIALIZED_OPTIONS,
    },
  };
};
