import * as Styled from "components/Clara/MessageThread/style";
import { PDComponent } from "components/PDComponents";
import { startTransition, useCallback, useState } from "react";
import { Tooltip } from "utils/componentLibrary";

export const FavoriteButton = ({
  isLoading,
  isFavorite,
  toggleFavorite,
}: {
  isLoading: boolean;
  isFavorite: boolean;
  toggleFavorite: () => void;
}) => {
  const [tooltipMessage, setTooltipMessage] = useState("Favorite");
  const [renderKey, setRenderKey] = useState(0);

  const handleOnClickFavorite = useCallback(() => {
    if (isFavorite) {
      setTooltipMessage("Unfavorite");
      setRenderKey((prevKey) => prevKey + 1); // to force re-rendering of the tooltip
    } else if (!isLoading) {
      setTooltipMessage("Added to Favorites");
    }
    toggleFavorite();
  }, [isFavorite, isLoading, toggleFavorite]);

  const handleOnOpenTooltip = useCallback(
    (isOpen: boolean) => {
      startTransition(() => {
        if (isOpen) {
          if (isFavorite) {
            setTooltipMessage("Unfavorite");
          } else {
            setTooltipMessage("Favorite");
          }
        }
      });
    },
    [isFavorite],
  );

  return (
    <Tooltip
      placement="bottom"
      title={tooltipMessage}
      onOpenChange={handleOnOpenTooltip}
      key={renderKey}
    >
      <Styled.SimpleButtonWrapper
        $isLoading={isLoading}
        role="button"
        onClick={handleOnClickFavorite}
      >
        {/* 25.5 number because one icon was the wrong size. Needed one number that sizes them the same - needs a replace-like effect */}
        {isFavorite ? (
          <PDComponent.SvgIcon name="starFilled" />
        ) : (
          <PDComponent.SvgIcon name="star" style={{ fontSize: 25.5 }} />
        )}
      </Styled.SimpleButtonWrapper>
    </Tooltip>
  );
};
