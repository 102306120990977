import { PivotType } from "apis/oag";
import { createContext, useContext } from "react";

export enum RigCardLayoutType {
  Split = "Split",
  BarsOnly = "BarsOnly",
  TableOny = "TableOny",
}
interface IRigsCommonOptions {
  pivot: PivotType;
  layoutType: RigCardLayoutType;
}

const RIGS_COMMON_KEY = "rigsCommon";

const defaultOptions = {
  pivot: PivotType.Rig,
  layoutType: RigCardLayoutType.Split,
};

const setFleetPerformanceOptions = (value: IRigsCommonOptions) => {
  localStorage.setItem(RIGS_COMMON_KEY, JSON.stringify(value));
};

export const getAllRigsOptions = () => {
  const optionsString = localStorage.getItem(RIGS_COMMON_KEY);
  return optionsString
    ? (JSON.parse(optionsString) as IRigsCommonOptions)
    : defaultOptions;
};

export const RigOptionsContext = createContext<
  | [
      IRigsCommonOptions,
      React.Dispatch<React.SetStateAction<IRigsCommonOptions>>,
    ]
  | undefined
>(undefined);

export function useFleetPerformanceOptions() {
  const contextValues = useContext(RigOptionsContext);

  if (contextValues === undefined) {
    throw new Error(
      "useFleetPerformanceOptions must be within context of RigOptionsContext ",
    );
  }

  const [rigOptions, setRigOptions] = contextValues;

  const setStateWrapper = (value: IRigsCommonOptions) => {
    setFleetPerformanceOptions(value);
    setRigOptions(value);
  };

  const setPivot = (pivot: PivotType) => {
    setStateWrapper({ ...rigOptions, pivot });
  };

  const setLayoutType = (layoutType: RigCardLayoutType) => {
    setStateWrapper({ ...rigOptions, layoutType });
  };

  return { rigOptions, setRigOptions, setPivot, setLayoutType };
}
