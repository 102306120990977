/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GeneratorLoadProfileFactDto } from './GeneratorLoadProfileFactDto';
import {
    GeneratorLoadProfileFactDtoFromJSON,
    GeneratorLoadProfileFactDtoFromJSONTyped,
    GeneratorLoadProfileFactDtoToJSON,
} from './GeneratorLoadProfileFactDto';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface GeneratorLoadProfileFactSetDto
 */
export interface GeneratorLoadProfileFactSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof GeneratorLoadProfileFactSetDto
     */
    readonly stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof GeneratorLoadProfileFactSetDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof GeneratorLoadProfileFactSetDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof GeneratorLoadProfileFactSetDto
     */
    lastUpdatedAt?: DateDto | null;
    /**
     * 
     * @type {Array<GeneratorLoadProfileFactDto>}
     * @memberof GeneratorLoadProfileFactSetDto
     */
    facts?: Array<GeneratorLoadProfileFactDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof GeneratorLoadProfileFactSetDto
     */
    readonly dataState: ResultDataState;
}



/**
 * Check if a given object implements the GeneratorLoadProfileFactSetDto interface.
 */
export function instanceOfGeneratorLoadProfileFactSetDto(value: object): value is GeneratorLoadProfileFactSetDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function GeneratorLoadProfileFactSetDtoFromJSON(json: any): GeneratorLoadProfileFactSetDto {
    return GeneratorLoadProfileFactSetDtoFromJSONTyped(json, false);
}

export function GeneratorLoadProfileFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratorLoadProfileFactSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': json['LastUpdatedAt'] == null ? undefined : DateDtoFromJSON(json['LastUpdatedAt']),
        'facts': json['Facts'] == null ? undefined : ((json['Facts'] as Array<any>).map(GeneratorLoadProfileFactDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function GeneratorLoadProfileFactSetDtoToJSON(value?: Omit<GeneratorLoadProfileFactSetDto, 'StopWatch'|'DataState'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'Facts': value['facts'] == null ? undefined : ((value['facts'] as Array<any>).map(GeneratorLoadProfileFactDtoToJSON)),
    };
}

