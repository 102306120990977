import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext, useMemo, useState } from "react";

interface FilteredWellsContext {
  visibleWellsList: number[];
  setVisibleWellsList: Dispatch<SetStateAction<number[]>>;
}

export const FilteredAllWellsContext = createContext<
  FilteredWellsContext | undefined
>(undefined);

export function useFilteredAllWells<T extends { id: number }>(list: Array<T>) {
  const wellContext = useContext(FilteredAllWellsContext);

  if (wellContext === undefined) {
    console.warn(
      "No context for filtered wells exists in hierarchy, defaulting to displaying all",
    );
    return list;
  } else {
    return list.filter((well) =>
      wellContext.visibleWellsList.includes(well.id),
    );
  }
}

export function useFilteredAllWellsContext() {
  const wellContext = useContext(FilteredAllWellsContext);

  if (wellContext === undefined) {
    throw new Error(
      "useFilteredAllWellsContext must be used within a FilteredAllWellsContext",
    );
  }

  return wellContext;
}

export const FilteredAllWellsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [visibleWellsList, setVisibleWellsList] = useState<
    FilteredWellsContext["visibleWellsList"]
  >([]);

  const props = useMemo(
    () => ({ visibleWellsList, setVisibleWellsList }),
    [visibleWellsList],
  );
  return (
    <FilteredAllWellsContext.Provider value={props}>
      {children}
    </FilteredAllWellsContext.Provider>
  );
};
