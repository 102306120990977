import { useMutation } from "@tanstack/react-query";
import type {
  GeneratorSlotType,
  StatCardLensTemplateDto,
  StatCardUserLensDto,
} from "apis/oag";
import {
  DimensionType,
  FuelType,
  ResultDataState,
  StatCardDataType,
  StatCardUserLensesApi,
} from "apis/oag";
import type { GasType } from "apis/oag/models/GasType";
import type { GeneratorAndBoilerOptions } from "components/Lenses/common/GeneratorAndBoilerSelector/utils";
import type { ContainerLensProps } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { GenericStatCard } from "components/Lenses/ContainerLens/StatCard/Chart/GenericStatCard";
import { GhgEmissionsStatCard } from "components/Lenses/ContainerLens/StatCard/Chart/GhgEmissionsStatCard";
import { useStatCardFacts } from "components/Lenses/ContainerLens/StatCard/fetcher";
import {
  getGeneratorAndBoilerOptionFromLensSettings,
  getLensSettingsFromOptions,
} from "components/Lenses/ContainerLens/StatCard/utils";
import { allGenerators } from "components/Lenses/utils";
import { useLensNameByTemplateId } from "hooks/lens/useLensNameByTemplateId";
import { useLensSize } from "hooks/lens/useLensSize";
import { useLensTemplates } from "hooks/lens/useLensTemplates";
import { useCallback, useMemo } from "react";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import { useUOM, UtilDimensions } from "utils/format";

const statCardUserLensesApi = new StatCardUserLensesApi(apiConfig);
interface StatCardProps extends ContainerLensProps {
  lens: StatCardUserLensDto;
}

export function StatCardChart({
  lens,
  onLensUpdated,
  isLocked,
}: StatCardProps) {
  const [width, height] = useLensSize(lens.id);
  const lensName = useLensNameByTemplateId(lens?.lensTemplateId);
  const { data: templates } = useLensTemplates();
  const template = templates?.byId[
    lens.lensTemplateId
  ] as StatCardLensTemplateDto;
  const { data, isLoading } = useStatCardFacts(lens, template);
  const dimension = data?.dimension;
  const value = data?.value;
  const UNSTABLE_DIMENSION =
    dimension && dimension === DimensionType.CubicMetresPerSecond
      ? UtilDimensions.ALTERNATE_CubicMetresPerSecond
      : dimension ?? DimensionType.Metres;

  const uom = useUOM(UNSTABLE_DIMENSION);
  const handleLensUpdate = useMutation({
    mutationFn: (lens: StatCardUserLensDto) => {
      return statCardUserLensesApi.apiStatCardUserLensesIdPut({
        id: lens.id,
        statCardUserLensDto: lens,
      });
    },
    onSettled: (lens) => {
      if (lens && onLensUpdated) {
        onLensUpdated(lens);
      }
    },
  });

  const handleFuelUpdate = useCallback(
    async (fuelType: FuelType) => {
      Track.interact("Evergreen Dashboard - Update Fuel", {
        lensId: lens.id,
        lens: lensName,
        selectedFuel: fuelType,
      });
      if (lens.selectedBoilers.length) {
        await handleLensUpdate.mutateAsync({
          ...lens,
          selectedBoilers: [],
          selectedGenerators: allGenerators,
          selectedFuel: fuelType,
        });
      } else {
        await handleLensUpdate.mutateAsync({ ...lens, selectedFuel: fuelType });
      }
    },
    [handleLensUpdate, lensName, lens],
  );

  const handleGasUpdate = useCallback(
    async (gasType: GasType) => {
      Track.interact("Evergreen Dashboard - Update Gas", {
        lensId: lens.id,
        lens: lensName,
        selectedGas: gasType,
      });
      await handleLensUpdate.mutateAsync({ ...lens, selectedGas: gasType });
    },
    [handleLensUpdate, lensName, lens],
  );

  const handleGeneratorUpdate = useCallback(
    async (generators: GeneratorSlotType[]) => {
      await handleLensUpdate.mutateAsync({
        ...lens,
        selectedGenerators: generators,
      });
    },
    [handleLensUpdate, lens],
  );

  const lensGeneratorAndBoilerOption = useMemo(
    () =>
      getGeneratorAndBoilerOptionFromLensSettings(
        lens?.selectedGenerators,
        lens?.selectedBoilers,
      ),
    [lens?.selectedGenerators, lens?.selectedBoilers],
  );

  const handleGeneratorAndBoilerUpdate = useCallback(
    async (
      generatorAndBoilerOption: GeneratorAndBoilerOptions,
      generator?: GeneratorSlotType,
    ) => {
      const lensSettings = getLensSettingsFromOptions({
        generatorAndBoilerOption,
        selectedGenerator: generator,
        isDiesel: lens?.selectedFuel === FuelType.Diesel,
      });
      await handleLensUpdate.mutateAsync({
        ...lens,
        selectedGenerators: lensSettings.selectedGenerators,
        selectedBoilers: lensSettings.selectedBoilers,
      });
    },
    [handleLensUpdate, lens],
  );

  const { title, val, unit }: { title: string; val: string; unit?: string } =
    useMemo(() => {
      const getText = () => {
        switch (template?.dataSource) {
          case StatCardDataType.TotalEnergyConsumed:
            return "Power Consumed";
          case StatCardDataType.AveragePowerConsumptionPerSecond:
            return "Power Consumed per Day";
          case StatCardDataType.TotalActiveDuration:
            return "Operating Days";
          case StatCardDataType.FuelConsumption:
            return lens?.selectedFuel === FuelType.Diesel
              ? "Diesel Used"
              : "Nat Gas Used";
          case StatCardDataType.FuelFlow:
            return lens?.selectedFuel === FuelType.Diesel
              ? "Avg Diesel Flow"
              : "Avg Nat Gas Flow";
          case StatCardDataType.TotalEnergyCapacity:
            return "Power Available";
          case StatCardDataType.GhgEmissions:
            return "GHG Emissions";
          default:
            return "";
        }
      };
      if (!data || isLoading) {
        return {
          title: "Loading...",
          val: "...",
        };
      }
      if (data?.dataState !== ResultDataState.Valid) {
        return {
          title: getText(),
          val: "No data found",
        };
      }
      switch (template?.dataSource) {
        case StatCardDataType.TotalEnergyConsumed:
          return {
            title: getText(),
            val: uom.display(value, {
              fractionDigits: (value || 0) > 100 ? 0 : 2,
              unit: "",
            }),
            unit: uom.abbr,
          };
        case StatCardDataType.AveragePowerConsumptionPerSecond:
          return {
            title: getText(),
            val: uom.display(value, {
              factor: 24 / 1000,
              fractionDigits: (value || 0) > 100 ? 0 : 2,
              unit: "",
            }),
            unit: "kWh/Day",
          };
        case StatCardDataType.TotalActiveDuration:
          return {
            title: getText(),
            val: uom.display(value, { factor: 1 / 60 / 24, unit: "" }),
            unit: "Days",
          };
        case StatCardDataType.FuelConsumption:
          return {
            title: getText(),
            val: uom.display(value, {
              fractionDigits: (value || 0) > 100 ? 0 : 2,
              unit: "",
            }),
            unit: uom.abbr,
          };
        case StatCardDataType.FuelFlow:
          return {
            title: getText(),
            val: uom.display(value, {
              fractionDigits: (value || 0) > 100 ? 0 : 2,
              unit: "",
            }),
            unit: uom.abbr,
          };
        case StatCardDataType.TotalEnergyCapacity:
          return {
            title: getText(),
            val: uom.display(value, {
              fractionDigits: (value || 0) > 100 ? 0 : 2,
              unit: "",
            }),
            unit: (value || 0) >= 1e6 ? "MWh" : uom.abbr,
          };
        case StatCardDataType.GhgEmissions:
          return {
            title: getText(),
            val: uom.display(value, { fractionDigits: 2, unit: "" }),
            unit: `${lens?.selectedGas === "Unknown" ? "CO₂e" : ""} ${uom.abbr}`,
          };
      }

      return {
        title: "",
        val: "",
      };
    }, [
      data,
      isLoading,
      lens?.selectedFuel,
      lens?.selectedGas,
      template?.dataSource,
      uom,
      value,
    ]);

  const hasFuelTypeSelection = useMemo(() => {
    return (
      template?.dataSource !==
        StatCardDataType.AveragePowerConsumptionPerSecond &&
      template?.dataSource !== StatCardDataType.TotalActiveDuration &&
      template?.dataSource !== StatCardDataType.TotalEnergyConsumed &&
      template?.dataSource !== StatCardDataType.TotalEnergyCapacity
    );
  }, [template?.dataSource]);

  const hasGeneratorSelection = useMemo(() => {
    return (
      template?.dataSource !==
        StatCardDataType.AveragePowerConsumptionPerSecond &&
      template?.dataSource !== StatCardDataType.TotalActiveDuration &&
      template?.dataSource !== StatCardDataType.FuelFlow &&
      template?.dataSource !== StatCardDataType.FuelConsumption
    );
  }, [template?.dataSource]);

  const hasGeneratorAndBoilerSelection = useMemo(() => {
    return (
      template?.dataSource === StatCardDataType.FuelFlow ||
      template?.dataSource === StatCardDataType.FuelConsumption
    );
  }, [template?.dataSource]);

  switch (template?.dataSource) {
    case StatCardDataType.GhgEmissions:
      return (
        <GhgEmissionsStatCard
          width={width}
          height={height}
          gasType={lens?.selectedGas}
          value={val}
          unit={unit}
          isLocked={isLocked}
          handleGasUpdate={handleGasUpdate}
          hasData={data?.dataState === ResultDataState.Valid}
          isLoading={isLoading}
        />
      );

    default:
      return (
        <GenericStatCard
          width={width}
          height={height}
          fuelType={lens?.selectedFuel}
          dataSource={template?.dataSource}
          title={title}
          value={val}
          unit={unit}
          lensId={lens?.id}
          lensTemplateId={lens?.lensTemplateId}
          hasData={data?.dataState === ResultDataState.Valid}
          isLocked={isLocked}
          isLoading={isLoading}
          hasFuelTypeSelection={hasFuelTypeSelection}
          selectedGenerators={lens?.selectedGenerators}
          selectedGeneratorAndBoiler={lensGeneratorAndBoilerOption}
          hasGeneratorSelection={hasGeneratorSelection}
          hasGeneratorAndBoilerSelection={hasGeneratorAndBoilerSelection}
          handleFuelUpdate={handleFuelUpdate}
          handleGeneratorUpdate={handleGeneratorUpdate}
          handleGeneratorAndBoilerUpdate={handleGeneratorAndBoilerUpdate}
        />
      );
  }
}
