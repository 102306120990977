import { FuelType } from "apis/oag";
import GeneratorSelector from "components/Lenses/common/GeneratorSelector";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import { StyledChartContainerDiv } from "components/Lenses/ContainerLens/common/StyledComponents";
import { FuelConsumptionChart } from "components/Lenses/ContainerLens/FuelConsumptionByGenerator/Chart";
import {
  useFuelConsumptionByGeneratorFacts,
  useFuelConsumptionByGeneratorLensUpdate,
} from "components/Lenses/ContainerLens/FuelConsumptionByGenerator/common/fetcher";
import FuelSelector from "components/Lenses/ContainerLens/FuelConsumptionByGenerator/common/FuelSelector";
import {
  ChevronDownIcon as FuelChevronDownIcon,
  SelectedFuel,
} from "components/Lenses/ContainerLens/FuelConsumptionByGenerator/common/FuelSelector/styles";
import type { FuelConsumptionByGeneratorProps } from "components/Lenses/interfaces";
import { SelectedOption } from "components/PDComponents/Dropdown/style";
import { ChevronDownIcon } from "components/PDComponents/Dropdown/style";
import { useLensNameByTemplateId } from "hooks/lens/useLensNameByTemplateId";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { zIndexLayer } from "utils/zIndex";
const StyledContainer = styled(StyledChartContainerDiv)`
  ${SelectedOption} {
    position: absolute;
    top: 24px;
    right: 10px;
    z-index: ${zIndexLayer.base};
  }

  ${SelectedFuel} {
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: ${zIndexLayer.base};
  }
  :hover {
    ${ChevronDownIcon}, ${FuelChevronDownIcon} {
      opacity: 1;
    }
  }
`;
export const FuelConsumptionByGeneratorKpi: React.FC<
  FuelConsumptionByGeneratorProps
> = ({ lens, setLensDate, isLocked = false, onLensUpdated }) => {
  const { data } = useFuelConsumptionByGeneratorFacts(lens);

  useEffect(() => {
    if (data?.lastUpdatedAt && setLensDate) setLensDate(data.lastUpdatedAt);
  }, [data.lastUpdatedAt, setLensDate]);

  const { handleGeneratorUpdate, handleFuelUpdate } =
    useFuelConsumptionByGeneratorLensUpdate(lens, onLensUpdated);

  const [fuelMenuVisible, setFuelMenuVisible] = useState<boolean>(false);
  const [selectedFuel, setSelectedFuel] = useState<FuelType>(
    lens?.selectedFuel ?? FuelType.Diesel,
  );
  const lensName = useLensNameByTemplateId(lens?.lensTemplateId);
  return (
    <StyledContainer>
      <FuelSelector
        selectedFuel={selectedFuel}
        fuelMenuVisible={fuelMenuVisible}
        setFuelMenuVisible={setFuelMenuVisible}
        isLocked={isLocked}
        handleFuelUpdate={(fuel) => {
          setSelectedFuel(fuel);
          handleFuelUpdate(fuel);
        }}
      />
      <GeneratorSelector
        selectedGenerators={lens.selectedGenerators}
        handleGeneratorUpdate={handleGeneratorUpdate}
        isLocked={isLocked}
        trackingText="Evergreen Dashboard - Update Generator"
        trackingData={{
          lensId: lens.id,
          lens: lensName,
        }}
      />
      <LensLoadingContainer
        key={lens.id}
        dataState={data?.dataState}
        isDetailed={false}
        LoadedComponent={
          <FuelConsumptionChart
            lens={lens}
            detailed={false}
            selectedFuel={selectedFuel}
          />
        }
      />
    </StyledContainer>
  );
};
