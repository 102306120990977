import { ErrorBoundary } from "@sentry/react";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { ErrorPage } from "components/Clara/ErrorPage/ErrorPage";
import { LandingPageLoader } from "components/Clara/Loaders/LandingPageLoader";
import { MessagePending } from "components/Clara/MessagePending";
import { ModalBody } from "components/Clara/ModalBody";
import { ClaraContainer } from "components/Clara/style";
import { useConfirmModal } from "components/ConfirmModal";
import { PDComponent } from "components/PDComponents";
import { useClaraRequestParameters } from "hooks/clara/useClara";
import { Fragment, Suspense, useEffect, useState } from "react";
import Modal from "react-modal";
import { useAppDispatch, useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { Tooltip } from "utils/componentLibrary";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

const customStyles = {
  overlay: {
    zIndex: zIndexLayer.stratosphere,
    backgroundColor: colors.faded_bg,
  },
  content: {
    border: "none",
    top: "50%",
    left: "50%",
    overflow: "hidden",
    right: "auto",
    height: "85%",
    width: "80%",
    borderRadius: 6,
    bottom: "auto",
    padding: "0px!important",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export const ClaraModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const isSessionFetching = useIsFetching({
    queryKey: [
      { uid: RequestUID.clara, type: PDQueryType.CLARA_CREATE_SESSION },
    ],
    exact: false,
  });
  const isPromptsFetching = useIsFetching({
    queryKey: [
      { uid: RequestUID.clara, type: PDQueryType.CLARA_CONVERSATION_PROMPTS },
    ],
    exact: false,
  });

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const requestParameters = useClaraRequestParameters();

  // needed because unit of measurement changes did change that object
  const strRequestParameters = JSON.stringify(requestParameters);
  useEffect(() => {
    queryClient.setQueryData(
      [{ uid: RequestUID.clara, type: PDQueryType.CLARA_CONVERSATION_HISTORY }],
      () => [],
    );
    dispatch({
      type: "CLARA_SET_CHAT_THREAD_MODE",
      payload: false,
    });
  }, [dispatch, strRequestParameters, queryClient]);

  const isChatMode = useAppSelector(
    (state) => state.claraReducer.isChatThreadMode,
  );
  const { confirmModalElement, openConfirmModal } = useConfirmModal({
    title: "Exit current chat?",
    description:
      "Are you sure you want to leave? Your current conversation will not be saved.",
    onConfirm: () => {
      setIsOpen(false);
      dispatch({
        type: "CLARA_SET_CHAT_THREAD_MODE",
        payload: false,
      });
      requestAnimationFrame(() => {
        queryClient.removeQueries({
          queryKey: [
            { uid: RequestUID.clara, type: PDQueryType.CLARA_CREATE_SESSION },
          ],
          exact: false,
        });
      });

      queryClient.setQueryData(
        [
          {
            uid: RequestUID.clara,
            type: PDQueryType.CLARA_CONVERSATION_HISTORY,
          },
        ],
        () => [],
      );
    },
  });

  const { isDark } = useCustomTheme();

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          content: {
            ...customStyles.content,
            backgroundColor: "transparent",
            boxShadow:
              isSessionFetching || isPromptsFetching
                ? "none"
                : `0px 4px 6px 0px ${colors.black}1f`,
          },
          overlay: customStyles.overlay,
        }}
        ariaHideApp={false}
      >
        <Suspense fallback={<LandingPageLoader />}>
          <ErrorBoundary
            fallback={({ resetError }) => (
              <ErrorPage
                resetError={resetError}
                onClose={() => setIsOpen(false)}
              />
            )}
          >
            {isSessionFetching ? (
              <LandingPageLoader />
            ) : (
              <ModalBody
                onClose={() => {
                  openConfirmModal();
                }}
                onMinimize={() => setIsOpen(false)}
              />
            )}
          </ErrorBoundary>
        </Suspense>
      </Modal>
      <Tooltip title="Clara" placement="top">
        <ClaraContainer
          onClick={() => {
            setIsOpen((open) => !open);
          }}
        >
          {isChatMode ? <MessagePending /> : null}
          {isDark ? (
            <PDComponent.SvgIcon name="claraDark" id="clara" />
          ) : (
            <PDComponent.SvgIcon name="claraLight" id="clara" />
          )}
        </ClaraContainer>
      </Tooltip>

      {confirmModalElement}
    </Fragment>
  );
};
