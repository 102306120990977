import { customRandom } from "nanoid";
import seedRandom from "seedrandom";

export const getErrorHashCode = (message: string) => {
  const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const rng = seedRandom(message);
  return customRandom(alphabet, 6, (size) =>
    new Uint8Array(size).map(() => 256 * rng()),
  )();
};
