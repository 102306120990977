import {
  FormattedRow,
  FormGroupContainer,
  FormLabel,
} from "components/General/style";
import React from "react";
import { Col, Row } from "utils/componentLibrary";

export const FormGroup: React.FC<{
  label: React.ReactNode;
  rightElement?: React.ReactNode;
  hasBorder?: boolean;
  children?: React.ReactNode;
}> = ({ label, rightElement, hasBorder, children }) => {
  return (
    <FormGroupContainer>
      <FormattedRow
        justify="space-between"
        align="middle"
        $hasBorder={hasBorder}
      >
        <Col>
          {typeof label === "string" ? <FormLabel>{label}</FormLabel> : label}
        </Col>
        {rightElement ? <Col>{rightElement}</Col> : null}
      </FormattedRow>
      <Row>{children}</Row>
    </FormGroupContainer>
  );
};
