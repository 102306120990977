import { TooltipWithBounds } from "@visx/tooltip";
import {
  getParameterAxisWidth,
  getParameterNameWidth,
  getParameterStatsWidth,
} from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/utils";
import type { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { zIndexLayer } from "utils/zIndex";

export const InnerDiv = styled.div<{ detailed?: boolean; showGraph?: boolean }>`
  overflow: hidden;
  display: grid;
  grid-template-columns: ${(props) =>
      getParameterNameWidth(!!props?.detailed)}px ${(props) => {
      if (!props?.showGraph) {
        return "1fr";
      } else {
        return `${getParameterStatsWidth(!!props?.detailed)}px ${getParameterAxisWidth(!!props?.detailed)}px 1fr`;
      }
    }};
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_chart_bg};
  border-bottom: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
  height: ${(props) => (props.detailed ? "150px" : "84px")};
`;

export const ParameterLeftAxis = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
  svg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

export const IconContainer = styled.div<{ cursor?: CSSProperties["cursor"] }>`
  font-size: 20px;
  color: #8d9599;
  visibility: hidden;
  cursor: ${(props) => props.cursor || "pointer"};
  z-index: ${zIndexLayer.base};

  svg {
    transform: rotate(90deg);
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  overflow: hidden;
  gap: 8px;
`;

export const ParameterAvgKpiVal = styled.div<{
  detailed?: boolean;
  parameterNameWidth?: string;
}>`
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  height: 100%;
  width: ${(props) =>
    props.parameterNameWidth ||
    `${getParameterStatsWidth(!!props?.detailed)}px`};
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid rgba(0, 0, 0, 0.06);

  span {
    font-size: ${(props) => (props.detailed ? "34px" : "24px")};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
  }
`;

export const ParameterComparisonGrid = styled.div<{ rowCount: number }>`
  display: grid;
  grid-template-rows: repeat(${(props) => props.rowCount}, 1fr);
  row-gap: 8px;
`;

export const ParameterComparisonGridItem = styled.div<{
  isFocalWell?: boolean;
}>`
  display: flex;
  column-gap: 5px;
  & > div {
    height: 16px;
  }
  &,
  & > span {
    font-size: 16px;
    line-height: 1;
  }
  font-weight: ${(props) => (props.isFocalWell ? 500 : "normal")};
  font-stretch: normal;
  font-style: normal;

  letter-spacing: -0.2px;
  color: ${({ isFocalWell, theme }) =>
    isFocalWell
      ? theme.themeStyle.colors.alt_typography
      : theme.themeStyle.colors.disabled_typography};
`;

export const BottomRowContainer = styled.div<{ $columnWidth?: number }>`
  background: ${({ theme }) => theme.themeStyle.colors.tertiary_chart_bg};
  display: grid;
  grid-template-columns: ${({ $columnWidth }) => $columnWidth}px 1fr;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
`;

export const LensContainer = styled.div<{
  rows?: number;
  detailed?: boolean;
  variant: "large" | "regular";
}>`
  height: calc(100% - ${(props) => (props.detailed ? 160 : 80)}px);
  display: grid;
  grid-template-rows: repeat(${(props) => props.rows || 1}, 1fr);
  position: relative;
  overflow-y: ${(props) => (props.detailed ? "auto" : "scroll")};
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }

  ${BottomRowContainer} {
    &,
    & > div {
      &:first-child {
        border-right: 1px solid rgba(0, 0, 0, 0.06);
      }
    }
  }
  ${(props) =>
    props.variant === "large" &&
    `
      ${ParameterComparisonGrid}{
        row-gap: 6px;
      }
      
      ${ParameterComparisonGridItem} {
          font-size:13px;
          & > div {
            height: 10px;
          }

          & > span {
            font-size: 16px;
            line-height: 10px;
            svg {
              width: 10px;
              height: 10px;
              transform: scale(1.5);
            }
          }
      }
    `}
`;

export const ChartContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  user-select: none;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const TooltipBoundsContainer = styled(TooltipWithBounds)`
  background-color: ${({ theme }) =>
    theme.themeStyle.colors.quaterniary_chart_bg};
  border-radius: 6px;
  padding: 10px 16px;
  width: fit-content;
  color: white;
  font-size: 12px;
  pointer-events: none;
  user-select: none;
  z-index: ${zIndexLayer.scraper};
`;

export const TooltipContainer = styled(TooltipBoundsContainer)`
  position: absolute;
  translate: 0 -50% !important;
  top: 50% !important;
`;

export const TooltipEntry = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 6px;
  }
  & > *:last-child {
    margin-right: 0;
    margin-left: auto;
  }

  & + & {
    margin-top: 10px;
  }
`;

export const TooltipExtra = styled.div`
  display: flex;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  border-top: 1px solid rgba(130, 140, 145, 0.1);
  padding-top: 10px;
  margin-top: 10px;

  & > * {
    margin-right: 12px;
  }

  & > *:last-child {
    margin-right: 0;
    margin-left: auto;
  }
`;

export const TooltipWellColor = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  background: ${(props) => props.color};
  border-radius: 2px;
`;

export const KPIText = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  overflow: hidden;
  max-width: 60px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const UOMText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  white-space: nowrap;
`;

export const ParameterName = styled.div<{
  detailed?: boolean;
  parameterNameWidth?: string;
}>`
  display: grid;
  grid-template-rows: ${(props) => (props.detailed ? "64px 1fr 64px" : "1fr")};
  place-items: center;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_chart_bg};
  width: ${(props) =>
    props.parameterNameWidth || `${getParameterNameWidth(!!props.detailed)}px`};
  max-width: ${(props) => getParameterNameWidth(!!props.detailed)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.06);

  & > * {
    transform: rotate(-90deg);
  }

  &:hover {
    ${IconContainer} {
      visibility: visible;
    }

    ${KPIText} {
      display: none;
    }

    ${UOMText} {
      margin-left: 0;
    }
  }
  ${(props) =>
    props.detailed
      ? css`
          &:hover {
            background-color: ${({ theme }) =>
              theme.themeStyle.colors.primary_chart_bg};
          }
        `
      : css`
          gap: 0px;
          display: flex;
          justify-content: center;
          flex-direction: column-reverse;
          &:hover {
            background-color: ${({ theme }) =>
              theme.themeStyle.colors.primary_chart_bg};
            gap: 5px;
          }
        `}
`;
