import { useMutation, useQueryClient } from "@tanstack/react-query";
import type {
  ExceptionRequestNotificationDto,
  ScorecardCommentNotificationDto,
} from "apis/oag";
import { NotificationsApi } from "apis/oag";
import type { NotificationResultSetDto } from "apis/oag/models/NotificationResultSetDto";
import { CustomSwitch } from "atoms/common";
import { Title } from "atoms/Typography";
import { EmptyNotificationsList } from "components/General/EmptyNotificationsList/EmptyNotificationsList";
import { NotificationList } from "components/Header/RightContent/Notifications/NotificationsPopover/NotificationList/NotificationList";
import * as Styled from "components/Header/RightContent/Notifications/NotificationsPopover/style";
import { PDComponent } from "components/PDComponents";
import { CheckboxCheckedState } from "components/PDComponents/Checkbox/Checkbox";
import { ChevronDownIcon } from "components/PDComponents/Dropdown/style";
import {
  ActiveNotificationsQueryKey,
  useActiveNotifications,
} from "hooks/notifications/useNotifications";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { PDRoutesMapping } from "routes";
import { apiConfig } from "utils/apiConfig";
import { READ_UNREAD_LABEL } from "utils/notifications/constants";
import { ActiveNotificationsSelector } from "utils/notifications/mappers";

enum NotificationTabs {
  All = "All",
  ScorecardComments = "Scorecard Comments",
  Exceptions = "Exceptions",
}

const notificationStatusApi = new NotificationsApi(apiConfig);

export function isVisible(
  notification:
    | ExceptionRequestNotificationDto
    | ScorecardCommentNotificationDto,
) {
  return !notification.isArchived;
}

export function isUnread(
  notification:
    | ExceptionRequestNotificationDto
    | ScorecardCommentNotificationDto,
) {
  return !notification.isRead;
}

export const NotificationsPopover = ({
  onPopoverClose,
}: {
  onPopoverClose: () => void;
}) => {
  const [isUnreadOnly, setIsUnreadOnly] = useState(false);
  const [selectedTab, setSelectedTab] = useState<NotificationTabs>(
    NotificationTabs.All,
  );

  const { data: initialNotifications, isLoading } = useActiveNotifications({
    select: ActiveNotificationsSelector,
  });

  const [notifications, setNotifications] = useState(initialNotifications);
  useEffect(() => {
    // update when new data comes from the backend
    setNotifications(initialNotifications);
  }, [initialNotifications]);

  const queryClient = useQueryClient();

  const tabNotifications = useMemo(() => {
    if (notifications) {
      const all = notifications.getNotificationList?.() ?? [];
      switch (selectedTab) {
        case NotificationTabs.All:
          return all;
        case NotificationTabs.ScorecardComments:
          return notifications.scorecardCommentNotifications;
        case NotificationTabs.Exceptions:
          return notifications.exceptionRequestNotifications;
        default:
          return [];
      }
    }
    return [];
  }, [notifications, selectedTab]);

  const getCount = useCallback(
    (tab: NotificationTabs) => {
      if (isLoading) {
        return 0;
      }
      const comments = notifications.getUnreadUnarchivedCount(
        notifications.scorecardCommentNotifications,
      );
      const exceptions = notifications.getUnreadUnarchivedCount(
        notifications.exceptionRequestNotifications,
      );

      switch (tab) {
        case NotificationTabs.All:
          return comments + exceptions;
        case NotificationTabs.ScorecardComments:
          return comments;
        case NotificationTabs.Exceptions:
          return exceptions;
        default:
          return 0;
      }
    },
    [isLoading, notifications],
  );

  const visibleNotifications = useMemo(
    () =>
      isUnreadOnly
        ? tabNotifications.filter(isUnread)
        : tabNotifications.filter(isVisible),
    [isUnreadOnly, tabNotifications],
  );

  const [selectedIdsList, setSelectedIdsList] = useState<number[]>([]);
  const isEditMode = selectedIdsList.length > 0;

  const handleOnSelectAll = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        setSelectedIdsList(visibleNotifications.map((n) => n.id));
      } else {
        setSelectedIdsList([]);
      }
    },
    [visibleNotifications],
  );

  const handleOnTabSelect = useCallback((tab: NotificationTabs) => {
    setSelectedTab(tab);
    setSelectedIdsList([]);
  }, []);

  const selectAllStatus = useMemo(() => {
    if (selectedIdsList.length === visibleNotifications.length) {
      return CheckboxCheckedState.Checked;
    } else if (selectedIdsList.length > 0) {
      return CheckboxCheckedState.Indeterminate;
    } else return CheckboxCheckedState.Unchecked;
  }, [selectedIdsList.length, visibleNotifications.length]);

  const updateNotificationStatus = useMutation({
    mutationFn: ({
      initialNotifications,
      notifications,
    }: {
      initialNotifications: NotificationResultSetDto;
      notifications: NotificationResultSetDto;
    }) => {
      const statusMap: Record<"Archived" | "Read" | "Unread", Array<number>> = {
        Archived: [],
        Read: [],
        Unread: [],
      };

      queryClient.setQueryData(
        [ActiveNotificationsQueryKey],
        () => notifications,
      );

      initialNotifications?.exceptionRequestNotifications.forEach((n) => {
        const local = notifications.exceptionRequestNotifications.find(
          (l) => l.id === n.id,
        );
        if (local?.isArchived && !n.isArchived) {
          statusMap.Archived.push(n.id);
        }
        if (local?.isRead && !n.isRead) {
          statusMap.Read.push(n.id);
        }
        if (!local?.isRead && n.isRead) {
          statusMap.Unread.push(n.id);
        }
      });

      initialNotifications?.scorecardCommentNotifications.forEach((n) => {
        const local = notifications.scorecardCommentNotifications.find(
          (l) => l.id === n.id,
        );
        if (local?.isArchived && !n.isArchived) {
          statusMap.Archived.push(n.id);
        }
        if (local?.isRead && !n.isRead) {
          statusMap.Read.push(n.id);
        }
        if (!local?.isRead && n.isRead) {
          statusMap.Unread.push(n.id);
        }
      });

      const calls = [];
      if (statusMap.Read.length > 0) {
        calls.push(
          notificationStatusApi.apiNotificationsMarkAsReadPut({
            notificationStatusUpdateRequestDto: {
              notificationIds: statusMap.Read,
            },
          }),
        );
      }
      if (statusMap.Archived.length > 0) {
        calls.push(
          notificationStatusApi.apiNotificationsMarkAsArchivedPut({
            notificationStatusUpdateRequestDto: {
              notificationIds: statusMap.Archived,
            },
          }),
        );
      }

      if (statusMap.Unread.length > 0) {
        calls.push(
          notificationStatusApi.apiNotificationsMarkAsUnreadPut({
            notificationStatusUpdateRequestDto: {
              notificationIds: statusMap.Unread,
            },
          }),
        );
      }

      const update = Promise.all(calls);

      return update;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [ActiveNotificationsQueryKey],
      });
    },
  });

  const handleOnUnreadToggle = useCallback(() => {
    setIsUnreadOnly((prev) => !prev);
    setSelectedIdsList([]);
  }, []);

  const selectionReadAction = useMemo(() => {
    return selectedIdsList.every(
      (id) => visibleNotifications.find((n) => n.id === id)?.isRead,
    )
      ? "Unread"
      : "Read";
  }, [selectedIdsList, visibleNotifications]);

  const handleOnMarkAsReadSelection = useCallback(() => {
    const newNotifications = {
      ...notifications,
      exceptionRequestNotifications:
        notifications.exceptionRequestNotifications.map((n) =>
          selectedIdsList.includes(n.id)
            ? { ...n, isRead: selectionReadAction === "Read" }
            : n,
        ),
      scorecardCommentNotifications:
        notifications.scorecardCommentNotifications.map((n) =>
          selectedIdsList.includes(n.id)
            ? { ...n, isRead: selectionReadAction === "Read" }
            : n,
        ),
    };

    setNotifications(newNotifications);
    updateNotificationStatus.mutate({
      initialNotifications,
      notifications: newNotifications,
    });
  }, [
    initialNotifications,
    notifications,
    selectedIdsList,
    selectionReadAction,
    updateNotificationStatus,
  ]);

  const handleOnArchiveSelection = useCallback(() => {
    const newNotifications = {
      ...notifications,
      exceptionRequestNotifications:
        notifications.exceptionRequestNotifications.map((n) =>
          selectedIdsList.includes(n.id) ? { ...n, isArchived: true } : n,
        ),
      scorecardCommentNotifications:
        notifications.scorecardCommentNotifications.map((n) =>
          selectedIdsList.includes(n.id) ? { ...n, isArchived: true } : n,
        ),
    };
    setSelectedIdsList([]);
    setNotifications(newNotifications);
    updateNotificationStatus.mutate({
      initialNotifications,
      notifications: newNotifications,
    });
  }, [
    initialNotifications,
    notifications,
    selectedIdsList,
    updateNotificationStatus,
  ]);

  const handleOnToggleMarkAsRead = useCallback(
    (id: number) => {
      const newNotifications = {
        ...notifications,
        exceptionRequestNotifications:
          notifications.exceptionRequestNotifications.map((n) =>
            n.id === id ? { ...n, isRead: !n.isRead } : n,
          ),
        scorecardCommentNotifications:
          notifications.scorecardCommentNotifications.map((n) =>
            n.id === id ? { ...n, isRead: !n.isRead } : n,
          ),
      };

      setNotifications(newNotifications);
      updateNotificationStatus.mutate({
        initialNotifications,
        notifications: newNotifications,
      });
    },
    [initialNotifications, notifications, updateNotificationStatus],
  );

  const handleOnMarkAsArchived = useCallback(
    (id: number) => {
      const newNotifications = {
        ...notifications,
        exceptionRequestNotifications:
          notifications.exceptionRequestNotifications.map((n) =>
            n.id === id ? { ...n, isArchived: true } : n,
          ),
        scorecardCommentNotifications:
          notifications.scorecardCommentNotifications.map((n) =>
            n.id === id ? { ...n, isArchived: true } : n,
          ),
      };

      setNotifications(newNotifications);
      updateNotificationStatus.mutate({
        initialNotifications,
        notifications: newNotifications,
      });
    },
    [initialNotifications, notifications, updateNotificationStatus],
  );

  return (
    <Styled.Container>
      <Suspense fallback={<div>Loading...</div>}>
        <Styled.StyledRow>
          <Styled.CustomCol span={14}>
            <h3> Notifications </h3>
            <Link
              to={PDRoutesMapping.private.notificationSettings.path}
              onClick={onPopoverClose}
            >
              <Styled.StyledGear />
            </Link>
          </Styled.CustomCol>

          <Styled.CustomCol span={7}>
            <CustomSwitch
              disabled={isLoading}
              onChange={handleOnUnreadToggle}
            />
            <Styled.UnreadText $isActive={isUnreadOnly}>
              Unread Only
            </Styled.UnreadText>
          </Styled.CustomCol>

          <Styled.CustomCol span={3}>
            <Styled.CloseButton
              icon={<PDComponent.SvgIcon name="close" />}
              type="ghost"
              onClick={onPopoverClose}
            />
          </Styled.CustomCol>
        </Styled.StyledRow>
        <Styled.TabBar>
          {Object.entries(NotificationTabs).map(([key, value]) => (
            <Styled.StyledTab
              key={key}
              $isActive={selectedTab === value}
              onClick={() => handleOnTabSelect(value)}
            >
              {value}
              <Styled.StyledTabCounter>
                {getCount(value)}
              </Styled.StyledTabCounter>
            </Styled.StyledTab>
          ))}
        </Styled.TabBar>
        <Styled.ListContainer>
          {visibleNotifications?.length > 0 ? (
            <NotificationList
              notifications={visibleNotifications}
              selectedIdsList={selectedIdsList}
              setSelectedIdsList={setSelectedIdsList}
              handleOnMarkAsArchived={handleOnMarkAsArchived}
              handleOnToggleMarkAsRead={handleOnToggleMarkAsRead}
              key={selectedTab}
            />
          ) : (
            <EmptyNotificationsList />
          )}
        </Styled.ListContainer>
        <Styled.Footer>
          {isEditMode ? (
            <>
              <Styled.SelectContainer>
                <PDComponent.Checkbox
                  id="select-all-footer-notifications"
                  onChange={handleOnSelectAll}
                  checkedState={selectAllStatus}
                  labelId="select-all-footer-notifications"
                />
                <label htmlFor="select-all-footer-notifications">
                  {selectAllStatus === CheckboxCheckedState.Checked
                    ? "Deselect all"
                    : "Select all"}
                </label>
              </Styled.SelectContainer>

              <Styled.Controls>
                <Title
                  level={4}
                  weight={500}
                  onClick={handleOnMarkAsReadSelection}
                >
                  {READ_UNREAD_LABEL}
                </Title>
                <Title
                  level={4}
                  weight={500}
                  onClick={handleOnArchiveSelection}
                >
                  Archive
                </Title>
              </Styled.Controls>
            </>
          ) : (
            <Link to={"notifications/all"}>
              <Styled.SeeAllText>
                See all notifications <ChevronDownIcon />
                <PDComponent.SvgIcon name="reply" />
              </Styled.SeeAllText>
            </Link>
          )}
        </Styled.Footer>
      </Suspense>
    </Styled.Container>
  );
};
