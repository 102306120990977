import { useIsAuthenticated } from "@azure/msal-react";
import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { useCurrentUserCanAccessIntel } from "hooks/user/useCurrentUser";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { IndicatorsState } from "reducers/stateReducer";
import type { AppDispatch } from "reducers/store";
import { useAppDispatch } from "reducers/store";
import { initialFilters } from "reducers/types";
import { routePaths } from "routePaths";
import { PDRoutesMapping } from "routes";
import styled from "styled-components";
import colors from "utils/colors";
import { Space } from "utils/componentLibrary";
import { stripSlashes } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

const StyledTitle = styled(Title)`
  color: white !important;
  cursor: pointer;

  .pd-icon {
    pointer-events: none;
  }
`;

const Tabs = styled(Title)<{ $selected?: string }>`
  margin-left: 7px;
  color: ${(props) =>
    props.$selected === "true"
      ? colors.white
      : props.theme.themeStyle.colors.disabled_typography}!important;
  position: relative;
  display: flex;
  gap: 5px;
  ::after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    background-color: ${colors.well_color};
    height: 0px;
    ${(props) =>
      props.$selected === "true"
        ? `
          height: 2.8px;
        `
        : ``}
  }
`;

enum urlDashboardRoutes {
  "WELL" = "/",
  "RIG" = "/rig",
  "EVERGREEN" = "/evergreen",
  "INTEL" = "/intel",
}

const LeftContent = ({ renderReady }: { renderReady: boolean }) => {
  const location = useLocation();
  const params = useParams<{
    dashboardType: string;
    dashboardId: string;
    rigId: string;
    accountId: string;
    wellId: string;
    lensId: string;
    breadcrumbs: string;
  }>();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { data: rigs } = useRigs();
  const wellData = useWellShortInfoSuspended({
    enabled: isAuthenticated && renderReady,
  });
  const _dispatch = useAppDispatch();
  const dispatch = useCallback(
    (action: ReturnType<AppDispatch>) =>
      requestAnimationFrame(() => _dispatch(action)),
    [_dispatch],
  );
  const {
    isRootDashboardPage,
    isRig: isRigDashboard,
    isWell: isWellDashboard,
  } = useDashboardType();

  const canGoBack = useMemo(() => {
    if (!isRootDashboardPage)
      dispatch({
        type: "RESET_AVAILABLE_FILTERS",
      });

    if (
      location.pathname === "/" ||
      stripSlashes(location.pathname) === stripSlashes(routePaths.evergreen)
    ) {
      dispatch({
        type: "SET_REPORT_FILTERS",
        payload: {
          filters: initialFilters,
          state: false,
        },
      });
      dispatch({
        type: "SET_SELECTED_INDICATORS",
        payload: new Set([
          IndicatorsState["Directional Intervals"],
          IndicatorsState["Hole Sections"],
        ]),
      });
      dispatch({
        type: "SET_REPORT_OFFSET_WELLS",
        payload: { wells: [] },
      });
      dispatch({
        type: "RESET_ZOOM_WIDGET",
      });
      dispatch({
        type: "RESET_REPORT_ZOOM",
      });
      return false;
    }
    return true;
  }, [dispatch, isRootDashboardPage, location.pathname]);

  const showName = useMemo(() => {
    if ("lensId" in params && "dashboardId" in params) {
      return true;
    }
    if (location.pathname.includes("report")) {
      return true;
    }
    return null;
  }, [location.pathname, params]);

  const getName = useMemo(() => {
    if (isRigDashboard) {
      const rig = rigs?.list.find(
        (r) => r.id === +(params.dashboardId || "-1"),
      );
      return rig?.shortName || "";
    } else if (isWellDashboard) {
      return !wellData.isLoading
        ? wellData.data?.byId[Number(params.dashboardId || -1)]?.name
        : "";
    }
  }, [
    isRigDashboard,
    isWellDashboard,
    params.dashboardId,
    rigs?.list,
    wellData.data?.byId,
    wellData.isLoading,
  ]);

  const isBackAllowed = useMemo(() => {
    if (
      [
        urlDashboardRoutes.EVERGREEN,
        urlDashboardRoutes.RIG,
        urlDashboardRoutes.WELL,
      ].includes(location.pathname as urlDashboardRoutes)
    ) {
      return false;
    } else return true;
  }, [location.pathname]);

  const plannedBackUrl = useMemo(() => {
    // These are hardcoded circuits, should find a better way to concatenate strings
    if (canGoBack) {
      const spots = location.pathname.split("/");
      // TODO spots length is too big we need to do a filter(e => e) on it
      // but that will require testing of the routing in the whole app, so for now we just use what we have
      if (location?.pathname?.includes("system")) {
        return `/rig/scorecard/${params?.rigId}${(location?.search ?? "?") !== "?" ? location.search : ""}`;
      }
      if (spots[1] === "rig" && spots[2] === "scorecard") {
        // If coming back from a scorecard, we need to go back to the rig metrics page
        return `/rig/leaderboard`;
      }
      if (params?.breadcrumbs) {
        if (params.breadcrumbs === "management") return "/admin/users";
        return `/admin/accounts/${params.accountId}/users`;
      }
      if (spots[1] === "export") {
        if (spots[3] !== "wellDashboard")
          return `/well/${params.wellId}/${params.lensId}${(location?.search ?? "?") !== "?" ? location.search : ""}`;
        else
          return `/well/${params.wellId}${(location?.search ?? "?") !== "?" ? location.search : ""}`;
      }
      if (spots[1] === "admin") {
        if (spots.length > 3) {
          return `/admin/${spots[2]}`;
        } else {
          return "/";
        }
      } else if (spots[1] === "account" && spots[2] === "me") {
        return "/";
      } else if (params.dashboardType) {
        return `/${params.dashboardType}/${params.dashboardId}${
          (location?.search ?? "?") !== "?" ? location.search : ""
        }`;
      } else if (spots[1] === "well") {
        if (spots.length === 3) {
          return "/";
        }
        return `/well/${spots[2]}${(location?.search ?? "?") !== "?" ? location.search : ""}`;
      } else if (spots[1] === "evergreen") {
        if (spots.length === 3) {
          return "/";
        }
        return `/evergreen/${spots[2]}${(location?.search ?? "?") !== "?" ? location.search : ""}`;
      } else if (spots[1] === "rig") {
        if (spots.length === 3) {
          return "/rig";
        }
        return "/";
      } else if (
        location.pathname.includes(
          PDRoutesMapping.private.notificationSettings.path,
        )
      ) {
        return -1;
      }
    }
    return "/";
  }, [
    canGoBack,
    location.pathname,
    location.search,
    params.breadcrumbs,
    params.dashboardType,
    params.rigId,
    params.accountId,
    params.wellId,
    params.lensId,
    params.dashboardId,
  ]);

  const { atomThemeVariant } = useCustomTheme();
  const canAccessIntel = useCurrentUserCanAccessIntel();
  return (
    <Space style={{ height: "64px" }}>
      <div
        onClick={() => {
          if (isBackAllowed) {
            navigate(plannedBackUrl as string);
          } else {
            navigate(urlDashboardRoutes.WELL, { replace: true });
            dispatch({
              type: "RESET_ALL_WELLS",
            });
          }
        }}
      >
        <div aria-hidden="true">
          <StyledTitle level={2} variant="dark">
            {plannedBackUrl !== "/" ? (
              <PDComponent.SvgIcon name="arrowLeft" />
            ) : (
              <PDComponent.SvgIcon name="clarityLogo" />
            )}
          </StyledTitle>
        </div>
      </div>
      <Title level={3} variant="white">
        {location.pathname.split("/")[1] === "export"
          ? "Create a report"
          : showName && getName}
      </Title>
      <Link to={urlDashboardRoutes.WELL}>
        <Tabs
          $selected={
            location.pathname === urlDashboardRoutes.WELL.toString() ||
            location.pathname.includes("/well")
              ? "true"
              : "false"
          }
          level={3}
          variant={atomThemeVariant}
        >
          Wells
        </Tabs>
      </Link>
      <Link to={urlDashboardRoutes.RIG}>
        <Tabs
          $selected={
            location.pathname.includes(urlDashboardRoutes.RIG)
              ? "true"
              : "false"
          }
          level={3}
          variant={atomThemeVariant}
        >
          Rigs
        </Tabs>
      </Link>
      <Link to={urlDashboardRoutes.EVERGREEN}>
        <Tabs
          $selected={
            location.pathname.includes(urlDashboardRoutes.EVERGREEN)
              ? "true"
              : "false"
          }
          level={3}
          variant={atomThemeVariant}
        >
          EverGreen
        </Tabs>
      </Link>
      {canAccessIntel ? (
        <Link to={urlDashboardRoutes.INTEL}>
          <Tabs
            $selected={
              location.pathname.includes(urlDashboardRoutes.INTEL)
                ? "true"
                : "false"
            }
            level={3}
            variant={atomThemeVariant}
          >
            Intel
          </Tabs>
        </Link>
      ) : null}
    </Space>
  );
};

export default LeftContent;
