/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntelWellInfoDto
 */
export interface IntelWellInfoDto {
    /**
     * 
     * @type {string}
     * @memberof IntelWellInfoDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof IntelWellInfoDto
     */
    longitude: number;
    /**
     * 
     * @type {number}
     * @memberof IntelWellInfoDto
     */
    latitude: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntelWellInfoDto
     */
    groupingKeys: Array<string>;
}

/**
 * Check if a given object implements the IntelWellInfoDto interface.
 */
export function instanceOfIntelWellInfoDto(value: object): value is IntelWellInfoDto {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('longitude' in value) || value['longitude'] === undefined) return false;
    if (!('latitude' in value) || value['latitude'] === undefined) return false;
    if (!('groupingKeys' in value) || value['groupingKeys'] === undefined) return false;
    return true;
}

export function IntelWellInfoDtoFromJSON(json: any): IntelWellInfoDto {
    return IntelWellInfoDtoFromJSONTyped(json, false);
}

export function IntelWellInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelWellInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['Name'],
        'longitude': json['Longitude'],
        'latitude': json['Latitude'],
        'groupingKeys': json['GroupingKeys'],
    };
}

export function IntelWellInfoDtoToJSON(value?: IntelWellInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Name': value['name'],
        'Longitude': value['longitude'],
        'Latitude': value['latitude'],
        'GroupingKeys': value['groupingKeys'],
    };
}

