import { Title as AtomTitle } from "atoms/Typography";
import ReactModal from "react-modal";
import styled from "styled-components";
import colors from "utils/colors";
import { zIndexLayer } from "utils/zIndex";

export const DEFAULT_MODAL_WIDTH = 448;
export const MODAL_TRANSITION_TIMING = 250;

export const defaultModalStyles = {
  overlay: {
    zIndex: zIndexLayer.jupiter,
    backgroundColor: colors.black_bg_transparent,
  },
};

export const ModalContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
`;

export const Modal = styled(ReactModal)<{
  $width?: number;
  $centered: boolean;
}>`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  border-radius: 12px;
  width: ${({ $width }) => $width ?? DEFAULT_MODAL_WIDTH}px;
  height: auto;
  background-color: transparent;
  overflow: hidden;
  border: none;
  position: absolute;
  left: 50%;
  top: ${({ $centered }) => ($centered ? "50%" : "100px")};
  transform: ${({ $centered }) =>
    $centered ? "translate(-50%, -50%)" : "translate(-50%, 0)"};

  .ReactModal__Overlay & ${ModalContainer} {
    transform: scale(0);
    transform-origin: 50% 50%;
    transition: transform ${MODAL_TRANSITION_TIMING}ms ease-in-out;
  }

  .ReactModal__Overlay--after-open & ${ModalContainer} {
    transform: scale(1);
  }

  .ReactModal__Overlay--before-close & ${ModalContainer} {
    transform: scale(0);
  }

  .ant-typography {
    color: ${colors.gray};
    line-height: 1.25;
  }
`;

export const CustomModal = styled(Modal)`
  top: calc(50% - 250px);
`;

export const ModalContent = styled.div`
  padding: 0px 24px 26px 24px;
  color: ${colors.gray};
`;

export const ModalHeader = styled.div`
  padding: 20px 24px 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const ModalFooter = styled.div`
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};
`;

export const Title = styled(AtomTitle).attrs({ level: 2, weight: 500 })`
  font-size: 24px;
`;

export const CloseIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 10px;
  color: ${({ theme }) => theme.themeStyle.colors.faint_typography};
  font-size: 24px;
  cursor: pointer;
  z-index: ${zIndexLayer.base};
`;
