import { TorqueAndDragType } from "apis/oag";
import {
  GRID_WIDTH_HALF,
  GRID_WIDTH_QUARTER,
  GRID_WIDTH_THREE_QUARTERS,
} from "components/Lenses/constants";
import { useMemo } from "react";

export const LENS_TITLE = "Torque and Drag";

// TD main
export const LEGEND_WIDTH = 174;
export const HORIZONTAL_AXIS_HEIGHT = 36;
export const LEFT_AXIS_WIDTH = 88;
export const MINI_VIEW_AXIS = 40;
export const Y_AXIS_RANGE_PADDING = 14;

// Inspection
export const MODAL_WIDTH_PX = 959;
export const MODAL_HEIGHT_PX = 246;
export const X_AXIS_HEIGHT = 36;
export const CHART_PADDING_TOP = 36;
export const CHART_PADDING_BOTTOM = 12;
export const STATUS_BAR_HEIGHT = 24;
export const Y_AXIS_WIDTH = 52;
export const CHART_CONTAINER_X = 795;
export const GRAPH_CONTAINER_HEIGHT_PX =
  MODAL_HEIGHT_PX -
  X_AXIS_HEIGHT -
  STATUS_BAR_HEIGHT -
  CHART_PADDING_TOP -
  CHART_PADDING_BOTTOM;
export const CONTROLS_CONTAINER_WIDTH = 120;

// Palette & Others

export const activityLineColors = {
  // TODO add colors to theme
  "Pick up": "#3eaf9c",
  ROB: "#c0b1a2",
  "Slack off": "#e5aecb",
  OPH: "#3eaf9c",
};

export const activityCircleColors = {
  WashOut: "#3eaf9c",
  ReamUp: "#0b6e57",
  PickUp: "#5f9bb8",
  RotateOffBottom: "#c0b1a2",
  SlackOff: "#f5c3c6",
  WashIn: "#D399CF",
  ReamDown: "#9e83e1",
};

export const toNormalCase = (text: string) => {
  if (text === "RotateOffBottom") return "ROB";
  return (text ?? "")
    .split(/([A-Z][a-z]+)/)
    .filter(Boolean)
    .join(" ");
};

export const getFractionDigitCount = (val: number) => (val < 1000 ? 2 : 0);
export const kpiToLabel = (kpiType: TorqueAndDragType) =>
  kpiType === TorqueAndDragType.Drag ? "Hookload" : "Torque";

export const useNumValueTicks = (containerWidth: number) => {
  return useMemo(() => {
    if (containerWidth <= GRID_WIDTH_QUARTER) {
      return 3;
    }
    if (containerWidth <= GRID_WIDTH_HALF) {
      return 5;
    }
    if (containerWidth <= GRID_WIDTH_THREE_QUARTERS) {
      return 11;
    }
    return 20;
  }, [containerWidth]);
};
