import styled from "styled-components";

export const PopupContainer = styled.div`
  width: 1112px;
  height: 600px;
  display: flex;
  padding-bottom: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  background: ${({ theme }) => theme.themeStyle.colors.primary_bg};
`;

export const PopupHeader = styled.div`
  display: flex;
  padding: 8px 24px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  background: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
`;

export const Grid = styled.div`
  display: grid;
  gap: 8px;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  background: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  padding: 0 12px;
`;
