import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import type {
  OperatorClaimDto,
  UserClaimDto,
  UserRigAccessDto,
} from "apis/oag";
import { ClaimPermissionType, ClaimType } from "apis/oag";
import { Title } from "atoms/Typography";
import { StyledCheckbox } from "components/WellAccess/Checkbox";
import {
  StyledCollapse,
  StyledDivider,
  StyledWellContainerSpace,
} from "components/WellAccess/style";
import type { TSingleRigAccess } from "components/WellAccess/types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "utils/componentLibrary";
import { CheckboxState } from "utils/enums";
import { useCustomTheme } from "utils/useTheme";

export const WellAccessSingle = ({
  rigAccess,
  rigName,
  selector,
  claimCallback,
  mode,
  viewOnly,
  deselectBack,
  commonFuture,
  setCanUpdate,
}: {
  rigAccess: UserRigAccessDto;
  rigName: string;
  selector: number;
  claimCallback: (x: any, y: any) => void;
  mode: "user" | "operator";
  viewOnly: boolean;
  deselectBack: (x: (y: boolean) => void) => void;
  commonFuture: boolean;
  setCanUpdate?: React.Dispatch<boolean>;
}) => {
  const claims = useRef<UserClaimDto[] | OperatorClaimDto[]>([]);

  const [commonCheckbox, setCommonCheckbox] = useState(CheckboxState.None);

  const { atomThemeVariant } = useCustomTheme();
  useEffect(() => {
    deselectBack((target: boolean) => {
      onCheckAllChange({
        target: {
          checked: target,
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deselectBack]);

  const [fields, setFields] = useState<TSingleRigAccess>({
    wells: {},
    rig: { future: false },
  });

  const onValuesChange = useCallback(
    (fields: TSingleRigAccess) => {
      setCanUpdate?.(true);
      setFields(fields);
      // @ts-ignore
      claims.current = Object.keys(fields.wells ?? {}).map((e) => ({
        [mode === "user" ? "userId" : "operatorId"]: selector,
        wellId: Number(e),
        rigId: rigAccess.rigId,
        type: ClaimType.Well,
        permission:
          fields.wells[e] === true
            ? ClaimPermissionType.All
            : ClaimPermissionType.None,
      }));

      if (rigAccess.futureWellAccess !== undefined)
        // @ts-ignore
        claims.current.push({
          [mode === "user" ? "userId" : "operatorId"]: selector,
          rigId: rigAccess.rigId,
          type: ClaimType.FutureWellAccess,
          permission:
            (fields.rig?.future || false) === false
              ? ClaimPermissionType.None
              : ClaimPermissionType.All,
        });
      claimCallback(rigAccess.rigId, claims.current);
      if (fields.wells) {
        const currentList: Array<boolean> = Array.from(
          new Set(Object.values(fields.wells)),
        );
        if (currentList.length === 1) {
          if (
            rigAccess.futureWellAccess === undefined ||
            fields.rig.future === currentList[0]
          ) {
            setCommonCheckbox(
              currentList[0] ? CheckboxState.Selected : CheckboxState.None,
            );
            return;
          }
          setCommonCheckbox(CheckboxState.Partially);
        } else {
          setCommonCheckbox(CheckboxState.Partially);
        }
      } else {
        setCommonCheckbox(
          rigAccess.futureWellAccess !== undefined && fields.rig?.future
            ? CheckboxState.Selected
            : CheckboxState.None,
        );
      }
    },
    [
      claimCallback,
      mode,
      rigAccess.rigId,
      selector,
      setCanUpdate,
      rigAccess.futureWellAccess,
    ],
  );

  const onCheckAllChange = (f: Partial<CheckboxChangeEvent>) => {
    const newFieldsValue = {
      wells: (rigAccess.wells ?? []).reduce<{ [key: string]: boolean }>(
        (prev, curr) => ({
          ...prev,
          [`${curr.id}`]: !!f?.target?.checked,
        }),
        {},
      ),
      rig: {
        future:
          rigAccess.futureWellAccess !== undefined
            ? !!f?.target?.checked
            : false,
      },
    };

    setCommonCheckbox(
      f?.target?.checked ? CheckboxState.Selected : CheckboxState.None,
    );
    onValuesChange(newFieldsValue);
  };

  useEffect(() => {
    if (rigAccess.futureWellAccess === undefined || commonFuture === undefined)
      return;
    const newFields = {
      ...fields,
      rig: {
        future: commonFuture,
      },
    };

    onValuesChange(newFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonFuture, rigAccess.futureWellAccess]);

  useEffect(() => {
    if (rigAccess) {
      onValuesChange({
        ...fields,
        wells: (rigAccess.wells ?? []).reduce<{ [key: string]: boolean }>(
          (prev, curr) => ({
            ...prev,
            [`${curr.id}`]: curr.permission === ClaimPermissionType.All,
          }),
          {},
        ),
      });
      if (rigAccess.futureWellAccess !== undefined)
        if ((rigAccess.wells ?? [])?.length > 0) {
          onValuesChange({
            wells: (rigAccess.wells ?? []).reduce<{ [key: string]: boolean }>(
              (prev, curr) => ({
                ...prev,
                [`${curr.id}`]: curr.permission === ClaimPermissionType.All,
              }),
              {},
            ),
            rig: {
              future: !!rigAccess.futureWellAccess,
            },
          });
          const currentList = [
            ...new Set((rigAccess.wells ?? []).map((e) => e.permission)),
          ];
          if (currentList.length === 1) {
            if (
              rigAccess.futureWellAccess === undefined ||
              rigAccess.futureWellAccess ===
                (currentList[0] === ClaimPermissionType.All)
            ) {
              setCommonCheckbox(
                currentList[0] === ClaimPermissionType.All
                  ? CheckboxState.Selected
                  : CheckboxState.None,
              );
              return;
            }
            setCommonCheckbox(CheckboxState.Partially);
          } else {
            setCommonCheckbox(CheckboxState.Partially);
          }
        } else {
          setCommonCheckbox(
            rigAccess.futureWellAccess
              ? CheckboxState.Selected
              : CheckboxState.None,
          );
        }
      // @ts-ignore
      claims.current = rigAccess.wells.map((e) => ({
        [mode === "user" ? "userId" : "operatorId"]: selector,
        wellId: e.id,
        rigId: rigAccess.rigId,
        type: ClaimType.Well,
        permission: e.permission,
      }));
      if (rigAccess.futureWellAccess !== undefined)
        // @ts-ignore
        claims.current.push({
          [mode === "user" ? "userId" : "operatorId"]: selector,
          rigId: rigAccess.rigId,
          type: ClaimType.FutureWellAccess,
          permission:
            rigAccess.futureWellAccess === true
              ? ClaimPermissionType.All
              : ClaimPermissionType.None,
        });
      claimCallback(rigAccess.rigId, claims.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, rigAccess, selector]);

  // have this in an useMemo because if we do have a separated component of this
  // styles don't get applied correctly
  const WellChildren = useMemo(() => {
    return [
      (rigAccess.wells ?? []).map((e) => {
        return (
          <StyledCheckbox
            key={`wells${e.id}`}
            disabled={viewOnly}
            checked={fields.wells[`${e.id}`]}
            onChange={() => {
              onValuesChange({
                ...fields,
                wells: {
                  ...fields.wells,
                  [`${e.id}`]: !fields.wells[`${e.id}`],
                },
              });
            }}
          >
            <Row justify="space-between" align="middle">
              <Col flex="0 auto">
                <Title level={3} variant={atomThemeVariant}>
                  {e.name}
                </Title>
              </Col>
              <Col flex="0 auto">
                <Title level={3} variant="faded">
                  {e.status}
                </Title>
              </Col>
            </Row>
          </StyledCheckbox>
        );
      }),
      rigAccess.futureWellAccess !== undefined && (
        <StyledCheckbox
          disabled={viewOnly}
          checked={fields.rig.future}
          key="future-wells-included"
          onChange={() => {
            onValuesChange({
              ...fields,
              rig: {
                future: !fields.rig.future,
              },
            });
          }}
        >
          <Title level={3} variant={atomThemeVariant}>
            All Future Wells
          </Title>
        </StyledCheckbox>
      ),
    ];
  }, [atomThemeVariant, fields, onValuesChange, rigAccess, viewOnly]);

  return (
    <StyledCollapse ghost expandIconPosition="right">
      <StyledCollapse.Panel
        key={1}
        header={
          <StyledCheckbox
            style={{ display: "inline-flex" }}
            checked={commonCheckbox === CheckboxState.Selected}
            indeterminate={commonCheckbox === CheckboxState.Partially}
            onChange={onCheckAllChange}
            disabled={viewOnly}
          >
            <Title level={3} variant={atomThemeVariant}>
              Rig {rigName}
            </Title>
          </StyledCheckbox>
        }
      >
        <StyledDivider type="horizontal" />
        <StyledWellContainerSpace>{WellChildren}</StyledWellContainerSpace>
      </StyledCollapse.Panel>
    </StyledCollapse>
  );
};
