import { useDirectionalIntervals } from "hooks/drillingInvariants/useDirectionalIntervals";
import { useHoleSections } from "hooks/drillingInvariants/useHoleSections";
import { useHoleSizes } from "hooks/drillingInvariants/useHoleSizes";
import { usePhases } from "hooks/drillingInvariants/usePhases";
import { useReferenceFilters } from "hooks/filters/useReferenceFilters";
import { useEffect, useState } from "react";

export const useFiltersLoading = () => {
  const [filtersLoading, setFiltersLoading] = useState(false);
  const { data: holeSections, isLoading: isLoadingHoleSections } =
    useHoleSections();
  const { data: holeSizes, isLoading: isLoadingHoleSizes } = useHoleSizes();
  const { data: phases, isLoading: isLoadingPhases } = usePhases();
  const {
    data: directionalIntervals,
    isLoading: isLoadingDirectionalIntervals,
  } = useDirectionalIntervals();
  const { data: availableFilters, isLoading: isLoadingAvailableFilters } =
    useReferenceFilters();

  useEffect(() => {
    setFiltersLoading(
      isLoadingAvailableFilters ||
        isLoadingHoleSections ||
        isLoadingHoleSizes ||
        isLoadingDirectionalIntervals ||
        isLoadingPhases,
    );
  }, [
    isLoadingAvailableFilters,
    isLoadingHoleSections,
    isLoadingHoleSizes,
    isLoadingDirectionalIntervals,
    isLoadingPhases,
  ]);

  return {
    filtersLoading,
    holeSections,
    phases,
    holeSizes,
    directionalIntervals,
    availableFilters,
  };
};
