import type { UseQueryOptions } from "@tanstack/react-query";
import type {
  StackedStandKpiComparisonDto,
  StackedStandKpiDto,
} from "apis/oag";
import { useKpiGroupStackedComparisonFacts } from "hooks/facts/useKpiGroupStackedComparisonFacts";
import { useKpiGroupStackedFacts } from "hooks/facts/useKpiGroupStackedFacts";

export function useStackedKpiSimpleFacts({
  lensId,
  opts,
}: {
  lensId: number;
  opts?: UseQueryOptions<StackedStandKpiDto>;
}) {
  return useKpiGroupStackedFacts(lensId, opts);
}

export function useStackedKpiComparisonFacts({
  lensId,
  opts,
}: {
  lensId: number;
  opts?: UseQueryOptions<StackedStandKpiComparisonDto>;
}) {
  return useKpiGroupStackedComparisonFacts(lensId, opts);
}
