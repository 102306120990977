import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ScorecardKpiCommentInfoDto } from "apis/oag";
import {
  ScorecardExceptionStatusType,
  ScorecardKpiCommentsApi,
  TimeUnit,
} from "apis/oag";
import { PDComponent } from "components/PDComponents";
import * as Styled from "components/RigScoreCardCommentsModal/CommentStatus/styled";
import { RigScorecardInvalidationKey } from "hooks/rigs/scorecard/useRigScorecardCardFacts";
import { CommentsInvalidationKey } from "hooks/rigs/scorecard/useScoreCardComments";
import React, { useCallback, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { useTimeUom } from "utils/format";

export const CommentStatus: React.FC<{
  comment: ScorecardKpiCommentInfoDto;
}> = ({ comment }) => {
  const hoursUOM = useTimeUom(TimeUnit.Hour);
  const user = useAppSelector((state) => state.global.userInfo);
  const queryClient = useQueryClient();
  const isApprover = useMemo(
    () => comment.exceptionApproverId === user?.id,
    [comment.exceptionApproverId, user?.id],
  );
  const shouldShowControls =
    isApprover &&
    comment.exceptionStatus === ScorecardExceptionStatusType.Pending;
  const commentsApi = new ScorecardKpiCommentsApi(apiConfig);

  const approveComment = useMutation({
    mutationFn: (id: number) => {
      return commentsApi.apiScorecardKpiCommentsIdApprovePut({ id });
    },
  });

  const rejectComment = useMutation({
    mutationFn: (id: number) => {
      return commentsApi.apiScorecardKpiCommentsIdRejectPut({ id });
    },
  });

  const handleOnApprove = useCallback(async () => {
    await approveComment.mutateAsync(comment.id, {
      onSettled: () => {
        Track.interact("Rig Scorecard - Approve Exception", {
          "Comment Id": comment.id,
          Approver: `${user?.firstName} ${user?.lastName}`,
          Hours: hoursUOM.display(comment.exceptionTime, { fractionDigits: 2 }),
        });
        queryClient.invalidateQueries({ queryKey: [CommentsInvalidationKey] });
        queryClient.invalidateQueries({
          queryKey: [RigScorecardInvalidationKey],
        });
      },
    });
  }, [approveComment, comment, queryClient, user, hoursUOM]);

  const handleOnReject = useCallback(async () => {
    await rejectComment.mutateAsync(comment.id, {
      onSettled: async () => {
        Track.interact("Rig Scorecard - Reject Exception", {
          "Comment Id": comment.id,
          Approver: `${user?.firstName} ${user?.lastName}`,
          Hours: hoursUOM.display(comment.exceptionTime, { fractionDigits: 2 }),
        });
        await queryClient.invalidateQueries({
          queryKey: [RigScorecardInvalidationKey],
        });
        queryClient.invalidateQueries({ queryKey: [CommentsInvalidationKey] });
      },
    });
  }, [comment, queryClient, user, hoursUOM, rejectComment]);

  const [icon, message] = useMemo(() => {
    switch (comment.exceptionStatus) {
      case ScorecardExceptionStatusType.Approved:
        return [
          <PDComponent.SvgIcon
            name="checkmarkFilled"
            style={{ fontSize: 16 }}
            key={ScorecardExceptionStatusType.Approved}
          />,
          "Exception approved.",
        ];
      case ScorecardExceptionStatusType.Rejected:
        return [
          <PDComponent.SvgIcon
            name="forbidden"
            style={{ fontSize: 16 }}
            key={ScorecardExceptionStatusType.Rejected}
          />,
          "Exception Rejected.",
        ];
      case ScorecardExceptionStatusType.Pending:
        return [
          <PDComponent.SvgIcon
            name="warning"
            style={{ color: colors.exciting_orange, fontSize: 16 }}
            key={ScorecardExceptionStatusType.Pending}
          />,
          "Exception waiting for approval.",
        ];
      default:
        return [null, null];
    }
  }, [comment.exceptionStatus]);
  return (
    <Styled.ListNoteExceptionStatus>
      {shouldShowControls ? (
        <Styled.ApprovalActions>
          <Styled.RejectButton
            size="middle"
            type="primary"
            onClick={handleOnReject}
          >
            Reject
          </Styled.RejectButton>
          <Styled.ApproveButton
            size="middle"
            type="primary"
            onClick={handleOnApprove}
          >
            Approve
          </Styled.ApproveButton>
        </Styled.ApprovalActions>
      ) : (
        <Styled.ApprovalStatus>
          {icon}
          {message}
        </Styled.ApprovalStatus>
      )}

      <Styled.StatusText>
        <Styled.NoWrap>
          Hours:{" "}
          <b>
            {hoursUOM.display(comment.exceptionTime, { fractionDigits: 2 })}
          </b>
        </Styled.NoWrap>
        <Styled.OverflowEllipsis>
          Approver: <b>{comment.exceptionApproverDisplayName}</b>
        </Styled.OverflowEllipsis>
      </Styled.StatusText>
    </Styled.ListNoteExceptionStatus>
  );
};
