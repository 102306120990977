import { useMutation, useQueryClient } from "@tanstack/react-query";
import type {
  DateDto,
  KpiGroupUserLensDto,
  KpiTypeUserLensDto,
  LensTabWithVisibilityDto,
  ParameterByDepthUserLensDto,
  ParameterByDepthUserLensTrackItemDto,
  UserLensDto,
} from "apis/oag";
import { GenericUserLensesApi } from "apis/oag";
import { toast } from "atoms/toast";
import { useTrackAdd } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTrackAdd";
import { useTrackAddStickSlip } from "components/Lenses/ContainerLens/StickSlipByDepth/Parts/hooks/useTrackAddStickSlip";
import { ResizeContainer } from "components/Lenses/style";
import { LensGridContext } from "components/Lenses/utils";
import { OptionUnavailableTooltip } from "components/MiniLens/OptionUnavailableTooltip";
import {
  Button,
  StyledBottomRow,
  StyledBottomRowContent,
  StyledCard,
  StyledLensShell,
} from "components/MiniLens/style";
import { PDComponent } from "components/PDComponents";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import { useLensMouseIn } from "hooks/lens/useLensMouseIn";
import {
  isStickSlipByDepthLensTemplate,
  useLensTemplates,
} from "hooks/lens/useLensTemplates";
import type { UserLensesQueryResult } from "hooks/lens/useUserLenses";
import { UserLensesQueryKey, useUserLenses } from "hooks/lens/useUserLenses";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { uniqBy } from "lodash";
import React, { useCallback, useContext, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import superjson from "superjson";
import { apiConfig } from "utils/apiConfig";
import { Col, Row, Space } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

interface LensContainerProps {
  children: React.ReactNode;
  graphKey?: string;
  hasSettings?: boolean;
  hasDetailed?: boolean;
  hasHiddenToolbar?: boolean;
  lastUpdated: DateDto | string;
  lensId: number;
  localLens?: UserLensDto;
  selectedTrackItems: ParameterByDepthUserLensTrackItemDto[];
  setSelectedTrackItems: React.Dispatch<
    React.SetStateAction<ParameterByDepthUserLensTrackItemDto[]>
  >;
  tab: LensTabWithVisibilityDto;
  title: string;
  openSettingsModal: (lens: KpiTypeUserLensDto | KpiGroupUserLensDto) => void;
  onLensUpdated?: (newItem: UserLensDto) => void;
  canAddTracks?: boolean;
}

export const CommonLensShell = ({
  localLens,
  lensId,
  title,
  hasSettings,
  hasDetailed,
  hasHiddenToolbar,
  tab,
  lastUpdated,
  children,
  selectedTrackItems,
  setSelectedTrackItems,
  onLensUpdated,
  openSettingsModal,
  canAddTracks,
}: LensContainerProps) => {
  const userLensesApi = new GenericUserLensesApi(apiConfig);
  const queryClient = useQueryClient();
  const { mouseIn, setMouseIn } = useLensMouseIn();
  const location = useLocation();
  const { data: userLenses } = useUserLenses();
  const { data: templates } = useLensTemplates();
  const lens = localLens || userLenses?.byId[lensId];
  const template = templates.byId[lens.lensTemplateId];
  const { setDeletedElements } = useContext(LensGridContext);
  const onAdd = useCallback(
    (tracks: ParameterByDepthUserLensTrackItemDto[]) => {
      setSelectedTrackItems(tracks);
      (onLensUpdated as (newItem: ParameterByDepthUserLensDto) => void)?.({
        ...(lens as ParameterByDepthUserLensDto),
        userLensTrackItems: tracks,
      });
    },
    [lens, onLensUpdated, setSelectedTrackItems],
  );

  const { TrackAddModalElement, openTrackAddModal } = useTrackAdd({
    lens,
    selectedTrackItems,
    onAdd,
  });

  const {
    TrackAddModalElement: TrackAddModalElementStickSlip,
    openTrackAddModal: openTrackAddModalStickSlip,
  } = useTrackAddStickSlip({
    lens,
    selectedTrackItems,
    onAdd,
  });
  const isStickSlip = isStickSlipByDepthLensTemplate(template);

  const removeLensMutation = useMutation<unknown, unknown, number>({
    mutationFn: (id: number) => {
      return userLensesApi.apiGenericUserLensesIdDelete({ id });
    },
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: [UserLensesQueryKey] });

      const previousLenses = await queryClient.getQueryData([
        UserLensesQueryKey,
      ]);

      queryClient.setQueryData([UserLensesQueryKey], (old) => {
        if (old) {
          const tabs = old as UserLensesQueryResult;
          return {
            list: (tabs?.list ?? []).filter((item) => item.id !== id),
            byId: { ...tabs.byId, [id]: undefined } as {
              [x: number]: UserLensDto | undefined;
            },
            byTab: {
              ...tabs.byTab,
              [tab.id]: tabs.byTab[tab.id].filter((item) => item.id !== id),
            },
          };
        }
        setDeletedElements?.((elements) => [...elements, id]);

        return [];
      });

      return { previousLenses };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        [UserLensesQueryKey],
        (context as { previousLenses: unknown }).previousLenses,
      );
    },
    onSuccess: () => {
      Track.interact("Lens - Remove", {
        "Lens Id": lensId,
        "Lens Name": title ?? "Parameter Lens",
        "Is Parameter Lens": title === "",
      });
      toast.success({
        message: "Lens removed successfully!",
      });
    },
  });

  const widgetState = useAppSelector((state) => state.widgetOptions);
  const [offsetWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WELL,
    [],
  );
  const rigDashboard = useAppSelector((state) => state.rigDashboard);

  const dataString = useMemo(() => {
    return window.btoa(
      superjson.stringify([widgetState, offsetWells, rigDashboard]),
    );
  }, [offsetWells, widgetState, rigDashboard]);

  const getQuery = () => {
    const qsArr = uniqBy(
      (location?.search ?? "").replace("?", "").split("&"),
      (e) => e.split("=")?.[0],
    )
      .map((e) => {
        if (e.startsWith("selectedTab=")) {
          return `selectedTab=${tab.id}`;
        }
        return e;
      })
      .filter((e) => e);
    if (qsArr.find((e) => e.startsWith("selectedTab="))) return qsArr.join("&");
    return [...qsArr, `selectedTab=${tab.id}`].join("&");
  };

  const { dashboardType } = useDashboardType();
  const { isDark } = useCustomTheme();

  return (
    <StyledLensShell>
      <StyledCard
        $hasHiddenToolbar={!!hasHiddenToolbar}
        onMouseEnter={() => setMouseIn(true)}
        onMouseLeave={() => setMouseIn(false)}
        bordered={false}
      >
        {children}
        <StyledBottomRow>
          {/* TODO change this and create some proper data states */}
          {(!mouseIn || lastUpdated === "Click to Reload") && (
            <StyledBottomRowContent />
          )}
          {mouseIn && lastUpdated !== "Click to Reload" ? (
            <Row
              justify="space-between"
              style={{
                width: "100%",
                padding: 0,
                paddingRight: 42,
                marginTop: -7.5,
              }}
            >
              <Col>
                <Row>
                  <Col flex="0 auto">
                    <div className="grid-item__drag_handler">
                      <OptionUnavailableTooltip isPassThrough={!tab.isLocked}>
                        <Button
                          $border={"none!important"}
                          icon={<PDComponent.SvgIcon name="move" />}
                          disabled={tab.isLocked}
                        />
                      </OptionUnavailableTooltip>
                    </div>
                  </Col>

                  {hasSettings ? (
                    <Col flex="0 auto">
                      <div className="grid-item__settings">
                        <Button
                          $border={"none!important"}
                          icon={<PDComponent.SvgIcon name="settings" />}
                          onClick={() => {
                            if (lens) {
                              openSettingsModal(
                                lens as
                                  | KpiTypeUserLensDto
                                  | KpiGroupUserLensDto,
                              );
                            }
                          }}
                        />
                      </div>
                    </Col>
                  ) : null}

                  <Col flex="0 auto">
                    {/* Mutating does result in too many mixpanel events, trigger the event on the success branch */}
                    <OptionUnavailableTooltip isPassThrough={!tab.isLocked}>
                      <Button
                        $border={"none!important"}
                        onClick={() => removeLensMutation.mutate(lensId)}
                        icon={<PDComponent.SvgIcon name="delete" />}
                        disabled={tab.isLocked}
                      />
                    </OptionUnavailableTooltip>
                  </Col>

                  <Col flex="0 auto">
                    {canAddTracks ? (
                      <Button
                        $border={"none!important"}
                        onClick={() => {
                          isStickSlip
                            ? openTrackAddModalStickSlip()
                            : openTrackAddModal();
                        }}
                        icon={
                          <PDComponent.SvgIcon
                            name="addCircle"
                            width="1.1em"
                            height="1.1em"
                          />
                        }
                      />
                    ) : null}
                  </Col>
                </Row>
              </Col>
              {hasDetailed ? (
                <Col>
                  <Space
                    style={{
                      padding: 0,
                    }}
                  >
                    <Link
                      to={`${location.pathname}/${lensId}/prepare/${dataString}${
                        getQuery().length > 0 ? `?${getQuery()}` : ""
                      }`}
                    >
                      <Button
                        type="text"
                        style={{
                          padding: "0px !important",
                        }}
                        onClick={() => {
                          Track.clickEvent(`Open Detailed View`, {
                            "Dashboard Type": `${dashboardType} Dashboard`,
                          });
                        }}
                      >
                        Detailed View
                      </Button>
                    </Link>
                  </Space>
                </Col>
              ) : null}
            </Row>
          ) : null}
          {mouseIn && tab.isLocked && lastUpdated !== "Click to Reload" ? (
            <ResizeContainer>
              <OptionUnavailableTooltip isPassThrough={!tab?.isLocked}>
                <PDComponent.SvgIcon
                  name="dragToResize"
                  width="24px"
                  height="24px"
                />
              </OptionUnavailableTooltip>
            </ResizeContainer>
          ) : null}
        </StyledBottomRow>
      </StyledCard>
      {TrackAddModalElement}
      {TrackAddModalElementStickSlip}
    </StyledLensShell>
  );
};
