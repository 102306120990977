import { PDComponent } from "components/PDComponents";
import styled from "styled-components";
import { Button } from "utils/componentLibrary";

export const Container = styled.div`
  padding: 0px;
`;

export const Header = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  color: ${(props) => props.theme.themeStyle.colors.primary_typography};
  margin-left: 6px;
`;

export const ChevronDownIcon = styled(PDComponent.SvgIcon).attrs({
  name: "chevronDown",
})`
  opacity: 0;
`;

export const StyledButton = styled(Button)<{
  $isActive?: boolean;
  $backgroundColor?: string;
}>`
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${(props) => props.theme.themeStyle.colors.primary_typography};
  border: none;

  :focus,
  &.ant-btn:focus {
    outline: none;
    box-shadow: none;
    color: ${(props) => props.theme.themeStyle.colors.primary_typography};
    background: transparent;
  }

  :hover {
    background: transparent;
    color: ${(props) => props.theme.themeStyle.colors.primary_typography};
  }

  ${ChevronDownIcon} {
    opacity: 1;
    transition: transform 0.2s ease-in-out;
    transform: rotate(${(props) => (props.$isActive ? "180deg" : "0deg")});
  }
`;
