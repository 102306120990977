import { useQuery } from "@tanstack/react-query";
import type { WellAccessQueryDto } from "apis/oag";
import { UsersApi } from "apis/oag";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDAdminUsersWellAccessQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const users = new UsersApi(apiConfig);

export function useAdminSingleUserWellAccess(
  { userId }: { userId: number },
  enabled = true,
) {
  const period = useAppSelector((state) => state.admin.wellAccess.period);
  const rigs = useAppSelector((state) => state.admin.wellAccess.rigs);
  const wellStatus = useAppSelector(
    (state) => state.admin.wellAccess.wellStatus,
  );
  const search = useAppSelector((state) => state.admin.wellAccess.search);

  const requestQuery: WellAccessQueryDto = {
    spudDateStart: period.startDate,
    spudDateEnd: period.endDate,
    rigIds: rigs,
    status: wellStatus,
    search: search,
  };

  const queryKey: PDAdminUsersWellAccessQueryKey = {
    type: PDQueryType.ADMIN_USERS_WELL_ACCESS,
    uid: RequestUID.adminUsersWellAccess,
    params: requestQuery,
    userId,
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: () =>
      users.apiUsersIdWellAccessPut({
        id: userId,
        wellAccessQueryDto: requestQuery,
      }),
    enabled,
  });
}
