/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface IntelScorecardWellDetailsDto
 */
export interface IntelScorecardWellDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof IntelScorecardWellDetailsDto
     */
    rigName: string;
    /**
     * 
     * @type {DateDto}
     * @memberof IntelScorecardWellDetailsDto
     */
    endDate: DateDto;
}

/**
 * Check if a given object implements the IntelScorecardWellDetailsDto interface.
 */
export function instanceOfIntelScorecardWellDetailsDto(value: object): value is IntelScorecardWellDetailsDto {
    if (!('rigName' in value) || value['rigName'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    return true;
}

export function IntelScorecardWellDetailsDtoFromJSON(json: any): IntelScorecardWellDetailsDto {
    return IntelScorecardWellDetailsDtoFromJSONTyped(json, false);
}

export function IntelScorecardWellDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelScorecardWellDetailsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'rigName': json['RigName'],
        'endDate': DateDtoFromJSON(json['EndDate']),
    };
}

export function IntelScorecardWellDetailsDtoToJSON(value?: IntelScorecardWellDetailsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'RigName': value['rigName'],
        'EndDate': DateDtoToJSON(value['endDate']),
    };
}

