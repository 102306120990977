import { SingleKPIComparison } from "components/Lenses/ContainerLens/SingleKpi/SingleKpiComparison";
import { SingleKPISimple } from "components/Lenses/ContainerLens/SingleKpi/SingleKpiSimple";
import type { SingleKpiProps } from "components/Lenses/interfaces";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";

export const SingleKPI = (props: SingleKpiProps) => {
  const [comparisonWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );
  const isComparing = comparisonWells?.length > 0;

  return isComparing ? (
    <SingleKPIComparison {...props} />
  ) : (
    <SingleKPISimple {...props} />
  );
};
