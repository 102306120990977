import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import type { NaturalGasStateFactDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import {
  TooltipFadedValue,
  TooltipHighlightValue,
} from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { NO_DATA_KPI_STRING } from "components/Lenses/constants";
import * as CommonStyles from "components/Lenses/ContainerLens/common/StyledComponents";
import { Indicator } from "components/Lenses/ContainerLens/common/StyledComponents";
import { addIndexToSeries } from "components/Lenses/ContainerLens/common/utils/utils";
import {
  PRESSURE_ID,
  TEMPERATURE_ID,
} from "components/Lenses/ContainerLens/NaturalGasState/utils";
import { bisector } from "d3-array";
import type { ScaleLinear } from "d3-scale";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { OnRefChangeType } from "react-resize-detector/build/types/types";
import { DEFAULT_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

type NaturalGasStateFactDtoWithIndex = NaturalGasStateFactDto & {
  index: number;
};

export const useNaturalGasStateTooltip = ({
  containerRef,
  legendItems,
  xScale,
  yScalePrimary,
  yScaleSecondary,
  series: unorderedSeries,
  isPointerInsideChart,
  plotWidth,
  plotHeight,
}: {
  containerRef:
    | OnRefChangeType<HTMLDivElement>
    | React.MutableRefObject<HTMLDivElement>;
  legendItems: LegendItem[];
  xScale: ScaleLinear<number, number, never>;
  yScalePrimary: ScaleLinear<number, number, never>;
  yScaleSecondary: ScaleLinear<number, number, never>;
  series: NaturalGasStateFactDto[];
  isPointerInsideChart: boolean;
  plotWidth: number;
  plotHeight: number;
}) => {
  const series = useMemo(
    () => addIndexToSeries(unorderedSeries),
    [unorderedSeries],
  );

  const [pointerPosition, setPointerPosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });

  const yScalePrimaryUOM = useUOM(DimensionType.Pascals);
  const yScaleSecondaryUOM = useUOM(DimensionType.Temperature);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const handlePointerMove = useCallback(
    (event: React.PointerEvent<SVGSVGElement>) => {
      const { left, top } = event.currentTarget.getBoundingClientRect();
      const x = ("clientX" in event ? event.clientX : 0) - left;
      const y = ("clientY" in event ? event.clientY : 0) - top;
      setPointerPosition({ x, y });
    },
    [],
  );

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip({
    containerRef,
    renderContent: ({
      tooltipData,
    }: {
      tooltipData?: NaturalGasStateFactDtoWithIndex;
    }) => {
      return (
        <CommonStyles.TooltipContainer>
          {legendItems.find((legendItem) => legendItem.id === TEMPERATURE_ID)
            ?.isEnabled && tooltipData?.temperature ? (
            <CommonStyles.TooltipRow>
              <CommonStyles.HighlightValue>
                <Indicator color={legendItems[1].color} />
                Temperature
              </CommonStyles.HighlightValue>

              <TooltipHighlightValue>
                {yScaleSecondaryUOM.display(tooltipData.temperature, {
                  fractionDigits: 2,
                })}
              </TooltipHighlightValue>
            </CommonStyles.TooltipRow>
          ) : null}

          {legendItems.find((legendItem) => legendItem.id === PRESSURE_ID)
            ?.isEnabled && tooltipData?.pressure ? (
            <CommonStyles.TooltipRow>
              <CommonStyles.HighlightValue>
                <Indicator color={legendItems[0].color} />
                Pressure
              </CommonStyles.HighlightValue>

              <TooltipHighlightValue>
                {yScalePrimaryUOM.display(tooltipData.pressure, {
                  fractionDigits: 2,
                })}
              </TooltipHighlightValue>
            </CommonStyles.TooltipRow>
          ) : null}

          <CommonStyles.TooltipBorder>
            <div />
            <TooltipFadedValue>
              {tooltipData?.at
                ? formatTime(tooltipData?.at, {
                    formatStr: DEFAULT_DATE_FORMAT,
                  })
                : NO_DATA_KPI_STRING}
            </TooltipFadedValue>
          </CommonStyles.TooltipBorder>
        </CommonStyles.TooltipContainer>
      );
    },
  });

  const tooltipPoint: NaturalGasStateFactDtoWithIndex | null = useMemo(() => {
    if (!series) return null;

    if (pointerPosition.x > plotWidth) {
      return null;
    }

    const x0 = xScale.invert(pointerPosition.x);

    let extremitiesBisectFix = 0;

    if (pointerPosition.x < 30) {
      // To make sure we can select both points at the edge of the graph, normal  bisect leaves out either one or the other
      // Artificially adding mouse X if it's close to the plot edges to get correct edge index
      extremitiesBisectFix = -0.001;
    } else if (Math.abs(pointerPosition.x - plotWidth) < 10) {
      extremitiesBisectFix = 0.001;
    }

    const index = bisector<NaturalGasStateFactDtoWithIndex, number>(
      (p) => p.index,
    ).left(series, x0 + extremitiesBisectFix);

    if (index >= 0 && index < series.length && series[index].index) {
      return series[index];
    }

    return null;
  }, [plotWidth, pointerPosition.x, series, xScale]);

  useEffect(() => {
    const top = Math.max(
      Math.min(
        (Number.isFinite(tooltipPoint?.pressure)
          ? yScalePrimary(tooltipPoint?.pressure || 0)
          : Infinity) || Infinity, // protect against NAN
        (Number.isFinite(tooltipPoint?.temperature)
          ? yScaleSecondary(tooltipPoint?.temperature || 0)
          : Infinity) || Infinity,
      ),
      0,
    );

    return isPointerInsideChart && tooltipPoint
      ? showTooltip({
          tooltipLeft: xScale(tooltipPoint.index) || 0,
          tooltipTop: top,
          tooltipData: tooltipPoint,
        })
      : hideTooltip();
  }, [
    hideTooltip,
    isPointerInsideChart,
    pointerPosition,
    showTooltip,
    tooltipPoint,
    xScale,
    yScalePrimary,
    yScaleSecondary,
  ]);

  const Tooltip =
    tooltipPoint && isPointerInsideChart ? (
      <>
        {tooltipElement}
        <Group>
          <Line
            from={{
              x: xScale(tooltipPoint.index),
              y: plotHeight,
            }}
            to={{
              x: xScale(tooltipPoint.index),
              y: 0,
            }}
            strokeOpacity={0.3}
            stroke={themeColors.primary_typography}
            strokeWidth={2}
          />

          {(
            [] as {
              value: number;
              scale: ScaleLinear<number, number, never>;
              color: string;
            }[]
          )
            .concat(
              legendItems.find((item) => item.id === PRESSURE_ID)?.isEnabled
                ? [
                    {
                      value: tooltipPoint.pressure,
                      scale: yScalePrimary,
                      color: legendItems[0].color,
                    },
                  ]
                : [],
            )
            .concat(
              legendItems.find((item) => item.id === TEMPERATURE_ID)?.isEnabled
                ? [
                    {
                      value: tooltipPoint.temperature,
                      scale: yScaleSecondary,
                      color: legendItems[1].color,
                    },
                  ]
                : [],
            )
            .map(({ value, scale, color }, idx) => {
              return Number.isFinite(value) ? (
                <g key={idx}>
                  <circle
                    cx={xScale(tooltipPoint.index)}
                    cy={scale(value)}
                    r={4}
                    fill={color}
                  />
                  <circle
                    cx={xScale(tooltipPoint.index)}
                    cy={scale(value)}
                    r={1}
                    fill={"white"}
                  />
                </g>
              ) : null;
            })}
        </Group>
      </>
    ) : null;
  return { handlePointerMove, tooltipPoint, tooltipElement: Tooltip };
};
