import { LensTabStateType } from "apis/oag";
import type { LensTabWithVisibilityDto } from "apis/oag/models/LensTabWithVisibilityDto";
import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import { useShareRecipients } from "hooks/dashboard/useShareRecipients";
import { ClickableElement } from "pages/WellDashboard/ManageTabs/style";
import * as Styled from "pages/WellDashboard/ManageTabs/Tables/style";
import { useFilterTabsByText } from "pages/WellDashboard/ManageTabs/Tables/useFilterTabsByText";
import { RigSpecificityDropdown } from "pages/WellDashboard/RigSpecificityDropdown/RigSpecificityDropdown";
import { useCallback, useMemo } from "react";
import colors from "utils/colors";
import { formatDate } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

export const ReceivedTabsTable = ({
  tabs,
  uncomittedDeletedTabs,
  handleOnUndoDelete,
  handleOnAttemptDeleteId,
  onTabsChange,
  filterTerm,
}: {
  tabs: LensTabWithVisibilityDto[];
  handleOnAttemptDeleteId: (id: number) => void;
  handleOnUndoDelete: (id: number) => void;
  uncomittedDeletedTabs: number[];
  onTabsChange: (tabs: LensTabWithVisibilityDto[]) => void;
  filterTerm: string;
}) => {
  const filteredTabs = useFilterTabsByText(tabs, filterTerm);
  const visibleTabs = useMemo(
    () =>
      filteredTabs?.filter(
        (tab) => tab.state === LensTabStateType.PendingApproval,
      ),
    [filteredTabs],
  );

  const { isLoading: isLoadingRecipients, data: shareRecipients } =
    useShareRecipients();
  const getRecipientName = useCallback(
    (id: number) => {
      return isLoadingRecipients
        ? "Loading..."
        : shareRecipients?.find((recipient) => recipient.userId === id)
            ?.displayName;
    },
    [isLoadingRecipients, shareRecipients],
  );

  const isTabPendingDelete = useCallback(
    (tab: LensTabWithVisibilityDto) => uncomittedDeletedTabs.includes(tab?.id),
    [uncomittedDeletedTabs],
  );

  const handleOnClickAccept = useCallback(
    (acceptedTab: LensTabWithVisibilityDto) => {
      if (!isTabPendingDelete(acceptedTab)) {
        onTabsChange(
          tabs.map((tab) =>
            tab.id === acceptedTab.id
              ? {
                  ...tab,
                  state: LensTabStateType.Visible,
                }
              : tab,
          ),
        );
      }
    },
    [isTabPendingDelete, onTabsChange, tabs],
  );

  const { themeStyle } = useCustomTheme();
  return visibleTabs?.length ? (
    <>
      <Styled.GridLayoutRow>
        <Styled.HeaderCol>Configuration</Styled.HeaderCol>
        <Styled.HeaderCol>Tab Name</Styled.HeaderCol>
        <Styled.HeaderCol>Shared by</Styled.HeaderCol>
        <Styled.HeaderCol>Date Shared</Styled.HeaderCol>
        <Styled.HeaderCol $isCentered>Add Tab</Styled.HeaderCol>
        <Styled.HeaderCol></Styled.HeaderCol>
      </Styled.GridLayoutRow>

      {visibleTabs.map((tab) => {
        return (
          <Styled.GridLayoutRow
            key={tab.id}
            $bordered
            $accepted={tab?.state === LensTabStateType.Visible}
            $isPendingDelete={isTabPendingDelete(tab)}
          >
            <Styled.CustomCol $isSpaceBetween $isDisabled $paddingLeft={10}>
              <PDComponent.SvgIcon name="dataShare" width="16" height="16" />

              {tab?.isLocked ? (
                <Styled.IconShell
                  $bgColor={colors.alpine_landing}
                  $color={themeStyle.colors.white_black}
                  $disabled
                  role="button"
                >
                  <PDComponent.SvgIcon name="locked" style={{ fontSize: 10 }} />
                </Styled.IconShell>
              ) : (
                <Styled.IconShell
                  $bgColor={themeStyle.colors.primary_accent}
                  $color={themeStyle.colors.black_white}
                  $bordered
                  $disabled
                  role="button"
                >
                  <PDComponent.SvgIcon
                    name="unlocked"
                    style={{ fontSize: 10 }}
                  />
                </Styled.IconShell>
              )}

              <RigSpecificityDropdown
                selectedRigs={tab?.rigIds ?? []}
                placement="bottomLeft"
                isReadOnly
              >
                <Styled.IconShell
                  $bgColor={
                    tab?.rigIds?.length
                      ? colors.well_color
                      : themeStyle.colors.primary_accent
                  }
                  $color={
                    tab?.rigIds?.length
                      ? themeStyle.colors.white_black
                      : themeStyle.colors.black_white
                  }
                  $bordered={!tab?.rigIds?.length}
                  role="button"
                  $disabled={isTabPendingDelete(tab)}
                >
                  <PDComponent.SvgIcon name="rig" style={{ fontSize: 10 }} />
                </Styled.IconShell>
              </RigSpecificityDropdown>
            </Styled.CustomCol>

            <Styled.CustomCol>
              <Styled.CustomInput value={tab.name} disabled />
            </Styled.CustomCol>

            <Styled.CustomCol>
              {tab?.sharedById ? getRecipientName(tab?.sharedById) : ""}
            </Styled.CustomCol>
            <Styled.CustomCol>
              {tab?.sharedAtUtc ? formatDate(tab?.sharedAtUtc) : null}
            </Styled.CustomCol>

            <Styled.CustomCol $isCentered>
              <Styled.IconShell
                $bgColor={colors.necron_compound_light}
                $color={themeStyle.colors.faint_typography}
                $hoverBg={colors.well_color}
                $hoverColor={themeStyle.colors.primary_bg}
                role="button"
                $disabled={isTabPendingDelete(tab)}
                onClick={() => handleOnClickAccept(tab)}
              >
                ＋
              </Styled.IconShell>
            </Styled.CustomCol>

            <Styled.CustomCol
              $isCentered
              onClick={() =>
                isTabPendingDelete(tab)
                  ? handleOnUndoDelete(tab?.id)
                  : handleOnAttemptDeleteId(tab?.id)
              }
              role="button"
            >
              <ClickableElement>
                {isTabPendingDelete(tab) ? (
                  <PDComponent.SvgIcon name="reset" style={{ fontSize: 16 }} />
                ) : (
                  <PDComponent.SvgIcon
                    name="trashCan"
                    style={{ fontSize: 16 }}
                  />
                )}
              </ClickableElement>
            </Styled.CustomCol>
          </Styled.GridLayoutRow>
        );
      })}
    </>
  ) : (
    <em>
      <Title level={4}>No items to display</Title>
    </em>
  );
};
