import type { DragEndEvent } from "@dnd-kit/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { StickSlipByDepthUserLensDto } from "apis/oag";
import {
  ParameterByDepthUserLensesApi,
  StickSlipByDepthUserLensesApi,
} from "apis/oag";
import { arrayMoveImmutable } from "array-move";
import { useTrackSettingsModal } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTrackSettingsModal";
import type {
  LensItem,
  TrackItem,
} from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/interfaces";
import type { ITrackUnitTypes } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/utils/trackUnitTypes";
import {
  trackUnitTypesByDepth,
  trackUnitTypesStickSlip,
} from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/utils/trackUnitTypes";
import type { TracksResponse } from "hooks/drillingInvariants/useTracks";
import { UserLensesQueryKey } from "hooks/lens/useUserLenses";
import { useCallback } from "react";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import type { IValueWithUnit } from "utils/interfaces/Well/interfaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const byDepthApi = new ParameterByDepthUserLensesApi(apiConfig);
const stickSlipApi = new StickSlipByDepthUserLensesApi(apiConfig);

export function useTrackChangeOperations({
  lens,
  stringIds,
  selectedTrackItems,
  setSelectedTrackItems,
  onLensUpdated,
  tracks,
  selectedUom,
  availableTrackIds,
  selectedUnits,
  isStickSlip = false,
  trackUnitTypes,
}: {
  lens: LensItem;
  stringIds: string[];
  selectedTrackItems: TrackItem[];
  setSelectedTrackItems?: (value: React.SetStateAction<TrackItem[]>) => void;
  onLensUpdated?: (newLens: LensItem) => void;
  tracks?: TracksResponse;
  selectedUom: React.MutableRefObject<{ [key: number]: string }>;
  availableTrackIds: number[];
  selectedUnits: Record<number, IValueWithUnit>;
  isStickSlip?: boolean;
  trackUnitTypes: ITrackUnitTypes;
}) {
  const queryClient = useQueryClient();

  const softTrackMutation = useMutation({
    mutationFn: ({ trackItems }: { trackItems: TrackItem[] }) => {
      const { id } = lens;

      if (isStickSlip) {
        return stickSlipApi.apiStickSlipByDepthUserLensesIdPut({
          id,
          stickSlipByDepthUserLensDto: {
            ...(lens as StickSlipByDepthUserLensDto),
            userLensTrackItems: trackItems,
          },
        });
      } else {
        return byDepthApi.apiParameterByDepthUserLensesIdPut({
          id,
          parameterByDepthUserLensDto: {
            ...lens,
            userLensTrackItems: trackItems,
          },
        });
      }
    },
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: [UserLensesQueryKey] });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [UserLensesQueryKey] });
    },
  });

  const handleChangeTracksOrderAndUpdateLens = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const oldIndex = stringIds.indexOf(active.id.toString());
        const newIndex = stringIds.indexOf(over.id.toString());

        const newTrackItems = arrayMoveImmutable(
          selectedTrackItems,
          oldIndex,
          newIndex,
        ).map((trackItem, index) => ({
          ...trackItem,
          position: index + 1,
        }));

        setSelectedTrackItems?.(newTrackItems);
        softTrackMutation.mutate({ trackItems: newTrackItems });
        onLensUpdated?.({ ...lens, userLensTrackItems: newTrackItems });
      }
    },
    [
      lens,
      onLensUpdated,
      selectedTrackItems,
      setSelectedTrackItems,
      softTrackMutation,
      stringIds,
    ],
  );

  const handleRemoveTrackAndUpdateLens = async (trackId: number) => {
    const newTrackItems = selectedTrackItems
      .filter((item) => item.trackId !== trackId)
      .map((item, index) => ({ ...item, position: index + 1 }));

    const deletedTrack = tracks?.byId?.[trackId]?.name ?? "";
    Track.interact("Delete", { "Track Name": deletedTrack });
    setSelectedTrackItems?.(newTrackItems);

    await softTrackMutation.mutateAsync({ trackItems: newTrackItems });
    onLensUpdated?.({ ...lens, userLensTrackItems: newTrackItems });
  };

  const updateTracksMutation = useMutation({
    mutationFn: ({ trackItems }: { trackItems: TrackItem[] }) => {
      const { id } = lens;

      if (isStickSlip) {
        return stickSlipApi.apiStickSlipByDepthUserLensesIdPut({
          id,
          stickSlipByDepthUserLensDto: {
            ...(lens as StickSlipByDepthUserLensDto),
            userLensTrackItems: trackItems,
          },
        });
      } else
        return byDepthApi.apiParameterByDepthUserLensesIdPut({
          id,
          parameterByDepthUserLensDto: {
            ...lens,
            userLensTrackItems: trackItems,
          },
        });
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [UserLensesQueryKey] });
      await queryClient.cancelQueries({
        queryKey: [{ type: PDQueryType.WELL_OVERVIEW }],
        exact: false,
      });
      if (isStickSlip) {
        await queryClient.cancelQueries({
          queryKey: [{ uid: RequestUID.stickSlipByDepthFacts }],
          exact: false,
        });
      } else {
        await queryClient.cancelQueries({
          queryKey: [{ uid: RequestUID.parameterByDepthFacts }],
          exact: false,
        });
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [UserLensesQueryKey] });
      if (isStickSlip) {
        await queryClient.invalidateQueries({
          queryKey: [
            { uid: RequestUID.stickSlipByDepthFacts, lensId: lens.id },
          ],
          ...{
            refetchType: "all",
            exact: false,
          },
        });
      } else {
        await queryClient.invalidateQueries({
          queryKey: [
            { uid: RequestUID.parameterByDepthFacts, lensId: lens.id },
          ],
          ...{
            refetchType: "all",
            exact: false,
          },
        });
      }

      await queryClient.invalidateQueries({
        queryKey: [{ type: PDQueryType.WELL_OVERVIEW }],
        ...{
          refetchType: "all",
          exact: false,
        },
      });
    },
  });

  const { open: openTrackSettingsModal, node: trackSettingsModalElement } =
    useTrackSettingsModal({
      trackUnitTypes,
      onSave: async ({ track }) => {
        const newTrackItems = selectedTrackItems
          .map((t) => {
            if (t.id === track.id) {
              return track;
            }

            return t;
          })
          .map((item, index) => ({ ...item, position: index + 1 }));
        const trackProps = tracks?.byId?.[track.trackId];
        const trackUnits = isStickSlip
          ? trackUnitTypesStickSlip[trackProps?.name || ""]
          : trackUnitTypesByDepth[trackProps?.name || ""];

        const trackDimension = Object.keys(trackUnits).find(
          (unit) =>
            unit &&
            trackUnits?.[unit]?.unitSystem?.startsWith?.(
              track?.systemOfMeasurementType,
            ),
        );
        selectedUom.current = {
          ...selectedUom.current,
          [track.trackId]: track?.systemOfMeasurementType,
        };
        Track.interact("Change Track", {
          "Track Properties": trackProps && {
            Name: trackProps.name,
            YAxis: [
              (trackDimension &&
                trackUnits[trackDimension]?.toString?.(
                  +(track.yaxisStart || 0),
                )) ||
                track.yaxisStart,
              (trackDimension &&
                trackUnits[trackDimension]?.toString?.(
                  +(track.yaxisEnd || 0),
                )) ||
                track.yaxisEnd,
            ],
            Description: trackProps.description,
            Dimension: trackDimension,
          },
        });
        setSelectedTrackItems?.(newTrackItems);
        await updateTracksMutation.mutateAsync({ trackItems: newTrackItems });
      },
      onDelete: handleRemoveTrackAndUpdateLens,
      availableTrackIds,
      selectedUnits,
    });

  return {
    handleChangeTracksOrderAndUpdateLens,
    openTrackSettingsModal,
    trackSettingsModalElement,
  };
}
