import { Circle } from "@visx/shape";
import type { CircleProps } from "@visx/shape/lib/shapes/Circle";
import type { AddSVGProps } from "@visx/shape/lib/types";
import type { RigCardFactDto } from "apis/oag";
import { ScoreComplianceStateType } from "apis/oag";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useScoreCardComments } from "hooks/rigs/scorecard/useScoreCardComments";
import {
  BenchmarkType,
  useScoreBenchmarkContext,
} from "pages/RigScoreCard/ScoreBenchmarkContext";
import { memo, useMemo } from "react";
import colors from "utils/colors";

type CommentCircleProps = AddSVGProps<CircleProps, SVGCircleElement> & {
  fact: RigCardFactDto;
  lensId: number;
};

const CommentCircle = ({ fact, lensId, cx, cy, r }: CommentCircleProps) => {
  const { scoreBenchmark } = useScoreBenchmarkContext();
  const [rigSelectedWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.SELECTED_WELLS_RIG_SCORECARD,
    [],
  );
  const memoizedParameter = rigSelectedWells.toString();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedWellsIds = useMemo(() => rigSelectedWells, [memoizedParameter]);
  const { data: commentsByLens } = useScoreCardComments(selectedWellsIds, {
    refetchOnMount: false,
  });
  const color = useMemo(() => {
    const wellId = +fact?.label;
    let hasComplianceComments = false;
    const hasComments =
      (
        commentsByLens
          ?.find((commentGroup) => commentGroup.scorecardLensId === lensId)
          ?.comments.filter((comm) => comm.wellId === wellId) || []
      ).length > 0;

    if (
      (scoreBenchmark === BenchmarkType.OperatorTarget &&
        fact.operatorScoreComplianceState ===
          ScoreComplianceStateType.ActionPending) ||
      (scoreBenchmark === BenchmarkType.PDBenchmark &&
        fact.rigScoreComplianceState === ScoreComplianceStateType.ActionPending)
    ) {
      hasComplianceComments = true;
    }

    if (hasComments && !hasComplianceComments) return colors.well_color;
    else if (hasComplianceComments) return colors.error;
    else return null;
  }, [
    commentsByLens,
    fact?.label,
    fact.operatorScoreComplianceState,
    fact.rigScoreComplianceState,
    lensId,
    scoreBenchmark,
  ]);

  return color ? <Circle cx={cx} cy={cy} r={r} fill={color} /> : null;
};

export default memo(CommentCircle);
