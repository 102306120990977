import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { LensTabStateType, type LensTabWithVisibilityDto } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { TAB_NAME_MAX_LENGTH } from "pages/WellDashboard/AddNewTabModal";
import { ClickableElement } from "pages/WellDashboard/ManageTabs/style";
import * as Styled from "pages/WellDashboard/ManageTabs/Tables/style";
import { RigSpecificityDropdown } from "pages/WellDashboard/RigSpecificityDropdown/RigSpecificityDropdown";
import colors from "utils/colors";
import { formatDate } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

interface SortableGridRowProps {
  tab: LensTabWithVisibilityDto;
  isTabPendingDelete: boolean;
  handleOnUndoDelete: (id: number) => void;
  handleOnClickToggleLock: (id: number) => void;
  handleOnRigIdsSelect: (id: number, rigIds: number[]) => void;
  handleOnNameChange: (id: number, name: string) => void;
  getRecipientName: (id: number) => string;
  handleOnClickToggleVisibility: (id: number) => void;
  handleOnClickDelete: (id: number) => void;
}

export const SortableGridRow = ({
  tab,
  isTabPendingDelete,
  handleOnUndoDelete,
  handleOnClickToggleLock,
  handleOnRigIdsSelect,
  handleOnNameChange,
  getRecipientName,
  handleOnClickToggleVisibility,
  handleOnClickDelete,
}: SortableGridRowProps) => {
  const { themeStyle } = useCustomTheme();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isOver,
  } = useSortable({
    id: tab.id.toString(),
    disabled: isTabPendingDelete,
  });

  const style = {
    transform: transform
      ? CSS.Transform.toString({ ...transform, scaleX: 1, scaleY: 1 })
      : undefined,
    transition,
  };

  return (
    <Styled.GridLayoutRow
      key={tab.id}
      $bordered
      style={style}
      ref={setNodeRef}
      $isPendingDelete={isTabPendingDelete}
    >
      <Styled.CustomCol $isSpaceBetween>
        <Styled.DraggableIcon
          $isOver={isOver}
          $isDragging={isDragging}
          $isDisabled={isTabPendingDelete}
        >
          <PDComponent.SvgIcon
            name="drag"
            {...attributes}
            {...listeners}
            width="8px"
            height="12px"
          />
        </Styled.DraggableIcon>

        {tab?.isLocked ? (
          <Styled.IconShell
            $bgColor={colors.alpine_landing}
            $color={themeStyle.colors.white_black}
            onClick={() =>
              isTabPendingDelete ? null : handleOnClickToggleLock(tab?.id)
            }
            $disabled={isTabPendingDelete}
            role="button"
          >
            <PDComponent.SvgIcon name="locked" style={{ fontSize: 10 }} />
          </Styled.IconShell>
        ) : (
          <Styled.IconShell
            $bgColor={themeStyle.colors.primary_accent}
            $color={themeStyle.colors.black_white}
            onClick={() =>
              isTabPendingDelete ? null : handleOnClickToggleLock(tab?.id)
            }
            $bordered
            $disabled={isTabPendingDelete}
            role="button"
          >
            <PDComponent.SvgIcon name="unlocked" style={{ fontSize: 10 }} />
          </Styled.IconShell>
        )}

        <RigSpecificityDropdown
          onRigIdsSelect={(rigIds) => handleOnRigIdsSelect(tab?.id, rigIds)}
          selectedRigs={tab?.rigIds ?? []}
          placement="bottomLeft"
        >
          <Styled.IconShell
            $bgColor={
              tab?.rigIds?.length
                ? colors.well_color
                : themeStyle.colors.primary_accent
            }
            $color={
              tab?.rigIds?.length
                ? themeStyle.colors.white_black
                : themeStyle.colors.black_white
            }
            $bordered={!tab?.rigIds?.length}
            $disabled={isTabPendingDelete}
            role="button"
          >
            <PDComponent.SvgIcon name="rig" style={{ fontSize: 10 }} />
          </Styled.IconShell>
        </RigSpecificityDropdown>
      </Styled.CustomCol>

      <Styled.CustomCol>
        <Styled.CustomInput
          value={tab.name}
          onChange={(ev) => handleOnNameChange(tab?.id, ev.target?.value)}
          maxLength={TAB_NAME_MAX_LENGTH}
          disabled={isTabPendingDelete}
        />
      </Styled.CustomCol>

      <Styled.CustomCol>
        <Styled.Ellipsis>
          {tab?.sharedById ? getRecipientName(tab?.sharedById) : ""}
        </Styled.Ellipsis>
      </Styled.CustomCol>
      <Styled.CustomCol>
        {tab?.sharedAtUtc ? formatDate(tab?.sharedAtUtc) : null}
      </Styled.CustomCol>

      <Styled.CustomCol $isCentered>
        <Styled.StyledSwitch
          size="small"
          checked={tab?.state === LensTabStateType.Visible}
          $isChecked={tab?.state === LensTabStateType.Visible}
          onChange={() => handleOnClickToggleVisibility(tab?.id)}
          disabled={isTabPendingDelete}
        />
      </Styled.CustomCol>

      <Styled.CustomCol
        $isCentered
        onClick={() =>
          isTabPendingDelete
            ? handleOnUndoDelete(tab?.id)
            : handleOnClickDelete(tab?.id)
        }
        role="button"
      >
        <ClickableElement>
          {isTabPendingDelete ? (
            <PDComponent.SvgIcon name="reset" style={{ fontSize: 16 }} />
          ) : (
            <PDComponent.SvgIcon name="trashCan" style={{ fontSize: 16 }} />
          )}
        </ClickableElement>
      </Styled.CustomCol>
    </Styled.GridLayoutRow>
  );
};
