import { IconContainer } from "icons/styles";
import type { FC } from "react";
import React from "react";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  fill?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onMouseOver?: React.MouseEventHandler<HTMLElement>;
  onMouseOut?: React.MouseEventHandler<HTMLElement>;
  width?: number;
  height?: number;
  size?: number;
  id?: string;
};

const IconWrapper = (SVG: FC<Props>) => {
  const DeltaComponent: FC<Props> = ({
    className = "",
    style = {},
    ...rest
  }) => {
    return (
      <IconContainer className={`pd-icon ${className}`} style={style} {...rest}>
        <SVG />
      </IconContainer>
    );
  };

  return DeltaComponent;
};
export default IconWrapper;
