import type { IntelGroupingType } from "apis/oag";
import { IntelLegendVisibleTabs } from "pages/IntelDashboard/components/LegendGroupSelector/LegendGroupSelector";
import type { Dispatch, FC, SetStateAction } from "react";
import {
  createContext,
  useCallback,
  useDeferredValue,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "reducers/store";
import colors from "utils/colors";
import type { ReactChildren } from "utils/types";
import { useCustomTheme } from "utils/useTheme";

import { ColorGenerator } from "./colorGenerator";

export type ColorAccessorParams = {
  key: string;
};

export type ColorCreatorParams = {
  key: string;
  index: number;
};

type IAccessColorCallback = ({ key }: ColorAccessorParams) => string;
type ICreateColorCallback = ({ key, index }: ColorCreatorParams) => string;
type GroupColor = { [key in IntelGroupingType]?: { [key: string]: string } };

const initialColorsByGroup: GroupColor = {
  [IntelLegendVisibleTabs.Contractor]: {
    "Precision Drilling Corporation": colors.well_color,
  },
};

const initialState: {
  colors: typeof initialColorsByGroup;
  getColor: IAccessColorCallback;
  getOrCreateColor: ICreateColorCallback;
} = {
  colors: {},
  getColor: () => "",
  getOrCreateColor: () => "",
};

export const IntelLegendColorsContext = createContext(initialState);

export const IntelLegendColorsProvider: FC<ReactChildren> = ({ children }) => {
  const generator = useMemo(() => new ColorGenerator(), []);
  const [colors, setColors] = useState(initialColorsByGroup);

  const colorsRef = useRef(initialColorsByGroup).current;

  const deferredColors = useDeferredValue(colors);
  const selectedLegendTab = useAppSelector(
    (state) => state.intelDashboard.groupingType,
  );

  const theme = useCustomTheme();

  const getColor = useCallback(
    ({ key }: ColorAccessorParams) => {
      const foundColor = colorsRef[selectedLegendTab]?.[key];
      return foundColor ?? theme.themeStyle.intel.typography;
    },
    [colorsRef, selectedLegendTab, theme.themeStyle.intel.typography],
  );

  const getOrCreateColor = useCallback(
    ({ key, index }: ColorCreatorParams) => {
      const foundColor = colorsRef[selectedLegendTab]?.[key];
      if (foundColor) {
        return foundColor;
      }

      if (!colorsRef[selectedLegendTab]) {
        colorsRef[selectedLegendTab] = {};
      }
      colorsRef[selectedLegendTab][key] = generator.getNextColor(index);

      setColors(colorsRef);
      return colorsRef[selectedLegendTab][key];
    },
    [colorsRef, generator, selectedLegendTab],
  );

  const value = useMemo(
    () => ({
      colors: deferredColors,
      getColor,
      getOrCreateColor,
    }),
    [deferredColors, getColor, getOrCreateColor],
  );

  return (
    <IntelLegendColorsContext.Provider value={value}>
      {children}
    </IntelLegendColorsContext.Provider>
  );
};
