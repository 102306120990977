import type { StickSlipByTimeUserLensTrackItemDto } from "apis/oag";
import { Button, Input } from "atoms/Form";
import { FormGroup } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/TrackSettingsModal/FormGroup";
import * as Styled from "components/Lenses/ContainerLens/StickSlipByTime/components/TrackSettingsModal/style";
import { PDComponent } from "components/PDComponents";
import { round } from "lodash";
import React, { useCallback, useState } from "react";
import { Col, Row } from "utils/componentLibrary";
import type { UOMHelper } from "utils/format";

interface TrackSettingsModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSave: (newTrackItem: StickSlipByTimeUserLensTrackItemDto) => void;
  uom: UOMHelper;
  isManualYaxis?: boolean;
  isLoading: boolean;
  lensTrackSettings: StickSlipByTimeUserLensTrackItemDto;
  trackTitle: string;
}

const getInitialValue = (value: number | null | undefined, uom: UOMHelper) => {
  if (value === null || value === undefined) {
    return "";
  }
  return round(uom.fromSI(value), 2).toString();
};

export function TrackSettingsModal({
  isVisible,
  onClose,
  onSave,
  uom,
  lensTrackSettings,
  isLoading,
  trackTitle,
}: TrackSettingsModalProps) {
  const [localYMin, setLocalYMin] = useState(
    lensTrackSettings.yaxisStart &&
      Number.isFinite(lensTrackSettings.yaxisStart) &&
      lensTrackSettings.isManualYaxis
      ? getInitialValue(lensTrackSettings.yaxisStart, uom)
      : "",
  );

  const [localYMax, setLocalYMax] = useState(
    lensTrackSettings.yaxisEnd &&
      Number.isFinite(lensTrackSettings.yaxisEnd) &&
      lensTrackSettings.isManualYaxis
      ? getInitialValue(lensTrackSettings.yaxisEnd, uom)
      : "",
  );

  const [isManualYaxis, setIsManualYaxis] = useState(
    lensTrackSettings.isManualYaxis,
  );

  const [localYLimits, setLocalYLimits] = useState({
    min: getInitialValue(lensTrackSettings.minLimit, uom),
    max: getInitialValue(lensTrackSettings.maxLimit, uom),
  });

  const localYMinError = !Number.isFinite(+localYMin)
    ? "Incorrect value"
    : +localYMin >= +localYMax
      ? `Value should be less than ${localYMax}`
      : null;

  const localYMaxError = !Number.isFinite(+localYMax)
    ? "Incorrect value"
    : +localYMin >= +localYMax
      ? `Value should be more than ${localYMin}`
      : null;

  const localYMinLimitError = !Number.isFinite(+localYLimits.min)
    ? "Incorrect value"
    : +localYLimits.min >= +localYLimits.max &&
        localYLimits.min !== "" &&
        localYLimits.max !== ""
      ? `Value should be less than ${localYLimits.max}`
      : null;

  const localYMaxLimitError = !Number.isFinite(+localYLimits.max)
    ? "Incorrect value"
    : +localYLimits.min >= +localYLimits.max &&
        localYLimits.max !== "" &&
        localYLimits.min !== ""
      ? `Value should be more than ${localYLimits.min}`
      : null;

  const hasInputError =
    (isManualYaxis && (!!localYMinError || !!localYMaxError)) ||
    !!localYMinLimitError ||
    !!localYMaxLimitError;

  const handleOnSave = useCallback(() => {
    onSave({
      ...lensTrackSettings,
      yaxisStart:
        isManualYaxis && localYMin !== "" ? uom.toSI(+localYMin) : undefined,
      yaxisEnd:
        isManualYaxis && localYMax !== "" ? uom.toSI(+localYMax) : undefined,
      isManualYaxis: isManualYaxis,
      minLimit:
        localYLimits.min !== "" ? uom.toSI(+localYLimits.min) : undefined,
      maxLimit:
        localYLimits.max !== "" ? uom.toSI(+localYLimits.max) : undefined,
    });
  }, [
    isManualYaxis,
    lensTrackSettings,
    localYLimits.max,
    localYLimits.min,
    localYMax,
    localYMin,
    onSave,
    uom,
  ]);

  return (
    <PDComponent.Modal
      onCancel={onClose}
      title="Track Settings"
      footer={
        <Row justify="space-between">
          <Col>
            <Button onClick={onClose} loading={isLoading}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              disabled={hasInputError}
              onClick={handleOnSave}
              loading={isLoading}
            >
              Save Changes
            </Button>
          </Col>
        </Row>
      }
      open={isVisible}
    >
      <Styled.TrackName>{`${trackTitle} Track`}</Styled.TrackName>
      <FormGroup
        label="Y Axis Range"
        rightElement={
          <div>
            <Styled.ToggleButton
              inactive={isManualYaxis}
              onClick={() => setIsManualYaxis(false)}
            >
              Auto
            </Styled.ToggleButton>
            <Styled.ToggleButton
              inactive={!isManualYaxis}
              onClick={() => setIsManualYaxis(true)}
            >
              Manual
            </Styled.ToggleButton>
          </div>
        }
      >
        <Styled.Grid>
          <Input
            suffix={uom.abbr}
            disabled={!isManualYaxis}
            value={isManualYaxis ? localYMin : 0}
            onChange={(e) => setLocalYMin(e.currentTarget.value)}
            error={isManualYaxis ? localYMinError ?? "" : ""}
          />
          <Input
            suffix={uom.abbr}
            disabled={!isManualYaxis}
            value={isManualYaxis ? localYMax : "Auto"}
            onChange={(e) => setLocalYMax(e.currentTarget.value)}
            error={isManualYaxis ? localYMaxError ?? "" : ""}
          />
        </Styled.Grid>
      </FormGroup>

      <Styled.Separator />

      <FormGroup
        label="Filter Options"
        rightElement={
          <Styled.FilterOptions>
            <Styled.Grid>
              <Styled.FormLabel>Exclude values above</Styled.FormLabel>
              <Input
                suffix={uom.abbr}
                value={localYLimits.max ?? ""}
                placeholder="Max"
                onChange={(e) => {
                  setLocalYLimits({
                    ...localYLimits,
                    max: e.currentTarget.value,
                  });
                }}
                error={localYMaxLimitError ? localYMaxLimitError ?? "" : ""}
              />
            </Styled.Grid>

            <Styled.Grid>
              <Styled.FormLabel>Exclude values below</Styled.FormLabel>
              <Input
                suffix={uom.abbr}
                value={localYLimits.min ?? ""}
                placeholder="Min"
                onChange={(e) =>
                  setLocalYLimits({
                    ...localYLimits,
                    min: e.currentTarget.value,
                  })
                }
                error={localYMinLimitError ? localYMinLimitError ?? "" : ""}
              />
            </Styled.Grid>
          </Styled.FilterOptions>
        }
      />
    </PDComponent.Modal>
  );
}
