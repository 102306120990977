import { IntelGroupingType, ProfilesApi } from "apis/oag";
import type { SelectorItem } from "components/GroupSelector/GroupSelector";
import { GroupSelector } from "components/GroupSelector/GroupSelector";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";

const api = new ProfilesApi(apiConfig);

export enum IntelLegendVisibleTabs {
  "Rig" = "Rig",
  "Contractor" = "Contractor",
  "Operator" = "Operator",
}

export const LegendGroupSelector = () => {
  const dispatch = useAppDispatch();
  const selectedGroupingType = useAppSelector(
    (state) => state.intelDashboard.groupingType,
  );

  const selectorItems: SelectorItem<IntelGroupingType>[] = useMemo(
    () =>
      Object.keys(IntelLegendVisibleTabs).map((key) => {
        return {
          key: key as IntelGroupingType,
          label: key,
        };
      }),
    [],
  );

  const handleOnClickLegend = (intelGroupingName: IntelGroupingType) => {
    dispatch({
      type: "INTEL_DASHBOARD_SET_GROUPING_TYPE",
      payload: intelGroupingName,
    });

    api.apiProfilesIntelDashboardGroupingTypePut({
      body: intelGroupingName,
    });
  };

  return (
    <GroupSelector
      width={253}
      items={selectorItems}
      selectedItem={selectedGroupingType}
      onSelect={(key) => handleOnClickLegend(key)}
    />
  );
};
