import {
  StyledContainer,
  StyledSpace,
  StyledText,
  StyledTitle,
} from "components/NoTabs/style";

export const NoTabs = () => {
  return (
    <StyledContainer>
      <StyledSpace align="center" direction="vertical" size={16}>
        <img src="/assets/images/noTab.png" alt="no tabs available" />
        <StyledTitle level={2}>No tab is available yet</StyledTitle>
        <StyledText level={3}>
          Please click the &quot;+&quot; button on the tab header to create{" "}
          <br />a new tab and get started
        </StyledText>
      </StyledSpace>
    </StyledContainer>
  );
};
