import type { RigPowerSummaryDto } from "apis/oag";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { LegendPreviewerType } from "components/Lenses/common/ChartLegend/interfaces";
import { useLensNameByTemplateId } from "hooks/lens/useLensNameByTemplateId";
import { useState } from "react";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";
import type { UOMHelper } from "utils/format";

export const AVAILABLE_POWER_ID = -1;

export function useAdditionalLegendItems({
  summary,
  lensId,
  lensTemplateId,
  uomValue,
}: {
  summary: RigPowerSummaryDto;
  lensId: number;
  lensTemplateId: number;
  uomValue: UOMHelper;
}) {
  const displayValue = (val: number) =>
    uomValue.display(val, {
      fractionDigits: 0,
      unit: "kW",
    });

  const lensName = useLensNameByTemplateId(lensTemplateId);

  const [capacityLegendItem, setCapacityLegendItem] = useState<LegendItem>({
    // This one is purely cosmetic, not enabling and disabling it on server
    id: AVAILABLE_POWER_ID,
    name: "Available Power",
    color: colors.aurora_magenta,
    isEnabled: true,
    previewerType: LegendPreviewerType.LINE,
    columnValues: {
      Min: displayValue(summary.availablePower?.min || 0),
      Max: displayValue(summary.availablePower?.max || 0),
      Mean: displayValue(summary.availablePower?.mean || 0),
    },
    onClick: () => {
      Track.interact("Evergreen Dashboard - Update legend", {
        lens: lensName,
        lensId: lensId,
        selectedItem: capacityLegendItem.name,
        isEnabled: !capacityLegendItem.isEnabled,
      });
      setCapacityLegendItem((capacityLegendItem) => ({
        ...capacityLegendItem,
        isEnabled: !capacityLegendItem.isEnabled,
      }));
    },
  });

  return capacityLegendItem;
}
