import styled from "styled-components";

export const StyledLensGroup = styled.div`
  margin: 3px;
  height: 279px;
  background: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 16px 0px;
  height: 279px;
`;

export const Padding = styled.div`
  display: block;
`;

export const Grid = styled.div<{
  max: number;
  crt: number;
  $isWellOverview: boolean;
  selectedWells: number;
}>`
  display: grid;
  grid-gap: 4px;
  margin-bottom: 4px;
  max-height: 100%;
  width: 100%;
  grid-template-columns: ${({ max, crt, $isWellOverview, selectedWells }) => {
    if ($isWellOverview) {
      return `minmax(16px, ${selectedWells > 9 ? 0.5 : 2}fr) 4fr minmax(16px, ${selectedWells > 9 ? 0.5 : 2}fr)`;
    }
    return `minmax(16px,${(max - crt) / 2}fr) repeat(${crt}, calc(${100 / max}% - ${4 * (max - 1)}px)) minmax(16px,${
      (max - crt) / 2
    }fr)`;
  }};
`;
