/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PivtoTargetSegmentItemDto } from './PivtoTargetSegmentItemDto';
import {
    PivtoTargetSegmentItemDtoFromJSON,
    PivtoTargetSegmentItemDtoFromJSONTyped,
    PivtoTargetSegmentItemDtoToJSON,
} from './PivtoTargetSegmentItemDto';

/**
 * 
 * @export
 * @interface PivotTargetSegment
 */
export interface PivotTargetSegment {
    /**
     * 
     * @type {number}
     * @memberof PivotTargetSegment
     */
    target?: number | null;
    /**
     * 
     * @type {PivtoTargetSegmentItemDto}
     * @memberof PivotTargetSegment
     */
    first?: PivtoTargetSegmentItemDto | null;
    /**
     * 
     * @type {PivtoTargetSegmentItemDto}
     * @memberof PivotTargetSegment
     */
    last?: PivtoTargetSegmentItemDto | null;
}

/**
 * Check if a given object implements the PivotTargetSegment interface.
 */
export function instanceOfPivotTargetSegment(value: object): value is PivotTargetSegment {
    return true;
}

export function PivotTargetSegmentFromJSON(json: any): PivotTargetSegment {
    return PivotTargetSegmentFromJSONTyped(json, false);
}

export function PivotTargetSegmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotTargetSegment {
    if (json == null) {
        return json;
    }
    return {
        
        'target': json['Target'] == null ? undefined : json['Target'],
        'first': json['First'] == null ? undefined : PivtoTargetSegmentItemDtoFromJSON(json['First']),
        'last': json['Last'] == null ? undefined : PivtoTargetSegmentItemDtoFromJSON(json['Last']),
    };
}

export function PivotTargetSegmentToJSON(value?: PivotTargetSegment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Target': value['target'],
        'First': PivtoTargetSegmentItemDtoToJSON(value['first']),
        'Last': PivtoTargetSegmentItemDtoToJSON(value['last']),
    };
}

