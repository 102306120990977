import styled from "styled-components";
import colors from "utils/colors";

export const Indicator = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  width: 8px;
  height: 7.356px;
  border-radius: 2px;
`;
export const StyledTooltipContainer = styled.div`
  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    width: 100%;
    color: ${colors.actions_bg};
    span {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: space-between;
      margin-bottom: 6px;
      div {
        display: flex;
        align-items: center;
        gap: 4px;
        white-space: nowrap;
      }
    }
  }
  hr {
    opacity: 0.3;
    border-top: none;
    height: 0px;
    background: ${colors.necron_compound_light};
  }
`;
