import { Title } from "atoms/Typography";
import {
  LEFT_COLUMN_WIDTH,
  LEFT_COLUMN_WIDTH_DETAILED,
  SUMMARY_HEIGHT,
} from "components/Lenses/ContainerLens/StickSlipByTime/utils/constants";
import styled from "styled-components";

export const Summary = styled.div`
  display: flex;
  padding: 16px;
  height: ${SUMMARY_HEIGHT}px;
  background-color: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;
export const SummaryTitle = styled.div<{ $isDetailed: boolean }>`
  width: ${({ $isDetailed }) =>
    $isDetailed ? LEFT_COLUMN_WIDTH_DETAILED : LEFT_COLUMN_WIDTH}px;
  ${Title} {
    font-size: ${({ $isDetailed }) => ($isDetailed ? "18px !important" : null)};
  }
`;
export const SummaryDetails = styled.div<{ $leftColumnWidth: number }>`
  display: flex;
  width: calc(100% - ${({ $leftColumnWidth }) => $leftColumnWidth}px);
  gap: 12px;
`;
export const SummaryColumn = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;
export const SummaryItemTitle = styled.div`
  text-align: right;
`;
export const SummaryInfo = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const SummaryData = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;
