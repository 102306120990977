import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type {
  ApiGeneratorStatusBarUserLensesIdFactsPutRequest,
  GeneratorStatusBarFactSetDto,
} from "apis/oag";
import { GeneratorStatusBarUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { useStateQuery } from "hooks/navigation/useQueryState";
import { URL_STATE_PARAM } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useAppDispatch } from "reducers/store";
import type { IZoomData } from "reducers/types";
import { IZoomType } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateDto } from "utils/helper";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new GeneratorStatusBarUserLensesApi(apiConfig);

export const useGeneratorStatusBarFacts = (
  lensId: number,
  requestParameters: ApiGeneratorStatusBarUserLensesIdFactsPutRequest,
  options?: Omit<
    UseQueryOptions<GeneratorStatusBarFactSetDto>,
    "queryKey" | "queryFn"
  >,
) => {
  const [zoomData] = useStateQuery<IZoomData>(
    URL_STATE_PARAM.ZOOM_WELL,
    initialZoomData,
    [URL_STATE_PARAM.ZOOM_WIDGET],
  );

  const crtWellId = useSelectedWell();
  const dispatch = useAppDispatch();

  let requestParametersWithZoom = requestParameters;
  if (zoomData.date_start && zoomData.type === IZoomType.DATE) {
    requestParametersWithZoom = {
      ...requestParameters,
      rigPowerQueryDto: requestParameters.rigPowerQueryDto
        ? {
            ...requestParameters.rigPowerQueryDto,
            from: dateToDateDto(zoomData.date_start) || defaultDateDto.from,
            to: dateToDateDto(zoomData.date_end) || defaultDateDto.to,
          }
        : undefined,
    };
  }

  const queryKey: PDLensFactsQueryKey<ApiGeneratorStatusBarUserLensesIdFactsPutRequest> =
    {
      type: PDQueryType.FACTS,
      uid: RequestUID.generatorStatusBarFacts,
      lensId,
      params: requestParametersWithZoom,
    };

  const request = useSuspenseQuery<GeneratorStatusBarFactSetDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      api
        .apiGeneratorStatusBarUserLensesIdFactsPut(requestParametersWithZoom, {
          signal,
        })
        .then((data) => {
          if (data?.lastUpdatedAt) {
            dispatch({
              type: "SET_POWER_MANAGEMENT_REFRESH_DATE",
              payload: {
                [crtWellId]: data?.lastUpdatedAt,
              },
            });
          }

          return data;
        }),
    ...options,
  });

  return request;
};
