import React from "react";
import styled from "styled-components";
import colors from "utils/colors";

export interface CardProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  bordered?: boolean;
  title?: React.ReactNode;
  actions?: React.ReactNode[];
}

const PdCard = styled.div<{
  bordered: boolean;
}>`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 1;
  list-style: none;
  position: relative;
  background: ${({ theme: { isDark } }) =>
    isDark ? colors.shutter_black : colors.white};
  border-radius: 4px;
  border: ${({ bordered, theme }) =>
    bordered ? `1px solid ${theme.themeStyle.colors.primary_accent}` : "none"};
`;

export const PdCardBody = styled.div`
  padding: 20px;
  background: transparent;
  :before {
    display: table;
    content: "";
  }

  :after {
    display: table;
    clear: both;
    content: "";
  }
`;

export const PdCardHead = styled.div`
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 20px;
  font-weight: 500;

  border-bottom: solid 1px
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const PdCardActions = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  background: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};

  :before {
    display: table;
    content: "";
  }

  :after {
    display: table;
    clear: both;
    content: "";
  }
`;

export const PdCardAction = styled.li<{ $width: number }>`
  float: left;
  margin: 0;
  padding: 0;
  text-align: center;
  cursor: pointer;
  width: ${({ $width }) => $width}%;
`;

function getAction(actions: React.ReactNode[]): React.ReactNode[] {
  return actions.map<React.ReactNode>((action, index) => (
    <PdCardAction $width={100 / actions.length} key={`action-${index}`}>
      <span>{action}</span>
    </PdCardAction>
  ));
}

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ bordered = true, title, actions, children, ...others }, ref) => {
    return (
      <PdCard ref={ref} bordered={bordered} {...others}>
        {title ? <PdCardHead>{title}</PdCardHead> : null}
        <PdCardBody>{children}</PdCardBody>
        {actions && actions.length ? (
          <PdCardActions>{getAction(actions)}</PdCardActions>
        ) : null}
      </PdCard>
    );
  },
);

Card.displayName = "Card";
