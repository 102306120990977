import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  AllRigsQueryDto,
  DateDto,
  RigSummaryDto,
  WellStatusType,
} from "apis/oag";
import { RigsApi } from "apis/oag";
import {
  AttributeStore,
  useLinkedWellAttributes,
} from "hooks/filters/useLinkedAttributes";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { RigTechnology } from "utils/enums";
import { dateToDateOnlyDto } from "utils/helper";
import type { PDRigsSummariesQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const rigs = new RigsApi(apiConfig);

export function useRigSummaries(
  optionsQuery?: Partial<UseQueryOptions<RigSummaryDto[]>>,
) {
  const filterState = useAppSelector((state) => state.rigsCommon.filters);
  const periodState = useAppSelector((state) => state.rigsCommon.period);

  const {
    options,
    isLoading: isLoadingObject,
    selectedValues,
  } = useLinkedWellAttributes({
    storeName: AttributeStore.rigsCommon,
  });

  const dispatch = useAppDispatch();
  const dispatchLastUpdateDate = (data: RigSummaryDto[]): RigSummaryDto[] => {
    if (data?.length) {
      const lastUpdatedAtDate = data.reduce<DateDto | null>(
        (acc: DateDto | null, rig) => {
          if (!rig.lastFactUpdatedAt) return acc;
          const date1 = new Date(rig.lastFactUpdatedAt.utc).getTime();
          const date2 = acc && new Date(acc.utc).getTime();
          if (!date2 || date1 > date2) return rig.lastFactUpdatedAt;
          return acc;
        },
        null,
      );

      dispatch({
        type: "RIGS_COMMON_SET_LAST_UPDATE_DATE",
        payload: lastUpdatedAtDate,
      });
    }

    return data;
  };

  const requestQuery: AllRigsQueryDto = {
    operatorIds:
      selectedValues.operators === null
        ? null
        : (options?.operators ?? []).map((op) => op.id),
    rigIds:
      selectedValues.rigs === null
        ? null
        : (options?.rigs ?? []).map((rig) => rig.id),
    from: dateToDateOnlyDto(periodState?.startDate ?? defaultDateDto.from.utc),
    to: dateToDateOnlyDto(periodState?.endDate ?? defaultDateDto.to.utc),
    includeFullWellFacts: false,
    formationIds:
      selectedValues.formations === null
        ? null
        : (options?.formations ?? []).map((f) => f.id),
    selectedWellIds:
      selectedValues.wells === null
        ? null
        : (options?.wells ?? []).map((w) => w.id),

    selectedFilters: {
      // BEGIN : Not impacted by Fact related data
      directionalIntervalIds: null,
      holeSizeIds: null,
      sectionIds: null,
      phaseIds: null,
      includeFlatTime: true,
      includeSlidingTime: true,
      includeRotatingTime: true,
      includeNullHoleDepth: true,
      // END

      includeAlphaRigs: filterState?.technology
        ? filterState?.technology
            .map((tech) => tech.id)
            .includes(RigTechnology.Alpha)
        : true,
      includeNonAlphaRigs: filterState?.technology
        ? filterState?.technology
            .map((tech) => tech.id)
            .includes(RigTechnology.NonAlpha)
        : true,
      selectedClasses: filterState?.rigType
        ? filterState?.rigType.map((t) => (t.id || -1).toString())
        : null,
      selectedCountryIds: filterState?.country
        ? filterState?.country.map((c) => +(c.id || -1))
        : null,
      selectedHorsePowers: filterState?.horsePower
        ? filterState?.horsePower.map((hp) => +(hp.id || -1))
        : null,
      selectedOperatingCenters: filterState?.operatingCenter
        ? filterState?.operatingCenter.map((oc) => (oc.id || -1).toString())
        : null,
      selectedWellStatusTypes:
        filterState?.wellStatus?.map((ws) => ws.value as WellStatusType) ??
        null,
    },
  };

  const RigsSummariesQueryKey: PDRigsSummariesQueryKey = {
    uid: RequestUID.rigsSummaries,
    params: requestQuery,
    type: PDQueryType.RIGS_SUMMARIES,
  };

  const request = useQuery<RigSummaryDto[]>({
    queryKey: [RigsSummariesQueryKey],
    queryFn: () =>
      rigs
        .apiRigsSummariesPut({
          allRigsQueryDto: requestQuery,
        })
        .then((data) => dispatchLastUpdateDate(data)),
    ...(optionsQuery ?? {}),
    enabled: !Object.values(isLoadingObject).some((loading) => loading),
  });

  return request;
}
