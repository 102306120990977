import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { useEffectExceptOnMount } from "hooks/react-utils/useEffectExceptOnMount";
import { useState } from "react";
import { IndicatorsState } from "reducers/stateReducer";
import { useAppSelector } from "reducers/store";
import { useAppDispatch } from "reducers/store";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import { Checkbox, Popover, Space, Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";
export interface IProps {
  disabled: boolean;
  icon: JSX.Element;
}

const getString = (index: IndicatorsState) => {
  return IndicatorsState[index];
};

const StyledSpace = styled(Space)`
  .ant-checkbox-wrapper {
    display: flex;
  }
`;

export const Indicators = (props: IProps) => {
  const [visible, setVisible] = useState(false);
  const selectedIndicators = useAppSelector(
    (state) => state.state.selectedIndicators,
  );
  const dispatch = useAppDispatch();

  const handleVisibleChange = (visibility: boolean) => setVisible(visibility);

  useEffectExceptOnMount(() => {
    Track.interact("Well Dashboard - Indicators", {
      Selection: selectedIndicators,
    });
  }, [selectedIndicators]);

  const { atomThemeVariant } = useCustomTheme();

  return (
    <Tooltip title="Indicators">
      <Popover
        content={
          <Space
            direction="vertical"
            size={10}
            style={{
              padding: "12px 16px",
            }}
          >
            <Title level={3} variant={atomThemeVariant} weight={500}>
              Indicators
            </Title>
            <Checkbox.Group
              onChange={(selection) =>
                dispatch({
                  type: "SET_SELECTED_INDICATORS",
                  payload: new Set(selection as Array<IndicatorsState>),
                })
              }
              value={Array.from(selectedIndicators)}
            >
              <StyledSpace direction="vertical" size={8}>
                {Object.keys(IndicatorsState).map((indicator, idx) => (
                  <Checkbox key={`${indicator}_${idx}`} value={indicator}>
                    <Title
                      level={3}
                      variant={
                        selectedIndicators.has(
                          indicator as unknown as IndicatorsState,
                        )
                          ? atomThemeVariant
                          : "faded"
                      }
                      style={{ display: "inline-block" }}
                    >
                      {getString(indicator as unknown as IndicatorsState)}
                    </Title>
                  </Checkbox>
                ))}
              </StyledSpace>
            </Checkbox.Group>
          </Space>
        }
        placement="bottom"
        trigger={props.disabled ? [] : "click"}
        open={visible}
        onOpenChange={handleVisibleChange}
        destroyTooltipOnHide
      >
        <Button
          disabled={props.disabled}
          icon={props.icon}
          $engaged={visible}
          type={selectedIndicators.size !== 2 ? "primary" : "default"}
          ghost={selectedIndicators.size !== 2}
        />
      </Popover>
    </Tooltip>
  );
};
