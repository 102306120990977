import { StackedKpiComparison } from "components/Lenses/ContainerLens/StackedKpi/StackedKpiComparison";
import { StackedKpiSimple } from "components/Lenses/ContainerLens/StackedKpi/StackedKpiSimple";
import type { StackedKpiProps } from "components/Lenses/interfaces";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import React from "react";

export const StackedKPI = (props: StackedKpiProps) => {
  const [comparisonWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );
  const isComparing = comparisonWells?.length > 0;

  return isComparing ? (
    <StackedKpiComparison {...props} />
  ) : (
    <StackedKpiSimple {...props} />
  );
};

export default StackedKPI;
