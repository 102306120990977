import type {
  KpiGroupUserLensDto,
  KpiTypeUserLensDto,
  UserLensDto,
} from "apis/oag";
import { useKpiGroups } from "hooks/drillingInvariants/useKPIGroups";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";

export function useKpiOptions(lens: UserLensDto) {
  const { data: kpiGroups } = useKpiGroups();
  const { data: kpiTypes } = useKpiTypes();

  const kpiType = kpiTypes?.byId?.[(lens as KpiTypeUserLensDto).kpiTypeId];
  const kpiGroup = kpiGroups?.byId?.[(lens as KpiGroupUserLensDto).kpiGroupId];
  return { kpiType, kpiGroup };
}
