import type { QueryObserverOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { AllRigsQueryDto, RigSummaryDto } from "apis/oag";
import { RigsApi } from "apis/oag";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { RigTechnology } from "utils/enums";
import type { PDRigsSummariesQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const rigs = new RigsApi(apiConfig);

export function useRigSummariesNotifications(
  options?: QueryObserverOptions<RigSummaryDto[]>,
) {
  const filterState = useAppSelector((state) => state.notifications.filters);

  const requestQuery: AllRigsQueryDto = {
    from: defaultDateDto.from,
    to: defaultDateDto.to,
    selectedFilters: {
      includeFlatTime: true,
      includeRotatingTime: true,
      includeSlidingTime: true,
      includeNullHoleDepth: true,
      includeAlphaRigs: !!filterState?.technology
        .map((tech) => tech.id)
        .includes(RigTechnology.Alpha),
      includeNonAlphaRigs: !!filterState?.technology
        .map((tech) => tech.id)
        .includes(RigTechnology.NonAlpha),
      selectedClasses: filterState?.rigType
        ? filterState?.rigType.map((t) => (t.id || -1).toString())
        : null,
      selectedCountryIds: filterState?.country
        ? filterState?.country.map((c) => +(c.id || -1))
        : null,
      selectedHorsePowers: filterState?.horsePower.map((hp) => +(hp.id || -1)),
      selectedOperatingCenters: filterState?.operatingCenter.map((oc) =>
        (oc.id || -1).toString(),
      ),
    },

    includeFullWellFacts: false,
  };

  const RigsSummariesQueryKey: PDRigsSummariesQueryKey = {
    uid: RequestUID.rigsSummaries,
    params: requestQuery,
    type: PDQueryType.RIGS_SUMMARIES,
  };

  const request = useQuery<RigSummaryDto[]>({
    queryKey: [RigsSummariesQueryKey],
    queryFn: () =>
      rigs.apiRigsSummariesPut({
        allRigsQueryDto: requestQuery,
      }),
    ...(options ?? {}),
  });

  return request;
}
