import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import {
  BORDER_WIDTH,
  CHART_CONTAINER_X,
  CONTROLS_CONTAINER_WIDTH,
  LEFT_SIDEBAR_WIDTH,
  MODAL_HEIGHT_PX,
  STATUS_BAR_HEIGHT,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from "components/Lenses/common/InspectionView/utils";
import styled from "styled-components";
import colors from "utils/colors";
import { Col, Row } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

export const StyledDistanceDuration = styled.div`
  margin-top: 12px;
`;
export const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  & + & {
    margin-top: 12px;
  }
`;

export const LineLegend = styled.hr<{
  backgroundColor: string;
  dashed?: boolean;
}>`
  width: 18px;
  margin-left: -8px;
  height: 1px;
  border: 0px;
  border-top: 1px ${({ dashed }) => (dashed ? "dotted" : "solid")}
    ${({ backgroundColor }) => backgroundColor};
`;

export const ColLine = styled.div<{ isVisible?: boolean }>`
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0.3)};
`;

export const StyledDetailsCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  width: ${LEFT_SIDEBAR_WIDTH}px;
  flex-wrap: nowrap;
  border-right: 1px solid
    ${({ theme }) => theme.themeStyle.colors.secondary_chart_accent};
  padding: 16px 24px;
`;

export const StyledChartCol = styled(Col)`
  width: ${CHART_CONTAINER_X}px;
`;

export const StyledBottomAxisDiv = styled.div`
  display: flex;
  height: ${X_AXIS_HEIGHT}px;
  width: ${CHART_CONTAINER_X}px;
  position: relative;

  border-bottom: 1px solid
    ${({ theme }) => theme.themeStyle.colors.inspection_view_border};
  border-right: 1px solid
    ${({ theme }) => theme.themeStyle.colors.inspection_view_border};
`;

export const StyledRightContainerDiv = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 36px);
`;

export const StyledYAxisDiv = styled.div<{
  left?: number;
  leftBorder?: boolean;
}>`
  width: ${Y_AXIS_WIDTH}px;
  top: ${STATUS_BAR_HEIGHT}px;
  height: calc(100% - ${X_AXIS_HEIGHT + STATUS_BAR_HEIGHT}px);
  border-right: ${BORDER_WIDTH}px solid
    ${({ theme }) => theme.themeStyle.colors.secondary_chart_accent};
  border-left: ${({ theme, leftBorder }) =>
    leftBorder
      ? `${BORDER_WIDTH}px solid ${theme.themeStyle.colors.secondary_chart_accent}`
      : "none"};
  position: absolute;
  left: ${({ left }) => (left ? `${left}px` : "0")};
`;

export const StyledXAxisLabelContainer = styled.div`
  width: ${2 * Y_AXIS_WIDTH}px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const StyledInspectionContainer = styled.div`
  border: 1px solid
    ${({ theme }) => theme.themeStyle.colors.inspection_view_border};
  background: ${({ theme }) => theme.themeStyle.colors.alternate_bg};
  overflow: hidden;
  border-radius: 6px;
  outline: none;
  min-height: ${MODAL_HEIGHT_PX}px;
  width: 100%;
`;

export const StyledRow = styled(Row)`
  height: 460px;
  width: 100%;
  background-color: ${({ theme }) => theme.themeStyle.colors.alternate_bg};
  border-radius: 8px;
  box-sizing: content-box;
`;

export const StyledSvg = styled.svg<{ top?: number; bordered?: boolean }>`
  overflow: visible;
  user-select: none;
  z-index: ${zIndexLayer.elevated};
  position: absolute;
  top: ${({ top }) => (top ? `${top}px` : "auto")};
  border-bottom: ${({ bordered, theme }) =>
    bordered
      ? `${BORDER_WIDTH}px solid ${theme.themeStyle.colors.secondary_chart_accent}`
      : "none"};
`;

export const StyledLegendGroup = styled.div<{ top?: number }>`
  margin-top: ${({ top }) => (top ? `${top}px` : "auto")};
  margin-left: 8px;
`;

export const StyledTitle = styled(Title)`
  user-select: none;
  pointer-events: none;
`;

export const StyledChartBackground = styled.div`
  background: ${(theme) =>
    theme.theme.isDark ? "transparent" : colors.default_bg};
  position: absolute;
  left: ${2 * Y_AXIS_WIDTH}px;
  width: ${CHART_CONTAINER_X - 4 * Y_AXIS_WIDTH}px;
  height: calc(100% - ${X_AXIS_HEIGHT}px);
  z-index: ${zIndexLayer.base};
`;

export const StyledControlsContainer = styled.div<{
  top: number;
  left: number;
}>`
  position: absolute;
  top: ${({ top }) => top + 12}px;
  left: ${({ left }) => left - CONTROLS_CONTAINER_WIDTH}px;
`;

export const StyledControlsButtom = styled(Button)`
  margin-left: 2px;

  &:not([disabled]) {
    background-color: ${({ theme }) =>
      theme.isDark
        ? theme.themeStyle.colors.secondary_bg
        : theme.themeStyle.colors.off_secondary_bg} !important;
  }

  &[disabled] {
    background-color: ${({ theme }) =>
      theme.isDark
        ? theme.themeStyle.colors.off_secondary_bg
        : theme.themeStyle.colors.secondary_bg}!important;
  }
`;

export const ResetContainer = styled.div`
  position: absolute;
  top: 30px;
  right: ${Y_AXIS_WIDTH * 2 + 5}px;
  z-index: ${zIndexLayer.mars};
`;
