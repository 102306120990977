import { useQueryClient } from "@tanstack/react-query";
import type { ScorecardKpiCommentExceptionInfoDto } from "apis/oag";
import {
  DashboardType,
  DimensionType,
  NotificationType,
  RigScorecardLensType,
  ScorecardExceptionStatusType,
  TimeUnit,
} from "apis/oag";
import { Title } from "atoms/Typography";
import { Chart } from "components/Notification/Chart";
import { IconWrapperTasks } from "components/Notification/style";
import { PDComponent } from "components/PDComponents";
import dayjs from "dayjs";
import { useUserDashboard } from "hooks/dashboard/useUserLensTabs";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { URL_STATE_PARAM } from "hooks/navigation/useQueryState";
import { useDetailedNotification } from "hooks/notifications/useDetailedNotification";
import { useDetailedNotificationsActions } from "hooks/notifications/useDetailedNotificationsActions";
import {
  TasksCountQueryKey,
  TasksPendingQueryKey,
} from "hooks/notifications/useTasks";
import { useWellShortInfo } from "hooks/wells/useWellShortInfo";
import { cardKpiTypesMap } from "pages/FleetPerformance/components/utils";
import * as Styled from "pages/Notifications/components/style";
import { GroupTitle } from "pages/RigScoreCard/LeftPane/components/GroupTitle";
import { useScoreCardTags } from "pages/RigScoreCard/LeftPane/utils";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Track } from "services/Mixpanel";
import {
  FULL_YEAR_DATE_FORMAT,
  useTimeUom,
  useUOM,
  UtilDimensions,
} from "utils/format";
export const ModalBodyTask: React.FC<{
  task: ScorecardKpiCommentExceptionInfoDto | null;
}> = ({ task: selectedNotification }) => {
  const { data: rigs } = useRigs();
  const { data: wellShortInfo } = useWellShortInfo();
  const rig = selectedNotification
    ? rigs.byId[selectedNotification?.rigId]
    : null;
  const well = selectedNotification
    ? wellShortInfo?.byId[selectedNotification?.wellId]
    : null;

  const { data: detailedNotification } = useDetailedNotification(
    selectedNotification?.id,
  );
  const queryClient = useQueryClient();
  const rigCardFactSeries = useMemo(
    () => detailedNotification?.factSet?.cardGroups?.[0]?.facts?.[0] ?? null,
    [detailedNotification?.factSet],
  );

  const isWellScoreCard =
    detailedNotification?.comment?.scorecardType ===
    RigScorecardLensType.OverallWellScores;

  const defaultUom = useUOM(rigCardFactSeries?.dimension);
  const percentageUom = useUOM(UtilDimensions.Percentage);
  const dayUom = useTimeUom(TimeUnit.Day);
  const hoursUOM = useTimeUom(TimeUnit.Hour);

  const uom = useMemo(() => {
    if (isWellScoreCard) return percentageUom;
    if (rigCardFactSeries?.dimension === DimensionType.Undefined)
      return percentageUom;
    if (rigCardFactSeries?.kpiType === cardKpiTypesMap.LocationRigMoveTime)
      return dayUom;
    if (
      rigCardFactSeries?.kpiType &&
      [
        cardKpiTypesMap.WalkSpudTime,
        cardKpiTypesMap.WalkTime,
        cardKpiTypesMap.NippleTimeSurface,
        cardKpiTypesMap.NippleTimeIntermediate,
        cardKpiTypesMap.NippleTimeTotal,
        cardKpiTypesMap.PressureTestTimeIntermediate,
        cardKpiTypesMap.PressureTestTimeSurface,
        cardKpiTypesMap.PressureTestTimeTotal,
        cardKpiTypesMap.SlipCutTime,
      ].includes(rigCardFactSeries?.kpiType)
    )
      return hoursUOM;

    return defaultUom;
  }, [
    isWellScoreCard,
    percentageUom,
    rigCardFactSeries?.dimension,
    rigCardFactSeries?.kpiType,
    dayUom,
    hoursUOM,
    defaultUom,
  ]);

  const { data: kpiTypes } = useKpiTypes();
  const lensName = useMemo(() => {
    if (isWellScoreCard) {
      return "Well Scores";
    }
    const lens = detailedNotification?.comment?.kpiType
      ? kpiTypes?.byId?.[detailedNotification?.comment?.kpiType]
      : null;

    return lens?.shortName || lens?.name || "";
  }, [isWellScoreCard, detailedNotification?.comment?.kpiType, kpiTypes?.byId]);

  const { data: lensTabsDashboard } = useUserDashboard(
    DashboardType.RigScorecard,
  );
  const tabTitle =
    useMemo(
      () =>
        [
          ...(lensTabsDashboard?.operatorTabs || []),
          ...(lensTabsDashboard?.personalTabs || []),
        ].find(
          (t) => t.id === detailedNotification?.factSet?.cardGroups?.[0]?.tabId,
        ),
      [
        detailedNotification?.factSet?.cardGroups,
        lensTabsDashboard?.operatorTabs,
        lensTabsDashboard?.personalTabs,
      ],
    )?.name ?? "";

  const urlRoutingIds = useMemo(
    () => ({
      cardGroupId: detailedNotification?.factSet?.cardGroups?.[0].tabId,
      kpiId: rigCardFactSeries?.kpiType,
    }),
    [detailedNotification?.factSet?.cardGroups, rigCardFactSeries?.kpiType],
  );

  const shouldShowControls =
    detailedNotification?.comment?.exceptionStatus ===
    ScorecardExceptionStatusType.Pending;

  const softRemoveActionedItemFromList = useCallback(() => {
    queryClient.setQueryData(
      [TasksPendingQueryKey],
      (oldData: ScorecardKpiCommentExceptionInfoDto[]) => {
        return oldData.filter((task) => task.id !== selectedNotification?.id);
      },
    );
  }, [queryClient, selectedNotification?.id]);

  const { handleOnReject: onReject, handleOnApprove: onApprove } =
    useDetailedNotificationsActions(detailedNotification?.comment?.id || -1);

  const handleOnReject = useCallback(() => {
    onReject().then(() => {
      queryClient.invalidateQueries({ queryKey: [TasksCountQueryKey] });
    });
    softRemoveActionedItemFromList();
  }, [onReject, queryClient, softRemoveActionedItemFromList]);

  const handleOnApprove = useCallback(() => {
    onApprove().then(() => {
      queryClient.invalidateQueries({ queryKey: [TasksCountQueryKey] });
    });
    softRemoveActionedItemFromList();
  }, [onApprove, queryClient, softRemoveActionedItemFromList]);

  const { getTagsByAveragesOrFact } = useScoreCardTags({ borderless: true });
  const tags = useMemo(() => {
    const tags = getTagsByAveragesOrFact({
      averages: detailedNotification?.factSet?.cardGroups?.[0]?.averages,
    });

    return isWellScoreCard
      ? [tags.score, tags.opportunityTime]
      : [tags.score, tags.opportunityTime, tags.timeSaved];
  }, [
    getTagsByAveragesOrFact,
    detailedNotification?.factSet?.cardGroups,
    isWellScoreCard,
  ]);

  if (!selectedNotification) return null;

  const detailedNotificationTimestamp =
    detailedNotification?.comment?.updatedAt?.utc ??
    detailedNotification?.comment?.createdAt?.utc;
  return (
    <>
      <Styled.ModalLeft>
        <Styled.RigTitleContainer>
          <Styled.NotificationTitle
            $isRead={false}
            $notificationType={NotificationType.ExceptionRequest}
          >
            <IconWrapperTasks>
              <PDComponent.SvgIcon name="tag" style={{ fontSize: 18 }} />
            </IconWrapperTasks>
          </Styled.NotificationTitle>
          <Title level={3}>
            <strong>{rig?.shortName}</strong>
          </Title>
        </Styled.RigTitleContainer>
      </Styled.ModalLeft>
      <Styled.ModalRight>
        <Styled.ModalTopContainer>
          <Styled.InfoContainer>
            <Styled.TitleContainer>
              <Styled.TaskTitle>Pending Exception Request</Styled.TaskTitle>
              <Styled.WellContainer>
                <Styled.WellName>
                  <Title level={3}>
                    <strong>{well?.name}</strong>
                    {(selectedNotification?.jobExternalIds || [])?.length >
                    0 ? (
                      <Styled.JobDetailed>
                        <Title level={3} variant="faded">
                          Job:{" "}
                          {(selectedNotification?.jobExternalIds || [])?.join(
                            ", ",
                          )}
                        </Title>
                      </Styled.JobDetailed>
                    ) : null}
                  </Title>
                </Styled.WellName>
              </Styled.WellContainer>

              <Styled.KpiTitle>
                {tabTitle}{" "}
                {detailedNotification?.comment?.scorecardType ===
                RigScorecardLensType.VerticalOnlyNoSurface
                  ? "(Excluding Surface)"
                  : ""}
              </Styled.KpiTitle>

              <Styled.LensTitle>
                {lensName}:{" "}
                {uom.display(
                  isWellScoreCard
                    ? detailedNotification?.factSet?.cardGroups?.[0]?.facts?.[0]
                        .facts?.[0].operatorScore?.value ?? 0
                    : detailedNotification?.factSet?.cardGroups?.[0]?.facts?.[0]
                        .facts?.[0].kpiValue?.value ?? 0,
                )}
              </Styled.LensTitle>
              {!isWellScoreCard ? (
                <Styled.KpiSummaryContainer>
                  <Styled.SummaryItem>
                    <Styled.SummaryTitle>Target: </Styled.SummaryTitle>
                    <Styled.SummaryValue>
                      {uom.display(
                        detailedNotification?.factSet?.cardGroups?.[0]?.averages
                          ?.targetValue,
                      )}
                    </Styled.SummaryValue>
                  </Styled.SummaryItem>
                  <Styled.SummaryItem>
                    <Styled.SummaryTitle>Base Value: </Styled.SummaryTitle>
                    <Styled.SummaryValue>
                      {uom.display(
                        detailedNotification?.factSet?.cardGroups?.[0]?.averages
                          ?.baseValue,
                      )}
                    </Styled.SummaryValue>
                  </Styled.SummaryItem>
                </Styled.KpiSummaryContainer>
              ) : null}
              <GroupTitle inner title={""} withTagSeparators tags={tags} />
              <Styled.SeparatorLine />
            </Styled.TitleContainer>
          </Styled.InfoContainer>
          <Styled.ModalChartContainer>
            <Chart
              notificationId={selectedNotification?.id}
              lensName={lensName}
              facts={detailedNotification?.factSet}
              uom={uom}
              isWellScoreCard={isWellScoreCard}
            />
            <Styled.ScorecardButton>
              <Link
                to={{
                  pathname: `/rig/scorecard/${selectedNotification?.rigId}`,
                  search: [
                    `${URL_STATE_PARAM.ADDITIONAL_SELECTED_WELLS_RIG_SCORECARD}=${selectedNotification?.wellId}`,
                    `${URL_STATE_PARAM.NOTIFICATION_SCORE_CARD_GROUP_ID}=${urlRoutingIds.cardGroupId}`,
                    `${URL_STATE_PARAM.NOTIFICATION_SCORE_CARD_KPI_ID}=${urlRoutingIds.kpiId}`,
                  ].join("&"),
                }}
                onClick={() => {
                  Track.clickEvent("Notification - Go to Scorecard", {
                    "Notification Id": selectedNotification?.id,
                    "Notification Type": NotificationType.ExceptionRequest,
                    "Notification Title": selectedNotification?.title,
                  });
                }}
                target="_blank"
              >
                Go to Scorecard <PDComponent.SvgIcon name="newTab" />
              </Link>
            </Styled.ScorecardButton>
          </Styled.ModalChartContainer>
        </Styled.ModalTopContainer>
        <Styled.ModalCommentContainer>
          <Styled.ListNoteExceptionStatus>
            <Styled.AuthorName>
              {detailedNotification?.comment.authorDisplayName}
            </Styled.AuthorName>
            <Styled.CommentDate>
              {detailedNotificationTimestamp
                ? dayjs(detailedNotificationTimestamp).format(
                    FULL_YEAR_DATE_FORMAT,
                  )
                : null}
            </Styled.CommentDate>

            <Styled.Description>
              {selectedNotification?.description}
            </Styled.Description>

            <Styled.StatusText>
              <Styled.NoWrap>
                Hours:{" "}
                <b>
                  {hoursUOM.display(
                    detailedNotification?.comment?.exceptionTime,
                    { fractionDigits: 2 },
                  )}
                </b>
              </Styled.NoWrap>
              <Styled.OverflowEllipsis>
                Approver:{" "}
                <b>
                  {detailedNotification?.comment?.exceptionApproverDisplayName}
                </b>
              </Styled.OverflowEllipsis>
            </Styled.StatusText>

            {shouldShowControls ? (
              <Styled.ApprovalActions>
                <Styled.RejectButton
                  size="middle"
                  type="primary"
                  onClick={handleOnReject}
                >
                  Reject
                </Styled.RejectButton>
                <Styled.ApproveButton
                  size="middle"
                  type="primary"
                  onClick={handleOnApprove}
                >
                  Approve
                </Styled.ApproveButton>
              </Styled.ApprovalActions>
            ) : null}
          </Styled.ListNoteExceptionStatus>
        </Styled.ModalCommentContainer>
      </Styled.ModalRight>
    </>
  );
};
