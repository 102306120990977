import { SummaryBlock } from "components/Lenses/common/KpiSummaries/SummaryBlock";
import type { IKPIValue } from "pages/Lens/LensSummaryView";

export function SecondaryKpiSummary({
  kpis,
  detailed,
}: {
  kpis: IKPIValue[];
  detailed?: boolean;
}) {
  return (
    <>
      {kpis.map((kpi) => (
        <SummaryBlock
          key={kpi.name + kpi.main}
          title={kpi.name}
          main={kpi.main}
          secondary={kpi.secondary}
          titleLevel={detailed ? 1 : 2}
        />
      ))}
    </>
  );
}
