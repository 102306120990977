import { MIN_TICKS_X_AXIS } from "utils/constants";

export const getXAxisTickCount = ({
  itemsCount,
  categoryBandwidth,
  maxBottomTickWidth = 60,
}: {
  itemsCount: number;
  categoryBandwidth: number;
  maxBottomTickWidth?: number;
}) => {
  return Math.min(
    itemsCount,
    Math.max(
      MIN_TICKS_X_AXIS,
      (categoryBandwidth / (maxBottomTickWidth ?? 60)) * itemsCount,
    ),
  );
};
