import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type {
  HoleSectionDto,
  PhaseDto,
  PlanActivityDto,
  PlanDto,
} from "apis/oag";
import { DimensionType } from "apis/oag";
import { Select } from "atoms/Form";
import { Input } from "components/WellPlan/Input";
import type { WellPointType } from "components/WellPlan/types";
import IconComponent from "components/WellPlan/WellPlanCommun/Icon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IUnitSystem } from "reducers/types";
import { Track } from "services/Mixpanel";
import { ftInM, secondsInDay, secondsInHour } from "utils/common";
import { Col, Row } from "utils/componentLibrary";
import { useUOM } from "utils/format";
import type { IPlanActivities } from "utils/wellplan/errors";

export const OverviewRow = ({
  type,
  errors,
  setPlan,
  activity,
  onDescriptionClick,
  onDeleteClick,
  setPlanModified,
  sectionData,
  phaseData,
  selection,
  initialStartDepth,
}: {
  errors: any;
  type: WellPointType;
  sectionData: HoleSectionDto[];
  phaseData: PhaseDto[];
  activity?: IPlanActivities;
  onDescriptionClick?: () => void;
  onDeleteClick?: () => void;
  setPlan: React.Dispatch<React.SetStateAction<PlanDto>>;
  setPlanModified: React.Dispatch<React.SetStateAction<boolean>>;
  initialStartDepth?: number;
  selection: string;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: `${activity?.id}`,
      disabled: !activity,
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [state, setState] = useState<PlanActivityDto | undefined>(activity);
  const [dropdownOpen, setSetDropdownOpen] = useState(false);
  const [sectionId, setSectionId] = useState<number | undefined>(
    activity?.sectionId ?? undefined,
  );

  const uom = useUOM(DimensionType.Metres);

  // TODO convert to map, quadratic complexity bad
  const sectionFromPhase = useMemo(
    () => sectionData.find((section) => section.id === sectionId),
    [sectionData, sectionId],
  );

  useEffect(() => {
    if (!state?.phaseId) return;
    const newId = phaseData.find(
      (phase) => phase.id === state.phaseId,
    )?.sectionId;
    if (Number.isFinite(sectionId)) {
      setState((prevState) =>
        prevState
          ? {
              ...prevState,
              sectionId: Number.isFinite(sectionId) ? sectionId : undefined,
            }
          : undefined,
      );
      setPlan((prevPlan) => ({
        ...prevPlan,
        activities: prevPlan.activities.map((item: any) => {
          if (item.id === activity?.id) {
            return {
              ...item,
              sectionId: Number.isFinite(sectionId) ? sectionId : undefined,
            };
          }
          return item;
        }),
      }));
      setPlanModified(true);
    }
    if (sectionId === undefined && Number.isFinite(newId)) {
      setSectionId(newId);
    }
  }, [
    activity?.id,
    phaseData,
    sectionId,
    setPlan,
    setPlanModified,
    state?.phaseId,
  ]);

  const setNumericValue = (value: number | undefined, key: string) => {
    setState((state) => (state ? { ...state, [key]: value } : undefined));
    setPlan((prevPlan) => ({
      ...prevPlan,
      activities: prevPlan.activities.map((item: any) => {
        if (item.id === activity?.id) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      }),
    }));
    setPlanModified(true);
  };

  const toSI = useCallback(
    (value: number) => {
      if (uom.currentUOM === IUnitSystem.METRIC || !value) return value;
      return value / ftInM;
    },
    [uom],
  );

  const fromSI = useCallback(
    (value: number) => {
      if (uom.currentUOM === IUnitSystem.METRIC || !value) return value;
      return value * ftInM;
    },
    [uom],
  );

  const toSITime = useCallback(
    (e: number) => {
      if (selection.toLocaleLowerCase() === "days") return e * secondsInDay;
      else if (selection.toLocaleLowerCase() === "hours")
        return e * secondsInHour;
    },
    [selection],
  );
  const fromSITime = useCallback(
    (e: number) => {
      if (selection.toLocaleLowerCase() === "days") return e / secondsInDay;
      else if (selection.toLocaleLowerCase() === "hours")
        return e / secondsInHour;
    },
    [selection],
  );

  useEffect(() => {
    const sectionFromPhase = phaseData.find(
      (phase) => phase.id === activity?.phaseId,
    )?.sectionId;
    if (sectionId === undefined && sectionFromPhase) {
      setSectionId(sectionFromPhase);
    }
  }, [activity?.phaseId, phaseData, sectionId]);

  return (
    <Row
      justify="space-between"
      gutter={8}
      ref={setNodeRef}
      {...attributes}
      style={style}
    >
      <Col flex="1">
        <Select
          onBlur={() => setSetDropdownOpen(false)}
          placeholder="Section"
          open={type !== "StartDepth" && dropdownOpen}
          disabled={type === "StartDepth"}
          onClick={() => setSetDropdownOpen((open) => !open)}
          style={{ width: "100%" }}
          size="large"
          value={
            type === "StartDepth"
              ? "Start Depth"
              : sectionFromPhase?.name ||
                sectionData.find(
                  (section) => section.id === activity?.sectionId,
                )?.name
            // TODO think about expanding the activity type
          }
          error={activity?.id ? errors?.[activity.id]?.sectionId : null}
          showArrow={type !== "StartDepth"}
          notFoundContent={null}
          onSelect={(idStr) => {
            const id = +(idStr || "");
            Track.interact("Well Overview - Section", {
              "Section ID": idStr,
              "Section Name": sectionData.find((section) => section.id === id)
                ?.name,
            });
            setSectionId(id);
            setState((prevState) =>
              prevState ? { ...prevState, sectionId: id } : undefined,
            );
            setPlan((prevPlan) => ({
              ...prevPlan,
              activities: prevPlan.activities.map((item) =>
                item.id === activity?.id ? { ...item, sectionId: id } : item,
              ),
            }));
            setPlanModified(true);
          }}
        >
          {type !== "StartDepth" &&
            sectionData.map(
              (data) =>
                data.id !== 1 && (
                  <Select.Option key={data.id} value={data.id}>
                    {data.name}
                  </Select.Option>
                ),
            )}
        </Select>
      </Col>
      <Col flex="1">
        <Select
          disabled={type === "StartDepth"}
          showArrow={type !== "StartDepth"}
          placeholder={type === "StartDepth" ? "—" : "Phase"}
          style={{ width: "100%" }}
          size="large"
          error={activity?.id ? errors?.[activity.id]?.phaseId : null}
          value={
            type === "StartDepth"
              ? "Not available"
              : phaseData.find((phase: any) => phase.id === activity?.phaseId)
                  ?.name
          }
          onSelect={(idStr) => {
            const id = +(idStr || "");
            Track.interact("Well Overview - Phase", {
              "Phase ID": idStr,
              "Phase Name": phaseData.find((phase) => phase.id === id)?.name,
            });
            setState((stateNew) => {
              return stateNew ? { ...stateNew, phaseId: id } : undefined;
            });
            setPlan((prevPlan) => ({
              ...prevPlan,
              activities: prevPlan.activities.map((item1: any) =>
                item1.id === activity?.id ? { ...item1, phaseId: id } : item1,
              ),
            }));
            setPlanModified(true);
          }}
        >
          {phaseData
            .filter((phase) => phase.sectionId === sectionId)
            .map((value) => {
              return (
                <Select.Option key={value.id} value={value.id}>
                  {value.name}
                </Select.Option>
              );
            })}
        </Select>
      </Col>
      <Col span={4}>
        <Input
          disabled={type === "StartDepth"}
          type="number"
          placeholder={
            type === "StartDepth"
              ? "—"
              : [selection[0].toUpperCase(), ...selection.slice(1)].join("")
          }
          size="large"
          error={
            type !== "StartDepth" && activity?.id
              ? errors?.[activity.id]?.duration
              : null
          }
          tosi={toSITime}
          fromsi={fromSITime}
          uom={selection}
          decimals={2}
          initialvalue={activity?.duration}
          updatevalue={(value) => {
            setNumericValue(value, "duration");
            setPlanModified(true);
          }}
        />
      </Col>
      <Col span={4}>
        <Input
          placeholder={
            uom.currentUOM === IUnitSystem.IMPERIAL ? "Feet" : "Meters"
          }
          type="number"
          size="large"
          isStartDepth={type === "StartDepth"}
          error={
            type === "StartDepth"
              ? errors?.startDepth
              : activity?.id && errors?.[activity.id]?.endHoleDepth
          }
          initialvalue={
            type === "StartDepth" ? initialStartDepth : activity?.endHoleDepth
          }
          tosi={toSI}
          fromsi={fromSI}
          decimals={2}
          updatevalue={(value) => {
            if (type === "StartDepth") {
              setPlan((prevPlan) => ({
                ...prevPlan,
                startHoleDepth: value,
              }));
              setPlanModified(true);
              return;
            }
            setNumericValue(value, "endHoleDepth");
          }}
        />
      </Col>
      <Col flex="0 auto">
        <Row gutter={8}>
          <Col span={8}>
            <IconComponent
              name={activity?.description ? "chat" : "addComment"}
              active={!!activity?.description}
              disabled={type === "StartDepth"}
              onClick={onDescriptionClick}
            />
          </Col>
          <Col span={8}>
            <IconComponent
              name="delete"
              disabled={type === "StartDepth"}
              onClick={() => {
                Track.interact("Well Overview - Delete");
                if (onDeleteClick) {
                  onDeleteClick();
                }
              }}
            />
          </Col>
          <Col span={8}>
            <IconComponent
              name="move"
              iconProps={{ ...listeners }}
              disabled={type === "StartDepth"}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
