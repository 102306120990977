import type { StatCardUserLensDto } from "apis/oag";
import type { ContainerLensProps } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { StatCardChart } from "components/Lenses/ContainerLens/StatCard/Chart";

interface StatCardProps extends ContainerLensProps {
  lens: StatCardUserLensDto;
}

export const StatCard: React.FC<StatCardProps> = ({
  detailed,
  graphKey,
  lens,
  isLocked,
  onLensUpdated,
}) => {
  return (
    <StatCardChart
      detailed={detailed}
      onLensUpdated={onLensUpdated}
      graphKey={graphKey}
      lens={lens}
      isLocked={isLocked}
    />
  );
};
