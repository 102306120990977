import { Group } from "@visx/group";
import { Pie } from "@visx/shape";
import type {
  PowerLoadefficiencyFactDto,
  PowerLoadEfficiencyUserLensDto,
} from "apis/oag";
import * as Styled from "components/Lenses/ContainerLens/PowerLoadEfficiency/Chart/styles";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { useOperationColors } from "hooks/evergreen/useOperationColors";
import { useLensSize } from "hooks/lens/useLensSize";
import { useResizeDetector } from "react-resize-detector";
import { useCustomTheme } from "utils/useTheme";

export default function PowerEfficiencyChart({
  lens,
  data,
}: {
  lens: PowerLoadEfficiencyUserLensDto;
  data: PowerLoadefficiencyFactDto;
}) {
  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: containerRef,
  } = useResizeDetector();
  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  const chartMargin =
    (chartWidth < chartHeight ? chartWidth : chartHeight) / 20;
  const availableWidth = chartWidth / 2 - chartMargin;
  const availableHeight = chartHeight - chartMargin * 2;

  const outerRadius =
    availableWidth < availableHeight ? availableWidth : availableHeight;
  const { getOperationColor } = useOperationColors();

  const [, height] = useLensSize(lens.id);
  return (
    <Styled.ChartContainer ref={containerRef}>
      <svg
        width={chartWidth}
        height={chartHeight}
        style={{ overflow: "hidden", userSelect: "none" }}
      >
        <Group>
          <Group top={chartHeight} left={chartWidth / 2}>
            <Pie
              data={[
                {
                  value: data.proportion,
                  fill: lens.selectedOperationClassId
                    ? getOperationColor(lens.selectedOperationClassId)
                    : themeColors.primary_wedge_arc,
                },
                {
                  value: 1 - data.proportion,
                  fill: themeColors.primary_wedge_bg,
                },
              ]}
              pieValue={(d) => d.value}
              pieSort={null}
              outerRadius={outerRadius}
              innerRadius={outerRadius / 2}
              startAngle={-(Math.PI / 2)}
              endAngle={Math.PI / 2}
            >
              {(pie) => {
                return pie.arcs.map((arc, index) => {
                  return (
                    <g key={`arc-${index}`}>
                      <path
                        d={pie.path(arc) || undefined}
                        fill={arc.data.fill}
                      />
                    </g>
                  );
                });
              }}
            </Pie>
          </Group>
          <text
            x={chartWidth / 2}
            y={chartHeight - chartMargin}
            textAnchor="middle"
            fill={themeColors.primary_typography}
            fontSize={height < 3 ? outerRadius / 5 : outerRadius / 8}
            fontWeight="bold"
          >
            {Math.round(data.proportion * 100)}%
          </text>
        </Group>
      </svg>
    </Styled.ChartContainer>
  );
}
