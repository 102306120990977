import type { linkedFilters } from "hooks/filters/useFilterTags";
import type { IconName } from "icons/autogeneratedTypes";

export const getCombinedFilters = <T>({
  selectedItems,
  options,
}: {
  selectedItems: T[];
  options: T[] | undefined;
}) => {
  if (!options) return selectedItems;
  // sad that i can t use the intersection operator
  return selectedItems?.filter((item) => options.includes(item)) ?? [];
};

export const linkedFiltersIconMap: Record<
  (typeof linkedFilters)[number],
  IconName
> = {
  rigs: "rig",
  operators: "identification",
  formations: "formation",
  wells: "wellInfo",
};

export const popupInitialState: Record<
  (typeof linkedFilters)[number],
  boolean
> = {
  formations: false,
  operators: false,
  rigs: false,
  wells: false,
};
