import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type {
  ApiWedgeUserLensesIdFactsPutRequest,
  WedgeComparisonDto,
  WedgeValueDto,
} from "apis/oag";
import { WedgeUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls/index";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useAppDispatch, useAppSelector } from "reducers/store";
import {
  type IFiltersType,
  initialFilters,
  type IZoomData,
} from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, TVD_SAMPLE_COUNT } from "utils/common";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new WedgeUserLensesApi(apiConfig);

interface IFlatten {
  [key: `${keyof WedgeValueDto}_${number}`]: number | undefined;
}
// TODO this function needs to be removed
// TODO this is too complicated, please refactor
export const wedgeDataTransformation = (facts: WedgeComparisonDto) => {
  if (!facts.comparisons) {
    return { wells: [] as number[], lastUpdated: null, values: [], ...facts };
  }
  const flattenedValues: Array<IFlatten> = [];

  facts.comparisons?.[0]?.values?.forEach((e, i) => {
    const flattValue: IFlatten = {};
    (facts.comparisons || []).forEach((f) => {
      flattValue[`value_${f.wellId}`] = f.values?.[i].value;
      flattValue[`proportion_${f.wellId}`] = f.values?.[i].proportion;
    });
    flattenedValues.push(flattValue);
  });
  const base = {
    wells: facts.comparisons.map((e) => e.wellId),
    lastUpdated: facts.lastUpdatedAt,
    values: flattenedValues || [],
    ...facts,
  };

  return base;
};

export function useWedgeFacts(
  lensId: number,
  options?: UseQueryOptions<ReturnType<typeof wedgeDataTransformation>>,
) {
  const wellDataID = useSelectedWell();
  const dispatch = useAppDispatch();

  const comparisonWellIds = useAppSelector(
    (state) => state.widgetOptions.offsetWells,
  );
  const [
    {
      sections: sectionIds,
      operationTime,
      directionalIntervals: directionalIntervalIds,
      holeSizes: holeSizeIds,
      phases: phaseIds,
    },
  ] = useStateQuery<IFiltersType>(
    URL_STATE_PARAM.FILTERS_WIDGET,
    initialFilters,
  );
  const [
    {
      ts_start: zoomStartDuration,
      ts_end: zoomEndDuration,
      depth_start: zoomStartDepth,
      depth_end: zoomEndDepth,
    },
  ] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WIDGET, initialZoomData);

  const requestParameters: ApiWedgeUserLensesIdFactsPutRequest = {
    id: lensId ?? -1,
    wedgeUserLensQueryDto: {
      selectedFilters: {
        sectionIds,
        directionalIntervalIds,
        holeSizeIds,
        phaseIds,
        includeFlatTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined
            ? true
            : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
        includeAlphaRigs: true,
        includeNonAlphaRigs: true,
      },
      zoomStartDepth,
      zoomEndDepth,
      zoomStartDuration,
      zoomEndDuration,
      wellId: wellDataID,
      comparisonWellIds: comparisonWellIds,
      useMockData: false,
      sampleCount: TVD_SAMPLE_COUNT,
      from: defaultDateDto.from,
      to: defaultDateDto.to,
      wellAggregationThreshold: 6,
    },
  };

  const queryKey: PDLensFactsQueryKey<ApiWedgeUserLensesIdFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.WedgeFacts,
    params: requestParameters,
    lensId,
    comparisonType: "single",
  };

  return useSuspenseQuery({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      api
        .apiWedgeUserLensesIdFactsPut(requestParameters, { signal })
        .then((data) => {
          if (data?.lastUpdatedAt && wellDataID) {
            dispatch({
              type: "SET_RIG_STATE_REFRESH_DATE",
              payload: { [wellDataID]: data?.lastUpdatedAt },
            });
          }

          // Will need transformations when comparisons come in.
          // const wells = facts.comparison.map(e => e.wellId);

          return wedgeDataTransformation(data);
        }),
    ...options,
  });
}
