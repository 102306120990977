/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TorqueAndDragType } from './TorqueAndDragType';
import {
    TorqueAndDragTypeFromJSON,
    TorqueAndDragTypeFromJSONTyped,
    TorqueAndDragTypeToJSON,
} from './TorqueAndDragType';
import type { TorqueAndDragCurveDto } from './TorqueAndDragCurveDto';
import {
    TorqueAndDragCurveDtoFromJSON,
    TorqueAndDragCurveDtoFromJSONTyped,
    TorqueAndDragCurveDtoToJSON,
} from './TorqueAndDragCurveDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';

/**
 * 
 * @export
 * @interface TorqueAndDragDataSetDto
 */
export interface TorqueAndDragDataSetDto {
    /**
     * 
     * @type {TorqueAndDragType}
     * @memberof TorqueAndDragDataSetDto
     */
    type: TorqueAndDragType;
    /**
     * 
     * @type {Array<TorqueAndDragCurveDto>}
     * @memberof TorqueAndDragDataSetDto
     */
    curves?: Array<TorqueAndDragCurveDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof TorqueAndDragDataSetDto
     */
    readonly dataState: ResultDataState;
}



/**
 * Check if a given object implements the TorqueAndDragDataSetDto interface.
 */
export function instanceOfTorqueAndDragDataSetDto(value: object): value is TorqueAndDragDataSetDto {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function TorqueAndDragDataSetDtoFromJSON(json: any): TorqueAndDragDataSetDto {
    return TorqueAndDragDataSetDtoFromJSONTyped(json, false);
}

export function TorqueAndDragDataSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TorqueAndDragDataSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': TorqueAndDragTypeFromJSON(json['Type']),
        'curves': json['Curves'] == null ? undefined : ((json['Curves'] as Array<any>).map(TorqueAndDragCurveDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function TorqueAndDragDataSetDtoToJSON(value?: Omit<TorqueAndDragDataSetDto, 'DataState'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Type': TorqueAndDragTypeToJSON(value['type']),
        'Curves': value['curves'] == null ? undefined : ((value['curves'] as Array<any>).map(TorqueAndDragCurveDtoToJSON)),
    };
}

