import { useQuery } from "@tanstack/react-query";
import type { AllWellsKpiDto } from "apis/oag";
import { WellsApi } from "apis/oag";
import { useFilteredAllWellsContext } from "pages/AllWells/useFilteredAllWells";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDWellIncludedWellsKpisQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const wells = new WellsApi(apiConfig);

export function useAllWellsKPI() {
  const { visibleWellsList } = useFilteredAllWellsContext();
  const exclusionList = useAppSelector((state) => state.allWells.exclusions); // exclusion state when clicking on a specific well in the list

  const wellIds = (visibleWellsList || []).filter(
    (wellId) => !(exclusionList || []).includes(wellId),
  );

  const queryKey: PDWellIncludedWellsKpisQueryKey = {
    type: PDQueryType.WELL_INCLUDED_WELLS_KPIS,
    uid: RequestUID.wellIncludedWellsKpi,
    wellIds,
  };

  return useQuery<AllWellsKpiDto>({
    queryKey: [queryKey],
    queryFn: () =>
      wells.apiWellsKpisAggregatedPut({
        requestBody: visibleWellsList ? wellIds : undefined,
      }),
  });
}
