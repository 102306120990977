import { DisplayOption } from "apis/oag";
import {
  baseMatrix,
  MatrixAvailability,
} from "components/Lenses/ContainerLens/common/utils/constants";
import type {
  AbilityMatrixProps,
  AbilityMatrixType,
} from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";

export const getSingleKpiAbilityMatrix = (
  props?: AbilityMatrixProps,
): AbilityMatrixType => {
  return {
    ...baseMatrix,
    showsOutliers: props?.isComparing
      ? MatrixAvailability.NOT_AVAILABLE
      : MatrixAvailability.AVAILABLE,
    squeezesDisplay: props?.isComparing
      ? MatrixAvailability.NOT_AVAILABLE
      : MatrixAvailability.AVAILABLE,
    showStandKpiComments: props?.isComparing
      ? MatrixAvailability.NOT_AVAILABLE
      : MatrixAvailability.AVAILABLE,
    kpiSelect: props?.isSystem
      ? MatrixAvailability.NOT_AVAILABLE
      : MatrixAvailability.AVAILABLE,
    hasExport: props?.isSystem
      ? MatrixAvailability.NOT_AVAILABLE
      : MatrixAvailability.AVAILABLE,
    trackKPI: props?.isSystem
      ? MatrixAvailability.NOT_AVAILABLE
      : MatrixAvailability.AVAILABLE,
    manual_axis: props?.isSystem
      ? MatrixAvailability.NOT_AVAILABLE
      : MatrixAvailability.AVAILABLE,
    outlierFlaggingType: props?.isSystem
      ? MatrixAvailability.NOT_AVAILABLE
      : MatrixAvailability.AVAILABLE,
    showOperationCount:
      props?.displayOption === DisplayOption.StandDepth
        ? MatrixAvailability.NOT_AVAILABLE
        : MatrixAvailability.AVAILABLE,
    indicators:
      props?.isComparing || props?.displayOption === DisplayOption.DateShift
        ? MatrixAvailability.NOT_AVAILABLE
        : MatrixAvailability.AVAILABLE,
  };
};
