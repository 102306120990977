/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandKpiCommentAco } from './StandKpiCommentAco';
import {
    StandKpiCommentAcoFromJSON,
    StandKpiCommentAcoFromJSONTyped,
    StandKpiCommentAcoToJSON,
} from './StandKpiCommentAco';

/**
 * 
 * @export
 * @interface StandKpiCommentSetAco
 */
export interface StandKpiCommentSetAco {
    /**
     * 
     * @type {number}
     * @memberof StandKpiCommentSetAco
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof StandKpiCommentSetAco
     */
    wellName: string;
    /**
     * 
     * @type {string}
     * @memberof StandKpiCommentSetAco
     */
    kpiGroupName: string;
    /**
     * 
     * @type {string}
     * @memberof StandKpiCommentSetAco
     */
    kpiSubgroupName: string;
    /**
     * 
     * @type {string}
     * @memberof StandKpiCommentSetAco
     */
    kpiName: string;
    /**
     * 
     * @type {Array<StandKpiCommentAco>}
     * @memberof StandKpiCommentSetAco
     */
    comments: Array<StandKpiCommentAco>;
}

/**
 * Check if a given object implements the StandKpiCommentSetAco interface.
 */
export function instanceOfStandKpiCommentSetAco(value: object): value is StandKpiCommentSetAco {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('wellName' in value) || value['wellName'] === undefined) return false;
    if (!('kpiGroupName' in value) || value['kpiGroupName'] === undefined) return false;
    if (!('kpiSubgroupName' in value) || value['kpiSubgroupName'] === undefined) return false;
    if (!('kpiName' in value) || value['kpiName'] === undefined) return false;
    if (!('comments' in value) || value['comments'] === undefined) return false;
    return true;
}

export function StandKpiCommentSetAcoFromJSON(json: any): StandKpiCommentSetAco {
    return StandKpiCommentSetAcoFromJSONTyped(json, false);
}

export function StandKpiCommentSetAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiCommentSetAco {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'wellName': json['WellName'],
        'kpiGroupName': json['KpiGroupName'],
        'kpiSubgroupName': json['KpiSubgroupName'],
        'kpiName': json['KpiName'],
        'comments': ((json['Comments'] as Array<any>).map(StandKpiCommentAcoFromJSON)),
    };
}

export function StandKpiCommentSetAcoToJSON(value?: StandKpiCommentSetAco | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'WellName': value['wellName'],
        'KpiGroupName': value['kpiGroupName'],
        'KpiSubgroupName': value['kpiSubgroupName'],
        'KpiName': value['kpiName'],
        'Comments': ((value['comments'] as Array<any>).map(StandKpiCommentAcoToJSON)),
    };
}

