import type { ApiRigPowerUserLensesIdFactsPutRequest } from "apis/oag";
import { RIG_POWER_SAMPLE_COUNT } from "components/Lenses/ContainerLens/RigPower/common/utils";
import { useRigPowerFacts } from "hooks/facts/useRigPowerFacts";
import { useFilterParams } from "hooks/filters/useFilterParams";

interface UseRigPowerStateFetcherParams {
  lensId: number;
  comparisonWellIds?: number[];
}

export function useRigPowerFactsFetcher({
  lensId,
}: UseRigPowerStateFetcherParams) {
  const filterParams = useFilterParams();
  filterParams.sampleCount = RIG_POWER_SAMPLE_COUNT;

  const requestParameters: ApiRigPowerUserLensesIdFactsPutRequest = {
    id: lensId ?? -1,
    rigPowerQueryDto: filterParams,
  };
  const request = useRigPowerFacts(lensId, requestParameters, {
    enabled: !!lensId,
    refetchOnMount: false,
  });

  return { request };
}
