/* eslint-disable react/no-multi-comp */
import type { GeneratorSlotType } from "apis/oag";
import { FuelType, StatCardDataType } from "apis/oag";
import {
  StyledCard,
  StyledDropDown,
  StyledOptionRow,
} from "components/Header/RightContent/style";
import GeneratorAndBoilerSelector from "components/Lenses/common/GeneratorAndBoilerSelector";
import type { GeneratorAndBoilerOptions } from "components/Lenses/common/GeneratorAndBoilerSelector/utils";
import GeneratorSelector from "components/Lenses/common/GeneratorSelector";
import NoData from "components/Lenses/common/NoData";
import * as Styled from "components/Lenses/ContainerLens/StatCard/Chart/GenericStatCard/styled";
import { StyledIconSelectorContainer } from "components/Lenses/ContainerLens/StatCard/Chart/GenericStatCard/styled";
import { PDComponent } from "components/PDComponents";
import { useLensMouseIn } from "hooks/lens/useLensMouseIn";
import { useLensNameByTemplateId } from "hooks/lens/useLensNameByTemplateId";
import type { FC } from "react";
import { useMemo, useState } from "react";
import { useCustomTheme } from "utils/useTheme";

interface GenericStatCardProps {
  fuelType: FuelType;
  title?: string;
  value: string;
  unit?: string;
  selectedGenerators?: GeneratorSlotType[];
  selectedGeneratorAndBoiler: GeneratorAndBoilerOptions;
  height: number;
  width: number;
  lensId: number;
  lensTemplateId: number;
  dataSource?: StatCardDataType;
  hasData?: boolean;
  isLoading?: boolean;
  isLocked?: boolean;
  hasFuelTypeSelection?: boolean;
  hasGeneratorSelection?: boolean;
  hasGeneratorAndBoilerSelection?: boolean;
  handleFuelUpdate?: (fuelType: FuelType) => Promise<void>;
  handleGeneratorAndBoilerUpdate?: (
    generatorAndBoilerOption: GeneratorAndBoilerOptions,
    generator?: GeneratorSlotType,
  ) => Promise<void>;
  handleGeneratorUpdate?: (generator: GeneratorSlotType[]) => Promise<void>;
}

export const GenericStatCard: FC<GenericStatCardProps> = ({
  fuelType = FuelType.Diesel,
  title = "",
  value = "No data found",
  unit,
  selectedGenerators,
  selectedGeneratorAndBoiler,
  dataSource,
  hasData,
  isLoading,
  height = 1,
  isLocked = false,
  width = 1,
  lensId,
  lensTemplateId,
  hasFuelTypeSelection,
  hasGeneratorSelection,
  hasGeneratorAndBoilerSelection,
  handleFuelUpdate,
  handleGeneratorUpdate,
  handleGeneratorAndBoilerUpdate,
}) => {
  const { atomThemeVariant } = useCustomTheme();
  const [menuVisible, setMenuVisible] = useState(false);
  const [generatorMenuVisible, setGeneratorMenuVisible] = useState(false);

  const { mouseIn } = useLensMouseIn();

  const fuelTypeIcon = useMemo(() => {
    if (!hasFuelTypeSelection) return null;
    if (fuelType === FuelType.Diesel) {
      return <PDComponent.SvgIcon name="diesel" />;
    } else if (fuelType === FuelType.NaturalGas) {
      return <PDComponent.SvgIcon name="naturalGas" />;
    }
  }, [fuelType, hasFuelTypeSelection]);

  const lensName = useLensNameByTemplateId(lensTemplateId);

  const MenuItems = () => (
    <StyledCard>
      <StyledOptionRow justify="space-between" align="middle">
        <Styled.Option
          onClick={() => {
            setMenuVisible(false);
            handleFuelUpdate?.(FuelType.Diesel);
          }}
        >
          {dataSource === StatCardDataType.FuelFlow
            ? "Avg Diesel Flow"
            : "Diesel Used"}
        </Styled.Option>
      </StyledOptionRow>
      <StyledOptionRow justify="space-between" align="middle">
        <Styled.Option
          onClick={async () => {
            setMenuVisible(false);
            await handleFuelUpdate?.(FuelType.NaturalGas);
          }}
        >
          {dataSource === StatCardDataType.FuelFlow
            ? "Avg Nat Gas Flow"
            : "Natural Gas Used"}
        </Styled.Option>
      </StyledOptionRow>
    </StyledCard>
  );

  return (
    <Styled.StatCard
      height={height}
      width={width}
      $mouseIn={isLocked ? false : mouseIn}
      $hasData={!!hasData}
      $isMenuOpen={isLocked ? false : menuVisible || generatorMenuVisible}
    >
      <Styled.DieselContainer>
        {hasFuelTypeSelection ? (
          <StyledDropDown
            overlay={<MenuItems />}
            placement="bottomLeft"
            trigger={["click"]}
            open={isLocked ? false : menuVisible}
            onOpenChange={() => {
              setMenuVisible(!menuVisible);
            }}
          >
            <Styled.DieselType variant={"faded"}>
              {title} {isLocked ? null : <Styled.ChevronDownIcon />}
            </Styled.DieselType>
          </StyledDropDown>
        ) : (
          <Styled.DieselType variant={"faded"}>{title}</Styled.DieselType>
        )}
      </Styled.DieselContainer>
      <Styled.ValueContainer>
        {!hasData && !isLoading ? (
          <>
            {height > 2 && width > 2 ? (
              <NoData isStatic />
            ) : (
              <Styled.NoDataText>{value}</Styled.NoDataText>
            )}
          </>
        ) : (
          <Styled.Value variant={atomThemeVariant}>{value}</Styled.Value>
        )}

        {hasData && unit ? (
          <Styled.Unit variant={atomThemeVariant}>{unit}</Styled.Unit>
        ) : null}
      </Styled.ValueContainer>

      <StyledIconSelectorContainer
        height={height}
        $mouseIn={isLocked ? false : mouseIn}
        hasIcon={!!fuelTypeIcon}
      >
        {hasGeneratorSelection && handleGeneratorUpdate ? (
          <GeneratorSelector
            selectedGenerators={selectedGenerators || []}
            setGeneratorMenuVisible={setGeneratorMenuVisible}
            isLocked={isLocked}
            handleGeneratorUpdate={handleGeneratorUpdate}
            trackingText="Evergreen Dashboard - Update Generator"
            trackingData={{
              lensId,
              lens: lensName,
            }}
          />
        ) : null}
        {hasGeneratorAndBoilerSelection && handleGeneratorAndBoilerUpdate ? (
          <GeneratorAndBoilerSelector
            isDiesel={fuelType === FuelType.Diesel}
            selectedOption={selectedGeneratorAndBoiler}
            selectedGenerators={selectedGenerators}
            isLocked={isLocked}
            handleGeneratorAndBoilerUpdate={handleGeneratorAndBoilerUpdate}
            trackingText="Evergreen Dashboard - Update Generator"
            trackingData={{
              lensId,
              lens: lensName,
            }}
          />
        ) : null}
        {fuelTypeIcon ? (
          <Styled.IconContainer>{fuelTypeIcon}</Styled.IconContainer>
        ) : null}
      </StyledIconSelectorContainer>
    </Styled.StatCard>
  );
};
