import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext } from "react";

interface AutoScrollEnabledValue {
  autoScrollEnabled: boolean;
  setAutoScrollEnabled: Dispatch<SetStateAction<boolean>>;
}

export const AutoScrollEnabledContext = createContext<
  AutoScrollEnabledValue | undefined
>(undefined);

export const useAutoScrollEnabledContext = () => {
  const context = useContext(AutoScrollEnabledContext);
  if (context === undefined) {
    throw new Error(
      "useAutoScrollEnabledContext must be used within a AutoScrollEnabledContextProvider",
    );
  }
  return context;
};

export const AutoScrollEnabledProvider = ({
  children,
  autoScrollEnabled,
  setAutoScrollEnabled,
}: {
  children: React.ReactNode;
  autoScrollEnabled: boolean;
  setAutoScrollEnabled: Dispatch<SetStateAction<boolean>>;
}) => (
  <AutoScrollEnabledContext.Provider
    value={{ autoScrollEnabled, setAutoScrollEnabled }}
  >
    {children}
  </AutoScrollEnabledContext.Provider>
);
