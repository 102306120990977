import type { RigPowerFactDto } from "apis/oag";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";

export const UNIT_LABEL = "kW";
export const AVG_UNIT_LABEL = "kW avg";

export const getInvartiantBusinessRuleForAxisValue = (
  maxAxisNumber: number,
) => {
  // in WATTS
  if (maxAxisNumber <= 3500000) {
    return 3500000;
  } else return 4500000;
};

export function getTotalDisplayedPower(
  generatorLegendItems: LegendItem[],
  point?: RigPowerFactDto,
) {
  return generatorLegendItems
    .filter((legendItem) => legendItem.isEnabled)
    .reduce((acc, legendItem) => {
      const matched = point?.slices?.find(
        (slice) => slice.generator === legendItem.id,
      );
      return acc + (matched ? matched.sliceValue : 0);
    }, 0);
}

export function getCumulativeVisibleGenerators(
  generatorLegendItems: LegendItem[],
  point?: RigPowerFactDto | null,
) {
  return generatorLegendItems
    .filter((legendItem) => legendItem.isEnabled)
    .map((genLegendItem) => {
      return {
        legendItem: genLegendItem,
        rawValue: point?.slices?.find(
          (slice) => slice.generator === genLegendItem.id,
        )?.sliceValue,
      };
    })
    .reduce(
      (acc, genItem, idx) => {
        acc.push({
          positionalValue:
            (genItem?.rawValue ?? 0) + (acc[idx - 1]?.positionalValue ?? 0),
          ...genItem,
        });

        return acc;
      },
      [] as {
        legendItem: LegendItem;
        rawValue: number | undefined;
        positionalValue: number | undefined;
      }[],
    );
}
