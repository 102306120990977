import type { ApiFuelTankVolumeUserLensesIdFactsPutRequest } from "apis/oag/apis/FuelTankVolumeUserLensesApi";
import { useFuelTankVolumeFacts } from "hooks/facts/useFuelTankVolumeFacts";
import { useFilterParams } from "hooks/filters/useFilterParams";
import { isNull } from "lodash";

interface UseFuelTankVolumeFetcherParams {
  lensId: number;
}

export function useFuelTankVolumeFetcher({
  lensId,
}: UseFuelTankVolumeFetcherParams) {
  const filterParams = useFilterParams();
  const requestParameters: ApiFuelTankVolumeUserLensesIdFactsPutRequest = {
    id: lensId,
    baseFuelQueryDto: filterParams,
  };

  const request = useFuelTankVolumeFacts(lensId, requestParameters, {
    enabled: !isNull(lensId),
    refetchOnMount: false,
  });

  return { request };
}
