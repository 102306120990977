import styled from "styled-components";
import { Space } from "utils/componentLibrary";
import { PdCardBody } from "utils/componentLibrary/Card";
import { zIndexLayer } from "utils/zIndex";

export const StyledContainer = styled.div`
  background-color: ${({ theme }) =>
    theme.themeStyle.colors.alt_quaterniary_bg};
  padding: 0px 32px;
  position: fixed;
  width: 100%;
  z-index: ${zIndexLayer.sky};
  box-shadow: 0 1px 3px rgb(0 0 0 / 0.2);
`;

export const PaddedContainer = styled.div`
  padding-right: 16px;
`;

export const StyledHeaderContainer = styled.div`
  margin: 0 24px;
  height: 36px;
  display: flex;
  align-items: center;
`;

export const StyledScrollMask = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  background: ${({ theme }) => theme.themeStyle.colors.primary_bg};
`;

export const StyledSpace = styled(Space)`
  height: 100%;
  background: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  ${PdCardBody} {
    padding: 0px 7px;
  }
`;

export const StyledMapviewWrapper = styled.div<{ $headerHeight: number }>`
  margin-top: ${({ $headerHeight }) => $headerHeight + 4}px;
`;

export const StyledSidebarWrapper = styled.div`
  height: calc(100vh - 205px);
`;
