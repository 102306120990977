import * as Styled from "components/Collapsible/styles";
import React, { useState } from "react";

interface CollapsibleProps {
  title: string;
  rightContent?: React.ReactNode;
  children: React.ReactNode;
}

export const Collapsible: React.FC<CollapsibleProps> = ({
  title,
  rightContent,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapsible = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Styled.Container>
      <Styled.Header onClick={toggleCollapsible}>
        <Styled.TitleContainer>
          <Styled.StyledButton $isActive={isOpen}>
            <Styled.ChevronDownIcon />
          </Styled.StyledButton>
          <Styled.Title>{title}</Styled.Title>
        </Styled.TitleContainer>
        {rightContent}
      </Styled.Header>
      {isOpen ? <div>{children}</div> : null}
    </Styled.Container>
  );
};
