import { ReportsApi, ReportStatusType } from "apis/oag";
import { toast } from "atoms/toast";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import type { RootState } from "reducers/store";
import type { IUnitSystem } from "reducers/types";
import { Track } from "services/Mixpanel";
import superjson from "superjson";
import { apiConfig } from "utils/apiConfig";
import { Button, Col, Row } from "utils/componentLibrary";
import { blobToDownload, sleep } from "utils/helper";

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const reportsApi = new ReportsApi(apiConfig);

export type PayloadType = RootState["report"][];

export const TVD_ID = Number.MIN_SAFE_INTEGER;
export const getReportTimestamp = () => {
  return [
    dayjs().format("MMMM D, YYYY · h:mm A"),
    dayjs()
      .format("zzz")
      .split(" ")
      .map((e) => e.charAt(0))
      .join(""),
  ].join(" ");
};

export const createReport = async ({
  wellId,
  lenses,
  payloadItems,
  uom,
}: {
  wellId: number;
  lenses: number[];
  payloadItems: PayloadType;
  uom: IUnitSystem;
}) => {
  return reportsApi.apiReportsQueuePost({
    reportGenerationParametersDto: {
      payload: superjson.stringify([
        getReportTimestamp(),
        uom,
        ...payloadItems,
      ]),
      pageUrls: [
        `report/<report_id>/${wellId}/0/-1/prepare/front`,
        ...lenses.map(
          (e, index) =>
            `report/<report_id>/${wellId}/${e === TVD_ID ? "TvD" : e}/${index}/prepare/${
              e === TVD_ID ? "tvd" : "lens"
            }`,
        ),
      ],
      id: 0,
      reportIdUrlPlaceHolder: "<report_id>",
    },
  });
};

export const keepCheckingForReport = async (
  reportID: number,
): Promise<boolean> => {
  await sleep(5000); // waits 5 sec
  return reportsApi
    .apiReportsIdStatusGet({
      id: reportID,
    })
    .then((e) => {
      if (e.status === ReportStatusType.Done) {
        return reportsApi
          .apiReportsIdDownloadGet({
            id: reportID,
          })
          .then((f) => {
            toast.downloadDone({
              message: (
                <Row gutter={16}>
                  <Col>Report is ready to be downloaded.</Col>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        Track.interact("Download Report");

                        if (e.filename) {
                          blobToDownload(f, e.filename);
                          toast.close("download");
                          return;
                        }

                        toast.error({
                          message: "Unable to generate filename!",
                        });
                      }}
                    >
                      Download
                    </Button>
                  </Col>
                </Row>
              ),
            });
            return true;
          });
      } else if (e.status === ReportStatusType.Error) {
        toast.error({
          message: "Could not generate report!",
        });
        return false;
      } else {
        return keepCheckingForReport(reportID);
      }
    });
};
