import type {
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { OperatorDto } from "apis/oag";
import { OperatorsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import { secondsInHour } from "utils/common";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const operators = new OperatorsApi(apiConfig);

const QueryKey: PDUniqueQueryKey = { uid: RequestUID.operatorsList };

interface CustomOperators {
  list: OperatorDto[];
  byId: {
    [key: number]: OperatorDto;
  };
}

export function useOperators(
  options?: Omit<
    UseSuspenseQueryOptions<CustomOperators>,
    "queryKey" | "queryFn"
  >,
): UseSuspenseQueryResult<{
  list: OperatorDto[];
  byId: { [key: number]: OperatorDto };
}> {
  return useSuspenseQuery<CustomOperators>({
    queryKey: [QueryKey],
    queryFn: () =>
      operators.apiOperatorsGet().then((data) => {
        return {
          list: data,
          byId: data.reduce<Record<number, OperatorDto>>((acc, cur) => {
            acc[cur.id ?? 0] = cur;
            return acc;
          }, {}),
        };
      }),
    refetchOnMount: false,
    gcTime: 2 * secondsInHour,
    ...options,
  });
}
