import TextArea from "antd/lib/input/TextArea";
import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import type { Dict } from "mixpanel-browser";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import colors from "utils/colors";
import { Row } from "utils/componentLibrary";

export interface DescriptionModalProps {
  title: string;
  trackingProps: Dict;
  setVisible: (value: boolean) => void;
  visible: boolean;
  onSave: (description: string) => Promise<void> | void;
  initialDescription: string;
}

const CustomTextArea = styled(TextArea)`
  &.ant-input {
    background-color: ${({ theme }) =>
      theme.themeStyle.colors.quaterniary_bg}!important;
    color: ${({ theme }) =>
      theme.themeStyle.colors.primary_typography}!important;
  }
  &.ant-input:focus {
    background-color: ${({ theme }) =>
      theme.themeStyle.colors.quaterniary_bg}!important;
    border: solid 1px ${colors.buttons_clicked}!important;
    outline: none !important;
    box-shadow: none !important;
  }
`;

const ErrorWrapper = styled.div<{ $hasError?: boolean }>`
  color: ${(props) =>
    props.$hasError
      ? colors.error
      : props.theme.themeStyle.colors.primary_typography};
`;

export function DescriptionModal({
  onSave,
  setVisible,
  visible,
  initialDescription,
  title,
  trackingProps,
}: DescriptionModalProps) {
  const [description, setDescription] = useState(initialDescription ?? "");
  useEffect(() => {
    setDescription(initialDescription ?? "");
  }, [initialDescription]);

  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) =>
      setDescription(e.currentTarget.value),
    [],
  );

  const handleCancel = useCallback(() => {
    setDescription(initialDescription ?? "");
    setVisible(false);
  }, [setVisible, initialDescription]);

  const handleClear = useCallback(() => setDescription(""), []);

  return (
    <PDComponent.Modal
      open={visible}
      title={title}
      footer={
        <Row justify="space-between" align="middle">
          <ErrorWrapper $hasError={description.length >= 200}>
            {description.length}/200{" "}
            {description.length >= 200 && (
              <PDComponent.SvgIcon name="warningField" />
            )}
          </ErrorWrapper>
          <div>
            <Button
              trackingName="Clear Description Plan Overview"
              trackingProps={{
                ...trackingProps,
                "Old Description": description,
              }}
              onClick={handleClear}
            >
              Clear
            </Button>
            <Button
              disabled={description.length > 200}
              type="primary"
              trackingName="Add Description Plan Overview"
              trackingProps={{
                ...trackingProps,
                Description: description,
              }}
              onClick={async () => {
                await onSave(description);
                setDescription("");
                setVisible(false);
              }}
            >
              Save changes
            </Button>
          </div>
        </Row>
      }
      onCancel={handleCancel}
    >
      <div>Phase Description</div>
      <CustomTextArea
        placeholder="Description"
        value={description}
        onChange={handleDescriptionChange}
      />
    </PDComponent.Modal>
  );
}

export function useDescriptionModal({
  onSave,
}: {
  onSave: (description: string, activityId: number) => void;
}) {
  const [visible, setVisible] = useState(false);
  const [activityId, setActivityId] = useState<number | undefined>(undefined);
  const [initialDescription, setInitialDescription] = useState<
    string | undefined
  >("");
  const [title, setTitle] = useState("");
  const [trackingProps, setTrackingProps] = useState({});
  const open = useCallback(
    ({
      activityId,
      initialDescription,
      title,
      trackingProps,
    }: {
      trackingProps: Dict;
      activityId: number;
      initialDescription?: string;
      title: string;
    }) => {
      setTitle(title);
      setTrackingProps(trackingProps);
      setActivityId(activityId);
      setInitialDescription(initialDescription);
      setVisible(true);
    },
    [],
  );

  const close = useCallback(() => {
    setVisible(false);
    setActivityId(undefined);
    setInitialDescription("");
  }, []);

  const handleSave = useCallback(
    (description: string) => {
      if (activityId) onSave(description, activityId);
    },
    [activityId, onSave],
  );

  const node = (
    <DescriptionModal
      setVisible={setVisible}
      trackingProps={trackingProps}
      visible={visible}
      onSave={handleSave}
      initialDescription={initialDescription || ""}
      title={title}
    />
  );

  return { visible, open, close, node };
}
