import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { WellKpiCommentDetailsDto } from "apis/oag";
import { ScorecardKpiCommentsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDDetailedNotificaionsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const scorecardKpiCommentsApi = new ScorecardKpiCommentsApi(apiConfig);

export const DetailedNotificationsQueryKey = {
  uid: RequestUID.detailedNotifications,
};
export function useDetailedNotification(
  id?: number,
  options?: UseSuspenseQueryOptions<WellKpiCommentDetailsDto | null>,
) {
  const DetailedNotificationsRequestKey: PDDetailedNotificaionsQueryKey = {
    uid: RequestUID.detailedNotifications,
    type: PDQueryType.FACTS,
    params: { id: id || -1 },
  };

  return useSuspenseQuery<WellKpiCommentDetailsDto | null>({
    queryKey: [DetailedNotificationsRequestKey],
    queryFn: () =>
      id
        ? scorecardKpiCommentsApi.apiScorecardKpiCommentsIdWithFactsGet({ id })
        : null,
    ...options,
  });
}
