/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParameterByDepthTrackComparisonWellDto } from './ParameterByDepthTrackComparisonWellDto';
import {
    ParameterByDepthTrackComparisonWellDtoFromJSON,
    ParameterByDepthTrackComparisonWellDtoFromJSONTyped,
    ParameterByDepthTrackComparisonWellDtoToJSON,
} from './ParameterByDepthTrackComparisonWellDto';
import type { Int32DecimalFactIndicatorSetDto } from './Int32DecimalFactIndicatorSetDto';
import {
    Int32DecimalFactIndicatorSetDtoFromJSON,
    Int32DecimalFactIndicatorSetDtoFromJSONTyped,
    Int32DecimalFactIndicatorSetDtoToJSON,
} from './Int32DecimalFactIndicatorSetDto';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface ParameterByDepthKpiComparisonDto
 */
export interface ParameterByDepthKpiComparisonDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    readonly stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    lastUpdatedAt?: DateDto | null;
    /**
     * 
     * @type {Array<ParameterByDepthTrackComparisonWellDto>}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    facts: Array<ParameterByDepthTrackComparisonWellDto>;
    /**
     * 
     * @type {Int32DecimalFactIndicatorSetDto}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    indicators?: Int32DecimalFactIndicatorSetDto | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof ParameterByDepthKpiComparisonDto
     */
    readonly dataState: ResultDataState;
}



/**
 * Check if a given object implements the ParameterByDepthKpiComparisonDto interface.
 */
export function instanceOfParameterByDepthKpiComparisonDto(value: object): value is ParameterByDepthKpiComparisonDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('facts' in value) || value['facts'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function ParameterByDepthKpiComparisonDtoFromJSON(json: any): ParameterByDepthKpiComparisonDto {
    return ParameterByDepthKpiComparisonDtoFromJSONTyped(json, false);
}

export function ParameterByDepthKpiComparisonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterByDepthKpiComparisonDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': json['LastUpdatedAt'] == null ? undefined : DateDtoFromJSON(json['LastUpdatedAt']),
        'facts': ((json['Facts'] as Array<any>).map(ParameterByDepthTrackComparisonWellDtoFromJSON)),
        'indicators': json['Indicators'] == null ? undefined : Int32DecimalFactIndicatorSetDtoFromJSON(json['Indicators']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function ParameterByDepthKpiComparisonDtoToJSON(value?: Omit<ParameterByDepthKpiComparisonDto, 'StopWatch'|'DataState'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'Facts': ((value['facts'] as Array<any>).map(ParameterByDepthTrackComparisonWellDtoToJSON)),
        'Indicators': Int32DecimalFactIndicatorSetDtoToJSON(value['indicators']),
    };
}

