import type { RigFleetPerformanceCardUserLensDto } from "apis/oag";
import NoData from "components/Lenses/common/NoData";
import { PDComponent } from "components/PDComponents";
import { HoveredDataProvider } from "pages/FleetPerformance/components/helpers/HoveredDataContext";
import {
  StyledAverage,
  StyledCard,
  StyledCardHeader,
  StyledExpandButton,
  StyledKpiTitle,
  StyledSummary,
  ViewContainer,
} from "pages/FleetPerformance/components/styled";
import { cardKpiTypeToLabel } from "pages/FleetPerformance/components/utils";
import { Space } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

export default function NoDataCard({
  lens,
  isSingleOnGrid,
}: {
  lens: RigFleetPerformanceCardUserLensDto;
  isSingleOnGrid?: boolean;
}) {
  const { atomThemeVariant } = useCustomTheme();

  return (
    <HoveredDataProvider>
      <StyledCard $isSingleOnGrid={isSingleOnGrid}>
        <StyledCardHeader>
          <StyledSummary>
            <StyledKpiTitle>
              {cardKpiTypeToLabel[lens.cardKpiType] ?? "-"}
            </StyledKpiTitle>
            <StyledAverage variant={atomThemeVariant}>--</StyledAverage>
          </StyledSummary>
          <Space>
            <StyledExpandButton
              type="ghost"
              disabled
              icon={<PDComponent.SvgIcon name="fleetPerformanceExpandView" />}
            />
          </Space>
        </StyledCardHeader>
        <ViewContainer>
          <NoData />
        </ViewContainer>
      </StyledCard>
    </HoveredDataProvider>
  );
}
