import { LinePath } from "@visx/shape";
import { Threshold } from "@visx/threshold";
import type { ParameterByDepthTrackValuesDto } from "apis/oag";
import type {
  BoundaryRange,
  TrackWithRanges,
} from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTrackRanges";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import type { ScaleLinear } from "d3";
import { curveMonotoneX } from "d3";
import React, { useMemo } from "react";
import colors from "utils/colors";
export const returnZeroIfNegative = (value: number) => {
  return value < 0 ? 0 : value;
};
export const returnZeroIfInvalid = (value: number) => {
  return Number.isNaN(value) ? 0 : value;
};

const RoadmapColoredSegment = ({
  track,
  rangeIdx,
  trackRange,
  valueScale,
  depthScale,
  chartHeight: _chartHeight,
  chartWidth: _chartWidth,
}: {
  track: TrackWithRanges;
  rangeIdx: number;
  trackRange: BoundaryRange;
  valueScale: ScaleLinear<number, number>;
  depthScale: ScaleLinear<number, number>;
  chartHeight: number;
  chartWidth: number;
}) => {
  const chartHeight = Math.abs(
    returnZeroIfNegative(returnZeroIfInvalid(_chartHeight)),
  );
  const chartWidth = Math.abs(
    returnZeroIfNegative(returnZeroIfInvalid(_chartWidth)),
  );
  const negativeThreshold = useMemo(
    () => ({
      fill: colors.error_transparent,
      fillOpacity: 0.4,
      stroke: colors.error,
    }),
    [],
  );

  const positiveThreshold = useMemo(
    () => ({
      fill: colors.well_color_transparent,
      fillOpacity: 0.4,
      stroke: colors.well_color,
    }),
    [],
  );

  const type = useMemo(() => {
    if (
      Number.isFinite(trackRange.boundary?.min) &&
      Number.isFinite(trackRange.boundary?.max)
    ) {
      return "minMax";
    } else if (Number.isFinite(trackRange.boundary?.min)) {
      return "minOnly";
    } else if (Number.isFinite(trackRange.boundary?.max)) {
      return "maxOnly";
    }
  }, [trackRange.boundary?.max, trackRange.boundary?.min]);

  const regularLinePathProps = useMemo(
    () => ({
      curve: curveMonotoneX,
      data: trackRange.rangePoints ?? [],
      x: (d: ParameterByDepthTrackValuesDto) =>
        returnZeroIfNegative(returnZeroIfInvalid(depthScale(d.holeDepth))),
      y: (d: ParameterByDepthTrackValuesDto) =>
        returnZeroIfNegative(returnZeroIfInvalid(valueScale(d.value ?? 0))),
      strokeWidth: 2,
    }),
    [depthScale, trackRange.rangePoints, valueScale],
  );

  const regularThresholdProps = useMemo(
    () => ({
      data: trackRange.rangePoints ?? [],
      curve: curveMonotoneX,
      x: (d: ParameterByDepthTrackValuesDto) =>
        returnZeroIfNegative(returnZeroIfInvalid(depthScale(d.holeDepth))),
      y0: (d: ParameterByDepthTrackValuesDto) =>
        returnZeroIfNegative(returnZeroIfInvalid(valueScale(d.value ?? 0))),
    }),
    [depthScale, trackRange.rangePoints, valueScale],
  );

  if (!trackRange.rangePoints?.length || !chartHeight) return null;
  if (type === "minMax")
    return (
      <React.Fragment key={rangeIdx}>
        <defs>
          <clipPath id={`${track.trackId}-${rangeIdx}-negative-top`}>
            <rect
              x={0}
              y={0}
              width={returnZeroIfNegative(returnZeroIfInvalid(chartWidth))}
              height={returnZeroIfNegative(
                returnZeroIfInvalid(
                  valueScale(
                    (trackRange.boundary?.max ?? 0) -
                      (trackRange.boundary?.min ?? 0),
                  ),
                ),
              )}
            />
          </clipPath>

          <clipPath id={`${track.trackId}-${rangeIdx}-positive`}>
            <rect
              x={0}
              y={returnZeroIfNegative(
                returnZeroIfInvalid(
                  valueScale(trackRange.boundary?.max ?? 0) - 1,
                ),
              )}
              width={returnZeroIfNegative(returnZeroIfInvalid(chartWidth))}
              height={returnZeroIfNegative(
                returnZeroIfInvalid(
                  -valueScale(trackRange.boundary?.max ?? 0) +
                    valueScale(trackRange.boundary?.min ?? 0) +
                    1,
                ),
              )}
            />
          </clipPath>
          <clipPath id={`${track.trackId}-${rangeIdx}-negative-bottom`}>
            <rect
              x={0}
              y={returnZeroIfNegative(
                returnZeroIfInvalid(valueScale(trackRange.boundary?.min ?? 0)),
              )}
              width={returnZeroIfNegative(returnZeroIfInvalid(chartWidth))}
              height={returnZeroIfNegative(
                returnZeroIfInvalid(
                  valueScale(
                    (trackRange.boundary?.max ?? 0) -
                      (trackRange.boundary?.min ?? 0),
                  ),
                ),
              )}
            />
          </clipPath>
        </defs>
        <LinePath
          stroke={colors.error}
          clipPath={`url(#${track.trackId}-${rangeIdx}-negative-top)`}
          {...regularLinePathProps}
        />
        <LinePath
          stroke={colors.well_color}
          clipPath={`url(#${track.trackId}-${rangeIdx}-positive)`}
          {...regularLinePathProps}
        />
        <LinePath
          stroke={colors.error}
          clipPath={`url(#${track.trackId}-${rangeIdx}-negative-bottom)`}
          {...regularLinePathProps}
        />
        <Threshold
          id={`${track.trackId}-${rangeIdx}-${trackRange.boundary?.min}-bottom`}
          y1={returnZeroIfNegative(
            returnZeroIfInvalid(valueScale(trackRange.boundary?.min ?? 0)),
          )}
          clipAboveTo={returnZeroIfNegative(
            returnZeroIfInvalid(valueScale(trackRange.boundary?.min ?? 0)),
          )}
          clipBelowTo={chartHeight}
          belowAreaProps={negativeThreshold}
          aboveAreaProps={positiveThreshold}
          {...regularThresholdProps}
        />
        <Threshold
          id={`${track.trackId}-${rangeIdx}-${trackRange.boundary?.min}-mid`}
          y1={returnZeroIfNegative(
            returnZeroIfInvalid(valueScale(trackRange.boundary?.min ?? 0)),
          )}
          clipAboveTo={valueScale(trackRange.boundary?.max ?? 0)}
          clipBelowTo={valueScale(trackRange.boundary?.min ?? 0)}
          belowAreaProps={positiveThreshold}
          aboveAreaProps={positiveThreshold}
          {...regularThresholdProps}
        />
        <Threshold
          id={`${track.trackId}-${rangeIdx}-${trackRange.boundary?.min}-top`}
          y1={getSVGNormalizedValue(
            valueScale(trackRange.boundary?.max ?? 0),
            Number.MIN_VALUE,
          )}
          clipAboveTo={0}
          clipBelowTo={valueScale(trackRange.boundary?.max ?? 0)}
          aboveAreaProps={negativeThreshold}
          {...regularThresholdProps}
        />
      </React.Fragment>
    );

  if (type === "minOnly" || type === "maxOnly")
    return (
      <React.Fragment>
        <defs>
          <clipPath id={`${track.trackId}-${rangeIdx}-top`}>
            <rect
              x={0}
              y={0}
              width={returnZeroIfNegative(returnZeroIfInvalid(chartWidth))}
              height={returnZeroIfNegative(
                returnZeroIfInvalid(
                  valueScale(
                    trackRange.boundary?.min ?? trackRange.boundary?.max ?? 0,
                  ),
                ),
              )}
            />
          </clipPath>

          <clipPath id={`${track.trackId}-${rangeIdx}-bottom`}>
            <rect
              x={0}
              y={returnZeroIfNegative(
                returnZeroIfInvalid(
                  valueScale(
                    trackRange.boundary?.min ?? trackRange.boundary?.max ?? 0,
                  ),
                ),
              )}
              width={returnZeroIfNegative(returnZeroIfInvalid(chartWidth))}
              height={returnZeroIfNegative(
                returnZeroIfInvalid(
                  valueScale(0) -
                    valueScale(
                      trackRange.boundary?.min ?? trackRange.boundary?.max ?? 0,
                    ) +
                    1,
                ),
              )}
            />
          </clipPath>
        </defs>

        <LinePath
          stroke={trackRange.boundary?.min ? colors.well_color : colors.error}
          clipPath={`url(#${track.trackId}-${rangeIdx}-top)`}
          {...regularLinePathProps}
        />

        <LinePath
          stroke={trackRange.boundary?.min ? colors.error : colors.well_color}
          clipPath={`url(#${track.trackId}-${rangeIdx}-bottom)`}
          {...regularLinePathProps}
        />

        <Threshold
          id={`${track.trackId}-${rangeIdx}-${trackRange.boundary?.min}-min-or-max`}
          y1={returnZeroIfNegative(
            returnZeroIfInvalid(
              valueScale(
                trackRange.boundary?.min ?? trackRange.boundary?.max ?? 0,
              ),
            ),
          )}
          y0={(d) =>
            returnZeroIfNegative(returnZeroIfInvalid(valueScale(d.value ?? 0)))
          }
          clipAboveTo={0}
          clipBelowTo={returnZeroIfNegative(returnZeroIfInvalid(valueScale(0)))}
          belowAreaProps={
            trackRange.boundary?.min ? negativeThreshold : positiveThreshold
          }
          aboveAreaProps={
            trackRange.boundary?.min ? positiveThreshold : negativeThreshold
          }
          {...regularLinePathProps}
        />
      </React.Fragment>
    );
  return null;
};

export { RoadmapColoredSegment };
