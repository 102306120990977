import { useIsFetching } from "@tanstack/react-query";
import CoreLayout, { Section, SectionLayout } from "components/Layout";
import LensesView from "components/RigComparisonLenses/LensesView";
import ControlHeader from "components/RigDashboard/ControlHeader";
import { RigPageSwitcher } from "components/RigPageSwitcher";
import { useRigsDataFeed } from "hooks/dataFeed/useRigsDataFeed";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import {
  URL_STATE_PARAM,
  useResetQueryState,
} from "hooks/navigation/useQueryState";
import { Legend } from "pages/RigDashboard/RigLegend";
import { StyledTabs } from "pages/WellDashboard/style";
import type { FC } from "react";
import {
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { usePageView } from "services/Mixpanel";
import { RequestUID } from "utils/queryNamespaces";

const Main: FC = () => {
  const params = useParams<{ rigId: string }>();
  const rigId = useMemo(() => Number(params.rigId), [params]);
  useRigsDataFeed();
  const [resetQOffsetRigs] = useResetQueryState([
    [URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET, URL_STATE_PARAM.OFFSET_RIGS],
  ]);
  const [resetQ] = useResetQueryState([
    [URL_STATE_PARAM.PERIOD_RIG_WIDGET, URL_STATE_PARAM.PERIOD_RIG_DASHBOARD],
    [URL_STATE_PARAM.FILTERS_WIDGET, URL_STATE_PARAM.FILTERS_DASHBOARD],
    [URL_STATE_PARAM.DATA_GROUPS_WIDGET, URL_STATE_PARAM.DATA_GROUPS_DASHBOARD],
    [
      URL_STATE_PARAM.SELECTED_WELLS_RIG_WIDGET,
      URL_STATE_PARAM.SELECTED_WELLS_RIG_DASHBOARD,
    ],
  ]);

  useEffect(() => {
    resetQ?.();
    // Wait some time before resetting the offset rigs because of some race condition that i can not identify
    setTimeout(() => resetQOffsetRigs?.(), 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: rigs, isLoading: isLoadingRigs } = useRigs();

  // Operators list is going to be decided by the frontend and when we unselect the operator the wells get unselected and uncheck the wells from that operator
  // use the intermediary checkbox checked when not all wells from operator are checked

  // groupings -> pivot type
  // G1 G2 Visual-Aids, filters, time, add lens
  const rigName = useMemo(() => {
    if (isLoadingRigs || !rigs?.byId) return "";
    return rigs.byId[rigId]?.shortName || "";
  }, [isLoadingRigs, rigs?.byId, rigId]);

  usePageView({
    title: "Rig Dashboard",
    enabled: !!rigName,
    options: { "Rig Id": rigId, "Rig Name": rigName || "" },
  });

  const refr = useRef<HTMLDivElement>(null);
  const sectionRef = useRef<HTMLDivElement>(null);
  const onWheel = useCallback(() => {
    if (refr.current) {
      refr.current.style.position = "fixed";
      refr.current.style.top = "128px";
    }
    if (sectionRef.current?.style) {
      sectionRef.current.style.marginTop = "74px";
    }
  }, []);
  const isFetchingLenses = useIsFetching({
    queryKey: [
      {
        uid: RequestUID.rigOperationTimes,
      },
    ],
  });

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0].contentRect.width && refr.current)
        refr.current.style.width = entries[0].contentRect.width + "px";
    });

    setTimeout(() => {
      if (sectionRef.current) observer.observe(sectionRef.current);
    }, 350);
    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
    };
  }, [isFetchingLenses]);
  const dispatch = useAppDispatch();
  const selectedRigId = useAppSelector(
    (state) => state.rigDashboard.selectedRig,
  );

  useEffect(() => {
    if (rigId !== selectedRigId) {
      // useMemo gives a different reference even if the value is the same, hence this check.
      dispatch({
        type: "SET_SELECTED_RIG",
        payload: Number(rigId),
      });
    }
  }, [dispatch, rigId, selectedRigId]);

  return (
    <CoreLayout
      onWheel={onWheel}
      sidebar={
        <SectionLayout
          header={
            <>
              <StyledTabs
                activeKey={"Legend"}
                type="line"
                size="large"
                items={[
                  {
                    key: "Legend",
                    label: "Legend",
                  },
                ]}
              />
              <RigPageSwitcher />
            </>
          }
        >
          <Suspense fallback={<div />}>
            <Legend />
          </Suspense>
        </SectionLayout>
      }
    >
      <SectionLayout
        useScrollCol
        header={
          <Section style={{ marginBottom: "14px" }}>
            <Suspense fallback={<div />}>
              <ControlHeader />
            </Suspense>
          </Section>
        }
      >
        <Suspense fallback={null}>
          <LensesView refr={refr} sectionRef={sectionRef} />
        </Suspense>
      </SectionLayout>
    </CoreLayout>
  );
};

export default Main;
