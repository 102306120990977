import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { EmptyTasksList } from "components/General/EmptyNotificationsList/EmptyTasksList";
import { PDComponent } from "components/PDComponents";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { useTasks } from "hooks/notifications/useTasks";
import { CloseIcon } from "pages/PendingTasks/components/style";
import { NotificationList } from "pages/PendingTasks/components/TasksList";
import {
  CustomCol,
  CustomRow,
  NotificationHeader,
  StyledNotificationContainer,
  StyledTab,
  StyledTabCounter,
  TabName,
  TasksPage,
  TitleBar,
} from "pages/PendingTasks/style";
import { type FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePageView } from "services/Mixpanel";
import { Popover, Tooltip } from "utils/componentLibrary";

export const PendingTasksPage: FC = () => {
  usePageView("Pending tasks");

  const { data: unfilteredTasks } = useTasks();
  const { data: rigs } = useRigs();
  const [showRigPopup, setShowRigPopup] = useState(false);
  const [selectedRigIds, setSelectedRigIds] = useState<number[]>(
    rigs.list.map((r) => r.id),
  );
  const isRigSubsetSelected = selectedRigIds.length < rigs.list.length;
  const tasks = unfilteredTasks
    .filter((t) => selectedRigIds.includes(t.rigId))
    .toReversed();

  const navigate = useNavigate();
  return (
    <TasksPage>
      <TitleBar>
        <CustomCol span={12}>
          <Title level={2}>Action Items</Title>
          <Tooltip title="Rigs">
            <Popover
              content={
                <PDComponent.ComboBoxMultiSelect
                  placeholder="Search Rigs"
                  options={(rigs.list ?? []).map((e) => ({
                    id: e.id,
                    name: e.shortName || "",
                  }))}
                  values={selectedRigIds}
                  onChange={(rigIds: number[]) => {
                    setSelectedRigIds(rigIds);
                    setShowRigPopup(false);
                    return void 0;
                  }}
                />
              }
              trigger="click"
              placement="bottom"
              open={showRigPopup}
              onOpenChange={(e) => setShowRigPopup(e)}
              destroyTooltipOnHide
            >
              <Button
                size="large"
                icon={<PDComponent.SvgIcon name="rig" />}
                type="default"
                ghost={isRigSubsetSelected}
                $engaged={showRigPopup}
              />
            </Popover>
          </Tooltip>
        </CustomCol>

        <CustomCol span={6}>
          <CustomRow justify={"end"}>
            <CustomCol>
              <CloseIcon
                $large
                onClick={() => {
                  navigate(-1);
                }}
              >
                <PDComponent.SvgIcon name="close" />
              </CloseIcon>
            </CustomCol>
          </CustomRow>
        </CustomCol>
      </TitleBar>
      <NotificationHeader>
        <StyledTab $isActive={true}>
          <TabName>Pending Approvals</TabName>{" "}
          <StyledTabCounter>{tasks.length}</StyledTabCounter>
        </StyledTab>
      </NotificationHeader>

      <StyledNotificationContainer>
        {tasks?.length > 0 ? (
          <NotificationList tasks={tasks} />
        ) : (
          <EmptyTasksList />
        )}
      </StyledNotificationContainer>
    </TasksPage>
  );
};
