import Legend from "components/Legend";
import { EmptyTimeline } from "components/Timeline/components/EmptyTimeline";
import { TimelineDrawing } from "components/Timeline/components/TimelineDrawing/TimelineDrawing";
import { useTimelineEventsList } from "hooks/tvd/useTimelineEventsList";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import type { ITimelineInfo } from "pages/WellDashboard/types";
import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "reducers/store";

export const Timeline = ({
  timelineOverride,
  setTimelineOverride,
}: {
  timelineOverride?: ITimelineInfo | null;
  setTimelineOverride: React.Dispatch<ITimelineInfo | null>;
}) => {
  const { actualEvents, planEvents } = useAppSelector(
    (state) => state.timeline.events,
  );
  const { planTimelineEventsList, actualTimelineEventsList } =
    useTimelineEventsList({ actualEvents, planEvents });
  const selectedTimeline = useAppSelector(
    (state) => state.state.timeline_state,
  );
  const wellId = useSelectedWell();
  const actionEnabled = useAppSelector((state) => state.timeline.actionEnabled);

  useEffect(() => {
    if (!actionEnabled) setTimelineOverride(null);
  }, [actionEnabled, setTimelineOverride]);

  const events = useMemo(
    () =>
      selectedTimeline === "Actual"
        ? actualTimelineEventsList
        : planTimelineEventsList,
    [actualTimelineEventsList, planTimelineEventsList, selectedTimeline],
  );

  if (selectedTimeline === "Legend") return <Legend />;
  if (!events.length)
    return (
      <EmptyTimeline
        selectedTimeline={selectedTimeline}
        link={`/well/${wellId}/plan`}
      />
    );

  return (
    <TimelineDrawing
      events={events}
      timelineOverride={timelineOverride || null}
      setTimelineOverride={setTimelineOverride}
      selectedTimeline={selectedTimeline}
    />
  );
};
