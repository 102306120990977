import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { StandKpiGroupDto } from "apis/oag";
import { StandKpiGroupsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const kpiGroups = new StandKpiGroupsApi(apiConfig);

const KpiGroupsQueryKey: PDUniqueQueryKey = { uid: RequestUID.kpiGroups };

export async function fetchKpiGroups() {
  const data = await kpiGroups.apiStandKpiGroupsGet();

  return {
    list: data,
    byId: data.reduce<Record<number, StandKpiGroupDto>>((acc, cur) => {
      acc[cur.id ?? 0] = cur;
      return acc;
    }, {}),
  };
}

export function useKpiGroups(
  options?: Omit<
    UseQueryOptions<{
      list: StandKpiGroupDto[];
      byId: Record<number, StandKpiGroupDto>;
    }>,
    "queryKey" | "queryFn"
  >,
) {
  return useQuery<{
    list: StandKpiGroupDto[];
    byId: Record<number, StandKpiGroupDto>;
  }>({
    queryKey: [KpiGroupsQueryKey],
    queryFn: () => fetchKpiGroups(),
    ...options,
    refetchOnMount: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
