import { ReportsApi } from "apis/oag";
import { Loader } from "components/Loader";
import {
  URL_STATE_PARAM,
  useBulkQuerySet,
} from "hooks/navigation/useQueryState";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { initialState } from "reducers/reportReducer";
import { IndicatorsState } from "reducers/stateReducer";
import { useAppDispatch } from "reducers/store";
import type { IUnitSystem } from "reducers/types";
import { CurvesEnum, initialDisplayOptions } from "reducers/types";
import superjson from "superjson";
import { apiConfig } from "utils/apiConfig";

const reportsApi = new ReportsApi(apiConfig);

const PreparePage = () => {
  const params = useParams<{
    wellId: string;
    lensId: string;
    reportId: string;
    page: string;
    position: string;
  }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [, getBulkState] = useBulkQuerySet();

  useEffect(() => {
    sessionStorage.setItem("isReport", "true");

    reportsApi
      .apiReportsIdPayloadGet({
        id: Number(params.reportId),
      })
      .then((e) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payload: [string, IUnitSystem, any] =
          superjson.parse<[string, IUnitSystem, any]>(e);
        localStorage.setItem("reportTime", payload[0]);
        dispatch({
          type: "SET_UNIT_OF_MEASURE",
          payload: payload[1],
        });
        const state = {
          ...payload[2],
          ...(params.position === "-1"
            ? {}
            : payload[2].selectedLenses[
                Number.parseInt(params.position || "")
              ]),
        };

        const indicators =
          payload[2].selectedLenses[Number.parseInt(params.position || "")]
            ?.indicators;
        if (indicators)
          dispatch({
            type: "SET_SELECTED_INDICATORS",
            payload: new Set(indicators as Array<IndicatorsState>),
          });
        else
          dispatch({
            type: "SET_SELECTED_INDICATORS",
            payload: new Set(
              Object.keys(IndicatorsState) as unknown as Array<IndicatorsState>,
            ),
          });
        dispatch({
          type: "SET_REPORT_STATE",
          payload: state,
        });
        dispatch({
          type: "REPORT_INCLUDE_LEGEND",
          payload:
            (params.position || "") === "-1"
              ? false
              : payload[2].selectedLenses[
                  Number.parseInt(params.position || "")
                ]?.legendVisible ?? true,
        });

        dispatch({
          type: "RAW_WIDGET_STATE_SET",
          payload: {
            ...state,
            display_options: state.displayOptions,
          },
        });
        const curves =
          payload[2].selectedLenses[Number.parseInt(params.position || "")]
            ?.displayOptions;

        const blkState = getBulkState([
          {
            strParam: URL_STATE_PARAM.OFFSET_WELL,
            defaultValue: payload[2].offsetWells ?? initialState.offsetWells,
          },
          {
            strParam: URL_STATE_PARAM.OFFSET_WIDGET,
            defaultValue: payload[2].offsetWells ?? initialState.offsetWells,
          },
          {
            strParam: URL_STATE_PARAM.FILTERS_DASHBOARD,
            defaultValue: payload[2].filters ?? initialState.filters,
          },
          {
            strParam: URL_STATE_PARAM.FILTERS_WIDGET,
            defaultValue: payload[2].filters ?? initialState.filters,
          },
          {
            strParam: URL_STATE_PARAM.ZOOM_WELL,
            defaultValue: payload[2].zoom ?? initialState.zoom,
          },
          {
            strParam: URL_STATE_PARAM.ZOOM_WIDGET,
            defaultValue: payload[2].zoom ?? initialState.zoom,
          },
          {
            strParam: URL_STATE_PARAM.DISPLAY_OPTIONS_WELL,
            defaultValue: {
              ...initialDisplayOptions,
              curves: curves
                ? curves.map((e: string) => {
                    if (e === "Plan") return CurvesEnum.PLAN;
                    if (e === "Bit Depth") return CurvesEnum.BIT_DEPTH;
                    if (e === "Actual") return CurvesEnum.HOLE_DEPTH;
                    return "";
                  })
                : null,
            },
          },
        ]);
        const rest = `/report/${params.reportId}/${params.wellId}/${params.lensId}/${params.position}/${params.page}`;
        if (params.page === "front") {
          navigate(
            `/report/${params.reportId}/${params.wellId}/${params.position}/front?${(blkState || "").replace(/.*\?/, "")}`,
            { replace: true },
          );
        } else {
          // TODO not sure why this replace is needed
          navigate(`${rest}?${(blkState || "").replace(/.*\?/, "")}`, {
            replace: true,
          });
        }
      });
  }, [dispatch, getBulkState, navigate, params]);

  return <Loader centered />;
};

export default PreparePage;
