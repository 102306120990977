import type { ScaleBand } from "d3-scale";

export interface ComputedTargetSegment {
  target: number;
  lineStart: number;
  lineEnd: number;
  categoryId: number | string | Date;
  showTag?: boolean;
}

export type CategoryTargetInfo = {
  id: number | string | Date;
  list: {
    id: number | string | Date;
    targetValue?: number | null;
  }[];
};

export interface TargetInfo {
  id: number | string | Date;
  targetValue?: number | null;
}

function isCategoryTargetInfo(
  data: (CategoryTargetInfo | TargetInfo)[],
): data is CategoryTargetInfo[] {
  return (data[0] as CategoryTargetInfo)?.list !== undefined;
}

function isSimpleTargetInfo(
  data: (CategoryTargetInfo | TargetInfo)[],
): data is TargetInfo[] {
  return (data[0] as CategoryTargetInfo)?.list === undefined;
}

function compress(
  segments: ComputedTargetSegment[],
  hideCategoryBridge?: boolean,
) {
  const newSegments = [...segments];
  let i = 0;

  while (i < newSegments.length - 1) {
    const seg = newSegments[i];
    const nextSeg = newSegments[i + 1];

    if (
      seg.target === nextSeg.target &&
      (!hideCategoryBridge || seg.categoryId === nextSeg.categoryId)
    ) {
      seg.lineEnd = nextSeg.lineEnd;
      newSegments.splice(i + 1, 1);
      i--;
    }
    i++;
  }
  return newSegments;
}

export function useTargetSegments(
  targetInfo: (CategoryTargetInfo | TargetInfo)[],
  outerScale: ScaleBand<any>,
  innerScale?: ScaleBand<any>,
  hideCategoryBridge?: boolean,
): ComputedTargetSegment[] {
  const segments: ComputedTargetSegment[] = [];

  if (isCategoryTargetInfo(targetInfo) && innerScale) {
    targetInfo?.forEach((categoryInfo) => {
      categoryInfo.list.forEach((bar) => {
        const lineStart =
          (outerScale(categoryInfo.id) || 0) + (innerScale(bar.id) || 0);
        if (bar.targetValue) {
          segments.push({
            target: bar.targetValue,
            lineStart,
            lineEnd: lineStart + innerScale.bandwidth(),
            categoryId: categoryInfo.id,
          });
        }
      });
    });
  }
  // If there is no inner scale, we are dealing with a single category chart
  else if (isSimpleTargetInfo(targetInfo)) {
    targetInfo?.forEach((bar) => {
      const lineStart = outerScale(bar.id);
      if (bar.targetValue && lineStart) {
        segments.push({
          target: bar.targetValue,
          lineStart,
          lineEnd: lineStart + outerScale.bandwidth(),
          categoryId: 0,
        });
      }
    });
  }

  const segmentsWithValue = segments.filter((seg) => seg.target);

  return compress(segmentsWithValue, hideCategoryBridge).map(
    (segment, idx) => ({
      ...segment,
      showTag: idx === 0 && segment.lineEnd - segment.lineStart > 0,
    }),
  );
}
