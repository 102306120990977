import { useQuery } from "@tanstack/react-query";
import { UserRoleType, UsersApi } from "apis/oag";
import { useOperators } from "hooks/drillingInvariants/useOperators";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const users = new UsersApi(apiConfig);
const queryKey: PDUniqueQueryKey = { uid: RequestUID.userWhoAmI };

export function useCurrentUser(enabled: boolean = true) {
  return useQuery({
    queryKey: [queryKey],
    queryFn: () => users.apiUsersMeGet(),
    enabled,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useCurrentUserCanAccessIntel() {
  const { data: currentUser } = useCurrentUser();
  const { data: operators } = useOperators();
  const canAccessIntel =
    currentUser !== undefined &&
    (currentUser.role === UserRoleType.Administrator ||
      (operators.byId[currentUser.operatorId]?.providesCrossOperatorClaims ??
        false)); // aka "Precision Drilling"

  return canAccessIntel;
}
