import { Title } from "atoms/Typography";
import { NoDataIcon } from "icons/custom/NoData";
import React from "react";
import styled from "styled-components";
import { Space } from "utils/componentLibrary";

export const NoDataContainer = styled(Space).attrs({
  direction: "vertical",
  align: "center",
})<{ $static: boolean }>`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: ${({ $static }) => ($static ? "static" : "absolute")};
  top: 0;
  left: 0;
`;

const StyledTitle = styled(Title).attrs({ level: 5, variant: "faded" })<{
  $detailed?: boolean;
}>`
  font-weight: 500 !important;
  font-size: ${({ $detailed }) => ($detailed ? 16 : 12)}px !important;
`;

const StyledDiv = styled.div<{ $detailed?: boolean }>`
  .pd-icon {
    font-size: ${({ $detailed }) => ($detailed ? 60 : 41)}px;
  }
`;
export default function NoData({
  isStatic,
  detailed,
  icon,
  message,
}: {
  isStatic?: boolean;
  detailed?: boolean;
  icon?: React.ReactElement;
  message?: string | React.ReactElement;
}) {
  return (
    <NoDataContainer $static={!!isStatic}>
      <StyledDiv $detailed={detailed}>{icon ?? <NoDataIcon />}</StyledDiv>
      <StyledTitle $detailed={detailed}>
        {message || "No data found"}
      </StyledTitle>
    </NoDataContainer>
  );
}
