import { Title } from "atoms/Typography";
import NoData from "components/Lenses/common/NoData";
import { InfoContainer } from "components/MiniLens/InfoContainer";
import { useCustomTheme } from "utils/useTheme";

export function LoadingChart({
  detailed,
  hasHeader,
  isSmallLens,
  title = "",
  isLoading = false,
  description = "...",
}: {
  isLoading: boolean;
  detailed?: boolean;
  hasHeader?: boolean;
  isSmallLens?: boolean;
  title?: string;
  description?: string;
}) {
  const { isDark, atomThemeVariant } = useCustomTheme();
  return (
    <div
      style={{
        padding: 0,
        margin: 0,
        width: "100%",
        height: hasHeader
          ? `calc(100% - ${detailed ? 190 : 0}px`
          : `calc(100% - ${detailed ? 91 : 0}px`,
        position: "relative",
      }}
    >
      {!detailed || hasHeader ? (
        <InfoContainer
          size={6}
          direction="vertical"
          $smallSizeLens={isSmallLens}
        >
          <Title
            ellipsis={{ rows: 1 }}
            level={3}
            style={{ paddingBottom: 2 }}
            variant={isDark ? "caption" : "faded"}
          >
            {title}
          </Title>
          <Title level={2} variant={atomThemeVariant} weight={500}>
            {description}
          </Title>
        </InfoContainer>
      ) : null}
      {isLoading ? null : <NoData detailed={detailed} />}
    </div>
  );
}
