import styled, { css } from "styled-components";

export const SelectorGroupContainer = styled.div<{ $width?: number }>`
  display: flex;
  width: ${(props) => (props.$width ? `${props.$width}px` : "fit-content")};
  padding: 4px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  background: ${(props) => props.theme.themeStyle.intel.selector_bg};
`;

export const StyledSelector = styled.button<{
  $selected: boolean;
  $removePadding?: boolean;
}>`
  display: flex;
  ${(props) => (props.$removePadding ? undefined : "padding: 6px 10px")};
  height: 34px;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 4px;
  background-color: transparent;
  color: ${(props) => props.theme.themeStyle.colors.disabled_typography};
  box-shadow: none;
  cursor: pointer;
  ${(props) =>
    props.$selected
      ? css`
          background: ${(props) =>
            props.theme.themeStyle.intel.selected_button_bg};
          color: ${(props) => props.theme.themeStyle.colors.black_white};
          box-shadow: 0px 1px 2px 0px
            ${(props) => props.theme.themeStyle.colors.primary_shadow};
        `
      : null}
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;
