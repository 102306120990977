import type { StandKpiType } from "apis/oag";
import { PivotType } from "apis/oag";

export const CARD_HEIGHT_TOTAL = 410;
export const CARD_SUMMARY_HEIGHT = 50;
export const CARD_SUMMARY_PADDING = 16;
export const CARD_SUMMARY_VERTICAL_SPACE =
  CARD_SUMMARY_HEIGHT + CARD_SUMMARY_PADDING;

export const CARD_PADDING = 15;
const MENU_HEIGHT = 128;

export const EXCESS_HEIGHT = MENU_HEIGHT + CARD_PADDING * 3;

export const BOTTOM_AXIS_HEIGHT_REGULAR_LABELS = 35;
export const WELL_AXIS_ADDED_HEIGHT = 40;
export const OPERATOR_AXIS_ADDED_HEIGHT = 35;
export const RIG_AXIS_ADDED_HEIGHT = 15;

export const RIGHT_AXIS_WIDTH = 50;
export const SCROLL_HEIGHT = 4;
export const SCROLL_PADDING = 12;
export const CHART_PADDING_TOP = 5;
export const CHART_HORIZONTAL_PADDING = 24;

// Table
export const TABLE_HEADER_HEIGHT = 30;
export const TABLE_RANK_WIDTH = 50;
export const TABLE_TREND_WIDTH = 76;
export const TABLE_KPI_WIDTH = 95;
export const TABLE_EXCESS_WIDTH =
  TABLE_RANK_WIDTH + TABLE_TREND_WIDTH + TABLE_KPI_WIDTH;

export const HIGHLIGHT_RATIO = 0.6;

export const MIN_CHART_HEIGHT = 60 + SCROLL_HEIGHT + SCROLL_PADDING + 20;
export const MIN_CHART_WIDTH =
  RIGHT_AXIS_WIDTH + SCROLL_HEIGHT + SCROLL_PADDING + 20;

export const LABEL_TRUNCATION_LENGTH_TABLE = 8;
export const LABEL_TRUNCATION_LENGTH = 13;
export const APPROX_CHAR_WIDTH = 8;
export const TOOLTIP_TOP = 10;
export const MAX_NUMBER_DECIMAL_THRESHOLD = 100;
export const SECONDS_IN_MINUTE = 60;

// Unfortunately this needs to be hardcoded on FE
// TODO NS: But why? And With integer and not StandKpiType enum value?
const CardKpiKeys = [
  "OverallPerformance",
  "DrillingS2STime",
  "DrillingS2BTime",
  "DrillingB2STime",
  "DrillingW2WTime",
  "TrippingS2STime",
  "TrippingPipeMovingSpeed",
  "TrippingOverallSpeed",
  "CasingS2STime",
  "CasingPipeMovingSpeed",
  "CasingOverallSpeed",
  "DrillingProductivity",
  "DrillingProductivityDistance",
  "DrillingProductivityOnBottomDuration",
  "DrillingProductivityOverallDuration",
  "LocationRigMoveTime",
  "WalkSpudTime",
  "WalkTime",
  "NippleTimeSurface",
  "PressureTestTimeSurface",
  "Downtime",
  "SlipCutTime",
  "NippleTimeIntermediate",
  "PressureTestTimeIntermediate",
  "NippleTimeTotal",
  "PressureTestTimeTotal",
] as const;
type CardStandKpiType = Record<(typeof CardKpiKeys)[number], StandKpiType>;

export const cardKpiTypesMap: CardStandKpiType = {
  OverallPerformance: 0, // It's not a KPI, but we need the name mapping. Lack of KPI means it's a cross-KPI notion
  DrillingS2STime: 1,
  DrillingS2BTime: 2,
  DrillingB2STime: 3,
  DrillingW2WTime: 4,
  TrippingS2STime: 19,
  TrippingPipeMovingSpeed: 21,
  TrippingOverallSpeed: 22,
  CasingS2STime: 34,
  CasingPipeMovingSpeed: 36,
  CasingOverallSpeed: 37,
  DrillingProductivity: 1000,
  DrillingProductivityDistance: 1001,
  DrillingProductivityOnBottomDuration: 1002,
  DrillingProductivityOverallDuration: 1003,
  LocationRigMoveTime: 2000,
  WalkSpudTime: 2001,
  WalkTime: 2002,
  NippleTimeSurface: 2003,
  PressureTestTimeSurface: 2004,
  Downtime: 2005,
  SlipCutTime: 2006,
  NippleTimeIntermediate: 2007,
  PressureTestTimeIntermediate: 2008,
  NippleTimeTotal: 2009,
  PressureTestTimeTotal: 2010,
};

export const cardKpiTypeToLabel = {
  [cardKpiTypesMap.OverallPerformance]: "Overall Performance",
  [cardKpiTypesMap.DrillingS2STime]: "Drilling S2S Time",
  [cardKpiTypesMap.DrillingS2BTime]: "Drilling S2B Time",
  [cardKpiTypesMap.DrillingB2STime]: "Drilling B2S Time",
  [cardKpiTypesMap.DrillingW2WTime]: "Drilling W2W Time",
  [cardKpiTypesMap.TrippingS2STime]: "Tripping S2S Time",
  [cardKpiTypesMap.TrippingPipeMovingSpeed]: "Tripping Pipe Moving Speed",
  [cardKpiTypesMap.TrippingOverallSpeed]: "Tripping Overall Speed",
  [cardKpiTypesMap.CasingS2STime]: "Casing S2S Time",
  [cardKpiTypesMap.CasingPipeMovingSpeed]: "Casing Pipe Moving Speed",
  [cardKpiTypesMap.CasingOverallSpeed]: "Casing Overall Speed",
  [cardKpiTypesMap.DrillingProductivity]: "Drilling Productivity",
  [cardKpiTypesMap.DrillingProductivityDistance]: "Drilling Distance",
  [cardKpiTypesMap.DrillingProductivityOnBottomDuration]: "On Bottom Duration",
  [cardKpiTypesMap.DrillingProductivityOverallDuration]: "Total Duration",
  [cardKpiTypesMap.LocationRigMoveTime]: "Location Rig Move Time",
  [cardKpiTypesMap.WalkSpudTime]: "Walk to Spud Time",
  [cardKpiTypesMap.WalkTime]: "Walk to Nipple Up",
  [cardKpiTypesMap.NippleTimeTotal]: "Nipple up Time",
  [cardKpiTypesMap.PressureTestTimeTotal]: "Pressure Test Time",
  [cardKpiTypesMap.NippleTimeSurface]: "Nipple up Time - Surf",
  [cardKpiTypesMap.PressureTestTimeSurface]: "Pressure Test Time - Surf",
  [cardKpiTypesMap.NippleTimeIntermediate]: "Nipple up Time - Int",
  [cardKpiTypesMap.PressureTestTimeIntermediate]: "Pressure Test Time - Int",
  [cardKpiTypesMap.PressureTestTimeTotal]: "Pressure Test Time",
  [cardKpiTypesMap.Downtime]: "Downtime #% ",
  [cardKpiTypesMap.SlipCutTime]: "Slip & Cut Time",
};

const DEFAULT_KPI_VALUES = {
  [cardKpiTypesMap.DrillingProductivity]: 0.00011574074,
  // ...
  // Add default values for other KPIs here
};

export const getDefaultValueFromKpiType = (kpiType: StandKpiType) => {
  if (DEFAULT_KPI_VALUES[kpiType] !== undefined) {
    return DEFAULT_KPI_VALUES[kpiType];
  }
  return 0;
};

export const drillingProductivityTypes = [
  cardKpiTypesMap.DrillingProductivity,
  cardKpiTypesMap.DrillingProductivityDistance,
  cardKpiTypesMap.DrillingProductivityOnBottomDuration,
  cardKpiTypesMap.DrillingProductivityOverallDuration,
];
export const surfaceTypes = [
  cardKpiTypesMap.LocationRigMoveTime,
  cardKpiTypesMap.WalkSpudTime,
  cardKpiTypesMap.WalkTime,
  cardKpiTypesMap.NippleTimeTotal,
  cardKpiTypesMap.PressureTestTimeTotal,
  cardKpiTypesMap.NippleTimeSurface,
  cardKpiTypesMap.PressureTestTimeSurface,
  cardKpiTypesMap.NippleTimeIntermediate,
  cardKpiTypesMap.PressureTestTimeIntermediate,
  cardKpiTypesMap.Downtime,
  cardKpiTypesMap.SlipCutTime,
];

export const isTimePivot = (pivotType: PivotType) =>
  (
    [
      PivotType.Day,
      PivotType.Week,
      PivotType.Month,
      PivotType.Quarter,
      PivotType.Year,
    ] as PivotType[]
  ).includes(pivotType);

export const shouldDisplayHighlightBar = (pivotType: PivotType) =>
  isTimePivot(pivotType) || pivotType === PivotType.Operator;
