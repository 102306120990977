import type { WellIntelQueryDto } from "apis/oag";
import { IntelGroupingType } from "apis/oag";

import type { IAction } from "./types";

export const UNFILTERED_SELECTIONS: WellIntelQueryDto = {
  grouping: IntelGroupingType.Contractor,
  selectedQuarters: null,
  wellNames: null,
  rigNames: null,
  contractorNames: null,
  operatorNames: null,
  formations: null,
  rigClasses: null,
  regions: null,
  fields: null,
  basins: null,
  plays: null,
  countries: null,
  stateProvinces: null,
  countyParishes: null,
};

export const EMPTY_SELECTIONS: WellIntelQueryDto = {
  // TODO fix this
  grouping: IntelGroupingType.Contractor,
  selectedQuarters: [],
  wellNames: [],
  rigNames: [],
  contractorNames: [],
  operatorNames: [],
  formations: [],
  rigClasses: [],
  regions: [],
  fields: [],
  basins: [],
  plays: [],
  countries: [],
  stateProvinces: [],
  countyParishes: [],
};

export type IFiltersReducerState = WellIntelQueryDto;

const initialState: IFiltersReducerState = UNFILTERED_SELECTIONS;

interface ISetAllUncommittedFilters extends IAction {
  type: "INTEL_DASHBOARD_SET_ALL_UNCOMMITTED_FILTERS";
  payload: IFiltersReducerState;
}

interface ISetFilterByKey extends IAction {
  type: "INTEL_DASHBOARD_SET_FILTER_KEY";
  payload: {
    key: keyof IFiltersReducerState;
    value: IFiltersReducerState[keyof IFiltersReducerState];
  };
}

interface IResetFilterByKey extends IAction {
  type: "INTEL_DASHBOARD_RESET_FILTER_KEY";
  payload: keyof IFiltersReducerState;
}

interface IEmptyFilterByKey extends IAction {
  type: "INTEL_DASHBOARD_EMPTY_FILTER_KEY";
  payload: keyof IFiltersReducerState;
}
interface IFiltersResetToInitialState extends IAction {
  type: "INTEL_DASHBOARD_FILTERS_RESET_TO_INITIAL_STATE";
}

type AvailableActions =
  | ISetAllUncommittedFilters
  | ISetFilterByKey
  | IResetFilterByKey
  | IEmptyFilterByKey
  | IFiltersResetToInitialState;

function intelFiltersReducerUncommitted(
  state: IFiltersReducerState = initialState,
  action: AvailableActions,
): IFiltersReducerState {
  switch (action.type) {
    case "INTEL_DASHBOARD_SET_ALL_UNCOMMITTED_FILTERS":
      return {
        ...action.payload,
      };

    case "INTEL_DASHBOARD_SET_FILTER_KEY":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case "INTEL_DASHBOARD_RESET_FILTER_KEY":
      return {
        ...state,
        [action.payload]: null,
      };

    case "INTEL_DASHBOARD_EMPTY_FILTER_KEY":
      return {
        ...state,
        [action.payload]: [],
      };

    case "INTEL_DASHBOARD_FILTERS_RESET_TO_INITIAL_STATE":
      return initialState;

    default:
      return state;
  }
}

export enum DetailedFilterVisibleKeys {
  "regions" = "regions",
  "basins" = "basins",
  "plays" = "plays",
  "fields" = "fields",
  "countries" = "countries",
  "stateProvinces" = "stateProvinces",
  "countyParishes" = "countyParishes",
  "rigClasses" = "rigClasses",
}

export enum SimpleFilterVisibleKeys {
  "rigNames" = "rigNames",
  "wellNames" = "wellNames",
  "contractorNames" = "contractorNames",
  "operatorNames" = "operatorNames",
  "formations" = "formations",
}

export const isDetailedFilterApplied = (filterGroups: IFiltersReducerState) => {
  return Object.keys(DetailedFilterVisibleKeys).some(
    (key) =>
      filterGroups[key as keyof typeof DetailedFilterVisibleKeys] !== null,
  );
};

export const isDetailedFilterEmptyFiltersSelection = (
  filterGroups: IFiltersReducerState,
) => {
  return Object.keys(DetailedFilterVisibleKeys).every(
    (filterKey) =>
      filterGroups[filterKey as keyof typeof DetailedFilterVisibleKeys]
        ?.length === 0,
  );
};

export default intelFiltersReducerUncommitted;
