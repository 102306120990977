/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface AllRigsDataFeedQueryDto
 */
export interface AllRigsDataFeedQueryDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof AllRigsDataFeedQueryDto
     */
    formationIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllRigsDataFeedQueryDto
     */
    operatorIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllRigsDataFeedQueryDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {DateDto}
     * @memberof AllRigsDataFeedQueryDto
     */
    from?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof AllRigsDataFeedQueryDto
     */
    to?: DateDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllRigsDataFeedQueryDto
     */
    includeFullWellFacts: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllRigsDataFeedQueryDto
     */
    selectedWellIds?: Array<number> | null;
    /**
     * 
     * @type {FiltersDto}
     * @memberof AllRigsDataFeedQueryDto
     */
    selectedFilters?: FiltersDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllRigsDataFeedQueryDto
     */
    isOnLeaderboard: boolean;
    /**
     * 
     * @type {DateDto}
     * @memberof AllRigsDataFeedQueryDto
     */
    lastWellRefreshDate?: DateDto | null;
}

/**
 * Check if a given object implements the AllRigsDataFeedQueryDto interface.
 */
export function instanceOfAllRigsDataFeedQueryDto(value: object): value is AllRigsDataFeedQueryDto {
    if (!('includeFullWellFacts' in value) || value['includeFullWellFacts'] === undefined) return false;
    if (!('isOnLeaderboard' in value) || value['isOnLeaderboard'] === undefined) return false;
    return true;
}

export function AllRigsDataFeedQueryDtoFromJSON(json: any): AllRigsDataFeedQueryDto {
    return AllRigsDataFeedQueryDtoFromJSONTyped(json, false);
}

export function AllRigsDataFeedQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllRigsDataFeedQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'formationIds': json['FormationIds'] == null ? undefined : json['FormationIds'],
        'operatorIds': json['OperatorIds'] == null ? undefined : json['OperatorIds'],
        'rigIds': json['RigIds'] == null ? undefined : json['RigIds'],
        'from': json['From'] == null ? undefined : DateDtoFromJSON(json['From']),
        'to': json['To'] == null ? undefined : DateDtoFromJSON(json['To']),
        'includeFullWellFacts': json['IncludeFullWellFacts'],
        'selectedWellIds': json['SelectedWellIds'] == null ? undefined : json['SelectedWellIds'],
        'selectedFilters': json['SelectedFilters'] == null ? undefined : FiltersDtoFromJSON(json['SelectedFilters']),
        'isOnLeaderboard': json['IsOnLeaderboard'],
        'lastWellRefreshDate': json['LastWellRefreshDate'] == null ? undefined : DateDtoFromJSON(json['LastWellRefreshDate']),
    };
}

export function AllRigsDataFeedQueryDtoToJSON(value?: AllRigsDataFeedQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'FormationIds': value['formationIds'],
        'OperatorIds': value['operatorIds'],
        'RigIds': value['rigIds'],
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
        'IncludeFullWellFacts': value['includeFullWellFacts'],
        'SelectedWellIds': value['selectedWellIds'],
        'SelectedFilters': FiltersDtoToJSON(value['selectedFilters']),
        'IsOnLeaderboard': value['isOnLeaderboard'],
        'LastWellRefreshDate': DateDtoToJSON(value['lastWellRefreshDate']),
    };
}

