import { ErrorBoundary } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import type {
  DateDto,
  DrillingProductivityUserLensDto,
  KpiGroupUserLensDto,
  LensTabWithVisibilityDto,
  ParameterByDepthUserLensDto,
  ParameterByDepthUserLensTrackItemDto,
  StandKpiGroupDto,
  StandKpiTypeDto,
  StickSlipByDepthUserLensTrackItemDto,
  UserLensDto,
  WedgeUserLensDto,
} from "apis/oag";
import { DimensionType, DisplayOption } from "apis/oag";
import { MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import { getAbilityMatrix } from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";
import { getContainerLens } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { MiniLoadingChart } from "components/Lenses/MiniLoadingChart";
import { useLensSettingsModal } from "components/Lenses/useLensSettingsModal";
import { CommonLensShell } from "components/MiniLens/CommonLensShell";
import { FallbackCom } from "components/MiniLens/FallbackComponent";
import { StyledLensContainer } from "components/MiniLens/style";
import { useKpiOptions } from "hooks/drillingInvariants/useKpiOptions";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { MouseInContext } from "hooks/lens/useLensMouseIn";
import { useLensSize } from "hooks/lens/useLensSize";
import type { LensTemplate } from "hooks/lens/useLensTemplates";
import {
  isDrillingProductivityLensTemplate,
  isWedgeTemplate,
  useLensTemplates,
} from "hooks/lens/useLensTemplates";
import { useLensUpdate } from "hooks/lens/useLensUpdate";
import { UserLensesQueryKey } from "hooks/lens/useUserLenses";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useEffectExceptOnMount } from "hooks/react-utils/useEffectExceptOnMount";
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { IZoomData } from "reducers/types";
import { drillingProductivityMap } from "utils/common";
import { getErrorHashCode } from "utils/getErrorHashCode";

export interface MiniLensProps {
  item: UserLensDto;
  isComparing: boolean;
  selectedTab: LensTabWithVisibilityDto;
  focalWellColor: string;
  displayOption: DisplayOption | null;
  graphKey: string;
}

export function getLensTitle({
  kpiType,
  kpiGroup,
  template,
  lens,
}: {
  kpiType?: StandKpiTypeDto;
  kpiGroup?: StandKpiGroupDto;
  template?: LensTemplate;
  lens: UserLensDto;
}) {
  const templateName = template?.name ?? "";
  if (isWedgeTemplate(template)) {
    return template?.breakdowns
      ? template?.breakdowns.find(
          (breakdown) =>
            breakdown.id === (lens as WedgeUserLensDto).breakdownId,
        )?.name || ""
      : "";
  }
  if (isDrillingProductivityLensTemplate(template)) {
    return (
      drillingProductivityMap.find(
        (i) =>
          i.id ==
          (lens as DrillingProductivityUserLensDto)?.drillingProductivityType,
      )?.name || ""
    );
  }

  return kpiType?.name || kpiGroup?.name || templateName || "";
}

function MiniLens({
  item: initialItem,
  selectedTab,
  focalWellColor,
  displayOption,
  graphKey,
}: MiniLensProps) {
  const { data: templates } = useLensTemplates();
  const [lensDate, setLensDate] = useState<string | DateDto>("");
  const [localLens, setLocalLens] = useState(initialItem);
  useEffect(() => {
    setLocalLens(initialItem);
  }, [initialItem]);
  const { data: kpiTypes } = useKpiTypes();
  const queryClient = useQueryClient();
  const [comparisonWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );
  const template = localLens?.lensTemplateId
    ? templates?.byId[localLens.lensTemplateId]
    : undefined;
  const ref = useRef<HTMLButtonElement>(null);
  const { kpiType, kpiGroup } = useKpiOptions(localLens);
  const [offsetWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WELL,
    [],
  );
  const [zoom] = useStateQuery<IZoomData | null>(
    URL_STATE_PARAM.ZOOM_WELL,
    null,
  );
  const [mouseIn, setMouseIn] = useState(false);

  const [selectedTrackItems, setSelectedTrackItems] = useState<
    | ParameterByDepthUserLensTrackItemDto[]
    | StickSlipByDepthUserLensTrackItemDto[]
  >([]);

  useEffect(() => {
    // FIX HERE

    if (
      selectedTrackItems.length === 0 &&
      (localLens as ParameterByDepthUserLensDto)?.userLensTrackItems
    ) {
      setSelectedTrackItems(
        (localLens as ParameterByDepthUserLensDto)?.userLensTrackItems
          ?.slice()
          .sort((a, b) => a.position - b.position) ?? [],
      );
    }
  }, [setSelectedTrackItems, selectedTrackItems.length, localLens]);

  const dimension: DimensionType = useMemo(() => {
    if (kpiType) {
      return kpiType.dimension;
    }

    if (kpiGroup && kpiTypes?.byId[kpiGroup.kpiTypeIds[0]].dimension) {
      return kpiTypes?.byId[kpiGroup.kpiTypeIds[0]].dimension;
    }

    return DimensionType.Seconds;
  }, [kpiType, kpiGroup, kpiTypes?.byId]);

  const lensTitle = useMemo(
    () => getLensTitle({ kpiType, kpiGroup, template, lens: localLens }),
    [kpiType, kpiGroup, template, localLens],
  );

  const [, height] = useLensSize(localLens?.id);
  const loadingElement =
    template && localLens ? (
      <MiniLoadingChart
        id={localLens.id}
        isSmallLens={height < 3}
        selectedTrackItems={selectedTrackItems}
        template={template.type}
        label={lensTitle}
        description="..."
        isLoading
      />
    ) : null;

  useEffectExceptOnMount(() => {
    if (ref.current) ref.current?.click?.();
  }, [
    offsetWells,
    zoom?.date_start,
    zoom?.date_end,
    zoom?.ts_start,
    zoom?.ts_end,
    zoom?.depth_start,
    zoom?.depth_end,
    // TODO add rigs related data as well
  ]);
  const onLensUpdated = useCallback(
    (newItem: UserLensDto) => {
      setLocalLens(newItem);
      queryClient.invalidateQueries({ queryKey: [UserLensesQueryKey] });
    },
    [queryClient],
  );

  const ContainerComponentInfo = getContainerLens(template?.type);
  const hasHiddenToolbar = ContainerComponentInfo?.settings?.hasHiddenToolbar;
  const hasNoHeader = !ContainerComponentInfo?.settings?.hasHeader;
  const defaultHeight = ContainerComponentInfo?.settings?.defaultHeight;
  const containerHeight = useMemo(() => {
    if (defaultHeight) return defaultHeight;
    if (hasHiddenToolbar) return "100%";
    if (hasNoHeader) return "calc(100% - 35px)";
    return "calc(100% - 125px)";
  }, [defaultHeight, hasNoHeader, hasHiddenToolbar]);

  const abilityMatrix = useMemo(() => {
    return getAbilityMatrix({
      type: template?.type,
      isComparing: comparisonWells.length > 0,
      displayOption: displayOption || DisplayOption.Well,
      stackingType: (localLens as KpiGroupUserLensDto)?.stackingType,
      isSystem: localLens?.isSystem,
    });
  }, [comparisonWells.length, displayOption, localLens, template?.type]);

  const canAddTracks =
    abilityMatrix.addUserTracks === MatrixAvailability.AVAILABLE;

  const hasSettings =
    ContainerComponentInfo?.LensSettingsModal !== null
      ? !!ContainerComponentInfo?.LensSettingsModal ||
        abilityMatrix.lensSettings === MatrixAvailability.AVAILABLE
      : false;

  const hasDetailed =
    abilityMatrix.hasDetailedView === MatrixAvailability.AVAILABLE;
  const handleLensUpdate = useLensUpdate({
    updateLens: ContainerComponentInfo?.lensUpdater,
    onLensUpdated,
  });

  const { open, node: lensSettingsModalNode } = useLensSettingsModal({
    lens: localLens,
    handleLensUpdate,
    isLocked: selectedTab?.isLocked,
    onLensUpdated,
    templateType: template?.type,
  });

  if (!template) return null;

  return (
    <MouseInContext.Provider value={{ mouseIn, setMouseIn }}>
      <CommonLensShell
        localLens={localLens}
        graphKey={graphKey}
        key={localLens?.id}
        tab={selectedTab}
        title={lensTitle}
        lensId={localLens?.id ?? 0}
        hasHiddenToolbar={hasHiddenToolbar}
        selectedTrackItems={selectedTrackItems}
        setSelectedTrackItems={setSelectedTrackItems}
        hasDetailed={hasDetailed}
        hasSettings={hasSettings}
        lastUpdated={lensDate}
        onLensUpdated={onLensUpdated}
        openSettingsModal={open}
        canAddTracks={canAddTracks}
      >
        <Suspense fallback={loadingElement}>
          <ErrorBoundary
            beforeCapture={(scope, error) => {
              scope.setTag("location", lensTitle);
              scope.setTag("lensId", localLens?.id ?? 0);
              scope.setTag(
                "pd-error-tracking-id",
                getErrorHashCode(error?.stack ?? ""),
              );
            }}
            fallback={({ resetError }) => (
              <FallbackCom
                resetError={resetError}
                setLensDate={setLensDate}
                lensTitle={lensTitle}
                localLensId={localLens.id}
              />
            )}
          >
            <StyledLensContainer $height={containerHeight} $padding={0}>
              {ContainerComponentInfo ? (
                <ContainerComponentInfo.Component
                  lens={localLens}
                  detailed={false}
                  dimension={dimension}
                  setLastUpdatedDate={setLensDate}
                  setLensDate={setLensDate}
                  graphKey={graphKey}
                  isLocked={selectedTab?.isLocked}
                  focalWellColor={focalWellColor}
                  displayOption={displayOption}
                  selectedTrackItems={selectedTrackItems}
                  setSelectedTrackItems={setSelectedTrackItems}
                  onLensUpdated={onLensUpdated}
                />
              ) : null}
            </StyledLensContainer>
          </ErrorBoundary>
          {lensSettingsModalNode}
        </Suspense>
      </CommonLensShell>
    </MouseInContext.Provider>
  );
}

export { MiniLens };
