import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  ApiOperatorsIdUsersPutRequest,
  UserWithAccessCountDto,
} from "apis/oag";
import { OperatorsApi } from "apis/oag";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDOperatorsAccountUsersQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const operators = new OperatorsApi(apiConfig);

export function useAdminSingleAccountUserList({
  id,
}: {
  id: number;
}): UseQueryResult<{
  list: UserWithAccessCountDto[];
  byId: { [key: number]: UserWithAccessCountDto };
}> {
  const search = useAppSelector(
    (state) => state.admin.singleAccountUsers.search || undefined,
  );
  const period = useAppSelector(
    (state) => state.admin.singleAccountUsers.period,
  );
  const rigs = useAppSelector((state) => state.admin.singleAccountUsers.rigs);
  const userStatus = useAppSelector(
    (state) => state.admin.singleAccountUsers.userStatus,
  );

  const parameters: ApiOperatorsIdUsersPutRequest = {
    id,
    userQueryDto: {
      search,
      expireDateStart: period.startDate,
      expireDateEnd: period.endDate,
      rigIds: rigs,
      status: userStatus,
    },
  };

  const queryKey: PDOperatorsAccountUsersQueryKey = {
    type: PDQueryType.OPERATORS_ACCOUNT_USERS,
    uid: RequestUID.operatorsAccountUsers,
    params: parameters,
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: () =>
      operators.apiOperatorsIdUsersPut(parameters).then((data) => {
        return {
          list: data,
          byId: data.reduce<Record<number, UserWithAccessCountDto>>(
            (acc, cur) => {
              acc[cur.id] = cur;
              return acc;
            },
            {},
          ),
        };
      }),
  });
}
