export const MockPowerLoadDistributionSvg = () => (
  <svg
    width="326"
    height="106"
    viewBox="0 0 326 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity=".6">
      <path
        opacity=".08"
        fill="#828C91"
        d="M0 42h326v1H0zM0 21h326v1H0zM0 0h326v1H0zM0 63h326v1H0zM0 84h326v1H0zM0 105h326v1H0z"
      />
      <path opacity=".08" fill="#131B1D" d="M0 105h326v1H0z" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 6a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v99H24V6z"
      fill="#C2B8A0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 28h6.636v77H24V28z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 74h6.636v31H24V74z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.636 56a1 1 0 0 1 1-1h4.637a1 1 0 0 1 1 1v49h-6.637V56z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.636 69h6.637v36h-6.637V69z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.636 83h6.637v22h-6.637V83zM41.273 86a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v19h-6.636V86z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.273 95h6.636v10h-6.636V95z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.273 101h6.636v4h-6.636v-4z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.91 66a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v39h-6.637V66zM58.545 46a1 1 0 0 1 1-1h4.637a1 1 0 0 1 1 1v59h-6.637V46z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.545 68h6.637v37h-6.637V68z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.545 90h6.637v15h-6.637V90z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.182 56a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v49h-6.636V56z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.182 69h6.636v36h-6.636V69z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.182 83h6.636v22h-6.636V83z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.818 56a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v49h-6.636V56z"
      fill="#C2B8A0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.455 46a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v59h-6.636V46z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.455 68h6.636v37h-6.636V68z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.455 90h6.636v15h-6.636V90z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.09 86a1 1 0 0 1 1-1h4.637a1 1 0 0 1 1 1v19h-6.636V86z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.09 95h6.637v10h-6.636V95z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.09 101h6.637v4h-6.636v-4z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.727 66a1 1 0 0 1 1-1h4.637a1 1 0 0 1 1 1v39h-6.637V66z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.364 66a1 1 0 0 1 1-1H116a1 1 0 0 1 1 1v39h-6.636V66z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.364 79H117v26h-6.636V79z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110.364 94H117v11h-6.636V94z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119 86a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v19H119V86z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119 95h6.636v10H119V95z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119 101h6.636v4H119v-4z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.636 66a1 1 0 0 1 1-1h4.637a1 1 0 0 1 1 1v39h-6.637V66z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.636 79h6.637v26h-6.637V79z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.636 94h6.637v11h-6.637V94z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.273 76a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v29h-6.636V76z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.273 85h6.636v20h-6.636V85z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.273 96h6.636v9h-6.636v-9z"
      fill="#C2B8A0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.909 76a1 1 0 0 1 1-1h4.637a1 1 0 0 1 1 1v29h-6.637V76z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.909 85h6.637v20h-6.637V85z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.909 96h6.637v9h-6.637v-9zM153.545 86a1 1 0 0 1 1-1h4.637a1 1 0 0 1 1 1v19h-6.637V86z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M162.182 56a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v49h-6.636V56z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M162.182 69h6.636v36h-6.636V69z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M162.182 83h6.636v22h-6.636V83z"
      fill="#C2B8A0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M170.818 66a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v39h-6.636V66zM179.455 46a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v59h-6.636V46z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M179.455 68h6.636v37h-6.636V68z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M179.455 90h6.636v15h-6.636V90z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M188.091 66a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v39h-6.636V66z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M188.091 79h6.636v26h-6.636V79z"
      fill="#C2B8A0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M188.091 94h6.636v11h-6.636V94z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M196.727 86a1 1 0 0 1 1-1h4.637a1 1 0 0 1 1 1v19h-6.637V86z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M196.727 95h6.637v10h-6.637V95z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M196.727 101h6.637v4h-6.637v-4z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M205.364 66a1 1 0 0 1 1-1H211a1 1 0 0 1 1 1v39h-6.636V66zM214 46a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v59H214V46z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M214 68h6.636v37H214V68z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M214 90h6.636v15H214V90z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M222.636 36a1 1 0 0 1 1-1h4.637a1 1 0 0 1 1 1v69h-6.637V36z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M222.636 57h6.637v48h-6.637V57z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M222.636 78h6.637v27h-6.637V78z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M231.273 46a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v59h-6.636V46zM239.909 66a1 1 0 0 1 1-1h4.637a1 1 0 0 1 1 1v39h-6.637V66z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M239.909 79h6.637v26h-6.637V79z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M239.909 94h6.637v11h-6.637V94z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M248.545 86a1 1 0 0 1 1-1h4.637a1 1 0 0 1 1 1v19h-6.637V86z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M248.545 95h6.637v10h-6.637V95z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M248.545 101h6.637v4h-6.637v-4z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M257.182 46a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v59h-6.636V46z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M257.182 68h6.636v37h-6.636V68z"
      fill="#C2B8A0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M257.182 90h6.636v15h-6.636V90z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M265.818 66a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v39h-6.636V66z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M265.818 79h6.636v26h-6.636V79z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M265.818 94h6.636v11h-6.636V94z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M274.455 46a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v59h-6.636V46z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M274.455 68h6.636v37h-6.636V68z"
      fill="#C2B8A0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M274.455 90h6.636v15h-6.636V90z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M283.091 66a1 1 0 0 1 1-1h4.636a1 1 0 0 1 1 1v39h-6.636V66z"
      fill="#C2B8A0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M291.727 46a1 1 0 0 1 1-1h4.637a1 1 0 0 1 1 1v59h-6.637V46z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M291.727 68h6.637v37h-6.637V68z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M291.727 90h6.637v15h-6.637V90z"
      fill="#726588"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M300.364 86a1 1 0 0 1 1-1H306a1 1 0 0 1 1 1v19h-6.636V86z"
      fill="#5DC0CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M300.364 95H307v10h-6.636V95z"
      fill="#D5ABA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M300.364 101H307v4h-6.636v-4z"
      fill="#726588"
    />
  </svg>
);
