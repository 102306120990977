import type {
  BinCoordinates,
  DecimalRange,
  ParameterHeatmapBin,
} from "apis/oag";
import type {
  ChartBin,
  HoveredBinType,
} from "components/Lenses/ContainerLens/ParameterHeatmapKpi/HoneyComb/HoneyCombD3";
import { render as renderHoneyComb } from "components/Lenses/ContainerLens/ParameterHeatmapKpi/HoneyComb/HoneyCombD3";
import { GLOWING_FILTER_HTML } from "components/Lenses/ContainerLens/ParameterHeatmapKpi/utils";
import React, { useEffect, useMemo, useRef } from "react";
import colors from "utils/colors";
import { useCustomTheme } from "utils/useTheme";

export const DEFAULT_TRANSLATE = 16; //For setting aside the hexbin from its axis
export const BIN_PADDING_X = 64;
export const TOOLTIP_TOP = -10;
export const BIN_LEGEND_WIDTH = 64;
export const LEGEND_PADDING = 64;
export const LINEAR_GRADIENT_WIDTH = 10;
export const AXIS_TRANSLATE = 25;
export const MAX_BIN_COUNT = 150;
// TODO later remove these after layout refactoring
export const INFO_ICON_X = 60;
export const INFO_ICON_Y = -42;

// This is used both for request params purposes and to calculate display. The tight coupling is intentional,
// We need to request from the server approximately amount of bins we can fit into the display.
// In one instance we use the lens metadata size, in the other we use the react measurement hook

export const HoneyCombChart: React.FC<{
  bins: ParameterHeatmapBin[];
  activeBinSettings?: { bin: BinCoordinates; isRealtime: boolean };
  overlayBinSettings?: { primary?: BinCoordinates; secondary?: BinCoordinates };
  xAxisValueRange: DecimalRange;
  yAxisValueRange: DecimalRange;
  parentContainerHeight: number;
  parentContainerWidth: number;
  isLoading: boolean;
  onBinHover: (
    e: MouseEvent,
    hoveredBinType: HoveredBinType,
    binData?: ChartBin,
  ) => void;
  onBinHoverOut: () => void;
  trackLabels: { x: string; y: string; z: string };
  axesUnitsTransformer: { x: any; y: any; z: any };
  title?: string;
}> = React.memo(
  ({
    bins,
    activeBinSettings,
    overlayBinSettings,
    xAxisValueRange,
    yAxisValueRange,
    parentContainerHeight,
    parentContainerWidth,
    isLoading,
    trackLabels,
    axesUnitsTransformer,
    title,
    onBinHover,
    onBinHoverOut,
  }) => {
    const svgEl = useRef<SVGSVGElement | null>(null);
    const { isDark } = useCustomTheme();
    const emptyBinColor = useMemo(
      () => (isDark ? colors.secondary_transparent_black : colors.white),
      [isDark],
    );

    useEffect(() => {
      // Since this uses d3 dom mutation, clear it again and redraw everytime deps change
      if (svgEl?.current?.innerHTML) {
        svgEl.current.innerHTML = GLOWING_FILTER_HTML;
      }

      renderHoneyComb({
        bins,
        xAxisValueRange,
        yAxisValueRange,
        containerInfo: { parentContainerWidth, parentContainerHeight, svgEl },
        onBinHover,
        onBinHoverOut,
        isLoading,
        emptyBinColor,
        trackLabels,
        isDarkTheme: isDark,
        axesUnitsTransformer,
        activeBinSettings,
        overlayBinSettings,
        title,
      });
    }, [
      bins,
      parentContainerWidth,
      parentContainerHeight,
      onBinHover,
      onBinHoverOut,
      isLoading,
      emptyBinColor,
      trackLabels,
      axesUnitsTransformer,
      xAxisValueRange,
      yAxisValueRange,
      isDark,
      activeBinSettings,
      overlayBinSettings,
      title,
    ]);

    return (
      <svg
        style={{ overflow: "visible" }}
        ref={svgEl}
        preserveAspectRatio="none"
      />
    );
  },
);
