import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import superjson from "superjson";

export enum URL_STATE_PARAM {
  FILTERS_DASHBOARD = "filtersW",
  FILTERS_WIDGET = "filtersWW",
  ZOOM_WELL = "zoomW",
  ZOOM_WIDGET = "zoomWW",
  OFFSET_WIDGET = "ofsWW", // TODO add well in define
  OFFSET_WELL = "ofsW", // TODO add well in define
  OFFSET_WELLS_RIGS_WIDGET = "ofsRWW", // TODO rename to offset rigs widget
  OFFSET_RIGS = "ofsRW",
  DISPLAY_OPTIONS_WELL = "displayOptions",
  PERIOD_RIG_DASHBOARD = "periodR",
  PERIOD_RIG_WIDGET = "periodRW",
  DATA_GROUPS_DASHBOARD = "optionR",
  SORT_ORDER_GROUPS_DASHBOARD = "optionOrderR",
  DATA_GROUPS_WIDGET = "optionRW",
  SORT_ORDER_GROUPS_WIDGET = "optionOrderRW",
  REALTIME_DATA_DASHBOARD = "realtimeDataW",
  REALTIME_DATA_WIDGET = "realtimeDataWW",
  SELECTED_OPERATORS_RIGS = "operatorsR",
  SELECTED_OPERATORS_RIG_SCORECARD = "operatorsRSC",
  ADDITIONAL_SELECTED_WELLS_RIG_SCORECARD = "wellsRSCplus",
  NOTIFICATION_SCORE_CARD_KPI_ID = "kpiIdNSC",
  NOTIFICATION_SCORE_CARD_GROUP_ID = "groupIdNSC",
  SELECTED_WELLS_RIG_SCORECARD = "wellsRSC",
  PRIMARY_SELECTOR_RIGS = "rigOpSelector",
  SELECTED_WELLS_RIG_DASHBOARD = "wellsR",
  SELECTED_WELLS_RIG_WIDGET = "wellsRW",
  WELL_NORMALIZED_DEPTH = "dpthN",
}

export function useStateQuery<T>(
  strParam: string,
  defaultValue: T,
  restParams?: Array<string>,
  ignoreIfAlreadySet?: boolean,
): [T, React.Dispatch<T>] {
  const location = useLocation();
  const navigate = useNavigate();
  const data = (
    (location?.search ?? "").replace("?", "").split("&") ?? []
  ).find((e) => e.split("=")[0] === strParam);
  const getValue = (data: string | undefined, defaultValue: T) => {
    if (!data) return defaultValue;
    let ret: T;
    try {
      ret = superjson.parse(window.atob(data.split("=")[1]));
    } catch (e) {
      ret = data.split("=")[1] as unknown as T;
    }
    return ret;
  };

  const defaultValRef = useRef(defaultValue);
  const [state, setState] = useState<T>(getValue(data, defaultValue));

  // Set state that will not trigger an update if the value of the state has not changed
  const smartSetState = (param: T) => {
    if (JSON.stringify(param) === JSON.stringify(state)) return;
    setState(param);
  };
  const locationString = JSON.stringify(location);

  const setStateWrapper: React.Dispatch<T> = useCallback(
    (param) => {
      if (!location) return;
      const qs = location.search;
      const params = Object.fromEntries(
        qs
          .replace("?", "")
          .split("&")
          .map((e) => e.split("=")) ?? [],
      );
      if (params[strParam] && ignoreIfAlreadySet) return;
      const crtState =
        typeof param === "string"
          ? param
          : window.btoa(superjson.stringify(param));
      smartSetState(param);

      [strParam, ...(restParams ?? [])].forEach(
        (parameter) => (params[parameter] = crtState),
      );

      const historyStr = `${location.pathname}?${Object.entries(params)
        .filter((e) => e[0])
        .map((e) => e.join("="))
        .join("&")}`;
      return navigate(historyStr, { replace: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ignoreIfAlreadySet, locationString, navigate, restParams, strParam],
  );

  useEffect(() => {
    if (!location) return;
    const qs = location.search;
    const params = qs.replace("?", "").split("&");
    const param = params.find((e) => e.split("=")[0] === strParam);
    if (param) {
      try {
        const val: T = superjson.parse(window.atob(param.split("=")[1]));
        if (val !== undefined) {
          smartSetState(val);
        } else {
          smartSetState(defaultValRef.current);
        }
      } catch (e) {
        const val: T = (param.split("=")[1] ?? "").replace(
          "%20",
          " ",
        ) as unknown as T;
        if (val) {
          smartSetState(val);
        } else {
          smartSetState(defaultValRef.current);
        }
      }
    } else {
      smartSetState(defaultValRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationString, strParam]);
  return [state, setStateWrapper];
}

export const useResetQueryState = (
  parameters: Array<[string, string | undefined]>,
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const resetCallback = useCallback(() => {
    const qs = location.search;
    const params = Object.fromEntries(
      qs
        .replace("?", "")
        .split("&")
        .map((e) => e.split("=")) ?? [],
    );
    parameters.forEach(([oldKey, replaceKey]) => {
      if (replaceKey && params[replaceKey]) params[oldKey] = params[replaceKey];
      else delete params[oldKey];
    });
    const historyStr = `${location.pathname}?${Object.entries(params)
      .filter((e) => e[0])
      .map((e) => e.join("="))
      .join("&")}`;
    navigate(historyStr, { replace: true });
  }, [location.pathname, location.search, navigate, parameters]);
  return [resetCallback];
};

export function useBulkQuerySet<T>() {
  const navigate = useNavigate();
  const location = useLocation();
  const locationString = JSON.stringify(location);

  const getBulkState = useCallback(
    (
      parameters: Array<{
        strParam: string;
        defaultValue?: T;
      }>,
    ) => {
      if (!location) return;
      const qs = location.search;
      const params = Object.fromEntries(
        qs
          .replace("?", "")
          .split("&")
          .map((e) => e.split("=")) ?? [],
      );

      (parameters ?? []).forEach(
        (parameter) =>
          (params[parameter.strParam] =
            typeof parameter.defaultValue === "string"
              ? parameter.defaultValue
              : window.btoa(superjson.stringify(parameter.defaultValue))),
      );

      const historyStr = `${location.pathname}?${Object.entries(params)
        .filter((e) => e[0])
        .map((e) => e.join("="))
        .join("&")}`;
      return historyStr;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationString],
  );

  const setStateWrapper = useCallback(
    (
      parameters: Array<{
        strParam: string;
        defaultValue?: T;
      }>,
    ) => {
      const stateUrl = getBulkState(parameters);
      if (stateUrl) navigate(stateUrl, { replace: true });
      return "";
    },
    [getBulkState, navigate],
  );

  return [setStateWrapper, getBulkState];
}
