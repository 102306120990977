import type { IAction } from "reducers/types";

interface ILensDimensionsState {
  width: number;
}

export const initialState: ILensDimensionsState = { width: 500 };

interface ISetWidthAction extends IAction {
  type: "SET_LENS_CONTAINER_WIDTH";
  payload: {
    width: number;
  };
}

type AvailableActions = ISetWidthAction;

function themeReducer(
  state: ILensDimensionsState = initialState,
  action: AvailableActions,
): ILensDimensionsState {
  switch (action.type) {
    case "SET_LENS_CONTAINER_WIDTH":
      // -6 to account for paddings and such..temporary hack
      return { ...state, width: action.payload.width - 6 };
    default:
      return state;
  }
}

export default themeReducer;
