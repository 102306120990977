/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { PivotOrderType } from './PivotOrderType';
import {
    PivotOrderTypeFromJSON,
    PivotOrderTypeFromJSONTyped,
    PivotOrderTypeToJSON,
} from './PivotOrderType';
import type { PivotType } from './PivotType';
import {
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface RigKpiPivotQueryDto
 */
export interface RigKpiPivotQueryDto {
    /**
     * 
     * @type {boolean}
     * @memberof RigKpiPivotQueryDto
     */
    useMockData: boolean;
    /**
     * 
     * @type {number}
     * @memberof RigKpiPivotQueryDto
     */
    sampleCount: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigKpiPivotQueryDto
     */
    comparisonWellIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigKpiPivotQueryDto
     */
    selectedRigIds?: Array<number> | null;
    /**
     * 
     * @type {FiltersDto}
     * @memberof RigKpiPivotQueryDto
     */
    selectedFilters?: FiltersDto | null;
    /**
     * 
     * @type {number}
     * @memberof RigKpiPivotQueryDto
     */
    zoomStartDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigKpiPivotQueryDto
     */
    zoomEndDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigKpiPivotQueryDto
     */
    zoomStartDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigKpiPivotQueryDto
     */
    zoomEndDepth?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof RigKpiPivotQueryDto
     */
    from?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof RigKpiPivotQueryDto
     */
    to?: DateDto | null;
    /**
     * 
     * @type {PivotType}
     * @memberof RigKpiPivotQueryDto
     */
    pivotLevel1: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof RigKpiPivotQueryDto
     */
    pivotLevel2: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof RigKpiPivotQueryDto
     */
    pivotLevel3: PivotType;
    /**
     * 
     * @type {PivotOrderType}
     * @memberof RigKpiPivotQueryDto
     */
    pivotLevel1Order: PivotOrderType;
    /**
     * 
     * @type {PivotOrderType}
     * @memberof RigKpiPivotQueryDto
     */
    pivotLevel2Order: PivotOrderType;
    /**
     * 
     * @type {PivotOrderType}
     * @memberof RigKpiPivotQueryDto
     */
    pivotLevel3Order: PivotOrderType;
}



/**
 * Check if a given object implements the RigKpiPivotQueryDto interface.
 */
export function instanceOfRigKpiPivotQueryDto(value: object): value is RigKpiPivotQueryDto {
    if (!('useMockData' in value) || value['useMockData'] === undefined) return false;
    if (!('sampleCount' in value) || value['sampleCount'] === undefined) return false;
    if (!('pivotLevel1' in value) || value['pivotLevel1'] === undefined) return false;
    if (!('pivotLevel2' in value) || value['pivotLevel2'] === undefined) return false;
    if (!('pivotLevel3' in value) || value['pivotLevel3'] === undefined) return false;
    if (!('pivotLevel1Order' in value) || value['pivotLevel1Order'] === undefined) return false;
    if (!('pivotLevel2Order' in value) || value['pivotLevel2Order'] === undefined) return false;
    if (!('pivotLevel3Order' in value) || value['pivotLevel3Order'] === undefined) return false;
    return true;
}

export function RigKpiPivotQueryDtoFromJSON(json: any): RigKpiPivotQueryDto {
    return RigKpiPivotQueryDtoFromJSONTyped(json, false);
}

export function RigKpiPivotQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigKpiPivotQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'useMockData': json['UseMockData'],
        'sampleCount': json['SampleCount'],
        'comparisonWellIds': json['ComparisonWellIds'] == null ? undefined : json['ComparisonWellIds'],
        'selectedRigIds': json['SelectedRigIds'] == null ? undefined : json['SelectedRigIds'],
        'selectedFilters': json['SelectedFilters'] == null ? undefined : FiltersDtoFromJSON(json['SelectedFilters']),
        'zoomStartDuration': json['ZoomStartDuration'] == null ? undefined : json['ZoomStartDuration'],
        'zoomEndDuration': json['ZoomEndDuration'] == null ? undefined : json['ZoomEndDuration'],
        'zoomStartDepth': json['ZoomStartDepth'] == null ? undefined : json['ZoomStartDepth'],
        'zoomEndDepth': json['ZoomEndDepth'] == null ? undefined : json['ZoomEndDepth'],
        'from': json['From'] == null ? undefined : DateDtoFromJSON(json['From']),
        'to': json['To'] == null ? undefined : DateDtoFromJSON(json['To']),
        'pivotLevel1': PivotTypeFromJSON(json['PivotLevel1']),
        'pivotLevel2': PivotTypeFromJSON(json['PivotLevel2']),
        'pivotLevel3': PivotTypeFromJSON(json['PivotLevel3']),
        'pivotLevel1Order': PivotOrderTypeFromJSON(json['PivotLevel1Order']),
        'pivotLevel2Order': PivotOrderTypeFromJSON(json['PivotLevel2Order']),
        'pivotLevel3Order': PivotOrderTypeFromJSON(json['PivotLevel3Order']),
    };
}

export function RigKpiPivotQueryDtoToJSON(value?: RigKpiPivotQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'UseMockData': value['useMockData'],
        'SampleCount': value['sampleCount'],
        'ComparisonWellIds': value['comparisonWellIds'],
        'SelectedRigIds': value['selectedRigIds'],
        'SelectedFilters': FiltersDtoToJSON(value['selectedFilters']),
        'ZoomStartDuration': value['zoomStartDuration'],
        'ZoomEndDuration': value['zoomEndDuration'],
        'ZoomStartDepth': value['zoomStartDepth'],
        'ZoomEndDepth': value['zoomEndDepth'],
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
        'PivotLevel1': PivotTypeToJSON(value['pivotLevel1']),
        'PivotLevel2': PivotTypeToJSON(value['pivotLevel2']),
        'PivotLevel3': PivotTypeToJSON(value['pivotLevel3']),
        'PivotLevel1Order': PivotOrderTypeToJSON(value['pivotLevel1Order']),
        'PivotLevel2Order': PivotOrderTypeToJSON(value['pivotLevel2Order']),
        'PivotLevel3Order': PivotOrderTypeToJSON(value['pivotLevel3Order']),
    };
}

