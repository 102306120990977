import AccountMe from "pages/Account/Me";
import AccountsListPage from "pages/Admin/Accounts";
import AccountsEditorPage from "pages/Admin/Accounts/Editor";
import UsersListPage from "pages/Admin/Users";
import UserEditorPage from "pages/Admin/Users/Editor";
import AllWells from "pages/AllWells";
import AuthBlank from "pages/Auth/Blank";
import Error401 from "pages/Auth/Error401";
import Error404 from "pages/Auth/Error404";
import AuthSignedOut from "pages/Auth/SignedOut";
import { Evergreen } from "pages/Evergreen";
import EvergreenDashboard from "pages/EvergreenDashboard";
import { FleetPerformance } from "pages/FleetPerformance";
import { ResetFiltersBeforeNavigate } from "pages/IntelDashboard/components/ResetFiltersBeforeNavigate";
import { IntelDashboard } from "pages/IntelDashboard/IntelDashboard";
import Lens from "pages/Lens";
import LensPrepare from "pages/Lens/LensPrepare";
import { LensLibrary } from "pages/LensLibrary";
import { AllNotifications } from "pages/Notifications";
import { NotificationSettingsPage } from "pages/NotificationSettings/NotificationSettingsPage";
import { PendingTasksPage } from "pages/PendingTasks";
import Report from "pages/Report/creator";
import ReportFrontPage from "pages/Report/Front";
import ReportLensPage from "pages/Report/Lens";
import ReportPreparePage from "pages/Report/Prepare";
import ReportTvDPage from "pages/Report/TimeVsDepth";
import RigDashboard from "pages/RigDashboard";
import { RigsLeaderboard } from "pages/RigLeaderboard";
import RigScoreCard from "pages/RigScoreCard";
import WellEditor from "pages/Well/Edit";
import WellPlanContainer from "pages/Well/Plan";
import WellDashboard from "pages/WellDashboard";
import { routePaths } from "routePaths";

export const PDRoutesMapping = {
  public: {
    blank: {
      path: routePaths.blank,
      element: <AuthBlank />,
    },
    error401: {
      path: routePaths.error401,
      element: <Error401 />,
    },
    error401WithId: {
      path: `${routePaths.error401}/:errorId`,
      element: <Error401 />,
    },
    error404: {
      path: routePaths.error404,
      element: <Error404 />,
    },
    signOut: {
      path: routePaths.signOut,
      element: <AuthSignedOut />,
    },
    reportPrepare: {
      path: "/report/:reportId/:wellId/:lensId/:position/prepare/:page",
      element: <ReportPreparePage />,
    },
  },
  private: {
    allWells: {
      path: routePaths.allWells,
      element: <AllWells />,
    },
    evergreen: {
      path: routePaths.evergreen,
      element: <Evergreen />,
    },
    fleetPerformance: {
      path: routePaths.fleetPerformance,
      element: <FleetPerformance />,
    },
    intelDashboard: {
      path: routePaths.intelDashboard,
      element: (
        <ResetFiltersBeforeNavigate>
          <IntelDashboard />
        </ResetFiltersBeforeNavigate>
      ),
    },
    rigsLeaderboard: {
      path: routePaths.rigLeaderboard,
      element: <RigsLeaderboard />,
    },
    notificationSettings: {
      path: "/notification-settings",
      element: <NotificationSettingsPage />,
    },
    // Admin pages
    accountList: {
      path: routePaths.accountList,
      element: <AccountsListPage />,
    },
    accountEdit: {
      path: routePaths.accountEdit,
      element: <AccountsEditorPage />,
    },
    usersList: {
      path: routePaths.usersList,
      element: <UsersListPage />,
    },
    userCreate: {
      path: routePaths.userCreate,
      element: <UserEditorPage mode="create" />,
    },
    userEdit: {
      path: routePaths.userEdit,
      element: <UserEditorPage mode="edit" />,
    },
    // Account page
    accountMe: {
      path: routePaths.accountMe,
      element: <AccountMe />,
    },
    // Reporting page
    createReport: {
      path: routePaths.createReport,
      element: <Report />,
    },
    // Well pages
    newWell: {
      path: routePaths.newWell,
      element: <WellEditor mode="create" />,
    },
    wellEdit: {
      path: routePaths.wellEdit,
      element: <WellEditor mode="edit" />,
    },
    evergreenWellEdit: {
      path: routePaths.evergreenWellEdit,
      element: <WellEditor mode="edit" />,
    },
    evergreenDashboard: {
      path: routePaths.evergreenDashboard,
      element: <EvergreenDashboard />,
    },

    wellDashboard: {
      path: routePaths.wellDashboard,
      element: <WellDashboard />,
    },
    rigScoreCard: {
      path: routePaths.rigScoreCard,
      element: <RigScoreCard />,
    },
    rigDashboard: {
      path: routePaths.rigDashboard,
      element: <RigDashboard />,
    },
    // Well plan
    wellPlan: {
      path: routePaths.wellPlan,
      element: <WellPlanContainer />,
    },
    // Lens pages
    prepareLens: {
      path: routePaths.prepareLens,
      element: <LensPrepare />,
    },
    lensLibrary: {
      path: routePaths.lensLibrary,
      element: <LensLibrary />,
    },
    lensDetailed: {
      path: routePaths.lensDetailed,
      element: <Lens />,
    },
    systemRigLens: {
      path: routePaths.systemRigLens,
      element: <Lens />,
    },

    // Notifications page
    notifications: {
      path: routePaths.notifications,
      element: <AllNotifications />,
    },
    pendingTasks: {
      path: routePaths.pendingTasks,
      element: <PendingTasksPage />,
    },
  },
  report: {
    front: {
      path: routePaths.reportFront,
      element: <ReportFrontPage />,
    },
    lens: {
      path: routePaths.reportLens,
      element: <ReportLensPage />,
    },
    tvd: {
      path: routePaths.reportTvd,
      element: <ReportTvDPage />,
    },
  },
} as const;
