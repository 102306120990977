import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type {
  ApiIntelRankingRibbonUserLensesIdFactsPutRequest,
  IntelRankingRibbonFactSetDto,
} from "apis/oag";
import { IntelRankingRibbonUserLensesApi } from "apis/oag";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new IntelRankingRibbonUserLensesApi(apiConfig);
export const useIntelRankingRibbonFacts = <T,>(
  lensId: number,
  requestParameters: ApiIntelRankingRibbonUserLensesIdFactsPutRequest,
  options?: Partial<
    UseSuspenseQueryOptions<IntelRankingRibbonFactSetDto, Error, T>
  >,
) => {
  const enabled = useAppSelector(
    (state) => state.intelDashboard.hasAutoSelectedQuarterYearList,
  );

  const queryKey: PDLensFactsQueryKey<ApiIntelRankingRibbonUserLensesIdFactsPutRequest> =
    {
      type: PDQueryType.FACTS,
      uid: RequestUID.intelRankingRibbon,
      params: requestParameters,
      lensId,
    };

  return useSuspenseQuery({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      enabled
        ? api.apiIntelRankingRibbonUserLensesIdFactsPut(requestParameters, {
            signal,
          })
        : new Promise(() => {}),
    ...options,
  });
};
