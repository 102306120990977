import {
  INFO_ICON_X,
  INFO_ICON_Y,
} from "components/Lenses/ContainerLens/ParameterHeatmapKpi/HoneyComb/HoneyCombChart";
import { PDComponent } from "components/PDComponents";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Col, Divider, Row, Switch } from "utils/componentLibrary";

export const StyledRow = styled(Row)`
  width: 100%;
  padding: 24px;
`;

export const StyledLensContainer = styled.div<{
  isDetailed: boolean;
  isLoading?: boolean;
}>`
  display: flex;
  width: 100%;
  position: absolute;
  overflow: visible;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  ${(props) => {
    if (props.isLoading)
      return css`
        height: 100%;
      `;
    if (props.isDetailed)
      return css`
        position: absolute;
        height: calc(100% - 300px);
        top: 200px;
        width: calc(100% - 128px);
      `;
    return css`
      height: calc(100% - 140px);
      top: 60px;
      width: 100%;
    `;
  }}

  svg > text,
  text,
  span {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    fill: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    font-size: 14px;
  }
`;

export const StyledTooltipGrayText = styled.span`
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const StyledInformationIcon = styled(PDComponent.SvgIcon).attrs({
  name: "information",
})`
  position: absolute;
  transform: translate(${INFO_ICON_X}px, ${INFO_ICON_Y}px);
`;

export const FormGroupContainer = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

export const FormLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const StyledDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const ToggleButton = styled.button<{ inactive?: boolean }>`
  outline: none;
  background: none;
  border: none;

  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: center;
  text-transform: uppercase;

  cursor: pointer;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  opacity: ${(props) => (props.inactive ? 0.4 : 1)};
`;

export const Grid = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 6fr 1fr 6fr 6fr;
  column-gap: 8px;
  row-gap: 8px;
  width: 100%;
`;

export const DepthGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 7fr;
  column-gap: 10px;
  align-items: center;
  row-gap: 8px;
  width: 100%;
  margin-top: 24px;
  div {
    min-width: auto;
  }
`;

export const RangeContainer = styled.div`
  margin-top: 24px;
  align-items: center;
`;

export const StyledDepthText = styled.span`
  font-size: 12px;
`;

export const CustomSwitch = styled(Switch)<{ checked: boolean }>`
  width: 44px !important;
  height: 24px !important;
  border-radius: 4px !important;
  padding: 2px 2px 2px 22px !important;
  border-radius: 4px !important;

  .ant-switch-handle:before {
    left: ${(props) =>
      props.checked ? "calc(100% - 18px - 3px)" : "0px"}!important;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
  }
`;

export const BoldRowLabel = styled(Col)<{ fill?: string }>`
  font-weight: bold;
  display: flex;
  align-items: center;
`;

export const SwitchRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
