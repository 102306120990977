import type { IAction } from "reducers/types";

export interface LegendColors {
  colorById: Map<string, { enabled: boolean }>;
}

export const initialState: LegendColors = {
  colorById: new Map(),
};

interface IAddLegendColor extends IAction {
  type: "ADD_COLOR_FOR_ID";
  payload: string;
}

interface IDisableLegendColor extends IAction {
  type: "DISABLE_COLOR_BY_ID";
  payload: string;
}

interface IResetLegendColors extends IAction {
  type: "RESET_LEGEND_COLORS";
}

type AvailableActions =
  | IAddLegendColor
  | IDisableLegendColor
  | IResetLegendColors;

export const AddColorForIdAction = (
  payload: IAddLegendColor["payload"],
): IAddLegendColor => ({
  type: "ADD_COLOR_FOR_ID",
  payload,
});

export const DisableColorForIdAction = (
  payload: IDisableLegendColor["payload"],
): IDisableLegendColor => ({
  type: "DISABLE_COLOR_BY_ID",
  payload,
});

export const ResetLegendColorsAction = (): IResetLegendColors => ({
  type: "RESET_LEGEND_COLORS",
});

function legendColorReducer(
  state: LegendColors = initialState,
  action: AvailableActions,
): LegendColors {
  const key = action.payload;

  const existingEntry = state.colorById.get(key);
  switch (action.type) {
    case "ADD_COLOR_FOR_ID": {
      if (existingEntry?.enabled) {
        return state;
      }

      return {
        ...state,
        colorById: new Map(state.colorById).set(key, { enabled: true }),
      };
    }
    case "DISABLE_COLOR_BY_ID":
      return {
        ...state,
        colorById: new Map(state.colorById).set(key, { enabled: false }),
      };
    case "RESET_LEGEND_COLORS": {
      return {
        colorById: new Map(),
      };
    }
    default:
      return state;
  }
}

export default legendColorReducer;
