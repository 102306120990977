/* eslint-disable react/no-multi-comp */
import { TimeUnit } from "apis/oag";
import { NO_DATA_KPI_STRING } from "components/Lenses/constants";
import { PDComponent } from "components/PDComponents";
import { URL_STATE_PARAM } from "hooks/navigation/useQueryState";
import {
  StyledDescriptionNumber,
  StyledRigDescriptionContainer,
} from "pages/FleetPerformance/RigList/styles";
import { MiniWellChart } from "pages/RigLeaderboard/components/MiniWellChart/MiniWellChart";
import type { RigViewmodel } from "pages/RigLeaderboard/components/RigLeaderboardList";
import { TSummary } from "pages/RigLeaderboard/components/ScoreBreakout/SummarizedComments";
import { useScoreBreakoutModal } from "pages/RigLeaderboard/components/ScoreBreakout/useScoreBreakoutModal";
import * as Styled from "pages/RigLeaderboard/components/styled";
import { useScoreValues } from "pages/RigLeaderboard/components/utils";
import { CustomTag } from "pages/RigScoreCard/LeftPane/components/CustomTag";
import {
  getColorByNetTime,
  getColorByScore,
  getPercentByScore,
  scoreColors,
} from "pages/RigScoreCard/LeftPane/utils";
import { memo } from "react";
import { useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import superjson from "superjson";
import colors from "utils/colors";
import { Skeleton } from "utils/componentLibrary";
import { useTimeUom, useUOM, UtilDimensions } from "utils/format";

const TREND_COLORS = {
  positive: colors.green_fluorite,
  negative: colors.coral_expression,
};

const TrendArrow = ({ trend }: { trend: number }) => {
  if (!Number.isFinite(trend)) return null;
  else
    return trend > 0 ? (
      <PDComponent.SvgIcon name="trendUp" />
    ) : (
      <PDComponent.SvgIcon name="trendDown" />
    );
};

export const RigLeaderboardListRow = memo(
  ({ rig, order }: { rig: RigViewmodel; order: number }) => {
    const getScoreValues = useScoreValues();
    const exceptions = useAppSelector((state) => state.rigsCommon.exceptions);

    const scoreValue = getScoreValues(rig, "score");
    const opportunityValue = getScoreValues(rig, "opportunityTime") || 0;
    const timeSavedValue = getScoreValues(rig, "timeSaved") || 0;
    const trendValue = getScoreValues(rig, "trend") || 0;

    const hourUom = useTimeUom(TimeUnit.Hour);
    const percentageUom = useUOM(UtilDimensions.Percentage);
    const complianceRate = getScoreValues(rig, "complianceRate");
    const { openScoreBreakout, scoreBreakoutElement } = useScoreBreakoutModal();
    const selectedWells = window.btoa(
      superjson.stringify(rig.wellBars.map((well) => well.id)),
    );
    return (
      <Styled.ListRow>
        <Styled.OrderColumn hasSmallFont>{order}</Styled.OrderColumn>
        <Styled.RigColumn>
          <StyledRigDescriptionContainer>
            <StyledDescriptionNumber>{rig?.name}</StyledDescriptionNumber>
            <Styled.OperatorName title={rig?.operatorName}>
              {rig?.operatorName}{" "}
            </Styled.OperatorName>
          </StyledRigDescriptionContainer>
        </Styled.RigColumn>
        <Styled.Column>
          <CustomTag
            icon={<PDComponent.SvgIcon name="meter" />}
            color={getColorByScore(scoreValue || 0)}
            text={percentageUom.display(scoreValue, { fractionDigits: 1 })}
            variant="filled"
            size="large"
            onClick={() => {
              openScoreBreakout(
                {
                  opportunityTime: opportunityValue,
                  score: scoreValue,
                  name: rig.name,
                  id: rig.rigId,
                  scoreSummary: rig.scoreSummary,
                  wellInfo: rig.wellBars,
                  summaryType: TSummary.Rig,
                },
                { title: "Rig Leaaderboard - Open Score Breakout" },
              );
            }}
          />
        </Styled.Column>

        <Styled.TrendColumn>
          <span
            style={{
              color:
                trendValue > 0 ? TREND_COLORS.positive : TREND_COLORS.negative,
            }}
          >
            <TrendArrow trend={trendValue} />
          </span>
          {Number.isFinite(trendValue)
            ? percentageUom.display(trendValue, { fractionDigits: 2 })
            : NO_DATA_KPI_STRING}
        </Styled.TrendColumn>

        <Styled.Column>
          <span
            style={{ color: scoreColors[getColorByNetTime(opportunityValue)] }}
          >
            {Number.isFinite(opportunityValue)
              ? hourUom.display(opportunityValue)
              : NO_DATA_KPI_STRING}
          </span>
        </Styled.Column>

        <Styled.Column>
          <span
            style={{ color: scoreColors[getColorByNetTime(timeSavedValue)] }}
          >
            {Number.isFinite(timeSavedValue)
              ? hourUom.display(timeSavedValue)
              : NO_DATA_KPI_STRING}
          </span>
        </Styled.Column>

        {exceptions ? (
          <Styled.Column>
            <span>
              {rig.approvedExceptionCount} of {rig.totalExceptionCount}
            </span>
          </Styled.Column>
        ) : (
          <Styled.Column>
            <span>{getPercentByScore(rig.totalDowntime || 0, 2)}</span>
          </Styled.Column>
        )}

        {exceptions ? (
          <Styled.Column>
            <span>
              {Number.isFinite(rig.approvedExceptionTime)
                ? rig.approvedExceptionTime == 0
                  ? "- -"
                  : hourUom.display(rig.approvedExceptionTime)
                : NO_DATA_KPI_STRING}
            </span>
          </Styled.Column>
        ) : Number.isFinite(complianceRate) ? (
          <Styled.ComplianceColumn>
            <Styled.Progress
              progress={(complianceRate || 0) * 100}
              role="progressbar"
            />
            {getPercentByScore(complianceRate || 0, 0)}
          </Styled.ComplianceColumn>
        ) : (
          <Styled.Column>{NO_DATA_KPI_STRING}</Styled.Column>
        )}

        <Styled.OperatorTargetColumn>
          <MiniWellChart wellBars={rig.wellBars} />
          <Styled.Link
            to={{
              pathname: `/rig/scorecard/${rig.rigId}`,
              search: `${URL_STATE_PARAM.SELECTED_WELLS_RIG_SCORECARD}=${selectedWells}`,
            }}
            onClick={() => {
              Track.interact("All Rigs - Open Rig Scorecard", {
                id: rig.rigId,
              });
            }}
          >
            <Styled.StyledRigScoreIcon />
          </Styled.Link>
        </Styled.OperatorTargetColumn>
        {scoreBreakoutElement}
      </Styled.ListRow>
    );
  },
);

export const RigLeaderboardListSkeleton = () => {
  return (
    <>
      {[...(Array(20).fill(1) as Array<number>)].map((_key, idx) => (
        <Styled.SkeletonContainer key={idx}>
          <Skeleton.Avatar
            active
            style={{ width: "calc(100vw - 64px - 24px)", borderRadius: 4 }}
            shape="square"
          />
        </Styled.SkeletonContainer>
      ))}
    </>
  );
};

RigLeaderboardListRow.displayName = "RigLeaderboardListRow";
