import NoData from "components/Lenses/common/NoData";
import { GroupTitle } from "pages/RigScoreCard/LeftPane/components/GroupTitle";
import * as Styled from "pages/RigScoreCard/LeftPane/Styled";
import { SCORECARD_HEIGHT } from "pages/RigScoreCard/LeftPane/utils";

export function NoDataScoreCard({ title }: { title: string }) {
  return (
    <Styled.RigScoreCardContainer height={SCORECARD_HEIGHT}>
      <Styled.CardWrapper>
        <GroupTitle inner title={title} withTagSeparators tags={[]} />
        <NoData />
      </Styled.CardWrapper>
    </Styled.RigScoreCardContainer>
  );
}
