import styled from "styled-components";
import colors from "utils/colors";
import { Button } from "utils/componentLibrary";

export const StoreBreakoutContainer = styled.div`
  padding: 24px 0px;
`;

export const HeaderContainer = styled.div`
  padding: 0px 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const HeaderRightContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const CloseButton = styled(Button)`
  padding: 0;
  background: transparent;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  outline: none;
  border: none;
  width: 24px;
  font-size: 24px;
  margin-left: 6px;
`;

export const DescriptionNumber = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  text-wrap: nowrap;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  @media screen and (max-width: 1300px) {
    font-size: 18px;
  }
`;

export const Description = styled.span`
  color: ${colors.necron_compound};
  font-size: 14px;
  font-style: normal;
  padding-bottom: 8px;
  font-weight: 400;
  line-height: normal;
`;
export const HeaderLeftContainer = styled.span`
  display: grid;
`;

export const OpportunityTime = styled.span<{ $color: string }>`
  display: inline-flex;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: ${({ $color }) => $color};

  h4 {
    padding-right: 6px;
  }
`;

export const CollapsibleContainer = styled.div`
  margin-top: 24px;
  padding: 0px 24px;
  height: auto;
  min-height: 175px;
  max-height: 400px;
  overflow: hidden auto;
`;

export const CollapsibleInner = styled.div`
  margin-top: 0px;
  padding: 12px;
  padding-left: 24px;
  padding-right: 0px;
`;

export const InnerTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  span {
    color: ${colors.necron_compound};
    font-size: 14px;
    font-style: normal;
    padding-bottom: 8px;
    font-weight: 400;
    line-height: normal;
  }
`;

export const TabContainer = styled.div`
  padding: 6px 12px;
  padding-right: 0px;
`;

export const TabInner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 0px;
  padding-top: 8px;
`;

export const SwitchButton = styled.div<{ $isCombinedComments: boolean }>`
  gap: 0px !important;
  span {
    padding: 6px;
    background: ${({ theme }) => theme.themeStyle.colors.off_bg};
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    border: 1px solid ${colors.necron_compound_light};
  }
  span:first-of-type {
    border-right: none;
    border-radius: 4px 0px 0px 4px;
  }
  span:last-of-type {
    border-radius: 0px 4px 4px 0px;
  }
  ${({ $isCombinedComments }) =>
    $isCombinedComments ? "span:last-of-type" : "span:first-of-type"} {
    background: ${colors.flamboyant_teal};
    color: ${colors.white};
  }
`;

export const Title = styled.h1`
  display: grid;
  padding: 8px 16px;

  align-items: center;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin: 0px;
  background-color: ${({ theme }) => theme.themeStyle.colors.label_pill};
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.alt_primary_bg};
  border-bottom: 1px solid
    ${({ theme }) => theme.themeStyle.colors.alt_primary_bg};
  span {
    color: ${colors.necron_compound};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const Subtitle = styled.h2`
  background-color: ${({ theme }) => theme.themeStyle.colors.label_pill};
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 8px 48px;
  align-items: center;
  gap: 2px;
  align-self: stretch;
  margin: 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.alt_primary_bg};
  border-bottom: 1px solid
    ${({ theme }) => theme.themeStyle.colors.alt_primary_bg};
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px !important;
  padding: 32px 0 32px 24px;
  width: calc(100% - 32px);
  overflow: auto;
  max-height: 400px;
  div {
    h2:first-of-type {
      border-top: none;
    }
  }
`;

export const LoaderContainer = styled.div`
  position: relative;
  margin-top: 24px;
`;

export const CommentContainer = styled.div`
  padding: 0px 36px;
`;
