import type {
  OperatorClaimDto,
  UserClaimDto,
  UserRigAccessDto,
} from "apis/oag";
import { ClaimPermissionType, ClaimType } from "apis/oag";
import { StyledSpace } from "components/WellAccess/style";
import { WellAccessSingle } from "components/WellAccess/WellsSingle";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";

export const WellAccessMultiple = memo(
  ({
    allRigAccesses: allRigAccesses,
    selector,
    allClaimsCallback = () => void 0,
    mode,
    viewOnly = false,
    deselectBack = () => void 0,
    commonFuture,
    setCanUpdate,
  }: {
    allRigAccesses: UserRigAccessDto[];
    selector: number;
    commonFuture?: boolean;
    allClaimsCallback?: (x: UserClaimDto[] | OperatorClaimDto[]) => void;
    mode: "user" | "operator";
    viewOnly?: boolean;
    deselectBack?: (x: (y: boolean) => void) => void;
    setCanUpdate?: React.Dispatch<boolean>;
  }) => {
    const rigs = useRigs();
    const allClaimRef = useRef<{
      [id: string]: UserClaimDto[] | OperatorClaimDto[];
    }>({});
    const allDeselectFunc = useRef<{ [x: number]: (y: boolean) => void }>({});

    const saveClaims = useCallback(
      (rid: number, claims: any) => {
        allClaimRef.current[rid] = claims;

        // @ts-ignore
        allClaimsCallback(Object.values(allClaimRef.current).flat());
      },
      [allClaimsCallback],
    );
    const deselectBackFn = useCallback(
      (rigId: number) => (f: (y: boolean) => void) =>
        (allDeselectFunc.current[rigId] = f),
      [allDeselectFunc],
    );

    useEffect(() => {
      let initialClaims: Array<UserClaimDto | OperatorClaimDto> = [];
      allClaimRef.current = {};
      Object.values(allRigAccesses ?? []).forEach((rig) => {
        const claimsPerRig: Array<UserClaimDto | OperatorClaimDto> = [];
        if (rig.futureWellAccess !== undefined) {
          // @ts-ignore
          claimsPerRig.push({
            [mode === "user" ? "userId" : "operatorId"]: selector,
            rigId: rig.rigId,
            type: ClaimType.FutureWellAccess,
            permission:
              rig.futureWellAccess === true
                ? ClaimPermissionType.All
                : ClaimPermissionType.None,
          });
        }

        (rig.wells ?? []).forEach((well) => {
          // @ts-ignore
          claimsPerRig.push({
            ...well,
            [mode === "user" ? "userId" : "operatorId"]: selector,
            wellId: Number(well.id),
            rigId: rig.rigId,
            type: ClaimType.Well,
          });
        });
        initialClaims = [...claimsPerRig, ...initialClaims];

        // @ts-ignore
        allClaimRef.current[rig.rigId] = claimsPerRig;
      });

      // @ts-ignore
      allClaimsCallback(initialClaims);
    }, [allClaimsCallback, allRigAccesses, mode, selector]);

    const deselectAll = (target: boolean) => {
      Object.keys(allDeselectFunc.current).forEach((k) => {
        allDeselectFunc.current[Number(k)](target);
      });
    };

    useEffect(() => {
      deselectBack(deselectAll);
    }, [deselectBack]);
    const filteredRigs = useMemo(() => {
      return allRigAccesses?.filter((e) => rigs.data?.byId[e?.rigId]);
    }, [allRigAccesses, rigs.data?.byId]);

    return (
      <StyledSpace direction="vertical" size={12}>
        {filteredRigs.map((e) => (
          <WellAccessSingle
            setCanUpdate={setCanUpdate}
            rigName={rigs.data?.byId[e?.rigId]?.shortName || ""}
            key={e.rigId}
            rigAccess={e}
            selector={selector}
            claimCallback={saveClaims}
            mode={mode}
            viewOnly={viewOnly}
            commonFuture={!!commonFuture}
            deselectBack={deselectBackFn(e.rigId)}
          />
        ))}
      </StyledSpace>
    );
  },
);
