import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Text } from "@visx/text";
import { type IntelRankingRibbonUserLensDto, TimeUnit } from "apis/oag";
import { StandardTickComponent } from "components/Lenses/common/ChartElements";
import NoData from "components/Lenses/common/NoData";
import {
  Scrollbar,
  type ScrollbarRange,
} from "components/Lenses/common/Scrollbar";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import {
  getAxisFontSize,
  LATERAL_AXIS_TEXT_WIDTH,
  LATERAL_AXIS_WIDTH,
  LATERAL_LABEL_POSITION,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import { useIntelRankingRibbonFacts } from "hooks/facts/useIntelRankingRibbonFacts";
import { groupBy } from "lodash";
import {
  CardHeader,
  CardTitle,
} from "pages/IntelDashboard/components/IntelScatterPlot/style";
import { IntelLegendColorsContext } from "pages/IntelDashboard/useIntelLegendColors";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useResizeDetector } from "react-resize-detector";
import { useAppSelector } from "reducers/store";
import { Col, Divider, Row } from "utils/componentLibrary";
import { useMetresPerTimeUnitUom } from "utils/format";
import { useCustomTheme } from "utils/useTheme";

import type { IntelRankingRibbonVm } from "./mappers";
import { IntelRankingRibbonSelector } from "./mappers";
import { RibbonLinks } from "./RibbonLinks";
import {
  CardInner,
  Circle,
  ContainerDiv,
  RankSpan,
  TooltipContainer,
} from "./style";
import type { TTooltip } from "./types";
import {
  BOTTOM_AXIS_HEIGHT,
  BOTTOM_AXIS_WITH_LABEL_HEIGHT,
  getColorByDiff,
  getIconByDiff,
  LEFT_AXIS_WIDTH,
  SCROLLBAR_HEIGHT,
  SCROLLBAR_PADDING,
  ZOOM_RATE_BREAKPOINT_LARGE,
  ZOOM_RATE_BREAKPOINT_MID,
  ZOOM_RATE_BREAKPOINT_SMALL,
} from "./utils";

export const IntelRankingRibbon = ({
  lens,
}: {
  lens: IntelRankingRibbonUserLensDto;
}) => {
  const { getColor } = useContext(IntelLegendColorsContext);
  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: outsideRef,
  } = useResizeDetector();
  const { chartWidth, chartHeight } = useMemo(
    () => ({
      chartHeight: getSVGNormalizedValue(chartHeightHook),
      chartWidth: getSVGNormalizedValue(chartWidthHook),
    }),
    [chartHeightHook, chartWidthHook],
  );

  const groupingType = useAppSelector(
    (state) => state.intelDashboard.groupingType,
  );

  const width = useMemo(() => chartWidth, [chartWidth]);
  const height = useMemo(() => chartHeight, [chartHeight]);
  const containerRef = useRef<HTMLDivElement>(null);
  const plotHeight = useMemo(
    () =>
      getSVGNormalizedValue(
        height - BOTTOM_AXIS_WITH_LABEL_HEIGHT - BOTTOM_AXIS_HEIGHT,
      ),
    [height],
  );

  const plotWidth = useMemo(
    () => getSVGNormalizedValue(width - LEFT_AXIS_WIDTH),
    [width],
  );
  const intelFilters = useAppSelector((state) => state.intelFiltersCommitted);

  const { data } = useIntelRankingRibbonFacts(
    lens.id,
    {
      id: lens.id,
      // comes from state
      wellIntelQueryDto: {
        ...intelFilters,
        grouping: groupingType,
      },
    },
    {
      select: IntelRankingRibbonSelector,
    },
  );

  // each bar is going to have height of value
  const names = useMemo(
    () => [...new Set(data?.map((d) => d.name).filter((name) => name))],
    [data],
  );

  const quarters = useMemo(
    () => [...new Set(data?.map((d) => d.quarter))],
    [data],
  );

  const needsScrollBar = useMemo(
    () => quarters.length > ZOOM_RATE_BREAKPOINT_SMALL,
    [quarters],
  );

  const scrollbarWidth = getSVGNormalizedValue(
    plotWidth - SCROLLBAR_PADDING * 2,
  );

  const zoomRate = useMemo(() => {
    if (
      quarters.length > ZOOM_RATE_BREAKPOINT_SMALL &&
      quarters.length <= ZOOM_RATE_BREAKPOINT_MID
    ) {
      return 1.5;
    }
    if (
      quarters.length > ZOOM_RATE_BREAKPOINT_MID &&
      quarters.length <= ZOOM_RATE_BREAKPOINT_LARGE
    ) {
      return 2;
    }
    if (quarters.length > ZOOM_RATE_BREAKPOINT_LARGE) {
      return 2.5;
    }
    return 1;
  }, [quarters]);

  const [scrollbarRange, setScrollbarRange] = useState<ScrollbarRange>({
    startX: 1 - 1 / zoomRate,
    endX: 1,
  });

  useEffect(() => {
    setScrollbarRange({ startX: 1 - 1 / zoomRate, endX: 1 });
  }, [zoomRate]);

  const xAxis = useMemo(() => {
    const chartSize = plotWidth * zoomRate;

    const startX = chartSize * scrollbarRange.startX;

    return scaleBand<string>({
      domain: quarters,
      range: [-startX, chartSize - startX],
      paddingInner: 0.6,
      paddingOuter: 0.2,
    });
  }, [quarters, plotWidth, scrollbarRange, zoomRate]);

  const scrollbarScale = useMemo(
    () => scaleLinear<number>({ domain: [0, 1], range: [0, scrollbarWidth] }),
    [scrollbarWidth],
  );

  const yAxis = useMemo(
    () =>
      scaleLinear({
        domain: [
          Math.max(
            ...Object.values(groupBy(data, (d) => d.quarter)).map((d) =>
              d.reduce((acc, curr) => acc + curr.productivity, 0),
            ),
          ),
          0,
        ],
        range: [plotHeight, 0],
      }),
    [data, plotHeight],
  );

  // currentValue is all the previous values plus the current one
  const getCurrentValue = useCallback(
    (d: IntelRankingRibbonVm, data: IntelRankingRibbonVm[]) => {
      const previous = data.filter(
        (p) => p.quarter === d.quarter && p.rank < d.rank,
      );
      if (previous.length === 0) {
        return yAxis(0);
      }
      return previous.reduce((acc, curr) => acc + yAxis(curr.productivity), 0);
    },
    [yAxis],
  );

  const {
    themeStyle: { colors: themeColors, intel: intelColors },
  } = useCustomTheme();
  const axisFontSize = useMemo(() => getAxisFontSize(false), []);

  const uom = useMetresPerTimeUnitUom(TimeUnit.Day);

  const { verticalAxisTicksCount, yDisplayOnlyScale, formatTickDecimals } =
    useYAxisDisplayScale({
      originalScale: yAxis,
      uom,
      tickContainerHeight: plotHeight,
    });

  const { showTooltip, hideTooltip, tooltipElement } =
    useChartTooltip<TTooltip>({
      containerRef,
      renderContent: ({ tooltipData }) => {
        if (!tooltipData) return null;
        return (
          <TooltipContainer>
            {(Object.keys(tooltipData) as Array<keyof TTooltip>).map((key) =>
              key === "Productivity Change" ? (
                <Row
                  key={key}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col>{key}</Col>
                  <Col>
                    <RankSpan color={tooltipData[key].color}>
                      {tooltipData[key].value}
                    </RankSpan>
                  </Col>
                </Row>
              ) : (
                <Row
                  key={key}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {key === "Legend" ? (
                    <>
                      <Divider
                        style={{
                          width: "100%",
                          borderTop: `1px solid #828C911F`,
                          margin: "8px 0",
                        }}
                      />
                      <Col>{key}</Col>
                      <Col>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <Circle color={tooltipData[key].color} />
                              </td>
                              <td>{tooltipData[key].value}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col>{key}</Col>
                      <Col>{tooltipData[key]}</Col>
                    </>
                  )}
                </Row>
              ),
            )}
          </TooltipContainer>
        );
      },
    });

  const handlePointerMove = useCallback(
    (
      event: React.MouseEvent<SVGPathElement, MouseEvent>,
      currentPoint: IntelRankingRibbonVm,
    ) => {
      if (!currentPoint) return;
      const { left, top } = containerRef.current?.getBoundingClientRect() ?? {
        left: 0,
        top: 0,
      };
      const x = ("clientX" in event ? event.clientX : 0) - left;
      const y = ("clientY" in event ? event.clientY : 0) - top;

      const currentProductivity = uom.display(currentPoint.productivity, {
        fractionDigits: 0,
      });

      const productivityChange = currentPoint.diff?.productivityDiff ?? 0;

      const productivityChangeDisplay = {
        value: currentPoint.diff?.productivityDiff
          ? `${uom.display(productivityChange, {
              fractionDigits: 0,
            })} (${productivityChange > 0 ? "+" : "-"}${(
              (currentPoint.diff?.productivityDiffPCt ?? 0) * 100
            ).toFixed(0)}%)`
          : "- -",
        color: getColorByDiff(productivityChange),
      };

      const rankIcon = getIconByDiff(currentPoint.diff?.rankDiff);

      const rankChange = (
        <RankSpan
          color={getColorByDiff((currentPoint.diff?.rankDiff ?? 0) * -1)}
        >
          {rankIcon ? <PDComponent.SvgIcon name={rankIcon} /> : null}

          {currentPoint.diff?.rankDiff
            ? `${Math.abs(currentPoint.diff?.rankDiff)}`
            : "- -"}
        </RankSpan>
      );

      const legend = {
        value: currentPoint.name,
        color: getColor({
          key: currentPoint.name,
        }),
      };

      showTooltip({
        tooltipLeft: x,
        tooltipTop: y,
        tooltipData: {
          Productivity: currentProductivity,
          "Productivity Change": productivityChangeDisplay,
          Rank: currentPoint.rank,
          "Rank Change": rankChange,
          Legend: legend,
        },
      });
    },
    [showTooltip, uom, getColor],
  );

  if (!data) return null;

  return (
    <CardInner ref={outsideRef}>
      <CardHeader>
        <CardTitle>Ranking Ribbon Chart</CardTitle>
      </CardHeader>
      <ContainerDiv ref={containerRef}>
        {names.length === 0 ? (
          <NoData isStatic />
        ) : width <= 0 || height <= 0 ? null : (
          <svg width={width} height={height}>
            <GridRows
              scale={yDisplayOnlyScale}
              width={xAxis.range()[1] - xAxis.paddingOuter() * xAxis.step() * 2}
              height={plotHeight}
              numTicks={verticalAxisTicksCount / 2}
              stroke={intelColors.grid_lines}
              strokeWidth={1}
              strokeOpacity={1}
              left={LEFT_AXIS_WIDTH + xAxis.paddingOuter() * xAxis.step()}
              top={BOTTOM_AXIS_HEIGHT}
            />
            <Group>
              {/* create rectangles for each of the values for the series */}
              {names.map((name) => {
                if (!name) return null;
                const dataForSeries = data.filter((d) => d.name === name);

                return (
                  <Group
                    key={name}
                    top={BOTTOM_AXIS_HEIGHT}
                    left={LEFT_AXIS_WIDTH}
                  >
                    {dataForSeries.map((d, i) => {
                      const height = yAxis(d.productivity);
                      const y = getCurrentValue(d, data);
                      if (height <= 0) return null;

                      // if no next in the next quarter you don t connect them
                      return (
                        <Group key={`${d.name}-${d.quarter}-${d.rank}`}>
                          <rect
                            x={xAxis(d.quarter) ?? 0}
                            y={y}
                            onMouseLeave={hideTooltip}
                            onMouseMove={(e) => {
                              handlePointerMove(e, d);
                            }}
                            width={xAxis.bandwidth()}
                            height={height}
                            fill={getColor({
                              key: d.name,
                            })}
                          />

                          <RibbonLinks
                            d={d}
                            hideTooltip={hideTooltip}
                            handlePointerMove={handlePointerMove}
                            xAxis={xAxis}
                            yAxis={yAxis}
                            i={i}
                            dataForSeries={dataForSeries}
                            getCurrentValue={getCurrentValue}
                            data={data}
                          />
                        </Group>
                      );
                    })}
                  </Group>
                );
              })}
            </Group>

            <AxisBottom
              hideAxisLine
              hideTicks
              label="Quarterly Time Trend"
              labelProps={{
                fill: intelColors.typography,
                fontSize: axisFontSize,
                textAnchor: "middle",
              }}
              numTicks={quarters.length}
              left={LEFT_AXIS_WIDTH}
              scale={xAxis}
              top={plotHeight + BOTTOM_AXIS_HEIGHT}
              tickComponent={(props) => {
                return (
                  <Text
                    x={props.x}
                    y={props.y - axisFontSize}
                    fontSize={axisFontSize}
                    fill={themeColors.disabled_typography}
                    pointerEvents="none"
                    verticalAnchor="start"
                    textAnchor="middle"
                  >
                    {props.formattedValue}
                  </Text>
                );
              }}
            />

            {needsScrollBar ? (
              <Group top={BOTTOM_AXIS_HEIGHT - 1}>
                {/* Rect to hide the scrolled chart behind left axis */}
                <rect
                  width={LATERAL_AXIS_WIDTH + LATERAL_AXIS_TEXT_WIDTH}
                  height={plotHeight + BOTTOM_AXIS_HEIGHT + axisFontSize + 1}
                  fill={intelColors.chart_bg}
                />
              </Group>
            ) : null}

            <AxisLeft
              hideTicks
              hideAxisLine
              label={`Productivity (${uom.abbr})`}
              labelOffset={LATERAL_LABEL_POSITION - LATERAL_AXIS_TEXT_WIDTH}
              scale={yDisplayOnlyScale}
              numTicks={verticalAxisTicksCount / 2}
              labelProps={{
                fill: intelColors.typography,
                textAnchor: "middle",
                fontSize: axisFontSize,
              }}
              top={BOTTOM_AXIS_HEIGHT}
              left={LATERAL_AXIS_WIDTH - LATERAL_AXIS_TEXT_WIDTH}
              tickComponent={(props) => (
                <StandardTickComponent rendererProps={props}>
                  {(val) => formatTickDecimals(val)}
                </StandardTickComponent>
              )}
            />
            {needsScrollBar ? (
              <Group
                top={plotHeight + BOTTOM_AXIS_HEIGHT + SCROLLBAR_PADDING}
                left={SCROLLBAR_PADDING + LATERAL_AXIS_WIDTH}
              >
                <Scrollbar
                  width={scrollbarWidth}
                  height={SCROLLBAR_HEIGHT}
                  scale={scrollbarScale}
                  onScroll={setScrollbarRange}
                  valueSpan={1 / zoomRate}
                />
              </Group>
            ) : null}
            {tooltipElement}
          </svg>
        )}
      </ContainerDiv>
    </CardInner>
  );
};
