export const MockStackedKpiSvg = () => {
  return (
    <svg
      width="326"
      height="126"
      viewBox="0 0 326 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2594_68037"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="326"
        height="126"
      >
        <rect width="326" height="126" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2594_68037)">
        <g opacity="0.6">
          <rect opacity="0.08" y="62" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="41" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="20" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="83" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="104" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="125" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="125" width="326" height="1" fill="#131B1D" />
        </g>
        <g clipPath="url(#clip0_2594_68037)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 87C24 86.4477 24.4477 86 25 86H41C41.5523 86 42 86.4477 42 87V95H24V87Z"
            fill="#89CEC2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 95H42V126H24V95Z"
            fill="#56B9A8"
          />
        </g>
        <g clipPath="url(#clip1_2594_68037)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.6364 67C47.6364 66.4477 48.0841 66 48.6364 66H64.6364C65.1886 66 65.6364 66.4477 65.6364 67V88H47.6364V67Z"
            fill="#89CEC2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.6364 88H65.6364V126H47.6364V88Z"
            fill="#56B9A8"
          />
        </g>
        <g clipPath="url(#clip2_2594_68037)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71.2727 107C71.2727 106.448 71.7205 106 72.2727 106H88.2727C88.825 106 89.2727 106.448 89.2727 107V114H71.2727V107Z"
            fill="#89CEC2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71.2727 114H89.2727V126H71.2727V114Z"
            fill="#56B9A8"
          />
        </g>
        <g clipPath="url(#clip3_2594_68037)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M94.9091 74C94.9091 73.4477 95.3568 73 95.9091 73H111.909C112.461 73 112.909 73.4477 112.909 74V95H94.9091V74Z"
            fill="#89CEC2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M94.9091 95H112.909V126H94.9091V95Z"
            fill="#56B9A8"
          />
        </g>
        <g clipPath="url(#clip4_2594_68037)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M118.545 47C118.545 46.4477 118.993 46 119.545 46H135.545C136.098 46 136.545 46.4477 136.545 47V65H118.545V47Z"
            fill="#89CEC2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M118.545 65H136.545V126H118.545V65Z"
            fill="#56B9A8"
          />
        </g>
        <g clipPath="url(#clip5_2594_68037)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M142.182 74C142.182 73.4477 142.63 73 143.182 73H159.182C159.734 73 160.182 73.4477 160.182 74V95H142.182V74Z"
            fill="#0D9B83"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M142.182 95H160.182V126H142.182V95Z"
            fill="#096C5B"
          />
        </g>
        <g clipPath="url(#clip6_2594_68037)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M165.818 87C165.818 86.4477 166.266 86 166.818 86H182.818C183.37 86 183.818 86.4477 183.818 87V95H165.818V87Z"
            fill="#0D9B83"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M165.818 95H183.818V126H165.818V95Z"
            fill="#096C5B"
          />
        </g>
        <g clipPath="url(#clip7_2594_68037)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M189.455 57C189.455 56.4477 189.902 56 190.455 56H206.455C207.007 56 207.455 56.4477 207.455 57V73H189.455V57Z"
            fill="#0D9B83"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M189.455 73H207.455V126H189.455V73Z"
            fill="#096C5B"
          />
        </g>
        <g clipPath="url(#clip8_2594_68037)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M213.091 37C213.091 36.4477 213.539 36 214.091 36H230.091C230.643 36 231.091 36.4477 231.091 37V62H213.091V37Z"
            fill="#0D9B83"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M213.091 62H231.091V126H213.091V62Z"
            fill="#096C5B"
          />
        </g>
        <g clipPath="url(#clip9_2594_68037)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M236.727 74C236.727 73.4477 237.175 73 237.727 73H253.727C254.28 73 254.727 73.4477 254.727 74V95H236.727V74Z"
            fill="#0D9B83"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M236.727 95H254.727V126H236.727V95Z"
            fill="#096C5B"
          />
        </g>
        <g clipPath="url(#clip10_2594_68037)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M260.364 97C260.364 96.4477 260.811 96 261.364 96H277.364C277.916 96 278.364 96.4477 278.364 97V106H260.364V97Z"
            fill="#89CEC2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M260.364 106H278.364V126H260.364V106Z"
            fill="#56B9A8"
          />
        </g>
        <g clipPath="url(#clip11_2594_68037)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 87C284 86.4477 284.448 86 285 86H301C301.552 86 302 86.4477 302 87V95H284V87Z"
            fill="#89CEC2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 95H302V126H284V95Z"
            fill="#56B9A8"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2594_68037">
          <rect
            width="18"
            height="106"
            fill="white"
            transform="translate(24 20)"
          />
        </clipPath>
        <clipPath id="clip1_2594_68037">
          <rect
            width="18"
            height="106"
            fill="white"
            transform="translate(47.6364 20)"
          />
        </clipPath>
        <clipPath id="clip2_2594_68037">
          <rect
            width="18"
            height="106"
            fill="white"
            transform="translate(71.2727 20)"
          />
        </clipPath>
        <clipPath id="clip3_2594_68037">
          <rect
            width="18"
            height="106"
            fill="white"
            transform="translate(94.9091 20)"
          />
        </clipPath>
        <clipPath id="clip4_2594_68037">
          <rect
            width="18"
            height="106"
            fill="white"
            transform="translate(118.545 20)"
          />
        </clipPath>
        <clipPath id="clip5_2594_68037">
          <rect
            width="18"
            height="106"
            fill="white"
            transform="translate(142.182 20)"
          />
        </clipPath>
        <clipPath id="clip6_2594_68037">
          <rect
            width="18"
            height="106"
            fill="white"
            transform="translate(165.818 20)"
          />
        </clipPath>
        <clipPath id="clip7_2594_68037">
          <rect
            width="18"
            height="106"
            fill="white"
            transform="translate(189.455 20)"
          />
        </clipPath>
        <clipPath id="clip8_2594_68037">
          <rect
            width="18"
            height="106"
            fill="white"
            transform="translate(213.091 20)"
          />
        </clipPath>
        <clipPath id="clip9_2594_68037">
          <rect
            width="18"
            height="106"
            fill="white"
            transform="translate(236.727 20)"
          />
        </clipPath>
        <clipPath id="clip10_2594_68037">
          <rect
            width="18"
            height="106"
            fill="white"
            transform="translate(260.364 20)"
          />
        </clipPath>
        <clipPath id="clip11_2594_68037">
          <rect
            width="18"
            height="106"
            fill="white"
            transform="translate(284 20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
