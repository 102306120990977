import type { ParameterByDepthUserLensDto } from "apis/oag";
import { ResultDataState } from "apis/oag";
import NoData from "components/Lenses/common/NoData";
import type { ContainerLensProps } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { ParameterByDepthChart } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/ParameterByDepthChart";
import { useParameterByDepthFacts } from "components/Lenses/ContainerLens/ParameterByDepthKPI/fetcher";
import { useLegendAutoUpdate } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useLegendAutoUpdate";
import { ParameterByDepthLoading } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/Loading/Loading";
import { Suspense } from "react";
import colors from "utils/colors";

export const ParameterByDepthKPI: React.FC<ContainerLensProps> = ({
  detailed,
  graphKey,
  focalWellColor,
  dimension,
  lens,
  selectedTrackItems,
  setSelectedTrackItems,
  onLensUpdated,
}) => {
  const parameterLens = lens;
  const { data, isLoading } = useParameterByDepthFacts(
    parameterLens as ParameterByDepthUserLensDto,
  );

  useLegendAutoUpdate({ detailed, data });

  if (data?.dataState === ResultDataState.NoData)
    return (
      <ParameterByDepthLoading
        title={"No data found"}
        titleElement={<NoData isStatic detailed={detailed} />}
        detailed={detailed}
        selectedTrackItems={selectedTrackItems}
        lensId={lens?.id}
      />
    );

  if (data?.dataState === ResultDataState.Valid)
    return (
      <Suspense
        fallback={
          <ParameterByDepthLoading
            title={"..."}
            detailed={detailed}
            selectedTrackItems={selectedTrackItems}
            lensId={lens?.id}
          />
        }
      >
        <ParameterByDepthChart
          detailed={detailed}
          graphKey={graphKey}
          isLoading={isLoading}
          focalWellColor={focalWellColor || colors.well_color}
          dimension={dimension}
          lens={lens as ParameterByDepthUserLensDto}
          data={data}
          onLensUpdated={onLensUpdated}
          selectedTrackItems={selectedTrackItems}
          setSelectedTrackItems={setSelectedTrackItems}
        />
      </Suspense>
    );

  return (
    <ParameterByDepthLoading
      title={"..."}
      detailed={detailed}
      selectedTrackItems={selectedTrackItems}
      lensId={lens?.id}
    />
  );
};
