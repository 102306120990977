import styled from "styled-components";
import { Card, Checkbox, Col, Row } from "utils/componentLibrary";
import { PdCardBody, PdCardHead } from "utils/componentLibrary/Card";

export const WellCard = styled(Card)`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  &,
  span {
    color: ${({ theme }) =>
      theme.themeStyle.colors.primary_typography} !important;
  }

  ${PdCardHead} {
    background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
    padding-left: 75px;
    padding-top: 44px;
    padding-bottom: 22px;
    border-bottom: 2px solid
      ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }

  ${PdCardBody} {
    padding-left: 75px;
    padding-top: 0px;
    background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  }
`;

export const Wrapper = styled.div`
  padding: 10px;
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox {
    top: 0;
  }
`;

export const Cell = styled(Col)`
  display: flex;
  align-items: center;
`;
export const TableRow = styled(Row)`
  height: 64px;
  border-bottom: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const TabelCol = styled(Col)`
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
