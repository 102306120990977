import { WellStatusType } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import { DatePicker } from "atoms/DatePicker";
import { Button, Input } from "atoms/Form";
import { ActionsHeader } from "components/Layout/Tabbed";
import { PDComponent } from "components/PDComponents";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { Col, Popover, Row, Space, Tooltip } from "utils/componentLibrary";
import { rangeAll } from "utils/helper";

export const Header = () => {
  const dispatch = useAppDispatch();
  const rigs = useRigs();

  const period = useAppSelector((state) => state.admin.wellAccess.period);
  const rigIdsState = useAppSelector((state) => state.admin.wellAccess.rigs);
  const statusState = useAppSelector(
    (state) => state.admin.wellAccess.wellStatus,
  );
  const searchState = useAppSelector((state) => state.admin.wellAccess.search);

  const [showRigPopup, setShowRigPopup] = useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);

  const [quickSearchValue, setQuickSearchValue] = useState<string>(
    searchState || "",
  );

  const updateStatuses = (f: WellStatusType[]) => {
    setShowStatusPopup(false);
    dispatch({
      type: "ADMIN_WELL_ACCESS_SET_WELL_STATUS",
      payload: {
        wellStatus: f,
      },
    });
    return true;
  };

  const updateSearch = () => {
    dispatch({
      type: "ADMIN_WELL_ACCESS_SET_SEARCH",
      payload: {
        search: quickSearchValue,
      },
    });
    return true;
  };
  const updateRigs = useCallback(
    (rigIds: number[]) => {
      dispatch({
        type: "ADMIN_WELL_ACCESS_SET_RIGS",
        payload: {
          rigs: rigIds,
        },
      });
      return true;
    },
    [dispatch],
  );
  useEffect(() => {
    if (rigs.data) {
      updateRigs(rigs.data.list.map((e) => e.id));
    }
  }, [rigs.data, updateRigs]);
  const updateTimeRange = (period: RangeType) => {
    dispatch({
      type: "ADMIN_WELL_ACCESS_SET_TIME_RANGE",
      payload: {
        period,
      },
    });
    return true;
  };

  return (
    <ActionsHeader>
      <Row
        gutter={12}
        justify="space-between"
        align="middle"
        style={{ height: "64px" }}
      >
        <Col flex="0 auto">
          <Space>
            <Space direction="horizontal" size={4}>
              <Input
                allowClear
                placeholder="Search by Well name"
                style={{ width: "300px" }}
                value={quickSearchValue}
                onChange={(e) => setQuickSearchValue(e.target.value)}
              />
              <Button
                size="large"
                type="primary"
                icon={<PDComponent.SvgIcon name="search" />}
                onClick={updateSearch}
              />
            </Space>

            <PDComponent.VerticalDivider />

            <Tooltip title="Time Range">
              <DatePicker
                allowAllDates
                defaultDate={rangeAll}
                selection={period}
                onApply={updateTimeRange}
              />
            </Tooltip>

            <Tooltip title="Rigs">
              <Popover
                content={
                  <PDComponent.ComboBoxMultiSelect
                    placeholder="Search Rigs"
                    options={(rigs.data?.list ?? []).map((e) => ({
                      id: e.id,
                      name: e.shortName || "",
                    }))}
                    values={rigIdsState ?? []}
                    onChange={(rigIds: number[]) => {
                      updateRigs(rigIds);
                      setShowRigPopup(false);
                      return void 0;
                    }}
                  />
                }
                trigger="click"
                placement="bottom"
                open={showRigPopup}
                onOpenChange={(e) => setShowRigPopup(e)}
                destroyTooltipOnHide
              >
                <Button
                  size="large"
                  icon={<PDComponent.SvgIcon name="rig" />}
                  type={
                    rigIdsState === null ||
                    rigIdsState?.length !== rigs.data?.list.length
                      ? "primary"
                      : "default"
                  }
                  ghost={
                    rigIdsState
                      ? rigIdsState?.length !== rigs.data?.list.length
                      : false
                  }
                  $engaged={showRigPopup}
                />
              </Popover>
            </Tooltip>

            <Tooltip title="Well Status">
              <Popover
                content={
                  <PDComponent.Pickoff
                    options={Object.values(WellStatusType).map((e) => ({
                      id: e,
                      name: e,
                    }))}
                    values={statusState || []}
                    onChange={(e) => updateStatuses(e)}
                  />
                }
                trigger="click"
                placement="bottom"
                open={showStatusPopup}
                onOpenChange={(e) => setShowStatusPopup(e)}
                destroyTooltipOnHide
              >
                <Button
                  icon={<PDComponent.SvgIcon name="checkmarkOutline" />}
                  type={
                    statusState?.length !== Object.keys(WellStatusType).length
                      ? "primary"
                      : "default"
                  }
                  ghost={
                    statusState?.length !== Object.keys(WellStatusType).length
                  }
                  $engaged={showStatusPopup}
                />
              </Popover>
            </Tooltip>
          </Space>
        </Col>
      </Row>
    </ActionsHeader>
  );
};
