import styled from "styled-components";

export const PageGrid = styled.div`
  height: calc(100vh + 450px + 16px);
  width: 100%;
  display: grid;
  grid-template-columns: 289px 1fr;
  gap: 16px;
  padding-bottom: 0;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const InnerGrid = styled.div`
  display: grid;
  grid-gap: 12px;
  position: absolute;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 450px 1fr;
  grid-template-areas:
    "scatter ribbon"
    "scorecard scorecard";

  @media screen and (max-width: 1100px) {
    grid-template-areas:
      "scatter"
      "ribbon"
      "scorecard";
    overflow: scroll;
    grid-template-columns: calc(100vw - 330px);
    grid-template-rows: 1fr 450px calc(100vh - 450px);
  }
`;

export const RibbonContainer = styled.div`
  grid-area: ribbon;
  width: 100%;
`;

export const ScatterPlotContainer = styled.div`
  grid-area: scatter;
  width: 100%;
`;

export const ScorecardContainer = styled.div`
  grid-area: scorecard;
  width: 100%;
`;

export const LeftPane = styled.div`
  display: flex;
  overflow: hidden;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_chart_bg};
  gap: 12px;
  padding: 16px 8px 0 20px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const RightPane = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 16px);
  margin: 16px 16px 0 0;
`;
