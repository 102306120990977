/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const IntelGroupingType = {
    None: 'None',
    Well: 'Well',
    Rig: 'Rig',
    Contractor: 'Contractor',
    Operator: 'Operator'
} as const;
export type IntelGroupingType = typeof IntelGroupingType[keyof typeof IntelGroupingType];


export function instanceOfIntelGroupingType(value: any): boolean {
    for (const key in IntelGroupingType) {
        if (Object.prototype.hasOwnProperty.call(IntelGroupingType, key)) {
            if (IntelGroupingType[key as keyof typeof IntelGroupingType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function IntelGroupingTypeFromJSON(json: any): IntelGroupingType {
    return IntelGroupingTypeFromJSONTyped(json, false);
}

export function IntelGroupingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelGroupingType {
    return json as IntelGroupingType;
}

export function IntelGroupingTypeToJSON(value?: IntelGroupingType | null): any {
    return value as any;
}

