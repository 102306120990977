import { Button } from "atoms/Form";
import TextareaAutosize from "react-textarea-autosize";
import styled, { css } from "styled-components";
import colors from "utils/colors";

export const BoxInner = styled.div`
  display: grid;
  grid-template-areas:
    "avatar message"
    "avatar controls"
    "avatar feedbacks";
  grid-template-columns: 40px 1fr;
  grid-template-rows: minmax(40px, 1fr) 25px auto;
  row-gap: 8px;
  column-gap: 20px;
`;

export const Avatar = styled.div<{ $isResponse?: boolean }>`
  grid-area: avatar;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  background: ${({ $isResponse }) =>
    !$isResponse
      ? css`linear-gradient(180deg, #3fe3bc 42.29%, #5b5b5b 126.25%)`
      : null};
  color: ${({ theme }) => theme.themeStyle.colors.inverted_primary_typography};
  font-weight: 900;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 40px;
  }
`;

export const Message = styled.div<{ $isResponse: boolean; $isError?: boolean }>`
  ${({ $isResponse, $isError, theme }) => css`
    grid-area: message;
    border-radius: 8px;
    white-space: pre-wrap;
    font-size: 16px;
    font-weight: ${$isError ? 300 : 400};
    line-height: ${$isError ? "20px" : undefined};
    padding: 16px;
    word-break: break-word;
    background: ${$isResponse
      ? theme.themeStyle.clara.sidebar_bg
      : "transparent"};

    &,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${theme.themeStyle.colors.primary_typography};
    }

    ${$isError
      ? theme.isDark
        ? css`
            background: linear-gradient(
                0deg,
                rgba(214, 110, 107, 0.2) 0%,
                rgba(214, 110, 107, 0.2) 100%
              ),
              rgba(0, 0, 0, 0.2);
            color: ${colors.white};
            background-blend-mode: lighten, normal;
            border: 1px solid ${colors.coral_expression};
          `
        : css`
            background: linear-gradient(
                0deg,
                rgba(214, 110, 107, 0.1) 0%,
                rgba(214, 110, 107, 0.1) 100%
              ),
              rgba(255, 255, 255, 0.2);
            color: ${colors.limo_scene};
            font-weight: 400;
            border: 1px solid ${colors.coral_expression};
          `
      : ""}

    p,
      ul {
      margin-bottom: 0;
    }
    li p {
      margin-top: -1em;
    }
  `}
`;

export const EditMessage = styled(TextareaAutosize)`
  resize: none;
  outline: none;
  border-radius: 8px;
  background: transparent;
  font-size: 16px;
  padding: 16px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const ErrorTitle = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
`;

export const Regenerate = styled(Button)`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 140px;
  border-radius: 4px;
  background: ${({ theme }) =>
    theme.isDark ? colors.actions_bg : colors.darth_vader} !important;
  color: ${({ theme }) =>
    theme.themeStyle.colors.inverted_primary_typography} !important;
`;

export const Controls = styled.div<{ $stripMargin?: boolean }>`
  grid-area: controls;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
  row-gap: 8px;
  ${({ $stripMargin }) =>
    $stripMargin
      ? null
      : css`
          margin-left: 12px;
        `}
`;

export const FeedbackControls = styled(Controls)`
  flex-direction: column;
  align-items: start;
`;

export const ControlsContainer = styled.div<{ $stripMargin?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
  row-gap: 8px;
`;

export const SimpleButtonWrapper = styled.div<{ $isLoading?: boolean }>`
  display: flex;
  cursor: pointer;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  ${({ $isLoading }) =>
    $isLoading
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `};
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    background: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }
`;

export const UpdateButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;
