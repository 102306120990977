import { PDComponent } from "components/PDComponents";
import { CheckboxCheckedState } from "components/PDComponents/Checkbox/Checkbox";
import { FilterOptionRow } from "pages/IntelDashboard/components/IntelHeader/DetailedFilterPopup/FilterGroup/FilterOptionRow";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import type { DetailedFilterVisibleKeys } from "reducers/intelFiltersReducerUncommitted";
import { useAppSelector } from "reducers/store";

import * as Styled from "./style";

export const FilterGroup = ({
  groupTitle,
  groupStoreKey,
  listOptions,
}: {
  groupTitle: string;
  groupStoreKey: keyof typeof DetailedFilterVisibleKeys;
  listOptions: string[];
}) => {
  const dispatch = useDispatch();

  const selectedOptions = useAppSelector(
    (state) => state.intelFiltersUncommitted[groupStoreKey],
  );

  const selectedOptionSet = useMemo(
    () => new Set(selectedOptions),
    [selectedOptions],
  );

  const areAllOptionsSelected = useMemo(
    () => selectedOptions === null || selectedOptions === undefined,
    [selectedOptions],
  );

  const getOptionCheckedState = useCallback(
    (option: string) => {
      return areAllOptionsSelected || selectedOptionSet?.has(option)
        ? CheckboxCheckedState.Checked
        : CheckboxCheckedState.Unchecked;
    },
    [areAllOptionsSelected, selectedOptionSet],
  );

  const onGroupCheckboxChange = useCallback(
    (isBecomingChecked: boolean) => {
      dispatch({
        type: "INTEL_DASHBOARD_SET_FILTER_KEY",
        payload: {
          key: groupStoreKey,
          value: isBecomingChecked ? null : [],
        },
      });
    },
    [dispatch, groupStoreKey],
  );

  const groupIdForLabel = useMemo(
    () => `filter-group-${groupTitle}`,
    [groupTitle],
  );

  const handleOnOptionChecked = useCallback(
    (option: string, becomingChecked: boolean) => {
      let newSelection;
      if (becomingChecked) {
        newSelection =
          (selectedOptions?.length ?? 0) + 1 === listOptions.length
            ? null
            : [...(selectedOptions ?? []), option];
      } else {
        // TODO potential later improvement point: use a Set instead of an array
        newSelection =
          selectedOptions?.filter(
            (selectedOption) => selectedOption !== option,
          ) ?? listOptions.filter((listOption) => listOption !== option);
      }

      dispatch({
        type: "INTEL_DASHBOARD_SET_FILTER_KEY",
        payload: {
          key: groupStoreKey,
          value: newSelection,
        },
      });
    },
    [dispatch, groupStoreKey, listOptions, selectedOptions],
  );

  const sortedListOptions = useMemo(
    () =>
      listOptions.toSorted(
        (a, b) =>
          (selectedOptionSet?.has(b) ? 1 : 0) -
          (selectedOptionSet?.has(a) ? 1 : 0),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listOptions],
  );

  const listWithSearchOptions = useMemo(() => {
    return sortedListOptions.map((option) => ({
      id: option,
      name: option,
      component: (
        <FilterOptionRow
          optionName={option}
          idForLabel={`${groupTitle}-option-${option}`}
          onOptionChecked={(isChecked) =>
            handleOnOptionChecked(option, isChecked)
          }
          checkedState={getOptionCheckedState(option)}
        />
      ),
    }));
  }, [
    getOptionCheckedState,
    groupTitle,
    handleOnOptionChecked,
    sortedListOptions,
  ]);

  const groupCheckboxCheckedState = useMemo(() => {
    if (areAllOptionsSelected) return CheckboxCheckedState.Checked;
    else if (selectedOptions?.length === 0)
      return CheckboxCheckedState.Unchecked;
    else return CheckboxCheckedState.Indeterminate;
  }, [areAllOptionsSelected, selectedOptions?.length]);

  return (
    <Styled.Container>
      <Styled.CheckAllContainer>
        <PDComponent.Checkbox
          size={14}
          onChange={onGroupCheckboxChange}
          checkedState={groupCheckboxCheckedState}
          id={groupIdForLabel}
        />

        <Styled.GroupTitleLabel htmlFor={groupIdForLabel}>
          {groupTitle}
        </Styled.GroupTitleLabel>
      </Styled.CheckAllContainer>

      <PDComponent.ListWithSearch
        onClick={() => null}
        options={listWithSearchOptions}
        placeholder="Search"
        values={[]}
        height={148}
        itemHeight={37}
        innerListStyle={{
          border: "none",
          padding: 8,
        }}
      />
    </Styled.Container>
  );
};
