import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { UserDto } from "apis/oag";
import { UsersApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUsersWellApproversQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const users = new UsersApi(apiConfig);

export function useApprovers(
  wellId: number,
  options?: UseQueryOptions<UserDto[]>,
) {
  const queryKey: PDUsersWellApproversQueryKey = {
    type: PDQueryType.USERS_WELL_APPROVERS,
    uid: RequestUID.approvers,
    wellId: wellId,
  };
  const useQueryParams: UseQueryOptions<UserDto[]> = {
    queryKey: [queryKey],
    queryFn: () => users.apiUsersMeApproversWellIdGet({ wellId }),
    staleTime: Infinity,
    ...options,
  };

  return useQuery(useQueryParams);
}
