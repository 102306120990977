import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import type { RigScorecardLensType, StandKpiType } from "apis/oag";
import { ScorecardKpiCommentsApi, TimeUnit } from "apis/oag";
import type { ScorecardKpiCommentInfoDto } from "apis/oag/models/ScorecardKpiCommentInfoDto";
import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import { FormItem } from "components/RigScoreCardCommentsModal/FormItem";
import * as Styled from "components/RigScoreCardCommentsModal/styled";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { useApprovers } from "hooks/rigs/scorecard/useApprovers";
import { RigScorecardInvalidationKey } from "hooks/rigs/scorecard/useRigScorecardCardFacts";
import { CommentsInvalidationKey } from "hooks/rigs/scorecard/useScoreCardComments";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import type { ChangeEvent } from "react";
import { useCallback, useMemo, useState } from "react";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { Col, Input, Row, Tooltip } from "utils/componentLibrary";
import { SECONDS_IN_HOUR, useTimeUom } from "utils/format";

const scorecardCommentApi = new ScorecardKpiCommentsApi(apiConfig);

const exceptionText = "An exception has already been set for this KPI.";

interface CardCommentEditProps {
  kpiType: StandKpiType;
  scorecardType: RigScorecardLensType;
  wellId: number;
  lensId: number;
  editedComment?: ScorecardKpiCommentInfoDto | null;
  maximumExceptionTime?: number;
  canUseExceptions?: boolean;
  canAddExceptions?: boolean;
  onEditModeClose: (shouldRefresh: boolean) => void;
  paddingTop?: number;
}

export const CardCommentEdit: React.FC<CardCommentEditProps> = ({
  editedComment,
  wellId,
  scorecardType,
  kpiType,
  onEditModeClose,
  canUseExceptions,
  paddingTop = 32,
  canAddExceptions = false,
  maximumExceptionTime,
}) => {
  const [currentEditedText, setCurrentEditedText] = useState(
    editedComment?.description || "",
  );
  const queryClient = useQueryClient();
  const { data: approvers } = useApprovers(wellId);
  const [isLoading, setIsLoading] = useState(false);
  const [isExceptionEnabled, setIsExceptionEnabled] = useState(
    canUseExceptions && !!editedComment?.exceptionApproverId,
  );
  const [exceptionTime, setExceptionTime] = useState<number | null>(
    editedComment?.exceptionTime || 0,
  );
  const { data: wellShortInfo } = useWellShortInfoSuspended();
  const hoursUOM = useTimeUom(TimeUnit.Hour);
  const { data: kpiTypes } = useKpiTypes();
  const sortedApprovers = useMemo(() => {
    return (approvers ?? []).sort((a, b) => {
      if ((a.firstName || "") > (b.firstName || "")) return 1;
      if ((a.firstName || "") < (b.firstName || "")) return -1;
      return 0;
    });
  }, [approvers]);

  const [approver, setApprover] = useState(
    editedComment?.exceptionApproverId
      ? (approvers ?? []).find(
          (approver) => approver?.id === editedComment?.exceptionApproverId,
        )
      : undefined,
  );

  const exceptionError = useMemo(() => {
    if (
      (exceptionTime && exceptionTime > (maximumExceptionTime || 0)) ||
      exceptionTime === null ||
      exceptionTime <= 0
    ) {
      return "Exception Hours must be between 0 and the actual KPI value";
    }
  }, [exceptionTime, maximumExceptionTime]);

  const handleCommentPost = useMutation({
    mutationFn: (text: string) => {
      if (!text || text.length === 0) {
        return new Promise((resolve) => resolve(null));
      }

      Track.interact("Rig Scorecard - Add Note", {
        "Well Id": wellId,
        "Well Name": wellShortInfo?.byId[wellId]?.name || "",
        "Kpi Type": kpiTypes?.byId?.[kpiType]?.shortName || "",

        ...(isExceptionEnabled
          ? {
              Approver: `${approver?.firstName} ${approver?.lastName}`,
              "Exception Time": hoursUOM.display(exceptionTime, {
                fractionDigits: 2,
              }),
            }
          : {}),
      });
      return scorecardCommentApi.apiScorecardKpiCommentsPost({
        scorecardKpiCommentDto: {
          wellId,
          kpiType,
          scorecardType,
          description: text,
          id: 0,
          exceptionApproverId: isExceptionEnabled ? approver?.id : undefined,
          exceptionTime: isExceptionEnabled ? exceptionTime : undefined,
        },
      });
    },
  });

  const handleCommentEdit = useMutation({
    mutationFn: (text: string) => {
      if (
        !text ||
        text.length === 0 ||
        !Number.isFinite(editedComment?.id) ||
        !editedComment
      ) {
        return new Promise((resolve) => resolve(null));
      }

      Track.interact("Rig Scorecard - Edit Note", {
        "Well Id": wellId,
        "Well Name": wellShortInfo?.byId[wellId]?.name || "",
        "Kpi Type": kpiTypes?.byId?.[kpiType]?.shortName || "",
        "Comment Id": editedComment?.id,
        ...(isExceptionEnabled
          ? {
              Approver: `${approver?.firstName} ${approver?.lastName}`,
              "Exception Time": hoursUOM.display(exceptionTime, {
                fractionDigits: 2,
              }),
            }
          : {}),
      });

      return scorecardCommentApi.apiScorecardKpiCommentsIdPut({
        id: editedComment.id,

        scorecardKpiCommentDto: {
          wellId: wellId,
          description: text,
          id: editedComment.id,
          kpiType: editedComment.kpiType,
          scorecardType: editedComment.scorecardType,
          exceptionApproverId: isExceptionEnabled ? approver?.id : undefined,
          exceptionTime: isExceptionEnabled ? exceptionTime : undefined,
        },
      });
    },
  });

  const handleOnSendButtonClick = useCallback(async () => {
    setIsLoading(true);
    if (editedComment) {
      Track.interact("Rig Scorecard - Save Edited Comment");
      await handleCommentEdit.mutateAsync(currentEditedText);
    } else {
      Track.interact("Rig Scorecard - Add New Comment");
      await handleCommentPost.mutateAsync(currentEditedText);
    }
    queryClient.invalidateQueries({ queryKey: [RigScorecardInvalidationKey] });
    queryClient.invalidateQueries({ queryKey: [CommentsInvalidationKey] });
    setIsLoading(false);
    onEditModeClose(true);
  }, [
    currentEditedText,
    editedComment,
    handleCommentEdit,
    handleCommentPost,
    onEditModeClose,
    queryClient,
  ]);

  const handleOnApproverChange = useCallback(
    (optionValue: number) => {
      setApprover(
        (approvers ?? []).find((approver) => approver?.id === optionValue),
      );
    },
    [approvers],
  );

  const handleOnExceptionHoursChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) {
        setExceptionTime(
          Number.isFinite(+e.target.value)
            ? +e.target.value * SECONDS_IN_HOUR
            : null,
        );
      } else setExceptionTime(null);
    },
    [],
  );

  const handleOnExceptionCheckboxChange = useCallback(
    (e: CheckboxChangeEvent) => {
      setIsExceptionEnabled(e.target.checked);
    },
    [],
  );

  const isSubmitDisabled = useMemo(() => {
    return (
      !currentEditedText.length ||
      !!(isExceptionEnabled && exceptionError) ||
      (isExceptionEnabled && !approver?.id)
    );
  }, [
    approver?.id,
    currentEditedText.length,
    exceptionError,
    isExceptionEnabled,
  ]);

  const canEditLeftPane = useMemo(() => {
    return canAddExceptions || editedComment?.exceptionStatus;
  }, [canAddExceptions, editedComment]);

  return (
    <Styled.CommentContainer $paddingTop={paddingTop}>
      <Styled.CommentIconContainer>
        <Styled.CommentIcon>
          <PDComponent.SvgIcon name="chat" />
        </Styled.CommentIcon>
      </Styled.CommentIconContainer>

      <Styled.NewComment>
        <Input.TextArea
          maxLength={300}
          onChange={(e) => setCurrentEditedText(e.target.value)}
          autoFocus
          defaultValue={currentEditedText}
          onFocus={(e) =>
            e.target.setSelectionRange(
              e.target.value.length,
              e.target.value.length,
            )
          }
        />
        <Styled.CommentActions>
          {canUseExceptions ? (
            <Styled.CommentActionsLeft $isDisabled={!canEditLeftPane}>
              {!canEditLeftPane ? (
                <Tooltip
                  overlayStyle={{ maxWidth: "500px" }}
                  title={
                    <Styled.TooltipText>{exceptionText}</Styled.TooltipText>
                  }
                  placement="topLeft"
                >
                  <Row
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto 1fr",
                    }}
                  >
                    <Col>
                      <Styled.ExceptionCheckbox
                        checked={isExceptionEnabled}
                        id="exceptionCheckbox"
                        name="exceptionCheckbox"
                        onChange={handleOnExceptionCheckboxChange}
                        disabled
                      />
                    </Col>
                    <Col>
                      <Styled.ExceptionContainer>
                        <Styled.ExceptionLabel htmlFor="exceptionCheckbox">
                          This is an exception.
                        </Styled.ExceptionLabel>
                        <FormItem
                          {...{
                            isExceptionEnabled: !!isExceptionEnabled,
                            exceptionTime: exceptionTime || 0,
                            handleOnExceptionHoursChange,
                            exceptionError: exceptionError || "",
                            approver,
                            handleOnApproverChange,
                            approvers: sortedApprovers,
                          }}
                        />
                      </Styled.ExceptionContainer>
                    </Col>
                  </Row>
                </Tooltip>
              ) : (
                <Row
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                  }}
                >
                  <Col>
                    <Styled.ExceptionCheckbox
                      checked={isExceptionEnabled}
                      id="exceptionCheckbox"
                      name="exceptionCheckbox"
                      onChange={handleOnExceptionCheckboxChange}
                    />
                  </Col>
                  <Col>
                    <Styled.ExceptionContainer>
                      <Styled.ExceptionLabel htmlFor="exceptionCheckbox">
                        This is an exception.
                      </Styled.ExceptionLabel>
                      <FormItem
                        {...{
                          isExceptionEnabled: !!isExceptionEnabled,
                          exceptionTime: exceptionTime || 0,
                          handleOnExceptionHoursChange,
                          exceptionError: exceptionError || "",
                          approver,
                          handleOnApproverChange,
                          approvers: sortedApprovers,
                        }}
                      />
                    </Styled.ExceptionContainer>
                  </Col>
                </Row>
              )}
            </Styled.CommentActionsLeft>
          ) : null}

          <Styled.CommentActionsRight>
            <Button
              icon={<PDComponent.SvgIcon name="closeOutline" />}
              onClick={() => onEditModeClose(false)}
            ></Button>
            <Button
              loading={isLoading}
              disabled={isSubmitDisabled}
              onClick={handleOnSendButtonClick}
              icon={
                <PDComponent.SvgIcon
                  name="sendFilled"
                  style={{
                    fontSize: 18,
                    color: isSubmitDisabled
                      ? colors.widget_line
                      : colors.well_color,
                  }}
                />
              }
            ></Button>
          </Styled.CommentActionsRight>
        </Styled.CommentActions>
      </Styled.NewComment>
    </Styled.CommentContainer>
  );
};
