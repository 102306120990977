import type { NotificationType } from "apis/oag";
import { EllipsisTextCSS } from "commonStyles";
import { IconContainer } from "icons/styles";
import styled from "styled-components";
import colors from "utils/colors";

export const NotificationRow = styled.div`
  height: 100%;
  border-bottom: 1px solid
    ${({ theme }) => (theme.isDark ? colors.limo_scene : colors.lunar_rays)};
  background-color: ${({ theme }) =>
    theme.themeStyle.colors.alt_quaterniary_bg};

  :hover {
    background-color: ${({ theme }) => theme.themeStyle.notifications.hover_bg};
  }
`;

export const NotificationInfoRow = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  gap: 8px;
  display: grid;
  grid-template-columns:
    minmax(100px, 0.5fr) minmax(252px, 1.5fr) minmax(252px, 2fr)
    minmax(252px, 2fr) minmax(90px, 0.5fr);
  height: 100%;
`;

export const RigTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotificationTitle = styled.div<{
  $notificationType: NotificationType;
}>`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.coral_expression};
`;

export const WellContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

export const WellName = styled.span`
  & h4 {
    ${EllipsisTextCSS};
  }
`;

export const Job = styled.span`
  display: flex;
  gap: 8px;
  & h4 {
    ${EllipsisTextCSS};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  place-content: center;
`;

export const SeparatorLine = styled.div`
  border-bottom: 1px solid
    ${({ theme }) => theme.themeStyle.colors.separation_line};
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
`;

export const Description = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  overflow-x: auto;
  overflow-y: hidden;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-height: 2lh;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-width: 450px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-left: 2px solid
    ${({ theme }) =>
      theme.isDark ? colors.necron_compound : colors.lunar_rays};
`;

export const Time = styled.div`
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  display: flex;
  font-size: 14px;
  place-items: center;
`;

export const CloseIcon = styled.span<{ $large?: boolean }>`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  cursor: pointer;
  ${IconContainer} {
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
    font-size: ${({ $large }) => ($large ? "30px" : "20px")};
    color: ${({ theme }) =>
      theme.isDark ? colors.white : colors.necron_compound};
  }
`;

export const KpiSummaryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
