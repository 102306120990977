import type { WellIntelQueryDto } from "apis/oag";
import { UNFILTERED_SELECTIONS } from "reducers/intelFiltersReducerUncommitted";

import type { IAction } from "./types";

export type IFiltersReducerState = WellIntelQueryDto;

const initialState: IFiltersReducerState = UNFILTERED_SELECTIONS;

interface ISetCosmeticOptions extends IAction {
  type: "INTEL_DASHBOARD_SET_FILTER_COSMETIC_OPTIONS";
  payload: {
    key: keyof IFiltersReducerState;
    value: IFiltersReducerState[keyof IFiltersReducerState];
  };
}

interface IResetFiltersCosmeticOptions extends IAction {
  type: "INTEL_DASHBOARD_RESET_FILTERS_COSMETIC_OPTIONS";
}

type AvailableActions = ISetCosmeticOptions | IResetFiltersCosmeticOptions;

function intelFiltersReducerCosmeticOptions(
  state: IFiltersReducerState = initialState,
  action: AvailableActions,
): IFiltersReducerState {
  switch (action.type) {
    case "INTEL_DASHBOARD_SET_FILTER_COSMETIC_OPTIONS":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case "INTEL_DASHBOARD_RESET_FILTERS_COSMETIC_OPTIONS":
      return {
        ...UNFILTERED_SELECTIONS,
      };
    default:
      return state;
  }
}

export default intelFiltersReducerCosmeticOptions;
