/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface AllWellsDataFeedQueryDto
 */
export interface AllWellsDataFeedQueryDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof AllWellsDataFeedQueryDto
     */
    formationIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllWellsDataFeedQueryDto
     */
    operatorIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllWellsDataFeedQueryDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof AllWellsDataFeedQueryDto
     */
    search?: string | null;
    /**
     * 
     * @type {DateDto}
     * @memberof AllWellsDataFeedQueryDto
     */
    from?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof AllWellsDataFeedQueryDto
     */
    to?: DateDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllWellsDataFeedQueryDto
     */
    includeEverGreenOnly: boolean;
    /**
     * 
     * @type {FiltersDto}
     * @memberof AllWellsDataFeedQueryDto
     */
    selectedFilters?: FiltersDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof AllWellsDataFeedQueryDto
     */
    lastWellRefreshDate?: DateDto | null;
}

/**
 * Check if a given object implements the AllWellsDataFeedQueryDto interface.
 */
export function instanceOfAllWellsDataFeedQueryDto(value: object): value is AllWellsDataFeedQueryDto {
    if (!('includeEverGreenOnly' in value) || value['includeEverGreenOnly'] === undefined) return false;
    return true;
}

export function AllWellsDataFeedQueryDtoFromJSON(json: any): AllWellsDataFeedQueryDto {
    return AllWellsDataFeedQueryDtoFromJSONTyped(json, false);
}

export function AllWellsDataFeedQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllWellsDataFeedQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'formationIds': json['FormationIds'] == null ? undefined : json['FormationIds'],
        'operatorIds': json['OperatorIds'] == null ? undefined : json['OperatorIds'],
        'rigIds': json['RigIds'] == null ? undefined : json['RigIds'],
        'search': json['Search'] == null ? undefined : json['Search'],
        'from': json['From'] == null ? undefined : DateDtoFromJSON(json['From']),
        'to': json['To'] == null ? undefined : DateDtoFromJSON(json['To']),
        'includeEverGreenOnly': json['IncludeEverGreenOnly'],
        'selectedFilters': json['SelectedFilters'] == null ? undefined : FiltersDtoFromJSON(json['SelectedFilters']),
        'lastWellRefreshDate': json['LastWellRefreshDate'] == null ? undefined : DateDtoFromJSON(json['LastWellRefreshDate']),
    };
}

export function AllWellsDataFeedQueryDtoToJSON(value?: AllWellsDataFeedQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'FormationIds': value['formationIds'],
        'OperatorIds': value['operatorIds'],
        'RigIds': value['rigIds'],
        'Search': value['search'],
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
        'IncludeEverGreenOnly': value['includeEverGreenOnly'],
        'SelectedFilters': FiltersDtoToJSON(value['selectedFilters']),
        'LastWellRefreshDate': DateDtoToJSON(value['lastWellRefreshDate']),
    };
}

