import { GridLayout, StyledCard } from "atoms/common";
import { Loader } from "components/Loader";
import Header from "components/RigsHeader";
import { useRigsCommonDataFeed } from "hooks/dataFeed/useRigsCommonDataFeed";
import FleetPerformanceSection from "pages/FleetPerformance/components/FleetPerformance";
import { FleetPerformanceMetricSelectionContextProvider } from "pages/FleetPerformance/components/FleetPerformanceMetricSelectionContext";
import { RigList } from "pages/FleetPerformance/RigList/RigList";
import { SelectedRigsContextProvider } from "pages/FleetPerformance/RigList/SelectedRigsContext";
import {
  getAllRigsOptions,
  RigOptionsContext,
} from "pages/FleetPerformance/utils";
import { Suspense, useCallback, useLayoutEffect, useState } from "react";
import type { IFilterOptions } from "reducers/rigsCommonReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { usePageView } from "services/Mixpanel";
import { zIndexLayer } from "utils/zIndex";

export const FleetPerformance = () => {
  usePageView("Fleet Performance");
  useRigsCommonDataFeed(false);
  const [totalRigs, setTotalRigs] = useState(0);
  const dispatch = useAppDispatch();

  const setFilters = useCallback(
    (filters: IFilterOptions | null) => {
      dispatch({ type: "RIGS_COMMON_SET_FILTERS", payload: filters });
    },
    [dispatch],
  );

  const rigsCommonFilters = useAppSelector((state) => state.rigsCommon.filters);

  useLayoutEffect(() => {
    dispatch({
      type: "RIGS_COMMON_FULL_WELLS_INCLUDED",
      payload: true,
    });
    dispatch({ type: "RESET_RIG_DASHBOARD" });
    dispatch({ type: "RESET_RIGS_COMMON_FILTERS_90_DAYS" });
  }, [dispatch]);

  const [rigOptions, setRigOptions] = useState(getAllRigsOptions());

  return (
    <SelectedRigsContextProvider>
      <Header
        filters={rigsCommonFilters}
        setFilters={setFilters}
        totalRigs={totalRigs}
      />
      <GridLayout>
        <StyledCard>
          <RigList setTotalRigs={setTotalRigs} />
        </StyledCard>
        <StyledCard>
          <Suspense fallback={<Loader centered zIndex={zIndexLayer.above} />}>
            <FleetPerformanceMetricSelectionContextProvider>
              <RigOptionsContext.Provider value={[rigOptions, setRigOptions]}>
                <FleetPerformanceSection />
              </RigOptionsContext.Provider>
            </FleetPerformanceMetricSelectionContextProvider>
          </Suspense>
        </StyledCard>
      </GridLayout>
    </SelectedRigsContextProvider>
  );
};
