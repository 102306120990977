/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigStateType } from './RigStateType';
import {
    RigStateTypeFromJSON,
    RigStateTypeFromJSONTyped,
    RigStateTypeToJSON,
} from './RigStateType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface ParameterByDepthTrackValuesDto
 */
export interface ParameterByDepthTrackValuesDto {
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackValuesDto
     */
    holeDepth: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackValuesDto
     */
    value?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackValuesDto
     */
    duration: number;
    /**
     * 
     * @type {DateDto}
     * @memberof ParameterByDepthTrackValuesDto
     */
    timestamp: DateDto;
    /**
     * 
     * @type {RigStateType}
     * @memberof ParameterByDepthTrackValuesDto
     */
    rigStateId: RigStateType;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterByDepthTrackValuesDto
     */
    isAggregation: boolean;
}



/**
 * Check if a given object implements the ParameterByDepthTrackValuesDto interface.
 */
export function instanceOfParameterByDepthTrackValuesDto(value: object): value is ParameterByDepthTrackValuesDto {
    if (!('holeDepth' in value) || value['holeDepth'] === undefined) return false;
    if (!('duration' in value) || value['duration'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    if (!('rigStateId' in value) || value['rigStateId'] === undefined) return false;
    if (!('isAggregation' in value) || value['isAggregation'] === undefined) return false;
    return true;
}

export function ParameterByDepthTrackValuesDtoFromJSON(json: any): ParameterByDepthTrackValuesDto {
    return ParameterByDepthTrackValuesDtoFromJSONTyped(json, false);
}

export function ParameterByDepthTrackValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterByDepthTrackValuesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'holeDepth': json['HoleDepth'],
        'value': json['Value'] == null ? undefined : json['Value'],
        'duration': json['Duration'],
        'timestamp': DateDtoFromJSON(json['Timestamp']),
        'rigStateId': RigStateTypeFromJSON(json['RigStateId']),
        'isAggregation': json['IsAggregation'],
    };
}

export function ParameterByDepthTrackValuesDtoToJSON(value?: ParameterByDepthTrackValuesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'HoleDepth': value['holeDepth'],
        'Value': value['value'],
        'Duration': value['duration'],
        'Timestamp': DateDtoToJSON(value['timestamp']),
        'RigStateId': RigStateTypeToJSON(value['rigStateId']),
        'IsAggregation': value['isAggregation'],
    };
}

