import { Title } from "atoms/Typography";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { useMemo } from "react";
import styled from "styled-components";

const StyledRigTitle = styled(Title).attrs({
  level: 3,
})`
  display: inline;
  ::before {
    content: "·";
    padding: 0px 5px;
  }
`;

function RigAtom({ uniqueRigIds = [] }: { uniqueRigIds: number[] }) {
  const { data: rigs } = useRigs();

  const uniqueRigIdsLabel = useMemo(() => {
    return uniqueRigIds.length > 0
      ? uniqueRigIds.map((e) => rigs?.byId[e]?.shortName || "").join(", ")
      : "";
  }, [rigs?.byId, uniqueRigIds]);

  return uniqueRigIds.length >= 1 ? (
    <StyledRigTitle variant="faded">{uniqueRigIdsLabel}</StyledRigTitle>
  ) : null;
}
export default RigAtom;
