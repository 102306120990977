import logoAnimationData from "components/Clara/Loaders/glowing-clara-logo.json";
import bgAnimationData from "components/Clara/Loaders/landing-page-loader.json";
import {
  BgContainer,
  LandingPageContainer,
  LogoContainer,
  PrimaryTitle,
  SecondaryTitle,
  TextBox,
  TopLogo,
} from "components/Clara/Loaders/style";
import { useLayoutEffect, useRef, useState } from "react";
import Lottie from "react-lottie";

export const LandingPageLoader = () => {
  const mainContainer = useRef<HTMLDivElement>(null);
  const [isWide, setIsWide] = useState(
    (mainContainer?.current?.offsetWidth ?? 0) >
      (mainContainer?.current?.offsetHeight ?? 0),
  );

  useLayoutEffect(() => {
    setIsWide(
      (mainContainer?.current?.offsetWidth ?? 0) >
        (mainContainer?.current?.offsetHeight ?? 0),
    );
    const handleResize = () => {
      setIsWide(
        (mainContainer?.current?.offsetWidth ?? 0) >
          (mainContainer?.current?.offsetHeight ?? 0),
      );
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mainContainer]);

  return (
    <LandingPageContainer ref={mainContainer}>
      <BgContainer $isWide={isWide}>
        <Lottie
          isPaused={false}
          isStopped={false}
          options={{
            loop: true,
            autoplay: true,
            animationData: bgAnimationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          style={{ margin: "0" }}
        />
        <LogoContainer>
          <Lottie
            isPaused={false}
            isStopped={false}
            options={{
              loop: true,
              autoplay: true,
              animationData: logoAnimationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            style={{ margin: "0" }}
          />
        </LogoContainer>
        <TextBox>
          <TopLogo>Clara</TopLogo>
          <PrimaryTitle>
            Beaming up the latest rig data to Clara...{" "}
          </PrimaryTitle>
          <SecondaryTitle>
            Please hold on while she gets ready to assist you!
          </SecondaryTitle>
        </TextBox>
      </BgContainer>
    </LandingPageContainer>
  );
};
