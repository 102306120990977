import { TooltipRow } from "components/MiniLens/style";
import { PDComponent } from "components/PDComponents";
import type { PropsWithChildren } from "react";
import { Tooltip } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

export const OptionUnavailableTooltip = (
  props: PropsWithChildren & { isPassThrough: boolean },
) => {
  return props.isPassThrough ? (
    props.children
  ) : (
    <Tooltip
      autoAdjustOverflow
      placement="topLeft"
      zIndex={zIndexLayer.mars}
      trigger={["hover"]}
      title={
        <TooltipRow>
          <PDComponent.SvgIcon name="forbiddenNeutral" />
          Not available on locked tabs
        </TooltipRow>
      }
    >
      <div>{props.children}</div>
    </Tooltip>
  );
};
