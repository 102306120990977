import type {
  KpiPivotFactSetDto,
  StackedStandKpiComparisonDto,
  WedgeComparisonDto,
} from "apis/oag";
import {
  DimensionType,
  DisplayOption,
  ResultDataState,
  StackingType,
} from "apis/oag";
import {
  PrimaryKpiSummary,
  SecondaryKpiSummary,
} from "components/Lenses/common/KpiSummaries";
import { LENS_KPI_HEIGHT_INNER } from "components/Lenses/constants";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import { StyledLensContainerFlex } from "components/Lenses/ContainerLens/common/StyledComponents";
import { StyledChartContainerFlexDiv } from "components/Lenses/ContainerLens/common/StyledComponents";
import { useDetailedLegendActiveState } from "components/Lenses/ContainerLens/hooks/useDetailedLegendActiveState";
import { StackedComparisonCategoryChart } from "components/Lenses/ContainerLens/StackedKpi/Chart/StackedComparisonCategoryChart";
import { StackedComparisonWellChart } from "components/Lenses/ContainerLens/StackedKpi/Chart/StackedComparisonWellChart";
import { useStackedKpiComparisonFacts } from "components/Lenses/ContainerLens/StackedKpi/fetcher";
import type { StackedKpiProps } from "components/Lenses/interfaces";
import { InfoContainer } from "components/MiniLens/InfoContainer";
import { getLensTitle } from "components/MiniLens/MiniLens";
import { SecondaryKpiContainer, StyledKpiRow } from "components/MiniLens/style";
import { useDirectionalIntervals } from "hooks/drillingInvariants/useDirectionalIntervals";
import { useHoleSections } from "hooks/drillingInvariants/useHoleSections";
import { useKpiOptions } from "hooks/drillingInvariants/useKpiOptions";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { useLensTemplates } from "hooks/lens/useLensTemplates";
import { usePreviousNonNullValue } from "hooks/react-utils/usePreviousNonNullValue";
import {
  getComparisonDisplayValue,
  RotationLensName,
} from "pages/Lens/LensSummaryView";
import { getSecondaryKPIValues } from "pages/Lens/LensSummaryView";
import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch } from "reducers/store";
import { useAppSelector } from "reducers/store";
import { useUOMbyLens } from "utils/format";
import { transformKey } from "utils/helper";

export const StackedKpiComparison = (props: StackedKpiProps) => {
  const { lens, detailed } = props;
  const displayOption = useAppSelector(
    (state) => state.widgetOptions.display_options,
  );
  const { data, isLoading } = useStackedKpiComparisonFacts({ lensId: lens.id });
  const { data: templates } = useLensTemplates();
  const template = templates?.byId[lens.lensTemplateId];
  const uom = useUOMbyLens(props.dimension, lens);
  const { data: kpiTypes } = useKpiTypes();
  const dispatch = useAppDispatch();
  const { data: holeSections } = useHoleSections({ refetchOnMount: false });
  const { data: directionalIntervals } = useDirectionalIntervals({
    refetchOnMount: false,
  });
  const previousData = usePreviousNonNullValue(data);

  useEffect(() => {
    // Stand KPI Fact is not sampled. So, BE compute available filters from the fact and send it in the same response.
    if (detailed && data?.availableFilters)
      dispatch({
        type: "SET_AVAILABLE_FILTERS",
        payload: data.availableFilters,
      });
  }, [data?.availableFilters, detailed, dispatch]);

  const dataSource = useMemo(
    () => (detailed && previousData && isLoading ? previousData : data),
    [data, detailed, isLoading, previousData],
  );

  const getTemplateName = useCallback(() => {
    if (!dataSource?.summaryByKpi) return "";
    return transformKey(
      kpiTypes?.byId[
        dataSource?.summaryByKpi
          .filter((e) => !e.isAggregate)
          .sort((a, b) => a.position - b.position)[0].id
      ]?.name || "",
    );
  }, [dataSource?.summaryByKpi, kpiTypes?.byId]);

  const mainKPIValues = useMemo(() => {
    const getSummary = () => {
      if (!dataSource?.summaryByKpi) return undefined;
      if (template.name === RotationLensName) {
        const summary = dataSource?.summaryByKpi
          .filter((e) => !e.isAggregate)
          .sort((a, b) => a.position - b.position)[0];
        return {
          ...summary,
          id: transformKey(kpiTypes?.byId[summary.id]?.name || ""),
        };
      }
      if (displayOption === DisplayOption.Well)
        return (dataSource?.summaryByDisplayOption || [])
          .filter((e) => !e.isAggregate)
          .sort((a, b) => a.position - b.position)[0];
      return dataSource?.summaryByKpi
        .filter((e) => !e.isAggregate)
        .sort((a, b) => a.position - b.position)[0];
    };

    if (lens.stackingType === StackingType.Distribution) {
      return {
        ...getComparisonDisplayValue(getSummary(), lens, uom),
        templateName: getTemplateName(),
      };
    }
    return getComparisonDisplayValue(
      (dataSource?.summaryByKpi ?? []).find((e) => e.isAggregate),
      lens,
      uom,
    );
  }, [
    dataSource?.summaryByDisplayOption,
    dataSource?.summaryByKpi,
    displayOption,
    getTemplateName,
    kpiTypes?.byId,
    lens,
    template.name,
    uom,
  ]);

  const { kpiType, kpiGroup } = useKpiOptions(lens);

  const primaryKpiTitle = useMemo(() => {
    const lensTitle = getLensTitle({ lens, template, kpiType, kpiGroup });
    if (template.name === RotationLensName) {
      return mainKPIValues?.name;
    }
    if (lensTitle) {
      return lensTitle;
    }
    if (getTemplateName()) {
      return getTemplateName();
    }
  }, [lens, template, kpiType, kpiGroup, getTemplateName, mainKPIValues?.name]);

  const secondaryKPIValues = useMemo(() => {
    const dataSource =
      detailed && previousData && isLoading ? previousData : data;
    return getSecondaryKPIValues(
      dataSource as StackedStandKpiComparisonDto &
        KpiPivotFactSetDto &
        WedgeComparisonDto,
      true,
      displayOption,
      template,
      lens,
      holeSections,
      directionalIntervals || [],
      uom,
      kpiTypes,
    );
  }, [
    data,
    detailed,
    directionalIntervals,
    displayOption,
    holeSections,
    isLoading,
    kpiTypes,
    lens,
    previousData,
    template,
    uom,
  ]);

  useDetailedLegendActiveState({
    comparisons: data?.comparisons || [],
    detailed,
    dataState: data?.dataState || ResultDataState.NoData,
  });

  const SummaryComponent = useMemo(
    () => (
      <div style={{ height: detailed ? 300 : undefined }}>
        <InfoContainer
          size={6}
          direction="vertical"
          style={{
            height: "auto",
          }}
        >
          <StyledKpiRow gutter={34} height={LENS_KPI_HEIGHT_INNER} wrap={true}>
            {mainKPIValues ? (
              <PrimaryKpiSummary
                secondary={mainKPIValues.secondary}
                main={mainKPIValues.main}
                description={""}
                title={primaryKpiTitle}
                detailed={detailed}
              />
            ) : null}
            {secondaryKPIValues ? (
              <SecondaryKpiContainer>
                <SecondaryKpiSummary
                  detailed={detailed}
                  kpis={secondaryKPIValues?.slice(0)}
                />
              </SecondaryKpiContainer>
            ) : null}
          </StyledKpiRow>
        </InfoContainer>
      </div>
    ),
    [detailed, mainKPIValues, primaryKpiTitle, secondaryKPIValues],
  );

  const LoadedComponent = useMemo(() => {
    if (!data) return null;
    switch (displayOption) {
      case DisplayOption.Well:
        return (
          <StackedComparisonWellChart
            {...props}
            data={data}
            displayOption={displayOption}
            dimension={props.dimension || DimensionType.Undefined}
            enableTooltip
          />
        );
      case DisplayOption.DirectionInterval:
      case DisplayOption.HoleSection:
        return (
          <StackedComparisonCategoryChart
            {...props}
            data={data}
            displayOption={displayOption}
          />
        );
      case DisplayOption.Shift:
        return (
          <StackedComparisonCategoryChart
            {...props}
            data={data}
            displayOption={displayOption}
          />
        );
    }
  }, [data, displayOption, props]);

  return (
    <LensLoadingContainer
      key={lens.id}
      dataState={data?.dataState}
      isDetailed={detailed}
      SummaryComponent={SummaryComponent}
      LoadedComponent={
        <StyledLensContainerFlex>
          {SummaryComponent}
          <StyledChartContainerFlexDiv style={{ paddingLeft: 24 }}>
            {LoadedComponent}
          </StyledChartContainerFlexDiv>
        </StyledLensContainerFlex>
      }
    />
  );
};
