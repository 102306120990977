import type { InspectionTvdPointDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import type {
  Curve,
  CurveWithData,
} from "components/Lenses/common/InspectionView/utils";
import colors from "utils/colors";
import { useUOM, UtilDimensions } from "utils/format";

export enum StandKpiCurves {
  HoleDepth = "holeDepth",
  BitDepth = "bitDepth",
  HookLoad = "hookLoad",
  BlockHeight = "blockHeight",
  SPP = "standpipePressure",
  PumpOutput = "pumpOutput",
  Torque = "torque",
  RPM = "revolutionsPerSecond",
  ROP = "rateOfPenetration",
  DiffPressure = "differentialPressure",
}

export const useStandKpiCurves = () => {
  const metresUOM = useUOM(DimensionType.Metres);
  const newtonsUOM = useUOM(DimensionType.Newtons);
  const pumpOutputUOM = useUOM(UtilDimensions.PumpOutput);
  const newtonMetersUOM = useUOM(DimensionType.NewtonMeters);
  const revolutionsPerSecondUOM = useUOM(DimensionType.RevolutionsPerSecond);
  const metresPerSecondUOM = useUOM(DimensionType.MetresPerSecond);
  const pascalsUOM = useUOM(DimensionType.Pascals);

  const standKpiCurves: Record<StandKpiCurves, Curve> = {
    [StandKpiCurves.HoleDepth]: {
      key: StandKpiCurves.HoleDepth,
      label: "Hole Depth",
      color: colors.well_color,
      valueTransformer: metresUOM,
      invertedScale: true,
      combinedScaleWith: StandKpiCurves.BitDepth,
    },
    [StandKpiCurves.BitDepth]: {
      key: StandKpiCurves.BitDepth,
      label: "Bit Depth",
      dashed: true,
      color: colors.light_green,
      valueTransformer: metresUOM,
      invertedScale: true,
      combinedScaleWith: StandKpiCurves.HoleDepth,
    },
    [StandKpiCurves.HookLoad]: {
      key: StandKpiCurves.HookLoad,
      label: "Hook Load",
      color: colors.helen_of_troy,
      valueTransformer: newtonsUOM,
    },
    [StandKpiCurves.BlockHeight]: {
      key: StandKpiCurves.BlockHeight,
      label: "Block Height",
      color: colors.sunburnt_toes,
      valueTransformer: metresUOM,
    },
    [StandKpiCurves.SPP]: {
      key: StandKpiCurves.SPP,
      label: "SPP",
      color: colors.flickering_sea,
      valueTransformer: pascalsUOM,
    },
    [StandKpiCurves.PumpOutput]: {
      key: StandKpiCurves.PumpOutput,
      label: "Pump Output",
      color: colors.prettiest_pink,
      valueTransformer: pumpOutputUOM,
    },
    [StandKpiCurves.Torque]: {
      key: StandKpiCurves.Torque,
      label: "Torque",
      color: colors.coral_expression,
      valueTransformer: newtonMetersUOM,
    },
    [StandKpiCurves.RPM]: {
      key: StandKpiCurves.RPM,
      label: "RPM",
      color: colors.perrywinkle,
      valueTransformer: revolutionsPerSecondUOM,
    },
    [StandKpiCurves.ROP]: {
      key: StandKpiCurves.ROP,
      label: "ROP",
      color: colors.charybdis,
      valueTransformer: metresPerSecondUOM,
    },
    [StandKpiCurves.DiffPressure]: {
      key: StandKpiCurves.DiffPressure,
      label: "Differential Pressure",
      color: colors.marble_green_grey,
      valueTransformer: pascalsUOM,
    },
  };

  const standKpiCurvesGroup = [
    [
      standKpiCurves[StandKpiCurves.HoleDepth],
      standKpiCurves[StandKpiCurves.BitDepth],
    ],
    [
      standKpiCurves[StandKpiCurves.HookLoad],
      standKpiCurves[StandKpiCurves.BlockHeight],
      standKpiCurves[StandKpiCurves.SPP],
      standKpiCurves[StandKpiCurves.PumpOutput],
    ],
    [
      standKpiCurves[StandKpiCurves.Torque],
      standKpiCurves[StandKpiCurves.RPM],
      standKpiCurves[StandKpiCurves.ROP],
      standKpiCurves[StandKpiCurves.DiffPressure],
    ],
  ];
  return { standKpiCurves, standKpiCurvesGroup };
};

export const getCurvesWithData = (
  data: InspectionTvdPointDto[],
  curves: Curve[],
) => {
  const getSeries = (curve: Curve) =>
    data.map((item) => item[curve.key as keyof InspectionTvdPointDto]);
  return curves.map(
    (curve) =>
      ({
        ...curve,
        series: getSeries(curve),
        axisSeries: curve.combinedScaleWith
          ? getSeries(
              curves.find((c) => c.key === curve.combinedScaleWith) ?? curve,
            )
          : [],
      }) as CurveWithData,
  );
};
