import { ErrorBoundary } from "@sentry/react";
import { Title } from "atoms/Typography";
import CoreLayout, { SectionLayout } from "components/Layout";
import MapView from "components/MapView";
import { WebGLSupportBanner } from "components/WebGLSupportBanner/WebGLSupportBanner";
import { useEvergeenWellsDataFeed } from "hooks/dataFeed/useAllWellsDataFeed";
import { ALL_WELLS_DEFAULT_HEADER_HEIGHT } from "pages/AllWells";
import AllWellsHeader from "pages/AllWells/components/AllWellsHeader";
import WellsContainer from "pages/AllWells/components/WellsContainer";
import {
  PaddedContainer,
  StyledContainer,
  StyledHeaderContainer,
  StyledMapviewWrapper,
  StyledSidebarWrapper,
  StyledSpace,
} from "pages/AllWells/StyledComponents";
import KPIsContainer from "pages/Evergreen/components/KPIsContainer";
import { type FC, useState } from "react";
import { useAppSelector } from "reducers/store";
import { usePageView } from "services/Mixpanel";
import { useCustomTheme } from "utils/useTheme";

export const Evergreen: FC = () => {
  usePageView("Evergreen Wells");
  useEvergeenWellsDataFeed();
  const { isDark, atomThemeVariant } = useCustomTheme();

  const [headerHeight, setHeaderHeight] = useState(
    ALL_WELLS_DEFAULT_HEADER_HEIGHT,
  );

  const wellFilters = useAppSelector((state) => state.evergreenWells.filters);
  return (
    <>
      <StyledContainer>
        <AllWellsHeader
          setHeaderHeight={setHeaderHeight}
          wellFilters={wellFilters}
        />
      </StyledContainer>

      <StyledMapviewWrapper $headerHeight={headerHeight}>
        <ErrorBoundary fallback={<WebGLSupportBanner />}>
          <MapView />
        </ErrorBoundary>
      </StyledMapviewWrapper>

      <PaddedContainer>
        <CoreLayout
          sidebar={
            <SectionLayout
              CustomChildrenWrapper={StyledSidebarWrapper}
              header={
                <StyledHeaderContainer>
                  <Title level={4} variant={atomThemeVariant}>
                    Access custom dashboards at
                  </Title>
                  <a
                    href="https://alphaanalytics.precisiondrilling.com/login"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      height={17}
                      width={129}
                      src={
                        isDark
                          ? "/assets/logos/alpha-analytics-white.png"
                          : "/assets/logos/alpha-analytics-dark.png"
                      }
                      alt="Alpha analytics"
                    />
                  </a>
                </StyledHeaderContainer>
              }
            >
              <StyledSpace align="start">
                <KPIsContainer />
              </StyledSpace>
            </SectionLayout>
          }
        >
          <WellsContainer />
        </CoreLayout>
      </PaddedContainer>
    </>
  );
};
