/* eslint-disable react/no-multi-comp */
import type { AccountDto, UserWithAccessCountDto } from "apis/oag";
import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import { useOperators } from "hooks/drillingInvariants/useOperators";
import type { FC } from "react";
import { Link } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import type { ListChildComponentProps } from "react-window";
import { FixedSizeList as List } from "react-window";
import styled from "styled-components";
import colors from "utils/colors";
import { Col, Row, Space, Table as AntTable } from "utils/componentLibrary";
import { PdCol } from "utils/componentLibrary/Col";
import { PdRow } from "utils/componentLibrary/Row";
import { useCustomTheme } from "utils/useTheme";

export const TableWhite = styled(AntTable).attrs({
  size: "large",
})`
  .ant-table {
    border-radius: 10px !important;
    overflow: hidden;
    table td,
    table th {
      border-color: ${({ theme }) =>
        theme.themeStyle.colors.primary_accent} !important;
    }
  }
  .ant-table-row,
  .ant-table-thead > tr {
    height: 64px;
  }

  .ant-table-thead > tr > th {
    font-weight: 400 !important;
    color: ${colors.gray} !important;
    background: ${({ theme }) =>
      theme.themeStyle.colors.secondary_bg} !important;
  }
  .ant-table,
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: ${({ theme }) =>
      theme.themeStyle.colors.off_secondary_bg} !important;
  }
`;

const TableContainer = styled.div`
  ${PdCol} {
    padding: 16px;
  }
`;

const TableHeader = styled.div`
  color: ${colors.gray} !important;
  border-bottom: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

const TableData = styled.div`
  height: calc(100vh - 64px - 120px - 64px - 120px);

  ${PdRow}:hover {
    background: ${({ theme }) =>
      theme.themeStyle.colors.primary_accent} !important;
  }

  ${PdRow} {
    cursor: pointer;
    transition: all 0.3s ease;
    border-bottom: 1px solid
      ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }

  ${PdCol} {
    padding: 16px;
  }
`;

const StyledSpace = styled(Space)`
  align-items: center;
  padding: 100px 0;
  .icon-holder {
    background: ${({ theme }) =>
      theme.themeStyle.colors.off_secondary_bg} !important;
    padding: 12px;
    border-radius: 100%;
    box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
    font-size: 16px;
  }
`;

const StyledList = styled(List)`
  outline: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  h3 {
    color: ${({ theme }) =>
      theme.themeStyle.colors.primary_typography} !important;
  }
  .pd-icon {
    color: ${({ theme }) =>
      theme.themeStyle.colors.primary_typography} !important;
  }
`;

function SingleItemAccounts({ style, index, data }: ListChildComponentProps) {
  const record: AccountDto = data.list[index];
  const id = record.id ?? 0;

  const { atomThemeVariant } = useCustomTheme();

  return (
    <Link to={`/admin/accounts/${id}`}>
      <Row style={style} key={record.id} align="middle">
        <Col span={12}>
          <Title level={3} variant={atomThemeVariant} weight={500}>
            {record.name}
          </Title>
        </Col>
        <Col span={2}>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {record.activeUserCount} / {record.userCount}
          </Title>
        </Col>
        <Col span={2}>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {record.operatorRigCount}
          </Title>
        </Col>
        <Col span={2}>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {record.claimedWellCount} / {record.operatorWellCount}
          </Title>
        </Col>
        <Col span={4}>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {record.ongoingAccess ? (
              <PDComponent.SvgIcon name="checkmark" />
            ) : null}
          </Title>
        </Col>
        <Col span={2}>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {record.status}
          </Title>
        </Col>
      </Row>
    </Link>
  );
}

interface IAccountsTable {
  data: AccountDto[];
}

export const AccountsTable: FC<IAccountsTable> = ({ data }) => {
  return (
    <TableContainer>
      <TableHeader>
        <Row>
          <Col span={12}>Operator</Col>
          <Col span={2}>Users</Col>
          <Col span={2}>Rigs</Col>
          <Col span={2}>Wells</Col>
          <Col span={4}>Ongoing Access</Col>
          <Col span={2}>Status</Col>
        </Row>
      </TableHeader>
      <TableData>
        {data.length > 0 && (
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <StyledList
                height={Number.isNaN(height) ? "100%" : height}
                width={width}
                itemSize={64}
                itemCount={data.length}
                itemData={{
                  list: data,
                  // onWellChange
                }}
              >
                {SingleItemAccounts}
              </StyledList>
            )}
          </AutoSizer>
        )}
        {data.length === 0 && (
          <Row align="middle" justify="center">
            <Col>
              <StyledSpace direction="vertical" size={16}>
                <div className="icon-holder">
                  <PDComponent.SvgIcon name="list" />
                </div>
                <Title level={3} variant="faded" weight={400}>
                  No Results
                </Title>
              </StyledSpace>
            </Col>
          </Row>
        )}
      </TableData>
    </TableContainer>
  );
};

function SingleItemUsers({ style, index, data }: ListChildComponentProps) {
  const operators = useOperators();

  const record: UserWithAccessCountDto = data.list[index];
  const id = record.id ?? 0;
  const { atomThemeVariant } = useCustomTheme();
  return (
    <Link to={`/admin/management/id/users/${id}`}>
      <Row style={style} key={record.id} align="middle">
        <Col span={10}>
          <Space>
            <Title level={3} variant={atomThemeVariant} weight={500}>
              {record?.firstName} {record?.lastName}
            </Title>
            <Title level={3} variant="faded">
              {record.userName}
            </Title>
          </Space>
        </Col>
        <Col span={4}>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {operators.data?.byId[record.operatorId ?? "0"]?.name || ""}
          </Title>
        </Col>
        <Col span={3}>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {record.role}
          </Title>
        </Col>
        <Col span={3}>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {record.jobTitle}
          </Title>
        </Col>
        <Col span={2}>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {record.claimedWellCount} / {record.operatorWellCount}
          </Title>
        </Col>
        <Col span={2}>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {record.status}
          </Title>
        </Col>
      </Row>
    </Link>
  );
}

interface IUsersTable {
  data: UserWithAccessCountDto[];
}

export const UsersTable: FC<IUsersTable> = ({ data }) => {
  return (
    <TableContainer>
      <TableHeader>
        <Row>
          <Col span={10}>User</Col>
          <Col span={4}>Account</Col>
          <Col span={3}>Role</Col>
          <Col span={3}>Job Title</Col>
          <Col span={2}>Wells</Col>
          <Col span={2}>Status</Col>
        </Row>
      </TableHeader>
      <TableData>
        {data.length > 0 && (
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <StyledList
                height={Number.isNaN(height) ? "100%" : height}
                width={width}
                itemSize={64}
                itemCount={data.length}
                itemData={{
                  list: data,
                }}
              >
                {SingleItemUsers}
              </StyledList>
            )}
          </AutoSizer>
        )}
        {data.length === 0 && (
          <Row align="middle" justify="center">
            <Col>
              <StyledSpace direction="vertical" size={16}>
                <div className="icon-holder">
                  <PDComponent.SvgIcon name="list" />
                </div>
                <Title level={3} variant="faded" weight={400}>
                  No Results
                </Title>
              </StyledSpace>
            </Col>
          </Row>
        )}
      </TableData>
    </TableContainer>
  );
};
