import { ResultDataState } from "apis/oag";
import type { ContainerLensProps } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { WellDrillingSummaryChart } from "components/Lenses/ContainerLens/WellDrillingSummaryKpi/Chart";
import { WellDrillingSummaryLoading } from "components/Lenses/ContainerLens/WellDrillingSummaryKpi/Chart/Loading";
import { useWellDrillingSummaryFacts } from "components/Lenses/ContainerLens/WellDrillingSummaryKpi/fetcher";
import { useMemo } from "react";

export const WellDrillingSummaryKpi: React.FC<ContainerLensProps> = ({
  detailed,
  graphKey,
  dimension,
  lens,
}) => {
  const { data, isLoading } = useWellDrillingSummaryFacts(lens.id);

  const dataState = useMemo(
    () =>
      data?.dataState === ResultDataState.NoData
        ? ResultDataState.NoData
        : ResultDataState.Valid,
    [data?.dataState],
  );

  return data?.dataState === ResultDataState.Valid && !isLoading ? (
    <WellDrillingSummaryChart
      detailed={detailed}
      graphKey={graphKey}
      dimension={dimension}
      lens={lens}
      data={data}
    />
  ) : (
    <WellDrillingSummaryLoading lensId={lens.id} dataState={dataState} />
  );
};
