import styled from "styled-components";
import colors from "utils/colors";

const StyledBar = styled.div`
  height: 100%;
  width: 50%;
  position: relative;
  background-color: ${(props) => props.theme.themeStyle.colors.soft_accent};
`;

const StyledInnerBar = styled.div<{ $widthRatio: number }>`
  width: ${(props) => Math.abs(props.$widthRatio) * 100}%;
  height: 100%;
  position: absolute;
  top: 0;
`;

const StyledNegativeBar = styled(StyledBar)`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;
const StyledPositiveBar = styled(StyledBar)`
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const StyledPositive = styled(StyledInnerBar)`
  left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: linear-gradient(
    270deg,
    ${colors.green_fluorite} 0%,
    ${colors.green_fluorite}80 100%
  );
`;

const StyledNegative = styled(StyledInnerBar)`
  right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: linear-gradient(
    90deg,
    ${colors.coral_expression} 0%,
    ${colors.coral_expression}80 100%
  );
`;

const StyledContainer = styled.div<{ $width: number; $height: number }>`
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  display: flex;
  position: relative;
  ::after {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: calc(100% + 24px);
    content: "";
    background-color: ${(props) =>
      props.theme.themeStyle.colors.primary_accent};
    display: flex;
  }
`;

export function HorizontalBars({
  width = 0,
  height,
  ratio,
}: {
  width: number;
  height: number;
  ratio: number;
}) {
  return (
    <StyledContainer $width={width} $height={height}>
      <StyledNegativeBar>
        {ratio < 0 ? <StyledNegative $widthRatio={ratio} /> : null}
      </StyledNegativeBar>
      <StyledPositiveBar>
        {ratio > 0 ? <StyledPositive $widthRatio={ratio} /> : null}
      </StyledPositiveBar>
    </StyledContainer>
  );
}
