import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ScorecardKpiCommentsApi } from "apis/oag";
import { DetailedNotificationsQueryKey } from "hooks/notifications/useDetailedNotification";
import {
  ActiveNotificationsQueryKey,
  ArchivedNotificationsQueryKey,
} from "hooks/notifications/useNotifications";
import { useCallback } from "react";
import { apiConfig } from "utils/apiConfig";

const commentsApi = new ScorecardKpiCommentsApi(apiConfig);
export const useDetailedNotificationsActions = (commentId: number) => {
  const queryClient = useQueryClient();
  const approveComment = useMutation({
    mutationFn: (id: number) => {
      return commentsApi.apiScorecardKpiCommentsIdApprovePut({ id });
    },
  });

  const rejectComment = useMutation({
    mutationFn: (id: number) => {
      return commentsApi.apiScorecardKpiCommentsIdRejectPut({ id });
    },
  });

  const handleOnApprove = useCallback(async () => {
    await approveComment.mutateAsync(commentId, {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [ActiveNotificationsQueryKey],
        });
        queryClient.invalidateQueries({
          queryKey: [ArchivedNotificationsQueryKey],
        });
        queryClient.invalidateQueries({
          queryKey: [DetailedNotificationsQueryKey],
        });
      },
    });
  }, [queryClient, approveComment, commentId]);

  const handleOnReject = useCallback(async () => {
    await rejectComment.mutateAsync(commentId, {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [ActiveNotificationsQueryKey],
        });
        queryClient.invalidateQueries({
          queryKey: [ArchivedNotificationsQueryKey],
        });
        queryClient.invalidateQueries({
          queryKey: [DetailedNotificationsQueryKey],
        });
      },
    });
  }, [queryClient, rejectComment, commentId]);

  return {
    handleOnApprove,
    handleOnReject,
  };
};
