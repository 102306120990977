export const MockDieselNatGasRatio = () => {
  return (
    <svg
      width="326"
      height="126"
      viewBox="0 0 326 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="45.0972"
        y="35.5"
        width="32"
        height="32"
        rx="16"
        fill="#D69985"
      />
      <path
        d="M61.0972 55.5V54.5C61.4838 54.4666 61.8462 54.2978 62.1205 54.0234C62.3949 53.749 62.5637 53.3866 62.5972 53H63.5972C63.5649 53.6527 63.2911 54.2699 62.8291 54.732C62.3671 55.194 61.7498 55.4677 61.0972 55.5Z"
        fill="white"
      />
      <path
        d="M61.0972 57.5C59.9042 57.4985 58.7604 57.024 57.9169 56.1804C57.0733 55.3368 56.5987 54.1931 56.5972 53C56.6241 52.1228 56.8817 51.2682 57.3442 50.5223L60.6736 45.2184C60.7217 45.1509 60.7852 45.0958 60.8589 45.0578C60.9326 45.0198 61.0143 45 61.0972 45C61.1801 45 61.2618 45.0198 61.3355 45.0578C61.4091 45.0958 61.4727 45.1509 61.5208 45.2184L64.8352 50.4966C65.3056 51.2493 65.5684 52.113 65.5972 53C65.5957 54.1931 65.1211 55.3368 64.2775 56.1804C63.4339 57.024 62.2902 57.4985 61.0972 57.5ZM61.0972 46.4242L58.2063 51.0281C57.8338 51.6206 57.6237 52.3007 57.5972 53C57.5972 53.9283 57.9659 54.8185 58.6223 55.4749C59.2787 56.1313 60.1689 56.5 61.0972 56.5C62.0254 56.5 62.9157 56.1313 63.572 55.4749C64.2284 54.8185 64.5972 53.9283 64.5972 53C64.5687 52.2908 64.3534 51.6017 63.9732 51.0024L61.0972 46.4242Z"
        fill="white"
      />
      <rect
        x="53.5542"
        y="73.5"
        width="15.0857"
        height="4"
        rx="2"
        fill="#828C91"
        fillOpacity="0.4"
      />
      <rect
        x="44"
        y="83.5"
        width="34.1943"
        height="8"
        rx="2"
        fill="#828C91"
        fillOpacity="0.4"
      />
      <rect
        x="24.5"
        y="12"
        width="277"
        height="102"
        rx="3.5"
        stroke="#828C91"
        strokeOpacity="0.4"
      />
      <rect
        x="249.097"
        y="35.5"
        width="32"
        height="32"
        rx="16"
        fill="#5B5BEA"
      />
      <path
        d="M269.513 50.9844C269.377 50.6611 269.222 50.2944 269.072 49.8418C268.677 48.6577 269.939 47.3657 269.951 47.3535L269.244 46.6465C269.173 46.7168 267.532 48.3852 268.123 50.1582C268.286 50.6472 268.448 51.0327 268.592 51.3732C268.925 52.0324 269.099 52.7612 269.097 53.5C269.039 54.0955 268.81 54.6617 268.439 55.1309C268.067 55.6002 267.569 55.9527 267.003 56.1465C267.181 55.4453 267.179 54.7101 266.995 54.0103C266.811 53.3104 266.451 52.669 265.951 52.1465L265.428 51.6243L265.138 52.303C264.22 54.445 263.127 55.38 262.485 55.77C262.09 55.5284 261.759 55.1967 261.518 54.8021C261.276 54.4074 261.132 53.9612 261.097 53.5C261.132 52.8706 261.289 52.2541 261.562 51.6855C261.884 51.0002 262.067 50.257 262.097 49.5V48.6108C262.534 48.7908 263.097 49.2627 263.097 50.5V51.8018L263.969 50.8347C265.525 49.1074 265.2 47.0508 264.572 45.6806C265.049 45.8399 265.46 46.1553 265.736 46.576C266.013 46.9967 266.14 47.4983 266.097 48H267.097C267.097 45.2315 264.808 44.5 263.597 44.5H262.597L263.197 45.2993C263.266 45.3921 264.628 47.2632 263.874 49.1433C263.706 48.6708 263.398 48.2605 262.991 47.967C262.585 47.6735 262.099 47.5106 261.597 47.5H261.097V49.5C261.063 50.1294 260.905 50.7459 260.633 51.3145C260.31 51.9998 260.128 52.743 260.097 53.5C260.097 55.4239 262.009 57.5 265.097 57.5C268.186 57.5 270.097 55.4239 270.097 53.5C270.098 52.6278 269.898 51.7671 269.513 50.9844ZM263.515 56.2632C264.437 55.4996 265.182 54.5444 265.698 53.4639C265.974 53.9088 266.13 54.4171 266.153 54.94C266.175 55.4628 266.063 55.9827 265.827 56.4497C265.585 56.4822 265.341 56.499 265.097 56.5C264.561 56.5038 264.027 56.4239 263.515 56.2632Z"
        fill="white"
      />
      <rect
        x="257.554"
        y="73.5"
        width="15.0857"
        height="4"
        rx="2"
        fill="#828C91"
        fillOpacity="0.4"
      />
      <rect
        x="248"
        y="83.5"
        width="34.1943"
        height="8"
        rx="2"
        fill="#828C91"
        fillOpacity="0.4"
      />
      <g clipPath="url(#clip0_2761_73046)">
        <path
          d="M95 114.5C95 96.4653 102.164 79.1692 114.917 66.4167C127.669 53.6643 144.965 46.5 163 46.5C181.035 46.5 198.331 53.6643 211.083 66.4167C223.836 79.1692 231 96.4653 231 114.5L199.72 114.5C199.72 104.761 195.851 95.4214 188.965 88.535C182.079 81.6487 172.739 77.78 163 77.78C153.261 77.78 143.921 81.6487 137.035 88.535C130.149 95.4214 126.28 104.761 126.28 114.5L95 114.5Z"
          fill="url(#paint0_linear_2761_73046)"
        />
        <path
          d="M95 114.5C95 98.9146 100.354 83.8023 110.165 71.6927C119.976 59.5831 133.65 51.2113 148.896 47.9787C164.142 44.7462 180.037 46.849 193.918 53.9352C207.799 61.0215 218.825 72.661 225.149 86.9054L196.561 99.5989C193.145 91.907 187.191 85.6216 179.696 81.795C172.2 77.9685 163.617 76.8329 155.384 78.5785C147.151 80.3241 139.767 84.8449 134.469 91.3841C129.171 97.9232 126.28 106.084 126.28 114.5L95 114.5Z"
          fill="url(#paint1_linear_2761_73046)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2761_73046"
          x1="163"
          y1="46.5"
          x2="163"
          y2="182.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5B5BEA" />
          <stop offset="1" stopColor="#5B5BEA" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2761_73046"
          x1="163"
          y1="46.5"
          x2="163"
          y2="182.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D69985" />
          <stop offset="1" stopColor="#D69985" stopOpacity="0.5" />
        </linearGradient>
        <clipPath id="clip0_2761_73046">
          <rect
            width="136"
            height="68"
            fill="white"
            transform="translate(95 46.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
