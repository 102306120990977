import type { DetailedLegendColumn } from "components/Lenses/common/ChartLegend/DetailedLegend";
import { DetailedLegend } from "components/Lenses/common/ChartLegend/DetailedLegend";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { SimpleLegend } from "components/Lenses/common/ChartLegend/SimpleLegend";

interface LensLegendProps {
  legendItems: LegendItem[];
  detailedColumns?: DetailedLegendColumn[];
  isDetailed?: boolean;
  lensGridSize: [number, number];
  customStyle?: React.CSSProperties;
  clickable?: boolean;
}

const ChartLegend: React.FC<LensLegendProps> = (props) => {
  return props.isDetailed ? (
    <DetailedLegend {...props} />
  ) : (
    <SimpleLegend {...props} />
  );
};

export default ChartLegend;
