import { AxisBottom, AxisRight } from "@visx/axis";
import { RectClipPath } from "@visx/clip-path";
import { LinearGradient } from "@visx/gradient";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { LinePath } from "@visx/shape";
import type { GeneratorSlotType, RigPowerFactDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import { Title } from "atoms/Typography";
import {
  AxisLocation,
  StandardTickComponent,
} from "components/Lenses/common/ChartElements";
import ChartLegend from "components/Lenses/common/ChartLegend";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { LegendPreviewerType } from "components/Lenses/common/ChartLegend/interfaces";
import { useChartDateTimeRange } from "components/Lenses/common/useChartDateTimeRange";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import { RotatedRightAxisLabel } from "components/Lenses/ContainerLens/common/RotatedRightAxisLabel";
import { StyledChartContainerFlexDiv } from "components/Lenses/ContainerLens/common/StyledComponents";
import {
  CLIP_SERIES_ID as CLIP_ID,
  getInternalAxisMargin,
  LATERAL_AXIS_WIDTH,
  X_AXIS_HEIGHT,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { usePowerConsumptionByOperationFetcher } from "components/Lenses/ContainerLens/PowerConsumptionByOperation/fetcher";
import { usePowerConsumptionByOperationTooltip } from "components/Lenses/ContainerLens/PowerConsumptionByOperation/usePowerConsumptionByOperationTooltip";
import {
  AVAILABLE_POWER_LEGEND_ID,
  UNIT_LABEL,
} from "components/Lenses/ContainerLens/PowerConsumptionByOperation/utils";
import { StyledLensContainerFlex } from "components/Lenses/ContainerLens/RigPower/RigPowerSimple/StyledComponents";
import type { PowerConsumptionByOperationProps } from "components/Lenses/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { curveMonotoneX, curveStepAfter } from "d3";
import { max, min } from "d3-array";
import useDiscontinuousTimeAxis from "hooks/charting/useDiscontinuousTimeAxis";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import { useOperationClasses } from "hooks/evergreen/useOperationClasses";
import { useOperationColors } from "hooks/evergreen/useOperationColors";
import { useLensNameByTemplateId } from "hooks/lens/useLensNameByTemplateId";
import { useLensSize } from "hooks/lens/useLensSize";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";
import { defaultDateDto } from "utils/common";
import { Col, Row } from "utils/componentLibrary";
import { DEFAULT_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

export const Single: React.FC<PowerConsumptionByOperationProps> = ({
  lens,
  detailed,
  setLensDate,
}) => {
  const [legend, setLegend] = useState<Array<
    GeneratorSlotType | number
  > | null>(null);

  const { request } = usePowerConsumptionByOperationFetcher({
    lensId: lens?.id,
  });
  const { data } = request;
  useEffect(() => {
    if (data?.lastUpdatedAt && setLensDate) setLensDate(data.lastUpdatedAt);
  }, [data, setLensDate]);

  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: containerRef,
  } = useResizeDetector();
  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  const plotWidth = getSVGNormalizedValue(chartWidth - 2 * LATERAL_AXIS_WIDTH);
  const plotHeight = getSVGNormalizedValue(chartHeight - X_AXIS_HEIGHT);
  const dataState = useMemo(() => data?.dataState, [data?.dataState]);
  const [isPointerInsideChart, setPointerInsideChart] = useState(false);
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const { data: operationClassesNames } = useOperationClasses();

  const series = useMemo(() => data?.facts || [], [data?.facts]);
  const keys = data.availableOperationClasses;

  const { data: operationClasses } = useOperationClasses();
  const { getOperationColor } = useOperationColors();

  const transformedData = useMemo(() => {
    if (!data?.facts) return [];
    const facts: Array<Array<RigPowerFactDto>> = data.facts.reduce<
      Array<Array<RigPowerFactDto>>
    >((prev, current) => {
      if (!prev?.length) {
        return [[current]];
      }
      const prevEl = prev.slice(-1)[0];
      const prevOperationClassId = prevEl[0]?.operationClassId;
      if (current.operationClassId === prevOperationClassId)
        return [...prev.slice(0, -1), [...prevEl, current]];
      return [
        ...prev.slice(0, -1),
        [
          ...prevEl,
          {
            ...prevEl[0],
            index: current.index,
            totalPower: current.totalPower,
          },
        ],
        [current],
      ];
    }, []);
    return facts;
  }, [data]);

  const lensName = useLensNameByTemplateId(lens?.lensTemplateId);
  const generatorLegendItems = useMemo<LegendItem[]>(() => {
    if (!data?.availableOperationClasses) return [];
    return data.availableOperationClasses
      .sort((a, b) => {
        const aIndex = operationClasses.findIndex(
          (operationClass) => operationClass.id === a,
        );
        const bIndex = operationClasses.findIndex(
          (operationClass) => operationClass.id === b,
        );
        return aIndex - bIndex;
      })
      .map((operationClassId) => ({
        id: operationClassId,
        name:
          operationClasses.find((opClass) => opClass.id === operationClassId)
            ?.name ?? "NO NAME",
        color: operationClassesNames.find((op) => op.id === operationClassId)
          ?.name
          ? getOperationColor(operationClassId)
          : "",
        isEnabled: legend === null ? true : legend?.includes(operationClassId),
        columnValues: undefined,
        previewerType: LegendPreviewerType.BOX,
        onClick: (item: LegendItem) => {
          Track.interact("Evergreen Dashboard - Update legend", {
            lens: lensName,
            lensId: lens.id,
            selectedItem: item.name,
            isEnabled: !item.isEnabled,
          });
          setLegend((currentLegend) => {
            if (currentLegend === null) {
              return (keys || []).filter((key) => key !== item.id);
            }
            if (currentLegend.length === 1 && item.isEnabled)
              return currentLegend;
            return item.isEnabled
              ? currentLegend.filter((element) => element !== item.id)
              : [...currentLegend, item.id];
          });
        },
      }));
  }, [
    lensName,
    lens.id,
    data.availableOperationClasses,
    getOperationColor,
    keys,
    legend,
    operationClasses,
    operationClassesNames,
  ]);

  const [availablePower, setAvailablePower] = useState<LegendItem>({
    // This one is purely cosmetic, not enabling and disabling it on server
    id: AVAILABLE_POWER_LEGEND_ID,
    name: "Available Power",
    color: colors.aurora_magenta,
    isEnabled: true,
    previewerType: LegendPreviewerType.LINE,
    columnValues: undefined,
    onClick: () => {
      Track.interact("Evergreen Dashboard - Update legend", {
        lensId: lens.id,
        lens: lensName,
        selectedItem: availablePower.name,
        isEnabled: !availablePower.isEnabled,
      });
      setAvailablePower((capacityLegendItem) => ({
        ...capacityLegendItem,
        isEnabled: !capacityLegendItem.isEnabled,
      }));
    },
  });

  const xScale = useMemo(() => {
    const chartSize = plotWidth;
    const startX = LATERAL_AXIS_WIDTH;

    const timeSeries = series.map((fact) => fact.index);
    const [_min = 0, _max = 0] = [min(timeSeries), max(timeSeries)];

    return scaleLinear<number>({
      domain: [_min, _max],
      range: [startX, chartSize + startX],
    });
  }, [plotWidth, series]);

  const { xScaleDate, chunksCount, getAverageChunkDate } =
    useDiscontinuousTimeAxis({
      plotWidth,
      xScaleDomain: xScale.domain(),
      series: series ?? [],
    });

  const [width, height] = useLensSize(lens?.id);
  const internalAxisMargin = useMemo(
    () => getInternalAxisMargin(detailed),
    [detailed],
  );
  const legendItems = useMemo(
    () => generatorLegendItems.concat(availablePower),
    [availablePower, generatorLegendItems],
  );

  const yScale = useMemo(() => {
    const [minValue, maxValue] = [
      0,
      max(
        (data.facts || []).map(
          (e) =>
            max([
              (e.slices || []).reduce((acc, ee) => acc + ee.sliceValue, 0),
              availablePower.isEnabled ? e.availablePower : 0,
              e.totalPower,
            ]) || 0,
        ),
      ),
    ];
    return scaleLinear<number>({
      range: [plotHeight, internalAxisMargin],
      domain: [minValue, maxValue || 0],
      nice: true,
    });
  }, [availablePower, data.facts, internalAxisMargin, plotHeight]);

  const uomValue = useUOM(DimensionType.Watts);

  const { yDisplayOnlyScale, verticalAxisTicksCount, formatTickDecimals } =
    useYAxisDisplayScale({
      tickContainerHeight: plotHeight,
      originalScale: yScale,
      uom: uomValue,
    });

  const CLIP_SERIES_ID = useMemo(() => `${CLIP_ID}-${lens.id}`, [lens.id]);

  const { handlePointerMove, tooltipElement } =
    usePowerConsumptionByOperationTooltip({
      containerRef,
      xScale,
      yScale,
      legend,
      legendItems,
      series: data?.facts || [],
      isPointerInsideChart,
      plotWidth: chartWidth,
      plotHeight,
    });

  const { selectionRectangleElement, isDragging } =
    useChartDateTimeRange<RigPowerFactDto>({
      xScale,
      yScale,
      plotWidth: plotWidth,
      plotWidthOffset: LATERAL_AXIS_WIDTH,
      plotHeight,
      series: data?.facts || [],
    });

  const LENS_TITLE = "Power Consumption by Operation";

  return (
    <LensLoadingContainer
      key={lens.id}
      dataState={dataState}
      title="Power Consumption by Operation"
      isDetailed={false}
      LoadedComponent={
        <StyledLensContainerFlex>
          <Row justify="space-between">
            <Col style={{ padding: 24 }}>
              <Title variant="faded" level={3}>
                {LENS_TITLE}
              </Title>
            </Col>
          </Row>

          <StyledChartContainerFlexDiv>
            <div
              style={{
                height: "auto",
                flexBasis: "50%",
                minHeight: 150,
                flexGrow: 1,
              }}
              ref={containerRef}
            >
              <svg
                onPointerMove={handlePointerMove}
                onMouseLeave={() => setPointerInsideChart(false)}
                onMouseEnter={() => setPointerInsideChart(true)}
                onMouseMove={() => setPointerInsideChart(true)}
                width={chartWidth}
                height="100%"
                style={{ overflow: "hidden", userSelect: "none" }}
              >
                <RectClipPath
                  y={0}
                  id={CLIP_SERIES_ID}
                  height={plotHeight}
                  width={plotWidth}
                  x={LATERAL_AXIS_WIDTH}
                />
                <GridRows
                  scale={yDisplayOnlyScale}
                  width={chartWidth}
                  height={chartHeight}
                  stroke={themeColors.primary_chart_accent}
                  strokeWidth={1}
                  strokeOpacity={1}
                  clipPath={`url(#${CLIP_SERIES_ID})`}
                />

                <Group clipPath={`url(#${CLIP_SERIES_ID})`}>
                  {transformedData.map((data, idx) => {
                    if (!data) return null;
                    if (
                      legend !== null &&
                      !legend.includes(data[0].operationClassId)
                    )
                      return null;
                    const gradientToColor = `${getOperationColor(data[0].operationClassId)}7f`;
                    const gradientFromColor = `${getOperationColor(data[0].operationClassId)}`;
                    return (
                      <Fragment key={idx}>
                        <LinearGradient
                          from={gradientFromColor}
                          to={gradientToColor}
                          fromOpacity={1}
                          toOpacity={0.9}
                          id={`linear_gradient_${gradientToColor}_${lens.id}`}
                        />
                        <LinePath
                          curve={curveMonotoneX}
                          data={[
                            { index: data[0].index, totalPower: 0 },
                            ...data,
                            { index: data.slice(-1)[0].index, totalPower: 0 },
                          ]}
                          x={(d) => xScale(d.index)}
                          y={(d) => yScale(d.totalPower)}
                          stroke={`${colors.neutral}40`}
                          strokeOpacity={0.1}
                          strokeWidth={1}
                          fill={`url(#linear_gradient_${gradientToColor}_${lens.id})`}
                        />
                      </Fragment>
                    );
                  })}
                  {availablePower.isEnabled ? (
                    <LinePath
                      curve={curveStepAfter}
                      data={data.facts || []}
                      x={(d) => xScale(d.index) || 0}
                      y={(d) => yScale(d.availablePower) || 0}
                      stroke={colors.aurora_magenta}
                      strokeWidth={1}
                    />
                  ) : null}
                </Group>

                <AxisRight
                  scale={yDisplayOnlyScale}
                  hideTicks
                  hideZero
                  numTicks={verticalAxisTicksCount}
                  left={(chartWidth ?? 0) - LATERAL_AXIS_WIDTH}
                  tickComponent={(props) => {
                    if (props.y === 0) return null;
                    return (
                      <StandardTickComponent rendererProps={props}>
                        {(val) => formatTickDecimals(val)}
                      </StandardTickComponent>
                    );
                  }}
                  hideAxisLine
                />

                {/* VISIBLE TIME AXIS */}
                <AxisBottom
                  hideAxisLine
                  hideTicks
                  scale={xScaleDate}
                  top={plotHeight}
                  numTicks={chunksCount}
                  tickComponent={(props) => {
                    return (
                      <StandardTickComponent
                        rendererProps={props}
                        axisLocation={AxisLocation.BOTTOM}
                      >
                        {(val) =>
                          formatTime(
                            getAverageChunkDate(+val) || defaultDateDto.from,
                            {
                              formatStr: DEFAULT_DATE_FORMAT,
                            },
                          )
                        }
                      </StandardTickComponent>
                    );
                  }}
                />
                {!isDragging && tooltipElement}
                {selectionRectangleElement}
              </svg>

              <RotatedRightAxisLabel
                plotWidth={plotWidth}
                chartHeight={chartHeight}
                displayText={UNIT_LABEL}
              />
            </div>
            <ChartLegend
              legendItems={legendItems}
              lensGridSize={[width, height]}
            />
          </StyledChartContainerFlexDiv>
        </StyledLensContainerFlex>
      }
    />
  );
};
