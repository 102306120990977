import { useQuery } from "@tanstack/react-query";
import type { WellAccessQueryDto } from "apis/oag";
import { OperatorsApi } from "apis/oag";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDAdminAccountsWellAccessQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const ops = new OperatorsApi(apiConfig);

export function useAdminSingleAccountWellAccess(uid: number) {
  const period = useAppSelector((state) => state.admin.wellAccess.period);
  const rigs = useAppSelector((state) => state.admin.wellAccess.rigs);
  const wellStatus = useAppSelector(
    (state) => state.admin.wellAccess.wellStatus,
  );
  const search = useAppSelector((state) => state.admin.wellAccess.search);

  const requestQuery: WellAccessQueryDto = {
    spudDateStart: period.startDate,
    spudDateEnd: period.endDate,
    rigIds: rigs,
    status: wellStatus,
    search: search,
  };

  const queryKey: PDAdminAccountsWellAccessQueryKey = {
    type: PDQueryType.ADMIN_ACCOUNTS_WELL_ACCESS,
    uid: RequestUID.adminAccountsWellAccess,
    params: requestQuery,
    paramsUid: uid,
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: () =>
      ops.apiOperatorsIdWellAccessPut({
        id: uid,
        wellAccessQueryDto: requestQuery,
      }),
    enabled: !!uid,
  });
}
