import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type {
  ApiParameterHeatmapUserLensesIdFactsPutRequest,
  ParameterHeatmapResultSetDto,
} from "apis/oag";
import { ParameterHeatmapUserLensesApi } from "apis/oag";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useAppDispatch } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new ParameterHeatmapUserLensesApi(apiConfig);

export const useParameterHeatmapFacts = (
  lensId: number,
  requestParameters: ApiParameterHeatmapUserLensesIdFactsPutRequest,
  options?: Omit<
    UseQueryOptions<ParameterHeatmapResultSetDto>,
    "queryKey" | "queryFn"
  >,
) => {
  const wellId = useSelectedWell();
  const dispatch = useAppDispatch();
  const queryKey: PDLensFactsQueryKey<ApiParameterHeatmapUserLensesIdFactsPutRequest> =
    {
      type: PDQueryType.FACTS,
      uid: RequestUID.parameterHeatmapFacts,
      lensId,
      params: requestParameters,
    };

  return useSuspenseQuery<ParameterHeatmapResultSetDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      api
        .apiParameterHeatmapUserLensesIdFactsPut(requestParameters, { signal })
        .then((data) => {
          if (data?.lastUpdatedAt) {
            dispatch({
              type: "SET_BY_DEPTH_REFRESH_DATE",
              payload: { [wellId]: data?.lastUpdatedAt },
            });
          }
          return data;
        }),
    ...options,
  });
};
