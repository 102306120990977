import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  DashboardsApi,
  type DashboardType,
  type LensTabWithVisibilityDto,
} from "apis/oag";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import { useUserDashboard } from "hooks/dashboard/useUserLensTabs";
import * as Styled from "pages/WellDashboard/ManageTabs/style";
import { CompanyTabsTable } from "pages/WellDashboard/ManageTabs/Tables/CompanyTabsTable.tsx/CompanyTabsTable";
import { ReceivedTabsTable } from "pages/WellDashboard/ManageTabs/Tables/ReceivedTabsTable/ReceivedTabsTable";
import { UserTabsTable } from "pages/WellDashboard/ManageTabs/Tables/UserTabsTable.tsx/UserTabsTable";
import { FooterContainer } from "pages/WellDashboard/ShareTabs/ShareTabsModal/style";
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Modal from "react-modal";
import { apiConfig } from "utils/apiConfig";
import { Col, Row, Space } from "utils/componentLibrary";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

const dashboardApi = new DashboardsApi(apiConfig);

export const ManageTabsModal = ({
  isVisible,
  onCancel,
}: {
  isVisible: boolean;
  onCancel: () => void;
}) => {
  const { themeStyle, atomThemeVariant } = useCustomTheme();
  const { dashboardType } = useDashboardType();
  const [isReceivedTabsOpen, setIsReceivedTabsOpen] = useState(true);
  const [isCompanyTabsOpen, setIsCompanyTabsOpen] = useState(false);
  const [isUserTabsOpen, setIsUserTabsOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const scrollableContentRef = useRef<HTMLDivElement>(null);
  const { data: allTabs, isLoading: isLoadingTabs } = useUserDashboard(
    dashboardType,
    { refetchOnMount: true },
  );
  const [isLoadingAccepting, setIsLoadingAccepting] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [globalTempPersonalTabsModel, setGlobalTempPersonalTabsModel] =
    useState<LensTabWithVisibilityDto[]>(allTabs?.personalTabs ?? []);
  const [uncommittedDeletedTabIds, setUncommittedDeletedTabIds] = useState<
    number[]
  >([]);

  useLayoutEffect(() => {
    if (scrollableContentRef?.current) {
      scrollableContentRef.current.scrollTop =
        scrollableContentRef.current?.scrollHeight;
    }
  }, []);

  const companyTabs = useMemo(() => {
    return allTabs?.operatorTabs ?? [];
  }, [allTabs?.operatorTabs]);

  const handleTabsUpdate = useMutation({
    mutationFn: ({
      tabs,
      dashboardType,
    }: {
      tabs: LensTabWithVisibilityDto[];
      dashboardType: DashboardType;
    }) =>
      dashboardApi.apiDashboardsTypePut({
        type: dashboardType,
        dashboardPersonalTabs: {
          personalTabs: tabs,
        },
      }),
  });

  const handleOnSave = useCallback(async () => {
    setIsLoadingAccepting(true);
    const leftTabs = globalTempPersonalTabsModel.filter(
      (tab) => !uncommittedDeletedTabIds.includes(tab.id),
    );
    await handleTabsUpdate.mutateAsync(
      {
        tabs: leftTabs,
        dashboardType,
      },
      {
        onSuccess: async () => {
          await queryClient.refetchQueries({
            queryKey: [{ type: PDQueryType.USER_LENS_TABS }],
          });
          setIsLoadingAccepting(false);
          onCancel();
        },
      },
    );
  }, [
    dashboardType,
    globalTempPersonalTabsModel,
    handleTabsUpdate,
    onCancel,
    queryClient,
    uncommittedDeletedTabIds,
  ]);

  const handleOnAttemptDeleteId = useCallback((tabId: number) => {
    setUncommittedDeletedTabIds((preppedForDelete) => [
      ...preppedForDelete,
      tabId,
    ]);
  }, []);

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onCancel}
      style={{
        content: {
          ...Styled.customStyles.content,
          backgroundColor: themeStyle.colors.alternate_bg,
        },
        overlay: Styled.customStyles.overlay,
      }}
      ariaHideApp={false}
      shouldCloseOnEsc
    >
      <Row
        justify="space-between"
        align="middle"
        style={{ padding: "16px 24px", paddingBottom: 0 }}
      >
        <Col>
          <Title variant={atomThemeVariant} level={2} weight={500}>
            Manage All Tabs
          </Title>
        </Col>

        <Col span={16}>
          <Row justify="end">
            <Space direction="horizontal">
              <Styled.Search
                addonAfter={<PDComponent.SvgIcon name="search" />}
                placeholder="Search Tab"
                size="large"
                allowClear
                onChange={(ev) => setSearchTerm(ev.target?.value)}
              />
            </Space>
          </Row>
        </Col>

        <span onClick={onCancel}>
          <Styled.StyledCloseIcon />
        </span>
      </Row>

      <Row
        justify="space-between"
        align="middle"
        style={{ padding: "16px 24px", paddingBottom: 0 }}
      >
        <Col>
          <Title level={3}>
            <b>Dashboard Name:</b> {dashboardType} Dashboard
          </Title>
        </Col>
      </Row>

      <Styled.ScrollableContent ref={scrollableContentRef}>
        <Row
          justify="space-between"
          align="middle"
          style={{ padding: "16px 24px" }}
        >
          <Col>
            <Styled.ClickableElement
              role="button"
              onClick={() => setIsCompanyTabsOpen((isOpen) => !isOpen)}
            >
              <Title level={3}>
                <Styled.Chevron isOpen={isCompanyTabsOpen} />
                <b>Company Tabs</b>
              </Title>
            </Styled.ClickableElement>
          </Col>
        </Row>

        {isCompanyTabsOpen && !isLoadingTabs ? (
          <Row style={{ padding: "16px 24px" }}>
            <CompanyTabsTable tabs={companyTabs} filterTerm={searchTerm} />
          </Row>
        ) : null}

        <Row
          justify="space-between"
          align="middle"
          style={{ padding: "16px 24px" }}
        >
          <Col>
            <Styled.ClickableElement
              onClick={() => setIsUserTabsOpen((isOpen) => !isOpen)}
              role="button"
            >
              <Title level={3}>
                <Styled.Chevron isOpen={isUserTabsOpen} />
                <b>User Tabs</b>
              </Title>
            </Styled.ClickableElement>
          </Col>
        </Row>

        {isUserTabsOpen && !isLoadingTabs ? (
          <Row style={{ padding: "16px 24px" }}>
            <UserTabsTable
              tabs={globalTempPersonalTabsModel}
              uncomittedDeletedTabs={uncommittedDeletedTabIds}
              handleOnAttemptDeleteId={handleOnAttemptDeleteId}
              handleOnUndoDelete={(id) => {
                setUncommittedDeletedTabIds((prev) =>
                  prev.filter((tabId) => tabId !== id),
                );
              }}
              onTabsChange={setGlobalTempPersonalTabsModel}
              filterTerm={searchTerm}
            />
          </Row>
        ) : null}

        <Row
          justify="space-between"
          align="middle"
          style={{ padding: "16px 24px" }}
        >
          <Col>
            <Styled.ClickableElement
              role="button"
              onClick={() => setIsReceivedTabsOpen((isOpen) => !isOpen)}
            >
              <Title level={3}>
                <Styled.Chevron isOpen={isReceivedTabsOpen} />
                <b>Received Tabs</b>
              </Title>
            </Styled.ClickableElement>
          </Col>
        </Row>
        {isReceivedTabsOpen && !isLoadingTabs ? (
          <Row style={{ padding: "16px 24px" }}>
            <ReceivedTabsTable
              tabs={globalTempPersonalTabsModel}
              uncomittedDeletedTabs={uncommittedDeletedTabIds}
              handleOnUndoDelete={(id) => {
                setUncommittedDeletedTabIds((prev) =>
                  prev.filter((tabId) => tabId !== id),
                );
              }}
              handleOnAttemptDeleteId={handleOnAttemptDeleteId}
              onTabsChange={setGlobalTempPersonalTabsModel}
              filterTerm={searchTerm}
            />
          </Row>
        ) : null}
      </Styled.ScrollableContent>

      <FooterContainer>
        <Row justify="space-between">
          <Col span={24}>
            <Space direction="horizontal">
              <Button type="ghost" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={handleOnSave}
                loading={isLoadingTabs || isLoadingAccepting}
              >
                Save Changes
              </Button>
            </Space>
          </Col>
        </Row>
      </FooterContainer>
    </Modal>
  );
};
