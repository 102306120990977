import { SummaryBlock } from "components/Lenses/common/KpiSummaries/SummaryBlock";
import {
  LOADING_KPI_STRING,
  NO_DATA_KPI_STRING,
} from "components/Lenses/constants";
import { PDComponent } from "components/PDComponents";
import { Row, Tooltip } from "utils/componentLibrary";

export const PrimaryKpiSummary = ({
  title = "",
  description = "",
  main = "",
  secondary = "",
  detailed,
}: {
  main: string;
  title?: string;
  description?: string;
  secondary: string;
  detailed?: boolean;
}) => {
  const showInformationIconIfAvailable =
    description && main !== LOADING_KPI_STRING && main !== NO_DATA_KPI_STRING;

  return (
    <SummaryBlock
      title={title}
      main={
        <Row align="middle" style={{ gap: "10px" }}>
          {main}
          {showInformationIconIfAvailable ? (
            <Tooltip placement="right" title={description} arrowPointAtCenter>
              <PDComponent.SvgIcon name="information" width="16" height="16" />
            </Tooltip>
          ) : null}
        </Row>
      }
      secondary={secondary}
      titleLevel={detailed ? 1 : 2}
    />
  );
};
