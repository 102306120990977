import type {
  GeneratorStatsFactDto,
  GeneratorStatsSummaryDto,
  GeneratorStatsUserLensDto,
} from "apis/oag";
import { DimensionType, FuelType } from "apis/oag";
import { GeneratorSlotType } from "apis/oag/models/GeneratorSlotType";
import ChartLegend from "components/Lenses/common/ChartLegend";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { LegendPreviewerType } from "components/Lenses/common/ChartLegend/interfaces";
import {
  generatorColorsBySlotType,
  generatorLabelsBySlotType,
} from "components/Lenses/ContainerLens/common/utils/utils";
import * as Styled from "components/Lenses/ContainerLens/GeneratorStats/Chart/styles";
import KpiView from "components/Lenses/ContainerLens/GeneratorStats/components/KpiView";
import {
  detailedLegendColumns,
  TITLE_MAX_LOAD,
  TITLE_RUN_HOURS,
  TITLE_TOTAL_ENERGY,
} from "components/Lenses/ContainerLens/GeneratorStats/utils";
import { PDComponent } from "components/PDComponents";
import { useLensSize } from "hooks/lens/useLensSize";
import { useMemo } from "react";
import { useUOM } from "utils/format";

export default function GeneratorStatsChart({
  lens,
  data,
  summary,
}: {
  lens: GeneratorStatsUserLensDto;
  data: GeneratorStatsFactDto[];
  summary: GeneratorStatsSummaryDto;
}) {
  const lensGridSize = useLensSize(lens?.id);
  const uomWatts = useUOM(DimensionType.Watts);
  const uomKwh = useUOM(DimensionType.KiloWattHours);
  const uomSeconds = useUOM(DimensionType.Seconds);

  const generatorLegendItems = useMemo<LegendItem[]>(() => {
    return data.map((generator) => ({
      id: generator.generator,
      name: generatorLabelsBySlotType[
        generator.generator || GeneratorSlotType.None
      ],
      color: generatorColorsBySlotType[generator.generator],
      isEnabled: true,
      previewerType: LegendPreviewerType.BOX,
      columnValues: {
        Model: generator.generatorModel || "",
        Rating: uomWatts.display(generator.rating, { fractionDigits: 0 }),
        Fuel: (() => {
          if (generator.fuelType === FuelType.Diesel)
            return <PDComponent.SvgIcon name="diesel" />;
          if (generator.fuelType === FuelType.NaturalGas)
            return <PDComponent.SvgIcon name="naturalGas" />;
          if (generator.fuelType === FuelType.BiFuel)
            return (
              <>
                <PDComponent.SvgIcon name="diesel" />
                <PDComponent.SvgIcon name="naturalGas" />
              </>
            );
          return <></>;
        })(),
      },
    }));
  }, [data, uomWatts]);

  return (
    <Styled.GeneratorStatsContainer $withSpace={lensGridSize[1] > 4}>
      <Styled.SummaryContainer>
        <Styled.Row>
          <KpiView
            title={TITLE_TOTAL_ENERGY}
            value={uomKwh.display(summary.totalEnergyInKWh, { unit: "" })}
          />
        </Styled.Row>
        <Styled.Row>
          <KpiView
            title={TITLE_RUN_HOURS}
            value={uomSeconds.display(summary.totalRunHours, {
              factor: 1 / 60,
              unit: "",
            })}
          />
          <KpiView
            title={TITLE_MAX_LOAD}
            value={uomWatts.display(summary.maxLoad, { unit: "" })}
          />
        </Styled.Row>
      </Styled.SummaryContainer>
      {lens.selectedGenerators.length === 1 ? (
        <Styled.GeneratorImage>
          <img
            src="/assets/images/generator-illustration.png"
            alt="Generator Model Illustration"
          />
        </Styled.GeneratorImage>
      ) : null}

      <Styled.LegendContainer>
        <ChartLegend
          lensGridSize={lensGridSize}
          legendItems={generatorLegendItems}
          isDetailed={true}
          clickable={false}
          detailedColumns={detailedLegendColumns}
          customStyle={{ padding: "4px 8px" }}
        />
      </Styled.LegendContainer>
    </Styled.GeneratorStatsContainer>
  );
}
