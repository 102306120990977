import { useQueryClient } from "@tanstack/react-query";
import { CustomInput } from "components/Clara/CustomInput/CustomInput";
import { Header } from "components/Clara/Header";
import { LastMessage } from "components/Clara/LastMessage";
import { ChatHistory } from "components/Clara/MessageThread/ChatHistory";
import { AutoScrollEnabledProvider } from "components/Clara/MessageThread/utils/AutoScrollEnabledContext";
import { PlusIcon } from "components/Clara/PlusIcon";
import { QuestionList } from "components/Clara/QuestionList/QuestionList";
import * as Styled from "components/Clara/style";
import { useConfirmModal } from "components/ConfirmModal";
import { PDComponent } from "components/PDComponents";
import {
  useClaraConversationHistory,
  useCreateClaraSession,
} from "hooks/clara/useClara";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";
const SCROLL_OFFSET = 5;
const AUTO_SCROLL_OFFSET = 80;

export const ModalBody = ({
  onClose,
  onMinimize,
}: {
  onClose: () => void;
  onMinimize: () => void;
}) => {
  const isChatThreadMode = useAppSelector(
    (state) => state.claraReducer.isChatThreadMode,
  );
  const pendingMessage = useAppSelector(
    (state) => state.claraReducer.pendingMessage,
  );
  const queryClient = useQueryClient();
  const { data: claraSession } = useCreateClaraSession();
  const dispatch = useAppDispatch();

  const { confirmModalElement, openConfirmModal } = useConfirmModal({
    title: "Start a new chat?",
    description:
      "Are you sure you want to leave? Your current conversation will not be saved.",
    onConfirm: () => {
      dispatch({ type: "CLARA_SET_CHAT_THREAD_MODE", payload: false });
      queryClient.refetchQueries({
        queryKey: [
          { uid: RequestUID.clara, type: PDQueryType.CLARA_CREATE_SESSION },
        ],
        exact: false,
      });

      queryClient.setQueryData(
        [
          {
            uid: RequestUID.clara,
            type: PDQueryType.CLARA_CONVERSATION_HISTORY,
          },
        ],
        () => [],
      );
    },
  });

  const setCurrentMessage = useCallback(
    (message: string) => {
      dispatch({ type: "SET_PENDING_MESSAGE", payload: { message, id: null } });
    },
    [dispatch],
  );

  const [showScrollHintTop, setShowScrollHintTop] = useState(false);
  const [showScrollHintBottom, setShowScrollHintBottom] = useState(false);
  const conversationHistory = useClaraConversationHistory();

  const containerRef = useRef<HTMLDivElement>(null);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

  const handleOnScroll = useCallback(() => {
    if (containerRef.current) {
      const isScrolledToBottom =
        containerRef.current.scrollHeight -
          containerRef.current.scrollTop -
          containerRef.current.clientHeight <
        AUTO_SCROLL_OFFSET;

      if (isScrolledToBottom && !autoScrollEnabled) {
        setAutoScrollEnabled(true);
      }

      if (!isScrolledToBottom && autoScrollEnabled) {
        setAutoScrollEnabled(false);
      }

      setShowScrollHintTop(containerRef.current.scrollTop > SCROLL_OFFSET);
      setShowScrollHintBottom(!isScrolledToBottom);
    }
  }, [autoScrollEnabled]);

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    // Scroll to bottom every time a new message is added, via dependencies
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversationHistory.data, pendingMessage]);

  const { isDark } = useCustomTheme();

  return (
    <Styled.Grid>
      <div id="sideMenu">
        {isDark ? (
          <PDComponent.SvgIcon
            name="claraDark"
            onClick={isChatThreadMode ? openConfirmModal : () => {}}
          />
        ) : (
          <PDComponent.SvgIcon
            name="claraLight"
            onClick={isChatThreadMode ? openConfirmModal : () => {}}
          />
        )}
        <PlusIcon onClick={openConfirmModal} />
      </div>
      <Header onClose={onClose} onMinimize={onMinimize} />

      {isChatThreadMode ? (
        <Styled.RightSideContainer
          ref={containerRef}
          $showScrollHintTop={showScrollHintTop}
          $showScrollHintBottom={showScrollHintBottom}
          onScroll={handleOnScroll}
        >
          <AutoScrollEnabledProvider
            autoScrollEnabled={autoScrollEnabled}
            setAutoScrollEnabled={setAutoScrollEnabled}
          >
            <ChatHistory sessionId={claraSession.sessionId} />
            <LastMessage sessionId={claraSession.sessionId} />
          </AutoScrollEnabledProvider>
          <div id="Sfadfar" ref={ref} />
        </Styled.RightSideContainer>
      ) : (
        <QuestionList />
      )}
      <CustomInput setCurrentMessage={setCurrentMessage} />
      {confirmModalElement}
    </Styled.Grid>
  );
};
