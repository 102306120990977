import { useCountries } from "hooks/drillingInvariants/useCountries";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { useFiltersLoading } from "hooks/filters/useFiltersLoading";
import { indexOf } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { NotificationRigFilters } from "reducers/notificationsReducer";
import type { IFilterOptionValue } from "reducers/rigsCommonReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { RigTechnology } from "utils/enums";

export type FilterTypes = keyof NotificationRigFilters;
const sortedTypes = ["Super Single", "Tele Double", "Super Triple"];

export const useNotificationRigFilterCategories = () => {
  const { data: countries, isLoading: isCountriesLoading } = useCountries();
  const { filtersLoading } = useFiltersLoading();
  const { data: rigsData, isLoading: isRigsLoading } = useRigs();
  const rigs = useMemo(() => rigsData?.list ?? [], [rigsData]);
  const dispatch = useAppDispatch();
  const filterState = useAppSelector((state) => state.notifications.filters);
  const [localFilters, setLocalFilters] =
    useState<NotificationRigFilters | null>(filterState);
  const areFiltersReadyForInitialSelfSet =
    localFilters === null &&
    !isCountriesLoading &&
    !isRigsLoading &&
    !filtersLoading;

  useEffect(() => {
    setLocalFilters(filterState);
  }, [filterState]);

  const idToCountry = useCallback(
    (id: number) => {
      const country = countries?.find((c) => c.id === id);
      return country ? country.name || "" : id.toString();
    },
    [countries],
  );

  const allFilters: Record<
    FilterTypes,
    {
      categoryLabel: string;
      options: IFilterOptionValue[];
    }
  > = useMemo(
    () => ({
      rigType: {
        categoryLabel: "Rig Type",
        options: [...new Set(rigs.map((rig) => rig._class))].map((type) => ({
          value: type,
          id: type,
        })),
      },
      horsePower: {
        categoryLabel: "Horsepower Rating",
        options: [...new Set(rigs.map((rig) => rig.horsePower.toString()))].map(
          (val) => ({
            value: `${val} hp`,
            id: val,
          }),
        ),
      },
      country: {
        categoryLabel: "Country",
        options: [...new Set(rigs.map((rig) => rig.countryId.toString()))].map(
          (country) => ({
            value: idToCountry(+country),
            id: country,
          }),
        ),
      },
      operatingCenter: {
        categoryLabel: "Operating Centers",
        options: [...new Set(rigs.map((rig) => rig.operatingCenter))].map(
          (center) => ({ value: center, id: center }),
        ),
      },
      technology: {
        categoryLabel: "Technology",
        options: [RigTechnology.Alpha, RigTechnology.NonAlpha].map((tech) => ({
          value: tech,
          id: tech,
        })),
      },
    }),
    [idToCountry, rigs],
  );
  const initialFilters: NotificationRigFilters = useMemo(
    () => ({
      rigType: allFilters.rigType.options.sort(
        (a, b) => indexOf(sortedTypes, a.value) - indexOf(sortedTypes, b.value),
      ),
      horsePower: allFilters.horsePower.options.sort((a, b) =>
        a.id && b.id ? +b.id - +a.id : 0,
      ),
      country: allFilters.country.options.sort((a, b) =>
        (a.value || 0) < (b.value || 0) ? -1 : 1,
      ),
      operatingCenter: allFilters.operatingCenter.options.sort((a, b) =>
        (a.value || 0) < (b.value || 0) ? -1 : 1,
      ),
      technology: allFilters.technology.options,
    }),
    [allFilters],
  );

  const toggleFilter = (filterType: FilterTypes, optionId: string | number) => {
    setLocalFilters((prev) => {
      const newFilters: NotificationRigFilters = prev
        ? { ...prev }
        : {
            rigType: [],
            horsePower: [],
            country: [],
            operatingCenter: [],
            technology: [],
          };
      if (
        newFilters[filterType]
          ?.map((allOptions) => allOptions.id)
          .includes(optionId)
      ) {
        newFilters[filterType] = newFilters[filterType]?.filter(
          (item) => item.id !== optionId,
        );
      } else {
        const filterItem = allFilters[filterType]?.options?.find(
          (option) => option.id === optionId,
        );
        if (filterItem) {
          newFilters[filterType]?.push(filterItem);
        }
      }
      return newFilters;
    });
  };

  useEffect(() => {
    if (areFiltersReadyForInitialSelfSet) {
      setLocalFilters(initialFilters);
      dispatch({ type: "NOTIFICATIONS_SET_FILTERS", payload: initialFilters });
    }
  }, [
    localFilters,
    initialFilters,
    dispatch,
    areFiltersReadyForInitialSelfSet,
  ]);

  const resetFilters = useCallback(() => {
    dispatch({ type: "NOTIFICATIONS_SET_FILTERS", payload: initialFilters });
  }, [dispatch, initialFilters]);

  return {
    initialFilters,
    allFilters,
    localFilters,
    setLocalFilters,
    idToCountry,
    toggleFilter,
    resetFilters,
  };
};
