/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScoreSummaryRootDto } from './ScoreSummaryRootDto';
import {
    ScoreSummaryRootDtoFromJSON,
    ScoreSummaryRootDtoFromJSONTyped,
    ScoreSummaryRootDtoToJSON,
} from './ScoreSummaryRootDto';
import type { ScoreComplianceStateType } from './ScoreComplianceStateType';
import {
    ScoreComplianceStateTypeFromJSON,
    ScoreComplianceStateTypeFromJSONTyped,
    ScoreComplianceStateTypeToJSON,
} from './ScoreComplianceStateType';
import type { WellBarDto } from './WellBarDto';
import {
    WellBarDtoFromJSON,
    WellBarDtoFromJSONTyped,
    WellBarDtoToJSON,
} from './WellBarDto';

/**
 * 
 * @export
 * @interface RigLeaderboardDto
 */
export interface RigLeaderboardDto {
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    rigId: number;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    lastOperatorId: number;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    operatorScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    rigScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    operatorTrend?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    rigTrend?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    targetDeltaTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    invisibleLostTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    savedTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    totalDowntime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    totalExceptionCount: number;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    totalExceptionTime: number;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    approvedExceptionCount: number;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    approvedExceptionTime: number;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof RigLeaderboardDto
     */
    operatorScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof RigLeaderboardDto
     */
    rigScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof RigLeaderboardDto
     */
    improvementScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    operatorScoreComplianceRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    rigScoreComplianceRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardDto
     */
    improvementScoreComplianceRate?: number | null;
    /**
     * 
     * @type {ScoreSummaryRootDto}
     * @memberof RigLeaderboardDto
     */
    scoreSummary: ScoreSummaryRootDto;
    /**
     * 
     * @type {Array<WellBarDto>}
     * @memberof RigLeaderboardDto
     */
    wellBars: Array<WellBarDto>;
}



/**
 * Check if a given object implements the RigLeaderboardDto interface.
 */
export function instanceOfRigLeaderboardDto(value: object): value is RigLeaderboardDto {
    if (!('rigId' in value) || value['rigId'] === undefined) return false;
    if (!('lastOperatorId' in value) || value['lastOperatorId'] === undefined) return false;
    if (!('totalExceptionCount' in value) || value['totalExceptionCount'] === undefined) return false;
    if (!('totalExceptionTime' in value) || value['totalExceptionTime'] === undefined) return false;
    if (!('approvedExceptionCount' in value) || value['approvedExceptionCount'] === undefined) return false;
    if (!('approvedExceptionTime' in value) || value['approvedExceptionTime'] === undefined) return false;
    if (!('operatorScoreComplianceState' in value) || value['operatorScoreComplianceState'] === undefined) return false;
    if (!('rigScoreComplianceState' in value) || value['rigScoreComplianceState'] === undefined) return false;
    if (!('improvementScoreComplianceState' in value) || value['improvementScoreComplianceState'] === undefined) return false;
    if (!('scoreSummary' in value) || value['scoreSummary'] === undefined) return false;
    if (!('wellBars' in value) || value['wellBars'] === undefined) return false;
    return true;
}

export function RigLeaderboardDtoFromJSON(json: any): RigLeaderboardDto {
    return RigLeaderboardDtoFromJSONTyped(json, false);
}

export function RigLeaderboardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardDto {
    if (json == null) {
        return json;
    }
    return {
        
        'rigId': json['RigId'],
        'lastOperatorId': json['LastOperatorId'],
        'operatorScore': json['OperatorScore'] == null ? undefined : json['OperatorScore'],
        'rigScore': json['RigScore'] == null ? undefined : json['RigScore'],
        'operatorTrend': json['OperatorTrend'] == null ? undefined : json['OperatorTrend'],
        'rigTrend': json['RigTrend'] == null ? undefined : json['RigTrend'],
        'targetDeltaTime': json['TargetDeltaTime'] == null ? undefined : json['TargetDeltaTime'],
        'invisibleLostTime': json['InvisibleLostTime'] == null ? undefined : json['InvisibleLostTime'],
        'savedTime': json['SavedTime'] == null ? undefined : json['SavedTime'],
        'totalDowntime': json['TotalDowntime'] == null ? undefined : json['TotalDowntime'],
        'totalExceptionCount': json['TotalExceptionCount'],
        'totalExceptionTime': json['TotalExceptionTime'],
        'approvedExceptionCount': json['ApprovedExceptionCount'],
        'approvedExceptionTime': json['ApprovedExceptionTime'],
        'operatorScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['OperatorScoreComplianceState']),
        'rigScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['RigScoreComplianceState']),
        'improvementScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['ImprovementScoreComplianceState']),
        'operatorScoreComplianceRate': json['OperatorScoreComplianceRate'] == null ? undefined : json['OperatorScoreComplianceRate'],
        'rigScoreComplianceRate': json['RigScoreComplianceRate'] == null ? undefined : json['RigScoreComplianceRate'],
        'improvementScoreComplianceRate': json['ImprovementScoreComplianceRate'] == null ? undefined : json['ImprovementScoreComplianceRate'],
        'scoreSummary': ScoreSummaryRootDtoFromJSON(json['ScoreSummary']),
        'wellBars': ((json['WellBars'] as Array<any>).map(WellBarDtoFromJSON)),
    };
}

export function RigLeaderboardDtoToJSON(value?: RigLeaderboardDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'RigId': value['rigId'],
        'LastOperatorId': value['lastOperatorId'],
        'OperatorScore': value['operatorScore'],
        'RigScore': value['rigScore'],
        'OperatorTrend': value['operatorTrend'],
        'RigTrend': value['rigTrend'],
        'TargetDeltaTime': value['targetDeltaTime'],
        'InvisibleLostTime': value['invisibleLostTime'],
        'SavedTime': value['savedTime'],
        'TotalDowntime': value['totalDowntime'],
        'TotalExceptionCount': value['totalExceptionCount'],
        'TotalExceptionTime': value['totalExceptionTime'],
        'ApprovedExceptionCount': value['approvedExceptionCount'],
        'ApprovedExceptionTime': value['approvedExceptionTime'],
        'OperatorScoreComplianceState': ScoreComplianceStateTypeToJSON(value['operatorScoreComplianceState']),
        'RigScoreComplianceState': ScoreComplianceStateTypeToJSON(value['rigScoreComplianceState']),
        'ImprovementScoreComplianceState': ScoreComplianceStateTypeToJSON(value['improvementScoreComplianceState']),
        'OperatorScoreComplianceRate': value['operatorScoreComplianceRate'],
        'RigScoreComplianceRate': value['rigScoreComplianceRate'],
        'ImprovementScoreComplianceRate': value['improvementScoreComplianceRate'],
        'ScoreSummary': ScoreSummaryRootDtoToJSON(value['scoreSummary']),
        'WellBars': ((value['wellBars'] as Array<any>).map(WellBarDtoToJSON)),
    };
}

