import { LensTabStateType, type LensTabWithVisibilityDto } from "apis/oag";
import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import * as Styled from "pages/WellDashboard/ManageTabs/Tables/style";
import { useFilterTabsByText } from "pages/WellDashboard/ManageTabs/Tables/useFilterTabsByText";
import { RigSpecificityDropdown } from "pages/WellDashboard/RigSpecificityDropdown/RigSpecificityDropdown";
import colors from "utils/colors";
import { formatDate } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

export const CompanyTabsTable = ({
  tabs = [],
  filterTerm,
}: {
  tabs: LensTabWithVisibilityDto[];
  filterTerm: string;
}) => {
  const { themeStyle } = useCustomTheme();

  const visibleTabs = useFilterTabsByText(tabs, filterTerm);

  return visibleTabs?.length ? (
    <Styled.LowOpacityChildren>
      <Styled.GridLayoutRow>
        <Styled.HeaderCol>Configuration</Styled.HeaderCol>
        <Styled.HeaderCol>Tab Name</Styled.HeaderCol>
        <Styled.HeaderCol>Shared by</Styled.HeaderCol>
        <Styled.HeaderCol>Date Shared</Styled.HeaderCol>
        <Styled.HeaderCol $isCentered>Visibility</Styled.HeaderCol>
        <Styled.HeaderCol></Styled.HeaderCol>
      </Styled.GridLayoutRow>

      {visibleTabs.map((tab) => {
        return (
          <Styled.GridLayoutRow key={tab.id} $bordered>
            <Styled.CustomCol $isSpaceBetween $paddingLeft={10}>
              <PDComponent.SvgIcon name="drag" width="8px" height="12px" />

              {tab?.isLocked ? (
                <Styled.IconShell
                  $bgColor={colors.alpine_landing}
                  $color={themeStyle.colors.white_black}
                  $disabled
                >
                  <PDComponent.SvgIcon name="locked" style={{ fontSize: 10 }} />
                </Styled.IconShell>
              ) : (
                <Styled.IconShell
                  $bgColor={themeStyle.colors.primary_accent}
                  $color={themeStyle.colors.black_white}
                  $bordered
                  $disabled
                >
                  <PDComponent.SvgIcon
                    name="unlocked"
                    style={{ fontSize: 10 }}
                  />
                </Styled.IconShell>
              )}

              <RigSpecificityDropdown
                selectedRigs={tab?.rigIds ?? []}
                placement="bottomLeft"
                isReadOnly
              >
                <Styled.IconShell
                  $bgColor={
                    tab?.rigIds?.length
                      ? colors.well_color
                      : themeStyle.colors.primary_accent
                  }
                  $color={
                    tab?.rigIds?.length
                      ? themeStyle.colors.white_black
                      : themeStyle.colors.black_white
                  }
                  $bordered={!tab?.rigIds?.length}
                  role="button"
                >
                  <PDComponent.SvgIcon name="rig" style={{ fontSize: 10 }} />
                </Styled.IconShell>
              </RigSpecificityDropdown>
            </Styled.CustomCol>

            <Styled.CustomCol>
              <Styled.CustomInput value={tab.name} disabled />
            </Styled.CustomCol>

            <Styled.CustomCol />
            <Styled.CustomCol>
              {tab?.sharedAtUtc ? formatDate(tab?.sharedAtUtc) : null}
            </Styled.CustomCol>

            <Styled.CustomCol $isCentered>
              <Styled.StyledSwitch
                size="small"
                checked={tab?.state === LensTabStateType.Visible}
                disabled
              />
            </Styled.CustomCol>

            <Styled.CustomCol $isCentered>
              <PDComponent.SvgIcon name="trashCan" />
            </Styled.CustomCol>
          </Styled.GridLayoutRow>
        );
      })}
    </Styled.LowOpacityChildren>
  ) : (
    <em>
      <Title level={4}>No items to display</Title>
    </em>
  );
};
