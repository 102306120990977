import {
  baseMatrix,
  MatrixAvailability,
} from "components/Lenses/ContainerLens/common/utils/constants";
import type { AbilityMatrixType } from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";

export const getWellDrillingSummaryKpiAbilityMatrix = (): AbilityMatrixType => {
  return {
    ...baseMatrix,
    lensSettings: MatrixAvailability.NOT_SUPPORTED,
    hasDetailedView: MatrixAvailability.NOT_SUPPORTED,
  };
};
