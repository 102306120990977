import * as Styled from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/DetailedViewTrackAdder/style";

export const DetailedViewTrackAdder = ({
  openTrackAddModal,
}: {
  openTrackAddModal: () => void;
}) => {
  return (
    <Styled.AddButton
      trackingName="Open Add Track"
      onClick={openTrackAddModal}
      type="link"
    >
      <Styled.AddTrackIcon name="addFilled" />
      Add track
    </Styled.AddButton>
  );
};
