/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BatteryStateUserLensDto } from './BatteryStateUserLensDto';
import {
    BatteryStateUserLensDtoFromJSON,
    BatteryStateUserLensDtoFromJSONTyped,
    BatteryStateUserLensDtoToJSON,
} from './BatteryStateUserLensDto';
import type { IntelScorecardUserLensDto } from './IntelScorecardUserLensDto';
import {
    IntelScorecardUserLensDtoFromJSON,
    IntelScorecardUserLensDtoFromJSONTyped,
    IntelScorecardUserLensDtoToJSON,
} from './IntelScorecardUserLensDto';
import type { RigFleetPerformanceCardUserLensDto } from './RigFleetPerformanceCardUserLensDto';
import {
    RigFleetPerformanceCardUserLensDtoFromJSON,
    RigFleetPerformanceCardUserLensDtoFromJSONTyped,
    RigFleetPerformanceCardUserLensDtoToJSON,
} from './RigFleetPerformanceCardUserLensDto';
import type { RigPowerUserLensDto } from './RigPowerUserLensDto';
import {
    RigPowerUserLensDtoFromJSON,
    RigPowerUserLensDtoFromJSONTyped,
    RigPowerUserLensDtoToJSON,
} from './RigPowerUserLensDto';
import type { TorqueAndDragUserLensDto } from './TorqueAndDragUserLensDto';
import {
    TorqueAndDragUserLensDtoFromJSON,
    TorqueAndDragUserLensDtoFromJSONTyped,
    TorqueAndDragUserLensDtoToJSON,
} from './TorqueAndDragUserLensDto';
import type { PowerLoadEfficiencyUserLensDto } from './PowerLoadEfficiencyUserLensDto';
import {
    PowerLoadEfficiencyUserLensDtoFromJSON,
    PowerLoadEfficiencyUserLensDtoFromJSONTyped,
    PowerLoadEfficiencyUserLensDtoToJSON,
} from './PowerLoadEfficiencyUserLensDto';
import type { DrillingProductivityUserLensDto } from './DrillingProductivityUserLensDto';
import {
    DrillingProductivityUserLensDtoFromJSON,
    DrillingProductivityUserLensDtoFromJSONTyped,
    DrillingProductivityUserLensDtoToJSON,
} from './DrillingProductivityUserLensDto';
import type { GhgEmissionsUserLensDto } from './GhgEmissionsUserLensDto';
import {
    GhgEmissionsUserLensDtoFromJSON,
    GhgEmissionsUserLensDtoFromJSONTyped,
    GhgEmissionsUserLensDtoToJSON,
} from './GhgEmissionsUserLensDto';
import type { PowerConsumptionByOperationUserLensDto } from './PowerConsumptionByOperationUserLensDto';
import {
    PowerConsumptionByOperationUserLensDtoFromJSON,
    PowerConsumptionByOperationUserLensDtoFromJSONTyped,
    PowerConsumptionByOperationUserLensDtoToJSON,
} from './PowerConsumptionByOperationUserLensDto';
import type { DieselNatGasRatioStatsUserLensDto } from './DieselNatGasRatioStatsUserLensDto';
import {
    DieselNatGasRatioStatsUserLensDtoFromJSON,
    DieselNatGasRatioStatsUserLensDtoFromJSONTyped,
    DieselNatGasRatioStatsUserLensDtoToJSON,
} from './DieselNatGasRatioStatsUserLensDto';
import type { GeneratorStatusBarUserLensDto } from './GeneratorStatusBarUserLensDto';
import {
    GeneratorStatusBarUserLensDtoFromJSON,
    GeneratorStatusBarUserLensDtoFromJSONTyped,
    GeneratorStatusBarUserLensDtoToJSON,
} from './GeneratorStatusBarUserLensDto';
import type { PivotKpiGroupUserLensDto } from './PivotKpiGroupUserLensDto';
import {
    PivotKpiGroupUserLensDtoFromJSON,
    PivotKpiGroupUserLensDtoFromJSONTyped,
    PivotKpiGroupUserLensDtoToJSON,
} from './PivotKpiGroupUserLensDto';
import type { PivotKpiTypeUserLensDto } from './PivotKpiTypeUserLensDto';
import {
    PivotKpiTypeUserLensDtoFromJSON,
    PivotKpiTypeUserLensDtoFromJSONTyped,
    PivotKpiTypeUserLensDtoToJSON,
} from './PivotKpiTypeUserLensDto';
import type { StatCardUserLensDto } from './StatCardUserLensDto';
import {
    StatCardUserLensDtoFromJSON,
    StatCardUserLensDtoFromJSONTyped,
    StatCardUserLensDtoToJSON,
} from './StatCardUserLensDto';
import type { KpiGroupUserLensDto } from './KpiGroupUserLensDto';
import {
    KpiGroupUserLensDtoFromJSON,
    KpiGroupUserLensDtoFromJSONTyped,
    KpiGroupUserLensDtoToJSON,
} from './KpiGroupUserLensDto';
import type { FuelTankVolumeUserLensDto } from './FuelTankVolumeUserLensDto';
import {
    FuelTankVolumeUserLensDtoFromJSON,
    FuelTankVolumeUserLensDtoFromJSONTyped,
    FuelTankVolumeUserLensDtoToJSON,
} from './FuelTankVolumeUserLensDto';
import type { IntelRankingRibbonUserLensDto } from './IntelRankingRibbonUserLensDto';
import {
    IntelRankingRibbonUserLensDtoFromJSON,
    IntelRankingRibbonUserLensDtoFromJSONTyped,
    IntelRankingRibbonUserLensDtoToJSON,
} from './IntelRankingRibbonUserLensDto';
import type { GeneratorStatsUserLensDto } from './GeneratorStatsUserLensDto';
import {
    GeneratorStatsUserLensDtoFromJSON,
    GeneratorStatsUserLensDtoFromJSONTyped,
    GeneratorStatsUserLensDtoToJSON,
} from './GeneratorStatsUserLensDto';
import type { WellDrillingSummaryUserLensDto } from './WellDrillingSummaryUserLensDto';
import {
    WellDrillingSummaryUserLensDtoFromJSON,
    WellDrillingSummaryUserLensDtoFromJSONTyped,
    WellDrillingSummaryUserLensDtoToJSON,
} from './WellDrillingSummaryUserLensDto';
import type { RigScorecardUserLensDto } from './RigScorecardUserLensDto';
import {
    RigScorecardUserLensDtoFromJSON,
    RigScorecardUserLensDtoFromJSONTyped,
    RigScorecardUserLensDtoToJSON,
} from './RigScorecardUserLensDto';
import type { TagBottomFingerprintUserLensDto } from './TagBottomFingerprintUserLensDto';
import {
    TagBottomFingerprintUserLensDtoFromJSON,
    TagBottomFingerprintUserLensDtoFromJSONTyped,
    TagBottomFingerprintUserLensDtoToJSON,
} from './TagBottomFingerprintUserLensDto';
import type { FuelFlowRateUserLensDto } from './FuelFlowRateUserLensDto';
import {
    FuelFlowRateUserLensDtoFromJSON,
    FuelFlowRateUserLensDtoFromJSONTyped,
    FuelFlowRateUserLensDtoToJSON,
} from './FuelFlowRateUserLensDto';
import type { PowerLoadDistributionUserLensDto } from './PowerLoadDistributionUserLensDto';
import {
    PowerLoadDistributionUserLensDtoFromJSON,
    PowerLoadDistributionUserLensDtoFromJSONTyped,
    PowerLoadDistributionUserLensDtoToJSON,
} from './PowerLoadDistributionUserLensDto';
import type { IntelScatterPlotUserLensDto } from './IntelScatterPlotUserLensDto';
import {
    IntelScatterPlotUserLensDtoFromJSON,
    IntelScatterPlotUserLensDtoFromJSONTyped,
    IntelScatterPlotUserLensDtoToJSON,
} from './IntelScatterPlotUserLensDto';
import type { KpiTypeUserLensDto } from './KpiTypeUserLensDto';
import {
    KpiTypeUserLensDtoFromJSON,
    KpiTypeUserLensDtoFromJSONTyped,
    KpiTypeUserLensDtoToJSON,
} from './KpiTypeUserLensDto';
import type { ParameterHeatmapUserLensDto } from './ParameterHeatmapUserLensDto';
import {
    ParameterHeatmapUserLensDtoFromJSON,
    ParameterHeatmapUserLensDtoFromJSONTyped,
    ParameterHeatmapUserLensDtoToJSON,
} from './ParameterHeatmapUserLensDto';
import type { FuelConsumptionByGeneratorUserLensDto } from './FuelConsumptionByGeneratorUserLensDto';
import {
    FuelConsumptionByGeneratorUserLensDtoFromJSON,
    FuelConsumptionByGeneratorUserLensDtoFromJSONTyped,
    FuelConsumptionByGeneratorUserLensDtoToJSON,
} from './FuelConsumptionByGeneratorUserLensDto';
import type { ParameterByDepthUserLensDto } from './ParameterByDepthUserLensDto';
import {
    ParameterByDepthUserLensDtoFromJSON,
    ParameterByDepthUserLensDtoFromJSONTyped,
    ParameterByDepthUserLensDtoToJSON,
} from './ParameterByDepthUserLensDto';
import type { NaturalGasStateUserLensDto } from './NaturalGasStateUserLensDto';
import {
    NaturalGasStateUserLensDtoFromJSON,
    NaturalGasStateUserLensDtoFromJSONTyped,
    NaturalGasStateUserLensDtoToJSON,
} from './NaturalGasStateUserLensDto';
import type { GeneratorLoadProfileUserLensDto } from './GeneratorLoadProfileUserLensDto';
import {
    GeneratorLoadProfileUserLensDtoFromJSON,
    GeneratorLoadProfileUserLensDtoFromJSONTyped,
    GeneratorLoadProfileUserLensDtoToJSON,
} from './GeneratorLoadProfileUserLensDto';
import type { StickSlipByDepthUserLensDto } from './StickSlipByDepthUserLensDto';
import {
    StickSlipByDepthUserLensDtoFromJSON,
    StickSlipByDepthUserLensDtoFromJSONTyped,
    StickSlipByDepthUserLensDtoToJSON,
} from './StickSlipByDepthUserLensDto';
import type { StickSlipByTimeUserLensDto } from './StickSlipByTimeUserLensDto';
import {
    StickSlipByTimeUserLensDtoFromJSON,
    StickSlipByTimeUserLensDtoFromJSONTyped,
    StickSlipByTimeUserLensDtoToJSON,
} from './StickSlipByTimeUserLensDto';
import type { WedgeUserLensDto } from './WedgeUserLensDto';
import {
    WedgeUserLensDtoFromJSON,
    WedgeUserLensDtoFromJSONTyped,
    WedgeUserLensDtoToJSON,
} from './WedgeUserLensDto';

/**
 * 
 * @export
 * @interface UserLensSetDto
 */
export interface UserLensSetDto {
    /**
     * 
     * @type {Array<KpiTypeUserLensDto>}
     * @memberof UserLensSetDto
     */
    kpiTypeUserLenses: Array<KpiTypeUserLensDto>;
    /**
     * 
     * @type {Array<KpiGroupUserLensDto>}
     * @memberof UserLensSetDto
     */
    kpiGroupUserLenses: Array<KpiGroupUserLensDto>;
    /**
     * 
     * @type {Array<DrillingProductivityUserLensDto>}
     * @memberof UserLensSetDto
     */
    drillingProductivityUserLenses: Array<DrillingProductivityUserLensDto>;
    /**
     * 
     * @type {Array<WedgeUserLensDto>}
     * @memberof UserLensSetDto
     */
    wedgeUserLenses: Array<WedgeUserLensDto>;
    /**
     * 
     * @type {Array<ParameterByDepthUserLensDto>}
     * @memberof UserLensSetDto
     */
    parameterByDepthUserLenses: Array<ParameterByDepthUserLensDto>;
    /**
     * 
     * @type {Array<ParameterHeatmapUserLensDto>}
     * @memberof UserLensSetDto
     */
    parameterHeatmapUserLenses: Array<ParameterHeatmapUserLensDto>;
    /**
     * 
     * @type {Array<WellDrillingSummaryUserLensDto>}
     * @memberof UserLensSetDto
     */
    wellDrillingSummaryUserLenses: Array<WellDrillingSummaryUserLensDto>;
    /**
     * 
     * @type {Array<TorqueAndDragUserLensDto>}
     * @memberof UserLensSetDto
     */
    torqueAndDragUserLenses: Array<TorqueAndDragUserLensDto>;
    /**
     * 
     * @type {Array<PivotKpiTypeUserLensDto>}
     * @memberof UserLensSetDto
     */
    pivotKpiTypeUserLenses: Array<PivotKpiTypeUserLensDto>;
    /**
     * 
     * @type {Array<PivotKpiGroupUserLensDto>}
     * @memberof UserLensSetDto
     */
    pivotKpiGroupUserLenses: Array<PivotKpiGroupUserLensDto>;
    /**
     * 
     * @type {Array<BatteryStateUserLensDto>}
     * @memberof UserLensSetDto
     */
    batteryStateUserLenses: Array<BatteryStateUserLensDto>;
    /**
     * 
     * @type {Array<RigPowerUserLensDto>}
     * @memberof UserLensSetDto
     */
    rigPowerUserLenses: Array<RigPowerUserLensDto>;
    /**
     * 
     * @type {Array<PowerConsumptionByOperationUserLensDto>}
     * @memberof UserLensSetDto
     */
    powerConsumptionByOperationUserLenses: Array<PowerConsumptionByOperationUserLensDto>;
    /**
     * 
     * @type {Array<StatCardUserLensDto>}
     * @memberof UserLensSetDto
     */
    statCardUserLenses: Array<StatCardUserLensDto>;
    /**
     * 
     * @type {Array<NaturalGasStateUserLensDto>}
     * @memberof UserLensSetDto
     */
    naturalGasStateUserLenses: Array<NaturalGasStateUserLensDto>;
    /**
     * 
     * @type {Array<GeneratorStatusBarUserLensDto>}
     * @memberof UserLensSetDto
     */
    generatorStatusBarUserLenses: Array<GeneratorStatusBarUserLensDto>;
    /**
     * 
     * @type {Array<FuelFlowRateUserLensDto>}
     * @memberof UserLensSetDto
     */
    fuelFlowRateUserLenses: Array<FuelFlowRateUserLensDto>;
    /**
     * 
     * @type {Array<GeneratorLoadProfileUserLensDto>}
     * @memberof UserLensSetDto
     */
    generatorLoadProfileUserLenses: Array<GeneratorLoadProfileUserLensDto>;
    /**
     * 
     * @type {Array<PowerLoadDistributionUserLensDto>}
     * @memberof UserLensSetDto
     */
    powerLoadDistributionUserLenses: Array<PowerLoadDistributionUserLensDto>;
    /**
     * 
     * @type {Array<GeneratorStatsUserLensDto>}
     * @memberof UserLensSetDto
     */
    generatorStatsUserLenses: Array<GeneratorStatsUserLensDto>;
    /**
     * 
     * @type {Array<FuelTankVolumeUserLensDto>}
     * @memberof UserLensSetDto
     */
    fuelTankVolumeUserLenses: Array<FuelTankVolumeUserLensDto>;
    /**
     * 
     * @type {Array<GhgEmissionsUserLensDto>}
     * @memberof UserLensSetDto
     */
    ghgEmissionsUserLenses: Array<GhgEmissionsUserLensDto>;
    /**
     * 
     * @type {Array<PowerLoadEfficiencyUserLensDto>}
     * @memberof UserLensSetDto
     */
    powerLoadEfficiencyUserLenses: Array<PowerLoadEfficiencyUserLensDto>;
    /**
     * 
     * @type {Array<FuelConsumptionByGeneratorUserLensDto>}
     * @memberof UserLensSetDto
     */
    fuelConsumptionByGeneratorUserLenses: Array<FuelConsumptionByGeneratorUserLensDto>;
    /**
     * 
     * @type {Array<DieselNatGasRatioStatsUserLensDto>}
     * @memberof UserLensSetDto
     */
    dieselNatGasRatioStatsUserLenses: Array<DieselNatGasRatioStatsUserLensDto>;
    /**
     * 
     * @type {Array<RigFleetPerformanceCardUserLensDto>}
     * @memberof UserLensSetDto
     */
    rigFleetPerformanceCardUserLenses: Array<RigFleetPerformanceCardUserLensDto>;
    /**
     * 
     * @type {Array<RigScorecardUserLensDto>}
     * @memberof UserLensSetDto
     */
    rigScorecardUserLenses: Array<RigScorecardUserLensDto>;
    /**
     * 
     * @type {Array<TagBottomFingerprintUserLensDto>}
     * @memberof UserLensSetDto
     */
    tagBottomFingerprintUserLenses: Array<TagBottomFingerprintUserLensDto>;
    /**
     * 
     * @type {Array<StickSlipByTimeUserLensDto>}
     * @memberof UserLensSetDto
     */
    stickSlipByTimeUserLenses: Array<StickSlipByTimeUserLensDto>;
    /**
     * 
     * @type {Array<StickSlipByDepthUserLensDto>}
     * @memberof UserLensSetDto
     */
    stickSlipByDepthUserLenses: Array<StickSlipByDepthUserLensDto>;
    /**
     * 
     * @type {Array<IntelScatterPlotUserLensDto>}
     * @memberof UserLensSetDto
     */
    intelScatterPlotUserLenses: Array<IntelScatterPlotUserLensDto>;
    /**
     * 
     * @type {Array<IntelRankingRibbonUserLensDto>}
     * @memberof UserLensSetDto
     */
    intelRankingRibbonUserLenses: Array<IntelRankingRibbonUserLensDto>;
    /**
     * 
     * @type {Array<IntelScorecardUserLensDto>}
     * @memberof UserLensSetDto
     */
    intelScorecardUserLenses: Array<IntelScorecardUserLensDto>;
}

/**
 * Check if a given object implements the UserLensSetDto interface.
 */
export function instanceOfUserLensSetDto(value: object): value is UserLensSetDto {
    if (!('kpiTypeUserLenses' in value) || value['kpiTypeUserLenses'] === undefined) return false;
    if (!('kpiGroupUserLenses' in value) || value['kpiGroupUserLenses'] === undefined) return false;
    if (!('drillingProductivityUserLenses' in value) || value['drillingProductivityUserLenses'] === undefined) return false;
    if (!('wedgeUserLenses' in value) || value['wedgeUserLenses'] === undefined) return false;
    if (!('parameterByDepthUserLenses' in value) || value['parameterByDepthUserLenses'] === undefined) return false;
    if (!('parameterHeatmapUserLenses' in value) || value['parameterHeatmapUserLenses'] === undefined) return false;
    if (!('wellDrillingSummaryUserLenses' in value) || value['wellDrillingSummaryUserLenses'] === undefined) return false;
    if (!('torqueAndDragUserLenses' in value) || value['torqueAndDragUserLenses'] === undefined) return false;
    if (!('pivotKpiTypeUserLenses' in value) || value['pivotKpiTypeUserLenses'] === undefined) return false;
    if (!('pivotKpiGroupUserLenses' in value) || value['pivotKpiGroupUserLenses'] === undefined) return false;
    if (!('batteryStateUserLenses' in value) || value['batteryStateUserLenses'] === undefined) return false;
    if (!('rigPowerUserLenses' in value) || value['rigPowerUserLenses'] === undefined) return false;
    if (!('powerConsumptionByOperationUserLenses' in value) || value['powerConsumptionByOperationUserLenses'] === undefined) return false;
    if (!('statCardUserLenses' in value) || value['statCardUserLenses'] === undefined) return false;
    if (!('naturalGasStateUserLenses' in value) || value['naturalGasStateUserLenses'] === undefined) return false;
    if (!('generatorStatusBarUserLenses' in value) || value['generatorStatusBarUserLenses'] === undefined) return false;
    if (!('fuelFlowRateUserLenses' in value) || value['fuelFlowRateUserLenses'] === undefined) return false;
    if (!('generatorLoadProfileUserLenses' in value) || value['generatorLoadProfileUserLenses'] === undefined) return false;
    if (!('powerLoadDistributionUserLenses' in value) || value['powerLoadDistributionUserLenses'] === undefined) return false;
    if (!('generatorStatsUserLenses' in value) || value['generatorStatsUserLenses'] === undefined) return false;
    if (!('fuelTankVolumeUserLenses' in value) || value['fuelTankVolumeUserLenses'] === undefined) return false;
    if (!('ghgEmissionsUserLenses' in value) || value['ghgEmissionsUserLenses'] === undefined) return false;
    if (!('powerLoadEfficiencyUserLenses' in value) || value['powerLoadEfficiencyUserLenses'] === undefined) return false;
    if (!('fuelConsumptionByGeneratorUserLenses' in value) || value['fuelConsumptionByGeneratorUserLenses'] === undefined) return false;
    if (!('dieselNatGasRatioStatsUserLenses' in value) || value['dieselNatGasRatioStatsUserLenses'] === undefined) return false;
    if (!('rigFleetPerformanceCardUserLenses' in value) || value['rigFleetPerformanceCardUserLenses'] === undefined) return false;
    if (!('rigScorecardUserLenses' in value) || value['rigScorecardUserLenses'] === undefined) return false;
    if (!('tagBottomFingerprintUserLenses' in value) || value['tagBottomFingerprintUserLenses'] === undefined) return false;
    if (!('stickSlipByTimeUserLenses' in value) || value['stickSlipByTimeUserLenses'] === undefined) return false;
    if (!('stickSlipByDepthUserLenses' in value) || value['stickSlipByDepthUserLenses'] === undefined) return false;
    if (!('intelScatterPlotUserLenses' in value) || value['intelScatterPlotUserLenses'] === undefined) return false;
    if (!('intelRankingRibbonUserLenses' in value) || value['intelRankingRibbonUserLenses'] === undefined) return false;
    if (!('intelScorecardUserLenses' in value) || value['intelScorecardUserLenses'] === undefined) return false;
    return true;
}

export function UserLensSetDtoFromJSON(json: any): UserLensSetDto {
    return UserLensSetDtoFromJSONTyped(json, false);
}

export function UserLensSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLensSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'kpiTypeUserLenses': ((json['KpiTypeUserLenses'] as Array<any>).map(KpiTypeUserLensDtoFromJSON)),
        'kpiGroupUserLenses': ((json['KpiGroupUserLenses'] as Array<any>).map(KpiGroupUserLensDtoFromJSON)),
        'drillingProductivityUserLenses': ((json['DrillingProductivityUserLenses'] as Array<any>).map(DrillingProductivityUserLensDtoFromJSON)),
        'wedgeUserLenses': ((json['WedgeUserLenses'] as Array<any>).map(WedgeUserLensDtoFromJSON)),
        'parameterByDepthUserLenses': ((json['ParameterByDepthUserLenses'] as Array<any>).map(ParameterByDepthUserLensDtoFromJSON)),
        'parameterHeatmapUserLenses': ((json['ParameterHeatmapUserLenses'] as Array<any>).map(ParameterHeatmapUserLensDtoFromJSON)),
        'wellDrillingSummaryUserLenses': ((json['WellDrillingSummaryUserLenses'] as Array<any>).map(WellDrillingSummaryUserLensDtoFromJSON)),
        'torqueAndDragUserLenses': ((json['TorqueAndDragUserLenses'] as Array<any>).map(TorqueAndDragUserLensDtoFromJSON)),
        'pivotKpiTypeUserLenses': ((json['PivotKpiTypeUserLenses'] as Array<any>).map(PivotKpiTypeUserLensDtoFromJSON)),
        'pivotKpiGroupUserLenses': ((json['PivotKpiGroupUserLenses'] as Array<any>).map(PivotKpiGroupUserLensDtoFromJSON)),
        'batteryStateUserLenses': ((json['BatteryStateUserLenses'] as Array<any>).map(BatteryStateUserLensDtoFromJSON)),
        'rigPowerUserLenses': ((json['RigPowerUserLenses'] as Array<any>).map(RigPowerUserLensDtoFromJSON)),
        'powerConsumptionByOperationUserLenses': ((json['PowerConsumptionByOperationUserLenses'] as Array<any>).map(PowerConsumptionByOperationUserLensDtoFromJSON)),
        'statCardUserLenses': ((json['StatCardUserLenses'] as Array<any>).map(StatCardUserLensDtoFromJSON)),
        'naturalGasStateUserLenses': ((json['NaturalGasStateUserLenses'] as Array<any>).map(NaturalGasStateUserLensDtoFromJSON)),
        'generatorStatusBarUserLenses': ((json['GeneratorStatusBarUserLenses'] as Array<any>).map(GeneratorStatusBarUserLensDtoFromJSON)),
        'fuelFlowRateUserLenses': ((json['FuelFlowRateUserLenses'] as Array<any>).map(FuelFlowRateUserLensDtoFromJSON)),
        'generatorLoadProfileUserLenses': ((json['GeneratorLoadProfileUserLenses'] as Array<any>).map(GeneratorLoadProfileUserLensDtoFromJSON)),
        'powerLoadDistributionUserLenses': ((json['PowerLoadDistributionUserLenses'] as Array<any>).map(PowerLoadDistributionUserLensDtoFromJSON)),
        'generatorStatsUserLenses': ((json['GeneratorStatsUserLenses'] as Array<any>).map(GeneratorStatsUserLensDtoFromJSON)),
        'fuelTankVolumeUserLenses': ((json['FuelTankVolumeUserLenses'] as Array<any>).map(FuelTankVolumeUserLensDtoFromJSON)),
        'ghgEmissionsUserLenses': ((json['GhgEmissionsUserLenses'] as Array<any>).map(GhgEmissionsUserLensDtoFromJSON)),
        'powerLoadEfficiencyUserLenses': ((json['PowerLoadEfficiencyUserLenses'] as Array<any>).map(PowerLoadEfficiencyUserLensDtoFromJSON)),
        'fuelConsumptionByGeneratorUserLenses': ((json['FuelConsumptionByGeneratorUserLenses'] as Array<any>).map(FuelConsumptionByGeneratorUserLensDtoFromJSON)),
        'dieselNatGasRatioStatsUserLenses': ((json['DieselNatGasRatioStatsUserLenses'] as Array<any>).map(DieselNatGasRatioStatsUserLensDtoFromJSON)),
        'rigFleetPerformanceCardUserLenses': ((json['RigFleetPerformanceCardUserLenses'] as Array<any>).map(RigFleetPerformanceCardUserLensDtoFromJSON)),
        'rigScorecardUserLenses': ((json['RigScorecardUserLenses'] as Array<any>).map(RigScorecardUserLensDtoFromJSON)),
        'tagBottomFingerprintUserLenses': ((json['TagBottomFingerprintUserLenses'] as Array<any>).map(TagBottomFingerprintUserLensDtoFromJSON)),
        'stickSlipByTimeUserLenses': ((json['StickSlipByTimeUserLenses'] as Array<any>).map(StickSlipByTimeUserLensDtoFromJSON)),
        'stickSlipByDepthUserLenses': ((json['StickSlipByDepthUserLenses'] as Array<any>).map(StickSlipByDepthUserLensDtoFromJSON)),
        'intelScatterPlotUserLenses': ((json['IntelScatterPlotUserLenses'] as Array<any>).map(IntelScatterPlotUserLensDtoFromJSON)),
        'intelRankingRibbonUserLenses': ((json['IntelRankingRibbonUserLenses'] as Array<any>).map(IntelRankingRibbonUserLensDtoFromJSON)),
        'intelScorecardUserLenses': ((json['IntelScorecardUserLenses'] as Array<any>).map(IntelScorecardUserLensDtoFromJSON)),
    };
}

export function UserLensSetDtoToJSON(value?: UserLensSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'KpiTypeUserLenses': ((value['kpiTypeUserLenses'] as Array<any>).map(KpiTypeUserLensDtoToJSON)),
        'KpiGroupUserLenses': ((value['kpiGroupUserLenses'] as Array<any>).map(KpiGroupUserLensDtoToJSON)),
        'DrillingProductivityUserLenses': ((value['drillingProductivityUserLenses'] as Array<any>).map(DrillingProductivityUserLensDtoToJSON)),
        'WedgeUserLenses': ((value['wedgeUserLenses'] as Array<any>).map(WedgeUserLensDtoToJSON)),
        'ParameterByDepthUserLenses': ((value['parameterByDepthUserLenses'] as Array<any>).map(ParameterByDepthUserLensDtoToJSON)),
        'ParameterHeatmapUserLenses': ((value['parameterHeatmapUserLenses'] as Array<any>).map(ParameterHeatmapUserLensDtoToJSON)),
        'WellDrillingSummaryUserLenses': ((value['wellDrillingSummaryUserLenses'] as Array<any>).map(WellDrillingSummaryUserLensDtoToJSON)),
        'TorqueAndDragUserLenses': ((value['torqueAndDragUserLenses'] as Array<any>).map(TorqueAndDragUserLensDtoToJSON)),
        'PivotKpiTypeUserLenses': ((value['pivotKpiTypeUserLenses'] as Array<any>).map(PivotKpiTypeUserLensDtoToJSON)),
        'PivotKpiGroupUserLenses': ((value['pivotKpiGroupUserLenses'] as Array<any>).map(PivotKpiGroupUserLensDtoToJSON)),
        'BatteryStateUserLenses': ((value['batteryStateUserLenses'] as Array<any>).map(BatteryStateUserLensDtoToJSON)),
        'RigPowerUserLenses': ((value['rigPowerUserLenses'] as Array<any>).map(RigPowerUserLensDtoToJSON)),
        'PowerConsumptionByOperationUserLenses': ((value['powerConsumptionByOperationUserLenses'] as Array<any>).map(PowerConsumptionByOperationUserLensDtoToJSON)),
        'StatCardUserLenses': ((value['statCardUserLenses'] as Array<any>).map(StatCardUserLensDtoToJSON)),
        'NaturalGasStateUserLenses': ((value['naturalGasStateUserLenses'] as Array<any>).map(NaturalGasStateUserLensDtoToJSON)),
        'GeneratorStatusBarUserLenses': ((value['generatorStatusBarUserLenses'] as Array<any>).map(GeneratorStatusBarUserLensDtoToJSON)),
        'FuelFlowRateUserLenses': ((value['fuelFlowRateUserLenses'] as Array<any>).map(FuelFlowRateUserLensDtoToJSON)),
        'GeneratorLoadProfileUserLenses': ((value['generatorLoadProfileUserLenses'] as Array<any>).map(GeneratorLoadProfileUserLensDtoToJSON)),
        'PowerLoadDistributionUserLenses': ((value['powerLoadDistributionUserLenses'] as Array<any>).map(PowerLoadDistributionUserLensDtoToJSON)),
        'GeneratorStatsUserLenses': ((value['generatorStatsUserLenses'] as Array<any>).map(GeneratorStatsUserLensDtoToJSON)),
        'FuelTankVolumeUserLenses': ((value['fuelTankVolumeUserLenses'] as Array<any>).map(FuelTankVolumeUserLensDtoToJSON)),
        'GhgEmissionsUserLenses': ((value['ghgEmissionsUserLenses'] as Array<any>).map(GhgEmissionsUserLensDtoToJSON)),
        'PowerLoadEfficiencyUserLenses': ((value['powerLoadEfficiencyUserLenses'] as Array<any>).map(PowerLoadEfficiencyUserLensDtoToJSON)),
        'FuelConsumptionByGeneratorUserLenses': ((value['fuelConsumptionByGeneratorUserLenses'] as Array<any>).map(FuelConsumptionByGeneratorUserLensDtoToJSON)),
        'DieselNatGasRatioStatsUserLenses': ((value['dieselNatGasRatioStatsUserLenses'] as Array<any>).map(DieselNatGasRatioStatsUserLensDtoToJSON)),
        'RigFleetPerformanceCardUserLenses': ((value['rigFleetPerformanceCardUserLenses'] as Array<any>).map(RigFleetPerformanceCardUserLensDtoToJSON)),
        'RigScorecardUserLenses': ((value['rigScorecardUserLenses'] as Array<any>).map(RigScorecardUserLensDtoToJSON)),
        'TagBottomFingerprintUserLenses': ((value['tagBottomFingerprintUserLenses'] as Array<any>).map(TagBottomFingerprintUserLensDtoToJSON)),
        'StickSlipByTimeUserLenses': ((value['stickSlipByTimeUserLenses'] as Array<any>).map(StickSlipByTimeUserLensDtoToJSON)),
        'StickSlipByDepthUserLenses': ((value['stickSlipByDepthUserLenses'] as Array<any>).map(StickSlipByDepthUserLensDtoToJSON)),
        'IntelScatterPlotUserLenses': ((value['intelScatterPlotUserLenses'] as Array<any>).map(IntelScatterPlotUserLensDtoToJSON)),
        'IntelRankingRibbonUserLenses': ((value['intelRankingRibbonUserLenses'] as Array<any>).map(IntelRankingRibbonUserLensDtoToJSON)),
        'IntelScorecardUserLenses': ((value['intelScorecardUserLenses'] as Array<any>).map(IntelScorecardUserLensDtoToJSON)),
    };
}

