import { useQueryClient } from "@tanstack/react-query";
import type { DefaultOptionType } from "antd/lib/select";
import { UserRoleType, UsersApi } from "apis/oag";
import { CustomSwitch } from "atoms/common";
import { Button, FormItem, Input } from "atoms/Form";
import { Select } from "atoms/Form";
import { toast } from "atoms/toast";
import { Title } from "atoms/Typography";
import CoreLayout, { Section, SectionLayout } from "components/Layout";
import { WellAccessMultiple } from "components/WellAccess";
import Pane from "components/WellEditor/Pane";
import dayjs from "dayjs";
import { useOperators } from "hooks/drillingInvariants/useOperators";
import { useRigSort } from "hooks/rigs/useRigSort";
import { useCurrentUser } from "hooks/user/useCurrentUser";
import { useCurrentWellAccess } from "hooks/user/useCurrentWellAccess";
import { InfoContainer, InputContainer } from "pages/Account/Me/style";
import type { FC } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMixpanel } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import { Col, Row, Space } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

const users = new UsersApi(apiConfig);

const AccountMe: FC = () => {
  const queryClient = useQueryClient();
  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();
  useEffect(() => {
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage("My Account");
    }
  }, [viewPage]);

  const { atomThemeVariant } = useCustomTheme();
  const { data: userData, isLoading: userLoading } = useCurrentUser();
  const { data: wellAccess, isLoading: wellLoading } = useCurrentWellAccess();
  const { data: operatorData, isLoading: operatorLoading } = useOperators();

  const userOperator = useMemo(() => {
    return userData ? operatorData?.byId[userData.operatorId ?? -1] : null;
  }, [operatorData, userData]);

  const focusOperatorSelectionList = useMemo(() => {
    const operatorItems = (operatorData?.list ?? []).map(
      (e) => ({ value: e.id, label: e.name }) as DefaultOptionType,
    );
    const noneItem: DefaultOptionType = { value: null, label: "None" };
    operatorItems.unshift(noneItem);
    return operatorItems;
  }, [operatorData]);

  const [isUpdating, setIsUpdating] = useState(false);
  const [hasFirstnameEdited, setHasFirstnameEdited] = useState(false);
  const [hasLastnameEdited, setHasLastnameEdited] = useState(false);

  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    job: "",
    account: operatorData?.byId[userData?.operatorId ?? -1]?.name,
    role: "",
    expiration: "",
    focusOperatorId: null as number | null,
    focusOperatorAsAccount: false,
  });

  useEffect(() => {
    if (userData) {
      setFields({
        firstName: userData.firstName ?? "",
        lastName: userData.lastName ?? "",
        email: userData.userName,
        job: userData.jobTitle ?? "",
        account: userOperator?.name ?? "",
        role: userData.role,
        expiration: userData.expirationDate
          ? dayjs(userData.expirationDate).format("MMMM D, YYYY h:mm A")
          : "Not Set",
        focusOperatorId: userData.focusOperatorId ?? null,
        focusOperatorAsAccount: userData.focusOperatorAsAccount,
      });
    }
  }, [userData, userOperator]);

  const isLoading = useMemo(
    () => userLoading || wellLoading || operatorLoading,
    [operatorLoading, userLoading, wellLoading],
  );

  const sortByRigs = useRigSort();

  const allRigs = useMemo(() => {
    return (wellAccess ?? []).sort(
      (a, b) => a && b && sortByRigs(a.rigId, b.rigId),
    );
  }, [sortByRigs, wellAccess]);

  const updateAccount = () => {
    if (isUpdating) return;
    setIsUpdating(true);

    if (!fields.firstName || !fields.lastName) {
      toast.error({
        message: "First Name & Last Name is required.",
      });
      setIsUpdating(false);
      return;
    }

    users
      .apiUsersMePut({
        userDto: userData
          ? {
              ...userData,
              firstName: fields.firstName,
              lastName: fields.lastName,
              jobTitle: fields.job,
              focusOperatorId: fields.focusOperatorId,
              focusOperatorAsAccount: fields.focusOperatorAsAccount,
            }
          : undefined,
      })
      .then(() => {
        //queryClient.invalidateQueries({ queryKey: [{ uid: RequestUID.userWhoAmI }], exact: false });
        queryClient.clear(); // ensures all data will be refetch following potential changes in the "Focus Operator" setup
        toast.success({
          message: "Details updated!",
        });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <CoreLayout>
      <SectionLayout
        header={
          <Section>
            <Row justify="space-between" align="middle">
              <Col flex="0 auto">
                <Title level={3} variant={atomThemeVariant}>
                  {isLoading ? "Loading..." : "My Account"}
                </Title>
              </Col>
              <Col flex="0 auto">
                <Space>
                  {/* TODO account send changes to MP */}
                  <Button
                    trackingName="Update Account"
                    type="primary"
                    block
                    onClick={updateAccount}
                    loading={isUpdating}
                    disabled={isLoading}
                  >
                    Update
                  </Button>
                </Space>
              </Col>
            </Row>
          </Section>
        }
      >
        <Section style={{ padding: "40px" }}>
          <Row gutter={[16, 16]}>
            <Col span={16}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Pane title="Account Information">
                    <InfoContainer>
                      <Col span={24}>
                        <InputContainer>
                          <Col span={4}>
                            <Title level={3} variant={atomThemeVariant}>
                              Name
                            </Title>
                          </Col>
                          <Col span={10}>
                            <FormItem
                              validateStatus={(() => {
                                if (!hasFirstnameEdited) return undefined;
                                if (fields.firstName) {
                                  return "success";
                                } else {
                                  return "error";
                                }
                              })()}
                            >
                              <Input
                                value={fields.firstName}
                                onChange={(ev) => {
                                  setFields({
                                    ...fields,
                                    firstName: ev.target.value,
                                  });
                                  setHasFirstnameEdited(true);
                                }}
                                placeholder={
                                  isLoading ? "Loading.." : "First Name"
                                }
                              />
                            </FormItem>
                          </Col>
                          <Col span={10}>
                            <FormItem
                              validateStatus={(() => {
                                if (!hasLastnameEdited) return undefined;
                                if (fields.lastName) {
                                  return "success";
                                } else {
                                  return "error";
                                }
                              })()}
                            >
                              <Input
                                value={fields.lastName}
                                onChange={(ev) => {
                                  setFields({
                                    ...fields,
                                    lastName: ev.target.value,
                                  });
                                  setHasLastnameEdited(true);
                                }}
                                placeholder={
                                  isLoading ? "Loading.." : "Last Name"
                                }
                              />
                            </FormItem>
                          </Col>
                        </InputContainer>
                      </Col>
                      <Col span={24}>
                        <InputContainer>
                          <Col span={4}>
                            <Title level={3} variant={atomThemeVariant}>
                              Email
                            </Title>
                          </Col>
                          <Col span={20}>
                            <FormItem
                              validateStatus={
                                fields.email ? undefined : "error"
                              }
                            >
                              <Input
                                value={fields.email}
                                placeholder={isLoading ? "Loading.." : "Email"}
                                disabled
                              />
                            </FormItem>
                          </Col>
                        </InputContainer>
                      </Col>
                      <Col span={24}>
                        <InputContainer>
                          <Col span={4}>
                            <Title level={3} variant={atomThemeVariant}>
                              Job Title
                            </Title>
                          </Col>
                          <Col span={20}>
                            <Input
                              value={fields.job}
                              placeholder={
                                isLoading ? "Loading.." : "Job Title"
                              }
                              onChange={(ev) =>
                                setFields({ ...fields, job: ev.target.value })
                              }
                            />
                          </Col>
                        </InputContainer>
                      </Col>
                      <Col span={24}>
                        <InputContainer>
                          <Col span={4}>
                            <Title level={3} variant={atomThemeVariant}>
                              Account
                            </Title>
                          </Col>
                          <Col span={20}>
                            <FormItem>
                              <Input
                                value={fields.account}
                                placeholder={
                                  isLoading ? "Loading.." : "Account"
                                }
                                disabled
                              />
                            </FormItem>
                          </Col>
                        </InputContainer>
                      </Col>
                    </InfoContainer>
                  </Pane>
                </Col>
                {userData?.role === UserRoleType.Administrator ||
                !userOperator ||
                !userOperator.providesCrossOperatorClaims ? null : (
                  <Col span={24}>
                    <Pane title="Operator Restriction">
                      <InfoContainer>
                        <Col span={24}>
                          <InputContainer>
                            <Col span={4}>
                              <Title level={3} variant={atomThemeVariant}>
                                Operator
                              </Title>
                            </Col>
                            <Col span={20}>
                              <FormItem>
                                <Select
                                  placeholder={
                                    isLoading ? "Loading.." : "Operator"
                                  }
                                  options={focusOperatorSelectionList}
                                  showSearch
                                  optionFilterProp="label"
                                  value={fields.focusOperatorId}
                                  onChange={(selection) => {
                                    setFields({
                                      ...fields,
                                      focusOperatorId: selection as
                                        | number
                                        | null,
                                    });
                                  }}
                                />
                              </FormItem>
                            </Col>
                          </InputContainer>
                        </Col>
                        <Col span={24}>
                          <InputContainer>
                            <Col span={4}>
                              <Title level={3} variant={atomThemeVariant}>
                                Match Operator Access
                              </Title>
                            </Col>
                            <Col span={1}>
                              <FormItem>
                                <CustomSwitch
                                  $isChecked={fields.focusOperatorAsAccount}
                                  checked={fields.focusOperatorAsAccount}
                                  onChange={(e) =>
                                    setFields({
                                      ...fields,
                                      focusOperatorAsAccount: e,
                                    })
                                  }
                                />
                              </FormItem>
                            </Col>
                            <Col
                              span={2}
                              style={{
                                paddingLeft: 16,
                              }}
                            >
                              <Title
                                level={5}
                                variant={
                                  fields.focusOperatorAsAccount
                                    ? atomThemeVariant
                                    : "faded"
                                }
                              >
                                {fields.focusOperatorAsAccount ? "YES" : "NO"}
                              </Title>
                            </Col>
                          </InputContainer>
                        </Col>
                      </InfoContainer>
                    </Pane>
                  </Col>
                )}
                <Col span={24}>
                  <Pane title="Permissions">
                    <InfoContainer>
                      <Col span={24}>
                        <InputContainer>
                          <Col span={4}>
                            <Title level={3} variant={atomThemeVariant}>
                              Role
                            </Title>
                          </Col>
                          <Col span={20}>
                            <FormItem>
                              <Input
                                value={fields.role}
                                placeholder={isLoading ? "Loading.." : "Role"}
                                disabled
                              />
                            </FormItem>
                          </Col>
                        </InputContainer>
                      </Col>
                      <Col span={24}>
                        <InputContainer>
                          <Col span={4}>
                            <Title level={3} variant={atomThemeVariant}>
                              Expiration Date
                            </Title>
                          </Col>
                          <Col span={20}>
                            <FormItem>
                              <Input
                                value={fields.expiration}
                                placeholder={
                                  isLoading ? "Loading.." : "Expiration"
                                }
                                disabled
                              />
                            </FormItem>
                          </Col>
                        </InputContainer>
                      </Col>
                    </InfoContainer>
                  </Pane>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Pane title="Well Access">
                    {userData?.role === UserRoleType.Administrator && (
                      <Title level={3} variant={atomThemeVariant}>
                        You have access to all wells &amp; rigs.
                      </Title>
                    )}
                  </Pane>
                  {!isLoading &&
                  userData?.id &&
                  userData?.role !== UserRoleType.Administrator ? (
                    <WellAccessMultiple
                      key={userData.id}
                      selector={userData.id}
                      mode="user"
                      allRigAccesses={allRigs}
                      viewOnly
                    />
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Section>
      </SectionLayout>
    </CoreLayout>
  );
};

export default AccountMe;
