import { PDComponent } from "components/PDComponents";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Input } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

export const StyledModalContent = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
`;

export const Chevron = styled(PDComponent.SvgIcon).attrs({
  name: "chevronDown",
})<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.1s ease;
  margin-right: 2px;
`;

export const ClickableElement = styled.div`
  cursor: pointer;
  user-select: none;
`;

export const ScrollableContent = styled.div`
  height: calc(544px - 56px - 48px - 65px); //56, 48, rows above; 65px = footer
  overflow-y: auto;
  position: relative;
`;

export const customStyles = {
  overlay: {
    zIndex: zIndexLayer.mars,
    backgroundColor: "rgba(32,43,48,0.6)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    borderRadius: 12,
    bottom: "auto",
    padding: "0px!important",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 868,
    height: 527,
    overflow: "hidden",
    border: "none",
  },
};

export const Search = styled(Input)`
  .ant-input {
    background-color: ${colors.actions_bg};
  }

  .ant-input-wrapper {
    .ant-input-affix-wrapper {
      background-color: ${({ theme: { isDark } }) =>
        isDark ? `${colors.jaguar_gray} !important` : colors.actions_bg};
      ${({ theme }) =>
        theme.isDark
          ? css`
              border-color: ${colors.limo_scene};
            `
          : null}
    }

    .ant-input-group-addon {
      background-color: ${colors.well_color};
      color: ${colors.white};
    }

    ${({ theme }) =>
      theme.isDark &&
      css`
        .ant-input-group-addon {
          border-color: ${colors.limo_scene};
        }

        input,
        .anticon.anticon-close-circle {
          color: ${({ theme }) =>
            theme.themeStyle.colors.primary_typography} !important;
        }
      `};
  }
`;

export const StyledCloseIcon = styled(PDComponent.SvgIcon).attrs({
  name: "close",
})`
  cursor: pointer;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;
