import type { RangeType } from "atoms/DatePicker";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "reducers/store";
import { defaultDateDto } from "utils/common";
import { dateToDateOnlyDto, last30Days, today } from "utils/helper";

export const useRigsPeriodState = () => {
  const { isRig, isRootDashboardPage } = useDashboardType();
  const rigsCommonPeriodState = useAppSelector(
    (state) => state.rigsCommon.period,
  );
  const { lensId: id } = useParams<{ lensId: string }>();
  const isDetailedView = !!id;
  const [rigDashboardPeriodState] = useStateQuery<RangeType>(
    isDetailedView
      ? URL_STATE_PARAM.PERIOD_RIG_WIDGET
      : URL_STATE_PARAM.PERIOD_RIG_DASHBOARD,
    {
      startDate: last30Days,
      endDate: today,
    },
  );
  const periodState = useMemo(() => {
    if (isRig) {
      if (
        isRootDashboardPage &&
        rigDashboardPeriodState.startDate &&
        rigDashboardPeriodState.endDate
      ) {
        return {
          from: dateToDateOnlyDto(rigDashboardPeriodState.startDate),
          to: dateToDateOnlyDto(rigDashboardPeriodState.endDate),
        };
      }
      if (rigsCommonPeriodState.startDate && rigsCommonPeriodState.endDate) {
        return {
          from: dateToDateOnlyDto(rigsCommonPeriodState.startDate),
          to: dateToDateOnlyDto(rigsCommonPeriodState.endDate),
        };
      }
    }
    return defaultDateDto;
  }, [
    isRig,
    isRootDashboardPage,
    rigDashboardPeriodState,
    rigsCommonPeriodState,
  ]);

  return periodState;
};
