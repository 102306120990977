import { useRigScorecardCardFacts } from "hooks/rigs/scorecard/useRigScorecardCardFacts";
import { useAppSelector } from "reducers/store";

export const useRigScores = ({
  selectedWells,
}: {
  selectedWells: number[];
}) => {
  const exceptions = useAppSelector((state) => state.rigsCommon.exceptions);
  const { data: factSet } = useRigScorecardCardFacts(
    selectedWells,
    exceptions,
    {
      staleTime: Infinity,
      refetchOnMount: false,
    },
  );

  return factSet;
};
