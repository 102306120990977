import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { ApiFiltersOperatorsByRigsPutRequest } from "apis/oag";
import { FiltersApi } from "apis/oag";
import { useRigsPeriodState } from "hooks/rigs/useRigsPeriodState";
import { apiConfig } from "utils/apiConfig";
import type { PDRigsActiveOperatorsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const filters = new FiltersApi(apiConfig);

export function useRigsActiveOperators(
  rigIds: number[] | null,
  filterEverGreenOnly: boolean,
  options: Omit<UseQueryOptions<Array<number>>, "queryKey" | "queryFn"> = {},
) {
  const periodState = useRigsPeriodState();
  const requestBody: ApiFiltersOperatorsByRigsPutRequest = {
    availableIdsByRigsQueryDto: {
      rigIds: rigIds ?? undefined,
      keepEvengreenOnly: filterEverGreenOnly,
      from: periodState.from,
      to: periodState.to,
    },
  };

  const RigsActiveOperatorQueryKey: PDRigsActiveOperatorsQueryKey = {
    uid: RequestUID.rigsActiveOperators,
    type: PDQueryType.RIGS_ACTIVE_OPERATORS,
    params: requestBody,
  };

  return useQuery<Array<number>>({
    queryKey: [RigsActiveOperatorQueryKey],
    queryFn: () => filters.apiFiltersOperatorsByRigsPut(requestBody),
    ...options,
    refetchOnMount: false,
  });
}
