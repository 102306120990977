import type { Hexbin as IHexBin } from "libs/HexBin/HexBinTypes";

const centerDxUnit = Math.sqrt(3); // Apothem of a hexagon with radius of 2
const centerDyUnit = 3 / 2;
const thirdPi = Math.PI / 3;
const angles: number[] = [
  0,
  thirdPi,
  2 * thirdPi,
  3 * thirdPi,
  4 * thirdPi,
  5 * thirdPi,
];

export type Center = {
  xCoordinate: number;
  yCoordinate: number;
  xPixels: number;
  yPixels: number;
};

interface Coords {
  x?: number;
  y?: number;
}
interface VisualBin<T> {
  xCenterPosition: number;
  yCenterPosition: number;
  bin: T;
}

export class HexBin<T> implements IHexBin<T> {
  computedWidthInPixels: number = 100;
  maxWidthInPixels: number = 100;
  computedHeightInPixels: number = 100;
  maxHeightInPixels: number = 100;
  xCount: number = 1;
  yCount: number = 1;
  xCountMin: number | null = null;
  yCountMin: number | null = null;
  useYBottomOrigin: boolean = false;
  computedRadius: number = 1;
  centerDx: number = 0;
  centerDy: number = 0;
  visualBins: VisualBin<T>[] = [];
  activeBinCoords: Coords | null = null;
  overlayBinsCoords: {
    primary?: { x?: number; y?: number };
    secondary?: { x?: number; y?: number };
  } = {};
  activeBin: VisualBin<T> | null = null;
  overlayPrimaryBin: Omit<VisualBin<T>, "bin"> | null = null;
  overlaySecondaryBin: Omit<VisualBin<T>, "bin"> | null = null;

  getPointX: (customBinPoint: T) => number = () => 0;
  getPointY: (customBinPoint: T) => number = () => 0;

  getHexagonCorners(radius: number) {
    let x0 = 0;
    let y0 = 0;
    return angles.map(function (angle) {
      const x1 = Math.sin(angle) * radius,
        y1 = -Math.cos(angle) * radius,
        diffX = x1 - x0,
        diffY = y1 - y0;

      x0 = x1;
      y0 = y1;
      return [diffX, diffY];
    });
  }

  grid(x: number, y: number) {
    this.xCount = Math.floor(x);
    this.yCount = Math.floor(y);

    // we use the Width (aka 'x') as the reference value to compute the radius...
    this.computedWidthInPixels = this.maxWidthInPixels;
    this.centerDx = this.computedWidthInPixels / (this.xCount + 1 / 2); // 1/2 to account for the extra width of offset rows
    this.computedRadius = this.centerDx / centerDxUnit;
    this.centerDy = this.computedRadius * centerDyUnit;

    // .. thus total height has to be adjusted accordingly (since we want yCount to be constant)
    this.computedHeightInPixels = this.centerDy * (this.yCount + 1);

    if (this.computedHeightInPixels > this.maxHeightInPixels) {
      // Formula to derive height fit in a hexgrid , look for r here: https://www.wolframalpha.com/input?i=%28h-2*r%29+%2F+%283%2F2*r%29+%3D+c-1
      this.computedRadius =
        (2 * this.maxHeightInPixels) / (3 * this.yCount + 1);
      this.computedHeightInPixels = this.maxHeightInPixels;
      this.centerDx = this.computedRadius * centerDxUnit;
      this.centerDy = this.computedRadius * centerDyUnit;
      this.computedWidthInPixels = this.xCount * this.centerDx;
    }

    return this;
  }

  hexagon() {
    // hexagon centered on (0,0): template for all the others (by copy and translation)
    const dPath =
      "m" + this.getHexagonCorners(this.computedRadius).join("l") + "z";
    return dPath;
  }

  setActiveBinCoords(coords: Coords) {
    this.activeBinCoords = coords ? { x: coords.x, y: coords.y } : null;
    return this;
  }

  setOverlayBinsCoords(primary?: Coords, secondary?: Coords) {
    this.overlayBinsCoords = {
      primary: primary ? { x: primary.x, y: primary.y } : undefined,
      secondary: secondary ? { x: secondary.x, y: secondary.y } : undefined,
    };
    return this;
  }

  bins(points: Array<T>): this {
    if (!arguments?.length) return this;

    // clean up the previous bins
    this.visualBins.length = 0;

    const binIds: string[] = [],
      n = points.length;

    if (!n) {
      this.xCount = this.xCountMin ?? 1;
      this.yCount = this.yCountMin ?? 1;
      return this;
    }

    this.xCount = Math.max(
      this.xCountMin || 1,
      Math.floor(Math.max(...points.map((p) => this.getPointX(p)))) + 1,
    );
    this.yCount = Math.max(
      this.yCountMin || 1,
      Math.floor(Math.max(...points.map((p) => this.getPointY(p)))) + 1,
    );

    this.grid(this.xCount, this.yCount);

    const centers = this.centers();
    for (let i = 0; i < n; ++i) {
      const point = points[i];
      const xCoordinate = Math.floor(this.getPointX(point));
      const yCoordinate = Math.floor(this.getPointY(point));

      if (isNaN(xCoordinate) || isNaN(yCoordinate)) continue;

      const center = centers.find(
        (c) => c.xCoordinate === xCoordinate && c.yCoordinate === yCoordinate,
      );
      if (!center) {
        console.warn(
          "HEXBIN",
          "Skip: Unable to find center point for (X, Y) = (",
          xCoordinate,
          ", ",
          yCoordinate,
          ")",
        );
        continue;
      }

      // we do not aggregate data in a bin: source needs to handle that
      // "1 data point <-> 1 bin"
      const id = xCoordinate + "-" + yCoordinate;
      if (binIds.includes(id)) {
        console.warn(
          "HEXBIN",
          "Skip: Bin alreacenterDy exists with (X, Y) = (",
          xCoordinate,
          ", ",
          yCoordinate,
          ")",
        );
      } else {
        const bin = {
          xCenterPosition: center.xPixels,
          yCenterPosition: center.yPixels,
          bin: point,
        };

        this.visualBins.push(bin);
        if (
          xCoordinate === this.activeBinCoords?.x &&
          yCoordinate === this.activeBinCoords?.y
        ) {
          this.activeBin = { ...bin };
        }

        binIds.push(id);
      }
    }

    this.overlayPrimaryBin = ((center) =>
      center
        ? {
            xCenterPosition: center.xPixels,
            yCenterPosition: center.yPixels,
          }
        : null)(
      centers.find(
        (c) =>
          c.xCoordinate === this.overlayBinsCoords?.primary?.x &&
          c.yCoordinate === this.overlayBinsCoords?.primary?.y,
      ),
    );

    this.overlaySecondaryBin = ((center) =>
      center
        ? {
            xCenterPosition: center.xPixels,
            yCenterPosition: center.yPixels,
          }
        : null)(
      centers.find(
        (c) =>
          c.xCoordinate === this.overlayBinsCoords?.secondary?.x &&
          c.yCoordinate === this.overlayBinsCoords?.secondary?.y,
      ),
    );

    return this;
  }

  centers() {
    const centers: Center[] = [];
    for (let xCoordinate = 0; xCoordinate < this.xCount; xCoordinate++) {
      for (let yCoordinate = 0; yCoordinate < this.yCount; yCoordinate++) {
        const xOffsetOnOdcenterDy =
          yCoordinate % 2 === 0 ? 0 : -this.centerDx / 2;

        const xPixels = (xCoordinate + 1) * this.centerDx + xOffsetOnOdcenterDy;
        let yPixels = this.centerDy + yCoordinate * this.centerDy;
        if (this.useYBottomOrigin)
          yPixels = this.computedHeightInPixels - yPixels;

        centers.push({ xCoordinate, yCoordinate, xPixels, yPixels });
      }
    }

    return centers;
  }

  mesh() {
    const fragment = this.getHexagonCorners(this.computedRadius).join("l");
    return this.centers()
      .map(function (c) {
        return "M" + [c?.xPixels, c?.yPixels] + "m" + fragment;
      })
      .join("");
  }

  x(fn: (d: T) => number) {
    this.getPointX = fn;
    return this;
  }

  y(fn: (d: T) => number) {
    this.getPointY = fn;
    return this;
  }

  maxSize(maxWidth: number, maxHeight: number) {
    this.maxWidthInPixels = Math.floor(maxWidth);
    this.maxHeightInPixels = Math.floor(maxHeight);

    return this;
  }

  minBinCounts(xMin: number, yMin: number) {
    this.xCountMin = xMin;
    this.yCountMin = yMin;

    return this;
  }

  bottomYOrigin(b: boolean) {
    this.useYBottomOrigin = !!b;
    return this;
  }

  computedSize() {
    return [this.computedWidthInPixels, this.computedHeightInPixels];
  }
}
