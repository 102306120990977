import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  ApiPivotKpiGroupUserLensesIdPivotFactsPutRequest,
  KpiPivotFactSetDto,
  PivotType,
} from "apis/oag";
import {
  PivotKpiGroupUserLensesApi,
  PivotKpiTypeUserLensesApi,
  PivotOrderType,
} from "apis/oag";
import {
  isPivotKpiGroupLensTemplate,
  useLensTemplates,
} from "hooks/lens/useLensTemplates";
import type { TOrder } from "hooks/pivot/useStackedKpi";
import { pivotArrayToObject } from "hooks/pivot/useStackedKpi";
import { useSelectedRig } from "hooks/rigs/useSelectedRig";
import { assign } from "lodash";
import { useParams } from "react-router-dom";
import { apiConfig } from "utils/apiConfig";
import { TVD_SAMPLE_COUNT } from "utils/common";
import type { PDKpiGroupPivotFactsMockQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const stackedApi = new PivotKpiGroupUserLensesApi(apiConfig);
const singleApi = new PivotKpiTypeUserLensesApi(apiConfig);
export const pivotsOrderArrayToObject = (pivots: Array<PivotOrderType>) => {
  const MAX_LEN_PIVOTS = 3;

  return Object.assign(
    {},
    ...assign(
      new Array(MAX_LEN_PIVOTS).fill(PivotOrderType.Unknown),
      pivots.filter((e) => e !== PivotOrderType.Unknown),
    ).map((option: PivotOrderType, index) => ({
      [`pivotLevel${index + 1}Order`]: option,
    })),
  ) as TOrder;
};
export function useKpiGroupPivotFactsMock(
  {
    pivots,
    lensTemplateId,
    pivotLevelOrder,
  }: {
    lensTemplateId?: number;
    pivots: Array<PivotType>;
    pivotLevelOrder: Array<PivotOrderType>;
  },
  options?: Omit<UseQueryOptions<KpiPivotFactSetDto>, "queryKey" | "queryFn">,
) {
  const { lensId } = useParams<{
    lensId: string;
  }>();
  const focalRigId = useSelectedRig();

  const { data: lensTemplates } = useLensTemplates();

  const requestParameters: ApiPivotKpiGroupUserLensesIdPivotFactsPutRequest = {
    id: lensId ? Number.parseInt(lensId) : 0,
    rigKpiPivotQueryDto: {
      useMockData: true,
      sampleCount: TVD_SAMPLE_COUNT,
      comparisonWellIds: [],
      selectedFilters: {
        includeFlatTime: false,
        includeSlidingTime: false,
        includeRotatingTime: false,
        includeNullHoleDepth: true,
        includeAlphaRigs: true,
        includeNonAlphaRigs: true,
      },
      from: {
        minutesOffset: 0,
        utc: new Date(new Date().getTime() - 700000),
        isDateOnly: true,
      },
      to: { minutesOffset: 0, utc: new Date(), isDateOnly: true },
      selectedRigIds: [focalRigId],
      ...pivotsOrderArrayToObject(pivotLevelOrder),
      ...pivotArrayToObject(pivots),
    },
  };

  const queryKey: PDKpiGroupPivotFactsMockQueryKey = {
    type: PDQueryType.KPI_GROUP_PIVOT_FACTS_MOCK,
    uid: RequestUID.kpiGroupPivotFactsMock,
    pivotLevelOrder,
    pivots,
  };

  return useQuery<KpiPivotFactSetDto>({
    queryKey: [queryKey],
    queryFn: () =>
      lensTemplateId &&
      isPivotKpiGroupLensTemplate(lensTemplates.byId[lensTemplateId])
        ? stackedApi.apiPivotKpiGroupUserLensesIdPivotFactsPut(
            requestParameters,
          )
        : singleApi.apiPivotKpiTypeUserLensesIdPivotFactsPut(requestParameters),
    ...options,
    refetchOnMount: false,
  });
}
