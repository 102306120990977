import type { Column } from "@tanstack/react-table";
import type { IntelScorecardFactDto } from "apis/oag";
import type { CSSProperties } from "react";

export const getCommonPinningStyles = (
  column: Column<IntelScorecardFactDto>,
): CSSProperties => {
  const isPinned = column.getIsPinned();

  return {
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    position: isPinned ? "sticky" : "relative",
    zIndex: isPinned ? 1 : 0,
    boxShadow: "none",
    transition: "box-shadow 0.3s ease",
  };
};
