import type {
  RigFleetPerformanceCardUserLensDto,
  ScoreSummaryLensDto,
} from "apis/oag";
import { Title } from "atoms/Typography";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { useUserLenses } from "hooks/lens/useUserLenses";
import * as Styled from "pages/RigLeaderboard/components/ScoreBreakout/styles";
import {
  BREAKOUT_BAR_HEIGHT_SMALL,
  BREAKOUT_BAR_WIDTH,
} from "pages/RigLeaderboard/components/utils";
import { Space } from "utils/componentLibrary";

import { HorizontalBars } from "./HorizontalBars";

export const TabsInner = ({
  barRatio,
  hourDisplay,
  lens,
}: {
  lens: ScoreSummaryLensDto;
  hourDisplay: string;
  barRatio: number;
}) => {
  const { data: userLenses } =
    useUserLenses<RigFleetPerformanceCardUserLensDto>({
      staleTime: Infinity,
    });
  const { data: standKpiTypes } = useKpiTypes();

  const getLabelByLensId = (lensId: number) => {
    if (!userLenses) return "";
    const userLens = userLenses.byId[lensId];
    return standKpiTypes?.byId[userLens.cardKpiType]?.shortName || "";
  };

  return (
    <Styled.TabInner key={lens.lensId}>
      <Title level={4}>{getLabelByLensId(lens.lensId) || " - - "}</Title>

      <Space size={12} key={lens.lensId}>
        <Title level={5}>{hourDisplay} </Title>
        <HorizontalBars
          height={BREAKOUT_BAR_HEIGHT_SMALL}
          width={BREAKOUT_BAR_WIDTH}
          ratio={barRatio}
        />
      </Space>
    </Styled.TabInner>
  );
};
