import IconWrapper from "icons/HOC";
import React from "react";

const IconSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#828C91">
          <g>
            <g>
              <g>
                <path
                  d="M3.75 12L9 6 14.25 12z"
                  transform="translate(-465 -152) translate(0 128) translate(456 15) translate(9 9) matrix(1 0 0 -1 0 18)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconWrapper(IconSVG);
