import { Title } from "atoms/Typography";
import styled from "styled-components";
import { Space } from "utils/componentLibrary";

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 775px);
  background-color: ${({ theme: { themeStyle } }) =>
    themeStyle.colors.active_tab_bg};
  display: flex;
  justify-content: center;
`;

export const StyledSpace = styled(Space)`
  padding: 80px 20px 0px 20px;
`;

export const StyledTitle = styled(Title)`
  width: 100%;
  font-size: 18px !important;
  font-weight: 500 !important;
`;

export const StyledText = styled(Title)`
  text-align: center;
  line-height: 20px !important;
`;
