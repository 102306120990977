/* eslint-disable react/no-multi-comp */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { UserDto } from "apis/oag";
import { ClaraApi, PromptType } from "apis/oag";
import { CopyButton } from "components/Clara/MessageThread/CopyButton/CopyButton";
import { FavoriteButton } from "components/Clara/MessageThread/FavoriteButton/FavoriteButton";
import * as Styled from "components/Clara/MessageThread/style";
import { PDComponent } from "components/PDComponents";
import { type Message } from "hooks/clara/useClara";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { Button, Tooltip } from "utils/componentLibrary";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const claraApi = new ClaraApi(apiConfig);
export const IndividualUserMessage = ({ message }: { message: Message }) => {
  const userInfo = useAppSelector((state) => state.global.userInfo);
  const isFavorite = message.isFavorite;
  const initials = `${userInfo?.firstName?.slice(0, 1)}${userInfo?.lastName?.slice(0, 1)}`;
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedMessage, setEditedMessage] = useState(message.message);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const anotherMessageIsInEditMode = useAppSelector(
    (state) => state.claraReducer.isEditMode,
  );
  const pendingMessage = useAppSelector(
    (state) => state.claraReducer.pendingMessage,
  );
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(false);

  const markPromptAsFavorite = useMutation({
    mutationFn: async ({
      prompt,
      userInfo,
    }: {
      prompt: Message;
      userInfo: UserDto;
    }) => {
      return claraApi.apiClaraPromptsFavoritePost({
        claraPromptDto: {
          id: 0,
          prompt: prompt.message,
          type: PromptType.Favorite,
          profileId: userInfo.activeProfileId,
          position: 0,
        },
      });
    },
  });

  const deleteFavoriteMutation = useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      return claraApi.apiClaraPromptsFavoriteIdDelete({ id });
    },
  });

  const toggleFavorite = useCallback(
    async (id: string | number, isFavorite: boolean) => {
      if (!userInfo || isLoadingFavorite) return;
      setIsLoadingFavorite(true);
      // update message history call the endpoint mutation
      // this needs to be async

      queryClient.setQueryData(
        [
          {
            uid: RequestUID.clara,
            type: PDQueryType.CLARA_CONVERSATION_HISTORY,
          },
        ],
        (oldData: Array<Message>) => {
          const messageIndex = oldData.findIndex(
            (dataMsg) => dataMsg.id === id,
          );
          oldData[messageIndex].isFavorite = !oldData[messageIndex].isFavorite;
          return oldData;
        },
      );

      if (isFavorite) {
        await deleteFavoriteMutation
          .mutateAsync({ id: id as number })
          .finally(() => {
            setIsLoadingFavorite(false);
          });
      } else {
        await queryClient.setQueryData(
          [
            {
              uid: RequestUID.clara,
              type: PDQueryType.CLARA_CONVERSATION_HISTORY,
            },
          ],
          (oldData: Array<Message>) => {
            const messageIndex = oldData.findIndex(
              (dataMsg) => dataMsg.id === id,
            );
            oldData[messageIndex].isFavorite = true;

            return oldData;
          },
        );

        await markPromptAsFavorite
          .mutateAsync({
            prompt: message,
            userInfo,
          })
          .then((receivedMessage) => {
            // Update the id of the message in the cache
            queryClient.setQueryData(
              [
                {
                  uid: RequestUID.clara,
                  type: PDQueryType.CLARA_CONVERSATION_HISTORY,
                },
              ],
              (oldData: Array<Message>) => {
                const messageIndex = oldData.findIndex(
                  (dataMsg) => dataMsg.id === id,
                );
                oldData[messageIndex].id = receivedMessage.id;
                return oldData;
              },
            );
          })
          .finally(() => {
            setIsLoadingFavorite(false);
          });
      }

      queryClient.refetchQueries({
        // Need to refetch the list of favorite prompts, for the other screen, so it can be paralell
        queryKey: [
          {
            uid: RequestUID.clara,
            type: PDQueryType.CLARA_CONVERSATION_PROMPTS,
          },
        ],
      });
    },
    [
      deleteFavoriteMutation,
      isLoadingFavorite,
      markPromptAsFavorite,
      message,
      queryClient,
      userInfo,
    ],
  );
  const setIsEditModeGlobally = useCallback(
    (isEditMode: boolean) => {
      dispatch({ type: "CLARA_SET_EDIT_MODE", payload: isEditMode });
      setIsEditMode(isEditMode);
    },
    [dispatch],
  );

  useEffect(() => {
    return () => {
      setIsEditModeGlobally(false);
    };
  }, [setIsEditModeGlobally]);

  const handleOnCancel = useCallback(() => {
    setEditedMessage(message.message);
    setIsEditModeGlobally(false);
  }, [message.message, setIsEditModeGlobally]);

  const handleOnUpdate = useCallback(() => {
    queryClient.setQueryData(
      [{ uid: RequestUID.clara, type: PDQueryType.CLARA_CONVERSATION_HISTORY }],
      (oldData: Array<Message>) => {
        const firstDeletableIndex = oldData.findIndex(
          (dataMsg) => dataMsg.id === message.id,
        );
        const newData = oldData.slice(0, firstDeletableIndex);

        dispatch({
          type: "SET_PENDING_MESSAGE",
          payload: { message: editedMessage, id: message.id },
        });
        return newData;
      },
    );

    setIsEditModeGlobally(false);
  }, [dispatch, editedMessage, message.id, queryClient, setIsEditModeGlobally]);

  return (
    <Styled.BoxInner>
      <Styled.Avatar $isResponse={false}>{initials}</Styled.Avatar>
      {isEditMode ? (
        <Styled.EditMessage
          value={editedMessage}
          onChange={(ev) => setEditedMessage(ev.target.value)}
          maxRows={7}
        >
          {editedMessage}
        </Styled.EditMessage>
      ) : (
        <Styled.Message $isResponse={false}>{message.message}</Styled.Message>
      )}
      <Styled.Controls $stripMargin={isEditMode}>
        {isEditMode ? (
          <Styled.UpdateButtonsContainer>
            <Button onClick={handleOnCancel} size="large">
              Cancel
            </Button>
            <Button
              onClick={handleOnUpdate}
              type="primary"
              disabled={!editedMessage.length}
              size="large"
            >
              Update
            </Button>
          </Styled.UpdateButtonsContainer>
        ) : (
          <>
            {anotherMessageIsInEditMode ? null : (
              <Tooltip placement="bottom" title="Edit">
                <Styled.SimpleButtonWrapper
                  onClick={() => setIsEditModeGlobally(true)}
                >
                  <PDComponent.SvgIcon name="edit" />
                </Styled.SimpleButtonWrapper>
              </Tooltip>
            )}

            <CopyButton message={message.message} />

            <FavoriteButton
              isLoading={isLoadingFavorite || !!pendingMessage}
              isFavorite={!!isFavorite}
              toggleFavorite={() => toggleFavorite(message.id, !!isFavorite)}
            />
          </>
        )}
      </Styled.Controls>
    </Styled.BoxInner>
  );
};
