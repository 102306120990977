import type {
  ScoreSummaryLensDto,
  ScoreSummaryRootDto,
  ScoreSummaryTabDto,
  ScoreSummaryTabGroupDto,
  UserLensTabDto,
} from "apis/oag";
import { TimeUnit } from "apis/oag";
import * as Styled from "pages/RigLeaderboard/components/ScoreBreakout/styles";
import { Fragment } from "react";
import { useTimeUom } from "utils/format";

import { TabsInner } from "./TabsInner";

export const CollapsibleInner = ({
  tab,
  getMaxTimeByBenchmarkType,
  getTimeByBenchmarkType,
  scoreSummary,
  lensTabs,
}: {
  tab: ScoreSummaryTabDto;
  getMaxTimeByBenchmarkType: (group: ScoreSummaryRootDto) => number;
  getTimeByBenchmarkType: (
    group: ScoreSummaryTabGroupDto | ScoreSummaryLensDto,
  ) => number;
  scoreSummary?: ScoreSummaryRootDto | null;
  lensTabs: UserLensTabDto[];
}) => {
  const hourUom = useTimeUom(TimeUnit.Hour);
  const hasData = tab.children?.some((lens) => getTimeByBenchmarkType(lens));
  const lensTab = lensTabs.find(({ id }) => id === tab.tabId);
  const name = lensTab?.shortName || "";
  const description = lensTab?.description || "";
  return hasData ? (
    <Styled.CollapsibleInner key={tab.tabId}>
      <Styled.InnerTitle>
        {name}
        {description ? <span> {description}</span> : null}
      </Styled.InnerTitle>
      <Styled.TabContainer>
        {tab.children?.map((lens) => (
          <Fragment key={lens.lensId}>
            {getTimeByBenchmarkType(lens) && scoreSummary ? (
              <TabsInner
                barRatio={
                  getTimeByBenchmarkType(lens) /
                  getMaxTimeByBenchmarkType(scoreSummary)
                }
                hourDisplay={`${getTimeByBenchmarkType(lens) > 0 ? "+" : ""}${hourUom.display(
                  getTimeByBenchmarkType(lens),
                )}`}
                lens={lens}
                key={lens.lensId}
              />
            ) : null}
          </Fragment>
        ))}
      </Styled.TabContainer>
    </Styled.CollapsibleInner>
  ) : null;
};
