import { IndividualMessage } from "components/Clara/MessageThread/IndividualMessage";
import { useClaraConversationHistory } from "hooks/clara/useClara";
import { memo, useMemo } from "react";

export const ChatHistory = memo(({ sessionId }: { sessionId: string }) => {
  const { data: messages } = useClaraConversationHistory();
  return useMemo(
    () =>
      messages.map((message, index) => (
        <IndividualMessage
          key={`${message.id}-${index}`}
          message={{ ...message, sessionId }}
        />
      )),
    [messages, sessionId],
  );
});
