import { AxisBottom } from "@visx/axis";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { Bar } from "@visx/shape";
import { Text as VisXText } from "@visx/text";
import type { DateDto, InspectionTvdPointDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import { Title } from "atoms/Typography";
import { DrillerComments } from "components/Lenses/common/InspectionView/DrillerComments";
import { InspectionChart } from "components/Lenses/common/InspectionView/InspectionChart";
import { getCurvesWithData } from "components/Lenses/common/InspectionView/StandKpis/utils";
import {
  ColLine,
  LineLegend,
  ResetContainer,
  StyledBottomAxisDiv,
  StyledChartBackground,
  StyledChartCol,
  StyledDescriptionContainer,
  StyledDetailsCol,
  StyledInspectionContainer,
  StyledLegendGroup,
  StyledRightContainerDiv,
  StyledRow,
  StyledSvg,
  StyledTitle,
  StyledXAxisLabelContainer,
  StyledYAxisDiv,
} from "components/Lenses/common/InspectionView/styles";
import type { Curve } from "components/Lenses/common/InspectionView/utils";
import {
  CHART_CONTAINER_X,
  GRAPH_HEIGHT_PX,
  STATUS_BAR_HEIGHT,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from "components/Lenses/common/InspectionView/utils";
import { ZoomChartContext } from "components/Lenses/common/LensZoom/ZoomChartContext";
import { Loader } from "components/Loader";
import { bisector } from "d3-array";
import useDiscontinuousTimeAxis from "hooks/charting/useDiscontinuousTimeAxis";
import { useCurrentUser } from "hooks/user/useCurrentUser";
import { useWellInspectionDetails } from "hooks/wells/useWellInspectionDetails";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { defaultDateDto } from "utils/common";
import { Row } from "utils/componentLibrary";
import { ALTERNATIVE_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime, toLocalWellTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import type { InspectionViewProps, LegendItem } from ".";

export const InspectionViewInner: React.FC<InspectionViewProps> = ({
  selectedStand,
  curvesGroup,
  valueUOM,
  lensId,
  lensTitle,
  totalDisplay,
  showDrillerComments,
  ControlButtons,
  standList,
  inspectionRef,
}) => {
  const curves = useMemo<Curve[]>(() => curvesGroup.flat(), [curvesGroup]);
  const depthUOM = useUOM(DimensionType.Metres);
  const [legendItems, setLegendItems] = useState<LegendItem[]>(
    curves.map((curve) => ({
      id: curve.key,
      label: curve.label,
      color: curve.color,
      isVisible: true,
    })),
  );

  const { data: currentUser } = useCurrentUser();

  const toggleLegendItemVisibility = useCallback((clickedItem: LegendItem) => {
    setLegendItems((legendItems) =>
      legendItems.map((item) => {
        return item.id === clickedItem.id
          ? {
              ...item,
              isVisible: !item.isVisible,
            }
          : item;
      }),
    );
  }, []);

  const zoomChartContext = useContext(ZoomChartContext);
  if (!zoomChartContext) {
    throw new Error("ZoomChartContext is not defined");
  }

  const { domain, initialDomain } = zoomChartContext;

  const [defaultData, setDefaultData] = useState<InspectionTvdPointDto[]>([]);

  const tsZoomLimits = useMemo(() => {
    if (defaultData.length === 0 || (domain?.[0] === 0 && domain?.[1] === 0))
      return [defaultDateDto.from, defaultDateDto.to] as [DateDto, DateDto];

    const startData = defaultData.filter(
      (d) => toLocalWellTime(d.at) <= (domain?.[0] ?? 0),
    );
    const endData = defaultData.filter(
      (d) => toLocalWellTime(d.at) >= (domain?.[1] ?? 0),
    );

    const start = startData[startData.length - 1]?.at ?? defaultData[0].at;
    const end = endData[0]?.at ?? defaultData[defaultData.length - 1].at;

    return [start, end] as [DateDto, DateDto];
  }, [domain, defaultData]);

  const { data, isFetching } = useWellInspectionDetails(
    selectedStand,
    tsZoomLimits,
    {
      enabled: !!selectedStand,
    },
  );

  useEffect(() => {
    setDefaultData((prev) => (prev.length === 0 ? data?.series ?? [] : prev));
  }, [data?.series]);

  const series = useMemo(() => data?.series ?? [], [data?.series]);

  const timeValueScale = useMemo(() => {
    const [min, max] = series?.length
      ? [
          toLocalWellTime(series[0].at),
          toLocalWellTime(series[series.length - 1].at),
        ]
      : [
          toLocalWellTime(defaultDateDto.from),
          toLocalWellTime(defaultDateDto.to),
        ];

    const currentDomain = domain?.every((x) => x !== 0) ? domain : [min, max];

    return scaleLinear<number>({
      domain: isFetching ? currentDomain : [min, max],
      range: [0, CHART_CONTAINER_X - 4 * Y_AXIS_WIDTH],
      clamp: true,
    });
  }, [domain, isFetching, series]);

  const { xScaleDate, chunksCount, getAverageChunkDate } =
    useDiscontinuousTimeAxis({
      plotWidth: CHART_CONTAINER_X - Y_AXIS_WIDTH * 4,
      xScaleDomain: [0, series.length - 1],
      series: series,
      overrideAxisLabelSize: 100,
    });

  const stateGuideBars = useMemo(() => {
    return data?.stand
      ? [
          {
            from: toLocalWellTime(series[0].at),
            to: toLocalWellTime(series[series.length - 1].at),
            isLight: true,
          },
          {
            from: toLocalWellTime(data.stand.startDate),
            to: toLocalWellTime(data.stand.endDate),
            isLight: false,
          },
        ]
      : [];
  }, [series, data?.stand]);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  // Hack to make sure the chart is rendered before the modal is shown
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 100);
  }, []);

  if (!data) return <Loader centered />;

  return (
    <StyledInspectionContainer ref={inspectionRef}>
      {isReady ? (
        <StyledRow wrap={false}>
          <StyledDetailsCol>
            <div>
              <StyledDescriptionContainer>
                <Title level={3}>
                  <strong>{lensTitle}</strong>
                </Title>
                <Title level={4}>{totalDisplay}</Title>
              </StyledDescriptionContainer>

              <StyledDescriptionContainer>
                <Title variant="faded" level={4}>
                  {depthUOM.display(selectedStand.startDepth)} to{" "}
                  {depthUOM.display(selectedStand.endDepth)}
                </Title>
                {selectedStand.startAt && selectedStand.endAt ? (
                  <Title variant="faded" level={4}>
                    {formatTime(selectedStand.startAt, {
                      formatStr: "MM/D HH:mm",
                    })}{" "}
                    to{" "}
                    {formatTime(selectedStand.endAt, {
                      formatStr: "MM/D HH:mm",
                    })}
                  </Title>
                ) : null}
              </StyledDescriptionContainer>
            </div>

            <div>
              {curvesGroup.map((curves, curvesIndex) => (
                <StyledLegendGroup key={curvesIndex} top={35}>
                  {curves.map((curve) => {
                    const item = legendItems.find(
                      (item) => item.id === curve.key,
                    );
                    return item ? (
                      <Row
                        onClick={() => toggleLegendItemVisibility(item)}
                        key={item.id}
                      >
                        <ColLine isVisible={item.isVisible}>
                          <LineLegend
                            dashed={curve.dashed}
                            backgroundColor={item.color}
                          />
                          <StyledTitle level={5} $lineHeight="1.75em">
                            {item.label} ({curve.valueTransformer.abbr})
                          </StyledTitle>
                        </ColLine>
                      </Row>
                    ) : null;
                  })}
                </StyledLegendGroup>
              ))}
            </div>
          </StyledDetailsCol>

          <StyledChartCol>
            <ResetContainer>
              {ControlButtons &&
              !(
                domain?.[0] === initialDomain?.[0] &&
                domain?.[1] === initialDomain?.[1]
              ) ? (
                <ControlButtons />
              ) : null}
            </ResetContainer>
            <StyledRightContainerDiv>
              <StyledYAxisDiv />
              <StyledYAxisDiv left={Y_AXIS_WIDTH} />
              <StyledChartBackground />
              <StyledYAxisDiv
                leftBorder
                left={CHART_CONTAINER_X - 2 * Y_AXIS_WIDTH}
              />
              <StyledYAxisDiv left={CHART_CONTAINER_X - Y_AXIS_WIDTH} />
              <StyledSvg
                width={CHART_CONTAINER_X}
                height={STATUS_BAR_HEIGHT}
                bordered
                top={-1}
              >
                <Group>
                  {stateGuideBars.map((bar, i) => {
                    return (
                      <Bar
                        key={`bar-${i}`}
                        x={2 * Y_AXIS_WIDTH + timeValueScale(bar.from)}
                        width={
                          timeValueScale(bar.to) - timeValueScale(bar.from)
                        }
                        height={STATUS_BAR_HEIGHT}
                        opacity={bar.isLight ? 0.5 : 0.8}
                        fill={themeColors.disabled_typography}
                      />
                    );
                  })}
                </Group>
              </StyledSvg>

              {curvesGroup.map((curves, index) => (
                <InspectionChart
                  key={`bar-${index}`}
                  data={data}
                  curves={getCurvesWithData(series, curves)}
                  top={index * GRAPH_HEIGHT_PX + STATUS_BAR_HEIGHT}
                  legendItems={legendItems}
                  timeValueScale={timeValueScale}
                />
              ))}
            </StyledRightContainerDiv>
            <StyledBottomAxisDiv>
              <StyledXAxisLabelContainer> Date time </StyledXAxisLabelContainer>
              <StyledSvg width={CHART_CONTAINER_X} height={X_AXIS_HEIGHT}>
                <AxisBottom
                  tickLength={1}
                  hideAxisLine
                  tickStroke={themeColors.disabled_typography}
                  stroke={themeColors.disabled_typography}
                  scale={xScaleDate}
                  numTicks={chunksCount}
                  left={Y_AXIS_WIDTH * 2}
                  tickFormat={(value) => {
                    const chunkDate = getAverageChunkDate(value);
                    return chunkDate
                      ? formatTime(chunkDate, {
                          formatStr: ALTERNATIVE_DATE_FORMAT,
                        })
                      : "";
                  }}
                  tickComponent={(props) => {
                    if (!props.formattedValue) return null;
                    const timeAxisPosition = props.x;
                    const cumulativeTimeForPosition =
                      timeValueScale.invert(timeAxisPosition);
                    const index = bisector<InspectionTvdPointDto, number>((d) =>
                      toLocalWellTime(d?.at),
                    ).left(series, cumulativeTimeForPosition, 1);
                    const d0 = series[index - 1];
                    const d1 = series[index];

                    let d = d0;
                    if (d1 && toLocalWellTime(d1?.at) && d0) {
                      d =
                        cumulativeTimeForPosition - toLocalWellTime(d0?.at) >
                        toLocalWellTime(d1?.at) - cumulativeTimeForPosition
                          ? d1
                          : d0;
                    }

                    const crtDate = formatTime(d.at, {
                      formatStr: ALTERNATIVE_DATE_FORMAT,
                    });
                    return (
                      <Group>
                        <VisXText
                          x={props.x}
                          y={props.y}
                          dy={12}
                          dx={-36}
                          fontSize={12}
                          fill={themeColors.disabled_typography}
                          pointerEvents="none"
                        >
                          {crtDate}
                        </VisXText>
                      </Group>
                    );
                  }}
                />
              </StyledSvg>
            </StyledBottomAxisDiv>
          </StyledChartCol>
        </StyledRow>
      ) : null}
      {currentUser?.canAccessRigScorecard && showDrillerComments ? (
        <DrillerComments
          lensId={lensId}
          lensTitle={lensTitle}
          selectedStand={selectedStand}
          standList={standList}
          valueUOM={valueUOM}
        />
      ) : null}
    </StyledInspectionContainer>
  );
};
