import styled from "styled-components";

export const MiniLoadingChartParameterGrid = styled.div<{
  trackCount: number;
  hasChartPlaceholder: string;
}>`
  height: 100%;
  min-height: ${(props) => `${props.trackCount * 90}px`};
  width: 100%;
  overflow-y: auto;
  display: grid;
  padding-bottom: 40px;
  grid-template-rows: repeat(
    ${(props) => props.trackCount},
    ${(props) => `calc(${100 / props.trackCount}%)`}
  );
  grid-template-columns: 60px ${(props) =>
      props.hasChartPlaceholder === "true" ? "237px 0 1fr" : "1frx"};
`;

export const ParameterLoadingContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ParameterToolbarContainer = styled.div`
  width: 100%;
  height: 35px;
  position: absolute;
  background: ${({ theme }) => theme.themeStyle.colors.secondary_chart_bg};
  bottom: 0;
  left: 0;
`;

export const KpiLabel = styled.div`
  background: rgba(13, 155, 131, 0.15);
  height: 100%;
  width: 100%;
  border-right: 1px solid rgba(130, 140, 145, 0.1);
  border-bottom: 1px solid rgba(130, 140, 145, 0.1);
  position: relative;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  & > div {
    word-spacing: 99999px;
    position: absolute;
    transform-origin: 0 0;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: rotate(270deg) translate(-50%, -50%);
    font-size: 16px;
    font-weight: 500;
  }
`;

export const LabelContainer = styled.div`
  background: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.4px;
  border-right: 1px solid rgba(130, 140, 145, 0.1);
  border-bottom: 1px solid rgba(130, 140, 145, 0.1);

  div:nth-child(2) {
    font-size: 12px;
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  }
`;

export const ChartPlaceholder = styled.div`
  background: ${({ theme }) => theme.themeStyle.colors.primary_chart_bg};
  border-bottom: 1px solid rgba(130, 140, 145, 0.1);
`;
