import type { IconName } from "icons/autogeneratedTypes";
import { IconContainer } from "icons/styles";
import React from "react";
import type colors from "utils/colors";

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  name: IconName;
  prefix?: string | undefined;
  className?: string;
  /**
   * @deprecated Favor using `height`, `width`, `color` props instead.
   */
  style?: React.CSSProperties;
  svgStyle?: React.CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  color?: keyof typeof colors | string;
  width?: number | string;
  height?: number | string;
}

export function _SvgIcon({
  name,
  width: initialWidth,
  height: initialHeight,
  className = "",
  prefix = "icon",
  color = "currentColor",
  style: initialStyle,
  svgStyle,
  ...props
}: ContainerProps) {
  let style = initialStyle;
  let width = initialWidth || style?.width;
  let height = initialHeight || style?.height || style?.fontSize;

  const symbolId = `#${prefix}-${name}`;
  if (!name) return null;
  if (color && color !== "currentColor" && !style?.color) {
    style = { ...style, color };
  }

  if (width && !height) {
    height = width;
  } else if (height && !width) {
    width = height;
  } else if (!width && !height) {
    width = "1em";
    height = "1em";
  }

  return (
    <IconContainer
      {...props}
      className={`pd-icon ${className}`}
      style={style}
      data-name={name}
    >
      <svg height={height} width={width} aria-hidden="true" style={svgStyle}>
        <use href={symbolId} fill={color} />
      </svg>
    </IconContainer>
  );
}
