/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IntelScorecardWellDetailsDto } from './IntelScorecardWellDetailsDto';
import {
    IntelScorecardWellDetailsDtoFromJSON,
    IntelScorecardWellDetailsDtoFromJSONTyped,
    IntelScorecardWellDetailsDtoToJSON,
} from './IntelScorecardWellDetailsDto';
import type { IntelGroupingKeyPair } from './IntelGroupingKeyPair';
import {
    IntelGroupingKeyPairFromJSON,
    IntelGroupingKeyPairFromJSONTyped,
    IntelGroupingKeyPairToJSON,
} from './IntelGroupingKeyPair';
import type { IntelScorecardProductivityDetailsDto } from './IntelScorecardProductivityDetailsDto';
import {
    IntelScorecardProductivityDetailsDtoFromJSON,
    IntelScorecardProductivityDetailsDtoFromJSONTyped,
    IntelScorecardProductivityDetailsDtoToJSON,
} from './IntelScorecardProductivityDetailsDto';

/**
 * 
 * @export
 * @interface IntelScorecardFactDto
 */
export interface IntelScorecardFactDto {
    /**
     * 
     * @type {IntelGroupingKeyPair}
     * @memberof IntelScorecardFactDto
     */
    key: IntelGroupingKeyPair;
    /**
     * 
     * @type {number}
     * @memberof IntelScorecardFactDto
     */
    measuredDepth: number;
    /**
     * 
     * @type {number}
     * @memberof IntelScorecardFactDto
     */
    drillingTime: number;
    /**
     * 
     * @type {number}
     * @memberof IntelScorecardFactDto
     */
    productivity: number;
    /**
     * 
     * @type {number}
     * @memberof IntelScorecardFactDto
     */
    quartile: number;
    /**
     * 
     * @type {IntelScorecardWellDetailsDto}
     * @memberof IntelScorecardFactDto
     */
    wellDetails?: IntelScorecardWellDetailsDto | null;
    /**
     * 
     * @type {IntelScorecardProductivityDetailsDto}
     * @memberof IntelScorecardFactDto
     */
    basin?: IntelScorecardProductivityDetailsDto | null;
    /**
     * 
     * @type {IntelScorecardProductivityDetailsDto}
     * @memberof IntelScorecardFactDto
     */
    operator?: IntelScorecardProductivityDetailsDto | null;
}

/**
 * Check if a given object implements the IntelScorecardFactDto interface.
 */
export function instanceOfIntelScorecardFactDto(value: object): value is IntelScorecardFactDto {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('measuredDepth' in value) || value['measuredDepth'] === undefined) return false;
    if (!('drillingTime' in value) || value['drillingTime'] === undefined) return false;
    if (!('productivity' in value) || value['productivity'] === undefined) return false;
    if (!('quartile' in value) || value['quartile'] === undefined) return false;
    return true;
}

export function IntelScorecardFactDtoFromJSON(json: any): IntelScorecardFactDto {
    return IntelScorecardFactDtoFromJSONTyped(json, false);
}

export function IntelScorecardFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelScorecardFactDto {
    if (json == null) {
        return json;
    }
    return {
        
        'key': IntelGroupingKeyPairFromJSON(json['Key']),
        'measuredDepth': json['MeasuredDepth'],
        'drillingTime': json['DrillingTime'],
        'productivity': json['Productivity'],
        'quartile': json['Quartile'],
        'wellDetails': json['WellDetails'] == null ? undefined : IntelScorecardWellDetailsDtoFromJSON(json['WellDetails']),
        'basin': json['Basin'] == null ? undefined : IntelScorecardProductivityDetailsDtoFromJSON(json['Basin']),
        'operator': json['Operator'] == null ? undefined : IntelScorecardProductivityDetailsDtoFromJSON(json['Operator']),
    };
}

export function IntelScorecardFactDtoToJSON(value?: IntelScorecardFactDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Key': IntelGroupingKeyPairToJSON(value['key']),
        'MeasuredDepth': value['measuredDepth'],
        'DrillingTime': value['drillingTime'],
        'Productivity': value['productivity'],
        'Quartile': value['quartile'],
        'WellDetails': IntelScorecardWellDetailsDtoToJSON(value['wellDetails']),
        'Basin': IntelScorecardProductivityDetailsDtoToJSON(value['basin']),
        'Operator': IntelScorecardProductivityDetailsDtoToJSON(value['operator']),
    };
}

