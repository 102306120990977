import styled from "styled-components";

export const CenterLoader = styled.div`
    height: 400px;
    width: 390px;
    display: grid;
    place-items: center;
`


export const Grid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  & > div:first-child {
    grid-column: span 2;
  }
`;