import { useIsFetching } from "@tanstack/react-query";
import type { RigScorecardUserLensDto } from "apis/oag";
import { DashboardType } from "apis/oag";
import { useUserLensTabs } from "hooks/dashboard/useUserLensTabs";
import { useUserLenses } from "hooks/lens/useUserLenses";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useRigToWellsSuspended } from "hooks/rigs/useRigToWells";
import { max } from "lodash";
import { NUM_DEFAULT_RECENT_WELLS } from "pages/RigScoreCard/Header";
import { LensTabGroup } from "pages/RigScoreCard/LeftPane/components/LensTabGroup";
import PaneHeader from "pages/RigScoreCard/LeftPane/components/PaneHeader";
import { LensGroupContainer } from "pages/RigScoreCard/LeftPane/Styled";
import { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { useAppDispatch } from "reducers/store";

const LeftPane = () => {
  const { data: lensTabs } = useUserLensTabs(DashboardType.RigScorecard, {
    staleTime: Infinity,
  });

  const [, setRigSelectedWells] = useStateQuery<Array<number> | null>(
    URL_STATE_PARAM.SELECTED_WELLS_RIG_SCORECARD,
    [],
    [URL_STATE_PARAM.SELECTED_WELLS_RIG_WIDGET],
    true,
  );

  const { data: availableWells } = useRigToWellsSuspended({
    isFilteringIgnored: true,
  });
  const [additionalWell] = useStateQuery<number | null>(
    URL_STATE_PARAM.ADDITIONAL_SELECTED_WELLS_RIG_SCORECARD,
    null,
  );

  useEffect(() => {
    if (availableWells) {
      const currentWells = additionalWell
        ? [
            ...new Set([
              ...(availableWells?.slice(0, NUM_DEFAULT_RECENT_WELLS) ?? []),
              +additionalWell,
            ]),
          ]
        : availableWells?.slice(0, NUM_DEFAULT_RECENT_WELLS);

      setRigSelectedWells(currentWells);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableWells]);
  const dispatch = useAppDispatch();

  const { data: lenses } = useUserLenses<RigScorecardUserLensDto>();

  const lensesGroups = useMemo(() => {
    if (!lensTabs || !lenses?.byTab) return [];
    return lensTabs.map((lensTab) => ({
      title: lensTab.name || "",
      id: lensTab.id,
      lenses: lenses.byTab[lensTab.id],
      description: lensTab.description || "",
    }));
  }, [lensTabs, lenses]);

  const numCombinedGroups = 2;
  const breakpoint = useMemo(() => {
    const titleFilter = "BOPE";
    return lensesGroups.findIndex((e) => e?.title?.includes(titleFilter));
  }, [lensesGroups]);

  const innerBoxRefArray = useRef<Array<HTMLDivElement>>(
    Array.from({
      length:
        lensesGroups.reduce((a, b) => a + (b.lenses?.length ?? 0), 0) ?? 0,
    }),
  );
  const outerBoxRef = useRef<Array<HTMLDivElement>>(
    Array.from({ length: lensesGroups?.length ?? 0 }),
  );
  const [notificationSelectedWellId] = useStateQuery<number | undefined>(
    URL_STATE_PARAM.ADDITIONAL_SELECTED_WELLS_RIG_SCORECARD,
    undefined,
  );
  const [notificationCardId] = useStateQuery<number | undefined>(
    URL_STATE_PARAM.NOTIFICATION_SCORE_CARD_GROUP_ID,
    undefined,
  );
  const [notificationKpiId] = useStateQuery<number | undefined>(
    URL_STATE_PARAM.NOTIFICATION_SCORE_CARD_KPI_ID,
    undefined,
  );
  const hasTriggeredScroll = useRef(false);
  const isFetchingNotificationData = useIsFetching();
  useLayoutEffect(() => {
    // check data finished loading
    if (
      !isFetchingNotificationData &&
      !hasTriggeredScroll.current &&
      notificationSelectedWellId !== undefined &&
      notificationKpiId !== undefined &&
      notificationCardId !== undefined
    ) {
      hasTriggeredScroll.current = true;
      // setSelectedTab(SelectedTab.Tasks);
      dispatch({
        type: "ADD_FACT_INFO",
        payload: {
          wellId: +notificationSelectedWellId,
          kpiId: +notificationKpiId,
          cardGroupId: +notificationCardId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetchingNotificationData,
    notificationCardId,
    notificationKpiId,
    notificationSelectedWellId,
  ]);
  useEffect(() => {
    if (!innerBoxRefArray.current || !outerBoxRef.current) return;
    const handleClickOutside = (el: MouseEvent) => {
      const target = el.target as HTMLElement;
      if (!target) return null;
      const isOutside = outerBoxRef.current.some((e) => e?.contains?.(target));
      const isInside = innerBoxRefArray.current.some((e) =>
        e?.contains?.(target),
      );
      if (isOutside && !isInside) {
        dispatch({
          type: "ADD_FACT_INFO",
          payload: undefined,
        });
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dispatch]);

  const maxLen = useMemo(() => {
    return max(lensesGroups.map((e) => e.lenses?.length || 0));
  }, [lensesGroups]);

  return (
    <>
      <PaneHeader />
      <div
        style={{
          overflow: "hidden",
          width: "100%",
        }}
      >
        {lensesGroups.slice(0, breakpoint).map((lensGroup, index) => (
          <LensTabGroup
            key={lensGroup.id}
            index={index}
            lensGroup={lensGroup}
            lensesGroups={lensesGroups}
            maxLen={maxLen}
            refs={{
              innerBoxRefArray,
              outerBoxRef,
            }}
          />
        ))}

        <LensGroupContainer>
          {lensesGroups
            .slice(breakpoint, breakpoint + numCombinedGroups)
            .map((lensGroup, index) => (
              <LensTabGroup
                key={lensGroup.id}
                index={index + breakpoint}
                lensGroup={lensGroup}
                lensesGroups={lensesGroups}
                maxLen={maxLen}
                refs={{
                  innerBoxRefArray,
                  outerBoxRef,
                }}
              />
            ))}
        </LensGroupContainer>

        {lensesGroups
          .slice(breakpoint + numCombinedGroups, lensesGroups?.length || 0)
          .map((lensGroup, index) => (
            <LensTabGroup
              key={lensGroup.id}
              index={index + breakpoint + numCombinedGroups}
              lensGroup={lensGroup}
              lensesGroups={lensesGroups}
              maxLen={maxLen}
              refs={{
                innerBoxRefArray,
                outerBoxRef,
              }}
            />
          ))}
      </div>
    </>
  );
};

export default LeftPane;
