import { Group } from "@visx/group";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useCustomTheme } from "utils/useTheme";
export interface ILabelProps {
  barHeight: number;
  barX: number;
  barY: number;
  columnWidth: number;
  detailed?: boolean;
  index: number;
  innerLabel?: boolean;
  value: string;
  label: boolean;
  topLabel?: boolean;
}

const StyledText = styled.text<{ $topLabel: boolean }>`
  ${({ $topLabel }) =>
    $topLabel &&
    css`
      tspan {
        stroke-width: 2px;
        stroke: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
        paint-order: stroke;
      }
    `}
`;

const PILL_PADDING = 2;
const TOPLABEL_MARGIN = 5;

export const Label: React.VFC<ILabelProps> = React.memo(
  ({
    index,
    topLabel,
    barHeight,
    barX,
    barY,
    columnWidth,
    value,
    label,
    innerLabel,
  }) => {
    const {
      themeStyle: { colors: themeColors },
    } = useCustomTheme();

    const textRef = useRef<SVGTextElement>(null);
    const [textWidth, setTextWidth] = useState<number | null>(null);
    const [textHeight, setTextHeight] = useState<number | null>(null);

    // Label rejection conditions
    // TODO: revisit this to simplify the logic
    const isHidden = useMemo(() => {
      return (
        !label ||
        value.length < 1 ||
        (barHeight === 0 && !topLabel) ||
        (innerLabel && barHeight < 15) ||
        (innerLabel && columnWidth < 34) ||
        (Math.abs(barHeight) < 30 && !topLabel) ||
        columnWidth < 6 ||
        (columnWidth < 34 && columnWidth >= 20 && index % 2 !== 0) ||
        (columnWidth < 20 && columnWidth >= 12 && index % 5 !== 0) ||
        (columnWidth < 12 && columnWidth >= 6 && index % 6 !== 0)
      );
    }, [barHeight, columnWidth, index, value, innerLabel, label, topLabel]);

    useEffect(() => {
      const refWidth = Math.round(textRef.current?.getBBox()?.width || 0) || 0;
      const refHeight =
        Math.round(textRef.current?.getBBox()?.height || 0) || 0;
      if (refWidth > 0 && textWidth === null) {
        setTextWidth(refWidth);
        setTextHeight(refHeight);
      }
    }, [textWidth, isHidden]);

    if (isHidden) {
      return null;
    }

    return (
      <Group>
        {textWidth && textHeight ? (
          <rect
            id={value + "label-bg"}
            x={barX + columnWidth / 2 - textWidth / 2 - PILL_PADDING}
            y={
              barY -
              textHeight / 2 +
              (innerLabel
                ? PILL_PADDING
                : -(TOPLABEL_MARGIN + textHeight + PILL_PADDING))
            }
            width={textWidth + PILL_PADDING * 2}
            height={textHeight + PILL_PADDING}
            fill={themeColors.label_pill}
            rx={2}
          />
        ) : null}

        <StyledText
          ref={textRef}
          $topLabel={!!topLabel}
          fontSize="14px"
          fontWeight={400}
          fill={themeColors.inner_label}
          width={columnWidth}
          height={textHeight || 0}
          x={barX + columnWidth / 2 - (textWidth || 0) / 2}
          y={
            barY +
            (textHeight || 0) / 2 +
            (innerLabel
              ? 0
              : -(TOPLABEL_MARGIN + (textHeight || 0) + PILL_PADDING * 2))
          }
          style={{ pointerEvents: "none" }}
        >
          {value}
        </StyledText>
      </Group>
    );
  },
);
