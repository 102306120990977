export const MockWedgeKpiSvg = () => {
  return (
    <svg
      width="326"
      height="125"
      viewBox="0 0 326 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2565_68421)">
        <path
          d="M69 125C69 99.8044 78.9035 75.6408 96.532 57.8249C114.16 40.0089 138.07 30 163 30C187.93 30 211.84 40.0089 229.468 57.8248C247.096 75.6408 257 99.8044 257 125L210 125C210 112.402 205.048 100.32 196.234 91.4124C187.42 82.5044 175.465 77.5 163 77.5C150.535 77.5 138.58 82.5045 129.766 91.4124C120.952 100.32 116 112.402 116 125H69Z"
          fill="#064B3F"
        />
        <path
          d="M69 125C69 103.226 76.4008 82.1136 89.9634 65.1958C103.526 48.278 122.427 36.5821 143.503 32.066C164.579 27.5499 186.551 30.4877 205.739 40.3876C224.928 50.2875 240.17 66.5486 248.912 86.4489L205.956 105.725C201.585 95.7744 193.964 87.6438 184.37 82.6939C174.775 77.7439 163.79 76.275 153.252 78.5331C142.714 80.7911 133.263 86.6391 126.482 95.098C119.7 103.557 116 114.113 116 125H69Z"
          fill="#096C5B"
        />
        <path
          d="M69 125C69 107.686 73.6822 90.7003 82.5405 75.8799C91.3988 61.0595 104.096 48.9676 119.261 40.911C134.425 32.8544 151.48 29.1395 168.582 30.1677C185.684 31.1959 202.183 36.9282 216.296 46.7451L189.648 85.8726C182.592 80.9641 174.342 78.098 165.791 77.5839C157.24 77.0697 148.713 78.9272 141.13 82.9555C133.548 86.9838 127.199 93.0298 122.77 100.44C118.341 107.85 116 116.343 116 125H69Z"
          fill="#0D9B83"
        />
        <path
          d="M69 125C69 105.781 74.7681 87.0133 85.5426 71.1762C96.317 55.3391 111.591 43.1772 129.348 36.2966L146.174 80.6484C137.296 84.0887 129.659 90.1696 124.271 98.0881C118.884 106.007 116 115.39 116 125H69Z"
          fill="#89CEC2"
        />
      </g>
      <defs>
        <clipPath id="clip0_2565_68421">
          <rect
            width="188"
            height="95"
            fill="white"
            transform="translate(69 30)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
