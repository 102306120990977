import { scaleLinear } from "@visx/scale";
import type {
  ParameterByDepthKpiComparisonDto,
  ParameterByDepthUserLensDto,
  ParameterByDepthUserLensTrackItemDto,
  StickSlipByDepthSetDto,
  StickSlipByDepthUserLensDto,
} from "apis/oag";
import type { ScrollbarRange } from "components/Lenses/common/Scrollbar";
import { getTracksById } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/utils/getTracksById";
import { extent } from "d3-array";
import { useMemo } from "react";

interface BaseParams {
  selectedTrackItems: ParameterByDepthUserLensTrackItemDto[];
  lens: ParameterByDepthUserLensDto | StickSlipByDepthUserLensDto;
  scrollState: ScrollbarRange;
  chartWidth: number;
}

interface ByDepthComparisonParams extends BaseParams {
  data: ParameterByDepthKpiComparisonDto;
}

interface StickSlipParams extends BaseParams {
  data: StickSlipByDepthSetDto;
}

export const useTransformedData = ({
  data,
  selectedTrackItems,
  lens,
  chartWidth,
  scrollState,
}: ByDepthComparisonParams | StickSlipParams) => {
  const tracksById = useMemo(() => getTracksById(data), [data]);
  const flatTracks = useMemo(
    () =>
      selectedTrackItems.flatMap((item) =>
        tracksById && item?.trackId
          ? (tracksById[item.trackId] || [])
              .flatMap((track) => track.trackValues)
              .sort((a, b) => a.holeDepth - b.holeDepth) || []
          : [],
      ),
    [selectedTrackItems, tracksById],
  );

  const depthScale = useMemo(() => {
    const [min = 0, max = 0] = extent(flatTracks, (track) => track.holeDepth);
    return scaleLinear<number>({
      range: [0, chartWidth],
      domain: lens.squeezesDisplay
        ? [min, max]
        : [scrollState.startX, scrollState.endX],
    });
  }, [
    chartWidth,
    flatTracks,
    scrollState.endX,
    scrollState.startX,
    lens.squeezesDisplay,
  ]);

  return { flatTracks, depthScale, tracksById };
};
