export const MockPowerLoadEfficiencySvg = () => {
  return (
    <svg
      width="326"
      height="126"
      viewBox="0 0 326 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2788_72368)">
        <path
          opacity="0.1"
          d="M216.5 55.5C216.5 45.687 220.398 36.2759 227.337 29.337C234.276 22.3982 243.687 18.5 253.5 18.5C263.313 18.5 272.724 22.3982 279.663 29.337C286.602 36.2759 290.5 45.687 290.5 55.5L273.48 55.5C273.48 50.201 271.375 45.119 267.628 41.372C263.881 37.625 258.799 35.52 253.5 35.52C248.201 35.52 243.119 37.625 239.372 41.372C235.625 45.119 233.52 50.201 233.52 55.5L216.5 55.5Z"
          fill="#E8E8EA"
        />
        <path
          d="M216.5 55.5C216.5 47.2887 219.231 39.3107 224.264 32.8224C229.297 26.3342 236.345 21.7045 244.298 19.6624L248.531 36.1477C244.236 37.2504 240.43 39.7505 237.713 43.2541C234.995 46.7578 233.52 51.0659 233.52 55.5L216.5 55.5Z"
          fill="#0D9B83"
        />
      </g>
      <rect
        x="245.5"
        y="41.5"
        width="16"
        height="8"
        rx="2"
        fill="#828C91"
        fillOpacity="0.4"
      />
      <rect
        x="290.5"
        y="18.5"
        width="8"
        height="8"
        rx="2"
        fill="#828C91"
        fillOpacity="0.4"
      />
      <rect
        x="201"
        y="12"
        width="104"
        height="48"
        rx="0.5"
        stroke="#828C91"
        strokeOpacity="0.4"
      />
      <g clipPath="url(#clip1_2788_72368)">
        <path
          opacity="0.1"
          d="M46.5 93.5C46.5 77.0566 53.0321 61.2866 64.6594 49.6594C76.2866 38.0321 92.0566 31.5 108.5 31.5C124.943 31.5 140.713 38.0321 152.341 49.6594C163.968 61.2866 170.5 77.0566 170.5 93.5L141.98 93.5C141.98 84.6205 138.453 76.1048 132.174 69.8261C125.895 63.5473 117.379 60.02 108.5 60.02C99.6205 60.02 91.1048 63.5473 84.8261 69.8261C78.5473 76.1048 75.02 84.6206 75.02 93.5L46.5 93.5Z"
          fill="#E8E8EA"
        />
        <path
          d="M46.5 93.5C46.5 82.0557 49.6676 70.8347 55.6519 61.0797C61.6362 51.3247 70.2037 43.4164 80.4056 38.2306C90.6076 33.0448 102.046 30.7839 113.453 31.6982C124.861 32.6125 135.793 36.6665 145.039 43.411L128.231 66.4519C123.238 62.8099 117.335 60.6208 111.175 60.127C105.015 59.6333 98.8381 60.8542 93.329 63.6545C87.82 66.4549 83.1935 70.7253 79.962 75.993C76.7305 81.2607 75.02 87.3201 75.02 93.5L46.5 93.5Z"
          fill="#0D9B83"
        />
      </g>
      <rect
        x="96.5"
        y="74.5"
        width="24"
        height="12"
        rx="2"
        fill="#828C91"
        fillOpacity="0.4"
      />
      <rect
        x="180.5"
        y="19.5"
        width="8"
        height="8"
        rx="2"
        fill="#828C91"
        fillOpacity="0.4"
      />
      <rect
        x="21"
        y="12"
        width="174"
        height="102"
        rx="0.5"
        stroke="#828C91"
        strokeOpacity="0.4"
      />
      <g clipPath="url(#clip2_2788_72368)">
        <path
          opacity="0.1"
          d="M216.5 109.5C216.5 99.687 220.398 90.2759 227.337 83.337C234.276 76.3982 243.687 72.5 253.5 72.5C263.313 72.5 272.724 76.3982 279.663 83.337C286.602 90.2759 290.5 99.687 290.5 109.5L273.48 109.5C273.48 104.201 271.375 99.119 267.628 95.372C263.881 91.625 258.799 89.52 253.5 89.52C248.201 89.52 243.119 91.625 239.372 95.372C235.625 99.119 233.52 104.201 233.52 109.5L216.5 109.5Z"
          fill="#E8E8EA"
        />
        <path
          d="M216.5 109.5C216.5 102.67 218.39 95.9739 221.962 90.1524C225.533 84.3309 230.646 79.6114 236.734 76.5166C242.822 73.4219 249.648 72.0726 256.456 72.6183C263.264 73.1639 269.788 75.5832 275.305 79.6082L265.275 93.3584C262.295 91.1849 258.773 89.8785 255.096 89.5839C251.42 89.2892 247.734 90.0178 244.446 91.689C241.159 93.3602 238.398 95.9087 236.469 99.0523C234.541 102.196 233.52 105.812 233.52 109.5L216.5 109.5Z"
          fill="#0D9B83"
        />
      </g>
      <rect
        x="245.5"
        y="95.5"
        width="16"
        height="8"
        rx="2"
        fill="#828C91"
        fillOpacity="0.4"
      />
      <rect
        x="290.5"
        y="72.5"
        width="8"
        height="8"
        rx="2"
        fill="#828C91"
        fillOpacity="0.4"
      />
      <rect
        x="201"
        y="66"
        width="104"
        height="48"
        rx="0.5"
        stroke="#828C91"
        strokeOpacity="0.4"
      />
      <defs>
        <clipPath id="clip0_2788_72368">
          <rect
            width="74"
            height="37"
            fill="white"
            transform="translate(216.5 18.5)"
          />
        </clipPath>
        <clipPath id="clip1_2788_72368">
          <rect
            width="124"
            height="62"
            fill="white"
            transform="translate(46.5 31.5)"
          />
        </clipPath>
        <clipPath id="clip2_2788_72368">
          <rect
            width="74"
            height="37"
            fill="white"
            transform="translate(216.5 72.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
