import { InteractionRequiredAuthError } from "@azure/msal-browser";
import type { FetchParams, Middleware, RequestContext } from "apis/oag";
import { Configuration } from "apis/oag";
import { msalInstance } from "msalInstance";
import { loginRequest } from "utils/auth/authConfig";
import { msToSeconds, secondsInMinute } from "utils/common";

export const API_REFRESH_INTERVAL = msToSeconds * secondsInMinute * 2; // 2 minutes

const authAccessTokenMiddleware: Middleware = {
  async pre(context: RequestContext): Promise<FetchParams | void> {
    const isReport = sessionStorage.getItem("isReport");

    // Check for and inject ReportToken if we are in report mode
    if (isReport) {
      const reportToken = new URLSearchParams(location.search).get(
        "ReportToken",
      );
      if (reportToken) {
        (context.init.headers as Record<string, string>)["ReportToken"] =
          reportToken;
        return context; // stop here, report token is enough
      }

      // If in `isReport` context but without ReportToken in query, continue with regular flow
      // It will:
      //  - Fail when generating report in headless browser
      //  - Succeed when loading manually a prepare page for debug
      //
      // Both scenarios and result above are valid flows
    }

    const allAccounts = msalInstance.getAllAccounts();

    if (!allAccounts.length) {
      throw Error(
        "ACCOUNT NOT FOUND! NO USER HAS EVER SIGNED IN! Should redirect to sign-in page.",
      );
    }

    const account = msalInstance.getActiveAccount() ?? allAccounts[0];

    try {
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account,
      });

      if (response?.accessToken) {
        (context.init.headers as Record<string, string>).Authorization =
          `Bearer ${response?.accessToken}`;
      }
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      (context.init.headers as Record<string, string>).Timezone = timezone;

      return context;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect({
          ...loginRequest,
        });
        window.location.href = "/auth/401/apiConfigCatch";
      }
    }
  },
};

export const apiConfig = new Configuration({
  middleware: [authAccessTokenMiddleware],
});
