import {
  SCROLLBAR_GAP_AROUND,
  SCROLLBAR_GAP_PX,
} from "components/Layout/LayoutUtils";
import { PDComponent } from "components/PDComponents";
import styled from "styled-components";
import colors from "utils/colors";
import { responsiveBreakpoints } from "utils/common";
import { Row } from "utils/componentLibrary";

// eslint-disable-next-line local-rules/styles-location
export const StyledChevron = styled.span.attrs({
  children: <PDComponent.SvgIcon name="chevronDown" />,
})<{ $isActive?: boolean; $isFaded: boolean }>`
  svg {
    width: 14px;
    color: ${(props) => {
      if (props.$isActive) {
        return props.theme.themeStyle.colors.primary_typography;
      }
      return props.$isFaded ? colors.off_state : colors.gray;
    }};
  }
`;

export const LimitedContainer = styled.div`
  height: calc(100% - 75px);
  padding-left: 16px;

  width: 100%;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
    visibility: hidden;
  }
`;

export const IconHolder = styled.div`
  height: 40px;
  width: 40px;
  font-size: 20px;
  background: ${({ theme: { themeStyle } }) =>
    themeStyle.colors.tertiary_chart_bg};
  border-radius: 100%;
  padding: 10px;
  display: flex;
  box-shadow: 0px 1px 2px 0px
    ${({ theme: { themeStyle } }) => themeStyle.colors.primary_accent};
  color: ${colors.gray};
  margin: 0 auto;
`;

export const StyledRow = styled.div`
  padding: 8px 0px;
  padding-right: ${SCROLLBAR_GAP_AROUND}px;
  background: ${({ theme: { themeStyle } }) => themeStyle.colors.primary_bg};
`;

export const StyledHeader = styled.div`
  margin: 0px calc(${SCROLLBAR_GAP_PX}px) 0 16px;
  height: 60px;
  background: ${({ theme: { themeStyle } }) =>
    themeStyle.colors.alt_quaterniary_bg};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WellContainer = styled.div`
  padding-left: 56px;
`;

export const InfoContainer = styled.div`
  display: flex;
`;

export const StyledTitle = styled.h3`
  color: ${({ theme: { themeStyle } }) => themeStyle.colors.primary_typography};
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
`;

export const SortIcons = styled.div`
  display: flex;
  flex-direction: column;
  & ${StyledChevron}:first-child {
    transform: rotate(180deg);
  }
  & ${StyledChevron}:last-child {
    margin-top: -8px;
  }
`;

export const TitleContainer = styled.div<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}px` : "78px")};
  cursor: pointer;
  gap: 5px;
  padding: 0px;
  transition: all 0.3s ease;
  align-items: center;
  margin-left: 16px;
  @media only screen and (max-width: ${responsiveBreakpoints.xl}px) {
    margin-left: 8px;
  }
  @media only screen and (min-width: ${responsiveBreakpoints.xxl}px) {
    margin-left: 24px;
  }
`;

export const LoaderContainer = styled(Row)`
  position: relative;
`;
