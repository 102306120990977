/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AvailableIdsByRigsQueryDto,
  IntelWellInfoSetDto,
  LinkedWellAttributesSetDto,
  QuarterYearSet,
  WellIntelQueryDto,
} from '../models/index';
import {
    AvailableIdsByRigsQueryDtoFromJSON,
    AvailableIdsByRigsQueryDtoToJSON,
    IntelWellInfoSetDtoFromJSON,
    IntelWellInfoSetDtoToJSON,
    LinkedWellAttributesSetDtoFromJSON,
    LinkedWellAttributesSetDtoToJSON,
    QuarterYearSetFromJSON,
    QuarterYearSetToJSON,
    WellIntelQueryDtoFromJSON,
    WellIntelQueryDtoToJSON,
} from '../models/index';

export interface ApiFiltersIntelLinkedWellAttributesPutRequest {
    wellIntelQueryDto?: WellIntelQueryDto;
}

export interface ApiFiltersIntelQuartersPutRequest {
    wellIntelQueryDto?: WellIntelQueryDto;
}

export interface ApiFiltersIntelWellsPutRequest {
    wellIntelQueryDto?: WellIntelQueryDto;
}

export interface ApiFiltersLinkedWellAttributesPutRequest {
    linkedWellAttributesSetDto?: LinkedWellAttributesSetDto;
}

export interface ApiFiltersOperatorsByRigsPutRequest {
    availableIdsByRigsQueryDto?: AvailableIdsByRigsQueryDto;
}

/**
 * 
 */
export class FiltersApi extends runtime.BaseAPI {

    /**
     */
    async apiFiltersIntelLinkedWellAttributesPutRaw(requestParameters: ApiFiltersIntelLinkedWellAttributesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WellIntelQueryDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Filters/Intel/LinkedWellAttributes`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WellIntelQueryDtoToJSON(requestParameters['wellIntelQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WellIntelQueryDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFiltersIntelLinkedWellAttributesPut(requestParameters: ApiFiltersIntelLinkedWellAttributesPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WellIntelQueryDto> {
        const response = await this.apiFiltersIntelLinkedWellAttributesPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFiltersIntelQuartersPutRaw(requestParameters: ApiFiltersIntelQuartersPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuarterYearSet>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Filters/Intel/Quarters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WellIntelQueryDtoToJSON(requestParameters['wellIntelQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuarterYearSetFromJSON(jsonValue));
    }

    /**
     */
    async apiFiltersIntelQuartersPut(requestParameters: ApiFiltersIntelQuartersPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuarterYearSet> {
        const response = await this.apiFiltersIntelQuartersPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFiltersIntelWellsPutRaw(requestParameters: ApiFiltersIntelWellsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntelWellInfoSetDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Filters/Intel/Wells`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WellIntelQueryDtoToJSON(requestParameters['wellIntelQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntelWellInfoSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFiltersIntelWellsPut(requestParameters: ApiFiltersIntelWellsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntelWellInfoSetDto> {
        const response = await this.apiFiltersIntelWellsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFiltersLinkedWellAttributesPutRaw(requestParameters: ApiFiltersLinkedWellAttributesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LinkedWellAttributesSetDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Filters/LinkedWellAttributes`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LinkedWellAttributesSetDtoToJSON(requestParameters['linkedWellAttributesSetDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkedWellAttributesSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFiltersLinkedWellAttributesPut(requestParameters: ApiFiltersLinkedWellAttributesPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LinkedWellAttributesSetDto> {
        const response = await this.apiFiltersLinkedWellAttributesPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFiltersOperatorsByRigsPutRaw(requestParameters: ApiFiltersOperatorsByRigsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Filters/Operators/ByRigs`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AvailableIdsByRigsQueryDtoToJSON(requestParameters['availableIdsByRigsQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiFiltersOperatorsByRigsPut(requestParameters: ApiFiltersOperatorsByRigsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apiFiltersOperatorsByRigsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
