/* eslint-disable react/forbid-dom-props */
import type { Column } from "@tanstack/react-table";
import type { IntelGroupingType, IntelScorecardFactDto } from "apis/oag";
import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import React, { useEffect, useMemo, useState } from "react";
import { Popover } from "utils/componentLibrary";

export const Filter = ({
  column,
  lensGrouping,
  legendGrouping,
  onReset,
}: Readonly<{
  column: Column<IntelScorecardFactDto, unknown>;
  lensGrouping: IntelGroupingType;
  legendGrouping: IntelGroupingType;
  onReset?: () => void;
}>) => {
  const columnOptions = useMemo(
    () =>
      [...column.getFacetedUniqueValues().keys()].sort().map((e) => ({
        id: e,
        name: `${e}`,
      })),
    [column],
  );
  const columnValues = useMemo(() => {
    if (!column.getFilterValue()) return null;
    return column.getFilterValue() as string[];
  }, [column]);

  useEffect(() => {
    column.setFilterValue([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lensGrouping, legendGrouping]);

  const [showSelect, setShowSelect] = useState(false);

  return (
    <span
      style={{
        display:
          showSelect || (columnValues ?? [])?.length > 0 ? "flex" : undefined,
        alignItems: "center",
        justifyContent: "center",
      }}
      className={
        showSelect || (columnValues ?? []).length > 0 ? "" : "filter-button"
      }
    >
      <Popover
        content={
          <PDComponent.ComboBoxMultiSelect
            placeholder="Search"
            width={400}
            options={columnOptions}
            values={columnValues ?? columnOptions.map((e) => e.id)}
            onReset={
              onReset
                ? () => {
                    column.setFilterValue([]);
                    onReset();
                    setShowSelect(false);
                  }
                : undefined
            }
            onChange={(e) => {
              column.setFilterValue(e);
              setShowSelect(false);
            }}
          />
        }
        trigger="click"
        placement="bottom"
        open={showSelect}
        onOpenChange={(e) => setShowSelect(e)}
        destroyTooltipOnHide
      >
        <Button
          trackingName="Add Offset Rigs"
          size="small"
          icon={<PDComponent.SvgIcon name="filter" />}
          $engaged={showSelect}
        />
      </Popover>
    </span>
  );
};
