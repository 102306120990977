import type { DetailedLegendColumn } from "components/Lenses/common/ChartLegend/DetailedLegend";

export const LEGEND_EMPTY_COLUMN_KEY = "name";
export const FALLBACK_DETAILED_LEGEND_HEIGHT = 100;
export const LEGEND_DISABLED_OPACITY = 0.35;

export const detailedLegendColumns: DetailedLegendColumn[] = [
  {
    title: "",
    widthPercent: 25,
    key: LEGEND_EMPTY_COLUMN_KEY,
  },
  {
    title: "Min",
    widthPercent: 12,
    key: "Min",
  },
  {
    title: "Max",
    widthPercent: 12,
    key: "Max",
  },
  {
    title: "Mean",
    widthPercent: 12,
    key: "Mean",
  },
];
