import { AxisLeft } from "@visx/axis";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { Text } from "@visx/text";
import type { UseTooltipParams } from "@visx/tooltip/lib/hooks/useTooltip";
import type {
  ParameterByDepthTrackValuesDto,
  StickSlipByTimeUserLensDto,
  StickSlipByTimeUserLensTrackItemDto,
} from "apis/oag";
import { DimensionType } from "apis/oag";
import { Indicator } from "components/Lenses/common/InspectionView/InspectionChart/style";
import { useZoom } from "components/Lenses/common/LensZoom/useZoom";
import NoData from "components/Lenses/common/NoData";
import {
  TooltipFadedValue,
  TooltipHighlightValue,
} from "components/Lenses/common/Tooltip";
import * as CommonStyles from "components/Lenses/ContainerLens/common/StyledComponents";
import { LineChart } from "components/Lenses/ContainerLens/StickSlipByTime/components/Chart/LineTrack/LineChart";
import * as Styled from "components/Lenses/ContainerLens/StickSlipByTime/components/Chart/style";
import { useTrackSettingsModal } from "components/Lenses/ContainerLens/StickSlipByTime/components/TrackSettingsModal/useTrackSettingsModal";
import { SingleTrack } from "components/Lenses/ContainerLens/StickSlipByTime/styles";
import {
  CHART_BOTTOM_PADDING,
  CHART_TOP_PADDING,
  LEFT_AXIS_OFFSET,
  LEFT_AXIS_OFFSET_DETAILED,
  LEFT_AXIS_WIDTH,
  LEFT_AXIS_WIDTH_DETAILED,
  LEFT_COLUMN_WIDTH,
  LEFT_COLUMN_WIDTH_DETAILED,
  VERTICAL_UNIT_HEIGHT,
} from "components/Lenses/ContainerLens/StickSlipByTime/utils/constants";
import type { TooltipPoint } from "components/Lenses/ContainerLens/StickSlipByTime/utils/useStickSlipByTimeTooltip";
import {
  useStickSlipByTimeContext,
  useStickSlipByTimeTooltip,
} from "components/Lenses/ContainerLens/StickSlipByTime/utils/useStickSlipByTimeTooltip";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import type { ScaleLinear } from "d3-scale";
import {
  getDisplayScale,
  getManualTickValues,
} from "hooks/charting/useYAxisDisplayScale";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { getYAxisTicksCount } from "utils/charting/getYAxisTicksCount";
import colors from "utils/colors";
import { APPROX_CHAR_WIDTH } from "utils/constants";
import type { UOMHelper } from "utils/format";
import { SHORTER_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

const fontWeightOnLimits = 600;

export interface TrackViewmodel {
  uom: UOMHelper;
  key: string;
  label: string;
  color: string;
  trackPoints: ParameterByDepthTrackValuesDto[];
  lensSettingsTrackId: number;
  unit?: string;
  fill: string;
  fontColor: string;
  zIndex: number;
}

function computeAxisDomain(
  trackValues: number[],
  track: StickSlipByTimeUserLensTrackItemDto,
) {
  const defDomain = [trackValues.length ? Math.max(...trackValues) : 0, 0];

  if (track.isManualYaxis) {
    return [track.yaxisEnd ?? defDomain[0], track.yaxisStart ?? defDomain[1]];
  }

  return defDomain;
}

export const LineTrack = ({
  height,
  isDetailed,
  containerWidth,
  xScale,
  title,
  lens,
  tracks,
  onLensUpdated,
  onMouseOverChartArea,
  onMouseLeaveChartArea,
}: {
  height: number;
  isDetailed: boolean;
  containerWidth: number;
  title: string;
  xScale: ScaleLinear<number, number, never>;
  lens: StickSlipByTimeUserLensDto;
  tracks: TrackViewmodel[];
  onLensUpdated: (lens: StickSlipByTimeUserLensDto) => void;
  onMouseOverChartArea: () => void;
  onMouseLeaveChartArea: () => void;
}) => {
  const chartHeight = getSVGNormalizedValue(
    height - CHART_TOP_PADDING - CHART_BOTTOM_PADDING,
  );

  const leftColumnWidth = isDetailed
    ? LEFT_COLUMN_WIDTH_DETAILED
    : LEFT_COLUMN_WIDTH;
  const chartWidth = getSVGNormalizedValue(containerWidth - leftColumnWidth);

  const { element: modalElement, open: openTrackSettings } =
    useTrackSettingsModal(lens, onLensUpdated);

  const tracksWithScales = useMemo(
    () =>
      tracks.map((track) => {
        const matchedTrack = lens.userLensTrackItems.find(
          (item) => item.trackId === track.lensSettingsTrackId,
        );

        if (!matchedTrack) {
          throw new Error("No matched track found for SSBT");
        }

        const yScale = scaleLinear<number>({
          domain: computeAxisDomain(
            track.trackPoints.map((point) => point.value ?? 0),
            matchedTrack,
          ),
          range: [0, chartHeight],
        });

        return {
          ...track,
          yScale,
          axisScale: getDisplayScale({ originalScale: yScale, uom: track.uom }),
          trackPoints: track.trackPoints.map((trackPoint, index) => ({
            ...trackPoint,
            index,
          })),
          matchedFromLensData: matchedTrack,
        };
      }),
    [tracks, lens.userLensTrackItems, chartHeight],
  );

  const anyTracksValues = useMemo(() => {
    if (tracksWithScales.length > 0) {
      return tracksWithScales[0].trackPoints
        .filter((trackPoint) => trackPoint.value)
        .map((trackPoint) => {
          const values: Record<string, number> = {};
          tracksWithScales.forEach((track) => {
            values[track.key] = trackPoint.value ?? 0;
          });
          return {
            ...trackPoint,
            values,
          };
        });
    }
    return [];
  }, [tracksWithScales]);

  const handleOnClickSettings = useCallback(
    (trackInfo: TrackViewmodel) => {
      openTrackSettings(trackInfo);
    },
    [openTrackSettings],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const depthUOM = useUOM(DimensionType.Metres);

  const tooltipRenderer: (
    params: UseTooltipParams<TooltipPoint>,
  ) => React.ReactNode = ({ tooltipData }) => {
    return tooltipData ? (
      <CommonStyles.TooltipContainer>
        {Object.keys(tooltipData.values).map((key) => {
          const trackData = tooltipData.values[key];
          return (
            <CommonStyles.TooltipRow key={key}>
              <TooltipHighlightValue
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                <Indicator
                  color={tracks.find((track) => track.key === key)!.color}
                />
                {trackData.label}{" "}
              </TooltipHighlightValue>
              <TooltipHighlightValue>
                {trackData.uom
                  ? `${trackData.uom?.displayWithAutoDecimals(trackData.visibleDomain, trackData.value, { unit: "" })} ${
                      trackData.uom?.abbr
                    }`
                  : `${trackData.value} ${trackData.label}`}
              </TooltipHighlightValue>
            </CommonStyles.TooltipRow>
          );
        })}

        <CommonStyles.TooltipBorder>
          <TooltipFadedValue $highlight>
            {depthUOM.display(tooltipData.holeDepth)}{" "}
          </TooltipFadedValue>
          <TooltipFadedValue>
            {formatTime(tooltipData?.timestamp, {
              formatStr: SHORTER_DATE_FORMAT,
            })}
          </TooltipFadedValue>
        </CommonStyles.TooltipBorder>
      </CommonStyles.TooltipContainer>
    ) : null;
  };

  const { setPointerInsideChart } = useStickSlipByTimeContext();

  useEffect(() => {
    // TODO: why not a ref?
    const element = document.getElementById("scroll-container-id-fix-tbf");
    const onScroll = () => {
      setPointerInsideChart(false);
    };
    element?.addEventListener("scroll", onScroll);
    return () => {
      element?.removeEventListener("scroll", onScroll);
    };
  }, [setPointerInsideChart]);

  const { handlePointerMove, tooltipElement } = useStickSlipByTimeTooltip({
    containerRef,
    xScale,
    plotWidth: chartWidth,
    plotHeight: chartHeight,
    renderContent: tooltipRenderer,
    tracks: tracksWithScales,
    trackValues: anyTracksValues,
  });

  const { selectionRectangleElement, zoomEvents } = useZoom({
    xScale,
    yScaleBrush: tracksWithScales?.[0]?.axisScale,
    series: Array.from({ length: anyTracksValues.length }, (_, index) => ({
      index,
    })),
    plotWidth: chartWidth,
    plotHeight: chartHeight,
    plotWidthOffset: 4,
    handlePointerMove,
  });
  const axisWidth = isDetailed ? LEFT_AXIS_WIDTH_DETAILED : LEFT_AXIS_WIDTH;

  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const isManualTrack = useCallback((track: (typeof tracksWithScales)[0]) => {
    return (
      track.matchedFromLensData?.isManualYaxis ||
      Number.isFinite(track.matchedFromLensData?.yaxisStart) ||
      Number.isFinite(track.matchedFromLensData?.yaxisEnd)
    );
  }, []);

  const isLimitedTrack = useCallback((track: (typeof tracksWithScales)[0]) => {
    return (
      Number.isFinite(track.matchedFromLensData?.maxLimit) ||
      Number.isFinite(track.matchedFromLensData?.minLimit)
    );
  }, []);

  const hasNoData = useMemo(() => {
    return tracksWithScales.every((track) => track.trackPoints.length === 0);
  }, [tracksWithScales]);

  const { isDark } = useCustomTheme();
  return (
    <SingleTrack $height={height} ref={ref}>
      <Styled.LeftContainer $leftColumnWidth={leftColumnWidth}>
        <Styled.VerticalTitle>
          <Styled.TrackTitle $isDetailed={isDetailed}>
            {title}
          </Styled.TrackTitle>
        </Styled.VerticalTitle>
        {tracksWithScales.length === 1 ? (
          <Styled.AxisContainer $axisWidth={axisWidth}></Styled.AxisContainer>
        ) : null}
        {tracksWithScales.map((track) => (
          <Styled.AxisContainer $axisWidth={axisWidth} key={track.key}>
            {isLimitedTrack(track) ? (
              <Styled.TopRightTriangle
                $color={track.key === "Sssi" ? colors.gray : track.color}
              />
            ) : null}
            <Styled.UnitContainer>
              <Styled.VerticalUnit height={height}>
                <Styled.Unit $isDetailed={isDetailed}>
                  {track?.unit ?? track?.uom.abbr ?? ""}
                </Styled.Unit>
              </Styled.VerticalUnit>
            </Styled.UnitContainer>
            <Styled.CogContainer onClick={() => handleOnClickSettings(track)}>
              <PDComponent.SvgIcon name="settings" />
            </Styled.CogContainer>
            <Styled.MainAxis $axisWidth={axisWidth}>
              <svg
                width={getSVGNormalizedValue(axisWidth - VERTICAL_UNIT_HEIGHT)}
                height={height}
                style={{ overflow: "visible" }}
              >
                <AxisLeft
                  top={CHART_TOP_PADDING}
                  scale={track.axisScale}
                  hideTicks
                  hideAxisLine
                  tickLabelProps={(value) => {
                    let bold = false;
                    if (isManualTrack(track)) {
                      if (
                        value ===
                          track.uom.fromSI(
                            track.matchedFromLensData?.yaxisStart ?? Infinity,
                          ) ||
                        value ===
                          track.uom.fromSI(
                            track.matchedFromLensData?.yaxisEnd ?? Infinity,
                          )
                      ) {
                        bold = true;
                      }
                    }

                    return {
                      fontSize: isDetailed ? 16 : 12,
                      fill: track.fontColor,
                      fontWeight: bold ? fontWeightOnLimits : "normal",
                      textAnchor: "end",
                      verticalAnchor: "middle",
                    };
                  }}
                  left={
                    isDetailed ? LEFT_AXIS_OFFSET_DETAILED : LEFT_AXIS_OFFSET
                  }
                  numTicks={getYAxisTicksCount({
                    tickContainerHeight: chartHeight,
                  })}
                  tickComponent={({ formattedValue, ...tickProps }) => {
                    const { x = 0, y = 0, ...restTickProps } = tickProps;

                    const innerColor = isDark ? colors.revolver : colors.white;
                    const includeHighlight =
                      tickProps.fontWeight === fontWeightOnLimits;
                    const paddingAroundText = 2;
                    const fontHeight = +(restTickProps?.fontSize ?? 12);
                    const textLengthInPx =
                      ((formattedValue?.toString()?.length ?? 0) *
                        APPROX_CHAR_WIDTH *
                        fontHeight) /
                      12;

                    return (
                      <Group top={y} left={x}>
                        {includeHighlight ? (
                          <rect
                            x={-textLengthInPx - paddingAroundText}
                            y={isDetailed ? -10 : -8}
                            width={textLengthInPx + 2 * paddingAroundText}
                            height={fontHeight + 2 * paddingAroundText}
                            rx={3}
                            stroke={innerColor}
                            strokeWidth="0.5px"
                            strokeLinejoin="round"
                            fill={innerColor}
                            style={{
                              filter: `drop-shadow(0px 1px 3px rgb(0 0 0 / 0.2))`,
                            }}
                          />
                        ) : null}

                        <Text {...restTickProps}>{formattedValue}</Text>
                      </Group>
                    );
                  }}
                  tickValues={
                    isManualTrack(track)
                      ? getManualTickValues({
                          yDisplayOnlyScale: track.axisScale,
                          verticalAxisTicksCount: getYAxisTicksCount({
                            tickContainerHeight: chartHeight,
                          }),
                        })
                      : undefined
                  }
                />
              </svg>
            </Styled.MainAxis>
          </Styled.AxisContainer>
        ))}
      </Styled.LeftContainer>

      <Styled.RightContainer
        ref={containerRef}
        $leftColumnWidth={leftColumnWidth}
        onMouseOver={onMouseOverChartArea}
        onMouseLeave={onMouseLeaveChartArea}
      >
        {hasNoData ? (
          <Styled.NoDataContainer>
            <NoData />
          </Styled.NoDataContainer>
        ) : (
          <svg
            width={chartWidth}
            height={chartHeight}
            style={{ marginTop: CHART_TOP_PADDING, overflow: "visible" }}
            {...zoomEvents}
          >
            <svg
              width={chartWidth}
              height={chartHeight}
              style={{ overflow: "hidden" }}
            >
              {tracksWithScales
                .toSorted((a, b) => a.zIndex - b.zIndex)
                .map((track) => (
                  <LineChart
                    key={track.key}
                    xScale={xScale}
                    yScale={track.yScale}
                    strokeColor={track.color}
                    trackPoints={track.trackPoints}
                    fill={track.fill}
                    chartHeight={chartHeight}
                  />
                ))}
            </svg>
            {inView ? tooltipElement : null}
            {selectionRectangleElement}
          </svg>
        )}
      </Styled.RightContainer>
      {modalElement}
    </SingleTrack>
  );
};
