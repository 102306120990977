import type { ActualTimelineDto, PlanTimelineDto } from "apis/oag";
import { WellStatusType } from "apis/oag";
import { usePhases } from "hooks/drillingInvariants/usePhases";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { sortEventsTimeline } from "utils/common";
import type { ITimelineEventList } from "utils/eventUtils";
import { toTimelineView } from "utils/eventUtils";

export const useTimelineEventsList = (props: {
  actualEvents: ActualTimelineDto | null;
  planEvents: PlanTimelineDto | null;
}) => {
  // DISCLAIMER Redux does not see multiple level state updates
  const actualEvents = useAppSelector(
    (state) => state.timeline.events.actualEvents?.events,
  );
  const planEvents = useAppSelector(
    (state) => state.timeline.events.planEvents?.events,
  );
  const currentWellDetails = useAppSelector(
    (state) => state.timeline.currentWellDetails,
  );
  const { data: phaseData } = usePhases();
  const isValidWellPlan = useCallback(
    (eventsList: ITimelineEventList[]) =>
      currentWellDetails?.planDetails ||
      currentWellDetails?.planDetails?.totalDuration === 0
        ? eventsList
        : [],
    [currentWellDetails?.planDetails],
  );
  const isValidWellActual = useCallback(
    (eventsList: ITimelineEventList[]) =>
      currentWellDetails?.drillingDetails?.maxHoleDepth ? eventsList : [],
    [currentWellDetails?.drillingDetails?.maxHoleDepth],
  );

  const { actualTimelineEventsList, planTimelineEventsList } = useMemo(() => {
    const planTimelineEventsList = toTimelineView(
      true,
      phaseData,
      { ...props.planEvents, events: planEvents },
      currentWellDetails?.planDetails?.totalDuration ?? -Infinity,
      currentWellDetails?.planDetails?.endDepth ?? -Infinity,
      {
        timelineHidden: !currentWellDetails?.planDetails,
      },
    );
    return {
      actualTimelineEventsList: isValidWellActual([
        ...toTimelineView(
          false,
          phaseData,
          { ...props.actualEvents, events: actualEvents },
          currentWellDetails?.status === WellStatusType.Active
            ? Math.max(
                currentWellDetails?.drillingDetails
                  ?.cumulativeDurationActiveOnly ?? -Infinity,
                currentWellDetails?.planDetails?.totalDuration ?? -Infinity,
              )
            : currentWellDetails?.drillingDetails
                ?.cumulativeDurationActiveOnly ?? 0,
          currentWellDetails?.status === WellStatusType.Active
            ? Math.max(
                currentWellDetails?.drillingDetails?.drillingDistance ??
                  -Infinity,
                currentWellDetails?.planDetails?.endDepth ?? -Infinity,
              )
            : currentWellDetails?.drillingDetails?.drillingDistance ?? 0,
          {
            timelineHidden: !currentWellDetails?.drillingDetails,
          },
        ),
      ]).sort(sortEventsTimeline),
      planTimelineEventsList: isValidWellPlan(planTimelineEventsList).sort(
        sortEventsTimeline,
      ),
    };
  }, [
    props.planEvents,
    phaseData,
    props.actualEvents,
    planEvents,
    currentWellDetails?.planDetails,
    currentWellDetails?.status,
    currentWellDetails?.drillingDetails,
    isValidWellActual,
    actualEvents,
    isValidWellPlan,
  ]);
  return { actualTimelineEventsList, planTimelineEventsList };
};
