/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import type { WedgeValueDto } from './WedgeValueDto';
import {
    WedgeValueDtoFromJSON,
    WedgeValueDtoFromJSONTyped,
    WedgeValueDtoToJSON,
} from './WedgeValueDto';

/**
 * 
 * @export
 * @interface WedgeComparisonWellDto
 */
export interface WedgeComparisonWellDto {
    /**
     * 
     * @type {number}
     * @memberof WedgeComparisonWellDto
     */
    wellId: number;
    /**
     * 
     * @type {boolean}
     * @memberof WedgeComparisonWellDto
     */
    isFocalWell: boolean;
    /**
     * 
     * @type {number}
     * @memberof WedgeComparisonWellDto
     */
    aggregatedWellsCount: number;
    /**
     * 
     * @type {Array<WedgeValueDto>}
     * @memberof WedgeComparisonWellDto
     */
    values?: Array<WedgeValueDto> | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeComparisonWellDto
     */
    total: number;
    /**
     * 
     * @type {DateDto}
     * @memberof WedgeComparisonWellDto
     */
    lastUpdatedAt?: DateDto | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof WedgeComparisonWellDto
     */
    readonly dataState: ResultDataState;
}



/**
 * Check if a given object implements the WedgeComparisonWellDto interface.
 */
export function instanceOfWedgeComparisonWellDto(value: object): value is WedgeComparisonWellDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('isFocalWell' in value) || value['isFocalWell'] === undefined) return false;
    if (!('aggregatedWellsCount' in value) || value['aggregatedWellsCount'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function WedgeComparisonWellDtoFromJSON(json: any): WedgeComparisonWellDto {
    return WedgeComparisonWellDtoFromJSONTyped(json, false);
}

export function WedgeComparisonWellDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WedgeComparisonWellDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'isFocalWell': json['IsFocalWell'],
        'aggregatedWellsCount': json['AggregatedWellsCount'],
        'values': json['Values'] == null ? undefined : ((json['Values'] as Array<any>).map(WedgeValueDtoFromJSON)),
        'total': json['Total'],
        'lastUpdatedAt': json['LastUpdatedAt'] == null ? undefined : DateDtoFromJSON(json['LastUpdatedAt']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function WedgeComparisonWellDtoToJSON(value?: Omit<WedgeComparisonWellDto, 'DataState'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'IsFocalWell': value['isFocalWell'],
        'AggregatedWellsCount': value['aggregatedWellsCount'],
        'Values': value['values'] == null ? undefined : ((value['values'] as Array<any>).map(WedgeValueDtoToJSON)),
        'Total': value['total'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
    };
}

