import type { KpiGroupUserLensDto } from "apis/oag";
import {
  isPivotKpiGroupLensTemplate,
  useLensTemplates,
} from "hooks/lens/useLensTemplates";
import { useKpiGroupPivotFacts } from "hooks/pivot/useStackedKpi";
import { useEffect } from "react";
import { useAppDispatch } from "reducers/store";

export function usePivotKpiFetcher(lens: KpiGroupUserLensDto) {
  const dispatch = useAppDispatch();
  const { data: templates } = useLensTemplates();
  const template = templates?.byId[lens.lensTemplateId];
  const kpiPivotFacts = useKpiGroupPivotFacts(
    lens.id,
    {},
    isPivotKpiGroupLensTemplate(template),
  );

  useEffect(() => {
    if (kpiPivotFacts.data?.availableFilters)
      dispatch({
        type: "SET_RIG_AVAILABLE_FILTERS",
        payload: kpiPivotFacts.data?.availableFilters,
      });
  }, [kpiPivotFacts.data?.availableFilters, dispatch]);

  useEffect(() => {
    if (kpiPivotFacts.data?.lastUpdatedAt)
      dispatch({
        type: "SET_LAST_REFRESH_DATE",
        payload: kpiPivotFacts.data?.lastUpdatedAt,
      });
  }, [kpiPivotFacts.data?.lastUpdatedAt, dispatch]);

  return kpiPivotFacts;
}
