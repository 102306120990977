import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { CheckboxValueType } from "antd/lib/checkbox/Group";
import type { ParameterHeatmapUserLensDto, UserLensDto } from "apis/oag";
import { ParameterHeatmapUserLensesApi } from "apis/oag";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import { useMemo, useState } from "react";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import {
  Checkbox,
  Col,
  Popover,
  Radio,
  Row,
  Space,
  Tooltip,
} from "utils/componentLibrary";
import { RequestUID } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

const displayOptions = {
  actual: "Selected Well",
  offset: "Offset Well(s)",
};

const VisualAidType = {
  activeBin: "Most Recent Value",
  recommendation: "Well Recommendation",
  offsetRecommendation: "Offset Recommendation",
};

export interface ParameterHeatmapSettingsProps {
  lens: ParameterHeatmapUserLensDto;
  setLocalLens: React.Dispatch<UserLensDto>;
}

const parameterHeatmapApi = new ParameterHeatmapUserLensesApi(apiConfig);

export const ParameterHeatmapSettings: React.FC<
  ParameterHeatmapSettingsProps
> = ({ lens, setLocalLens }) => {
  const { atomThemeVariant } = useCustomTheme();
  const queryClient = useQueryClient();

  const [visualAidsVisible, setVisualAidsVisible] = useState(false);
  const [displayOptionsVisible, setDisplayOptionsVisible] = useState(false);

  const shouldShowActual = useMemo(
    () => lens.showActiveBin,
    [lens.showActiveBin],
  );
  const shouldShowRecommendation = useMemo(
    () => lens.showFocalRecommendation,
    [lens.showFocalRecommendation],
  );
  const shouldShowOffsetRecommendation = useMemo(
    () => lens.showOffsetRecommendation,
    [lens.showOffsetRecommendation],
  );

  const visualAidsState = useMemo(() => {
    const visAidsState = [];

    shouldShowActual && visAidsState.push(VisualAidType.activeBin);
    shouldShowRecommendation && visAidsState.push(VisualAidType.recommendation);
    shouldShowOffsetRecommendation &&
      visAidsState.push(VisualAidType.offsetRecommendation);

    return visAidsState;
  }, [
    shouldShowActual,
    shouldShowRecommendation,
    shouldShowOffsetRecommendation,
  ]);

  const displayOptionState = useMemo(
    () => (lens.showOffsetData ? displayOptions.offset : displayOptions.actual),
    [lens.showOffsetData],
  );

  const handleVisualAidsUpdate = useMutation({
    mutationFn: ({
      visualAidState,
    }: {
      visualAidState: CheckboxValueType[];
    }) => {
      return parameterHeatmapApi.apiParameterHeatmapUserLensesIdPut({
        id: lens.id,
        parameterHeatmapUserLensDto: {
          ...lens,
          showActiveBin: visualAidState.includes(VisualAidType.activeBin),
          showFocalRecommendation: visualAidState.includes(
            VisualAidType.recommendation,
          ),
          showOffsetRecommendation: visualAidState.includes(
            VisualAidType.offsetRecommendation,
          ),
        },
      });
    },
    onSettled: (newLens) => {
      if (newLens) setLocalLens(newLens);
      queryClient.invalidateQueries({
        queryKey: [{ uid: RequestUID.parameterHeatmapFacts, lensId: lens.id }],
        exact: false,
      });
    },
  });

  const handleDisplayOptionsUpdate = useMutation({
    mutationFn: ({ displayOptionState }: { displayOptionState: string }) => {
      return parameterHeatmapApi.apiParameterHeatmapUserLensesIdPut({
        id: lens.id,
        parameterHeatmapUserLensDto: {
          ...lens,
          showOffsetData: displayOptionState === displayOptions.offset,
        },
      });
    },
    onSettled: (newLens) => {
      if (newLens) setLocalLens(newLens);
      queryClient.invalidateQueries({
        queryKey: [{ uid: RequestUID.parameterHeatmapFacts, lensId: lens.id }],
        exact: false,
      });
    },
  });

  return (
    <>
      <Tooltip title="Visual Aids">
        <Popover
          content={
            <Space
              direction="vertical"
              style={{
                padding: 12,
              }}
            >
              <Title level={3} variant={atomThemeVariant} weight={500}>
                Visual Aids
              </Title>
              <Checkbox.Group
                value={visualAidsState}
                onChange={async (visualAidState) => {
                  Track.interact("Lens - Heatmap - Visual Aids", {
                    Selection: visualAidState,
                  });
                  await handleVisualAidsUpdate.mutateAsync({ visualAidState });
                }}
                style={{ maxWidth: "250px" }}
              >
                <Row gutter={[0, 6]}>
                  {Object.values(VisualAidType).map((e) => {
                    return (
                      <Col span={24} key={e}>
                        <Checkbox value={e}>{e}</Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Space>
          }
          trigger={["click"]}
          placement="bottom"
          open={visualAidsVisible}
          onOpenChange={(e) => setVisualAidsVisible(e)}
          destroyTooltipOnHide
        >
          <Button
            icon={<PDComponent.SvgIcon name="cdArchive" />}
            $engaged={visualAidsVisible}
            type={visualAidsState?.length > 0 ? "primary" : "default"}
            ghost={visualAidsState?.length > 0}
            // For ant-d item margin, it doesn't work with React fragments
            style={{ marginRight: 8 }}
          />
        </Popover>
      </Tooltip>

      <Tooltip title="Display Options">
        <Popover
          content={
            <Space
              direction="vertical"
              style={{
                padding: "12px 16px",
              }}
            >
              <Title level={3} variant={atomThemeVariant} weight={500}>
                Display Options
              </Title>
              <Radio.Group
                onChange={async (e) => {
                  Track.interact("Lens - Heatmap - Display data options", {
                    Selection:
                      e.target.value
                        ?.split?.("_")
                        ?.map?.(
                          (e: string) => `${e[0].toUpperCase()}${e.slice(1)}`,
                        )
                        ?.join?.(" ") ?? "",
                  });
                  await handleDisplayOptionsUpdate.mutateAsync({
                    displayOptionState: e.target.value,
                  });
                }}
                value={displayOptionState}
              >
                <Space direction="vertical" size={6}>
                  {Object.values(displayOptions).map((displayOption) => (
                    <Radio key={displayOption} value={displayOption}>
                      {displayOption}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Space>
          }
          trigger={["click"]}
          placement="bottom"
          open={displayOptionsVisible}
          onOpenChange={(e) => setDisplayOptionsVisible(e)}
          destroyTooltipOnHide
        >
          <Button
            size="large"
            icon={<PDComponent.SvgIcon name="view" />}
            $engaged={displayOptionsVisible}
          />
        </Popover>
      </Tooltip>
    </>
  );
};
