import type { DisplayOption } from "apis/oag";
import type { ApiDrillingProductivityUserLensesIdComparisonFactsPutRequest } from "apis/oag/apis/DrillingProductivityUserLensesApi";
import type { DrillingProductivityUserLensDto } from "apis/oag/models/DrillingProductivityUserLensDto";
import { useDrillingProductivityFacts } from "hooks/facts/useDrillingProductivityFacts";
import { useFilterParams } from "hooks/filters/useFilterParams";
import { useAppSelector } from "reducers/store";

export const useDrillingProductivityStatsFacts = (
  lens: DrillingProductivityUserLensDto,
  displayOption?: DisplayOption,
) => {
  const filterParams = useFilterParams();
  const comparisonWells = useAppSelector(
    (state) => state.widgetOptions.offsetWells,
  );
  const isComparing = comparisonWells?.length > 0;
  const requestParameters: ApiDrillingProductivityUserLensesIdComparisonFactsPutRequest =
    {
      id: lens.id ?? -1,
      baseDisplayOptionUserLensQueryDto: displayOption
        ? {
            ...filterParams,
            comparisonWellIds: isComparing
              ? comparisonWells.map((wellId) => +wellId)
              : undefined,
            displayOption,
          }
        : undefined,
    };

  const request = useDrillingProductivityFacts(
    lens.id,
    isComparing,
    requestParameters,
  );

  return request;
};
