/* eslint-disable react/no-multi-comp */
import type { ProfileInfoDto } from "apis/oag";
import { Arrow, StyledDropdownButton } from "atoms/common";
import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import { useState } from "react";
import styled from "styled-components";
import { Popover, Space, Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

const StyledButton = styled(Button)<{ width?: number }>`
  justify-content: flex-start;
  display: flex;
  padding: 0px 0px 0px 15px;
  min-width: ${({ width }) => width}px;
`;
const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledArrowButton = styled(StyledDropdownButton)<{
  $showSelect: boolean;
}>`
  padding-top: ${({ $showSelect }) => ($showSelect ? 10 : 7)}px;
`;

const StyledSpace = styled(Space).attrs({
  direction: "horizontal",
  size: 6,
})`
  padding: 9px 0;
`;
export const ProfileSelectorList = ({
  profileList,
  setSelectedProfile,
  otherProfileId,
  width = 500,
  selectedProfile,
}: {
  profileList: ProfileInfoDto[];
  selectedProfile: ProfileInfoDto | null;
  setSelectedProfile: (profile: ProfileInfoDto) => void;
  otherProfileId: number;
  width?: number;
}) => {
  return (
    <PDComponent.ListWithSearch<number>
      width={width}
      placeholder={"Search..."}
      selectedItem={
        selectedProfile
          ? `${selectedProfile.displayName} ${selectedProfile.userName}`
          : undefined
      }
      options={profileList
        .filter((profile) => {
          return profile.profileId !== otherProfileId;
        })
        .map((profile) => ({
          id: profile.profileId,
          name: `${profile.displayName} ${profile.userName}`,
          component: (
            <>
              {" "}
              <b>{profile.displayName}</b> {profile.userName}
            </>
          ),
        }))}
      values={profileList.map((profile) => profile.profileId)}
      onClick={(option) => {
        setSelectedProfile(
          profileList.find((profile) => profile.profileId === option.id)!,
        );
      }}
    />
  );
};

export function UserSelector({
  profileList,
  selectedProfile,
  otherProfileId,
  placeholder,
  setSelectedProfile,
  width = 500,
}: {
  profileList: ProfileInfoDto[];
  selectedProfile: ProfileInfoDto | null;
  otherProfileId: number;
  placeholder?: string;
  setSelectedProfile: (profile: ProfileInfoDto) => void;
  width?: number;
}) {
  const [showSelect, setShowSelect] = useState(false);
  const { themeStyle } = useCustomTheme();
  return (
    <Tooltip title={"Select User"}>
      <Popover
        content={
          <ProfileSelectorList
            width={width}
            profileList={profileList}
            setSelectedProfile={(profile) => {
              setShowSelect(false);
              setSelectedProfile(profile);
            }}
            selectedProfile={selectedProfile}
            otherProfileId={otherProfileId}
          />
        }
        trigger="click"
        placement="bottom"
        open={showSelect}
        onOpenChange={(e) => setShowSelect(e)}
        destroyTooltipOnHide
      >
        <StyledButton
          width={width}
          onClick={() => setShowSelect(!showSelect)}
          $engaged={showSelect}
        >
          {selectedProfile ? (
            <StyledSpace>
              <b>{selectedProfile.displayName}</b> {selectedProfile.userName}
            </StyledSpace>
          ) : (
            <StyledSpace
              style={{
                color: themeStyle.colors.light_typography,
              }}
            >
              {placeholder ? placeholder : "Select Profile"}
            </StyledSpace>
          )}
          <StyledContainer>
            <StyledArrowButton $showSelect={showSelect}>
              <Arrow
                up={showSelect}
                color={themeStyle.colors.secondary_accent}
              />
            </StyledArrowButton>
          </StyledContainer>
        </StyledButton>
      </Popover>
    </Tooltip>
  );
}
