export const MockHexbinSvg = () => {
  return (
    <svg
      version="1.1"
      width="230px"
      height="89px"
      viewBox="0 0 230.0 89.0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="i0">
          <path d="M320,0 C323.313708,-1.4968968e-15 326,2.6862915 326,6 L326,315 C326,318.313708 323.313708,321 320,321 L6,321 C2.6862915,321 4.05812251e-16,318.313708 0,315 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 L320,0 Z"></path>
        </clipPath>
        <filter
          id="i1"
          x="-6.11072502%"
          y="-11.5384615%"
          filterUnits="userSpaceOnUse"
          width="117.55571%"
          height="115.384615%"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="i2"
          ></feGaussianBlur>
          <feOffset dx="0" dy="0" in="i2" result="i3"></feOffset>
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1599999964237213 0"
            type="matrix"
            in="i3"
          ></feColorMatrix>
        </filter>
        <clipPath id="i4">
          <path d="M12,0 L22.3923048,6 L22.3923048,18 L12,24 L1.60769515,18 L1.60769515,6 L12,0 Z"></path>
        </clipPath>
        <filter
          id="i5"
          x="-6.11072502%"
          y="-11.5384615%"
          filterUnits="userSpaceOnUse"
          width="117.55571%"
          height="115.384615%"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="i6"
          ></feGaussianBlur>
          <feOffset dx="0" dy="0" in="i6" result="i7"></feOffset>
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1599999964237213 0"
            type="matrix"
            in="i7"
          ></feColorMatrix>
        </filter>
        <filter
          id="i8"
          x="-6.11072502%"
          y="-11.5384615%"
          filterUnits="userSpaceOnUse"
          width="117.55571%"
          height="115.384615%"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="i9"
          ></feGaussianBlur>
          <feOffset dx="0" dy="0" in="i9" result="i10"></feOffset>
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1599999964237213 0"
            type="matrix"
            in="i10"
          ></feColorMatrix>
        </filter>
        <filter
          id="i11"
          x="-6.11072502%"
          y="-11.5384615%"
          filterUnits="userSpaceOnUse"
          width="117.55571%"
          height="115.384615%"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="i12"
          ></feGaussianBlur>
          <feOffset dx="0" dy="0" in="i12" result="i13"></feOffset>
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1599999964237213 0"
            type="matrix"
            in="i13"
          ></feColorMatrix>
        </filter>
        <filter
          id="i14"
          x="-6.11072502%"
          y="-11.5384615%"
          filterUnits="userSpaceOnUse"
          width="117.55571%"
          height="115.384615%"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="i15"
          ></feGaussianBlur>
          <feOffset dx="0" dy="0" in="i15" result="i16"></feOffset>
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1599999964237213 0"
            type="matrix"
            in="i16"
          ></feColorMatrix>
        </filter>
        <filter
          id="i17"
          x="-6.11072502%"
          y="-11.5384615%"
          filterUnits="userSpaceOnUse"
          width="117.55571%"
          height="115.384615%"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="i18"
          ></feGaussianBlur>
          <feOffset dx="0" dy="0" in="i18" result="i19"></feOffset>
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1599999964237213 0"
            type="matrix"
            in="i19"
          ></feColorMatrix>
        </filter>
        <filter
          id="i20"
          x="-6.11072502%"
          y="-11.5384615%"
          filterUnits="userSpaceOnUse"
          width="117.55571%"
          height="115.384615%"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="i21"
          ></feGaussianBlur>
          <feOffset dx="0" dy="0" in="i21" result="i22"></feOffset>
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1599999964237213 0"
            type="matrix"
            in="i22"
          ></feColorMatrix>
        </filter>
        <filter
          id="i23"
          x="-6.11072502%"
          y="-11.5384615%"
          filterUnits="userSpaceOnUse"
          width="117.55571%"
          height="115.384615%"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="i24"
          ></feGaussianBlur>
          <feOffset dx="0" dy="0" in="i24" result="i25"></feOffset>
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1599999964237213 0"
            type="matrix"
            in="i25"
          ></feColorMatrix>
        </filter>
        <filter
          id="i26"
          x="-6.11072502%"
          y="-11.5384615%"
          filterUnits="userSpaceOnUse"
          width="117.55571%"
          height="115.384615%"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="i27"
          ></feGaussianBlur>
          <feOffset dx="0" dy="0" in="i27" result="i28"></feOffset>
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1599999964237213 0"
            type="matrix"
            in="i28"
          ></feColorMatrix>
        </filter>
        <filter
          id="i29"
          x="-6.11072502%"
          y="-11.5384615%"
          filterUnits="userSpaceOnUse"
          width="117.55571%"
          height="115.384615%"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="i30"
          ></feGaussianBlur>
          <feOffset dx="0" dy="0" in="i30" result="i31"></feOffset>
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1599999964237213 0"
            type="matrix"
            in="i31"
          ></feColorMatrix>
        </filter>
        <filter
          id="i32"
          x="-6.11072502%"
          y="-11.5384615%"
          filterUnits="userSpaceOnUse"
          width="117.55571%"
          height="115.384615%"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="i33"
          ></feGaussianBlur>
          <feOffset dx="0" dy="0" in="i33" result="i34"></feOffset>
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1599999964237213 0"
            type="matrix"
            in="i34"
          ></feColorMatrix>
        </filter>
        <filter
          id="i35"
          x="-6.11072502%"
          y="-11.5384615%"
          filterUnits="userSpaceOnUse"
          width="117.55571%"
          height="115.384615%"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="i36"
          ></feGaussianBlur>
          <feOffset dx="0" dy="0" in="i36" result="i37"></feOffset>
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1599999964237213 0"
            type="matrix"
            in="i37"
          ></feColorMatrix>
        </filter>
        <filter
          id="i38"
          x="-6.11072502%"
          y="-11.5384615%"
          filterUnits="userSpaceOnUse"
          width="117.55571%"
          height="115.384615%"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="i39"
          ></feGaussianBlur>
          <feOffset dx="0" dy="0" in="i39" result="i40"></feOffset>
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1599999964237213 0"
            type="matrix"
            in="i40"
          ></feColorMatrix>
        </filter>
      </defs>
      <g transform="translate(-48.0 -18.0)">
        <g clipPath="url(#i0)">
          <g transform="translate(49.0 19.0)">
            <g transform="translate(84.0 21.0)">
              <polygon
                points="12,0 22.3923048,6 22.3923048,18 12,24 1.60769515,18 1.60769515,6 12,0 12,0"
                stroke="none"
                fill="#000000"
                filter="url(#i1)"
              ></polygon>
              <g clipPath="url(#i4)">
                <polygon
                  points="1.60769515,0 22.3923048,0 22.3923048,24 1.60769515,24 1.60769515,0"
                  stroke="none"
                  fill="#3EAF9C"
                ></polygon>
              </g>
            </g>
            <g transform="translate(72.0 42.0)">
              <polygon
                points="12,0 22.3923048,6 22.3923048,18 12,24 1.60769515,18 1.60769515,6 12,0 12,0"
                stroke="none"
                fill="#000000"
                filter="url(#i5)"
              ></polygon>
              <g clipPath="url(#i4)">
                <polygon
                  points="1.60769515,0 22.3923048,0 22.3923048,24 1.60769515,24 1.60769515,0"
                  stroke="none"
                  fill="#248F79"
                ></polygon>
              </g>
            </g>
            <g transform="translate(24.0 42.0)">
              <polygon
                points="12,0 22.3923048,6 22.3923048,18 12,24 1.60769515,18 1.60769515,6 12,0 12,0"
                stroke="none"
                fill="#000000"
                filter="url(#i8)"
              ></polygon>
              <g clipPath="url(#i4)">
                <polygon
                  points="1.60769515,0 22.3923048,0 22.3923048,24 1.60769515,24 1.60769515,0"
                  stroke="none"
                  fill="#3EAF9C"
                ></polygon>
              </g>
            </g>
            <g transform="translate(72.0 0.0)">
              <polygon
                points="12,0 22.3923048,6 22.3923048,18 12,24 1.60769515,18 1.60769515,6 12,0 12,0"
                stroke="none"
                fill="#000000"
                filter="url(#i11)"
              ></polygon>
              <g clipPath="url(#i4)">
                <polygon
                  points="1.60769515,0 22.3923048,0 22.3923048,24 1.60769515,24 1.60769515,0"
                  stroke="none"
                  fill="#248F79"
                ></polygon>
              </g>
            </g>
            <g transform="translate(48.0 0.0)">
              <polygon
                points="12,0 22.3923048,6 22.3923048,18 12,24 1.60769515,18 1.60769515,6 12,0 12,0"
                stroke="none"
                fill="#000000"
                filter="url(#i14)"
              ></polygon>
              <g clipPath="url(#i4)">
                <polygon
                  points="1.60769515,0 22.3923048,0 22.3923048,24 1.60769515,24 1.60769515,0"
                  stroke="none"
                  fill="#3EAF9C"
                ></polygon>
              </g>
            </g>
            <polygon
              points="12,0 22.3923048,6 22.3923048,18 12,24 1.60769515,18 1.60769515,6 12,0 12,0"
              stroke="none"
              fill="#000000"
              filter="url(#i17)"
            ></polygon>
            <g clipPath="url(#i4)">
              <polygon
                points="1.60769515,0 22.3923048,0 22.3923048,24 1.60769515,24 1.60769515,0"
                stroke="none"
                fill="#3EAF9C"
              ></polygon>
            </g>
            <g transform="translate(96.0 42.0)">
              <polygon
                points="12,0 22.3923048,6 22.3923048,18 12,24 1.60769515,18 1.60769515,6 12,0 12,0"
                stroke="none"
                fill="#000000"
                filter="url(#i20)"
              ></polygon>
              <g clipPath="url(#i4)">
                <polygon
                  points="1.60769515,0 22.3923048,0 22.3923048,24 1.60769515,24 1.60769515,0"
                  stroke="none"
                  fill="#248F79"
                ></polygon>
              </g>
            </g>
            <g transform="translate(144.0 42.0)">
              <polygon
                points="12,0 22.3923048,6 22.3923048,18 12,24 1.60769515,18 1.60769515,6 12,0 12,0"
                stroke="none"
                fill="#000000"
                filter="url(#i23)"
              ></polygon>
              <g clipPath="url(#i4)">
                <polygon
                  points="1.60769515,0 22.3923048,0 22.3923048,24 1.60769515,24 1.60769515,0"
                  stroke="none"
                  fill="#248F79"
                ></polygon>
              </g>
            </g>
            <g transform="translate(108.0 21.0)">
              <polygon
                points="12,0 22.3923048,6 22.3923048,18 12,24 1.60769515,18 1.60769515,6 12,0 12,0"
                stroke="none"
                fill="#000000"
                filter="url(#i26)"
              ></polygon>
              <g clipPath="url(#i4)">
                <polygon
                  points="1.60769515,0 22.3923048,0 22.3923048,24 1.60769515,24 1.60769515,0"
                  stroke="none"
                  fill="#248F79"
                ></polygon>
              </g>
            </g>
            <g transform="translate(156.0 63.0)">
              <polygon
                points="12,0 22.3923048,6 22.3923048,18 12,24 1.60769515,18 1.60769515,6 12,0 12,0"
                stroke="none"
                fill="#000000"
                filter="url(#i29)"
              ></polygon>
              <g clipPath="url(#i4)">
                <polygon
                  points="1.60769515,0 22.3923048,0 22.3923048,24 1.60769515,24 1.60769515,0"
                  stroke="none"
                  fill="#3EAF9C"
                ></polygon>
              </g>
            </g>
            <g transform="translate(204.0 63.0)">
              <polygon
                points="12,0 22.3923048,6 22.3923048,18 12,24 1.60769515,18 1.60769515,6 12,0 12,0"
                stroke="none"
                fill="#000000"
                filter="url(#i32)"
              ></polygon>
              <g clipPath="url(#i4)">
                <polygon
                  points="1.60769515,0 22.3923048,0 22.3923048,24 1.60769515,24 1.60769515,0"
                  stroke="none"
                  fill="#3EAF9C"
                ></polygon>
              </g>
            </g>
            <g transform="translate(132.0 63.0)">
              <polygon
                points="12,0 22.3923048,6 22.3923048,18 12,24 1.60769515,18 1.60769515,6 12,0 12,0"
                stroke="none"
                fill="#000000"
                filter="url(#i35)"
              ></polygon>
              <g clipPath="url(#i4)">
                <polygon
                  points="1.60769515,0 22.3923048,0 22.3923048,24 1.60769515,24 1.60769515,0"
                  stroke="none"
                  fill="#248F79"
                ></polygon>
              </g>
            </g>
            <g transform="translate(120.0 42.0)">
              <polygon
                points="12,0 22.3923048,6 22.3923048,18 12,24 1.60769515,18 1.60769515,6 12,0 12,0"
                stroke="none"
                fill="#000000"
                filter="url(#i38)"
              ></polygon>
              <g clipPath="url(#i4)">
                <polygon
                  points="1.60769515,0 22.3923048,0 22.3923048,24 1.60769515,24 1.60769515,0"
                  stroke="none"
                  fill="#248F79"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
