import type { DragEndEvent } from "@dnd-kit/core";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import {
  horizontalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { useQueryClient } from "@tanstack/react-query";
import type { DashboardDto, LensTabWithVisibilityDto } from "apis/oag";
import { DashboardType, LensTabStateType, UserLensTabsApi } from "apis/oag";
import { arrayMoveImmutable } from "array-move";
import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import { useUserDashboard } from "hooks/dashboard/useUserLensTabs";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useWellShortInfo } from "hooks/wells/useWellShortInfo";
import { IconContainer } from "icons/styles";
import { useAddTabModal } from "pages/WellDashboard/AddNewTabModal";
import {
  AddNewTabButton,
  LensTabsBar,
  OperatorTabs,
  PersonalLensTabContainer,
  PersonalTabs,
  StyledEditTabButton,
  StyledTooltipBody,
} from "pages/WellDashboard/style";
import { SortableTab } from "pages/WellDashboard/Tab";
import { TabManagementDropdown } from "pages/WellDashboard/TabManagementDropdown/TabManagementDropdown";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { Col, Tooltip } from "utils/componentLibrary";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

// TODO maybe add some tracking at some point
const userLensTabsApi = new UserLensTabsApi(apiConfig);
type keys = keyof Omit<DashboardDto, "defaultZoom">;
const tabIcons: Record<keys, React.ReactNode> = {
  operatorTabs: <PDComponent.SvgIcon name="contentDeliveryNetwork" />,
  personalTabs: <PDComponent.SvgIcon name="userProfile" />,
};

export const LensTabs = ({
  selectedTabId,
  setSelectedTabId,
  dashboardType,
  isEditingTabs,
  setIsEditingTabs,
}: {
  selectedTabId: number | null;
  setSelectedTabId: (tabId: number | null) => void;
  dashboardType: DashboardType;
  isEditingTabs: boolean;
  setIsEditingTabs: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const queryClient = useQueryClient();

  const selectedRigId = useAppSelector(
    (state) => state.rigDashboard.selectedRig,
  );
  const { data: lensTabsDashboard } = useUserDashboard(dashboardType);

  // with hidden/rig-specific tabs
  const personalTabs = useMemo(
    () => lensTabsDashboard?.personalTabs || [],
    [lensTabsDashboard],
  );
  const operatorTabs = useMemo(
    () => lensTabsDashboard?.operatorTabs || [],
    [lensTabsDashboard],
  );
  const flatLensTabs = useMemo(
    () => [...operatorTabs, ...personalTabs],
    [operatorTabs, personalTabs],
  );

  const [currentPersonalTabs, setCurrentPersonalTabs] = useState(personalTabs);

  const selectedTab = flatLensTabs.find((tab) => tab.id === selectedTabId);

  useEffect(() => {
    setCurrentPersonalTabs(personalTabs);
  }, [personalTabs]);

  const navigate = useNavigate();
  const location = useLocation();
  const focalWellId = useSelectedWell();
  const { data: wellShortInfo } = useWellShortInfo();

  const { addTabModalElement, openAddTabModal } = useAddTabModal();

  const rigIds = useMemo(
    () =>
      dashboardType !== DashboardType.Rig
        ? wellShortInfo?.byId?.[focalWellId || -1]?.rigIds || []
        : [selectedRigId],
    [dashboardType, focalWellId, selectedRigId, wellShortInfo?.byId],
  );
  const tabsToView = useCallback(
    (tabs: LensTabWithVisibilityDto[]) => {
      if (!tabs?.length) return [];
      return tabs.filter((tab) => {
        if (tab.state !== LensTabStateType.Visible) return false;
        const uniqueRigIds = [...new Set(rigIds)];
        return uniqueRigIds.some((rigId) =>
          (tab.rigIds ?? []).length === 0
            ? true
            : (tab.rigIds ?? []).includes(rigId || -1),
        );
      });
    },
    [rigIds],
  );
  const calculateSelectedTabId = useCallback(
    (prevSelectedTabId: number | null): number => {
      const visibleLensTabs = tabsToView(flatLensTabs);
      if (prevSelectedTabId && !visibleLensTabs?.length)
        return prevSelectedTabId;

      const selectedTabQuery = parseInt(
        qs.parse(location.search, { ignoreQueryPrefix: true })
          .selectedTab as string,
        10,
      );

      const selectedTabPossibilities =
        visibleLensTabs.find((tab) => tab.id === prevSelectedTabId) ??
        visibleLensTabs.find((tab) => tab.id === selectedTabQuery);

      return selectedTabPossibilities?.id ?? visibleLensTabs[0]?.id;
    },
    [flatLensTabs, location.search, tabsToView],
  );

  useEffect(() => {
    setSelectedTabId(calculateSelectedTabId(selectedTabId));
  }, [calculateSelectedTabId, selectedTabId, setSelectedTabId]);

  const sensors = useSensors(useSensor(PointerSensor));
  const stringIds = useMemo(
    () => currentPersonalTabs.map((tab) => tab.id.toString()),
    [currentPersonalTabs],
  );

  const handleChangeTabsPosition = useCallback(
    (event: DragEndEvent) => {
      setCurrentPersonalTabs((prevPersonalTabs) => {
        const { active, over } = event;

        if (over && active.id !== over.id) {
          const oldIndex = stringIds.indexOf(active.id.toString());
          const newIndex = stringIds.indexOf(over.id.toString());

          // Update UI pending the query invalidation to finish
          // otherwise the tab does a visual back-and-forth between new->old->new position
          const tabsWithFreshPosition = arrayMoveImmutable(
            prevPersonalTabs,
            oldIndex,
            newIndex,
          ).map((tabItem, index) => ({
            ...tabItem,
            position: index + 1,
          }));

          userLensTabsApi.apiUserLensTabsIdPositionNewPositionPut({
            id: +active.id,
            newPosition: newIndex + 1, // Positions are 1-based when array indices are 0-based
          });
          return tabsWithFreshPosition;
        }
        return prevPersonalTabs;
      });
    },
    [stringIds],
  );

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [
        {
          type: PDQueryType.USER_LENS_TABS,
          uid: RequestUID.userLensTabs,
          dashboardType,
        },
      ],
    });
  }, [dashboardType, isEditingTabs, queryClient]);

  const { themeStyle } = useCustomTheme();
  const addNewLens = useCallback(() => {
    const prevSelectedTabId = selectedTabId;
    const crtTabId = calculateSelectedTabId(prevSelectedTabId);
    navigate(
      `${location.pathname}/lens-library${
        location.search
          ? `${
              location.search.includes("selectedTab")
                ? (location.search ?? "").replaceAll(
                    /selectedTab=[0-9]*/g,
                    `selectedTab=${crtTabId}`,
                  )
                : `${location.search}&selectedTab=${crtTabId}`
            }`
          : `?selectedTab=${crtTabId}`
      }`,
    );
    setSelectedTabId(crtTabId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calculateSelectedTabId,
    location.pathname,
    location.search,
    navigate,
    selectedTabId,
  ]);

  return (
    <>
      {isEditingTabs ? (
        <StyledEditTabButton>
          <Button
            size="large"
            type="primary"
            trackingName="Edit Tabs"
            onClick={() => {
              setIsEditingTabs((prev) => !prev);
            }}
            icon={
              isEditingTabs ? (
                <PDComponent.SvgIcon name="exit" width="17" height="17" />
              ) : null
            }
          >
            {isEditingTabs ? "Exit" : "Edit Tabs"}
          </Button>
        </StyledEditTabButton>
      ) : (
        <TabManagementDropdown openEdit={() => setIsEditingTabs(true)} />
      )}
      <LensTabsBar>
        <OperatorTabs>
          <Tooltip
            title={
              <StyledTooltipBody>
                <PDComponent.SvgIcon
                  name="information"
                  width="16"
                  height="16"
                />
                Company tabs are locked with limited settings
              </StyledTooltipBody>
            }
            overlayStyle={{ maxWidth: "none" }}
            placement="topLeft"
            arrowPointAtCenter
          >
            <IconContainer
              style={{
                alignSelf: "center",
                color: themeStyle.colors.primary_typography,
              }}
            >
              {tabIcons["operatorTabs"]}
            </IconContainer>
          </Tooltip>
          {tabsToView(operatorTabs ?? []).map((tab) => (
            <SortableTab
              isEditingTabs={isEditingTabs}
              isOperatorTab
              key={tab.id}
              tab={tab}
              selectedTabId={selectedTabId}
              setSelectedTabId={setSelectedTabId}
            />
          ))}
        </OperatorTabs>
        <PersonalTabs>
          <Tooltip
            title={
              <StyledTooltipBody>
                <PDComponent.SvgIcon
                  name="information"
                  width="16"
                  height="16"
                />
                User tabs are fully customizable
              </StyledTooltipBody>
            }
            overlayStyle={{ maxWidth: "none" }}
            placement="topLeft"
            arrowPointAtCenter
          >
            <IconContainer
              style={{
                alignSelf: "center",
                color: themeStyle.colors.primary_typography,
              }}
            >
              {tabIcons["personalTabs"]}
            </IconContainer>
          </Tooltip>
          <PersonalLensTabContainer>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleChangeTabsPosition}
              modifiers={[restrictToHorizontalAxis]}
            >
              <SortableContext
                items={stringIds}
                strategy={horizontalListSortingStrategy}
              >
                {tabsToView(currentPersonalTabs ?? []).map((tab) => (
                  <SortableTab
                    isEditingTabs={isEditingTabs}
                    key={tab.id}
                    tab={tab}
                    selectedTabId={selectedTabId}
                    setSelectedTabId={setSelectedTabId}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </PersonalLensTabContainer>
          <Tooltip
            title={<StyledTooltipBody>Add new tab</StyledTooltipBody>}
            overlayStyle={{ maxWidth: "none" }}
            placement="topLeft"
            arrowPointAtCenter
          >
            <AddNewTabButton
              onClick={() => {
                openAddTabModal();
              }}
            >
              <IconContainer>
                <PDComponent.SvgIcon name="add" />
              </IconContainer>
            </AddNewTabButton>
          </Tooltip>
        </PersonalTabs>
      </LensTabsBar>

      <Col
        style={{
          backgroundColor: themeStyle.colors.personal_tab_bar_bg,
          height: "64px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 32px",
        }}
      >
        <Tooltip
          title={
            selectedTab?.isLocked ? (
              <StyledTooltipBody>
                <PDComponent.SvgIcon
                  name="information"
                  width="16"
                  height="16"
                />
                This option is not available on locked tabs
              </StyledTooltipBody>
            ) : (
              <StyledTooltipBody>Add Lens</StyledTooltipBody>
            )
          }
          overlayStyle={{ maxWidth: "none" }}
          placement="topLeft"
          arrowPointAtCenter
        >
          <div>
            <Button
              size="large"
              $secondary
              trackingName="Add New Lens"
              disabled={selectedTab?.isLocked || flatLensTabs.length === 0}
              onClick={addNewLens}
              icon={<PDComponent.SvgIcon name="addLensLogo" />}
            >
              Add Lens
            </Button>
          </div>
        </Tooltip>
      </Col>
      {addTabModalElement}
    </>
  );
};
