/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  IntelRankingRibbonFactSetDto,
  IntelRankingRibbonUserLensDto,
  WellIntelQueryDto,
} from '../models/index';
import {
    IntelRankingRibbonFactSetDtoFromJSON,
    IntelRankingRibbonFactSetDtoToJSON,
    IntelRankingRibbonUserLensDtoFromJSON,
    IntelRankingRibbonUserLensDtoToJSON,
    WellIntelQueryDtoFromJSON,
    WellIntelQueryDtoToJSON,
} from '../models/index';

export interface ApiIntelRankingRibbonUserLensesIdFactsPutRequest {
    id: number;
    wellIntelQueryDto?: WellIntelQueryDto;
}

export interface ApiIntelRankingRibbonUserLensesIdPutRequest {
    id: number;
    intelRankingRibbonUserLensDto?: IntelRankingRibbonUserLensDto;
}

export interface ApiIntelRankingRibbonUserLensesPostRequest {
    intelRankingRibbonUserLensDto?: IntelRankingRibbonUserLensDto;
}

/**
 * 
 */
export class IntelRankingRibbonUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiIntelRankingRibbonUserLensesIdFactsPutRaw(requestParameters: ApiIntelRankingRibbonUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntelRankingRibbonFactSetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiIntelRankingRibbonUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/IntelRankingRibbonUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WellIntelQueryDtoToJSON(requestParameters['wellIntelQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntelRankingRibbonFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiIntelRankingRibbonUserLensesIdFactsPut(requestParameters: ApiIntelRankingRibbonUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntelRankingRibbonFactSetDto> {
        const response = await this.apiIntelRankingRibbonUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiIntelRankingRibbonUserLensesIdPutRaw(requestParameters: ApiIntelRankingRibbonUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntelRankingRibbonUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiIntelRankingRibbonUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/IntelRankingRibbonUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IntelRankingRibbonUserLensDtoToJSON(requestParameters['intelRankingRibbonUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntelRankingRibbonUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiIntelRankingRibbonUserLensesIdPut(requestParameters: ApiIntelRankingRibbonUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntelRankingRibbonUserLensDto> {
        const response = await this.apiIntelRankingRibbonUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiIntelRankingRibbonUserLensesPostRaw(requestParameters: ApiIntelRankingRibbonUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntelRankingRibbonUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/IntelRankingRibbonUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntelRankingRibbonUserLensDtoToJSON(requestParameters['intelRankingRibbonUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntelRankingRibbonUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiIntelRankingRibbonUserLensesPost(requestParameters: ApiIntelRankingRibbonUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntelRankingRibbonUserLensDto> {
        const response = await this.apiIntelRankingRibbonUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
