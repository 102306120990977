import type { FuelFlowRateLensTemplateDto } from "apis/oag";
import { FuelConsumerType } from "apis/oag";
import { BoilerFuelRateChart } from "components/Lenses/ContainerLens/FuelFlowRate/Chart/BoilerFuelRateChart";
import { FuelRateChart } from "components/Lenses/ContainerLens/FuelFlowRate/Chart/FuelRateChart";
import type { FuelFlowRateProps } from "components/Lenses/interfaces";
import { useLensTemplates } from "hooks/lens/useLensTemplates";
import { useMemo } from "react";

export const FuelFlowRateKpi: React.FC<FuelFlowRateProps> = ({ lens }) => {
  const {
    data: { byId: templatesbyId },
  } = useLensTemplates();

  const fuelConsumerType = useMemo(
    () =>
      (templatesbyId[lens.lensTemplateId] as FuelFlowRateLensTemplateDto)
        ?.fuelConsumerType,
    [lens.lensTemplateId, templatesbyId],
  );

  if (fuelConsumerType === FuelConsumerType.Generator) {
    return <FuelRateChart lens={lens} detailed={false} />;
  }

  return <BoilerFuelRateChart lens={lens} detailed={false} />;
};
