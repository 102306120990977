import { PDComponent } from "components/PDComponents";
import { CheckboxCheckedState } from "components/PDComponents/Checkbox/Checkbox";
import React from "react";

import * as Styled from "./style";

export const FilterOptionRow = React.memo(
  ({
    optionName,
    idForLabel,
    onOptionChecked,
    checkedState,
  }: {
    optionName: string;
    idForLabel: string;
    onOptionChecked: (isChecked: boolean) => void;
    checkedState: CheckboxCheckedState;
  }) => {
    return (
      <Styled.OptionRowContainer
        title={optionName}
        onClick={() =>
          onOptionChecked(checkedState !== CheckboxCheckedState.Checked)
        }
      >
        <PDComponent.Checkbox
          size={12}
          onChange={() => null}
          checkedState={checkedState}
          id={idForLabel}
        />
        <Styled.OptionLabel htmlFor={idForLabel}>
          {optionName}
        </Styled.OptionLabel>
      </Styled.OptionRowContainer>
    );
  },
);
