import { Title } from "atoms/Typography";
import { SCROLLBAR_GAP_AROUND } from "components/Layout/LayoutUtils";
import { TABLE_HEADER_HEIGHT } from "pages/FleetPerformance/components/utils";
import styled, { css } from "styled-components";

const TableRowGridProportions = css`
  grid-template-columns: minmax(20px, 0.25fr) 0.75fr 0.5fr minmax(30px, 0.55fr);
`;

export const StyledTableContainer = styled.div<{ $isExpanded: boolean }>`
  width: calc(
    ${({ $isExpanded }) => ($isExpanded ? 97.5 : 100)}% -
      ${SCROLLBAR_GAP_AROUND}px
  );
  height: 100%;
  overflow: hidden;
  padding-left: 15px;
  background-color: transparent;

  .ant-table {
    background-color: transparent;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0px;
  }
  .ant-table-container table > thead > tr:last-child th:last-child {
    border-top-right-radius: 0px;
  }
  .ant-table-cell-scrollbar {
    box-shadow: none;
  }
  .ant-table-body {
    margin-right: 10px;
    ::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
      background-color: transparent;
      cursor: grab;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) =>
        theme.themeStyle.colors.primary_scroll_bg};
      border-radius: 2px;
      cursor: grab;
    }
  }
`;

export const StyledTableRow = styled.div<{
  $isHighlighted: boolean;
  $isActive: boolean;
}>`
  height: 21px;
  display: grid;
  ${TableRowGridProportions};
  background: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.themeStyle.colors.row_positive_hover : undefined};
  :hover {
    background: ${({ theme }) => theme.themeStyle.colors.row_positive_hover};
  }
  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      background: ${theme.themeStyle.colors.row_positive_hover};
    `}
`;

export const StyledHeaderCell = styled.div`
  white-space: nowrap;
  background-color: transparent;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
`;

export const StyledLabelText = styled(Title).attrs({
  level: 4,
})`
  white-space: nowrap;
`;

export const StyledKpiValue = styled(Title).attrs({
  level: 4,
})`
  font-size: 14px;
`;

export const StyledBodyCell = styled.div`
  display: table-cell;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border: none;
  background-color: transparent;
  @media (max-width: 1280px) {
    padding: 0px;
  }
`;

export const StyledTableHeader = styled.div`
  height: ${TABLE_HEADER_HEIGHT}px;
  display: grid;
  width: calc(100% - 12px);
  ${TableRowGridProportions};
`;

export const StyledTag = styled.div<{ $positiveValue: boolean }>`
  width: 60px;
  height: 20px;
  border-radius: 20px;
  text-align: center;
  line-height: 20px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  background-color: ${({
    $positiveValue,
    theme: {
      themeStyle: { colors: themeColors },
    },
  }) => ($positiveValue ? themeColors.positive_tag : themeColors.negative_tag)};
`;

export const StyledTagContainer = styled.div`
  width: 100%;
  display: flex;
  place-content: center;
`;
