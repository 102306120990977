import { PDComponent } from "components/PDComponents";
import React from "react";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Select as AntSelect, Tooltip } from "utils/componentLibrary";

const SelectSuffixIconWrapper = styled.div<{ iconColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  color: ${(props) => props.iconColor || colors.neutral};
  width: 36px;

  &&& * {
    pointer-events: none;
  }
`;

export const Select = styled(AntSelect).attrs<{
  error?: string;
  $fullWidth?: boolean;
}>((props) => ({
  size: "large",
  suffixIcon: (
    <SelectSuffixIconWrapper iconColor={colors.gray}>
      <div className="select-error">
        <Tooltip placement="bottomRight" title={props.error} arrowPointAtCenter>
          <PDComponent.SvgIcon name="warning" />
        </Tooltip>
      </div>
      <PDComponent.SvgIcon name="chevronDown" />
    </SelectSuffixIconWrapper>
  ),
}))<{ error?: string; $fullWidth?: boolean }>`
  height: 36px;
  background: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};

  &&.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
    border-radius: 4px;
    background: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};

    .ant-select-selection-placeholder {
      color: ${colors.gray};
      opacity: 0.5;
    }
  }

  &:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  }

  &&.ant-select-disabled .ant-select-selector {
    background-color: ${colors.white};
  }

  .select-error {
    margin-right: 6px;
    margin-left: -22px;
    display: none;
  }

  ${(props) =>
    props.error &&
    css`
      color: ${colors.error};

      .select-error {
        display: block;
        color: ${colors.error};
      }
    `}
  width: ${(props) => (props.$fullWidth ? "100%" : "auto")};
`;

export const SelectAlt = styled(Select)<{ isicondisabled?: string }>`
  ${(props) =>
    props.isicondisabled === "true"
      ? css`
          .ant-select-selection-item {
            padding-left: 18px !important;
          }
        `
      : null}
  ${(props) =>
    props.isicondisabled === "true"
      ? css`
          .ant-select-selector {
            position: relative;
            &::after {
              position: absolute;
              left: 58px;
              top: 9px;
              visibility: inherit;
              height: 20px !important;
              width: 20px !important;
              margin-left: -50px;
              mask-image: url("/assets/time.svg");
              mask-repeat: no-repeat;
              background-color: ${({ theme }) =>
                theme.themeStyle.colors.primary_typography} !important;
              background-size: 16px;
              background-repeat: no-repeat;
            }
          }
        `
      : null}
  &&.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    .ant-select-selection-item {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: -0.2px;
    }
  }

  &:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${colors.actions_bg};
  }

  .ant-select-arrow {
    top: 0;
    right: 0;
    margin-top: 0;
    height: 36px;
    width: 36px;
    background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
    border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
