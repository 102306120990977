import type {
  FiltersDto,
  LensTemplateType,
  ParameterByDepthTrackDetailsDto,
  ParameterByDepthTrackValuesDto,
  PivotTargetSegment,
} from "apis/oag";
import { DashboardBlockSize } from "apis/oag";
import {
  GeneratorSlotType,
  OutlierFlaggingType,
  VisualAidType,
} from "apis/oag";
import type {
  ZtrackInterval,
  ZtrackIntervalsWithWellId,
} from "components/Lenses/interfaces";
import { max } from "d3-array";
import type { ComputedTargetSegment } from "hooks/charting/useTargetSegments";
import { isEqual, xor } from "lodash";
import { createContext } from "react";
import type { Layout } from "react-grid-layout";
import { type IFiltersType, initialFilters } from "reducers/types";
import colors from "utils/colors";

const BlankComponent = () => <>This lens is not implemented</>;

export const lensComponentMap: Record<
  LensTemplateType,
  (props: unknown) => JSX.Element
> = {
  // Un-migrated lenses that need to be migrated as container lenses in the future
  // For legacy compatibility purposes with TypeScript
  BatteryState: BlankComponent,
  DieselNatGasRatioStats: BlankComponent,
  DrillingProductivity: BlankComponent,
  FuelConsumptionByGenerator: BlankComponent,
  FuelFlowRate: BlankComponent,
  FuelTankVolume: BlankComponent,
  GeneratorLoadProfile: BlankComponent,
  GeneratorStats: BlankComponent,
  GeneratorStatusBar: BlankComponent,
  GhgEmissions: BlankComponent,
  NaturalGasState: BlankComponent,
  ParameterByDepth: BlankComponent,
  ParameterByDepthRoadmap: BlankComponent,
  ParameterByDepthUnified: BlankComponent,
  ParameterHeatmap: BlankComponent,
  PivotKpiGroup: BlankComponent,
  PivotKpiType: BlankComponent,
  PowerConsumptionByOperation: BlankComponent,
  PowerLoadDistribution: BlankComponent,
  PowerLoadEfficiency: BlankComponent,
  RigFleetPerformanceCard: BlankComponent,
  RigPower: BlankComponent,
  RigScorecard: BlankComponent,
  SingleKpi: BlankComponent,
  StackedKpis: BlankComponent,
  StatCard: BlankComponent,
  TorqueAndDrag: BlankComponent,
  Undefined: BlankComponent,
  Wedge: BlankComponent,
  WellDrillingSummary: BlankComponent,
  TagBottomFingerprint: BlankComponent,
  StickSlipByTime: BlankComponent,
  StickSlipByDepth: BlankComponent,
  IntelScatterPlot: BlankComponent,
  IntelRankingRibbon: BlankComponent,
  IntelScorecard: BlankComponent,
};

export interface ICrtLayout {
  lg?: Layout[];
  md?: Layout[];
  sm?: Layout[];
  xxs?: Layout[];
}

export const LensGridContext = createContext<{
  setDeletedElements?: React.Dispatch<React.SetStateAction<number[]>>;
  crtLayout: ICrtLayout | null;
  bp: keyof ICrtLayout;
  dashboardScreenWidth: DashboardBlockSize;
}>({
  crtLayout: {},
  bp: "lg",
  dashboardScreenWidth: DashboardBlockSize.Unknown,
});

export const getSVGNormalizedValue = (
  x: number | undefined = 0,
  bottomLimit: number = 0,
) => (Number.isFinite(x) && x >= bottomLimit ? x : bottomLimit);

export const allGenerators = [
  GeneratorSlotType.Generator1,
  GeneratorSlotType.Generator2,
  GeneratorSlotType.Generator3,
  GeneratorSlotType.Generator4,
];

export const checkExcluded = (
  standValue: number,
  outlierThreshold: number,
  outlierFlaggingType: OutlierFlaggingType,
  outlierMin: number,
  outlierMax: number,
) => {
  const threshold = outlierThreshold || Number.MAX_VALUE;
  return (
    (outlierFlaggingType === OutlierFlaggingType.None &&
      standValue > threshold) ||
    (outlierFlaggingType === OutlierFlaggingType.Manual &&
      (standValue < outlierMin || standValue > outlierMax))
  );
};

export const getMinMax = (
  data: number[],
  targetSegments:
    | (PivotTargetSegment | ComputedTargetSegment)[]
    | undefined
    | null,
  outlierThreshold: number | null,
  medianValue: number | undefined,
  selectedVisualAids: VisualAidType[],
  outlierFlaggingType: OutlierFlaggingType,
  isManualYaxis: boolean,
  yaxisEnd?: number | null,
  yaxisStart?: number | null,
) => {
  const minValue = isManualYaxis ? yaxisStart || 0 : 0;

  const getMaxValue = () => {
    if (isManualYaxis) {
      return yaxisEnd || 0;
    }

    const dataPool = [...data];

    if ((selectedVisualAids ?? []).includes(VisualAidType.Median)) {
      dataPool.push(medianValue || 0);
    }

    const maxValue = max(dataPool) ?? 0;
    const maxTarget =
      (selectedVisualAids ?? []).includes(VisualAidType.Targets) &&
      targetSegments
        ? max(targetSegments.map((targetSegment) => targetSegment.target || 0))
        : 0;

    if (
      (selectedVisualAids ?? []).includes(VisualAidType.Targets) &&
      (maxTarget || 0) > maxValue
    ) {
      return maxTarget || 0;
    }

    if (outlierThreshold !== null && outlierThreshold < maxValue) {
      return outlierThreshold;
    }

    return maxValue;
  };

  return [minValue, getMaxValue()];
};

export const selectedBrushStyle = {
  stroke: colors.necron_compound,
  fill: colors.infinity_transparent,
  strokeDasharray: "5",
};

export function compressTrackValues(
  trackValues: ParameterByDepthTrackValuesDto[],
) {
  let start = 0;
  let end = 0;

  const intervals: ZtrackInterval[] = [];

  trackValues.forEach((point, idx) => {
    if (point.value === 1) {
      if (trackValues[start].value === 0) {
        start = idx;
      }
      if (idx === trackValues.length - 1 && trackValues[start].value === 1) {
        intervals.push({
          startDepth: trackValues[start].holeDepth,
          endDepth: trackValues[idx].holeDepth,
          startDate: trackValues[start].timestamp,
          endDate: trackValues[idx].timestamp,
          isOn: true,
        });
      }
    } else if (trackValues[start].value === 1) {
      intervals.push({
        startDepth: trackValues[start].holeDepth,
        endDepth: trackValues[end].holeDepth,
        startDate: trackValues[start].timestamp,
        endDate: trackValues[end].timestamp,
        isOn: true,
      });
      start = idx;
    }
    end = idx;
  });
  return intervals;
}

export function compressZTrackRanges(
  trackValuesWithWellId: {
    track: ParameterByDepthTrackDetailsDto;
    wellId: number;
  }[],
): Array<ZtrackIntervalsWithWellId> {
  const outerIntervals = [];

  for (const track of trackValuesWithWellId) {
    const trackValues = track?.track.trackValues;
    const intervals = compressTrackValues(trackValues);

    outerIntervals.push({ wellId: track.wellId, intervals });
  }

  return outerIntervals;
}

export const checkActiveState = (
  availableFilters: FiltersDto | undefined,
  filtersState: IFiltersType,
) =>
  !isEqual(filtersState, initialFilters) &&
  !(
    availableFilters &&
    filtersState &&
    xor(
      filtersState.directionalIntervals,
      availableFilters.directionalIntervalIds,
    ).length === 0 &&
    xor(filtersState.holeSizes, availableFilters.holeSizeIds).length === 0 &&
    xor(filtersState.sections, availableFilters.sectionIds).length === 0 &&
    xor(filtersState.phases, availableFilters.phaseIds).length === 0 &&
    (filtersState?.operationTime ?? []).includes("Flat Time") ===
      availableFilters.includeFlatTime &&
    (filtersState?.operationTime ?? []).includes("Sliding Time") ===
      availableFilters.includeSlidingTime &&
    (filtersState?.operationTime ?? []).includes("Rotating Time") ===
      availableFilters.includeRotatingTime
  );
