import { LinearGradient } from "@visx/gradient";
import type { PivotTargetSegment } from "apis/oag";
import { VisualAidType } from "apis/oag";
import { max } from "d3-array";
import { Stats } from "fast-stats";
import type { ComputedTargetSegment } from "hooks/charting/useTargetSegments";
import { uniqueId } from "lodash";
import { useMemo, useRef } from "react";
import { useCustomTheme } from "utils/useTheme";

export function useOutlierThreshold({
  values,
  enabled = true,
  targetSegments,
  selectedVisualAids,
}: {
  values: number[];
  enabled?: boolean;
  targetSegments?: PivotTargetSegment[] | ComputedTargetSegment[] | null;
  selectedVisualAids?: VisualAidType[];
}) {
  const gradientId = useRef(uniqueId("outlier-gradient-"));

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const outlierThreshold = useMemo(() => {
    if (!Array.isArray(values) || values.length === 0 || !enabled) {
      return null;
    }

    const stats = new Stats().push(values);
    const mean = stats.amean();
    const stddev = stats.stddev();
    const data = values.map((value) => ({
      value,
      zScore: (value - mean) / stddev,
    }));
    const maxZScoreItem = data.reduce((a, b) => (a.zScore > b.zScore ? a : b));

    const start = +(2 * stddev + mean).toFixed(3);

    const maxTarget =
      (selectedVisualAids ?? []).includes(VisualAidType.Targets) &&
      targetSegments
        ? max(
            targetSegments.map((targetSegment) => targetSegment.target || 0),
          ) || 0
        : 0;

    if (maxTarget > start) {
      return null;
    }

    if (maxZScoreItem.zScore > 2) {
      return start;
    }

    return null;
  }, [enabled, values, selectedVisualAids, targetSegments]);

  const gradientDefinition = (
    <LinearGradient
      id={gradientId.current}
      from={themeColors.alt_secondary_bg}
      to={themeColors.quaterniary_bg}
      fromOpacity={1}
      toOpacity={0}
    />
  );

  const gradientFill = `url(#${gradientId.current})`;

  return { outlierThreshold, gradientDefinition, gradientFill };
}
