import { scaleBand, scaleLinear } from "@visx/scale";
import { BarRounded } from "@visx/shape";
import { VisualAidType } from "apis/oag";
import {
  AverageLabel,
  AverageLine,
} from "components/Lenses/common/AverageLine";
import { Chart } from "components/Lenses/common/Chart";
import { Label } from "components/Lenses/common/Label";
import { MedianLabel, MedianLine } from "components/Lenses/common/MedianLine";
import { StyledOperationCountContainer } from "components/Lenses/common/OperationCount";
import OperationCount from "components/Lenses/common/OperationCount";
import TargetLine from "components/Lenses/common/TargetLine";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import {
  TooltipVisualAidInfo,
  useChartTooltip,
} from "components/Lenses/common/useChartTooltip";
import type { SingleKpiChartSimpleProps } from "components/Lenses/ContainerLens/SingleKpi/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { RIGHT_AXIS_PADDING } from "components/TvDChart/constants";
import { extent } from "d3-array";
import { useTargetSegments } from "hooks/charting/useTargetSegments";
import { shade } from "polished";
import React, { useCallback, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import colors from "utils/colors";
import { OPERATION_COUNT_HEIGHT } from "utils/constants";

export function SingleShiftChart({
  data,
  valueUOM,
  detailed,
  lens,
}: SingleKpiChartSimpleProps) {
  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: containerRef,
  } = useResizeDetector();
  const { chartWidth, chartHeight: containerHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };
  const chartHeight = getSVGNormalizedValue(
    containerHeight - (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0),
  );
  const plotHeight = getSVGNormalizedValue(chartHeight - (detailed ? 136 : 36));
  const plotWidth = getSVGNormalizedValue(
    chartWidth - (detailed ? 76 : 44) - 10,
  );
  const transformedData = useMemo(() => {
    return [
      {
        totalValue: data?.detailsByShift?.dayValue ?? 0,
        id: "Day",
        isFocalWell: false,
        targetValue: data?.detailsByShift?.dayTargetValue ?? 0,
        operationCount: data?.detailsByShift?.dayOperationCount ?? 0,
      },
      {
        totalValue: data?.detailsByShift?.nightValue ?? 0,
        id: "Night",
        isFocalWell: false,
        targetValue: data?.detailsByShift?.nightTargetValue ?? 0,
        operationCount: data?.detailsByShift?.nightOperationCount ?? 0,
      },
    ];
  }, [data?.detailsByShift]);

  const categoryScale = useMemo(
    () =>
      scaleBand<string>({
        domain: transformedData.map((i) => i.id),
        // Offsetting the pixel range based on zoom, provides scrolling and zoom at the same time
        range: [0, plotWidth],
        paddingInner: 0.2,
        paddingOuter: 0.2,
      }),
    [plotWidth, transformedData],
  );

  const computedTargetSegments = useTargetSegments(
    transformedData,
    categoryScale,
  );

  const totalValues = useMemo(
    () => transformedData.map((item) => item.totalValue),
    [transformedData],
  );
  const valueScale = useMemo(() => {
    const [min = 0, max = 0] = extent([
      0,
      ...totalValues,
      lens?.selectedVisualAids.includes(VisualAidType.Median)
        ? data?.summary?.median ?? 0
        : 0,
      lens?.selectedVisualAids.includes(VisualAidType.Average)
        ? data?.summary?.average ?? 0
        : 0,
      data?.summary?.average ?? 0,
    ]);
    const minMaxDomain = lens?.isManualYaxis
      ? [lens?.yaxisStart || 0, lens?.yaxisEnd || 0]
      : [min, max];

    return scaleLinear<number>({
      domain: minMaxDomain,
      range: [plotHeight, 0],
      clamp: true,
    });
  }, [data, lens, plotHeight, totalValues]);

  const valuesDomain = useMemo(() => valueScale.domain(), [valueScale]);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<
    (typeof transformedData)[0]
  >({
    containerRef,
    renderContent: ({ tooltipData }) => (
      <>
        <TooltipHighlightValue>
          {valueUOM.displayWithAutoDecimals(
            valuesDomain,
            tooltipData?.totalValue,
          )}
        </TooltipHighlightValue>

        <TooltipVisualAidInfo
          selectedVisualAids={lens?.selectedVisualAids}
          display={(value) =>
            valueUOM.displayWithAutoDecimals(valuesDomain, value)
          }
          targetValue={tooltipData?.targetValue}
          averageValue={data.summary?.average}
          median={data.summary?.median}
        />

        {lens.showOperationCount && tooltipData?.operationCount ? (
          <TooltipHighlightValue>
            Operation count: {tooltipData.operationCount}
          </TooltipHighlightValue>
        ) : null}
        <span>{tooltipData?.id}</span>
      </>
    ),
  });

  const columnWidth = categoryScale.bandwidth();
  const handleMouseOver = useCallback(
    (barData: (typeof transformedData)[0]) => {
      showTooltip({
        tooltipLeft: (categoryScale(barData.id) || 0) + columnWidth / 2,
        tooltipTop: valueScale(barData.totalValue),
        tooltipData: barData,
      });
    },
    [categoryScale, columnWidth, showTooltip, valueScale],
  );

  return (
    <div
      ref={containerRef}
      style={{
        padding: 0,
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <StyledOperationCountContainer visible={lens.showOperationCount} />
      <svg
        width={chartWidth}
        height={chartHeight}
        style={{ overflow: "visible", userSelect: "none" }}
      >
        <Chart
          detailed={detailed}
          isManual={lens?.isManualYaxis}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          plotWidth={plotWidth}
          plotHeight={plotHeight}
          valueScale={valueScale}
          showOperationCount={lens.showOperationCount}
          categoryScale={categoryScale}
          valueUOM={valueUOM}
          tickFormat={(id) => id as string}
        >
          {transformedData.map((d, index) => {
            if (!d.totalValue) return null;
            const barHeight = Math.min(
              plotHeight - valueScale(d.totalValue ?? 0),
              plotHeight,
            );
            const barY = plotHeight - barHeight;
            const value = valueUOM.displayWithAutoDecimals(
              valuesDomain,
              d.totalValue,
              { unit: "" },
            );
            return (
              <React.Fragment key={`${d.id}-bar-${value}`}>
                <BarRounded
                  radius={4}
                  top
                  x={categoryScale(d.id) || 0}
                  y={barY}
                  key={`single-kpi-${d.id}-${index}-${d.totalValue ?? 0}`}
                  onMouseOver={() => handleMouseOver(d)}
                  onMouseOut={hideTooltip}
                  width={categoryScale.bandwidth()}
                  height={barHeight}
                  fill={(() => {
                    if (d.id === "Night") return shade(0.3, colors.well_color);
                    return shade(0, colors.well_color);
                  })()}
                />
                <Label
                  barHeight={barHeight}
                  barX={categoryScale(d.id) || 0}
                  barY={barY + (detailed ? 0 : 10)}
                  columnWidth={columnWidth}
                  detailed={detailed}
                  index={index}
                  topLabel={barY !== plotHeight}
                  value={value}
                  label={lens?.label}
                />
                {lens.showOperationCount ? (
                  <OperationCount
                    x={categoryScale(d.id) || 0}
                    width={columnWidth}
                    index={index}
                    detailed={detailed}
                    value={d.operationCount}
                  />
                ) : null}
              </React.Fragment>
            );
          })}
          <AverageLine
            isVisible={(lens.selectedVisualAids ?? []).includes(
              VisualAidType.Average,
            )}
            y={valueScale(data.summary?.average ?? 0)}
            x={-RIGHT_AXIS_PADDING}
            width={plotWidth + RIGHT_AXIS_PADDING}
          />

          <MedianLine
            isVisible={(lens?.selectedVisualAids ?? []).includes(
              VisualAidType.Median,
            )}
            y={valueScale(data.summary?.median ?? 0)}
            x={-RIGHT_AXIS_PADDING}
            width={plotWidth + RIGHT_AXIS_PADDING}
          />

          {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Targets) &&
            computedTargetSegments.map(
              ({ target, lineStart, lineEnd, showTag }, index) => {
                if (!target) return null;
                return (
                  <TargetLine
                    key={`${index}-${target}-${lineStart}-${lineEnd}`}
                    start={lineStart}
                    end={lineEnd}
                    y={valueScale(target)}
                    label={valueUOM.displayWithAutoDecimals(
                      valuesDomain,
                      target,
                      { unit: "" },
                    )}
                    showTag={!!showTag}
                    detailed={detailed}
                  />
                );
              },
            )}
        </Chart>
      </svg>
      {(lens.selectedVisualAids ?? []).includes(VisualAidType.Average) &&
      data.summary?.average ? (
        <AverageLabel
          style={{
            top: `${valueScale(data.summary?.average) - 16 + (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)}px`,
          }}
        >
          Average:{" "}
          {valueUOM.displayWithAutoDecimals(
            valuesDomain,
            data.summary?.average,
          )}
        </AverageLabel>
      ) : null}

      {(lens.selectedVisualAids ?? []).includes(VisualAidType.Median) &&
      data.summary?.median ? (
        <MedianLabel
          isDetailed={detailed}
          style={{
            top: `${
              valueScale(data.summary?.median ?? 0) -
              16 +
              (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)
            }px`,
          }}
        >
          Median:{" "}
          {valueUOM.displayWithAutoDecimals(
            valuesDomain,
            data.summary?.median ?? 0,
          )}
        </MedianLabel>
      ) : null}
      {tooltipElement}
    </div>
  );
}
