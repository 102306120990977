import type { FormationDto, PlanDto, PlanFormationDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import { Select } from "atoms/Form";
import { Input } from "components/WellPlan/Input";
import IconComponent from "components/WellPlan/WellPlanCommun/Icon";
import React, { useCallback } from "react";
import { IUnitSystem } from "reducers/types";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import { ftInM } from "utils/common";
import { useUOM, UtilDimensions } from "utils/format";

const FormationRowWrapper = styled.div`
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr 1fr 1fr 36px;
  grid-gap: 8px;
  margin-bottom: 8px;
`;

interface PlanFormationInputs {
  id: number;
  formationId?: number;
  measuredDepth?: number;
  trueVerticalDepth?: number | null;
  porePressure?: number | null;
  fracturePressure?: number | null;
}

export function FormationRow({
  errors,
  formationOptions,
  formation,
  setPlan,
  setPlanModified,
  plan,
  isFirst = false,
}: {
  errors: {
    [id: number]: {
      [key: string]: string;
    };
    startDepth?: string;
  } | null;
  formationOptions: FormationDto[];
  formation: PlanFormationInputs;
  setPlan: React.Dispatch<React.SetStateAction<PlanDto | undefined>>;
  setPlanModified: React.Dispatch<React.SetStateAction<boolean>>;
  plan?: PlanDto;
  prevDepthValue?: number;
  prevTrueVerticalDepth?: number;
  isFirst?: boolean;
}) {
  const uom = useUOM(DimensionType.Metres);
  const deleteRow = useCallback(() => {
    Track.interact("Well Formations - Delete");

    setPlan((prevPlan) =>
      prevPlan
        ? {
            ...prevPlan,
            formations: prevPlan.formations.filter(
              (item) => item.id !== formation.id,
            ),
          }
        : undefined,
    );
    setPlanModified(true);
  }, [formation.id, setPlan, setPlanModified]);

  const setNumericValue = (value: number | undefined, key: string) => {
    setPlan((prevPlan) =>
      prevPlan
        ? {
            ...prevPlan,
            formations: prevPlan.formations.map((item: PlanFormationDto) => {
              if (item.id === formation?.id) {
                return {
                  ...item,
                  [key]: value,
                };
              }
              return item;
            }),
          }
        : undefined,
    );
    setPlanModified(true);
  };

  const toSI = useCallback(
    (value: number) => {
      if (uom.currentUOM === IUnitSystem.METRIC || !value) return value;
      return value / ftInM;
    },
    [uom],
  );
  const fromSI = useCallback(
    (value: number) => {
      if (uom.currentUOM === IUnitSystem.METRIC || !value) return value;
      return value * ftInM;
    },
    [uom],
  );

  const setStartDepth = (depth: number | undefined) => {
    setPlan((prevPlan) =>
      prevPlan
        ? {
            ...prevPlan,
            startFormationDepth: depth,
          }
        : undefined,
    );
  };

  const poreFractDepth = useUOM(UtilDimensions.KgM3);

  return (
    <FormationRowWrapper>
      <Input
        tosi={toSI}
        fromsi={fromSI}
        decimals={2}
        updatevalue={(value) => {
          if (isFirst) {
            setStartDepth(value ?? undefined);
            setPlanModified(true);
            return;
          }
          setNumericValue(value ?? undefined, "measuredDepth");
        }}
        type="number"
        style={{ width: "100%" }}
        initialvalue={
          isFirst
            ? plan?.startFormationDepth ?? undefined
            : formation.measuredDepth ?? undefined
        }
        placeholder={
          uom.currentUOM === IUnitSystem.IMPERIAL ? "Feet" : "Meters"
        }
        error={
          isFirst
            ? errors?.startDepth
            : formation.id
              ? errors?.[formation.id]?.measuredDepth
              : undefined
        }
      />
      <Input
        type="number"
        initialvalue={formation.trueVerticalDepth ?? undefined}
        updatevalue={(value) => {
          setNumericValue(value ?? undefined, "trueVerticalDepth");
        }}
        tosi={toSI}
        fromsi={fromSI}
        decimals={2}
        placeholder={
          isFirst
            ? "—"
            : uom.currentUOM === IUnitSystem.IMPERIAL
              ? "Feet"
              : "Meters"
        }
        error={
          !isFirst && formation.id
            ? errors?.[formation.id]?.trueVerticalDepth
            : undefined
        }
        disabled={isFirst}
      />
      <Select
        placeholder={isFirst ? "—" : "Formation"}
        style={{ width: "100%" }}
        size="large"
        value={formation.formationId}
        onSelect={(idStr) => {
          Track.interact("Formations - Formation Top", {
            "Formation Name": formationOptions.find(
              (e) => e.id === Number(idStr),
            )?.name,
          });
          setNumericValue(idStr as number, "formationId");
        }}
        disabled={isFirst}
        error={
          !isFirst && formation.id
            ? errors?.[formation.id]?.formationId
            : undefined
        }
      >
        {formationOptions.map((option) => (
          <Select.Option key={option.id} value={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
      <Input
        type="number"
        updatevalue={(value) => {
          setNumericValue(value ?? undefined, "porePressure");
        }}
        tosi={poreFractDepth.toSI}
        fromsi={poreFractDepth.fromSI}
        decimals={2}
        placeholder={
          isFirst
            ? "—"
            : uom.currentUOM === IUnitSystem.IMPERIAL
              ? "lb/ft³"
              : "kg/m³"
        }
        initialvalue={formation.porePressure ?? undefined}
        disabled={isFirst}
      />
      <Input
        placeholder={
          isFirst
            ? "—"
            : uom.currentUOM === IUnitSystem.IMPERIAL
              ? "lb/ft³"
              : "kg/m³"
        }
        initialvalue={formation.fracturePressure ?? undefined}
        type="number"
        updatevalue={(value) => {
          setNumericValue(value ?? undefined, "fracturePressure");
        }}
        tosi={poreFractDepth.toSI}
        fromsi={poreFractDepth.fromSI}
        decimals={2}
        disabled={isFirst}
      />
      <IconComponent name="delete" disabled={isFirst} onClick={deleteRow} />
    </FormationRowWrapper>
  );
}
