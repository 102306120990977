import { Button } from "atoms/Form";
import { toast } from "atoms/toast";
import { PDComponent } from "components/PDComponents";
import type { IOption } from "components/PDComponents/Search/utils";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import { useFilteredAllWells } from "pages/AllWells/useFilteredAllWells";
import { useEffect, useState } from "react";
import { useAppSelector } from "reducers/store";
import { useAppDispatch } from "reducers/store";
import { Track } from "services/Mixpanel";
import { Popover, Tooltip } from "utils/componentLibrary";
import { colorsSequence } from "utils/useColors";
// TODO make offset selector agnostic from well or rig

const OffsetWellSelector = ({
  report,
  lens,
}: {
  report?: boolean;
  lens?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const [showSelect, setShowSelect] = useState(false);

  const selectedWell = useAppSelector(
    (state) => state[report ? "report" : "state"]?.selectedWell,
  );
  const offsetWellsReport = useAppSelector((state) => state.report.offsetWells);
  const [offsetWells, setOffsetWells] = useStateQuery<Array<number>>(
    lens ? URL_STATE_PARAM.OFFSET_WIDGET : URL_STATE_PARAM.OFFSET_WELL,
    [],
    lens ? [] : [URL_STATE_PARAM.OFFSET_WIDGET],
  );

  const { data: wellData } = useWellShortInfoSuspended();

  const availableWells = (wellData?.list ?? []).filter(
    (e) => e.id !== selectedWell,
  );
  const visibleWells = useFilteredAllWells(availableWells);
  const [showFiltered, setShowFiltered] = useState(
    visibleWells.length > 0 && visibleWells.length < availableWells.length,
  );

  const searchableWells = showFiltered ? visibleWells : availableWells;

  const onOffsetWellsChange = (selectedOffsetWells: number[] = []) => {
    if (selectedOffsetWells.length > colorsSequence.length - 1) {
      Track.interact("Too Many Offset Wells Error");
      // Allow as many offsets wells as we have colors.
      toast.error({
        message: `You can only select up to ${colorsSequence.length - 1} offset wells, please unselect some wells.`,
      });
      return false;
    }
    Track.interact("Change Selected Offset Wells", {
      "Selected Offset Wells": selectedOffsetWells.map(
        (well) => wellData.byId[well].name,
      ),
    });
    dispatch({
      type: "SET_REPORT_OFFSET_WELLS",
      payload: {
        wells: selectedOffsetWells,
      },
    });
    setOffsetWells(selectedOffsetWells);
    dispatch({
      type: "SET_OFFSET_WELLS_WIDGET",
      payload: {
        wells: selectedOffsetWells,
      },
    });
    setShowSelect(false);
    return true;
  };

  useEffect(() => {
    if (
      (report ? offsetWellsReport : offsetWells).includes(selectedWell || -1)
    ) {
      dispatch({
        type: "SET_REPORT_OFFSET_WELLS",
        payload: {
          wells: offsetWells.filter((e) => e !== selectedWell),
        },
      });
      if (!report) {
        setOffsetWells(offsetWells.filter((e) => e !== selectedWell));
        dispatch({
          type: "SET_OFFSET_WELLS_WIDGET",
          payload: {
            wells: offsetWells.filter((e) => e !== selectedWell),
          },
        });
      }
    }
  }, [
    dispatch,
    lens,
    offsetWells,
    offsetWellsReport,
    report,
    selectedWell,
    setOffsetWells,
  ]);

  return (
    <Tooltip title="Offset Wells">
      <Popover
        content={
          <PDComponent.ComboBoxMultiSelect
            placeholder="Search"
            width={400}
            options={searchableWells as IOption<number>[]}
            values={report ? offsetWellsReport : offsetWells}
            onChange={onOffsetWellsChange}
            noSelectAll
            filterOptions={{
              numFilteredWells: visibleWells.length,
              numAvailableWells: availableWells.length,
              showFiltered,
              handleFilteredClick: () => setShowFiltered(!showFiltered),
            }}
          />
        }
        trigger="click"
        placement="bottom"
        open={showSelect}
        onOpenChange={(e) => setShowSelect(e)}
        destroyTooltipOnHide
      >
        <Button
          trackingName="Add Offset Wells"
          size="large"
          icon={<PDComponent.SvgIcon name="compare" />}
          $engaged={showSelect}
        >
          {(report ? offsetWellsReport : offsetWells).length > 0
            ? `${(report ? offsetWellsReport : offsetWells).length} Wells`
            : null}
        </Button>
      </Popover>
    </Tooltip>
  );
};

export default OffsetWellSelector;
