import { useMutation } from "@tanstack/react-query";
import { AxisBottom, AxisLeft, AxisRight } from "@visx/axis";
import { RectClipPath } from "@visx/clip-path";
import { LinearGradient } from "@visx/gradient";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { AreaClosed, LinePath } from "@visx/shape";
import type {
  ApiNaturalGasStateUserLensesIdPutRequest,
  GeneratorSlotType,
} from "apis/oag";
import { NaturalGasStateUserLensesApi } from "apis/oag";
import { DimensionType } from "apis/oag/models/DimensionType";
import type { NaturalGasStateFactDto } from "apis/oag/models/NaturalGasStateFactDto";
import { Title } from "atoms/Typography";
import {
  StyledCard,
  StyledDropDown,
  StyledOptionRow,
} from "components/Header/RightContent/style";
import {
  AxisLocation,
  StandardTickComponent,
} from "components/Lenses/common/ChartElements";
import ChartLegend from "components/Lenses/common/ChartLegend";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { LegendPreviewerType } from "components/Lenses/common/ChartLegend/interfaces";
import { useChartDateTimeRange } from "components/Lenses/common/useChartDateTimeRange";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import { RotatedRightAxisLabel } from "components/Lenses/ContainerLens/common/RotatedRightAxisLabel";
import {
  StyledChartContainerFlexDiv,
  StyledChevronDownIcon,
  StyledLensContainerFlex,
  StyledMenuItem,
  StyledOption,
} from "components/Lenses/ContainerLens/common/StyledComponents";
import {
  addIndexToSeries,
  CLIP_SERIES_ID as CLIP_ID,
  generatorLabelsBySlotType,
  getAxisFontSize,
  getGeneratorLabelsBySlotType,
  getInternalAxisMargin,
  LATERAL_AXIS_TEXT_WIDTH,
  LATERAL_AXIS_WHITESPACE_WIDTH,
  LATERAL_AXIS_WIDTH,
  LATERAL_LABEL_POSITION,
  X_AXIS_HEIGHT,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { useNaturalGasStateFetcher } from "components/Lenses/ContainerLens/NaturalGasState/fetcher";
import { StyledDropdownContainer } from "components/Lenses/ContainerLens/NaturalGasState/StyledComponents";
import { useNaturalGasStateTooltip } from "components/Lenses/ContainerLens/NaturalGasState/usenaturalGasStateTooltip";
import {
  FALLBACK_PRIMARY_EXTENT,
  PRESSURE_ID,
  TEMPERATURE_ID,
} from "components/Lenses/ContainerLens/NaturalGasState/utils";
import type { NaturalGasStateProps } from "components/Lenses/interfaces";
import { allGenerators, getSVGNormalizedValue } from "components/Lenses/utils";
import { curveMonotoneX } from "d3";
import { extent, max } from "d3-array";
import useDiscontinuousTimeAxis from "hooks/charting/useDiscontinuousTimeAxis";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import { useActiveGenerators } from "hooks/evergreen/useActiveGenerators";
import { useLensNameByTemplateId } from "hooks/lens/useLensNameByTemplateId";
import { useLensSize } from "hooks/lens/useLensSize";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { defaultDateDto } from "utils/common";
import { Col, Row } from "utils/componentLibrary";
import { ALTERNATIVE_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

const naturalGasStateApi = new NaturalGasStateUserLensesApi(apiConfig);

const LENS_TITLE = "Nat Gas Temp & Pressure";

export const NaturalGasState: React.FC<NaturalGasStateProps> = ({
  lens,
  setLensDate,
  onLensUpdated,
  isLocked,
}) => {
  const {
    request: { data },
  } = useNaturalGasStateFetcher({ lens });

  const lensName = useLensNameByTemplateId(lens?.lensTemplateId);

  const series = useMemo(() => data?.facts || [], [data?.facts]);
  const yScalePrimaryExtent = useMemo(() => {
    const [min = 0, max = 0] = extent([
      ...series.map((series) => series?.pressure),
    ]);
    return [min, max];
  }, [series]);

  const yScaleSecondaryExtent = useMemo(() => {
    const [min = 0, max = 0] = extent([
      ...series.map((series) =>
        series?.temperature === 0 ? 0 : series?.temperature,
      ),
    ]);
    return [min, max];
  }, [series]);

  const wellId = useSelectedWell();
  const { data: activeGenerators } = useActiveGenerators(+wellId);

  const yScalePrimaryUOM = useUOM(DimensionType.Pascals);
  const yScaleSecondaryUOM = useUOM(DimensionType.Temperature);
  const [isMenuChevronVisible, setIsMenuChevronVisible] = useState(false);

  const [isPointerInsideChart, setPointerInsideChart] = useState(false);

  useEffect(() => {
    if (data?.lastUpdatedAt && setLensDate) setLensDate(data.lastUpdatedAt);
  }, [data.lastUpdatedAt, setLensDate]);

  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: containerRef,
  } = useResizeDetector();
  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  const plotWidth = getSVGNormalizedValue(chartWidth - 2 * LATERAL_AXIS_WIDTH);
  const plotHeight = getSVGNormalizedValue(chartHeight - X_AXIS_HEIGHT);
  const dataState = useMemo(() => data?.dataState, [data?.dataState]);

  const lensGridSize = useLensSize(lens?.id);
  const [menuVisible, setMenuVisible] = useState(false);
  const handleLensUpdate = useMutation({
    mutationFn: (request: ApiNaturalGasStateUserLensesIdPutRequest) => {
      return naturalGasStateApi.apiNaturalGasStateUserLensesIdPut(request);
    },
    onSettled: (lens) => {
      if (lens && onLensUpdated) {
        onLensUpdated(lens);
      }
    },
  });

  const handleGeneratorUpdate = useCallback(
    async (generators: GeneratorSlotType[]) => {
      setMenuVisible(false);
      await handleLensUpdate.mutateAsync({
        id: lens?.id,
        naturalGasStateUserLensDto: { ...lens, selectedGenerators: generators },
      });
    },
    [handleLensUpdate, lens],
  );

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const [cosmeticLegendItems, setCosmeticLegendItems] = useState<LegendItem[]>([
    {
      id: PRESSURE_ID,
      name: "Pressure",
      color: colors.flickering_sea,
      isEnabled: true,
      previewerType: LegendPreviewerType.BOX,
      onClick: (item, items) => {
        if (
          (items || []).every(
            (other) => !other.isEnabled || other.id === item.id,
          )
        ) {
          return false;
        }
        Track.interact("Evergreen Dashboard - Update legend", {
          lensId: lens?.id,
          lens: lensName,
          selectedItem: item.name,
          isEnabled: !item.isEnabled,
        });
        setCosmeticLegendItems((items) =>
          items.map((item) =>
            item.id === PRESSURE_ID
              ? {
                  ...item,
                  isEnabled: !item.isEnabled,
                }
              : item,
          ),
        );
      },
    },
    {
      id: TEMPERATURE_ID,
      name: "Temperature",
      color: colors.fresco_green,
      isEnabled: true,
      previewerType: LegendPreviewerType.BOX,
      onClick: (item, items) => {
        if (
          (items || []).every(
            (other) => !other.isEnabled || other.id === item.id,
          )
        ) {
          return false;
        }
        Track.interact("Evergreen Dashboard - Update legend", {
          lensId: lens?.id,
          lens: lensName,
          selectedItem: item.name,
          isEnabled: !item.isEnabled,
        });
        setCosmeticLegendItems((items) =>
          items.map((item) =>
            item.id === TEMPERATURE_ID
              ? {
                  ...item,
                  isEnabled: !item.isEnabled,
                }
              : item,
          ),
        );
      },
    },
  ]);

  const xScale = useMemo(() => {
    const chartSize = plotWidth;
    const startX = LATERAL_AXIS_WIDTH;

    const timeSeries = series.map((fact, i) => i);
    const [_min = 0, _max = 0] = [0, max(timeSeries)];

    return scaleLinear<number>({
      domain: [_min, _max],
      range: [startX, chartSize + startX],
    });
  }, [plotWidth, series]);

  const { xScaleDate, chunksCount, getAverageChunkDate } =
    useDiscontinuousTimeAxis({
      plotWidth,
      xScaleDomain: xScale.domain(),
      series: addIndexToSeries(series),
    });

  const internalAxisMargin = useMemo(() => getInternalAxisMargin(false), []);

  const [
    yScalePrimary,
    yScaleSecondary,
    isYScalePrimaryShown,
    isYScaleSecondaryShown,
  ] = useMemo(() => {
    return [
      scaleLinear<number>({
        domain:
          yScalePrimaryExtent[0] !== yScalePrimaryExtent[1]
            ? yScalePrimaryExtent
            : FALLBACK_PRIMARY_EXTENT,
        range: [plotHeight, internalAxisMargin],
        nice: true,
      }),

      scaleLinear<number>({
        domain: yScaleSecondaryExtent,
        range: [plotHeight, internalAxisMargin],
        nice: true,
      }),
      yScalePrimaryExtent[0] !== yScalePrimaryExtent[1],
      yScaleSecondaryExtent[0] !== yScaleSecondaryExtent[1],
    ];
  }, [
    internalAxisMargin,
    plotHeight,
    yScalePrimaryExtent,
    yScaleSecondaryExtent,
  ]);

  const CLIP_SERIES_ID = useMemo(() => `${CLIP_ID}-${lens?.id}-2`, [lens?.id]);

  const { selectionRectangleElement, isDragging } =
    useChartDateTimeRange<NaturalGasStateFactDto>({
      xScale,
      yScale: yScalePrimary,
      plotWidth,
      plotWidthOffset: LATERAL_AXIS_WIDTH,
      plotHeight,
      series: addIndexToSeries(series),
    });

  const axisFontSize = useMemo(() => getAxisFontSize(false), []);

  const {
    yDisplayOnlyScale: yPrimaryDisplayScale,
    verticalAxisTicksCount,
    formatTickDecimals,
  } = useYAxisDisplayScale({
    tickContainerHeight: plotHeight,
    originalScale: yScalePrimary,
    uom: yScalePrimaryUOM,
  });

  const { yDisplayOnlyScale: ySecondaryDisplayScale } = useYAxisDisplayScale({
    originalScale: yScaleSecondary,
    uom: yScaleSecondaryUOM,
  });

  const { tooltipElement, handlePointerMove } = useNaturalGasStateTooltip({
    legendItems: cosmeticLegendItems,
    containerRef,
    yScalePrimary,
    yScaleSecondary,
    xScale,
    series,
    plotWidth: chartWidth,
    plotHeight,
    isPointerInsideChart,
  });

  const MenuItems = useMemo(
    () => (
      <>
        <StyledCard>
          <StyledOptionRow justify="space-between" align="middle">
            <StyledOption
              onClick={() => {
                Track.interact("Evergreen Dashboard - Update generator", {
                  lensId: lens?.id,
                  lens: lensName,
                  selectedGenerators: "Total Gen Bi-fuel",
                });

                handleGeneratorUpdate(allGenerators);
              }}
            >
              Total Gen Bi-fuel
            </StyledOption>
          </StyledOptionRow>
        </StyledCard>

        {activeGenerators.map((gen) => (
          <StyledCard key={gen}>
            <StyledOptionRow justify="space-between" align="middle">
              <StyledOption
                onClick={() => {
                  Track.interact("Evergreen Dashboard - Update generator", {
                    lensId: lens?.id,
                    lens: lensName,
                    selectedGenerator: `${generatorLabelsBySlotType[gen]} Nat Gas`,
                  });

                  handleGeneratorUpdate([gen]);
                }}
              >
                {generatorLabelsBySlotType[gen]} Nat Gas
              </StyledOption>
            </StyledOptionRow>
          </StyledCard>
        ))}
      </>
    ),
    [activeGenerators, handleGeneratorUpdate, lens?.id, lensName],
  );

  return (
    <div
      style={{ height: "100%", width: "100%" }}
      onPointerEnter={() => setIsMenuChevronVisible(true)}
      onPointerLeave={() => {
        if (!menuVisible) {
          setIsMenuChevronVisible(false);
        }
      }}
    >
      <LensLoadingContainer
        key={lens?.id}
        dataState={dataState}
        title={LENS_TITLE}
        isDetailed={false}
        LoadedComponent={
          <StyledLensContainerFlex>
            <Row justify="space-between">
              <Col style={{ padding: 24 }}>
                <Title variant="faded" level={3}>
                  {LENS_TITLE}
                </Title>
              </Col>
            </Row>

            <StyledChartContainerFlexDiv>
              <div
                style={{
                  height: "auto",
                  flexBasis: "50%",
                  minHeight: 150,
                  flexGrow: 1,
                }}
                ref={containerRef}
              >
                <svg
                  onPointerMove={handlePointerMove}
                  onMouseLeave={() => setPointerInsideChart(false)}
                  onMouseEnter={() => setPointerInsideChart(true)}
                  onMouseMove={() => setPointerInsideChart(true)}
                  width={chartWidth}
                  height="100%"
                  style={{ overflow: "hidden", userSelect: "none" }}
                >
                  <GridRows
                    scale={yPrimaryDisplayScale}
                    width={chartWidth}
                    height={chartHeight}
                    stroke={themeColors.primary_chart_accent}
                    strokeWidth={1}
                    strokeOpacity={1}
                    clipPath={`url(#${CLIP_SERIES_ID})`}
                  />
                  <RectClipPath
                    y={0}
                    id={CLIP_SERIES_ID}
                    height={plotHeight}
                    width={plotWidth}
                    x={LATERAL_AXIS_WIDTH}
                  />

                  <Group clipPath={`url(#${CLIP_SERIES_ID})`}>
                    <LinearGradient
                      from={cosmeticLegendItems[0].color}
                      to={cosmeticLegendItems[0].color}
                      toOpacity={0.55}
                      fromOpacity={1}
                      id={`linear_gradient_${cosmeticLegendItems[0].color}_${lens?.id}`}
                    />

                    {/* Primary Y KPI */}
                    {cosmeticLegendItems[0].isEnabled ? (
                      <AreaClosed
                        data={series}
                        x={(d, i) => xScale(i)}
                        y={(d) => yScalePrimary(d.pressure)}
                        yScale={yScalePrimary}
                        curve={curveMonotoneX}
                        stroke={`${colors.neutral}`}
                        strokeOpacity={0.1}
                        strokeWidth={1}
                        fill={`url(#linear_gradient_${cosmeticLegendItems[0].color}_${lens?.id})`}
                      />
                    ) : null}

                    {/* Secondary Y KPI */}
                    {cosmeticLegendItems[1].isEnabled ? (
                      <LinePath
                        curve={curveMonotoneX}
                        data={series}
                        x={(d, i) => xScale(i)}
                        y={(d) => yScaleSecondary(d.temperature)}
                        stroke={cosmeticLegendItems[1].color}
                        strokeWidth={1}
                      />
                    ) : null}
                  </Group>

                  <AxisRight
                    hideZero
                    scale={yPrimaryDisplayScale}
                    hideTicks
                    numTicks={verticalAxisTicksCount}
                    left={(chartWidth ?? 0) - LATERAL_AXIS_WIDTH}
                    tickComponent={(props) => {
                      if (props.y === 0) return null;
                      return (
                        <StandardTickComponent rendererProps={props}>
                          {(val) => formatTickDecimals(val)}
                        </StandardTickComponent>
                      );
                    }}
                    hideAxisLine
                  />

                  <AxisLeft
                    scale={ySecondaryDisplayScale}
                    hideTicks
                    label={
                      isYScaleSecondaryShown ? yScaleSecondaryUOM.abbr : ""
                    }
                    left={LATERAL_AXIS_WIDTH - LATERAL_AXIS_TEXT_WIDTH}
                    numTicks={4}
                    labelOffset={
                      LATERAL_LABEL_POSITION - LATERAL_AXIS_TEXT_WIDTH
                    }
                    labelProps={{
                      fill: themeColors.disabled_typography,
                      fontSize: axisFontSize,
                    }}
                    tickComponent={(props) => {
                      return (
                        <StandardTickComponent
                          rendererProps={props}
                          axisLocation={AxisLocation.LEFT}
                        >
                          {(val) => formatTickDecimals(val)}
                        </StandardTickComponent>
                      );
                    }}
                    hideAxisLine
                  />

                  {/* VISIBLE TIME AXIS */}
                  <AxisBottom
                    hideAxisLine
                    hideTicks
                    scale={xScaleDate}
                    top={plotHeight}
                    numTicks={chunksCount}
                    tickComponent={(props) => {
                      return (
                        <StandardTickComponent
                          rendererProps={props}
                          axisLocation={AxisLocation.BOTTOM}
                        >
                          {(val) =>
                            formatTime(
                              getAverageChunkDate(+val) || defaultDateDto.from,
                              {
                                formatStr: ALTERNATIVE_DATE_FORMAT,
                              },
                            )
                          }
                        </StandardTickComponent>
                      );
                    }}
                  />
                  {!isDragging && tooltipElement}
                  {selectionRectangleElement}
                </svg>
                <RotatedRightAxisLabel
                  plotWidth={plotWidth}
                  chartHeight={chartHeight}
                  displayText={
                    isYScalePrimaryShown ? yScalePrimaryUOM.abbr : null
                  }
                />
              </div>

              <ChartLegend
                legendItems={cosmeticLegendItems}
                lensGridSize={lensGridSize}
              />
            </StyledChartContainerFlexDiv>
          </StyledLensContainerFlex>
        }
      />
      {dataState ? (
        <StyledDropdownContainer right={LATERAL_AXIS_WHITESPACE_WIDTH + 10}>
          {isLocked ? (
            <Title level={4} variant={"faded"}>
              {lens.selectedGenerators?.length === allGenerators.length
                ? "Total Gen Bi-fuel"
                : `${getGeneratorLabelsBySlotType(lens?.selectedGenerators)} Nat Gas`}{" "}
            </Title>
          ) : (
            <StyledDropDown
              overlay={MenuItems}
              placement="bottomRight"
              trigger={["click"]}
              open={menuVisible}
              onOpenChange={() => {
                setMenuVisible(!menuVisible);
              }}
            >
              <StyledMenuItem variant={"faded"}>
                {lens.selectedGenerators?.length === allGenerators.length
                  ? "Total Gen Bi-fuel"
                  : `${getGeneratorLabelsBySlotType(lens?.selectedGenerators)} Nat Gas`}{" "}
                <StyledChevronDownIcon isVisible={isMenuChevronVisible} />
              </StyledMenuItem>
            </StyledDropDown>
          )}
        </StyledDropdownContainer>
      ) : null}
    </div>
  );
};
