export const BitDepth = ({ color }: { color: string }) => {
  return (
    <svg
      height="2"
      width="24"
      style={{
        alignSelf: "center",
      }}
    >
      <g fill="none" stroke={color} strokeWidth="2">
        <path strokeDasharray="1,1" d="M0 0 l215 0" />
      </g>
    </svg>
  );
};
