import { AxisBottom, AxisRight } from "@visx/axis";
import { RectClipPath } from "@visx/clip-path";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Line } from "@visx/shape";
import { Text as VisXText } from "@visx/text";
import { Threshold } from "@visx/threshold";
import { DimensionType } from "apis/oag";
import { SummaryBlock } from "components/Lenses/common/KpiSummaries/SummaryBlock";
import { Scrollbar } from "components/Lenses/common/Scrollbar";
import { useChartScrollbar } from "components/Lenses/common/useChartScrollbar";
import { useBatteryStateFetcher } from "components/Lenses/ContainerLens/BatteryStateKpi/fetcher";
import { useBatteryStateTooltip } from "components/Lenses/ContainerLens/BatteryStateKpi/useBatteryStateTooltip";
import {
  DETAILED_ZOOM_FACTOR,
  MINI_ZOOM_FACTOR,
  negativeThreshold,
  positiveThreshold,
  RIGHT_AXIS_WIDTH,
  SUMMARY_HEIGHT_DETAILED,
  SUMMARY_HEIGHT_MINI,
} from "components/Lenses/ContainerLens/BatteryStateKpi/utils";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import {
  StyledChartContainerDiv,
  StyledLensContainer,
  StyledSummaryCol,
} from "components/Lenses/ContainerLens/common/StyledComponents";
import {
  CLIP_SERIES_ID as CLIP_ID,
  getAxisFontSize,
  getInternalAxisMargin,
  getManualAxisLabelSize,
  LENS_CONTROLS_BAR_HEIGHT,
} from "components/Lenses/ContainerLens/common/utils/utils";
import type { BatteryStateKpiProps } from "components/Lenses/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { InfoContainer } from "components/MiniLens/InfoContainer";
import { PDComponent } from "components/PDComponents";
import { curveMonotoneX } from "d3";
import { max, min, quantile, range } from "d3-array";
import dayjs from "dayjs";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import colors from "utils/colors";
import { Row } from "utils/componentLibrary";
import { DEFAULT_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

export const BatteryState: React.FC<BatteryStateKpiProps> = ({
  lens,
  detailed,
}) => {
  const request = useBatteryStateFetcher({ lensId: lens?.id });

  const CLIP_SERIES_ID = useMemo(() => `${CLIP_ID}-${lens.id}`, [lens.id]);

  const tooltipContainerRef = useRef(null);

  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: containerRef,
  } = useResizeDetector<HTMLDivElement>();

  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  const plotHeight = getSVGNormalizedValue(
    chartHeight -
      (detailed ? SUMMARY_HEIGHT_DETAILED : SUMMARY_HEIGHT_MINI) -
      (detailed ? 98 : LENS_CONTROLS_BAR_HEIGHT) -
      LENS_CONTROLS_BAR_HEIGHT,
  );
  const plotWidth = getSVGNormalizedValue(chartWidth - RIGHT_AXIS_WIDTH);
  const dataState = useMemo(
    () => request?.data?.dataState,
    [request?.data?.dataState],
  );

  const series = useMemo(
    () => request?.data?.facts ?? [],
    [request?.data?.facts],
  );
  const totalIn = request?.data?.summary?.totalEnergyInKWh ?? 0;
  const totalOut = request?.data?.summary?.totalEnergyOutKWh ?? 0;

  const energyFlowMultiplier =
    totalIn >= 1_000_000 || totalOut >= 1_000_000 ? 1e-3 : 1;
  const energyFlowUnit = energyFlowMultiplier === 1 ? "kWh" : "MWh";

  const internalAxisMargin = useMemo(
    () => getInternalAxisMargin(detailed),
    [detailed],
  );

  const yScale = useMemo(() => {
    const absMax = max(series.map((fact) => Math.abs(fact.realPower))) || 0;
    return scaleLinear<number>({
      domain: [absMax, -absMax],
      range: [internalAxisMargin, plotHeight],
    });
  }, [internalAxisMargin, plotHeight, series]);

  const {
    scrollbarScale,
    scrollbarRange,
    scrollbarPadding,
    scrollbarWidth,
    zoomRate,
    setScrollbarRange,
  } = useChartScrollbar({
    plotWidth,
    detailed,
    zoomRate: lens?.squeezesDisplay
      ? 1
      : detailed
        ? DETAILED_ZOOM_FACTOR
        : MINI_ZOOM_FACTOR,
  });

  const [xScale, xScaleDate] = useMemo(() => {
    const chartSize = plotWidth * zoomRate;
    const startX = Math.floor(chartSize * scrollbarRange.startX);

    const timeSeries = series.map((fact) => fact.index);
    const [_min = 0, _max = 0] = [min(timeSeries), max(timeSeries)];

    const chunks = Math.floor(
      (plotWidth * zoomRate) / getManualAxisLabelSize(),
    );

    return [
      scaleLinear<number>({
        domain: [_min, _max],
        range: [-startX, chartSize - startX],
        clamp: true,
      }),

      scaleBand<number>({
        domain: range(chunks - 1),
        range: [-startX, chartSize - startX],
        paddingInner: 0,
        paddingOuter: 0,
      }),
    ];
  }, [plotWidth, scrollbarRange.startX, series, zoomRate]);

  const chunksCount = Math.floor(
    (plotWidth * zoomRate) / getManualAxisLabelSize(),
  );

  const chunkLength = useMemo(() => {
    const [min, max] = xScale.domain();
    const chunkLength = (max - min) / chunksCount;
    return chunkLength;
  }, [chunksCount, xScale]);

  const [isPointerInsideChart, setPointerInsideChart] = useState(false);

  const getAverageChunkDate = useCallback(
    (chunkIndex: number) => {
      const start =
        quantile(
          series.map((v, i) => i),
          chunkIndex / (chunksCount - 1),
        ) || 0;
      const idx = Math.floor((start + start + chunkLength) / 2);
      return formatTime(series[idx]?.at, {});
    },
    [chunkLength, chunksCount, series],
  );

  const { tooltipPoint, tooltipElement, handlePointerMove } =
    useBatteryStateTooltip({
      containerRef,
      xScale,
      series,
      isPointerInsideChart,
      plotWidth,
    });

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const uomValuePower = useUOM(DimensionType.Watts);
  const uomValueEnergy = useUOM(DimensionType.KiloWattHours);

  const axisFontSize = useMemo(() => getAxisFontSize(detailed), [detailed]);

  return (
    <LensLoadingContainer
      key={lens.id}
      dataState={dataState}
      title="Battery State"
      isDetailed={detailed}
      LoadedComponent={
        <StyledLensContainer ref={containerRef}>
          <InfoContainer direction="vertical">
            <Row gutter={12}>
              <StyledSummaryCol>
                <SummaryBlock
                  title="In"
                  main={uomValueEnergy.display(totalIn, {
                    fractionDigits: 0,
                    unit: energyFlowUnit,
                    factor: energyFlowMultiplier,
                  })}
                  secondary=""
                  verticalSpacing={detailed ? -1 : 0}
                  titleLevel={detailed ? 1 : 2}
                />
                <PDComponent.SvgIcon
                  name="arrowDownOutline"
                  style={{
                    display: "inline",
                    fontSize: detailed ? 30 : 24,
                    color: colors.tanzine,
                  }}
                />
              </StyledSummaryCol>
              <StyledSummaryCol>
                <SummaryBlock
                  title="Out"
                  main={uomValueEnergy.display(totalOut, {
                    fractionDigits: 0,
                    unit: energyFlowUnit,
                    factor: energyFlowMultiplier,
                  })}
                  secondary=""
                  verticalSpacing={detailed ? -1 : 0}
                  titleLevel={detailed ? 1 : 2}
                />
                <PDComponent.SvgIcon
                  name="arrowUpOutline"
                  style={{
                    display: "inline",
                    fontSize: detailed ? 30 : 24,
                    color: colors.waterfall,
                  }}
                />
              </StyledSummaryCol>
            </Row>
          </InfoContainer>

          <StyledChartContainerDiv>
            <svg
              onPointerMove={handlePointerMove}
              onMouseLeave={() => setPointerInsideChart(false)}
              onMouseEnter={() => setPointerInsideChart(true)}
              onMouseMove={() => setPointerInsideChart(true)}
              width={chartWidth}
              height={chartHeight}
              style={{ overflow: "hidden", userSelect: "none" }}
              ref={tooltipContainerRef}
            >
              <GridRows
                scale={yScale}
                width={plotWidth}
                height={chartHeight}
                stroke={themeColors.primary_chart_accent}
                strokeWidth={1}
                strokeOpacity={1}
              />

              <RectClipPath
                y={0}
                id={CLIP_SERIES_ID}
                height={chartHeight}
                width={plotWidth}
              />

              <Group clipPath={`url(#${CLIP_SERIES_ID})`}>
                <Threshold
                  id={`threshold-battery-state-${lens.id}`}
                  data={series ?? []}
                  x={(d) => xScale(d.index) || 0}
                  y1={yScale(0) || 0}
                  y0={(d) => yScale(d.realPower) || 0}
                  clipAboveTo={0}
                  clipBelowTo={chartHeight}
                  curve={curveMonotoneX}
                  belowAreaProps={negativeThreshold}
                  aboveAreaProps={positiveThreshold}
                />
                <Line
                  y2={yScale(0)}
                  y1={yScale(0)}
                  x1={plotWidth}
                  stroke={themeColors.disabled_typography}
                  strokeWidth={1}
                />
              </Group>
              <AxisRight
                scale={yScale}
                hideTicks
                left={(chartWidth ?? 0) - RIGHT_AXIS_WIDTH}
                tickComponent={(props) => (
                  <text
                    x={props.x}
                    y={props.y}
                    dy={props.dy}
                    fontSize={axisFontSize}
                    letterSpacing={-0.2}
                    textAnchor="right"
                    fill={themeColors.disabled_typography}
                    pointerEvents="none"
                  >
                    {uomValuePower.display(
                      Number.parseFloat(
                        (props.formattedValue || "")
                          .replaceAll(",", "")
                          .replaceAll("−", "-"),
                      ),
                      {
                        fractionDigits: 0,
                      },
                    )}
                  </text>
                )}
                hideAxisLine
              />

              {/* VISIBLE TIME AXIS */}
              <Group clipPath={`url(#${CLIP_SERIES_ID})`}>
                <AxisBottom
                  hideAxisLine
                  hideTicks
                  scale={xScaleDate}
                  top={plotHeight}
                  numTicks={chunksCount}
                  tickComponent={(props) => (
                    <Group>
                      <VisXText
                        x={props.x}
                        y={props.y}
                        dx={-40}
                        fontSize={axisFontSize}
                        fill={themeColors.disabled_typography}
                        pointerEvents="none"
                      >
                        {dayjs(
                          getAverageChunkDate(
                            +(props.formattedValue || "").replaceAll(",", ""),
                          ),
                        ).format(DEFAULT_DATE_FORMAT)}
                      </VisXText>
                    </Group>
                  )}
                />

                {!lens?.squeezesDisplay && zoomRate > 1 && (
                  <Group
                    top={plotHeight + (detailed ? 62 : 26)}
                    left={scrollbarPadding}
                  >
                    <Scrollbar
                      width={scrollbarWidth}
                      height={4}
                      scale={scrollbarScale}
                      wheelCaptureContainer={containerRef}
                      onScroll={setScrollbarRange}
                      valueSpan={1 / zoomRate}
                    />
                  </Group>
                )}
              </Group>

              {tooltipElement}

              {tooltipPoint && isPointerInsideChart ? (
                <Group>
                  <Line
                    from={{
                      x: xScale(tooltipPoint.index),
                      y: plotHeight,
                    }}
                    to={{
                      x: xScale(tooltipPoint.index),
                      y: 0,
                    }}
                    strokeOpacity={0.3}
                    stroke={themeColors.primary_typography}
                    strokeWidth={2}
                  />

                  <circle
                    cx={xScale(tooltipPoint.index)}
                    cy={yScale(tooltipPoint.realPower)}
                    r={3}
                    fill={themeColors.black_white}
                  />
                </Group>
              ) : null}
            </svg>
          </StyledChartContainerDiv>
        </StyledLensContainer>
      }
    />
  );
};

BatteryState.displayName = "BatteryStateKpi";
export default BatteryState;
