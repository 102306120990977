/* eslint-disable react/no-multi-comp */
import { PDComponent } from "components/PDComponents";
import styled, { css } from "styled-components";
import { Col } from "utils/componentLibrary";

const noScrollCommonStyle = css`
  overflow: hidden scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NoScrollCol = styled(Col)<{
  hz: number;
  $isReport: boolean;
  $isReportTvD: boolean;
}>`
  /* // TODO fix these legacy random numbers, 64px is probably the height of the header */
  height: calc(
    100vh - ${(props) => props.hz}px -
      ${(props) => (props.$isReport ? "0px" : "64px - 1px")}
  );
  ${noScrollCommonStyle}
`;

export const NoScrollColWithHints = styled(PDComponent.ScrollHintContainer)<{
  hz: number;
}>`
  height: calc(100vh - ${(props) => props.hz}px - 64px - 1px);
  width: 100%;
  ${noScrollCommonStyle}
`;
