import styled from "styled-components";
import colors from "utils/colors";
type SpinnerProps = {
  color?: string;
  borderSize?: number;
  borderColor?: string;
  size?: number;
  top?: number;
  left?: number;
};

const StyledSpinner = styled.span<SpinnerProps>`
  display: block;
  border-radius: 50%;
  ${({ borderSize, color, borderColor, size, top, left }) => `
    border: ${borderSize}px solid ${color};
    border-top: ${borderSize}px solid ${borderColor};
    border-left: ${borderSize}px solid ${borderColor};
    border-right: ${borderSize}px solid ${borderColor};
    width: ${size}px;
    height: ${size}px;
    margin-top: ${top}px;
    margin-left: ${left}px;
  `}
  animation: spin 2s linear infinite;
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Spinner = ({
  borderSize = 3,
  color = "transparent",
  borderColor = colors.off_state,
  size = 18,
  top = 0,
  left = 0,
}: SpinnerProps) => (
  <StyledSpinner
    borderSize={borderSize}
    color={color}
    borderColor={borderColor}
    size={size}
    top={top}
    left={left}
  />
);
