import type { ScorecardKpiCommentExceptionInfoDto } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { NotificationRow } from "pages/PendingTasks/components/TasksRow";

export const NotificationList = ({
  tasks,
}: {
  tasks: ScorecardKpiCommentExceptionInfoDto[];
}) => {
  return (
    <PDComponent.VirtualizedList items={tasks} itemSizePx={72}>
      {(task, idx) => <NotificationRow task={task} key={`${task.id}-${idx}`} />}
    </PDComponent.VirtualizedList>
  );
};
