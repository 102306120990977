import { Text } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import type { TimelineStates } from "reducers/reportReducer";
import { useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { Button, Col, Row } from "utils/componentLibrary";
import { highPrivilegeRoles } from "utils/helper";

export const EmptyTimeline = ({
  selectedTimeline,
  link,
}: {
  selectedTimeline: TimelineStates;
  link: string;
}) => {
  const userInfo = useAppSelector((state) => state.global.userInfo);
  const isInDemoMode = userInfo?.isInDemoMode ?? false;

  const text = useMemo(() => {
    if (selectedTimeline === "Plan") {
      if (!userInfo) return "";
      if (!isInDemoMode && highPrivilegeRoles.includes(userInfo.role))
        return "You do not have a drilling plan for this well yet. Click Create Plan to add a plan.";
      return "There’s no drilling plan available for this well as of yet.";
    }
    return "This well hasn’t started drilling yet. Once drilling begins, you’ll see an actual timeline added.";
  }, [selectedTimeline, userInfo, isInDemoMode]);

  return (
    <Row>
      <Col
        style={{
          margin: 18,
          padding: "20px 24px",
          borderRadius: "4px",
          border: `solid 1px ${colors.widget_line}`,
        }}
      >
        <Col>
          <Text primary="description" variant="faded" fontSize={16}>
            {text}
          </Text>
        </Col>
        {selectedTimeline === "Plan" &&
        userInfo?.role &&
        !isInDemoMode &&
        highPrivilegeRoles.includes(userInfo?.role) ? (
          <Link to={link}>
            <Button
              style={{
                marginTop: "16px",
                padding: 8,
              }}
            >
              <PDComponent.SvgIcon name="textLinkAnalysis" /> Create a plan
            </Button>
          </Link>
        ) : null}
      </Col>
    </Row>
  );
};
