/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IntelGroupingType } from './IntelGroupingType';
import {
    IntelGroupingTypeFromJSON,
    IntelGroupingTypeFromJSONTyped,
    IntelGroupingTypeToJSON,
} from './IntelGroupingType';

/**
 * 
 * @export
 * @interface IntelScorecardUserLensDto
 */
export interface IntelScorecardUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof IntelScorecardUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof IntelScorecardUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof IntelScorecardUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof IntelScorecardUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntelScorecardUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntelScorecardUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {IntelGroupingType}
     * @memberof IntelScorecardUserLensDto
     */
    grouping: IntelGroupingType;
    /**
     * 
     * @type {boolean}
     * @memberof IntelScorecardUserLensDto
     */
    showBasin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntelScorecardUserLensDto
     */
    showOperator: boolean;
}



/**
 * Check if a given object implements the IntelScorecardUserLensDto interface.
 */
export function instanceOfIntelScorecardUserLensDto(value: object): value is IntelScorecardUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('grouping' in value) || value['grouping'] === undefined) return false;
    if (!('showBasin' in value) || value['showBasin'] === undefined) return false;
    if (!('showOperator' in value) || value['showOperator'] === undefined) return false;
    return true;
}

export function IntelScorecardUserLensDtoFromJSON(json: any): IntelScorecardUserLensDto {
    return IntelScorecardUserLensDtoFromJSONTyped(json, false);
}

export function IntelScorecardUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelScorecardUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'grouping': IntelGroupingTypeFromJSON(json['Grouping']),
        'showBasin': json['ShowBasin'],
        'showOperator': json['ShowOperator'],
    };
}

export function IntelScorecardUserLensDtoToJSON(value?: IntelScorecardUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'Grouping': IntelGroupingTypeToJSON(value['grouping']),
        'ShowBasin': value['showBasin'],
        'ShowOperator': value['showOperator'],
    };
}

