import { ErrorBoundary } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { Title } from "atoms/Typography";
import CoreLayout, { SectionLayout } from "components/Layout";
import MapView from "components/MapView";
import { WellsMapViewProvider } from "components/MapView/useWellsMapView";
import { WebGLSupportBanner } from "components/WebGLSupportBanner/WebGLSupportBanner";
import { useAllWellsDataFeed } from "hooks/dataFeed/useAllWellsDataFeed";
import AllWellsHeader from "pages/AllWells/components/AllWellsHeader";
import KPIsContainer from "pages/AllWells/components/KPIsContainer";
import WellsContainer from "pages/AllWells/components/WellsContainer";
import {
  PaddedContainer,
  StyledContainer,
  StyledHeaderContainer,
  StyledMapviewWrapper,
  StyledScrollMask,
  StyledSidebarWrapper,
  StyledSpace,
} from "pages/AllWells/StyledComponents";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useAppSelector } from "reducers/store";
import { usePageView } from "services/Mixpanel";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

export const ALL_WELLS_DEFAULT_HEADER_HEIGHT = 64;

const Main: FC = () => {
  const queryClient = useQueryClient();

  usePageView("All Wells");
  useAllWellsDataFeed();

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({
        queryKey: [{ type: PDQueryType.WELL_TVD_POINTS }],
        exact: false,
      });
    };
  }, [queryClient]);

  const [headerHeight, setHeaderHeight] = useState(
    ALL_WELLS_DEFAULT_HEADER_HEIGHT,
  );
  const wellFilters = useAppSelector((state) => state.allWells.filters);

  const { atomThemeVariant } = useCustomTheme();

  return (
    <WellsMapViewProvider>
      <StyledContainer>
        <AllWellsHeader
          setHeaderHeight={setHeaderHeight}
          wellFilters={wellFilters}
        />
      </StyledContainer>

      <StyledMapviewWrapper $headerHeight={headerHeight}>
        <ErrorBoundary fallback={<WebGLSupportBanner />}>
          <MapView />
        </ErrorBoundary>
      </StyledMapviewWrapper>
      <PaddedContainer>
        <CoreLayout
          sidebar={
            <SectionLayout
              CustomChildrenWrapper={StyledSidebarWrapper}
              header={
                <StyledHeaderContainer>
                  <Title level={3} variant={atomThemeVariant} weight={600}>
                    Wells KPI&apos;s
                  </Title>
                  <StyledScrollMask />
                </StyledHeaderContainer>
              }
            >
              <StyledSpace align="start">
                <KPIsContainer />
              </StyledSpace>
            </SectionLayout>
          }
        >
          <WellsContainer />
        </CoreLayout>
      </PaddedContainer>
    </WellsMapViewProvider>
  );
};

export default Main;
