import type { HTMLAttributes } from "react";
import React from "react";
import styled from "styled-components";

export const Header = styled.header`
  flex: 0 0 auto;
  height: 64px;
  padding: 0 50px;
  color: #fff;
  line-height: 64px;
  background: #131b1d;
`;

export const Footer = styled.footer`
  flex: 0 0 auto;
  padding: 24px 50px;
  color: #000000d9;
  font-size: 16px;
  background: #f1f1f3;
`;

export const Content = styled.main`
  flex: auto;
  min-height: 0;
`;

export const Sider = styled.aside`
  position: relative;
  min-width: 0;
  background: ${(props) => props.theme.themeStyle.colors.quaterniary_chart_bg};
`;

const PdLayout = styled.section<{ $hasSideBar?: boolean }>`
  display: flex;
  flex: auto;
  flex-direction: ${({ $hasSideBar }) => ($hasSideBar ? "row" : "column")};
  min-height: 0;
  background: ${(props) => props.theme.themeStyle.colors.primary_bg};
`;

type LayoutProps = HTMLAttributes<HTMLDivElement> & { hasSideBar?: boolean };

export const Layout: React.FC<LayoutProps> & {
  Header?: typeof Header;
  Footer?: typeof Footer;
  Content?: typeof Content;
  Sider?: typeof Sider;
} = React.forwardRef<HTMLDivElement, LayoutProps>(
  ({ children, hasSideBar, ...rest }, ref) => (
    <PdLayout $hasSideBar={hasSideBar} ref={ref} {...rest}>
      {children}
    </PdLayout>
  ),
);

Layout.displayName = "Layout";
