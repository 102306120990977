import type { IconName } from "icons/autogeneratedTypes";
import colors from "utils/colors";

export const BOTTOM_AXIS_HEIGHT = 30;
export const BOTTOM_AXIS_WITH_LABEL_HEIGHT = 100;
export const RECT_WIDTH = 55;
export const LEFT_AXIS_WIDTH = 85;
export const SCROLLBAR_PADDING = 48;
export const SCROLLBAR_HEIGHT = 6;
export const ZOOM_RATE_BREAKPOINT_SMALL = 6;
export const ZOOM_RATE_BREAKPOINT_MID = 10;
export const ZOOM_RATE_BREAKPOINT_LARGE = 14;

export const TOOLTIP_WIDTH = 250;

export const getColorByDiff = (diff?: number) => {
  if (!diff) return colors.white;
  return diff > 0 ? colors.aloha : colors.coral_expression;
};
export const getIconByDiff: (diff?: number) => IconName | null = (diff) => {
  if (!diff) return null;
  return diff < 0 ? "up_arrow" : "down_arrow";
};
