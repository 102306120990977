import { AxisBottom } from "@visx/axis";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { Text as VisXText } from "@visx/text";
import type {
  ParameterByDepthUserLensDto,
  StickSlipByDepthUserLensDto,
} from "apis/oag";
import { DimensionType } from "apis/oag";
import type { ScrollbarRange } from "components/Lenses/common/Scrollbar";
import { Scrollbar } from "components/Lenses/common/Scrollbar";
import { GRID_WIDTH_QUARTER } from "components/Lenses/constants";
import {
  returnZeroIfInvalid,
  returnZeroIfNegative,
} from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/RoadmapColoredSegment";
import { BottomRowContainer } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/style";
import {
  getParameterAxisWidth,
  getParameterNameWidth,
  getParameterNameWidthStickSlip,
  getParameterStatsWidth,
  getParameterStatsWidthStickSlip,
} from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/utils";
import { getParameterAxisWidthStickSlip } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/utils";
import { DetailedViewTrackAdder } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/DetailedViewTrackAdder/DetailedViewTrackAdder";
import { extent } from "d3-array";
import type { NumberValue, ScaleLinear } from "d3-scale";
import { useLensSize } from "hooks/lens/useLensSize";
import { useCallback, useMemo } from "react";
import { useUOM } from "utils/format";
import { useCustomTheme } from "utils/useTheme";

type ObjectWithHoleDepth = { holeDepth: number };

export const BottomRowWithAxis = ({
  availableTrackIds,
  detailed,
  openTrackAddModal,
  chartWidth,
  containerHeight,
  flatTracks,
  depthScale,
  lens,
  setScrollState,
  isStickSlipByDepth,
}: {
  availableTrackIds: number[];
  detailed: boolean;
  openTrackAddModal: () => void;
  chartWidth: number;
  containerHeight: number;
  flatTracks: Array<ObjectWithHoleDepth>;
  depthScale: ScaleLinear<number, number, never>;
  lens: ParameterByDepthUserLensDto | StickSlipByDepthUserLensDto;
  setScrollState: React.Dispatch<React.SetStateAction<ScrollbarRange>>;
  isStickSlipByDepth: boolean;
}) => {
  const depthUOM = useUOM(DimensionType.Metres);
  const scrollbarWidth = useMemo(() => chartWidth - 28 * 2, [chartWidth]);
  const [containerGridWidth] = useLensSize(lens.id);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const scrollbarScale = useMemo(() => {
    const [min = 0, max = 0] = extent(flatTracks, (i) => i.holeDepth);
    return scaleLinear<number>({
      range: [0, scrollbarWidth],
      domain: [min, max],
    });
  }, [flatTracks, scrollbarWidth]);

  const tickLabelProps = useCallback(
    () => ({
      fontSize: "14px",
      fill: themeColors.primary_typography,
      letterSpacing: "-0.2px",
    }),
    [themeColors.primary_typography],
  );

  const tickFormat = useCallback(
    (value: NumberValue) =>
      depthUOM.display(+value, {
        unit: "",
        fractionDigits: +value < 20 ? 2 : 0,
      }),
    [depthUOM],
  );

  const shouldShowScrollbar = useMemo(
    () => (!detailed && containerGridWidth > GRID_WIDTH_QUARTER) || detailed,
    [containerGridWidth, detailed],
  );

  const columnWidth = useMemo(() => {
    if (isStickSlipByDepth) {
      return (
        getParameterNameWidthStickSlip(detailed) +
        getParameterStatsWidthStickSlip(detailed) +
        getParameterAxisWidthStickSlip(detailed)
      );
    } else {
      return (
        getParameterNameWidth(detailed) +
        getParameterStatsWidth(detailed) +
        getParameterAxisWidth(detailed)
      );
    }
  }, [detailed, isStickSlipByDepth]);

  return (
    <BottomRowContainer $columnWidth={columnWidth}>
      <div>
        {availableTrackIds.length > 0 && detailed ? (
          <DetailedViewTrackAdder openTrackAddModal={openTrackAddModal} />
        ) : null}
      </div>

      <div>
        <svg
          width={returnZeroIfNegative(
            returnZeroIfInvalid(chartWidth ? Math.max(0, chartWidth) : 0),
          )}
          height={returnZeroIfNegative(
            returnZeroIfInvalid(
              containerHeight ? Math.max(0, containerHeight) : 0,
            ),
          )}
        >
          {depthScale.domain().some((x) => x !== 0) ? (
            <AxisBottom
              top={detailed ? 17 : 0}
              scale={depthScale}
              numTicks={Math.floor(
                chartWidth ? Math.max(0, chartWidth) / 120 : 0,
              )}
              tickLabelProps={tickLabelProps}
              tickFormat={tickFormat}
              stroke={"0px"}
              tickStroke={"0px"}
              tickComponent={(props) => {
                if (props.x > scrollbarWidth) {
                  return null;
                }

                return (
                  <Group>
                    <VisXText
                      textAnchor="middle"
                      x={props.x}
                      y={props.y}
                      fontSize={detailed ? 16 : 14}
                      fill={themeColors.disabled_typography}
                      pointerEvents="none"
                    >
                      {props.formattedValue}
                    </VisXText>
                  </Group>
                );
              }}
            />
          ) : null}
          {shouldShowScrollbar ? (
            <Group top={detailed ? 64 : 26} left={28}>
              <Scrollbar
                scale={scrollbarScale}
                width={scrollbarWidth < 0 ? 0 : scrollbarWidth}
                height={detailed ? 8 : 6}
                disabled={lens.squeezesDisplay}
                onScroll={setScrollState}
              />
            </Group>
          ) : null}
        </svg>
      </div>
    </BottomRowContainer>
  );
};
