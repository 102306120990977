import { Group } from "@visx/group";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { Bar, BarRounded, BarStack } from "@visx/shape";
import type { KpiTypeUserLensDto, StandKpiSliceDto } from "apis/oag";
import {
  DimensionType,
  StackingType,
  StandKpiType,
  VisualAidType,
} from "apis/oag";
import {
  AverageLabel,
  AverageLine,
} from "components/Lenses/common/AverageLine";
import { Chart } from "components/Lenses/common/Chart";
import { Indicators } from "components/Lenses/common/Indicators";
import { useStandKpiInspectionView } from "components/Lenses/common/InspectionView/StandKpis/useStandKpiInspectionView";
import { Label } from "components/Lenses/common/Label";
import { MedianLabel, MedianLine } from "components/Lenses/common/MedianLine";
import type { ScrollbarRange } from "components/Lenses/common/Scrollbar";
import { Scrollbar } from "components/Lenses/common/Scrollbar";
import TargetLine from "components/Lenses/common/TargetLine";
import {
  TooltipGroup,
  TooltipHighlightValue,
} from "components/Lenses/common/Tooltip";
import { useAggregatedIndicators } from "components/Lenses/common/useAggregatedIndicators";
import {
  TooltipVisualAidInfo,
  useChartTooltip,
} from "components/Lenses/common/useChartTooltip";
import { useOutlierThreshold } from "components/Lenses/common/useOutlierThreshold";
import {
  DETAILED_ZOOM_SCALER,
  MINI_ZOOM_SCALER,
} from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleStandViewChart";
import type {
  StackedKpiChartSimpleProps,
  TransformedStandItem,
} from "components/Lenses/ContainerLens/StackedKpi/interfaces";
import { getMinMax, getSVGNormalizedValue } from "components/Lenses/utils";
import { RIGHT_AXIS_PADDING } from "components/TvDChart/constants";
import { useTargetSegments } from "hooks/charting/useTargetSegments";
import { useKpiGroups } from "hooks/drillingInvariants/useKPIGroups";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { uniqBy } from "lodash";
import { roundNumberToDecimal } from "pages/Lens/LensSummaryView";
import { shade, tint, transparentize } from "polished";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { Track } from "services/Mixpanel";
import { useUOM, useUOMbyLens, UtilDimensions } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

type TransformedData = TransformedStandItem[];

export const StackedStandViewChart = ({
  detailed,
  data,
  focalWellColor = "",
  enableTooltip,
  dimension,
  disableAxis,
  indicators,
  lens,
  templateType,
}: StackedKpiChartSimpleProps) => {
  const { data: kpiTypes } = useKpiTypes();
  const valueUOM = useUOMbyLens(dimension, lens);
  const depthUOM = useUOM(DimensionType.Metres);
  const { inspectionViewElement, openInspectionView } =
    useStandKpiInspectionView({
      lens: lens,
    });
  const percentageUOM = useUOM(UtilDimensions.Percentage);
  // TODO hack to not have to change mock elements
  const outliersSettingsEnabled =
    lens === undefined ? true : lens?.showsOutliers;
  const isManual = !!lens?.isManualYaxis;
  const outliersEnabled = outliersSettingsEnabled || isManual;
  const { data: kpiGroups } = useKpiGroups();
  const parentkpiType =
    kpiGroups?.byId[lens?.kpiGroupId ?? 0]?.parentKpiType ??
    StandKpiType.NUMBER_0; // "0" is the "Unknown" type
  const {
    width: chartWidth,
    height: chartHeight,
    ref: containerRef,
  } = useResizeDetector<HTMLDivElement>();

  const plotHeight = getSVGNormalizedValue(
    (chartHeight || 0) - (detailed ? 136 : 36) + (disableAxis ? 35 : 0),
  );
  const plotWidth = getSVGNormalizedValue(
    (chartWidth ?? 76) - (detailed ? 76 : 44),
  );
  const getData = useCallback(
    (d: StandKpiSliceDto) => {
      if (!d) return 0;
      if (lens.stackingType === StackingType.Distribution)
        return d.distribution;
      return d.average;
    },
    [lens.stackingType],
  );

  const scrollbarPadding = detailed ? 28 : 4;
  const scrollbarWidth = getSVGNormalizedValue(
    plotWidth - scrollbarPadding * 2,
  );

  const kpiKeys = data.summaryByKpi
    ?.slice()
    .sort((a, b) => a.position - b.position)
    // TODO is this correct?
    .map((item) => (item as unknown as KpiTypeUserLensDto).kpiTypeId);

  const totalValues = useMemo(
    () =>
      data?.detailsByStandDepth?.map((item) =>
        uniqBy(item.slices, (i) => i.kpiTypeId).reduce(
          (acc, item) => acc + getData(item),
          0,
        ),
      ) || [],
    [data.detailsByStandDepth, getData],
  );

  const transformedData = useMemo<TransformedData>(
    () =>
      (data.detailsByStandDepth || []).map((detail, index) => {
        const sliceMap = (detail.slices || []).reduce(
          (acc, slice) => {
            acc[slice.kpiTypeId] = getData(slice);
            return acc;
          },
          {} as Record<number, number>,
        );
        const averageMap = (detail.slices || []).reduce(
          (acc, slice) => {
            acc[slice.kpiTypeId] = slice.average;
            return acc;
          },
          {} as Record<number, number>,
        );

        const totalValue = Object.keys(sliceMap)
          .map((e) => sliceMap[+e])
          .reduce((acc, item) => acc + item, 0);

        return {
          index,
          id: detail.standId,
          standNumber: detail.standNumber,
          isOutlier: detail.isOutlier,
          startAt: detail.startAt,
          standId: detail.standId,
          endAt: detail.endAt,
          isDayShift: detail.isDayShift,
          targetValue: detail.targetValue || 0,
          startDepth: detail.startDepth,
          endDepth: detail.endDepth,
          totalValue,
          values: sliceMap,
          average: averageMap,
        };
      }),
    [data.detailsByStandDepth, getData],
  );

  // A quarter of the chart by default
  const zoomRate = useMemo(() => {
    return lens?.squeezesDisplay
      ? 1
      : Math.ceil(
          transformedData.length /
            (detailed ? DETAILED_ZOOM_SCALER : MINI_ZOOM_SCALER),
        );
  }, [detailed, lens?.squeezesDisplay, transformedData.length]);

  // initialize with 0.75 to 1 zoom (last quarter of the chart)
  const [scrollbarRange, setScrollbarRange] = useState<ScrollbarRange>({
    startX: 1 - 1 / zoomRate,
    endX: 1,
  });
  // update if squeeze changed
  useEffect(() => {
    setScrollbarRange({ startX: 1 - 1 / zoomRate, endX: 1 });
  }, [zoomRate]);

  const categoryScale = useMemo(() => {
    const chartSize = plotWidth * zoomRate + (disableAxis ? 35 : 0);

    const startX = chartSize * scrollbarRange.startX;

    return scaleBand<number>({
      domain: transformedData.map((i) => i.standId),
      // Offsetting the pixel range based on zoom, provides scrolling and zoom at the same time
      range: [-startX, chartSize - startX],
      paddingInner: 0.2,
      paddingOuter: 0.5,
    });
  }, [
    disableAxis,
    plotWidth,
    scrollbarRange.startX,
    transformedData,
    zoomRate,
  ]);

  const computedTargetSegments = useTargetSegments(
    transformedData,
    categoryScale,
  );

  const { outlierThreshold, gradientDefinition, gradientFill } =
    useOutlierThreshold({
      values: totalValues,
      selectedVisualAids: lens?.selectedVisualAids,
      targetSegments: computedTargetSegments,
      enabled: outliersEnabled,
    });

  const aggregatedIndicators = useAggregatedIndicators(data.indicators);

  const {
    isDark,
    themeStyle: { outliers: outliersTheme },
  } = useCustomTheme();

  const valueScale = useMemo(() => {
    return scaleLinear<number>({
      domain: getMinMax(
        (data?.detailsByStandDepth || [])
          .filter((value) => !value?.isOutlier)
          .map((item) =>
            uniqBy(item.slices, (i) => i.kpiTypeId).reduce(
              (acc, item) => acc + getData(item),
              0,
            ),
          ),
        computedTargetSegments,
        outlierThreshold,
        data.summary?.median,
        lens?.selectedVisualAids,
        lens?.outlierFlaggingType,
        lens?.isManualYaxis,
        lens?.yaxisEnd,
        lens?.yaxisStart,
      ),
      range: [plotHeight, 0],
      clamp: true,
    });
  }, [
    data.detailsByStandDepth,
    data.summary?.median,
    computedTargetSegments,
    outlierThreshold,
    lens?.selectedVisualAids,
    lens?.outlierFlaggingType,
    lens?.isManualYaxis,
    lens?.yaxisEnd,
    lens?.yaxisStart,
    plotHeight,
    getData,
  ]);

  const dateToDepthScale = useMemo(
    () =>
      scaleOrdinal({
        domain: transformedData.map((i) => i.standId),
        range: transformedData.map((i) => i.standNumber),
      }),
    [transformedData],
  );

  const gapWidth = categoryScale.paddingInner() * categoryScale.step();
  const columnWidth = categoryScale.bandwidth();

  // A separate scale for scrollbar
  // Maps scrollbar pixel position to a [0, 1] range
  const scrollbarScale = useMemo(
    () => scaleLinear<number>({ domain: [0, 1], range: [0, scrollbarWidth] }),
    [scrollbarWidth],
  );

  const { showTooltip, hideTooltip, tooltipElement } =
    useChartTooltip<TransformedStandItem>({
      containerRef,
      renderContent: ({ tooltipData }) => (
        <>
          <TooltipHighlightValue>
            {lens.stackingType === StackingType.Values
              ? valueUOM.display(tooltipData?.totalValue)
              : (kpiKeys || [])
                  .slice()
                  .reverse()
                  .map(
                    (key) =>
                      kpiTypes?.byId[key] && (
                        <div key={`${key}-label`}>
                          {kpiTypes?.byId[key].name}:{" "}
                          {roundNumberToDecimal(
                            (tooltipData?.values[key] ?? 0) * 100,
                            2,
                          ) + "%"}
                        </div>
                      ),
                  )}
          </TooltipHighlightValue>
          <TooltipGroup>
            {(kpiKeys || [])
              .slice()
              .reverse()
              .map(
                (key) =>
                  kpiTypes?.byId[key] && (
                    <div key={`${key}-label`}>
                      {kpiTypes?.byId[key].name}:{" "}
                      {valueUOM.display(tooltipData?.average[key] ?? 0)}
                    </div>
                  ),
              )}
          </TooltipGroup>

          <TooltipVisualAidInfo
            selectedVisualAids={lens?.selectedVisualAids}
            display={valueUOM.display}
            targetValue={tooltipData?.targetValue}
            averageValue={data?.summary?.average}
            median={data?.summary?.median}
          />

          <span>
            {depthUOM.display(tooltipData?.startDepth)} to{" "}
            {depthUOM.display(tooltipData?.endDepth)}
          </span>
          {tooltipData?.startAt && tooltipData?.endAt ? (
            <span>
              {formatTime(tooltipData?.startAt, { formatStr: "MM/D HH:mm" })} to{" "}
              {formatTime(tooltipData?.endAt, { formatStr: "MM/D HH:mm" })}
            </span>
          ) : null}
          <span>{tooltipData?.isDayShift ? "Day" : "Night"}</span>

          {tooltipData?.isOutlier ? (
            <TooltipHighlightValue fontWeight="normal">
              OUTLIER
            </TooltipHighlightValue>
          ) : null}
        </>
      ),
    });

  const handleMouseOver = useCallback(
    (barData: TransformedStandItem) => {
      if (!enableTooltip) return;
      showTooltip({
        tooltipLeft: (categoryScale(barData.standId) || 0) + columnWidth / 2,
        tooltipTop: valueScale(
          Math.min(barData.totalValue, valueScale.domain()[1]),
        ),
        tooltipData: barData,
      });
    },
    [categoryScale, columnWidth, enableTooltip, showTooltip, valueScale],
  );

  // Starting initialization only with div to calculate plot width & height
  if (Number.isNaN(plotWidth) || Number.isNaN(plotHeight)) {
    return (
      <div
        ref={containerRef}
        style={{
          padding: 0,
          width: "100%",
          height: "100%",
          position: "relative",
          overflow: "visible",
        }}
      />
    );
  }

  return (
    <div
      ref={containerRef}
      style={{
        padding: 0,
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "visible",
      }}
    >
      <svg
        width={chartWidth}
        height={chartHeight}
        style={{ overflow: "visible", userSelect: "none" }}
      >
        {gradientDefinition}

        <Chart
          disableAxis={disableAxis}
          isManual={lens?.isManualYaxis}
          plotWidth={plotWidth + (disableAxis ? 45 : 0)}
          detailed={detailed}
          chartWidth={chartWidth || 0}
          chartHeight={chartHeight || 0}
          plotHeight={plotHeight}
          valueScale={valueScale}
          categoryScale={categoryScale}
          rightTickFormat={
            lens.stackingType === StackingType.Distribution
              ? (value) => (value as string) + "%"
              : undefined
          }
          tickFormat={(value) =>
            depthUOM.display(dateToDepthScale(value as number), {
              unit: "",
              fractionDigits: 0,
            })
          }
          valueUOM={
            lens.stackingType === StackingType.Distribution
              ? percentageUOM
              : valueUOM
          }
        >
          <BarStack<TransformedStandItem, number>
            x={(d) => d.standId}
            keys={kpiKeys}
            value={(d, key) => d.values[key] ?? 0}
            data={transformedData}
            xScale={categoryScale}
            yScale={valueScale}
            color={() => focalWellColor}
            onMouseOut={hideTooltip}
          >
            {(barStacks) => [
              barStacks.map((barStack) => (
                <Group key={`bar-stack-${barStack.index}`}>
                  {barStack.bars.map((bar) => {
                    if (bar.bar.some((e) => Number.isNaN(e))) return null;
                    const bars = Object.values(
                      bar?.bar?.data?.average ?? [],
                    ).filter((x) => x);
                    const isLast = barStack.index === bars.length - 1;

                    const maxValue = valueScale.domain()[1];
                    const threshold =
                      isManual || outlierThreshold === null
                        ? maxValue
                        : outlierThreshold;
                    const thresholdValue = valueScale(maxValue);

                    if (
                      !disableAxis &&
                      (bar.x < -columnWidth * 5 ||
                        bar.x > plotWidth + columnWidth * 5)
                    ) {
                      return null;
                    }

                    const showGradient =
                      isLast && bar.bar.data.totalValue > threshold;

                    let barY = bar.y;
                    let barHeight = bar.height;

                    if (!disableAxis && showGradient) {
                      barHeight -= thresholdValue - barY;
                      barY = thresholdValue;
                    }

                    return (
                      <Group key={`bar-stack-${barStack.index}-${bar.index}`}>
                        <BarRounded
                          x={bar.x}
                          y={barY}
                          top={
                            lens.stackingType !== StackingType.Distribution &&
                            isLast
                          }
                          radius={2}
                          height={barHeight}
                          width={bar.width}
                          opacity={1}
                          filter={(() => {
                            if (
                              templateType === "Rotate vs Slide" &&
                              !bar.bar.data.isDayShift
                            )
                              return `grayscale(${barStack.index * 50}%)`;
                            return "";
                          })()}
                          fill={(() => {
                            if (templateType === "Rotate vs Slide")
                              return bar.bar.data.isDayShift
                                ? tint(0.5 * barStack.index, bar.color)
                                : shade(
                                    0.5 *
                                      (barStacks.length - barStack.index - 1),
                                    bar.color,
                                  );

                            if (bar.bar.data?.isOutlier)
                              return isDark
                                ? `${transparentize(
                                    0.9,
                                    shade(
                                      0.3 *
                                        (barStacks.length - barStack.index - 1),
                                      outliersTheme.bars,
                                    ),
                                  )}`
                                : `${tint(0.3 * barStack.index, outliersTheme.bars_stacked_transparent)}`;
                            return bar.bar.data.isDayShift
                              ? tint(0.3 * barStack.index, bar.color)
                              : shade(
                                  0.3 * (barStacks.length - barStack.index - 1),
                                  bar.color,
                                );
                          })()}
                        />
                        {!disableAxis &&
                        bar.bar.data?.isOutlier &&
                        barStack.index === barStacks.length - 1 ? (
                          <Bar
                            x={bar.x - 1}
                            y={barY - 1}
                            width={bar.width + 2}
                            height={detailed ? 50 : 25}
                            fill={gradientFill}
                          />
                        ) : null}
                        {!disableAxis && showGradient ? (
                          <Bar
                            x={bar.x - 1}
                            y={barY - 1}
                            width={bar.width + 2}
                            height={detailed ? 50 : 25}
                            fill={gradientFill}
                          />
                        ) : null}
                      </Group>
                    );
                  })}
                </Group>
              )),
              barStacks.map((barStack) => (
                <Group key={`bar-stack-${barStack.index}-label`}>
                  {barStack.bars.map((bar, index) => {
                    if (bar.bar.some((e) => Number.isNaN(e))) return null;
                    const isLast = barStack.index === barStacks.length - 1;

                    const maxValue = valueScale.domain()[1];
                    const threshold =
                      isManual || outlierThreshold === null
                        ? maxValue
                        : outlierThreshold;
                    const thresholdValue = valueScale(maxValue);

                    if (
                      !disableAxis &&
                      (bar.x < -columnWidth * 5 ||
                        bar.x > plotWidth + columnWidth * 5)
                    ) {
                      return null;
                    }

                    const showGradient =
                      isLast && bar.bar.data.totalValue > threshold;

                    let barY = bar.y;
                    let barHeight = bar.height;

                    if (!disableAxis && showGradient) {
                      barHeight -= thresholdValue - barY;
                      barY = thresholdValue;
                    }
                    return (
                      <Label
                        barX={bar.x}
                        key={`bar-stack-${index}-${barStack.index}-label`}
                        innerLabel
                        barY={barY + barHeight / 2}
                        columnWidth={bar.width}
                        barHeight={barHeight}
                        index={index}
                        value={
                          lens.stackingType === StackingType.Distribution
                            ? `${roundNumberToDecimal((bar.bar.data.values[bar.key] ?? 0) * 100, 2)}%`
                            : valueUOM.display(bar.bar.data.values[bar.key], {
                                unit: "",
                              })
                        }
                        label={lens?.label}
                      />
                    );
                  })}
                </Group>
              )),

              barStacks.map((barStack) => {
                const barStackIndex = barStack.index;
                // Todo: need to get actual barheight instead of recalculation
                let barHeight = 0;

                return (
                  <Group key={`bar-stack-${barStackIndex}`}>
                    {barStack.bars.map((bar, index) => {
                      const isLast = barStackIndex === barStacks.length - 1;
                      barHeight += bar.height;
                      if (!isLast) {
                        return null;
                      }
                      const maxValue = valueScale.domain()[1];
                      const threshold =
                        isManual || outlierThreshold === null
                          ? maxValue
                          : outlierThreshold;
                      const thresholdValue = valueScale(maxValue);

                      if (
                        !disableAxis &&
                        (bar.x < -columnWidth * 5 ||
                          bar.x > plotWidth + columnWidth * 5)
                      ) {
                        return null;
                      }

                      const showGradient =
                        isLast && bar.bar.data.totalValue > threshold;

                      let barY = bar.y;

                      if (!disableAxis && showGradient) {
                        barHeight -= thresholdValue - barY;
                        barY = thresholdValue;
                      }

                      return (
                        <Group key={`bar-label-${barStackIndex}-${bar.index}`}>
                          {barStackIndex === barStacks.length - 1 && (
                            <Label
                              barX={bar?.x}
                              barY={barY + (detailed ? 0 : 10)}
                              topLabel={bar.y !== plotHeight}
                              detailed={detailed}
                              index={index}
                              columnWidth={bar.width}
                              barHeight={barHeight}
                              value={
                                lens.stackingType === StackingType.Distribution
                                  ? ""
                                  : valueUOM.display(bar.bar.data.totalValue, {
                                      unit: "",
                                    })
                              }
                              label={lens?.label}
                            />
                          )}
                        </Group>
                      );
                    })}
                  </Group>
                );
              }),
            ]}
          </BarStack>

          {/* Rectangles to cover the whole stacked bar and handle all the events */}
          {transformedData.map((item) => {
            const x = categoryScale(item.standId) || 0;

            if (x < -columnWidth * 5 || x > plotWidth + columnWidth * 5) {
              return null;
            }

            return (
              <Bar
                style={{ cursor: "pointer" }}
                key={item.index}
                x={x}
                y={valueScale(item.totalValue)}
                width={columnWidth}
                height={valueScale(0) - valueScale(item.totalValue)}
                fillOpacity={0}
                onMouseOver={() => handleMouseOver(item)}
                onMouseOut={() => {
                  hideTooltip();
                }}
                onClick={() => {
                  Track.interact("Open Inspection View", {
                    "Stand Id": item.standId,
                  });
                  if (parentkpiType) {
                    openInspectionView(
                      {
                        ...item,
                        value: item.totalValue,
                        standKpiType: parentkpiType,
                      },
                      valueUOM,
                      valueUOM.display(item.totalValue),
                    );
                  }
                }}
              />
            );
          })}

          <AverageLine
            isVisible={(lens?.selectedVisualAids ?? []).includes(
              VisualAidType.Average,
            )}
            y={
              Number.isNaN(valueScale(data.summary?.average ?? 0))
                ? 0
                : valueScale(data.summary?.average ?? 0)
            }
            x={-RIGHT_AXIS_PADDING}
            width={plotWidth + RIGHT_AXIS_PADDING}
          />

          <MedianLine
            isVisible={(lens?.selectedVisualAids ?? []).includes(
              VisualAidType.Median,
            )}
            y={valueScale(data.summary?.median ?? 0)}
            x={-RIGHT_AXIS_PADDING}
            width={plotWidth + RIGHT_AXIS_PADDING}
          />

          <Indicators
            keys={aggregatedIndicators.keys}
            value={(key: string) => aggregatedIndicators.map[key]}
            xScale={categoryScale}
            height={plotHeight}
            gapWidth={gapWidth}
            selectedIndicators={indicators || []}
          />

          {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Targets) &&
            computedTargetSegments.map(
              ({ target, lineStart, lineEnd, showTag }, index) => {
                if (!target) return null;
                return (
                  <TargetLine
                    key={`${index}-${target}-${lineStart}-${lineEnd}`}
                    start={lineStart}
                    end={lineEnd}
                    y={valueScale(target)}
                    label={valueUOM.display(target, { unit: "" })}
                    showTag={showTag}
                    detailed={detailed}
                  />
                );
              },
            )}
        </Chart>

        {!lens?.squeezesDisplay && zoomRate > 1 && (
          <Group
            top={plotHeight + (detailed ? 62 : 26)}
            left={scrollbarPadding}
          >
            <Scrollbar
              width={scrollbarWidth}
              height={4}
              scale={scrollbarScale}
              wheelCaptureContainer={containerRef}
              onScroll={setScrollbarRange}
              valueSpan={1 / zoomRate}
            />
          </Group>
        )}
      </svg>

      {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Average) && (
        <AverageLabel
          style={{
            top: `${valueScale(data.summary?.average ?? 0) - 16}px`,
          }}
        >
          Average: {valueUOM.display(data.summary?.average ?? 0)}
        </AverageLabel>
      )}

      {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Median) && (
        <MedianLabel
          isDetailed={detailed}
          style={{
            top: `${valueScale(data.summary?.median ?? 0) - 16}px`,
          }}
        >
          Median: {valueUOM.display(data.summary?.median ?? 0)}
        </MedianLabel>
      )}

      {tooltipElement}
      {inspectionViewElement}
    </div>
  );
};
