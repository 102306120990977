import { PDComponent } from "components/PDComponents";
import * as CommonStyles from "pages/RigScoreCard/RightPane/StyledComponents";
import styled from "styled-components";
import colors from "utils/colors";

export const WellRank = styled.span`
  font-size: 14px;
  width: 36px;
  min-width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
  background-color: ${(props) => props.theme.themeStyle.colors.tertiary_bg};
`;

export const WellTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;

  @media screen and (max-width: 1300px) {
    font-size: 14px;
  }
`;

export const WellInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const WellJobs = styled(WellTitle)`
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  font-weight: 400;
`;

export const WellSubheading = styled.div`
  font-size: 14px;
`;
export const Title = styled.span`
  color: ${({ theme }) => theme.themeStyle.colors.tertiary_typography};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
`;
export const Description = styled.span`
  color: ${colors.gray};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
`;
export const DropdownRow = styled(CommonStyles.Row)`
  display: grid;
  cursor: pointer;
  align-items: center;
  user-select: none;
`;

export const CategoryDropdown = styled(DropdownRow)`
  grid-template-columns: auto 1fr auto;
  padding: 16px 18px;
  column-gap: 8px;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};

  h1 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    font-size: 16px;
    font-weight: 700;
  }

  p {
    font-size: 12px;
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    margin: 0;
  }
`;

export const SubcategoryDropdown = styled(DropdownRow)`
  padding: 8px 18px;
  column-gap: 4px;
  grid-template-columns: auto 1fr;

  h2 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;

export const Chevron = styled(PDComponent.SvgIcon).attrs({
  name: "chevronDown",
})<{ isRotated?: boolean }>`
  svg {
    transform: ${({ isRotated }) =>
      isRotated ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

export const SubtitleWithScore = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
`;
