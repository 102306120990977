import { AxisBottom, AxisLeft, AxisTop } from "@visx/axis";
import { RectClipPath } from "@visx/clip-path";
import { LinearGradient } from "@visx/gradient";
import { Grid } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { AreaClosed, Bar, LinePath } from "@visx/shape";
import type {
  BroomstickPointDto,
  DateDto,
  SurveyPointDto,
  TorqueAndDragActivityType,
} from "apis/oag";
import { DimensionType, ResultDataState, TorqueAndDragType } from "apis/oag";
import { Title } from "atoms/Typography";
import {
  StyledIcon,
  StyledItem,
  StyledMenu,
} from "components/DetailsTopBar/KpiSelect/style";
import {
  AxisLocation,
  StandardTickComponent,
} from "components/Lenses/common/ChartElements";
import NoData from "components/Lenses/common/NoData";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { GRID_WIDTH_FULL } from "components/Lenses/constants";
import {
  LATERAL_AXIS_TEXT_WIDTH,
  LATERAL_AXIS_WIDTH,
} from "components/Lenses/ContainerLens/common/utils/utils";
import {
  useBroomStickByType,
  useBroomSticks,
  useTorqueAndDragData,
} from "components/Lenses/ContainerLens/TorqueAndDrag/fetcher";
import { InspectionView } from "components/Lenses/ContainerLens/TorqueAndDrag/Inspection";
import {
  Circle,
  Col,
  ColLine,
  GraphWrapper,
  LegendWrapper,
  LineLegend,
  Row,
  Spacer,
  StyledButton,
} from "components/Lenses/ContainerLens/TorqueAndDrag/style";
import { StyledControlsContainer } from "components/Lenses/ContainerLens/TorqueAndDrag/styledComponents";
import { useTorqueDragZoom } from "components/Lenses/ContainerLens/TorqueAndDrag/useTorqueDragZoom";
import {
  activityCircleColors,
  activityLineColors,
  HORIZONTAL_AXIS_HEIGHT,
  kpiToLabel,
  LEFT_AXIS_WIDTH,
  LEGEND_WIDTH,
  LENS_TITLE,
  MINI_VIEW_AXIS,
  toNormalCase,
  useNumValueTicks,
  Y_AXIS_RANGE_PADDING,
} from "components/Lenses/ContainerLens/TorqueAndDrag/utils";
import type { ParameterTorqueAndDragKpiProps } from "components/Lenses/interfaces";
import { LensGridContext } from "components/Lenses/utils";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { InfoContainer } from "components/MiniLens/InfoContainer";
import { PDComponent } from "components/PDComponents";
import { LETTER_WIDTH } from "components/TvDChart/constants";
import { bisector, extent, max, min } from "d3-array";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import { uniq } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { IUnitSystem } from "reducers/types";
import type { CSSProperties } from "styled-components";
import colors from "utils/colors";
import { Popover } from "utils/componentLibrary";
import { useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

export type ExtendedBroomstickPointDto = BroomstickPointDto & { id: number };
export const TorqueAndDrag: React.FC<ParameterTorqueAndDragKpiProps> = ({
  lens,
  graphKey,
  setLensDate,
  detailed,
}) => {
  const dimension = useMemo(() => {
    return lens?.torqueAndDragType === TorqueAndDragType.Torque
      ? DimensionType.NewtonMeters
      : DimensionType.Newtons;
  }, [lens?.torqueAndDragType]);
  const valueUOM = useUOM(dimension);
  const dlsUOM = useUOM(DimensionType.Undefined);
  const { data: broomsticks } = useBroomSticks(lens?.id);
  const depthTransformer = useUOM(DimensionType.Metres);
  const { data: broomsticksType } = useBroomStickByType(
    lens?.torqueAndDragType,
    lens?.id,
  );
  const [visible, setVisible] = useState(false);
  const [selectedBroomstick, setSelectedBroomstick] = useState(
    broomsticksType[0],
  );
  const [isInspectionOpen, setIsInspectionOpen] = useState<boolean>(false);
  const [selectedInspectionPoint, setSelectedInspectionPoint] =
    useState<ExtendedBroomstickPointDto | null>(null);

  useEffect(() => {
    setSelectedBroomstick((prevSelection) =>
      (broomsticksType ?? []).includes(prevSelection)
        ? prevSelection
        : (broomsticksType ?? [])[0],
    );
  }, [broomsticksType]);

  const [hiddenCurvesPoints, setHiddenCurvesPoints] = useState<{
    points: Array<number | string>;
    curves: Array<number | string>;
  }>({ points: [], curves: [] });

  const { data: torqueData } = useTorqueAndDragData(lens, selectedBroomstick);
  useEffect(() => {
    if (torqueData?.lastUpdatedAt && setLensDate)
      setLensDate(torqueData.lastUpdatedAt);
  }, [setLensDate, torqueData?.lastUpdatedAt]);
  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: containerRef,
  } = useResizeDetector();
  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };
  const { crtLayout, bp } = useContext(LensGridContext);

  const containerWidth = useMemo(
    () =>
      crtLayout
        ? (crtLayout[bp] ?? []).find((item) => item?.i === graphKey)?.w ||
          GRID_WIDTH_FULL
        : GRID_WIDTH_FULL,
    [bp, crtLayout, graphKey],
  );

  const numValueTicks = useNumValueTicks(containerWidth);

  const {
    showTooltip: showDlsTooltip,
    hideTooltip: hideDlsTooltip,
    tooltipElement: dlsTooltipElement,
  } = useChartTooltip<SurveyPointDto>({
    containerRef,
    renderContent: ({ tooltipData }) =>
      tooltipData ? (
        <>
          <TooltipHighlightValue>
            <span>
              DLS:
              {dlsUOM.display(tooltipData.dls, {
                fractionDigits: 2,
                unit: `Deg/${depthTransformer.currentUOM === IUnitSystem.METRIC ? "30m" : "100ft"}`,
              })}
            </span>
          </TooltipHighlightValue>
          <TooltipHighlightValue
            style={{ color: colors.off_state, fontSize: 12 }}
          >
            Depth:{" "}
            {depthTransformer.display(tooltipData.depth, {
              fractionDigits: 2,
              unit: depthTransformer.abbr,
            })}
          </TooltipHighlightValue>
        </>
      ) : null,
  });

  const BOTTOM_AXIS_POSITION = chartHeight - 2 * HORIZONTAL_AXIS_HEIGHT + 16;
  const plotHeight = getSVGNormalizedValue(
    chartHeight - 2 * HORIZONTAL_AXIS_HEIGHT - MINI_VIEW_AXIS + 16,
  );
  const plotWidth = getSVGNormalizedValue(
    chartWidth - LEGEND_WIDTH - LEFT_AXIS_WIDTH,
  );
  const {
    isDark,
    themeStyle: { colors: themeColors },
    atomThemeVariant,
  } = useCustomTheme();

  const getDepth = (point: { depth?: number }) => point?.depth ?? 0;
  const getDLS = (point: SurveyPointDto) => point?.dls ?? 0;
  const getMeasure = (point: BroomstickPointDto) => point?.measure ?? 0;

  const dslCurve = useMemo(
    () => torqueData?.survey?.points ?? [],
    [torqueData?.survey?.points],
  );

  const torqueMeasurements = useMemo(
    () => torqueData?.torqueAndDragMeasures?.curves ?? [],
    [torqueData?.torqueAndDragMeasures?.curves],
  );

  const broomstickCurves = useMemo(
    () => torqueData?.broomsticks?.curves ?? [],
    [torqueData?.broomsticks?.curves],
  );

  const getMeasurementScale = useCallback(
    (overrideDomain?: number[]) => {
      const minValueTorque =
        min(
          torqueMeasurements.map(
            (e) => min((e.points ?? []).map(getMeasure)) ?? 0,
          ),
        ) ?? 0;
      const maxValueTorque =
        max(
          torqueMeasurements.map(
            (e) => max((e.points ?? []).map(getMeasure)) ?? 0,
          ),
        ) ?? 0;
      const minValueBroomStick = min(
        broomstickCurves.map((e) => min((e.points ?? []).map(getMeasure)) ?? 0),
      );
      const maxValueBroomStick =
        max(
          broomstickCurves.map(
            (e) =>
              max((e.points ?? []).map(getMeasure)) ?? Number.NEGATIVE_INFINITY,
          ),
        ) ?? Number.NEGATIVE_INFINITY;
      const minValue = Math.min(
        Number.isFinite(minValueTorque)
          ? minValueTorque
          : Number.POSITIVE_INFINITY,
        minValueBroomStick && Number.isFinite(minValueBroomStick)
          ? minValueBroomStick
          : Number.POSITIVE_INFINITY,
      );
      const maxValue = Math.max(
        Number.isFinite(maxValueTorque)
          ? maxValueTorque
          : Number.NEGATIVE_INFINITY,
        Number.isFinite(maxValueBroomStick)
          ? maxValueBroomStick
          : Number.NEGATIVE_INFINITY,
      );

      const domain = overrideDomain
        ? overrideDomain
        : Number.isFinite(minValue) && Number.isFinite(maxValue)
          ? [minValue, maxValue]
          : [];

      return scaleLinear({
        domain,
        // Offsetting the pixel range based on zoom, provides scrolling and zoom at the same time
        range: [0, plotWidth],
        clamp: true,
      });
    },
    [broomstickCurves, plotWidth, torqueMeasurements],
  );

  const measurementScale = useMemo(getMeasurementScale, [getMeasurementScale]);

  const categoryScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, 100],
        // Offsetting the pixel range based on zoom, provides scrolling and zoom at the same time
        range: [0, plotWidth ?? 0],
      }),
    [plotWidth],
  );

  const getValueScale = useCallback(
    (overrideDomain?: number[]) => {
      const minValueDLS = min(dslCurve.map(getDepth)) ?? 0;
      const maxValueDLS = max(dslCurve.map(getDepth));
      const minValueTorque =
        min(
          torqueMeasurements.map(
            (e) => min((e.points ?? []).map(getDepth)) ?? 0,
          ),
        ) ?? 0;
      const maxValueTorque = max(
        torqueMeasurements.map((e) => max((e.points ?? []).map(getDepth)) ?? 0),
      );
      const minValueBroomStick =
        min(
          broomstickCurves.map((e) => min((e.points ?? []).map(getDepth)) ?? 0),
        ) ?? 0;
      const maxValueBroomStick = max(
        broomstickCurves.map((e) => max((e.points ?? []).map(getDepth)) ?? 0),
      );
      const minValue = Math.min(
        Number.isFinite(minValueDLS) ? minValueDLS : Number.POSITIVE_INFINITY,
        Number.isFinite(minValueTorque)
          ? minValueTorque
          : Number.POSITIVE_INFINITY,
        Number.isFinite(minValueBroomStick)
          ? minValueBroomStick
          : Number.POSITIVE_INFINITY,
      );
      const maxValue = Math.max(
        maxValueDLS || Number.NEGATIVE_INFINITY,
        maxValueTorque || Number.NEGATIVE_INFINITY,
        maxValueBroomStick || Number.NEGATIVE_INFINITY,
      );

      const domain = overrideDomain
        ? overrideDomain
        : Number.isFinite(minValue) && Number.isFinite(maxValue)
          ? [minValue, maxValue]
          : [];
      return scaleLinear<number>({
        domain,
        range: [0, plotHeight - Y_AXIS_RANGE_PADDING], // To not overlap with the bottom axis text
        clamp: true,
      });
    },
    [dslCurve, torqueMeasurements, broomstickCurves, plotHeight],
  );

  const valueScale = useMemo(getValueScale, [getValueScale]);

  const domainValues = useMemo(() => {
    const measures = torqueMeasurements.flatMap((tm) =>
      (tm.points || []).map((p) => p.measure),
    );
    return extent(measures) as [number, number];
  }, [torqueMeasurements]);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<
    BroomstickPointDto & {
      activity: TorqueAndDragActivityType;
      startDate?: DateDto;
      endDate?: DateDto;
    }
  >({
    containerRef,
    renderContent: ({ tooltipData }) =>
      tooltipData ? (
        <>
          <TooltipHighlightValue>
            {toNormalCase(tooltipData.activity)}:{" "}
            {valueUOM.displayWithAutoDecimals(
              domainValues,
              tooltipData.measure,
              { unit: "" },
            )}{" "}
            {valueUOM.abbr}
          </TooltipHighlightValue>
          <span>{depthTransformer.display(tooltipData.depth)}</span>
          {tooltipData.startDate && tooltipData.endDate ? (
            <span>
              {formatTime(tooltipData.startDate, { formatStr: "MM/DD HH:mm" })}{" "}
              to {formatTime(tooltipData.endDate, { formatStr: "MM/DD HH:mm" })}
            </span>
          ) : null}
        </>
      ) : null,
  });

  const handleOnClick = useCallback((pointMeasurement: BroomstickPointDto) => {
    if (pointMeasurement) {
      setSelectedInspectionPoint(
        pointMeasurement as ExtendedBroomstickPointDto,
      );
      setIsInspectionOpen(true);
    }
  }, []);

  const tickValues = useMemo(() => {
    if (!categoryScale.domain()) return [];
    const [min, max] = categoryScale.domain() ?? [0, 0];
    if (!min && !max) return [];

    return [
      ...Array.from(
        { length: numValueTicks },
        (v, k) =>
          min + (((Number.isNaN(max) ? 0 : max) - min) / numValueTicks) * k,
      ),
      Number.isNaN(max) ? 0 : max,
    ];
  }, [categoryScale, numValueTicks]);

  const kpiLabel = kpiToLabel(lens.torqueAndDragType);

  const {
    ZoomRect,
    ZoomControls,
    resetZoom,
    zoomContainerProps,
    newXDomain,
    newYDomain,
  } = useTorqueDragZoom({
    chartHeight: plotHeight,
    xScale: measurementScale,
    yScale: valueScale,
    isHoveringPoint: !!tooltipElement,
  });

  useEffect(resetZoom, [resetZoom]);

  const zoomedMeasurementScale = useMemo(
    () => getMeasurementScale(newXDomain),
    [getMeasurementScale, newXDomain],
  );

  const zoomedValueScale = useMemo(
    () => getValueScale(newYDomain),
    [getValueScale, newYDomain],
  );

  const { verticalAxisTicksCount, yDisplayOnlyScale, formatTickDecimals } =
    useYAxisDisplayScale({
      originalScale: zoomedValueScale,
      uom: depthTransformer,
      tickContainerHeight: plotHeight,
    });

  const tickMeasurementsValues = useMemo(() => {
    if (!zoomedMeasurementScale.domain()) return [];
    const [min, max] = zoomedMeasurementScale.domain() ?? [0, 0];
    if (!min && !max) return [];

    return [
      ...Array.from(
        { length: numValueTicks },
        (v, k) =>
          min + (((Number.isNaN(max) ? 0 : max) - min) / numValueTicks) * k,
      ),
      Number.isNaN(max) ? 0 : max,
    ];
  }, [numValueTicks, zoomedMeasurementScale]);

  const filterBroomstickPoints = useCallback(
    (points: BroomstickPointDto[]) => {
      const [xMin, xMax] = zoomedMeasurementScale.domain();
      const [yMin, yMax] = zoomedValueScale.domain();

      return points.filter(
        (p) =>
          getMeasure(p) > xMin &&
          getMeasure(p) < xMax &&
          getDepth(p) > yMin &&
          getDepth(p) < yMax,
      );
    },
    [zoomedMeasurementScale, zoomedValueScale],
  );

  const handleMouseOverPoint = useCallback(
    (data: BroomstickPointDto & { activity: TorqueAndDragActivityType }) => {
      showTooltip({
        tooltipLeft: zoomedMeasurementScale(getMeasure(data)) + LEFT_AXIS_WIDTH,
        tooltipTop: zoomedValueScale(getDepth(data)) + HORIZONTAL_AXIS_HEIGHT,
        tooltipData: data,
      });
    },
    [showTooltip, zoomedMeasurementScale, zoomedValueScale],
  );

  const { style: zoomStyle, ...restZoomProps } = zoomContainerProps;

  const [selectedDlsCurvePoint, setSelectedDlsCurvePoint] =
    useState<SurveyPointDto | null>(null);

  const handleOnDlsMove = useCallback(
    (event: React.PointerEvent<SVGRectElement>) => {
      const { top, left } = event.currentTarget.getBoundingClientRect();
      const x = ("clientX" in event ? event.clientX : 0) - left;
      const y = ("clientY" in event ? event.clientY : 0) - top;

      // eslint-disable-next-line @typescript-eslint/unbound-method
      const bisectDlsDepth = bisector<SurveyPointDto, number>((d) =>
        getDepth(d),
      ).center;

      const y0 = zoomedValueScale.invert(y);
      const pointIndex = bisectDlsDepth(dslCurve, y0);
      const point = dslCurve[pointIndex];

      if (point && categoryScale.invert(x) <= getDLS(point)) {
        setSelectedDlsCurvePoint(point);
        showDlsTooltip({
          tooltipData: point,
          tooltipLeft: Math.min(
            categoryScale(getDLS(point)) + LEFT_AXIS_WIDTH,
            categoryScale(categoryScale.domain()[1]) + LEFT_AXIS_WIDTH,
          ),
          tooltipTop:
            zoomedValueScale(getDepth(point)) + HORIZONTAL_AXIS_HEIGHT,
        });
      } else {
        setSelectedDlsCurvePoint(null);
        hideDlsTooltip();
      }
    },
    [categoryScale, dslCurve, hideDlsTooltip, showDlsTooltip, zoomedValueScale],
  );

  const handleOnDlsOut = useCallback(() => {
    setSelectedDlsCurvePoint(null);
    hideDlsTooltip();
  }, [hideDlsTooltip]);

  // To construct a bounding box for the pointer hover listener
  const [minDlsCurveX, maxDlsCurveX] = useMemo(() => {
    const [minCurve = 0, maxCurve = 0] = [
      min(dslCurve.map(getDLS)),
      max(dslCurve.map(getDLS)),
    ];
    return [categoryScale(minCurve), categoryScale(maxCurve)];
  }, [categoryScale, dslCurve]);

  const DLS_CLIP_ID = `dls-clip-${lens.id}`;

  return (
    <>
      {!!selectedInspectionPoint && isInspectionOpen ? (
        <InspectionView
          lensId={lens.id}
          onCancel={() => {
            setIsInspectionOpen(false);
            setSelectedInspectionPoint(null);
          }}
          kpiType={lens?.torqueAndDragType}
          valueUOM={valueUOM}
          isVisible={isInspectionOpen}
          selectedPoint={selectedInspectionPoint}
        />
      ) : null}

      {torqueData.dataState === ResultDataState.Valid ? (
        <GraphWrapper
          ref={containerRef}
          LEGEND_WIDTH={LEGEND_WIDTH}
          style={{
            userSelect: "none",
            height: detailed ? "calc(100% - 70px)" : "100%",
          }}
        >
          <>
            <StyledControlsContainer
              left={LEFT_AXIS_WIDTH + plotWidth}
              top={HORIZONTAL_AXIS_HEIGHT}
            >
              <ZoomControls />
            </StyledControlsContainer>
            <svg
              width={plotWidth + LEFT_AXIS_WIDTH}
              height={chartHeight}
              style={zoomStyle as CSSProperties}
              {...restZoomProps}
            >
              <LinearGradient
                id="area-gradient"
                from={themeColors.frozen_tundra}
                to={themeColors.frozen_tundra}
                fromOpacity={0.4}
                toOpacity={0.4}
              />
              <Bar
                width={chartWidth}
                height={HORIZONTAL_AXIS_HEIGHT}
                fill={themeColors.primary_chart_bg}
              />
              <Group top={BOTTOM_AXIS_POSITION}>
                <Bar
                  width={chartWidth}
                  height={HORIZONTAL_AXIS_HEIGHT}
                  fill={themeColors.primary_chart_bg}
                />
              </Group>

              <Grid
                xScale={categoryScale}
                yScale={yDisplayOnlyScale}
                top={HORIZONTAL_AXIS_HEIGHT}
                left={LEFT_AXIS_WIDTH}
                width={plotWidth}
                height={plotHeight}
                numTicksColumns={numValueTicks}
                stroke={themeColors.primary_chart_accent}
                style={{ userSelect: "none" }}
              />

              {categoryScale.domain().length > 0 ? (
                <text
                  x={0}
                  y={0}
                  fontSize={14}
                  letterSpacing={-0.2}
                  textAnchor="start"
                  fill={themeColors.disabled_typography}
                  pointerEvents="none"
                  transform={`rotate(-90) translate(-${plotHeight / 2 + 70}  20) `}
                >
                  Depth (
                  {depthTransformer.currentUOM === IUnitSystem.METRIC
                    ? "m"
                    : "ft"}
                  )
                </text>
              ) : null}
              {zoomedMeasurementScale.domain().length > 0 ? (
                <text
                  x={0}
                  y={chartHeight - 55}
                  fontSize={14}
                  letterSpacing={-0.2}
                  textAnchor="start"
                  fill={themeColors.disabled_typography}
                  pointerEvents="none"
                >
                  <tspan x="0" dx="1em">
                    {kpiLabel}
                  </tspan>
                  <tspan x="0" dy="1.2em" dx="1.7em">
                    ({valueUOM.abbr})
                  </tspan>
                </text>
              ) : null}
              {lens?.showSurvey ? (
                <text
                  x={0}
                  y={12.5}
                  fontSize={12}
                  letterSpacing={-0.2}
                  textAnchor="start"
                  fill={themeColors.disabled_typography}
                  pointerEvents="none"
                >
                  <tspan x="0" dx="3em">
                    DLS
                  </tspan>
                  <tspan x="0" dy="1em" dx="1.5em">
                    (Deg/
                    {depthTransformer.currentUOM === IUnitSystem.METRIC
                      ? "30m"
                      : "100ft"}
                    )
                  </tspan>
                </text>
              ) : null}

              {lens?.showSurvey && dslCurve.length > 1 ? (
                <AxisTop
                  hideTicks
                  hideAxisLine
                  tickStroke={themeColors.disabled_typography}
                  tickValues={tickValues}
                  stroke={themeColors.disabled_typography}
                  left={LEFT_AXIS_WIDTH}
                  top={HORIZONTAL_AXIS_HEIGHT}
                  scale={categoryScale}
                  tickComponent={(props) => (
                    <Group>
                      <Bar
                        x={(props.x ?? 0) - 1.5}
                        y={-2}
                        width={3}
                        height={2}
                        opacity={0.3}
                        fill={themeColors.primary_bg_faded}
                      />
                      <text
                        x={
                          props.x +
                          (LETTER_WIDTH *
                            ((props.formattedValue || "").length ?? 0)) /
                            4
                        }
                        y={props.y}
                        dx={
                          -(
                            LETTER_WIDTH *
                            ((props.formattedValue || "").length ?? 0)
                          ) / 2
                        }
                        dy={-10}
                        fontSize={14}
                        letterSpacing={-0.2}
                        textAnchor="right"
                        fill={themeColors.disabled_typography}
                        pointerEvents="none"
                      >
                        {props.formattedValue}
                      </text>
                    </Group>
                  )}
                />
              ) : null}
              <AxisBottom
                hideTicks
                hideAxisLine
                tickStroke={themeColors.disabled_typography}
                tickValues={tickMeasurementsValues}
                stroke={themeColors.disabled_typography}
                scale={zoomedMeasurementScale}
                left={LEFT_AXIS_WIDTH}
                top={BOTTOM_AXIS_POSITION}
                tickComponent={(props) => (
                  <Group>
                    <Bar
                      x={props.x - 1.5}
                      width={3}
                      height={2}
                      opacity={0.3}
                      fill={themeColors.primary_bg_faded}
                    />
                    <text
                      x={
                        props.x +
                        (LETTER_WIDTH *
                          ((props.formattedValue || "").length ?? 0)) /
                          4
                      }
                      y={props.y}
                      dx={
                        -(
                          LETTER_WIDTH *
                          ((props.formattedValue || "").length ?? 0)
                        ) / 2
                      }
                      fontSize={14}
                      letterSpacing={-0.2}
                      textAnchor="right"
                      fill={themeColors.disabled_typography}
                      pointerEvents="none"
                    >
                      {valueUOM.display(
                        Number.parseFloat(
                          (props.formattedValue || "").replaceAll(",", ""),
                        ),
                        {
                          fractionDigits: 0,
                          unit: "",
                        },
                      )}
                    </text>
                  </Group>
                )}
              />

              <AxisLeft
                hideTicks
                hideAxisLine
                tickStroke={themeColors.disabled_typography}
                top={HORIZONTAL_AXIS_HEIGHT}
                left={LATERAL_AXIS_WIDTH - LATERAL_AXIS_TEXT_WIDTH}
                stroke={themeColors.disabled_typography}
                scale={yDisplayOnlyScale}
                numTicks={verticalAxisTicksCount}
                hideZero
                tickComponent={(props) => (
                  <StandardTickComponent
                    rendererProps={props}
                    axisLocation={AxisLocation.LEFT}
                  >
                    {(val) => formatTickDecimals(val)}
                  </StandardTickComponent>
                )}
              />
              <Group top={HORIZONTAL_AXIS_HEIGHT} left={LEFT_AXIS_WIDTH}>
                {lens?.showSurvey ? (
                  <Group clipPath={`url(#${DLS_CLIP_ID})`}>
                    <RectClipPath
                      y={0}
                      id={DLS_CLIP_ID}
                      height={chartHeight}
                      width={plotWidth}
                    />

                    <AreaClosed<SurveyPointDto>
                      // HACK: add pseudo-point at the start to get the correct closed area
                      data={[
                        {
                          depth:
                            (torqueData?.survey?.points ?? [])?.[0]?.depth ?? 0,
                          dls: 0,
                        },
                        ...dslCurve,
                        {
                          depth:
                            (torqueData?.survey?.points || []).slice(-1)[0]
                              ?.depth ?? 0,
                          dls: 0,
                        },
                      ]}
                      x={(d) => categoryScale(getDLS(d)) || 0}
                      y={(d) => zoomedValueScale(getDepth(d)) || 0}
                      yScale={zoomedValueScale}
                      fill="url(#area-gradient)"
                      onPointerMove={handleOnDlsMove}
                      onPointerOut={handleOnDlsOut}
                    />
                    <LinePath<SurveyPointDto>
                      data={dslCurve}
                      x={(d) => categoryScale(getDLS(d)) || 0}
                      y={(d) => zoomedValueScale(getDepth(d)) || 0}
                      strokeWidth={0.5}
                      stroke={colors.thermocline_grey}
                    />
                    <Bar
                      width={maxDlsCurveX - minDlsCurveX}
                      height={plotHeight}
                      fill="transparent"
                      rx={14}
                      onMouseMove={handleOnDlsMove}
                      onMouseLeave={handleOnDlsOut}
                    />

                    {selectedDlsCurvePoint ? (
                      <circle
                        cx={categoryScale(getDLS(selectedDlsCurvePoint))}
                        cy={zoomedValueScale(getDepth(selectedDlsCurvePoint))}
                        r={3.5}
                        fill={colors.thermocline_grey}
                        strokeWidth={0}
                      />
                    ) : null}
                  </Group>
                ) : null}
                {broomstickCurves.map((curve, i) => {
                  if (
                    hiddenCurvesPoints.curves.find(
                      (e) => e === curve.broomstickId,
                    )
                  )
                    return null;
                  const line = broomsticks.find(
                    (broomStick) => broomStick.id === curve.broomstickId,
                  );
                  return line ? (
                    <LinePath
                      data={filterBroomstickPoints(curve.points || [])}
                      x={(d) => zoomedMeasurementScale(getMeasure(d)) || 0}
                      y={(d) => zoomedValueScale(getDepth(d)) || 0}
                      strokeWidth={1}
                      stroke={
                        activityLineColors[
                          line.activity as keyof typeof activityLineColors
                        ]
                      }
                      key={`index-${curve.broomstickId}-${i}`}
                    />
                  ) : null;
                })}

                {torqueMeasurements.map(({ points, activity }) =>
                  filterBroomstickPoints(points || []).map((measurement, i) =>
                    hiddenCurvesPoints.points.find((e) => e === activity) ||
                    !Number.isFinite(
                      zoomedMeasurementScale(getMeasure(measurement)),
                    ) ||
                    !Number.isFinite(
                      zoomedValueScale(getDepth(measurement)),
                    ) ? null : (
                      <circle
                        key={`${getDepth(measurement)}-${getMeasure(measurement)}-${i}`}
                        cx={zoomedMeasurementScale(getMeasure(measurement))}
                        cy={zoomedValueScale(getDepth(measurement))}
                        onMouseEnter={() => {
                          handleMouseOverPoint({ ...measurement, activity });
                        }}
                        onMouseLeave={() => hideTooltip()}
                        onClick={() => handleOnClick(measurement)}
                        r={2.5}
                        fill={
                          activityCircleColors[
                            activity as keyof typeof activityCircleColors
                          ]
                        }
                        strokeWidth={0}
                      />
                    ),
                  ),
                )}
              </Group>
              {ZoomRect}
            </svg>

            <LegendWrapper>
              {broomsticksType.length > 0 ? (
                <Popover
                  content={
                    <StyledMenu>
                      {broomsticksType.map((option) => (
                        <StyledItem
                          key={option}
                          $isActive={(option === selectedBroomstick).toString()}
                          onClick={
                            option === selectedBroomstick
                              ? undefined
                              : () => {
                                  setVisible(false);
                                  setSelectedBroomstick(option);
                                }
                          }
                        >
                          {option}
                        </StyledItem>
                      ))}
                    </StyledMenu>
                  }
                  open={visible}
                  onOpenChange={(visible) => {
                    setVisible(visible);
                  }}
                  placement="bottomLeft"
                  trigger={["click"]}
                >
                  <StyledButton onClick={() => setVisible(true)}>
                    <Title
                      variant={atomThemeVariant}
                      level={5}
                      style={{
                        userSelect: "none",
                        pointerEvents: "none",
                      }}
                    >
                      {selectedBroomstick}
                    </Title>
                    <StyledIcon up={visible}>
                      <PDComponent.SvgIcon name="chevronDown" />
                    </StyledIcon>
                  </StyledButton>
                </Popover>
              ) : null}
              {broomsticksType.length > 0 ? <Spacer /> : null}
              {broomstickCurves.map(({ broomstickId }, idx) => {
                const line = broomsticks.find(
                  (broomStick) => broomStick.id === broomstickId,
                );
                return line ? (
                  <Row
                    key={idx}
                    onClick={() => {
                      setHiddenCurvesPoints((prev) =>
                        prev.curves.includes(broomstickId)
                          ? {
                              ...prev,
                              curves: prev.curves.filter(
                                (e) => e !== broomstickId,
                              ),
                            }
                          : {
                              ...prev,
                              curves: uniq([...prev.curves, broomstickId]),
                            },
                      );
                    }}
                  >
                    <ColLine>
                      <LineLegend
                        backgroundColor={
                          activityLineColors[
                            line.activity as keyof typeof activityLineColors
                          ]
                        }
                      />
                      <Title
                        variant={
                          hiddenCurvesPoints.curves.find(
                            (e) => e === broomstickId,
                          )
                            ? "torque_drag_legend_off"
                            : "torque_drag_legend_on"
                        }
                        level={5}
                        $lineHeight="1.75em"
                        style={{
                          userSelect: "none",
                          pointerEvents: "none",
                        }}
                      >
                        {line.activity} {line.frictionFactor || ""}
                      </Title>
                    </ColLine>
                  </Row>
                ) : null;
              })}
              {torqueMeasurements.map(({ activity }, i) => (
                <Row
                  key={`${activity}-${i}`}
                  onClick={() => {
                    setHiddenCurvesPoints((prev) =>
                      prev.points.includes(activity)
                        ? {
                            ...prev,
                            points: prev.points.filter((e) => e !== activity),
                          }
                        : {
                            ...prev,
                            points: uniq([...prev.points, activity]),
                          },
                    );
                  }}
                >
                  <Col>
                    <Circle
                      backgroundColor={
                        activityCircleColors[
                          activity as keyof typeof activityCircleColors
                        ]
                      }
                      key={`${activity}-${i}`}
                    />
                    <Title
                      variant={
                        hiddenCurvesPoints.points.find((e) => e === activity)
                          ? "torque_drag_legend_off"
                          : "torque_drag_legend_on"
                      }
                      level={5}
                      $lineHeight="1.75em"
                      style={{
                        userSelect: "none",
                        pointerEvents: "none",
                      }}
                    >
                      {toNormalCase(activity)}
                    </Title>
                  </Col>
                </Row>
              ))}
            </LegendWrapper>
          </>
          {tooltipElement}
          {dlsTooltipElement}
        </GraphWrapper>
      ) : (
        <>
          <InfoContainer size={6} direction="vertical">
            <Title
              ellipsis={{ rows: 1 }}
              level={3}
              style={{ paddingBottom: 2 }}
              variant={isDark ? "caption" : "faded"}
            >
              {LENS_TITLE}
            </Title>
          </InfoContainer>
          <NoData />
        </>
      )}
    </>
  );
};

export default TorqueAndDrag;
