import { Title } from "atoms/Typography";
import {
  BOTTOM_AXIS_HEIGHT,
  LEFT_TITLE_WIDTH,
  VERTICAL_UNIT_HEIGHT,
} from "components/Lenses/ContainerLens/StickSlipByTime/utils/constants";
import { IconContainer } from "icons/styles";
import styled from "styled-components";

export const VerticalText = styled.div`
  writing-mode: vertical-rl;
  text-align: center;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VerticalTitle = styled(VerticalText)`
  width: ${LEFT_TITLE_WIDTH - 1}px;
  border-left: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
  background-color: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
  padding: 8px;
`;

export const VerticalUnit = styled(VerticalText)<{ height: number }>`
  height: ${({ height }) => height}px;
  width: ${VERTICAL_UNIT_HEIGHT}px;
`;

export const Unit = styled(Title).attrs({ level: 5, variant: "faded" })<{
  $isDetailed: boolean;
}>`
  margin: 0;
  font-size: ${({ $isDetailed }) => ($isDetailed ? "14px !important" : null)};
`;

export const TrackTitle = styled(Title).attrs({ level: 3 })<{
  $isDetailed: boolean;
}>`
  margin: 0;
  font-weight: 500 !important;
  font-size: ${({ $isDetailed }) => ($isDetailed ? "18px !important" : null)};
`;

export const CogContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.isDark
      ? theme.themeStyle.colors.quaterniary_bg
      : theme.themeStyle.colors.primary_accent};
  cursor: pointer;
  display: none;
  ${IconContainer} svg {
    color: ${({ theme }) => theme.themeStyle.colors.light_typography};
    font-size: 17px;
  }
`;

export const AxisContainer = styled.div<{ $axisWidth: number }>`
  height: 100%;
  position: relative;
  width: ${({ $axisWidth }) => $axisWidth + 1}px;
  display: flex;
  background-color: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
  border-right: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
  :hover {
    ${CogContainer} {
      display: flex;
    }
  }
`;

export const MainAxis = styled.div<{ $axisWidth: number }>`
  height: 100%;
  width: ${({ $axisWidth }) => $axisWidth - VERTICAL_UNIT_HEIGHT}px;
`;

export const UnitContainer = styled.div`
  height: 100%;
  width: ${VERTICAL_UNIT_HEIGHT}px;
`;

export const LeftContainer = styled.div<{ $leftColumnWidth: number }>`
  display: flex;
  height: 100%;
  width: ${({ $leftColumnWidth }) => $leftColumnWidth}px;
`;

export const RightContainer = styled.div<{ $leftColumnWidth: number }>`
  display: flex;
  height: 100%;
  width: calc(100% - ${({ $leftColumnWidth }) => $leftColumnWidth}px);
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
`;

export const ButtonsContainer = styled.div<{ $headerHeight: number }>`
  position: absolute;
  top: ${({ $headerHeight }) => $headerHeight}px;
  right: 0;
  padding-right: 16px;
  padding-top: 16px;
`;

export const TracksContainer = styled.div<{
  $headerHeight: number;
  $height?: number;
}>`
  height: ${({ $headerHeight, $height }) =>
    $height ? `${$height}px` : `calc(100% - ${$headerHeight}px)`};
`;

export const ScrollContainer = styled.div<{
  $headerHeight?: number;
  $isScrollable: boolean;
}>`
  height: ${({ $headerHeight }) => `calc(100% - ${$headerHeight}px)`};
  overflow: ${({ $isScrollable }) => ($isScrollable ? "auto" : "hidden")};
`;

export const BottomAxisContainer = styled.div<{ $isScrollable: boolean }>`
  height: ${BOTTOM_AXIS_HEIGHT}px;
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  position: ${({ $isScrollable }) => ($isScrollable ? "absolute" : "static")};
  bottom: ${BOTTOM_AXIS_HEIGHT + 1}px;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_chart_bg};
`;

export const TopRightTriangle = styled.div<{ $color: string }>`
  position: absolute;
  top: 0;
  right: -4px;
  transform: rotate(-135deg);

  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid ${({ $color }) => $color};
`;

export const NoDataContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 110px;
`;
