import { useIsFetching } from "@tanstack/react-query";
import { AxisLeft } from "@visx/axis";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { Bar, Line, LinePath } from "@visx/shape";
import type {
  InspectionTvdPointDto,
  StandKpiInspectionResultDto,
} from "apis/oag";
import type { LegendItem } from "components/Lenses/common/InspectionView";
import {
  Indicator,
  StyledTooltipContainer,
} from "components/Lenses/common/InspectionView/InspectionChart/style";
import { StyledSvg } from "components/Lenses/common/InspectionView/styles";
import type { CurveWithData } from "components/Lenses/common/InspectionView/utils";
import {
  BORDER_WIDTH,
  CHART_CONTAINER_X,
  GRAPH_CONTAINER_WIDTH_PX,
  GRAPH_HEIGHT_PX,
  GRAPH_PADDING,
  STATUS_BAR_HEIGHT,
  Y_AXIS_PADDING,
  Y_AXIS_WIDTH,
} from "components/Lenses/common/InspectionView/utils";
import { useZoom } from "components/Lenses/common/LensZoom/useZoom";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import type { ScaleLinear } from "d3";
import { curveMonotoneX } from "d3";
import { bisector, extent } from "d3-array";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  FALLBACK_NUM_TICKS_Y_AXIS,
  INSPECTION_VIEW_TICK_HEIGHT,
} from "utils/constants";
import { toLocalWellTime } from "utils/helper";
import { RequestUID } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";
type TData = {
  label: string;
  value: number;
  y: number;
  displayValue: string;
  isVisible?: boolean;
  color: string;
};
interface TooltipData {
  values: { time: number; data: TData[] };
  chartValues: {
    time: number;
    data: TData[];
  };
}

export function InspectionChart({
  curves,
  top,
  data,
  legendItems,
  timeValueScale,
}: Readonly<{
  curves: CurveWithData[];
  data?: StandKpiInspectionResultDto;
  top: number;
  legendItems: LegendItem[];
  timeValueScale: ScaleLinear<number, number, never>;
}>) {
  const svgRef = useRef(null);

  const [isPointerInsideChart, setIsPointerInsideChart] = useState(false);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const series = useMemo(() => data?.series ?? [], [data?.series]);

  const curveScales = useMemo(() => {
    return curves.map((curve) => {
      const [max = 0, min = 0] = extent([
        ...(curve?.axisSeries || []),
        ...(curve?.series || []),
      ]).reverse();
      return scaleLinear<number>({
        domain: [max, min],
        range: [GRAPH_PADDING, GRAPH_HEIGHT_PX - GRAPH_PADDING],
        nice: true,
        reverse: curve.invertedScale,
      });
    });
  }, [curves]);
  const isFetching = useIsFetching({
    queryKey: [
      {
        uid: RequestUID.wellInspectionDetails,
      },
    ],
  });

  const axisScales = useMemo(() => {
    return curves.map((curve) => {
      const [max = 0, min = 0] = extent([
        ...(curve?.axisSeries || []).map(
          (c) => +curve.valueTransformer.fromSI(c),
        ),
        ...(curve?.series || []).map((c) => +curve.valueTransformer.fromSI(c)),
      ]).reverse();
      return scaleLinear<number>({
        domain: [max, min],
        range: [GRAPH_PADDING, GRAPH_HEIGHT_PX - GRAPH_PADDING],
        reverse: curve.invertedScale,
      });
    });
  }, [curves]);
  const { selectionRectangleElement, pointerPosition, zoomEvents } = useZoom({
    xScale: timeValueScale,
    yScaleBrush: axisScales[0],
    series: series.map((point, index) => ({
      ...point,
      index: toLocalWellTime(point.at) ?? index,
    })),
    plotWidth: GRAPH_CONTAINER_WIDTH_PX,
    plotHeight: GRAPH_HEIGHT_PX,
    plotWidthOffset: Y_AXIS_WIDTH * 2,
  });

  const tooltipInfo: TooltipData | null = useMemo(() => {
    if (isFetching) return null;
    const x0 = timeValueScale.invert(pointerPosition ?? 0);

    const index = bisector<InspectionTvdPointDto, number>((p) =>
      toLocalWellTime(p.at),
    ).left(series, x0);

    if (index <= 0 || !series[index]?.at) {
      return null;
    }

    const p0 = series[index - 1];
    const p1 = series[index];
    const selectedPoint = p1?.at?.utc ? p1 : p0;

    const timeValue = toLocalWellTime(selectedPoint.at) ?? 0;
    const chartTimeValue = timeValueScale(timeValue);
    const data: TData[] = curves.map((curve, curveIndex) => ({
      label: curve.label,
      isVisible:
        legendItems.find((item) => item.id === curve.key)?.isVisible ?? true,
      color: curve.color,
      value: (curve.series || [])[index],
      y: curveScales[curveIndex]((curve.series || [])[index]),
      displayValue: curve.valueTransformer.display(
        (curve.series || [])[index],
        { unit: curve.valueTransformer.abbr },
      ),
    }));

    return {
      chartValues: {
        time: chartTimeValue,
        data,
      },
      values: {
        time: timeValue,
        data,
      },
    };
  }, [
    isFetching,
    timeValueScale,
    pointerPosition,
    series,
    curves,
    legendItems,
    curveScales,
  ]);

  const { showTooltip, hideTooltip, tooltipElement } =
    useChartTooltip<TooltipData>({
      containerRef: svgRef,
      renderContent: ({ tooltipData }) => {
        return tooltipData ? (
          <StyledTooltipContainer>
            {tooltipData.values.data.map(
              (item) =>
                item.isVisible && (
                  <span key={item.label + "-" + item.displayValue}>
                    <span>
                      <div>
                        <Indicator color={item.color} />
                        {item.label}
                      </div>
                      <div>{item.displayValue}</div>
                    </span>
                  </span>
                ),
            )}
            <hr />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>{dayjs(tooltipData?.values.time).format("MM/DD/YYYY")}</div>
              <div>{dayjs(tooltipData?.values.time).format("HH:mm:ss")}</div>
            </div>
          </StyledTooltipContainer>
        ) : null;
      },
    });

  useEffect(() => {
    if (
      isPointerInsideChart &&
      tooltipInfo?.chartValues.data.some((item) => item.isVisible)
    )
      showTooltip({
        tooltipLeft: tooltipInfo.chartValues.time + 2 * Y_AXIS_WIDTH || 0,
        tooltipTop: STATUS_BAR_HEIGHT,
        tooltipData: tooltipInfo,
      });
    else {
      hideTooltip();
    }
  }, [
    hideTooltip,
    isPointerInsideChart,
    pointerPosition,
    showTooltip,
    timeValueScale,
    tooltipInfo,
  ]);

  return (
    <StyledSvg
      width={CHART_CONTAINER_X}
      height={GRAPH_HEIGHT_PX}
      onMouseMove={() => setIsPointerInsideChart(true)}
      ref={svgRef}
      top={top}
      bordered
      {...zoomEvents}
    >
      <Bar
        x={2 * Y_AXIS_WIDTH - BORDER_WIDTH}
        y={0}
        width={CHART_CONTAINER_X - 4 * Y_AXIS_WIDTH + BORDER_WIDTH}
        height={GRAPH_HEIGHT_PX - BORDER_WIDTH}
        fill={themeColors.secondary_bg}
      />
      <Bar
        x={2 * Y_AXIS_WIDTH}
        y={GRAPH_HEIGHT_PX + BORDER_WIDTH}
        width={CHART_CONTAINER_X - 4 * Y_AXIS_WIDTH}
        height={BORDER_WIDTH}
        fill={themeColors.tertiary_bg}
      />
      {curves.map((curve, index) => {
        return (
          <AxisLeft
            key={curve.key}
            hideTicks
            hideAxisLine
            scale={axisScales[index]}
            left={
              Y_AXIS_WIDTH * index +
              Y_AXIS_PADDING +
              (index > 1 ? GRAPH_CONTAINER_WIDTH_PX : 0)
            }
            numTicks={FALLBACK_NUM_TICKS_Y_AXIS}
            tickLabelProps={() => ({
              fill: curve.color,
              textAnchor: "middle",
              transform: `translate(0, ${INSPECTION_VIEW_TICK_HEIGHT / 2})`,
              style: { userSelect: "none", fontSize: "12px" },
            })}
          />
        );
      })}
      <Group left={Y_AXIS_WIDTH * 2}>
        {curves.map((curve, index) =>
          legendItems.find((item) => item.id === curve.key)?.isVisible ? (
            <LinePath
              key={curve.key}
              curve={curveMonotoneX}
              data={curve?.series ?? []}
              strokeDasharray={curve.dashed ? "1 2" : ""}
              strokeWidth={0.7}
              y={(d) => curveScales[index](d) || 0}
              x={(d, i) => timeValueScale(toLocalWellTime(series[i].at)) || 0}
              stroke={curve.color}
            />
          ) : null,
        )}
      </Group>

      {tooltipInfo &&
      Number.isFinite(tooltipInfo.chartValues.time) &&
      isPointerInsideChart &&
      tooltipInfo.chartValues.data.some((item) => item.isVisible) ? (
        <Group cursor="pointer">
          <Line
            type="vertical"
            from={{
              x: tooltipInfo.chartValues.time + 2 * Y_AXIS_WIDTH,
              y: 0,
            }}
            to={{
              x: tooltipInfo.chartValues.time + 2 * Y_AXIS_WIDTH,
              y: GRAPH_HEIGHT_PX,
            }}
            strokeWidth={0.5}
            stroke={themeColors.primary_typography}
          />
          {tooltipInfo.chartValues.data.map((item) =>
            item.isVisible ? (
              <g key={item.label + item.displayValue}>
                <circle
                  cx={tooltipInfo.chartValues.time + 2 * Y_AXIS_WIDTH}
                  cy={item.y}
                  r={3}
                  fill={item.color}
                />
                <circle
                  cx={tooltipInfo.chartValues.time + 2 * Y_AXIS_WIDTH}
                  cy={item.y}
                  r={1}
                  fill={"white"}
                />
              </g>
            ) : null,
          )}
        </Group>
      ) : null}
      {selectionRectangleElement}
      {tooltipElement}
    </StyledSvg>
  );
}
