/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface ParameterHeatmapUserLensQueryDto
 */
export interface ParameterHeatmapUserLensQueryDto {
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    useMockData: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    sampleCount: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    comparisonWellIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    selectedRigIds?: Array<number> | null;
    /**
     * 
     * @type {FiltersDto}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    selectedFilters?: FiltersDto | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    zoomStartDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    zoomEndDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    zoomStartDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    zoomEndDepth?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    from?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    to?: DateDto | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    wellId: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    wellAggregationThreshold: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    maxLookAheadDepthFromFocal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    xBinCount: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensQueryDto
     */
    yBinCount: number;
}

/**
 * Check if a given object implements the ParameterHeatmapUserLensQueryDto interface.
 */
export function instanceOfParameterHeatmapUserLensQueryDto(value: object): value is ParameterHeatmapUserLensQueryDto {
    if (!('useMockData' in value) || value['useMockData'] === undefined) return false;
    if (!('sampleCount' in value) || value['sampleCount'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('wellAggregationThreshold' in value) || value['wellAggregationThreshold'] === undefined) return false;
    if (!('xBinCount' in value) || value['xBinCount'] === undefined) return false;
    if (!('yBinCount' in value) || value['yBinCount'] === undefined) return false;
    return true;
}

export function ParameterHeatmapUserLensQueryDtoFromJSON(json: any): ParameterHeatmapUserLensQueryDto {
    return ParameterHeatmapUserLensQueryDtoFromJSONTyped(json, false);
}

export function ParameterHeatmapUserLensQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterHeatmapUserLensQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'useMockData': json['UseMockData'],
        'sampleCount': json['SampleCount'],
        'comparisonWellIds': json['ComparisonWellIds'] == null ? undefined : json['ComparisonWellIds'],
        'selectedRigIds': json['SelectedRigIds'] == null ? undefined : json['SelectedRigIds'],
        'selectedFilters': json['SelectedFilters'] == null ? undefined : FiltersDtoFromJSON(json['SelectedFilters']),
        'zoomStartDuration': json['ZoomStartDuration'] == null ? undefined : json['ZoomStartDuration'],
        'zoomEndDuration': json['ZoomEndDuration'] == null ? undefined : json['ZoomEndDuration'],
        'zoomStartDepth': json['ZoomStartDepth'] == null ? undefined : json['ZoomStartDepth'],
        'zoomEndDepth': json['ZoomEndDepth'] == null ? undefined : json['ZoomEndDepth'],
        'from': json['From'] == null ? undefined : DateDtoFromJSON(json['From']),
        'to': json['To'] == null ? undefined : DateDtoFromJSON(json['To']),
        'wellId': json['WellId'],
        'wellAggregationThreshold': json['WellAggregationThreshold'],
        'maxLookAheadDepthFromFocal': json['MaxLookAheadDepthFromFocal'] == null ? undefined : json['MaxLookAheadDepthFromFocal'],
        'xBinCount': json['XBinCount'],
        'yBinCount': json['YBinCount'],
    };
}

export function ParameterHeatmapUserLensQueryDtoToJSON(value?: ParameterHeatmapUserLensQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'UseMockData': value['useMockData'],
        'SampleCount': value['sampleCount'],
        'ComparisonWellIds': value['comparisonWellIds'],
        'SelectedRigIds': value['selectedRigIds'],
        'SelectedFilters': FiltersDtoToJSON(value['selectedFilters']),
        'ZoomStartDuration': value['zoomStartDuration'],
        'ZoomEndDuration': value['zoomEndDuration'],
        'ZoomStartDepth': value['zoomStartDepth'],
        'ZoomEndDepth': value['zoomEndDepth'],
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
        'WellId': value['wellId'],
        'WellAggregationThreshold': value['wellAggregationThreshold'],
        'MaxLookAheadDepthFromFocal': value['maxLookAheadDepthFromFocal'],
        'XBinCount': value['xBinCount'],
        'YBinCount': value['yBinCount'],
    };
}

