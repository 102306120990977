import type {
  DisplayOption,
  KpiGroupUserLensDto,
  KpiTypeUserLensDto,
  ParameterByDepthUserLensDto,
  TorqueAndDragUserLensDto,
  WedgeUserLensDto,
} from "apis/oag";
import type { IAction, IFiltersType, IZoomData } from "reducers/types";
import { initialFilters, IZoomType } from "reducers/types";
import colors from "utils/colors";

// TODO SHOULD BE CLEANED UP

export type TimelineStates = "Actual" | "Plan" | "Information" | "Legend";
export type AddIconStates =
  | "Actual"
  | "Plan"
  | "OffsetWell"
  | "Timeline"
  | "None";

export interface IReportState {
  offsetWells: Array<number>;
  filters: IFiltersType;
  focalColor: string;
  zoom: IZoomData;
  selectedWell: number | null;
  zoomSelection: boolean;
  filters_state: boolean;
  zoom_state: boolean;
  selectedLenses: Array<Partial<IItem>> | null;
}

enum TimeVsDepthDisplayOptions {
  Actual = "Actual",
  Plan = "Plan",
  "Bit Depth" = "Bit Depth",
}

export interface IItem
  extends KpiGroupUserLensDto,
    KpiTypeUserLensDto,
    WedgeUserLensDto,
    ParameterByDepthUserLensDto,
    TorqueAndDragUserLensDto {
  selected: boolean;
  name?: string;
  type?: string;
  displayOptions?: DisplayOption | string[] | TimeVsDepthDisplayOptions[];
  indicators?: Array<string>;
  lensInfo?: {
    name?: string;
    type?: string;
    displayOptions?: DisplayOption | string[] | TimeVsDepthDisplayOptions[];
    visualAids?: boolean;
  };
}

export const initialState: IReportState = {
  offsetWells: [],
  filters: initialFilters,
  selectedWell: null,
  focalColor: colors.well_color,
  zoom: {
    date_start: null,
    date_start_well_offset: null,
    date_end: null,
    date_end_well_offset: null,
    ts_start: 0,
    ts_end: null,
    depth_start: 0,
    depth_end: null,
    internal_zoom: false,
    type: IZoomType.TIME,
  },

  selectedLenses: null,
  zoomSelection: false,
  filters_state: false,
  zoom_state: false,
};
interface ISetZoomSelection extends IAction {
  type: "SET_REPORT_ZOOM_SELECTION";
  payload: boolean;
}

interface ISetFilterState extends IAction {
  type: "SET_REPORT_FILTER_STATE";
  payload: boolean;
}
interface ISetSelectedWell extends IAction {
  type: "SET_REPORT_SELECTED_WELL";
  payload: IReportState["selectedWell"];
}
interface ISetOffsetWellsAction extends IAction {
  type: "SET_REPORT_OFFSET_WELLS";
  payload: {
    wells: IReportState["offsetWells"];
  };
}

interface IFocalWellColor extends IAction {
  type: "SET_REPORT_FOCALWELL_COLOR";
  payload: {
    color: IReportState["focalColor"];
  };
}

interface ISetZoomAction extends IAction {
  type: "SET_REPORT_ZOOM";
  payload: {
    zoom: IReportState["zoom"];
    state: IReportState["zoom_state"];
  };
}

interface ISetReportSelectedLenses extends IAction {
  type: "SET_REPORT_SELECTED_LENSES";
  payload: IReportState["selectedLenses"];
}

interface ISetReportFilters extends IAction {
  type: "SET_REPORT_FILTERS";
  payload: {
    filters: IReportState["filters"];
    state: boolean;
  };
}

interface ISetFiltersWidget extends IAction {
  type: "SET_FILTERS_WIDGET";
  payload: {
    filters: IReportState["filters"];
    state: boolean;
  };
}

interface IResetZoom extends IAction {
  type: "RESET_REPORT_ZOOM";
  payload?: null;
}
type AvailableActions =
  | ISetFilterState
  | ISetZoomSelection
  | ISetSelectedWell
  | ISetOffsetWellsAction
  | IFocalWellColor
  | ISetZoomAction
  | IResetZoom
  | ISetFiltersWidget
  | ISetReportFilters
  | ISetReportSelectedLenses;

function reportReducer(
  state: IReportState = initialState,
  action: AvailableActions,
): IReportState {
  switch (action.type) {
    case "SET_REPORT_ZOOM_SELECTION":
      return {
        ...state,
      };
    case "SET_REPORT_SELECTED_WELL":
      return {
        ...state,
        selectedWell: action.payload,
      };
    case "SET_REPORT_OFFSET_WELLS":
      return {
        ...state,
        offsetWells: action.payload.wells || [],
      };
    case "SET_REPORT_FOCALWELL_COLOR":
      return {
        ...state,
        focalColor: action.payload.color,
      };
    case "SET_REPORT_ZOOM":
      return {
        ...state,
        zoom: action.payload.zoom,
        zoom_state: action.payload.state,
      };
    case "RESET_REPORT_ZOOM":
      return {
        ...state,
        zoom: initialState.zoom,
        zoom_state: false,
      };
    case "SET_REPORT_FILTERS":
      return {
        ...state,
        filters: action.payload.filters,
        filters_state: action.payload.state,
      };
    case "SET_REPORT_SELECTED_LENSES":
      return {
        ...state,
        selectedLenses: action.payload,
      };
    default:
      return state;
  }
}

export default reportReducer;
