/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TvdSectionDto } from './TvdSectionDto';
import {
    TvdSectionDtoFromJSON,
    TvdSectionDtoFromJSONTyped,
    TvdSectionDtoToJSON,
} from './TvdSectionDto';
import type { PlanTvdPointDto } from './PlanTvdPointDto';
import {
    PlanTvdPointDtoFromJSON,
    PlanTvdPointDtoFromJSONTyped,
    PlanTvdPointDtoToJSON,
} from './PlanTvdPointDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface PlanTvdSeriesDto
 */
export interface PlanTvdSeriesDto {
    /**
     * 
     * @type {number}
     * @memberof PlanTvdSeriesDto
     */
    wellId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof PlanTvdSeriesDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof PlanTvdSeriesDto
     */
    readonly dataState: ResultDataState;
    /**
     * 
     * @type {Array<PlanTvdPointDto>}
     * @memberof PlanTvdSeriesDto
     */
    series?: Array<PlanTvdPointDto> | null;
    /**
     * 
     * @type {Array<TvdSectionDto>}
     * @memberof PlanTvdSeriesDto
     */
    sectionIntervals?: Array<TvdSectionDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PlanTvdSeriesDto
     */
    planId: number;
}



/**
 * Check if a given object implements the PlanTvdSeriesDto interface.
 */
export function instanceOfPlanTvdSeriesDto(value: object): value is PlanTvdSeriesDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    if (!('planId' in value) || value['planId'] === undefined) return false;
    return true;
}

export function PlanTvdSeriesDtoFromJSON(json: any): PlanTvdSeriesDto {
    return PlanTvdSeriesDtoFromJSONTyped(json, false);
}

export function PlanTvdSeriesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanTvdSeriesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'series': json['Series'] == null ? undefined : ((json['Series'] as Array<any>).map(PlanTvdPointDtoFromJSON)),
        'sectionIntervals': json['SectionIntervals'] == null ? undefined : ((json['SectionIntervals'] as Array<any>).map(TvdSectionDtoFromJSON)),
        'planId': json['PlanId'],
    };
}

export function PlanTvdSeriesDtoToJSON(value?: Omit<PlanTvdSeriesDto, 'DataState'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'Series': value['series'] == null ? undefined : ((value['series'] as Array<any>).map(PlanTvdPointDtoToJSON)),
        'SectionIntervals': value['sectionIntervals'] == null ? undefined : ((value['sectionIntervals'] as Array<any>).map(TvdSectionDtoToJSON)),
        'PlanId': value['planId'],
    };
}

