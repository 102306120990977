import { useIntelFilterActions } from "hooks/filters/useIntelFilterActions";
import { useLayoutEffect } from "react";

export const ResetFiltersBeforeNavigate: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { resetFiltersToInitialState } = useIntelFilterActions();

  useLayoutEffect(() => {
    resetFiltersToInitialState();
  }, [resetFiltersToInitialState]);

  return children;
};
