import React from "react";
import styled from "styled-components";
import { RowContext } from "utils/componentLibrary/Row";

export interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
  flex?: number | string;
  span?: number;
}

export const PdCol = styled.div<{
  $flex?: number | string;
  $span?: number;
  $horizontalGutter?: number;
  $verticalGutter?: number;
  $wrap?: boolean;
}>`
  position: relative;
  max-width: 100%;
  min-height: 1px;
  flex: ${({ $flex }) => $flex};

  padding-left: ${({ $horizontalGutter }) =>
    $horizontalGutter ? `${$horizontalGutter / 2}px` : null};
  padding-right: ${({ $horizontalGutter }) =>
    $horizontalGutter ? `${$horizontalGutter / 2}px` : null};
  min-width: ${({ $wrap }) => ($wrap ? 0 : null)};

  ${({ $span }) =>
    $span
      ? `
        display: block;
        flex: 0 0 ${($span / 24) * 100}%;
        max-width: ${($span / 24) * 100}%;
      `
      : ``}
`;

export const Col = React.forwardRef<HTMLDivElement, ColProps>(
  ({ flex, span, children, ...others }, ref) => {
    const { gutter, wrap } = React.useContext(RowContext);

    return (
      <PdCol
        ref={ref}
        $flex={flex}
        $span={span}
        $horizontalGutter={gutter?.[0] ?? 0}
        $verticalGutter={gutter?.[1] ?? 0}
        $wrap={wrap}
        {...others}
      >
        {children}
      </PdCol>
    );
  },
);

Col.displayName = "Col";
