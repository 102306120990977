import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import {
  CHART_CONTAINER_X,
  CONTROLS_CONTAINER_WIDTH,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from "components/Lenses/ContainerLens/TorqueAndDrag/utils";
import styled from "styled-components";
import colors from "utils/colors";
import { Col, Row } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

export const StyledDistanceDuration = styled.div`
  margin-top: 12px;
`;

export const StyledDetailsCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 163px;
  flex-wrap: nowrap;
  border-right: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
  padding: 16px 24px;
`;

export const StyledChartCol = styled(Col)`
  width: ${CHART_CONTAINER_X}px;
`;

export const StyledBottomAxisDiv = styled.div`
  display: flex;
  width: 100%;
  height: 36px;
  border-top: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_chart_accent};
`;

export const StyledRightContainerDiv = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 36px);
`;

export const StyledFirstYAxisDiv = styled.div`
  width: ${Y_AXIS_WIDTH}px;
  top: 0;
  height: calc(100% - ${X_AXIS_HEIGHT}px);
  border-right: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_chart_accent};
  position: absolute;
`;

export const StyledSecondYAxisDiv = styled.div`
  width: ${Y_AXIS_WIDTH}px;
  top: 0;
  left: ${Y_AXIS_WIDTH}px;
  height: calc(100% - ${X_AXIS_HEIGHT}px);
  border-right: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_chart_accent};
  position: absolute;
`;

export const StyledXAxisLabelContainer = styled.div`
  width: ${2 * Y_AXIS_WIDTH}px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const StyledRow = styled(Row)`
  height: 100%;
  width: 100%;
`;

export const StyledSvg = styled.svg`
  overflow: visible;
  user-select: none;
  z-index: ${zIndexLayer.elevated};
`;

export const StyledTitle = styled(Title)`
  user-select: none;
  pointer-events: none;
`;

export const StyledChartBackground = styled.div`
  background: ${(theme) =>
    theme.theme.isDark ? "transparent" : colors.default_bg};
  position: absolute;
  left: ${2 * Y_AXIS_WIDTH}px;
  width: ${CHART_CONTAINER_X - 2 * Y_AXIS_WIDTH}px;
  height: calc(100% - ${X_AXIS_HEIGHT}px);
  z-index: ${zIndexLayer.base};
`;

export const StyledControlsContainer = styled.div<{
  top: number;
  left: number;
}>`
  position: absolute;
  top: ${({ top }) => top + 12}px;
  left: ${({ left }) => left - CONTROLS_CONTAINER_WIDTH}px;
`;

export const StyledControlsButton = styled(Button)`
  margin-left: 2px;

  &:not([disabled]) {
    background-color: ${({ theme }) =>
      theme.isDark
        ? theme.themeStyle.colors.secondary_bg
        : theme.themeStyle.colors.off_secondary_bg} !important;
  }

  &[disabled] {
    background-color: ${({ theme }) =>
      theme.isDark
        ? theme.themeStyle.colors.off_secondary_bg
        : theme.themeStyle.colors.secondary_bg}!important;
  }
`;
