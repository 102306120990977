/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IntelScorecardFactDto } from './IntelScorecardFactDto';
import {
    IntelScorecardFactDtoFromJSON,
    IntelScorecardFactDtoFromJSONTyped,
    IntelScorecardFactDtoToJSON,
} from './IntelScorecardFactDto';

/**
 * 
 * @export
 * @interface IntelScorecardFactSetDto
 */
export interface IntelScorecardFactSetDto {
    /**
     * 
     * @type {Array<IntelScorecardFactDto>}
     * @memberof IntelScorecardFactSetDto
     */
    facts: Array<IntelScorecardFactDto>;
}

/**
 * Check if a given object implements the IntelScorecardFactSetDto interface.
 */
export function instanceOfIntelScorecardFactSetDto(value: object): value is IntelScorecardFactSetDto {
    if (!('facts' in value) || value['facts'] === undefined) return false;
    return true;
}

export function IntelScorecardFactSetDtoFromJSON(json: any): IntelScorecardFactSetDto {
    return IntelScorecardFactSetDtoFromJSONTyped(json, false);
}

export function IntelScorecardFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntelScorecardFactSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'facts': ((json['Facts'] as Array<any>).map(IntelScorecardFactDtoFromJSON)),
    };
}

export function IntelScorecardFactSetDtoToJSON(value?: IntelScorecardFactSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Facts': ((value['facts'] as Array<any>).map(IntelScorecardFactDtoToJSON)),
    };
}

