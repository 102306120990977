import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { useZoomModeLabel } from "components/WellDashboard/ControlHeader/atoms/Zoom/utils";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import type { IZoomData } from "reducers/types";
import styled from "styled-components";
import { zIndexLayer } from "utils/zIndex";

interface IZoomButtonParams {
  onClick: () => void;
  disabled: boolean;
  isActive: boolean;
}

const StyledButton = styled(Button)`
  z-index: ${zIndexLayer.sky_plus};
  outline: none;
  &:disabled {
    color: ${(props) =>
      props.theme.themeStyle.colors.primary_typography} !important;
    background-color: transparent !important;
    border-color: ${(props) =>
      props.theme.themeStyle.colors.primary_button_bg} !important;
  }
`;

export const ZoomButton = ({
  onClick,
  disabled = false,
  isActive = false,
}: IZoomButtonParams) => {
  const [zoomData] = useStateQuery<IZoomData>(
    URL_STATE_PARAM.ZOOM_WIDGET,
    initialZoomData,
  );
  const zoomModelLabel = useZoomModeLabel(zoomData);

  return (
    <StyledButton
      size="large"
      icon={<PDComponent.SvgIcon name="zoomIn" />}
      onClick={onClick}
      disabled={disabled}
      type={isActive ? "primary" : "default"}
      ghost={isActive}
    >
      {zoomModelLabel?.label}
    </StyledButton>
  );
};
