import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Bar, BarRounded, BarStack } from "@visx/shape";
import type { KpiTypeUserLensDto, StandKpiSliceDto } from "apis/oag";
import { StackingType, VisualAidType } from "apis/oag";
import {
  AverageLabel,
  AverageLine,
} from "components/Lenses/common/AverageLine";
import { Chart } from "components/Lenses/common/Chart";
import { Label } from "components/Lenses/common/Label";
import { MedianLabel, MedianLine } from "components/Lenses/common/MedianLine";
import { StyledOperationCountContainer } from "components/Lenses/common/OperationCount";
import OperationCount from "components/Lenses/common/OperationCount";
import type { ScrollbarRange } from "components/Lenses/common/Scrollbar";
import { Scrollbar } from "components/Lenses/common/Scrollbar";
import TargetLine from "components/Lenses/common/TargetLine";
import {
  TooltipDayBorder,
  TooltipDayGrid,
  TooltipGapContainer,
} from "components/Lenses/common/Tooltip";
import {
  TooltipGroup,
  TooltipHighlightValue,
} from "components/Lenses/common/Tooltip";
import {
  TooltipVisualAidInfo,
  useChartTooltip,
} from "components/Lenses/common/useChartTooltip";
import { useOutlierThreshold } from "components/Lenses/common/useOutlierThreshold";
import {
  DETAILED_ZOOM_SCALER,
  MINI_ZOOM_SCALER,
} from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleStandViewChart";
import type { StackedKpiChartSimpleProps } from "components/Lenses/ContainerLens/StackedKpi/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { extent } from "d3-array";
import { useTargetSegments } from "hooks/charting/useTargetSegments";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { roundNumberToDecimal } from "pages/Lens/LensSummaryView";
import { shade, tint, transparentize } from "polished";
import React, { useCallback, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { OPERATION_COUNT_HEIGHT } from "utils/constants";
import { useUOM, useUOMbyLens, UtilDimensions } from "utils/format";
import { formatDate } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";
const pairKeys = ["day", "night"];

export type PairItem = {
  totalValue: number;
  operationCount?: number;
  isOutlier?: boolean;
  target?: number | null;
  values: Record<number, number>;
  average: Record<number, number>;
  type: "day" | "night";
};

export type TransformedDataItem = {
  index: number;
  date: Date;
  day: PairItem;
  night: PairItem;
};

export const StackedDayViewChart = ({
  detailed,
  data,
  focalWellColor = "",
  dimension,
  lens,
  templateType,
}: StackedKpiChartSimpleProps) => {
  const { data: kpiTypes } = useKpiTypes();
  const valueUOM = useUOMbyLens(dimension, lens);
  const percentageUOM = useUOM(UtilDimensions.Percentage);
  const outliersSettingsEnabled = lens?.showsOutliers;
  const isManual = !!lens?.isManualYaxis;
  const outliersEnabled = outliersSettingsEnabled || isManual;
  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: containerRef,
  } = useResizeDetector();
  const { chartWidth, chartHeight: containerHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };
  const chartHeight = getSVGNormalizedValue(
    containerHeight - (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0),
  );

  const plotHeight = getSVGNormalizedValue(chartHeight - (detailed ? 136 : 36));
  const plotWidth = getSVGNormalizedValue(
    chartWidth - (detailed ? 76 : 44) - 10,
  );
  const scrollbarPadding = detailed ? 28 : 4;
  const scrollbarWidth = getSVGNormalizedValue(
    plotWidth - scrollbarPadding * 2,
  );
  const getData = useCallback(
    (d: StandKpiSliceDto) => {
      if (!d) return 0;
      if (lens.stackingType === StackingType.Distribution)
        return d.distribution;
      return d.average;
    },
    [lens.stackingType],
  );

  const kpiKeys = data.summaryByKpi
    ?.slice()
    .sort((a, b) => a.position - b.position)
    // TODO is this correct?
    .map((item) => (item as unknown as KpiTypeUserLensDto).kpiTypeId);

  const transformedData = useMemo(() => {
    if (!data.detailsByDateShift) {
      return [];
    }

    return data.detailsByDateShift.map<TransformedDataItem>((detail, index) => {
      const daySliceMap =
        detail.daySlices?.reduce(
          (acc, slice) => {
            acc[slice.kpiTypeId] = getData(slice);
            return acc;
          },
          {} as Record<number, number>,
        ) ?? {};
      const nightSliceMap =
        detail.nightSlices?.reduce(
          (acc, slice) => {
            acc[slice.kpiTypeId] = getData(slice);
            return acc;
          },
          {} as Record<number, number>,
        ) ?? {};

      const daySliceMapAverage =
        detail.daySlices?.reduce(
          (acc, slice) => {
            acc[slice.kpiTypeId] = slice.average;
            return acc;
          },
          {} as Record<number, number>,
        ) ?? {};
      const nightSliceMapAverage =
        detail.nightSlices?.reduce(
          (acc, slice) => {
            acc[slice.kpiTypeId] = slice.average;
            return acc;
          },
          {} as Record<number, number>,
        ) ?? {};

      const totalDayValue = Object.keys(daySliceMap)
        .map((e) => daySliceMap[+e])
        .reduce((acc, item) => acc + item, 0);
      const totalNightValue = Object.keys(nightSliceMap)
        .map((e) => nightSliceMap[+e])
        .reduce((acc, item) => acc + item, 0);

      return {
        index,
        date: detail.date,
        day: {
          totalValue: totalDayValue,
          target: detail.dayTargetValue,
          isOutlier: detail.isOutlier,
          operationCount: detail.dayOperationCount,
          values: daySliceMap,
          average: daySliceMapAverage,
          type: "day",
        },
        night: {
          totalValue: totalNightValue,
          target: detail.nightTargetValue,
          isOutlier: detail.isOutlier,
          values: nightSliceMap,
          average: nightSliceMapAverage,
          operationCount: detail.nightOperationCount,
          type: "night",
        },
      };
    });
  }, [data.detailsByDateShift, getData]);

  const totalValues = useMemo(
    () =>
      transformedData.flatMap((item) => [
        item.day?.totalValue ?? 0,
        item.night?.totalValue ?? 0,
      ]),
    [transformedData],
  );

  // A quarter of the chart by default
  const zoomRate = useMemo(() => {
    return lens?.squeezesDisplay
      ? 1
      : Math.ceil(
          transformedData.length /
            (detailed ? DETAILED_ZOOM_SCALER : MINI_ZOOM_SCALER),
        );
  }, [detailed, lens?.squeezesDisplay, transformedData.length]);

  const { outlierThreshold, gradientDefinition, gradientFill } =
    useOutlierThreshold({
      values: totalValues,
      enabled: outliersEnabled,
    });

  const [scrollbarRange, setScrollbarRange] = useState<ScrollbarRange>({
    startX: 1 - 1 / zoomRate,
    endX: 1,
  });

  const dayScale = useMemo(() => {
    const chartSize = plotWidth * zoomRate;

    const startX = chartSize * scrollbarRange.startX;

    return scaleBand<Date>({
      domain: transformedData.map((i) => i.date),
      // Offsetting the pixel range based on zoom, provides scrolling and zoom at the same time
      range: [-startX, chartSize - startX],
      paddingInner: 0.3,
      paddingOuter: 1,
    });
  }, [plotWidth, scrollbarRange.startX, transformedData, zoomRate]);

  const pairScale = useMemo(() => {
    return scaleBand({
      domain: pairKeys,
      paddingInner: 0.15,
      range: [0, dayScale.bandwidth()],
    });
  }, [dayScale]);

  const computedTargetSegments = useTargetSegments(
    transformedData.map((data) => {
      return {
        id: data.date,
        list: [
          {
            id: pairKeys[0],
            targetValue: data.day.target,
          },
          {
            id: pairKeys[1],
            targetValue: data.night.target,
          },
        ],
      };
    }),
    dayScale,
    pairScale,
  );

  // update if squeeze changed
  React.useEffect(() => {
    setScrollbarRange({ startX: 1 - 1 / zoomRate, endX: 1 });
  }, [zoomRate]);

  const valueScale = useMemo(() => {
    let minMaxDomain = [0, 1];
    if (lens.stackingType !== StackingType.Distribution) {
      const [min = 0, max = 0] = extent([
        0,
        ...totalValues,
        lens?.selectedVisualAids.includes(VisualAidType.Median)
          ? data?.summary?.median ?? 0
          : 0,
        lens?.selectedVisualAids.includes(VisualAidType.Average)
          ? data?.summary?.average ?? 0
          : 0,
        outlierThreshold ?? 0,
        data?.summary?.average ?? 0,
      ]);
      minMaxDomain = lens?.isManualYaxis
        ? [lens?.yaxisStart || 0, lens?.yaxisEnd || 0]
        : [min, max];
    }

    return scaleLinear<number>({
      domain: minMaxDomain,
      range: [plotHeight, 0],
      nice: false,
      clamp: true,
    });
  }, [data, lens, outlierThreshold, plotHeight, totalValues]);

  // A separate scale for scrollbar
  // Maps scrollbar pixel position to a [0, 1] range
  const scrollbarScale = useMemo(
    () => scaleLinear<number>({ domain: [0, 1], range: [0, scrollbarWidth] }),
    [scrollbarWidth],
  );

  const {
    isDark,
    themeStyle: { colors: themeColors, outliers: outliersTheme },
  } = useCustomTheme();

  const { showTooltip, hideTooltip, tooltipElement } =
    useChartTooltip<TransformedDataItem>({
      containerRef,
      renderContent: ({ tooltipData }) => {
        const [day, night] = (["day", "night"] as const).map((timeKey) => (
          <TooltipGapContainer key={timeKey}>
            <TooltipHighlightValue>
              {lens.stackingType === StackingType.Values
                ? valueUOM.display(tooltipData?.[timeKey].totalValue)
                : (kpiKeys || [])
                    .slice()
                    .reverse()
                    .map((key) =>
                      kpiTypes?.byId[key] ? (
                        <div key={key.toString()}>
                          {kpiTypes.byId[key].name}:{" "}
                          {roundNumberToDecimal(
                            (tooltipData?.[timeKey].values[key] ?? 0) * 100,
                            2,
                          ) + "%"}
                        </div>
                      ) : null,
                    )}
            </TooltipHighlightValue>
            <TooltipGroup>
              {(kpiKeys || [])
                .slice()
                .reverse()
                .map((key) =>
                  kpiTypes?.byId[key] ? (
                    <div key={key.toString()}>
                      {kpiTypes.byId[key].name}:{" "}
                      {valueUOM.display(
                        tooltipData?.[timeKey].average[key] ?? 0,
                      )}
                    </div>
                  ) : null,
                )}
            </TooltipGroup>

            <TooltipVisualAidInfo
              selectedVisualAids={lens?.selectedVisualAids}
              display={valueUOM.display}
              targetValue={tooltipData?.[timeKey].target}
              averageValue={data?.summary?.average}
              median={data?.summary?.median}
            />

            {lens.showOperationCount ? (
              <TooltipHighlightValue>
                Operation count: {tooltipData?.[timeKey]?.operationCount}
              </TooltipHighlightValue>
            ) : null}
            {tooltipData?.date ? (
              <div>{formatDate(tooltipData.date)}</div>
            ) : null}
            <div>{timeKey === "day" ? "Day" : "Night"}</div>
          </TooltipGapContainer>
        ));

        return (
          <TooltipDayGrid>
            {day}
            <TooltipDayBorder />
            {night}
          </TooltipDayGrid>
        );
      },
    });

  // const dayGapWidth = dayScale.paddingInner() * dayScale.step();
  const dayColumnWidth = dayScale.bandwidth();

  const handleMouseOver = useCallback(
    (barData: TransformedDataItem) => {
      showTooltip({
        tooltipLeft: (dayScale(barData.date) || 0) + dayColumnWidth / 2,
        tooltipTop: valueScale(
          Math.min(
            Math.max(
              barData.day.totalValue ?? 0,
              barData.night.totalValue ?? 0,
            ),
            valueScale.domain()[1],
          ),
        ),
        tooltipData: barData,
      });
    },
    [dayColumnWidth, dayScale, showTooltip, valueScale],
  );

  // Starting initialization only with div to calculate plot width & height
  if (Number.isNaN(plotWidth) || Number.isNaN(plotHeight)) {
    return (
      <div
        ref={containerRef}
        style={{
          padding: 0,
          width: "100%",
          height: "100%",
          position: "relative",
          overflow: "visible",
        }}
      />
    );
  }

  return (
    <div
      ref={containerRef}
      style={{
        padding: 0,
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "visible",
      }}
    >
      <StyledOperationCountContainer visible={lens.showOperationCount} />
      <svg
        width={chartWidth}
        height={chartHeight}
        style={{ overflow: "visible", userSelect: "none" }}
      >
        {gradientDefinition}
        <Chart
          detailed={detailed}
          isManual={lens?.isManualYaxis}
          maxBottomTickWidth={80}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          plotWidth={plotWidth}
          plotHeight={plotHeight}
          valueScale={valueScale}
          showOperationCount={lens.showOperationCount}
          startX={plotWidth * zoomRate * scrollbarRange.startX}
          categoryScale={dayScale}
          valueUOM={
            lens.stackingType === StackingType.Distribution
              ? percentageUOM
              : valueUOM
          }
          rightTickFormat={
            lens.stackingType === StackingType.Distribution
              ? (value) => (value as string) + "%"
              : undefined
          }
          tickFormat={(value) =>
            formatDate(value as Date, { formatStr: "MM-DD-YY", useUTC: true })
          }
        >
          {transformedData.map((item) => (
            <Group key={item.date.toDateString()} left={dayScale(item.date)}>
              <BarStack<PairItem, number>
                x={(d) => d.type}
                keys={kpiKeys}
                data={[item.day, item.night]}
                value={(d, key) => d.values[key] ?? 0}
                xScale={pairScale}
                yScale={valueScale}
                color={() => focalWellColor}
              >
                {(barStacks) => [
                  barStacks.map((barStack, index) => (
                    <React.Fragment key={`bar-stack-${barStack.index}`}>
                      {barStack.bars.map((bar) => {
                        if (bar.bar.some((e) => Number.isNaN(e))) return null;
                        const bars = Object.values(
                          bar?.bar?.data?.values ?? [],
                        ).filter((x) => x);
                        const isLast = barStack.index === bars.length - 1;
                        const maxValue = valueScale.domain()[1];
                        const threshold =
                          isManual || outlierThreshold === null
                            ? maxValue
                            : outlierThreshold;
                        const thresholdValue = valueScale(maxValue);

                        if (
                          bar.x < -dayColumnWidth * 5 ||
                          bar.x > plotWidth + dayColumnWidth * 5
                        ) {
                          return null;
                        }

                        const showGradient =
                          isLast && bar.bar.data.totalValue > threshold;

                        let barY = bar.y;
                        let barHeight = bar.height;

                        if (showGradient) {
                          barHeight -= thresholdValue - barY;
                          barY = thresholdValue;
                        }

                        return (
                          <Group
                            key={`bar-stack-${barStack.index}-${bar.index}`}
                          >
                            {barStack.index === barStacks.length - 1 && (
                              <>
                                <Label
                                  barX={bar.x}
                                  barY={bar.y + (detailed ? 0 : 10)}
                                  topLabel={bar.y !== plotHeight}
                                  columnWidth={bar.width}
                                  barHeight={barHeight}
                                  detailed={detailed}
                                  index={index}
                                  value={
                                    lens.stackingType ===
                                    StackingType.Distribution
                                      ? ""
                                      : valueUOM.display(
                                          bar.bar.data.totalValue,
                                          { unit: "" },
                                        )
                                  }
                                  label={lens?.label}
                                />

                                {lens.showOperationCount ? (
                                  <OperationCount
                                    x={bar.x}
                                    width={bar.width}
                                    index={index}
                                    detailed={detailed}
                                    value={bar.bar.data.operationCount}
                                  />
                                ) : null}
                              </>
                            )}
                            <BarRounded
                              x={bar.x}
                              y={barY}
                              top={
                                isLast
                                  ? lens.stackingType !==
                                    StackingType.Distribution
                                  : undefined
                              }
                              radius={2}
                              height={barHeight}
                              width={bar.width}
                              filter={(() => {
                                if (
                                  templateType === "Rotate vs Slide" &&
                                  bar.bar.data.type !== "day"
                                )
                                  return `grayscale(${barStack.index * 50}%)`;
                                return "";
                              })()}
                              fill={(() => {
                                if (templateType === "Rotate vs Slide")
                                  return bar.bar.data.type === "day"
                                    ? tint(0.5 * barStack.index, bar.color)
                                    : shade(
                                        0.5 *
                                          (barStacks.length -
                                            barStack.index -
                                            1),
                                        bar.color,
                                      );

                                if (bar.bar.data?.isOutlier)
                                  return isDark
                                    ? `${transparentize(
                                        0.9,
                                        shade(
                                          0.3 *
                                            (barStacks.length -
                                              barStack.index -
                                              1),
                                          outliersTheme.bars,
                                        ),
                                      )}`
                                    : `${tint(0.3 * barStack.index, outliersTheme.bars_stacked_transparent)}`;
                                return bar.bar.data.type === "day"
                                  ? tint(0.3 * barStack.index, bar.color)
                                  : shade(
                                      0.3 *
                                        (barStacks.length - barStack.index - 1),
                                      bar.color,
                                    );
                              })()}
                            />

                            {bar.bar.data?.isOutlier &&
                            barStack.index === barStacks.length - 1 ? (
                              <Bar
                                x={bar.x - 1}
                                y={barY - 1}
                                width={bar.width + 2}
                                height={detailed ? 50 : 25}
                                fill={gradientFill}
                              />
                            ) : null}
                            {showGradient ? (
                              <Bar
                                x={bar.x - 1}
                                y={barY - 1}
                                width={bar.width + 2}
                                height={detailed ? 50 : 25}
                                fill={gradientFill}
                              />
                            ) : null}
                          </Group>
                        );
                      })}
                    </React.Fragment>
                  )),
                  barStacks.map((barStack) => (
                    <>
                      {barStack.bars.map((bar, index) => {
                        if (bar.bar.some((e) => Number.isNaN(e))) return null;
                        const isLast = barStack.index === barStacks.length - 1;
                        const maxValue = valueScale.domain()[1];
                        const threshold =
                          isManual || outlierThreshold === null
                            ? maxValue
                            : outlierThreshold;
                        const thresholdValue = valueScale(maxValue);

                        if (
                          bar.x < -dayColumnWidth * 5 ||
                          bar.x > plotWidth + dayColumnWidth * 5
                        ) {
                          return null;
                        }

                        const showGradient =
                          isLast && bar.bar.data.totalValue > threshold;

                        let barY = bar.y;
                        let barHeight = bar.height;

                        if (showGradient) {
                          barHeight -= thresholdValue - barY;
                          barY = thresholdValue;
                        }

                        return (
                          <Label
                            key={barStack.index + "-" + index}
                            barX={bar.x}
                            innerLabel
                            barY={barY + barHeight / 2}
                            columnWidth={bar.width}
                            barHeight={barHeight}
                            index={index}
                            value={
                              lens.stackingType === StackingType.Distribution
                                ? roundNumberToDecimal(
                                    bar.bar.data.values[bar.key] * 100,
                                    2,
                                  ) + "%"
                                : valueUOM.display(
                                    bar.bar.data.values[bar.key],
                                    { unit: "" },
                                  )
                            }
                            label={lens?.label}
                          />
                        );
                      })}
                    </>
                  )),
                ]}
              </BarStack>
            </Group>
          ))}

          {/* Rectangles to cover the whole stacked bar and handle all the events */}
          {transformedData.map((item) => {
            const x = dayScale(item.date) || 0;

            if (x < -dayColumnWidth * 5 || x > plotWidth + dayColumnWidth * 5) {
              return null;
            }

            const totalValue = Math.min(
              Math.max(item.day.totalValue ?? 0, item.night.totalValue ?? 0),
              valueScale.domain()[1],
            );

            return (
              <Bar
                key={item.date.toDateString()}
                x={x}
                y={valueScale(totalValue)}
                width={dayColumnWidth}
                height={valueScale(0) - valueScale(totalValue)}
                fillOpacity={0}
                onMouseOver={() => handleMouseOver(item)}
                onMouseOut={hideTooltip}
              />
            );
          })}

          <AverageLine
            isVisible={(lens?.selectedVisualAids ?? []).includes(
              VisualAidType.Average,
            )}
            y={valueScale(data.summary?.average ?? 0)}
            width={plotWidth}
          />

          <MedianLine
            isVisible={(lens?.selectedVisualAids ?? []).includes(
              VisualAidType.Median,
            )}
            y={valueScale(data.summary?.median ?? 0)}
            width={plotWidth}
          />

          {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Targets) &&
            computedTargetSegments.map(
              ({ target, lineStart, lineEnd, showTag }, index) => {
                if (!target) return null;
                return (
                  <TargetLine
                    key={`${index}-${target}-${lineStart}-${lineEnd}`}
                    start={lineStart}
                    end={lineEnd}
                    y={valueScale(target)}
                    label={valueUOM.display(target, { unit: "" })}
                    showTag={showTag}
                    detailed={detailed}
                  />
                );
              },
            )}
        </Chart>

        {!lens?.squeezesDisplay && zoomRate > 1 && (
          <Group
            top={plotHeight + (detailed ? 62 : 26)}
            left={scrollbarPadding}
          >
            <Scrollbar
              width={scrollbarWidth}
              height={4}
              scale={scrollbarScale}
              wheelCaptureContainer={containerRef}
              onScroll={setScrollbarRange}
              valueSpan={1 / zoomRate}
            />
          </Group>
        )}
      </svg>
      {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Average) && (
        <AverageLabel
          style={{
            top: `${
              valueScale(data.summary?.average ?? 0) -
              16 +
              (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)
            }px`,
          }}
        >
          Average: {valueUOM.display(data.summary?.average ?? 0)}
        </AverageLabel>
      )}

      {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Median) && (
        <MedianLabel
          isDetailed={detailed}
          style={{
            top: `${
              valueScale(data.summary?.median ?? 0) -
              16 +
              (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)
            }px`,
          }}
        >
          Median: {valueUOM.display(data.summary?.median ?? 0)}
        </MedianLabel>
      )}

      {tooltipElement}
    </div>
  );
};
