import type { DateDto } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import type { IAction } from "reducers/types";
import { range90Days } from "utils/helper";

export interface IFilterOptionValue {
  value?: string | number | null;
  id?: string | number | null;
}
export interface IFilterOptions {
  wellStatus: IFilterOptionValue[] | null;
  rigType: IFilterOptionValue[] | null;
  horsePower: IFilterOptionValue[] | null;
  country: IFilterOptionValue[] | null;
  operatingCenter: IFilterOptionValue[] | null;
  technology: IFilterOptionValue[] | null;
  holeSection: IFilterOptionValue[];
  directionalInterval: IFilterOptionValue[];
  holeSize: IFilterOptionValue[];
  phases: IFilterOptionValue[];
}

interface IAllRigsState {
  lastAllRigsRefreshDate: DateDto | null;
  operators: number[] | null;
  period: RangeType;
  rigIds: number[] | null;
  selectedWells: number[] | null;
  selectedWellsMap: number[] | null;
  filters: IFilterOptions | null;
  allWellsIncluded: boolean;
  exceptions: boolean;
  formationIds: number[] | null;
}

const initialState: IAllRigsState = {
  lastAllRigsRefreshDate: null,
  operators: null,
  rigIds: null,
  selectedWells: null,
  selectedWellsMap: null,
  period: range90Days,
  filters: null,
  allWellsIncluded: true,
  exceptions: false,
  formationIds: null,
};

interface ISetFiltersAction extends IAction {
  type: "RIGS_COMMON_SET_FILTERS";
  payload: IAllRigsState["filters"];
}

interface IUpdateFilterAction extends IAction {
  type: "RIGS_COMMON_UPDATE_FILTER";
  payload: {
    key: keyof IFilterOptions;
    value: (number | string)[] | null;
  };
}

interface ISetOperatorsAction extends IAction {
  type: "RIGS_COMMON_SET_OPERATORS";
  payload: {
    operators: IAllRigsState["operators"];
  };
}

interface ISetRigsAction extends IAction {
  type: "RIGS_COMMON_SET_RIGS";
  payload: {
    rigIds: IAllRigsState["rigIds"];
  };
}
interface ISetWellsAction extends IAction {
  type: "RIGS_COMMON_SET_WELLS";
  payload: {
    selectedWells: IAllRigsState["selectedWells"];
  };
}

interface ISetWellsMapAction extends IAction {
  type: "RIGS_COMMON_SET_WELLS_MAP";
  payload: {
    selectedWells: IAllRigsState["selectedWellsMap"];
  };
}

interface ISetPeriodAction extends IAction {
  type: "RIGS_COMMON_SET_PERIOD";
  payload: IAllRigsState["period"];
}
interface ISetLastUpdateDateAction extends IAction {
  type: "RIGS_COMMON_SET_LAST_UPDATE_DATE";
  payload: IAllRigsState["lastAllRigsRefreshDate"];
}

interface IResetAllRigsFilterers extends IAction {
  type: "RESET_RIGS_COMMON_FILTERS_90_DAYS";
  payload?: IAllRigsState["filters"];
}

interface IFullWellsIncluded extends IAction {
  type: "RIGS_COMMON_FULL_WELLS_INCLUDED";
  payload: IAllRigsState["allWellsIncluded"];
}
interface IExceptionsIncluded extends IAction {
  type: "RIGS_COMMON_EXCEPTIONS_INCLUDED";
  payload: IAllRigsState["exceptions"];
}

interface IFormationIds extends IAction {
  type: "RIGS_COMMON_FORMATIONS_IDS";
  payload: IAllRigsState["formationIds"];
}

type AvailableActions =
  | IExceptionsIncluded
  | IFullWellsIncluded
  | IResetAllRigsFilterers
  | ISetFiltersAction
  | ISetLastUpdateDateAction
  | ISetOperatorsAction
  | ISetPeriodAction
  | IFormationIds
  | ISetWellsAction
  | ISetWellsMapAction
  | ISetRigsAction
  | IUpdateFilterAction;

function rigsCommonReducer(
  state: IAllRigsState = initialState,
  action: AvailableActions,
): IAllRigsState {
  switch (action.type) {
    case "RIGS_COMMON_SET_LAST_UPDATE_DATE":
      return {
        ...state,
        lastAllRigsRefreshDate: action.payload,
      };
    case "RIGS_COMMON_UPDATE_FILTER":
      return {
        ...state,
        filters: state.filters
          ? {
              ...state.filters,
              [action.payload.key]: action.payload.value,
            }
          : null,
      };
    case "RIGS_COMMON_SET_OPERATORS":
      return {
        ...state,
        operators: action.payload.operators,
      };
    case "RIGS_COMMON_SET_RIGS":
      return {
        ...state,
        rigIds: action.payload.rigIds,
      };
    case "RIGS_COMMON_SET_WELLS":
      return {
        ...state,
        selectedWells: action.payload.selectedWells,
      };
    case "RIGS_COMMON_SET_WELLS_MAP":
      return {
        ...state,
        selectedWellsMap: action.payload.selectedWells,
      };
    case "RIGS_COMMON_SET_FILTERS":
      return {
        ...state,
        filters: action.payload,
      };
    case "RIGS_COMMON_SET_PERIOD":
      return {
        ...state,
        period: action.payload,
      };
    case "RIGS_COMMON_FULL_WELLS_INCLUDED":
      return {
        ...state,
        allWellsIncluded: action.payload,
      };
    case "RIGS_COMMON_EXCEPTIONS_INCLUDED":
      return {
        ...state,
        exceptions: action.payload,
      };
    case "RESET_RIGS_COMMON_FILTERS_90_DAYS":
      return {
        ...state,
        period: range90Days,
        filters: action.payload ? action.payload : null,
      };
    case "RIGS_COMMON_FORMATIONS_IDS":
      return {
        ...state,
        formationIds: action.payload,
      };
    default:
      return state;
  }
}

export default rigsCommonReducer;
