/* eslint-disable react/no-multi-comp */
import { useQueryClient } from "@tanstack/react-query";
import { ClaraMessageSource } from "apis/oag";
import { ChatLoader } from "components/Clara/Loaders/ChatLoader";
import { RobotLoader } from "components/Clara/Loaders/RobotLoader";
import * as Styled from "components/Clara/MessageThread/style";
import { PDComponent } from "components/PDComponents";
import { type Message } from "hooks/clara/useClara";
import { useEffect, useId } from "react";
import { useAppDispatch } from "reducers/store";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

export const IndividualMessageLoading = () => {
  return (
    <Styled.BoxInner>
      <Styled.Avatar $isResponse>{<RobotLoader />}</Styled.Avatar>
      <ChatLoader />
    </Styled.BoxInner>
  );
};

export const IndividualMessageFallback = ({
  resetError,
  message,
}: {
  resetError: () => void;
  message: Message;
}) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const id = useId();
  useEffect(() => {
    dispatch({ type: "RESET_PENDING_MESSAGE" });
    resetError();
    queryClient.setQueryData(
      [{ uid: RequestUID.clara, type: PDQueryType.CLARA_CONVERSATION_HISTORY }],
      (oldData: Array<Message>) => {
        return [
          ...oldData,
          message,
          {
            message: "",
            source: ClaraMessageSource.Model,
            isError: true,
            id: `error-id-${id}`,
          },
        ];
      },
    );
  }, [dispatch, id, message, queryClient, resetError]);

  return null;
};

export const IndividualMessageError = ({
  onReset,
}: {
  onReset: () => void;
}) => {
  return (
    <Styled.BoxInner>
      <Styled.Avatar $isResponse>
        {<PDComponent.SvgIcon name="claraError" />}
      </Styled.Avatar>
      <Styled.Message $isResponse $isError>
        <Styled.ErrorTitle>Oops! Something went wrong.</Styled.ErrorTitle>
        We&apos;re sorry, but we&apos;re having some trouble processing your
        request. Please try again later.
      </Styled.Message>
      <Styled.Regenerate
        onClick={() => {
          onReset();
        }}
      >
        <PDComponent.SvgIcon name="regenerate" />
        Regenerate
      </Styled.Regenerate>
    </Styled.BoxInner>
  );
};
