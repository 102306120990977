import { DashboardLayout } from "pages/Admin/Users/Editor/DashboardLayout";
import * as Styled from "pages/Admin/Users/style";
import { useCallback, useMemo, useState } from "react";
import Modal from "react-modal";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

const DashboardLayoutModal = ({
  isVisible,
  onCancel,
}: {
  isVisible: boolean;
  onCancel: () => void;
}) => {
  const { themeStyle } = useCustomTheme();
  // TODO move this custom style in other place
  const customStyles = {
    overlay: {
      zIndex: zIndexLayer.sky_plus,
      backgroundColor: "rgba(32,43,48,0.6)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      borderRadius: 12,
      bottom: "auto",
      padding: "0px!important",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
      height: "auto",
      overflow: "hidden",
      border: "none",
    },
  };
  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onCancel}
      style={{
        content: {
          ...customStyles.content,
          backgroundColor: themeStyle.colors.primary_bg,
        },
        overlay: customStyles.overlay,
      }}
      ariaHideApp={false}
    >
      <Styled.StyledModalContent>
        <DashboardLayout onCancel={onCancel} />
      </Styled.StyledModalContent>
    </Modal>
  );
};

export const useDashboardLayoutModal = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const modalElement = useMemo(() => {
    return (
      <DashboardLayoutModal
        isVisible={showModal}
        onCancel={() => setShowModal(false)}
      />
    );
  }, [showModal]);

  return { modalElement, openModal };
};
