/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { FuelFlowRateFactDto } from './FuelFlowRateFactDto';
import {
    FuelFlowRateFactDtoFromJSON,
    FuelFlowRateFactDtoFromJSONTyped,
    FuelFlowRateFactDtoToJSON,
} from './FuelFlowRateFactDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import type { FuelConsumptionByGeneratorPowerUsageFactDto } from './FuelConsumptionByGeneratorPowerUsageFactDto';
import {
    FuelConsumptionByGeneratorPowerUsageFactDtoFromJSON,
    FuelConsumptionByGeneratorPowerUsageFactDtoFromJSONTyped,
    FuelConsumptionByGeneratorPowerUsageFactDtoToJSON,
} from './FuelConsumptionByGeneratorPowerUsageFactDto';

/**
 * 
 * @export
 * @interface FuelConsumptionByGeneratorResultDto
 */
export interface FuelConsumptionByGeneratorResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    readonly stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    lastUpdatedAt?: DateDto | null;
    /**
     * 
     * @type {Array<FuelFlowRateFactDto>}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    dieselConsumptionFacts?: Array<FuelFlowRateFactDto> | null;
    /**
     * 
     * @type {Array<FuelFlowRateFactDto>}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    natGasConsumptionFacts?: Array<FuelFlowRateFactDto> | null;
    /**
     * 
     * @type {Array<FuelConsumptionByGeneratorPowerUsageFactDto>}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    generatorPowerUsageFacts?: Array<FuelConsumptionByGeneratorPowerUsageFactDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof FuelConsumptionByGeneratorResultDto
     */
    readonly dataState: ResultDataState;
}



/**
 * Check if a given object implements the FuelConsumptionByGeneratorResultDto interface.
 */
export function instanceOfFuelConsumptionByGeneratorResultDto(value: object): value is FuelConsumptionByGeneratorResultDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function FuelConsumptionByGeneratorResultDtoFromJSON(json: any): FuelConsumptionByGeneratorResultDto {
    return FuelConsumptionByGeneratorResultDtoFromJSONTyped(json, false);
}

export function FuelConsumptionByGeneratorResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelConsumptionByGeneratorResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': json['LastUpdatedAt'] == null ? undefined : DateDtoFromJSON(json['LastUpdatedAt']),
        'dieselConsumptionFacts': json['DieselConsumptionFacts'] == null ? undefined : ((json['DieselConsumptionFacts'] as Array<any>).map(FuelFlowRateFactDtoFromJSON)),
        'natGasConsumptionFacts': json['NatGasConsumptionFacts'] == null ? undefined : ((json['NatGasConsumptionFacts'] as Array<any>).map(FuelFlowRateFactDtoFromJSON)),
        'generatorPowerUsageFacts': json['GeneratorPowerUsageFacts'] == null ? undefined : ((json['GeneratorPowerUsageFacts'] as Array<any>).map(FuelConsumptionByGeneratorPowerUsageFactDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function FuelConsumptionByGeneratorResultDtoToJSON(value?: Omit<FuelConsumptionByGeneratorResultDto, 'StopWatch'|'DataState'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'DieselConsumptionFacts': value['dieselConsumptionFacts'] == null ? undefined : ((value['dieselConsumptionFacts'] as Array<any>).map(FuelFlowRateFactDtoToJSON)),
        'NatGasConsumptionFacts': value['natGasConsumptionFacts'] == null ? undefined : ((value['natGasConsumptionFacts'] as Array<any>).map(FuelFlowRateFactDtoToJSON)),
        'GeneratorPowerUsageFacts': value['generatorPowerUsageFacts'] == null ? undefined : ((value['generatorPowerUsageFacts'] as Array<any>).map(FuelConsumptionByGeneratorPowerUsageFactDtoToJSON)),
    };
}

