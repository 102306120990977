import {
  baseMatrixNotAvailable,
  MatrixAvailability,
} from "components/Lenses/ContainerLens/common/utils/constants";
import type { AbilityMatrixType } from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";

export const getTagBottomFingerprintAbilityMatrix = (): AbilityMatrixType => {
  return {
    ...baseMatrixNotAvailable,
    filters: MatrixAvailability.AVAILABLE,
    zoom: MatrixAvailability.AVAILABLE,
    lensSettings: MatrixAvailability.AVAILABLE,
    zoomByDate: MatrixAvailability.AVAILABLE,
    zoomByTime: MatrixAvailability.AVAILABLE,
    hasDetailedView: MatrixAvailability.AVAILABLE,
  };
};
