import {
  baseMatrix,
  MatrixAvailability,
} from "components/Lenses/ContainerLens/common/utils/constants";
import type { AbilityMatrixType } from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";

export const getGeneratorStatsAbilityMatrix = (): AbilityMatrixType => {
  return {
    ...baseMatrix,
    manual_axis: MatrixAvailability.NOT_SUPPORTED,
    kpiSelect: MatrixAvailability.AVAILABLE,
    perceptual_kpi_value: MatrixAvailability.NOT_SUPPORTED,
    comparison: MatrixAvailability.NOT_AVAILABLE,
    selectedVisualAids: MatrixAvailability.NOT_SUPPORTED,
    indicators: MatrixAvailability.NOT_SUPPORTED,
    display_options: MatrixAvailability.NOT_SUPPORTED,
    filters: MatrixAvailability.AVAILABLE,
    zoom: MatrixAvailability.AVAILABLE,
    squeezesDisplay: MatrixAvailability.NOT_SUPPORTED,
    showOperationCount: MatrixAvailability.NOT_SUPPORTED,
    showsOutliers: MatrixAvailability.NOT_SUPPORTED,
    zoomByTime: MatrixAvailability.AVAILABLE,
    zoomByDate: MatrixAvailability.AVAILABLE,
    lensSettings: MatrixAvailability.NOT_AVAILABLE,
    unitOfTime: MatrixAvailability.NOT_SUPPORTED,
    label: MatrixAvailability.NOT_SUPPORTED,
    trackKPI: MatrixAvailability.NOT_SUPPORTED,
    group: MatrixAvailability.NOT_SUPPORTED,
    subGroup: MatrixAvailability.NOT_SUPPORTED,
    period: MatrixAvailability.AVAILABLE,
    hasDetailedView: MatrixAvailability.NOT_AVAILABLE,
    legend: MatrixAvailability.NOT_SUPPORTED,
    showSurvey: MatrixAvailability.NOT_SUPPORTED,
    outlierFlaggingType: MatrixAvailability.NOT_SUPPORTED,
    breakdowns: MatrixAvailability.NOT_SUPPORTED,
    hasExport: MatrixAvailability.NOT_SUPPORTED,
    hasDetailedLegend: MatrixAvailability.NOT_SUPPORTED,
    hiddenFiltersDetailed: [],
  };
};
